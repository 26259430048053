<template>
  <div class="wrap">
    <describe-of-error
      :describeOnly="true"
      :description="$t('projectTemplate.popup.templateNotFound.description')"
    />

    <div class="button-wrap">
      <button-main
        type="emphasis"
        :text="$t('projectTemplate.popup.templateNotFound.button')"
        @click="$emit('click')"
      />
    </div>
  </div>
</template>

<script>
import ButtonMain from '@/components/atoms/button-main.vue'
import DescribeOfError from '@/components/organisms/common-popups/describe-of-error.vue'

export default {
  components: {
    ButtonMain,
    DescribeOfError
  },
  data() {
    return {}
  },
  props: {}
}
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
}

.desc {
  margin-bottom: $space-medium;
  white-space: pre-line;
}

.button-wrap {
  display: flex;
  align-items: center;
}
</style>
