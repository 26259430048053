<template>
  <div class="trained-ai-feature-importance-graph-info">
    <text-with-icon
      class="trained-ai-feature-importance-graph-info-title"
      :text="$t('trainedAiDetails.featureImportanceTab.distribution')"
      iconName="bubbleChart"
      color="green"
    />
    <div class="trained-ai-feature-importance-graph-info-desc-wrap">
      <texts
        v-for="(desc, key) in distributionDescs"
        :key="key"
        class="trained-ai-feature-importance-graph-info-desc"
        :text="desc"
        :size="key === 0 ? 'default' : 'small'"
        :color="key === 0 ? 'green' : 'gray'"
        :isBold="key === 0"
      />
    </div>
    <button-main
      class="trained-ai-feature-importance-graph-info-button"
      :text="$t('common.contact.consultant')"
      type="green"
      :fontSize="16"
      size="thin"
      isEllipse
      @click="goContact"
    />
  </div>
</template>

<script>
import buttonMain from '@/components/atoms//button-main.vue'
import texts from '@/components/atoms/text'
import textWithIcon from '@/components/molecules/text-with-icon'

export default {
  components: {
    buttonMain,
    texts,
    textWithIcon
  },
  methods: {
    goContact() {
      window.open(this.$urls.contactLink, '_blank')
    }
  },
  computed: {
    distributionDescs() {
      const target = Object.values(
        this.$t(
          'trainedAiDetails.featureImportanceTab.description.distribution'
        )
      )
      return target
    }
  }
}
</script>

<style lang="scss" scoped>
.trained-ai-feature-importance-graph-info {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  width: 100%;
  height: fit-content;
  max-height: 100%;
  padding: $space-small;
  border: $border-green;
  background: $background;
  border-radius: adjustVW(12);
  box-shadow: $box-shadow-main;

  &-title {
    margin-bottom: $space-sub;
  }

  &-desc {
    margin-bottom: $space-sub;
    line-height: $line-height-base;
    word-break: break-all;
    white-space: pre-line;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-wrap {
      overflow-y: auto;
      margin-bottom: $space-sub;
      @include scrollbar;
    }
  }

  &-button {
    height: adjustVW(40);
  }
}
</style>
