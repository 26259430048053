<template>
  <div class="preprocessing-list-check-wrap">
    <div class="preprocessing-list-check-main">
      <div
        v-if="checkDataset.description !== ''"
        class="preprocessing-list-check-description"
      >
        <description :text="checkDataset.description" />
      </div>
      <div v-if="lackColums.length > 0" class="preprocessing-list-check-lack">
        <div class="preprocessing-list-check-title">
          <texts
            :text="$t('preprocessing.applyingDataset.lackColumns')"
            color="caution"
            isBold
          />
        </div>
        <div class="preprocessing-list-check-row">
          <div
            v-for="(item, key) in lackColums"
            :key="key"
            class="preprocessing-list-check-col"
          >
            <bullet-item
              class="preprocessing-list-check-col-item"
              :text="item"
              caution
            />
          </div>
        </div>
      </div>
      <div class="preprocessing-list-check-title">
        <texts
          :text="$t('preprocessing.applyingDataset.datasetColumns')"
          isBold
        />
      </div>
      <div class="preprocessing-list-check-row">
        <div
          v-for="(item, key) in datasetColums"
          :key="key"
          class="preprocessing-list-check-col"
        >
          <bullet-item
            class="preprocessing-list-check-col-item"
            :text="item.name"
            :isDisabled="item.abled"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import description from '@/components/atoms/description'
import bulletItem from '@/components/atoms/bullet-item'

export default {
  components: {
    texts,
    description,
    bulletItem
  },
  props: {
    checkDataset: Object,
    preprocessing: Array
  },
  computed: {
    preprocessingList() {
      const preprocessingList = []
      for (const index in this.preprocessing[0].layers) {
        if (this.preprocessing[0].layers[index].column) {
          preprocessingList.push(this.preprocessing[0].layers[index].column)
        }
      }
      return preprocessingList
    },
    datasetColumList() {
      const col = []
      for (const index in this.checkDataset.columns) {
        col.push(this.checkDataset.columns[index])
      }
      return col
    },
    datasetColums() {
      const col = []
      for (const index in this.checkDataset.columns) {
        col.push({
          name: this.checkDataset.columns[index],
          abled:
            this.preprocessingList.indexOf(this.checkDataset.columns[index]) < 0
        })
      }
      return col
    },
    lackColums() {
      const col = []
      for (const index in this.preprocessingList) {
        if (this.datasetColumList.indexOf(this.preprocessingList[index]) < 0) {
          col.push(this.preprocessingList[index])
        }
      }
      return col
    }
  }
}
</script>

<style lang="scss" scoped>
.preprocessing-list-check {
  &-wrap {
    overflow: hidden;
    display: flex;
    width: 100%;
  }
  &-main {
    overflow-y: auto;
    flex-shrink: 0;
    flex-grow: 1;
    width: adjustVW(720);
    @include scrollbar;
  }
  &-description {
    margin: 0 0 $space-medium;
  }
  &-lack {
    margin: 0 0 $space-medium;
  }
  &-title {
    margin: 0 0 $space-sub;
  }
  &-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  &-col {
    width: 50%;
    padding: 0 $space-small $space-sub 0;
    &:last-child {
      padding: 0 $space-small 0 0;
    }
    &:nth-last-of-type(2) {
      padding: 0 $space-small 0 0;
    }
  }
}
</style>
