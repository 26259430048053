export function weeklyWrapping(data, { startDay = 1 } = {}) {
  function mod(x, y) {
    return ((x % y) + y) % y
  }
  const weekTime = 86400000 * 7
  const head = new Date(data[0].x)
  head.setDate(head.getDate() - ((head.getDay() - startDay + 7) % 7))
  head.setHours(0, 0, 0, 0)
  const headTime = head.getTime()
  const sortedData = data
    .map((d) => ({
      ...d,
      x: new Date(headTime + mod(d.x.getTime() - headTime, weekTime))
    }))
    .sort((a, b) => a.x - b.x)
  const first = sortedData[0]
  const last = sortedData[sortedData.length - 1]
  const wrappedData = [
    {
      ...last,
      x: new Date(last.x.getTime() - weekTime)
    },
    ...sortedData,
    {
      ...first,
      x: new Date(first.x.getTime() + weekTime)
    }
  ]

  return {
    data: wrappedData,
    start: head,
    end: new Date(headTime + weekTime)
  }
}

export function monthlyWrapping(data, { startDay = 1 } = {}) {
  function getTimeFromBeginningOfMonth(d) {
    const head = new Date(d)
    head.setDate(1)
    head.setHours(0, 0, 0, 0)
    return d.getTime() - head.getTime()
  }
  const monthTime = 86400000 * 31
  const head = new Date(data[0].x)
  head.setMonth(0) // 暫定的に31日の月に合わせる
  head.setDate(1)
  head.setHours(0, 0, 0, 0)
  const headTime = head.getTime()
  const sortedData = data
    .map((d) => ({
      ...d,
      x: new Date(headTime + getTimeFromBeginningOfMonth(d.x))
    }))
    .sort((a, b) => a.x - b.x)
  const first = sortedData[0]
  const last = sortedData[sortedData.length - 1]
  const wrappedData = [
    {
      ...last,
      x: new Date(last.x.getTime() - monthTime)
    },
    ...sortedData,
    {
      ...first,
      x: new Date(first.x.getTime() + monthTime)
    }
  ]

  return {
    data: wrappedData,
    start: head,
    end: new Date(headTime + monthTime)
  }
}

export function yearlyWrapping(data, { startDay = 1 } = {}) {
  function mod(x, y) {
    return ((x % y) + y) % y
  }
  const yearTime = 86400000 * 365
  const head = new Date(data[0].x)
  head.setMonth(0, 1)
  head.setHours(0, 0, 0, 0)
  const headTime = head.getTime()
  const sortedData = data
    .map((d) => ({
      ...d,
      x: new Date(headTime + mod(d.x.getTime() - headTime, yearTime))
    }))
    .sort((a, b) => a.x - b.x)
  const first = sortedData[0]
  const last = sortedData[sortedData.length - 1]
  const wrappedData = [
    {
      ...last,
      x: new Date(last.x.getTime() - yearTime)
    },
    ...sortedData,
    {
      ...first,
      x: new Date(first.x.getTime() + yearTime)
    }
  ]

  return {
    data: wrappedData,
    start: head,
    end: new Date(headTime + yearTime)
  }
}
