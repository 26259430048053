<template>
  <div class="apply-body-content-body">
    <texts
      class="apply-body-content-text"
      size="small"
      isShowAll
      :text="$t('preprocessing.message.standardize')"
    />
    <button-main
      class="apply-body-content-submit"
      :text="$t('preprocessing.applyingDataset.applyStandardize')"
      type="emphasis"
      @click="$emit('apply-standardize')"
    />
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import buttonMain from '@/components/atoms/button-main'

export default {
  components: {
    texts,
    buttonMain
  },
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {}
}
</script>
<style lang="scss" scoped>
.apply-body {
  &-content {
    &-body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      height: 100%;
      padding: 0 $space-small $space-small;
      opacity: 1;
      animation: toggle $transition-base;
      will-change: height, opacity;
    }
    &-text {
      flex-shrink: 0;
      padding: 0;
      margin: 0 0 $space-sub;
      white-space: pre-line;
    }
    &-submit {
      flex-shrink: 0;
      width: fit-content;
      margin: 0;
    }
  }
}

@keyframes toggle {
  0% {
    height: 0%;
    opacity: 0;
    transform: translateY(-$space-small);
  }
  100% {
    height: 100%;
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
