<template>
  <transition-toggle-contents>
    <div
      v-if="!inMainLoading && topic != null"
      key="main"
      class="topic-wrapper"
    >
      <card-base class="topic-target">
        <div class="topic-target-input">
          <text-with-title
            :title="$t('textMining.textTopic.info.target')"
            :text="value.targetName"
          />
        </div>
        <div class="topic-target-graph">
          <list-bar-graph
            v-model="selectedTopic"
            :title="$t('textMining.textTopic.info.targetTopic')"
            sortContent
            :scaleSize="Object.keys(topic).length"
            :list="topic"
            isHorizontal
            isButton
            :tipsLabel="$t('inference.result.confidence')"
            :maxCount="8"
            isPercentage
          />
        </div>
      </card-base>
      <card-base class="topic-list">
        <div class="topic-list-top">
          <texts
            :text="
              $t('textMining.textTopic.info.targetTopicWords', [selectedTopic])
            "
            color="gray"
            size="small"
          />
          <button @click="goTopic">
            <text-with-icon
              text="詳細を見る"
              iconName="forward"
              size="small"
              color="gray"
              pos="right"
            />
          </button>
        </div>
        <transition-toggle-contents>
          <div
            v-if="
              waitTextMiningLoading ||
                frequencies == null ||
                Object.keys(frequencies).length === 0
            "
            key="load-frequencies"
            class="loading"
          >
            <loading />
          </div>
          <div v-else key="topic-list" class="topic-list-graph">
            <list-bar-graph
              :scaleSize="8"
              :list="frequencies"
              :tipsLabel="$t('textMining.frequencies.tips.title')"
              isPercentage
            />
          </div>
        </transition-toggle-contents>
      </card-base>
      <card-base class="topic-contents">
        <div class="topic-contents-top">
          <div>
            <texts
              :text="
                $t('textMining.textTopic.info.contents', [value.targetName])
              "
              size="small"
              color="gray"
            />
          </div>
        </div>
        <div class="topic-contents-body">
          <text-scroll :contents="contents" />
        </div>
      </card-base>
      <div class="topic-similar">
        <div class="topic-similar-title">
          <texts :text="$t('textMining.textTopic.info.similar')" />
        </div>
        <transition-toggle-contents>
          <div
            v-if="waitTextMiningLoading && similar.length === 0"
            key="load-similar"
            class="loading"
          >
            <loading />
          </div>
          <div v-else key="similar" class="topic-similar-inner">
            <div
              v-for="(item, index) in similar"
              :key="index"
              class="topic-similar-item"
            >
              <card-item
                v-bind="item"
                showLda
                @go-text-detail="$emit('go-text-detail', $event)"
              />
            </div>
          </div>
        </transition-toggle-contents>
      </div>
    </div>
    <div v-else key="main-loading" class="loading">
      <loading />
    </div>
  </transition-toggle-contents>
</template>

<script>
import loading from '@/components/atoms/loading'
import cardBase from '@/components/atoms/card-base'
import textScroll from '@/components/molecules/text-mining/text-scroll'
import cardItem from '@/components/molecules/text-mining/card-item'
import textWithIcon from '@/components/molecules/text-with-icon'
import textWithTitle from '@/components/molecules/text-with-title'
import listBarGraph from '@/components/molecules/list-bar-graph'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

export default {
  components: {
    loading,
    cardBase,
    textScroll,
    cardItem,
    textWithIcon,
    textWithTitle,
    listBarGraph,
    transitionToggleContents
  },
  data() {
    return {
      selectedTopic: 0
    }
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    topic: {
      type: Object,
      default: () => {}
    },
    contents: {
      type: String,
      default: ''
    },
    similar: {
      type: Array,
      default: () => []
    },
    frequencies: {
      type: Object,
      default: () => {}
    },
    waitTextMiningLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goTopic() {
      this.$emit('go-topic', this.selectedTopic)
    },
    setTopicFrequencies(topic) {
      if (topic == null || Object.keys(topic).length === 0) return
      const targetTopic = Object.entries(topic).sort((x, y) => {
        return y[1] - x[1]
      })[0]
      this.selectedTopic = targetTopic[0]
    }
  },
  computed: {
    inMainLoading() {
      return (
        this.waitTextMiningLoading &&
        (this.contents == null ||
          this.contents.length === 0 ||
          this.topic == null ||
          (this.topic != null && Object.keys(this.topic).length === 0))
      )
    }
  },
  mounted() {
    this.setTopicFrequencies(this.topic)
  },
  watch: {
    topic: {
      handler(newVal) {
        this.setTopicFrequencies(newVal)
      },
      deep: true
    },
    selectedTopic(newVal) {
      this.$emit('select-topic', newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.topic {
  &-wrapper {
    display: grid;
    grid-template-areas:
      'target contents'
      'list similar';
    grid-template-columns: adjustVW(520) minmax(0, 1fr);
    grid-template-rows: repeat(2, calc(50% - $space-medium / 2));
    grid-column-gap: $space-medium;
    grid-row-gap: $space-medium;
    height: 100%;
  }
  &-target {
    display: flex;
    flex-direction: column;
    grid-area: target;
    height: 100%;
    &-input {
      margin-bottom: $space-small;
    }
    &-title {
      margin-bottom: $space-base;
    }
    &-graph {
      overflow: hidden;
      height: 100%;
    }
  }
  &-list {
    grid-area: list;
    display: flex;
    flex-direction: column;
    &-top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: $space-sub;
    }
    &-graph {
      overflow: hidden;
    }
  }
  &-contents {
    display: flex;
    flex-direction: column;
    grid-area: contents;
    &-top {
      display: flex;
      flex-shrink: 0;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: $space-base;
    }
    &-body {
      overflow: hidden;
      flex-shrink: 1;
      height: 100%;
    }
  }
  &-similar {
    grid-area: similar;
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-base;
    height: 100%;
    &-title {
      flex-shrink: 0;
    }
    &-inner {
      overflow-y: scroll;
      display: grid;
      grid-template-columns: repeat(
        3,
        calc((100% - (#{$space-small * 2})) / 3)
      );
      grid-column-gap: $space-small;
      grid-row-gap: $space-small;
      height: 100%;
      padding-right: $space-base;
      @include scrollbar;
    }
    &-item {
      height: adjustVW(240);
    }
  }
}
.loading {
  height: 100%;
}
</style>
