<template>
  <div class="add-new-service-nav">
    <div class="add-new-service-nav-desc">
      <transition-toggle-contents>
        <button v-if="dispBackButton" @click="back">
          <text-with-icon
            class="add-new-service-nav-title"
            :text="$t('common.pageBack')"
            size="small"
            iconName="backward"
          />
        </button>
      </transition-toggle-contents>
      <texts
        class="add-new-service-nav-desc-inner"
        :text="descText"
        size="small"
        color="gray"
      />
    </div>
    <div class="add-new-service-nav-main">
      <texts
        class="add-new-service-nav-main-title"
        :text="$t('common.progress')"
        size="small"
        color="gray"
      />
      <div class="add-new-service-nav-list">
        <texts
          :text="$t('serviceList.popup.addNewService.progress.recipe.title')"
          size="small"
          color="emphasis"
        />
        <icons iconName="forward" size="small" :color="checkColor(0)" />
        <template v-if="serviceType === 'learn'">
          <template v-if="!checkTextImageRecipeType">
            <texts
              :text="
                $t('serviceList.popup.addNewService.progress.dataset.title')
              "
              size="small"
              :color="checkColor(0)"
            />
            <icons iconName="forward" size="small" :color="checkColor(1)" />
            <texts
              :text="
                $t('serviceList.popup.addNewService.progress.preproc.title')
              "
              size="small"
              :color="checkColor(1)"
            />
            <icons iconName="forward" size="small" :color="checkColor(2)" />
            <texts
              :text="
                $t('serviceList.popup.addNewService.progress.column.title')
              "
              size="small"
              :color="checkColor(2)"
            />
            <icons iconName="forward" size="small" :color="checkColor(3)" />
          </template>
          <texts
            :text="$t('serviceList.popup.addNewService.progress.result.title')"
            size="small"
            :color="checkColor(3)"
          />
        </template>
        <template v-else-if="serviceType === 'optimization'">
          <texts
            :text="$t('serviceList.popup.addNewService.progress.preproc.title')"
            size="small"
            :color="checkColor(0)"
          />
          <icons iconName="forward" size="small" :color="checkColor(1)" />
          <texts
            :text="$t('serviceList.popup.addNewService.progress.result.title')"
            size="small"
            :color="checkColor(1)"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import textWithIcon from '@/components/molecules/text-with-icon'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

export default {
  components: {
    icons,
    textWithIcon,
    transitionToggleContents
  },
  props: {
    page: String,
    pageList: Array,
    serviceType: String,
    descText: String,
    checkTextImageRecipeType: {
      type: Boolean,
      default: false
    },
    checkTimeseriesRecipeType: {
      type: Boolean,
      default: false
    },
    inTimeseriesPredictionColumn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inPageNum() {
      if (this.inTimeseriesPredictionColumn) return 3
      return this.pageList.indexOf(this.page)
    },
    dispBackButton() {
      return this.inPageNum > 0
    }
  },
  methods: {
    back() {
      let backPage = this.pageList.indexOf(this.page) - 1
      backPage = this.pageList[backPage]

      if (this.serviceType === 'learn') {
        if (this.checkTextImageRecipeType) {
          backPage = 'recipe'
        } else if (this.inTimeseriesPredictionColumn) {
          backPage = 'preprocessing'
        } else if (this.checkTimeseriesRecipeType && this.page === 'column') {
          backPage = 'timeseriesPredictionColumn'
        }
      }

      this.$emit('page-back', backPage)
    },
    checkColor(index) {
      return this.inPageNum > index ? 'emphasis' : 'gray'
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-service {
  &-nav {
    display: grid;
    grid-template-columns: minmax(adjustVW(582), 1fr) adjustVW(966);
    grid-column-gap: $space-medium;
    padding: $space-sub;
    background: $background-sub;
    border-radius: adjustVW(8);
    &-list {
      display: flex;
      grid-column-gap: $space-small;
      border-radius: adjustVW(4);
    }
    &-title {
      margin-bottom: $space-text;
    }
    &-desc {
      &-inner {
        white-space: pre-line;
      }
    }
    &-main {
      &-title {
        margin-bottom: $space-text;
      }
    }
  }
}
</style>
