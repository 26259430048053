<template>
  <card-base class="regression-features-variables-card">
    <div class="trained-ai-feature-importance-size">
      <div class="trained-ai-feature-importance-size-title">
        <texts
          :text="$t('common.nFeatureDataShown')"
          color="gray"
          size="small"
        />

        <div class="trained-ai-feature-importance-size-count">
          <input
            :value="count"
            type="number"
            min="0"
            :max="maxCount"
            @input="$emit('input', $event.target.value)"
          >
        </div>
      </div>

      <div class="trained-ai-feature-importance-size-scale">
        <input
          class="trained-ai-feature-importance-size-scale-range"
          type="range"
          name="speed"
          min="0"
          :max="maxCount"
          :value="count"
          @input="$emit('input', $event.target.value)"
        >
        <div
          class="trained-ai-feature-importance-size-scale-range-status"
          :style="{ '--width': (count / maxCount) * 100 + '%' }"
        />
      </div>
    </div>
  </card-base>
</template>

<script>
import cardBase from '@/components/atoms/card-base.vue'
import texts from '@/components/atoms/text.vue'

export default {
  components: {
    cardBase,
    texts
  },
  props: {
    count: Number,
    maxCount: Number
  },
  watch: {
    count(newVal) {
      if (newVal < 0) {
        this.$emit('input', 0)
      } else if (newVal > this.maxCount) {
        this.$emit('input', this.maxCount)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.trained-ai-feature-importance-size-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $space-small;
}

.trained-ai-feature-importance-size-count {
  width: adjustVW(56);
  padding: $space-min;
  border: $border-emphasis;
  font-size: $text-base;
  border-radius: adjustVW(4);

  > input {
    appearance: textfield;
    width: 100%;
    text-align: center;
    &::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }
}

.trained-ai-feature-importance-size-scale {
  position: relative;

  &-range {
    appearance: none;
    width: 100%;
    height: adjustVH(8);
    background: $background-sub;
    border-radius: adjustVW(4);
    cursor: pointer;
    outline: none;

    &::-webkit-slider-thumb {
      position: relative;
      appearance: none;
      width: adjustVW(24);
      height: adjustVW(24);
      border: $border-emphasis;
      background: $background;
      border-radius: 9in;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
      z-index: 2;
    }

    &::-moz-range-thumb {
      width: adjustVW(24);
      height: adjustVW(24);
      border: $border-emphasis;
      background: $background;
      border-radius: 9in;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    }

    &-status {
      --width: 50;
      position: absolute;
      top: 50%;
      width: var(--width);
      height: adjustVH(8);
      background: $key-color;
      border-radius: 9in 0 0 9in;
      z-index: 1;
      transform: translateY(-50%);
    }
  }
}
</style>
