<template>
  <div class="inference-regression-features-wrapper">
    <div class="inference-regression-features-variables">
      <card-base
        v-if="
          usedAiInfo.predictionColumns &&
            usedAiInfo.predictionColumns.length > 1
        "
        class="inference-regression-features-multi"
      >
        <select-column-box
          :title="$t('trainedAi.multi.selectedColumn')"
          :finishColumns="finishColumnsFix"
          :trainedAi="usedAiInfo"
          :value="selectedItem"
          @input="$emit('change-column', $event)"
        />
      </card-base>
      <div class="inference-regression-features-variables-card">
        <transition-toggle-contents>
          <adjust-display-features
            key="features"
            :maxCount="featuresLength"
            :count="featuresCounter"
            @input="changeCount"
          />
        </transition-toggle-contents>
      </div>
    </div>

    <div class="inference-regression-features-graph">
      <div class="inference-regression-features-graph-card">
        <features-graph-card
          :features="meanShap"
          :viewType="viewType"
          :maxCount="featuresCounter"
          :loading="loading"
          :selectedColumnIndex="selectedColumnIndex"
        />
      </div>
    </div>

    <div class="inference-regression-features-used-ai">
      <div class="inference-regression-features-used-ai-card">
        <card-base>
          <model-info
            :title="this.$i18n.t('inference.result.card.usedAi')"
            :modelInfo="usedAiInfo"
          />
        </card-base>
      </div>
    </div>
  </div>
</template>

<script>
import adjustDisplayFeatures from '@/components/organisms/inference/result/regression/features/adjust-display-features.vue'
import cardBase from '@/components/atoms/card-base.vue'
import featuresGraphCard from './features/features-graph-card.vue'
import modelInfo from '@/components/organisms/model-info.vue'
import selectColumnBox from '@/components/organisms/trained-ai-common/select-column-box.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    adjustDisplayFeatures,
    cardBase,
    featuresGraphCard,
    modelInfo,
    selectColumnBox,
    transitionToggleContents
  },
  data() {
    const featuresLength = Object.keys(this.meanShap).length
    return {
      viewType: 'distribution',
      featuresLength,
      featuresCounter: Math.min(20, featuresLength)
    }
  },
  props: {
    featureImportance: Object,
    usedAiInfo: Object,
    meanShap: Object,
    finishColumnsFix: Array,
    selectedItem: String,
    selectedColumnIndex: Number,
    loading: Boolean
  },
  computed: {
    sortedEntries() {
      if (!this.meanShap) return null
      return Object.entries(this.meanShap).sort((x, y) => y[1] - x[1])
    },
    featureImportances() {
      if (!this.sortedEntries) return null
      const features = this.sortedEntries.map((value, index) => {
        return {
          rank: index + 1,
          name: value[0],
          value: value
        }
      })
      return features
    },
    variables() {
      return this.sortedEntries.map((value, index) => {
        return {
          id: index,
          name: value[0]
        }
      })
    }
  },
  methods: {
    changeGraph: function ({ graph }) {
      this.viewType = graph
    },
    changeCount: function (val) {
      this.featuresCounter = Number(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.inference-regression-features-wrapper {
  display: grid;
  grid-template-columns: adjustVW(344) 1fr minmax(0, #{adjustVW(384)});
  grid-template-rows: 1fr;
  grid-column-gap: $space-medium;
  height: 100%;
  padding: 0 $space-medium $space-medium;
}

.inference-regression-features {
  &-menu {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    &-card {
      display: flex;
      flex-direction: column;
    }
    &-title {
      margin-bottom: $space-base;
    }

    &-item {
      width: 100%;
      padding: $space-base $space-sub;
      margin: 0 0 $space-base;
      background: transparent;
      border-radius: 9in;
      &:last-child {
        margin: 0;
      }
      &-active {
        background: $key-lite;
      }
    }
  }

  &-variables {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    &-card {
      max-height: 100%;
    }
  }

  &-graph {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    &-card {
      max-width: 100%;
      height: 100%;
    }
  }

  &-used-ai {
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    &-card {
      height: fit-content;
    }
  }

  &-multi {
    margin-bottom: $space-medium;
  }
}
</style>
