<template>
  <div class="c-wrap">
    <sidebar-project-detail v-if="!notFound || loading" v-bind="sidebar" />
    <sidebar v-else />
    <div class="c-body-wrap">
      <header-tabs
        v-bind="headerTabs"
        @tab-click="$emit('tab-click', $event)"
      />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="loading" />
          <page-not-found v-else-if="notFound" />
          <service-detail-main
            v-else
            v-bind="body"
            :serviceType="serviceType"
            :isEditting="isEditting"
            :serviceMenu="serviceMenu"
            :service="service"
            :project="project"
            :customblockList="customblockList"
            @change-tab="$emit('change-tab', $event)"
            @input-edit-form="$emit('input-edit-form', $event)"
            @menu-click="$emit('menu-click', $event)"
            @save-preproc="$emit('save-preproc', $event)"
            @select-item="$emit('select-item', $event)"
            @change-set-recipe="$emit('change-set-recipe', $event)"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="disableClick" />
    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
    <service-detail-popup
      :service="service"
      v-bind="popup"
      :disableClick="disableClick"
      @close-modal="$emit('close-modal', $event)"
      @delete-service="$emit('delete-service', $event)"
      @cancel-delete="$emit('cancel-delete')"
    />
  </div>
</template>

<script>
import sidebarProjectDetail from '@/components/organisms/sidebar-project-detail.vue'
import Sidebar from '@/components/organisms/sidebar.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import serviceDetailMain from '@/components/organisms/service-detail/service-detail-main.vue'
import serviceDetailPopup from '@/components/organisms/service-detail/service-detail-popup.vue'
import Loading from '@/components/atoms/loading.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import transitionPage from '@/components/molecules/transition-page'
import TransitionToggleContents from '../molecules/transition-toggle-contents.vue'
import pageNotFound from '@/components/organisms/page-not-found'

export default {
  components: {
    sidebarProjectDetail,
    Sidebar,
    hoverMenu,
    headerTabs,
    serviceDetailMain,
    serviceDetailPopup,
    Loading,
    loadingProcessing,
    transitionPage,
    TransitionToggleContents,
    pageNotFound
  },
  props: {
    service: Object,
    serviceMenu: Array,
    serviceType: String,
    project: Object,
    sidebar: Object,
    headerTabs: Object,
    body: Object,
    popup: Object,
    isEditting: Boolean,
    loading: Boolean,
    notFound: Boolean,
    disableClick: Boolean,
    customblockList: Array
  }
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: grid;
  grid-template-columns: adjustVW(240) 100vw - adjustVW(240);
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  width: 100%;
  margin: adjustVH($headerTabHeight) 0 0;
}
</style>
