<template>
  <div class="optimization-contents">
    <div class="optimization-contents-block optimization-contents-block-column">
      <texts
        class="optimization-contents-title"
        :text="$t('training.config.detail.optimizationColumn')"
        size="small"
        color="gray"
      />
      <div class="column-list">
        <template v-for="(column, index) in optimizationColumns">
          <bullet-item
            :key="index"
            class="column-item"
            :text="column"
            size="small"
          />
        </template>
      </div>
    </div>
    <div
      class="optimization-contents-block optimization-contents-block-conditions"
    >
      <texts
        class="optimization-contents-title"
        :text="$t('trainedAi.optimization.conditions.titleResult')"
        size="small"
        color="gray"
      />
      <div class="conditions-list">
        <optimization-conditions-list
          class="conditions-list-body"
          :optimizationConditions="latestOptimizationConditions"
          vertical
          noBorder
        />
      </div>
    </div>
  </div>
</template>

<script>
import bulletItem from '@/components/atoms/bullet-item'
import optimizationConditionsList from '@/components/organisms/optimization/optimization-conditions-list'

export default {
  components: {
    bulletItem,
    optimizationConditionsList
  },
  props: {
    latestOptimizationConditions: Object,
    optimizationColumns: Array
  }
}
</script>

<style lang="scss" scoped>
$optimization-max-height: 360;

.optimization-contents {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  grid-row-gap: adjustVW(20);
  width: 100%;
  max-height: adjustVW($optimization-max-height);
  &-title {
    flex-shrink: 0;
    margin-bottom: $space-base;
  }
  &-block {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &-column {
      flex-shrink: 0;
      max-height: adjustVW(112);
      padding-bottom: adjustVW(20);
      border-bottom: $border-sub;
    }
  }
  &-none {
    &-optimization {
      white-space: pre-line;
    }
  }
}

.column {
  &-list {
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(
      3,
      calc(#{100% / 3} - #{$space-medium * 2 / 3})
    );
    grid-column-gap: $space-medium;
    grid-row-gap: $space-base;
    width: 100%;
    padding-right: $space-base;
    @include scrollbar;
  }
}

.conditions {
  &-list {
    overflow-y: auto;
    height: 100%;
    padding-right: $space-base;
    @include scrollbar;
  }
}
</style>
