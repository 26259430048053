import * as csv from 'csv-parser'

export async function getSamples(file, limit) {
  async function tryDecode(file, decoder) {
    const nonNullExists = new Set() // null以外が出たら追加する
    const headers = new Map() // ヘッダーにつける次の番号
    function mapHeaders({ header, index }) {
      let name = ''
      const headerName = header !== '' ? header : `Unnamed: ${index}`
      if (headers.has(headerName)) {
        const n = headers.get(headerName)
        headers.set(headerName, n + 1)
        name = `${headerName}.${n}`
      } else {
        headers.set(headerName, 1)
        name = `${headerName}`
      }
      if (header !== '') {
        nonNullExists.add(headerName) // ヘッダーが空でない
      }
      return name
    }

    const csvParser = csv({ mapHeaders })
    const reader = file.stream().getReader()
    const res = []
    let error = null

    try {
      let ended = false
      while (!ended) {
        const { done, value } = await reader.read()
        if (done) {
          csvParser.end()
          break
        }
        const t = new Promise((resolve, reject) => {
          csvParser.on('data', (e) => {
            for (const k in e) {
              if (e[k] !== null && e[k] !== '') {
                nonNullExists.add(k)
              }
            }
            res.push(e)
            if (limit != null && res.length >= limit) {
              csvParser.destroy()
              resolve(true)
            }
          })
          csvParser.on('end', () => {
            csvParser.destroy()
            resolve(true)
          })
          csvParser.on('error', reject)
          csvParser.on('pause', () => {
            resolve(false)
          })
        })
        csvParser.write(
          decoder.decode(value, {
            stream: !done
          })
        )
        csvParser.pause()
        ended = await t
        csvParser.resume()
      }
    } finally {
      reader.cancel()
      reader.releaseLock()
    }

    let rightmost = 0
    csvParser.headers.forEach((key, index) => {
      if (nonNullExists.has(key)) {
        rightmost = index
      }
    })

    // 1行もデータがない場合はエラーを表示
    if (res.length === 0) {
      error = 'NO_LINE_DATA'
    }

    const result = {
      columns: csvParser.headers.slice(0, rightmost + 1),
      entries: res,
      error
    }

    return result
  }
  try {
    const utf8Decoder = new TextDecoder('utf-8', { fatal: true })
    return await tryDecode(file, utf8Decoder)
  } catch (e) {
    const cp932Decoder = new TextDecoder('shift-jis', { fatal: true })
    return await tryDecode(file, cp932Decoder)
  }
}
