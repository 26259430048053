<template>
  <transition-toggle-contents>
    <trained-ai-detail-tab-detail
      v-if="activeTab === 'detail'"
      key="detail"
      :trainedAi="trainedAi"
      :finishColumns="finishColumns"
      :selectedColumnIndex="selectedColumnIndex"
      :loadChangeColumn="loadChangeColumn"
      @change-column="$emit('change-column', $event)"
    />
    <trained-ai-detail-tab-convert-result
      v-else-if="activeTab === 'convertResult'"
      key="convertResult"
      :head="trainedAi.result.converted_head"
      @download-result="$emit('download-result', $event)"
    />
    <trained-ai-detail-tab-wordcloud
      v-else-if="activeTab === 'wordcloud'"
      key="wordcloud"
      :wordcloud="trainedAi.wordcloudImg"
      :frequencies="trainedAi.frequencies"
    />
    <trained-ai-detail-tab-trained-info
      v-else-if="activeTab === 'trainedInfo'"
      key="trainedInfo"
      :trainedAi="trainedAi"
      :metricsDefs="metricsDefs"
      :finishColumns="finishColumns"
      :selectedColumnIndex="selectedColumnIndex"
      :loadChangeColumn="loadChangeColumn"
      @change-column="$emit('change-column', $event)"
    />
  </transition-toggle-contents>
</template>

<script>
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import trainedAiDetailTabDetail from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-detail'
import trainedAiDetailTabWordcloud from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-wordcloud'
import trainedAiDetailTabConvertResult from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-convert-result'
import trainedAiDetailTabTrainedInfo from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-trained-info'

import { classification } from '../metricsDefs.js'

export default {
  components: {
    transitionToggleContents,
    trainedAiDetailTabDetail,
    trainedAiDetailTabWordcloud,
    trainedAiDetailTabConvertResult,
    trainedAiDetailTabTrainedInfo
  },
  props: {
    trainedAi: Object,
    featureImportanceScatter: Object,
    tabs: Array,
    activeTab: String,
    activeMenu: String,
    finishColumns: Array,
    selectedColumnIndex: Number,
    loadChangeColumn: Boolean
  },
  computed: {
    metricsDefs() {
      return classification(this.$t.bind(this))
    }
  }
}
</script>
