var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"apply-wrap"},[_c('div',{staticClass:"apply-side"},[_c('div',{staticClass:"apply-side-dataset"},[_c('div',{staticClass:"apply-side-inputbox"},[_c('input-box-validation',{attrs:{"accept":_vm.validate.dataset.accept,"error":_vm.validate.dataset.error,"title":_vm.$t('preprocessing.applyingDataset.datasetNameToSave'),"placeholder":_vm.$t('preprocessing.applyingDataset.datasetNamePlaceholder'),"popupView":"","isNameValidation":"","nameValidationSuggests":_vm.datasetFormValidate.suggests},model:{value:(_vm.datasetOption.name),callback:function ($$v) {_vm.$set(_vm.datasetOption, "name", $$v)},expression:"datasetOption.name"}})],1),_c('div',{staticClass:"apply-side-inputbox"},[_c('input-box',{attrs:{"isTextArea":"","isGray":"","title":_vm.$t('preprocessing.applyingDataset.datasetDescriptionToSave'),"placeholder":_vm.$t('preprocessing.applyingDataset.datasetDescriptionPlaceholder')},model:{value:(_vm.datasetOption.description),callback:function ($$v) {_vm.$set(_vm.datasetOption, "description", $$v)},expression:"datasetOption.description"}})],1),_c('button-large',{staticClass:"apply-side-button",attrs:{"isDisabled":_vm.disabledSubmit || _vm.disableClick,"text":_vm.$t('preprocessing.applyingDataset.saveDataset'),"type":'emphasis'},on:{"click":function($event){return _vm.$emit('save-dataset', {
            dataset: _vm.datasetOption,
            preprocessing: _vm.preprocessing,
            preprocessingSave: _vm.checked
          })}}})],1),_c('div',{staticClass:"apply-side-preprocessing"},[_c('div',{staticClass:"apply-side-text"},[_c('texts',{attrs:{"text":_vm.$t('preprocessing.applyingDataset.settingsSave'),"isBold":""}})],1),_c('div',[_c('checkbox',{staticClass:"apply-side-checkbox",attrs:{"text":_vm.$t('preprocessing.applyingDataset.saveSameTIme'),"checked":_vm.checked},on:{"input":function($event){_vm.checked = $event}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.checked),expression:"checked"}]},[_c('div',{staticClass:"apply-side-text"},[_c('texts',{attrs:{"size":"small","text":_vm.$t('preprocessing.applyingDataset.preprocessingContent'),"isBold":""}})],1),_c('preprocessign-chart-list-min',{staticClass:"apply-side-chart",attrs:{"layers":_vm.layers}}),_c('button-toggle',{attrs:{"closeText":_vm.$t('preprocessing.applyingDataset.settingsHowSave'),"openText":_vm.$t('preprocessing.applyingDataset.settingsHowSave'),"value":_vm.checkPreprocName}},[_c('div',{staticClass:"apply-side-inputbox"},[_c('input-box-validation',{attrs:{"accept":_vm.validate.preproc.accept,"error":_vm.validate.preproc.error,"placeholder":_vm.$t(
                  'preprocessing.applyingDataset.preprocessingNamePlaceholder'
                ),"title":_vm.$t('preprocessing.applyingDataset.preprocessingNameToSave'),"popupView":"","isNameValidation":"","nameValidationSuggests":_vm.preprocFormValidate.suggests},model:{value:(_vm.preprocessing.name),callback:function ($$v) {_vm.$set(_vm.preprocessing, "name", $$v)},expression:"preprocessing.name"}})],1),_c('div',{staticClass:"apply-side-inputbox"},[_c('input-box',{attrs:{"isGray":"","isTextArea":"","placeholder":_vm.$t(
                  'preprocessing.applyingDataset.preprocessingDescriptionPlaceholder'
                ),"title":_vm.$t(
                  'preprocessing.applyingDataset.preprocessingDescriptionToSave'
                )},model:{value:(_vm.preprocessing.description),callback:function ($$v) {_vm.$set(_vm.preprocessing, "description", $$v)},expression:"preprocessing.description"}})],1)])],1)])]),_c('div',{staticClass:"apply-table"},[_c('div',{staticClass:"apply-table-inner"},[_c('table-main',{key:"dataset",staticClass:"apply-table-main",attrs:{"tableColumns":_vm.dataset.tableColumns.map(function (item) { return item.name; }),"table":_vm.dataset.table,"pagingCount":5}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }