<template>
  <div class="comparison-graph-body">
    <div class="comparison-graph-inner">
      <comparison-graph-detail-large
        v-if="dataCount > 5000"
        class="comparison-graph-inner"
        :data="list"
        :xLabel="$t('trainedAi.metrics.predictions')"
        :yLabel="$t('serviceDetail.sampleCode.responses.item.observedValue')"
        :cautionValue="checkedValue"
        @click="clickShowDetail"
      />
      <comparison-graph-detail
        v-else
        class="comparison-graph-inner"
        :data="list"
        :xLabel="$t('trainedAi.metrics.predictions')"
        :yLabel="$t('serviceDetail.sampleCode.responses.item.observedValue')"
        :cautionValue="checkedValue"
        @click="clickShowDetail"
      />
    </div>
    <div class="comparison-graph-side">
      <transition-toggle-contents>
        <div
          v-if="loadGraphDetail"
          key="loading"
          class="comparison-graph-side-none"
        >
          <loading />
        </div>
        <div
          v-else-if="!regressionGraphInfo"
          key="none"
          class="comparison-graph-side-none"
        >
          <texts
            :text="$t('trainedAi.regressionGraph.noDetail')"
            size="small"
            color="gray"
            class="comparison-graph-side-text"
          />
        </div>
        <div v-else key="detail" class="comparison-graph-side-inner">
          <div v-if="regressionGraphInfo" class="comparison-graph-side-top">
            <div
              v-for="(value, index) in regressionGraphInfoMain"
              :key="index"
              class="comparison-graph-side-content"
            >
              <div class="comparison-graph-side-content-title">
                <texts size="small" color="emphasis" :text="value.text" />
                <icons
                  v-if="value.target === 'residualError'"
                  v-tooltip="$t('trainedAi.regressionGraph.residualErrorTips')"
                  iconName="info"
                  size="small"
                  color="emphasis"
                />
              </div>
              <texts
                class="comparison-graph-side-content-last"
                size="small"
                color="emphasis"
                :text="regressionGraphInfo[value.target]"
              />
            </div>
          </div>
          <div v-if="graphDetailContents" class="comparison-graph-side-list">
            <div
              v-for="(obj, index) in graphDetailContents"
              :key="index"
              class="comparison-graph-side-content"
            >
              <texts :text="obj.key" size="min" color="gray" />
              <texts
                :text="obj.value"
                size="min"
                color="gray"
                class="comparison-graph-side-content-last"
              />
            </div>
          </div>
        </div>
      </transition-toggle-contents>
    </div>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import loading from '@/components/atoms/loading'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import comparisonGraphDetail from './comparison-graph-detail'
import comparisonGraphDetailLarge from './comparison-graph-detail-large'

export default {
  components: {
    icons,
    loading,
    transitionToggleContents,
    comparisonGraphDetail,
    comparisonGraphDetailLarge
  },
  props: {
    dataCount: Number,
    list: Array,
    loadGraphDetail: Boolean,
    selectedRegressionData: Number,
    graphDetail: Object,
    selectedResidualError: Number,
    setResidualError: Boolean,
    inputColumns: Array
  },
  computed: {
    regressionGraphInfo() {
      if (this.selectedRegressionData == null) return null
      const list = this.list
      const target = list.find((content) => {
        return content.index === this.selectedRegressionData
      })
      return {
        ...target,
        index: target.index + 1
      }
    },
    regressionGraphInfoMain() {
      return [
        {
          text: this.$t('datasetDetail.tableSide.row'),
          target: 'index'
        },
        {
          text: this.$t('trainedAi.regressionGraph.graph.actualValue'),
          target: 'actualValue'
        },
        {
          text: this.$t('trainedAiDetails.timeseries.predictionResult'),
          target: 'inferenceResult'
        },
        {
          text: this.$t('trainedAi.regressionGraph.graph.residualError'),
          target: 'residualError'
        }
      ]
    },
    graphDetailContents() {
      if (this.graphDetail == null || this.graphDetail?.contents == null)
        return null
      const target = Object.entries(this.graphDetail.contents)
      return target.reduce((prev, [key, value]) => {
        if (this.inputColumns.includes(key)) {
          prev.push({
            key,
            value
          })
        }
        return prev
      }, [])
    },
    checkedValue() {
      if (!this.setResidualError) return null
      return this.selectedResidualError
    }
  },
  methods: {
    clickShowDetail(e) {
      this.$emit('select-regression-graph', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.comparison {
  &-graph {
    width: 100%;
    height: 100%;
    &-body {
      display: grid;
      grid-template-columns: 1fr adjustVW(240);
      grid-template-rows: 100%;
      grid-column-gap: $space-small;
      height: adjustVW(400);
    }
    &-inner {
      width: 100%;
      height: 100%;
    }
    &-side {
      overflow: hidden;
      height: 100%;
      padding: $space-sub;
      border: $border-sub;
      border-radius: adjustVW(12);
      &-inner {
        overflow: hidden;
        display: grid;
        grid-template-rows: auto 1fr;
        height: 100%;
      }
      &-top {
        display: flex;
        flex-direction: column;
        grid-row-gap: $space-base;
        padding: $space-sub;
        margin-bottom: $space-small;
        background-color: $background-sub;
        border-radius: adjustVW(8);
      }
      &-list {
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        grid-row-gap: $space-base;
        max-height: 100%;
        padding-right: $space-sub;
        @include scrollbar;
      }
      &-content {
        display: grid;
        justify-content: space-between;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: $space-small;
        width: 100%;
        &-last {
          text-align: right;
        }
        &-title {
          display: flex;
          align-items: center;
          grid-column-gap: $space-base;
        }
      }
      &-none {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      &-text {
        text-align: center;
        white-space: pre-line;
      }
    }
  }
}
</style>
