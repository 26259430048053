<template>
  <div class="clusterflow-table">
    <div class="block">
      <table class="body">
        <thead>
          <tr class="label">
            <th class="label-inner label-result-1">
              <button class="label-button" @click="clickSort('index')">
                <texts
                  :text="$t('datasetDetail.tableSide.row')"
                  size="small"
                  :color="sortValue.target === 'index' ? 'emphasis' : 'default'"
                />
                <icons
                  class="label-button-icon"
                  :class="{
                    'label-button-icon-desc':
                      sortValue.sort === 'desc' && sortValue.target === 'index'
                  }"
                  iconName="toggle"
                  size="small"
                  :color="sortValue.target === 'index' ? 'emphasis' : 'default'"
                />
              </button>
            </th>
            <th class="label-inner label-result-2">
              <button class="label-button" @click="clickSort('class')">
                <texts
                  :text="$t('clustering.result.class')"
                  size="small"
                  :color="sortValue.target === 'class' ? 'emphasis' : 'default'"
                />
                <icons
                  class="label-button-icon"
                  :class="{
                    'label-button-icon-desc':
                      sortValue.sort === 'desc' && sortValue.target === 'class'
                  }"
                  iconName="toggle"
                  size="small"
                  :color="sortValue.target === 'class' ? 'emphasis' : 'default'"
                />
              </button>
            </th>
            <template v-for="(key, index) in clusteringInfo.inputList">
              <transition-group
                :key="index"
                tag="th"
                class="label-inner"
                name="toggle-detail"
                mode="out-in"
              >
                <template>
                  <texts :key="index" :text="key" size="small" />
                </template>
              </transition-group>
            </template>
          </tr>
        </thead>
        <tbody
          key="table"
          class="data-wrapper"
          :class="{
            'data-wrapper-loading': loading,
            'data-wrapper-active': !loading
          }"
        >
          <tr
            v-for="(entry, index) in clusteringResult.list"
            :key="index"
            class="data"
          >
            <td class="data-inner data-result-1">
              <texts :text="filterIndexClass(index, 'index')" size="small" />
            </td>
            <td class="data-inner data-result-2">
              <texts
                :text="
                  filterIndexClass(index, 'class')
                    ? filterIndexClass(index, 'class')
                    : $t('clustering.result.noise')
                "
                size="small"
                :color="filterIndexClass(index, 'class') ? 'default' : 'gray'"
              />
            </td>
            <template v-for="(key, index) in clusteringInfo.inputList">
              <td :key="index" class="data-inner">
                <texts :key="index" :text="entry[key]" size="small" />
              </td>
            </template>
          </tr>
        </tbody>
      </table>
      <transition-toggle-contents>
        <div v-if="loading || internalLoading" key="load" class="loading">
          <loading-icon />
        </div>
      </transition-toggle-contents>
    </div>
    <transition-toggle-contents>
      <div v-if="!resultId == null || resultId === ''" class="none">
        <texts :text="$t('clustering.result.noResult')" color="gray" />
      </div>
    </transition-toggle-contents>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import loadingIcon from '@/components/atoms/loading-icon'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    icons,
    loadingIcon,
    transitionToggleContents
  },
  data() {
    return {
      internalLoading: false
    }
  },
  props: {
    clusteringInfo: {
      type: Object,
      default: () => {}
    },
    clusteringResult: {
      type: Object,
      default: () => {}
    },
    sort: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    resultId: {
      type: [String, Number],
      default: ''
    },
    limit: {
      type: Number,
      default: 50
    },
    inPageNumber: {
      type: Number,
      default: 1
    }
  },
  computed: {
    sortValue() {
      const splitedValue = this.sort.split(/(class|index)/)
      return {
        target: splitedValue[1],
        sort: splitedValue[2].toLowerCase()
      }
    }
  },
  methods: {
    clickSort(type) {
      let emitValue = ''
      switch (type) {
        case 'index':
          if (this.sortValue.target === 'index') {
            emitValue = this.sortValue.sort === 'asc' ? 'indexDesc' : 'indexAsc'
          } else {
            emitValue = 'indexAsc'
          }
          break
        case 'class':
          if (this.sortValue.target === 'class') {
            emitValue = this.sortValue.sort === 'asc' ? 'classDesc' : 'classAsc'
          } else {
            emitValue = 'classAsc'
          }
          break
      }
      this.$emit('change-sort', emitValue)
    },
    filterIndexClass(index, type) {
      if (
        this.clusteringResult.listIndexClass == null ||
        this.clusteringResult.listIndexClass.length === 0 ||
        this.clusteringResult.listIndexClass[index] == null
      )
        return ''
      return this.clusteringResult.listIndexClass[index][type]
    }
  },
  // v-showを使っているため、すぐに結果が返却されると切り替わっているように見えないため、一瞬ローディングを回す
  watch: {
    loading() {
      this.internalLoading = true
      this.$nextTick(() => {
        this.internalLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$base-width: 96;
$label-width: 176;
$column-height: 56;

.clusterflow-table {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.block {
  position: relative;
  overflow: auto;
  display: flex;
  width: 100%;
  height: 100%;
  @include scrollbar;
  &-detail {
    overflow-x: hidden;
    overflow-y: scroll;
    @include scrollbar;
  }
}

.body {
  width: 100%;
  height: 100%;
  text-align: center;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
}

.label {
  background: $background;
  &-inner {
    position: sticky;
    top: 0;
    overflow: hidden;
    width: adjustVW($label-width);
    height: adjustVW($column-height);
    padding: $space-base;
    background: $background;
    font-size: $text-base;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 11;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: $border-gray;
    }
  }
  &-result {
    &-1 {
      left: 0;
      width: adjustVW($base-width);
      z-index: 12;
    }
    &-2 {
      left: adjustVW($base-width);
      z-index: 12;
      &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 1px;
        height: 100%;
        background: $line-gray;
      }
    }
  }
  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: $space-base;
    width: 100%;
    &-icon {
      transition: transform $transition-base;
      &-desc {
        transform: rotate(180deg);
      }
    }
  }
}
.data {
  &-inner {
    position: relative;
    overflow: hidden;
    width: adjustVW($label-width);
    height: adjustVW($column-height);
    padding: 0 $space-small;
    font-size: $text-base;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 1;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: $line-gray;
    }
  }
  &-result {
    &-1 {
      position: sticky;
      left: 0;
      width: adjustVW($base-width);
      background: $background;
      z-index: 11;
    }
    &-2 {
      position: sticky;
      left: adjustVW($base-width);
      background: $background;
      z-index: 11;
      > span {
        height: calc(#{adjustVW($column-height)} - 2px);
        margin: 0 $space-base 0 0;
        line-height: calc(#{adjustVW($column-height)} - 2px);
      }
      &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 1px;
        height: 100%;
        background: $line-gray;
      }
    }
  }
  &-active {
    > td {
      background: $key-lite;
    }
  }
  &-wrapper {
    &-loading {
      opacity: 0;
      transform: translateX($space-base);
      transition: opacity 0.15s, transform 0.15s;
      will-change: opacity, transform;
    }
    &-active {
      animation: showContent $transition-base;
    }
  }
}
.none {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@keyframes showContent {
  0% {
    opacity: 0;
    transform: translateX($space-base);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.loading {
  position: absolute;
  top: adjustVW($column-height);
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - #{adjustVW($column-height)});
  background-color: $white;
  z-index: 11;
}
.toggle-detail-enter-active,
.toggle-detail-leave-active {
  transition: opacity $transition-base;
}
.toggle-detail-enter,
.toggle-detail-leave-to {
  opacity: 0;
  will-change: opacity, transform;
}
</style>
