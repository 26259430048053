<template>
  <div class="select-dataset-wrapper">
    <div class="select-dataset-main">
      <card-base class="select-dataset-main-card">
        <div class="select-dataset-title">
          <texts
            size="large"
            :text="this.$i18n.t('inference.settings.selectDataset.title')"
          />
        </div>
        <div class="select-dataset-tab-block">
          <tab-list
            :tabs="tabs"
            :activeTabProps="activeTab === 'upload' ? 0 : 1"
            @change-tab="changeTabContent"
          />
        </div>

        <transition-toggle-contents>
          <!-- uploadの処理中にローディング回してほしいです -->
          <upload-dataset
            v-if="activeTab === 'upload'"
            key="upload"
            :selectedFile="selectedFile"
            :fileError="fileError"
            @on-file-input="$emit('on-file-input', $event)"
          />
          <dataset-list
            v-else-if="activeTab === 'dataset'"
            key="dataset"
            :items="sortedItems"
            :field="field"
            :requiredColumns="requiredColumns"
            @list-click="listClick"
          />
        </transition-toggle-contents>
      </card-base>
    </div>

    <div class="select-dataset-info">
      <preprocess-columns
        v-if="
          preprocessingAvailable &&
            preprocessingColumns &&
            preprocessingColumns.input &&
            preprocessingColumns.input.length
        "
        class="select-dataset-info-item"
        :columns="preprocessingColumns.input"
      />
      <required-columns
        v-if="requiredColumns && requiredColumns.length"
        class="select-dataset-info-item"
        :columns="requiredColumns"
        :timeTransformerV2Settings="timeTransformerV2Settings"
      />
    </div>
  </div>
</template>

<script>
import cardBase from '@/components/atoms/card-base.vue'
import texts from '@/components/atoms/text.vue'
import tabList from '@/components/molecules/tab-list'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

import datasetList from '@/components/organisms/inference/menu/dataset/dataset-list.vue'
import preprocessColumns from '@/components/organisms/inference/menu/dataset/preprocess-columns.vue'
import requiredColumns from '@/components/organisms/inference/menu/dataset/required-columns.vue'
import uploadDataset from '@/components/organisms/inference/menu/dataset/upload-dataset.vue'

export default {
  components: {
    cardBase,
    datasetList,
    preprocessColumns,
    requiredColumns,
    tabList,
    texts,
    transitionToggleContents,
    uploadDataset
  },
  props: {
    items: Array,
    field: Array,
    preprocessingColumns: Object,
    preprocessingAvailable: Boolean,
    requiredColumns: Array,
    selectedDataset: Object,
    datasetSelectAvailable: Boolean,
    timeTransformerV2Settings: Object,
    fileError: String
  },
  data() {
    const upload = this.selectedDataset?.upload ?? true
    return {
      activeTab: upload ? 'upload' : 'dataset'
    }
  },
  computed: {
    tabs() {
      let dataset = {
        id: 1,
        name: this.$i18n.t('inference.settings.selectDataset.datasetList'),
        value: 'dataset',
        iconName: 'dataset'
      }
      if (!this.datasetSelectAvailable) {
        dataset = {
          id: 1,
          name: this.$i18n.t('inference.settings.selectDataset.datasetList'),
          value: 'dataset',
          iconName: 'dataset',
          disabled: true
        }
      }
      return [
        {
          id: 0,
          name: this.$i18n.t('inference.settings.selectDataset.uploadFile'),
          value: 'upload',
          iconName: 'upload'
        },
        dataset
      ]
    },
    selectedFile() {
      return this.selectedDataset?.file
    },
    sortedItems() {
      return [...this.items].sort((x, y) =>
        x.updateTime < y.updateTime ? 1 : x.updateTime > y.updateTime ? -1 : 0
      )
    }
  },
  methods: {
    listClick: function ({ index }) {
      this.$emit('list-click', { index })
    },
    changeTabContent(val) {
      this.activeTab = val
    }
  }
}
</script>

<style lang="scss" scoped>
.select-dataset-main-card {
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 100%;
}

.select-dataset-wrapper {
  display: grid;
  grid-template-columns: adjustVW(816) calc(
      100% - #{adjustVW(816) + $space-medium}
    );
  grid-template-rows: 100%;
  grid-column-gap: $space-medium;
  height: 100%;
}

.select-dataset-title {
  margin-bottom: $space-small;
}

.select-dataset-tab-block {
  width: fit-content;
  margin-bottom: $space-small;
}

.select-dataset-info {
  display: flex;
  flex-direction: column;

  &-item {
    margin-bottom: $space-medium;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
