<template>
  <button
    v-tooltip="disabledTips"
    class="button"
    :class="{
      'button-confirm': check,
      'button-disabled': isDisabled,
      'button-set': !loadingItem,
      ['button-' + color]: color
    }"
    :style="{
      '--width': setWidth + 'px'
    }"
    @click="!isDisabled ? updateValue() : ''"
  >
    <icons
      ref="iconBlock"
      class="icon"
      :iconName="iconName"
      :size="size"
      :showTips="showTips"
      :showTipsFlag="showTipsFlag"
      :isDisabled="isDisabled"
      :color="isDisabled ? 'gray' : color"
    />
    <texts
      ref="textBlock"
      class="text"
      :text="text"
      :size="size"
      :color="isDisabled ? 'gray' : color"
    />
  </button>
</template>

<script>
import { checkErrori18n } from '@/lib/misc.js'
import icons from '@/components/atoms/icon'

export default {
  components: {
    icons
  },
  data() {
    return {
      check: false,
      widthIcon: 0,
      widthText: 0,
      loadingItem: true
    }
  },
  props: {
    /** クリックしたときに表示するテキスト */
    text: {
      type: String,
      default: null
    },
    /** 色 [default, green, gray, emphasis, caution, accept, disabled, off] */
    color: {
      type: String,
      default: 'default'
    },
    /** アイコンの名前を入れる（Storybook参照） */
    iconName: {
      type: String,
      default: 'dummy'
    },
    /** アイコンの大きさを指定 [ default, large, small, title, min, huge ] 数値での指定も可能です */
    size: {
      type: [Number, String],
      default: 'default'
    },
    /** tooltipを表示するi18n内にtooltipの情報がない場合は表示されない。common.icon.tooltipContents参照 */
    showTips: {
      type: Boolean,
      default: false,
      require: false
    },
    /** tooltipの表示非表示のフラグ。showTipsを入れた上でtooltipを非表示にする場合があるときに使う */
    showTipsFlag: {
      type: Boolean,
      default: false,
      require: false
    },
    /** 押下不可状態 */
    isDisabled: {
      type: Boolean,
      default: false,
      require: false
    },
    /** disabledの場合の理由 i18nのcommon.disabledに追加またはあるものを使用する */
    disabledReason: {
      type: String,
      default: null,
      require: false
    }
  },
  methods: {
    updateValue() {
      if (this.check) {
        this.$emit('click', this.check)
        this.check = false
      } else {
        this.check = true
      }
    },
    updateWidth() {
      const rectIcon = this.$refs.iconBlock.$el.getBoundingClientRect()
      const rectText = this.$refs.textBlock.$el.getBoundingClientRect()
      this.widthIcon = rectIcon.width
      this.widthText = rectText.width
    },
    outerClick(e) {
      if (!this.$el.contains(e.target)) {
        this.check = false
      }
    }
  },
  computed: {
    disabledTips() {
      if (!this.isDisabled) return null
      const reason = checkErrori18n.bind(this)(this.disabledReason)
      return {
        content: reason,
        trigger: 'hover',
        delay: { show: 300, hide: 300 }
      }
    },
    setWidth() {
      if (this.check) {
        return this.widthIcon + this.widthText
      } else {
        return this.widthIcon
      }
    }
  },
  mounted() {
    this.updateWidth()
    window.addEventListener('resize', this.updateWidth)
    window.addEventListener('click', this.outerClick)
    this.$nextTick(() => {
      this.loadingItem = false
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWidth)
    window.removeEventListener('click', this.outerClick)
  }
}
</script>

<style lang="scss" scoped>
.button {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: var(--width);
  background-color: $background-sub;
  border-radius: adjustVW(8);
  &-set {
    transition: width $transition-base;
  }
  &-default {
    background-color: $background-sub;
  }
  &-green {
    background-color: $green-lite;
  }
  &-gray {
    background-color: $background-sub;
  }
  &-emphasis {
    background-color: $background-decoration;
  }
  &-caution {
    background-color: $red-lite;
  }
  &-accept {
    background-color: $blue-bg;
  }
  &-disabled {
    background-color: $background-sub;
  }
  &-off {
    background-color: $background-sub;
  }
}
.icon {
  padding: $space-base;
}
.text {
  position: absolute;
  padding-right: $space-sub;
  opacity: 0;
  .button-confirm & {
    position: relative;
    opacity: 1;
  }
}
</style>
