<template>
  <div class="transformer-v2-list-item-wrapper">
    <div class="transformer-v2-list-item-main">
      <checkbox-base
        class="transformer-v2-list-item-main-check"
        isSmall
        :checked="entry.item.value"
        :isDisabled="entry.entry.isHasNull"
        @input="onCheck($event, entry)"
      />

      <div class="transformer-v2-mapping-item-main-content">
        <texts size="small" :text="entry.item.name" />
      </div>
    </div>

    <div v-if="checkMapping(entry)">
      <tooltip-popover noBorder :placement="'top-end'">
        <template v-slot:main>
          <div class="mapping-list-item-icon">
            <icons size="small" iconName="preproc" />
          </div>
        </template>

        <template v-slot:popup>
          <div class="mapping-list-item-popup-wrapper">
            <div class="mapping-list-item-popup-title">
              <texts
                :text="$t('training.popup.madeColumns.title')"
                size="small"
              />
            </div>
            <div class="mapping-list-item-popup-overview">
              <texts
                class="mapping-list-item-popup-overview-text"
                color="gray"
                noTips
                size="min"
                :text="$t('training.popup.madeColumns.overview')"
              />
            </div>

            <div class="mapping-list-item-popup-content-wrapper">
              <template v-for="(column, index) in entry.entry.mapping">
                <div :key="index">
                  <dot-text :text="column" />
                </div>
              </template>
            </div>
          </div>
        </template>
      </tooltip-popover>
    </div>
  </div>
</template>

<script>
import checkboxBase from '@/components/atoms/checkbox-base.vue'
import dotText from '@/components/atoms/dot-text.vue'
import icons from '@/components/atoms/icon.vue'
import texts from '@/components/atoms/text.vue'
import tooltipPopover from '@/components/molecules/tooltip-popover.vue'

export default {
  components: {
    checkboxBase,
    dotText,
    icons,
    texts,
    tooltipPopover
  },
  props: {
    entry: {
      type: Object,
      default: () => {
        return {
          item: {},
          entry: {}
        }
      }
    }
  },
  methods: {
    checkMapping: function (entry) {
      return entry.entry?.mapping != null && entry.entry.mapping.length > 0
    },
    onCheck: function (e, entry) {
      this.$emit('onCheck', { check: e, name: entry.item.name })
    }
  }
}
</script>

<style lang="scss" scoped>
.transformer-v2-list-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: $space-base;
  width: 100%;
}

.transformer-v2-list-item-main {
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
}

.transformer-v2-list-item-main-check {
  flex-shrink: 0;
}

.transformer-v2-mapping-item-main-content {
  overflow: hidden;
  width: 100%;
}

.mapping-list-item-icon {
  flex-shrink: 0;
}

.mapping-list-item-popup-wrapper {
  width: adjustVW(340);
}

.mapping-list-item-popup-title {
  margin-bottom: $space-text;
}
.mapping-list-item-popup-overview {
  margin-bottom: $space-sub;
}

.mapping-list-item-popup-overview-text {
  white-space: pre-line;
}

.mapping-list-item-popup-content-wrapper {
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: adjustVH(200);
  @include scrollbar;
  column-gap: $space-base;
  row-gap: $space-base;
}
</style>
