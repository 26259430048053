function getStyle(vueThis) {
  return [
    {
      selector: 'edge',
      style: {
        'curve-style': 'bezier',
        'target-arrow-shape': 'triangle',
        width: 4,
        label(el) {
          const options = el.data('options') ?? {}
          if (typeof options.specificTarget !== 'undefined') {
            return options.specificTarget
              ? vueThis.$t('recipe.specifyCol.target')
              : vueThis.$t('recipe.specifyCol.other')
          }
          return ''
        },
        color(el) {
          const options = el.data('options') ?? {}
          if (typeof options.specificTarget !== 'undefined') {
            return getEdgeColor(el)
          }
          return '#000000'
        },
        'line-color'(el) {
          return getEdgeColor(el)
        },
        'target-arrow-color'(el) {
          return getEdgeColor(el)
        },
        'border-width': 0,
        'text-outline-color'(el) {
          return getEdgeColor(el)
        },
        'font-size': 16,
        'text-background-color': '#fff',
        'text-background-opacity': 1,
        'text-background-padding': 8,
        'text-border-color'(el) {
          return getEdgeColor(el)
        },
        'text-border-opacity': 1,
        'text-border-style': 'dashed',
        'text-border-width': 2,
        'font-family': 'Noto Sans JP'
      }
    },
    {
      selector: 'edge.select-delete',
      style: {
        'line-color': '#d93025',
        'target-arrow-color': '#d93025'
      }
    },
    {
      selector: 'node.realNode',
      style: {
        shape: 'data(nodeShape)',
        // width: 'mapData(weight, 40, 80, 20, 60)',
        width: 'label',
        height: 18,
        label(el) {
          const layerName = el.data('name')
          let label
          if (layerName === 'customblock') {
            const customBlockId = el.data('customblockId')
            const customblock = vueThis.customblockList.find(
              (item) => item.customblock_id === customBlockId
            )
            label =
              customblock?.name ??
              vueThis.$t('customblock.error.unknownBlockPleaseDelete')
          } else {
            label = vueThis.$t('recipe.layerNames.' + layerName)
          }

          if (el.data('name') === 'inputData') {
            const dataType = el.data('params').dataType.value
            label +=
              ' (' + vueThis.$t('recipe.param.inputData.' + dataType) + ') '
          }
          return label
        },
        color(el) {
          const warnings = el.data('warnings')
          if (warnings != null && warnings.length > 0) {
            return '#d93025'
          }
          return '#1b101f'
        },
        'font-weight': 'bold',
        'background-color': '#ffffff',
        'border-color'(el) {
          if (el.data('name') === 'customblock') {
            return '#2972a1'
          }
          const warnings = el.data('warnings')
          if (warnings != null && warnings.length > 0) {
            return '#d93025'
          }
          return el.data('nodeColor')
        },
        'border-width': 1,
        'text-valign': 'center',
        'text-halign': 'center',
        padding: 12,
        'font-family': 'Noto Sans JP',
        'transition-property': 'border-color',
        'transition-duration': '0.1s'
      }
    },
    {
      selector: 'node.realNode:selected',
      style: {
        'border-color': 'data(nodeSelectedColor)',
        'border-width': 2
      }
    },
    {
      selector: 'node.select-delete.realNode:selected',
      style: {
        'border-color': '#d93025',
        'border-width': 2
      }
    },
    // edgehandle

    {
      selector: '.eh-handle',
      style: {
        'background-color': 'pink',
        width: 15,
        height: 15,
        shape: 'ellipse',
        'overlay-opacity': 0,
        'border-width': 5, // makes the handle easier to hit
        'border-opacity': 0
      }
    },

    {
      selector: '.eh-hover',
      style: {
        'border-color': 'red'
      }
    },

    {
      selector: '.eh-source',
      style: {
        'border-width': 2,
        'border-color': 'red'
      }
    },

    {
      selector: '.eh-target',
      style: {
        'border-width': 2,
        'border-color': 'red'
      }
    },

    {
      selector: '.eh-preview, .eh-ghost-edge',
      style: {
        'line-color': '#d93025',
        'target-arrow-color': '#d93025',
        'source-arrow-color': '#d93025'
      }
    },

    {
      selector: '.eh-ghost-edge.eh-preview-active',
      style: {
        opacity: 0
      }
    }
  ]
}

function getEdgeColor(el) {
  const options = el.data('options') ?? {}
  if (typeof options.specificTarget !== 'undefined') {
    return options.specificTarget ? '#049185' : '#850491'
  }
  return '#74717a'
}

export default getStyle
