<template>
  <transition
    name="slot-toggle"
    @before-enter="removeHeight"
    @enter="setHeight"
    @before-leave="setHeight"
    @leave="removeHeight"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  data() {
    return {
      resizeTimer: null
    }
  },
  props: {
    /** mountedのタイミングで高さが必要な場合 */
    getHeightInMount: Boolean
  },
  methods: {
    setHeight(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    removeHeight(el) {
      el.style.height = 0 + 'px'
    },
    setHeightRef() {
      window.clearTimeout(this.resizeTimer)
      this.resizeTimer = window.setTimeout(
        function () {
          this.$el.style.height = 'fit-content'
          this.$nextTick(() => {
            this.$el.style.height = this.$el.scrollHeight + 'px'
          })
        }.bind(this),
        200
      )
    }
  },
  mounted() {
    /**
     * mountedのタイミングで高さの計算が必要な場合
     * 具体的にはmount時点で要素が存在していることが確定している場合
     */
    if (this.getHeightInMount) {
      this.$el.style.height = this.$el.scrollHeight + 'px'
      window.addEventListener('resize', this.setHeightRef)
    }
  },
  beforeDestroy() {
    if (this.getHeightInMount) {
      window.removeEventListener('resize', this.setHeightRef)
    }
  }
}
</script>

<style lang="scss" scoped>
.slot-toggle-enter-active,
.slot-toggle-leave-active {
  overflow: hidden;
  transition: all $transition-base;
}
.slot-toggle-enter,
.slot-toggle-leave-to {
  height: 0;
  opacity: 0;
  transition: all $transition-base;
}
</style>
