<template>
  <dataset-detail
    v-bind="data"
    :rowsPerPage="rowsPerPage"
    :datasetInfo="datasetInfo"
    :disabledEdit="disabledEdit"
    :datasetLoading="loading"
    :datasetList="datasetList"
    :datasetMenu="datasetMenuDefault"
    :tableChangeTabs="tableChangeTabs"
    :sidebarTab="sidebarTab"
    :sidebar="sidebar"
    :notFound="notFound"
    :disableClick="disableClick"
    @file-info="fileInfo($event)"
    @loading-detail-data="changeloadingState()"
    @learning-data-image="learningDataImage($event)"
    @learning-data-texts="learningDataTexts($event)"
    @learning-data-table="learningDataTable($event)"
    @learning-data-describe="learningDataDescribe($event)"
    @component-page-name="componentPageName($event)"
    @change-tab="changeTabContent($event)"
    @change-data-tab="changeDataTabContent($event)"
    @edit-dataset-detail="editDatasetDetail()"
    @save-dataset-detail="saveDatasetDetail($event)"
    @dataset-menu-click="datasetMenuClick($event)"
    @select-item="selectItem($event)"
    @delete-dataset="deleteData()"
    @close-modal="closePopup($event)"
    @input-edit-form="inputEditForm($event)"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { splitFullId } from '@/lib/misc.js'
import { datasetValidator } from '@/lib/validator/dataset.js'
import setMountedTimer from '@/mixin/set-mounted-timer'

import datasetDetail from '@/components/templates/dataset-detail.vue'

export default {
  components: {
    datasetDetail
  },
  mixins: [setMountedTimer],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.$waitConnected()
      await vm.loadProjectDetail(vm.projectId)
    })
  },
  computed: {
    ...mapGetters('settings', ['rowsPerPage']),
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('datasets', {
      loadingDatasetList: 'loadingDatasetList'
    }),
    ...mapGetters('trainedAi', ['trainedAIs']),
    ...mapGetters('project', ['projectList', 'projectLoading']),

    disabledEdit() {
      if (!this.accountInfo || !this.datasetId) return true
      if (this.accountInfo?.organizationInfo?.role === 'admin') return false
      if (this.accountInfo?.accountId !== this.datasetId.accountId) {
        return true
      } else {
        return false
      }
    },
    disableDownloadDataset() {
      return this.datasetInfo && this.datasetInfo.size > 10 * 1024 * 1024
    },
    notFoundProject() {
      if (this.projectId === null) {
        return false
      } else {
        return !(this.projectId in this.projectList)
      }
    },
    notFoundDataset() {
      return !(this.datasetId.id in this.datasetList)
    },
    notFound() {
      return this.notFoundDataset || this.notFoundProject
    },
    datasetId() {
      /* データセットのidと、それに紐付いているユーザネームを
                        取ってくるコンポーネントです。
                        */
      const [id, accountId] = splitFullId(this.data.body.dataId)
      return {
        id,
        accountId
      }
    },
    datasetInfo() {
      return this.datasetList[this.datasetId.id]
    },
    ownModels() {
      if (
        this?.accountInfo?.accountId &&
        Object.keys(this.trainedAIs).length > 0
      ) {
        return Object.values(this.trainedAIs).filter(
          (m) => m.accountId === this.accountInfo.accountId
        ).length
      }
      return 0
    },
    projectId() {
      if (this.$route.params.projectId) {
        return parseInt(this.$route.params.projectId)
      }
      return null
    },
    tableChangeTabs() {
      // ページ上部のタブ
      const isStructured = this.datasetInfo?.type === 'structured'
      return {
        tabs: [
          {
            id: 0,
            name: this.$t('datasetDetail.dataTabs.showDataset'),
            iconName: 'dataset',
            value: 'dataset'
          },
          {
            id: 1,
            name: this.$t('datasetDetail.dataTabs.statistics'),
            iconName: 'statistics',
            value: 'statistics',
            disabled: !isStructured
          }
        ]
      }
    },
    sidebarTab() {
      // ページ右のサイドバーで扱っているタブです。
      return {
        tabs: [
          {
            id: 0,
            name: this.$t('datasetDetail.infoTabs.detail'),
            iconName: 'detail',
            value: 'detail'
          },
          {
            id: 1,
            name: this.$t('datasetDetail.infoTabs.history'),
            iconName: 'history',
            value: 'history'
          }
        ]
      }
    },
    canPreprocessingDataset() {
      if (!this.datasetInfo) return false
      if (
        this.datasetInfo.type === 'structured' &&
        this.datasetInfo.accountId === this.accountInfo.accountId
      ) {
        return true
      } else {
        return false
      }
    },
    preprocessingDisabledText() {
      const dataset = this.datasetInfo
      if (dataset?.type !== 'structured') {
        return this.$t('common.disabled.preprocessing.notStructured')
      } else if (dataset.accountId !== this.accountInfo.accountId) {
        return this.$t('common.disabled.preprocessing.otherAccountItem')
      } else {
        return this.$t('common.disabled.preprocessing.main')
      }
    },
    datasetMenuDefault() {
      return {
        // メニュー画面のタグ
        tabs: [
          {
            id: 0,
            name: this.$t('datasetDetail.menu.download'),
            iconName: 'download',
            value: 'download',
            disabled: this.downloadLoading || this.disableDownloadDataset,
            disabledTips: this.downloadLoading
              ? this.$t('datasetDetail.menu.downloadLoding')
              : this.$t('datasetDetail.menu.disabledDownload'),
            tips: {
              name: this.$t('datasetDetail.menu.finishedDownload')
            }
          },
          {
            id: 1,
            value: 'menu',
            isMenuShow: false,

            // メニューボタンを押した時にあわられる情報を記載するタグ
            menu: [
              {
                id: '1',
                name: this.$t('datasetDetail.menu.preprocAndEda'),
                icon: 'preproc',
                value: 'preprocAndEda',
                disabled: !this.canPreprocessingDataset,
                disabledTips: this.preprocessingDisabledText
              },
              /* {
                id: '2',
                name: this.$t('datasetDetail.menu.inference'),
                icon: 'inference',
                value: 'inference',
                disabled: false
              }, */
              {
                id: '3',
                name: this.$t('datasetDetail.menu.learning'),
                icon: 'train',
                value: 'learning',
                disabled: !this.projectId,
                disabledTips: this.$t('common.disabled.notInProject')
              },
              {
                id: '4',
                name: this.$t('datasetDetail.menu.delete'),
                icon: 'delete',
                value: 'delete',
                disabled: this.disabledEdit,
                disabledTips: this.$t(
                  'common.disabled.organization.otherAccountItemDelete'
                )
              }
            ]
          }
        ]
      }
    },
    sidebar() {
      // サイドバーに表示する情報
      return {
        project: {
          name: '退職予測',
          description:
            'このプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入ります'
        },
        activeLink: 'dataset'
      }
    },
    loading() {
      return this.loadingDatasetList || this.projectLoading
    },
    disableClick() {
      return this.submitDelete
    },
    datasetList() {
      const result = {}
      this.projectList[this.projectId]?.listData?.forEach((item) => {
        result[item.id] = item
      })
      return result
    }
  },
  data() {
    return {
      data: {
        // ヘッダーのタブ
        headerTabs: {
          tabs: [],
          tabSelect: 1
        },
        body: {
          dataId: this.$route.params.id, // データセット一覧画面から渡ってきたidを格納します。
          componentPageName: null, // どのコンポーネントを呼び出すかの判定結果を格納します。
          detail: {
            /** データセット画面で選ばれたデータの詳細な情報を取りに行くapiが走った時の処理の時のロードの状態を表すdataです。 */
            loadingDetailData: false,
            /** 非構造化のデータを選択した時に呼び出されるコンポーネントです。 */
            visibleContents: 'dataset',
            detailData: {
              info: null, // データセット画面で選択されたデータの詳細な情報を呼び出します。
              image: null, // 非構造の写真データをこのdataに格納しています。
              naturalLanguage: null, // 非構造の自然言語をこのdataに格納しています。
              table: null, // データソースから呼び出されていない構造化されたデータのをこのdataに格納しています。
              describe: null // データソースから呼び出された構造化されたデータのをこのdataに格納しています。
            }
          },
          // ページ上部に表示する情報
          pageTop: {
            // ページ名とアイコン
            summary: {
              title: 'データセット名がはいります',
              iconName: 'dummy'
            },
            activeTab: 0,
            activeMenu: '',
            visibleContents: 'dataset'
          },
          pageBody: {
            // データセット詳細のサイドバーのタブ
            infoTabs: {
              // データセット詳細のサイドバーに表示する情報
              visibleContents: 'detail'
            },
            editInfo: {
              datasetFormValidate: {
                duplicate: undefined
              }
            }
          }
        },
        loadingDelete: false,
        // 現在表示しているポップアップ
        popup: {
          showPopup: [],
          deleteData: {}
        }
      },
      submitCopy: false,
      submitDelete: false,
      downloadLoading: false
    }
  },
  methods: {
    ...mapActions('datasets', ['deleteDatasets', 'updateDataset', 'downloadDataset', 'loadDatasetList']),
    ...mapActions('project', ['loadProjectDetail']),
    ...mapActions('models', ['deleteProgressTraining', 'setProgressTraining']),

    showPopup(e) {
      // ポップアップを表示
      if (this.data.popup.showPopup.length > 0) {
        this.data.popup.showPopup = []
        this.data.popup.showPopup.push(e)
      } else {
        this.data.popup.showPopup.push(e)
      }
    },
    closePopup(e) {
      // ポップアップを閉じる
      this.data.popup.showPopup = this.data.popup.showPopup.filter(
        (n) => n !== e
      )
    },
    fileInfo(e) {
      // データセット詳細ページで表示しているコンポーネントを格納します
      this.data.body.detail.detailData.info = e
    },
    learningDataImage(e) {
      // 写真の学習データを格納します
      this.data.body.detail.detailData.image = e
    },
    learningDataTexts(e) {
      // 自然言語の学習データを格納します
      this.data.body.detail.detailData.naturalLanguage = e
    },
    learningDataTable(e) {
      // 自然言語の学習データを格納します
      this.data.body.detail.detailData.table = e
    },
    learningDataDescribe(e) {
      // 自然言語の学習データを格納します
      this.data.body.detail.detailData.describe = e
    },
    componentPageName(e) {
      // データセット詳細ページで表示しているコンポーネントを格納します
      this.data.body.componentPageName = e
    },
    changeTabContent(e) {
      // 詳細と履歴の表示を切り替えるタブの中身の切り替え
      e[1].target.visibleContents = e[0]
    },
    changeDataTabContent(e) {
      // データセットと統計情報の表示を切り替えるタブの中身の切り替える
      this.data.body.detail.visibleContents = e
    },
    async saveDatasetDetail(e) {
      // 編集内容を保存すると編集状態から通常状態への切り替え
      this.updateDataset({
        dataId: this.data.body.detail.detailData.info.id,
        accountId: this.data.body.detail.detailData.info.accountId,
        data: {
          name: e.newName,
          description: e.newDescription
        }
      })
      this.$router.push({ name: 'datasetDetail' })
    },
    deleteData: async function () {
      this.submitDelete = true
      const accountId = this.datasetInfo.accountId
      const dataId = this.datasetInfo.id
      const req = {
        action: 'deleteData',
        accountId,
        dataId
      }
      this.data.loadingDelete = true

      try {
        await this.$sendMessageAndReceive(req)
        await this.loadDatasetList(this.projectId)
      } finally {
        this.submitDelete = false
      }

      this.$router.push({
        name: 'datasetProjectList',
        params: { projectId: this.projectId }
      })
      this.data.loadingDelete = false
    },
    async datasetMenuClick(e) {
      // メニュー押下時の処理
      if (this.data.body.pageTop.activeMenu === e.activeMenu) {
        this.data.body.pageTop.activeMenu = ''
      } else {
        this.data.body.pageTop.activeMenu = e.activeMenu
      }
      switch (e.activeMenu) {
        case 'download': {
          this.downloadLoading = true
          const target = this.datasetInfo
          await this.downloadDataset({
            name: target.name,
            dataId: target.id,
            accountId: target.accountId,
            type: target.type
          })
          window.setTimeout(
            function () {
              this.downloadLoading = false
            }.bind(this),
            1000
          )
          break
        }
        case 'preprocAndEda':
          this.$router.push({
            name: 'preprocessingProjectApplyNonePreproc',
            params: { datasetId: this.datasetInfo.id }
          })
          break
        case 'inference':
          this.$router.push({
            name: 'inferenceProject',
            params: { projectId: this.projectId },
            query: { datasetId: this.datasetInfo.id }
          })
          break
        case 'learning':
          if (this.accountInfo.planDetail.numModel > this.ownModels) {
            const datasetType = this.datasetInfo.type
            if (datasetType === 'structured') {
              /* 前処理状態を確認 */
              const res = await this.$sendMessageAndReceive({
                action: 'getDataInfo',
                dataId: this.datasetInfo.id,
                accountId: this.datasetInfo.accountId
              })
              if (
                res.detail?.preprocessing?.version > 0 &&
                this.datasetInfo.accountId !== this.accountInfo.accountId
              ) {
                this.$notify({
                  group: 'alerts',
                  type: 'danger',
                  title: this.$t(
                    'datasetSetting.cautions.inPreprocessingOtherDataset'
                  ),
                  duration: 5000,
                  data: {
                    dismissible: false
                  }
                })
                return
              }
            }
            this.deleteProgressTraining({ projectId: this.projectId })

            this.setProgressTraining({
              item: this.projectId,
              setType: 'project'
            })
            this.setProgressTraining({
              item: this.datasetInfo.id,
              setType: 'dataset',
              projectId: this.projectId,
              datasetType: datasetType,
              datasetAccountId: this.datasetInfo.accountId
            })

            this.$gtmDataLayer.sendEvent('startTraining', 'fromDatasetDetail')
            if (datasetType === 'structured') {
              this.$router.push({
                name: 'selectTargetColumn',
                params: {
                  projectId: this.projectId,
                  datasetId: this.datasetInfo.id
                }
              })
            } else {
              this.$router.push({
                name: 'trainRecipeList',
                params: { projectId: this.projectId }
              })
            }
          } else {
            this.showPopup('upperLimitTrainedAi')
          }
          break
        case 'delete':
          this.data.body.pageBody.deleteData = { targets: this.datasetInfo }
          this.showPopup('deleteData')
          break
      }
    },
    changeloadingState() {
      // データ詳細に表示する学習済みデータを表示する時にはさむloading
      this.data.body.detail.loadingDetailData =
        !this.data.body.detail.loadingDetailData
    },
    selectItem(_e) {
      // セレクトボックスで要素を選択したときの処理
    },
    inputEditForm: async function (obj) {
      const { type, form } = obj

      if (type === 'dataset') {
        const datasetList = this.projectList[this.projectId].listData
        const datasets = datasetList
          .filter((dataset) => dataset.id !== this.datasetId.id)
          .reduce((result, dataset, index) => {
            result[index] = dataset
            return result
          }, {})

        this.data.body.pageBody.editInfo.datasetFormValidate = datasetValidator(
          datasets,
          form
        )
      }
    }
  }
}
</script>
