<template>
  <div class="table">
    <div class="table-wrap" :class="{ 'table-wrap-statics': isStatistics }">
      <!-- v-showでやるべきだが、tableが空の場合があるため、結局再描画が行われるので、v-ifで判定を加える -->
      <table
        v-if="table && table.length > 0"
        class="table-body"
        :class="{
          'table-body-loading': loadPage,
          'table-body-active': !loadPage
        }"
        @mouseleave="reaveItem()"
      >
        <thead>
          <tr class="table-name">
            <th
              v-for="(key, index) in displayColumns"
              :key="index"
              v-tooltip.left="setCautionTooltip(key, index)"
              class="table-name-inner"
              :class="{ 'table-name-inner-hover': index === hoverTarget }"
              @mouseover="hoverItem(index)"
            >
              <div class="table-name-text-wrap">
                <texts :text="key" size="small" :color="isCautionColumn(key)" />
                <icons
                  v-if="isCautionColumn(key)"
                  class="table-name-caution"
                  :iconName="setCautionIcon(key)"
                  :color="isCautionColumn(key)"
                  showTips
                  size="small"
                />
              </div>
              <transition name="toggle-icon">
                <div v-if="index === hoverTarget" class="table-name-icon">
                  <button
                    class="table-name-icon-item"
                    @click="showDetail(index)"
                  >
                    <icons iconName="preprocDetail" color="emphasis" showTips />
                  </button>
                </div>
              </transition>
            </th>
          </tr>
        </thead>
        <tbody>
          <table-main-graph
            v-if="isStatistics"
            :tableColumns="displayColumns"
            :graph="graph"
            :hoverTarget="hoverTarget"
            @mouseover="hoverItem($event)"
          />
          <tr
            v-for="(entry, entryIndex) in translateTable"
            :key="entryIndex + 'entry'"
            class="table-data"
          >
            <td
              v-for="(key, keyIndex) in displayColumns"
              :key="keyIndex + 'td'"
              class="table-data-inner"
              :class="{ 'table-data-inner-hover': keyIndex === hoverTarget }"
              @mouseover="hoverItem(keyIndex)"
            >
              <table-td-text
                :popupKey="keyIndex + '-' + entryIndex"
                :placeEnd="7 >= translateTable.length - entryIndex + 1"
                :text="makeCellValue(entry[key])"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="table-none">
        <texts
          class="table-none-text"
          :text="$t('preprocessing.tooLargeStatistics.description')"
          color="gray"
        />
        <button-main
          :text="$t('preprocessing.tooLargeStatistics.button')"
          type="sub"
          @click="$emit('get-statistics')"
        />
      </div>
      <transition-toggle-contents>
        <div v-if="loadingAll" class="table-loading">
          <loading />
        </div>
      </transition-toggle-contents>
    </div>
    <div class="table-paging">
      <paging
        :value="inPageNumber"
        :count="tableColumnsLength"
        :pagingCount="pagingCount"
        @input="changePage"
      />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import icons from '@/components/atoms/icon'
import buttonMain from '@/components/atoms/button-main.vue'
import loading from '@/components/atoms/loading'
import tableMainGraph from '@/components/molecules/table-main-graph.vue'
import tableTdText from '@/components/molecules/table-td-text.vue'
import paging from '@/components/molecules/paging'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    texts,
    icons,
    buttonMain,
    loading,
    tableMainGraph,
    tableTdText,
    paging,
    transitionToggleContents
  },
  data() {
    return {
      hoverTarget: null,
      hoverFlag: false,
      showTips: false,
      inPageNumber: 1,
      loadPage: false
    }
  },
  props: {
    /** テーブルの列名の配列 */
    tableColumns: Array,
    /** テーブル本体 */
    table: Array,
    /** 統計情報を表示 */
    isStatistics: Boolean,
    /** 欠損値のある列 */
    tableNullColumns: Object,
    /** 外れ値のある列 */
    tableOutlierColumns: Object,
    /** グラフボタンをクリックで表示されるグラフ */
    graph: Object,
    /** ページングの列数 */
    pagingCount: Number,
    loadingAll: Boolean,
    settedOutlier: Boolean
  },
  computed: {
    translateTable() {
      const target = JSON.parse(JSON.stringify(this.table))
      if (this.isStatistics) {
        target.forEach((element, index) => {
          element['/'] = this.$i18n.t('statistic.' + element['/'])
        })
        return target
      }
      return target
    },
    displayColumns() {
      let startCount = (this.inPageNumber - 1) * this.pagingCount
      let endCount = this.inPageNumber * this.pagingCount

      if (this.isStatistics && this.inPageNumber === 1) {
        endCount = this.inPageNumber * this.pagingCount + 1
      } else if (this.isStatistics && this.inPageNumber > 1) {
        startCount = (this.inPageNumber - 1) * this.pagingCount + 1
      }
      const base = this.tableColumns.slice(startCount, endCount)
      if (this.isStatistics && this.inPageNumber > 1) {
        base.unshift('/')
      }
      return [...base]
    },
    tableColumnsLength() {
      if (this.isStatistics) {
        return this.tableColumns.length - 1 // 統計情報の/列分減らす
      } else {
        return this.tableColumns.length
      }
    }
  },
  methods: {
    hoverItem(id) {
      if (this.hoverTarget !== id) {
        this.hoverTarget = id
      }
    },
    reaveItem() {
      this.hoverTarget = null
    },
    isCautionColumn: function (columnName) {
      const hasNull = this.tableNullColumns[columnName] > 0
      const hasOutliers =
        this.tableOutlierColumns[columnName] > 0 && this.settedOutlier

      if (hasNull) return 'caution'
      if (hasOutliers) return 'green'

      return ''
    },
    setCautionIcon: function (columnName) {
      const cautionStatus = this.isCautionColumn(columnName)

      if (cautionStatus === 'caution') return 'error'
      if (cautionStatus === 'green') return 'info'

      return ''
    },
    setCautionTooltip: function (columnName, index) {
      const cautionStatus = this.isCautionColumn(columnName)

      // 各列の状態によって、表示する文面を変更する
      let contentText = ''

      if (cautionStatus === 'caution') {
        contentText = this.$t('preprocessing.message.hasNullColumn')
      } else if (cautionStatus === 'green') {
        contentText = this.$t('preprocessing.message.hasOutliers')
      }

      // 欠損値 or 外れ値がある場合は tooltip を表示する
      const showTooltip = cautionStatus !== ''

      return {
        content: contentText,
        trigger: ['manual'],
        show: showTooltip && index === this.hoverTarget,
        delay: {
          show: 500,
          hide: 500
        }
      }
    },
    makeCellValue: function (value) {
      if (!this.isStatistics) return value

      return value ?? 'ー'
    },
    changePage(num) {
      this.loadPage = true
      this.inPageNumber = num
      this.$nextTick(() => {
        this.loadPage = false
      })
    },
    showDetail(index) {
      let emitValue = index
      if (this.isStatistics) {
        emitValue = emitValue - 1
      }
      emitValue = emitValue + (this.inPageNumber - 1) * this.pagingCount
      this.$emit('show-detail', emitValue)
    }
  },
  watch: {
    tableColumns: {
      handler() {
        this.loadPage = true
        this.$nextTick(() => {
          this.loadPage = false
        })
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-row-gap: $space-base;
  width: 100%;
  height: 100%;
  &-wrap {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    @include scrollbar;
  }
  &-body {
    width: 100%;
    text-align: center;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: collapse;
    &-loading {
      opacity: 0;
      transform: translateX($space-base);
      transition: opacity 0.15s, transform 0.15s;
      will-change: opacity, transform;
    }
    &-active {
      animation: showContent $transition-base;
    }
  }
  &-name {
    background: $background;
    &-inner {
      position: sticky;
      top: 0;
      overflow: hidden;
      width: auto;
      height: adjustVH(56);
      padding: 0 $space-base;
      background: $background;
      line-height: adjustVH(56);
      font-size: $text-base;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-radius: adjustVW(8) adjustVW(8) 0 0;
      z-index: 11;
      transition: background-color $transition-base;
      will-change: background-color;
      &::after {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 0;
        width: 100%;
        height: 1px;
        background: $border-gray;
      }
      &-hover {
        background: $background-decoration;
      }
    }
    &-icon {
      position: absolute;
      top: 0;
      left: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 1px);
      height: calc(100% - 1px);
      background: rgba(254, 247, 255, 0.9);
      z-index: 1;
      transform: translateX(-50%);
      transition: transform $transition-base, opacity $transition-base;
      will-change: transform, opacity;
      &-item {
        padding: $space-base;
        margin: 0 $space-base 0 0;
        transition: opacity $transition-base;
        will-change: opacity;
        &:last-of-type {
          margin: 0;
        }
        &:hover {
          opacity: 0.6;
        }
      }
    }
    &-text-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-column-gap: $space-base;
    }
    &-caution {
      flex-shrink: 0;
    }
  }
  &-data {
    &-inner {
      position: relative;
      overflow: hidden;
      height: adjustVH(56);
      padding: 0 $space-small;
      font-size: $text-base;
      text-overflow: ellipsis;
      white-space: nowrap;
      z-index: 1;
      transition: background-color $transition-base;
      will-change: background-color;
      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 1px;
        width: 100%;
        height: 1px;
        background: $line-gray;
      }
      &-hover {
        background: $background-decoration;
      }
      &-large-csv {
        white-space: pre;
      }
    }
  }
  &-paging {
    align-self: flex-end;
    padding-right: $space-sub;
  }
  &-loading {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: $white;
    z-index: 100;
  }
  &-none {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-row-gap: $space-small;
    width: 100%;
    height: 100%;
    animation: showContent $transition-base;
    &-text {
      text-align: center;
      white-space: pre-line;
    }
  }
}

.table-wrap-statics {
  .table {
    &-name-inner {
      &:first-child {
        left: 0;
        overflow: visible;
        background: $background;
        z-index: 23;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 1px;
          height: 100%;
          background: $border-gray;
        }
        .table-name-icon {
          display: none;
        }
      }
    }
    &-data {
      &:first-child {
        .c-table-data-inner {
          position: sticky;
          top: adjustVH(56);
          background: $background;
          z-index: 22;
          &:first-child {
            position: sticky;
            left: 0;
            overflow: visible;
            z-index: 24;
          }
        }
      }
      &-inner {
        &:first-child {
          position: sticky;
          left: 0;
          overflow: visible;
          background: $background;
          z-index: 20;
          &::before {
            content: '';
            position: absolute;
            right: 0;
            bottom: 1px;
            width: 100%;
            height: 1px;
            background: $line-gray;
          }
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 1px;
            height: 100%;
            background: $border-gray;
          }
        }
      }
    }
  }
}

@keyframes showContent {
  0% {
    opacity: 0;
    transform: translateX($space-base);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.toggle-detail-enter-active,
.toggle-detail-leave-active {
  transition: opacity $transition-base;
}
.toggle-detail-enter,
.toggle-detail-leave-to {
  opacity: 0;
  will-change: opacity, transform;
}

.toggle-icon-enter,
.toggle-icon-leave-to {
  opacity: 0;
  transform: translateX(calc(-50% + #{$space-small}));
  will-change: opacity, transform;
}
</style>
