<template>
  <div class="train-wrap" :class="{ 'train-wrap-finish': trainingComp }">
    <div class="train-inner">
      <training-config-main
        :rawPredictionColumnOptions="rawPredictionColumnOptions"
        :targetColumn="mainData.targetColumn"
        :selectedTrainingData="selectedTrainingData"
        :selectedTrainingDataSample="selectedTrainingDataSample"
        :selectedRecipe="selectedRecipe"
        :recipeType="recipeType"
        :accountInfo="accountInfo"
        :charts="charts"
        :chartsData="chartsData"
        :trainingProgressStatus="trainingProgressStatus"
        :elapsedTime="elapsedTime"
        :trainingNumIter="trainingNumIter"
        :trainingProgress="trainingProgress"
        :optimizationNumIter="optimizationNumIter"
        :optimizationProgress="optimizationProgress"
        :sortedTrainedAis="sortedTrainedAis"
        :message="message"
        :errorManual="errorManual"
        :errorDetail="errorDetail"
        :isSaved="isSaved"
        :isStopped="isStopped"
        :bestParams="bestParams"
        :trials="trials"
        :finishColumns="finishColumns"
        :selectedColumnIndex="internalSelectedColumn"
        :optimizationInfo="optimizationInfo"
        :checkOptimization="checkOptimization"
        :learningPredictionColumns="learningPredictionColumns"
        :timeTransformerV2Setting="timeTransformerV2Setting"
        @training-start="$emit('training-start', $event)"
        @training-stop="$emit('training-stop')"
        @background-training-stop="$emit('background-training-stop')"
        @save-trained-ai="$emit('save-trained-ai')"
        @show-graph="$emit('show-graph', $event)"
        @page-back="$emit('page-back')"
        @check-expressions="$emit('check-expressions', $event)"
        @show-optimization-tutorial="$emit('show-optimization-tutorial')"
        @show-time-transformer-before-setting="
          $emit('show-time-transformer-before-setting')
        "
      />
    </div>
    <div v-if="trainingComp" ref="trainingComp" class="train-inner">
      <training-result-main
        :accountInfo="accountInfo"
        :createInfo="createInfo"
        :isSaved="isSaved"
        :recipeType="recipeType"
        :resetKey="resetKey"
        :sortedFlag="sortedFlag"
        :sortedTrainedAis="sortedTrainedAis"
        :finishColumns="finishColumns"
        :loadChangeColumn="loadChangeColumn"
        :trainingProgressStatus="trainingProgressStatus"
        :selectedColumnIndex="internalSelectedColumn"
        :optimizationResult="optimizationResult"
        :optimizationInfo="optimizationInfo"
        :textMining="textMining"
        :threshold="threshold"
        :reversePositive="reversePositive"
        :testDatasetInfo="testDatasetInfo"
        :showTrainedAiType="showTrainedAiType"
        :showTrainedAiIndex="showTrainedAiIndex"
        :checkOptimization="checkOptimization"
        :clusteringSetting="clusteringSetting"
        :clusteringResult="clusteringResult"
        :clusteringDistributions="clusteringDistributions"
        :regressionGraph="regressionGraph"
        @delete-trained-ai="$emit('delete-trained-ai')"
        @input-create-form="$emit('input-create-form', $event)"
        @save-trained-ai="$emit('save-trained-ai')"
        @change-column="changeColumn"
        @change-tab="changeTab"
        @save-edit-optimization-form="
          $emit('save-edit-optimization-form', $event)
        "
        @change-page="$emit('change-page', $event)"
        @change-test-dataset-page="$emit('change-test-dataset-page', $event)"
        @change-filter-value="$emit('change-filter-value', $event)"
        @show-detail-optimization="$emit('show-detail-optimization', $event)"
        @download-result="$emit('download-result', $event)"
        @download-test-dataset="$emit('download-test-dataset', $event)"
        @close-optimization-result-tutorial="
          $emit('close-optimization-result-tutorial')
        "
        @change-threshold="$emit('change-threshold', $event)"
        @reverse-positive="$emit('reverse-positive', $event)"
        @load-text-mining="$emit('load-text-mining', $event)"
        @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
        @update-show-type="$emit('update-show-type', $event)"
        @update-show-trained-ai="$emit('update-show-trained-ai', $event)"
        @input-clustering-setting="$emit('input-clustering-setting', $event)"
        @download-clustering-result="
          $emit('download-clustering-result', $event)
        "
        @change-clustering-distribution-column="
          $emit('change-clustering-distribution-column', $event)
        "
        @toggle-clustering-show-dimension="
          $emit('toggle-clustering-show-dimension', $event)
        "
        @select-regression-graph="$emit('select-regression-graph', $event)"
        @download-confusion-matrix="$emit('download-confusion-matrix', $event)"
      />
    </div>
  </div>
</template>

<script>
import trainingConfigMain from './training-config-main'
import trainingResultMain from './training-result-main'

export default {
  components: {
    trainingConfigMain,
    trainingResultMain
  },
  data() {
    return {
      trainingComp: false,
      internalSelectedColumn: 0
    }
  },
  props: {
    rawPredictionColumnOptions: Object,
    selectedTrainingData: Object,
    selectedTrainingDataSample: Object,
    selectedRecipe: Object,
    recipeType: String,
    accountInfo: Object,
    charts: Object,
    chartsData: Object,
    trainingProgressStatus: String,
    elapsedTime: Number,
    trainingNumIter: Number,
    trainingProgress: Number,
    optimizationNumIter: Number,
    optimizationProgress: Number,
    sortedTrainedAis: Array,
    mainData: Object,
    message: String,
    errorManual: String,
    errorDetail: Object,
    sortedFlag: Boolean,
    isSaved: Boolean,
    isStopped: Boolean,
    finishColumns: Array,
    bestParams: Object,
    trials: Array,
    resetKey: Number,
    createInfo: Object,
    loadChangeColumn: Boolean,
    loadingReGet: Boolean,
    selectedColumnIndex: Number,
    optimizationInfo: Object,
    optimizationResult: Object,
    textMining: Object,
    checkOptimization: Boolean,
    learningPredictionColumns: Array,
    threshold: Number,
    reversePositive: Boolean,
    testDatasetInfo: Object,
    showTrainedAiType: String,
    showTrainedAiIndex: Number,
    timeTransformerV2Setting: Object,
    clusteringSetting: Object,
    clusteringResult: Object,
    clusteringDistributions: Array,
    regressionGraph: Object
  },
  computed: {
    showOptimizationResult() {
      if (!this.checkOptimization) return false
      if (!this.sortedTrainedAis || this.sortedTrainedAis.length === 0)
        return false
      if (!this.optimizationResult?.result) return false
      return Object.keys(this.optimizationResult.result).length > 0
    }
  },
  methods: {
    changeTab() {
      this.internalSelectedColumn = this.selectedColumnIndex
      this.$emit('change-tab-optimization') // 最適化の情報のリセットを行う
    },
    changeColumn(column) {
      let columnIndex = 0
      if (this.finishColumns && this.finishColumns.length > 1) {
        columnIndex = this.finishColumns.indexOf(column)
      }
      this.internalSelectedColumn = columnIndex
      this.$emit('change-column', column)
    }
  },
  watch: {
    // ソートされた学習済みAIに変更が入ったら、下画面に移動している。
    // 学習・再学習完了時に下画面にスクロールするための処理
    sortedTrainedAis() {
      if (
        this.trainingProgressStatus === 'finishTraining' &&
        this.sortedTrainedAis !== null
      ) {
        this.trainingComp = true
        this.$nextTick(() => {
          window.scrollTo({
            top: this.$refs.trainingComp.offsetTop,
            behavior: 'smooth'
          })
        })
      } else {
        if (this.finishColumns.length > 0) return
        this.trainingComp = false
      }
    },
    trainingProgressStatus() {
      if (this.checkOptimization) return
      if (
        this.trainingProgressStatus === 'finishTraining' &&
        this.sortedTrainedAis !== null
      ) {
        this.trainingComp = true
        const top = this.$refs.trainingComp?.offsetTop
        // trainingCompの表示場所がある場合だけ、スクロール処理を実行
        if (top) {
          this.$nextTick(() => {
            window.scrollTo({
              top: top,
              behavior: 'smooth'
            })
          })
        }
      }
    },
    finishColumns(newVal) {
      if (this.checkOptimization) return
      if (
        newVal.length === 1 &&
        this.sortedTrainedAis &&
        this.sortedTrainedAis.length > 0 &&
        !this.checkOptimization
      ) {
        this.trainingComp = true
        this.$nextTick(() => {
          window.scrollTo({
            top: this.$refs.trainingComp.offsetTop,
            behavior: 'smooth'
          })
        })
      }
    },
    /**
     * 他のAIの場合とことなり、sortedTrainedAiがセットされたタイミングでは判別できないため。
     * 専用の判定を入れている。
     */
    showOptimizationResult(newVal) {
      if (newVal) {
        this.trainingComp = true
        this.$nextTick(() => {
          window.scrollTo({
            top: this.$refs.trainingComp.offsetTop,
            behavior: 'smooth'
          })
        })
      }
    },
    selectedColumnIndex(num) {
      if (num !== this.internalSelectedColumn) {
        this.internalSelectedColumn = num
      }
    },
    loadingReGet(newBool) {
      const tmpTrainingComp = this.trainingComp
      if (newBool) {
        // 再取得するときは、一旦完了状態を解除する
        this.$nextTick(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        })
        this.trainingComp = false
      } else if (!tmpTrainingComp) {
        // 再取得が終わったら、完了状態に戻す
        this.trainingComp = true
      }
    }
  },
  mounted() {
    this.internalSelectedColumn = this.selectedColumnIndex
  }
}
</script>

<style lang="scss" scoped>
.train {
  &-wrap {
    display: grid;
    grid-template-rows: repeat(1, adjustVH($baseHeight - $headerTabHeight));
    &-finish {
      grid-template-rows: repeat(2, adjustVH($baseHeight - $headerTabHeight));
    }
  }
}
</style>
