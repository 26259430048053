var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"c-sidebar"},[_c('div',{staticClass:"c-sidebar-top"},[_c('div',{staticClass:"c-sidebar-top-link"},[_c('router-link',{attrs:{"to":{ name: 'projectList' }}},[_c('text-with-icon',{attrs:{"text":_vm.$t('datasetDetail.detailSidebar.backward'),"iconName":"backward","size":"small"}})],1)],1),(_vm.taragetProject)?_c('div',{staticClass:"c-sidebar-project-outline"},[_c('texts',{attrs:{"text":_vm.taragetProject.name,"size":"large"}}),_c('description',{attrs:{"text":_vm.taragetProject.description,"line":"2"}})],1):(_vm.projectLoading)?_c('div',{staticClass:"c-sidebar-project-outline c-sidebar-project-outline-loading"},[_c('loading-icon')],1):_vm._e(),_c('div',{staticClass:"c-sidebar-project-link-list"},[_c('div',{staticClass:"\n            c-sidebar-project-link-list-body c-sidebar-project-button-list\n          "},[_c('button-main',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
              content: _vm.$t('sidebar.project.tips.createNewAi'),
              classes: 'tooltip-long-text',
              trigger: this.activeLink === 'inference' ? 'manual' : 'hover',
              delay: { show: 200, hide: 200 }
            }),expression:"{\n              content: $t('sidebar.project.tips.createNewAi'),\n              classes: 'tooltip-long-text',\n              trigger: this.activeLink === 'inference' ? 'manual' : 'hover',\n              delay: { show: 200, hide: 200 }\n            }",modifiers:{"right":true}}],staticClass:"c-sidebar-project-button-item",attrs:{"text":_vm.$t('sidebar.project.titles.createNew'),"type":"emphasis-sub","iconName":"train"},on:{"click":_vm.addNewTrainedAi}}),_c('button-main',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
              content: _vm.$t('sidebar.project.tips.inference'),
              classes: 'tooltip-long-text',
              trigger: this.activeLink === 'inference' ? 'manual' : 'hover',
              delay: { show: 200, hide: 200 }
            }),expression:"{\n              content: $t('sidebar.project.tips.inference'),\n              classes: 'tooltip-long-text',\n              trigger: this.activeLink === 'inference' ? 'manual' : 'hover',\n              delay: { show: 200, hide: 200 }\n            }",modifiers:{"right":true}}],staticClass:"c-sidebar-project-button-item",attrs:{"text":_vm.$t('sidebar.project.titles.prediction'),"type":this.activeLink === 'inference' ? 'green' : 'green-sub',"iconName":"inference"},on:{"click":_vm.inferenceClicked}})],1)]),_c('div',{staticClass:"c-sidebar-project-link-list"},[_c('div',{staticClass:"c-sidebar-project-link-list-title"},[_c('texts',{attrs:{"text":_vm.$t('datasetDetail.detailSidebar.project'),"size":"small","color":"gray"}})],1),_c('div',{staticClass:"c-sidebar-project-link-list-body"},[_c('button',{staticClass:"c-sidebar-project-link-list-inner",class:{ active: this.activeLink === 'dashboard' },on:{"click":_vm.dashboardClicked}},[_c('text-with-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
                content: _vm.$t('sidebar.project.tips.dashboard'),
                classes: 'tooltip-long-text',
                trigger: this.activeLink === 'dashboard' ? 'manual' : 'hover',
                delay: { show: 200, hide: 200 }
              }),expression:"{\n                content: $t('sidebar.project.tips.dashboard'),\n                classes: 'tooltip-long-text',\n                trigger: this.activeLink === 'dashboard' ? 'manual' : 'hover',\n                delay: { show: 200, hide: 200 }\n              }",modifiers:{"right":true}}],attrs:{"iconName":"dashbord","text":_vm.$t('datasetDetail.detailSidebar.dashboard'),"color":this.activeLink === 'dashboard' ? 'emphasis' : 'gray'}})],1),_c('button',{staticClass:"c-sidebar-project-link-list-inner",class:{ active: this.activeLink === 'dataset' },on:{"click":_vm.datasetClicked}},[_c('text-with-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
                content: _vm.$t('sidebar.project.tips.dataset'),
                classes: 'tooltip-long-text',
                trigger: this.activeLink === 'dataset' ? 'manual' : 'hover',
                delay: { show: 200, hide: 200 }
              }),expression:"{\n                content: $t('sidebar.project.tips.dataset'),\n                classes: 'tooltip-long-text',\n                trigger: this.activeLink === 'dataset' ? 'manual' : 'hover',\n                delay: { show: 200, hide: 200 }\n              }",modifiers:{"right":true}}],attrs:{"iconName":"dataset","text":_vm.$t('datasetDetail.detailSidebar.dataset'),"color":this.activeLink === 'dataset' ? 'emphasis' : 'gray'}})],1),_c('button',{staticClass:"c-sidebar-project-link-list-inner",class:{ active: this.activeLink === 'preprocessing' },on:{"click":_vm.preprocessingClicked}},[_c('text-with-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
                content: _vm.$t('sidebar.project.tips.preprocessing'),
                classes: 'tooltip-long-text',
                trigger:
                  this.activeLink === 'preprocessing' ? 'manual' : 'hover',
                delay: { show: 200, hide: 200 }
              }),expression:"{\n                content: $t('sidebar.project.tips.preprocessing'),\n                classes: 'tooltip-long-text',\n                trigger:\n                  this.activeLink === 'preprocessing' ? 'manual' : 'hover',\n                delay: { show: 200, hide: 200 }\n              }",modifiers:{"right":true}}],attrs:{"iconName":"preproc","text":_vm.$t('datasetDetail.detailSidebar.preprocAndEda'),"color":this.activeLink === 'preprocessing' ? 'emphasis' : 'gray'}})],1),_c('button',{staticClass:"c-sidebar-project-link-list-inner",class:{ active: this.activeLink === 'recipe' },on:{"click":_vm.recipeClicked}},[_c('text-with-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
                content: _vm.$t('sidebar.project.tips.recipe'),
                classes: 'tooltip-long-text',
                trigger: this.activeLink === 'recipe' ? 'manual' : 'hover',
                delay: { show: 200, hide: 200 }
              }),expression:"{\n                content: $t('sidebar.project.tips.recipe'),\n                classes: 'tooltip-long-text',\n                trigger: this.activeLink === 'recipe' ? 'manual' : 'hover',\n                delay: { show: 200, hide: 200 }\n              }",modifiers:{"right":true}}],attrs:{"iconName":"recipe","text":_vm.$t('datasetDetail.detailSidebar.recipe'),"color":this.activeLink === 'recipe' ? 'emphasis' : 'gray'}})],1),_c('button',{staticClass:"c-sidebar-project-link-list-inner",class:{ active: this.activeLink === 'model' },on:{"click":_vm.modelClicked}},[_c('text-with-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
                content: _vm.$t('sidebar.project.tips.trainedAi'),
                classes: 'tooltip-long-text',
                trigger: this.activeLink === 'model' ? 'manual' : 'hover',
                delay: { show: 200, hide: 200 }
              }),expression:"{\n                content: $t('sidebar.project.tips.trainedAi'),\n                classes: 'tooltip-long-text',\n                trigger: this.activeLink === 'model' ? 'manual' : 'hover',\n                delay: { show: 200, hide: 200 }\n              }",modifiers:{"right":true}}],attrs:{"iconName":"trainedAi","text":_vm.$t('datasetDetail.detailSidebar.createdAI'),"color":this.activeLink === 'model' ? 'emphasis' : 'gray'}})],1),(_vm.showService)?_c('button',{staticClass:"c-sidebar-project-link-list-inner",class:{ active: this.activeLink === 'service' },on:{"click":_vm.serviceClicked}},[_c('text-with-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
                content: _vm.$t('sidebar.project.tips.service'),
                classes: 'tooltip-long-text',
                trigger: this.activeLink === 'service' ? 'manual' : 'hover',
                delay: { show: 200, hide: 200 }
              }),expression:"{\n                content: $t('sidebar.project.tips.service'),\n                classes: 'tooltip-long-text',\n                trigger: this.activeLink === 'service' ? 'manual' : 'hover',\n                delay: { show: 200, hide: 200 }\n              }",modifiers:{"right":true}}],attrs:{"iconName":"service","text":_vm.$t('common.service'),"color":this.activeLink === 'service' ? 'emphasis' : 'gray'}})],1):_vm._e()])])])]),_c('popup-array',{attrs:{"popupDefs":_vm.popupDefs,"showPopup":_vm.showPopupList},on:{"close-modal":function($event){return _vm.closePopup($event)}},scopedSlots:_vm._u([{key:"upperLimitTrainedAis",fn:function(){return [_c('upper-limit-trained-ais',{on:{"close-modal":function($event){return _vm.closePopup('upperLimitTrainedAis')}}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }