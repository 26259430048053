<template>
  <div
    class="project-detail-summary"
    :class="{
      'project-detail-summary-multi': checkNotTimeseriesMulti
    }"
  >
    <select-column-box
      v-if="checkNotTimeseriesMulti"
      v-model="selectedItem"
      :trainedAi="sortedTrainedAi"
      :title="$t('trainedAi.multi.selectedColumn')"
      @change-column="$emit('change-column', $event)"
    />
    <detail-metrics
      v-if="sortedTrainedAi.summary.metricsList"
      :trainedAi="sortedTrainedAi"
      :isMulti="checkNotTimeseriesMulti"
      :selectedColumnIndex="selectedColumnIndex"
      :metricsList="sortedTrainedAi.summary.metricsList"
      :loadChangeColumn="loadChangeColumn"
    />
    <div
      v-if="checkNotTimeseriesMulti"
      class="project-detail-summary-multi-line"
    />
    <card-fi
      :class="{
        'project-detail-summary-multi-feature-imporntace':
          checkNotTimeseriesMulti
      }"
      :predictionColumns="sortedTrainedAi.summary.predictionColumns"
      :featureImportance="sortedTrainedAi.summary.featureImportance"
      :selectedColumnIndex="selectedColumnIndex"
      :loadFeatureImportance="loadChangeColumn"
      :maxItem="5"
    />
  </div>
</template>

<script>
import cardFi from '@/components/organisms/card-fi'

import selectColumnBox from '@/components/organisms/trained-ai-common/select-column-box'
import detailMetrics from '@/components/organisms/trained-ai-detail/trained-ai-detai-tab-inner/detail/detail-metrics'

export default {
  components: {
    cardFi,
    selectColumnBox,
    detailMetrics
  },
  data() {
    return {
      selectedItem: null
    }
  },
  props: {
    sortedTrainedAi: Object,
    selectedColumnIndex: Number,
    loadChangeColumn: Boolean
  },
  computed: {
    trainedAiType() {
      return this.sortedTrainedAi.summary.type
    },
    checkNotTimeseriesMulti() {
      return (
        this.sortedTrainedAi.trainConfig.predictionColumn.length > 1 &&
        !this.$recipeType.time.includes(this.trainedAiType)
      )
    }
  },
  mounted() {
    const configColumn =
      this.sortedTrainedAi?.trainConfig?.predictionColumn ?? []
    if (configColumn.length === 0) return
    this.selectedItem = configColumn[this.selectedColumnIndex]
  },
  watch: {
    selectedItem(newVal) {
      this.$emit('change-column', newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.project-detail {
  &-summary {
    display: grid;
    grid-template-rows: minmax(auto, adjustVW(160)) minmax(auto, 1fr);
    grid-row-gap: $space-medium;
    grid-area: summary;
    &-multi {
      grid-template-areas:
        'input metrics'
        'line line'
        'importance importance';
      grid-template-columns: adjustVW(288) 1fr;
      grid-template-rows: max-content max-content 1fr;
      grid-column-gap: $space-large;
      grid-row-gap: $space-medium;
      padding: $space-small;
      background-color: #fff;
      border-radius: adjustVW(16);
      box-shadow: $box-shadow-main;
      &-feature-imporntace {
        padding: 0;
        background: none;
        box-shadow: none;
        grid-area: importance;
      }
      &-line {
        height: adjustVW(1);
        background-color: $line-gray;
        grid-area: line;
      }
      &-none {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        grid-area: importance;
      }
    }
  }
}
</style>
