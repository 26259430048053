<template>
  <div class="limit-wrap">
    <texts class="limit-inner">
      <span
        v-html="
          $t('training.popup.limitTrainedAi.reason_html', { num: limitCount })
        "
      />
    </texts>
    <div class="limit-detail">
      <text-with-title
        class="limit-detail-item"
        :text="
          $t('training.popup.limitTrainedAi.saveTrainedAiCountDetail', {
            count: numOwntrainedAIs,
            maxCount: maxTrainedAIs
          })
        "
        :title="$t('training.popup.limitTrainedAi.saveTrainedAiCount')"
      />
      <text-with-title
        class="limit-detail-item"
        :text="numCreatingtrainedAIs"
        :title="$t('training.popup.limitTrainedAi.createTrainedAiCount')"
      />
    </div>
    <div class="limit-button-list">
      <div>
        <button-main
          :text="$t('training.button.checkPlan')"
          type="emphasis"
          @click="checkPlan"
        />
        <button-main
          :text="$t('training.button.deleteTrainedAi')"
          type="sub"
          @click="deleteTrainedAis"
        />
      </div>
      <button-main
        class="limit-button-last"
        :text="$t('training.button.forceStartTraining')"
        type="sub"
        @click="$emit('training-start-over')"
      />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import buttonMain from '@/components/atoms/button-main.vue'
import textWithTitle from '@/components/molecules/text-with-title.vue'

export default {
  components: {
    texts,
    buttonMain,
    textWithTitle
  },
  props: {
    accountInfo: Object,
    numOwntrainedAIs: Number,
    numCreatingtrainedAIs: Number
  },
  computed: {
    limitCount() {
      return this.maxTrainedAIs - this.numOwntrainedAIs
    },
    maxTrainedAIs() {
      return this.accountInfo.planDetail.numModel
    }
  },
  methods: {
    checkPlan() {
      this.$router.push({
        name: 'changePlan'
      })
    },
    deleteTrainedAis() {
      this.$router.push({
        name: 'trainedAiList'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.limit {
  &-wrap {
    width: adjustVW(800 - 48);
  }
  &-inner {
    margin: 0 0 $space-medium;
    white-space: pre-line;
  }
  &-detail {
    display: flex;
    margin: 0 0 $space-medium;
    &-item {
      width: calc(50% - #{$space-sub});
    }
  }
  &-button-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
.caution {
  color: $text-caution;
}
</style>
