<template>
  <card-base class="wrap">
    <div class="inner">
      <div class="image">
        <!-- 画像の作成忘れないように！！！ Trelloのカード:142 -->
        <images class="image-item" imageName="askSupportMin" />
      </div>
      <div class="text">
        <texts
          class="main"
          :text="$t('selectTemplate.detail.datasetInfo.basic')"
          isShowAll
        />
        <!-- 遷移先忘れずに -->
        <a :href="$urls.contactLink" target="_blank">
          <texts
            :text="$t('selectTemplate.detail.datasetInfo.link')"
            color="link"
          />
        </a>
      </div>
    </div>
  </card-base>
</template>

<script>
import cardBase from '@/components/atoms/card-base'
import texts from '@/components/atoms/text'
import images from '@/components/atoms/images'

export default {
  components: {
    cardBase,
    texts,
    images
  }
}
</script>

<style scoped lang="scss">
.inner {
  display: flex;
  align-items: center;
}
.image {
  width: adjustVW(88);
  height: adjustVW(88);
  margin: 0 $space-sub 0 0;
}
.text {
  width: calc(100% - #{adjustVW(88)});
}
.main {
  margin: 0 0 $space-base;
  white-space: pre-line;
}
</style>
