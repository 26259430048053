<template>
  <div class="wrap">
    <div class="title" :class="{ 'title-confidence': checkGraphProb }">
      <div
        v-if="inferencedColumns && inferencedColumns.length > 1"
        class="input-box"
      >
        <select-column-box
          :title="$t('trainedAi.multi.selectedColumn')"
          :finishColumns="finishedColumnItems"
          :trainedAi="usedAiInfo"
          :value="selectedItem"
          @input="$emit('change-column', $event)"
        />
      </div>
      <texts
        v-else
        :text="
          testInferencedColumn
            ? $t('trainedAiDetails.testDatasetTab.tableTitle')
            : $t('inference.result.title')
        "
        size="large"
      />
      <div v-if="testInferencedColumn" class="title-download">
        <icon-button
          v-tooltip="downloadTips"
          class="title-download-button"
          iconName="download"
          size="min"
          @click="$emit('download-test-dataset', encodingType)"
        />
        <select-box
          v-model="encodingType"
          class="title-download-input"
          isGray
          min
          :items="downloadTypes"
        />
      </div>
    </div>
    <div class="main">
      <div v-show="targetColumn" ref="tableBody" class="block">
        <div ref="detailScrollTarget" :class="{ 'block-detail': showDetail }">
          <table class="body">
            <thead>
              <tr class="label">
                <th class="label-inner label-result-1 label-select">
                  <select-box-input
                    :value="selectTarget"
                    isGray
                    isSeparateBox
                    :items="fixResultSelectTarget"
                    isScroll
                    :margin="40"
                    noIcon
                    @select-item="selectedTarget"
                  />
                </th>
                <th class="label-inner label-result-2 label-main">
                  <texts
                    :text="fixTargetText"
                    size="small"
                    :color="testInferencedColumn ? 'green' : 'default'"
                  />
                </th>
                <th
                  v-if="testInferencedColumn"
                  class="label-inner label-result-test-inferenced label-main"
                >
                  <texts
                    :text="
                      $t('trainedAi.metrics.predictions') + ': ' + targetColumn
                    "
                    size="small"
                    color="emphasis"
                  />
                </th>
                <th
                  v-if="checkGraphProb"
                  class="label-inner label-result-confidence label-small"
                >
                  <texts
                    :text="$t('inference.result.confidence')"
                    size="small"
                  />
                </th>
                <th class="label-inner label-result-3 label-small">
                  <texts
                    :text="$t('inference.result.openDetail')"
                    size="small"
                  />
                </th>
                <template v-for="(key, index) in tableColumns">
                  <transition-group
                    v-if="key !== targetColumn && key !== testInferencedColumn"
                    :key="index"
                    tag="th"
                    class="label-inner"
                    name="toggle-detail"
                    mode="out-in"
                  >
                    <template v-if="!showDetail">
                      <texts :key="index" :text="key" size="small" />
                    </template>
                  </transition-group>
                </template>
              </tr>
            </thead>
            <tbody
              key="table"
              class="data-wrapper"
              :class="{
                'data-wrapper-loading': loading,
                'data-wrapper-active': !loading
              }"
            >
              <tr
                v-for="(entry, index) in table"
                :key="index"
                class="data"
                :class="{ 'data-active': showId === index }"
              >
                <td class="data-inner data-result-1 data-select">
                  <texts :text="displayData(index, entry)" size="small" />
                </td>
                <td class="data-inner data-result-2">
                  <texts
                    :text="entry[targetColumn]"
                    size="small"
                    :color="testInferencedColumn ? 'green' : 'default'"
                    :isBold="Boolean(testInferencedColumn)"
                  />
                </td>
                <td
                  v-if="testInferencedColumn"
                  class="data-inner data-result-test-inferenced"
                >
                  <texts
                    :text="entry[testInferencedColumn]"
                    size="small"
                    color="emphasis"
                    isBold
                  />
                </td>
                <td
                  v-if="checkGraphProb"
                  class="data-inner data-result-confidence data-small"
                >
                  <texts
                    :text="getProbData(index, entry[targetColumn])"
                    size="small"
                  />
                </td>
                <td
                  class="data-inner data-result-3 data-small"
                  @click="openDetail(index)"
                >
                  <span>{{ $t('inference.result.openDetail') }}</span><icons iconName="forward" color="emphasis" size="small" />
                </td>
                <template v-for="(key, index) in tableColumns">
                  <td
                    v-if="key !== targetColumn && key !== testInferencedColumn"
                    :key="index"
                    class="data-inner"
                  >
                    <texts :key="index" :text="entry[key]" size="small" />
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
          <transition name="toggle-detail" mode="out-in">
            <div v-if="loading" key="load" class="loading">
              <loading-icon />
            </div>
          </transition>
        </div>
        <transition name="toggle-detail" mode="out-in">
          <numerical-table-detail
            v-if="showDetail"
            :targetColumn="targetColumn"
            :table="table"
            :showId="showId"
            :graphProb="graphProb"
            :checkGraphProb="checkGraphProb"
            :importanceProb="importanceProb"
            :testInferencedColumn="testInferencedColumn"
            @close-detail="closeDetail"
          />
        </transition>
      </div>
      <transition-toggle-contents>
        <div v-if="!targetColumn" class="none">
          <texts :text="$t('trainedAi.multi.notFound')" color="gray" />
        </div>
      </transition-toggle-contents>
    </div>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import iconButton from '@/components/atoms/icon-button'
import texts from '@/components/atoms/text'
import loadingIcon from '@/components/atoms/loading-icon'
import numericalTableDetail from './numerical-table-detail'
import selectBox from '@/components/molecules/select-box'
import selectBoxInput from '@/components/molecules/select-box-input.vue'
import selectColumnBox from '@/components/organisms/trained-ai-common/select-column-box.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

function isWindows() {
  return window.navigator.userAgent.toLowerCase().indexOf('windows') >= 0
}

const SELECTER_INDEX_NAME = 'index'

export default {
  components: {
    icons,
    iconButton,
    texts,
    loadingIcon,
    numericalTableDetail,
    selectBox,
    selectBoxInput,
    selectColumnBox,
    transitionToggleContents
  },
  data() {
    return {
      showDetail: false,
      showId: null,
      encodingType: isWindows() ? 'cp932' : 'utf8',
      selectTarget: SELECTER_INDEX_NAME
    }
  },
  props: {
    /** 予測する列 */
    targetColumn: String,
    /** 表示する推論結果の列名 */
    tableColumns: Array,
    /** 表示する推論結果 */
    table: Array,
    /** 表示する推論結果の信頼度 */
    graphProb: Array,
    /** 表示する推論結果の重要度 */
    importanceProb: Array,
    /** 現在表示している推論結果が何ページ目か */
    inPageNumber: Number,
    /** 推論結果の表示が最大何ページあるか */
    pagingCount: Number,
    /** 推論結果の表示待ち */
    loading: Boolean,
    /** 推論が終わった列 */
    finishColumnsFix: Array,
    /** 推論結果を表示する列 */
    selectedItem: String,
    /** 推論に使用した学習済みAI */
    usedAiInfo: Object,
    /** 推論を行う対象の列 */
    inferencedColumns: Array,
    /** 学習時のテストデータでの推論結果の列名 */
    testInferencedColumn: {
      type: String,
      default: null
    },
    /** 学習時のダウンロードボタン用設定値 */
    download: {
      type: Object,
      default: null
    }
  },
  methods: {
    openDetail(index) {
      const scrollTop = this.$refs.tableBody.scrollTop
      let stillDetail = false
      if (this.showDetail) {
        stillDetail = true
      }
      this.showDetail = true
      this.showId = index
      if (!stillDetail) {
        this.$nextTick(function () {
          this.$refs.detailScrollTarget.scrollTop = scrollTop
        })
      }
    },
    closeDetail() {
      const scrollTop = this.$refs.detailScrollTarget.scrollTop
      this.showDetail = false
      this.showId = ''
      this.$nextTick(function () {
        this.$refs.tableBody.scrollTop = scrollTop
      })
    },
    getFeatureImportance(num) {
      return num
    },
    getProbData: function (index, targetValue) {
      const probTargetList = Object.keys(this.graphProb[0])

      if (probTargetList.includes(targetValue))
        return this.graphProb[index][targetValue]

      const includedTargetLabel = probTargetList.filter((label) =>
        label.includes(targetValue)
      )

      if (includedTargetLabel.length > 0) {
        const label = includedTargetLabel[0]
        return this.graphProb[index][label]
      }
    },
    displayData(index, entry) {
      if (this.selectTarget === SELECTER_INDEX_NAME) {
        return index + 1 + (this.inPageNumber - 1) * this.pagingCount
      } else {
        return entry[this.selectTarget]
      }
    },
    selectedTarget({ selectItem }) {
      this.selectTarget = selectItem.value
    }
  },
  computed: {
    checkGraphProb() {
      if (!this.graphProb) return false
      const checkItem = this.graphProb.findIndex((x) => {
        return x && Object.keys(x).length > 0
      })

      return checkItem >= 0
    },
    finishedColumnItems() {
      if (!this.finishColumnsFix) return []
      return this.finishColumnsFix
    },
    fixTargetText() {
      if (this.testInferencedColumn != null) {
        return (
          this.$t('trainedAi.metrics.observations') + ': ' + this.targetColumn
        )
      } else {
        return this.targetColumn
      }
    },
    fixResultSelectTarget() {
      if (
        this.importanceProb == null ||
        this.importanceProb.length === 0 ||
        this.importanceProb[0] == null
      )
        return []
      const importanceProbBase = Object.keys(this.importanceProb[0])
      if (
        this.graphProb == null ||
        this.graphProb.length === 0 ||
        this.graphProb[0] == null
      )
        return []
      const graphProbBase = Object.keys(this.graphProb[0])

      const columns = Object.keys(this.table[0]).filter((column) => {
        const checkNotColumnImportance = !importanceProbBase.some(
          (columnName) => {
            return column === columnName + '_importance'
          }
        )
        const checkNotProbability = !graphProbBase.some((graphName) => {
          return column === graphName + '_probability'
        })

        const checkNotInferenceResult =
          column !== this.targetColumn + '_inference_result'

        return (
          checkNotColumnImportance &&
          checkNotProbability &&
          checkNotInferenceResult
        )
      })

      const resColumns = columns.map((column) => {
        return {
          name: column,
          value: column
        }
      })

      const index = {
        name: this.$t('common.index'),
        value: SELECTER_INDEX_NAME
      }
      return [index, ...resColumns]
    },
    downloadTypes() {
      return [
        {
          id: 0,
          name: 'Windows (Shift-JIS)',
          value: 'cp932'
        },
        {
          id: 1,
          name: 'Mac (UTF-8)',
          value: 'utf8'
        }
      ]
    },
    downloadTips() {
      let tips = ''
      if (this.download?.downloadComp) {
        tips = this.$t('trainedAiDetails.testDatasetTab.download.comp')
      } else if (this.download?.downloading) {
        tips = this.$t('trainedAiDetails.testDatasetTab.download.loading')
      } else {
        tips = this.$t('trainedAiDetails.testDatasetTab.download.wait')
      }
      return {
        content: tips,
        hideOnTargetClick: false
      }
    }
  },
  watch: {
    table() {
      this.showDetail = false
      this.showId = null
      this.$refs.tableBody.scrollTop = 0
    },
    loading(newVal) {
      if (newVal) {
        this.showDetail = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$base-width: 80;
$label-width: 176;
$column-height: 56;
$select-width: 184;

.wrap {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.title {
  display: grid;
  align-items: center;
  grid-template-columns: adjustVW($select-width + $label-width + $base-width) + $space-small 1fr;
  margin-bottom: $space-base;
  &-backward {
    display: flex;
    cursor: pointer;
    &-icon {
      margin: 0 $space-base 0 0;
    }
  }
  &-confidence {
    grid-template-columns: adjustVW($base-width * 3 + $label-width) + $space-small 1fr;
  }
  &-download {
    display: flex;
    margin: 0 0 0 auto;
    &-button {
      margin: 0 $space-sub 0 0;
    }
    &-input {
      width: adjustVW(188);
      height: adjustVW(32);
      &::v-deep {
        .c-func-select-button-text {
          font-size: $text-min;
          @include text-crop;
        }
      }
    }
  }
}

.main {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.block {
  overflow: auto;
  display: flex;
  width: 100%;
  height: 100%;
  @include scrollbar;
  &-detail {
    overflow-x: hidden;
    overflow-y: scroll;
    @include scrollbar;
  }
}
.body {
  width: 100%;
  text-align: center;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
}
.label {
  background: $background;
  &-inner {
    position: sticky;
    top: 0;
    overflow: hidden;
    width: adjustVW($label-width);
    height: adjustVW($column-height);
    padding: $space-base;
    background: $background;
    font-size: $text-base;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 11;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: $border-gray;
    }
  }
  &-result {
    &-1 {
      left: 0;
      z-index: 12;
    }
    &-2 {
      left: adjustVW($select-width);
      z-index: 12;
    }
    &-test-inferenced {
      left: adjustVW($select-width + $label-width);
      z-index: 12;
      + .label-result-3 {
        left: adjustVW($select-width + $label-width * 2);
      }
      + .label-result-confidence {
        left: adjustVW($select-width + $label-width * 2);
        + .label-result-3 {
          left: adjustVW($select-width + $label-width * 2 + $base-width);
        }
      }
    }
    &-confidence {
      left: adjustVW($select-width + $label-width);
      background: $background;
      z-index: 12;
      + .label-result-3 {
        left: adjustVW($select-width + $label-width + $base-width);
      }
    }
    &-3 {
      left: adjustVW($select-width + $label-width);
      padding: 0 $space-small 0 0;
      z-index: 12;
      &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 1px;
        height: 100%;
        background: $line-gray;
      }
    }
  }
  &-small {
    width: adjustVW($base-width);
  }
  &-main {
    max-width: adjustVW($label-width);
  }
  &-select {
    width: adjustVW($select-width);
  }
}
.data {
  &-inner {
    position: relative;
    overflow: hidden;
    width: adjustVW($label-width);
    height: adjustVW($column-height);
    padding: 0 $space-small;
    font-size: $text-base;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 1;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: $line-gray;
    }
  }
  &-small {
    width: adjustVW($base-width);
    padding: 0;
  }
  &-select {
    width: adjustVW($select-width);
  }
  &-result {
    &-1 {
      position: sticky;
      left: 0;
      background: $background;
      z-index: 11;
    }
    &-2 {
      position: sticky;
      left: adjustVW($select-width);
      background: $background;
      z-index: 11;
    }
    &-test-inferenced {
      position: sticky;
      left: adjustVW($select-width + $label-width);
      background: $background;
      z-index: 11;
      + .data-result-3 {
        left: adjustVW($select-width + $label-width * 2);
      }
      + .data-result-confidence {
        left: adjustVW($select-width + $label-width * 2);
        + .data-result-3 {
          left: adjustVW($select-width + $label-width * 2 + $base-width);
        }
      }
    }
    &-confidence {
      position: sticky;
      left: adjustVW($select-width + $label-width);
      background: $background;
      z-index: 11;
      + .data-result-3 {
        left: adjustVW($select-width + $label-width + $base-width);
      }
    }
    &-3 {
      position: sticky;
      left: adjustVW($select-width + $label-width);
      display: flex;
      align-content: center;
      justify-content: center;
      width: auto;
      padding: 0 $space-small 0 0;
      background: $background;
      color: $text-decoration;
      cursor: pointer;
      user-select: none;
      z-index: 11;
      > span {
        height: calc(#{adjustVW($column-height)} - 2px);
        margin: 0 $space-base 0 0;
        line-height: calc(#{adjustVW($column-height)} - 2px);
      }
      &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 1px;
        height: 100%;
        background: $line-gray;
      }
    }
  }
  &-active {
    > td {
      background: $key-lite;
    }
  }
  &-wrapper {
    &-loading {
      opacity: 0;
      transform: translateX($space-base);
      transition: opacity 0.15s, transform 0.15s;
      will-change: opacity, transform;
    }
    &-active {
      animation: showContent $transition-base;
    }
  }
}

@keyframes showContent {
  0% {
    opacity: 0;
    transform: translateX($space-base);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.input-box {
  width: adjustVW(320);
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.none {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.toggle-detail-enter-active,
.toggle-detail-leave-active {
  transition: opacity $transition-base;
}
.toggle-detail-enter,
.toggle-detail-leave-to {
  opacity: 0;
  will-change: opacity, transform;
}
</style>
