<template>
  <plan-card-base
    class="free-plan-card-wrapper"
    v-bind="planData"
    :planName="this.$i18n.t('changePlan.freePlanInfo.planName')"
    :isSelected="isSelected"
    @active-item="activeItem"
  >
    <texts
      class="free-plan-card-text"
      color="gray"
      size="large"
      :text="this.$i18n.t('changePlan.freePlanInfo.price')"
    />
    <texts
      color="gray"
      size="min"
      :text="this.$i18n.t('changePlan.freePlanInfo.priceOption')"
    />
  </plan-card-base>
</template>

<script>
import PlanCardBase from '@/components/molecules/change-plan/plan-card-base.vue'
import Texts from '@/components/atoms/text.vue'

export default {
  components: {
    PlanCardBase,
    Texts
  },
  props: {
    planData: Object,
    isSelected: Boolean
  },
  methods: {
    activeItem: function ({ id }) {
      this.$emit('active-item', { id })
    }
  }
}
</script>

<style lang="scss" scoped>
.free-plan-card-text {
  margin-bottom: $space-text;
}
</style>
