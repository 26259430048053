<template>
  <div class="wrap">
    <div>
      <account-info-top />
    </div>
    <div>
      <account-info-card-list
        :accountDetailCard="accountDetailCard"
        :accountInfo="accountInfo"
        :accountInfoLoading="accountInfoLoading"
        :ownModels="ownModels"
        :ownServices="ownServices"
        :ownProjects="ownProjects"
        @create="$emit('create')"
        @delete="$emit('delete')"
      />
    </div>
  </div>
</template>

<script>
import accountInfoTop from '@/components/organisms/account-info/account-info-top.vue'
import accountInfoCardList from '@/components/organisms/account-info/account-info-card-list.vue'

export default {
  components: {
    accountInfoTop,
    accountInfoCardList
  },
  props: {
    accountDetailCard: Object,
    accountInfo: Object,
    accountInfoLoading: Boolean,
    ownModels: Number,
    ownServices: Number,
    ownProjects: Number
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: $space-base;
  height: 100%;
  padding: 0 $space-medium;
}
</style>
