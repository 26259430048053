<template>
  <div>
    <prevent-leave
      ref="preventLeave"
      v-model="isPrevent"
      :title="$t('common.trainLeave.popup.title')"
      :saveButton="
        $t('common.trainLeave.popup.saveButton', { currentPage: currentPage })
      "
      :leaveButton="$t('common.trainLeave.popup.leaveButtonProject')"
      leaveButtonColor="sub"
      @save-event="closePrevent"
    >
      <texts class="prevent-leave-text" isShowAll size="small">
        <span
          v-html="
            $t('common.trainLeave.reason.first', {
              leavePage: leavePage,
              currentPage: currentPage
            })
          "
        />
        <span
          v-html="
            $t('common.trainLeave.reason.second', { currentPage: currentPage })
          "
        />
        <span v-html="$t('common.trainLeave.reason.project')" />
      </texts>
    </prevent-leave>
    <project-template-detail
      :datasetId="datasetId"
      :disabledClick="disabledClick"
      :headerTabs="headerTabs"
      :loadingPage="loadingPage"
      :loadingDatasets="loadingDatasets"
      :sidebar="sidebar"
      :popup="popup"
      :projectDetail="data.projectDetail"
      @close-modal="closePopup($event), changeStatus('confirm')"
      @create-project="createProject($event)"
      @input-create-form="inputCreateForm($event)"
      @select-project="showPopup('projectSetting'), changeStatus('setting')"
      @page-back="pageBack"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import setMountedTimer from '@/mixin/set-mounted-timer'

import { projectValidator } from '@/lib/validator/project.js'
import {
  checkOuterTraining,
  checkCorrectBackPage
} from '@/lib/training-pages-correct-behavior'

import projectTemplateDetail from '@/components/templates/project-template-detail.vue'
import preventLeave from '@/components/molecules/prevent-leave'

export default {
  components: {
    projectTemplateDetail,
    preventLeave
  },
  mixins: [setMountedTimer],
  async beforeRouteLeave(to, from, next) {
    this.isPrevent = this.checkPrevent(to.name)

    if (this.isPrevent) {
      this.leavePageName = to.name
      const result = await this.$refs.preventLeave.$confirm()
      if (result) {
        this.$router.push({
          name: 'projectList'
        })
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.templateId = String(to.params.id)
      vm.loadingPage = true

      await vm.$waitConnected()
      await vm.checkProjectTemplateExist()
      await vm.loadTemplateDetail()
      await vm.initTemplateInfo()

      vm.loadingPage = false
      vm.loadingDatasets = true

      if (vm.datasetId?.length > 0) {
        await vm.loadDataset({ datasetId: vm.datasetId })
        vm.makeDatasetTable()
      }

      vm.loadingDatasets = false
    })
  },
  computed: {
    ...mapGetters('datasets', ['datasetList']),
    ...mapGetters('project', ['projectList']),
    ...mapGetters('projectTemplate', ['createdProject']),
    ...mapGetters('projectTemplate', {
      projectTemplateList: 'projectList'
    }),
    ...mapGetters('settings', ['rowsPerPage']),

    headerTabs() {
      return {
        // ヘッダーのタブ
        tabs: [],
        tabSelect: 1
      }
    },
    sidebar() {
      return {
        // サイドバーに表示する情報
        project: {
          name: '退職予測',
          description:
            'このプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入ります',
          template: '退職予測テンプレート',
          dataset: 'データセット名が入ります',
          target: '予測する列名が入ります',
          preprocessingDataset: '前処理後のデータセットが入ります',
          recipe: '使用するレシピ名が入ります'
        },
        activeLink: 'projectTemplate',
        status: 'confirm'
      }
    },
    leavePage() {
      return this.$t('common.trainLeave.titles.' + this.leavePageName)
    },
    currentPage() {
      return this.$t('common.trainLeave.titles.' + this.currentPageName)
    }
  },
  data() {
    return {
      data: {
        projectDetail: {
          id: null,
          title: '',
          summary: {
            description: '',
            tags: [],
            goal: '',
            target: '',
            industry: ''
          },
          expectedEffect: null,
          sampleDataset: {
            tableColumns: null,
            table: null,
            statisticsColumns: null,
            statistics: null,
            labels: null,
            datas: null,
            type: null,
            unstructuredType: null,
            graph: null
          }
        }
      },
      disabledClick: false,
      loadingPage: false,
      loadingDatasets: false,
      datasetId: null,
      datasetInfo: {},
      templateId: null,
      popup: {
        createInfo: {
          projectFormValidate: {
            duplicate: undefined
          }
        },
        projectTitle: null,
        showPopup: []
      },
      detail: null,
      isPrevent: false,
      correctTransition: false,
      currentPageName: this.$route.name,
      leavePageName: null
    }
  },
  methods: {
    ...mapActions('datasets', ['loadDatasetDetail']),
    ...mapActions('project', ['loadProjectDetail', 'loadProjectList']),
    ...mapActions('projectTemplate', ['cloneProjectTemplate']),
    ...mapActions('projectTemplate', {
      loadProjectTemplateList: 'loadProjectList'
    }),
    ...mapActions('models', ['setProgressTraining']),

    showPopup(e) {
      // ポップアップを表示
      if (e === 'projectSetting') {
        this.popup.projectTitle = this.checkProjectTitle()
      }
      if (this.popup.showPopup.length > 0) {
        this.popup.showPopup = []
        this.popup.showPopup.push(e)
      } else {
        this.popup.showPopup.push(e)
      }
    },
    checkProjectTemplateExist: async function () {
      if (
        !this.projectTemplateList ||
        Object.keys(this.projectTemplateList).length === 0
      ) {
        await this.loadProjectTemplateList()
      }

      const keys = Object.keys(this.projectTemplateList)
      const templateIdList = keys.map((key) => this.projectTemplateList[key].id)
      const templateId = this.templateId
      const isExist = templateIdList.includes(Number(templateId))

      if (!isExist) {
        this.popup.showPopup.push('templateNotFound')
      }
    },
    closePopup(e) {
      // ポップアップを閉じる
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
    },
    /**
     *   Projectの新規作成
     *   プロジェクト作成完了後データセット設定画面へ遷移
     *   必要であればローディングのフラグを作成して渡してローディング表示
     **/
    createProject: async function ({ name, description, tags }) {
      const params = {
        templateId: this.templateId,
        revision: this.detail.revision,
        name,
        description,
        tags
      }
      try {
        this.disabledClick = true
        await this.cloneProjectTemplate(params)
        await this.loadProjectList()
        this.correctTransition = true

        this.setProgressTraining({
          item: this.createdProject.projectId,
          setType: 'project'
        })

        this.$gtmDataLayer.sendEvent('createProject', 'projectTemplate')
        this.$gtmDataLayer.sendEvent(
          'selectProjectTemplate',
          this.data.projectDetail.title
        )
        this.$router.push({
          name: 'datasetSetting',
          params: { projectId: this.createdProject.projectId }
        })
      } catch (err) {
        this.disabledClick = false
        this.log_info(err)
      }
    },
    checkProjectTitle() {
      const projectTemplate = this.detail
      let projectTitle = projectTemplate.name
      const projectTitleValidate = projectValidator(
        this.projectList,
        projectTemplate
      )
      if (!projectTitleValidate?.duplicate) {
        projectTitle = projectTitleValidate?.suggests[0]
      }
      return projectTitle
    },
    changeStatus(status) {
      this.sidebar.status = status
    },
    initTemplateInfo: function () {
      this.data.projectDetail = Object.assign(this.data.projectDetail, {
        id: this.templateId,
        title: this.detail.name,
        summary: {
          description: this.detail.description,
          tags: this.detail.tags,
          goal: this.detail.wantToAchieve,
          target: this.detail.predictedAndClassified,
          industry: this.detail.industryOccupation,
          thumbnailUrl: this.detail?.thumbnailUrl
        },
        expectedEffect:
          this.detail.effects.length > 0 ? this.detail.effects[0] : null,
        sampleDataset: {}
      })
    },
    inputCreateForm: function (obj) {
      const { type, form } = obj

      if (type === 'project') {
        this.popup.createInfo.projectFormValidate = projectValidator(
          this.projectList,
          form
        )
      }
    },
    loadDataset: async function ({ datasetId }) {
      try {
        let data = {}

        const req = {
          action: 'getData',
          accountId: '__TEMPLATES__',
          dataId: datasetId,
          limit: this.rowsPerPage,
          offset: 0
        }
        const res = await this.$sendMessageAndReceive(req)

        if (res.detail) {
          data = Object.assign(data, res.detail)
          data.detailLoaded = true
          data.table = res.list
          if (data.storage === 'db') data.describe = res.describe

          this.datasetInfo = data
          this.data.projectDetail.sampleDataset.type = data.type
        }
      } catch (err) {
        this.log_info(err)
      }

      if (this.datasetInfo.type !== 'structured') {
        let data = this.datasetInfo

        const req = {
          action: 'getDataSample',
          dataId: datasetId,
          accountId: '__TEMPLATES__',
          offset: 0,
          limit: 6
        }

        try {
          const res = await this.$sendMessageAndReceive(req)

          if (res) {
            data = Object.assign(data, {
              datasetType: res.data_type,
              datas: res.labels
            })
            data.detailLoaded = true
          }

          this.datasetInfo = data
        } catch (err) {
          this.log_info(err)
        }
      }
    },
    loadTemplateDetail: async function () {
      try {
        const response = await this.$sendMessageAndReceive({
          action: 'detailProjectTemplate',
          templateId: this.templateId
        })

        if (response.status !== 'error') {
          this.detail = response.result
          this.data.projectDetail = this.detail.project

          this.datasetId = this.detail.sampleDataset.id
        } else {
          throw response
        }
      } catch (err) {
        this.log_info(err)
      }
    },
    makeDatasetTable: function () {
      this.data.projectDetail.sampleDataset.name = this.datasetInfo.name
      this.data.projectDetail.sampleDataset.description =
        this.datasetInfo.description

      if (this.data.projectDetail.sampleDataset.type === 'structured') {
        const datasetTable = this.datasetInfo.table
        const columns = Object.keys(datasetTable[0])

        this.data.projectDetail.sampleDataset.tableColumns = columns
        this.data.projectDetail.sampleDataset.table = datasetTable

        const statisticalData = this.datasetInfo.describe

        this.data.projectDetail.sampleDataset.statisticsColumns = ['/'].concat(
          Object.keys(datasetTable[0])
        )
        this.data.projectDetail.sampleDataset.statistics = statisticalData
        this.data.projectDetail.sampleDataset.graph = this.datasetInfo.graph
      } else {
        this.data.projectDetail.sampleDataset.datas = this.datasetInfo.datas
        this.data.projectDetail.sampleDataset.type = this.datasetInfo.type
        this.data.projectDetail.sampleDataset.unstructuredType =
          this.datasetInfo.datasetType
      }
    },
    checkPrevent(nextPageName) {
      return (
        !checkOuterTraining(nextPageName) &&
        !checkCorrectBackPage('project', nextPageName) &&
        !this.correctTransition
      )
    },
    closePrevent() {
      this.$refs.preventLeave.closePopup()
    },
    pageBack() {
      this.$router.push({
        name: 'projectTemplateList'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.prevent-leave-text {
  display: flex;
  flex-direction: column;
  grid-row-gap: $space-sub;
  ::v-deep {
    .prevent-leave-text-emphasis {
      color: $text-decoration;
    }
    .prevent-leave-text-caution {
      color: $text-caution;
    }
  }
}
</style>
