<template>
  <div class="samples-wrap">
    <list-item-slot
      class="samples-list-body"
      :field="field[type]"
      :items="items"
      :class="{ 'samples-list-body-texts': type === 'texts' }"
    >
      <template v-slot:name="entry">
        <texts :text="entry.item" size="small" />
      </template>
      <template v-slot:count="entry">
        <div class="samples-item-count-wrap">
          <texts
            class="samples-item-count-text"
            :text="entry.item"
            size="small"
          />
          <texts
            class="samples-item-count-percentage"
            :text="Math.ceil((entry.item / maxCount) * 100) + '%'"
            size="min"
            color="gray"
          />
        </div>
        <graph-bar-count
          class="samples-item-bar"
          :count="entry.item"
          :maxCount="maxCount"
        />
      </template>
      <template v-slot:sample="entry">
        <template v-if="type === 'images'">
          <div class="samples-item-image-wrap" @mouseleave="closePopup">
            <button
              v-for="(data, index) in entry.item"
              :key="index"
              class="samples-item-image-inner"
              @click="showPopup(data.label, index)"
            >
              <image-wrap class="samples-item-image-item" :url="data.body" />
              <popup-balloon-image
                class="samples-item-balloon"
                :showPopup="popupId === index && popupLabel === data.label"
                :posRight="index > 1"
                :posBottom="
                  labels.length - labels.indexOf(data.label.toString()) < 3 &&
                    labels.length > 3
                "
                :popupContents="data"
              />
            </button>
            <!--  -->
          </div>
        </template>
        <template v-else>
          <div class="samples-item-text-wrap" @mouseleave="closePopup">
            <button
              v-for="(data, index) in entry.item"
              :key="index"
              class="samples-item-text-inner"
              @click="showPopup(data.label, index)"
            >
              <texts class="samples-item-text-item" :text="data.body" noTips />
              <popup-balloon-text
                class="samples-item-balloon"
                :showPopup="popupId === index && popupLabel === data.label"
                :posBottom="
                  labels.length - labels.indexOf(data.label) < 3 &&
                    labels.length > 3
                "
                :popupContents="data"
              />
            </button>
          </div>
        </template>
      </template>
    </list-item-slot>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import listItemSlot from '@/components/molecules/list-item-slot.vue'
import GraphBarCount from '@/components/atoms/graph-bar-count.vue'
import ImageWrap from '@/components/atoms/image-wrap.vue'
import popupBalloonImage from '@/components/molecules/popup-balloon-image'
import popupBalloonText from '@/components/molecules/popup-balloon-text'

export default {
  components: {
    texts,
    listItemSlot,
    GraphBarCount,
    ImageWrap,
    popupBalloonImage,
    popupBalloonText
  },
  data() {
    return {
      popupId: null,
      popupLabel: null
    }
  },
  computed: {
    field() {
      return {
        images: [
          {
            key: 'name',
            label: this.$t('datasetDetail.table.labels'),
            width: 178
          },
          {
            key: 'count',
            label: this.$t('datasetDetail.table.count'),
            width: 178
          },
          {
            key: 'sample',
            label: this.$t('datasetDetail.table.image'),
            width: 1212
          }
        ],
        texts: [
          {
            key: 'name',
            label: this.$t('datasetDetail.table.labels'),
            width: 178
          },
          {
            key: 'count',
            label: this.$t('datasetDetail.table.count'),
            width: 178
          },
          {
            key: 'sample',
            label: this.$t('datasetDetail.table.text'),
            width: 1212 - 24 - 24
          }
        ]
      }
    },
    labels() {
      if (!this.datas) return null
      return Object.keys(this.datas)
    },
    items() {
      if (!this.labels) return null
      const items = []
      for (const key of this.labels) {
        items.push({
          name: key,
          count: this.datas[key].count,
          sample: this.datas[key].datas
        })
      }
      return items
    },
    maxCount() {
      if (!this.items) return null
      let maxCount = 0
      for (const value of this.items) {
        maxCount = maxCount + value.count
      }
      return maxCount
    }
  },
  methods: {
    showPopup(label, index) {
      if (this.popupId !== index) {
        this.popupId = null
        this.popupLabel = null
        this.popupId = index
        this.popupLabel = label
      }
    },
    closePopup() {
      this.popupId = null
      this.popupLabel = null
    }
  },
  props: {
    /** getDataSamplesで取得したlabels */
    datas: Object,
    /** images or texts */
    type: String
  }
}
</script>

<style lang="scss" scoped>
.samples {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-top {
    flex-shrink: 0;
    padding: 0 0 $space-small;
    margin: 0 0 $space-small;
    border-bottom: $border-sub;
    &-title {
      margin: 0 0 $space-small;
    }
  }
  &-inner {
    display: flex;
    &-item {
      margin: 0 $space-medium 0 0;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  &-list {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 1;
    height: 100%;
    &-body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      ::v-deep .list-item-entry-list {
        overflow-y: scroll;
        /* stylelint-disable-next-line declaration-no-important */
        height: 100% !important;
        padding: 0 $space-base 0 0;
        @include scrollbar;
      }
      ::v-deep .list-item-entry-inner {
        overflow: visible;
      }
      ::v-deep .list-item-key-block {
        padding: 0 $space-large 0 $space-sub;
      }
    }
  }
  &-item {
    &-count {
      &-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 $space-base;
      }
      &-percentage {
        flex-shrink: 0;
      }
    }
    &-bar {
      z-index: 1;
    }
    &-balloon {
      top: calc(100% + #{$space-sub});
      &.wrap-bottom {
        top: auto;
        bottom: calc(100% + #{$space-sub});
      }
    }
    &-image {
      &-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      &-inner {
        position: relative;
        width: adjustVW(178);
        height: adjustVW(100);
        &:hover {
          opacity: 1;
        }
      }
      &-item {
        position: relative;
        z-index: 0;
      }
    }
    &-text {
      &-wrap {
        width: calc(100% - #{adjustVW(8)}); // スクロールバー分調整
        margin-left: auto;
      }
      &-inner {
        position: relative;
        display: block;
        width: 100%;
        margin: 0 0 $space-base;
        &:hover {
          opacity: 1;
        }
      }
      &-item {
        overflow: hidden;
        width: 100%;
        z-index: -1;
      }
    }
  }
}
</style>
