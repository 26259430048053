<template>
  <div
    class="c-card"
    :class="['c-card-' + this.row, { 'c-card-active': isActive }]"
    @click="$emit('click', $event)"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    /** 横に表示する枚数 */
    row: {
      type: Number,
      default: 3,
      require: false
    },
    /** 未使用のため削除予定 */
    isMenu: {
      type: Boolean,
      default: false,
      require: false
    },
    /** 活性化常態か否か */
    isActive: {
      type: Boolean,
      default: false,
      require: false
    },
    /** 未使用のため削除予定 */
    menus: {
      type: Array,
      require: false
    }
  },
  data() {
    return {
      menuOpen: false
    }
  },
  methods: {
    active: function () {
      this.menuOpen = true
      this.$emit('update:isActive', !this.isActive)
    }
  }
}
</script>

<style lang="scss" scoped>
.c-card {
  position: relative;
  padding: $space-small;
  border: 2px solid transparent;
  // border: $border-sub;
  background: $background;
  border-radius: adjustVW(16);
  cursor: pointer;
  box-shadow: $box-shadow-main;
  transition: $transition-base;
  @include cardHover;
  @for $row from 1 through 6 {
    $nth: #{$row} + 'n';
    &-#{$row} {
      width: (
          100vw -
            adjustVW(240) -
            $space-medium *
            ($row - 1) -
            $space-medium *
            2 -
            $space-medium
        ) /
        $row;
    }
  }
  &:last-child {
    margin: 0;
  }
  &-menu {
    position: absolute;
    right: $space-base;
    bottom: $space-small;
    opacity: 0;
    transition: $transition-base;
    .c-card:hover & {
      opacity: 1;
    }
    &.open {
      opacity: 1;
    }
  }
  &-active {
    border: $border-card-emphasis;
    background: $background-decoration;
  }
}
</style>
