<template>
  <div class="result">
    <transition-toggle-contents>
      <div v-if="!showDetail" key="list" class="result-body">
        <card-base class="result-inner">
          <texts
            class="result-title"
            :text="$t('trainedAi.optimization.result.list.title')"
            size="small"
            color="gray"
          />
          <transition-toggle-contents>
            <div
              v-if="optimizationInfo.submitDeletingOptimization"
              key="list-loading"
              class="result-list"
            >
              <loading />
            </div>
            <list-item-slot
              v-else
              key="list"
              class="result-list"
              :field="field"
              :items="optimizationConditions"
              type="button"
              @list-click="clickList"
            >
              <template #name="entry">
                <texts class="result-item-title" :text="entry.item" isBold />
                <description :text="entry.entry.description" :line="2" />
              </template>
              <template #createTime="entry">
                <texts size="small">
                  <a-time :value="entry.item" />
                </texts>
              </template>
              <template #conditions="entry">
                <optimization-conditions-list
                  :optimizationConditions="
                    optimizationConditions[entry.listIndex]
                  "
                />
              </template>
            </list-item-slot>
          </transition-toggle-contents>
        </card-base>
      </div>
      <template v-else>
        <optimization-result-detail
          key="detail"
          class="result-detail"
          :accountInfo="accountInfo"
          :selectedConditions="selectedConditions"
          :optimizationInfo="optimizationInfo"
          :optimizationResult="optimizationResult"
          :showDelete="!isTraining"
          @close-detail="closeDetail"
          @input-edit-form="$emit('input-edit-form', $event)"
          @save-edit-optimization-form="
            $emit('save-edit-optimization-form', $event)
          "
          @show-delete-optimization="$emit('show-delete-optimization')"
          @change-page="$emit('change-page', $event)"
          @change-filter-value="$emit('change-filter-value', $event)"
          @download-result="$emit('download-result', $event)"
        />
      </template>
    </transition-toggle-contents>
  </div>
</template>

<script>
import cardBase from '@/components/atoms/card-base'
import description from '@/components/atoms/description'
import loading from '@/components/atoms/loading'
import listItemSlot from '@/components/molecules/list-item-slot'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import optimizationConditionsList from '@/components/organisms/optimization/optimization-conditions-list'
import optimizationResultDetail from '@/components/organisms/optimization/optimization-result-detail'

export default {
  components: {
    description,
    cardBase,
    loading,
    listItemSlot,
    transitionToggleContents,
    optimizationConditionsList,
    optimizationResultDetail
  },
  data() {
    return {
      showDetail: false
    }
  },
  props: {
    accountInfo: Object,
    optimizationInfo: Object,
    optimizationResult: Object,
    optimizationConditions: Array,
    isTraining: Boolean
  },
  computed: {
    field() {
      return [
        {
          key: 'name',
          label: this.$t('trainedAi.optimization.result.list.name'),
          width: 280
        },
        {
          key: 'createTime',
          label: this.$t('common.createTime'),
          width: 120
        },
        {
          key: 'conditions',
          label: this.$t('trainedAi.optimization.conditions.title'),
          width: 1108
        }
      ]
    },
    selectedConditions() {
      return (
        this.optimizationConditions.find((condition) => {
          return condition.id === this.optimizationResult.optimizationId
        }) ?? {}
      )
    }
  },
  methods: {
    clickList(e) {
      this.showDetail = true
      this.$emit('show-detail-optimization', e.id)
    },
    closeDetail() {
      this.showDetail = false
    }
  },
  watch: {
    'optimizationInfo.submitDeletingOptimization': {
      handler(newVal) {
        if (newVal) {
          this.showDetail = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.result {
  overflow: hidden;
  height: 100%;
  &-title {
    flex-shrink: 0;
    margin-bottom: $space-base;
  }
  &-body {
    height: 100%;
    padding-bottom: $space-medium;
    margin: 0 $space-medium;
  }
  &-detail {
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-medium;
    @extend .result-body;
  }
  &-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    &-detail {
      height: adjustVW(200);
    }
    &-table {
      height: adjustVW(466);
    }
  }
  &-list {
    overflow-y: scroll;
    height: 100%;
    padding-right: $space-base;
    @include scrollbar;
  }
}
</style>
