import { render, staticRenderFns } from "./recipe-list-top.vue?vue&type=template&id=00ac8d32&scoped=true&"
import script from "./recipe-list-top.vue?vue&type=script&lang=js&"
export * from "./recipe-list-top.vue?vue&type=script&lang=js&"
import style0 from "./recipe-list-top.vue?vue&type=style&index=0&id=00ac8d32&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00ac8d32",
  null
  
)

export default component.exports