var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-base',{staticClass:"text-mining-sidebar"},[_c('div',{staticClass:"text-mining-sidebar-title"},[_c('texts',{attrs:{"text":_vm.$t('trainedAiDetails.featureImportanceTab.togglePage'),"size":"small","color":"gray"}})],1),_c('div',{staticClass:"text-mining-sidebar-body"},[(_vm.checkSentiments || _vm.checkLDA)?_c('div',{staticClass:"text-mining-sidebar-content"},[_c(_vm.checkSentiments ? 'div' : 'button',{tag:"component",staticClass:"text-mining-sidebar-button",class:{
          'text-mining-sidebar-button-active':
            _vm.pageTypeList.TEXT_LIST === _vm.displayType && !_vm.checkSentiments,
          'text-mining-sidebar-button-disabled': _vm.waitTextMiningLoading
        },on:{"click":function($event){_vm.checkSentiments || _vm.waitTextMiningLoading
            ? ''
            : _vm.changeTab(_vm.pageTypeList.TEXT_LIST)}}},[_c('text-with-icon',{attrs:{"text":_vm.$t('textMining.typeList.textList'),"iconName":"textList","size":"small","color":_vm.pageTypeList.TEXT_LIST === _vm.displayType && !_vm.checkSentiments
              ? 'emphasis'
              : 'gray'}})],1),_c('div',{staticClass:"text-mining-sidebar-list"},[(_vm.checkSentiments)?_c('button',{staticClass:"text-mining-sidebar-button",class:{
            'text-mining-sidebar-button-active':
              _vm.pageTypeList.TEXT_LIST === _vm.displayType,
            'text-mining-sidebar-button-disabled': _vm.waitTextMiningLoading
          },on:{"click":function($event){_vm.waitTextMiningLoading ? '' : _vm.changeTab(_vm.pageTypeList.TEXT_LIST)}}},[_c('text-with-icon',{attrs:{"text":_vm.$t('trainedAi.textMining.types.sentiments'),"iconName":"sentiments","size":"small","color":_vm.pageTypeList.TEXT_LIST === _vm.displayType ? 'emphasis' : 'gray'}})],1):_vm._e(),(_vm.checkLDA)?_c('button',{staticClass:"text-mining-sidebar-button",class:{
            'text-mining-sidebar-button-active':
              _vm.pageTypeList.TEXT_TOPIC === _vm.displayType,
            'text-mining-sidebar-button-disabled': _vm.waitTextMiningLoading
          },on:{"click":function($event){_vm.waitTextMiningLoading ? '' : _vm.changeTab(_vm.pageTypeList.TEXT_TOPIC)}}},[_c('text-with-icon',{attrs:{"text":_vm.$t('textMining.typeList.textTopic'),"iconName":"textTopicDetail","size":"small","color":_vm.pageTypeList.TEXT_TOPIC === _vm.displayType ? 'emphasis' : 'gray'}})],1):_vm._e()])],1):_vm._e(),(_vm.checkLDA)?_c('div',{staticClass:"text-mining-sidebar-content"},[_c('button',{staticClass:"text-mining-sidebar-button",class:{
          'text-mining-sidebar-button-active':
            _vm.pageTypeList.TEXT_TOPIC_DETAIL === _vm.displayType,
          'text-mining-sidebar-button-disabled': _vm.waitTextMiningLoading
        },on:{"click":function($event){_vm.waitTextMiningLoading
            ? ''
            : _vm.changeTab(_vm.pageTypeList.TEXT_TOPIC_DETAIL)}}},[_c('text-with-icon',{attrs:{"text":_vm.$t('textMining.typeList.textTopicDetail'),"iconName":"textTopic","size":"small","color":_vm.pageTypeList.TEXT_TOPIC_DETAIL === _vm.displayType
              ? 'emphasis'
              : 'gray'}})],1)]):_vm._e(),(_vm.checkCoOccurrence)?_c('div',{staticClass:"text-mining-sidebar-content"},[_c('button',{staticClass:"text-mining-sidebar-button",class:{
          'text-mining-sidebar-button-active':
            _vm.pageTypeList.CO_OCCURRENCE === _vm.displayType,
          'text-mining-sidebar-button-disabled': _vm.waitTextMiningLoading
        },on:{"click":function($event){_vm.waitTextMiningLoading ? '' : _vm.changeTab(_vm.pageTypeList.CO_OCCURRENCE)}}},[_c('text-with-icon',{attrs:{"text":_vm.$t('trainedAi.textMining.types.coOccurrence'),"iconName":"coOccurrence","size":"small","color":_vm.pageTypeList.CO_OCCURRENCE === _vm.displayType ? 'emphasis' : 'gray'}})],1)]):_vm._e(),(_vm.checkWordCloud || _vm.checkFrequencies)?_c('div',{staticClass:"text-mining-sidebar-content"},[_c('button',{staticClass:"text-mining-sidebar-button",class:{
          'text-mining-sidebar-button-active':
            _vm.pageTypeList.FREQUENCIES_WORDCLOUD === _vm.displayType,
          'text-mining-sidebar-button-disabled': _vm.waitTextMiningLoading
        },on:{"click":function($event){_vm.waitTextMiningLoading
            ? ''
            : _vm.changeTab(_vm.pageTypeList.FREQUENCIES_WORDCLOUD)}}},[_c('text-with-icon',{attrs:{"text":_vm.$t('textMining.typeList.wordcroudFrequencies'),"iconName":"frequenciesWordcloud","size":"small","color":_vm.pageTypeList.FREQUENCIES_WORDCLOUD === _vm.displayType
              ? 'emphasis'
              : 'gray'}})],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }