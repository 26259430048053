<template>
  <div class="setting-wrap">
    <button class="setting-title" @click="$emit('close-table')">
      <text-with-icon
        :text="$t('datasetList.popup.addDataset.backToDatasource')"
        iconName="backward"
        size="small"
      />
    </button>
    <div class="setting-content setting-content-shrink">
      <template v-if="isAddTable">
        <datasource-import-from-table
          v-model="newData"
          :datasourceItemsInfo="datasourceItemsInfo"
          :loadingdatasourceItemsInfo="loadingdatasourceItemsInfo"
          :maxRows="maxRows"
        />
      </template>
      <template v-else-if="isEditSql">
        <datasource-import-from-sql
          v-model="newData.import_query"
          :datasourceItemsInfo="datasourceItemsInfo"
        />
      </template>
    </div>
    <div class="setting-content">
      <input-box-validation
        v-model="newData.name"
        isNameValidation
        popupView
        :accept="validate.accept"
        :error="validate.error"
        :nameValidationSuggests="datasetFormValidate.suggests"
        :title="$t('datasetList.datasetList.datasetName')"
        :placeholder="$t('datasetList.popup.addDataset.datasetNamePlaceholder')"
      />
    </div>
    <div class="setting-content">
      <input-box
        v-model="newData.description"
        :title="$t('datasetList.popup.addDataset.description')"
        :placeholder="$t('datasetList.popup.addDataset.descriptionPlaceholder')"
        isTextArea
        isGray
      />
    </div>
    <div class="setting-content">
      <button-large
        :text="$t('datasetList.popup.showDatasource.submit')"
        :isDisabled="disabledSubmit"
        @click="doImport"
      />
    </div>
  </div>
</template>

<script>
import buttonLarge from '@/components/atoms/button-large'
import inputBox from '@/components/molecules/input-box'
import inputBoxValidation from '@/components/molecules/input-box-validation'
import textWithIcon from '@/components/molecules/text-with-icon'
import datasourceImportFromTable from './datasource-import-from-table.vue'
import datasourceImportFromSql from './datasource-import-from-sql.vue'

export default {
  components: {
    buttonLarge,
    inputBox,
    inputBoxValidation,
    textWithIcon,
    datasourceImportFromTable,
    datasourceImportFromSql
  },
  props: {
    datasourceId: Number,
    datasetFormValidate: Object,
    importLoading: Boolean,
    disableClick: Boolean,
    datasourceItemsInfo: Object,
    loadingdatasourceItemsInfo: Boolean,
    maxRows: Number,
    isAddTable: Boolean,
    isEditSql: Boolean,
    showSqlValue: Number,
    dataSourceSqlItems: Array,
    importFromDataSourceTableName: String
  },
  data() {
    return {
      newData: {
        checked: [],
        name: '',
        description: '',
        columns: null,
        import_query: ''
      },
      validate: {
        accept: null,
        error: null
      }
    }
  },
  computed: {
    disabledSubmit: function () {
      if (this.validate.error) return true
      if (this.importLoading || this.disableClick) return true

      if (this.isAddTable) {
        return this.newData.name === '' || this.newData.checked.length === 0
      } else {
        return (
          this.newData.name === '' || this.newData.import_query.length === 0
        )
      }
    }
  },
  watch: {
    newData: {
      deep: true,
      handler: function (form) {
        this.$emit('input-create-form', {
          form,
          type: 'dataset'
        })
      }
    },
    datasetFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    }
  },
  methods: {
    doImport() {
      const payload = JSON.parse(JSON.stringify(this.newData))
      payload.id = this.datasourceId

      if (this.isAddTable) {
        payload.import_query = null
        payload.table = this.importFromDataSourceTableName
      } else if (this.isEditSql) {
        payload.checked = null
      }

      this.$emit('do-import', payload)
    }
  },
  mounted() {
    if (this.isEditSql && this.showSqlValue != null) {
      this.newData.import_query =
        this.dataSourceSqlItems[this.showSqlValue].import_sql
    }
  }
}
</script>

<style lang="scss" scoped>
.setting {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-title {
    margin: 0 0 $space-small;
    background: $background;
  }
  &-content {
    &:not(:last-of-type) {
      margin-bottom: $space-medium;
    }
    &-shrink {
      overflow: hidden;
      flex-shrink: 1;
      height: 100%;
    }
  }
}
</style>
