<template>
  <div class="wrap">
    <div class="item-wrap">
      <list-item :items="targets" :field="field" isToggle>
        <template v-slot:toggleContent="{ entry }">
          <div class="item-info">
            <div class="item-block">
              <div class="item-inner">
                <div class="item-type">
                  <datasource-type-wrap :type="entry.dbtype" />
                </div>
              </div>
              <div class="item-inner">
                <text-with-title
                  class="item-text"
                  :text="entry.port"
                  :title="$t('datasetList.popup.addDataset.datasource.port')"
                  isShowAll
                />
                <text-with-title
                  class="item-text"
                  :text="entry.database"
                  :title="$t('datasetList.popup.addDataset.datasource.dbName')"
                  isShowAll
                />
              </div>
            </div>
            <div class="item-block">
              <text-with-title
                class="item-host"
                :text="entry.host"
                :title="$t('datasetList.popup.addDataset.datasource.host')"
                isShowAll
              />
            </div>
          </div>
        </template>
      </list-item>
    </div>
    <div class="footer">
      <div class="button">
        <button-main
          type="sub"
          :text="$t('common.cancel')"
          :disabled="disableClick"
          @click="$emit('close-modal')"
        />
      </div>
      <div class="button">
        <button-main
          type="caution"
          :text="$t('common.deleteButton')"
          :disabled="disableClick"
          @click="deleteDatasorce"
        />
      </div>
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import datasourceTypeWrap from '@/components/atoms/datasource-type-wrap'
import textWithTitle from '@/components/molecules/text-with-title'
import listItem from '@/components/molecules/list-item'

export default {
  components: {
    datasourceTypeWrap,
    buttonMain,
    textWithTitle,
    listItem
  },
  data() {
    return {
      field: [
        {
          label: this.$t('datasetList.popup.deleteData.field'),
          key: 'name'
        }
      ]
    }
  },
  props: {
    targets: Array,
    disableClick: Boolean
  },
  methods: {
    deleteDatasorce() {
      this.$emit('delete-datasource', this.targets)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: adjustVW(720);
  height: 100%;
  margin: $space-base 0 0;
}
.item {
  &-wrap {
    overflow-y: scroll;
    padding: 0 $space-small 0 0;
    margin: 0 0 $space-medium;
    @include scrollbar;
  }
  &-block {
    display: flex;
    width: 100%;
    margin: 0 0 $space-small;
    &:last-child {
      margin: 0;
    }
  }
  &-inner {
    margin: 0 $space-medium 0 0;
    &:last-child {
      margin: 0;
    }
  }
  &-text {
    margin: 0 0 $space-sub;
    &:last-child {
      margin: 0;
    }
  }
  &-type {
    display: flex;
    align-items: center;
    justify-content: center;
    width: adjustVW(120);
    height: adjustVH(80);
    background: $background-sub;
  }
}
.footer {
  display: flex;
  justify-content: flex-end;
}
.button {
  margin: 0 $space-medium 0 0;
  &:last-of-type {
    margin: 0;
  }
}
</style>
