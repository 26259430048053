<script>
import * as d3 from 'd3'
import { truncateLabel } from '@/lib/truncate-label'
import Basis from './Basis'

function sortKeys(obj) {
  const sortedKeys = Object.keys(obj).sort(d3.ascending)
  const res = {}
  for (const k of sortedKeys) {
    res[k] = obj[k]
  }
  return res
}

// eslint-disable-next-line no-unused-vars
function transposeObj(obj) {
  const res = {}
  for (const k1 in obj) {
    for (const k2 in obj[k1]) {
      if (res[k2] === undefined) {
        res[k2] = {}
      }
      res[k2][k1] = obj[k1][k2]
    }
  }
  return res
}

export default {
  mixins: [Basis],
  name: 'Axis',
  props: {
    data: {},
    graphMargin: {
      default: () => ({ top: 40, right: 20, bottom: 20, left: 60 })
    },
    horizontal: {
      type: Boolean,
      default: true
    },
    sorted: {
      type: Boolean,
      default: false
    },
    categoryName: { default: '' },
    autoResize: { type: Boolean, default: true },
    locale: { type: String, default: navigator.language }
  },
  mounted() {
    this.initializeAxis()
  },
  data() {
    return {
      zoomRatio: 1.0,
      resizeCooltime: false,
      currentBBox: null,

      root: null,
      gx: null,
      gy: null,
      colorScale: null,
      gyWidth: 0,
      resizeInterval: null,

      outerHeight: 0,
      outerWidth: 0
    }
  },
  computed: {
    Lmargin() {
      return Math.max(this.gyWidth, this.graphMargin.left)
    },
    graphHeight() {
      const outerHeight = this.outerHeight
      const margin = this.graphMargin
      return outerHeight - margin.top - margin.bottom
    },
    graphWidth() {
      const outerWidth = this.outerWidth
      const margin = this.graphMargin
      return outerWidth - this.Lmargin - margin.right
    },
    categories() {
      if (this.data instanceof Array) {
        return this.data.map((d) => d.name)
      } else {
        return this.data.name ? [this.data.name] : [this.categoryName]
      }
    },
    realColor() {
      if (!this.colorScale) return null
      const data = this.data instanceof Array ? this.data : [this.data]
      const colorList = []
      for (const d of data) {
        if (d.color) {
          colorList[d.name] = d.color
        }
      }
      const vueThis = this
      const res = function (x) {
        return colorList[x] || vueThis.colorScale(x)
      }
      Object.assign(res, this.colorScale)
      return res
    },
    valueGroupedByX() {
      /**
       * return {
       *  1 : { hoge : 7 , fuga : 9 }
       *  2 : { hoge : 8 }
       *  3 : { fuga : 9 }
       * }
       */
      let res = {}
      if (this.data instanceof Array) {
        for (const k1 in this.data) {
          for (const k2 in this.data[k1].values) {
            if (res[k2] === undefined) {
              res[k2] = {}
            }
            res[k2][this.data[k1].name] = this.data[k1].values[k2]
          }
        }
      } else {
        for (const k2 in this.data.values) {
          if (res[k2] === undefined) {
            res[k2] = {}
          }
          res[k2][this.data.name] = this.data.values[k2]
        }
      }
      if (this.sorted) {
        res = sortKeys(res)
      }
      return res
    },
    stackValue() {
      const vueThis = this
      const stack = d3
        .stack()
        .keys(this.categories)
        .offset(d3.stackOffsetDiverging)
      const stackValue = stack(Object.values(this.valueGroupedByX))
      const totals = Object.values(this.valueGroupedByX).map((d) =>
        d3.sum(Object.values(d))
      )
      stackValue.forEach(function (x, i) {
        x.name = x.key
        x.forEach(function (y, j) {
          y.key = vueThis.stackLabel[j]
          y.value = y.data[x.key]
          y.total = totals[j]
          y.category = vueThis.categories[i]
        })
      })
      return stackValue
    },
    stackLabel() {
      const stackLabel = Object.keys(this.valueGroupedByX)
      return stackLabel
    }
  },
  methods: {
    initializeAxis() {
      const el = this.$refs.graph
      this.outerHeight = el.clientHeight
      this.outerWidth = el.clientWidth
      this.currentBBox = { height: this.graphHeight, width: this.graphWidth }
      const margin = this.graphMargin

      const root = this.svg
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
      this.root = root

      // ここのtransformの位置を左端にして、この横幅分だけ、SVGの描画領域を狭めたら、どんな値が入っても崩れなくなるのでは？現状のmarginを数値指定するのよりはましかも
      this.gx = root
        .append('g')
        .classed('x axis d3-axis', true)
        .attr('transform', `translate(0,${this.graphHeight})`)

      this.gy = root.append('g').classed('y axis d3-axis', true)

      this.gx
        .append('text')
        .classed('label', true)
        .attr('x', this.graphWidth)
        .attr('y', margin.bottom - 10)
        .style('text-anchor', 'end')

      this.gy
        .append('text')
        .classed('label', true)
        .attr('transform', 'rotate(-90)')
        .attr('y', -margin.left)
        .attr('dy', '.71em')
        .style('text-anchor', 'end')

      this.body = root.append('g').classed('objects', true)

      this.recalcAxis()
      this.drawGraph()
    },
    resized() {
      const el = this.$refs.graph
      if (!el) return
      this.outerHeight = el.clientHeight
      this.outerWidth = el.clientWidth
      const newBB = { height: this.graphHeight, width: this.graphWidth }
      if (
        this.currentBBox.width !== newBB.width ||
        this.currentBBox.height !== newBB.height
      ) {
        this.currentBBox = newBB
        this.recalcAxis()
        this.drawGraph()
      }
    },
    valueRange(
      data,
      { isStack = false, marginratio = 0.1, mustContainZero = false } = {}
    ) {
      const vs = Object.values(this.valueGroupedByX)
      if (isStack) {
        const totalsPosi = vs.map((d) =>
          d3.sum(Object.values(d), (x) => (x > 0 ? x : 0))
        )
        const totalsNega = vs.map((d) =>
          d3.sum(Object.values(d), (x) => (x < 0 ? x : 0))
        )
        let mx = d3.max(totalsPosi)
        let mn = d3.min(totalsNega)
        const ex = (mx - mn) * marginratio
        if (mustContainZero) {
          mx = mx > 0 ? mx + ex : 0
          mn = mn < 0 ? mn - ex : 0
          return [mn, mx]
        } else {
          return [mn - ex, mx + ex]
        }
      } else {
        let mx = d3.max(vs, (x) => d3.max(Object.values(x)))
        let mn = d3.min(vs, (x) => d3.min(Object.values(x)))
        const ex = (mx - mn) * marginratio
        if (mustContainZero) {
          mx = mx > 0 ? mx + ex : 0
          mn = mn < 0 ? mn - ex : 0
          return [mn, mx]
        } else {
          return [mn - ex, mx + ex]
        }
      }
    },
    recalcAxis() {
      const width = this.graphWidth
      const height = this.graphHeight

      this.colorScale = d3
        .scaleOrdinal()
        .domain(this.categories)
        .range(d3.schemeCategory10)

      const xAxis = d3.axisBottom(this.xScale).tickSize(-height)
      const yAxis = d3.axisLeft(this.yScale).tickSize(-width)

      this.xAxis = xAxis
      this.yAxis = yAxis
      this.body.attr('width', this.graphWidth).attr('height', this.graphHeight)
      this.gx.attr('transform', `translate(0,${this.graphHeight})`)
    },
    drawAxes() {
      const vueThis = this
      function adjustGraphPos() {
        let gyWidth = 10
        vueThis.gy.selectAll('text').each(function () {
          if (this.getBBox().width > gyWidth) gyWidth = this.getBBox().width
        })
        vueThis.gyWidth = gyWidth + 15
        vueThis.root
          .transition()
          .duration(vueThis.transitionDuration)
          .attr(
            'transform',
            'translate(' + vueThis.Lmargin + ',' + vueThis.graphMargin.top + ')'
          )
      }
      const locale = this.locale
      function omitLabels(axis, graphWidth, letterWidth, verticalLabel) {
        const scale = axis.scale()
        /** @type {Array<any>} */
        const ticks = scale.ticks ? scale.ticks() : scale.domain()
        const maxLabelLength = verticalLabel
          ? 4
          : Math.min(
              15,
              Math.max(5, Math.floor(graphWidth / letterWidth / ticks.length))
            )
        const formatter = truncateLabel(maxLabelLength, locale)
        axis.tickFormat(formatter)
        /** max string length of labels */
        const maxLen = verticalLabel
          ? 1
          : Math.min(
              maxLabelLength,
              ticks
                .map((v) => String(v).length)
                .reduce((x, y) => Math.max(x, y))
            )
        if (ticks.length * maxLen * letterWidth > graphWidth) {
          const fstep = Math.ceil(
            (ticks.length * maxLen * letterWidth) / graphWidth
          )
          let foffset = ticks.indexOf(0)
          if (foffset === -1) foffset = 0
          const _mod = (x, y) => (x > 0 ? x % y : -(-x % y))
          axis.tickValues(
            ticks.filter(function (d, i) {
              return _mod(i - foffset, fstep) === 0
            })
          )
        }
      }
      omitLabels(this.xAxis, this.graphWidth, 10, false)
      omitLabels(this.yAxis, this.graphHeight, 30, true)
      this.gx.transition().duration(vueThis.transitionDuration).call(this.xAxis)
      this.gy
        .transition()
        .duration(vueThis.transitionDuration)
        .call(this.yAxis)
        .on('end', adjustGraphPos)
    }
  }
}
</script>
<style scoped>
.scatter-plot {
  min-width: 100px;
  min-height: 100px;
}
</style>
<style lang="scss">
.scatter-tip-image {
  max-width: 200px;
  max-height: 200px;
}
.tick text {
  color: $text-sub;
  font-size: 1.2rem;
}
</style>
