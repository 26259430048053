<template>
  <card-base class="card-setting-navi">
    <texts
      class="setting-navi-title"
      color="gray"
      :text="
        !checkTypeOptimization
          ? $t('inference.settings.navi.title')
          : $t('inference.optimization.setting')
      "
    />
    <navi-flow
      :noPreprocess="noPreprocess"
      :choosedItems="choosedItems"
      :activeMenu="activeMenu"
      :availableSteps="availableSteps"
      :preprocessingAvailable="preprocessingAvailable"
      :checkTimeSeries="checkTimeSeries"
      :checkTypeOptimization="checkTypeOptimization"
      :reset="reset"
      @click="selectNaviMenu"
    />
    <div class="run-inference">
      <button-main
        class="run-inference-button"
        type="emphasis"
        :text="
          !checkTypeOptimization
            ? $t('inference.settings.navi.submit')
            : $t('inference.optimization.startOptimization')
        "
        :isDisabled="disableStartInferece || runInference || checkColumns"
        @click="
          !checkTypeOptimization ? startInference($event) : startOptimization()
        "
      />
      <icon-button
        class="run-inference-setting"
        iconName="trainedInfo"
        :isDisabled="disableStartInferece || runInference || checkColumns"
        disabledReason="INFERENCE_SETTING_NOT_AVAILABLE"
        @click="$emit('show-inference-enveiroment-setting')"
      />
    </div>
    <transition-toggle-contents>
      <div
        v-if="inferenceErrors || optimizationErrorMessage"
        class="inference-error"
        name="inferenceError"
      >
        <texts
          class="inference-error-text"
          :text="errorMessage"
          size="min"
          color="caution"
        />
      </div>
      <template v-if="inferenceWarnings">
        <div
          v-for="item in inferenceWarnings"
          :key="item"
          class="inference-error"
          name="inferenceError"
        >
          <texts
            class="inference-error-text"
            :text="getWarningMessage(item)"
            size="min"
            color="caution"
          />
        </div>
      </template>
    </transition-toggle-contents>
    <transition-toggle-contents>
      <div v-if="optimizationErrorManual" class="inference-error-manual">
        <a
          class="inference-error-manual-link"
          :href="
            $t(
              'training.progressStatus.errorManual.' +
                optimizationErrorManual +
                '.link'
            )
          "
          target="_blank"
          rel="noopener noreferrer"
        >
          <texts
            class="inference-error-text"
            :text="
              $t(
                'training.progressStatus.errorManual.' +
                  optimizationErrorManual +
                  '.displayText'
              )
            "
            size="min"
            color="link-caution"
          />
        </a>
      </div>
    </transition-toggle-contents>
    <transition-toggle-contents>
      <div v-if="checkColumns" class="inference-error" name="checkColumn">
        <texts
          class="inference-error-text"
          :text="$t('inference.settings.errors.lackeDatasetColumns')"
          size="min"
          color="caution"
        />
      </div>
    </transition-toggle-contents>
    <transition-toggle-contents>
      <div
        v-if="checkOptimizationError"
        class="inference-error"
        name="checkOptimizationError"
      >
        <texts
          class="inference-error-text"
          :text="checkOptimizationError"
          size="min"
          color="caution"
        />
      </div>
    </transition-toggle-contents>
  </card-base>
</template>

<script>
import buttonMain from '../../../atoms/button-main.vue'
import cardBase from '@/components/atoms/card-base'
import texts from '@/components/atoms/text.vue'
import iconButton from '@/components/atoms/icon-button.vue'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

import naviFlow from '@/components/organisms/inference/menu/navi/navi-flow.vue'

export default {
  components: {
    buttonMain,
    cardBase,
    naviFlow,
    texts,
    iconButton,
    TransitionToggleContents
  },
  props: {
    activeMenu: Number,
    availableSteps: Array,
    preprocessingAvailable: Boolean,
    checkTimeSeries: Boolean,
    disableStartInferece: {
      type: Boolean,
      default: true
    },
    runInference: Boolean,
    inferenceErrors: Object,
    inferenceWarnings: Array,

    lackedColumns: Array,
    lackedPreprocessingColumns: Array,
    selectedItems: Object,
    choosedDatasetInfo: Object,
    selectedTrainedAiType: String,
    disableOptimization: Array,
    optimizationErrorMessage: String,
    optimizationErrorManual: String,
    reset: Boolean
  },
  computed: {
    trainedAi() {
      return this.selectedItems?.trainedAi?.name
    },
    preprocess() {
      return this.selectedItems?.preprocess?.name
    },
    noPreprocess() {
      return this.selectedItems?.noPreprocess
    },
    dataset() {
      return this.choosedDatasetInfo?.name
    },
    choosedItems() {
      return [
        {
          id: 0,
          name: this.trainedAi
        },
        {
          id: 1,
          name: this.preprocess
        },
        {
          id: 2,
          name: this.dataset
        }
      ]
    },
    errorMessage() {
      if (this.optimizationErrorMessage) {
        return this.optimizationErrorMessage
        // MFTransformerV2の周期性がバラバラな場合のエラー
      } else if (
        this.inferenceErrors?.name === 'TIMESERIES_INVALID_TIME_UNIT_ERROR'
      ) {
        return this.$t(
          'training.progressStatus.ERROR_TIMESERIES_INVALID_TIME_UNIT_ERROR',
          {
            unitCountErrorCandidate:
              this.inferenceErrors.detail.unitCountErrorCandidate,
            unitErrorCandidate: this.$t(
              'trainedAi.timeTransformerV2.timeUnit.' +
                this.inferenceErrors.detail.unitErrorCandidate
            ),
            unitCount: this.inferenceErrors.detail.unitCount,
            unit: this.$t(
              'trainedAi.timeTransformerV2.timeUnit.' +
                this.inferenceErrors.detail.unit
            )
          }
        )
      } else if (
        this.$te(
          'inference.result.progressStatus.' + this.inferenceErrors?.name
        )
      ) {
        return this.$t(
          'inference.result.progressStatus.' + this.inferenceErrors.name,
          this.inferenceErrors.detail
        )
      } else {
        return this.$t('inference.result.progressStatus.ERROR_UNKNOWN')
      }
    },
    checkColumns() {
      return (
        this.lackedColumns.length > 0 ||
        this.lackedPreprocessingColumns.length > 0
      )
    },
    checkTypeOptimization() {
      return this.selectedTrainedAiType === 'optimization'
    },
    checkOptimizationError() {
      if (!this.disableOptimization || this.disableOptimization.length === 0)
        return ''
      const checkEmpty = this.disableOptimization.some((reason) => {
        return reason.type === 'notSet'
      })
      const checkError = this.disableOptimization.some((reason) => {
        return reason.type !== 'notSet'
      })
      if (checkEmpty) {
        return this.$t('trainedAi.optimization.error.notSet')
      } else if (checkError) {
        return this.$t('trainedAi.optimization.error.settingError')
      } else {
        return ''
      }
    }
  },
  methods: {
    selectNaviMenu(val) {
      this.$emit('click', { id: val })
    },
    startInference: function (e) {
      this.$emit('start-inference', e)
    },
    startOptimization() {
      this.$emit('start-optimization')
    },
    getWarningMessage(name) {
      return this.$t('inference.result.warnings.' + name)
    }
  }
}
</script>

<style lang="scss" scoped>
.card-setting-navi {
  width: 100%;
  height: 100%;
  padding: $space-small;
}

.run-inference {
  display: grid;
  grid-template-columns: 1fr adjustVW(48);
  grid-column-gap: $space-small;
  &-button {
    width: 100%;
  }
  &-setting {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.setting-navi-title {
  margin-bottom: $space-small;
}

.setting-navi-button-wrapper {
  display: flex;
  flex-direction: column;
}

.setting-navi-button {
  margin-bottom: 0;
}

.inference-error {
  margin-top: $space-medium;
  &-text {
    white-space: pre-line;
  }
  &-manual {
    margin-top: $space-base;
    &-link {
      display: block;
      @include buttonHover;
    }
  }
}
</style>
