var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"service-list-wrap"},[_c('div',{staticClass:"service-list-top"},[_c('service-list-top',{attrs:{"activeTab":_vm.activeTab,"accountInfo":_vm.accountInfo,"accountInfoLoading":_vm.accountInfoLoading,"deleteTest":_vm.deleteTest,"serviceListFiltered":_vm.serviceListFiltered,"checked":_vm.checked},on:{"close-delete-popup":function($event){return _vm.$emit('close-delete-popup')},"cancel-deleted":function($event){return _vm.$emit('cancel-deleted')},"menu-click":function($event){return _vm.menuClick($event)},"tab-changed":function($event){return _vm.changeTab($event)},"input":function($event){return _vm.search($event)}}})],1),_c('div',{staticClass:"service-list-table"},[_c('transition-toggle-contents',[(_vm.activeTab == 'infer')?_c('tab-infer',{attrs:{"option":_vm.option,"projectId":_vm.projectId,"services":_vm.serviceList,"servicesLoading":_vm.servicesLoading},on:{"add-new-service":function($event){return _vm.$emit('add-new-service', {
            type: 'infer'
          })}},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}}):_vm._e(),(_vm.activeTab == 'learn')?_c('tab-learning',{attrs:{"option":_vm.option,"projectId":_vm.projectId,"services":_vm.serviceList,"servicesLoading":_vm.servicesLoading},on:{"add-new-service":function($event){return _vm.$emit('add-new-service', {
            type: 'learn'
          })}},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}}):_vm._e(),(_vm.activeTab == 'upload')?_c('tab-upload',{attrs:{"option":_vm.option,"projectId":_vm.projectId,"servicesList":_vm.serviceList,"servicesLoading":_vm.servicesLoading},on:{"add-new-service":function($event){return _vm.$emit('add-new-service', {
            type: 'upload'
          })}},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}}):_vm._e(),(_vm.activeTab == 'optimization')?_c('tab-optimization',{attrs:{"option":_vm.option,"projectId":_vm.projectId,"services":_vm.serviceList,"servicesLoading":_vm.servicesLoading},on:{"add-new-service":function($event){return _vm.$emit('add-new-service', {
            type: 'optimization'
          })}},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }