<!-- trained-ai-detail-tab-detail から分割。将来的には統合したいが、複雑になりすぎる懸念があるため、trained-ai-detail-tab-detailをリファクタリングしてから統合 -->
<template>
  <div class="no-accuracy">
    <div class="no-accuracy-path">
      <DetailPath :trainedAi="trainedAi" />
    </div>
    <div class="no-accuracy-info">
      <DetailInfo :trainedAi="trainedAi" />
    </div>
  </div>
</template>

<script>
import DetailInfo from './detail/detail-info.vue'
import DetailPath from './detail/detail-path.vue' // 折り返しの挙動実装中あとi18n
export default {
  components: {
    DetailInfo,
    DetailPath
  },
  props: {
    trainedAi: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.no-accuracy {
  width: adjustVW(804);
  padding-left: $space-medium;
  &-path {
    margin-bottom: $space-medium;
  }
}
</style>
