<template>
  <div
    :id="`timeseries${$_uid}`"
    v-resize:throttle.100="resized"
    class="timeseries-plot"
    :style="styles"
  />
</template>

<script>
import * as d3 from 'd3'
import timeseries from 'd3-timeseries'
import 'd3-timeseries/dist/d3_timeseries.min.css'
import resize from 'vue-resize-directive'
import {
  timeTooltipFormatters,
  secondsTooltipFormatter,
  minutelyTooltipFormatter,
  hourlyTooltipFormatter,
  dailyTooltipFormatter,
  weeklyTooltipFormatter,
  monthlyTooltipFormatter,
  yearlyTooltipFormatter,
  timeFormatters,
  secondsFormatter,
  minutelyFormatter,
  hourlyFormatter,
  dailyFormatter,
  weeklyFormatter,
  monthlyFormatter,
  yearlyFormatter
} from './timeseries/formatter.js'
import {
  weeklyWrapping,
  monthlyWrapping,
  yearlyWrapping
} from './timeseries/wrap.js'

export default {
  directives: {
    resize
  },
  name: 'Timeseries',
  props: {
    series: Array,
    columnName: String,
    width: { type: Number, default: 900 },
    height: { type: Number, default: 480 },
    marginLeft: { type: Number, default: 35 },
    marginRight: { type: Number, default: 25 },
    marginTop: { type: Number, default: 20 },
    marginBottom: { type: Number, default: 20 },
    locale: { type: String, default: navigator.language },
    type: { type: String, default: 'normal' },
    cyclic: { type: Boolean, default: false }
  },
  mounted() {
    this.render()
  },
  data() {
    return {
      formatterTooltip: {
        normal: timeTooltipFormatters,
        sec: secondsTooltipFormatter,
        min: minutelyTooltipFormatter,
        hour: hourlyTooltipFormatter,
        day: dailyTooltipFormatter,
        week: weeklyTooltipFormatter,
        month: monthlyTooltipFormatter,
        year: yearlyTooltipFormatter
      },
      formatterDict: {
        normal: timeFormatters,
        sec: secondsFormatter,
        min: minutelyFormatter,
        hour: hourlyFormatter,
        day: dailyFormatter,
        week: weeklyFormatter,
        month: monthlyFormatter,
        year: yearlyFormatter
      },
      chart: null
    }
  },
  computed: {
    styles() {
      return {
        width: this.width,
        'min-height': (this.height / 1080) * 100 + 'vh'
      }
    },
    graphArgs() {
      return {
        series: this.series,
        width: this.width,
        height: this.height,
        marginLeft: this.marginLeft,
        marginRight: this.marginRight,
        marginTop: this.marginTop,
        marginBottom: this.marginBottom,
        locale: this.locale
      }
    }
  },
  methods: {
    resized() {
      this.render()
    },
    render() {
      const chart = timeseries()
      this.chart = chart
      let i = 0
      for (const series of this.series) {
        i++
        const interpolate = series.interpolate || 'linear'
        const dashed = series.dashed || false
        const color = series.color
        const label = series.label || `value ${i}`
        const columnName = this.columnName ?? null
        let data = series.data
        if (this.cyclic) {
          let wrappedData, start, end
          switch (this.type) {
            case 'week':
              ({ data: wrappedData, start, end } = weeklyWrapping(data))
              break
            case 'month':
              ({ data: wrappedData, start, end } = monthlyWrapping(data))
              break
            case 'year':
              ({ data: wrappedData, start, end } = yearlyWrapping(data))
              break
          }
          if (wrappedData) {
            data = wrappedData
            chart.xscale.fixedDomain = [start, end]
          }
        }
        chart.addSerie(
          data,
          { x: 'x', y: 'y', ci_up: 'y_upper', ci_down: 'y_lower' },
          { interpolate, dashed, color, label },
          columnName
        )
      }

      const el = document.getElementById(`timeseries${this.$_uid}`)
      chart.width(Math.max(100, el.clientWidth))
      chart.height(Math.max(100, el.clientHeight))
      chart.margin.left(this.marginLeft)
      chart.margin.right(this.marginRight)
      chart.margin.top(this.marginTop)
      chart.margin.bottom(this.marginBottom)
      const yAxisTarget = d3.select('.d3_timeseries.y.axis')._groups[0]
      if (yAxisTarget && yAxisTarget[0]) {
        chart.margin.left(yAxisTarget[0].getBoundingClientRect().width)
      }

      let formatter = null
      let tooltipFormatter = null
      if (this.formatterDict[this.type] && this.formatterTooltip[this.type]) {
        formatter = this.formatterDict[this.type][this.locale]
        tooltipFormatter = this.formatterTooltip[this.type][this.locale]
      }
      if (formatter) {
        chart.xscale.tickFormat(formatter)
        chart.tooltipScale.tickFormat(tooltipFormatter)
      }
      d3.select(`#timeseries${this.$_uid}`).selectAll('svg').remove()
      d3.select('.tooltip').remove()
      if (chart.xscale.fixedDomain) {
        chart.xscale.domain(chart.xscale.fixedDomain)
      }
      chart(`#timeseries${this.$_uid}`)
      d3.select(`#timeseries${this.$_uid}`)
        .selectAll('svg')
        .attr('viewBox', '0 0 ' + chart.width() + ' ' + chart.height() + '')
    }
  },
  watch: {
    graphArgs() {
      this.render()
    }
  }
}
</script>
<style lang="scss" scoped>
.timeseries-plot {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: inherit;

  &::v-deep .tick text {
    font-size: 9px;
  }

  /* prettier-ignore */
  /* stylelint-disable selector-class-pattern */
  &::v-deep .d3_timeseries {
    /* stylelint-enable selector-class-pattern */
    &.axis path,
    &.axis line {
      fill: none;
      stroke: $border-gray;
      stroke-width: 1;
      shape-rendering: crispEdges;
    }
    &.mousevline {
      opacity: 0.5;
      fill: none;
      stroke: $key-color;
      stroke-dasharray: 8;
      stroke-width: 2;
    }
    .selection {
      opacity: 0.1;
      fill: $key-color;
      fill-opacity: 1;
    }
    &.ci-area {
      opacity: 0.1;
      fill: $key-color;
    }
    &.tooltip {
      position: absolute;
      padding: adjustVW(6) adjustVW(8);
      margin-top: $space-sub;
      border: $border-main;
      background: $background;
      color: $text-main;
      font-family: $font-family;
      font-size: $text-min2;
      font-weight: 500;
      text-align: left;
      white-space: nowrap;
      border-radius: adjustVW(4);
      h4 {
        margin: 0 0 adjustVW(2);
        color: $text-sub;
        font-size: $text-min;
        font-weight: 500;
        &:last-of-type {
          margin-bottom: adjustVW(4);
        }
        &.timeseires-column-name {
          overflow: hidden;
          display: -webkit-box;
          max-width: adjustVW(160);
          text-overflow: ellipsis;
          white-space: pre-line;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
      table {
        border-spacing: 0;
      }
      td {
        &:first-of-type {
          padding-right: adjustVW(6);
        }
      }
    }
  }
}
</style>
