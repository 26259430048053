<template>
  <div class="upload-summary-image-wrap">
    <div class="upload-summary-image-item-name">
      <texts :text="itemName" />
    </div>
    <div class="upload-summary-image-input">
      <input-dataset-box
        :value="file.item"
        :accept="accept"
        @on-file-input="onFileInput"
      />
    </div>
  </div>
</template>

<script>
import InputDatasetBox from '@/components/molecules/input-dataset-box.vue'
import Texts from '@/components/atoms/text.vue'

export default {
  components: {
    InputDatasetBox,
    Texts
  },
  props: {
    index: Number,
    file: Object,
    uploadErrors: Object,
    uploadWarnings: Array
  },
  data() {
    return {
      selectedFile: null
    }
  },
  computed: {
    itemName: function () {
      if (this.index === 0) return '要約画像'
      if (this.index === 1) return 'Output 1 (推論結果) 下段左'
      if (this.index === 2) return 'Output 2 (学習結果の重要度) 下段右'
      return ''
    },
    accept() {
      return ['image/png', 'image/jpeg']
    }
  },
  methods: {
    onFileInput: function (file) {
      this.$emit('on-file-input', {
        index: this.index,
        file
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-summary-image-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: $space-small;
}

.upload-summary-image-item-name {
  margin-bottom: $space-small;
}

.upload-summary-image-input {
  height: adjustVW(168);
  border: $line;
  border-radius: adjustVW(8);
}
</style>
