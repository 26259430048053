<template>
  <input-form-box
    name="firstname"
    :errorText="this.$i18n.t('signup.error.nicknameTooLong')"
    :explanationText="this.$i18n.t('signup.description.name')"
    :exampleText="this.$i18n.t('signup.example.name')"
    :title="this.$i18n.t('signup.form.name')"
    focusStart
    v-bind="nickname"
    @change="changeVal"
  />
</template>

<script>
import inputFormBox from '@/components/molecules/signup/input-form-box.vue'

export default {
  components: {
    inputFormBox
  },
  data() {
    return {
      nickname: {
        isError: false,
        isOk: false,
        value: ''
      },
      isValid: false
    }
  },
  props: {
    backNickname: String
  },
  watch: {
    'nickname.value': function (val) {
      this.nickname.isError = false
      this.nickname.isOk = false

      if (val !== '') {
        this.isValid = this._validateNickname(val)
        this.nickname.isOk = this.isValid
        this.nickname.isError = !this.isValid
      }
      this.$emit('change', {
        value: this.nickname.value,
        isOk: this.nickname.isOk
      })
    }
  },
  methods: {
    _validateNickname(name) {
      if (name.length > 32) return false
      return true
    },
    changeVal(val) {
      this.nickname.value = val
    }
  },
  mounted() {
    if (this.backNickname) {
      this.nickname.value = this.backNickname
    }
  }
}
</script>
