<template>
  <div v-if="progressSettingDataset !== null" class="wait-wrap">
    <card-base class="wait-inner">
      <transition-toggle-contents>
        <div v-if="!isLargeCsv" class="wait-icon">
          <svg viewBox="0 0 36 36" class="wait-pie">
            <circle
              class="wait-pie-base"
              cx="18"
              cy="18"
              r="15.9"
              fill="none"
              stroke="#efefef"
            />
            <circle
              class="wait-pie-main"
              cx="18"
              cy="18"
              r="15.9"
              fill="none"
              stroke="#850491"
              :stroke-dasharray="scale + ' 100'"
            />
          </svg>
          <svg
            v-if="progressSettingDataset === progressSettingDatasetMax"
            viewBox="0 0 36 36"
            class="wait-comp"
          >
            <polyline class="wait-comp-path" points="36,4 12,32 0,20" />
          </svg>
        </div>
        <div v-else class="wait-large">
          <loading-icon class="wait-large-icon" />
          <texts
            class="wait-large-text"
            :text="$t('datasetList.popup.addDataset.status.notification')"
            size="large"
            color="gray"
          />
        </div>
      </transition-toggle-contents>
      <texts :text="datasetStatusText" size="large" />
      <div
        v-if="
          Object.keys(uploadLearningDataWarnings).length > 0 &&
            uploadLearningDataWarnings.reasonList.length > 0
        "
        class="warning-list"
      >
        <div v-if="uploadLearningDataWarnings.fileType === 'zip'">
          <texts
            class="warning-title"
            :text="$t('datasetList.warnings.zip.title')"
            size="small"
            color="caution"
          />
          <div class="warning-inner">
            <texts
              v-for="(warning, index) in uploadLearningDataWarnings.reasonList"
              :key="index"
              class="warning-item"
              :text="
                warning.filename +
                  ' : ' +
                  $t('datasetList.warnings.zip.' + warning.type)
              "
              color="caution"
              size="small"
            />
          </div>
        </div>
      </div>
    </card-base>
  </div>
</template>

<script>
import cardBase from '@/components/atoms/card-base'
import texts from '@/components/atoms/text.vue'
import LoadingIcon from '@/components/atoms/loading-icon.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
export default {
  components: {
    cardBase,
    texts,
    LoadingIcon,
    transitionToggleContents
  },
  props: {
    /** データセットのアップロードインポートの進行度 */
    progressSettingDataset: Number,
    /** データセットのアップロードインポートの最大値 */
    progressSettingDatasetMax: Number,
    /** データセットのアップロードインポートの進行状態 */
    progressStatus: String,
    /** データセットのアップロードインポー卜時のエラーを配列で渡す */
    uploadLearningDataWarnings: Object,
    /** 大容量CSVかどうか */
    isLargeCsv: Boolean
  },
  computed: {
    scale() {
      return (
        (this.progressSettingDataset / this.progressSettingDatasetMax) *
        100
      ).toFixed(0)
    },
    datasetStatusText() {
      let texts = ''
      if (this.progressStatus === 'uploadingLearningData') {
        texts =
          this.$t('datasetList.popup.addDataset.status.uploadingLearningData') +
          ' ' +
          this.scale +
          '%'
      } else if (this.progressStatus === 'preparingData') {
        texts = this.isLargeCsv
          ? this.$t('datasetList.popup.addDataset.status.preparingData')
          : this.$t('datasetList.popup.addDataset.status.preparingData') +
            ' ' +
            this.scale +
            '%'
      }
      return texts
    }
  }
}
</script>

<style lang="scss" scoped>
.wait {
  &-wrap {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.85);
    z-index: 100;
  }
  &-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: adjustVW(672);
    padding: adjustVW(80) 0;
    text-align: center;
  }
  &-icon {
    position: relative;
    width: adjustVW(160);
    height: adjustVW(160);
    margin: 0 0 $space-large;
  }
  &-pie {
    width: 100%;
    height: 100%;
    stroke-width: adjustVW(2);
    &-main {
      transform: rotate(-90deg);
      transform-origin: center;
      transition: stroke-dasharray $transition-base;
    }
  }
  &-comp {
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: visible;
    width: adjustVW(64);
    transform: translate(-50%, -50%);
    stroke: $key-color;
    stroke-width: adjustVW(2);
    &-path {
      transform-origin: center;
      animation: dash-check $transition-base ease-in-out forwards;
      fill: none;
      stroke: $key-color;
      stroke-dasharray: 1000;
      stroke-dashoffset: -100;
      stroke-width: adjustVW(4);
      stroke-linecap: round;
      stroke-miterlimit: 1;
    }
  }
  &-large {
    display: flex;
    grid-column-gap: $space-small;
    &-text {
      margin-bottom: $space-medium;
      white-space: pre-line;
    }
  }
}

.warning {
  &-list {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-height: adjustVH(240);
    padding: $space-small;
    padding-right: $space-base;
    margin-top: $space-medium;
    background: $background-sub;
    text-align: left;
    border-radius: adjustVW(8);
    @include scrollbar;
  }
  &-title {
    flex-shrink: 0;
    margin-bottom: $space-sub;
  }
  &-inner {
    overflow-y: auto;
    height: 100%;
    @include scrollbar;
  }
  &-item {
    margin-bottom: $space-sub;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
</style>
