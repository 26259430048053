<template>
  <!--
    データソースの再設定を行うポップアップ
  -->
  <div class="popup-add-dataset-datasource-wrap">
    <!-- 本来は全部の項目が埋まったらボタンをONにする -->
    <div class="popup-add-dataset-datasource-block">
      <input
        type="text"
        name="accountId"
        autocomplete="username"
        class="stop-autocomplete-dummy"
      >
      <input
        type="password"
        autocomplete="new-password"
        class="stop-autocomplete-dummy"
      >
      <input-box-validation
        v-model="resetValue.name"
        :accept="validate.accept"
        :error="validate.error"
        :placeholder="
          $t('datasetList.popup.setDatasource.datasourceNamePlaceholder')
        "
        :title="$t('datasetList.popup.setDatasource.datasourceName')"
        popupView
        isNameValidation
        :nameValidationSuggests="datasourceFormValidate.suggests"
      />
    </div>
    <div class="popup-add-dataset-datasource-block">
      <div class="popup-add-dataset-datasource-title">
        <texts :text="$t('datasetList.popup.setDatasource.dbType')" />
      </div>
      <div class="popup-add-dataset-datasource-type-list">
        <button
          class="popup-add-dataset-datasource-type-item"
          @click="selectDB('mysql')"
        >
          <datasource-type-wrap
            type="mysql"
            :isOn="resetValue.dbtype === 'mysql'"
          />
        </button>
        <button
          class="popup-add-dataset-datasource-type-item"
          @click="selectDB('postgresql')"
        >
          <datasource-type-wrap
            type="postgresql"
            :isOn="resetValue.dbtype === 'postgresql'"
          />
        </button>
        <button
          class="popup-add-dataset-datasource-type-item"
          @click="selectDB('oracle')"
        >
          <datasource-type-wrap
            type="oracle"
            :isOn="resetValue.dbtype === 'oracle'"
          />
        </button>
        <button
          class="popup-add-dataset-datasource-type-item"
          @click="selectDB('bigquery')"
        >
          <datasource-type-wrap
            type="bigquery"
            :isOn="resetValue.dbtype === 'bigquery'"
          />
        </button>
        <button
          class="popup-add-dataset-datasource-type-item"
          @click="selectDB('snowflake')"
        >
          <datasource-type-wrap
            type="snowflake"
            :isOn="resetValue.dbtype === 'snowflake'"
          />
        </button>
      </div>
    </div>
    <transition-toggle-contents>
      <!-- Big Query 専用の設定 GCPProject GCPDatasetId credential -->
      <div v-if="checkBigquery" key="bigquery">
        <div class="popup-add-dataset-datasource-list">
          <div class="popup-add-dataset-datasource-item">
            <input-box
              v-model="resetValue.GCPProject"
              :title="$t('datasetList.datasourceList.GCPProject')"
              isGray
            />
          </div>
          <div class="popup-add-dataset-datasource-item">
            <input-box
              v-model="resetValue.GCPDatasetId"
              :title="$t('datasetList.datasourceList.GCPDatasetId')"
              isGray
            />
          </div>
        </div>
        <div class="popup-add-dataset-datasource-list">
          <div class="popup-add-dataset-datasource-item-full">
            <input-box-file
              v-model="resetValue.credential"
              accept="application/json"
              :title="$t('datasetList.datasourceList.credential')"
              :placeholder="
                $t('datasetList.datasourceList.credentialPlaceholder')
              "
              :info="$t('datasetList.datasourceList.credentialInfo')"
            />
          </div>
        </div>
      </div>
      <div v-else-if="checkSnowflake">
        <div class="popup-add-dataset-datasource-list">
          <div class="popup-add-dataset-datasource-item">
            <input-box
              v-model="resetValue.database"
              :title="$t('datasetList.datasourceList.dbName')"
              isGray
            />
          </div>
          <div class="popup-add-dataset-datasource-item">
            <input-box
              v-model="resetValue.schema"
              :title="$t('datasetList.popup.setDatasource.schema')"
              isGray
            />
          </div>
        </div>
        <div class="popup-add-dataset-datasource-list">
          <div class="popup-add-dataset-datasource-item">
            <input-box-validation
              v-model="resetValue.password"
              :title="$t('datasetList.popup.setDatasource.dbPassword')"
              isPass
            />
          </div>
          <div class="popup-add-dataset-datasource-item">
            <input-box
              v-model="resetValue.username"
              :title="$t('datasetList.popup.setDatasource.dbUsername')"
              isGray
            />
          </div>
          <div class="popup-add-dataset-datasource-item">
            <input-box
              v-model="resetValue.account"
              :title="$t('datasetList.popup.setDatasource.snowflakeAccount')"
              isGray
            />
          </div>
        </div>
      </div>
      <div v-else key="default">
        <div class="popup-add-dataset-datasource-list">
          <div class="popup-add-dataset-datasource-item">
            <input-box
              v-model="resetValue.host"
              :title="$t('datasetList.datasourceList.host')"
              :placeholder="$t('datasetList.datasourceList.hostPlaceholder')"
              isGray
            />
          </div>
          <div class="popup-add-dataset-datasource-item">
            <input-box
              v-model.number="resetValue.port"
              :title="$t('datasetList.datasourceList.port')"
              :placeholder="$t('datasetList.datasourceList.portPlaceholder')"
              isGray
            />
          </div>
          <div class="popup-add-dataset-datasource-item">
            <input-box
              v-model="resetValue.database"
              :title="$t('datasetList.datasourceList.dbName')"
              isGray
            />
          </div>
        </div>
        <div class="popup-add-dataset-datasource-list">
          <div class="popup-add-dataset-datasource-item">
            <input-box-validation
              v-model="resetValue.password"
              :title="$t('datasetList.popup.setDatasource.dbPassword')"
              isPass
            />
          </div>
          <div class="popup-add-dataset-datasource-item">
            <input-box
              v-model="resetValue.username"
              :title="$t('datasetList.popup.setDatasource.dbUsername')"
              isGray
            />
          </div>
        </div>
      </div>
    </transition-toggle-contents>
    <div class="popup-add-dataset-datasource-button-list">
      <div class="popup-add-dataset-datasource-button-item">
        <button-main
          :text="$t('datasetList.popup.setDatasource.connectionTest')"
          :type="
            resetDatasource.inTesting || isTestDisabled ? 'disabled' : 'sub'
          "
          :isDisabled="resetDatasource.inTesting || isTestDisabled"
          @click="testDatasource"
        />
      </div>
      <div class="popup-add-dataset-datasource-button-item">
        <button-main
          :isDisabled="isDatasourceDisabled || disableClick"
          :text="$t('datasetList.popup.setDatasource.submit')"
          :type="
            isDatasourceDisabled || validate.error ? 'disabled' : 'emphasis'
          "
          @click="createNewDatasource"
        />
      </div>
      <transition name="respons" mode="out-in">
        <div
          v-if="resetDatasource.inTesting"
          key="loading"
          class="popup-add-dataset-datasource-loading"
        >
          <loading-icon />
        </div>
        <text-box
          v-else-if="resetDatasource.connentTest"
          key="success"
          :text="$t('datasetList.popup.setDatasource.testSuccess')"
        />
        <text-box
          v-else-if="resetDatasource.connentTest === false"
          key="false"
          :text="$t('datasetList.popup.setDatasource.testError')"
          isError
        />
      </transition>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import textBox from '@/components/atoms/text-box.vue'
import buttonMain from '@/components/atoms/button-main'
import loadingIcon from '@/components/atoms/loading-icon.vue'
import inputBoxValidation from '@/components/molecules/input-box-validation'
import inputBox from '@/components/molecules/input-box'
import inputBoxFile from '@/components/molecules/input-box-file.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import datasourceTypeWrap from '@/components/atoms/datasource-type-wrap'
import { mapMutations, mapActions } from 'vuex'

const defaultPort = { oracle: 1521, postgresql: 5432, mysql: 3306, bigquery: 0 } // bigqueryはportなしになるが、action側でnullを許可していないため、0を入れる

export default {
  components: {
    texts,
    textBox,
    buttonMain,
    loadingIcon,
    inputBoxValidation,
    inputBox,
    inputBoxFile,
    transitionToggleContents,
    datasourceTypeWrap
  },
  data() {
    return {
      resetValue: {
        name: this.resetDatasource?.datasourceInfo?.name ?? '',
        description: this.resetDatasource?.datasourceInfo?.description ?? '',
        dbtype: this.resetDatasource?.datasourceInfo?.dbtype ?? '',
        host: this.resetDatasource?.datasourceInfo?.host ?? '',
        port: this.resetDatasource?.datasourceInfo?.port ?? null,
        database: this.resetDatasource?.datasourceInfo?.database ?? '',
        schema: this.resetDatasource?.datasourceInfo?.schema ?? '',
        username: this.resetDatasource?.datasourceInfo?.username ?? '',
        password: this.resetDatasource?.datasourceInfo?.password ?? '',
        GCPProject: this.resetDatasource?.datasourceInfo?.GCPProject ?? '',
        GCPDatasetId: this.resetDatasource?.datasourceInfo?.GCPDatasetId ?? '',
        account: this.resetDatasource?.datasourceInfo?.account ?? '',
        credential: null
      },
      validate: {
        accept: null,
        error: null
      }
    }
  },
  props: {
    datasourceFormValidate: Object,
    resetDatasource: Object,
    disableClick: Boolean
  },
  computed: {
    checkBigquery() {
      return this.resetValue.dbtype === 'bigquery'
    },
    checkSnowflake() {
      return this.resetValue.dbtype === 'snowflake'
    },
    isDatasourceDisabled() {
      if (this.isTestDisabled) return true
      return !this.resetValue.name
    },
    isTestDisabled() {
      if (this.checkBigquery) {
        return !(
          this.resetValue.credential &&
          this.resetValue.GCPProject &&
          this.resetValue.GCPDatasetId
        )
      } else if (this.checkSnowflake) {
        return !(
          this.resetValue.dbtype &&
          this.resetValue.schema &&
          this.resetValue.account &&
          this.resetValue.database &&
          this.resetValue.username &&
          this.resetValue.password
        )
      } else {
        return !(
          this.resetValue.dbtype &&
          this.resetValue.host &&
          this.resetValue.database &&
          this.resetValue.username &&
          this.resetValue.password &&
          this.resetValue.port
        )
      }
    }
  },
  methods: {
    ...mapActions('datasource', ['addDataSource', 'testDataSource', 'deleteDataSource']),
    ...mapMutations('datasource', ['SET_SUBMITTING']),

    selectDB(DB) {
      this.resetValue.dbtype = DB
      this.resetValue.port = defaultPort[DB]
    },
    createNewDatasource() {
      const req = this.resetValue
      if (req.dbtype === 'bigquery') {
        req.fileName = req.credential.name
      }
      this.$emit('new-datasource', req)
    },
    testDatasource() {
      const req = this.resetValue
      if (req.dbtype === 'bigquery') {
        req.fileName = req?.credential?.name ?? null
      }
      this.$emit('test-datasource', req)
    }
  },
  watch: {
    datasourceFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    },
    // データソースを登録ボタンを有効化
    resetValue: {
      deep: true,
      handler: function (val) {
        this.$emit('input-create-form', {
          type: 'datasource',
          form: val
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-add-dataset {
  &-datasource {
    overflow: hidden;
    height: 100%;
    &-wrap {
      width: adjustVW(592) + adjustVW(960) + adjustVW(24);
    }
    &-block {
      width: adjustVW(592);
      margin: 0 0 $space-large;
    }
    &-list {
      display: flex;
      margin: 0 0 $space-large;
    }
    &-item {
      width: adjustVW(288);
      height: 100%;
      margin: 0 $space-medium 0 0;
      &:last-child {
        margin: 0;
      }
      &-full {
        width: adjustVW(288 * 2);
      }
    }
    &-title {
      margin: 0 0 $space-base;
    }
    &-type {
      &-list {
        display: flex;
      }
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: adjustVW(120);
        height: adjustVH(80);
        margin: 0 $space-small 0 0;
        background: $background-sub;
        border-radius: adjustVW(8);
        &:last-child {
          margin: 0;
        }
      }
    }
    &-button {
      &-list {
        display: flex;
      }
      &-item {
        margin: 0 $space-medium 0 0;
        &:last-child {
          margin: 0;
        }
      }
    }
    &-loading {
      display: flex;
      align-items: center;
    }
  }
}
.respons-enter-active,
.respons-leave-active {
  transition: transform $transition-base, opacity $transition-base;
}
.respons-enter,
.respons-leave-to {
  opacity: 0;
  will-change: opacity, transform;
}
.respons-enter {
  transform: translateX(-$space-small);
}
.respons-leave-to {
  transform: translateX($space-small);
}

.stop-autocomplete-dummy {
  display: none;
}
</style>
