<template>
  <div class="error-inner">
    <template v-for="(error, index) in selectRecipeErrors">
      <div :key="index" class="error-item">
        <texts class="error-text" size="small" :text="error.errorDesc" />
        <div
          v-if="error.columns && error.columns.length > 0"
          class="error-columns"
        >
          <texts
            class="error-columns-title"
            :text="$t('recipeDetail.selectRecipeErrors.errorColumns')"
            size="min"
            color="gray"
          />
          <div class="error-columns-list">
            <div class="error-columns-list-inner">
              <texts
                v-for="(column, key) in error.columns"
                :key="key"
                class="error-columns-item"
                :text="column"
                size="small"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'

export default {
  components: {
    texts
  },
  props: {
    selectRecipeErrors: Array
  }
}
</script>

<style lang="scss" scoped>
.error {
  &-item {
    padding-bottom: $space-medium;
    margin-bottom: $space-medium;
    border-bottom: $border-sub;
    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }
  &-inner {
    width: adjustVW(720 - 24 - 24);
  }
  &-text {
    line-height: $line-height-base;
    white-space: pre-line;
  }
  &-columns {
    margin-top: $space-sub;
    &-list {
      display: flex;
      flex-direction: column;
      max-height: adjustVH(240);
      padding: $space-small;
      background-color: $background-sub;
      border-radius: adjustVW(8);
      &-inner {
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;
        grid-column-gap: $space-sub;
        grid-row-gap: $space-base;
        padding-right: $space-base;
        @include scrollbar;
      }
    }
    &-item {
      max-width: adjustVW(160);
    }
    &-title {
      margin-bottom: adjustVW(6);
    }
  }
}
</style>
