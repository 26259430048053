<template>
  <card-base>
    <div class="selected-dataset-card-tab-wrap">
      <tab-list
        class="selected-dataset-card-tab-menu"
        :tabs="tabs"
        @change-tab="changeMenu"
      />
      <toggle-button
        v-tooltip.left="
          $t('selectTargetColumn.menu.targetColumnInfo.addButtonHelp')
        "
        class="selected-dataset-card-toggle"
        :text="$t('selectTargetColumn.menu.targetColumnInfo.setMultiColumn')"
        @input="toggleMulti"
      />
    </div>

    <div class="selected-dataset-card-main">
      <transition-toggle-contents>
        <columns-list
          v-if="activeMenu === 'list'"
          v-bind="columnsList"
          :cautionMessage="cautionMessage"
          :checkNotes="checkNotes"
          :multiSelectMode="multiSelectMode"
          @check-notes="$emit('check-notes', $event)"
          @get-selected-column="getSelectedColumn"
          @change-added-selectbox="$emit('change-added-selectbox', $event)"
          @go-manual-select-target-column="
            $emit('go-manual-select-target-column')
          "
        />
        <dataset-table
          v-else
          v-bind="bindData"
          :cautionItems="columnsList.cautionItems"
          :cautionMessage="cautionMessage"
          :checkNotes="checkNotes"
          :preventCautionMessage="preventCautionMessage"
          :multiSelectMode="multiSelectMode"
          :table="tableData"
          :graph="trainingDatasetDetail.detail.graph"
          :loadingDatasetDetail="loadingDatasetDetail"
          :isStatics="activeMenu === 'statistics'"
          @click-column="getSelectedColumn"
          @check-notes="$emit('check-notes', $event)"
          @change-added-selectbox="$emit('change-added-selectbox', $event)"
          @show-too-large-data="$emit('show-too-large-data')"
        />
      </transition-toggle-contents>
    </div>
  </card-base>
</template>

<script>
import CardBase from '@/components/atoms/card-base.vue'
import ColumnsList from '@/components/organisms/select-target-column/dataset-info/columns-list.vue'
import DatasetTable from '@/components/organisms/select-target-column/dataset-info/dataset-table.vue'
import TabList from '@/components/molecules/tab-list.vue'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import ToggleButton from '@/components/atoms/toggle-button.vue'

export default {
  components: {
    CardBase,
    ColumnsList,
    DatasetTable,
    TabList,
    TransitionToggleContents,
    ToggleButton
  },
  data() {
    return {
      activeMenu: 'list'
    }
  },
  props: {
    checkNotes: Object,
    columnsList: Object,
    selectedDataset: Object,
    statisticalData: Object,
    preventCautionMessage: Boolean,
    multiSelectMode: Boolean,
    trainingDatasetDetail: Object,
    loadingDatasetDetail: Boolean
  },
  computed: {
    tabs() {
      return [
        {
          id: 0,
          name: this.$i18n.t('selectTargetColumn.datasetInfo.tabs.list'),
          iconName: 'selectList',
          value: 'list',
          disabled: this.loadingDatasetDetail
        },
        {
          id: 1,
          name: this.$i18n.t('selectTargetColumn.datasetInfo.tabs.table'),
          iconName: 'dataset',
          value: 'table',
          disabled: this.loadingDatasetDetail
        },
        {
          id: 2,
          name: this.$i18n.t('selectTargetColumn.datasetInfo.tabs.statistics'),
          iconName: 'statistics',
          value: 'statistics',
          disabled: this.loadingDatasetDetail
        }
      ]
    },
    cautionMessage() {
      return [
        {
          index: 0,
          value: 'missingNumber',
          text: this.$i18n.t(
            'selectTargetColumn.datasetInfo.list.caution.reason.missingValue'
          )
        },
        {
          index: 1,
          value: 'manyUniqueNumber',
          text: this.$i18n.t(
            'selectTargetColumn.datasetInfo.list.caution.reason.manyUniquNumber'
          )
        },
        {
          index: 2,
          value: 'isObjectValue',
          text: this.$t(
            'selectTargetColumn.datasetInfo.list.caution.reason.isObjectValue'
          )
        },
        {
          index: 3,
          value: 'notNumber',
          text: this.$t(
            'selectTargetColumn.datasetInfo.list.caution.reason.notNumber'
          )
        },
        {
          index: 4,
          value: 'notInt',
          text: this.$t(
            'selectTargetColumn.datasetInfo.list.caution.reason.notInt'
          )
        }
      ]
    },
    tableData() {
      if (this.activeMenu === 'table') {
        return this.trainingDatasetDetail.list
      } else if (this.activeMenu === 'statistics') {
        return this.trainingDatasetDetail.describe
      } else {
        return []
      }
    },
    bindData() {
      if (this.activeMenu === 'table') {
        return this.selectedDataset
      } else if (this.activeMenu === 'statistics') {
        return this.statisticalData
      } else {
        return {}
      }
    }
  },
  methods: {
    changeMenu: function (value) {
      this.activeMenu = value
    },
    getSelectedColumn: function (val) {
      this.$emit('get-selected-column', val)
    },
    toggleMulti: function (val) {
      this.$emit('change-select-mode', val)
    },
    goPlan: function () {
      this.$router.push({ name: 'changePlan' })
    }
  }
}
</script>

<style lang="scss" scoped>
.selected-dataset-card-tab-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: $space-small;
}

.selected-dataset-card-tab-menu {
  width: fit-content;
}

.selected-dataset-card-main {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.selected-dataset-card-toggle {
  margin-right: $space-base;
  &-pop {
    &-text {
      margin-bottom: $space-sub;
      white-space: pre-line;
    }
    &-button {
      height: adjustVW(32);
      line-height: 1;
    }
  }
}
</style>
