<template>
  <div class="clone-form">
    <input-box
      v-model="name"
      isGray
      class="template-effect-form-term"
      :title="'期待される効果のタイトル'"
    />
    <input-box
      v-model="description"
      isGray
      isTextArea
      class="template-effect-form-term"
      :title="'期待される効果の詳細'"
    />
  </div>
</template>

<script>
import InputBox from '@/components/molecules/input-box.vue'

export default {
  components: {
    InputBox
  },
  props: {
    projectTemplateEffect: Object
  },
  data() {
    return {
      name: this.projectTemplateEffect.name,
      description: this.projectTemplateEffect.description,
      formula: this.projectTemplateEffect.formula
    }
  },
  computed: {
    formData: function () {
      return {
        name: this.name,
        description: this.description,
        formula: this.formula
      }
    }
  },
  watch: {
    formData: {
      deep: true,
      handler: function (form) {
        this.$emit('input-edit-form', {
          type: 'projectTemplateEffect',
          form
        })
      }
    }
  },
  mounted: function () {
    this.$emit('input-edit-form', {
      type: 'projectTemplateEffect',
      form: this.formData
    })
  }
}
</script>

<style lang="scss" scoped>
.project-create-form {
  width: 80rem;
}

.project-create-form > div {
  margin: 1rem;
}

.template-effect-form-term {
  width: 100%;
  border: $line;
  background-color: $background;
  border-radius: adjustVW(8);
}

.submit-button {
  display: flex;
  justify-content: center;
  margin: 1rem;
}
</style>
