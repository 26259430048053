<template>
  <div class="dataset-detail-side-wrap">
    <div class="dataset-detail-side-tab">
      <tab-list
        :tabs="sidebarTab.tabs"
        :activeTab="infoTabs.activeTab"
        @change-tab="$emit('change-tab', $event)"
      />
    </div>
    <div class="dataset-detail-side-body">
      <!-- body -->
      <transition-toggle-contents>
        <div
          v-if="infoTabs.visibleContents === 'detail'"
          key="info"
          class="dataset-detail-side-inner"
        >
          <!-- info -->
          <div v-if="editting === false">
            <div class="dataset-detail-side-summary">
              <div class="dataset-detail-side-title">
                <texts :text="name" size="large" />
              </div>
              <div class="dataset-detail-side-desc">
                <description-long :title="name" :text="description" />
              </div>
              <button
                class="dataset-detail-side-edit"
                :disabled="disabledEdit"
                :class="{ 'dataset-detail-side-edit-disabled': disabledEdit }"
                @click="edit"
              >
                <text-with-icon
                  :text="$t('datasetDetail.tableSide.edit')"
                  iconName="edit"
                  size="small"
                />
              </button>
            </div>
            <div class="dataset-detail-side-info">
              <!-- 保存した前処理の表示を削除 -->
              <div class="dataset-detail-side-item-block">
                <text-with-title
                  class="dataset-detail-side-item-text"
                  :text="accountId"
                  :title="$t('datasetDetail.tableSide.owner')"
                />
              </div>
              <div class="dataset-detail-side-item-block">
                <div class="dataset-detail-side-item-half">
                  <text-with-title
                    class="dataset-detail-side-item-text"
                    :text="formatFileSize(this.size)"
                    :title="$t('datasetDetail.tableSide.dataSize')"
                  />
                </div>
                <div
                  v-if="type === 'structured'"
                  class="dataset-detail-side-item-half"
                >
                  <text-with-title
                    class="dataset-detail-side-item-text"
                    :text="nRows"
                    :title="$t('datasetDetail.tableSide.row')"
                  />
                  <text-with-title
                    class="dataset-detail-side-item-text"
                    :text="nCols"
                    :title="$t('datasetDetail.tableSide.column')"
                  />
                </div>
                <div v-else-if="nData" class="dataset-detail-side-item-half">
                  <text-with-title
                    class="dataset-detail-side-item-text"
                    :text="nData"
                    :title="$t('datasetDetail.statistics.count')"
                  />
                </div>
              </div>
              <div class="dataset-detail-side-item-block">
                <div class="dataset-detail-side-item-half">
                  <text-with-title
                    class="dataset-detail-side-item-text"
                    :title="$t('datasetDetail.tableSide.createdDate')"
                  >
                    <a-time :value="createTime" />
                  </text-with-title>
                </div>
                <div class="dataset-detail-side-item-half">
                  <text-with-title
                    class="dataset-detail-side-item-text"
                    :title="$t('datasetDetail.tableSide.lastUpdatedDate')"
                  >
                    <a-time :value="updateTime" />
                  </text-with-title>
                </div>
              </div>
            </div>
            <!-- 外部からインポートした場合の表示。実際には、データベース情報があるかないかとかでの判定が必要。画面の表示のテスト用に、ページの仮のパラメータ（outside）を参照している -->
            <div
              v-if="componentPageName === 'outside'"
              class="dataset-detail-side-datasource"
            >
              <button
                class="dataset-detail-side-datasource-button"
                v-on:click="isDatasourceShow = !isDatasourceShow"
              >
                <text-with-icon
                  v-if="!isDatasourceShow"
                  :text="$t('datasetDetail.tableSide.showDatasource')"
                  iconName="plus"
                  size="small"
                />
                <text-with-icon
                  v-else
                  :text="$t('datasetDetail.tableSide.closeDatasourceShow')"
                  iconName="minus"
                  size="small"
                />
              </button>
              <div
                v-show="isDatasourceShow"
                class="dataset-detail-side-datasource-wrap"
              >
                <div class="dataset-detail-side-datasource-title">
                  <texts :text="datasourceInfo.name" size="large" />
                </div>
                <div class="dataset-detail-side-datasource-block">
                  <div class="dataset-detail-side-datasource-inner">
                    <div class="dataset-detail-side-datasource-type">
                      <div class="dataset-detail-side-datasource-type-inner">
                        <texts
                          color="gray"
                          :text="$t('datasetDetail.tableSide.databaseType')"
                          size="small"
                        />
                      </div>
                      <datasource-type-wrap :type="datasourceInfo.type" />
                    </div>
                    <div class="dataset-detail-side-datasource-info">
                      <div class="dataset-detail-side-datasource-info-inner">
                        <text-with-title :text="datasourceInfo.port" />
                      </div>
                      <div class="dataset-detail-side-datasource-info-inner">
                        <text-with-title :text="datasourceInfo.dbName" />
                      </div>
                    </div>
                  </div>
                  <div class="dataset-detail-side-datasource-inner">
                    <text-with-title
                      class="dataset-detail-side-datasource-inner-host"
                      :text="this.datasourceInfo.host"
                      title="接続先ホスト"
                    />
                  </div>
                </div>
                <div class="dataset-detail-side-datasource-table">
                  <div class="dataset-detail-side-datasource-table-inner">
                    <text-with-title
                      :text="this.tableInfo.name"
                      :title="$t('datasetDetail.tableSide.usedTable')"
                    />
                  </div>
                  <div class="dataset-detail-side-datasource-table-inner">
                    <texts
                      color="gray"
                      :text="$t('datasetDetail.tableSide.importColumn')"
                      size="small"
                    />
                    <div class="dataset-detail-side-datasource-table-labels">
                      <div
                        v-for="column in tableInfo.columns"
                        :key="column.id"
                        class="dataset-detail-side-datasource-table-label"
                      >
                        <texts :text="column.name" size="small" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="dataset-detail-side-summary-edit">
            <div class="dataset-detail-side-summary-edit-title">
              <input-box-validation
                v-model="newName"
                :accept="validate.accept"
                :error="validate.error"
                popupView
                isNameValidation
                :nameValidationSuggests="datasetFormValidate.suggests"
              />
            </div>
            <div class="dataset-detail-side-summary-edit-desc">
              <input-box
                v-model="newDescription"
                class="dataset-detail-side-summary-edit-desc-input"
                isTextArea
                isGray
              />
            </div>
            <div class="dataset-detail-side-summary-edit-button-list">
              <div class="dataset-detail-side-summary-edit-button-save">
                <button-main
                  type="emphasis"
                  :isDisabled="disabledSubmit"
                  :text="$t('datasetDetail.tableSide.save')"
                  @click="
                    $emit('save-dataset-detail', { newName, newDescription })
                  "
                />
              </div>
              <div class="dataset-detail-side-summary-edit-button-cancel">
                <button-main
                  size="min"
                  :text="$t('datasetDetail.tableSide.cancel')"
                  @click="editting = false"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="infoTabs.visibleContents === 'history'"
          key="history"
          class="now-developing"
        >
          <images imageName="nowDeveloping" class="now-developing-image" />
          <texts :text="$t('common.nowDeveloping')" />
        </div>
      </transition-toggle-contents>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import datasourceTypeWrap from '@/components/atoms/datasource-type-wrap'
import descriptionLong from '@/components/atoms/description-long'
import images from '@/components/atoms/images.vue'
import tabList from '@/components/molecules/tab-list'
import textWithTitle from '@/components/molecules/text-with-title'
import textWithIcon from '@/components/molecules/text-with-icon'
import inputBox from '@/components/molecules/input-box'
import inputBoxValidation from '@/components/molecules/input-box-validation'
import buttonMain from '@/components/atoms/button-main'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

export default {
  components: {
    texts,
    datasourceTypeWrap,
    descriptionLong,
    images,
    tabList,
    textWithTitle,
    textWithIcon,
    inputBox,
    inputBoxValidation,
    buttonMain,
    transitionToggleContents
  },
  computed: {
    disabledSubmit: function () {
      if (this.newName === '') return true
      if (this.validate.error) return true

      return false
    },
    nCols() {
      if (!this.dtypes) return 0
      const gp = Object.keys(this.dtypes)
      return gp.length
    }
  },
  data() {
    return {
      datasourceInfo: null,
      editting: false,
      isDatasourceShow: false,
      newName: '',
      newDescription: '',
      validate: {
        accept: null,
        error: null
      }
    }
  },
  watch: {
    newName: function (name) {
      this.$emit('input-edit-form', {
        type: 'dataset',
        form: {
          name
        }
      })
    },
    datasetFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    }
  },
  methods: {
    edit() {
      this.editting = true
      this.newName = this.name
      this.newDescription = this.description

      this.$emit('input-edit-form', {
        type: 'dataset',
        form: {
          name: this.name
        }
      })
    }
  },
  props: {
    disabledEdit: Boolean,
    infoTabs: Object,
    id: String,
    name: String,
    description: String,
    createTime: String,
    updateTime: String,
    size: Number,
    accountId: String,
    dtypes: Object,
    nRows: Number,
    nData: Number,
    // 保存した前処理の表示を削除
    type: String,
    componentPageName: String,
    sidebarTab: Object,
    datasetFormValidate: Object
  }
}
</script>

<style lang="scss" scoped>
.dataset-detail-side {
  &-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-body {
    height: 100%;
  }
  &-inner {
    height: 100%;
  }
  &-tab {
    position: sticky;
    top: 0;
    margin: 0 0 $space-small;
    background: $background;
    z-index: 1;
  }
  &-summary {
    padding: 0 0 $space-small;
    margin: 0 0 $space-small;
    border-bottom: $border-main;
  }
  &-title {
    margin: 0 0 $space-base;
  }
  &-desc {
    margin: 0 0 $space-small;
  }
  &-edit {
    position: relative;
    display: block;
    margin: 0 0 0 auto;
    z-index: 1;
    &::after {
      content: '';
      position: absolute;
      top: -$space-text;
      left: -$space-base;
      width: calc(100% + #{$space-base * 2});
      height: calc(100% + #{$space-text * 2});
      background: $medium-gray;
      border-radius: 9in;
      opacity: 0;
      z-index: -1;
      will-change: opacity;
      transition: $transition-base;
    }
    &:hover {
      opacity: 1;
    }
    &:hover::after {
      opacity: 1;
    }
    &-disabled {
      cursor: not-allowed;
      opacity: 0.5;
      &::after {
        content: inherit;
      }
    }
  }
  &-item {
    &-block {
      display: flex;
      margin: 0 0 $space-medium;
      &:last-child {
        margin: 0;
      }
    }
    &-half {
      display: flex;
      width: calc((100% - #{$space-medium}) / 2);
      margin: 0 $space-medium 0 0;
      &:last-child {
        margin: 0;
      }
      > div {
        margin: 0 $space-medium 0 0;
      }
    }
    &-text {
      width: 100%;
    }
  }
  &-summary-edit {
    display: flex;
    flex-direction: column;
    height: 100%;
    &-title {
      margin: 0 0 $space-small;
    }
    &-desc {
      height: 100%;
      margin: 0 0 $space-small;
      &-input {
        height: 100%;
        > ::v-deep div {
          height: 100%;
        }
      }
    }
    &-button {
      &-list {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      &-save {
        margin: 0 $space-base 0 0;
      }
    }
  }
  &-datasource {
    padding-top: $space-small;
    margin-top: $space-small;
    border-top: $border-main;
    &-button {
      margin: 0 0 $space-small;
    }
    &-block {
      padding: 0 0 $space-small;
      margin: 0 0 $space-small;
      border-bottom: $border-main;
    }
    &-inner {
      display: flex;
      margin: 0 0 $space-small;
      &:last-child {
        margin: 0;
      }
      &-host {
        overflow: hidden;
      }
    }
    &-title {
      margin: 0 0 $space-sub;
    }
    &-type {
      width: calc(50% - #{$space-small});
      margin: 0 $space-small 0 0;
      &-inner {
        margin: 0 0 $space-text;
      }
    }
    &-info {
      width: 50%;
      &-inner {
        margin: 0 0 $space-base;
      }
    }
    &-table {
      &-inner {
        margin: 0 0 $space-small;
      }
      &-labels {
        display: flex;
        flex-wrap: wrap;
        margin: $space-base 0 0;
      }
      &-label {
        max-width: 100%;
        padding: $space-base $space-small;
        margin: 0 $space-sub $space-sub 0;
        background: $line-gray;
        border-radius: 9in;
      }
    }
  }
}

.now-developing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-image {
    width: 100%;
    height: auto;
    margin-bottom: $space-medium;
  }
}
</style>
