<template>
  <div
    class="trained-ai-detail-main"
    :class="{
      'trained-ai-detail-main-text-mining-multi':
        trainedAi.summary.textMining != null &&
        trainedAi.trainConfig.predictionColumn &&
        trainedAi.trainConfig.predictionColumn.length > 1
    }"
  >
    <template v-if="noFeatureImportance">
      <DetailMetrics
        :trainedAi="trainedAi"
        class="trained-ai-detail-accuracy l-column t-row"
      />
      <DetailInfo
        :trainedAi="trainedAi"
        class="l-column b-row trained-ai-detail-info-no-feature-importance"
      />
      <div class="trained-ai-detail-sub r-column tb-row">
        <DetailPath :trainedAi="trainedAi" />
      </div>
    </template>
    <template
      v-else-if="
        trainedAi.summary.textMining &&
          Object.keys(trainedAi.summary.textMining).length > 0
      "
    >
      <div
        class="trained-ai-detail-text-mining-metrics"
        :class="{
          'trained-ai-detail-text-mining-multi-metrics':
            trainedAi.trainConfig.predictionColumn &&
            trainedAi.trainConfig.predictionColumn.length > 1
        }"
      >
        <div
          v-if="
            trainedAi.trainConfig.predictionColumn &&
              trainedAi.trainConfig.predictionColumn.length > 1
          "
          class="trained-ai-detail-text-mining-multi-input"
        >
          <select-column-box
            v-model="selectedItem"
            :title="$t('trainedAi.multi.selectedColumnWordCloud')"
            :trainedAi="trainedAi"
            :finishColumns="finishColumns"
          />
        </div>
        <DetailMetrics
          :trainedAi="trainedAi"
          :selectedColumnIndex="selectedColumnIndex"
          :loadChangeColumn="loadChangeColumn"
          :isMulti="
            trainedAi.trainConfig.predictionColumn &&
              trainedAi.trainConfig.predictionColumn.length > 1
          "
          class="trained-ai-detail-accuracy l-column t-row"
        />
      </div>
      <div class="trained-ai-detail-text-mining">
        <DetailTextMiningList
          :typeList="textMiningTypeList"
          @go-text-mining="$emit('go-text-mining', $event)"
        />
      </div>
      <div class="trained-ai-detail-sub r-column tb-row">
        <DetailPath :trainedAi="trainedAi" />
        <DetailInfo :trainedAi="trainedAi" />
      </div>
    </template>
    <template v-else-if="pageName === 'timeseries'">
      <div class="trained-ai-detail-timeseries l-column tb-row">
        <div class="trained-ai-detail-timeseries-body">
          <div
            class="
              trained-ai-detail-title-sub trained-ai-detail-title-sub-timeseries
            "
          >
            <texts
              :text="$t('trainedAiDetails.timeseries.predictionResult')"
              size="large"
              isBold
            />
          </div>
          <div
            v-if="
              trainedAi.trainConfig.predictionColumn &&
                trainedAi.trainConfig.predictionColumn.length > 1
            "
            class="trained-ai-detail-timeseries-select-box"
          >
            <select-column-box
              v-model="selectedItem"
              :finishColumns="finishColumns"
              :trainedAi="trainedAi"
            />
          </div>
          <transition-toggle-contents>
            <timeseries
              v-if="
                prediction &&
                  prediction.length > 0 &&
                  !loadChangeColumn &&
                  checkColumn
              "
              :series="prediction"
              :columnName="selectedItem"
              :height="
                trainedAi.trainConfig.predictionColumn.length > 0 ? 424 : 480
              "
            />
            <div
              v-else-if="loadChangeColumn"
              class="trained-ai-detail-timeseries-loading"
            >
              <loading />
            </div>
            <div
              v-else-if="!loadingTabContent"
              class="trained-ai-detail-timeseries-no-item"
            >
              <texts
                :text="$t('inference.result.timeseries.notFound')"
                color="gray"
              />
            </div>
          </transition-toggle-contents>
        </div>
        <div
          v-if="$recipeType.timeTransformer.includes(trainedAi.summary.type)"
          class="trained-ai-detail-timeseries-metrics"
        >
          <timeseries-metrics :trainedAi="trainedAi" checkTimeseriesColumn />
        </div>
      </div>
      <div class="trained-ai-detail-sub r-column tb-row">
        <DetailColumns
          v-if="
            trainedAi.trainConfig &&
              trainedAi.trainConfig.predictionColumn &&
              trainedAi.trainConfig.predictionColumn.length > 0
          "
          :trainedAi="trainedAi"
          :selectedColumnIndex="selectedColumnIndex"
        />
        <DetailPath class="trained-ai-detail-sub-path" :trainedAi="trainedAi" />
        <DetailInfo :trainedAi="trainedAi" />
      </div>
    </template>
    <template v-else>
      <template
        v-if="
          trainedAi.trainConfig &&
            trainedAi.trainConfig.predictionColumn &&
            trainedAi.trainConfig.predictionColumn.length > 1
        "
      >
        <card-base class="trained-ai-detail-multi">
          <div class="trained-ai-detail-multi-input">
            <select-column-box
              v-model="selectedItem"
              :title="$t('trainedAi.multi.selectedColumn')"
              :trainedAi="trainedAi"
              :finishColumns="finishColumns"
            />
          </div>
          <DetailMetrics
            :trainedAi="trainedAi"
            :selectedColumnIndex="selectedColumnIndex"
            :loadChangeColumn="loadChangeColumn"
            isMulti
            class="trained-ai-detail-multi-metrics"
          />
          <div class="trained-ai-detail-multi-line" />
          <feature-importance
            :predictionColumns="trainedAi.summary.predictionColumns"
            :featureImportance="trainedAi.summary.featureImportance"
            :selectedColumnIndex="selectedColumnIndex"
            :loadFeatureImportance="loadChangeColumn"
            :maxItem="8"
            class="trained-ai-detail-multi-importance"
          />
        </card-base>
      </template>
      <template v-else>
        <DetailMetrics :trainedAi="trainedAi" />
        <feature-importance
          :featureImportance="trainedAi.summary.featureImportance"
          :maxItem="7"
          class="l-column b-row trained-ai-detail-feature-importance"
        />
      </template>
      <div
        class="trained-ai-detail-sub r-column tb-row"
        :class="{
          'trained-ai-detail-sub-no-data': !(
            trainedAi.trainConfig &&
            trainedAi.trainConfig.predictionColumn &&
            trainedAi.trainConfig.predictionColumn.length > 0
          )
        }"
      >
        <DetailColumns
          v-if="
            trainedAi.trainConfig &&
              trainedAi.trainConfig.predictionColumn &&
              trainedAi.trainConfig.predictionColumn.length > 0
          "
          :trainedAi="trainedAi"
          :selectedColumnIndex="selectedColumnIndex"
        />
        <DetailPath :trainedAi="trainedAi" />
        <DetailInfo :trainedAi="trainedAi" />
      </div>
    </template>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import FeatureImportance from '@/components/organisms/card-fi.vue'
import DetailInfo from './detail/detail-info.vue'
import DetailPath from './detail/detail-path.vue' // 折り返しの挙動実装中あとi18n
import DetailMetrics from './detail/detail-metrics.vue'
import DetailColumns from './detail/detail-columns.vue'
import DetailTextMiningList from './detail/detail-text-mining-list.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import timeseries from '@/components/organisms/graph/timeseries.vue'
import Loading from '@/components/atoms/loading.vue'
import cardBase from '@/components/atoms/card-base.vue'
import selectColumnBox from '@/components/organisms/trained-ai-common/select-column-box'
import timeseriesMetrics from './detail/timeseries-metrics.vue'

import { DEEP_RECIPE_TYPE } from '@/lib/training.js'

export default {
  components: {
    texts,
    FeatureImportance,
    DetailInfo,
    DetailPath,
    DetailMetrics,
    DetailColumns,
    DetailTextMiningList,
    transitionToggleContents,
    timeseries,
    Loading,
    cardBase,
    selectColumnBox,
    timeseriesMetrics
  },
  data() {
    return {
      isTipsShow: '',
      displayType: 'distribution',
      displayMin: 0,
      displayMax: 10,
      themeColor: '#890491',
      subColor: '#049185',
      wordCloud: null,
      selectedItem: null,
      loadingTabContent: true
    }
  },
  props: {
    trainedAi: Object,
    pageName: String,
    finishColumns: Array,
    loadChangeColumn: Boolean,
    selectedColumnIndex: Number
  },
  methods: {
    scale(e) {
      return Math.round(e * 10) / 10
    },
    maxScale() {
      return Math.ceil(this.trainedAi.summary.featureImportance.size * 10) / 10
    },
    scaleWidth(e) {
      return (this.scale(e) / this.maxScale()) * 100
    },
    isHoverGraph(e) {
      this.isTipsShow = e
    },
    isLeaveGraph() {
      this.isTipsShow = ''
    }
  },
  computed: {
    noFeatureImportance() {
      return DEEP_RECIPE_TYPE.indexOf(this.trainedAi.summary.type) > -1
    },
    prediction() {
      const forecast = this.trainedAi?.result?.forecast
      if (!forecast) return null
      const observations = []
      const prediction = forecast.map((d, index) => {
        const tz = new Date().getTimezoneOffset() * 60000
        const date = new Date(d.ds + tz) // UTC
        const ob = this.trainedAi.result.observations[d.ds]
        if (ob != null) {
          observations.push({
            x: date,
            y: ob,
            y_lower: ob,
            y_upper: ob
          })
        }
        return {
          x: date,
          y: d.yhat,
          y_lower: d.yhat_lower,
          y_upper: d.yhat_upper
        }
      })
      const fixPrediction = prediction.filter((value) => {
        return value.y != null
      })
      return [
        {
          label: this.$i18n.t('graph.timeseries.predictions'),
          data: fixPrediction,
          color: this.themeColor
        },
        {
          label: this.$i18n.t('graph.timeseries.observations'),
          data: observations,
          color: this.subColor
        }
      ]
    },
    checkColumn() {
      if (!this.trainedAi?.trainConfig?.predictionColumn) return false
      if (this.trainedAi.trainConfig.predictionColumn.length <= 1) return true
      const target = this.selectedItem
      return this.trainedAi.trainConfig.predictionColumn.indexOf(target) !== -1
    },
    textMiningTypeList() {
      if (this.trainedAi.summary?.textMining == null) return {}
      return this.trainedAi.summary?.textMining.typeList
    }
  },
  mounted() {
    const configColumn = this.trainedAi?.trainConfig?.predictionColumn ?? []
    if (configColumn.length === 0) return
    this.selectedItem = configColumn[this.selectedColumnIndex]
    this.$nextTick(() => {
      // 初回の描画完了まで待機する
      this.loadingTabContent = false
    })
  },
  watch: {
    selectedItem(newVal) {
      this.$emit('change-column', newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.l-column {
  grid-column: 1;
}
.r-column {
  grid-column: 2;
}
.t-row {
  grid-row: 1;
}
.b-row {
  grid-row: 2;
}
.tb-row {
  grid-row: 1 / 3;
}

.trained-ai-detail {
  &-main {
    overflow: hidden;
    display: grid;
    grid-template-areas:
      'detail sub'
      'detail info';
    grid-template-columns: minmax(auto, adjustVW(804)) minmax(
        auto,
        adjustVW(804)
      );
    grid-template-rows: adjustVH(188) 1fr;
    grid-column-gap: $space-medium;
    grid-row-gap: $space-medium;
    height: 100%;
    padding: 0 $space-medium $space-medium;
    &-text-mining-multi {
      grid-template-rows: minmax(0, auto) 1fr;
    }
  }
  &-accuracy {
    height: 100%;
  }
  &-feature-importance {
    display: flex;
    flex-direction: column;
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    &-title {
      margin: 0 0 $space-small;
    }
    &-wrap {
      position: relative;
      overflow: hidden;
      height: 100%;
    }
    &-block {
      position: relative;
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: calc(100% - #{$space-medium});
      padding: 0 0 $space-base;
      margin: $space-min 0 $space-medium;
      z-index: 2;
      @include scrollbar;
    }
    &-inner {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0 0 $space-medium;
      &:first-child {
        margin: $space-base 0 $space-medium;
      }
      &:last-child {
        margin: 0 0 $space-base;
      }
    }
    &-name {
      overflow: hidden;
      width: adjustVW(168);
      margin: 0 $space-small 0 0;
    }
    &-bar {
      width: calc(100% - #{adjustVW(168) + $space-small + $space-base});
      &-inner {
        --width: 100%;
        position: relative;
        width: calc(var(--width) - #{$space-small});
        height: adjustVH(16);
        background: $line-emphasis;
        border-radius: adjustVW(4);
      }
    }
    &-tooltip {
      &-wrap {
        position: absolute;
        top: -200%;
        left: 50%;
        width: adjustVW(240);
        text-align: center;
        transform: translateX(-50%);
      }
      &-inner {
        width: fit-content;
        max-width: 100%;
      }
    }
    &-bg {
      position: absolute;
      top: 0;
      right: $space-small;
      width: calc(100% - #{adjustVW(168) + $space-small});
      height: 100%;
      z-index: 1;
      &-scale {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: $space-min 0 0;
        &-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: adjustVW(32);
          height: adjustVH(24);
          color: $text-sub;
          font-size: $text-min;
          @include text-crop;
        }
      }
      &-line {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: calc(100% - #{adjustVW(24)});
        &-item {
          position: relative;
          width: adjustVW(32);
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 1px;
            height: 100%;
            background: $line-gray;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
  &-text-mining {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    + .trained-ai-detail-sub {
      grid-template-rows: minmax(auto, adjustVH(388)) minmax(auto, min-content);
    }
    &-metrics {
      display: flex;
    }
    &-multi {
      &-input {
        padding-right: $space-medium;
        margin-right: $space-medium;
        border-right: $border-sub;
      }
      &-metrics {
        padding: $space-small;
        background: #fff;
        border-radius: adjustVW(16);
        box-shadow: $box-shadow-main;
      }
    }
  }
  &-timeseries {
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-medium;
    height: fit-content;
    max-height: 100%;
    &-body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: fit-content;
      max-height: 100%;
      padding: $space-small;
      background: $background;
      border-radius: adjustVW(16);
      box-shadow: $box-shadow-main;
    }
    + .trained-ai-detail-sub {
      grid-template-rows: adjustVH(188) 1fr;
    }
    &-select-box {
      width: adjustVW(320);
      height: adjustVW(56);
      margin: $space-text 0 $space-sub;
    }
    &-loading {
      height: adjustVH(480);
    }
    &-no-item {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: adjustVH(480);
    }
    &-metrics {
      flex-shrink: 0;
    }
  }
  &-sub {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: min-content 1fr min-content;
    grid-row-gap: $space-medium;
    &-no-data {
      grid-template-rows: minmax(auto, adjustVH(388)) minmax(auto, min-content);
    }
    &-path {
      .tb-row & {
        min-height: 0;
        max-height: adjustVH(390);
      }
    }
  }
  &-info-no-feature-importance {
    height: fit-content;
    max-height: 100%;
    + .trained-ai-detail-sub {
      grid-template-rows: minmax(auto, adjustVH(388)) minmax(auto, min-content);
    }
  }
  &-title-sub-timeseries {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-multi {
    display: grid;
    grid-template-areas:
      'input metrics'
      'line line'
      'importance importance';
    grid-template-columns: adjustVW(288) 1fr;
    grid-template-rows: max-content max-content 1fr;
    grid-column-gap: $space-large;
    grid-row-gap: $space-medium;
    height: 100%;
    grid-area: detail;
    &-input {
      &-title {
        margin-bottom: $space-sub;
      }
    }
    &-line {
      height: adjustVW(1);
      background-color: $line-gray;
      grid-area: line;
    }
    &-importance {
      padding: 0;
      background: none;
      box-shadow: none;
      grid-area: importance;
      &-none {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-area: importance;
      }
    }
  }
}
</style>
