<template>
  <div class="project-detail-wrap">
    <top
      :projectInfo="projectInfo"
      :accountInfo="accountInfo"
      :projectFormValidate="editInfo.projectFormValidate"
      :editLoading="loading.editLoading"
      @change-description="$emit('change-description', $event)"
      @delete-project="$emit('delete-project')"
      @input-edit-form="$emit('input-edit-form', $event)"
      @tag-add="$emit('tag-add', $event)"
      @tag-remove="$emit('tag-remove', $event)"
    />
    <div class="project-detail-border">
      <div class="project-detail-border-inner" />
    </div>
    <div
      v-if="sortedTrainedAi"
      class="project-detail-body"
      :class="{
        'project-detail-body-optimization': checkOptimization
      }"
    >
      <model-outline
        :id="sortedTrainedAi.summary.id"
        class="project-detail-main"
        :name="sortedTrainedAi.summary.name"
        :predictionColumn="sortedTrainedAi.trainConfig.predictionColumn"
        :optimizationColumn="sortedTrainedAi.summary.optimizationColumns"
        :createTime="sortedTrainedAi.summary.createTime"
        :progressTraining="progressTraining"
        :runningTasks="runningTasks"
        :checkOptimization="checkOptimization"
        :clusteringInfo="sortedTrainedAi.summary.clusteringInfo"
        @reenter-click="$emit('reenter-click', $event)"
      />
      <template v-if="!checkOptimization">
        <template v-if="sortedTrainedAi.summary.type === 'NO_ACCURACY'">
          <model-summary-text-mining
            :trainedAi="sortedTrainedAi"
            @go-text-mining="$emit('go-text-mining', $event)"
          />
        </template>
        <template v-else>
          <model-summary
            :sortedTrainedAi="sortedTrainedAi"
            :selectedColumnIndex="internalSelectedColumn"
            :loadChangeColumn="loading.changeColumn"
            @change-column="changeColumn"
          />
        </template>
        <card-base class="project-detail-dataset">
          <template v-if="sortedTrainedAi.summary.datasets">
            <model-dataset
              :dataset="sortedTrainedAi.summary.datasets[0]"
              :rowsPerPage="rowsPerPage"
            />
          </template>
          <template v-else>
            <loading />
          </template>
        </card-base>
      </template>
      <template v-else>
        <model-summary-optimization
          :accountInfo="accountInfo"
          :sortedTrainedAi="sortedTrainedAi"
          :optimizationInfo="optimizationInfo"
          :optimizationResult="optimizationResult"
          @change-page="$emit('change-page', $event)"
          @change-filter-value="$emit('change-filter-value', $event)"
          @download-result="$emit('download-result', $event)"
          @load-optimization-result="$emit('load-optimization-result', $event)"
        />
      </template>
    </div>
    <div
      v-else-if="projectInfo && projectInfo.numAIs === 0"
      class="project-detail-body-none"
    >
      <images
        class="project-detail-body-none-image"
        imageName="createNewItem"
      />
      <texts
        class="project-detail-body-none-text"
        :text="$t('projectDetail.nonTrainedAis')"
      />
      <button-main
        :text="$t('projectDetail.restartFirstTraining')"
        type="emphasis"
        @click="restart"
      />
    </div>
    <loading v-else />
  </div>
</template>

<script>
import Top from './top'
import ModelOutline from './model-outline'
import ModelDataset from './model-dataset'
import ModelSummary from './model-summary'
import ModelSummaryOptimization from './model-summary-optimization'
import ModelSummaryTextMining from './model-summary-text-mining'
import images from '@/components/atoms/images'
import cardBase from '@/components/atoms/card-base.vue'
import loading from '@/components/atoms/loading.vue'
import ButtonMain from '@/components/atoms/button-main.vue'

import { restartTraining } from '@/lib/progress-training'
import { mapActions } from 'vuex'

export default {
  components: {
    Top,
    ModelOutline,
    ModelDataset,
    ModelSummary,
    ModelSummaryOptimization,
    ModelSummaryTextMining,
    images,
    cardBase,
    loading,
    ButtonMain
  },
  data() {
    return {
      internalSelectedColumn: 0,
      progressTraining: null
    }
  },
  props: {
    projectInfo: Object,
    sortedTrainedAi: Object,
    accountInfo: Object,
    editInfo: Object,
    rowsPerPage: Number,
    runningTasks: Array,
    loading: Object,
    selectedColumnIndex: Number,
    optimizationInfo: Object,
    optimizationResult: Object
  },
  computed: {
    checkOptimization() {
      if (!this.sortedTrainedAi) return false
      return this.sortedTrainedAi?.summary?.is_optimization
    }
  },
  methods: {
    ...mapActions('models', ['getProgressTraining', 'setProgressTraining']),

    restart() {
      this.$gtmDataLayer.sendEvent('startTraining', 'fromProjectDetail')
      if (this.progressTraining) {
        restartTraining(this.progressTraining)
      } else {
        this.setProgressTraining({
          item: this.projectInfo?.id,
          setType: 'project'
        })

        this.$router.push({ name: 'datasetSetting' })
      }
    },
    changeColumn(column) {
      let columnIndex = 0
      if (
        this.sortedTrainedAi.summary.predictionColumns &&
        this.sortedTrainedAi.summary.predictionColumns.length > 1
      ) {
        columnIndex =
          this.sortedTrainedAi.summary.predictionColumns.indexOf(column)
      }
      this.internalSelectedColumn = columnIndex
      this.$emit('change-column', column)
    },
    setModelSettingInfo: async function (projectId) {
      if (projectId) {
        this.progressTraining = await this.getProgressTraining({
          projectId
        })
      } else {
        this.progressTraining = null
      }
    }
  },
  beforeMount: async function () {
    await this.setModelSettingInfo(this.projectInfo?.id)
  }
}
</script>

<style lang="scss" scoped>
.project-detail {
  &-wrap {
    overflow: hidden;
    display: grid;
    grid-template-rows: minmax(auto, adjustVH(272)) adjustVW(34) minmax(
        auto,
        1fr
      );
    width: 100%;
    height: 100%;
    .c-page-body {
      overflow: hidden;
    }
  }
  &-body {
    overflow: hidden;
    display: grid;
    grid-template-areas:
      'top top'
      'summary dataset';
    grid-template-columns: minmax(auto, adjustVW(804)) minmax(
        auto,
        adjustVW(804)
      );
    grid-template-rows: auto 1fr;
    grid-column-gap: $space-medium;
    grid-row-gap: $space-medium;
    height: 100%;
    padding: 0 $space-medium $space-medium;
    &-none {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      &-image {
        width: adjustVW(560);
        height: fit-content;
        margin-bottom: $space-large;
      }
      &-text {
        margin-bottom: $space-small;
      }
    }
    &-optimization {
      grid-template-columns: 1fr;
      grid-template-rows: auto minmax(0, 1fr);
      grid-column-gap: 0;
    }
  }
  &-border {
    display: flex;
    align-items: center;
    padding: 0 $space-medium;
    &-inner {
      width: 100%;
      height: adjustVH(2);
      background: $line-gray;
    }
  }
  &-main {
    grid-area: top;
  }
  &-dataset {
    overflow: hidden;
    display: flex;
    grid-area: dataset;
  }
}
</style>
