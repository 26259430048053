<template>
  <div class="classification-result-card-list">
    <template v-if="targetType === 'image'">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="classification-result-image-card-block"
      >
        <image-classification-card
          v-bind="imageCardData(index)"
          :modelInfo="modelInfo"
          :posBottom="posBottomCard(index)"
        />
      </div>
    </template>
    <template v-else>
      <div
        v-for="(item, index) in items"
        :key="index"
        class="classification-result-text-card-block"
      >
        <text-classification-card
          v-bind="textCardData(index)"
          :posBottom="posBottomCard(index)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import imageClassificationCard from '@/components/organisms/inference/result/classfication/image-classification-card.vue'
import textClassificationCard from '@/components/organisms/inference/result/classfication/text-classification-card.vue'

export default {
  components: {
    imageClassificationCard,
    textClassificationCard
  },
  props: {
    modelInfo: Object,
    targetType: String,
    items: Array,
    posBottomPopupBorder: Number
  },
  methods: {
    posBottomCard: function (index) {
      const items = this.items
      if (items.length < this.posBottomPopupBorder) return false
      return items.length - index < this.posBottomPopupBorder - 1
    },
    imageCardData(index) {
      return {
        ...this.items[index]
      }
    },
    textCardData(index) {
      return {
        ...this.items[index]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.classification-result-card-list {
  overflow-y: scroll;
  display: grid;
  grid-template-columns: repeat(2, calc(50% - #{$space-medium / 2}));
  grid-template-rows: max-content;
  grid-column-gap: $space-medium;
  grid-row-gap: $space-medium;
  width: adjustVW(1240);
  height: 100%;
  padding-right: $space-sub;
  padding-left: $space-medium;
  @include scrollbar;
}

.classification-result-image-card-block {
  width: 100%;
  height: calc(#{adjustVH(272)} + #{$space-sub * 2});
  &:last-of-type {
    margin-bottom: $space-base; // 最後をシャドウが消えない程度の少しのマージンにしている
  }
}

.classification-result-text-card-block {
  width: 100%;
  height: adjustVH(314);
  &:last-of-type {
    margin-bottom: $space-base; // 最後をシャドウが消えない程度の少しのマージンにしている
  }
}
</style>
