<template>
  <div>
    <div class="input-title">
      <texts
        :text="$t('trainedAi.optimization.expression.title', { count: count })"
        size="small"
        color="gray"
      />
      <confirm-icon-button
        iconName="delete"
        :text="$t('common.deleteButton')"
        color="caution"
        size="min"
        @click="$emit('delete-expression-item'), $emit('check-expressions')"
      />
    </div>
    <div v-tooltip.top-start="tooltipContents" class="input-tips">
      <div
        class="input-wrap"
        :class="{
          'input-wrap-focus': inFocus,
          'input-wrap-error': isError && !inFocus
        }"
      >
        <div class="input-dummy">
          <span
            v-for="(text, index) in splitTexts"
            :key="index"
            :class="checkClass(text)"
          >{{ text }}</span>
        </div>
        <textarea
          class="input"
          :value="internalVal"
          :placeholder="$t('trainedAi.optimization.expression.placeholder')"
          @input="setValue"
          @focus="focusInput"
          @blur="blurInput"
          @click="clickInput"
        />
      </div>
    </div>
    <div class="expression-block">
      <div class="expression-list">
        <dot-button
          v-for="(type, index) in expressionTypes"
          :key="index"
          v-tooltip="tipsTypes(type)"
          class="expression-item"
          :text="type"
          size="default"
          color="emphasis"
          isBold
          @click="clickExpression(type)"
        />
      </div>
      <input
        class="column-input"
        :value="columnSelect"
        :placeholder="$t('trainedAi.optimization.expression.placeholderColumn')"
        @input="searchColumn"
      >
    </div>
    <div class="column-list">
      <transition-group name="column-move">
        <dot-button
          v-for="(column, index) in showColumns"
          :key="index + column"
          class="column-item"
          :text="column"
          color="green"
          @click="clickColumn(column)"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
import confirmIconButton from '@/components/atoms/confirm-icon-button'
import dotButton from '@/components/atoms/dot-button'

export default {
  components: {
    confirmIconButton,
    dotButton
  },
  data() {
    return {
      internalVal: this.value,
      inFocus: false,
      selectArea: 0,
      columnSelect: null
    }
  },
  props: {
    columns: Array,
    value: String,
    count: Number,
    isError: Boolean
  },
  computed: {
    splitTexts() {
      const regs = /("[^"]+"|\+|-|\/|\(|\)|\*)/
      let msg = null
      if (this.value && this.value.length > 0) {
        const val = this.value + '\u200b' // 改行で文字分の高さを用意する
        msg = val.split(regs)
      }
      return msg
    },
    expressionTypes() {
      return [
'+',
'-',
'/',
'*',
'(',
')'
]
    },
    showColumns() {
      if (this.columnSelect) {
        return this.columns.filter((item) => {
          return item.indexOf(this.columnSelect) > -1
        })
      } else {
        return this.columns
      }
    },
    checkActiveTips() {
      return this.isError && !this.inFocus
    },
    tooltipContents() {
      if (this.checkActiveTips) {
        return {
          content: `<div class="tooltip-item-mergin-small">${this.$t(
            'trainedAi.optimization.expression.error'
          )}</div><a class="tooltip-text-link" href="${
            this.$urls.manual
          }train-training/最適化の式での条件設定/" target="_blank" rel="noopener">${this.$t(
            'trainedAi.optimization.expression.errorLink'
          )}</a>
          `,
          classes: 'tooltip-long-text tooltip-expression-error',
          trigger: 'hover',
          delay: { show: 200, hide: 200 },
          autoHide: false
        }
      } else {
        return null
      }
    }
  },
  methods: {
    clickColumn(column) {
      let msg = this.value
      if (!this.value || this.value.length === 0) {
        msg = `"${column}"`
      } else if (this.selectArea !== this.value.length) {
        msg =
          msg.slice(0, this.selectArea) +
          `"${column}"` +
          msg.slice(this.selectArea)
      } else {
        msg = msg + `"${column}"`
      }
      this.selectArea = this.selectArea + column.length + 2
      this.$emit('input', msg)
      this.$nextTick(() => {
        this.$emit('check-expressions')
      })
    },
    clickExpression(type) {
      let msg = this.value
      let expressionItem = type
      if (!this.value || this.value.length === 0) {
        expressionItem = type + ' '
        msg = expressionItem
      } else if (this.selectArea !== this.value.length) {
        const first = msg.slice(0, this.selectArea)
        const firstEmpty = first.slice(-1).match(/\s/) ? '' : ' '
        const last = msg.slice(this.selectArea)
        const lastEmpty = last.slice(0).match(/\s/) ? '' : ' '

        expressionItem = firstEmpty + expressionItem + lastEmpty
        msg = first + expressionItem + last
      } else {
        const checkEmpty = msg.slice(-1).match(/\s/) ? '' : ' '
        expressionItem = checkEmpty + type + ' '
        msg = msg + expressionItem
      }
      this.selectArea = this.selectArea + expressionItem.length
      this.$emit('input', msg)
      this.$nextTick(() => {
        this.$emit('check-expressions')
      })
    },
    setValue(val) {
      const msg = val.target.value
      this.$emit('input', msg)
      this.$nextTick(() => {
        this.selectArea = val.target.selectionStart
      })
    },
    checkClass(str) {
      const regColumn = /"[^"]+"/
      const regExpression = /\+|-|\/|\*|\(|\)/
      const trimLastText = (str) => {
        if (str.indexOf('\u200b')) {
          return !isNaN(str.replace('\u200b', ''))
        } else {
          return !isNaN(str)
        }
      }
      const regColumnTest = regColumn.test(str)
      if (regColumnTest) {
        const fixColumnText = str.replace(/"([^"]+)"/, '$1')
        if (this.columns.includes(fixColumnText)) {
          return 'input-text-green'
        }
      } else if (regExpression.test(str)) {
        return 'input-text-gray'
      } else if (trimLastText(str)) {
        return 'input-text-green'
      }
    },
    focusInput() {
      this.inFocus = true
    },
    blurInput() {
      this.inFocus = false
      this.$emit('check-expressions')
    },
    clickInput(e) {
      this.selectArea = e.target.selectionStart
    },
    tipsTypes(type) {
      return {
        content: type
      }
    },
    searchColumn(e) {
      this.columnSelect = e.target.value
    }
  },
  watch: {
    value(newVal) {
      this.internalVal = newVal
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  padding: $space-sub;
  color: transparent;
  font-size: $text-base;
  word-break: break-all;
  white-space: pre-wrap;
  resize: none;
  z-index: $z-expression-base + 2;
  caret-color: $text-main;
  &::placeholder {
    color: $border-gray;
  }
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-dummy {
    position: relative;
    padding: $space-sub;
    color: $red;
    font-size: $text-base;
    pointer-events: none;
    z-index: $z-expression-base + 1;
    > span {
      word-break: break-all;
      overflow-wrap: break-word;
      white-space: pre-wrap;
    }
  }
  &-wrap {
    position: relative;
    min-height: adjustVW(52) + $space-sub * 2;
    background: $background-sub;
    border-radius: adjustVW(8);
    z-index: $z-expression-base;
    transition: box-shadow $transition-base;
    &-focus {
      background: $background;
      box-shadow: $border-radius-emphasis inset, $box-shadow-hover;
    }
    &-error {
      background: $background;
      box-shadow: 0 0 0 adjustVW(2) $red inset, $box-shadow-hover;
    }
  }
  &-text {
    &-green {
      color: $green;
    }
    &-gray {
      color: $text-sub;
    }
  }
  &-tips {
    padding-top: $space-sub;
    padding-bottom: adjustVW(20);
    margin-top: -$space-base;
    margin-bottom: -$space-base;
  }
}

.expression {
  &-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: adjustVW(14);
  }
  &-list {
    display: flex;
    grid-column-gap: $space-base;
  }
}

.column {
  &-list {
    overflow-y: scroll;
    height: adjustVW(104);
    @include scrollbar;
    > span {
      display: flex;
      flex-wrap: wrap;
      grid-column-gap: $space-base;
      grid-row-gap: $space-base;
    }
  }
  &-item {
    height: fit-content;
  }
  &-input {
    width: adjustVW(180);
    padding: $space-base $space-sub;
    background-color: $background-sub;
    font-size: $text-min;
    border-radius: adjustVW(4);
    &::placeholder {
      color: $border-gray;
    }
  }
  &-move {
    &-enter-active,
    &-leave-active {
      transition: transform $transition-base, opacity $transition-base;
    }
    &-enter,
    &-leave-to {
      opacity: 0;
      transform: translateY($space-small);
    }
  }
}
</style>
