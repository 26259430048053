<template>
  <div class="top">
    <page-top
      :title="$t('organization.title')"
      iconName="backward"
      isBackButton
      size="min"
    />
  </div>
</template>

<script>
import pageTop from '@/components/atoms/page-top'

export default {
  components: {
    pageTop
  }
}
</script>

<style lang="scss" scoped>
.top {
  padding: 0 $space-medium;
}
</style>
