var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"service-detail-edit-wrap"},[_c('div',{staticClass:"service-detail-edit-inner"},[_c('div',{staticClass:"service-detail-edit-title"},[_c('texts',{attrs:{"text":_vm.$t('serviceDetail.editService'),"size":"large"}})],1),_c('div',{staticClass:"service-detail-edit-block"},[_c('input-box-validation',{attrs:{"accept":_vm.validate.accept,"error":_vm.validate.error,"title":_vm.$t('serviceDetail.serviceName'),"popupView":"","isNameValidation":"","nameValidationSuggests":_vm.serviceFormValidate.suggests},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('div',{staticClass:"service-detail-edit-block"},[_c('input-box',{attrs:{"isTextArea":"","isGray":"","title":_vm.$t('serviceDetail.serviceDetail')},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('div',{staticClass:"service-detail-edit-block-list"},[_c('div',{staticClass:"service-detail-edit-block-item"},[_c('div',{staticClass:"service-detail-edit-inner-title"},[_c('texts',{attrs:{"text":_vm.$t('serviceList.popup.addNewService.AItoUse'),"size":"small","color":"gray"}})],1),_c('selectBox',{staticClass:"service-detail-edit-block-item-input",attrs:{"valueKey":"id","items":_vm.modelList,"isGray":"","scrollBar":"","showTop":""},model:{value:(_vm.trainedAI),callback:function ($$v) {_vm.trainedAI=$$v},expression:"trainedAI"}})],1),_c('div',{staticClass:"service-detail-edit-block-item"},[_c('transition-toggle-contents',[(!_vm.isNotAvailableTrainedAi)?_c('div',{key:"loading"},[_c('div',{staticClass:"service-detail-edit-inner-title"},[_c('texts',{attrs:{"text":_vm.$t(
                    'inference.settings.selectInferenceType.inferenceType.title'
                  ),"size":"small","color":"gray"}})],1),_c('transition-toggle-contents',[(
                  _vm.inferenceTypeOptions.length > 0 && !_vm.loadingTrainedAiDetail
                )?_c('select-box',{staticClass:"service-detail-edit-block-item-input",attrs:{"placeholder":_vm.$t('serviceList.popup.addNewService.typePlaceholder'),"items":_vm.inferenceTypeOptions,"isGray":"","scrollBar":"","showTop":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}):_c('loading-icon')],1)],1):_c('div',{key:"caution",staticClass:"service-detail-edit-caution"},[_c('texts',{staticClass:"service-detail-edit-caution-text",attrs:{"text":_vm.$t('common.disabled.NOT_AVAILABLE_CUSTOMBLOCK_FOR_INFERENCE'),"color":"caution","size":"min"}})],1)])],1)]),_c('div',{staticClass:"service-detail-edit-preproc"},[_c('checkbox-base',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.disabledTips),expression:"disabledTips"}],staticClass:"service-detail-edit-preproc-check",class:{
          'service-detail-edit-preproc-check-disabled': _vm.disabledTips !== null
        },attrs:{"text":_vm.$t('serviceList.popup.addNewService.selectPreprocessing'),"checked":_vm.opened,"isDisabled":_vm.disabledTips !== null},on:{"input":_vm.inputCheck}}),_c('transition-toggle-slot-contents',[(_vm.opened)?_c('div',{staticClass:"service-detail-edit-preproc-input-wrap"},[_c('div',{staticClass:"service-detail-edit-inner-title"},[_c('texts',{attrs:{"text":_vm.$t(
                  'serviceList.popup.addNewService.PreprocessingToUseOptional'
                ),"size":"small","color":"gray"}})],1),_c('div',[_c('select-box',{staticClass:"service-detail-edit-preproc-input",attrs:{"isGray":"","scrollBar":"","showTop":"","valueKey":"id","items":_vm.preprocessingList,"placeholder":_vm.$t(
                  'serviceList.popup.addNewService.PreprocessingToUsePlaceholder'
                ),"title":_vm.$t(
                  'serviceList.popup.addNewService.PreprocessingToUseOptional'
                )},on:{"select-box-open":function($event){return _vm.$emit('select-box-get-preprocessings')}},model:{value:(_vm.preprocessing),callback:function ($$v) {_vm.preprocessing=$$v},expression:"preprocessing"}})],1)]):_vm._e()])],1),_c('div',{staticClass:"service-detail-edit-button-block"},[_c('div',{staticClass:"service-detail-edit-button-inner"},[_c('button-main',{attrs:{"type":"emphasis","isDisabled":_vm.disabledSubmit,"text":_vm.$t('serviceDetail.button.save')},on:{"click":_vm.save}})],1),_c('div',{staticClass:"service-detail-edit-button-inner"},[_c('button-main',{attrs:{"type":"sub","text":_vm.$t('common.cancel')},on:{"click":function($event){return _vm.$emit('cancel')}}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }