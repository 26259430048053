<template>
  <div class="outline-wrap">
    <div class="outline-title-wrap">
      <icons class="outline-title-icon" iconName="trainedAi" :size="36" />
      <texts class="outline-title-text" :text="name" size="big" />
      <texts
        class="outline-title-desc"
        :text="$t('projectDetail.mostResentlyAi')"
        size="small"
        color="gray"
      />
    </div>
    <div class="outline-main">
      <div class="outline-inner">
        <div
          v-if="displayColumns.length > 0 && clusteringInfo == null"
          class="outline-item"
        >
          <texts
            class="outline-title-text"
            :text="
              checkOptimization
                ? $t('training.config.detail.optimizationColumn')
                : $t('projectDetail.objectiveVariable')
            "
            size="small"
            color="gray"
          />
          <div
            class="outline-col-list"
            :class="{
              'outline-col-list-multi': displayColumns.length > 1
            }"
          >
            <texts
              v-for="column in displayColumns"
              :key="column"
              class="outline-col-item"
              :text="column"
            />
          </div>
        </div>
        <div v-else-if="clusteringInfo != null" class="clustering">
          <text-with-title
            :title="$t('clustering.setting.result.maxClass')"
            :text="checkMaxClusterCount"
          />
          <text-with-title
            :title="$t('clustering.setting.result.technique')"
            :text="clusteringInfo.type"
          />
        </div>
        <text-with-title :title="$t('common.createTime')">
          <a-time :value="createTime" />
        </text-with-title>
        <div
          v-if="buttonText != null"
          v-tooltip="disabledTips"
          class="outline-progress"
          :class="{
            'outline-progress-disabled': checkDifferenceOptimization
          }"
        >
          <button-main
            class="outline-progress-exist"
            :text="buttonText"
            type="emphasis"
            :isDisabled="checkDifferenceOptimization"
            @click="!checkDifferenceOptimization ? restart() : ''"
          />
        </div>
      </div>
      <router-link
        class="outline-link"
        :to="{ name: 'trainedAiDetail', params: { id: id } }"
      >
        <text-with-icon
          :text="$t('projectDetail.showDetail')"
          iconName="forward"
          size="small"
          pos="right"
          color="link"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import icons from '@/components/atoms/icon'
import buttonMain from '@/components/atoms/button-main'
import textWithTitle from '@/components/molecules/text-with-title'
import textWithIcon from '@/components/molecules/text-with-icon'

export default {
  components: {
    texts,
    icons,
    buttonMain,
    textWithTitle,
    textWithIcon
  },
  props: {
    name: String,
    createTime: String,
    predictionColumn: Array,
    id: String,
    projectInfo: Object,
    progressTraining: Object,
    runningTasks: Array,
    checkOptimization: Boolean,
    optimizationColumn: Array,
    clusteringInfo: Object
  },
  computed: {
    buttonText() {
      if (this.runningTasks?.length > 0) {
        if (this.runningTasks[0]?.isDifferentOptimization) {
          return this.$t('projectDetail.restartOptimization')
        } else {
          return this.$t('projectDetail.reenterRunningTraining')
        }
      } else if (this.progressTraining) {
        return this.$t('projectDetail.restartTraining')
      } else {
        return null
      }
    },
    checkDifferenceOptimization() {
      return (
        this.runningTasks &&
        this.runningTasks.length > 0 &&
        this.runningTasks[0]?.isDifferentOptimization
      )
    },
    disabledTips() {
      if (this.checkDifferenceOptimization) {
        return this.$t('projectDetail.restartOptimizationTemporary')
      } else {
        return null
      }
    },
    displayColumns() {
      if (this.checkOptimization) {
        return this.optimizationColumn ?? []
      } else {
        return this.predictionColumn ?? []
      }
    },
    checkMaxClusterCount() {
      if (
        this.clusteringInfo == null ||
        Object.keys(this.clusteringInfo).length === 0
      )
        return null
      const sort = Object.values(this.clusteringInfo.resultList).sort(
        (x, y) => {
          return y.nClusters - x.nClusters
        }
      )
      return sort[0].nClusters
    }
  },
  methods: {
    async restart() {
      this.$emit('reenter-click', this.progressTraining)
    }
  }
}
</script>

<style lang="scss" scoped>
.outline {
  &-main {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &-title {
    &-wrap {
      display: flex;
      align-items: center;
      width: 50%;
      margin-bottom: $space-small;
    }
    &-icon {
      margin-right: $space-sub;
    }
    &-text {
      margin-right: $space-medium;
    }
    &-desc {
      flex-shrink: 0;
    }
  }
  &-inner {
    display: flex;
  }
  &-item {
    overflow: hidden;
    max-width: adjustVW(390);
    margin: 0 $space-medium 0 0;
    &:last-child {
      margin: 0;
    }
  }
  &-progress {
    height: adjustVW(48);
    margin-left: $space-large;
    &-disabled {
      cursor: not-allowed;
    }
  }
  &-col {
    &-list {
      margin-top: adjustVW(2);
      &-multi {
        overflow-x: auto;
        display: flex;
        grid-column-gap: $space-sub;
        max-width: 100%;
        padding-bottom: $space-base;
        @include scrollbar;
      }
    }
    &-item {
      flex-shrink: 0;
      max-width: adjustVW(160);
    }
  }
}

.clustering {
  display: flex;
  grid-column-gap: $space-small;
  margin-right: $space-small;
}
</style>
