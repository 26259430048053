<template>
  <div>
    <div class="text-mining-title">
      <texts :text="$t('textMining.resultTitle')" size="large" isBold />
    </div>
    <div class="text-mining-list">
      <button
        v-for="(type, index) in textMiningTypeList"
        :key="index"
        class="text-mining-item"
        @click="goTextMining(type)"
      >
        <div class="text-mining-icon">
          <icons :iconName="type" color="emphasis" size="title" />
        </div>
        <div class="text-mining-desc">
          <texts
            :text="$t('trainedAi.textMining.types.' + type)"
            color="emphasis"
          />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'

export default {
  components: {
    icons
  },
  props: {
    typeList: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    textMiningTypeList() {
      if (this.typeList == null || this.typeList.length === 0) return []
      return Object.entries(this.typeList).reduce((prev, [item, value]) => {
        if (!value) return prev
        prev.push(item)
        return prev
      }, [])
    }
  },
  methods: {
    goTextMining(type) {
      this.$emit('go-text-mining', type)
    }
  }
}
</script>

<style lang="scss" scoped>
.text-mining {
  &-title {
    margin-bottom: $space-sub;
  }
  &-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: $space-small;
    grid-row-gap: $space-small;
  }
  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-row-gap: $space-small;
    padding: $space-large 0;
    border: adjustVW(1) solid $key-color;
    background-color: $key-sub;
    border-radius: adjustVW(8);
  }
}
</style>
