<template>
  <service-detail
    v-bind="data"
    :serviceType="serviceType"
    :isEditting="isEditting"
    :loading="loading"
    :notFound="notFound"
    :disableClick="disableClick"
    :popup="popup"
    :service="currentService"
    :serviceMenu="serviceMenu"
    :project="project"
    :customblockList="customblockList"
    @cancel-delete="cancelDelete()"
    @change-tab="changeTab($event)"
    @close-modal="closePopup($event)"
    @delete-service="deleteService($event)"
    @edit-menu-click="editMenuClick($event)"
    @input-edit-form="inputEditForm($event)"
    @menu-click="menuClick($event)"
    @save-preproc="savePreproc"
    @select-item="selectItem($event)"
    @change-set-recipe="loadRecipeDetail($event)"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { serviceValidator } from '@/lib/validator/service.js'
import setMountedTimer from '@/mixin/set-mounted-timer'

import serviceDetail from '@/components/templates/service-detail.vue'

export default {
  components: {
    serviceDetail
  },
  mixins: [setMountedTimer],
  beforeRouteEnter(to, from, next) {
    const serviceType = to.params.serviceType
    const projectId = to.params.projectId
    next(async (vm) => {
      vm.pageLoading = true
      await vm.$store.dispatch(`services/${serviceType}/loadServiceList`, {
        projectId: projectId
      })
      if (serviceType === 'infer') {
        const trainedAiId = vm.currentService?.trainedAI.id
        if (trainedAiId) {
          await vm.loadTrainedAiDetail(trainedAiId)
        }
      }
      // TODO 最適化のみサーバーとの通信を下層ではなくViewsで行っている。学習・推論もリファクタリング時にここで取得しているprojectDetailの中身を利用して、下層での通信を辞める https://trello.com/c/t9DzSYMv
      if (serviceType === 'optimization' && vm.projectList[projectId]) {
        const check = vm.projectList[projectId]?.listData
        if (!check) {
          await vm.loadProjectDetail(parseInt(projectId))
        }
      }
      await vm.fetchCustomblocks()
      vm.pageLoading = false
    })
  },
  computed: {
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('project', ['projectList', 'projectLoading']),
    ...mapGetters('customblock', ['customblockList']),
    serviceType() {
      return this.$route.params.serviceType
    },
    services() {
      return this.$store.getters[`services/${this.serviceType}/services`]
    },
    loadingServices() {
      return this.$store.getters[`services/${this.serviceType}/loadingServices`]
    },
    currentService() {
      return this.services ? this.services[this.$route.params.id] : null
    },
    isEditting() {
      return (
        this.$route.name.endsWith('serviceDetailEdit') ||
        this.$route.name.endsWith('serviceProjectDetailEdit')
      )
    },
    projectId: function () {
      if (this.$route.params.projectId)
        return parseInt(this.$route.params.projectId)
      return null
    },
    project() {
      if (!this.projectList || this.projectList.length === 0) return {}
      return this.projectList[this.projectId]
    },
    serviceId: function () {
      return this.$route.params.id
    },
    // ページ上部のメニュー
    serviceMenu() {
      return [
        // ページ上部のメニュー
        {
          id: 0,
          name: this.$t('common.editButton'),
          iconName: 'edit',
          value: 'edit',
          disabled: this.serviceMenuValidate,
          disabledTips: this.$t('common.disabled.organization.otherAccountItem')
        },
        {
          id: 1,
          name: this.$t('common.deleteButton'),
          iconName: 'delete',
          value: 'delete',
          disabled: this.serviceMenuValidate,
          disabledTips: this.$t(
            'common.disabled.organization.otherAccountItemDelete'
          )
        }
      ]
    },
    serviceMenuValidate() {
      if (this.accountInfo?.organizationInfo?.role === 'admin') return false
      if (this.accountInfo.accountId !== this.currentService?.serviceOwner) {
        return true
      } else {
        return false
      }
    },
    notFoundProject() {
      if (this.projectId === null) {
        return false
      } else {
        return !(this.projectId in this.projectList)
      }
    },
    notFoundService() {
      return !(this.$route.params.id in this.services)
    },
    notFound() {
      return this.notFoundProject || this.notFoundService
    },
    loading() {
      return this.loadingServices || this.projectLoading || this.pageLoading
    },
    disableClick() {
      return this.submitDeleting
    }
  },
  data() {
    return {
      popup: {
        showPopup: [],
        deleteService: {
          service: null
        }
      },
      data: {
        sidebar: {
          // サイドバーに表示する情報
          project: {
            name: '退職予測',
            description:
              'このプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入ります'
          },
          activeLink: 'service'
        },
        headerTabs: {
          // ヘッダーのタブ
          tabs: [],
          tabSelect: 1
        },
        body: {
          pageBody: {
            editInfo: {
              serviceFormValidate: {
                duplicate: undefined
              }
            },
            modelInfo: {
              name: '使用する学習済みAIの名称が入ります使用する学習済みAIの名称が入ります',
              description:
                'AIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの',
              link: '#',
              targetValiable:
                '目的変数名が入ります目的変数名が入ります目的変数名が入ります目的変数名が入ります',
              accuracy: {
                effect: {
                  name: '退職率の削減量',
                  value: 15
                },
                firstAccuracy: {
                  name: '再現率',
                  value: 76.2
                }
              },
              featureImportance: [
                {
                  id: 1,
                  name: '年齢',
                  value: 1.222,
                  color: '#333'
                },
                {
                  id: 2,
                  name: '出張頻度-高',
                  value: 1.0,
                  color: '#333'
                },
                {
                  id: 3,
                  name: '長い説明変数が入ります長い説明変数が入ります',
                  value: 0.9543210123456789,
                  color: '#333'
                },
                {
                  id: 4,
                  name: '長い説明変数が入ります長い説明変数が入ります',
                  value: 0.7543210123456789,
                  color: '#333'
                },
                {
                  id: 5,
                  name: '長い説明変数が入ります長い説明変数が入ります',
                  value: 0.5543210123456789,
                  color: '#333'
                }
              ]
            },
            trainedAiDetail: null,
            recipeDetail: null,
            loadingRecipeDetail: false
          }
        },
        popup: {
          showPopup: [],
          deleteService: {
            service: {
              // データセット詳細に表示するテーブル
              name: 'サービス名が入ります',
              description:
                'AIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIのAIの説明が入りますAIの入りますAIの説明が入りますAIのAIの説明が入りますAIの',
              model: {
                name: '使用する学習済みAIが入ります使用する学習済みAIが入ります使用する学習済みAIが入ります使用する学習済みAIが入ります',
                link: '#'
              },
              preprocAndEda: {
                name: '使用する前処理名が入ります使用する前処理名が入ります使用する前処理名が入ります使用する前処理名が入ります',
                link: '#'
              },
              createDate: '2020 11 22 12:34:56',
              updateDate: '2020 11 22 12:34:56'
            }
          }
        }
      },
      submitDeleting: false,
      pageLoading: false
    }
  },
  methods: {
    ...mapActions('services', ['loadServiceList', 'deleteServices', 'updateService']),
    ...mapActions('project', ['loadProjectDetail']),
    ...mapActions('trainedAi', ['fetchTrainedAiDetail']),
    ...mapActions('customblock', ['fetchCustomblocks']),

    showPopup(e) {
      // ポップアップを表示
      if (this.popup.showPopup.length > 0) {
        this.popup.showPopup = []
        this.popup.showPopup.push(e)
      } else {
        this.popup.showPopup.push(e)
      }
    },
    closePopup(e) {
      // ポップアップを閉じる
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
    },
    changeTab(e) {
      e[1].target.activeTab = e[0]
    },
    selectItem(e) {
      e[1].target.selectItemValue = e[0].selectItem
    },
    deleteMenuClick() {
      this.popup.deleteService.service = this.currentService
      this.showPopup('deleteService')
    },
    async deleteService() {
      // delete
      this.submitDeleting = true
      try {
        await this.deleteServices({
          serviceType: this.serviceType,
          projectId: this.projectId,
          payload: [this.currentService.serviceId]
        })
      } finally {
        this.submitDeleting = false
      }
      this.$router.push({
        name: 'serviceList'
      })
    },
    async savePreproc(e) {
      await this.updateService({
        serviceType: this.serviceType,
        projectId: this.projectId,
        ...e
      })
      if (this.projectId) {
        this.$router.replace(
          {
            name: 'serviceProjectDetail',
            params: this.$route.params
          },
          () => {},
          () => {}
        )
      } else {
        this.$router.replace(
          {
            name: 'serviceDetail',
            params: this.$route.params
          },
          () => {},
          () => {}
        )
      }
    },
    cancelDelete() {
      this.data.popup.showPopup = [] // 削除ポップアップを閉じるためのダミー
    },
    menuClick(e) {
      if (e.activeMenu === 'delete') {
        this.deleteMenuClick()
      } else if (e.activeMenu === 'edit') {
        if (this.projectId) {
          this.$router.replace(
            {
              name: 'serviceProjectDetailEdit',
              params: this.$route.params
            },
            () => {},
            () => {}
          )
        } else {
          this.$router.replace(
            {
              name: 'serviceDetailEdit',
              params: this.$route.params
            },
            () => {},
            () => {}
          )
        }
      }
    },
    inputEditForm: function (obj) {
      const { type, form } = obj

      if (type === 'service') {
        const serviceList = Object.values(this.services)

        const services = serviceList.filter(
          (service) => service.id !== this.serviceId
        )

        this.data.body.pageBody.editInfo.serviceFormValidate = serviceValidator(
          services,
          form
        )
      }
    },
    async loadTrainedAiDetail(id) {
      const res = await this.fetchTrainedAiDetail({
        id,
        columnIndex: 0
      })
      this.data.body.pageBody.trainedAiDetail = res
    },
    async loadRecipeDetail({ id, accountId }) {
      this.data.body.pageBody.loadingRecipeDetail = true
      const res = await this.$sendMessageAndReceive({
        action: 'getRecipeDetail',
        recipe_id: id,
        recipe_account_id: accountId
      })
      this.data.body.pageBody.recipeDetail = res.detail
      this.data.body.pageBody.loadingRecipeDetail = false
    }
  }
}
</script>
