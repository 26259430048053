<template>
  <div class="service-detail-edit-wrap">
    <div class="service-detail-edit-inner">
      <div class="service-detail-edit-title">
        <texts :text="$t('serviceDetail.editService')" size="large" />
      </div>
      <div class="service-detail-edit-block">
        <input-box-validation
          v-model="name"
          :accept="validate.accept"
          :error="validate.error"
          :title="$t('serviceDetail.serviceName')"
          popupView
          isNameValidation
          :nameValidationSuggests="serviceFormValidate.suggests"
        />
      </div>
      <div class="service-detail-edit-block">
        <input-box
          v-model="description"
          isTextArea
          isGray
          :title="$t('serviceDetail.serviceDetail')"
        />
      </div>
      <div class="service-detail-edit-block-list">
        <div class="service-detail-edit-block-item-column">
          <div class="service-detail-edit-inner-title">
            <texts
              :text="$t('sidebar.train.status.recipe.itemName')"
              size="small"
              color="gray"
            />
          </div>
          <div class="service-detail-edit-block-item-input-wrap">
            <selectBox
              v-model="recipe"
              class="service-detail-edit-block-item-input"
              valueKey="id"
              :items="recipeList"
              isGray
              scrollBar
              showTop
              :isRequired="checkOptimization"
            />
            <loading-icon
              v-if="loadingRecipeDetail"
              class="service-detail-edit-block-item-input-loading"
            />
            <transition-toggle-contents>
              <text-box
                v-if="errorRecipeText"
                class="service-detail-edit-block-item-input-error"
                :text="errorRecipeText"
                isError
                isFolded
              />
            </transition-toggle-contents>
          </div>
        </div>
      </div>
      <transition-toggle-contents>
        <div v-if="!checkUnstructuredRecipeType">
          <div class="service-detail-edit-block-list">
            <div class="service-detail-edit-block-item-column">
              <div class="service-detail-edit-inner-title">
                <texts
                  v-tooltip.top-start="
                    showColumnTips === 'predict' ? tipsContents : ''
                  "
                  class="service-detail-edit-inner-title-text"
                  :text="$t('projectDetail.objectiveVariable')"
                  size="small"
                  color="gray"
                />
              </div>
              <div class="service-detail-edit-inner-columns">
                <tag-editable-list
                  :value="predictionColumns"
                  :addButtonText="$t('common.addColumn')"
                  :placeholder="$t('common.inputColumnName')"
                  isWhite
                  edit
                  @input="checkColumnValuePredict($event)"
                />
              </div>
            </div>
          </div>
          <div class="service-detail-edit-block-list">
            <div class="service-detail-edit-block-item-column">
              <div class="service-detail-edit-inner-title">
                <texts
                  v-tooltip.top-start="
                    showColumnTips === 'input' ? tipsContents : ''
                  "
                  class="service-detail-edit-inner-title-text"
                  :text="$t('service.learn.inputColumns')"
                  size="small"
                  color="gray"
                />
              </div>
              <div class="service-detail-edit-inner-columns">
                <tag-editable-list
                  :value="inputColumns"
                  :addButtonText="$t('common.addColumn')"
                  :placeholder="$t('common.inputColumnName')"
                  isWhite
                  edit
                  @input="checkColumnValueInput($event)"
                />
              </div>
            </div>
          </div>
          <div>
            <button-toggle
              :value="opened"
              :openText="
                $t('serviceList.popup.addNewService.showPreprocessing')
              "
              :closeText="
                $t('serviceList.popup.addNewService.showPreprocessing')
              "
              @close="preprocessing = null"
            >
              <div class="service-detail-edit-inner-title">
                <texts
                  :text="
                    $t(
                      'serviceList.popup.addNewService.PreprocessingToUseOptional'
                    )
                  "
                  size="small"
                  color="gray"
                />
              </div>
              <select-box
                v-model="preprocessing"
                class="service-detail-edit-preproc-input"
                isGray
                scrollBar
                showTop
                valueKey="id"
                :items="preprocessingList"
                :placeholder="
                  preprocessingList.length
                    ? ''
                    : $t(
                      'serviceList.popup.addNewService.PreprocessingToUsePlaceholder'
                    )
                "
                :title="
                  $t(
                    'serviceList.popup.addNewService.PreprocessingToUseOptional'
                  )
                "
                @select-box-open="$emit('select-box-get-preprocessings')"
              />
            </button-toggle>
          </div>
        </div>
      </transition-toggle-contents>
      <div class="service-detail-edit-button-block">
        <div class="service-detail-edit-button-inner">
          <button-main
            type="emphasis"
            :isDisabled="disabledSubmit"
            :text="$t('serviceDetail.button.save')"
            @click="save"
          />
        </div>
        <div class="service-detail-edit-button-inner">
          <button-main
            type="sub"
            :text="$t('common.cancel')"
            @click="$emit('cancel')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { usedCustomblockList } from '@/lib/inference.js'

import inputBox from '@/components/molecules/input-box'
import inputBoxValidation from '@/components/molecules/input-box-validation'
import buttonMain from '@/components/atoms/button-main.vue'
import buttonToggle from '@/components/molecules/button-toggle'
import selectBox from '@/components/molecules/select-box'
import texts from '@/components/atoms/text'
import textBox from '@/components/atoms/text-box'
import tagEditableList from '@/components/molecules/tag-editable-list.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import loadingIcon from '@/components/atoms/loading-icon'

export default {
  components: {
    texts,
    textBox,
    selectBox,
    inputBox,
    inputBoxValidation,
    buttonToggle,
    buttonMain,
    tagEditableList,
    transitionToggleContents,
    loadingIcon
  },
  data() {
    return {
      name: this.service.name,
      description: this.service.description,
      recipe: this.service.recipe?.id,
      recipeDetail: null,
      loadingRecipeDetail: false,
      preprocessing: this.service.edaRecipe?.id,
      inputColumns: this.service.inputColumns,
      predictionColumns: this.service.predictionColumns,
      validate: {
        accept: null,
        error: null
      },

      opened: !!this.service.edaRecipe?.id,
      showColumnTips: null,
      showColumnTipsTimer: null,
      isNotAvailableCustomblock: false
    }
  },
  props: {
    service: Object,
    serviceFormValidate: Object,
    customblockList: Array
  },
  watch: {
    name: function (val) {
      this.$emit('input-edit-form', {
        type: 'service',
        form: {
          name: val
        }
      })
    },
    serviceFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    },
    targetRecipe: async function () {
      this.loadingRecipeDetail = true
      await this.loadRecipeDetail(this.targetRecipe)
      this.loadingRecipeDetail = false
    }
  },
  async mounted() {
    this.$waitConnected()
    this.loadingRecipeDetail = true
    if (!this.EDARecipes && !this.loadingEDARecipes) {
      this.fetchEDARecipeList()
    }
    await this.loadRecipeDetail(this.targetRecipe)
    this.loadingRecipeDetail = false

    this.$emit('input-edit-form', {
      type: 'service',
      form: {
        name: this.name
      }
    })
  },
  methods: {
    // TODO 本来はViewsでやるべき処理。他の処理がすべてこの階層で行われてしまっているためやむを得ずここで実装。リファクタリング対象
    ...mapActions('preprocessings', ['fetchEDARecipeList']),
    save() {
      if (this.checkUnstructuredRecipeType) {
        this.preprocessing = null
        this.inputColumns = []
        this.predictionColumns = []
      }
      this.$emit('save', {
        serviceId: this.service.serviceId,
        name: this.name,
        description: this.description,
        recipeId: this.recipe,
        edaRecipeId: this.preprocessing,
        inputColumns: this.inputColumns,
        predictionColumns: this.predictionColumns
      })
    },
    async loadRecipeDetail(e) {
      this.isNotAvailableCustomblock = false
      const res = await this.$sendMessageAndReceive({
        action: 'getRecipeDetail',
        recipe_id: e.id,
        recipe_account_id: e.accountId
      })
      const checkCustomblock = await this.checkCustomblockAvailable(res.detail)
      if (checkCustomblock) {
        this.isNotAvailableCustomblock = true
        return
      }
      this.recipeDetail = res.detail
    },
    checkColumnValuePredict(e) {
      window.clearTimeout(this.showColumnTipsTimer)
      this.showColumnTips = null

      const check = e.some((item) => {
        return this.inputColumns.includes(item)
      })
      let newVal = e

      if (check) {
        newVal = e.filter((item) => {
          return !this.inputColumns.includes(item)
        })
        this.showColumnTips = 'predict'
        this.tipsTimeout()
      }

      this.predictionColumns = newVal
    },
    checkColumnValueInput(e) {
      window.clearTimeout(this.showColumnTipsTimer)
      this.showColumnTips = null

      const check = e.some((item) => {
        return this.inputColumns.includes(item)
      })
      let newVal = e

      if (check) {
        newVal = e.filter((item) => {
          return !this.predictionColumns.includes(item)
        })
        this.showColumnTips = 'input'
        this.tipsTimeout()
      }
      this.inputColumns = newVal
    },
    tipsTimeout() {
      this.showColumnTipsTimer = window.setTimeout(
        function () {
          this.showColumnTips = null
        }.bind(this),
        4000
      )
    },
    async checkCustomblockAvailable(recipeDetail) {
      const usedCustomblockItems =
        usedCustomblockList(recipeDetail.body.layers) ?? []
      if (usedCustomblockItems.length === 0) return false
      const checkCustomblock = usedCustomblockItems.some((target) => {
        return !this.customblockList.some((customblock) => {
          return customblock.customblock_id === target.customblock_id
        })
      })
      return checkCustomblock
    }
  },
  computed: {
    // TODO 本来はViewsでやるべき処理。他の処理がすべてこの階層で行われてしまっているためやむを得ずここで実装。リファクタリング対象
    ...mapGetters('preprocessings', ['EDARecipes', 'loadingEDARecipes']),
    ...mapGetters('project', ['projectList']),
    ...mapGetters('recipes', { getterRecipes: 'recipes' }),

    disabledSubmit: function () {
      if (this.name === '') return true
      if (this.validate.error) return true
      if (!this.checkStructuredInput) return true
      if (this.checkOptimization) return true
      if (this.isNotAvailableCustomblock) return true
      return false
    },
    recipeList() {
      if (!this.recipes) return []
      return Object.values(this.recipes).sort((x, y) =>
        x.updateTime > y.updateTime ? -1 : 1
      )
    },
    preprocessingList() {
      const nullElement = {
        fullId: null,
        name: this.$t('common.notSelected')
      }
      let preprocessings = null
      if (this.project) {
        preprocessings = this.project.listEdaRecipe
      } else {
        preprocessings = this.EDARecipes ? Object.values(this.EDARecipes) : []
      }
      preprocessings = preprocessings.sort((x, y) =>
        x.updateTime > y.updateTime ? -1 : 1
      )
      return [nullElement, ...preprocessings]
    },

    project() {
      const projectId = this.$route.params?.projectId
      if (!projectId) return null
      return this.projectList[projectId]
    },
    recipes() {
      if (this.project) {
        const res = {}
        this.project.listRecipe?.forEach((item) => {
          res[item.id] = item
        })
        return res
      } else {
        return this.getterRecipes
      }
    },
    targetRecipe() {
      if (!this.recipeList) return null
      return this.recipeList.find((item) => item.id === this.recipe)
    },
    checkUnstructuredRecipeType() {
      if (!this.recipeDetail) return true
      const recipeInput = this.recipeDetail.body.layers.find(
        (item) => item.type === 'input'
      )
      return (
        recipeInput?.params?.dataType?.value === 'texts' ||
        recipeInput?.params?.dataType?.value === 'images'
      )
    },
    checkStructuredInput() {
      if (this.checkUnstructuredRecipeType) return true
      return this.inputColumns.length > 0 && this.predictionColumns.length > 0
    },
    tipsContents() {
      if (this.showColumnTips === 'predict') {
        return {
          content: this.$t(
            'serviceDetail.edit.error.setDuplicatePredictionColumn'
          ),
          show: this.showColumnTips === 'predict',
          trigger: 'manual'
        }
      } else if (this.showColumnTips === 'input') {
        return {
          content: this.$t('serviceDetail.edit.error.setDuplicateInputColumn'),
          show: this.showColumnTips === 'input',
          trigger: 'manual'
        }
      } else {
        return null
      }
    },
    checkOptimization() {
      if (!this.recipeDetail) return false
      return this.recipeDetail?.is_optimization
    },
    errorRecipeText() {
      if (this.isNotAvailableCustomblock) {
        return this.$t('common.disabled.NOT_AVAILABLE_CUSTOMBLOCK_FOR_RECIPE')
      } else if (this.checkOptimization) {
        return this.$t(
          'serviceList.popup.addNewService.progress.recipe.descNotUseOptimization'
        )
      } else {
        return ''
      }
    }
  },
  destroyed() {
    window.clearTimeout(this.showColumnTipsTimer)
  }
}
</script>
<style lang="scss" scoped>
.service-detail-edit {
  &-wrap {
    width: 100%;
    height: 100%;
    padding: $space-small $space-base;
  }
  &-inner {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding: $space-base $space-small;
    @include scrollbar;
    &-columns {
      overflow-y: auto;
      height: 100%;
      max-height: adjustVW(240);
      @include scrollbar;
    }
  }
  &-block {
    width: adjustVW(720);
    margin: 0 0 $space-medium;
    &-list {
      display: flex;
      width: adjustVW(720);
      margin-bottom: $space-large;
    }
    &-item {
      width: calc(50% - #{$space-sub});
      margin-right: $space-medium;
      &:last-of-type {
        margin: 0;
      }
      &-input {
        height: auto;
        &-wrap {
          position: relative;
        }
        &-loading {
          position: absolute;
          top: 50%;
          right: adjustVW(-48);
          transform: translateY(-50%);
        }
        &-error {
          margin-top: $space-small;
        }
      }
      &-column {
        width: 100%;
      }
    }
  }
  &-title {
    margin: 0 0 $space-medium;
  }
  &-inner-title {
    margin: 0 0 $space-base;
    &-text {
      width: fit-content;
    }
  }
  &-button {
    &-block {
      display: flex;
    }
    &-inner {
      margin: 0 $space-small 0 0;
      &:last-child {
        margin: 0;
      }
    }
  }
  &-preproc-input {
    width: adjustVW(722);
    margin-bottom: $space-medium;
  }
}
</style>
