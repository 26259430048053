<template>
  <div class="optimization-wrap">
    <card-base class="optimization-table">
      <transition-toggle-contents>
        <div
          v-if="optimizationInfo.loadOptimizationDetail"
          class="optimization-loading"
        >
          <loading />
        </div>
        <optimization-no-item
          v-else-if="!optimizationResult.result"
          :trainedAi="sortedTrainedAi"
        />
        <optimization-table
          v-else-if="
            optimizationResult.result &&
              Object.keys(optimizationResult.result).length > 0
          "
          :result="optimizationResult.result"
          :optimizationConditions="
            sortedTrainedAi.summary.latestOptimizationConditions
          "
          :accountInfo="accountInfo"
          :columnRanges="optimizationResult.columnRanges"
          :limit="optimizationResult.limit"
          :total="optimizationResult.total"
          :inPageNumber="optimizationResult.inPageNumber"
          :loadPaging="optimizationInfo.loadOptimizationPaging"
          :filters="optimizationResult.filters"
          :download="optimizationInfo.download"
          @close-optimization-result-tutorial="
            $emit('close-optimization-result-tutorial')
          "
          @change-page="$emit('change-page', $event)"
          @change-filter-value="$emit('change-filter-value', $event)"
          @download-result="$emit('download-result', $event)"
        />
      </transition-toggle-contents>
    </card-base>
    <card-base class="optimization-conditions">
      <texts
        class="optimization-conditions-title"
        size="small"
        color="gray"
        :text="$t('trainedAi.optimization.latestHistory')"
      />
      <optimization-conditions-list
        v-if="sortedTrainedAi.summary.latestOptimizationConditions"
        class="optimization-conditions-list"
        :optimizationConditions="
          sortedTrainedAi.summary.latestOptimizationConditions
        "
        vertical
      />
    </card-base>
  </div>
</template>

<script>
import cardBase from '@/components/atoms/card-base'
import loading from '@/components/atoms/loading'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import optimizationTable from '@/components/organisms/optimization/optimization-table'
import optimizationConditionsList from '@/components/organisms/optimization/optimization-conditions-list'
import optimizationNoItem from '@/components/organisms/optimization/optimization-no-item'

export default {
  components: {
    cardBase,
    loading,
    transitionToggleContents,
    optimizationTable,
    optimizationConditionsList,
    optimizationNoItem
  },
  props: {
    accountInfo: Object,
    sortedTrainedAi: Object,
    optimizationInfo: Object,
    optimizationResult: Object
  },
  mounted() {
    this.$emit(
      'load-optimization-result',
      this.sortedTrainedAi.summary.latestOptimizationConditions.id
    )
  }
}
</script>

<style lang="scss" scoped>
.optimization {
  &-wrap {
    display: grid;
    grid-template-columns: 1fr adjustVW(320);
    grid-column-gap: $space-medium;
  }
  &-table {
    overflow: hidden;
  }
  &-conditions {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &-title {
      flex-shrink: 0;
      margin-bottom: $space-base;
    }
    &-list {
      overflow-y: auto;
      flex-wrap: nowrap;
      max-height: 100%;
      @include scrollbar;
    }
  }
  &-loading {
    height: 100%;
  }
}
</style>
