<template>
  <div class="template-wrap">
    <div class="template-main">
      <div class="template-title">
        <texts
          :text="$t('serviceList.popup.addNewService.datasetSelectTitle')"
        />
      </div>
      <transition-toggle-contents>
        <div v-if="datasets" class="template-list-wrap">
          <div class="template-search">
            <fuse-search
              :placeholder="$t('datasetList.searchDataset')"
              :option="option"
              :value="datasets"
              gray
              @input="inputSearch"
            />
          </div>
          <div class="template-list">
            <list-item-slot
              :items="showItems ? showItems : datasets"
              :field="field"
              type="button"
              @list-click="listClick"
            >
              <template v-slot:name="entry">
                <texts
                  class="template-item-name"
                  :text="entry.entry.name"
                  isBold
                />
                <description-tooltip
                  :description="entry.entry.description"
                  :index="entry.listIndex"
                />
              </template>
            </list-item-slot>
          </div>
        </div>
        <loading v-else class="template-list-wrap" />
      </transition-toggle-contents>
      <div class="template-button">
        <button-large
          class="template-button-item"
          :text="$t('serviceList.popup.addNewService.datasetSelected')"
          :isDisabled="!checked || disableClick || loadDatasetDetail"
          @click="$emit('select-dataset', checked)"
        />
        <button-large
          class="template-button-item"
          :text="$t('serviceList.popup.addNewService.selectColumnsSelf')"
          isSub
          @click="$emit('select-dataset', null)"
        />
      </div>
    </div>
    <dataset-setting-table
      class="template-detail"
      :table="checkDatasetDetail.table"
      :columns="checkDatasetDetail.tableColumns"
      :type="checkDatasetDetail.type"
      :datasetTableLoading="loadDatasetDetail"
      :nLabels="nLabels"
    />
  </div>
</template>
<script>
import descriptionTooltip from '@/components/molecules/description-tooltip'
import texts from '@/components/atoms/text'
import listItemSlot from '@/components/molecules/list-item-slot'
import fuseSearch from '@/components/molecules/fuse-search.vue'
import ButtonLarge from '@/components/atoms/button-large.vue'
import Loading from '@/components/atoms/loading.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import datasetSettingTable from '@/components/organisms/dataset-setting/dataset-setting-table'

export default {
  components: {
    descriptionTooltip,
    texts,
    listItemSlot,
    fuseSearch,
    ButtonLarge,
    Loading,
    transitionToggleContents,
    datasetSettingTable
  },
  data() {
    return {
      checked: null,
      graph: null,
      showItems: null,
      option: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.2,
        keys: [
          {
            name: 'name',
            weight: 1
          }
        ]
      },
      nLabels: 0
    }
  },
  props: {
    datasetDetail: Object,
    datasetList: Object,
    datasetLoading: Boolean,
    disableClick: Boolean,
    loadDatasetDetail: Boolean
  },
  methods: {
    listClick(e) {
      const id = e.id
      this.checked = id
      this.$emit('list-click', { id: id })
    },
    inputSearch(item) {
      this.showItems = item.result
    },
    nLabelsCount(count) {
      this.nLabels = count
    }
  },
  computed: {
    datasets() {
      /** そもそも画像テキストの場合はレシピの次点で弾くのでstructuredのみ */
      let target = Object.values(this.datasetList).filter(
        (item) => item.type === 'structured'
      )
      target = target.sort((x, y) => (x.updateTime > y.updateTime ? -1 : 1))
      return target
    },
    field() {
      return [
        {
          label: this.$t('datasetSetting.selectDataset.table.fieldName'),
          key: 'name'
        }
      ]
    },
    unstructuredField() {
      return {
        images: [
          {
            id: 0,
            key: 'name',
            label: this.$t('datasetSetting.selectDataset.field.columnName'),
            width: 176
          },
          {
            id: 1,
            key: 'count',
            label: this.$t('datasetSetting.selectDataset.field.count'),
            width: 120
          },
          {
            id: 1,
            key: 'sample',
            label: this.$t('datasetSetting.selectDataset.field.images'),
            width: 534
          }
        ],
        texts: [
          {
            id: 0,
            key: 'name',
            label: this.$t('datasetSetting.selectDataset.field.columnName'),
            width: 176
          },
          {
            id: 1,
            key: 'count',
            label: this.$t('datasetSetting.selectDataset.field.count'),
            width: 120
          },
          {
            id: 1,
            key: 'sample',
            label: this.$t('datasetSetting.selectDataset.field.texts'),
            width: 534
          }
        ]
      }
    },
    checkDatasetDetail() {
      if (this.datasetDetail == null) return {}
      if (this.datasetDetail?.data_type === 'list') {
        return {
          tableColumns: Object.keys(this.datasetDetail.detail.dtypes),
          table: this.datasetDetail.list,
          type: this.datasetDetail.data_type
        }
      } else if (
        this.datasetDetail?.data_type === 'images' ||
        this.datasetDetail?.data_type === 'texts'
      ) {
        const items = []
        let nLabelsCount = 0
        for (const [key, value] of Object.entries(this.datasetDetail.labels)) {
          items.push({
            name: key,
            count: value.count,
            sample: value.datas
          })
          nLabelsCount = nLabelsCount + value.count
        }
        this.nLabelsCount(nLabelsCount)
        return {
          tableColumns: this.unstructuredField[this.datasetDetail.data_type],
          table: items,
          type: this.datasetDetail.data_type
        }
      }
      return {}
    }
  }
}
</script>

<style lang="scss" scoped>
.template {
  &-wrap {
    display: grid;
    grid-template-columns: minmax(adjustVW(594), 1fr) adjustVW(966);
    grid-column-gap: $space-medium;
    width: 100%;
    height: 100%;
  }
  &-main {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  &-title {
    flex-shrink: 0;
    margin: 0 0 $space-base;
  }
  &-search {
    flex-shrink: 0;
    margin: 0 0 $space-medium;
  }
  &-list {
    overflow-y: auto;
    flex-shrink: 1;
    flex-grow: 1;
    height: 100%;
    padding: 0 $space-base 0 0;
    margin: 0 0 $space-medium;
    @include scrollbar;
    &-wrap {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
  &-button {
    flex-shrink: 0;
    &-item {
      margin-bottom: $space-small;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &-detail {
    overflow: hidden;
  }
  &-item {
    &-name {
      overflow: hidden;
      margin-bottom: $space-text;
    }
  }
}
</style>
