<template>
  <div class="table">
    <div class="table-wrap" :class="{ 'table-statics': isStatics }">
      <div v-if="noData" key="noData" class="no-data">
        <transition-toggle-contents>
          <div v-if="!loadingDatasetDetail" key="none" class="no-data-content">
            <texts
              class="no-data-title"
              :text="$t('datasetSetting.selectDataset.tooLarge')"
              size="small"
              color="gray"
            />
            <button-main
              :text="$t('datasetSetting.selectDataset.tooLargeButton')"
              type="sub"
              @click="$emit('show-too-large-data')"
            />
          </div>
          <div v-else key="loading" class="no-data-content">
            <loading />
          </div>
        </transition-toggle-contents>
      </div>
      <table
        v-show="!noData"
        key="table"
        class="table-body"
        :class="{
          'table-body-loading': loadPage,
          'table-body-active': !loadPage
        }"
        @mouseleave="reaveItem"
      >
        <thead>
          <tr class="table-name">
            <th
              v-for="(key, index) in displayColumns"
              :key="index"
              class="table-name-inner"
              :class="cellStatus(index)"
              @mouseover="hoverItem(index)"
              @click="clickColumn(index)"
            >
              <texts
                :text="key"
                :color="checkCautionName(key) ? 'caution' : ''"
                size="small"
              />
              <div class="caution-popup-wrapper">
                <popup-balloon
                  class="caution-popup-inner"
                  :class="{
                    'caution-popup-inner-right':
                      displayColumns.length - index < 3
                  }"
                  :isShow="
                    preventCautionMessage ? false : showCautionMessage(index)
                  "
                  :showCloseButton="false"
                  showBottom
                >
                  <texts
                    class="caution-popup-title"
                    :text="
                      $i18n.t(
                        'selectTargetColumn.datasetInfo.list.caution.title'
                      )
                    "
                  />
                  <texts
                    class="caution-popup-title-sub"
                    color="gray"
                    size="small"
                    :text="
                      $i18n.t(
                        'selectTargetColumn.datasetInfo.list.caution.titleSub'
                      )
                    "
                  />

                  <div class="caution-popup-reason-message-wrapper">
                    <template v-for="item in cautionMessage">
                      <div
                        v-if="checkReason(key, item.value)"
                        :key="item.index"
                        class="caution-popup-reason-message-item"
                      >
                        <div class="caution-popup-reason-message-marker" />
                        <texts
                          class="caution-popup-reason-message-text"
                          size="small"
                          :text="item.text"
                        />
                      </div>
                    </template>
                  </div>

                  <button-main
                    class="caution-popup-checked-button"
                    type="emphasis"
                    :text="
                      $i18n.t(
                        'selectTargetColumn.datasetInfo.list.caution.button'
                      )
                    "
                    @click.native.stop="confirmCaution(index)"
                  />
                </popup-balloon>
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <table-main-graph
            v-if="isStatics"
            :graph="graph"
            :tableColumns="displayColumns"
            :hoverTarget="hoverTarget"
            :selectedColumn="selectedColumn"
            isCellButton
            @mouseover="hoverItem($event)"
            @click="clickColumn($event)"
          />
          <tr
            v-for="(entry, entryIndex) in translateTable"
            :key="entryIndex + 'entry'"
            class="table-data"
          >
            <td
              v-for="(key, keyIndex) in displayColumns"
              :key="keyIndex + 'td'"
              class="table-data-inner"
              :class="cellStatus(keyIndex)"
              @mouseover="hoverItem(keyIndex)"
              @click="clickColumn(keyIndex)"
            >
              <div
                v-if="isStatics && entry[key] == undefined"
                v-tooltip="noItemInfo"
                class="table-data-inner-no-item"
              >
                <table-td-text
                  :popupKey="keyIndex + '-' + entryIndex"
                  text="ー"
                />
              </div>
              <table-td-text
                v-else
                :popupKey="keyIndex + '-' + entryIndex"
                :placeEnd="7 >= translateTable.length - entryIndex + 1"
                :text="entry[key]"
                :isDisabled="
                  cellStatus(keyIndex) !== 'table-item-selected-cells'
                "
                @click.native="checkClickStatus(keyIndex, $event)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-paging">
      <paging
        v-tooltip="noteHasError ? pagingDisabledMessage : null"
        :value="inPageNumber"
        :count="tableColumnsLength"
        :pagingCount="pagingCount"
        :isDisabled="noteHasError"
        @input="changePage"
      />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import loading from '@/components/atoms/loading'
import ButtonMain from '@/components/atoms/button-main.vue'
import PopupBalloon from '@/components/atoms/popup-balloon.vue'
import tableMainGraph from '@/components/molecules/table-main-graph.vue'
import tableTdText from '@/components/molecules/table-td-text.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import paging from '@/components/molecules/paging'

export default {
  components: {
    texts,
    loading,
    ButtonMain,
    PopupBalloon,
    tableMainGraph,
    tableTdText,
    transitionToggleContents,
    paging
  },
  data() {
    return {
      hoverTarget: null,
      hoverFlag: false,
      inPageNumber: 1,
      pagingCount: 7,
      loadPage: false
    }
  },
  props: {
    cautionItems: Array,
    cautionMessage: Array,
    checkNotes: Object,
    graph: Object,
    isStatics: Boolean,
    tableColumns: Array,
    table: Array,
    selectedColumn: {
      type: Array,
      default: () => []
    },
    preventCautionMessage: Boolean,
    multiSelectMode: Boolean,
    loadingDatasetDetail: Boolean
  },
  computed: {
    translateTable() {
      if (!this.table) return []
      const target = JSON.parse(JSON.stringify(this.table))
      if (this.isStatics) {
        target.forEach((element, index) => {
          element['/'] = this.$i18n.t('statistic.' + element['/'])
        })
        return target
      }
      return target
    },
    noItemInfo() {
      return {
        content: this.$t('common.table.statistics.objectItem'),
        trigger: 'hover',
        delay: { show: 200, hide: 200 }
      }
    },
    noData() {
      return !this.table
    },
    displayColumns() {
      let startCount = (this.inPageNumber - 1) * this.pagingCount
      let endCount = this.inPageNumber * this.pagingCount

      if (this.isStatics && this.inPageNumber === 1) {
        endCount = this.inPageNumber * this.pagingCount + 1
      } else if (this.isStatics && this.inPageNumber > 1) {
        startCount = (this.inPageNumber - 1) * this.pagingCount + 1
      }
      const base = this.tableColumns.slice(startCount, endCount)
      if (this.isStatics && this.inPageNumber > 1) {
        base.unshift('/')
      }
      return [...base]
    },
    tableColumnsLength() {
      if (this.isStatics) {
        return this.tableColumns.length - 1 // 統計情報の/列分減らす
      } else {
        return this.tableColumns.length
      }
    },
    noteHasError() {
      const checkTarget = this.selectedColumn.filter((item) => {
        return !this.checkNotes[item]
      })
      return checkTarget.length > 0
    },
    pagingDisabledMessage() {
      return {
        content: this.$t('selectTargetColumn.pagingDisabled'),
        trigger: 'hover',
        delay: { show: 300, hide: 300 }
      }
    }
  },
  methods: {
    cellStatus(index) {
      if (this.selectedColumn.includes(this.displayColumns[index])) {
        return 'table-item-selected-cells'
      } else if (index === this.hoverTarget) {
        return 'table-item-hovered-cells'
      } else {
        return ''
      }
    },
    hoverItem(index) {
      if (this.hoverTarget !== index) {
        this.hoverTarget = index
      }
    },
    reaveItem() {
      this.hoverTarget = null
    },
    clickColumn: function (index) {
      const columnName = this.displayColumns[index]

      // 複数選択モードでなく、注意事項が表示されているならスキップ
      if (
        !this.multiSelectMode &&
        this.showCautionMessage(index) &&
        this.selectedColumn.includes(columnName)
      )
        return

      // 統計情報の1列目のタイトル列は除外
      if (!(index === 0 && columnName === '/')) {
        // 複数選択モードで既に選択されている場合だけチェックを外すための処理
        const selected =
          !this.multiSelectMode || !this.selectedColumn.includes(columnName)
        // 未選択のときに注意対象であればshowNoteをfalse
        const showNote = !(
          !this.selectedColumn.includes(columnName) &&
          this.checkCautionName(columnName)
        )

        this.$emit('click-column', { value: columnName, selected: selected })
        this.$emit('check-notes', { key: columnName, showNote: showNote })
      }
    },
    confirmCaution: function (index) {
      const columnName = this.displayColumns[index]
      this.$emit('check-notes', { key: columnName, showNote: true })
    },
    checkReason: function (itemName, reason) {
      const item = this.cautionItems.find((item) => item.name === itemName)

      if (item) return item[reason]
      else return false
    },
    checkCautionName(itemName) {
      return this.cautionItems.some((caution) => caution.name === itemName)
    },
    checkClickStatus(keyIndex, e) {
      if (this.cellStatus(keyIndex) === 'table-item-selected-cells') {
        e.stopPropagation()
      }
    },
    showCautionMessage: function (index) {
      const item = this.displayColumns[index]
      const caution = this.checkCautionName(item)
      const isSelected = this.selectedColumn.includes(item)
      const checkNotes = this.checkNotes[item]

      return caution && isSelected && !this.checkCaution && !checkNotes
    },
    changePage(num) {
      this.loadPage = true
      this.inPageNumber = num
      this.$nextTick(() => {
        this.loadPage = false
      })
    },
    fixIndex(index) {
      let fixIndex = index
      if (this.isStatics) {
        fixIndex = fixIndex - 1
      }
      if (fixIndex + 1 > this.pagingCount) {
        fixIndex = fixIndex - this.pagingCount * (this.inPageNumber - 1)
      }
      return fixIndex
    },
    checkIndex(index) {
      const min = this.pagingCount * (this.inPageNumber - 1)
      let max = min + this.pagingCount - 1
      if (this.isStatics) {
        max = max + 1
      }
      return index >= min && max >= index
    }
  },
  mounted() {
    if (!this.table) return

    const selectedIndex = this.tableColumns.findIndex((item) =>
      this.selectedColumn.includes(item)
    )
    if (selectedIndex > -1 && selectedIndex + 1 > this.pagingCount) {
      const firstPageNumber =
        Math.floor((selectedIndex + 1) / this.pagingCount) + 1
      this.inPageNumber = firstPageNumber
    }
  },
  watch: {
    tableColumns: {
      handler() {
        this.loadPage = true
        this.$nextTick(() => {
          this.loadPage = false
        })
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  display: flex;
  flex-direction: column;
  grid-row-gap: $space-base;
  width: 100%;
  height: 100%;
}

.table-wrap {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  @include scrollbar;
}

.table-body {
  width: 100%;
  text-align: center;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
  &-loading {
    opacity: 0;
    transform: translateX($space-base);
    transition: opacity 0.15s, transform 0.15s;
    will-change: opacity, transform;
  }
  &-active {
    animation: showContent $transition-base;
  }
}

.table-name {
  background: $background;
}

.table-name-inner {
  position: sticky;
  top: 0;
  width: auto;
  height: adjustVH(56);
  padding: 0 $space-base;
  background: $background;
  line-height: adjustVH(56);
  font-size: $text-base;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: adjustVW(8) adjustVW(8) 0 0;
  cursor: pointer;
  z-index: 11;
  transition: background-color $transition-base;
  will-change: background-color;

  &::after {
    content: '';
    position: absolute;
    bottom: adjustVH(1);
    left: 0;
    width: 100%;
    height: adjustVH(1);
    background: $border-gray;
  }

  &-disabled {
    background: $background-sub;
    cursor: not-allowed;
  }
}

.table-data-inner {
  position: relative;
  overflow: hidden;
  height: adjustVH(56);
  padding: 0 $space-small;
  font-size: $text-base;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  z-index: 1;
  transition: background-color $transition-base;
  will-change: background-color;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: adjustVH(1);
    width: 100%;
    height: adjustVH(1);
    background: $line-gray;
  }
}

.table-paging {
  align-self: flex-end;
  padding-right: $space-sub;
}

.table-statics {
  .table {
    &-name-inner {
      &:first-child {
        left: 0;
        overflow: visible;
        background: $background;
        cursor: default;
        z-index: 23;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: adjustVH(1);
          height: 100%;
          background: $border-gray;
        }
      }
    }
    &-data {
      &:first-child {
        height: adjustVH(80);
        .table-data-inner {
          position: sticky;
          top: adjustVH(56);
          &:first-child {
            position: sticky;
            left: 0;
            overflow: visible;
          }
          &-show-graph {
            z-index: 30;
          }
        }
      }
      &-inner {
        &:first-child {
          position: sticky;
          left: 0;
          overflow: visible;
          background: $background;
          cursor: default;
          z-index: 20;
          &::before {
            content: '';
            position: absolute;
            right: 0;
            bottom: adjustVH(1);
            width: 100%;
            height: adjustVH(1);
            background: $line-gray;
          }
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: adjustVH(1);
            height: 100%;
            background: $border-gray;
          }
        }
      }
    }
  }
}

.table-item-selected-cells {
  background: $key-lite;
}

.table-item-hovered-cells {
  background: $background-decoration;
}

.table-item-disabled-cells {
  background: $background-sub;
  cursor: not-allowed;
}

.caution-popup-wrapper {
  text-align: left;
}

.caution-popup {
  &-title {
    margin-bottom: $space-text;
  }

  &-title-sub {
    margin-bottom: $space-small;
  }

  &-inner {
    left: $space-base;
    &-right {
      right: $space-base;
      left: inherit;
      &::after {
        right: $space-large;
        left: inherit;
      }
    }
  }
}

.caution-popup-reason-message-wrapper {
  padding-left: $space-base;
  margin-bottom: $space-small;
}

.caution-popup-reason-message {
  &-item {
    display: flex;
    align-items: center;
    margin-bottom: $space-sub;
    &:last-of-type {
      margin: 0;
    }
  }

  &-marker {
    flex-shrink: 1;
    width: adjustVW(12);
    height: adjustVW(12);
    margin: 0 $space-base 0 0;
    background: $text-decoration;
    border-radius: 9in;
  }
  &-text {
    width: calc(100% - #{adjustVW(12) + $space-base});
    white-space: pre-line;
  }
}

.caution-popup-checked-button {
  width: 100%;
}

.graph {
  &-inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-contents {
    width: adjustVW(1000);
    height: 100%;
  }
}

.no-data {
  height: 100%;
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-row-gap: $space-small;
    height: 100%;
  }
  &-title {
    text-align: center;
    white-space: pre-wrap;
  }
}

@keyframes showContent {
  0% {
    opacity: 0;
    transform: translateX($space-base);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
