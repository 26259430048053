<template>
  <card-base>
    <div class="sample-dataset">
      <texts
        class="sample-dataset-title"
        :text="$t('projectTemplate.detail.useSampleData')"
        size="big"
      />
      <texts
        class="sample-dataset-desc"
        :text="$t('projectTemplate.detail.notFreeDescription')"
        isShowAll
        size="small"
      />
      <div class="sample-dataset-info">
        <texts
          class="sample-dataset-info-title"
          :text="$t('projectTemplate.detail.sampleDatasetDetail')"
          size="large"
          isBold
        />
        <texts class="sample-dataset-info-name" :text="name" size="medium" />
        <div class="sample-dataset-info-desc">
          <texts
            class="sample-dataset-info-desc-inner"
            :text="description"
            size="small"
          />
        </div>
      </div>
      <template v-if="!loadingDatasets">
        <div class="sample-dataset-tab">
          <tab-list
            :tabs="tabs"
            :showIcon="false"
            @change-tab="changeTab($event)"
            @select-item="changeTab($event)"
          />
        </div>
        <div class="sample-dataset-table">
          <card-base class="sample-dataset-table-inner">
            <template v-if="type === 'structured'">
              <table-main
                :tableColumns="fixTableColumns"
                :table="fixTableData"
                :graph="graph"
                :pagingCount="8"
                :isStatics="activeTab === 'statistics'"
              />
            </template>
            <template v-else>
              <table-img-min
                :type="unstructuredType"
                :datas="datas"
                @data-text-click="dataTextClick($event)"
              />
            </template>
          </card-base>
        </div>
      </template>
      <loading v-else class="sample-dataset-loading" />
    </div>
    <div class="sample-dataset-contact">
      <dataset-info-card />
    </div>
  </card-base>
</template>

<script>
import texts from '@/components/atoms/text.vue'
import cardBase from '@/components/atoms/card-base.vue'
import tabList from '@/components/molecules/tab-list.vue'
import tableMain from '@/components/molecules/table-main.vue'
import datasetInfoCard from '@/components/organisms/select-template/dataset-info-card.vue'
import tableImgMin from '@/components/molecules/table-img-min'
import Loading from '@/components/atoms/loading.vue'

export default {
  data() {
    return {
      activeTab: 'dataset'
    }
  },
  props: {
    name: String,
    description: String,
    graph: Object,
    tableColumns: Array,
    table: Array,
    statisticsColumns: Array,
    statistics: Array,
    datas: Object,
    type: String,
    unstructuredType: String,
    loadingDatasets: Boolean
  },
  components: {
    texts,
    cardBase,
    tabList,
    tableMain,
    datasetInfoCard,
    tableImgMin,
    Loading
  },
  computed: {
    tabs() {
      const isStructured = this.type === 'structured'
      return [
        {
          id: 0,
          name: this.$t('projectTemplate.detail.tab.dataset'),
          value: 'dataset'
        },
        {
          id: 1,
          name: this.$t('projectTemplate.detail.tab.statistics'),
          value: 'statistics',
          disabled: !isStructured
        }
      ]
    },
    fixTableData() {
      if (this.activeTab === 'statistics') {
        return this.statistics
      } else {
        return this.table
      }
    },
    fixTableColumns() {
      if (this.activeTab === 'statistics') {
        return this.statisticsColumns
      } else {
        return this.tableColumns
      }
    }
  },
  methods: {
    changeTab(e) {
      this.activeTab = e
    }
  }
}
</script>

<style lang="scss" scoped>
.sample-dataset {
  &-title {
    margin: 0 0 $space-base;
  }
  &-desc {
    margin: 0 0 $space-small;
    line-height: $line-height-base;
  }
  &-tab {
    width: fit-content;
    margin: 0 0 $space-small;
  }
  &-table {
    overflow: hidden;
    height: adjustVH(600);
    padding: $space-base;
    background: $background-sub;
    border-radius: adjustVW(16);
    &-inner {
      height: 100%;
      padding: $space-base;
    }
  }
  &-contact {
    position: absolute;
    top: $space-small;
    right: $space-small;
    width: fit-content;
  }
  &-loading {
    height: adjustVH(600);
  }
  &-info {
    padding-top: $space-medium;
    margin: $space-medium 0;
    border-top: $border-main;
    &-title {
      margin-bottom: $space-sub;
    }
    &-name {
      margin-bottom: $space-base;
    }
    &-desc {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      max-height: adjustVH(280);
      padding: $space-small;
      background: $background-sub;
      border-radius: adjustVW(16);
      &-inner {
        overflow-y: auto;
        height: 100%;
        white-space: pre-line;
        @include scrollbar;
      }
    }
  }
}
</style>
