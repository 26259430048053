<template>
  <trained-ai-list
    v-bind="data"
    :trainedAIs="trainedAIs"
    :project="project"
    :projectId="projectId"
    :trainedAILoading="trainedAILoading"
    :allTrainedAILoading="allTrainedAILoading"
    :notFound="notFoundProject"
    :disableClick="disableClick"
    :accountInfo="accountInfo"
    @add-new-trained-ai="addNewTrainedAi()"
    @add-to-project="applyProjectAddTrainedAI"
    @delete-menu-click="showPopup('deleteTrainedAi'), setDeleteList($event)"
    @project-menu-click="projectMenuClick"
    @inference-menu-click="inferenceMenuClick"
    @service-menu-click="serviceMenuClick"
    @close-modal="closePopup($event)"
    @cancel-delete="cancelDelete()"
    @delete="deleteTrainedAi"
    @check-service="showPopup('deleteServiceCheck')"
  />
</template>

<script>
import trainedAiList from '@/components/templates/trained-ai-list.vue'
import { mapActions, mapGetters } from 'vuex'
import { setMetricsList, getModelType } from '@/lib/trainedAI.js'
import setMountedTimer from '@/mixin/set-mounted-timer'

import * as metricsDefsByModelType from '@/components/organisms/trained-ai-detail/metricsDefs'

export default {
  components: {
    trainedAiList
  },
  mixins: [setMountedTimer],
  async mounted() {
    await this.$waitConnected()
    this.fetchProjectAIs()
  },
  computed: {
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('trainedAi', ['loadingtrainedAIs']),
    ...mapGetters('trainedAi', {
      allTrainedAIs: 'trainedAIs',
      allTrainedAILoading: 'trainedAILoading'
    }),
    ...mapGetters('project', ['projectList']),
    project() {
      return {
        template: {
          // テンプレートがある場合はテンプレートごとに一番表示したい精度指標をとってきたいです
          name: 'hoge',
          description: 'hoge',
          metrics: 'accuracy', // Listに表示される精度指標
          expectedEffectName: '退職予測'
        },
        /**
         * テンプレートがない場合は、
         * CLASSIFICATION → accuracy
         * REGRESSION → r2
         * DEEP（画像） → test_accuracy
         * テキストなど複数の分類 → overall_accuracy
         * TIME → mse これだせないんでしたっけ？
         */
        type: 'accuracy'
      }
    },
    projectId() {
      if (this.$route.params.projectId) {
        return parseInt(this.$route.params.projectId)
      }
      return null
    },
    notFoundProject() {
      if (this.projectId === null) {
        return false
      } else {
        return !(this.projectId in this.projectList)
      }
    },
    trainedAIs() {
      if (this.projectId) {
        return this.projectTrainedAIs
      } else {
        return this.allTrainedAIs
      }
    },
    numOwnTrainedAIs() {
      return Object.values(this.allTrainedAIs).filter(
        (item) => item.accountId === this.accountInfo.accountId
      ).length
    },
    trainedAILoading() {
      if (this.projectId) {
        return this.projectTrainedAILoading || this.loadingtrainedAIs
      } else {
        return this.allTrainedAILoading
      }
    },
    disableClick() {
      return this.submitDeleting || this.submitOther
    }
  },
  data() {
    return {
      projectTrainedAIs: {},
      projectTrainedAILoading: true,
      data: {
        sidebar: {
          // サイドバーに表示する情報
          project: {
            name: '退職予測',
            description:
              'このプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入ります'
          },
          activeLink: 'model'
        },
        headerTabs: {
          // ヘッダーのタブ
          tabs: [],
          tabSelect: 1
        },
        body: {
          pageTop: {
            // ページ上部に表示する情報
            summary: {
              title: '学習済みAI管理',
              iconName: 'trainedAi'
            }
          },
          pageBody: {
            layoutType: 'grid'
          }
        },
        popup: {
          // ポップアップの情報
          deleteTrainedAi: {
            trainedAiList: []
          },
          intoProject: {
            targets: []
          },
          showPopup: [] // 現在表示しているポップアップ
        }
      },
      submitDeleting: false,
      submitOther: false
    }
  },
  methods: {
    ...mapActions('trainedAi', ['fetchModelList', 'deleteTrainedAIs']),
    ...mapActions('project', ['projectAddTrainedAI', 'loadProjectDetail']),
    ...mapActions('services', ['deleteServices']),
    ...mapActions('models', ['deleteProgressTraining', 'setProgressTraining']),

    async fetchProjectAIs() {
      if (this.projectId) {
        this.projectTrainedAILoading = true
        const projectId = parseInt(this.$route.params.projectId)
        const res = await this.$sendMessageAndReceive({
          action: 'getTrainedAIList',
          projectId
        })
        function setupModel(model, metricsDefs) {
          model.fullId = model.id + '-' + model.accountId
          model.fullRecipeId = model.recipeId + '-' + model.recipeAccountId
          model.fullDataId = model.dataId + '-' + model.dataAccountId
          model.mode = 'detail'
          model.confusionMatrixList = []
          model.results = null
          model.wordCloud = null
          model.charts = {}
          model.mapping = null
          model.trainConfig = null

          model.metricsList = setMetricsList(model, metricsDefs)
        }
        const modelList = {}
        res.list.forEach((model) => {
          const modelType = getModelType(model.type)
          let metricsDefs = metricsDefsByModelType[modelType](
            this.$t.bind(this)
          )

          if (modelType === 'images') {
            // 特定の精度指標が含まれるかどうかで、分類が 2 種類 or それ以上かをチェックする
            const isMultiClass = Object.keys(
              model?.metrics?.[0] ?? {}
            ).includes('test_overall_accuracy')

            // 分類の種類数に応じて精度評価を変更する。
            metricsDefs = isMultiClass
              ? metricsDefsByModelType.imagesMultiClass(this.$t.bind(this))
              : metricsDefsByModelType.images(this.$t.bind(this))

            // 推論のときは表示したくない精度指標があるので除去
            const metricsDefsKeyList = Object.keys(metricsDefs)
            const removeAccuracyList = ['train_accuracy', 'train_loss']

            metricsDefs = metricsDefsKeyList.reduce((acc, key) => {
              if (!removeAccuracyList.includes(key)) acc[key] = metricsDefs[key]

              return acc
            }, {})
          }

          setupModel(model, metricsDefs)
          modelList[model.id] = model
        })
        this.projectTrainedAIs = modelList
        this.projectTrainedAILoading = false
      }
    },
    showPopup(e) {
      // ポップアップを表示
      if (this.data.popup.showPopup.length > 0) {
        this.data.popup.showPopup = []
        this.data.popup.showPopup.push(e)
      } else {
        this.data.popup.showPopup.push(e)
      }
    },
    closePopup(e) {
      // ポップアップを閉じる
      this.data.popup.showPopup = this.data.popup.showPopup.filter(
        (n) => n !== e
      )
    },
    setDeleteList(e) {
      // 削除する学習済みAIの一覧をセット
      this.data.popup.deleteTrainedAi.trainedAiList = []
      // eslint-disable-next-line no-unused-vars
      for (const [_, deleteTarget] of e.target.entries()) {
        if (deleteTarget) {
          this.data.popup.deleteTrainedAi.trainedAiList.push(
            this.trainedAIs[deleteTarget]
          )
        }
      }
    },
    cancelDelete() {
      // 削除する学習済みAIの一覧をセット
      this.data.popup.showPopup = [] // 削除ポップアップを閉じるためのダミー
    },
    addNewTrainedAi() {
      if (this.accountInfo?.planDetail?.numModel > this.numOwnTrainedAIs) {
        this.deleteProgressTraining({ projectId: this.projectId })
        this.setProgressTraining({
          item: this.project,
          setType: 'project'
        })

        this.$gtmDataLayer.sendEvent('startTraining', 'fromTrainedAiList')
        this.$router.push({
          name: 'datasetSetting',
          params: { projectId: this.projectId }
        })
      } else {
        this.showPopup('upperLimitTrainedAis')
      }
    },
    async deleteTrainedAi(targets) {
      this.submitDeleting = true
      try {
        await this.deleteTrainedAIService(targets)
        await this.deleteTrainedAIs(targets)
        if (this.projectId) {
          await this.fetchProjectAIs()
          await this.loadProjectDetail(this.projectId)
        } else {
          throw new Error('deprecated')
        }
      } finally {
        this.submitDeleting = false
      }
      this.closePopup('deleteTrainedAi')
      this.closePopup('deleteServiceCheck')
    },
    async deleteTrainedAIService(targets) {
      const targetAIs = Object.values(this.projectTrainedAIs).filter((ai) => {
        return targets.includes(ai.id)
      })
      const hasServicesAIs = targetAIs.filter((ai) => ai.services.length > 0)
      if (hasServicesAIs.length > 0) {
        const ids = []
        for (const ai of hasServicesAIs) {
          const targetServices = ai.services.map((x) => x.serviceId)
          ids.push(...targetServices)
        }
        await this.deleteServices({
          serviceType: 'infer',
          projectId: this.projectId,
          payload: ids
        })
      }
    },
    projectMenuClick({ target, newProject = false }) {
      this.data.popup.intoProject = {
        targets: target.map((id) => this.trainedAIs[id]),
        newProject
      }
      this.showPopup('intoProject')
    },
    inferenceMenuClick({ target }) {
      if (this.projectId) {
        const query = {
          trainedAiId: target[0]
        }
        if (this.projectTrainedAIs[target[0]]?.is_optimization) {
          query.isOptimization = true
        }

        this.$router.push({
          name: 'inferenceProject',
          params: { projectId: this.projectId },
          query: query
        })
      } else {
        this.$router.push({
          name: 'inference'
        })
      }
    },
    serviceMenuClick() {
      if (this.projectId) {
        this.$router.push({
          name: 'serviceProjectList',
          params: { projectId: this.projectId }
        })
      } else {
        this.$router.push({
          name: 'serviceList'
        })
      }
    },
    async applyProjectAddTrainedAI(target) {
      this.submitOther = true
      this.closePopup('intoProject')
      try {
        if (target.project.new) {
          await this.projectAddTrainedAI({
            targets: target.targets,
            project: target.project
          })
          this.$router.push({
            name: 'projectList'
          })
        } else {
          await this.projectAddTrainedAI({
            targets: target.targets,
            projectId: target.project.id
          })
        }
        await this.fetchProjectAIs()
      } finally {
        this.submitOther = false
      }
      this.closePopup('intoProject')
    }
  }
}
</script>
