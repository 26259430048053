<template>
  <card-base>
    <div class="created-ai">
      <div class="created-ai-main">
        <texts
          class="created-ai-title"
          :text="$t('projectTemplate.detail.createdAIeffect')"
          size="big"
        />
        <div class="created-ai-main-inner">
          <texts
            class="created-ai-main-desc"
            :text="description"
            size="small"
            color="gray"
          />
          <div v-if="images && images.length > 0" class="created-ai-main-image">
            <img :src="encodeURI(images[0])" >
          </div>
        </div>
      </div>
      <div v-if="resultImages" class="created-ai-output">
        <texts
          class="created-ai-title"
          :text="$t('projectTemplate.detail.createdAIoutput')"
          size="big"
        />
        <div class="created-ai-output-list">
          <image-preview-card
            v-for="(resultImage, index) in resultImages"
            :key="index"
            class="created-ai-output-item"
            :image="resultImage"
          />
        </div>
      </div>
    </div>
  </card-base>
</template>

<script>
import texts from '@/components/atoms/text.vue'
import cardBase from '@/components/atoms/card-base.vue'
import imagePreviewCard from '@/components/molecules/image-preview-card.vue'

export default {
  components: {
    texts,
    cardBase,
    imagePreviewCard
  },
  props: {
    name: String,
    description: String,
    images: Array,
    formula: Object
  },
  computed: {
    resultImages() {
      if (!this.images || this.images.length === 0) return null
      const target = JSON.parse(JSON.stringify(this.images))
      return target.slice(1)
    }
  }
}
</script>

<style lang="scss" scoped>
.created-ai {
  &-title {
    margin: 0 0 $space-base;
  }
  &-main {
    margin: 0 0 $space-medium;
    &-inner {
      display: grid;
      grid-template-columns: 1fr adjustVW(800);
      grid-column-gap: $space-medium;
    }
    &-desc {
      line-height: 1.8;
      white-space: pre-line;
    }
    &-image {
      width: adjustVW(800);
      height: adjustVW(450); // 16:9にしたいのでVW
      background: $background-sub;
      border-radius: adjustVW(16);
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &-output {
    &-list {
      display: flex;
    }
    &-item {
      position: relative;
      margin: 0 $space-medium 0 0;
      &:last-of-type {
        margin: 0;
      }
    }
  }
}
</style>
