<template>
  <transition name="tutorial-fade">
    <div v-if="show" class="tutorial-wrapper" @click.stop.self="clickOuter">
      <div
        ref="tutorialSelect"
        class="tutorial-select"
        :class="{
          'tutorial-select-on': posOn
        }"
        :style="{
          '--top': selectTop + 'px',
          '--left': selectLeft + 'px',
          '--width': selectWidth + 'px',
          '--height': selectHeight + 'px'
        }"
      />
      <div
        ref="tutorialBody"
        class="tutorial-body"
        :class="{
          'tutorial-body-left': showContent.showLeft,
          'tutorial-body-bottom-inner': showContent.showBottomInner,
          'tutorial-body-on': posOn
        }"
        :style="{
          '--left': posLeft + 'px',
          '--top': posTop + 'px'
        }"
      >
        <div ref="tutorialInner" class="tutorial-inner">
          <div class="tutorial-title">
            <text-with-icon
              :text="showContent.title"
              :iconName="showContent.iconName"
              color="green"
            />
            <icons
              iconName="close"
              size="small"
              isButton
              @icon-click="clickClose"
            />
          </div>
          <div class="tutorial-content">
            <template v-for="(item, index) in items">
              <div
                v-if="index === count"
                :key="index"
                class="tutorial-content-body"
              >
                <slot :name="index" />
                <div class="tutorial-content-bottom">
                  <div class="tutorial-content-button">
                    <button-main
                      ref="buttonMain"
                      class="tutorial-content-button-item"
                      :text="buttonText"
                      type="green"
                      fontSize="15"
                      size="thin"
                      @click="clickMainButton"
                    />
                    <button-main
                      v-if="checkLast"
                      class="tutorial-content-button-item"
                      :text="$t('common.tutorial.restart')"
                      type="sub"
                      fontSize="15"
                      size="thin"
                      @click="count = 0"
                    />
                  </div>
                  <template v-if="checkLink">
                    <button @click="goManual">
                      <texts
                        :text="showContent.linkText"
                        size="min"
                        color="link-default"
                      />
                    </button>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import icons from '@/components/atoms/icon'
import buttonMain from '@/components/atoms/button-main'
import textWithIcon from '@/components/molecules/text-with-icon'

export default {
  components: {
    icons,
    buttonMain,
    textWithIcon
  },
  data() {
    return {
      posLeft: 0,
      posTop: 0,
      selectLeft: 0,
      selectTop: 0,
      selectWidth: 0,
      selectHeight: 0,
      count: 0,
      posOn: false
    }
  },
  props: {
    /** {title: 'タイトル', iconName: 'アイコン名', link: 'マニュアルURL', linkText: 'マニュアルリンクのテキスト', showLeft: 要素の左に表示するかどうか（boolean）} を渡す */
    items: Array,
    /** チュートリアルを表示するかどうか */
    show: Boolean,
    /** 表示するチュートリアルのdata属性 */
    type: String
  },
  watch: {
    target(newEl) {
      if (newEl) {
        this.posOn = false
        this.setVal(newEl)
      }
    },
    show(newVal) {
      if (!newVal) {
        window.removeEventListener('resize', this.resizeEvent)
        window.removeEventListener('scroll', this.resizeEvent)
      } else {
        window.addEventListener('resize', this.resizeEvent)
        window.addEventListener('scroll', this.resizeEvent)
      }
    }
  },
  methods: {
    setVal(el) {
      const val = el.getBoundingClientRect()
      this.posLeft = this.showContent?.showLeft ? val.left : val.right
      this.posTop = val.top
      this.selectLeft = val.left
      this.selectTop = val.top

      this.selectWidth = val.width
      if (this.showContent?.margin) {
        this.selectWidth = this.selectWidth + this.showContent.margin
        this.selectLeft = this.selectLeft - this.showContent.margin / 2
      }

      this.selectHeight = val.height
      if (this.showContent?.marginHeight) {
        this.selectHeight = this.selectHeight + this.showContent.marginHeight
        this.selectTop = this.selectTop - this.showContent.marginHeight / 2
      }

      this.$nextTick(() => {
        const bodyBound = this.$refs.tutorialBody.getBoundingClientRect()
        if (this.showContent?.showLeft) {
          this.posLeft = val.left - bodyBound.width
        }
        if (this.showContent?.showBottomInner) {
          this.posLeft = val.right - bodyBound.width
          this.posTop = val.bottom - bodyBound.height
        }
        this.posOn = true
      })
    },
    clickMainButton() {
      if (this.checkClose) {
        this.clickClose()
      } else {
        this.count++
      }
    },
    clickClose() {
      this.$emit('close-tutorial')
      this.reset()
    },
    reset() {
      this.target.style['box-shadow'] = '0 0 0 0 transparent inset'
      this.count = 0
      this.posLeft = 0
      this.posTop = 0
      this.selectLeft = 0
      this.selectTop = 0
      this.selectWidth = 0
      this.selectHeight = 0
    },
    goManual() {
      window.open(this.showContent.link, '_blank')
    },
    clickOuter() {
      if (!this.$refs?.buttonMain) return
      this.$refs.tutorialInner.style['box-shadow'] =
        '0 0 0 1.5px #049185 inset, 0 4px 16px rgba(43, 43, 44, 0.15)'
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.tutorialInner.style['box-shadow'] =
            '0 0 0 0px #049185 inset, 0 4px 16px rgba(43, 43, 44, 0.15)'
        }, 300)
      })
    },
    resizeEvent() {
      if (this.show) {
        this.posOn = false
        window.clearTimeout(this.resizeTimer)
        this.resizeTimer = window.setTimeout(
          function () {
            this.setVal(this.target)
          }.bind(this),
          200
        )
      }
    }
  },
  computed: {
    target() {
      if (!this.show) return null
      const el = document.querySelector(`[data-${this.type}="${this.count}"]`)
      if (!el) return null
      return el
    },
    showContent() {
      return this.items[this.count]
    },
    buttonText() {
      if (!this.items) return null
      if (this.checkClose) {
        return this.$t('common.close')
      } else {
        return this.$t('common.button.next')
      }
    },
    checkLast() {
      if (!this.items) return false
      return this.items.length > 1 && this.items.length - 1 === this.count
    },
    checkClose() {
      if (!this.items) return false
      return this.items.length === 1 || this.checkLast
    },
    checkLink() {
      return this.showContent?.link && this.showContent?.linkText
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeEvent)
    window.removeEventListener('scroll', this.resizeEvent)
  }
}
</script>

<style lang="scss" scoped>
.tutorial {
  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
  }
  &-select {
    --top: 0;
    --left: 0;
    --width: 0;
    --height: 0;
    position: absolute;
    top: var(--top);
    left: var(--left);
    width: var(--width);
    height: var(--height);
    border-radius: adjustVW(8);
    box-shadow: 0 0 0 adjustVW(0) $green inset;
    transition: box-shadow $transition-base;
    &-on {
      box-shadow: 0 0 0 adjustVW(2) $green inset;
      animation: showSelect $transition-base * 2;
    }
  }
  &-body {
    --left: 0;
    --top: 0;
    position: absolute;
    top: var(--top);
    left: calc(var(--left) + $space-medium);
    width: fit-content;
    opacity: 0;
    transition: opacity $transition-base;
    &-left {
      left: calc(var(--left) - $space-medium);
    }
    &-bottom-inner {
      left: var(--left);
    }
    &-on {
      opacity: 1;
      animation: showItem $transition-base * 2;
    }
  }
  &-inner {
    padding: $space-small;
    background: #fff;
    border-radius: adjustVW(8);
    box-shadow: $box-shadow-hover;
    transition: box-shadow $transition-base;
  }
  &-title {
    display: flex;
    align-items: center;
    grid-column-gap: $space-medium;
    margin-bottom: $space-small;
  }
  &-content {
    &-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-column-gap: $space-large;
      margin-top: $space-small;
    }
    &-button {
      display: flex;
      grid-column-gap: $space-small;
      &-item {
        width: adjustVW(128) !important;
        height: adjustVW(36);
        min-height: auto;
        + .c-button {
          margin-left: 0;
        }
      }
    }
  }
  &-fade {
    &-enter-active,
    &-leave-active {
      transition: opacity $transition-base;
    }
    &-enter,
    &-leave-to {
      opacity: 0;
    }
  }
}

@keyframes showItem {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes showSelect {
  0% {
    box-shadow: 0 0 0 adjustVW(0) $green inset;
  }
  100% {
    box-shadow: 0 0 0 adjustVW(2) $green inset;
  }
}
</style>
