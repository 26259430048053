<template>
  <div class="box">
    <texts
      v-if="title"
      class="box-title"
      :text="title"
      color="gray"
      size="small"
    />
    <select-box-input
      v-model="innerSelectItem"
      :items="predictionItems"
      :placeholder="$t('trainedAiDetails.timeseries.selectBox')"
      isGray
      isScroll
      showCloseButton
    />
  </div>
</template>

<script>
import selectBoxInput from '@/components/molecules/select-box-input.vue'

export default {
  components: {
    selectBoxInput
  },
  props: {
    finishColumns: Array,
    trainedAi: Object,
    title: String,
    value: String
  },
  computed: {
    predictionItems() {
      if (!this.trainedAi && !this.finishColumns) return null
      if (this.finishColumns && this.finishColumns.length > 0) {
        return this.finishColumns.map((item) => {
          return {
            name: item,
            value: item
          }
        })
      } else {
        const predictionColumn =
          this.trainedAi?.trainConfig?.predictionColumn ??
          this.trainedAi?.predictColumns
        return predictionColumn.map((item) => {
          return {
            name: item,
            value: item
          }
        })
      }
    },
    innerSelectItem: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  methods: {
    changeColumn(e) {
      if (this.innerSelectItem === e) return
      this.innerSelectItem = e
      const payload = {
        column: e,
        type: this.trainedAi.summary.type ?? this.trainedAi.type
      }
      this.$emit('change-column', payload)
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  &-title {
    margin-bottom: $space-base;
  }
}
</style>
