<template>
  <div class="add-new-service-wrap">
    <div class="add-new-service-input">
      <div class="add-new-service-input-block">
        <div class="add-new-service-input-inner">
          <input-box-validation
            v-model="name"
            :accept="validate.accept"
            :error="validate.error"
            :title="$t('serviceList.popup.addNewService.serviceName')"
            :placeholder="
              $t('serviceList.popup.addNewService.serviceNamePlaceholder')
            "
            popupView
            isNameValidation
            :nameValidationSuggests="serviceFormValidate.suggests"
          />
        </div>
        <div class="add-new-service-input-inner">
          <input-box
            v-model="description"
            isTextArea
            isGray
            :placeholder="
              $t(
                'serviceList.popup.addNewService.serviceDescriptionPlaceholder'
              )
            "
            :title="$t('serviceList.popup.addNewService.serviceDescription')"
          />
        </div>
      </div>
      <div class="add-new-service-input-button">
        <button-main
          type="emphasis"
          :isDisabled="disabledSubmit || disableClick"
          :text="$t('serviceList.popup.addNewService.submitButton')"
          @click="createService"
        />
        <button-main
          type="sub"
          :isDisabled="disableClick"
          :text="$t('common.cancel')"
          @click="$emit('cancel')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import inputBox from '@/components/molecules/input-box'
import inputBoxValidation from '@/components/molecules/input-box-validation.vue'

export default {
  components: {
    buttonMain,
    inputBox,
    inputBoxValidation
  },
  data() {
    return {
      name: '',
      description: '',

      validate: {
        accept: null,
        error: null
      }
    }
  },
  props: {
    serviceFormValidate: Object,
    disableClick: Boolean
  },
  computed: {
    disabledSubmit() {
      if (this.name === '') return true
      if (this.validate.error) return true

      return false
    }
  },
  watch: {
    name: function (val) {
      this.$emit('input-create-form', {
        type: 'service',
        form: {
          name: val
        }
      })
    },
    serviceFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    }
  },
  methods: {
    createService() {
      const res = {
        name: this.name,
        description: this.description
      }
      this.$emit('create-service', res)
    }
  },
  mounted: function () {
    this.$emit('input-create-form', {
      type: 'service',
      form: {
        name: this.name
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.add-new-service {
  &-wrap {
    width: adjustVW(768);
    height: 100%;
  }
  &-input {
    &-inner {
      margin: 0 0 $space-medium;
    }
  }
}
</style>
