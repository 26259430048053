<template>
  <div class="natural-languages-wrap">
    <div class="natural-languages-top">
      <texts
        class="natural-languages-top-title"
        :text="$t('training.config.detail.trainingDataset')"
        size="large"
      />
      <div class="natural-languages-inner">
        <text-with-title
          class="natural-languages-inner-item"
          :title="$t('training.config.detail.dataCount')"
          :text="selectedTrainingData.nTexts"
        />
        <text-with-title
          class="natural-languages-inner-item"
          :title="$t('training.config.detail.labelCount')"
          :text="selectedTrainingData.nLabels"
        />
        <text-with-title
          class="natural-languages-inner-item"
          :title="$t('training.config.detail.classCount')"
          :text="selectedTrainingData.nClasses"
        />
      </div>
    </div>
    <button-toggle
      class="natural-languages-list"
      :openText="$t('training.config.detail.datasetDetail')"
      :closeText="$t('training.config.detail.datasetDetail')"
    >
      <list-item-slot
        class="natural-languages-list-body"
        :field="field"
        :items="items"
      >
        <template v-slot:name="entry">
          <texts :text="entry.item" size="small" />
        </template>
        <template v-slot:count="entry">
          <div class="natural-languages-item-count-wrap">
            <texts
              class="natural-languages-item-count-text"
              :text="entry.item"
              size="small"
            />
            <texts
              class="natural-languages-item-count-percentage"
              :text="
                Math.ceil((entry.item / selectedTrainingData.nLabels) * 100) +
                  '%'
              "
              size="min"
              color="gray"
            />
          </div>
          <graph-bar-count
            class="natural-languages-item-bar"
            :count="entry.item"
            :maxCount="selectedTrainingData.nLabels"
          />
        </template>
        <template v-slot:texts="entry">
          <div class="natural-languages-item-inner" @mouseleave="closePopup">
            <div class="natural-languages-item-main">
              <button
                v-for="(data, index) in entry.item.datas"
                :key="index"
                class="natural-languages-item-data"
                @click="showPopup(data.label, index)"
              >
                <texts
                  class="natural-languages-item-data-text"
                  :text="data.body"
                  noTips
                />
                <popup-balloon-text
                  class="natural-languages-item-balloon"
                  :showPopup="popupId === index && popupLabel === data.label"
                  :posBottom="
                    Object.keys(selectedTrainingData.statistics).length -
                      Object.keys(selectedTrainingData.statistics).indexOf(
                        data.label
                      ) <
                      2 &&
                      Object.keys(selectedTrainingData.statistics).length > 3
                  "
                  :popupContents="data"
                />
              </button>
            </div>
          </div>
        </template>
      </list-item-slot>
    </button-toggle>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import textWithTitle from '@/components/molecules/text-with-title'
import buttonToggle from '@/components/molecules/button-toggle'
import listItemSlot from '@/components/molecules/list-item-slot.vue'
import GraphBarCount from '@/components/atoms/graph-bar-count.vue'
import popupBalloonText from '@/components/molecules/popup-balloon-text'

export default {
  components: {
    texts,
    textWithTitle,
    buttonToggle,
    listItemSlot,
    GraphBarCount,
    popupBalloonText
  },
  data() {
    return {
      popupId: null,
      popupLabel: null
    }
  },
  computed: {
    field() {
      return [
        {
          key: 'name',
          label: this.$t('training.config.detail.labelCount'),
          width: 178
        },
        {
          key: 'count',
          label: this.$t('training.config.detail.dataCount'),
          width: 136
        },
        {
          key: 'texts',
          label: this.$t('training.config.detail.texts'),
          width: 608
        }
      ]
    },
    items() {
      const items = []
      for (const [key, value] of Object.entries(
        this.selectedTrainingData.statistics
      )) {
        items.push({
          name: key,
          count: value,
          texts: this.selectedTrainingDataSample[key]
        })
      }
      return items
    }
  },
  methods: {
    showPopup(label, index) {
      if (this.popupId !== index) {
        this.popupId = null
        this.popupLabel = null
        this.popupId = index
        this.popupLabel = label
      }
    },
    closePopup() {
      this.popupId = null
      this.popupLabel = null
    }
  },
  props: {
    selectedTrainingData: Object,
    selectedTrainingDataSample: Object
  }
}
</script>

<style lang="scss" scoped>
.natural-languages {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-top {
    flex-shrink: 0;
    padding: 0 0 $space-small;
    margin: 0 0 $space-small;
    border-bottom: $border-sub;
    &-title {
      margin: 0 0 $space-small;
    }
  }
  &-inner {
    display: flex;
    &-item {
      margin: 0 $space-medium 0 0;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  &-list {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 1;
    height: 100%;
    ::v-deep .add-new-service-button {
      margin: 0 0 $space-sub;
    }
    ::v-deep > div {
      overflow-y: scroll;
      height: 100% !important; /* stylelint-disable-line declaration-no-important */
      padding: 0 $space-base 0 0;
      @include scrollbar;
    }
    &-body ::v-deep .list-item-entry-inner {
      overflow: visible;
    }
  }
  &-item {
    &-count {
      &-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 $space-base;
      }
      &-percentage {
        flex-shrink: 0;
      }
    }
    &-bar {
      z-index: 1;
    }
    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    &-control {
      flex-shrink: 0;
    }
    &-main {
      flex-shrink: 1;
      width: 100%;
    }
    &-data {
      position: relative;
      display: block;
      width: 100%;
      margin: 0 0 $space-base;
      &-text {
        overflow: hidden;
        width: 100%;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
