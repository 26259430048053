<template>
  <div
    :id="`scatter${$_uid}`"
    ref="scatter"
    v-resize:throttle.100="resized"
    class="scatter-plot"
  >
    <slot />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import * as d3 from 'd3'
import d3tip from 'd3-tip'
import * as Stardust from 'stardust-core'
import 'stardust-webgl'

import resize from 'vue-resize-directive'
export default {
  directives: {
    resize
  },
  name: 'StarScatter',
  props: {
    data: Array,
    xLabel: { type: String, default: 'X' },
    yLabel: { type: String, default: 'Y' },
    colorScale: { type: Array, default: () => [] }
  },
  mounted() {
    this.init()
    this.$nextTick(() => this.show2D())
  },
  data() {
    return {
      zoomRatio: 0.9,
      resizeCooltime: false,

      svg: null,
      root: null,
      area: null,
      platform: null,
      tip: null,
      xScale: null,
      yScale: null,
      xAxis: null,
      yAxis: null,
      gx: null,
      gy: null,

      showingTip: null,

      margin: { top: 10, right: 10, bottom: 40, left: 40 },
      computedMarginLeft: 40
    }
  },
  computed: {
    resizeParams() {
      return { data: this.data, xLabel: this.xLabel, yLabel: this.yLabel }
    }
  },
  methods: {
    init() {
      const margin = this.margin
      const el = this.$refs.scatter
      const outerWidth = el.clientWidth
      const outerHeight = el.clientHeight
      const width = Math.max(outerWidth - margin.left - margin.right, 100)
      const height = Math.max(outerHeight - margin.top - margin.bottom, 100)
      const svg = d3
        .select('#scatter' + this.$_uid)
        .append('svg')
        .classed('scatter-main', true)
        .attr('width', outerWidth)
        .attr('height', outerHeight)
        .attr('focusable', true)
        .on('focus', function () {
          svg.style('pointer-events', 'all')
        })
        .on('blur', function () {
          svg.style('pointer-events', 'unset')
        })
      this.svg = svg
      this.root = svg
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

      const xScale = d3.scaleLinear().range([0, width]).nice()
      const yScale = d3.scaleLinear().range([height, 0]).nice()
      this.xScale = xScale
      this.yScale = yScale

      const xAxis = d3.axisBottom(xScale).tickSize(-height)
      const yAxis = d3.axisLeft(yScale).tickSize(-width)
      this.xAxis = xAxis
      this.yAxis = yAxis

      const gx = this.root
        .append('g')
        .classed('x axis d3-axis', true)
        .call(this.xAxis)

      const xLabel = gx.append('text').classed('label', true)

      const gy = this.root
        .append('g')
        .classed('y axis d3-axis', true)
        .call(this.yAxis)
      this.gx = gx
      this.gy = gy

      gx.append('svg:line')
        .classed('axisLine v-axis-line', true)
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', -height)

      gy.append('svg:line')
        .classed('axisLine h-axis-line', true)
        .attr('x1', 0)
        .attr('y1', height)
        .attr('x2', width)
        .attr('y2', height)

      const yLabel = gy
        .append('text')
        .classed('label', true)
        .attr('transform', 'rotate(-90)')

      this.tip = d3tip()
        .attr('class', 'd3-tip')
        .style('z-index', 100)
        .offset([-10, 0])
        .html(this.generateTip)

      this.zoomBeh = d3
        .zoom()
        .scaleExtent([0, 500])
        .on('zoom', () => this.zoomed())
      this.root.call(this.tip)
      this.root.call(this.zoomBeh)

      this.area = d3
        .select('#scatter' + this.$_uid)
        .append('canvas')
        .classed('area', true)
        .attr('width', width)
        .attr('height', height)
        .style('left', `${margin.left}px`)
        .style('top', `${margin.top}px`)

      const vueThis = this
      this.root.node().onmousemove = function (...args) {
        vueThis.onmousemove(...args, this)
      }
      this.resized()
      this.zoomBeh.scaleTo(this.root, 0.9)
    },
    zoomed() {
      const zoomRatio = (this.zoomRatio = d3.event.transform.k)
      this.gx.call(this.xAxis.scale(d3.event.transform.rescaleX(this.xScale)))
      this.gy.call(this.yAxis.scale(d3.event.transform.rescaleY(this.yScale)))

      this.recalcYAxis()
      this.drawPoints(
        this.data,
        d3.event.transform.rescaleX(this.xScale),
        d3.event.transform.rescaleY(this.yScale)
      )
    },
    resized() {
      const margin = this.margin
      const el = this.$refs.scatter

      const outerWidth = el.clientWidth
      const outerHeight = el.clientHeight
      const width = Math.max(
        outerWidth - this.computedMarginLeft - margin.right,
        1
      )
      const height = Math.max(outerHeight - margin.top - margin.bottom, 1)
      console.log(width, height)

      this.root.attr(
        'transform',
        'translate(' + this.computedMarginLeft + ',' + margin.top + ')'
      )

      this.svg.attr('width', outerWidth).attr('height', outerHeight)

      this.xScale.range([0, width])
      this.yScale.range([height, 0])
      this.xAxis.tickSize(-height)
      this.yAxis.tickSize(-width)

      this.gx.attr('transform', 'translate(0,' + height + ')')

      this.gx
        .select('.v-axis-line')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', -height)

      this.gy
        .select('.h-axis-line')
        .attr('x1', 0)
        .attr('y1', height)
        .attr('x2', width)
        .attr('y2', height)

      this.gx
        .select('.label')
        .attr('x', width / 2)
        .attr('y', margin.bottom)
        .text(this.xLabel)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'text-after-edge')
        .attr('fill', 'black')

      this.recalcYAxis()

      this.area.attr('width', width).attr('height', height)

      const canvas = this.area.node()
      this.platform = Stardust.platform('webgl-2d', canvas, width, height)

      this.zoomBeh.translateBy(this.root, 0, 0)
    },
    recalcYAxis() {
      const margin = this.margin
      const el = this.$refs.scatter
      const outerHeight = el.clientHeight
      const height = outerHeight - margin.top - margin.bottom
      const yLabel = this.gy.select('.label').text(this.yLabel)
      const yLabelWidth = yLabel.node() ? yLabel.node().getBBox().height : 25 // rotated

      const minMarginLeft =
        d3.max(
          this.gy
            .selectAll('.tick text')
            .nodes()
            .map((x) => x.getBBox().width)
        ) + yLabelWidth
      this.computedMarginLeft = Math.max(margin.left, minMarginLeft)

      yLabel
        .attr('x', -height / 2)
        .attr('y', -this.computedMarginLeft + yLabelWidth)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'text-after-edge')
        .attr('fill', 'black')

      this.area.style('left', `${this.computedMarginLeft}px`)
    },
    clicked(d) {
      this.$emit('click', d)
    },
    onmousemove(e) {
      const canvas = this.area.node()
      const svg = this.svg.node()
      const x = e.clientX - canvas.getBoundingClientRect().left
      const y = e.clientY - canvas.getBoundingClientRect().top
      const offsetx = e.clientX - svg.getBoundingClientRect().left
      const offsety = e.clientY - svg.getBoundingClientRect().top
      const platform = this.platform
      const p = platform.getPickingPixel(
        x * platform.pixelRatio,
        y * platform.pixelRatio
      )
      if (p == null) {
        this.hideTip()
      } else {
        const [mark, i] = p
        this.showTip(mark._data[i], i, [offsetx, offsety])
      }
    },
    showTip(d, i, [x, y]) {
      if (this.showingTip != null) {
        this.hideTip()
      }
      const el = this.svg
        .append('g')
        .classed('dummy', true)
        .style('pointer-events', 'none')
        .attr('transform', 'translate(' + x + ',' + y + ')')
        .node()
      this.showingTip = [el, d, i, null]
      this.tip.show.call(...this.showingTip)
    },
    hideTip() {
      if (!this.showingTip) return
      this.tip.hide.call(...this.showingTip)
      this.showingTip[0].remove()
      this.showingTip = null
    },
    generateTip(d) {
      return `
        <p>${this.xLabel}: ${d.xValue}</p>
        <p>${this.yLabel}: ${d.yValue}</p>
        <p>${this.$t('clustering.result.class')}: ${
        d.class ? d.class : this.$t('clustering.result.noise')
      }</p>
        <p>${this.$t('datasetDetail.tableSide.row')}: ${d.index}</p>
      `
    },
    drawPoints(data, xs, ys) {
      const vueThis = this
      if (!xs) {
        xs = vueThis.xScale
      }
      if (!ys) {
        ys = vueThis.yScale
      }
      const x = function (d, i) {
        const xValue = d.xValue
        if (xValue == null || Number.isNaN(xValue)) {
          return null
        }
        return xs(xValue)
      }
      const y = function (d, i) {
        const yValue = d.yValue
        if (yValue == null || Number.isNaN(yValue)) {
          return null
        }
        return ys(yValue)
      }
      const color = function (d, i) {
        const colorValue = d?.class
        // ノイズ点の色
        if (colorValue == null || Number.isNaN(colorValue)) {
          const rgbNoise = d3.rgb('#e9cccf')
          return [rgbNoise.r / 255, rgbNoise.g / 255, rgbNoise.b / 255, 0.8]
        }
        // クラス数が10以上の場合の色の設定。グラデーションカラーになる
        if (vueThis.colorScale.length > 10) {
          // d3.interpolateSpectral: 暖色から寒色のグラデーション（白に近い色になる場合がある）
          const colorOdd = d3
            .scaleSequential(d3.interpolateSpectral)
            .domain([0, vueThis.colorScale.length])
          // d3.interpolateRainbow: 色相関係のみを変更するグラデーション（白色にならない）
          const colorEven = d3
            .scaleSequential(d3.interpolateRainbow)
            .domain([0, vueThis.colorScale.length])

          const colorIndex = vueThis.colorScale.indexOf(colorValue)
          let colorFix = null

          // グラデーションのままだと、隣り合ったクラスの色が似た色になってしまうため、奇数偶数に分けて異なるグラデーションから色をを設定している
          if (colorIndex % 2 > 0) {
            colorFix = colorOdd(colorIndex)
          } else {
            colorFix = colorEven(colorIndex)
          }
          const rgb = d3.rgb(colorFix)
          return [rgb.r / 255, rgb.g / 255, rgb.b / 255, 0.8]
        } else {
          // 10クラスまでの分類はd3に組み込まれているカラースキームを利用する
          // d3.schemeTableau10: Tableauで使われているグラフのカテゴリーわけのカラースキーム
          const colorFix = d3
            .scaleOrdinal(d3.schemeTableau10)
            .domain([...vueThis.colorScale])
          const rgb = d3.rgb(colorFix(colorValue))
          return [rgb.r / 255, rgb.g / 255, rgb.b / 255, 0.8]
        }
      }

      // noise点があるかどうかの処理。
      let fixData = {
        data: this.data,
        noise: null
      }
      if (this.colorScale.includes(null)) {
        const defaultObj = {
          data: [],
          noise: []
        }
        fixData = this.data.reduce((prev, current) => {
          if (current.class == null) {
            prev.noise.push(current)
          } else {
            prev.data.push(current)
          }
          return prev
        }, defaultObj)
      }

      const platform = vueThis.platform
      const canvas = this.area.node()
      platform.clear()

      // ノイズ点がある場合は先にノイズ点を描画する
      let cross = null
      if (fixData?.noise != null) {
        // ノイズ点の三角形の計算式を設定
        const squere = Stardust.mark.compile(`
            import { Triangle } from P2D;

            mark Glyph(
                x: float, y: float,
                v: float,
                color: Color = [ 0, 0, 0, 0.8 ]
            ) {
                let c = Vector2(x, y - v);
                let p1 = Vector2(x - v, y + v);
                let p2 = Vector2(x, y + v);
                let p3 = Vector2(x + v, y + v);
                Triangle(c, p1, p2, color);
                Triangle(c, p2, p3, color);
            }
        `)
        cross = Stardust.mark.create(squere.Glyph, vueThis.platform)
        const baseSize = 6

        // ノイズ点の三角形の式にデータを代入する
        cross
          .attr('x', (d) => x(d))
          .attr('y', (d) => y(d))
          .attr('v', baseSize)
          .attr('color', (d) => color(d))
        cross.data(fixData.noise)
        cross.render()
      }

      // 通常のデータの点の描画
      const circleSpec = Stardust.mark.circle()
      const circle = Stardust.mark.create(circleSpec, vueThis.platform)
      circle.attr('center', (d) => [x(d), y(d)])
      circle.attr('radius', 6)
      circle.attr('color', (d) => {
        return color(d)
      })

      circle.data(fixData.data)
      circle.render()

      platform.beginPicking(canvas.width, canvas.height)
      if (fixData?.noise != null) {
        cross.render()
      }
      circle.render()
      platform.endPicking()
    },
    show2D() {
      const data = this.data

      const xMax = d3.max(data, function (d) {
        return d.xValue
      })
      const xMin = d3.min(data, function (d) {
        return d.xValue
      })
      const yMax = d3.max(data, function (d) {
        return d.yValue
      })
      const yMin = d3.min(data, function (d) {
        return d.yValue
      })

      this.xScale.domain([xMin, xMax]).nice()
      this.yScale.domain([yMin, yMax]).nice()

      this.resized()
    }
  },
  watch: {
    resizeParams() {
      this.resized()
    },
    computedMarginLeft(nv, ov) {
      if (nv !== ov) this.resized()
    }
  },
  beforeDestroy() {
    d3.select('.scatter-plot').attr('style', 'pointer-events: none;')
  },
  destroyed() {
    this.tip.destroy()
  }
}
</script>
<style scoped>
.scatter-plot {
  min-width: 100px;
  min-height: 100px;
}
</style>
<style lang="scss">
/* stylelint-disable selector-no-qualifying-type */
.scatter-plot {
  .tick {
    user-select: none;
  }

  .h-axis-line {
    opacity: 0;
    fill: none;
    stroke: $border-gray;
  }

  .v-axis-line {
    opacity: 0;
    fill: none;
    stroke: $border-gray;
  }

  text.label {
    font-size: 1.4rem;
    white-space: pre-line;
  }
  .dot:not([fill]) {
    opacity: 0.5;
    fill: $key-color;
  }
}
.scatter-tip-image {
  max-width: 200px;
  max-height: 200px;
}

svg.scatter-main {
  position: relative;
  z-index: 2;
}

canvas.area {
  position: absolute;
  z-index: 1;
}

svg.scatter-main:focus rect {
  stroke: $gray;
}

.scatter-modal {
  width: min-content;
  max-width: unset;
}
</style>
