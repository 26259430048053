<template>
  <div
    class="add-tag-wrap"
    :class="[pos, size, { 'add-tag-wrap-edit': editting }]"
    @click="startEdit"
  >
    <div v-if="iconName && !editting" class="add-tag-inner">
      <icon
        :iconName="iconName"
        :size="size"
        :color="color"
        :isButton="isButton"
      />
    </div>
    <texts
      :text="text"
      :size="size"
      :color="color"
      :isShowAll="isShowAll"
      :isBold="isBold"
    >
      <form v-if="editting" @submit.stop="submitEdit">
        <input
          ref="editBox"
          v-model="editValue"
          :placeholder="
            placeholder
              ? placeholder
              : $t('molecules.tagEdittableList.placeholder')
          "
          class="add-tag-edit"
          @blur="cancelEdit($event)"
        >
      </form>
      <template v-else>
        <slot />
      </template>
    </texts>
    <!-- blur時に要素取得のため、こっちにクリックイベントを付与 -->
    <button
      v-if="editting"
      id="addTagSubmit"
      class="add-tag-edit-icon"
      @click.stop="submitEdit"
    >
      <icon iconName="check" :size="size" color="emphasis" />
    </button>
  </div>
</template>

<script>
import texts from '../atoms/text.vue'
import icon from '../atoms/icon.vue'

export default {
  name: 'addTagBox',
  components: {
    texts,
    icon
  },
  data() {
    return {
      editting: false,
      editValue: ''
    }
  },
  methods: {
    startEdit() {
      this.editting = true
      this.$nextTick(() => this.$refs.editBox.focus())
    },
    cancelEdit(e) {
      if (e.relatedTarget && e.relatedTarget.id === 'addTagSubmit') {
        return false
      } else {
        this.editValue = ''
        this.editting = false
      }
    },
    submitEdit() {
      this.$emit('add', this.editValue)
      this.editValue = ''
      this.editting = false
    }
  },
  props: {
    /** 使わないで下さい */
    text: String,
    /** ボタンの大きさtextsコンポーネントを同じ値を渡せます */
    size: {
      type: [String, Number],
      require: false,
      default: 'min'
    },
    /** アイコンを変更したい場合 */
    iconName: {
      type: String,
      require: false,
      default: 'plus'
    },
    /** アイコンの表示位置を変更したい場合 */
    pos: {
      type: String,
      require: false,
      default: 'left',
      validator: function (value) {
        return ['left', 'right'].indexOf(value) !== -1
      }
    },
    /** アイコンとテキストの色を変更 */
    color: {
      type: String,
      require: false,
      default: 'function'
    },
    /** アイコン部分を押下可能にするかどうか */
    isButton: {
      type: Boolean,
      require: false,
      default: true
    },
    /** 長いテキストを入れた場合、折り返して表示するかどうか */
    isShowAll: {
      type: Boolean,
      require: false,
      default: false
    },
    /** 太字にするかどうか */
    isBold: {
      type: Boolean,
      require: false,
      default: false
    },
    /** プレースホルダーに表示するテキスト */
    placeholder: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.add-tag {
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    height: 100%;
    padding: adjustVW(6) $space-base;
    background: $key-color;
    border-radius: adjustVW(2);
    cursor: pointer;
    box-shadow: $border-radius-emphasis inset;
    transform-origin: left;
    transition: width $transition-base, background-color $transition-base;
    &.right {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    &-edit {
      position: relative;
      width: adjustVW(200);
      background: $background-decoration;
      z-index: 0;
    }
  }
  &-inner {
    padding: 0 $space-small 0 0;
    .small & {
      padding: 0 $space-sub 0 0;
    }
    .min & {
      padding: 0 $space-base 0 0;
    }
    .right & {
      padding: 0 0 0 $space-small;
    }
    .right.small & {
      padding: 0 0 0 $space-sub;
    }
  }
  &-edit {
    width: 100%;
    padding: 0;
    color: $text-decoration;
    &::placeholder {
      color: $text-decoration;
      font-size: $text-min;
    }
    /* stylelint-disable-next-line selector-pseudo-element-no-unknown */
    &::input-placeholder {
      color: $text-decoration;
      font-size: $text-min;
    }
  }
}
</style>
