<template>
  <div class="apply-body-wrap">
    <div class="apply-body-tab">
      <div class="apply-body-tab-list">
        <tab-list
          :tabs="tabs"
          :activeTabProps="activeTab"
          @change-tab="$emit('change-tab', $event)"
        />
      </div>
      <div class="apply-body-toggle">
        <transition-toggle-contents>
          <loading-icon v-if="mainData.loadingAll" />
        </transition-toggle-contents>
        <toggle-button
          :isDisabled="mainData.loadingAll || mainData.settedAll"
          :checked="mainData.settedAll"
          :text="$t('preprocessing.tooLargeOutlier')"
          @input="getOutlier"
        />
      </div>
    </div>
    <div class="apply-body-table">
      <table-main-preprocessing
        :tableColumns="tableColumns"
        :table="table"
        :tableNullColumns="nullColumns"
        :tableOutlierColumns="outlierColumns"
        :graph="graph"
        :isStatistics="visibleContents === 'statistics'"
        :pagingCount="7"
        :loadingAll="mainData.loadingAll"
        :settedOutlier="mainData.settedAll"
        @show-detail="showDetail"
        @get-statistics="$emit('get-statistics')"
      />
    </div>
  </div>
</template>

<script>
import loadingIcon from '../../atoms/loading-icon.vue'
import toggleButton from '@/components/atoms/toggle-button.vue'
import tableMainPreprocessing from '@/components/molecules/table-main-preprocessing'
import tabList from '@/components/molecules/tab-list'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    loadingIcon,
    toggleButton,
    tableMainPreprocessing,
    tabList,
    transitionToggleContents
  },
  props: {
    dataset: Object,
    graph: Object,
    statistics: Object,
    visibleContents: String,
    tabs: Array,
    activeTab: Number,
    includeOutlier: Boolean,
    mainData: Object
  },
  methods: {
    cropName(name) {
      if (name.length > 10) {
        return name.substring(0, 10) + '...'
      } else {
        return name
      }
    },
    showDetail(event) {
      if (this.visibleContents) {
        this.$emit('show-detail', event)
      } else {
        this.$emit('show-statistics-detail', event)
      }
    },
    getOutlier(bool) {
      this.$emit('get-outlier')
    }
  },
  computed: {
    nullColumns() {
      if (!this.dataset || !this.dataset?.tableColumns) return []
      const obj = {}
      for (const column of this.dataset.tableColumns) {
        obj[column.name] = column.nNull
      }
      return obj
    },
    outlierColumns: function () {
      if (!this.dataset || !this.dataset?.tableColumns) return {}

      const obj = {}
      for (const column of this.dataset.tableColumns) {
        obj[column.name] = column?.outlierNums
      }
      return obj
    },
    tableColumns() {
      if (this.visibleContents === 'dataset') {
        return this.dataset.tableColumns.map((item) => item.name)
      } else {
        return this.statistics.tableColumns.map((item) => item.name)
      }
    },
    table() {
      if (this.visibleContents === 'dataset') {
        return this.dataset.table
      } else {
        return this.statistics.table
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.apply-body {
  &-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 $space-medium $space-medium;
  }
  &-table {
    overflow: hidden;
    flex-shrink: 1;
    height: 100%;
    padding: $space-base $space-base $space-base $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
  }
  &-tab {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: $space-sub;
  }
  &-toggle {
    display: flex;
    align-items: center;
    grid-column-gap: $space-small;
  }
}
</style>
