<template>
  <div class="column-info">
    <div class="column-info-list">
      <card-base class="column-info-card">
        <div class="column-info-list-body">
          <div class="column-info-top">
            <texts
              class="column-info-title"
              :text="$t('trainedAi.timeTransformerV2.conditions.title')"
              size="large"
            />
            <texts
              class="column-info-desc"
              :text="$t('trainedAi.timeTransformerV2.conditions.description')"
              size="small"
            />
          </div>
          <list-item-slot
            :field="fields"
            :items="items"
            class="column-info-list-inner"
          >
            <template v-slot:isKnownField>
              <div
                v-tooltip="
                  $t('trainedAi.timeTransformerV2.conditions.type.isKnown.info')
                "
              >
                <text-with-icon
                  :text="
                    $t(
                      'trainedAi.timeTransformerV2.conditions.type.isKnown.title'
                    )
                  "
                  iconName="info"
                  size="small"
                  pos="right"
                  isBold
                />
              </div>
            </template>
            <template v-slot:isTimeVaryingField>
              <div
                v-tooltip="
                  $t(
                    'trainedAi.timeTransformerV2.conditions.type.isTimeVarying.info'
                  )
                "
              >
                <text-with-icon
                  :text="
                    $t(
                      'trainedAi.timeTransformerV2.conditions.type.isTimeVarying.title'
                    )
                  "
                  iconName="info"
                  size="small"
                  pos="right"
                  isBold
                />
              </div>
            </template>
            <template v-slot:isRealField>
              <div
                v-tooltip="
                  $t('trainedAi.timeTransformerV2.conditions.type.isReal.info')
                "
              >
                <text-with-icon
                  :text="
                    $t(
                      'trainedAi.timeTransformerV2.conditions.type.isReal.title'
                    )
                  "
                  iconName="info"
                  size="small"
                  pos="right"
                  isBold
                />
              </div>
            </template>
            <template v-slot:columnName="entry">
              <texts :text="entry.item" size="small" />
            </template>
            <template v-slot:isKnown="entry">
              <div>
                <texts
                  :text="conditionText('isKnown', entry.item.value)"
                  size="small"
                  :color="'default'"
                />
              </div>
            </template>
            <template v-slot:isTimeVarying="entry">
              <div
                v-tooltip="
                  entry.item.auto
                    ? $t('trainedAi.timeTransformerV2.conditions.autoSetting')
                    : ''
                "
              >
                <texts
                  :text="conditionText('isTimeVarying', entry.item.value)"
                  size="small"
                  :color="entry.item.auto ? 'emphasis' : 'default'"
                />
              </div>
            </template>
            <template v-slot:isReal="entry">
              <div
                v-tooltip="
                  entry.item.auto
                    ? $t('trainedAi.timeTransformerV2.conditions.autoSetting')
                    : ''
                "
              >
                <texts
                  :text="conditionText('isReal', entry.item.value)"
                  size="small"
                  :color="entry.item.auto ? 'emphasis' : 'default'"
                />
              </div>
            </template>
          </list-item-slot>
        </div>
      </card-base>
    </div>
    <div class="column-info-side">
      <card-base v-if="checkPeriod">
        <div class="column-info-setting">
          <div>
            <text-with-icon
              v-tooltip="
                $t('trainedAi.timeTransformerV2.duration.predict.description')
              "
              class="column-info-title"
              :text="$t('trainedAi.timeTransformerV2.duration.predict.title')"
              iconName="info"
              size="small"
              color="gray"
              pos="right"
            />
            <texts
              :text="trainedAi.trainConfig.timeseries.maxPredictionLength"
            />
          </div>
          <div>
            <text-with-icon
              v-tooltip="
                $t('trainedAi.timeTransformerV2.duration.input.description')
              "
              class="column-info-title"
              :text="$t('trainedAi.timeTransformerV2.duration.input.title')"
              iconName="info"
              size="small"
              color="gray"
              pos="right"
            />
            <texts :text="trainedAi.trainConfig.timeseries.maxEncoderLength" />
          </div>
        </div>
      </card-base>
      <card-base class="column-info-card">
        <div class="column-info-side-body">
          <setting-list
            :settingList="trainedAi.trainConfig.columnConditions"
            :predictionColumns="trainedAi.summary.predictionColumns"
            noFilter
          />
        </div>
      </card-base>
    </div>
  </div>
</template>

<script>
import cardBase from '@/components/atoms/card-base.vue'
import listItemSlot from '@/components/molecules/list-item-slot.vue'
import textWithIcon from '@/components/molecules/text-with-icon.vue'
import settingList from '@/components/organisms/time-transformer-v2-settings/setting-list.vue'
export default {
  components: { cardBase, listItemSlot, textWithIcon, settingList },
  props: {
    finishColumns: Array,
    trainedAi: Object,
    loadChangeColumn: Boolean,
    selectedColumnIndex: Number
  },
  computed: {
    fields() {
      return [
        {
          label: this.$t('common.columnName'),
          key: 'columnName',
          width: 160
        },
        {
          key: 'isKnown',
          width: 240
        },
        {
          key: 'isTimeVarying',
          width: 240
        },
        {
          key: 'isReal',
          width: 240
        }
      ]
    },
    items() {
      const selectedColumn =
        this.trainedAi.summary.predictionColumns[this.selectedColumnIndex]
      let columnConditions
      if (
        this.trainedAi.trainConfig.overrideColumnConditions[selectedColumn] !=
        null
      ) {
        columnConditions =
          this.trainedAi.trainConfig.overrideColumnConditions[selectedColumn]
      } else {
        columnConditions = this.trainedAi.trainConfig.columnConditions
      }
      return columnConditions.filter((column) => {
        return !this.trainedAi.summary.predictionColumns.includes(
          column.columnName
        )
      })
    },
    checkPeriod() {
      const timeConfig = this.trainedAi.trainConfig.timeseries
      return (
        timeConfig != null &&
        timeConfig.maxEncoderLength != null &&
        timeConfig.maxPredictionLength != null
      )
    }
  },
  methods: {
    conditionText(type, value) {
      const fixValue = value ? 'on' : 'off'
      return this.$t(
        `trainedAi.timeTransformerV2.conditions.type.${type}.${fixValue}`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.column-info {
  overflow: hidden;
  display: grid;
  grid-template-areas: 'list side';
  grid-template-columns: 1fr adjustVW(532);
  grid-template-rows: minmax(0, 1fr);
  grid-column-gap: $space-medium;
  width: 100%;
  height: 100%;
  padding: 0 $space-medium $space-medium;
  &-list {
    height: 100%;
    grid-area: list;
    &-inner {
      overflow-y: scroll;
      height: 100%;
      padding-right: $space-base;
      @include scrollbar;
    }
    &-body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
  &-side {
    grid-area: side;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    grid-row-gap: $space-medium;
    &-body {
      overflow: hidden;
      height: 100%;
    }
  }
  &-top {
    margin-bottom: $space-sub;
  }
  &-title {
    width: fit-content;
    margin-bottom: $space-min;
  }
  &-desc {
    white-space: pre-line;
  }
  &-card {
    height: 100%;
  }
  &-setting {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $space-medium;
  }
}
</style>
