// 実行できるテキストマイニングの種類
export const TEXT_MINING_TYPE = {
  SENTIMENTS: 'sentiments',
  CO_OCCURRENCE: 'coOccurrence',
  LDA: 'lda',
  FREQUENCIES: 'frequencies',
  WORDCLOUD: 'wordcloud'
}

// テキストマイニングの結果表示でサイドバーに表示されるページの種類
export const TEXT_MINING_PAGE_TYPE = {
  TEXT_LIST: 'textList',
  TEXT_TOPIC_DETAIL: 'textTopicDetail',
  TEXT_TOPIC: 'textTopic',
  FREQUENCIES_WORDCLOUD: 'frequenciesWordCloud',
  CO_OCCURRENCE: 'coOccurrence'
}

// テキストマイニングの結果を取得する際に叩くActionの種類
export const TEXT_MINING_LOAD_TYPE = {
  TEXT_LIST: 'textList',
  TEXT_TOPIC_DETAIL: 'textTopicDetail',
  TEXT_TOPIC: 'textTopic',
  CO_OCCURRENCE: 'coOccurrence',
  FREQUENCIES: 'frequencies',
  WORDCLOUD: 'wordcloud'
}

// テキストマイニングの種類をサイドバーのページの種類に変換する変換表
export const TEXT_MINING_TYPE_TO_PAGE_TYPE = {
  [TEXT_MINING_TYPE.SENTIMENTS]: 'textList',
  [TEXT_MINING_TYPE.CO_OCCURRENCE]: 'coOccurrence',
  [TEXT_MINING_TYPE.LDA]: 'textTopic',
  [TEXT_MINING_TYPE.FREQUENCIES]: 'frequenciesWordCloud',
  [TEXT_MINING_TYPE.WORDCLOUD]: 'frequenciesWordCloud'
}

// サイドバーのページの種類をテキストマイニングの種類に変換する変換表
export const TEXT_MINING_PAGE_TYPE_TO_TYPE = {
  [TEXT_MINING_PAGE_TYPE.TEXT_LIST]: [TEXT_MINING_LOAD_TYPE.TEXT_LIST],
  [TEXT_MINING_PAGE_TYPE.TEXT_TOPIC_DETAIL]: [TEXT_MINING_LOAD_TYPE.TEXT_TOPIC_DETAIL],
  [TEXT_MINING_PAGE_TYPE.TEXT_TOPIC]: [TEXT_MINING_LOAD_TYPE.TEXT_TOPIC],
  [TEXT_MINING_PAGE_TYPE.FREQUENCIES_WORDCLOUD]: [TEXT_MINING_LOAD_TYPE.FREQUENCIES, TEXT_MINING_LOAD_TYPE.WORDCLOUD],
  [TEXT_MINING_PAGE_TYPE.CO_OCCURRENCE]: [TEXT_MINING_LOAD_TYPE.CO_OCCURRENCE]
}

// テキストマイニングで取得した情報やパラメータの定義
export const textMiningResultSetting = function () {
  return {
    fromDashboad: false,
    activeTab: null,
    textList: null,
    textTopic: null,
    textTopicDetail: null,
    coOccurrence: null,
    frequencies: null,
    wordcloud: null,
    params: {
      [TEXT_MINING_PAGE_TYPE.TEXT_LIST]: {
        sentiments: 'negative',
        sortTarget: 'indexDesc',
        page: 1
      },
      [TEXT_MINING_PAGE_TYPE.TEXT_TOPIC_DETAIL]: {
        targetTopic: 1,
        maxWords: 50
      },
      [TEXT_MINING_PAGE_TYPE.TEXT_TOPIC]: {
        targetName: null
      },
      [TEXT_MINING_PAGE_TYPE.FREQUENCIES_WORDCLOUD]: {
        count: 20
      },
      [TEXT_MINING_PAGE_TYPE.CO_OCCURRENCE]: {
        maxShow: 20,
        keyword: ''
      },
      columnName: null
    },
    waitTextMiningLoading: false
  }
}
