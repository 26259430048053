<template>
  <div>
    <button-main
      :text="$t('training.button.stopTraining')"
      type="caution"
      @click="$emit('background-training-stop-confirm')"
    />
    <button-main
      :text="$t('common.cancel')"
      type="sub"
      @click="$emit('close-modal', 'backgroundTrainingStop')"
    />
  </div>
</template>

<script>
import ButtonMain from '@/components/atoms/button-main.vue'

export default {
  components: {
    ButtonMain
  }
}
</script>
