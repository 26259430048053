<template>
  <div class="template-wrap">
    <div class="template-main">
      <div class="template-inner">
        <div class="template-item">
          <text-with-title
            :title="$t('serviceList.popup.addNewService.datasetSelectedTitle')"
            :text="
              datasetDetail
                ? datasetDetail.detail.name
                : $t('common.notSelected')
            "
          />
        </div>
        <div class="template-item">
          <texts
            class="template-item-title"
            :text="$t('selectTargetColumn.menu.targetColumnInfo.title')"
            size="small"
            color="gray"
          />
          <div class="template-item-selected">
            <texts
              v-for="(item, key) in selectedPredictionColumns"
              :key="key"
              class="template-item-selected-item"
              :text="item"
            />
          </div>
        </div>
        <div v-tooltip.bottom-start="cautionTips" class="template-item">
          <texts
            class="template-item-title"
            :text="$t('service.learn.inputColumns')"
            size="small"
            color="gray"
          />
          <select-box-input
            v-model="inputText"
            :items="columns"
            isGray
            isScroll
            noDisabledItem
            :option="option"
            :noIcon="!this.datasetColumns || !this.datasetDetail"
            :placeholder="
              $t(
                'serviceList.popup.addNewService.timeseriesMulti.selectTrainColumnPlaceholder'
              )
            "
          />
        </div>
      </div>
      <div class="template-button">
        <button-large
          class="template-button-item"
          :text="$t('common.checkSetting')"
          :isDisabled="
            !inputText || isInputPredictionColumn || isInputNotTimeColumn
          "
          @click="clickSelectColumn"
        />
      </div>
    </div>
    <dataset-setting-table
      class="template-detail"
      :table="checkDatasetDetail.table"
      :columns="checkDatasetDetail.tableColumns"
      :type="checkDatasetDetail.type"
      :nLabels="nLabels"
    />
  </div>
</template>

<script>
import buttonLarge from '@/components/atoms/button-large'
import datasetSettingTable from '@/components/organisms/dataset-setting/dataset-setting-table'
import selectBoxInput from '@/components/molecules/select-box-input'
import textWithTitle from '@/components/molecules/text-with-title'

export default {
  components: {
    buttonLarge,
    datasetSettingTable,
    selectBoxInput,
    textWithTitle
  },
  data() {
    return {
      inputText: '',
      nLabels: 0,
      option: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.2,
        keys: [
          {
            name: 'value',
            weight: 0.5
          }
        ],
        distance: 10000
      }
    }
  },
  props: {
    projectId: Number,
    datasetColumns: Array,
    datasetDetail: Object,
    selectedPredictionColumns: Array
  },
  computed: {
    checkDatasetDetail() {
      if (this.datasetDetail == null) return {}
      if (this.datasetDetail?.data_type === 'list') {
        return {
          tableColumns: Object.keys(this.datasetDetail.detail.dtypes),
          table: this.datasetDetail.list,
          type: this.datasetDetail.data_type
        }
      }
      return {}
    },
    columns() {
      if (!this.datasetColumns || !this.datasetDetail) return []
      const others = []
      const time = []
      this.datasetColumns.forEach((item) => {
        if (!this.selectedPredictionColumns.includes(item)) {
          const checkTime =
            this.datasetDetail.detail.dtypes[item] !== 'datetime_prob'
          const checkTimeVal = checkTime
            ? ' ' +
              this.$t(
                'serviceList.popup.addNewService.timeseriesMulti.cautionTrainItem'
              )
            : ''
          if (!checkTime) {
            time.push({
              name: item,
              value: item
            })
          } else {
            others.push({
              name: item + checkTimeVal,
              value: item,
              disabled: true
            })
          }
        }
      })
      return [...time, ...others]
    },
    isInputPredictionColumn() {
      return this.selectedPredictionColumns.some(
        (item) => item === this.inputText
      )
    },
    isInputNotTimeColumn() {
      if (!this.datasetDetail) return false
      const checkExistColumn = this.columns.some(
        (item) => item.value === this.inputText
      )
      return (
        checkExistColumn &&
        this.datasetDetail.detail.dtypes[this.inputText] !== 'datetime_prob'
      )
    },
    cautionTips() {
      if (this.isInputPredictionColumn) {
        return {
          content: this.$t(
            'serviceList.popup.addNewService.timeseriesMulti.cautionTrainTips'
          ),
          show: this.isInputPredictionColumn,
          trigger: ['manual']
        }
      } else if (this.isInputNotTimeColumn) {
        return {
          content: this.$t(
            'serviceList.popup.addNewService.timeseriesMulti.cautionTrainTipsNotTime'
          ),
          show: this.isInputNotTimeColumn,
          trigger: ['manual']
        }
      } else {
        return null
      }
    }
  },
  methods: {
    clickSelectColumn() {
      this.$emit('select-column', { inputColumns: [this.inputText] })
    }
  }
}
</script>

<style lang="scss" scoped>
.template {
  &-wrap {
    display: grid;
    grid-template-columns: minmax(adjustVW(594), 1fr) adjustVW(966);
    grid-column-gap: $space-medium;
    width: 100%;
    height: 100%;
  }
  &-main {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  &-item {
    &-title {
      margin-bottom: $space-base;
    }
    &-selected {
      overflow-x: scroll;
      display: flex;
      grid-column-gap: $space-sub;
      width: 100%;
      padding-bottom: $space-base;
      margin-bottom: $space-base;
      @include scrollbar;
      &-item {
        flex-shrink: 0;
        max-width: adjustVW(160);
      }
    }
  }
  &-inner {
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-medium;
    height: 100%;
  }
  &-detail {
    overflow: hidden;
  }
}
</style>
