<template>
  <div class="datasource-wrap">
    <div class="datasource-info">
      <div class="datasource-block">
        <div class="datasource-inner">
          <div class="datasource-type">
            <datasource-type-wrap :type="datasourceInfo.dbtype" />
          </div>
        </div>
        <div class="datasource-inner">
          <text-with-title
            v-if="datasourceInfo.port"
            class="datasource-text"
            :title="$t('datasetList.datasourceList.port')"
            :text="datasourceInfo.port"
            isShowAll
          />
          <text-with-title
            v-if="datasourceInfo.database"
            class="datasource-text"
            :title="$t('datasetList.datasourceList.dbName')"
            :text="datasourceInfo.database"
            isShowAll
          />
          <text-with-title
            v-if="datasourceInfo.GCPDatasetId"
            class="datasource-text"
            :title="$t('datasetList.datasourceList.GCPDatasetId')"
            :text="datasourceInfo.GCPDatasetId"
            isShowAll
          />
        </div>
      </div>
      <div class="datasource-block">
        <text-with-title
          v-if="datasourceInfo.host"
          class="datasource-host"
          :title="$t('datasetList.datasourceList.host')"
          :text="datasourceInfo.host"
          isShowAll
        />
        <text-with-title
          v-if="datasourceInfo.GCPProject"
          class="datasource-host"
          :title="$t('datasetList.datasourceList.GCPProject')"
          :text="datasourceInfo.GCPProject"
          isShowAll
        />
      </div>
      <div v-if="!hideReset" class="datasource-block">
        <button
          class="datasource-setting"
          @click="$emit('set-datasource', { datasourceInfo: datasourceInfo })"
        >
          <text-with-icon
            :text="$t('datasetList.popup.showDatasource.reconfigure')"
            iconName="datasource"
            size="small"
          />
        </button>
      </div>
    </div>
    <template v-if="!connectionError">
      <div class="datasource-tab">
        <tab-list
          :tabs="importTypeTabs"
          :activeTab="selectImportType"
          @change-tab="changeTabContent"
        />
      </div>
      <div class="datasource-list">
        <transition-toggle-contents>
          <div
            v-if="!loadingDatasourceItems"
            key="content"
            class="datasource-list-inner"
          >
            <transition-toggle-contents>
              <div
                v-if="selectImportType === 'table'"
                key="table"
                class="datasource-list-content"
              >
                <div class="datasource-list-scroll">
                  <list-item
                    :field="field"
                    :items="dataSourceItems"
                    isButton
                    @list-click="showTable($event.id)"
                  />
                </div>
              </div>
              <div
                v-else-if="selectImportType === 'sql'"
                key="sql"
                class="datasource-list-content"
              >
                <div class="datasource-list-scroll">
                  <list-item
                    :field="sqlField"
                    :items="fixDatasourceItem"
                    isButton
                    @list-click="showSql($event.id)"
                  />
                </div>
                <div class="datasource-list-add">
                  <button-main
                    :text="$t('datasetList.popup.showDatasource.addSql')"
                    type="sub"
                    @click="createNewSql"
                  />
                </div>
              </div>
            </transition-toggle-contents>
          </div>
          <div v-else key="load" class="datasource-list-inner">
            <loading />
          </div>
        </transition-toggle-contents>
      </div>
    </template>
    <div v-else>
      <texts
        class="datasource-error"
        :text="$t('datasetList.datasourceList.connectionError')"
        size="small"
        color="gray"
      />
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main.vue'
import datasourceTypeWrap from '@/components/atoms/datasource-type-wrap'
import loading from '@/components/atoms/loading'
import listItem from '@/components/molecules/list-item'
import tabList from '@/components/molecules/tab-list'
import textWithIcon from '@/components/molecules/text-with-icon'
import textWithTitle from '@/components/molecules/text-with-title'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import moment from 'moment-timezone'

export default {
  components: {
    buttonMain,
    datasourceTypeWrap,
    loading,
    listItem,
    tabList,
    textWithIcon,
    textWithTitle,
    transitionToggleContents
  },
  data() {
    return {
      selectImportType: 'table'
    }
  },
  props: {
    datasourceInfo: Object,
    dataSourceItems: Array,
    dataSourceSqlItems: Array,
    loadingDatasourceItems: Boolean,
    connectionError: Boolean,
    hideReset: Boolean
  },
  computed: {
    field() {
      return [
        {
          id: 0,
          key: 'name',
          label: this.$t(
            'datasetList.popup.addDataset.datasource.namePlaceholder'
          )
        },
        {
          id: 1,
          key: 'n_rows',
          label: this.$t('datasetList.popup.addDataset.datasource.nData')
        }
      ]
    },
    sqlField() {
      return [
        {
          id: 0,
          key: 'created_at',
          label: this.$t('common.createTime')
        },
        {
          id: 1,
          key: 'import_sql',
          label: this.$t('datasetList.popup.showDatasource.importSql')
        }
      ]
    },
    importTypeTabs() {
      return [
        {
          id: 0,
          name: this.$t('datasetList.popup.showDatasource.importFromTable'),
          value: 'table',
          iconName: 'download'
        },
        {
          id: 1,
          name: this.$t('datasetList.popup.showDatasource.importFromSql'),
          value: 'sql',
          iconName: 'datasource'
        }
      ]
    },
    fixDatasourceItem() {
      if (
        this.dataSourceSqlItems == null ||
        this.dataSourceSqlItems.length === 0
      )
        return []
      return this.dataSourceSqlItems.map((item) => {
        const res = item
        res.created_at = this.formattedDate(item.created_at)
        return res
      })
    }
  },
  methods: {
    showTable: function (id) {
      const payload = {
        datasourceId: this.datasourceInfo.id,
        table: this.dataSourceItems[id],
        tableId: id
      }
      this.$emit('show-table', payload)
    },
    changeTabContent(type) {
      this.selectImportType = type
      if (type === 'sql') {
        this.$emit('get-sql-list', this.datasourceInfo.id)
      }
    },
    createNewSql() {
      this.$emit('show-sql-edit')
    },
    showSql(id) {
      this.$emit('show-sql-edit', id)
    },
    formattedDate(time) {
      const UtcDate = moment.utc(time)
      const date = UtcDate.local()
      return date.format('YYYY MM DD')
    }
  }
}
</script>

<style lang="scss" scoped>
.datasource {
  &-wrap {
    overflow: hidden;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto minmax(0, 1fr);
    grid-row-gap: $space-medium;
    width: 100%;
    height: 100%;
  }
  &-info {
    padding: 0 0 $space-medium;
    border-bottom: $border-sub;
  }
  &-block {
    display: flex;
    width: 100%;
    margin: 0 0 $space-small;
    &:last-child {
      margin: 0;
    }
  }
  &-inner {
    margin: 0 $space-medium 0 0;
    &:last-child {
      overflow-x: hidden;
      margin: 0;
    }
  }
  &-text {
    margin: 0 0 $space-sub;
    &:last-child {
      margin: 0;
    }
  }
  &-type {
    display: flex;
    align-items: center;
    justify-content: center;
    width: adjustVW(120);
    height: adjustVH(80);
    background: $background-sub;
  }
  &-setting {
    margin: 0 0 0 auto;
  }
  &-list {
    overflow: hidden;
    height: 100%;
    &-inner {
      height: 100%;
    }
    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      grid-row-gap: $space-small;
      height: 100%;
    }
    &-scroll {
      overflow-y: scroll;
      width: 100%;
      height: 100%;
      max-height: adjustVW(440);
      padding-right: $space-sub;
      @include scrollbar;
    }
    &-add {
      width: fit-content;
      cursor: pointer;
    }
  }
  &-error {
    white-space: pre-line;
  }
}
</style>
