<!-- 各種学習済みAI詳細の表示に呼び出されているが、利用していないコンポーネント。不要なら削除 -->
<template>
  <transition-toggle-contents>
    <trained-ai-detail-tab-detail
      v-if="activeTab === 'detail'"
      key="detail"
      :trainedAi="trainedAi"
      :selectedColumnIndex="selectedColumnIndex"
    />
    <trained-ai-detail-tab-expected-effect
      v-else-if="activeTab === 'expectedEffect'"
      key="expectedEffect"
      :expectedEffect="trainedAi.expectedEffect"
    />
    <trained-ai-detail-tab-accuracy
      v-else-if="activeTab === 'accuracy'"
      key="accuracy"
      :trainedAi="trainedAi"
      :metricsDefs="metricsDefs"
      :selectedColumnIndex="selectedColumnIndex"
      @download-confusion-matrix="$emit('download-confusion-matrix', $event)"
    />
    <trained-ai-detail-tab-text-mining
      v-else-if="activeTab === 'wordcloud'"
      key="wordcloud"
      :wordcloud="trainedAi.wordcloudImg"
      :frequencies="trainedAi.frequencies"
    />
    <trained-ai-detail-tab-trained-info
      v-else-if="activeTab === 'trainedInfo'"
      key="trainedInfo"
      :trainedAi="trainedAi"
      :metricsDefs="metricsDefs"
      :loadChangeColumn="loadChangeColumn"
    />
  </transition-toggle-contents>
</template>

<script>
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import trainedAiDetailTabDetail from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-detail'
import trainedAiDetailTabExpectedEffect from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-expected-effect'
import trainedAiDetailTabAccuracy from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-accuracy'
import trainedAiDetailTabTextMining from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-text-mining'
import trainedAiDetailTabTrainedInfo from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-trained-info'

import { naturalLanguage } from '../metricsDefs.js'

export default {
  components: {
    transitionToggleContents,
    trainedAiDetailTabDetail,
    trainedAiDetailTabExpectedEffect,
    trainedAiDetailTabAccuracy,
    trainedAiDetailTabTextMining,
    trainedAiDetailTabTrainedInfo
  },
  props: {
    trainedAi: Object,
    tabs: Array,
    menus: Array,
    activeTab: String,
    activeMenu: String,
    selectedColumnIndex: Number,
    loadChangeColumn: Boolean
  },
  computed: {
    metricsDefs() {
      return naturalLanguage(this.$t.bind(this))
    }
  }
}
</script>
