<template>
  <describe-of-error
    :description="$t('training.popup.errorTraining.desc')"
    :okButton="$t('training.popup.errorTraining.reload')"
    :cancelButton="$t('training.popup.errorTraining.contact')"
    @ok="reload"
    @cancel="goContact"
  />
</template>

<script>
import describeOfError from '@/components/organisms/common-popups/describe-of-error'

export default {
  components: {
    describeOfError
  },
  methods: {
    reload() {
      this.$router.go(0)
    },
    goContact() {
      window.open(this.$urls.contactLink, '_blank')
    }
  }
}
</script>
