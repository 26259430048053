<template>
  <div class="co-occurrence">
    <div class="co-occurrence-top">
      <config-box-co-occurrence
        :value="value"
        :total="80"
        :columns="columns"
        :columnName="columnName"
        :waitTextMiningLoading="waitTextMiningLoading"
        @create-co-occurrence="$emit('create-co-occurrence', $event)"
      />
    </div>
    <card-base class="co-occurrence-main">
      <div class="co-occurrence-main-top">
        <texts
          :text="$t('trainedAi.textMining.types.coOccurrence')"
          size="small"
          color="gray"
        />
        <!--
          TODO 共起ネットワークの図のダウンロード機能
        <button>
          <text-with-icon
            :text="$t('textMining.coOccurrence.download')"
            iconName="download"
            size="small"
          />
        </button>
        -->
      </div>
      <transition-toggle-contents>
        <div v-if="waitTextMiningLoading" key="loading" class="not-found">
          <div>
            <loading />
            <texts
              class="not-found-content loading-message"
              color="gray"
              size="small"
            >
              {{ $t('textMining.coOccurrence.loading') }}
            </texts>
          </div>
        </div>
        <div
          v-else-if="value.keyword == null || value.keyword.length === 0"
          key="not-set"
          class="not-found"
        >
          <texts
            class="not-found-content"
            :text="$t('textMining.coOccurrence.info')"
            size="small"
            color="gray"
          />
        </div>
        <div
          v-else-if="coOccurrence && coOccurrence.length === 0"
          key="not-found"
          class="not-found"
        >
          <texts
            class="not-found-content"
            :text="$t('textMining.coOccurrence.noResult')"
            size="small"
            color="gray"
          />
        </div>
        <div
          v-else-if="
            !waitTextMiningLoading && coOccurrence && coOccurrence.length > 0
          "
          key="main"
          class="co-occurrence-graph"
        >
          <coOccurrenceGraph
            :graph="coOccurrence"
            :targetKeywordRelation="targetKeywordRelation"
            :targetValue="value.keyword"
          />
        </div>
      </transition-toggle-contents>
    </card-base>
    <card-base class="co-occurrence-sub">
      <div class="co-occurrence-sub-top">
        <texts
          :text="$t('textMining.coOccurrence.list.title')"
          size="small"
          color="gray"
        />
      </div>
      <transition-toggle-contents>
        <div
          v-if="!waitTextMiningLoading"
          key="list"
          class="co-occurrence-sub-list"
        >
          <list-item-slot :field="field" :items="items" nonIcon>
            <template v-slot:word="entry">
              <texts :text="entry.item" size="small" color="gray" />
            </template>
            <template v-slot:relevance="entry">
              <texts :text="entry.item" size="small" color="gray" />
            </template>
            <template v-slot:frequency="entry">
              <texts :text="entry.item" size="small" color="gray" />
            </template>
          </list-item-slot>
        </div>
        <div v-else key="list-loading" class="not-found">
          <loading />
        </div>
      </transition-toggle-contents>
    </card-base>
  </div>
</template>

<script>
import loading from '@/components/atoms/loading'
import cardBase from '@/components/atoms/card-base'
import listItemSlot from '@/components/molecules/list-item-slot'
import coOccurrenceGraph from '@/components/molecules/text-mining/co-occurrence-graph'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import configBoxCoOccurrence from './config-box/co-occurrence'

import { toRounded } from '@/lib/misc'

export default {
  components: {
    loading,
    cardBase,
    listItemSlot,
    coOccurrenceGraph,
    transitionToggleContents,
    configBoxCoOccurrence
  },
  props: {
    coOccurrence: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {}
    },
    columns: {
      type: Array,
      default: () => []
    },
    waitTextMiningLoading: {
      type: Boolean,
      default: false
    },
    columnName: {
      type: String,
      default: ''
    }
  },
  computed: {
    field() {
      return [
        {
          id: 0,
          key: 'word',
          label: this.$t('textMining.coOccurrence.list.word'),
          width: 100
        },
        {
          id: 1,
          key: 'relevance',
          label: this.$t('textMining.coOccurrence.list.relevance'),
          width: 80
        },
        {
          id: 2,
          key: 'frequency',
          label: this.$t('textMining.coOccurrence.list.frequency'),
          width: 80
        }
      ]
    },
    targetKeywordRelation() {
      const data = this.coOccurrence.reduce((prev, current) => {
        if (current[0] === this.value.keyword) {
          prev[current[1]] = {
            value: current[4],
            frequency: current[3]
          }
        }
        return prev
      }, {})
      return data
    },
    items() {
      const list = Object.entries(this.targetKeywordRelation).map(
        ([key, value]) => {
          return {
            word: key,
            relevance: toRounded(value.value),
            frequency: value.frequency
          }
        }
      )

      list.sort((x, y) => {
        return y.relevance - x.relevance
      })

      return list
    }
  }
}
</script>

<style lang="scss" scoped>
.co-occurrence {
  display: grid;
  grid-template-areas:
    'config config'
    'main sub';
  grid-template-columns: 1fr adjustVW(360);
  grid-template-rows: auto 1fr;
  grid-column-gap: $space-medium;
  grid-row-gap: $space-medium;
  height: 100%;
  &-top {
    width: fit-content;
    grid-area: config;
  }
  &-main {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    grid-area: main;
    &-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: $space-sub;
    }
  }
  &-sub {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    grid-area: sub;
    &-top {
      flex-shrink: 0;
      margin-bottom: $space-text;
    }
    &-list {
      overflow-y: scroll;
      @include scrollbar;
    }
  }
  &-graph {
    overflow: hidden;
    height: 100%;
  }
}

.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  &-content {
    text-align: center;
    white-space: pre-line;
  }
  .loading-message {
    margin-top: $space-medium;
  }
}
</style>
