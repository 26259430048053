<template>
  <popup
    :showPopup="showPopup"
    :title="title"
    @close-modal="$emit('close-modal', $event)"
  >
    <div class="dataset-detail-wrapper">
      <div class="dataset-detail-item">
        <description :text="about" />
      </div>

      <div class="dataset-detail-column">
        <div
          v-if="lackedColumns && lackedColumns.length > 0"
          class="dataset-detail-item"
        >
          <texts
            class="dataset-detail-item-title"
            size="large"
            color="caution"
            :text="
              this.$i18n.t('inference.settings.selectDataset.lackedColumns')
            "
          />
          <div class="dataset-detail-bottom-item-list">
            <div
              v-for="(column, index) in lackedColumns"
              :key="index + 'lacked'"
              class="dataset-detail-bottom-item-column"
            >
              <bullet-item :caution="true" :text="column" />
            </div>
          </div>
        </div>

        <div class="dataset-detail-bottom-item">
          <texts
            class="dataset-detail-item-title"
            size="large"
            :text="
              this.$i18n.t('inference.settings.selectDataset.includeedColumuns')
            "
          />
          <div class="dataset-detail-bottom-item-list">
            <div
              v-for="(column, index) in datasetColums"
              :key="index + 'include'"
              class="dataset-detail-bottom-item-column"
            >
              <bullet-item :isDisabled="column.abled" :text="column.text" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </popup>
</template>

<script>
import bulletItem from '@/components/atoms/bullet-item.vue'
import { popup } from '@/components/molecules/popup'
import texts from '@/components/atoms/text.vue'
import description from '@/components/atoms/description.vue'

export default {
  components: {
    bulletItem,
    popup,
    texts,
    description
  },
  props: {
    about: String,
    includeColumns: Array,
    showPopup: {
      type: Boolean,
      default: false
    },
    title: String,
    requiredColumns: Array
  },
  computed: {
    datasetColums() {
      const col = []
      for (const index in this.includeColumns) {
        col.push({
          text: this.includeColumns[index].text,
          abled: !this.requiredColumns.includes(this.includeColumns[index].text)
        })
      }
      return col
    },
    lackedColumns() {
      const col = []
      if (!this.includeColumns || this.includeColumns.length === 0) return col
      for (const index in this.requiredColumns) {
        if (
          !this.includeColumns.some(
            (column) => column.text === this.requiredColumns[index]
          )
        ) {
          col.push(this.requiredColumns[index])
        }
      }
      return col
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.dataset-detail {
  &-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: adjustVW(800);
  }
  &-item {
    margin-bottom: $space-medium;
  }

  &-item-title {
    margin-bottom: $space-base;
  }

  &-column {
    overflow-y: auto;
    flex-shrink: 1;
    max-height: 100%;
    @include scrollbar;
  }
  &-bottom {
    &-item {
      &-list {
        display: flex;
        flex-wrap: wrap;
      }
      &-column {
        width: calc(50% - #{adjustVW(16)});
        margin: 0 $space-large $space-sub 0;
        &:nth-of-type(2n) {
          margin: 0 0 $space-sub;
        }
      }
    }
  }
}
</style>
