<template>
  <card-base class="text-card">
    <div class="text-card-body">
      <div class="text-card-title">
        <div class="text-card-title-content">
          <texts :text="fileName" size="small" isBold />
        </div>
        <button v-if="showLda" @click="goTextDetail">
          <text-with-icon
            :text="$t('common.showDetail')"
            iconName="forward"
            size="small"
            color="gray"
            pos="right"
          />
        </button>
      </div>
      <text-scroll :contents="contents" />
      <div v-if="showSentiment" class="text-card-sentiment">
        <sentiments-bar :proba="sentiments.proba" />
      </div>
    </div>
  </card-base>
</template>

<script>
import cardBase from '@/components/atoms/card-base'
import sentimentsBar from '@/components/molecules/text-mining/sentiments-bar'
import textWithIcon from '@/components/molecules/text-with-icon'
import textScroll from './text-scroll.vue'

export default {
  components: {
    cardBase,
    sentimentsBar,
    textWithIcon,
    textScroll
  },
  props: {
    fileName: {
      type: [String, Number],
      default: ''
    },
    contents: {
      type: String,
      default: ''
    },
    sentiments: {
      type: Object,
      default: null
    },
    showSentiment: {
      type: Boolean,
      default: false
    },
    showLda: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goTextDetail() {
      this.$emit('go-text-detail', this.fileName.toString())
    }
  }
}
</script>

<style lang="scss" scoped>
.text-card {
  height: 100%;
  &-body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-align: left;
  }
  &-title {
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    grid-column-gap: $space-sub;
    width: 100%;
    margin-bottom: $space-base;
    &-content {
      overflow: hidden;
    }
  }
  &-sentiment {
    flex-shrink: 0;
    width: 100%;
    height: fit-content;
    margin-top: $space-sub;
  }
}
</style>
