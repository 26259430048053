var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('prevent-leave',{ref:"preventLeave",attrs:{"title":_vm.$t('common.trainLeave.popup.title'),"saveButton":_vm.$t('common.trainLeave.popup.saveButton', { currentPage: _vm.currentPage }),"leaveButton":_vm.$t('common.trainLeave.popup.leaveButton'),"leaveButtonColor":"sub"},on:{"save-event":_vm.closePrevent},model:{value:(_vm.isPrevent),callback:function ($$v) {_vm.isPrevent=$$v},expression:"isPrevent"}},[_c('texts',{staticClass:"prevent-leave-text",attrs:{"isShowAll":"","size":"small"}},[_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.$t('common.trainLeave.reason.first', {
            leavePage: _vm.leavePage,
            currentPage: _vm.currentPage
          })
        )}}),_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.$t('common.trainLeave.reason.second', { currentPage: _vm.currentPage })
        )}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('common.trainLeave.reason.third'))}})])],1),_c('recipe-list',{attrs:{"isTrain":"","sidebar":_vm.sidebar,"projectInfo":_vm.projectInfo,"progressTraining":_vm.progressTraining,"headerTabs":_vm.headerTabs,"popup":_vm.popup,"recipes":_vm.projectRecipes,"accountInfo":_vm.accountInfo,"recipeLayers":_vm.recipeLayers,"recipeTemplates":_vm.recipeTemplates,"loadingRecipeTemplates":_vm.loadingRecipeTemplates,"loading":_vm.loading,"notFound":_vm.notFoundProject,"disableClick":_vm.disableClick,"incorrectOrder":_vm.incorrectOrder},on:{"menu-click":_vm.iconMenuClick,"close-modal":_vm.closePopup,"delete-recipes":_vm.deleteRecipe,"get-recipe-template":function($event){return _vm.getRecipeTemplate()},"save-recipe-template":function($event){return _vm.saveRecipeTemplate($event)},"not-prevent":_vm.notPrevent,"restart-prevent":_vm.restartPrevent,"page-back":_vm.pageBack}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }