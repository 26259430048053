<template>
  <div :id="`confusion${$_uid}`" class="confusion-plot" />
</template>

<script>
/* eslint-disable no-unused-vars */
import * as d3 from 'd3'
import d3tip from 'd3-tip'

import { intlSorter, sortedIndex } from '@/lib/sort'

export default {
  name: 'Confusion',
  props: {
    data: Array,
    labels: { type: Array, default: null },
    gamma: { type: Number, default: 1.5 },
    xLabelDir: { type: Number, default: 0 },
    yLabelDir: { type: Number, default: 90 },
    sorted: { type: Boolean, default: false },
    propsWidth: { type: Number, default: 300 },
    propsHeight: { type: Number, default: 300 }
  },
  mounted() {
    this.show2D()
  },
  data() {
    return {
      xAxisTitle: null,
      yAxisTitle: null,
      tip: null
    }
  },
  computed: {
    props() {
      const data = this.data
      const labels = this.labels
      const gamma = this.gamma
      const xLabelDir = this.xLabelDir
      const yLabelDir = this.yLabelDir
      const sorted = this.sorted
      return {
        data,
        labels,
        gamma,
        xLabelDir,
        yLabelDir,
        sorted
      }
    },
    sortIndex() {
      if (this.labels && this.sorted) {
        return sortedIndex(this.labels, intlSorter)
      } else {
        return null
      }
    },
    total() {
      let t = 0
      this.sortedData.forEach((row) => row.forEach((v) => (t += v)))
      return t
    },
    dataAndPosition() {
      return this.sortedData.map((row, i) => row.map((v, j) => [+v, i, j]))
    },

    sortedLabels() {
      const nRows = this.data.length
      const nCols = this.data[0].length
      const unsortedLabels = this.labels || [...Array(Math.max(nRows, nCols)).keys()]
      if (this.labels && this.sorted) {
        const si = this.sortIndex
        const labels = []
        for (let i = 0; i < nRows; i++) {
          labels[i] = unsortedLabels[si[i]]
        }
        return labels
      } else {
        return unsortedLabels
      }
    },
    sortedData() {
      const unsortedData = this.data.map((row, i) => row.map((v, j) => +v))
      const nRows = unsortedData.length
      const nCols = unsortedData[0].length
      if (this.labels && this.sorted) {
        const si = this.sortIndex
        const data = []
        for (let i = 0; i < nRows; i++) {
          data[i] = new Array(nCols)
          for (let j = 0; j < nCols; j++) {
            data[i][j] = unsortedData[si[i]][si[j]]
          }
        }
        return data
      } else {
        return unsortedData
      }
    }
  },
  methods: {
    toLabel(i) {
      if (this.labels) {
        if (this.sorted) {
          const si = this.sortIndex
          return this.labels[si[i]]
        } else {
          return this.labels[i]
        }
      }
      return i
    },
    updateLocale() {
      this.xAxisTitle.text(this.$t('graph.confusion.predicted'))
      this.yAxisTitle.text(this.$t('graph.confusion.actual'))
    },
    show2D() {
      const nRows = this.data.length
      const nCols = this.data[0].length
      const data = this.sortedData
      const labels = this.sortedLabels

      const el = document.getElementById('confusion' + this.$_uid)
      while (el.firstChild) el.removeChild(el.lastChild) // remove all child elements of el
      const margin = { top: 50, right: 50, bottom: 50, left: 50 }
      const outerWidth = this.propsWidth
      const outerHeight = this.propsHeight
      const width = outerWidth - margin.left - margin.right
      const height = outerHeight - margin.top - margin.bottom

      const xLabelDir = this.xLabelDir
      const yLabelDir = this.yLabelDir

      const vTotal = d3.sum(data, function (row) {
        return d3.sum(row, (v) => v)
      })
      const vMax = d3.max(data, function (row) {
        return d3.max(row, (v) => v)
      })
      const vMin = d3.min(data, function (row) {
        return d3.min(row, (v) => v)
      })

      const x = d3.scaleLinear().range([0, width]).domain([0, nCols])
      const y = d3.scaleLinear().range([0, height]).domain([0, nRows])

      const xAxis = d3.axisBottom(x).tickSize(-height).ticks(nCols)
      const yAxis = d3.axisLeft(y).tickSize(-width).ticks(nRows)
      const colWidth = width / nCols
      const rowHeight = height / nRows

      // 文字色をx ** gammaの値によって変更できるようになったら['#e9e0ea', '#850491']に変更
      const MfInterpolate = d3.interpolateRgbBasis(['#e9e0ea', '#850491'])
      const color = d3
        .scaleSequential((x) => MfInterpolate(x ** this.gamma))
        .domain([0, vMax])

      const svg = d3.select('#confusion' + this.$_uid).append('svg')

      const mainArea = svg.append('g')
      const vueThis = this

      this.tip = d3tip()
        .attr('class', 'd3-tip')
        .offset([-10, 0])
        .html((d, ...args) => {
          const [v, i, j] = d
          const li = this.toLabel(i)
          const lj = this.toLabel(j)
          const percent = ((100 * v) / this.total).toFixed(3)
          return `<div><p>${this.$t(
            'graph.confusion.actual'
          )} : ${li}</p><p>${this.$t(
            'graph.confusion.predicted'
          )} : ${lj}</p><p><center>${v}</center></p></div>`
        })

      svg.call(this.tip)

      mainArea.append('g').attr('width', width).attr('height', height)

      const xAxisEl = mainArea
        .append('g')
        .classed('x axis d3-axis', true)
        .attr('transform', 'translate(0,' + height + ')')

      const yAxisEl = mainArea
        .append('g')
        .classed('y axis d3-axis', true)
        .attr('transform', 'rotate(-90)')

      xAxisEl
        .append('g')
        .selectAll('.ticklabel')
        .data(labels.slice(0, nCols))
        .enter()
        .each(labelGenX)
      yAxisEl
        .append('g')
        .selectAll('.ticklabel')
        .data(labels.slice(0, nRows))
        .enter()
        .each(labelGenY)

      let xAxisOffset = xAxisEl.node().getBBox().height
      this.xAxisTitle = xAxisEl
        .append('text')
        .text(this.$t('graph.confusion.predicted'))
        .style('font-size', '1.8rem')
      xAxisOffset += this.xAxisTitle.node().getBBox().height
      this.xAxisTitle
        .classed('label', true)
        .attr('fill', 'currentColor')
        .attr('x', width / 2)
        .attr('y', xAxisOffset)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'text-before-edge')

      let yAxisOffset = yAxisEl.node().getBBox().height
      this.yAxisTitle = yAxisEl
        .append('text')
        .text(this.$t('graph.confusion.actual'))
        .style('font-size', '1.8rem')
      yAxisOffset += this.yAxisTitle.node().getBBox().height
      this.yAxisTitle
        .classed('label', true)
        .attr('fill', 'currentColor')
        .attr('y', -yAxisOffset)
        .attr('x', -height / 2)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'text-after-edge')
      const objects = mainArea
        .append('svg')
        .classed('objects', true)
        .attr('width', width)
        .attr('height', height)

      function labelGenX(dat, index) {
        //        console.log(dat, index, colWidth, (index + 0.5) * colWidth)
        const lx = (index + 0.5) * colWidth
        const ly = 10

        d3.select(this)
          .append('g')
          .attr('transform', `translate(${lx},${ly})`)
          .append('text')
          .classed('ticklabel', true)
          .text(dat)
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'central')
          .attr('transform', `rotate(${xLabelDir})`)
          .style('font-size', '1.4rem')
      }
      function labelGenY(dat, index) {
        //        console.log(dat, index, colWidth, (index + 0.5) * colWidth)

        const lx = -(index + 0.5) * colWidth
        const ly = -10
        d3.select(this)
          .append('g')
          .attr('transform', `translate(${lx},${ly})`)
          .append('text')
          .classed('ticklabel', true)
          .text(dat)
          .attr('text-anchor', 'end')
          .attr('dominant-baseline', 'central')
          .attr('transform', `rotate(${yLabelDir})`)
          .style('font-size', '1.4rem')
      }
      function rowGen(rowData, index) {
        function showtip(v, i, ...oth) {
          vueThis.tip.show.call(this, [v, index, i], i, ...oth)
        }
        function hidetip(v, i, ...oth) {
          vueThis.tip.hide.call(this, [v, index, i], i, ...oth)
        }
        const target = d3
          .select(this)
          .selectAll('.cell')
          .data(rowData)
          .enter()
          .append('g')
          .classed('cell', true)
          .attr('transform', (_, idx) => 'translate(' + x(idx) + ',0)')
          .attr('width', colWidth)
          .attr('height', rowHeight)
        target
          .append('rect')
          .attr('width', colWidth)
          .attr('height', rowHeight)
          .style('fill', color)
          //         .on('mouseover', tip.show)
          //          .on('mouseout', tip.hide)
          .on('mouseenter', showtip)
          .on('mouseout', hidetip)
        const text = target
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'central')
          .attr('x', colWidth / 2)
          .attr('y', rowHeight / 2)
          .text((v) => v)
          .each(function (item) {
            const preWidth = this.getBoundingClientRect().width
            const preHeight = this.getBoundingClientRect().height
            if (rowHeight > preHeight) {
              this.setAttribute('font-size', (rowHeight / preHeight) * 75 + '%') // 行の高さの4/3のフォントサイズ
            }
            if (preWidth > colWidth) {
              this.setAttribute('font-size', (colWidth / preWidth) * 100 + '%')
            }
            if (!isNaN(item)) {
              if (item / vMax > 0.5) {
                this.setAttribute('fill', '#ffffff') // 半分を超えたら白（紫と黒では見づらいため）
              }
            }
          })
      }

      objects
        .selectAll('.row')
        .data(data)
        .enter()
        .append('g')
        .classed('row', true)
        .attr('transform', (_, idx) => 'translate(0,' + y(idx) + ')')
        .each(rowGen)

      const defs = svg.append('defs')

      const legendGradient = defs
        .append('linearGradient')
        .attr('id', 'legend' + this.$_uid)
        .attr('x1', '0%')
        .attr('x2', '0%')
        .attr('y1', '100%')
        .attr('y2', '0%')

      const gradientSplit = 10
      const [gradientMin, gradientMax] = color.domain()
      const gradientD = gradientMax - gradientMin
      for (let i = 0; i <= gradientSplit; i++) {
        legendGradient
          .append('stop')
          .attr(
            'stop-color',
            color((i / gradientSplit) * gradientD + gradientMin)
          )
          .attr('offset', (100.0 * i) / gradientSplit + '%')
      }

      mainArea
        .append('g')
        .append('rect')
        .attr('x', width + 10)
        .attr('width', 36)
        .attr('height', height)
        .style('fill', 'url(#legend' + this.$_uid + ')')

      const bb = mainArea.node().getBBox()
      svg.attr('width', bb.width).attr('height', bb.height)
      mainArea.attr('transform', 'translate(' + -bb.x + ',' + -bb.y + ')')
    }
  },
  watch: {
    '_i18n.locale'() {
      this.updateLocale()
    },
    props() {
      this.show2D()
    }
  },
  beforeDestroy() {
    d3.select('.confusion-plot').attr('style', 'pointer-events: none;')
  },
  destroyed() {
    this.tip.destroy()
  }
}
</script>
<style lang="scss">
.cell {
  transform-origin: center center;
  text {
    pointer-events: none;
  }
}
</style>
