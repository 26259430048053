<template>
  <div class="delete-wrap">
    <div class="delete-inner">
      <texts :text="$t('training.popup.delete.text1')" />
      <texts :text="$t('training.popup.delete.text2')" />
    </div>
    <div class="delete-button-list">
      <button-main
        :text="$t('training.button.discardTrainedAi')"
        :isDisabled="disableClick"
        type="caution"
        @click="$emit('delete-confirm')"
      />
      <button-main
        :text="$t('common.cancel')"
        :isDisabled="disableClick"
        type="sub"
        @click="$emit('close-modal', 'deleteTrainedAi')"
      />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import ButtonMain from '@/components/atoms/button-main'

export default {
  components: {
    texts,
    ButtonMain
  },
  props: {
    loadingDelete: Boolean,
    disableClick: Boolean
  }
}
</script>

<style lang="scss" scoped>
.delete {
  &-wrap {
    width: 100%;
  }
  &-inner {
    margin: 0 0 $space-medium;
  }
  &-button-list {
    width: 100%;
  }
}
</style>
