<template>
  <div class="save-trained-ai-wrap">
    <template v-if="!showConfirm">
      <template v-for="(trainedAi, index) in sortedTrainedAis">
        <save-trained-ai-individual
          v-if="showTrainedAi === index"
          :key="index"
          :isMulti="sortedTrainedAis.length > 1"
          :loadingSave="loadingSave"
          :metricsDefs="metricsDefs"
          :modelType="modelType"
          :reSelectFlag="reSelectFlag"
          :showAllPass="sortedTrainedAis.length !== index + 1"
          :trainedAi="trainedAi"
          :trainedAIFormValidate="trainedAIFormValidate"
          :trainedAIIndex="index"
          :disableClick="disableClick"
          :checkOptimization="checkOptimization"
          @close-modal="$emit('close-modal', 'saveTrainedAi')"
          @input-create-form="$emit('input-create-form', $event)"
          @pass-all-trained-ai="passAllTrainedAi(index)"
          @pass-this-trained-ai="passThisTrainedAi(index)"
          @save-single-trained-ai="saveSingleTrainedAi()"
          @save-this-trained-ai="saveThisTrainedAi(index)"
        />
      </template>
    </template>
    <div v-else>
      <save-trained-ai-multi
        :sortedTrainedAis="sortedTrainedAis"
        :modelType="modelType"
        :metricsDefs="metricsDefs"
        :saveTrainedAis="saveTrainedAis"
        :loadingSave="loadingSave"
        :accountInfo="accountInfo"
        :numOwntrainedAIs="numOwntrainedAIs"
        :numCreatingtrainedAIs="numCreatingtrainedAIs"
        :disableClick="disableClick"
        @re-select="reSelect($event)"
        @save-confirm="$emit('save-confirm', saveTrainedAis)"
        @discard-confirm="$emit('discard-confirm')"
        @close-modal="$emit('close-modal', 'saveTrainedAi')"
      />
    </div>
  </div>
</template>

<script>
import saveTrainedAiIndividual from './save-trained-ai-individual'
import saveTrainedAiMulti from './save-trained-ai-multi'

import { getModelType } from '@/lib/trainedAI.js'

// metricsと学習の形式の組み合わせで表示文言が変わるので、それをまとめている
import * as metricsDefsByModelType from '@/components/organisms/trained-ai-detail/metricsDefs'

export default {
  components: {
    saveTrainedAiIndividual,
    saveTrainedAiMulti
  },
  data() {
    return {
      showTrainedAi: 0,
      saveTrainedAis: [],
      showConfirm: false,
      reSelectFlag: false
    }
  },
  props: {
    accountInfo: Object,
    loadingSave: Boolean,
    numCreatingtrainedAIs: Number,
    numOwntrainedAIs: Number,
    recipeType: String,
    sortedTrainedAis: Array,
    trainedAIFormValidate: Object,
    disableClick: Boolean,
    checkOptimization: Boolean
  },
  computed: {
    modelType() {
      return getModelType(this.recipeType)
    },
    metricsDefs() {
      let metricsInfo = metricsDefsByModelType[this.modelType](
        this.$t.bind(this)
      )

      if (this.modelType === 'images') {
        const trainedAiInfo = this.sortedTrainedAis[0]

        // 特定の精度指標が含まれるかどうかで、分類が 2 種類 or それ以上かをチェックする
        const isMultiClass = Object.keys(
          trainedAiInfo.summary.metrics[0]
        ).includes('test_overall_accuracy')

        // 分類の種類数に応じて精度評価を変更する。
        metricsInfo = isMultiClass
          ? metricsDefsByModelType.imagesMultiClass(this.$t.bind(this))
          : metricsDefsByModelType.images(this.$t.bind(this))
      }

      // popup に表示したくない精度指標があるので除去
      const metricsDefsKeyList = Object.keys(metricsInfo)
      const removeAccuracyList = ['train_accuracy', 'train_loss']

      metricsInfo = metricsDefsKeyList.reduce((acc, key) => {
        if (!removeAccuracyList.includes(key)) acc[key] = metricsInfo[key]

        return acc
      }, {})

      return metricsInfo
    }
  },
  methods: {
    // 保存する学習済みAIに対象のAIを追加
    saveThisTrainedAi(id) {
      this.$emit('save-this-trained-ai')

      const checkId = this.saveTrainedAis.find(
        (x) => x.id === this.sortedTrainedAis[id].id
      )

      // すでに存在する場合ははじく
      if (!checkId) {
        this.saveTrainedAis.push({
          name: this.sortedTrainedAis[id].name,
          description: this.sortedTrainedAis[id].description,
          id: this.sortedTrainedAis[id].id,
          index: parseInt(this.sortedTrainedAis[id].id.split('-')[1])
        })
      } else {
        const index = this.saveTrainedAis.findIndex(
          (x) => x.id === this.sortedTrainedAis[id].id
        )
        this.$set(this.saveTrainedAis, index, {
          name: this.sortedTrainedAis[id].name,
          description: this.sortedTrainedAis[id].description,
          id: this.sortedTrainedAis[id].id,
          index: parseInt(this.sortedTrainedAis[id].id.split('-')[1])
        })
      }

      // 1つしかない場合はそのまま保存の処理を実行
      if (this.sortedTrainedAis.length === 1) {
        this.saveConfirm()
      } else if (this.sortedTrainedAis.length > this.showTrainedAi + 1) {
        // 再選択しに戻ってきた場合は、そのまま確認のポップアップに移動
        if (this.reSelectFlag) {
          this.showConfirm = true
          this.reSelectFlag = false
        } else {
          // 次のAIの保存確認へ移動
          this.showTrainedAi++
        }
      } else {
        // 最後のAIだったら確認画面へ
        this.showConfirm = true
      }
    },
    // 対象のAIを保存せずに次に進む、または、保存対象から取り除く
    passThisTrainedAi(id) {
      const checkId = this.saveTrainedAis.findIndex(
        (x) => x.id === this.sortedTrainedAis[id].id
      )
      if (checkId > -1) {
        // 保存対象として存在している場合は、それを取り除く
        this.saveTrainedAis.splice(checkId, 1)
      }
      if (this.sortedTrainedAis.length > this.showTrainedAi + 1) {
        if (this.reSelectFlag) {
          this.showConfirm = true
          this.reSelectFlag = false
        } else {
          this.showTrainedAi++
        }
      } else {
        this.showConfirm = true
      }
    },
    // 対象のAIのみを保存して、残りのAIを破棄する
    passAllTrainedAi(id) {
      const checkId = this.saveTrainedAis.find(
        (x) => x.id === this.sortedTrainedAis[id].id
      )
      if (!checkId) {
        this.saveTrainedAis.push({
          name: this.sortedTrainedAis[id].name,
          description: this.sortedTrainedAis[id].description,
          id: this.sortedTrainedAis[id].id,
          index: parseInt(this.sortedTrainedAis[id].id.split('-')[1])
        })
      }
      this.showConfirm = true
    },
    // 保存の確定、保存するフラグを持ったAIの名前、説明、IDをemitしている。viewsでそれ以外に必要な情報をまとめて、保存してください
    saveConfirm() {
      this.$emit('save-confirm', this.saveTrainedAis)
      return false
    },
    reSelect(id) {
      this.showConfirm = false
      this.showTrainedAi = id
      this.reSelectFlag = true
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-save-page-enter-active,
.toggle-save-page-leave-active {
  transition: opacity $transition-base, transform $transition-base;
}
.toggle-save-page-enter,
.toggle-save-page-leave-to {
  opacity: 0;
  transform: translateX(-$space-medium);
  will-change: opacity, transform;
}
</style>
