<template>
  <div>
    <texts v-if="!checkLength(text)" :text="text" size="small" />
    <tooltip-popover
      v-else
      class="pop"
      :trigger="!isDisabled ? 'click' : 'manual'"
      autoHide
      adjustScroll
      :placement="placeEnd ? 'left-end' : 'left-start'"
      :offset="8"
      popoverInnerClass="popover-no-border"
      @auto-hide="hidePopupText(popupKey)"
      @apply-show="clickPopupText(popupKey)"
    >
      <template slot="main">
        <texts
          class="pop-text"
          :class="{ 'pop-text-disabled': isDisabled }"
          :text="text"
          size="small"
          noTips
          :color="showTipsText === popupKey ? 'emphasis' : 'default'"
        />
      </template>
      <template slot="popup">
        <div class="tips">
          <div class="tips-inner">
            <texts
              class="tips-text"
              :text="text"
              size="small"
              noTips
            />
          </div>
        </div>
      </template>
    </tooltip-popover>
  </div>
</template>

<script>
import tooltipPopover from '@/components/molecules/tooltip-popover.vue'

export default {
  components: {
    tooltipPopover
  },
  data() {
    return {
      showTipsText: null
    }
  },
  props: {
    /** どのセルのポップアップを表示するかの識別 */
    popupKey: String,
    /** 画面下部に表示されたくないときにtrue */
    placeEnd: Boolean,
    /** セルに表示するテキスト */
    text: [String, Number],
    /** ポップアップを表示しない場合にtrue */
    isDisabled: Boolean
  },
  methods: {
    checkLength(item) {
      if (!isNaN(item)) return false
      const reg = /\r\n|\n|\r/g
      const regTest = [...item.matchAll(reg)]
      return regTest.length >= 3 || item.length > 70 // 70文字を超えたら長いとみなす 3行までは通常のツールチップ
    },
    clickPopupText(key) {
      this.showTipsText = key
    },
    hidePopupText(key) {
      if (this.showTipsText !== key) return
      this.showTipsText = null
    }
  }
}
</script>

<style lang="scss" scoped>
.pop {
  display: flex;
  &::v-deep .trigger {
    overflow: hidden;
    width: 100%;
  }
  &-text {
    text-align: center;
    cursor: pointer;
    @include buttonHover;
    &-disabled {
      transition: inherit;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.tips {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: adjustVW(480);
  max-height: adjustVW(320);
  padding: $space-sub;
  border: $border-main;
  &-inner {
    overflow-y: scroll;
    padding-right: $space-base;
    @include scrollbar;
  }
  &-text {
    word-break: break-all;
    white-space: pre-line;
  }
}
</style>
