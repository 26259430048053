<template>
  <div>
    <icons
      v-if="block.subscriptionNeeded === true"
      iconName="marketplaceRecipeType"
      color="emphasis"
      size="small"
    />
    <icons
      v-else-if="block.type === 'processing'"
      :iconName="block.type + nameConvert + 'RecipeType'"
      color="emphasis"
      size="small"
    />
    <icons
      v-else-if="isCustomblock"
      iconName="customblock"
      size="small"
      :color="allEmphasis ? 'emphasis' : ''"
    />
    <icons
      v-else
      :iconName="block.type + 'RecipeType'"
      color="emphasis"
      size="small"
    />
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'

export default {
  components: {
    icons
  },
  props: {
    block: {
      type: Object,
      default: () => {}
    },
    isCustomblock: {
      type: Boolean,
      default: false
    },
    allEmphasis: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    nameConvert() {
      return this.block.name.charAt(0).toUpperCase() + this.block.name.slice(1)
    }
  }
}
</script>
