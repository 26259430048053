var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-base',{staticClass:"card"},[_c('texts',{staticClass:"title",attrs:{"size":"large","text":_vm.$t('account.setting.accountUsage.title')}}),_c('div',{staticClass:"user-data"},[_c('div',{staticClass:"user-data-inner"},[_c('texts',{attrs:{"text":_vm.$t('account.setting.accountUsage.dataUsageCapacity')}}),_c('div',{staticClass:"user-data-detail"},[_c('texts',{staticClass:"user-data-use",attrs:{"text":_vm.totalDataSize}}),_c('texts',{attrs:{"text":_vm.$t('account.setting.accountUsage.MaximumCapacity') +
              _vm.maxDataSize +
              ')',"color":"gray"}})],1)],1),(!_vm.accountInfoLoading)?_c('graph-bar-count',{staticClass:"user-data-graph",attrs:{"count":_vm.accountInfo.totalDataSize,"maxCount":_vm.accountInfo.planDetail.maxDataSize}}):_vm._e()],1),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"item"},[_c('texts',{staticClass:"item-title",attrs:{"text":_vm.$t('account.setting.accountUsage.projects'),"size":"small","color":"gray"}}),_c('div',{staticClass:"item-detail"},[(!_vm.accountInfoLoading)?_c('texts',{staticClass:"item-count",attrs:{"text":_vm.ownProjects,"size":"medium"}}):_vm._e()],1)],1),_c('div',{staticClass:"item"},[_c('texts',{staticClass:"item-title",attrs:{"text":_vm.$t('account.setting.accountUsage.learnedAl'),"size":"small","color":"gray"}}),_c('div',{staticClass:"item-detail"},[_c('texts',{staticClass:"item-count",attrs:{"text":_vm.ownModels,"size":"medium"}}),(!_vm.accountInfoLoading)?_c('texts',{attrs:{"text":'(' +
              _vm.$t('account.setting.accountUsage.maxDataServices') +
              _vm.accountInfo.planDetail.numModel +
              ')',"size":"small","color":"gray"}}):_vm._e()],1)],1),_c('div',{staticClass:"item"},[_c('texts',{staticClass:"item-title",attrs:{"text":_vm.$t('account.setting.accountUsage.services'),"size":"small","color":"gray"}}),_c('div',{staticClass:"item-detail"},[_c('texts',{staticClass:"item-count",attrs:{"text":_vm.ownServices,"size":"medium"}}),(!_vm.accountInfoLoading)?_c('texts',{attrs:{"text":'(' +
              _vm.$t('account.setting.accountUsage.maxDataServices') +
              _vm.accountInfo.planDetail.maxServices +
              ')',"size":"small","color":"gray"}}):_vm._e()],1)],1),_c('div',{staticClass:"item"},[_c('texts',{staticClass:"item-title",attrs:{"text":_vm.$t('account.setting.accountUsage.totalStudyTime'),"size":"small","color":"gray"}}),_c('div',{staticClass:"item-detail"},[(!_vm.accountInfoLoading)?_c('texts',{staticClass:"item-count",attrs:{"text":_vm.formatTime(this.accountInfo.usedLearningTimeSec),"size":"medium"}}):_vm._e()],1)],1),_c('div',{staticClass:"item"},[_c('texts',{staticClass:"item-title",attrs:{"text":_vm.$t('account.setting.accountUsage.totalGPUStudyTime'),"size":"small","color":"gray"}}),_c('div',{staticClass:"item-detail"},[(!_vm.accountInfoLoading)?_c('texts',{staticClass:"item-count",attrs:{"text":_vm.formatTime(this.accountInfo.usedGPULearningTimeSec),"size":"medium"}}):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }