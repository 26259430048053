<template>
  <div class="c-wrap">
    <sidebar-project-detail
      v-if="!notFound || datasetLoading"
      v-bind="sidebar"
    />
    <sidebar v-else />
    <div class="c-body-wrap">
      <header-tabs
        v-bind="headerTabs"
        @tab-click="$emit('tab-click', $event)"
      />
      <transition-page>
        <transition-toggle-contents>
          <div v-if="datasetLoading" class="loading-wrap">
            <loading />
          </div>
          <page-not-found v-else-if="notFound" />
          <dataset-detail-main
            v-else-if="datasetInfo"
            v-bind="body"
            :datasetInfo="datasetInfo"
            :disabledEdit="disabledEdit"
            :datasetList="datasetList"
            :rowsPerPage="rowsPerPage"
            :tableChangeTabs="tableChangeTabs"
            :sidebarTab="sidebarTab"
            :datasetMenu="datasetMenu"
            @loading-detail-data="$emit('loading-detail-data')"
            @file-info="$emit('file-info', $event)"
            @learning-data-image="$emit('learning-data-image', $event)"
            @learning-data-texts="$emit('learning-data-texts', $event)"
            @learning-data-table="$emit('learning-data-table', $event)"
            @learning-data-describe="$emit('learning-data-describe', $event)"
            @component-page-name="$emit('component-page-name', $event)"
            @change-tab="$emit('change-tab', $event)"
            @change-data-tab="$emit('change-data-tab', $event)"
            @data-img-click="$emit('data-img-click', $event)"
            @data-text-click="$emit('data-text-click', $event)"
            @edit-dataset-detail="$emit('edit-dataset-detail')"
            @save-dataset-detail="$emit('save-dataset-detail', $event)"
            @dataset-menu-click="$emit('dataset-menu-click', $event)"
            @select-item="$emit('select-item', $event)"
            @input-edit-form="$emit('input-edit-form', $event)"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="loadingDelete" />
    <div class="dataset-detail-list-popup">
      <dataset-detail-popup
        v-bind="popup"
        :datasetInfo="datasetInfo"
        :loadingDelete="loadingDelete"
        :disableClick="disableClick"
        @close-modal="$emit('close-modal', $event)"
        @delete-dataset="$emit('delete-dataset', $event)"
      />
    </div>
    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
  </div>
</template>

<script>
import loading from '@/components/atoms/loading'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import sidebarProjectDetail from '@/components/organisms/sidebar-project-detail.vue'
import Sidebar from '@/components/organisms/sidebar.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import datasetDetailMain from '@/components/organisms/dataset-detail/dataset-detail-main.vue'
import transitionPage from '@/components/molecules/transition-page'
import datasetDetailPopup from '@/components/organisms/dataset-detail/dataset-detail-popup.vue'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import pageNotFound from '@/components/organisms/page-not-found'

export default {
  components: {
    loading,
    loadingProcessing,
    sidebarProjectDetail,
    Sidebar,
    hoverMenu,
    headerTabs,
    datasetDetailMain,
    transitionPage,
    datasetDetailPopup,
    TransitionToggleContents,
    pageNotFound
  },
  props: {
    datasetInfo: Object,
    disabledEdit: Boolean,
    datasetLoading: Boolean,
    datasetList: Object,
    rowsPerPage: Number,
    sidebar: Object,
    datasetMenu: Object,
    tableChangeTabs: Object,
    headerTabs: Object,
    sidebarTab: Object,
    body: Object,
    popup: Object,
    loadingDelete: Boolean,
    notFound: Boolean,
    disableClick: Boolean
  }
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
}

.loading-wrap {
  width: 100%;
  height: 100%;
}

.wait-downloading {
  position: absolute;
  top: 0;
  left: adjustVW(240);
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - #{adjustVW(240)});
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100000;
}
</style>
