var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-base',{staticClass:"card-setting-navi"},[_c('texts',{staticClass:"setting-navi-title",attrs:{"color":"gray","text":!_vm.checkTypeOptimization
        ? _vm.$t('inference.settings.navi.title')
        : _vm.$t('inference.optimization.setting')}}),_c('navi-flow',{attrs:{"noPreprocess":_vm.noPreprocess,"choosedItems":_vm.choosedItems,"activeMenu":_vm.activeMenu,"availableSteps":_vm.availableSteps,"preprocessingAvailable":_vm.preprocessingAvailable,"checkTimeSeries":_vm.checkTimeSeries,"checkTypeOptimization":_vm.checkTypeOptimization,"reset":_vm.reset},on:{"click":_vm.selectNaviMenu}}),_c('div',{staticClass:"run-inference"},[_c('button-main',{staticClass:"run-inference-button",attrs:{"type":"emphasis","text":!_vm.checkTypeOptimization
          ? _vm.$t('inference.settings.navi.submit')
          : _vm.$t('inference.optimization.startOptimization'),"isDisabled":_vm.disableStartInferece || _vm.runInference || _vm.checkColumns},on:{"click":function($event){!_vm.checkTypeOptimization ? _vm.startInference($event) : _vm.startOptimization()}}}),_c('icon-button',{staticClass:"run-inference-setting",attrs:{"iconName":"trainedInfo","isDisabled":_vm.disableStartInferece || _vm.runInference || _vm.checkColumns,"disabledReason":"INFERENCE_SETTING_NOT_AVAILABLE"},on:{"click":function($event){return _vm.$emit('show-inference-enveiroment-setting')}}})],1),_c('transition-toggle-contents',[(_vm.inferenceErrors || _vm.optimizationErrorMessage)?_c('div',{staticClass:"inference-error",attrs:{"name":"inferenceError"}},[_c('texts',{staticClass:"inference-error-text",attrs:{"text":_vm.errorMessage,"size":"min","color":"caution"}})],1):_vm._e(),(_vm.inferenceWarnings)?_vm._l((_vm.inferenceWarnings),function(item){return _c('div',{key:item,staticClass:"inference-error",attrs:{"name":"inferenceError"}},[_c('texts',{staticClass:"inference-error-text",attrs:{"text":_vm.getWarningMessage(item),"size":"min","color":"caution"}})],1)}):_vm._e()],2),_c('transition-toggle-contents',[(_vm.optimizationErrorManual)?_c('div',{staticClass:"inference-error-manual"},[_c('a',{staticClass:"inference-error-manual-link",attrs:{"href":_vm.$t(
            'training.progressStatus.errorManual.' +
              _vm.optimizationErrorManual +
              '.link'
          ),"target":"_blank","rel":"noopener noreferrer"}},[_c('texts',{staticClass:"inference-error-text",attrs:{"text":_vm.$t(
              'training.progressStatus.errorManual.' +
                _vm.optimizationErrorManual +
                '.displayText'
            ),"size":"min","color":"link-caution"}})],1)]):_vm._e()]),_c('transition-toggle-contents',[(_vm.checkColumns)?_c('div',{staticClass:"inference-error",attrs:{"name":"checkColumn"}},[_c('texts',{staticClass:"inference-error-text",attrs:{"text":_vm.$t('inference.settings.errors.lackeDatasetColumns'),"size":"min","color":"caution"}})],1):_vm._e()]),_c('transition-toggle-contents',[(_vm.checkOptimizationError)?_c('div',{staticClass:"inference-error",attrs:{"name":"checkOptimizationError"}},[_c('texts',{staticClass:"inference-error-text",attrs:{"text":_vm.checkOptimizationError,"size":"min","color":"caution"}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }