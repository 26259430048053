<template>
  <div class="sentiment">
    <div class="sentiment-icon">
      <icons iconName="sentimentNegative" color="negative" size="small" />
    </div>
    <div class="sentiment-bar">
      <div
        class="sentiment-bar-inner sentiment-bar-negative"
        :style="{
          '--width': negativeValue
        }"
      >
        <div v-if="checkCloseNegative" class="sentiment-bar-text">
          <texts :text="negativeValue" color="negative" size="small" />
        </div>
      </div>
      <div class="sentiment-bar-inner sentiment-bar-positive">
        <div v-if="!checkCloseNegative" class="sentiment-bar-text">
          <texts :text="positiveValue" color="positive" size="small" />
        </div>
      </div>
    </div>
    <div class="sentiment-icon">
      <icons iconName="sentimentPositive" color="positive" size="small" />
    </div>
  </div>
</template>

<script>
import { toPercentage } from '@/lib/misc'
import icons from '@/components/atoms/icon'

export default {
  components: {
    icons
  },
  props: {
    proba: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    negativeValue() {
      return toPercentage(this.proba.negative)
    },
    positiveValue() {
      return toPercentage(this.proba.positive)
    },
    checkCloseNegative() {
      return this.proba.negative > this.proba.positive
    }
  }
}
</script>

<style lang="scss" scoped>
.sentiment {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: $space-base;
  &-bar {
    position: relative;
    width: 100%;
    height: adjustVW(26);
    &-inner {
      position: absolute;
      top: 50%;
      display: flex;
      align-items: center;
      transform: translateY(-50%);
    }
    &-negative {
      left: 0;
      width: var(--width);
      height: 100%;
      background-color: $color-negative-lite;
      border-radius: adjustVW(4) 0 0 adjustVW(4);
      z-index: 2;
      .sentiment-bar-text {
        margin-left: $space-base;
      }
    }
    &-positive {
      right: 0;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      background-color: $color-positive-lite;
      border-radius: 0 adjustVW(4) adjustVW(4) 0;
      z-index: 1;
      .sentiment-bar-text {
        margin-right: $space-base;
      }
    }
    &-text {
      padding-bottom: adjustVW(1);
    }
  }
}
</style>
