import { render, staticRenderFns } from "./account-info-reset-nickname.vue?vue&type=template&id=331808fe&scoped=true&"
import script from "./account-info-reset-nickname.vue?vue&type=script&lang=js&"
export * from "./account-info-reset-nickname.vue?vue&type=script&lang=js&"
import style0 from "./account-info-reset-nickname.vue?vue&type=style&index=0&id=331808fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "331808fe",
  null
  
)

export default component.exports