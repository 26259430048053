<template>
  <div
    class="item"
    :class="{ disabled: !available }"
    @click="available ? $emit('click') : 0"
  >
    <div class="flow-list-item">
      <text-with-icon
        :text="name"
        :iconName="'num' + (index + 1)"
        :color="active ? 'emphasis' : available ? 'gray' : 'off'"
      />
    </div>
  </div>
</template>

<script>
import textWithIcon from '@/components/molecules/text-with-icon'

export default {
  components: {
    textWithIcon
  },
  data() {
    return {
      activeFlow: 0
    }
  },
  props: {
    choosedItems: Array,
    isSelected: Array,
    active: Boolean,
    available: Boolean,
    index: Number,
    checkTypeOptimization: Boolean
  },
  computed: {
    name() {
      return this.checkTypeOptimization
        ? this.$t('inference.optimization.optimizationSetting')
        : this.$t('inference.settings.navi.selectInferenceType')
    }
  },
  watch: {
    activeMenu: function (val) {
      this.activeFlow = val
    }
  },
  methods: {
    changeFlow(e) {
      this.activeFlow = e
      this.$emit('click', this.activeFlow)
    }
  }
}
</script>

<style lang="scss" scoped>
.flow-list-item {
  margin-bottom: $space-base;
}

.choosed-item {
  margin-bottom: $space-base;
}

.item {
  margin: 0 0 $space-medium;
  cursor: pointer;
  user-select: none;

  &.disabled {
    cursor: not-allowed;
  }
}
</style>
