<template>
  <popup-array
    :popupDefs="popupDefs"
    :showPopup="showPopup"
    :noClose="disableClick"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #deleteService>
      <service-detail-popup-delete-service
        v-bind="deleteService"
        :disableClick="disableClick"
        @delete-service="$emit('delete-service', $event)"
        @close-modal="$emit('close-modal', 'deleteService')"
        @cancel="$emit('close-modal', 'deleteService')"
      />
    </template>
  </popup-array>
</template>

<script>
import serviceDetailPopupDeleteService from './service-detail-popup-delete-service'
import { popupArray } from '@/components/molecules/popup'

export default {
  components: {
    serviceDetailPopupDeleteService,
    popupArray
  },
  props: {
    showPopup: Array,
    deleteService: Object,
    disableClick: Boolean
  },
  computed: {
    popupDefs() {
      return {
        deleteService: {
          // 登録済みのデータソース一覧ポップアップ
          title: this.$t('serviceDetail.popup.deleteService.title')
        }
      }
    }
  }
}
</script>
