<template>
  <div class="vectorization-result-card-list">
    <template v-if="targetType === 'image'">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="vectorization-result-image-card-block"
      >
        <image-vectorization-card
          v-bind="imageCardData(index)"
          :posBottom="posBottomCard(index)"
          :posRight="index % 2 === 1"
          :bodyList="bodyList"
        />
      </div>
    </template>
    <template v-else>
      <div
        v-for="(item, index) in items"
        :key="index"
        class="vectorization-result-text-card-block"
      >
        <text-vectorization-card
          v-bind="textCardData(index)"
          :posBottom="posBottomCard(index)"
          :posRight="index % 2 === 1"
          :bodyList="bodyList"
        />
      </div>
    </template>
  </div>
</template>

<script>
import imageVectorizationCard from '@/components/organisms/inference/result/vectorization/image-vectorization-card.vue'
import textVectorizationCard from '@/components/organisms/inference/result/vectorization/text-vectorization-card.vue'

export default {
  components: {
    imageVectorizationCard,
    textVectorizationCard
  },
  props: {
    targetType: String,
    items: Array,
    allItems: Array,
    posBottomPopupBorder: Number
  },
  methods: {
    posBottomCard: function (index) {
      if (this.items.length < this.posBottomPopupBorder) return false
      return this.items.length - index < this.posBottomPopupBorder - 1
    },
    imageCardData(index) {
      return {
        ...this.items[index]
      }
    },
    textCardData(index) {
      return {
        ...this.items[index]
      }
    }
  },
  computed: {
    bodyList() {
      return this.allItems.map((x) => x.body)
    }
  }
}
</script>

<style lang="scss" scoped>
.vectorization-result-card-list {
  overflow-y: scroll;
  display: grid;
  grid-template-columns: repeat(2, calc(50% - #{$space-medium / 2}));
  grid-template-rows: max-content;
  grid-column-gap: $space-medium;
  grid-row-gap: $space-medium;
  width: adjustVW(1240);
  height: 100%;
  padding-right: $space-sub;
  padding-left: $space-medium;
  @include scrollbar;
}

.vectorization-result-image-card-block {
  width: 100%;
  height: fit-content;
  &:last-of-type {
    margin-bottom: $space-base; // 最後をシャドウが消えない程度の少しのマージンにしている
  }
}

.vectorization-result-text-card-block {
  width: 100%;
  height: adjustVH(314);
  &:last-of-type {
    margin-bottom: $space-base; // 最後をシャドウが消えない程度の少しのマージンにしている
  }
}
</style>
