<template>
  <div class="project-template-form">
    <input-box
      v-model="name"
      class="project-template-form-term"
      isGray
      :title="$t('projectList.projectName')"
    />
    <input-box
      v-model="description"
      isTextArea
      class="project-template-form-term"
      isGray
      :title="$t('common.description')"
    />
    <input-box
      v-model="wantToAchieve"
      class="project-template-form-term"
      isGray
      :title="$t('projectTemplate.wantToAchieve')"
    />
    <input-box
      v-model="predictedAndClassified"
      class="project-template-form-term"
      isGray
      :title="$t('projectTemplate.predictedAndClassified')"
    />
    <input-box
      v-model="industryOccupation"
      class="project-template-form-term"
      isGray
      :title="$t('projectTemplate.industryOccupation')"
    />
    <tag-editable-list
      v-model="tags"
      class="project-template-form-term"
      edit
      @add="$emit('tag-add', $event)"
      @remove="$emit('tag-remove', $event)"
    />

    <div class="project-template-form-term">
      <texts text="会員制限" />
      <checkbox-list
        v-model="restrictionCheked.checked"
        :values="restrictionCheked.values"
      />
    </div>

    <div class="project-template-form-image">
      <div class="project-template-form-image-inner">
        <texts
          class="project-template-form-image-title"
          text="現在表示している画像"
        />
        <image-lazy-load
          v-if="project.thumbnailUrl"
          class="project-template-form-image-now"
          :src="project.thumbnailUrl"
        />
        <texts
          v-else
          class="project-template-form-image-now"
          text="表示している画像はありません"
        />
      </div>
      <div class="project-template-form-image-inner">
        <texts class="project-template-form-image-title" text="変更する画像" />
        <input-dataset-box
          class="project-template-form-image-edit"
          :value="thumbnail.item"
          :accept="['image/png', 'image/jpeg']"
          @on-file-input="onFileInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import imageLazyLoad from '@/components/atoms/image-lazy-load.vue'
import CheckboxList from '@/components/molecules/checkbox-list.vue'
import InputBox from '@/components/molecules/input-box.vue'
import InputDatasetBox from '@/components/molecules/input-dataset-box.vue'
import TagEditableList from '@/components/molecules/tag-editable-list.vue'
import Texts from '@/components/atoms/text.vue'

export default {
  components: {
    imageLazyLoad,
    CheckboxList,
    InputBox,
    InputDatasetBox,
    TagEditableList,
    Texts
  },
  props: {
    project: Object
  },
  data() {
    return {
      name: '',
      description: '',
      wantToAchieve: '',
      predictedAndClassified: '',
      industryOccupation: '',
      tags: [],
      restrictionCheked: {
        checked: [],
        values: ['basic', 'education_1', 'free']
      },
      thumbnail: {
        item: null,
        name: null
      }
    }
  },
  computed: {
    formData: function () {
      return {
        name: this.name,
        description: this.description,
        wantToAchieve: this.wantToAchieve,
        predictedAndClassified: this.predictedAndClassified,
        industryOccupation: this.industryOccupation,
        tags: this.tags
      }
    },
    restrictionData: function () {
      const checkList = this.restrictionCheked.checked

      return {
        basic: checkList.includes('basic'),
        education_1: checkList.includes('education_1'),
        free: checkList.includes('free')
      }
    }
  },
  methods: {
    onFileInput(file) {
      this.thumbnail = {
        item: file,
        name: file.name.substr(0, file.name.lastIndexOf('.'))
      }
    }
  },
  watch: {
    formData: {
      deep: true,
      handler: function (form) {
        this.$emit('input-edit-form', {
          type: 'projectTemplate',
          form: {
            ...form,
            restriction: this.restrictionData,
            thumbnail: this.thumbnail
          }
        })
      }
    },
    restrictionData: {
      deep: true,
      handler: function (form) {
        this.$emit('input-edit-form', {
          type: 'projectTemplate',
          form: {
            ...this.formData,
            restriction: form,
            thumbnail: this.thumbnail
          }
        })
      }
    },
    thumbnail: {
      deep: true,
      handler: function (form) {
        this.$emit('input-edit-form', {
          type: 'projectTemplate',
          form: {
            ...this.formData,
            restriction: this.restrictionData,
            thumbnail: form
          }
        })
      }
    }
  },
  mounted: function () {
    this.name = this.project?.name ?? ''
    this.description = this.project?.description ?? ''
    this.wantToAchieve = this.project?.wantToAchieve ?? ''
    this.predictedAndClassified = this.project?.predictedAndClassified ?? ''
    this.industryOccupation = this.project?.industryOccupation ?? ''
    this.tags = this.project?.tags ?? []

    const restriction = this.project?.usageRestriction ?? {}
    const checkedValue = Object.keys(restriction).filter(
      (plan) => restriction[plan] === true
    )

    this.$set(this.restrictionCheked, 'checked', checkedValue)
  }
}
</script>

<style lang="scss" scoped>
.project-template-form {
  width: 100%;
}

.project-template-form-term {
  width: 100%;
  margin-bottom: $space-medium;
}

.project-template-form-image {
  display: flex;
  grid-column-gap: $space-medium;
  width: 100%;
  height: adjustVW(280);
  &-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-now {
    overflow: hidden;
    width: adjustVW(320);
    height: 100%;
    object-fit: contain;
  }
  &-edit {
    width: adjustVW(640);
  }
  &-title {
    flex-shrink: 0;
    margin-bottom: $space-small;
  }
}

.submit-button {
  display: flex;
  justify-content: center;
  margin: 1rem;
}
</style>
