<script>
import * as d3 from 'd3'

import Axis from './mixins/Axis'
import Tip from './mixins/Tip'
import Legend from './mixins/Legend'
import { toRounded } from '@/lib/misc.js'

export default {
  mixins: [Axis, Tip, Legend],
  props: {
    data: {},
    transitionDuration: {
      default: 500
    }
  },
  data() {
    return {}
  },
  computed: {
    xScale() {
      return d3
        .scalePoint()
        .range([0, this.graphWidth])
        .domain(Object.keys(this.valueGroupedByX))
    },
    yScale() {
      return d3
        .scaleLinear()
        .range([this.graphHeight, 0])
        .domain(this.valueRange(this.data))
    }
  },
  methods: {
    generateTip(d) {
      let percent = ''
      if (this.isStack) {
        percent = `<p>${((d.value / d.total) * 100).toFixed(2)}%</p>`
      }
      return `<p>${d.category} ${d.key} ${this.checkAlgorithm(
        d.key,
        d.category
      )}</p><p>${toRounded(d.value)}</p>${percent}`
    },
    drawGraph() {
      const vueThis = this
      this.drawAxes()

      this.body.attr('class', 'objects d3-line-plot-hover-parent')

      let groupSel = this.body.selectAll('.group')

      groupSel = groupSel.data(this.stackValue)

      groupSel
        .enter()
        .append('g')
        .attr('class', 'group d3-line-plot-hover-target')
        .style('fill', function (d) {
          return vueThis.realColor(d.name)
        })
        .style('stroke', function (d) {
          return vueThis.realColor(d.name)
        })
        .each(function (...args) {
          vueThis.updateGroup(this, ...args)
        })

      groupSel.each(function (...args) {
        vueThis.updateGroup(this, ...args)
      })

      groupSel.exit().remove()
    },
    updateGroup(el, g, i, node) {
      const vueThis = this
      g = g.filter((x) => x.value != null)
      const sel = d3.select(el).selectAll('.plot-obj').data(g)
      const x = function (d, i) {
        return vueThis.xScale(d.key)
      }
      const y = function (d, i) {
        if (d.value == null || Number.isNaN(d.value)) {
          return null
        }
        return vueThis.yScale(d.value)
      }
      const yZero = function (d, i) {
        return vueThis.yScale(0)
      }

      d3.select(el).selectAll('.plot-path').remove()

      d3.select(el)
        .append('path')
        .datum(g.sort((a, b) => d3.ascending(x(a), x(b))))
        .attr('class', 'plot-path')
        .attr('fill', 'none')
        .attr('stroke-width', 1.5)
        .attr('d', d3.line().x(x).y(y).curve(d3.curveCatmullRom))

      sel
        .enter()
        .append('circle')
        .attr('class', 'plot-obj')
        .on('mouseover', vueThis.showTip)
        .on('mouseout', vueThis.hideTip)
        .attr('cx', x)
        .attr('cy', yZero)
        .attr('r', 3)
        .transition()
        .duration(vueThis.transitionDuration)
        .attr('cy', y)

      sel
        .transition()
        .duration(vueThis.transitionDuration)
        .attr('cx', x)
        .attr('cy', y)

      sel
        .exit()
        .transition()
        .duration(vueThis.transitionDuration)
        .attr('cy', yZero)
        .attr('r', 0)
        .remove()
    },
    checkAlgorithm(key, category) {
      const target = this.data.find((x) => x.name === category)
      if (
        target?.algorithms &&
        Object.keys(target?.algorithms).length > 0 &&
        target.algorithms[key]
      ) {
        return ' : ' + this.$t('recipe.layerNames.' + target.algorithms[key])
      }
      return ''
    }
  },
  watch: {
    data() {
      const categories = this.data.map((d) => d.name)
      this.recalcAxis({ categories })
      this.drawGraph()
    },
    isStack() {
      this.recalcAxis()
      this.drawGraph()
    },
    locale() {
      this.recalcAxis()
      this.drawGraph()
    }
  },
  beforeDestroy() {
    d3.select('.plot-svg').attr('style', 'pointer-events: none;')
  }
}
</script>
<style scoped>
.scatter-plot {
  min-width: 100px;
  min-height: 100px;
}
</style>
<style>
.scatter-tip-image {
  max-width: 200px;
  max-height: 200px;
}
</style>
