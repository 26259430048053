<template>
  <div class="toggle-contens">
    <div
      class="title"
      :class="{
        'title-disabled': isDisabled
      }"
      @click="showToggleContents"
    >
      <slot name="button">
        <texts
          class="text"
          :text="title"
          :color="color"
          :size="size"
          :isBold="isBold"
        />
      </slot>
      <icons
        class="icon"
        :class="{ 'icon-on': isShow }"
        iconName="toggle"
        :color="color"
      />
    </div>
    <transition name="toggle" @enter="enter()" @leave="leave()">
      <div v-show="isShow && !isDisabled" ref="target" class="content">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import icons from '@/components/atoms/icon'
export default {
  components: {
    texts,
    icons
  },
  data() {
    return {
      isShow: false
    }
  },
  props: {
    /** トグルするカードの開閉ボタンのテキスト */
    title: String,
    /** トグルボタンの開閉ボタンのテキストとアイコンの色 */
    color: String,
    /** トグルボタンの開閉ボタンのテキストのサイズ */
    size: String,
    /** トグルボタンの開閉ボタンのテキストを太字にするかどうか */
    isBold: Boolean,
    /** トグルボタンの開閉ボタンの活性 or 非活性化 */
    isDisabled: {
      type: Boolean,
      default: false
    },
    /** 開いたまま開始 */
    isOpenStart: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    enter() {
      this.$refs.target.style.height = this.$refs.target.scrollHeight + 'px'
    },
    leave() {
      this.$refs.target.style.height = '0'
    },
    showToggleContents() {
      if (!this.isDisabled) {
        this.isShow = !this.isShow
      }
    }
  },
  created() {
    if (this.isOpenStart) {
      this.isShow = true
    }
  },
  mounted() {
    if (this.isOpenStart) {
      this.enter()
    }
  },
  watch: {
    isDisabled(bool) {
      if (bool) {
        this.isShow = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-contents {
  transform-origin: top;
}
.title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $background;
  cursor: pointer;
  z-index: 1;
  &-disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}
.text {
  margin: 0 $space-medium 0 0;
  user-select: none;
}
.icon {
  transition: transform ease-in-out $transition-base;
  &-on {
    transform: rotate(180deg);
  }
}
.content {
  position: relative;
  height: 0;
  margin-top: $space-base;
  z-index: 0;
  transition: opacity $transition-base ease-in-out,
    height $transition-base ease-in-out, margin-top $transition-base ease-in-out,
    transform $transition-base ease-in-out;
  will-change: opacity, height, margin-top, transform;
}
.toggle-enter-active,
.toggle-leave-active {
  overflow: visible;
  margin-top: $space-base;
  opacity: 1;
  transform: translateY(0);
}
.toggle-enter,
.toggle-leave-to {
  overflow: hidden;
  margin-top: 0;
  opacity: 0;
  transform: translateY(-$space-small);
}
</style>
