<template>
  <div class="c-wrap">
    <sidebar v-bind="sidebar" />

    <div class="c-body-wrap">
      <header-tabs
        v-bind="headerTabs"
        @tab-click="$emit('tab-click', $event)"
      />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="loading" />
          <project-template-edit-main
            v-else
            v-bind="body"
            @cancel-edit-form="$emit('cancel-edit-form')"
            @on-file-input="$emit('on-file-input', $event)"
            @submit-edit-form="$emit('submit-edit-form', $event)"
            @tag-add="$emit('tag-add', $event)"
            @tag-remove="$emit('tag-remove', $event)"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>

    <hover-menu />
  </div>
</template>

<script>
import HeaderTabs from '@/components/organisms/header-tabs.vue'
import HoverMenu from '@/components/organisms/hover-menu.vue'
import Loading from '@/components/atoms/loading.vue'
import ProjectTemplateEditMain from '@/components/organisms/project-template-edit/project-template-edit-main'
import Sidebar from '@/components/organisms/sidebar.vue'
import TransitionPage from '@/components/molecules/transition-page'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    HeaderTabs,
    HoverMenu,
    Loading,
    ProjectTemplateEditMain,
    Sidebar,
    TransitionPage,
    TransitionToggleContents
  },
  props: {
    body: Object,
    headerTabs: Object,
    loading: Boolean,
    popup: Object,
    project: Object,
    sidebar: Object
  }
}
</script>

<style lang="scss" scoped>
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
  background: $background;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
  background: $background-sub;
}
</style>
