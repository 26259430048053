<template>
  <div class="graph">
    <div class="graph-title-wrap">
      <texts
        v-if="
          selectedTrainingDataType === 'images' ||
            checkChartsLength < 0 ||
            isOptimizationConfig
        "
        class="graph-title"
        :text="$t('training.graph.title')"
        :size="isOptimizationConfig ? 'default' : 'large'"
      />
      <text-with-icon
        v-else
        :text="$t('training.graph.title')"
        iconName="popup"
        class="graph-item-title"
        size="large"
        isButton
        pos="right"
        @icon-click="$emit('show-graph', 'best_accuracy')"
      />
      <div class="config">
        <button
          class="config-button"
          :disabled="!checkStatus"
          :class="{ 'config-button-disabled': !checkStatus }"
          @click="showPopup = true"
        >
          <text-with-icon
            iconName="trainedInfo"
            :text="$t('training.config.title')"
            :color="checkStatus ? 'emphasis' : 'gray'"
          />
        </button>
        <!-- 同じconfigの値を使いたいのでここにPopupをおいてv-modelで値を変更してる -->
        <popup
          :showPopup="showPopup"
          :title="$t('training.config.toggleButton')"
          @close-modal="showPopup = !showPopup"
        >
          <setting-deep-param
            v-model="config"
            :recipeType="recipeType"
            :nTestTrain="nTestTrain"
            :nIters="nIters"
            :TOO_FEW_TH="TOO_FEW_TH"
            :tooFewTestData="tooFewTestData"
            :tooFewTrainData="tooFewTrainData"
            :accountInfo="accountInfo"
          />
        </popup>
      </div>
    </div>
    <div
      v-if="checkChartsLength >= 0 && !isOptimizationConfig"
      class="graph-list"
    >
      <template v-for="(c, index) in chartsData">
        <template v-if="c.data && c.data.length > 0">
          <div :key="'chart_' + index" class="graph-item">
            <text-with-icon
              v-if="selectedTrainingDataType === 'images'"
              :text="$t('training.metrics.' + c.label)"
              iconName="popup"
              class="graph-item-title"
              size="small"
              isButton
              pos="right"
              @icon-click="$emit('show-graph', c.label)"
            />
            <div class="graph-item-inner">
              <div v-if="trainingColumnStatus" class="graph-item-column">
                <texts
                  :text="trainingColumnStatus.columnName"
                  size="min"
                  color="gray"
                />
                <texts
                  class="graph-item-column-desc"
                  :text="$t('training.result.multiResult.graph')"
                  size="min"
                  color="gray"
                />
              </div>
              <line-plot
                class="graph-item-chart"
                :data="c.data"
                :graphMargin="{ top: 40, right: 24, bottom: 20, left: 24 }"
              />
            </div>
          </div>
        </template>
      </template>
    </div>
    <div
      v-else
      class="graph-none"
      :class="{ 'graph-none-optimization': checkOptimization }"
    >
      <images class="graph-none-image" imageName="showGraph" />
      <texts
        class="graph-none-text"
        :text="$t('training.graph.none')"
        :size="checkOptimization ? 'small' : 'default'"
      />
    </div>
    <div
      v-if="
        ((bestParams && Object.keys(bestParams).length > 0) ||
          (trials && trials.length > 0)) &&
          !isOptimizationConfig
      "
      class="training-status-list"
    >
      <div
        v-if="bestParams && Object.keys(bestParams).length > 0"
        class="best-params-wrap"
      >
        <texts
          class="best-params-title"
          :text="$t('trainedAiDetails.bestParams')"
          size="small"
          color="gray"
        />
        <div class="best-params-list">
          <template v-for="(value, key) in bestParams">
            <texts
              v-if="key == 'classifier'"
              :key="key"
              class="best-params-algorithm"
              :text="$t('recipe.layerNames.' + value)"
            />
            <div v-else :key="key" class="best-params-algorithm-params">
              <text-with-title
                :title="
                  $t('recipe.param.' + bestAlgorithm + '.' + key.split('#')[0])
                "
                :text="traialsValue(bestAlgorithm, value)"
              />
            </div>
          </template>
        </div>
      </div>
      <div v-if="trials && trials.length > 0" class="trials-wrap">
        <texts
          class="trials-title"
          :text="$t('trainedAiDetails.trialHistory')"
          size="small"
        />
        <div class="trials-block">
          <div class="trials-list">
            <div
              v-for="items in trials"
              :key="
                trainingColumnStatus
                  ? items.iter + trainingColumnStatus.columnName
                  : items.iter
              "
              class="trials-item"
            >
              <div class="trials-inner">
                <text-with-title
                  :title="$t('trainedAiDetails.trialCount')"
                  :text="items.iter"
                />
              </div>
              <div class="trials-inner">
                <texts
                  :text="$t('trainedAiDetails.algorithm')"
                  size="small"
                  color="gray"
                />
                <toggle-contents
                  :title="$t('recipe.layerNames.' + items.params.classifier)"
                  size="small"
                >
                  <template v-for="(paramValue, paramKey) in items.params">
                    <div
                      v-if="paramKey !== 'classifier'"
                      :key="paramKey"
                      class="trials-inner"
                    >
                      <text-with-title
                        :title="
                          $t(
                            'recipe.param.' +
                              items.params.classifier +
                              '.' +
                              paramKey.split('#')[0]
                          )
                        "
                        :text="
                          traialsValue(items.params.classifier, paramValue)
                        "
                      />
                    </div>
                  </template>
                </toggle-contents>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import images from '@/components/atoms/images.vue'
import textWithIcon from '@/components/molecules/text-with-icon'
import textWithTitle from '@/components/molecules/text-with-title'
import toggleContents from '@/components/molecules/toggle-contents'
import linePlot from '@/components/organisms/graph/LinePlot'
import popup from '@/components/molecules/popup/popup.vue'
import settingDeepParam from '@/components/organisms/training/popup/setting-deep-param'

export default {
  components: {
    texts,
    images,
    textWithIcon,
    textWithTitle,
    toggleContents,
    linePlot,
    popup,
    settingDeepParam
  },
  data() {
    return {
      showPopup: false
    }
  },
  props: {
    value: Object,
    nTestTrain: Array,
    nIters: Number,
    TOO_FEW_TH: Number,
    recipeType: String,
    tooFewTestData: Boolean,
    tooFewTrainData: Boolean,
    charts: Object,
    chartsData: Object,
    selectedTrainingDataType: String,
    bestParams: Object,
    trials: Array,
    trainingProgressStatus: String,
    trainingColumnStatus: Object,
    checkOptimization: Boolean,
    trainingFlag: Boolean,
    accountInfo: Object
  },
  computed: {
    checkChartsLength() {
      return Object.values(this.chartsData)
        .map((x) => x.data.length > 0)
        .indexOf(true)
    },
    // たぶんグラフのオプション、使われている形跡はない。木上さんに聞いて不要なら消す
    chartOptions() {
      return {
        responsive: false,
        maintainAspectRatio: false
      }
    },
    config: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    bestAlgorithm() {
      if (this.bestParams) {
        return this.bestParams.classifier
      }
      return ''
    },
    checkStatus() {
      if (!this.trainingProgressStatus) return true
      return !['training', 'preparingEnvironment', 'stoppingTraining'].includes(
        this.trainingProgressStatus
      )
    },
    // 最適化の場合、学習中でない場合はグラフを非表示にする
    isOptimizationConfig() {
      return !this.trainingFlag && this.checkOptimization
    }
  },
  methods: {
    traialsValue(classifier, value) {
      if (this.$te('recipe.param.' + classifier + '.' + value)) {
        return this.$t('recipe.param.' + classifier + '.' + value)
      } else {
        if (!isNaN(value)) {
          return Math.ceil(value * 1000) / 1000
        }
        return value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.graph {
  display: flex;
  flex-direction: column;
  height: 100%;
  &-title {
    width: fit-content;
    &-wrap {
      display: flex;
      flex-shrink: 0;
      justify-content: space-between;
      margin: 0 0 $space-sub;
    }
  }
  &-none {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    &-image {
      width: adjustVW(320);
      height: auto;
      margin: 0 0 $space-medium;
    }
    &-text {
      text-align: center;
      white-space: pre-line;
    }
    &-optimization {
      flex-direction: row;
      height: 100%;
      .graph-none {
        &-image {
          width: adjustVW(160);
          margin-right: $space-medium;
          margin-bottom: 0;
        }
      }
    }
  }
  &-list {
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    height: 100%;
    @include scrollbar;
  }
  &-item {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    height: 100%;
    margin: 0 0 $space-sub;
    &:last-of-type {
      margin: 0;
    }
    &-title {
      flex-shrink: 0;
      width: fit-content;
      margin: 0 0 $space-base;
    }
    &-chart {
      flex-shrink: 1;
      height: 100%;
    }
    &-inner {
      position: relative;
      width: 100%;
      height: 100%;
    }
    &-column {
      position: absolute;
      top: $space-text;
      right: 0;
      overflow: hidden;
      display: flex;
      align-items: center;
      grid-column-gap: adjustVW(2);
      max-width: adjustVW(280);
      &-desc {
        flex-shrink: 0;
      }
    }
  }
}

.training-status-list {
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
  max-height: adjustVH(400);
  padding-top: $space-small;
  margin-top: $space-small;
  border-top: $border-main;
}

.best-params {
  &-wrap {
    overflow-y: auto;
    width: 40%;
    padding-right: $space-base;
    @include scrollbar;
  }
  &-title {
    margin-bottom: $space-text;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
  }
  &-algorithm {
    width: 100%;
    margin-bottom: $space-small;
    &-params {
      width: 100%;
      margin-bottom: $space-small;
      &:nth-last-of-type(-n + 1) {
        margin-bottom: 0;
      }
    }
  }
}

.trials {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 60%;
    padding-left: $space-small;
    border-left: $border-main;
  }
  &-title {
    flex-shrink: 0;
    margin-bottom: $space-base;
  }
  &-block {
    overflow-y: scroll;
    padding-right: $space-base;
    @include scrollbar;
  }
  &-list {
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-small;
  }
  &-item {
    padding-bottom: $space-small;
    border-bottom: $border-main;
    &:last-of-type {
      padding-bottom: 0;
      border-bottom: inherit;
    }
  }
  &-inner {
    overflow: hidden;
    margin-bottom: $space-base;
    white-space: pre-line;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.config {
  &-button {
    position: relative;
    opacity: 1;
    transition: opacity $transition-base;
    &:hover {
      opacity: 0.5;
    }
    &-disabled {
      cursor: not-allowed;
      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
