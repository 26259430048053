var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"setting-wrap"},[_c('div',{staticClass:"setting-title"},[_c('texts',{attrs:{"text":_vm.$t('trainedAi.timeTransformerV2.settingResult.title'),"size":"large"}}),_c('transition-toggle-contents',[(_vm.filteredKey)?_c('button',{on:{"click":_vm.closeFilter}},[_c('text-with-icon',{staticClass:"setting-filter",attrs:{"text":_vm.$t(
              'trainedAi.timeTransformerV2.settingResult.types.' + _vm.filteredKey
            ),"iconName":"close","size":"small"}})],1):_vm._e()])],1),_c('div',{staticClass:"setting-body"},[_c('div',{staticClass:"head"},_vm._l((_vm.field),function(key,index){return _c('div',{key:index,staticClass:"head-item",class:("head-item-" + key)},[_c('texts',{attrs:{"text":key.label,"isBold":"","size":"small"}})],1)}),0),_c('div',{staticClass:"item"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"item-inner"},[_c('div',{staticClass:"item-main"},[_c('div',{staticClass:"item-key"},[_c('texts',{staticClass:"item-key-text",attrs:{"size":"small","isBold":"","text":_vm.$t(
                  'trainedAi.timeTransformerV2.settingResult.types.' +
                    item.key
                )}})],1),_c('div',{staticClass:"item-conditions"},[_c('div',{staticClass:"item-conditions-list"},[_c('conditions-list',{attrs:{"conditions":item.conditions}})],1),(!_vm.noFilter)?_c('div',{staticClass:"item-filter",class:{
                'item-filter-active': _vm.filteredKey === item.key
              }},[_c('icons',{attrs:{"iconName":"search","size":"small","isButton":"","color":_vm.checkColor(item),"isDisabled":item.columns.length === 0 && _vm.filteredKey !== item.key},on:{"icon-click":function($event){return _vm.clickFilter(item.columns, item.key)}}})],1):_vm._e()])]),_c('div',[_c('toggle-contents',{attrs:{"title":_vm.$t('trainedAi.timeTransformerV2.settingResult.columns'),"size":"small","color":item.columns.length === 0 ? 'gray' : 'emphasis',"isDisabled":item.columns.length === 0}},[[_c('div',{staticClass:"columns-list"},_vm._l((item.columns),function(column,index){return _c('div',{key:index,staticClass:"columns-item"},[_c('dot-text',{attrs:{"text":column,"color":"emphasis","size":"min"}})],1)}),0)]],2)],1)])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }