<template>
  <page-top
    :title="title"
    iconName="backward"
    size="min"
    notScroll
    isButton
    @click="$emit('page-back')"
  />
</template>

<script>
import pageTop from '@/components/atoms/page-top'

export default {
  components: {
    pageTop
  },
  props: {
    title: String
  }
}
</script>
