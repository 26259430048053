<template>
  <div class="c-wrap">
    <sidebar v-bind="sidebar" />
    <div class="c-body-wrap">
      <header-tabs v-bind="headerTabs" />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="accountInfoLoading" />
          <organization
            v-else-if="accountInfo.plan === 'basic'"
            :accountInfo="accountInfo"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
    <template v-if="accountInfo.plan !== 'basic'">
      <popup
        :title="$t('organization.unable.title')"
        :showPopup="accountInfo.plan !== 'wait' && !accountInfoLoading"
        @close-modal="backProjectlist"
      >
        <freePlan />
      </popup>
    </template>
  </div>
</template>

<script>
import loading from '@/components/atoms/loading'
import organization from '@/components/organisms/organization/organization.vue'
import sidebar from '@/components/organisms/sidebar.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import { popup } from '@/components/molecules/popup'
import freePlan from '@/components/organisms/organization/popup/organization-free-plan.vue'
import transitionPage from '@/components/molecules/transition-page'
import TransitionToggleContents from '../molecules/transition-toggle-contents.vue'

export default {
  components: {
    loading,
    sidebar,
    headerTabs,
    organization,
    freePlan,
    popup,
    hoverMenu,
    transitionPage,
    TransitionToggleContents
  },
  props: {
    sidebar: Object,
    headerTabs: Object,
    accountInfo: Object,
    accountInfoLoading: Boolean
  },
  methods: {
    backProjectlist() {
      this.$router.push({ name: 'projectList' })
    }
  }
}
</script>

<style lang="scss" scoped>
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
  background: $background;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
  background: $background-sub;
}
</style>
