<template>
  <div class="limit-wrap">
    <texts class="limit-text" :text="$t('trainedAiList.upperLimit.desc')" />
    <button-main
      :text="$t('trainedAiList.upperLimit.checkPlan')"
      type="emphasis"
      @click="checkPlan"
    />
    <button-main
      :text="$t('datasetList.popup.upperLimitProject.deleteTrainedAi')"
      type="sub"
      @click="deleteTrainedAi"
    />
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import buttonMain from '@/components/atoms/button-main'

export default {
  components: {
    texts,
    buttonMain
  },
  methods: {
    checkPlan() {
      this.$router.push({ name: 'changePlan' })
    },
    deleteTrainedAi() {
      this.$router.push({ name: 'trainedAiList' })
    }
  }
}
</script>

<style lang="scss" scoped>
.limit {
  &-wrap {
    min-width: adjustVW(768 - 48);
  }
  &-text {
    margin-bottom: $space-medium;
    white-space: pre-line;
  }
}
</style>
