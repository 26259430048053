<template>
  <div class="preprocessing-list-delete-wrap">
    <div class="preprocessing-list-delete-list-wrap">
      <list-item :field="field" :items="deletePreprocessing" isToggle>
        <template v-slot:toggleContent="entry">
          <preprocessing-delete-inner
            class="preprocessing-list-delete-item"
            :entry="entry.entry"
          />
        </template>
      </list-item>
    </div>
    <div class="preprocessing-list-delete-button-wrap">
      <div class="preprocessing-list-delete-button-inner">
        <button-main
          :text="$t('common.cancel')"
          type="sub"
          :disabled="disableClick"
          @click="$emit('cancel-delete')"
        />
      </div>
      <div class="preprocessing-list-delete-button-inner">
        <button-main
          :text="$t('common.deleteButton')"
          type="caution"
          :disabled="disableClick"
          @click="$emit('delete-preprocessing')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import listItem from '@/components/molecules/list-item'
import preprocessingDeleteInner from '@/components/organisms/preprocessing-common/preprocessing-delete-inner'

export default {
  components: {
    buttonMain,
    listItem,
    preprocessingDeleteInner
  },
  props: {
    deletePreprocessing: Array,
    disableClick: Boolean
  },
  computed: {
    field() {
      return [
        {
          id: 0,
          key: 'name',
          label: this.$t('preprocessing.deletePreprocessing')
        },
        {
          id: 1,
          key: 'caution',
          label: '',
          isCaution: true
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.preprocessing-list-delete {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-list-wrap {
    overflow-y: auto;
    width: adjustVW(724);
    max-height: 100%;
    padding: 0 $space-small 0 0;
    margin: 0 0 $space-medium;
    @include scrollbar;
  }
  &-item {
    margin-top: -$space-small;
  }
  &-button {
    &-wrap {
      display: flex;
      justify-content: flex-end;
      padding: 0 $space-small 0 0;
    }
    &-inner {
      margin: 0 $space-small 0 0;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
