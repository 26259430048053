<template>
  <div class="code-block-wrap">
    <div
      v-for="(code, index) in codeCramp"
      :key="index"
      class="code-block-inner"
    >
      <div class="code-block-index" v-html="index + 1" />
      <div class="code-block-code" v-html="code" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      codes: []
    }
  },
  props: {
    /** 表示するコード。改行する場合は改行コードが必要 */
    baseCode: String
  },
  computed: {
    codeCramp() {
      return this.baseCode.split(/\n/)
    }
  }
}
</script>

<style lang="scss" scoped>
.code-block {
  &-wrap {
    overflow-y: auto;
    height: 100%;
    @include scrollbar;
  }
  &-inner {
    position: relative;
    display: flex;
    padding: 0 0 $space-base;
    font-family: Menlo, Consolas, 'DejaVu Sans Mono', monospace;
    white-space: pre-wrap;
    &:first-child {
      padding: $space-base 0 $space-base;
    }
    &:last-child {
      padding: 0 0 $space-base;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: adjustVW(26);
      width: 1px;
      height: 100%;
      background: $border-gray;
    }
  }
  &-index {
    width: adjustVW(26);
    margin: 0 $space-small 0 0;
    color: $text-sub;
    font-size: $text-base;
    user-select: none;
  }
  &-code {
    width: 100%;
    color: $text-main;
    font-size: $text-base;
  }
}
</style>
