<template>
  <transition-toggle-contents>
    <div
      v-if="!showDatasourceList"
      key="datasetList"
      class="dataset-list-block"
    >
      <table-list
        v-model="checked"
        class="dataset-list-list"
        :items="detasetResult"
        :field="datasetListFields"
        :noSearchResults="noSearchResults"
        isButton
        defaultSortedBy="updateTime"
        label-click="$emit('sort-label', {label: $event})"
        @list-click="$emit('show-dataset', $event)"
      />
    </div>
    <div v-else key="datasourceList" class="dataset-list-block">
      <table-list
        v-model="checked"
        class="dataset-list-list"
        :items="detasourceResult"
        :field="datasourceListFields"
        :noSearchResults="noSearchResults"
        isButton
        defaultSortedBy="updateTime"
        buttonIcon="popup"
        @list-click="$emit('show-datasource', { datasourceInfo: $event })"
      />
    </div>
  </transition-toggle-contents>
</template>

<script>
import tableList from '@/components/molecules/table-list'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

export default {
  components: {
    tableList,
    transitionToggleContents
  },
  data() {
    return {
      checked: []
    }
  },
  methods: {
    gotoDataset(e) {
      this.$router.push({
        name: 'datasetDetail',
        params: {
          id: e.id + '-' + e.accountId
        }
      })
    },
    gotoDatasource(e) {
      this.$emit('show-datasource', {
        datasourceInfo: this.detasourceResult[e.id]
      })
    }
  },
  computed: {
    datasetListFields() {
      return [
        {
          id: 0,
          key: 'name',
          label: this.$t('datasetList.datasetList.datasetName'), // 'データセット名/説明',
          thClass: 'name',
          listType: 'name',
          sortable: true
        },
        {
          id: 1,
          key: 'updateTime',
          label: this.$t('common.updateTime'), // '最終更新日',
          thClass: 'default',
          listType: 'time',
          sortable: true
        },
        {
          id: 2,
          key: 'size',
          label: this.$t('datasetList.datasetList.size'), // 'サイズ',
          thClass: 'default',
          listType: 'dataSize',
          sortable: true
        },
        {
          id: 3,
          key: 'accountId',
          label: this.$t('datasetList.datasetList.accountId'), // '所有者',
          thClass: 'large',
          listType: 'large',
          sortable: true
        }
        // 使用した前処理は削除
      ]
    },
    datasourceListFields() {
      return [
        {
          key: 'name',
          label: this.$t('datasetList.datasourceList.name'), // 'データソース名',
          thClass: 'default',
          listType: 'default',
          sortable: false
        },
        {
          key: ['database', 'GCPProject'],
          label: this.$t('datasetList.datasourceList.databaseOrGCPProject'), // 'データベース名またはGCPProject',
          thClass: 'default',
          listType: 'default',
          sortable: false
        },
        {
          key: 'dbtype',
          label: this.$t('datasetList.datasourceList.type'), // 'データベースの種類',
          thClass: 'default',
          listType: 'default',
          sortable: false
        },
        {
          key: ['host', 'GCPDatasetId'],
          label: this.$t('datasetList.datasourceList.hostOrGCPDatasetId'), // 接続先ホストまたはGCPDatasetId
          thClass: 'large',
          listType: 'large',
          sortable: false
        }
      ]
    }
  },
  props: {
    detasetResult: Array,
    datasetLoading: Boolean,
    uploadLoading: Boolean,
    detasourceResult: Array,
    showDatasourceList: Boolean,
    checkedClear: Boolean,
    noSearchResults: Boolean
  },
  watch: {
    checked(newVal) {
      this.$emit('input', newVal)
    },
    checkedClear(newVal) {
      if (newVal) {
        this.checked = []
      }
    },
    showDatasourceList() {
      this.checked = []
    }
  }
}
</script>

<style lang="scss" scoped>
.dataset-list {
  &-block {
    position: relative;
    height: 100%;
  }
  &-list {
    height: 100%;
  }
}
</style>
