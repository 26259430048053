<template>
  <div class="c-wrap">
    <header-tabs v-bind="headerTabs" />
    <sidebar-train
      v-if="!notFound || loadingPage"
      v-bind="sidebar"
      :incorrectOrder="incorrectOrder"
      :projectInfo="projectInfo"
      :progressTraining="progressTraining"
      @not-prevent="$emit('not-prevent')"
      @restart-prevent="$emit('restart-prevent')"
    />
    <sidebar v-else />

    <div class="c-body-wrap">
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="loadingPage" />
          <page-not-found v-else-if="notFound" />
          <select-target-column-main
            v-else
            v-bind="body"
            :checkOtherAccountDataset="checkOtherAccountDataset"
            :progressTraining="progressTraining"
            :preventCautionMessage="preventCautionMessage"
            :multiSelectMode="multiSelectMode"
            :trainingDatasetDetail="trainingDatasetDetail"
            :loadingDatasetDetail="loadingDatasetDetail"
            @get-selected-column="getSelectedColumn"
            @submit-target-column="submitTargetColumn"
            @check-notes="$emit('check-notes', $event)"
            @go-preprocess-page="$emit('go-preprocess-page')"
            @page-back="$emit('page-back')"
            @change-select-mode="$emit('change-select-mode', $event)"
            @change-added-selectbox="$emit('change-added-selectbox', $event)"
            @go-manual-select-target-column="
              $emit('go-manual-select-target-column')
            "
            @show-too-large-data="$emit('show-too-large-data')"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>

    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
    <div class="select-target-column-popup">
      <select-target-column-popup
        v-bind="popup"
        :rePreprocess="rePreprocess"
        @skip-select="$emit('skip-select')"
        @close-modal="$emit('close-modal', $event)"
        @re-preprocess="$emit('re-preprocess')"
        @not-prevent="$emit('not-prevent')"
      />
    </div>
  </div>
</template>

<script>
import HeaderTabs from '@/components/organisms/header-tabs.vue'
import HoverMenu from '@/components/organisms/hover-menu.vue'
import Loading from '@/components/atoms/loading.vue'
import SelectTargetColumnMain from '@/components/organisms/select-target-column/select-target-column-main.vue'
import SelectTargetColumnPopup from '@/components/organisms/select-target-column/select-target-column-popup'
import SidebarTrain from '@/components/organisms/sidebar-train.vue'
import Sidebar from '@/components/organisms/sidebar.vue'
import TransitionPage from '@/components/molecules/transition-page.vue'
import TransitionToggleContents from '../molecules/transition-toggle-contents.vue'
import pageNotFound from '@/components/organisms/page-not-found'

export default {
  components: {
    HeaderTabs,
    HoverMenu,
    Loading,
    SelectTargetColumnMain,
    SelectTargetColumnPopup,
    SidebarTrain,
    Sidebar,
    TransitionPage,
    TransitionToggleContents,
    pageNotFound
  },
  props: {
    sidebar: Object,
    body: Object,
    deleteTest: Object,
    headerTabs: Object,
    incorrectOrder: Boolean,
    loadingPage: {
      type: Boolean,
      default: false
    },
    popup: Object,
    projectInfo: Object,
    progressTraining: Object,
    checkOtherAccountDataset: Boolean,
    notFound: Boolean,
    rePreprocess: Boolean,
    preventCautionMessage: Boolean,
    multiSelectMode: Boolean,
    trainingDatasetDetail: Object,
    loadingDatasetDetail: Boolean
  },
  methods: {
    getSelectedColumn: function (val) {
      this.$emit('get-selected-column', val)
    },
    submitTargetColumn: function () {
      this.$emit('submit-target-column')
    }
  }
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  width: 100%;
  height: calc(100vh - #{adjustVH($headerTabHeight)});
  margin-top: adjustVH($headerTabHeight);
  background-color: $bg-train;
}

.c-body-inner {
  overflow: hidden;
  height: calc(100vh - #{adjustVH($headerTabHeight)});
}

.inference-menu {
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 0 0 $space-medium;
}

.inference-page-top {
  padding: 0 $space-medium;
}
</style>
