<template>
  <div class="project-template-create-wrap">
    <loading-processing v-if="submitting" />
    <project-template-create-top />

    <transition-toggle-contents name="toggle-contents" mode="out-in">
      <div v-if="makeProjectTemplate">
        <form class="template-form" @submit.prevent="submitCreateForm">
          <template-info @input-create-form="inputCreateForm($event)" />

          <div class="submit-button">
            <button-main
              type="sub"
              :disabled="submitting"
              :text="$t('common.cancel')"
              @click="$emit('cancel-create-form')"
            />
            <button-main
              button-type="submit"
              type="emphasis"
              :disabled="submitting"
              :text="$t('common.button.next')"
            />
          </div>
        </form>
      </div>

      <div v-else-if="openTemplateSampleDatasetForm">
        <div class="template-form">
          <div class="sample-dataset-select-box">
            <select-sample-dataset
              :sampleDatasetList="sampleDatasetList"
              @input-create-form="inputCreateForm($event)"
            />
          </div>

          <div class="submit-button">
            <button-main
              type="sub"
              :disabled="submitting"
              :text="$t('common.cancel')"
              @click="$emit('cancel-create-form')"
            />
            <button-main
              button-type="submit"
              type="emphasis"
              :disabled="submitting"
              :text="$t('common.button.next')"
              @click="submitSampleDataset"
            />
          </div>
        </div>
      </div>

      <div v-else-if="openTemplateEffectForm">
        <form class="template-form" @submit.prevent="submitEffectForm">
          <template-effect-info
            v-bind="effectForm"
            @input-create-form="inputCreateForm($event)"
          />
          <div class="submit-button">
            <button-main
              type="sub"
              :disabled="submitting"
              :text="$t('common.cancel')"
              @click="$emit('cancel-create-form')"
            />
            <button-main
              button-type="submit"
              type="emphasis"
              :disabled="submitting"
              :text="$t('common.button.next')"
            />
          </div>
        </form>
      </div>

      <div v-else>
        <form class="template-form" @submit.prevent="submitSumaryImages">
          <upload-summary-image
            v-bind="summaryImages.main"
            :index="0"
            @on-file-input="$emit('on-file-input', $event)"
          />
          <upload-summary-image
            v-bind="summaryImages.sub1"
            :index="1"
            @on-file-input="$emit('on-file-input', $event)"
          />
          <upload-summary-image
            v-bind="summaryImages.sub2"
            :index="2"
            @on-file-input="$emit('on-file-input', $event)"
          />

          <div class="submit-button">
            <button-main
              type="sub"
              :disabled="submitting"
              :text="$t('common.cancel')"
              @click="$emit('cancel-create-form')"
            />
            <button-main
              button-type="submit"
              type="emphasis"
              :disabled="submitting"
              :text="$t('common.button.create')"
            />
          </div>
        </form>
      </div>
    </transition-toggle-contents>
  </div>
</template>

<script>
import ButtonMain from '@/components/atoms/button-main.vue'
import LoadingProcessing from '@/components/atoms/loading-processing.vue'
import ProjectTemplateCreateTop from '@/components/organisms/project-template-create/project-template-create-top.vue'
import TemplateEffectInfo from '@/components/organisms/project-template-create/create-form/template-effect-info.vue'
import TemplateInfo from '@/components/organisms/project-template-create/create-form/template-info.vue'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import UploadSummaryImage from '@/components/organisms/project-template-create/create-form/upload-summary-image.vue'
import SelectSampleDataset from '@/components/organisms/project-template-create/create-form/select-sample-dataset.vue'

export default {
  components: {
    ButtonMain,
    LoadingProcessing,
    ProjectTemplateCreateTop,
    SelectSampleDataset,
    TemplateEffectInfo,
    TemplateInfo,
    TransitionToggleContents,
    UploadSummaryImage
  },
  props: {
    makeProjectTemplate: Boolean,
    openTemplateEffectForm: Boolean,
    openTemplateSampleDatasetForm: Boolean,
    project: Object,
    sampleDatasetList: Array,
    submitting: {
      type: Boolean,
      default: false
    },
    summaryImages: Object
  },
  data() {
    return {
      createForm: {
        name: '',
        description: '',
        wantToAchieve: '',
        predictedAndClassified: '',
        industryOccupation: '',
        tags: [],
        restriction: {
          basic: true,
          education_1: true,
          free: true
        }
      },
      effectForm: {
        name: '',
        description: '',
        formula: {}
      },
      sampleDatasetForm: {
        datasetId: ''
      }
    }
  },
  methods: {
    inputCreateForm: function (obj) {
      const { form, type } = obj
      const keys = Object.keys(form)

      if (type === 'projectTemplate') {
        keys.forEach((key) => {
          this.createForm[key] = form[key]
        })
      } else if (type === 'projectTemplateSampleDataset') {
        keys.forEach((key) => {
          this.sampleDatasetForm[key] = form[key]
        })
      } else if (type === 'projectTemplateEffect') {
        keys.forEach((key) => {
          this.effectForm[key] = form[key]
        })
      }
    },
    submitCreateForm: function () {
      this.$emit('create-template', {
        form: {
          projectId: this.project.id,
          ...this.createForm
        }
      })
    },
    submitEffectForm: function () {
      this.$emit('add-template-effect', {
        ...this.effectForm
      })
    },
    submitSampleDataset: function () {
      this.$emit('add-template-sample-dataset', {
        form: {
          ...this.sampleDatasetForm
        }
      })
    },
    submitSumaryImages: function () {
      this.$emit('add-template-images')
    }
  }
}
</script>

<style lang="scss" scoped>
.template-form {
  padding-top: $space-small;
  padding-right: $space-medium;
  padding-bottom: $space-small;
  padding-left: $space-medium;
  border-color: $border-gray;
  border-style: solid;
  background-color: $white;
  border-radius: adjustVW(16);
}

.project-template-create-wrap {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: $space-base;
  height: calc(100vh - #{adjustVH($headerTabHeight)});
  padding: 0 $space-medium $space-medium;
}

.project-template-create-form {
  overflow: hidden;
  max-height: 100%;
  margin-top: $space-medium;
}

.sample-dataset-select-box {
  margin-bottom: $space-medium;
}
</style>
