<template>
  <div class="dataset-no-item">
    <images class="dataset-no-item-image" imageName="showDataset" />
    <texts :text="$t('common.showDatasetDetail')" />
  </div>
</template>

<script>
import images from '@/components/atoms/images'
import texts from '@/components/atoms/text'

export default {
  components: {
    images,
    texts
  }
}
</script>

<style lang="scss" scoped>
.dataset {
  &-no-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    &-image {
      width: adjustVW(420);
      height: auto;
      margin-bottom: $space-large;
    }
  }
}
</style>
