import { i18n } from '@/main'

function zipDict(keys, values, type) {
  let startCount = 1
  if (type.includes('deep')) {
    startCount = 0
  }
  const dic = {}
  if (!keys) return {}
  keys.forEach((x, i) => {
    dic[x + startCount] = values[i]
  })
  return dic
}

export function convertCharts({ chartData = {}, themeColor }) {
  const secondColor = '#049185'
  const defs = [
    {
      label: 'best_accuracy',
      sources: {
        trial_accuracy: { color: themeColor },
        best_accuracy: { color: secondColor }
      },
      type: ['classification', 'regression']
    },
    {
      label: 'loss',
      sources: {
        train_loss: { color: themeColor },
        test_loss: { color: secondColor }
      },
      type: ['deep']
    },
    {
      label: 'accuracy',
      sources: {
        train_accuracy: { color: themeColor },
        test_accuracy: { color: secondColor }
      },
      type: ['deep']
    }
  ]
  const charts = {}
  for (const def of defs) {
    const result = []
    for (const sourceKey in def.sources) {
      const typeTag = sourceKey.split('_')
      const source = def.sources[sourceKey]
      let keys, data, algorithms
      if (
        chartData &&
        chartData[typeTag[0]] &&
        chartData[typeTag[0]][typeTag[1]]
      ) {
        keys = chartData[typeTag[0]].step
        data = chartData[typeTag[0]][typeTag[1]]
        if (chartData[typeTag[0]]?.algorithm) {
          algorithms = chartData[typeTag[0]].algorithm
        }
      } else {
        continue
      }
      result.push({
        name: i18n.t('training.metrics.' + sourceKey),
        color: source.color,
        values: zipDict(keys, data, def.type),
        algorithms: algorithms ? zipDict(keys, algorithms, def.type) : null
      })
    }
    charts[def.label] = {
      data: result,
      type: def.type,
      label: def.label
    }
  }

  return charts
}
/*
"{
  "test": {
    "accuracy": [
      0.529411792755127,
      0.7176470756530762
    ],
    "loss": [
      0.682388961315155,
      0.5950218439102173
    ],
    "step": [
      30,
      60
    ]
  },
  "train": {
    "accuracy": [
      0.53125,
      0.53125,
      0.515625,
      0.59375,
      0.65625,
      0.65625
    ],
    "loss": [
      0.6894128918647766,
      0.690119743347168,
      0.6840274333953857,
      0.6574088335037231,
      0.6510074138641357,
      0.6232016086578369
    ],
    "step": [
      10,
      20,
      30,
      40,
      50,
      60
    ]
  }
}"
*/
