<template>
  <div key="outside" class="popup-add-dataset-contents-wrap">
    <transition-toggle-contents>
      <set-datasource
        v-if="datasourceList.length === 0"
        key="setDatasource"
        :resetDatasource="resetDatasource"
        @test-datasource="$emit('test-datasource', $event)"
        @new-datasource="$emit('new-datasource', $event)"
      />
      <!-- ↓↓ インポートするテーブル選択 ↓↓ -->
      <div v-else key="importDataset" class="popup-show-datasource-wrap">
        <transition-toggle-contents>
          <div
            v-if="!isAddTable && !isEditSql"
            key="datasource-list"
            class="popup-show-datasource-list"
          >
            <list-item
              :field="datasourceField"
              :items="Object.values(datasourceList)"
              isToggle
              @list-click="$emit('import-dataset-loading', $event.id)"
            >
              <template v-slot:toggleContent="{ entry }">
                <div>
                  <datasource-contents
                    :datasourceInfo="entry"
                    :dataSourceItems="selectedDataSourceItems(entry.id)"
                    :dataSourceSqlItems="selectedDataSourceSqlItems(entry.id)"
                    :loadingDatasourceItems="loadingDatasourceItems"
                    :connectionError="selectedDataSourceError(entry.id)"
                    @set-datasource="$emit('set-datasource', $event)"
                    @show-table="showTable($event)"
                    @get-sql-list="$emit('get-and-set-sql-list', entry.id)"
                    @show-sql-edit="showSqlEdit($event, entry.id)"
                  />
                </div>
              </template>
            </list-item>
          </div>

          <div v-else key="add-table" class="popup-show-add-table-wrap">
            <datasource-import-setting
              :datasourceId="importFromDataSourceId"
              :datasetFormValidate="datasetFormValidate"
              :importLoading="importLoading"
              :disableClick="disableClick"
              :datasourceItemsInfo="datasourceItemsInfo"
              :loadingdatasourceItemsInfo="loadingdatasourceItemsInfo"
              :maxRows="maxRows"
              :isAddTable="isAddTable"
              :isEditSql="isEditSql"
              :showSqlValue="showSqlValue"
              :dataSourceSqlItems="
                selectedDataSourceSqlItems(importFromDataSourceId)
              "
              :importFromDataSourceTableName="importFromDataSourceTableName"
              @close-table="closeTable"
              @do-import="$emit('do-import', $event)"
              @input-create-form="$emit('input-create-form', $event)"
            />
            <transition-toggle-contents>
              <div
                v-if="datasourceErrorText"
                class="popup-show-add-table-error"
              >
                <text-box
                  isError
                  :text="datasourceErrorText"
                  color="caution"
                  size="min"
                />
              </div>
            </transition-toggle-contents>
          </div>
        </transition-toggle-contents>
        <div
          v-if="!loadingdatasourceItemsInfo"
          class="popup-show-datasource-table"
        >
          <transition-toggle-contents>
            <div v-if="isShowTable" class="popup-show-datasource-table-inner">
              <table-main
                v-if="datasourceItemsInfo"
                :table="datasourceItemsInfo.data"
                :tableColumns="datasourceColumns"
                :pagingCount="5"
              />
            </div>
            <datasource-table-column-list
              v-else-if="isEditSql"
              :dataSourceItems="selectedDataSourceItems(importFromDataSourceId)"
              :sqlTablePreviewData="sqlTablePreviewData"
              :datasourceId="importFromDataSourceId"
              :loadingSqlTablePreview="loadingSqlTablePreview"
              @select-sql-preview-table="
                $emit('select-sql-preview-table', $event)
              "
            />
            <select-dataset-image v-else />
          </transition-toggle-contents>
        </div>
        <div v-else>
          <loading />
        </div>
      </div>
      <!-- ↑↑ インポートするテーブル選択 ↑↑ -->
    </transition-toggle-contents>
  </div>
</template>

<script>
import loading from '@/components/atoms/loading'
import textBox from '@/components/atoms/text-box.vue'
// import buttonMain from '@/components/atoms/button-main' データソースがない場合は表示（エラーが出てしまうので、コメントアウトしています）
import tableMain from '@/components/molecules/table-main'
import listItem from '@/components/molecules/list-item'
import selectDatasetImage from '@/components/molecules/select-dataset-image'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import SetDatasource from '../set-datasource.vue'
import datasourceContents from '../datasource-contents.vue'
import datasourceImportSetting from '../datasource-import-setting.vue'
import datasourceTableColumnList from '../datasource-table-column-list'

export default {
  components: {
    loading,
    textBox,
    tableMain,
    listItem,
    selectDatasetImage,
    transitionToggleContents,
    SetDatasource,
    datasourceContents,
    datasourceImportSetting,
    datasourceTableColumnList
  },
  data() {
    return {
      // 外部からインポートする際の値が入ります
      datasourceInputValue: {
        name: '',
        type: '',
        host: '',
        port: '',
        dbName: '',
        pass: '',
        userName: ''
      },

      limitsColumn: false,
      loading: false,
      isDatasourceDisabled: true,
      isDisabled: true,

      minRows: 0,
      showDatasourceId: null,
      showDatasetId: null,
      validate: {
        accept: null,
        error: null
      },
      isAddTable: false,
      isEditSql: false,
      isShowTable: false,
      showSqlValue: null
    }
  },
  computed: {
    datasourceField() {
      return [
        {
          key: 'name',
          label: this.$t(
            'datasetList.popup.addDataset.datasource.datasourceName'
          ),
          thClass: 'name',
          sortable: false
        }
      ]
    },
    datasourceItemField() {
      return [
        {
          key: 'name',
          label: this.$t('datasetList.popup.addDataset.datasource.tableName'),
          thClass: 'name',
          sortable: false
        },
        {
          key: 'n_rows',
          label: this.$t('datasetList.popup.addDataset.datasource.nData'),
          thClass: 'default',
          sortable: false
        }
      ]
    },
    datasourceColumns() {
      if (this.datasourceItemsInfo?.columns == null) return null
      return Object.keys(this.datasourceItemsInfo.columns)
    },
    datasourceErrorText() {
      if (this.importError == null) return null
      return this.$t(
        `datasetList.datasourceList.importError.${this.importError}`
      )
    }
  },
  props: {
    importLoading: Boolean,
    importError: String,
    loadingDatasourceItems: Boolean,

    loadingdatasourceItemsInfo: Boolean,
    datasourceItemsInfo: Object,

    datasourceList: Array,
    maxRows: Number,
    resetDatasource: Object,
    datasetFormValidate: Object,
    disableClick: Boolean,
    datasouceSqlItems: Array,
    dataSourceListItemsData: Array,
    dataSourceListItemsSqlData: Array,
    importFromDataSourceId: Number,
    importFromDataSourceTableName: String,
    sqlTablePreviewData: Object,
    loadingSqlTablePreview: Boolean
  },
  methods: {
    showTable(payload) {
      this.isAddTable = true
      this.isShowTable = true
      this.$emit('show-table', payload)
    },
    closeTable() {
      this.isAddTable = false
      this.isShowTable = false
      this.isEditSql = false
      this.showSqlValue = null
    },
    selectedDataSourceItems(id) {
      if (
        this.dataSourceListItemsData == null ||
        this.dataSourceListItemsData.length === 0
      )
        return null
      const check = this.dataSourceListItemsData.find((item) => {
        return item.id === id
      })
      if (!check) return null
      return check.items
    },
    selectedDataSourceSqlItems(id) {
      if (
        this.dataSourceListItemsSqlData == null ||
        this.dataSourceListItemsSqlData.length === 0
      )
        return null
      const check = this.dataSourceListItemsSqlData.find((item) => {
        return item.id === id
      })
      if (!check) return null
      return check.items
    },
    selectedDataSourceError(id) {
      if (
        this.dataSourceListItemsData == null ||
        this.dataSourceListItemsData.length === 0
      )
        return null
      const check = this.dataSourceListItemsData.find((item) => {
        return item.id === id
      })
      if (!check) return null
      return check.error
    },
    showSqlEdit(id, datasourceId) {
      this.showSqlValue = id ?? null
      this.isEditSql = true
      this.$emit('show-sql-edit', datasourceId)
    }
  },
  watch: {
    datasetFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-add-dataset {
  display: grid;
  grid-template-columns: adjustVW(592) adjustVW(960);
  grid-template-rows: adjustVH(40) calc(100% - #{adjustVW(40)});
  grid-gap: adjustVW(24);
  height: calc(100% - #{adjustVW(24)});
  &-top {
    overflow: hidden;
    display: grid;
    grid-template-columns: 30.8333333333vw 50vw;
    grid-column-gap: adjustVW(24);
    height: auto;
  }
  &-wrap {
    overflow: hidden;
    display: grid;
    grid-template-columns: adjustVW(592) adjustVW(960);
    grid-template-rows: 100%;
    grid-column-gap: adjustVW(24);
    height: 100%;
  }
  &-tab-block {
    width: adjustVW(592);
  }
  &-contents-wrap {
    display: grid;
    grid-template-columns: adjustVW(592) adjustVW(960);
    grid-template-rows: 100%;
    grid-gap: adjustVW(24);
  }
  &-main {
    display: flex;
    flex-direction: column;
    &-block {
      margin: 0 0 $space-medium;
      &:last-child {
        margin: 0;
      }
      &-file {
        height: 100%;
        max-height: adjustVH(320);
      }
    }
  }
  &-table {
    height: 100%;
    padding: $space-small;
    background: $background-sub;
    border-radius: adjustVW(16);
    &-inner {
      overflow: hidden;
      height: 100%;
      padding: $space-small $space-small $space-small $space-medium;
      background: $background;
      border-radius: adjustVW(16);
      box-shadow: $box-shadow-main;
    }
  }
  &-file {
    &-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      max-height: adjustVH(320);
      background: $background-sub;
      border-radius: adjustVW(16);
      &-on {
        background: $background-decoration;
      }
    }
    &-icon {
      margin: 0 0 $space-small;
    }
    &-text {
      &-block {
        width: 100%;
        margin: 0 0 $space-text;
        text-align: center;
      }
    }
  }
  &-datasource {
    overflow: hidden;
    height: 100%;
    &-wrap {
      width: adjustVW(592) + adjustVW(960) + adjustVW(24);
    }
    &-block {
      width: adjustVW(592);
      margin: 0 0 $space-large;
    }
    &-list {
      display: flex;
      margin: 0 0 $space-large;
    }
    &-item {
      width: adjustVW(288);
      height: 100%;
      margin: 0 $space-medium 0 0;
      &:last-child {
        margin: 0;
      }
    }
    &-title {
      margin: 0 0 $space-base;
    }
    &-type {
      &-list {
        display: flex;
      }
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: adjustVW(120);
        height: adjustVH(80);
        margin: 0 $space-small 0 0;
        background: $background-sub;
        border-radius: adjustVW(8);
        &:last-child {
          margin: 0;
        }
      }
    }
    &-button {
      &-list {
        display: flex;
      }
      &-item {
        margin: 0 $space-medium 0 0;
        &:last-child {
          margin: 0;
        }
      }
    }
    &-new {
      margin: 0 0 $space-small;
    }
  }
}

.popup-show-datasource {
  &-wrap {
    display: grid;
    grid-template-columns: adjustVW(592) adjustVW(960);
    grid-template-rows: 100%;
    grid-column-gap: adjustVW(24);
    height: 100%;
  }
  &-list {
    overflow-y: auto;
    height: 100%;
    padding: 0 $space-small 0 0;
    @include scrollbar;
    &-title {
      position: sticky;
      top: 0;
      margin: 0 0 $space-small;
      background: $background;
    }
  }
  &-table {
    padding: $space-small;
    background: $background-sub;
    border-radius: adjustVW(16);
    &-inner {
      overflow: hidden;
      height: 100%;
      padding: $space-small $space-small $space-small $space-medium;
      background: $background;
      border-radius: adjustVW(16);
      box-shadow: $box-shadow-main;
    }
  }
  &-toggle {
    &-wrap {
      margin: $space-sub 0;
    }
    &-info {
      padding: 0 0 $space-medium;
      margin: 0 0 $space-medium;
      border-bottom: $border-sub;
    }
    &-block {
      display: flex;
      width: 100%;
      margin: 0 0 $space-small;
      &:last-child {
        margin: 0;
      }
    }
    &-inner {
      margin: 0 $space-medium 0 0;
      &:last-child {
        margin: 0;
      }
    }
    &-text {
      margin: 0 0 $space-sub;
      &:last-child {
        margin: 0;
      }
    }
    &-type {
      display: flex;
      align-items: center;
      justify-content: center;
      width: adjustVW(120);
      height: adjustVH(80);
      background: $background-sub;
    }
    &-setting {
      margin: 0 0 0 auto;
    }
  }
}

.popup-show-add-table {
  &-wrap {
    display: flex;
    flex-direction: column;
  }
  &-block {
    margin: 0 0 $space-medium;
    &:last-child {
      margin: 0;
    }
    &-checkbox {
      overflow: hidden;
      flex-shrink: 1;
    }
  }
  &-limit {
    &-columns {
      display: flex;
      align-items: center;
      padding: $space-base 0;
      margin: 0 0 $space-small;
      border-top: $border-sub;
      border-bottom: $border-sub;
    }
    &-input {
      display: flex;
      align-items: center;
      &-box {
        margin: 0 $space-base 0 0;
      }
    }
  }
  &-check-wrap {
    overflow: auto;
    max-height: adjustVH(232);
    @include scrollbar;
  }
  &-button {
    width: 100%;
  }
  &-error {
    flex-shrink: 0;
    height: fit-content;
    margin-top: $space-small;
  }
}
</style>
