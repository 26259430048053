/**
 * @param { String } nextPageName - 遷移したいページこのページが学習関係のページ以外であれば通す
 **/
export function checkOuterTraining(nextPageName) {
  const allPages = [
    'selectMode',
    'projectTemplateList',
    'projectTemplateDetail',
    'datasetSetting',
    'selectTargetColumn',
    'trainPreprocessing',
    'trainRecipeList',
    'trainRecipeDetail',
    'trainRecipeEdit',
    'training'
  ]
  return !allPages.includes(nextPageName) // 学習ページ以外であればtrueを返す
}

/**
 * @param { String } pageType - project/dataset/targetColumn/preprocessing/recipe/training
 * @param { String } nextPageName - 遷移したいページこのページに行っていいか判定する
 * @param { Boolean } beforePreprocessing - 前処理を先にしているかどうか
 * @type { Object } datasetType - データセットの形式
 * @property { Boolean } unstructured - 非構造化データかどうか
 * @property { Boolean } disabledPreprocessing - 大容量CSVor他人のデータかどうか
 **/
export function checkCorrectBackPage(
  pageType,
  nextPageName,
  beforePreprocessing,
  datasetType
) {
  let correctPages = []
  switch (pageType) {
    case 'project':
      // 各種プロジェクト関係は許容
      correctPages = ['selectMode', 'projectTemplateList', 'projectTemplateDetail']
      break
    case 'dataset':
      // 自身のみ許容
      correctPages = ['datasetSetting']
      break
    case 'targetColumn':
      // 先に前処理
      if (beforePreprocessing) {
        // 前処理と、自身のみ
        correctPages = ['selectTargetColumn', 'trainPreprocessing']
        // 通常
      } else {
        // データセット設定と、自身のみ
        correctPages = ['selectTargetColumn', 'datasetSetting']
      }
      break
    case 'preprocessing':
      // 先に前処理
      if (beforePreprocessing) {
        // データセット設定と、自身のみ
        correctPages = ['trainPreprocessing', 'datasetSetting']
        // 通常
      } else {
        // 予測する列の選択と、自身のみ
        correctPages = ['trainPreprocessing', 'selectTargetColumn']
      }
      break
    case 'recipe':
      // 非構造化データ
      if (datasetType.unstructured) {
        correctPages = ['trainRecipeList', 'trainRecipeDetail', 'trainRecipeEdit', 'datasetSetting']
        // 先に前処理
      } else if (datasetType.disabledPreprocessing || beforePreprocessing) {
        // 予測する列の選択と、各種レシピ画面
        correctPages = ['trainRecipeList', 'trainRecipeDetail', 'trainRecipeEdit', 'selectTargetColumn']
        // 通常
      } else {
        // 前処理画面と、各種レシピ画面
        correctPages = ['trainRecipeList', 'trainRecipeDetail', 'trainRecipeEdit', 'trainPreprocessing']
      }
      break
    case 'training':
      // 編集画面を除く各種レシピ画面（編集画面には戻したくない）
      correctPages = ['trainRecipeList', 'trainRecipeDetail']
      break
    default:
      break
  }
  return correctPages.includes(nextPageName) // 正しい遷移先であればtrueを返す
}
