<template>
  <div
    class="list-card-contents"
    :class="{
      'list-card-contents-optimization': checkOptimization
    }"
  >
    <div v-if="!isPopup" class="list-card-contents-title">
      <texts
        class="list-card-contents-title-main"
        :text="'#' + id + '\xa0' + trainedAi.name"
        size="big"
      />
      <template v-if="id === 1 && recipeType !== 'TIME' && sortedFlag">
        <icons
          class="list-card-contents-title-icon"
          iconName="trainTop"
          color="gray"
        />
        <texts
          class="list-card-contents-title-desc"
          :text="$t('training.result.best.badge')"
          size="small"
          color="gray"
        />
      </template>
      <div
        v-else-if="recipeType === 'TIME'"
        class="list-card-contents-title-timeseries"
      >
        <texts
          v-if="trainedAi.result.seasonality_mode"
          :text="
            $t(
              'training.result.timeseries.' + trainedAi.result.seasonality_mode
            )
          "
          color="gray"
          size="small"
        />
      </div>
    </div>
    <div
      class="list-card-contents-main"
      :class="{ 'list-card-contents-main-popup': isPopup }"
    >
      <div class="list-card-contents-info">
        <div v-if="checkOptimization" class="list-card-contents-metrics-wrap">
          <optimization-list-card-contents
            :latestOptimizationConditions="
              trainedAi.summary.latestOptimizationConditions
            "
            :optimizationColumns="trainedAi.summary.optimizationColumns"
          />
        </div>
        <div
          v-else-if="
            trainedAi.summary.metrics &&
              Object.keys(trainedAi.summary.metrics).length > 0
          "
          class="list-card-contents-metrics-wrap"
        >
          <template
            v-if="
              trainedAi.summary.predictionColumns &&
                trainedAi.summary.predictionColumns.length > 1
            "
          >
            <div class="list-card-contents-metrics-multi">
              <texts
                class="list-card-contents-metrics-multi-title"
                :text="fixMetrics[0].name"
                size="large"
              />
              <div class="list-card-contents-metrics-multi-list">
                <metrics
                  v-for="metric in limitMetrics"
                  :key="metric.columnName"
                  class="list-card-contents-metrics-multi-item"
                  :name="metric.columnName"
                  :value="metric.value"
                  :percentage="metric.percentage"
                  isMinTitle
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div
              v-if="'expectedEffect' in trainedAi.summary.metrics[0]"
              class="list-card-contents-metrics-inner"
            >
              <texts
                :text="trainedAi.summary.metrics.expectedEffectName"
                :size="40"
                isBold
              />
              <texts
                :text="trainedAi.summary.metrics.expectedEffect"
                color="emphasis"
                size="large"
              />
            </div>
            <metrics
              v-for="(metric, index) in fixMetrics"
              :key="index"
              class="list-card-contents-metrics-inner"
              :name="metric.name"
              :value="metric.value"
              :percentage="metric.percentage"
              isCard
            />
            <div class="list-card-contents-metrics-other">
              <div
                v-for="(metrics, index) in otherMetrics"
                :key="index"
                class="list-card-contents-metrics-other-inner"
              >
                <texts
                  class="list-card-contents-metrics-other-name"
                  :text="metrics.name"
                  size="small"
                  color="emphasis"
                />
                <metrics-text
                  class="list-card-contents-metrics-other-count"
                  :value="metrics.value"
                  :percentage="metrics.percentage"
                  size="small"
                />
              </div>
            </div>
          </template>
        </div>
        <div
          v-if="trainedAi.summary.featureImportance && !checkOptimization"
          class="list-card-contents-feature-wrap"
          :class="{
            'list-card-contents-feature-wrap-none':
              !trainedAi.summary.featureImportance
          }"
        >
          <div class="list-card-contents-feature-inner">
            <feature-importance
              :maxItem="4"
              :predictionColumns="trainedAi.summary.predictionColumns"
              :selectedColumnIndex="selectedColumnIndex"
              :featureImportance="trainedAi.summary.FeatureImportance"
              notCard
              checkShowFeatureImportance
              :type="trainedAi.summary.type"
            />
          </div>
        </div>
      </div>
      <div v-if="!isPopup" class="list-card-contents-recipe">
        <recipe-block-list-vertical
          class="list-card-contents-recipe-inner"
          :blocks="trainedAi.result.layer_info"
        />
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import icons from '@/components/atoms/icon'
import metricsText from '@/components/atoms/metrics-text'
import metrics from '@/components/molecules/graph/metrics.vue'
import FeatureImportance from '@/components/organisms/card-fi.vue'
import optimizationListCardContents from '@/components/organisms/optimization/optimization-list-card-contents'
import RecipeBlockListVertical from '../../../../molecules/recipe-block-list-vertical.vue'

export default {
  components: {
    texts,
    icons,
    metricsText,
    metrics,
    FeatureImportance,
    optimizationListCardContents,
    RecipeBlockListVertical
  },
  data() {
    return {
      showTipsCard: '',
      showTipsItem: ''
    }
  },
  props: {
    id: Number,
    trainedAi: Object,
    provisionalName: String,
    project: Object,
    modelType: String,
    metricsDefs: Object,
    trainedAiInput: Object,
    isPopup: {
      type: Boolean,
      default: false,
      require: false
    },
    recipeType: String,
    sortedFlag: Boolean,
    selectedColumnIndex: Number,
    checkOptimization: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    otherMetrics() {
      if (!this.trainedAi.summary?.metrics) return []
      // 複数の予測する列の場合非表示
      if (Object.keys(this.trainedAi.summary.metrics).length > 1) return []
      const res = []
      for (const key in this.trainedAi.summary.metrics[0]) {
        if (Object.keys(this.metricsDefs).indexOf(key) < 0) continue
        const isTemplateMetrics =
          this.project?.template?.metrics &&
          this.project?.template?.metrics.indexOf(key) >= 0
        if (isTemplateMetrics !== key && !this.metricsDefs[key]?.showList) {
          res.push({
            name: this.metricsDefs[key].name,
            value: this.trainedAi.summary.metrics[0][key],
            percentage: this.metricsDefs[key].percentage,
            def: this.metricsDefs[key]
          })
        }
      }
      return res
    },
    limitMetrics() {
      if (!this.fixMetrics) return []
      return this.fixMetrics.slice(0, 6)
    },
    fixMetrics() {
      return this.trainedAi.summary?.metricsListSave
        ? this.trainedAi.summary.metricsListSave
        : this.trainedAi.summary.metricsList
    }
  }
}
</script>

<style lang="scss" scoped>
.list-card-contents {
  &-title {
    display: flex;
    align-items: center;
    margin: 0 0 $space-medium;
    &-main {
      margin: 0 $space-medium 0 0;
    }
    &-icon {
      margin: 0 $space-text 0 0;
    }
  }
  &-main {
    overflow: hidden;
    display: flex;
    &-popup {
      .list-card-contents-info {
        width: 100%;
        border: none;
      }
    }
  }
  &-info {
    flex-shrink: 1;
    width: calc(100% - #{adjustVW(224)});
    padding: 0 $space-small 0 0;
    margin: 0 $space-small 0 0;
    border-right: $border-sub;
  }
  &-metrics {
    &-wrap {
      overflow: hidden;
      display: flex;
      align-items: flex-start;
      margin: 0 0 $space-medium;
    }
    &-inner {
      flex-shrink: 1;
      width: auto;
      margin: 0 $space-medium 0 0;
      &::v-deep .trained-ai-accuracy-item-inner {
        justify-content: center;
      }
    }
    &-other {
      flex-shrink: 0;
      &-inner {
        display: flex;
        margin: 0 0 $space-base;
        &:last-of-type {
          margin: 0;
        }
      }
      &-name {
        flex-grow: 1;
        margin: 0 $space-small 0 0;
      }
      &-count {
        width: adjustVW(72);
      }
    }
    &-multi {
      width: 100%;
      &-title {
        margin-bottom: $space-small;
      }
      &-list {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-column-gap: $space-medium;
        grid-row-gap: $space-large;
      }
      &-item {
        overflow: hidden;
      }
    }
  }
  &-recipe {
    flex-shrink: 0;
    width: adjustVW(224);
    height: adjustVH(316);
    &-inner {
      padding: 0 $space-sub 0 0;
    }
  }
  &-feature-inner {
    &::v-deep .card-feature-importance-none {
      justify-content: flex-start;
      margin-top: $space-base;
    }
  }
  &-optimization {
    overflow: hidden;
  }
}
</style>
