<template>
  <div class="preprocessing-detail-chart-wrap">
    <div class="preprocessing-detail-chart-title">
      <texts :text="$t('preprocessing.preprocessingContent')" size="large" />
    </div>
    <div class="preprocessing-detail-chart-body">
      <preprocessing-chart-list :layers="layers" />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import preprocessingChartList from '@/components/molecules/preprocessing-chart-list'

export default {
  components: {
    texts,
    preprocessingChartList
  },
  props: {
    format_version: Number,
    version: Number,
    layers: Array,
    edges: Array
  }
}
</script>

<style lang="scss" scoped>
.preprocessing-detail-chart {
  &-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-title {
    padding: $space-small $space-small 0;
  }
  &-body {
    overflow-x: visible;
    overflow-y: scroll;
    height: 100%;
    padding: $space-medium $space-small $space-small;
    margin: 0 $space-base $space-medium 0;
    @include scrollbar;
  }
}
</style>
