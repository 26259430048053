<template>
  <popup-array
    :showPopup="showPopup"
    :popupDefs="popupDefs"
    :noClose="disableClick"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #deleteData>
      <popup-delete-data
        :target="datasetInfo"
        :loadingDelete="loadingDelete"
        :disableClick="disableClick"
        @cancel-delete="$emit('close-modal', 'deleteData')"
        @delete-dataset="$emit('delete-dataset')"
      />
    </template>

    <template #upperLimitTrainedAi>
      <popup-upper-limit-trained-ai
        @close-modal="$emit('close-modal', 'upperLimitTrainedAi')"
      />
    </template>
  </popup-array>
</template>

<script>
import PopupUpperLimitTrainedAi from './popup/upper-limit-trained-ai.vue'
import PopupDeleteData from './popup/delete-data.vue'
import PopupArray from '@/components/molecules/popup/popup-array.vue'

export default {
  components: {
    PopupArray,
    PopupDeleteData,
    PopupUpperLimitTrainedAi
  },
  props: {
    datasetInfo: Object,
    deleteData: Object,
    showPopup: Array,
    loadingDelete: Boolean,
    disableClick: Boolean
  },
  computed: {
    popupDefs() {
      return {
        showDatasource: {
          // 登録済みのデータソース一覧ポップアップ
          title: '',
          maxHeight: true
        },
        deleteData: {
          title: this.$t('datasetList.popup.deleteData.title')
        },
        upperLimitTrainedAi: {
          title: this.$t('trainedAiList.upperLimit.title')
        }
      }
    }
  }
}
</script>
