<template>
  <div class="tutorial-wrap">
    <transition-toggle-contents>
      <div v-if="showContents === 1" key="first" class="tutorial-body">
        <div class="tutorial-image">
          <img
            class="tutorial-image-inner"
            src="@/assets/images/tutorial-whats-project.gif"
          ><!-- プロジェクト新規作成のパターンを取り直し -->
        </div>
        <div class="tutorial-inner">
          <div>
            <texts
              class="tutorial-desc"
              :text="$t('projectList.tutorial.whatIsProject.first')"
              size="small"
              color="gray"
            />
            <texts
              class="tutorial-desc"
              :text="$t('projectList.tutorial.whatIsProject.second')"
              size="small"
              color="gray"
            />
            <texts
              class="tutorial-desc"
              :text="$t('projectList.tutorial.whatIsProject.third')"
              size="small"
              color="gray"
            />
            <texts
              class="tutorial-desc"
              :text="$t('projectList.tutorial.whatIsProject.fourth')"
              size="small"
              color="gray"
            />
          </div>
          <div class="tutorial-button-wrap">
            <button-main
              :text="$t('common.button.goBack')"
              type="sub"
              isDisabled
              @click="backPage"
            />
            <button-main
              :text="$t('common.button.goNext')"
              type="emphasis"
              @click="nextPage"
            />
          </div>
        </div>
      </div>
      <div v-else-if="showContents === 2" key="second" class="tutorial-body">
        <div class="tutorial-image">
          <img
            class="tutorial-image-inner"
            src="@/assets/images/tutorial-show-project.gif"
          >
        </div>
        <div class="tutorial-inner">
          <div>
            <texts
              class="tutorial-desc"
              :text="$t('projectList.tutorial.showProjectDetail.first')"
              size="small"
              color="gray"
            />
            <texts
              class="tutorial-desc tutorial-desc-large"
              :text="$t('projectList.tutorial.showProjectDetail.second')"
              size="small"
              color="gray"
            />
            <texts
              class="tutorial-desc"
              :text="
                $t('projectList.tutorial.showProjectDetail.migrationFirst')
              "
              size="small"
              color="gray"
            />
            <texts
              class="tutorial-desc"
              :text="
                $t('projectList.tutorial.showProjectDetail.migrationSecond')
              "
              size="small"
              color="gray"
            />
            <texts
              class="tutorial-desc"
              :text="
                $t('projectList.tutorial.showProjectDetail.migrationThird')
              "
              size="small"
              color="gray"
            />
          </div>
          <div class="tutorial-button-wrap">
            <button-main
              :text="$t('common.button.goBack')"
              type="sub"
              @click="backPage"
            />
            <button-main
              :text="$t('common.button.goNext')"
              type="emphasis"
              @click="nextPage"
            />
          </div>
        </div>
      </div>
      <div v-else-if="showContents === 3" key="third" class="tutorial-body">
        <div class="tutorial-image">
          <img
            class="tutorial-image-inner"
            src="@/assets/images/tutorial-create-new-project.gif"
          >
        </div>
        <div class="tutorial-inner">
          <div>
            <texts
              class="tutorial-desc"
              :text="$t('projectList.tutorial.createNewProject.first')"
              size="small"
              color="gray"
            />
            <texts
              class="tutorial-desc"
              :text="$t('projectList.tutorial.createNewProject.second')"
              size="small"
              color="gray"
            />
            <texts
              class="tutorial-desc"
              :text="$t('projectList.tutorial.createNewProject.third')"
              size="small"
              color="gray"
            />
          </div>
          <div class="tutorial-button-wrap">
            <button-main
              :text="$t('common.button.goBack')"
              type="sub"
              @click="backPage"
            />
            <button-main
              :text="$t('common.button.goNext')"
              type="emphasis"
              @click="nextPage"
            />
          </div>
        </div>
      </div>
      <div v-else key="last" class="tutorial-body">
        <div class="tutorial-image">
          <img
            class="tutorial-image-inner"
            src="@/assets/images/tutorial-move-other-project.gif"
          >
        </div>
        <div class="tutorial-inner">
          <div>
            <texts
              class="tutorial-desc"
              :text="$t('projectList.tutorial.moveOtherProject.first')"
              size="small"
              color="gray"
            />
            <texts
              class="tutorial-desc"
              :text="$t('projectList.tutorial.moveOtherProject.second')"
              size="small"
              color="gray"
            />
            <texts
              class="tutorial-desc"
              :text="$t('projectList.tutorial.moveOtherProject.third')"
              size="small"
              color="gray"
            />
          </div>
          <div class="tutorial-button-wrap">
            <button-main
              :text="$t('common.button.goBack')"
              type="sub"
              @click="backPage"
            />
            <button-main
              key="last"
              :text="$t('projectList.tutorial.closeButton')"
              type="emphasis"
              @click="$emit('close-modal')"
            />
          </div>
        </div>
      </div>
    </transition-toggle-contents>
    <page-count-dots v-model="showContents" :totalCount="maxPageCount" />
  </div>
</template>

<script>
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import buttonMain from '@/components/atoms/button-main'
import pageCountDots from '@/components/molecules/page-count-dots'

export default {
  components: {
    transitionToggleContents,
    buttonMain,
    pageCountDots
  },
  data() {
    return {
      showContents: 1,
      maxPageCount: 4
    }
  },
  methods: {
    nextPage() {
      this.showContents = this.showContents + 1
      this.$emit('in-last-page', this.maxPageCount === this.showContents)
    },
    backPage() {
      this.showContents = this.showContents - 1
      this.$emit('in-last-page', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.tutorial {
  &-body {
    display: grid;
    grid-template-columns: adjustVW(796) auto;
    grid-column-gap: $space-medium;
    margin-bottom: $space-medium;
  }
  &-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &-image {
    height: adjustVW(448);
    border: $border-main;
    background: $background-sub;
    &-inner {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-desc {
    max-width: adjustVW(480);
    margin-bottom: $space-sub;
    line-height: $line-height-base;
    white-space: pre-line;
    &:last-of-type {
      margin-bottom: $space-small;
    }
    &-large {
      padding-bottom: $space-sub;
      border-bottom: $border-main;
    }
  }
}
</style>
