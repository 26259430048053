<template>
  <div class="dataset-detail-popup-delete-wrap">
    <div>
      <div class="dataset-detail-popup-delete-title-wrap">
        <div class="dataset-detail-popup-delete-title">
          <texts :text="target.name" isBold />
        </div>
        <div class="dataset-detail-popup-delete-block">
          <description :text="target.description" />
        </div>
        <div class="dataset-detail-popup-delete-block">
          <div class="dataset-detail-popup-delete-inner">
            <text-with-title
              :title="$t('common.owner')"
              :text="target.accountId"
            />
          </div>
        </div>
        <div class="dataset-detail-popup-delete-block">
          <div class="dataset-detail-popup-delete-inner">
            <text-with-title :title="$t('common.createTime')">
              <a-time :value="target.createTime" />
            </text-with-title>
          </div>
          <div class="dataset-detail-popup-delete-inner">
            <text-with-title :title="$t('common.updateTime')">
              <a-time :value="target.updateTime" />
            </text-with-title>
          </div>
        </div>
      </div>
      <div class="dataset-detail-popup-delete-footer">
        <div class="dataset-detail-popup-delete-button-block">
          <div class="dataset-detail-popup-delete-button">
            <button-main
              type="sub"
              :text="$t('common.cancel')"
              :disabled="disableClick"
              @click="$emit('cancel-delete')"
            />
          </div>
          <div class="dataset-detail-popup-delete-button">
            <button-main
              type="caution"
              :text="$t('datasetList.popup.deleteData.submit')"
              :disabled="disableClick"
              @click="$emit('delete-dataset')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import description from '@/components/atoms/description'
import buttonMain from '@/components/atoms/button-main'
import textWithTitle from '@/components/molecules/text-with-title'

export default {
  components: {
    texts,
    description,
    buttonMain,
    textWithTitle
  },
  props: {
    target: Object,
    loadingDelete: Boolean,
    disableClick: Boolean
  }
}
</script>

<style lang="scss" scoped>
.dataset-detail-popup-delete {
  &-wrap {
    display: flex;
    flex-direction: column;
    width: adjustVW(720);
    height: 100%;
  }
  &-block {
    display: flex;
    margin: 0 0 $space-medium;
  }
  &-inner {
    overflow: hidden;
    width: 100%;
    margin: 0 $space-small 0 0;
    &:last-child {
      margin: 0;
    }
  }
  &-button {
    margin: 0 $space-small 0 0;
    &:last-child {
      margin: 0;
    }
    &-block {
      display: flex;
      justify-content: flex-end;
      margin-top: $space-medium;
    }
  }
  &-title-wrap {
    margin: 0 0 $space-medium;
  }
  &-footer {
    margin-top: $space-medium;
  }
}
</style>
