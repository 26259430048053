<template>
  <div class="c-wrap">
    <sidebar-project-detail
      v-if="!notFoundProject || loading"
      v-bind="sidebar"
    />
    <sidebar v-else />
    <div class="c-body-wrap">
      <header-tabs
        v-bind="headerTabs"
        @tab-click="$emit('tab-click', $event)"
      />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="loading" />
          <page-not-found v-else-if="notFoundProject" />
          <preprocessing-list-main
            v-else
            :accountInfo="accountInfo"
            :preprocessings="preprocessings"
            :checked="checked"
            @delete-menu-click="$emit('delete-menu-click', $event)"
            @dataset-menu-click="$emit('dataset-menu-click', $event)"
            @project-menu-click="$emit('project-menu-click', $event)"
            @set-checked="$emit('set-checked', $event)"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="disableClick" />
    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
    <div class="preprocessing-list-popup">
      <preprocessing-list-popup
        v-bind="popup"
        :deletePreprocessing="popup.items"
        :datasetList="dataset"
        :disableClick="disableClick"
        @close-modal="$emit('close-modal', $event)"
        @delete-preprocessing="$emit('delete-preprocessing', $event)"
        @apply-dataset="$emit('apply-dataset', $event)"
        @click-dataset="$emit('click-dataset', $event)"
        @add-to-project="$emit('add-to-project', $event)"
      />
    </div>
  </div>
</template>

<script>
import sidebarProjectDetail from '@/components/organisms/sidebar-project-detail.vue'
import Sidebar from '@/components/organisms/sidebar.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import preprocessingListMain from '@/components/organisms/preprocessing-list/preprocessing-list-main.vue'
import preprocessingListPopup from '@/components/organisms/preprocessing-list/preprocessing-list-popup.vue'
import transitionPage from '@/components/molecules/transition-page'
import loading from '@/components/atoms/loading.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import TransitionToggleContents from '../molecules/transition-toggle-contents.vue'
import pageNotFound from '@/components/organisms/page-not-found'

export default {
  components: {
    sidebarProjectDetail,
    Sidebar,
    hoverMenu,
    headerTabs,
    preprocessingListMain,
    preprocessingListPopup,
    transitionPage,
    loading,
    loadingProcessing,
    TransitionToggleContents,
    pageNotFound
  },
  props: {
    accountInfo: Object,
    sidebar: Object,
    headerTabs: Object,
    dataset: Array,
    popup: Object,
    checked: Array,
    loading: Boolean,
    preprocessings: Object,
    notFoundProject: Boolean,
    disableClick: Boolean
  }
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
}
</style>
