<template>
  <div class="save-trained-ai-wrap">
    <div class="save-trained-ai-body">
      <div class="save-trained-ai-input-block">
        <input-box-validation
          v-model="form.name"
          class="save-trained-ai-input"
          :accept="validate.accept"
          :error="validate.error"
          :title="$t('training.popup.save.saveTrainedAiName')"
          popupView
          isNameValidation
          :nameValidationSuggests="trainedAIFormValidate.suggests"
        />
        <input-box
          v-model="form.description"
          class="save-trained-ai-input"
          isTextArea
          isGray
          :title="$t('training.popup.save.saveTrainedAiDescripition')"
        />
      </div>
      <list-card-contents
        class="save-trained-ai-info"
        isPopup
        :trainedAi="trainedAi"
        :modelType="modelType"
        :metricsDefs="metricsDefs"
        :checkOptimization="checkOptimization"
      />
    </div>
    <div v-if="isMulti" class="save-trained-ai-button-list">
      <button-main
        type="emphasis"
        :isDisabled="disabledSubmit"
        :text="$t('training.button.saveTrainedAi')"
        @click="$emit('save-this-trained-ai')"
      />
      <button-main
        type="sub"
        :text="$t('training.button.notSaveTrainedAi')"
        @click="passThisTrainedAI"
      />
      <button-main
        v-if="showAllPass && !reSelectFlag"
        :text="$t('training.button.notSaveOtherTrainedAi')"
        type="sub"
        @click="$emit('pass-all-trained-ai')"
      />
    </div>
    <div v-else>
      <button-main
        type="emphasis"
        :text="$t('training.button.saveTrainedAi')"
        :isDisabled="disableClick || disabledSubmit"
        @click="$emit('save-this-trained-ai')"
      />
      <button-main
        :text="$t('common.cancel')"
        type="sub"
        :isDisabled="disableClick"
        @click="$emit('close-modal')"
      />
    </div>
  </div>
</template>

<script>
import ButtonMain from '@/components/atoms/button-main.vue'
import InputBox from '@/components/molecules/input-box.vue'
import InputBoxValidation from '@/components/molecules/input-box-validation.vue'
import ListCardContents from '@/components/organisms/training/result/list/list-card-contents'

export default {
  components: {
    ButtonMain,
    InputBox,
    InputBoxValidation,
    ListCardContents
  },
  props: {
    isMulti: Boolean,
    loadingSave: Boolean,
    modelType: String,
    metricsDefs: Object,
    reSelectFlag: Boolean,
    showAllPass: Boolean,
    trainedAi: Object,
    trainedAIFormValidate: Object,
    trainedAIIndex: Number,
    disableClick: Boolean,
    checkOptimization: Boolean
  },
  data() {
    return {
      form: {
        description: this.trainedAi.description,
        id: this.trainedAi.id,
        name: this.trainedAi.name,
        oldName: this.trainedAi.name,
        revision: false
      },
      validate: {
        accept: null,
        error: null
      }
    }
  },
  computed: {
    disabledSubmit: function () {
      return this.form.name === '' || this.validate.error
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function (info) {
        this.trainedAi = Object.assign(this.trainedAi, info)

        this.$emit('input-create-form', {
          type: 'trainedAI',
          form: info
        })
      }
    },
    trainedAIFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null

        if (!this.form.revision) {
          this.form.revision = true
          if (!duplicate) this.renameTrainedAI()
        }
      }
    },
    'trainedAi.revision': {
      handler: function (status) {
        this.form.revision = !status
      }
    }
  },
  methods: {
    passThisTrainedAI: function () {
      this.trainedAi = Object.assign(this.trainedAi, {
        name: this.form.oldName
      })

      this.$emit('pass-this-trained-ai')
    },
    renameTrainedAI: function () {
      this.form.name = this.trainedAi.name + ' - ' + (this.trainedAIIndex + 1)
    }
  },
  mounted: function () {
    this.$emit('input-create-form', {
      type: 'trainedAI',
      form: this.form
    })
  }
}
</script>

<style lang="scss" scoped>
.save-trained-ai {
  &-wrap {
    width: adjustVW(1116 - 48);
  }
  &-body {
    display: flex;
    align-items: stretch;
    margin: 0 0 $space-medium;
  }
  &-input {
    &-block {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      width: adjustVW(496);
      height: auto;
      padding: 0 $space-medium 0 0;
      margin: 0 $space-medium 0 0;
      border-right: $border-sub;
    }
    margin: 0 0 $space-medium;
    &:last-of-type {
      margin: 0;
    }
  }
  &-info {
    width: 100%;
  }
}
</style>
