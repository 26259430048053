<template>
  <div class="shap-scatter">
    <scatter :data="data" :xLabel="xLabel" :yLabel="yLabel" />
  </div>
</template>

<script>
import Scatter from '@/components/organisms/graph/scatter.vue'
export default {
  name: 'ShapScatter',
  components: {
    scatter: Scatter
  },
  props: {
    shapValues: Array,
    xTest: Array,
    index: Number,
    inputColumnName: String
  },
  data() {
    return {
      data: this.calcData()
    }
  },
  computed: {
    xLabel() {
      return this.inputColumnName
    },
    yLabel() {
      return this.$t('graph.shapValueFor', { name: this.inputColumnName })
    }
  },
  methods: {
    calcData() {
      const l = this.shapValues.length // samples
      const res = new Array(l)
      for (let i = 0; i < l; i++) {
        res[i] = {
          vector: [this.xTest[i][this.index], this.shapValues[i][this.index]]
        }
      }
      return Object.freeze(res)
    }
  },
  watch: {
    shapValues() {
      this.data = this.calcData()
    },
    xTest() {
      this.data = this.calcData()
    },
    index() {
      this.data = this.calcData()
    }
  }
}
</script>
<style lang="scss" scoped>
.shap-scatter {
  max-width: 668px;
  height: 432px;

  &::v-deep .scatter-plot {
    height: 100%;
  }
}
</style>
