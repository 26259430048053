<template>
  <div>
    <div class="c-sidebar">
      <div class="c-sidebar-top">
        <div class="c-sidebar-top-link">
          <router-link :to="{ name: 'projectList' }">
            <text-with-icon
              :text="$t('datasetDetail.detailSidebar.backward')"
              iconName="backward"
              size="small"
            />
          </router-link>
        </div>
        <div v-if="taragetProject" class="c-sidebar-project-outline">
          <texts :text="taragetProject.name" size="large" />
          <description :text="taragetProject.description" line="2" />
        </div>
        <div
          v-else-if="projectLoading"
          class="c-sidebar-project-outline c-sidebar-project-outline-loading"
        >
          <loading-icon />
        </div>
        <div class="c-sidebar-project-link-list">
          <div
            class="
              c-sidebar-project-link-list-body c-sidebar-project-button-list
            "
          >
            <button-main
              v-tooltip.right="{
                content: $t('sidebar.project.tips.createNewAi'),
                classes: 'tooltip-long-text',
                trigger: this.activeLink === 'inference' ? 'manual' : 'hover',
                delay: { show: 200, hide: 200 }
              }"
              class="c-sidebar-project-button-item"
              :text="$t('sidebar.project.titles.createNew')"
              type="emphasis-sub"
              iconName="train"
              @click="addNewTrainedAi"
            />
            <button-main
              v-tooltip.right="{
                content: $t('sidebar.project.tips.inference'),
                classes: 'tooltip-long-text',
                trigger: this.activeLink === 'inference' ? 'manual' : 'hover',
                delay: { show: 200, hide: 200 }
              }"
              class="c-sidebar-project-button-item"
              :text="$t('sidebar.project.titles.prediction')"
              :type="this.activeLink === 'inference' ? 'green' : 'green-sub'"
              iconName="inference"
              @click="inferenceClicked"
            />
          </div>
        </div>
        <div class="c-sidebar-project-link-list">
          <div class="c-sidebar-project-link-list-title">
            <texts
              :text="$t('datasetDetail.detailSidebar.project')"
              size="small"
              color="gray"
            />
          </div>
          <div class="c-sidebar-project-link-list-body">
            <button
              class="c-sidebar-project-link-list-inner"
              :class="{ active: this.activeLink === 'dashboard' }"
              @click="dashboardClicked"
            >
              <text-with-icon
                v-tooltip.right="{
                  content: $t('sidebar.project.tips.dashboard'),
                  classes: 'tooltip-long-text',
                  trigger: this.activeLink === 'dashboard' ? 'manual' : 'hover',
                  delay: { show: 200, hide: 200 }
                }"
                iconName="dashbord"
                :text="$t('datasetDetail.detailSidebar.dashboard')"
                :color="this.activeLink === 'dashboard' ? 'emphasis' : 'gray'"
              />
            </button>
            <button
              class="c-sidebar-project-link-list-inner"
              :class="{ active: this.activeLink === 'dataset' }"
              @click="datasetClicked"
            >
              <text-with-icon
                v-tooltip.right="{
                  content: $t('sidebar.project.tips.dataset'),
                  classes: 'tooltip-long-text',
                  trigger: this.activeLink === 'dataset' ? 'manual' : 'hover',
                  delay: { show: 200, hide: 200 }
                }"
                iconName="dataset"
                :text="$t('datasetDetail.detailSidebar.dataset')"
                :color="this.activeLink === 'dataset' ? 'emphasis' : 'gray'"
              />
            </button>
            <button
              class="c-sidebar-project-link-list-inner"
              :class="{ active: this.activeLink === 'preprocessing' }"
              @click="preprocessingClicked"
            >
              <text-with-icon
                v-tooltip.right="{
                  content: $t('sidebar.project.tips.preprocessing'),
                  classes: 'tooltip-long-text',
                  trigger:
                    this.activeLink === 'preprocessing' ? 'manual' : 'hover',
                  delay: { show: 200, hide: 200 }
                }"
                iconName="preproc"
                :text="$t('datasetDetail.detailSidebar.preprocAndEda')"
                :color="
                  this.activeLink === 'preprocessing' ? 'emphasis' : 'gray'
                "
              />
            </button>
            <button
              class="c-sidebar-project-link-list-inner"
              :class="{ active: this.activeLink === 'recipe' }"
              @click="recipeClicked"
            >
              <text-with-icon
                v-tooltip.right="{
                  content: $t('sidebar.project.tips.recipe'),
                  classes: 'tooltip-long-text',
                  trigger: this.activeLink === 'recipe' ? 'manual' : 'hover',
                  delay: { show: 200, hide: 200 }
                }"
                iconName="recipe"
                :text="$t('datasetDetail.detailSidebar.recipe')"
                :color="this.activeLink === 'recipe' ? 'emphasis' : 'gray'"
              />
            </button>
            <button
              class="c-sidebar-project-link-list-inner"
              :class="{ active: this.activeLink === 'model' }"
              @click="modelClicked"
            >
              <text-with-icon
                v-tooltip.right="{
                  content: $t('sidebar.project.tips.trainedAi'),
                  classes: 'tooltip-long-text',
                  trigger: this.activeLink === 'model' ? 'manual' : 'hover',
                  delay: { show: 200, hide: 200 }
                }"
                iconName="trainedAi"
                :text="$t('datasetDetail.detailSidebar.createdAI')"
                :color="this.activeLink === 'model' ? 'emphasis' : 'gray'"
              />
            </button>
            <button
              v-if="showService"
              class="c-sidebar-project-link-list-inner"
              :class="{ active: this.activeLink === 'service' }"
              @click="serviceClicked"
            >
              <text-with-icon
                v-tooltip.right="{
                  content: $t('sidebar.project.tips.service'),
                  classes: 'tooltip-long-text',
                  trigger: this.activeLink === 'service' ? 'manual' : 'hover',
                  delay: { show: 200, hide: 200 }
                }"
                iconName="service"
                :text="$t('common.service')"
                :color="this.activeLink === 'service' ? 'emphasis' : 'gray'"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <popup-array
      :popupDefs="popupDefs"
      :showPopup="showPopupList"
      @close-modal="closePopup($event)"
    >
      <template #upperLimitTrainedAis>
        <upper-limit-trained-ais
          @close-modal="closePopup('upperLimitTrainedAis')"
        />
      </template>
    </popup-array>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main.vue'
import description from '@/components/atoms/description.vue'
import LoadingIcon from '@/components/atoms/loading-icon.vue'
import popupArray from '@/components/molecules/popup/popup-array.vue'
import textWithIcon from '@/components/molecules/text-with-icon.vue'
import upperLimitTrainedAis from '@/components/organisms/trained-ai-list/trained-ai-list-popup-upper-limit-trained-ai'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    buttonMain,
    description,
    LoadingIcon,
    popupArray,
    textWithIcon,
    upperLimitTrainedAis
  },
  data() {
    return {
      showPopupList: []
    }
  },
  props: {
    datasetLoading: Boolean,
    project: Object,
    activeLink: String
  },
  computed: {
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('trainedAi', ['trainedAIs', 'trainedAILoading']),
    ...mapGetters('project', ['projectList', 'projectLoading']),

    projectId() {
      return this.$route.params.projectId ?? this.$route.params.id
    },
    taragetProject() {
      if (!this.projectId || this.projectLoading) return null
      return this.projectList[this.projectId]
    },
    popupDefs() {
      return {
        upperLimitTrainedAis: {
          title: this.$t('trainedAiList.upperLimit.title')
        }
      }
    },
    numOwnTrainedAIs() {
      return Object.values(this.trainedAIs).filter(
        (item) => item.accountId === this.accountInfo.accountId
      ).length
    },
    showService() {
      return (
        this.accountInfo.plan === 'basic' &&
        this.accountInfo?.organizationInfo?.auth_services
      )
    }
  },
  methods: {
    ...mapActions('models', ['deleteProgressTraining', 'setProgressTraining']),

    dashboardClicked() {
      this.$router.push({
        name: 'projectDetail',
        params: {
          projectId: this.projectId
        }
      })
    },
    modelClicked() {
      this.$router.push({
        name: 'trainedAiProjectList',
        params: {
          projectId: this.projectId
        }
      })
    },
    inferenceClicked() {
      this.$router.push({
        name: 'inferenceProject',
        params: {
          projectId: this.projectId
        }
      })
    },
    serviceClicked() {
      this.$router.push({
        name: 'serviceProjectList',
        params: {
          projectId: this.projectId
        }
      })
    },
    datasetClicked() {
      this.$router.push({
        name: 'datasetProjectList',
        params: {
          projectId: this.projectId
        }
      })
    },
    preprocessingClicked() {
      this.$router.push({
        name: 'preprocessingProjectList',
        params: {
          projectId: this.projectId
        }
      })
    },
    recipeClicked() {
      this.$router.push({
        name: 'recipeProjectList',
        params: {
          projectId: this.projectId
        }
      })
    },
    addNewTrainedAi() {
      if (this.accountInfo?.planDetail?.numModel > this.numOwnTrainedAIs) {
        this.deleteProgressTraining({ projectId: this.projectId })
        this.setProgressTraining({
          item: this.projectId,
          setType: 'project'
        })

        this.$gtmDataLayer.sendEvent('startTraining', 'fromSidebar')
        this.$router.push({
          name: 'datasetSetting',
          params: { projectId: this.projectId }
        })
      } else {
        this.showPopup('upperLimitTrainedAis')
      }
    },
    showPopup(e) {
      // ポップアップを表示
      if (this.showPopupList.length > 0) {
        this.showPopupList = []
        this.showPopupList.push(e)
      } else {
        this.showPopupList.push(e)
      }
    },
    closePopup(e) {
      // ポップアップを閉じる
      this.showPopupList = this.showPopupList.filter((n) => n !== e)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// サイドバー
.c-sidebar {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: adjustVW(240); // 12.5%
  height: 100vh;
  padding: 0 $space-small;
  background: $background;
  z-index: 1000;
  &-top {
    &-link {
      display: flex;
      align-items: center;
      height: adjustVH(80);
      > a {
        overflow: hidden;
      }
    }
  }
  &-project {
    &-outline {
      padding-bottom: $space-medium;
      margin-bottom: $space-medium;
      border-bottom: $border-main;
      &-loading {
        height: adjustVH(120);
      }
    }
    &-link-list {
      padding-bottom: adjustVW(20);
      margin-bottom: $space-medium;
      border-bottom: $border-main;
      &:last-of-type {
        padding-bottom: 0;
        margin-bottom: 0;
        border: none;
      }
      &-title {
        margin: 0 0 $space-small;
      }
      &-inner {
        position: relative;
        width: 100%;
        padding: $space-sub $space-small;
        margin: 0 0 $space-sub;
        &.active {
          background: $key-lite;
          border-radius: adjustVW(4);
          &:hover {
            opacity: 1;
          }
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    &-button {
      &-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-row-gap: adjustVW(20);
      }
      &-item {
        width: 100%;
        margin: 0;
        + .c-button {
          margin: 0 0 adjustVW(2);
        }
        &.active {
          opacity: 0.5;
        }
      }
    }
  }
}
</style>
