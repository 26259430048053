<template>
  <div class="list" @mouseleave.stop="isLeaveText()">
    <div ref="scrollTarget" class="list-inner" @scroll="onScroll($event)">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="item"
        :class="{ 'item-orderly': isOrderly }"
      >
        <bullet-item
          v-if="!isOrderly"
          :ref="'item-' + index"
          :text="item.text"
          :caution="item.caution"
          :sub="item.sub"
          :isDisabled="item.isDisabled"
          noTips
          @click.native="isHoverText(item, index)"
        />
        <div
          v-else
          :ref="'item-' + index"
          class="item-orderly-inner"
          @click="isHoverText(item, index)"
        >
          <div
            class="item-orderly-rank"
            :class="'item-orderly-rank-' + (index + 1)"
          >
            <texts
              class="item-orderly-rank-text"
              :text="index + 1"
              color="function"
              size="min"
              noTips
            />
          </div>
          <texts :text="item.text" size="small" noTips />
        </div>
        <popup-balloon-text
          class="popup"
          :style="{ '--posTop': posTop, '--scrollTop': scrollTop }"
          :class="{ 'popup-right': posRight, 'popup-bottom': posBottom }"
          :showPopup="showPopup === index"
          :popupContents="popupContents"
          :posBottom="posBottom"
          :posRight="posRight"
          @mouseleave.stop="isLeaveText()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import bulletItem from '@/components/atoms/bullet-item'
import popupBalloonText from '@/components/molecules/popup-balloon-text'

export default {
  components: {
    bulletItem,
    popupBalloonText
  },
  data() {
    return {
      showPopup: null,
      posTop: '0px',
      scrollTop: '0px',
      popupContents: {
        body: '',
        name: '',
        length: ''
      }
    }
  },
  props: {
    items: Array,
    posBottom: {
      type: Boolean,
      default: false
    },
    posRight: {
      type: Boolean,
      default: false
    },
    isOrderly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    isHoverText(item, id) {
      const targetItem = this.$refs['item-' + id][0]
      const target = targetItem?.$el ? targetItem.$el : targetItem
      const posTop = target.offsetTop
      const targetHeight = target.offsetHeight
      const popupContents = {
        body: item.text,
        name: item.name,
        length: item.text.length
      }

      this.popupContents = Object.assign(this.popupContents, popupContents)
      this.showPopup = id
      this.$emit('show-popup', id)

      // ポップアップの初期位置を設定
      if (this.posBottom) {
        this.posTop = posTop + 'px'
      } else {
        this.posTop = posTop + targetHeight + 'px'
      }

      // スクロールの監視 スクロール領域を出たらポップアップが消える
      const options = {
        root: this.$refs.scrollTarget,
        rootMargin: '0px',
        threshold: [0, 1.0]
      }
      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0]
        if (this.showPopup === id && !entry.isIntersecting) {
          this.showPopup = null
        }
      }, options)
      observer.observe(target)
    },
    isLeaveText: function () {
      this.showPopup = null
    },
    onScroll(e) {
      this.scrollTop = e.target.scrollTop + 'px'
    },
    closePopup() {
      this.showPopup = null
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  position: relative;
  height: 100%;
  &-inner {
    overflow-y: scroll;
    height: 100%;
    @include scrollbar;
  }
}

.item {
  margin: 0 0 $space-base;
  cursor: pointer;
  &:last-of-type {
    margin: 0;
  }
  &-orderly {
    margin: 0 0 $space-sub;
    &-inner {
      display: flex;
      align-items: center;
    }
    &-rank {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: adjustVW(24);
      height: adjustVW(24);
      margin-right: $space-base;
      background-color: $border-gray;
      border-radius: 9in;
      &-1 {
        background-color: $key-color;
      }
      &-2 {
        background-color: #a94fb2;
      }
      &-3 {
        background-color: #ce9bd3;
      }
      &-text {
        margin-top: adjustVW(1);
      }
    }
  }
}

.popup {
  --posTop: 0;
  --scrollTop: 0;
  position: absolute;
  top: calc(
    var(--posTop) - var(--scrollTop) + #{adjustVW(20)}
  ); // ポップアップの三角の矢印分高さを追加
  left: 0;
  width: max-content;
  max-width: adjustVW(648);
  &-bottom {
    top: inherit;
    bottom: calc(
      100% - (var(--posTop) - var(--scrollTop) - #{adjustVW(20)})
    ); // ポップアップの三角の矢印分高さを追加
  }
  &-right {
    right: 0;
    left: inherit;
  }
}
</style>
