<template>
  <div
    class="learn-side-wrap"
    :class="{
      'learn-side-wrap-optimization': serviceType === 'optimization'
    }"
  >
    <texts
      class="learn-side-title"
      :text="$t('serviceDetail.learnSide.title')"
      size="large"
    />
    <div class="learn-side-block">
      <text-with-title
        :title="$t('serviceList.header.recipe')"
        :text="service.recipe.name"
      />
    </div>
    <div v-if="service.edaRecipe" class="learn-side-block">
      <text-with-title
        :title="$t('serviceList.popup.addNewService.PreprocessingToUse')"
        :text="service.edaRecipe.name"
      />
    </div>
    <div v-if="serviceType === 'learn'" class="learn-side-input">
      <div
        v-if="service.predictionColumns && service.predictionColumns.length > 0"
        class="learn-side-block-input"
      >
        <texts
          class="learn-side-block-title"
          :text="$t('selectTargetColumn.menu.targetColumnInfo.title')"
          size="small"
          color="gray"
        />
        <div class="learn-side-item">
          <div class="learn-side-item-inner learn-side-item-input">
            <template v-for="(column, key) in service.predictionColumns">
              <texts :key="key" :text="column" size="small" />
            </template>
          </div>
        </div>
      </div>
      <div
        v-if="service.inputColumns && service.inputColumns.length > 0"
        class="learn-side-block-input"
      >
        <texts
          class="learn-side-block-title"
          :text="$t('service.learn.inputColumns')"
          size="small"
          color="gray"
        />
        <div class="learn-side-item">
          <div class="learn-side-item-inner learn-side-item-input">
            <template v-for="(column, key) in service.inputColumns">
              <texts :key="key" :text="column" size="small" />
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="serviceType === 'optimization'" class="learn-side-block">
      <div class="learn-side-select">
        <texts
          class="learn-side-block-desc"
          :text="$t('serviceDetail.optimization.datasetDesc')"
          size="14"
          color="gray"
        />
        <select-box
          v-model="showedDataset"
          :items="datasetItems"
          valueKey="id"
          scrollBar
        />
      </div>
      <transition-toggle-contents>
        <div
          v-if="datasetColumns && datasetColumns.length > 0 && !changeDataset"
        >
          <div class="learn-side-item learn-side-item-column">
            <div class="learn-side-item-inner learn-side-item-column-item">
              <template v-for="(column, key) in datasetColumns">
                <texts :key="key" :text="column" size="min" />
              </template>
            </div>
          </div>
        </div>
      </transition-toggle-contents>
    </div>
  </div>
</template>

<script>
import selectBox from '@/components/molecules/select-box'
import textWithTitle from '@/components/molecules/text-with-title'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

export default {
  components: {
    selectBox,
    textWithTitle,
    transitionToggleContents
  },
  data() {
    return {
      showedDataset: null,
      changeDataset: false
    }
  },
  props: {
    service: Object,
    serviceType: String,
    project: Object
  },
  computed: {
    datasetItems() {
      if (this.serviceType !== 'optimization') return []
      if (!this.project || !this.project?.listData) return []
      return this.project.listData
    },
    datasetColumns() {
      if (this.serviceType !== 'optimization') return []
      if (
        !this.showedDataset ||
        !this.datasetItems ||
        this.datasetItems.length === 0
      )
        return []
      const target = this.datasetItems.find((item) => {
        return item.id === this.showedDataset
      })
      return target?.columns ?? []
    }
  },
  watch: {
    showedDataset() {
      this.changeDataset = true
      this.$nextTick(() => {
        this.changeDataset = false
      })
    }
  },
  mounted() {
    if (this.serviceType !== 'optimization') return []
    if (!this.project || !this.project?.listData) return
    this.showedDataset = this.project.listData[0].id
  }
}
</script>

<style lang="scss" scoped>
.learn-side {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    &-optimization {
      overflow: visible;
    }
  }
  &-title {
    flex-shrink: 0;
    margin-bottom: $space-sub;
  }
  &-block {
    flex-shrink: 0;
    margin-bottom: $space-medium;
    &:last-of-type {
      margin-bottom: 0;
    }
    &-title {
      flex-shrink: 0;
      margin-bottom: $space-base;
    }
    &-input {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
    }
    &-desc {
      padding-top: $space-medium;
      margin-bottom: $space-text;
      border-top: $border-sub;
      word-break: break-all;
      white-space: pre-line;
    }
  }
  &-item {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: $space-sub;
    background: $background-sub;
    border-radius: adjustVW(8);
    &-input {
      overflow-y: auto;
      @include scrollbar;
    }
    &-inner {
      display: flex;
      flex-wrap: wrap;
      grid-column-gap: $space-sub;
      grid-row-gap: $space-sub;
    }
    &-column {
      max-height: adjustVW(328);
      &-item {
        overflow-y: scroll;
        @include scrollbar;
      }
    }
  }
  &-input {
    overflow: hidden;
    display: grid;
    flex-direction: column;
    flex-shrink: 1;
    grid-template-rows: minmax(1fr, auto) minmax(1fr, auto);
    grid-row-gap: $space-medium;
  }
  &-select {
    height: auto;
    margin-bottom: $space-small;
  }
}
</style>
