export function setValue(dtypes, targetColumns) {
  const columns = Object.entries(dtypes)
  const filteredColumns = columns.filter((column) => {
    return !targetColumns.includes(column[0])
  })
  return filteredColumns.map((column) => {
    return {
      column: column[0],
      condition: {
        type: null,
        params: null
      },
      integer: column[1] === 'int',
      disabledInteger: true
    }
  })
}

/**
 * @param { Object } value // 変更する条件の内容
 * @param { String } value.type // integer or condition
 * @param { String } value.column // 変更する列名
 * @param { Object } [value.condition] // 最適化の条件
 * @param { Boolean } [value.integer] // 整数値にするかどうか
 * @param { Array } columnConditions // 列の条件の配列
 * @returns { Object }
 **/
export function updateColumnValue(value, columnConditions) {
  const index = columnConditions.findIndex((item) => {
    return item.column === value.column
  })
  const target = columnConditions[index]
  if (value.type === 'condition') {
    target.condition = value.condition
  } else if (value.type === 'integer') {
    target.integer = value.integer
  }
}

export function setByColumnConditions(newVal, targetColumns) {
  const predictionColumnItem = newVal.filter((item) => {
    const checkOptimizationTarget = targetColumns.includes(item.column)
    const checkNull = !item?.condition?.type
    return !checkOptimizationTarget && !checkNull
  })
  const predictionColumn = predictionColumnItem.map((item) => {
    return item.column
  })

  return predictionColumn
}

export function setByExpressions(newVal, targetColumns, dtypes) {
  const predictionColumn = []
  newVal.forEach((item) => {
    if (!item.expression) return

    const columns = item.expression.match(/"[^"]+"/g)
    if (!columns) return

    const fixColumns = columns.map((column) => {
      return column.replace(/"([^"]+)"/, '$1')
    })

    const existColumns = Object.keys(dtypes)
    const checkNotExist = fixColumns.some((column) => {
      return !existColumns.includes(column)
    })
    if (checkNotExist) return

    const checkOptimizationTarget = fixColumns.filter((column) => {
      return !targetColumns.includes(column)
    })

    predictionColumn.push(...checkOptimizationTarget)
  })
  return predictionColumn
}

export function validConditions(
  optimizationConditions,
  validExpressions,
  isNotSaved,
  inTraining
) {
  const res = []
  const optimizationCheck = optimizationConditions.columnConditions.some(
    (target) => {
      return target.condition.type !== null
    }
  )
  const expressionCheck = optimizationConditions.expressions.some((target) => {
    return target?.expression && target?.expression.length > 0
  })
  // 最適化の条件が一つも設定されていない場合
  if (!optimizationCheck && !expressionCheck && !isNotSaved && !inTraining) {
    res.push({
      type: 'notSet'
    })
  }
  // 式が存在する場合に、条件が未指定
  if (
    optimizationConditions.expressions &&
    optimizationConditions.expressions.length > 0
  ) {
    const noCondtionExpression = optimizationConditions.expressions.filter(
      (target) => {
        return target.condition.type === null
      }
    )
    if (noCondtionExpression && noCondtionExpression.length > 0) {
      res.push({
        type: 'expressionNeedCondition'
      })
    }
  }
  // 式条件にエラー
  if (validExpressions.length > 0) {
    res.push({
      type: 'expressionError'
    })
  }
  // 値に近づける場合に値が設定されていない
  const noParamsCondition = optimizationConditions.columnConditions.filter(
    (target) => {
      return (
        target.condition.type === 'closer' &&
        target.condition.params.target === null
      )
    }
  )
  const noParamsExpression = optimizationConditions.expressions.filter(
    (target) => {
      return (
        target.condition.type === 'closer' &&
        target.condition.params.target === null
      )
    }
  )
  if (noParamsCondition.length > 0 || noParamsExpression.length > 0) {
    res.push({
      type: 'notSetParams',
      errorTarget: [...noParamsCondition, ...noParamsExpression]
    })
  }
  return res
}
