<template>
  <div v-if="!notShowFI" class="trained-ai-feature-importance">
    <div class="trained-ai-feature-importance-side">
      <div class="trained-ai-feature-importance-display-type">
        <select-column-box
          v-if="
            trainedAi.trainConfig.predictionColumn &&
              trainedAi.trainConfig.predictionColumn.length > 1
          "
          v-model="selectedItem"
          class="trained-ai-feature-importance-input"
          :title="$t('trainedAi.multi.selectedColumn')"
          :trainedAi="trainedAi"
          :finishColumns="finishColumns"
        />
        <div class="trained-ai-detail-title-sub">
          <texts
            :text="$t('trainedAiDetails.featureImportanceTab.togglePage')"
            color="gray"
            size="small"
          />
        </div>
        <div class="trained-ai-feature-importance-display-type-list">
          <button
            class="trained-ai-feature-importance-display-type-item"
            :class="
              displayType === 'distribution'
                ? 'trained-ai-feature-importance-display-type-item-active'
                : ''
            "
            @click="displayTypeClick('distribution')"
          >
            <text-with-icon
              iconName="bubbleChart"
              :text="$t('trainedAiDetails.featureImportanceTab.distribution')"
              size="small"
              :color="displayType === 'distribution' ? 'emphasis' : 'gray'"
            />
          </button>
          <button
            class="trained-ai-feature-importance-display-type-item"
            :class="
              displayType === 'size'
                ? 'trained-ai-feature-importance-display-type-item-active'
                : ''
            "
            @click="displayTypeClick('size')"
          >
            <text-with-icon
              iconName="compareChart"
              :text="
                $t('trainedAiDetails.featureImportanceTab.importanceGraph')
              "
              size="small"
              :color="displayType === 'size' ? 'emphasis' : 'gray'"
            />
          </button>
        </div>
      </div>
      <transition-toggle-contents>
        <div
          v-if="displayType === 'distribution'"
          key="distribution"
          class="trained-ai-feature-importance-distribution"
        >
          <transition-toggle-contents>
            <div
              v-if="loadChangeColumn"
              key="load-distribution-info"
              class="trained-ai-feature-importance-distribution-inner-none"
            >
              <loading-icon />
            </div>
            <div
              v-else-if="selectedColumnIndex === -1"
              key="none-distribution-info"
              class="trained-ai-feature-importance-distribution-inner-none"
            >
              <texts
                class="
                  trained-ai-feature-importance-distribution-inner-none-text
                "
                :text="$t('trainedAi.multi.notFoundSeparate')"
                size="small"
                color="gray"
              />
            </div>
            <div
              v-else-if="
                targetFeature && Object.keys(targetFeature).length === 0
              "
              key="none-feature-info"
              class="trained-ai-feature-importance-distribution-inner-none"
            >
              <texts
                class="
                  trained-ai-feature-importance-distribution-inner-none-text
                "
                :text="$t('trainedAiDetails.noFeatureImportance')"
                color="gray"
                size="small"
              />
            </div>
            <div
              v-else
              key="distribution-info"
              class="trained-ai-feature-importance-distribution-inner"
            >
              <div class="trained-ai-detail-title-sub">
                <texts
                  :text="
                    $t('trainedAiDetails.featureImportanceTab.selectedVariable')
                  "
                  color="gray"
                  size="small"
                />
              </div>
              <div
                class="trained-ai-feature-importance-distribution-select-box"
              >
                <select-box
                  :value="selectedValiable"
                  :items="valiablesSelect"
                  isGray
                  scrollBar
                  @input="changeValiable"
                />
              </div>
              <div
                class="
                  trained-ai-detail-title-sub
                  trained-ai-feature-importance-distribution-title
                "
              >
                <texts
                  :text="
                    $t(
                      'trainedAiDetails.featureImportanceTab.importantValiable'
                    )
                  "
                  color="gray"
                  size="small"
                />
              </div>
              <div
                class="trained-ai-feature-importance-distribution-variable-list"
              >
                <div
                  v-for="([variableName], index) in limitCountDistribution"
                  :key="variableName"
                  class="
                    trained-ai-feature-importance-distribution-variable-item
                  "
                >
                  <div
                    class="
                      trained-ai-feature-importance-distribution-variable-item-rank
                    "
                  >
                    <texts
                      class="
                        trained-ai-feature-importance-distribution-variable-item-rank-item
                      "
                      :text="index + 1"
                      size="min"
                      :color="index < 3 ? 'function' : ''"
                    />
                  </div>
                  <div
                    class="
                      trained-ai-feature-importance-distribution-variable-item-name
                    "
                  >
                    <texts :text="variableName" size="small" />
                  </div>
                </div>
              </div>
            </div>
          </transition-toggle-contents>
        </div>
        <div
          v-else-if="displayType === 'size'"
          key="size"
          class="trained-ai-feature-importance-size"
        >
          <div class="trained-ai-feature-importance-size-inner">
            <div
              class="
                trained-ai-detail-title-sub
                trained-ai-feature-importance-size-title
              "
            >
              <texts
                :text="$t('common.nImportanceShown')"
                color="gray"
                size="small"
              />
              <div class="trained-ai-feature-importance-size-count">
                <input
                  v-model="scaleSize"
                  type="number"
                  min="0"
                  :max="scaleMax"
                >
              </div>
            </div>
            <div class="trained-ai-feature-importance-size-scale">
              <div class="trained-ai-feature-importance-size-scale-inner">
                <input
                  v-model="scaleSize"
                  class="trained-ai-feature-importance-size-scale-range"
                  type="range"
                  name="speed"
                  min="0"
                  :max="scaleMax"
                >
                <div
                  class="trained-ai-feature-importance-size-scale-range-status"
                  :style="{ '--width': (scaleSize / scaleMax) * 100 + '%' }"
                />
              </div>
            </div>
          </div>
        </div>
      </transition-toggle-contents>
    </div>
    <div class="trained-ai-feature-importance-graph">
      <transition-toggle-contents>
        <template v-if="displayType === 'distribution'">
          <div class="trained-ai-feature-importance-graph-distribution">
            <div class="trained-ai-feature-importance-graph-distribution-main">
              <transition-toggle-contents>
                <loading
                  v-if="
                    featureImportanceScatterInfo == null ||
                      loadingValiable ||
                      loadChangeColumn
                  "
                  key="distribution"
                  class="trained-ai-feature-importance-graph-distribution"
                />
                <div
                  v-else-if="selectedColumnIndex === -1"
                  class="trained-ai-feature-importance-none-item"
                >
                  <texts :text="$t('trainedAi.multi.notFound')" color="gray" />
                </div>
                <div
                  v-else-if="
                    targetFeature && Object.keys(targetFeature).length === 0
                  "
                  key="none-feature-info"
                  class="trained-ai-feature-importance-none-item"
                >
                  <texts
                    :text="$t('trainedAiDetails.noFeatureImportance')"
                    color="gray"
                  />
                </div>
                <ShapScatter
                  v-else
                  key="distribution"
                  class="trained-ai-feature-importance-graph-distribution-inner"
                  :xTest="featureImportanceScatterInfo.x_test"
                  :shapValues="featureImportanceScatterInfo.shap_values"
                  :inputColumnName="selectedValiable"
                  :index="selectedValiableIndex"
                />
              </transition-toggle-contents>
            </div>

            <transition-toggle-contents>
              <column-labled-info
                v-if="showLabledInfo"
                :labelInfo="labelInfo"
              />
              <consultation-service v-else />
            </transition-toggle-contents>
          </div>
        </template>
        <template v-if="displayType === 'size'">
          <div key="size" class="trained-ai-feature-importance-graph-size">
            <card-base class="trained-ai-feature-importance-graph-size-card">
              <transition-toggle-contents>
                <loading
                  v-if="loadingValiable || loadChangeColumn"
                  key="distribution"
                  class="trained-ai-feature-importance-graph-distribution"
                />
                <div
                  v-else-if="selectedColumnIndex === -1"
                  class="trained-ai-feature-importance-none-item"
                >
                  <texts :text="$t('trainedAi.multi.notFound')" color="gray" />
                </div>
                <div
                  v-else-if="
                    targetFeature && Object.keys(targetFeature).length === 0
                  "
                  key="none-feature-info"
                  class="trained-ai-feature-importance-none-item"
                >
                  <texts
                    :text="$t('trainedAiDetails.noFeatureImportance')"
                    color="gray"
                  />
                </div>
                <feature-importance-bar-graph
                  v-else
                  :features="targetFeature"
                  :maxCount="Number(scaleSize)"
                />
              </transition-toggle-contents>
            </card-base>
            <div class="trained-ai-feature-importance-graph-info">
              <text-with-icon
                class="trained-ai-feature-importance-graph-info-title"
                :text="
                  $t('trainedAiDetails.featureImportanceTab.importanceGraph')
                "
                iconName="compareChart"
                color="green"
              />
              <div class="trained-ai-feature-importance-graph-info-desc-wrap">
                <texts
                  v-for="(desc, key) in sizeDescs"
                  :key="key"
                  class="trained-ai-feature-importance-graph-info-desc"
                  :text="desc"
                  :size="'small'"
                  :color="'gray'"
                />
              </div>
              <button-main
                class="trained-ai-feature-importance-graph-info-button"
                :text="$t('common.contact.consultant')"
                type="green"
                :fontSize="16"
                size="thin"
                isEllipse
                @click="goContact"
              />
            </div>
          </div>
        </template>
      </transition-toggle-contents>
    </div>
  </div>
  <div v-else class="trained-ai-feature-importance-none">
    <texts :text="$t('trainedAiDetails.noFeatureImportance')" color="gray" />
  </div>
</template>

<script>
import buttonMain from '@/components/atoms//button-main.vue'
import texts from '@/components/atoms/text'
import textWithIcon from '@/components/molecules/text-with-icon'
import selectBox from '@/components/molecules/select-box'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import FeatureImportanceBarGraph from '@/components/molecules/feature-importance-bar-graph.vue'
import CardBase from '@/components/atoms/card-base.vue'
import Loading from '@/components/atoms/loading.vue'
import LoadingIcon from '@/components/atoms/loading-icon.vue'
import ShapScatter from './shap-scatter.vue'
import selectColumnBox from '@/components/organisms/trained-ai-common/select-column-box'
import consultationService from '@/components/organisms/trained-ai-detail/trained-ai-detai-tab-inner/feature-importance/consultation-service.vue'
import columnLabledInfo from '@/components/organisms/trained-ai-detail/trained-ai-detai-tab-inner/feature-importance/column-labeled-info.vue'

export default {
  components: {
    buttonMain,
    texts,
    textWithIcon,
    selectBox,
    transitionToggleContents,
    consultationService,
    FeatureImportanceBarGraph,
    CardBase,
    Loading,
    LoadingIcon,
    ShapScatter,
    selectColumnBox,
    columnLabledInfo
  },
  data() {
    return {
      isTipsShow: '',
      displayType: 'distribution',
      scaleSize: 0,
      selectedValiable: null,
      selectedItem: '',
      loadingValiable: false
    }
  },
  mounted() {
    this.$emit('load-feature-importance')

    if (
      this.selectedColumnIndex >= 0 &&
      this.trainedAi?.summary?.featureImportance &&
      Object.keys(this.trainedAi.summary.featureImportance).length > 0
    ) {
      const preprocessedMeanShap =
        this.featureImportanceScatter?.preprocessed_info?.mean_shap
      const meanShap =
        preprocessedMeanShap ?? this.trainedAi.summary.featureImportance

      const valiables = Object.entries(meanShap).sort((x, y) => y[1] - x[1])

      this.scaleSize = Math.min(20, valiables.length)
      this.selectedValiable = valiables.length > 0 ? valiables[0][0] : null
    }

    const configColumn = this.trainedAi?.trainConfig?.predictionColumn ?? []
    if (configColumn.length === 0) return
    this.selectedItem = configColumn[this.selectedColumnIndex]
  },
  props: {
    trainedAi: Object,
    featureImportanceScatter: Object,
    selectedColumnIndex: Number,
    finishColumns: Array,
    loadChangeColumn: Boolean
  },
  methods: {
    displayTypeClick(e) {
      this.displayType = e
    },
    goContact() {
      window.open(this.$urls.contactLink, '_blank')
    },
    changeValiable(e) {
      this.loadingValiable = true
      this.selectedValiable = e
      this.$nextTick(() => {
        this.loadingValiable = false
      })
    }
  },
  computed: {
    meanShapList: function () {
      const preprocessedInfo = this.featureImportanceScatter?.preprocessed_info
      const meanShap =
        preprocessedInfo?.mean_shap ??
        this.trainedAi?.summary?.featureImportance

      return meanShap
    },
    featureImportanceScatterInfo: function () {
      const preprocessedInfo = this.featureImportanceScatter?.preprocessed_info

      if (preprocessedInfo ?? false) {
        return {
          input_columns: preprocessedInfo.input_columns,
          shap_values: preprocessedInfo.shap_values,
          x_test: preprocessedInfo.x_test,
          label_info: preprocessedInfo.label_info
        }
      } else {
        return this.featureImportanceScatter
      }
    },
    targetFeature() {
      if (!this.meanShapList) return {}

      // 重要度の大きさ比較の場合は、onehot 列の加工がされたデータを使用する
      if (this.displayType === 'size') {
        return this.trainedAi.summary.featureImportance
      } else {
        return this.meanShapList
      }
    },
    /**
     * 重要度の大きさ比較用
     */
    scaleMax() {
      if (this.selectedColumnIndex < 0) return 0
      return Object.keys(this.trainedAi.summary.featureImportance).length
    },
    /**
     * 複数の目的変数以外の場合で、重要度がない場合の表示判定（複数の目的変数の場合、template内で判定）
     * また、時系列の場合はこのタブが表示されないので判定不要
     */
    notShowFI() {
      if (!this.meanShapList || !this.trainedAi?.trainConfig?.predictionColumn)
        return true
      return (
        Object.keys(this.meanShapList).length === 0 &&
        this.trainedAi.trainConfig.predictionColumn.length < 2
      )
    },
    valiablesSelect() {
      const valiables = this.sortedEntries
      return valiables.map((x) => ({
        name: x[0],
        value: x[0]
      }))
    },
    sortedEntries() {
      if (this.selectedColumnIndex < 0) return []
      return Object.entries(this.meanShapList).sort((x, y) => y[1] - x[1])
    },
    limitCountDistribution() {
      if (this.sortedEntries.length > 10) {
        return this.sortedEntries.slice(0, 10)
      } else {
        return this.sortedEntries
      }
    },
    selectedValiableIndex() {
      if (this.featureImportanceScatterInfo == null) return null

      const columns = this.featureImportanceScatterInfo.input_columns

      const index = columns.findIndex((x) => x === this.selectedValiable)
      return index
    },
    selectedValiableImportance() {
      return this.$t('trainedAIDetail.featureImportanceTab.scatterY', {
        name: this.selectedValiable
      })
    },
    sizeDescs() {
      const target = Object.values(
        this.$t(
          'trainedAiDetails.featureImportanceTab.description.importanceGraph'
        )
      )
      return target
    },
    showLabledInfo: function () {
      const hasLabelInfo = Object.keys(this.labelInfo).length > 0
      return hasLabelInfo
    },
    labelInfo: function () {
      const targetColumn = this.selectedValiable
      const labelInfo = this.featureImportanceScatterInfo?.label_info ?? {}

      const targetLabelInfo = Object.keys(labelInfo).includes(targetColumn)
        ? labelInfo[targetColumn]
        : {}

      return targetLabelInfo
    }
  },
  watch: {
    scaleSize(newVal) {
      if (newVal < 0) {
        this.scaleSize = 0
      } else if (newVal > this.scaleMax) {
        this.scaleSize = this.scaleMax
      }
    },
    selectedItem(newVal) {
      this.$emit('change-column', newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.trained-ai-feature-importance {
  overflow: hidden;
  display: grid;
  grid-template-columns: adjustVW(320) 1fr;
  grid-template-rows: 100%;
  grid-column-gap: $space-medium;
  height: 100%;
  padding: 0 $space-medium $space-medium;
  &-side {
    display: flex;
    flex-direction: column;
  }
  &-display-type {
    padding: $space-small;
    margin: 0 0 $space-medium;
    background: $background;
    border-radius: adjustVW(12);
    box-shadow: $box-shadow-main;
    &-item {
      width: 100%;
      padding: $space-base $space-sub;
      margin: 0 0 $space-base;
      background: transparent;
      border-radius: 9in;
      &:last-child {
        margin: 0;
      }
      &-active {
        background: $key-lite;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  &-distribution {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(12);
    box-shadow: $box-shadow-main;
    &-inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      &-none {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        &-text {
          text-align: center;
          white-space: pre-line;
        }
      }
    }
    &-select-box {
      padding: 0 0 $space-small;
      margin: 0 0 $space-sub;
      border-bottom: $border-main;
    }
    &-variable {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      padding: $space-small;
      background: $background;
      border-radius: adjustVW(12);
      box-shadow: $box-shadow-main;
      &-list {
        overflow-y: auto;
        width: 100%;
        padding: 0 $space-min 0 0;
        @include scrollbar;
      }
      &-item {
        display: flex;
        align-items: center;
        margin: 0 0 $space-small;
        &:last-child {
          margin: 0;
        }
        &-rank {
          display: flex;
          align-items: center;
          justify-content: center;
          width: adjustVW(24);
          height: adjustVW(24);
          margin: 0 $space-sub 0 0;
          background: $medium-gray;
          border-radius: 9in;
          .trained-ai-feature-importance-distribution-variable-item:nth-child(1)
            & {
            background: $key-color;
          }
          .trained-ai-feature-importance-distribution-variable-item:nth-child(2)
            & {
            background: rgba(133, 4, 145, 0.75);
          }
          .trained-ai-feature-importance-distribution-variable-item:nth-child(3)
            & {
            background: rgba(133, 4, 145, 0.5);
          }
          &-item {
            line-height: 1;
          }
        }
        &-name {
          overflow: hidden;
          width: calc(100% - #{adjustVW(24) + $space-sub});
        }
      }
    }
  }
  &-size {
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-medium;
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-inner {
      padding: $space-small;
      background: $background;
      border-radius: adjustVW(12);
      box-shadow: $box-shadow-main;
    }
    &-count {
      width: adjustVW(56);
      padding: $space-min;
      border: $border-emphasis;
      font-size: $text-base;
      border-radius: adjustVW(4);
      > input {
        appearance: textfield;
        width: 100%;
        text-align: center;
        &::-webkit-outer-spin-button {
          appearance: none;
          margin: 0;
        }
        &::-webkit-inner-spin-button {
          appearance: none;
          margin: 0;
        }
      }
    }
    &-scale {
      &-inner {
        position: relative;
      }
      &-range {
        appearance: none;
        width: 100%;
        height: adjustVH(8);
        background: $background-sub;
        border-radius: adjustVW(4);
        cursor: pointer;
        outline: none;
        &::-webkit-slider-thumb {
          position: relative;
          appearance: none;
          width: adjustVW(24);
          height: adjustVW(24);
          border: $border-emphasis;
          background: $background;
          border-radius: 9in;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
          z-index: 2;
        }
        &::-moz-range-thumb {
          width: adjustVW(24);
          height: adjustVW(24);
          border: $border-emphasis;
          background: $background;
          border-radius: 9in;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
        }
        &-status {
          --width: 50;
          position: absolute;
          top: 50%;
          width: var(--width);
          height: adjustVH(8);
          background: $key-color;
          border-radius: 9in 0 0 9in;
          z-index: 1;
          transform: translateY(-50%);
        }
      }
    }
  }
  &-graph {
    width: 100%;
    height: 100%;
    &-distribution {
      display: flex;
      grid-column-gap: $space-medium;
      width: 100%;
      height: 100%;
      &-main {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: adjustVW(756);
        max-width: inherit;
        height: 100%;
        padding: $space-small;
        background: $background;
        border-radius: adjustVW(12);
        box-shadow: $box-shadow-main;
      }
      &-inner {
        width: 100%;
        max-width: inherit;
        height: 100%;
      }
    }
    &-size {
      display: flex;
      grid-column-gap: $space-medium;
      height: 100%;
      &-card {
        overflow: hidden;
        flex-shrink: 0;
        width: adjustVW(880);
        height: 100%;
      }
    }
    &-info {
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      width: 100%;
      height: fit-content;
      max-height: 100%;
      padding: $space-small;
      border: $border-green;
      background: $background;
      border-radius: adjustVW(12);
      box-shadow: $box-shadow-main;
      &-title {
        margin-bottom: $space-sub;
      }
      &-desc {
        margin-bottom: $space-sub;
        line-height: $line-height-base;
        word-break: break-all;
        white-space: pre-line;
        &:last-of-type {
          margin-bottom: 0;
        }
        &-wrap {
          overflow-y: auto;
          margin-bottom: $space-sub;
          @include scrollbar;
        }
      }
      &-button {
        height: adjustVW(40);
      }
    }
  }
  &-input {
    margin-bottom: $space-medium;
  }
  &-none {
    width: fit-content;
    height: fit-content;
    padding: $space-medium;
    margin-left: $space-medium;
    background: $background;
    border-radius: adjustVW(12);
    box-shadow: $box-shadow-main;
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
