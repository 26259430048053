<template>
  <!-- project-list -->
  <transition-group name="toggle-item" class="project-list">
    <card key="create" :row="3" class="project-item project-item-first-wrap">
      <div class="project-item-first-inner">
        <images class="project-item-first-image" imageName="createNewItem" />
      </div>
      <div class="project-item-first-button">
        <button-main
          :text="$t('projectList.newProjectButton')"
          type="emphasis"
          @click="linkTo"
        />
      </div>
    </card>
    <project-list-card
      v-for="item in sortedItems"
      :key="item.id"
      class="project-item"
      :project="item"
      :isAlreadyChecked="value.length > 0"
      :disabledCheck="disabledCheck(item.id)"
      :checked="isChecked(item.id)"
      :plan="plan"
      @input="setChecked($event, item.id)"
    />
    <div v-if="noSearchResults" key="notFound" class="project-item-not-found">
      <images imageName="notFound" class="project-item-not-found-image" />
      <texts :text="$t('common.noSearchResults')" size="small" />
    </div>
  </transition-group>
</template>

<script>
import images from '@/components/atoms/images.vue'
import projectListCard from './project-list-card.vue'
import card from '@/components/atoms/card.vue'
import buttonMain from '@/components/atoms/button-main.vue'
import Texts from '@/components/atoms/text.vue'

export default {
  components: {
    images,
    projectListCard,
    card,
    buttonMain,
    Texts
  },
  data() {
    return {
      checked: new Set(this.value)
    }
  },
  props: {
    items: Array,
    sortOrder: String,
    hasTrain: Boolean,
    hasService: Boolean,
    hasRecipe: Boolean,
    value: Array,
    noSearchResults: Boolean,
    disableCheckProjectId: Array,
    plan: String
  },
  methods: {
    createProject() {
      this.$emit('open-create')
    },
    isChecked(id) {
      return this.checked.has(id)
    },
    setChecked(e, id) {
      if (e) {
        this.checked.add(id)
      } else {
        this.checked.delete(id)
      }
      this.checked = new Set(this.checked)
      this.$emit('input', Array.from(this.checked))
    },
    linkTo() {
      this.$router.push({ name: 'selectMode' })
    },
    disabledCheck(id) {
      return this.disableCheckProjectId.includes(id)
    }
  },
  computed: {
    filteredItems() {
      let target = this.items
      if (this.hasTrain) {
        target = target.filter((item) => {
          return item.numAIs > 0
        })
      }
      if (this.hasRecipe) {
        target = target.filter((item) => {
          return item.numRecipe > 0
        })
      }
      if (this.hasService) {
        target = target.filter((item) => {
          return item.nServices.total > 0
        })
      }
      return target
    },
    sortedItems() {
      const compareTime = (x, y) =>
        x.updateTime < y.updateTime ? 1 : x.updateTime > y.updateTime ? -1 : 0
      if (this.sortOrder === 'ascending') {
        return this.filteredItems.slice().sort(compareTime).reverse()
      } else {
        return this.filteredItems.slice().sort(compareTime)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// プロジェクト一覧用
.project {
  &-list {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: $space-medium;
    grid-row-gap: $space-medium;
  }
  &-item-first {
    &-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: auto;
      min-height: adjustVH(312);
      cursor: inherit;
      &:hover {
        background-color: $background;
        opacity: 1;
      }
    }
    &-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 $space-medium;
    }
    &-image {
      width: adjustVW(200);
      height: auto;
    }
  }
  &-item {
    transition: transform $transition-base;
    &-not-found {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &-image {
        width: adjustVW(240);
        margin-bottom: $space-medium;
      }
    }
  }
}

.toggle-item-leave-active {
  position: absolute;
  opacity: 0;
}
.toggle-item-enter,
.toggle-item-leave-to {
  transform: translateY(-$space-small);
  will-change: opacity, transform;
}
</style>
