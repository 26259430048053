<template>
  <div class="template-wrap">
    <div class="template-main">
      <div class="template-title">
        <texts
          :text="$t('serviceList.popup.addNewService.preprocessingSelectTitle')"
        />
      </div>
      <transition-toggle-contents>
        <div v-if="preprocessingList" class="template-list-wrap">
          <div class="template-search">
            <fuse-search
              :placeholder="$t('preprocessing.search')"
              :option="option"
              :value="preprocessingList"
              gray
              @input="inputSearch"
            />
          </div>
          <div class="template-list">
            <list-item-slot
              :items="showItems ? showItems : preprocessingList"
              :field="field"
              type="button"
              @list-click="listClick"
            >
              <template v-slot:name="entry">
                <texts
                  class="template-item-name"
                  :text="entry.entry.name"
                  isBold
                />
                <description-tooltip
                  :description="entry.entry.description"
                  :index="entry.listIndex"
                />
              </template>
            </list-item-slot>
          </div>
        </div>
        <loading v-else class="template-list-wrap" />
      </transition-toggle-contents>
      <div class="template-button">
        <button-large
          class="template-button-item"
          :text="$t('serviceList.popup.addNewService.preprocessingSelected')"
          :isDisabled="!checked"
          @click="$emit('select-eda-recipe', checked)"
        />
        <button-large
          class="template-button-item"
          :text="$t('preprocessing.noPreprocessing')"
          isSub
          @click="$emit('select-eda-recipe', null)"
        />
      </div>
    </div>
    <div class="template-preprocessing-list">
      <div class="template-preprocessing-list-inner">
        <transition-toggle-contents>
          <preprocessing-chart-list
            v-if="layers && !layersLoading"
            :layers="layers"
          />
        </transition-toggle-contents>
      </div>
    </div>
  </div>
</template>

<script>
import descriptionTooltip from '@/components/molecules/description-tooltip'
import texts from '@/components/atoms/text'
import listItemSlot from '@/components/molecules/list-item-slot'
import fuseSearch from '@/components/molecules/fuse-search.vue'
import ButtonLarge from '@/components/atoms/button-large.vue'
import Loading from '@/components/atoms/loading.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import preprocessingChartList from '@/components/molecules/preprocessing-chart-list'

export default {
  components: {
    descriptionTooltip,
    texts,
    listItemSlot,
    fuseSearch,
    ButtonLarge,
    Loading,
    transitionToggleContents,
    preprocessingChartList
  },
  data() {
    return {
      checked: null,
      showItems: null,
      option: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.2,
        keys: [
          {
            name: 'name',
            weight: 1
          }
        ]
      },
      layersLoading: false
    }
  },
  props: {
    preprocessingList: Array
  },
  methods: {
    listClick(e) {
      const id = e.id
      this.checked = id
    },
    inputSearch(item) {
      this.showItems = item.result
    }
  },
  computed: {
    field() {
      return [
        {
          label: this.$t('preprocessing.preprocessingName'),
          key: 'name'
        }
      ]
    },
    layers() {
      const target = this.preprocessingList.find(
        (item) => item.id === this.checked
      )
      if (!target) return null
      return target?.layers
    }
  },
  watch: {
    layers() {
      this.layersLoading = true
      this.$nextTick(function () {
        this.layersLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.template {
  &-wrap {
    display: grid;
    grid-template-columns: minmax(adjustVW(594), 1fr) adjustVW(966);
    grid-column-gap: $space-medium;
    width: 100%;
    height: 100%;
  }
  &-main {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  &-title {
    flex-shrink: 0;
    margin: 0 0 $space-base;
  }
  &-search {
    flex-shrink: 0;
    margin: 0 0 $space-medium;
  }
  &-list {
    overflow-y: auto;
    flex-shrink: 1;
    flex-grow: 1;
    height: 100%;
    padding: 0 $space-base 0 0;
    margin: 0 0 $space-medium;
    @include scrollbar;
    &-wrap {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
  &-button {
    flex-shrink: 0;
    &-item {
      margin-bottom: $space-small;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &-detail {
    overflow: hidden;
  }
  &-item {
    &-name {
      overflow: hidden;
      margin-bottom: $space-text;
    }
  }
  &-preprocessing {
    &-list {
      overflow: hidden;
      padding: $space-small $space-base $space-small $space-small;
      background: $background-sub;
      border-radius: adjustVW(16);
      &-inner {
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        height: 100%;
        padding: adjustVW(20) $space-base $space-base 0;
        @include scrollbar;
      }
    }
  }
}
</style>
