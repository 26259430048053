<template>
  <div class="free-plan">
    <texts
      class="free-plan-text"
      :text="$t('serviceList.popup.limitServices.text')"
    />
    <div class="free-plan-footer">
      <button-main
        :text="$t('common.close')"
        type="sub"
        @click="$emit('close-modal')"
      />
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import texts from '@/components/atoms/text'

export default {
  components: {
    buttonMain,
    texts
  }
}
</script>

<style lang="scss" scoped>
.free-plan {
  &-wrap {
    width: adjustVW(720 + 24 + 24);
  }
  &-text {
    white-space: pre-line;
  }
  &-footer {
    padding: $space-medium $space-medium 0 0;
    border-right: $border-sub;
    &-inner {
      margin: 0 0 $space-medium;
    }
  }
}
</style>
