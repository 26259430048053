var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-base',{staticClass:"c-card"},[_c('div',{staticClass:"choosed-dataset-wrapper"},[_c('div',{staticClass:"choosed-dataset-item choosed-dataset-title"},[_c('texts',{attrs:{"size":"large","text":this.$i18n.t(
            'inference.settings.selectInferenceType.choosedDataset.title'
          )}})],1),(_vm.choosedDatasetInfo)?_c('div',{staticClass:"choosed-dataset-item"},[_c('texts',{staticClass:"choosed-dataset-name",attrs:{"text":_vm.choosedDatasetInfo.name,"isBold":""}}),_c('description',{staticClass:"choosed-dataset-about",attrs:{"size":"small","text":_vm.choosedDatasetInfo.description}})],1):_vm._e(),_c('div',{staticClass:"choosed-dataset-columns"},[(_vm.lackedColumns && _vm.lackedColumns.length > 0)?_c('div',{staticClass:"choosed-dataset-item"},[_c('texts',{staticClass:"choosed-dataset-columns-title",attrs:{"coler":"red","size":"large","text":this.$i18n.t(
              'inference.settings.selectInferenceType.choosedDataset.lackedColumns'
            )}}),_c('bullet-list',{attrs:{"items":_vm.lackedColumns}})],1):_vm._e(),(
          _vm.lackedPreprocessingColumns && _vm.lackedPreprocessingColumns.length > 0
        )?_c('div',{staticClass:"choosed-dataset-item"},[_c('texts',{staticClass:"choosed-dataset-columns-title",attrs:{"coler":"red","size":"large","text":_vm.$t(
              'inference.settings.selectInferenceType.choosedDataset.lackedPreprocessingColumns'
            )}}),_c('bullet-list',{attrs:{"items":_vm.lackedPreprocessingColumns}})],1):_vm._e(),(_vm.datasetColumns && _vm.datasetColumns.length > 0)?_c('div',{staticClass:"choosed-dataset-item-bottom"},[_c('texts',{staticClass:"choosed-dataset-columns-title",attrs:{"size":"large","text":this.$i18n.t(
              'inference.settings.selectInferenceType.choosedDataset.includeedColumuns'
            )}}),_c('bullet-list',{attrs:{"items":_vm.datasetColumns}})],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }