<template>
  <div class="add-new-service-input">
    <div class="add-new-service-input-body">
      <div class="add-new-service-input-block">
        <div class="add-new-service-input-inner">
          <input-box-validation
            v-model="name"
            :accept="validate.accept"
            :error="validate.error"
            :title="$t('serviceList.popup.addNewService.serviceName')"
            :placeholder="
              $t('serviceList.popup.addNewService.serviceNamePlaceholder')
            "
            popupView
            isNameValidation
            :nameValidationSuggests="serviceFormValidate.suggests"
          />
        </div>
        <div class="add-new-service-input-inner">
          <input-box
            v-model="description"
            isTextArea
            isGray
            :placeholder="
              $t(
                'serviceList.popup.addNewService.serviceDescriptionPlaceholder'
              )
            "
            :title="$t('serviceList.popup.addNewService.serviceDescription')"
          />
        </div>
      </div>
      <div class="add-new-service-input-button">
        <button-main
          type="emphasis"
          :isDisabled="disabledSubmit || disableClick"
          :text="$t('serviceList.popup.addNewService.submitButton')"
          @click="createService"
        />
        <button-main
          type="sub"
          :isDisabled="disableClick"
          :text="$t('common.cancel')"
          @click="$emit('cancel')"
        />
      </div>
    </div>
    <div class="add-new-service-settings">
      <div class="add-new-service-settings-inner">
        <div class="add-new-service-settings-item">
          <text-with-title
            class="add-new-service-settings-content"
            :title="$t('serviceList.popup.addNewService.recipeSelected')"
            :text="recipeDetail.name"
          />
          <text-with-title
            class="add-new-service-settings-content"
            :title="$t('inference.settings.navi.choosedItems.preprocess')"
            :text="
              targetEdaRecipe ? targetEdaRecipe.name : $t('common.notSelected')
            "
          />
        </div>
      </div>
      <div
        v-if="predictionColumns && predictionColumns.length > 0"
        class="add-new-service-settings-inner"
      >
        <texts
          class="add-new-service-settings-title"
          :text="$t('selectTargetColumn.menu.targetColumnInfo.title')"
          size="small"
          color="gray"
        />
        <div
          class="
            add-new-service-settings-item add-new-service-settings-item-array
          "
        >
          <div class="add-new-service-settings-item-array-inner">
            <template v-for="(column, index) in predictionColumns">
              <texts :key="index" :text="column" size="small" />
            </template>
          </div>
        </div>
      </div>
      <div
        v-if="inputColumns && inputColumns.length > 0"
        class="add-new-service-settings-inner"
      >
        <texts
          class="add-new-service-settings-title"
          :text="$t('service.learn.inputColumns')"
          size="small"
          color="gray"
        />
        <div
          class="
            add-new-service-settings-item add-new-service-settings-item-array
          "
        >
          <div class="add-new-service-settings-item-array-inner">
            <template v-for="(column, index) in inputColumns">
              <texts :key="index" :text="column" size="small" />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import buttonMain from '@/components/atoms/button-main'
import inputBox from '@/components/molecules/input-box'
import inputBoxValidation from '@/components/molecules/input-box-validation.vue'
import textWithTitle from '@/components/molecules/text-with-title'

export default {
  components: {
    buttonMain,
    inputBox,
    inputBoxValidation,
    textWithTitle
  },
  props: {
    disableClick: Boolean,
    inputColumns: Array,
    predictionColumns: Array,
    recipeDetail: Object,
    serviceFormValidate: Object,
    targetEdaRecipe: Object
  },
  data() {
    return {
      name: '',
      description: ''
    }
  },
  methods: {
    createService(e) {
      const res = {
        name: this.name,
        description: this.description
      }
      this.$emit('create-service', res)
    }
  },
  computed: {
    disabledSubmit() {
      if (this.name === '') return true
      if (this.validate.error) return true

      return false
    },
    validate() {
      const status = this.serviceFormValidate
      const { duplicate } = status
      return {
        accept: duplicate !== undefined ? duplicate : null,
        error: duplicate !== undefined ? !duplicate : null
      }
    }
  },
  watch: {
    name(val) {
      this.$emit('input-create-form', {
        type: 'service',
        form: {
          name: val
        }
      })
    }
  },
  mounted() {
    this.$emit('input-create-form', {
      type: 'service',
      form: {
        name: this.name
      }
    })
  }
}
</script>
<style lang="scss" scoped>
.add-new-service {
  &-input {
    display: grid;
    grid-template-columns: minmax(adjustVW(594), 1fr) adjustVW(966);
    grid-column-gap: $space-medium;
    width: 100%;
    height: 100%;
    &-block {
      margin-bottom: $space-large;
    }
    &-inner {
      margin: 0 0 $space-medium;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  &-settings {
    overflow-y: auto;
    @include scrollbar;
    &-inner {
      padding-bottom: $space-medium;
      margin-bottom: $space-medium;
      border-bottom: $border-sub;
      &:last-of-type {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 0;
      }
    }
    &-item {
      display: flex;
      flex-wrap: wrap;
      grid-column-gap: $space-medium;
      grid-row-gap: $space-base;
      &-array {
        padding: $space-small;
        background: $background-sub;
        border-radius: adjustVW(8);
        &-inner {
          overflow-y: auto;
          display: flex;
          flex-wrap: wrap;
          grid-column-gap: $space-medium;
          grid-row-gap: $space-base;
          width: 100%;
          max-height: adjustVW(180);
          padding-right: $space-base;
          @include scrollbar;
        }
      }
    }
    &-content {
      overflow: hidden;
      width: calc(50% - #{$space-medium / 2});
    }
    &-title {
      margin-bottom: $space-base;
    }
  }
}
</style>
