<template>
  <div
    v-if="calcError && Object.keys(calcError).length > 0"
    class="detail-conditions-calc"
  >
    <texts
      class="detail-conditions-calc-title"
      :text="$t('trainedAi.optimization.result.mesurementError')"
      size="min"
      color="gray"
    />
    <texts
      class="detail-conditions-calc-params"
      :text="calcError.params"
      :color="calcError.params === '+' ? 'emphasis' : 'green'"
      size="min"
    />
    <texts
      :text="calcError.value"
      size="min"
      :color="calcError.params === '+' ? 'emphasis' : 'green'"
    />
  </div>
</template>

<script>
export default {
  props: {
    entry: Object
  },
  computed: {
    calcError() {
      if (!this.entry) return {}
      const value = this.entry.value
      const condition = this.entry?.conditions?.condition
      if (condition?.type !== 'closer') return {}
      const base = condition.params.target
      const error = {
        value: 0,
        params: null
      }
      error.value = value - base
      if (error.value > 0) {
        error.params = '+'
      } else if (error.value < 0) {
        error.params = 'ｰ'
        error.value = error.value * -1
      }
      return error
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-conditions-calc {
  display: flex;
  align-items: center;
  grid-column-gap: adjustVW(2);
  height: adjustVW(24);
  &-params {
    flex-shrink: 0;
  }
  &-title {
    flex-shrink: 0;
    margin-right: $space-text;
  }
}
</style>
