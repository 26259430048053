<template>
  <page-top
    :title="name"
    iconName="backward"
    notScroll
    isBackButton
  >
    <div class="dataset-top-func">
      <!--tabkei-->
      <tab-list
        v-if="componentPageName !== 'timeSeries'"
        :tabs="tableChangeTabs.tabs"
        :activeTab="activeTab"
        @change-tab="$emit('change-data-tab', $event)"
      />
      <!-- timeSeriesになることがなさそうなので無視。将来的にはここで時系列のグラフを表示したい -->
      <tab-list
        v-else
        :tabs="timeSerieseTabs"
        :activeTab="activeTab"
        @change-tab="$emit('change-data-tab', $event)"
        @select-item="$emit('select-item', $event)"
      />
      <div />
      <text-menu-list
        :menus="datasetMenu.tabs"
        :activeMenu="activeMenu"
        checked
        isMenuShow
        @menu-click="$emit('dataset-menu-click', $event)"
      />
    </div>
  </page-top>
</template>

<script>
import pageTop from '@/components/atoms/page-top'
import tabList from '@/components/molecules/tab-list'
import textMenuList from '@/components/molecules/text-menu-list'

export default {
  components: {
    pageTop,
    tabList,
    textMenuList
  },
  data() {
    // 時系列用のタブ
    return {
      timeSerieseTabs: [
        {
          id: 0,
          name: this.$t('datasetDetail.dataTabs.showDataset'),
          value: 'dataset'
        },
        {
          id: 1,
          name: this.$t('datasetDetail.dataTabs.statistics'),
          value: 'statistics',
          options: {
            items: [
              {
                id: 0,
                name: this.$t('datasetDetail.dataTabs.showDataset'),
                value: 'statistics'
              },
              {
                id: 1,
                name: this.$t('datasetDetail.dataTabs.DisplayTimeSeriesGraph'),
                value: 'timeseries'
              }
            ],
            isActive: false,
            selectItem: ''
          }
        }
      ]
    }
  },
  props: {
    name: String,
    summary: Object,
    componentPageName: String,
    tabs: Array,
    tableChangeTabs: Object,
    activeTab: Number,
    activeMenu: String,
    datasetMenu: Object,
    type: String
  }
}
</script>

<style lang="scss" scoped>
.dataset-top-func {
  display: flex;
  justify-content: space-between;
}

.tab-item {
  padding: $space-base;
  margin: 0;
  border-bottom: $border-transparent;
}
</style>
