<template>
  <features-compare-graph
    class="features-graph-card-transition-item"
    :features="features"
    :maxCount="maxCount"
    :loading="loading"
    :selectedColumnIndex="selectedColumnIndex"
  />
</template>

<script>
import featuresCompareGraph from './features-compare-graph.vue'

export default {
  components: {
    featuresCompareGraph
  },
  props: {
    highInportanceVariables: Object,
    features: Object,
    maxCount: Number,
    viewType: String,
    loading: Boolean,
    selectedColumnIndex: Number
  }
}
</script>

<style lang="scss" scoped>
.features-graph-card-transition-item {
  overflow-y: hidden;
  height: 100%;
}
</style>
