<template>
  <popup-array
    :showPopup="showPopup"
    :popupDefs="popupDefs"
    :noClose="disableClick || showPopup.includes('preventTrainingStatus')"
    @close-modal="$emit('close-modal', $event)"
    @popup-inner-click="popupInnerClick"
  >
    <template #applyAutoPreprocess>
      <popup-apply-auto-preprocess
        @close-modal="$emit('close-modal', 'applyAutoPreprocess')"
        @apply-auto-preprocess="$emit('apply-auto-preprocess')"
      />
    </template>

    <template #saveDataset>
      <popup-save-dataset
        :appliedChart="appliedChart"
        :autoPreprocessingApplied="autoPreprocessingApplied"
        :dataset="dataset"
        :datasetFormValidate="createInfo.datasetFormValidate"
        :datasetName="datasetName"
        :preprocFormValidate="createInfo.preprocFormValidate"
        :preProcHeadVersion="preProcHeadVersion"
        :disableClick="disableClick"
        :preprocessingList="preprocessingList"
        @close-modal="$emit('close-modal', 'saveDataset')"
        @input-create-form="$emit('input-create-form', $event)"
        @save-dataset="$emit('save-dataset', $event)"
      />
      <div v-if="disableClick && isLargeCSV">
        <!-- 大容量の場合に時間がかかるので、データセットアップロード時と同様の表示をさせる -->
        <wait-setting-dataset
          :progressSettingDataset="0"
          :progressSettingDatasetMax="0"
          :progressStatus="'preparingData'"
          :uploadLearningDataWarnings="{}"
          isLargeCsv
        />
      </div>
    </template>
    <template #savePreprocessing>
      <popup-save-preprocessing
        ref="savePreprocessing"
        :appliedChart="appliedChart"
        :autoPreprocessingApplied="autoPreprocessingApplied"
        :datasetName="datasetName"
        :preprocFormValidate="createInfo.preprocFormValidate"
        :preProcHeadVersion="preProcHeadVersion"
        :disableClick="disableClick"
        @close-modal="$emit('close-modal', 'savePreprocessing')"
        @input-create-form="$emit('input-create-form', $event)"
        @save-preprocessing="$emit('save-preprocessing', $event)"
      />
    </template>
    <template #resetPreprocessing>
      <popup-reset-preprocessing
        :disableClick="disableClick"
        @close-modal="$emit('close-modal', 'resetPreprocessing')"
        @reset-preprocessing-confirm="$emit('reset-preprocessing-confirm')"
      />
    </template>
    <template #datasetSizeExceedCaution>
      <popup-dataset-size-exceed-caution
        v-bind="datasetSizeWarning"
        @close-modal="$emit('close-modal', 'datasetSizeExceedCaution')"
        @go-dataset-list-page="$emit('go-dataset-list-page')"
      />
    </template>
    <!-- 未使用ポップアップ 削除予定 -->
    <template #caution>
      <popup-caution
        :columnName="objectiveColumn"
        @close-modal="$emit('close-modal', 'caution')"
        @back-column-select="$emit('back-column-select')"
      />
    </template>
    <template #preventTrainingStatus>
      <prevent-training-status @not-prevent="$emit('not-prevent')" />
    </template>
    <template #noAutoPreprocessing>
      <describe-of-error
        :description="$t('preprocessing.popup.noAutoPreprocessing.description')"
        :okButton="$t('preprocessing.popup.noAutoPreprocessing.backDataset')"
        :cancelButton="$t('preprocessing.popup.noAutoPreprocessing.close')"
        @ok="$emit('go-dataset-list-page')"
        @cancel="$emit('close-modal', 'noAutoPreprocessing')"
      />
    </template>
    <template #noAutoPreprocessingInTraining>
      <describe-of-error
        :description="
          $t('preprocessing.popup.noAutoPreprocessing.descriptionInTraining')
        "
        :okButton="$t('common.close')"
        @ok="$emit('close-modal', 'noAutoPreprocessingInTraining')"
      />
    </template>
  </popup-array>
</template>

<script>
import popupApplyAutoPreprocess from '@/components/organisms/preprocessing-apply/popup/apply-auto-preprocess.vue'
import popupDatasetSizeExceedCaution from '@/components/organisms/preprocessing-apply/popup/dataset-size-exceed-caution.vue'
import popupSaveDataset from './popup/save-dataset'
import popupSavePreprocessing from './popup/save-preprocessing'
import popupResetPreprocessing from './popup/reset-preprocessing'
import popupCaution from './popup/caution'
import preventTrainingStatus from '@/components/organisms/common-popups/prevent-training-status.vue'
import PopupArray from '@/components/molecules/popup/popup-array.vue'
import waitSettingDataset from '@/components/organisms/wait-setting-dataset'
import describeOfError from '@/components/organisms/common-popups/describe-of-error.vue'

export default {
  components: {
    popupApplyAutoPreprocess,
    popupDatasetSizeExceedCaution,
    popupSaveDataset,
    popupSavePreprocessing,
    popupResetPreprocessing,
    popupCaution,
    preventTrainingStatus,
    PopupArray,
    waitSettingDataset,
    describeOfError
  },
  props: {
    appliedChart: Object,
    autoPreprocessingApplied: Boolean,
    createInfo: Object,
    dataset: Object,
    datasetName: String,
    datasetSizeWarning: Object,
    preProcHeadVersion: Number,
    showPopup: Array,
    stopMove: Object,
    disableClick: Boolean,
    preprocessingList: Object,
    objectiveColumn: {
      type: Array,
      default: () => []
    },
    isLargeCSV: Boolean
  },
  computed: {
    popupDefs() {
      return {
        applyAutoPreprocess: {
          title: this.$t('preprocessing.popup.applyAutoPreprocess.title')
        },
        saveDataset: {
          title: this.$t('preprocessing.applyingDataset.saveDataset'),
          maxWidth: true,
          maxHeight: true
        },
        savePreprocessing: {
          title: this.$t(
            'preprocessing.applyingDataset.savePreprocessingButton'
          ),
          maxWidth: true,
          maxHeight: true
        },
        resetPreprocessing: {
          title: this.$t('preprocessing.resetPreprocessingConfirm')
        },
        caution: {
          title: this.$t('preprocessing.applyingDataset.cautionPopup.title')
        },
        datasetSizeExceedCaution: {
          title: this.datasetSizeWarning?.afterSave
            ? this.$t(
                'preprocessing.popup.datasetSizeExceedCaution.afterSave.title'
              )
            : this.$t(
                'preprocessing.popup.datasetSizeExceedCaution.beforeSave.title'
              )
        },
        preventTrainingStatus: {
          title: this.$t('common.popup.preventTrainingStatus.title')
        },
        noAutoPreprocessing: {
          title: this.$t('preprocessing.popup.noAutoPreprocessing.title')
        },
        noAutoPreprocessingInTraining: {
          title: this.$t('preprocessing.popup.noAutoPreprocessing.title')
        }
      }
    }
  },
  methods: {
    popupInnerClick() {
      if (
        !this.showPopup ||
        this.showPopup.length === 0 ||
        !this.showPopup.find((x) => x === 'savePreprocessing')
      )
        return
      this.$refs.savePreprocessing.closePreprocessingDetail()
    }
  }
}
</script>
