<template>
  <card-base class="c-card">
    <div class="choosed-dataset-wrapper">
      <div class="choosed-dataset-item choosed-dataset-title">
        <texts
          size="large"
          :text="
            this.$i18n.t(
              'inference.settings.selectInferenceType.choosedDataset.title'
            )
          "
        />
      </div>

      <div v-if="choosedDatasetInfo" class="choosed-dataset-item">
        <texts
          class="choosed-dataset-name"
          :text="choosedDatasetInfo.name"
          isBold
        />
        <description
          class="choosed-dataset-about"
          size="small"
          :text="choosedDatasetInfo.description"
        />
      </div>

      <div class="choosed-dataset-columns">
        <div
          v-if="lackedColumns && lackedColumns.length > 0"
          class="choosed-dataset-item"
        >
          <texts
            class="choosed-dataset-columns-title"
            coler="red"
            size="large"
            :text="
              this.$i18n.t(
                'inference.settings.selectInferenceType.choosedDataset.lackedColumns'
              )
            "
          />
          <bullet-list :items="lackedColumns" />
        </div>

        <div
          v-if="
            lackedPreprocessingColumns && lackedPreprocessingColumns.length > 0
          "
          class="choosed-dataset-item"
        >
          <texts
            class="choosed-dataset-columns-title"
            coler="red"
            size="large"
            :text="
              $t(
                'inference.settings.selectInferenceType.choosedDataset.lackedPreprocessingColumns'
              )
            "
          />
          <bullet-list :items="lackedPreprocessingColumns" />
        </div>

        <div
          v-if="datasetColumns && datasetColumns.length > 0"
          class="choosed-dataset-item-bottom"
        >
          <texts
            class="choosed-dataset-columns-title"
            size="large"
            :text="
              this.$i18n.t(
                'inference.settings.selectInferenceType.choosedDataset.includeedColumuns'
              )
            "
          />
          <bullet-list :items="datasetColumns" />
        </div>
      </div>
    </div>
  </card-base>
</template>

<script>
import bulletList from '@/components/molecules/bullet-list.vue'
import cardBase from '@/components/atoms/card-base.vue'
import texts from '@/components/atoms/text.vue'
import description from '@/components/atoms/description.vue'

export default {
  components: {
    bulletList,
    cardBase,
    texts,
    description
  },
  props: {
    preprocessingColumns: Object,
    choosedDatasetInfo: Object,
    lackedColumns: Array,
    lackedPreprocessingColumns: Array
  },
  computed: {
    datasetColumns() {
      return this.choosedDatasetInfo?.columns
    }
  }
}
</script>

<style lang="scss" scoped>
.c-card {
  height: 100%;
}

.choosed-dataset {
  &-wrapper {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  &-item {
    width: 100%;
    margin-bottom: $space-medium;
  }
  &-item-bottom {
    width: 100%;
  }
  &-name {
    margin: 0 0 $space-min;
  }
  &-columns {
    overflow-y: auto;
    height: 100%;
    padding: 0 $space-base 0 0;
    @include scrollbar;
  }
  &-title {
    margin-bottom: $space-small;
  }
  &-columns-title {
    margin-bottom: $space-base;
  }
}
</style>
