<template>
  <card-base class="c-card">
    <div v-if="!isTimeTransformerV2Setting" class="need-columns-wrapper">
      <div class="need-columns-item need-columns-title">
        <texts
          size="large"
          :text="
            this.$i18n.t('inference.settings.selectDataset.requiredColumns')
          "
        />
      </div>

      <bullet-list class="need-columns-scroll" :items="columnsItems" />
    </div>
    <div v-else>
      <div v-if="isKnownColumns.length > 0" class="need-columns-block">
        <div class="need-columns-item time-transformer-title">
          <texts
            size="large"
            :text="
              $t(
                'inference.settings.selectDataset.timeTransformerV2.isKnown.title'
              )
            "
          />
          <div
            v-tooltip="
              $t(
                'inference.settings.selectDataset.timeTransformerV2.isKnown.description'
              )
            "
            class="time-transformer-info"
          >
            <icons iconName="info" size="small" />
          </div>
        </div>
        <bullet-list class="need-columns-scroll" :items="isKnownColumns" />
      </div>
      <div v-if="isNotKnownColumns.length > 0" class="need-columns-block">
        <div class="need-columns-item time-transformer-title">
          <texts
            size="large"
            :text="
              $t(
                'inference.settings.selectDataset.timeTransformerV2.isNotKnown.title'
              )
            "
          />
          <div
            v-tooltip="
              $t(
                'inference.settings.selectDataset.timeTransformerV2.isNotKnown.description'
              )
            "
            class="time-transformer-info"
          >
            <icons iconName="info" size="small" />
          </div>
        </div>
        <bullet-list class="need-columns-scroll" :items="isNotKnownColumns" />
      </div>
    </div>
  </card-base>
</template>

<script>
import bulletList from '@/components/molecules/bullet-list.vue'
import cardBase from '@/components/atoms/card-base.vue'
import texts from '@/components/atoms/text.vue'
import icons from '@/components/atoms/icon.vue'

export default {
  components: {
    bulletList,
    cardBase,
    texts,
    icons
  },
  props: {
    columns: Array,
    timeTransformerV2Settings: Object
  },
  computed: {
    columnsItems() {
      return this.columns.map((item) => {
        return {
          text: item
        }
      })
    },
    isKnownColumns() {
      if (
        this.timeTransformerV2Settings == null ||
        this.timeTransformerV2Settings.isKnown.length === 0
      )
        return []
      return this.timeTransformerV2Settings.isKnown.map((column) => {
        return {
          text: column
        }
      })
    },
    isNotKnownColumns() {
      if (
        this.timeTransformerV2Settings == null ||
        this.timeTransformerV2Settings.isNotKnown.length === 0
      )
        return []
      return this.timeTransformerV2Settings.isNotKnown.map((column) => {
        return {
          text: column
        }
      })
    },
    isTimeTransformerV2Setting() {
      return this.isKnownColumns.length > 0 || this.isNotKnownColumns.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
.c-card {
  max-height: calc(50% - #{$space-sub});
}

.need-columns {
  &-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-scroll {
    overflow-y: auto;
    @include scrollbar;
  }
  &-item {
    width: 100%;
    margin-bottom: $space-small;
  }
  &-block {
    &:not(:last-of-type) {
      padding-bottom: $space-small;
      margin-bottom: $space-small;
      border-bottom: $border-sub;
    }
  }
}

.need-columns-title {
  margin-bottom: $space-base;
}

.time-transformer {
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $space-base;
  }
}
</style>
