<!-- comparison-graph-detail-largeと共通化したいが、色の判定など異なる箇所が多いのでわけている -->
<template>
  <div
    :id="`scatter${$_uid}`"
    ref="scatter"
    v-resize:throttle.100="resized"
    class="scatter-plot"
  >
    <slot />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import * as d3 from 'd3'
import d3tip from 'd3-tip'
import * as Stardust from 'stardust-core'
import 'stardust-webgl'

import resize from 'vue-resize-directive'
export default {
  directives: {
    resize
  },
  name: 'StarScatter',
  props: {
    data: Array,
    xLabel: { type: String, default: 'X' },
    yLabel: { type: String, default: 'Y' },
    colorScale: { type: Array, default: () => [] },
    cautionValue: { type: Number, default: null },
    targetValueKey: { type: String, default: null },
    showDetailTargetRange: { type: Array, default: () => [] },
    minMaxValue: { type: Object, default: () => {} }
  },
  mounted() {
    this.init()
    this.$nextTick(() => this.show2D())
  },
  data() {
    return {
      zoomRatio: 1,
      resizeCooltime: false,

      svg: null,
      root: null,
      area: null,
      platform: null,
      tip: null,
      xScale: null,
      yScale: null,
      xAxis: null,
      yAxis: null,
      gx: null,
      gy: null,

      showingTip: null,

      margin: { top: 10, right: 10, bottom: 40, left: 0 },
      computedMarginLeft: 40,
      showDetailTarget: null,
      transitionDuration: 150
    }
  },
  computed: {
    resizeParams() {
      return { data: this.data, xLabel: this.xLabel, yLabel: this.yLabel }
    }
  },
  methods: {
    init() {
      const margin = this.margin
      const el = this.$refs.scatter
      const outerWidth = el.clientWidth
      const outerHeight = el.clientHeight
      const width = Math.max(outerWidth - margin.left - margin.right, 100)
      const height = Math.max(outerHeight - margin.top - margin.bottom, 100)
      const svg = d3
        .select('#scatter' + this.$_uid)
        .append('svg')
        .classed('scatter-main', true)
        .attr('width', outerWidth)
        .attr('height', outerHeight)
        .attr('focusable', true)
        .on('focus', function () {
          svg.style('pointer-events', 'all')
        })
        .on('click', function (...args) {
          svg.style('pointer-events', 'all')
        })
        .on('blur', function () {
          svg.style('pointer-events', 'unset')
        })
      this.svg = svg
      this.root = svg
        .append('g')
        .attr('transform', 'translate(' + 0 + ',' + margin.top + ')')

      const xScale = d3.scaleLinear().range([0, width]).nice()
      const yScale = d3.scaleLinear().range([height, 0]).nice()
      this.xScale = xScale
      this.yScale = yScale

      const xAxis = d3.axisBottom(xScale).tickSize(-height)
      const yAxis = d3.axisLeft(yScale).tickSize(0)
      this.xAxis = xAxis
      this.yAxis = yAxis

      const gx = this.root
        .append('g')
        .classed('x axis d3-axis', true)
        .call(this.xAxis)

      const xLabel = gx.append('text').classed('label', true)

      const gy = this.root
        .append('g')
        .classed('y axis d3-axis', true)
        .call(this.yAxis)
      this.gx = gx
      this.gy = gy

      gx.append('svg:line')
        .classed('axisLine v-axis-line', true)
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', -height)

      gy.append('svg:line')
        .classed('axisLine h-axis-line', true)
        .attr('x1', 0)
        .attr('y1', width)
        .attr('x2', width)
        .attr('y2', width)

      const yLabel = gy
        .append('text')
        .classed('label', true)
        .attr('transform', 'rotate(-90)')

      this.tip = d3tip()
        .attr('class', 'd3-tip')
        .style('z-index', 100)
        .offset([-10, 0])
        .html(this.generateTip)

      this.zoomBeh = d3
        .zoom()
        .scaleExtent([0, 500])
        .on('zoom', () => this.zoomed())
      this.root.call(this.tip)
      this.root.call(this.zoomBeh)

      this.area = d3
        .select('#scatter' + this.$_uid)
        .append('canvas')
        .classed('area', true)
        .attr('width', width)
        .attr('height', height)
        .style('left', `0px`)
        .style('top', `${margin.top}px`)

      const vueThis = this
      this.root.node().onmousemove = function (...args) {
        vueThis.onmousemove(...args, this)
      }
      this.root.node().onclick = function (...args) {
        vueThis.clicked(...args, this)
      }
      this.resized()
      this.zoomBeh.scaleTo(this.root, 1)
    },
    zoomed() {
      const zoomRatio = (this.zoomRatio = d3.event.transform.k)
      this.gx.call(this.xAxis.scale(d3.event.transform.rescaleX(this.xScale)))
      this.gy.call(this.yAxis.scale(d3.event.transform.rescaleY(this.yScale)))

      this.recalcYAxis()
      this.drawPoints(
        this.data,
        d3.event.transform.rescaleX(this.xScale),
        d3.event.transform.rescaleY(this.yScale)
      )
    },
    resized() {
      const margin = this.margin
      const el = this.$refs.scatter

      const outerWidth = el.clientWidth
      const outerHeight = el.clientHeight
      const width = Math.max(outerWidth - margin.right, 1)
      const height = Math.max(outerHeight - margin.top - margin.bottom, 1)

      this.root.attr('transform', 'translate(' + 0 + ',' + margin.top + ')')

      this.svg.attr('width', outerWidth).attr('height', outerHeight)

      this.xScale.range([0, width])
      this.yScale.range([height, 0])
      this.xAxis.tickSize(-height)
      this.yAxis.tickSize(-width)

      this.gx.attr('transform', 'translate(0,' + height + ')')

      this.gx
        .select('.v-axis-line')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', -height)

      this.gy
        .select('.h-axis-line')
        .attr('x1', 0)
        .attr('y1', width)
        .attr('x2', width)
        .attr('y2', width)

      this.gx
        .select('.label')
        .attr('x', width / 2)
        .attr('y', margin.bottom)
        .text(this.xLabel)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'text-after-edge')
        .attr('fill', 'black')

      this.recalcYAxis()

      this.area.attr('width', width).attr('height', height)

      const canvas = this.area.node()
      this.platform = Stardust.platform('webgl-2d', canvas, width, height)

      this.zoomBeh.translateBy(this.root, 0, 0)
    },
    recalcYAxis() {
      const margin = this.margin
      const minMarginLeft = d3.max(
        this.gy
          .selectAll('.tick text')
          .nodes()
          .map((x) => x.getBBox().width)
      )
      this.computedMarginLeft = Math.max(margin.left, minMarginLeft)
      this.area.style('left', `0px`)
    },
    findPlatform(e) {
      const canvas = this.area.node()
      const x = e.clientX - canvas.getBoundingClientRect().left
      const y = e.clientY - canvas.getBoundingClientRect().top
      const platform = this.platform
      const p = platform.getPickingPixel(
        x * platform.pixelRatio,
        y * platform.pixelRatio
      )
      return p
    },
    clicked(e) {
      const p = this.findPlatform(e)
      if (p == null) {
        this.hideTip()
      } else {
        const [mark, i] = p
        this.$emit('click', mark._data[i], i)
        this.showDetailTarget = mark._data[i].index
      }
    },
    onmousemove(e) {
      const svg = this.svg.node()
      const offsetx = e.clientX - svg.getBoundingClientRect().left
      const offsety = e.clientY - svg.getBoundingClientRect().top
      const p = this.findPlatform(e)
      if (p == null) {
        this.hideTip()
      } else {
        const [mark, i] = p
        this.showTip(mark._data[i], i, [offsetx, offsety])
      }
    },
    showTip(d, i, [x, y]) {
      if (this.showingTip != null) {
        this.hideTip()
      }
      this.$emit('show-detail-once', d)
      this.showDetailTarget = d.index
      const el = this.svg
        .append('g')
        .classed('dummy', true)
        .style('pointer-events', 'none')
        .attr('transform', 'translate(' + x + ',' + (y - 2) + ')')
        .node()
      this.showingTip = [el, d, i, null]
      this.tip.show.call(...this.showingTip)
    },
    hideTip() {
      if (!this.showingTip) return
      this.tip.hide.call(...this.showingTip)
      this.showingTip[0].remove()
      this.showingTip = null
    },
    generateTip(d) {
      return `
        <p>${this.$t('datasetDetail.tableSide.row')}: ${d.index + 1}</p>
        <p>${this.xLabel}: ${d[this.targetValueKey]}</p>
        <p>${this.yLabel}: ${d.residualError}</p>
      `
    },
    setColor(d, i) {
      const rgbBase = d3.rgb('#DBB4DE')
      const rgbCaution = d3.rgb('#DD493F')
      const rgbClick = d3.rgb('#850491')
      if (this.showDetailTarget && d.index === this.showDetailTarget) {
        return [rgbClick.r / 255, rgbClick.g / 255, rgbClick.b / 255, 1]
      }
      if (this.showDetailTargetRange && this.showDetailTargetRange.length > 0) {
        const check =
          this.showDetailTargetRange[0] <= d.residualError &&
          this.showDetailTargetRange[1] >= d.residualError
        if (check) {
          return [rgbClick.r / 255, rgbClick.g / 255, rgbClick.b / 255, 1]
        }
      }
      if (!this.cautionValue)
        return [rgbBase.r / 255, rgbBase.g / 255, rgbBase.b / 255, 0.8]
      if (Math.abs(d.residualError) >= this.cautionValue) {
        return [rgbCaution.r / 255, rgbCaution.g / 255, rgbCaution.b / 255, 0.8]
      } else {
        return [rgbBase.r / 255, rgbBase.g / 255, rgbBase.b / 255, 0.8]
      }
    },
    drawPoints(data, xs, ys) {
      const vueThis = this
      if (!xs) {
        xs = vueThis.xScale
      }
      if (!ys) {
        ys = vueThis.yScale
      }
      const x = function (d, i) {
        const targetValueKey = d[vueThis.targetValueKey]
        if (targetValueKey == null || Number.isNaN(targetValueKey)) {
          return null
        }
        return xs(targetValueKey)
      }
      const y = function (d, i) {
        const residualError = d.residualError
        if (residualError == null || Number.isNaN(residualError)) {
          return null
        }
        return ys(residualError)
      }
      const platform = vueThis.platform
      const canvas = this.area.node()
      platform.clear()

      // データの点の描画
      const circleSpec = Stardust.mark.circle()
      const circle = Stardust.mark.create(circleSpec, vueThis.platform)
      circle.attr('center', (d) => [x(d), y(d)])
      circle.attr('radius', 6)
      circle.attr('color', vueThis.setColor)

      circle.data(vueThis.data)
      circle.render()

      platform.beginPicking(canvas.width, canvas.height)
      circle.render()
      platform.endPicking()
    },
    show2D() {
      const vueThis = this

      this.xScale
        .domain([vueThis.minMaxValue.xMin, vueThis.minMaxValue.xMax])
        .nice()
      this.yScale
        .domain([vueThis.minMaxValue.yMax, vueThis.minMaxValue.yMin])
        .nice()

      this.resized()
    }
  },
  watch: {
    resizeParams() {
      this.resized()
    },
    computedMarginLeft(nv, ov) {
      if (nv !== ov) this.resized()
    },
    showDetailTarget() {
      this.resized()
    },
    cautionValue() {
      this.resized()
    },
    showDetailTargetRange(newVal) {
      if (newVal.length > 0) {
        this.showDetailTarget = null
      }
      this.resized()
    }
  },
  beforeDestroy() {
    d3.select('.scatter-plot').attr('style', 'pointer-events: none;')
  },
  destroyed() {
    this.tip.destroy()
  }
}
</script>
<style scoped>
.scatter-plot {
  min-width: 100px;
  min-height: 100px;
}
</style>
<style lang="scss">
/* stylelint-disable selector-no-qualifying-type */
.scatter-plot {
  .tick {
    user-select: none;
  }

  .h-axis-line {
    opacity: 0;
    fill: none;
    stroke: $border-gray;
  }

  .v-axis-line {
    opacity: 0;
    fill: none;
    stroke: $border-gray;
  }

  text.label {
    font-size: 1.4rem;
    white-space: pre-line;
  }
  .dot:not([fill]) {
    opacity: 0.5;
    fill: $key-color;
  }
}
.scatter-tip-image {
  max-width: 200px;
  max-height: 200px;
}

svg.scatter-main {
  position: relative;
  z-index: 2;
}

canvas.area {
  position: absolute;
  z-index: 1;
}

svg.scatter-main:focus rect {
  stroke: $gray;
}

.scatter-modal {
  width: min-content;
  max-width: unset;
}
</style>
