import { render, staticRenderFns } from "./regression-result-info.vue?vue&type=template&id=1124de17&scoped=true&"
import script from "./regression-result-info.vue?vue&type=script&lang=js&"
export * from "./regression-result-info.vue?vue&type=script&lang=js&"
import style0 from "./regression-result-info.vue?vue&type=style&index=0&id=1124de17&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1124de17",
  null
  
)

export default component.exports