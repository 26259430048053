<!--
  チェックボックスを並べて表示する
-->
<template>
  <div class="checkbox-list">
    <template v-for="(value, index) in values">
      <checkbox-base
        :key="index"
        :text="value"
        :checked="checkedNames.indexOf(value) !== -1"
        @input="updateValues(value, $event)"
      />
    </template>
  </div>
</template>

<script>
import checkboxBase from '@/components/atoms/checkbox-base'
export default {
  components: {
    checkboxBase
  },
  data() {
    return {
      checkedNames: this.checked !== '' ? this.checked : []
    }
  },
  props: {
    values: Array, // チェックボックスに表示するテキストと値の配列
    checked: Array // チェックされた状態のリスト
  },
  model: { prop: 'checked', event: 'change' }, // v-modelにしたとき、結びつける先のpropとイベント
  methods: {
    updateValues(value) {
      if (this.checkedNames.indexOf(value) === -1) {
        this.checkedNames.push(value)
      } else {
        this.checkedNames = this.checkedNames.filter((x) => x !== value)
      }
      this.$emit('change', this.checkedNames)
    }
  },
  watch: {
    checked(newVal) {
      this.checkedNames = newVal
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox-list {
  display: flex;
  flex-wrap: wrap;
}
</style>
