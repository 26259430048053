<template>
  <result-text-card
    ref="parent"
    class="text-vectorization-card"
    :itemName="filename"
    :text="body"
    :reliabilities="reliabilities"
    :posBottom="posBottom"
    :posRight="posRight"
    @show-popup="closeListPopup"
  >
    <div class="vectorization-similarity-wrapper">
      <texts
        class="vectorization-similarity-title"
        size="small"
        :text="this.$i18n.t('inference.result.vectorization.text.similarity')"
      />

      <div class="vectorization-similarity-text-list">
        <bullet-list-show-popup
          ref="list"
          :items="convertedSimilarities"
          :posBottom="posBottom"
          :posRight="posRight"
          isOrderly
          @show-popup="closeParentPopup"
        />
      </div>
    </div>
  </result-text-card>
</template>

<script>
import resultTextCard from '@/components/molecules/inference/result/result-text-card.vue'
import texts from '@/components/atoms/text.vue'
import bulletListShowPopup from '@/components/molecules/inference/result/bullet-list-show-popup.vue'

export default {
  components: {
    bulletListShowPopup,
    resultTextCard,
    texts
  },
  data() {
    return {
      maxCount: 1.0
    }
  },
  computed: {
    convertedSimilarities() {
      return this.similarities?.map((item) => ({
        text: this.bodyList[item.index],
        name: item.filename
      }))
    }
  },
  props: {
    filename: String,
    body: String,
    similarities: Array,
    reliabilities: Array,
    posBottom: {
      type: Boolean,
      default: false
    },
    posRight: {
      type: Boolean,
      default: false
    },
    bodyList: Array
  },
  methods: {
    closeParentPopup() {
      this.$refs.parent.closePopup()
    },
    closeListPopup() {
      this.$refs.list.closePopup()
    }
  }
}
</script>

<style lang="scss" scoped>
.text-vectorization-card {
  width: 100%;
  height: 100%;
}

.vectorization-similarity {
  &-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}

.vectorization-similarity-title {
  margin-bottom: $space-base;
}

.vectorization-similarity-text-list {
  width: 100%;
  height: adjustVH(238);
}
</style>
