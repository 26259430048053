<template>
  <div v-if="!servicesLoading" class="service-wrap">
    <div class="service-top">
      <div class="service-desc">
        <texts
          class="service-desc-title"
          :text="$t('service.types.infer')"
          size="large"
        />
        <texts :text="$t('serviceList.typeDescription.infer')" size="small" />
      </div>
      <div class="service-func">
        <button-main
          class="service-button"
          :text="$t('serviceList.popup.addNewService.title.infer')"
          type="emphasis"
          @click="$emit('add-new-service')"
        />
        <fuse-search
          class="service-search"
          :option="option"
          :value="servicesList"
          :placeholder="this.$t('serviceList.searchService')"
          @input="search($event)"
        />
      </div>
    </div>
    <table-list
      v-model="internalChecked"
      is-button
      :field="serviceListFields"
      :items="searchArray ? searchArray : servicesList"
      :noSearchResults="noSearchResults"
      defaultSortedBy="updatedAt"
      id-column="serviceId"
      @list-click="listClicked"
    />
  </div>
  <loading v-else />
</template>
<script>
import buttonMain from '@/components/atoms/button-main'
import fuseSearch from '@/components/molecules/fuse-search'
import tableList from '@/components/molecules/table-list'
import loading from '@/components/atoms/loading.vue'

export default {
  name: 'ServiceListTabLearning',
  components: {
    buttonMain,
    fuseSearch,
    tableList,
    loading
  },
  model: {
    prop: 'checked',
    event: 'change'
  },
  data() {
    return {
      searchArray: null,
      noSearchResults: false
    }
  },
  props: {
    option: Object,
    projectId: Number,
    services: Array,
    servicesLoading: Boolean,
    checked: Array
  },
  computed: {
    internalChecked: {
      get() {
        return this.checked
      },
      set(v) {
        this.$emit('change', v)
      }
    },
    servicesList() {
      return this.services?.map((item) => ({
        ...item,
        model: item.trainedAI
          ? {
              name: item.trainedAI.name,
              link: {
                name: 'trainedAiDetail',
                params: {
                  id: item.trainedAI.id
                }
              }
            }
          : {}
      }))
    },
    serviceListFields() {
      return [
        {
          id: 0,
          key: 'name',
          label: this.$t('serviceList.header.serviceName'),
          thClass: 'name',
          listType: 'name',
          sortable: true
        },
        {
          id: 1,
          key: 'model',
          label: this.$t('serviceList.header.trainedAI'),
          thClass: 'large',
          listType: 'link',
          sortable: false
        },
        {
          id: 2,
          key: 'serviceOwner',
          label: this.$t('common.owner'),
          thClass: 'large',
          listType: 'default',
          sortable: true
        },
        {
          id: 3,
          key: 'createdAt',
          label: this.$t('common.createTime'),
          thClass: 'default',
          listType: 'time',
          sortable: true
        },
        {
          id: 4,
          key: 'updatedAt',
          label: this.$t('common.updateTime'),
          thClass: 'default',
          listType: 'time',
          sortable: true
        }
      ]
    }
  },
  methods: {
    listClicked(e) {
      this.$router.push({
        name: 'serviceProjectDetail',
        params: {
          serviceType: 'infer',
          id: e.serviceId,
          projectId: this.projectId
        }
      })
    },
    search(e) {
      this.searchArray = e.result
      this.noSearchResults = e.noSearchResults
    }
  }
}
</script>

<style lang="scss" scoped>
.service {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-base;
    height: 100%;
    background: $background;
  }
  &-top {
    padding: $space-medium $space-medium 0 $space-medium;
    margin-bottom: -$space-base;
  }
  &-desc {
    margin-bottom: adjustVW(20);
    &-title {
      margin-bottom: $space-text;
    }
  }
  &-func {
    display: flex;
    grid-column-gap: $space-medium;
    padding-bottom: adjustVW(28);
    border-bottom: $border-main;
  }
}
</style>
