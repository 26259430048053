import { duplicateChecker, duplicateSuggests } from './misc'

/**
 * @param { Object[] } recipeList
 * @param { Object } newRecipe
 * @returns { Object }
 **/
export function recipeValidator(recipes, newRecipe) {
  const recipeKeys = Object.keys(recipes)

  const nameList =
    recipeKeys.length > 0 ? recipeKeys.map((key) => recipes[key].name) : []
  const newRecipeName = newRecipe.name

  const checkResult = duplicateChecker(nameList, newRecipeName)
  let suggests = []
  if (!checkResult) {
    suggests = duplicateSuggests(nameList, newRecipeName)
  }

  return {
    duplicate: checkResult,
    suggests: suggests
  }
}
