<template>
  <!-- orgnization-list -->
  <div v-if="checkOrganizationInfo" class="inner">
    <!--組織管理ページの左側にあたるページです-->
    <organization-info-card :accountInfo="accountInfo" />
    <!--組織管理ページの右側にあたるページです-->
    <organization-member-card :accountInfo="accountInfo" />
  </div>
  <div v-else class="inner-no-item">
    <images class="inner-no-item-image" imageName="createNewItem" />
    <texts
      class="inner-no-item-text"
      :text="$t('organization.unable.notAMemberMessage')"
      isShowAll
    />
  </div>
</template>

<script>
import organizationInfoCard from '@/components/organisms/organization/organization-info-card'
import organizationMemberCard from '@/components/organisms/organization/organization-member-card'
import images from '@/components/atoms/images'

export default {
  components: {
    organizationInfoCard,
    organizationMemberCard,
    images
  },
  props: {
    accountInfo: Object
  },
  computed: {
    checkOrganizationInfo() {
      return this?.accountInfo?.organizationInfo != null
    }
  }
}
</script>

<style lang="scss" scoped>
.inner {
  overflow: hidden;
  display: grid;
  grid-template-columns: adjustVW(480) 1fr;
  grid-column-gap: $space-medium;
  padding: 0 $space-medium $space-medium;
}
.inner-no-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  &-image {
    width: adjustVW(560);
    height: auto;
    margin-bottom: $space-large;
  }
  &-text {
    line-height: $line-height-base;
    text-align: center;
  }
}
</style>
