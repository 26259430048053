<template>
  <div class="feature-importance-label-info-wrapper">
    <div class="feature-importance-label-info-title">
      <texts
        :text="
          $t('trainedAiDetails.featureImportanceTab.encodingInformation.title')
        "
        color="gray"
        size="small"
      />
    </div>

    <div class="feature-importance-label-info-list-wrapper">
      <div
        v-for="(valueList, label) in labelInfo"
        :key="label"
        class="feature-importance-label-info-list-item"
      >
        <div class="feature-importance-label-info-new-value">
          <text-with-title
            :title="
              $t(
                'trainedAiDetails.featureImportanceTab.encodingInformation.label'
              )
            "
            :text="label"
          />
        </div>

        <div class="feature-importance-label-info-old-value-wrapper">
          <div
            v-for="(oldValue, index) in valueList"
            :key="label + '_' + index"
            class="feature-importance-label-info-old-value-item"
          >
            <texts :text="oldValue" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import textWithTitle from '@/components/molecules/text-with-title'

export default {
  components: {
    texts,
    textWithTitle
  },
  props: {
    labelInfo: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.feature-importance-label-info-wrapper {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  width: 100%;
  height: fit-content;
  max-height: 100%;
  padding: $space-small;
  background: $background;
  border-radius: adjustVW(12);
  box-shadow: $box-shadow-main;
}

.feature-importance-label-info-title {
  width: 100%;
  margin-bottom: $space-sub;
}

.feature-importance-label-info-list {
  &-wrapper {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    width: 100%;
    height: fit-content;
    max-height: 100%;
    padding-right: $space-small;
    @include scrollbar;
  }

  &-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: $space-sub;
    border-bottom: $border-sub;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0 none $border-gray;
    }
  }
}

.feature-importance-label-info-new-value {
  width: 28%;
  margin-right: $space-sub;
  margin-bottom: $space-sub;
  border-right: $border-main;
}

.feature-importance-label-info-old-value {
  &-wrapper {
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-height: adjustVW(208);
    padding-right: $space-sub;
    margin-bottom: $space-sub;
    column-gap: $space-base;
    row-gap: $space-base;
    @include scrollbar;
  }

  &-item {
    width: calc((100% - #{$space-base * 2}) / 3);
  }
}
</style>
