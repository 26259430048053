<template>
  <div class="distribution">
    <div class="distribution-inner">
      <div class="distribution-title">
        <texts
          :text="$t('trainedAi.regressionGraph.tab.distribution')"
          size="small"
        />
      </div>
      <distribution-histogram
        class="distribution-graph"
        :data="list"
        :dataCount="dataCount"
        :yLabel="$t('trainedAi.regressionGraph.graph.residualError')"
        :xLabel="$t('trainedAi.regressionGraph.graph.residualErrorCount')"
        targetValueKey="inferenceResult"
        :selectedResidualError="selectedResidualError"
        :showDetailTarget="showDetailTarget"
        @set-detail="setDetail"
      />
    </div>
    <template v-if="dataCount > 5000">
      <div class="distribution-inner">
        <div class="distribution-title">
          <texts
            :text="$t('trainedAi.regressionGraph.graph.fromActualValue')"
            size="small"
          />
        </div>
        <distribution-graph-detail-large
          class="distribution-graph"
          :data="list"
          :showDetailTargetRange="showDetailTargetRange"
          targetValueKey="actualValue"
          :cautionValue="checkedValue"
          :minMaxValue="minMaxValue"
          :xLabel="$t('serviceDetail.sampleCode.responses.item.observedValue')"
          :yLabel="$t('trainedAi.regressionGraph.graph.residualError')"
          @show-detail-once="showDetailOnce"
        />
      </div>
      <div class="distribution-inner">
        <div class="distribution-title">
          <texts
            :text="$t('trainedAi.regressionGraph.graph.fromPrediction')"
            size="small"
          />
        </div>
        <distribution-graph-detail-large
          class="distribution-graph"
          :data="list"
          :showDetailTargetRange="showDetailTargetRange"
          targetValueKey="inferenceResult"
          :cautionValue="checkedValue"
          :minMaxValue="minMaxValue"
          :xLabel="$t('trainedAi.metrics.predictions')"
          :yLabel="$t('trainedAi.regressionGraph.graph.residualError')"
          @show-detail-once="showDetailOnce"
        />
      </div>
    </template>
    <template v-else>
      <div class="distribution-inner">
        <div class="distribution-title">
          <texts
            :text="$t('trainedAi.regressionGraph.graph.fromActualValue')"
            size="small"
          />
        </div>
        <distribution-graph-detail
          class="distribution-graph"
          :data="list"
          :showDetailTargetRange="showDetailTargetRange"
          targetValueKey="actualValue"
          :cautionValue="checkedValue"
          :minMaxValue="minMaxValue"
          :xLabel="$t('serviceDetail.sampleCode.responses.item.observedValue')"
          :yLabel="$t('trainedAi.regressionGraph.graph.residualError')"
          @show-detail-once="showDetailOnce"
        />
      </div>
      <div class="distribution-inner">
        <div class="distribution-title">
          <texts
            :text="$t('trainedAi.regressionGraph.graph.fromPrediction')"
            size="small"
          />
        </div>
        <distribution-graph-detail
          class="distribution-graph"
          :data="list"
          :showDetailTargetRange="showDetailTargetRange"
          targetValueKey="inferenceResult"
          :cautionValue="checkedValue"
          :minMaxValue="minMaxValue"
          :xLabel="$t('trainedAi.metrics.predictions')"
          :yLabel="$t('trainedAi.regressionGraph.graph.residualError')"
          @show-detail-once="showDetailOnce"
        />
      </div>
    </template>
  </div>
</template>

<script>
import distributionGraphDetail from './distribution-graph-detail.vue'
import distributionGraphDetailLarge from './distribution-graph-detail-large.vue'
import distributionHistogram from './distribution-histogram.vue'
import * as d3 from 'd3'

export default {
  components: {
    distributionGraphDetail,
    distributionGraphDetailLarge,
    distributionHistogram
  },
  props: {
    list: Array,
    dataCount: Number,
    selectedResidualError: Number,
    setResidualError: Boolean
  },
  data() {
    return {
      showDetailTargetRange: [],
      showDetailTarget: null
    }
  },
  methods: {
    setDetail(data) {
      this.showDetailTarget = null
      this.showDetailTargetRange = [data.x0, data.x1]
    },
    showDetailOnce(data) {
      this.showDetailTargetRange = []
      this.showDetailTarget = data.residualError
    }
  },
  computed: {
    checkedValue() {
      if (!this.setResidualError) return null
      return this.selectedResidualError
    },
    minMaxValue() {
      const actualValueMax = d3.max(this.list, function (d) {
        return d.actualValue
      })
      const actualValueMin = d3.min(this.list, function (d) {
        return d.actualValue
      })
      const inferenceResultMax = d3.max(this.list, function (d) {
        return d.inferenceResult
      })
      const inferenceResultMin = d3.min(this.list, function (d) {
        return d.inferenceResult
      })

      const residualErrorMin = d3.min(this.list, function (d) {
        return d.residualError
      })
      const residualErrorMax = d3.max(this.list, function (d) {
        return d.residualError
      })
      return {
        xMin: Math.min(actualValueMin, inferenceResultMin),
        xMax: Math.max(actualValueMax, inferenceResultMax),
        yMin: residualErrorMin,
        yMax: residualErrorMax
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.distribution {
  display: grid;
  grid-template-columns: adjustVW(240) 1fr 1fr;
  grid-column-gap: $space-small;
  height: adjustVW(320);
  &-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-graph {
    height: 100%;
  }
}
</style>
