<template>
  <div class="trained-ai-detail-accuracy">
    <transition-toggle-contents>
      <div v-if="filteredMetrics">
        <div
          :class="{
            'trained-ai-detail-accuracy-item-multi': isMulti,
            'trained-ai-detail-accuracy-item': !isMulti
          }"
        >
          <metrics
            class="trained-ai-detail-accuracy"
            :name="filteredMetrics.name"
            :value="filteredMetrics.value"
            :percentage="filteredMetrics.percentage"
          />
          <tooltip-popover
            v-if="!isMulti"
            trigger="hover"
            placement="right"
            :autoHide="false"
            :offset="12"
            popoverArrowClass="trained-ai-detail-accuracy-arrow"
          >
            <template slot="main">
              <texts
                class="trained-ai-detail-accuracy-item-info-text"
                :text="$t('trainedAiDetails.metrics.askAccuracyRate')"
                size="small"
                color="link-green"
              />
            </template>
            <template slot="popup">
              <accuracy-tooltip-inner :metrics="filteredMetrics" />
            </template>
          </tooltip-popover>
        </div>
      </div>
      <div
        v-else-if="deepTrainedAi"
        key="deep"
        class="trained-ai-detail-accuracy-item"
      >
        <texts
          class="trained-ai-detail-accuracy-item-no-item"
          :text="$t('trainedAiDetails.metrics.noMetrics')"
          size="small"
          color="gray"
        />
      </div>
    </transition-toggle-contents>
  </div>
</template>

<script>
import metrics from '@/components/molecules/graph/metrics.vue'
import accuracyTooltipInner from '@/components/molecules/accuracy-tooltip-inner.vue'
import tooltipPopover from '@/components/molecules/tooltip-popover'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

import { DEEP_RECIPE_TYPE } from '@/lib/training.js'

export default {
  name: 'DetailMetrics',
  components: {
    metrics,
    accuracyTooltipInner,
    tooltipPopover,
    transitionToggleContents
  },
  props: {
    trainedAi: Object,
    isMulti: Boolean,
    selectedColumnIndex: Number,
    loadChangeColumn: Boolean
  },
  computed: {
    trainedAiType() {
      return this.trainedAi?.summary?.type
    },
    filteredMetrics() {
      if (
        !this.trainedAi?.summary?.metricsList ||
        this.trainedAi?.summary?.metricsList.length === 0
      )
        return null
      if (this.isMulti) {
        if (this.selectedColumnIndex >= 0 && !this.loadChangeColumn) {
          return this.trainedAi.summary.metricsList[0]
        } else {
          return null
        }
      } else {
        return this.trainedAi.summary.metricsList[0]
      }
    },
    deepTrainedAi() {
      return DEEP_RECIPE_TYPE.indexOf(this.trainedAiType) !== -1
    }
  }
}
</script>

<style lang="scss" scoped>
.trained-ai-detail-accuracy {
  display: flex;
  &-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-row-gap: $space-sub;
    width: adjustVW(390);
    height: 100%;
    padding: $space-small;
    margin: 0 $space-medium 0 0;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    &:last-child {
      margin: 0;
    }
    &-inner {
      text-align: center;
    }
    &-graph {
      width: adjustVW(72);
      margin: 0 0 0 adjustVW(56);
    }
    &-no-item {
      white-space: pre-line;
    }
    &-info {
      &-text {
        margin-bottom: adjustVW(-4);
        cursor: pointer;
      }
    }
    &-multi {
      padding: $space-sub adjustVW(20);
      background: $background-decoration;
      border-radius: adjustVW(16);
      .trained-ai-detail-accuracy {
        transform: scale(0.85);
      }
    }
  }
}
</style>

<style lang="scss">
.tooltip.popover .popover-arrow.trained-ai-detail-accuracy-arrow {
  height: adjustVH(188 + 32);
}
</style>
