<template>
  <div class="wrap">
    <div class="wrap-text-email">
      <text-with-title
        :title="$t('account.setting.info.popup.email.title')"
        :text="oldEmail"
      />
    </div>
    <div class="wrap-text">
      <input-box-validation
        v-model="newEMail"
        popupView
        :placeholder="$t('account.setting.info.popup.email.example.email')"
        :error="!checkNewEmail"
        :popupText="errorMessage"
        :title="$t('account.setting.info.popup.email.newAdress')"
      />
    </div>
    <div class="wrap-text">
      <input-box-validation
        v-model="confirmEMail"
        popupView
        :placeholder="
          $t('account.setting.info.popup.email.example.emailConfirm')
        "
        :error="!checkConfirmEmail"
        :popupText="confirmErrorMessage"
        :title="$t('account.setting.info.popup.email.confirmAdress')"
      />
    </div>
    <!--
      メールアドレス変更の成功
    -->
    <text-box
      v-if="changeEMailError"
      class="text-box"
      isError
      :text="$t('account.setting.info.popup.email.changeFailedAdress')"
    />

    <div class="button-wrap">
      <buttons
        buttonType="submit"
        :type="checkConfirmEmail && !disableSend ? 'emphasis' : 'disabled'"
        :isDisabled="!checkNewEmail || !checkConfirmEmail"
        :text="$t('account.setting.info.popup.email.createNewAdress')"
        @click="changeEmail"
      />
      <buttons
        class="button-space"
        type="sub"
        :text="$t('account.setting.info.popup.email.cancel')"
        @click="$emit('close-modal', closePopup)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import textBox from '@/components/atoms/text-box'
import buttons from '@/components/atoms/button-main'
import textWithTitle from '@/components/molecules/text-with-title'
import inputBoxValidation from '@/components/molecules/input-box-validation'
export default {
  name: 'ChangeEmail',
  components: {
    buttons,
    textWithTitle,
    inputBoxValidation,
    textBox
  },
  computed: {
    oldEmail() {
      return this.accountInfo.email
    },
    closePopup() {
      return this.showPopup
    }
  },
  data() {
    return {
      showChangeEMail: false,
      disableSend: false,
      newEMail: '',
      confirmEMail: '',
      checkNewEmail: false,
      checkConfirmEmail: false,
      changeEMailSuccess: false,
      changeEMailError: null,
      errorMessage: '',
      confirmErrorMessage: ''
    }
  },
  props: {
    accountInfo: Object,
    showPopup: Boolean
  },
  methods: {
    ...mapActions('auth', ['fetchAccountInfo']),
    async changeEmail() {
      this.disableSend = true
      this.changeEMailSuccess = false
      this.changeEMailError = null
      const request = {
        action: 'changeEmail',
        newEMail: this.confirmEMail
      }
      try {
        const res = await this.$sendMessageAndReceive(request)
        if (res.status === 'error') {
          throw res
        } else {
          this.newEMail = ''
          this.confirmEMail = ''
          this.changeEMailSuccess = true
          this.fetchAccountInfo()
        }
      } catch (ex) {
        this.changeEMailError = ex.message // notMatchCurrentPassword
      } finally {
        this.disableSend = false
      }
    }
  },
  watch: {
    /** 結構複雑になったので下のwatchでどのような処理を行ってるか記載しておきます。
     * 1. 最初のif文はinputBoxが空かそうでないか。
     * 2. 二つ目のif分はメールアドレスかそうでないか。
     * 3. 三つ目のif分は newEMail の方は既に登録されているメールアドレスかそうでないか。
     *    confirmEMail の方は自身の値が newEMail と同値かそうでないか。
     * 4. newEMailのみ4つめのif文があり、自身の値が confirmEMail と同値かそうでないかを判断するif文になっています。
     */
    newEMail(newVal) {
      if (newVal.length === 0) {
        // 新しいメールアドレスの文字数が0の時走るif文
        this.checkNewEmail = false
        this.showErrorPopup = false
        this.errorMessage = this.$t(
          'account.setting.info.popup.email.validationError.correct'
        )
      } else {
        const emailPattern =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/
        if (!emailPattern.test(newVal)) {
          this.checkNewEmail = false
          this.errorMessage = this.$t(
            'account.setting.info.popup.email.validationError.correct'
          )
        } else {
          if (newVal === this.oldEmail) {
            // 前のアドレスと変更がない場合走るif文
            this.checkNewEmail = false
            this.errorMessage = this.$t(
              'account.setting.info.popup.email.validationError.oldEmalil'
            )
          } else if (newVal !== this.oldEmail) {
            if (!this.confirmEMail) {
              // 確認用のメールアドレス(confirmEMail)が空の場合のみtrueをかえすif文
              this.checkNewEmail = true
              this.errorMessage = null
            } else if (newVal === this.confirmEMail) {
              this.checkNewEmail = true
              this.checkConfirmEmail = true
            } else {
              this.checkNewEmail = false
              this.errorMessage = this.$t(
                'account.setting.info.popup.email.validationError.wrong'
              )
            }
          } else {
            this.checkNewEmail = false
          }
        }
      }
    },
    confirmEMail(newVal) {
      if (newVal.length === 0) {
        // 確認用メールアドレスの文字数が0の時走るif文
        this.checkConfirmEmail = false
        this.showConfirmErrorPopup = false
        this.errorMessage = this.$t(
          'account.setting.info.popup.email.validationError.correct'
        )
      } else {
        const emailPattern =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/
        if (!emailPattern.test(newVal)) {
          this.checkConfirmEmail = false
          this.confirmErrorMessage = this.$t(
            'account.setting.info.popup.email.validationError.correct'
          )
        } else {
          if (newVal === this.oldEmail) {
            // 前のアドレスと変更がない場合走るif文
            this.checkNewEmail = false
            this.confirmErrorMessage = this.$t(
              'account.setting.info.popup.email.validationError.oldEmalil'
            )
          } else if (this.newEMail === newVal) {
            this.checkConfirmEmail = true
            this.checkNewEmail = true
            this.confirmErrorMessage = ''
          } else {
            this.confirmErrorMessage = this.$t(
              'account.setting.info.popup.email.validationError.correct'
            )
            this.checkConfirmEmail = false
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  width: adjustVW(640);
  &-text {
    margin: 0 0 $space-medium;
    &-email {
      margin: $space-base 0 $space-small;
    }
  }
}
.text-box {
  margin: 0 0 $space-medium;
}
.button-wrap .c-button + .button-space {
  margin-left: $space-small;
}
</style>
