<script>
import * as d3 from 'd3'

import Axis from '@/components/atoms/axis'
import Tip from '@/components/atoms/tip'
import Legend from '@/components/atoms/legend'

export default {
  mixins: [Axis, Tip, Legend],
  props: {
    data: {},
    isStack: {
      type: Boolean,
      default: false
    },
    ticks: {
      type: Boolean,
      default: false
    },
    transitionDuration: {
      default: 500
    }
  },
  data() {
    return {}
  },
  computed: {
    xScale() {
      return d3
        .scaleBand()
        .range([0, this.graphWidth])
        .domain(Object.keys(this.valueGroupedByX))
        .padding(0.1)
    },
    yScale() {
      const range = this.valueRange(this.data, {
        isStack: this.isStack,
        mustContainZero: true
      })
      return d3.scaleLinear().range([this.graphHeight, 0]).domain(range)
    },
    xGroupScale() {
      return d3
        .scaleBand()
        .range([0, this.xScale.bandwidth()])
        .domain(this.categories)
        .paddingInner(0.05)
    }
  },
  methods: {
    generateTip(d) {
      let percent = ''
      if (this.isStack) {
        percent = `<p>${((d.value / d.total) * 100).toFixed(2)}%</p>`
      }
      return `<p>${d.category} ${d.key}</p><p>${d.value}</p>${percent}`
    },
    drawGraph() {
      const vueThis = this
      this.drawAxes()

      let groupSel = this.body.selectAll('.group')

      groupSel = groupSel.data(this.stackValue)

      groupSel
        .enter()
        .append('g')
        .attr('class', 'group')
        .style('fill', function (d) {
          return vueThis.realColor(d.name)
        })
        .each(function (...args) {
          vueThis.updateGroup(this, ...args)
        })

      groupSel.each(function (...args) {
        vueThis.updateGroup(this, ...args)
      })

      groupSel.exit().remove()
    },
    updateGroup(el, g, i, node) {
      const vueThis = this

      const name = g.name
      const sel = d3.select(el).selectAll('.bar').data(g)
      let x, y, w, h, yZero
      if (this.isStack) {
        x = function (d, i) {
          return vueThis.xScale(d.key)
        }
        y = function (d, i) {
          if (d.value == null || Number.isNaN(d.value) || Number.isNaN(d[1])) {
            return vueThis.yScale(d[0])
          }
          return vueThis.yScale(d[1])
        }
        w = function (d, i) {
          return vueThis.xScale.bandwidth()
        }
        h = function (d, i) {
          if (d.value == null || Number.isNaN(d.value) || Number.isNaN(d[1])) {
            return 0
          }
          return Math.max(
            0,
            Math.abs(vueThis.yScale(d[0]) - vueThis.yScale(d[1]))
          )
        }
        yZero = function (d, i) {
          return vueThis.yScale(d[0])
        }
      } else {
        x = function (d, i) {
          return vueThis.xScale(d.key) + vueThis.xGroupScale(name)
        }
        y = function (d, i) {
          if (d.value == null || Number.isNaN(d.value)) {
            return vueThis.yScale(0)
          }
          return Math.min(vueThis.yScale(0), vueThis.yScale(d.value))
        }
        w = function (d, i) {
          return vueThis.xGroupScale.bandwidth()
        }
        h = function (d, i) {
          if (d.value == null || Number.isNaN(d.value)) {
            return 0
          }
          return Math.abs(vueThis.yScale(d.value) - vueThis.yScale(0))
        }
        yZero = function (d, i) {
          return vueThis.yScale(0)
        }
      }
      sel
        .enter()
        .append('rect')
        .attr('class', 'bar')
        .on('mouseover', vueThis.showTip)
        .on('mouseout', vueThis.hideTip)
        .attr('x', x)
        .attr('y', yZero)
        .attr('width', w)
        .attr('height', 0)
        .transition()
        .duration(vueThis.transitionDuration)
        .attr('y', y)
        .attr('height', h)

      sel
        .transition()
        .duration(vueThis.transitionDuration)
        .attr('x', x)
        .attr('y', y)
        .attr('width', w)
        .attr('height', h)

      sel
        .exit()
        .transition()
        .duration(vueThis.transitionDuration)
        .attr('y', yZero)
        .attr('height', 0)
        .remove()
    }
  },
  watch: {
    data() {
      this.recalcAxis()
      this.drawGraph()
    },
    isStack() {
      this.recalcAxis()
      this.drawGraph()
    },
    horizontal() {
      this.recalcAxis()
      this.drawGraph()
    },
    locale() {
      this.recalcAxis()
      this.drawGraph()
    }
  },
  beforeDestroy() {
    d3.select('.plot-svg').attr('style', 'pointer-events: none;')
  }
}
</script>
<style lang="scss">
.scatter-plot {
  min-width: 100px;
  min-height: 100px;
}
.scatter-tip-image {
  max-width: 200px;
  max-height: 200px;
}
</style>
