<template>
  <div class="button-list">
    <button-main
      :text="$t('common.cancel')"
      type="sub"
      :disabled="disableClick"
      @click="$emit('close-modal')"
    />
    <button-main
      :text="$t('preprocessing.applyingDataset.reset')"
      type="caution"
      :disabled="disableClick"
      @click="$emit('reset-preprocessing-confirm')"
    />
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'

export default {
  components: {
    buttonMain
  },
  props: {
    disableClick: Boolean
  }
}
</script>

<style lang="scss">
.button-list {
  margin: $space-sub 0 0;
}
</style>
