<template>
  <transition-toggle-contents>
    <div v-if="optimizationFlag" class="status-wrap">
      <training-status
        :status="optimizationValues.optimizationProgressStatus"
        :maxProgress="checkNumIter"
        :progress="checkProgress"
        :trainingColumnStatus="trainingColumnStatus"
        :optimizationNumIter="optimizationValues.optimizationNumIter"
        :optimizationProgress="optimizationValues.optimizationProgress"
        checkOptimization
        :hideTrainStatus="hideTrainStatus"
        :disabledStop="disabledStop"
        :disabledStopTips="disabledStopTips"
        :notShowGraph="notShowGraph"
        isDifferentOptimizationConditions
        @training-stop="$emit('optimization-stop')"
      />
      <train-tips-optimization />
    </div>
  </transition-toggle-contents>
</template>

<script>
import trainingStatus from '@/components/organisms/training/popup/training-status'
import trainTipsOptimization from '@/components/organisms/training/popup/train-tips-optimization'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

export default {
  components: {
    trainingStatus,
    trainTipsOptimization,
    transitionToggleContents
  },
  props: {
    optimizationValues: Object,
    optimizationFlag: Boolean
  },
  computed: {
    trainingColumnStatus() {
      const predictionColumns = this.optimizationValues.newlyPredictionColumns
      const finishColumns =
        this.optimizationValues.newlyFinishedPredictionColumns
      if (predictionColumns.length === 0) return null
      if (predictionColumns.length === finishColumns.length) {
        return {
          columnName: finishColumns[finishColumns.length - 1],
          count: finishColumns.length,
          max: predictionColumns.length
        }
      } else {
        return {
          columnName: predictionColumns[finishColumns.length],
          count: finishColumns.length,
          max: predictionColumns.length
        }
      }
    },
    checkNumIter() {
      const predictionColumns = this.optimizationValues.newlyPredictionColumns
      const trainingNumIter = this.optimizationValues.trainingNumIter

      let count = trainingNumIter
      if (predictionColumns.length > 1) {
        count = predictionColumns.length * trainingNumIter
      }
      if (
        predictionColumns.length ===
        this.optimizationValues.newlyFinishedPredictionColumns.length
      ) {
        count = 1
      }
      return count
    },
    checkProgress() {
      const predictionColumns = this.optimizationValues.newlyPredictionColumns
      const finishColumns =
        this.optimizationValues.newlyFinishedPredictionColumns
      const trainingNumIter = this.optimizationValues.trainingNumIter
      const trainingProgress = this.optimizationValues.trainingProgress

      let count = trainingProgress
      if (predictionColumns.length > 1) {
        count = finishColumns.length * trainingNumIter + trainingProgress
      }
      if (predictionColumns.length === finishColumns.length) {
        count = 1
      }
      if (predictionColumns.length === 0) {
        count = 0
      }
      return count
    },
    hideTrainStatus() {
      return this.optimizationValues.newlyPredictionColumns.length === 0
    },
    disabledStop() {
      return this.optimizationValues?.jobInfo === null
    },
    disabledStopTips() {
      if (this.disabledStop) {
        return this.$t('inference.optimization.waitingStop')
      } else {
        return ''
      }
    },
    notShowGraph() {
      return (
        this.optimizationValues.newlyPredictionColumns &&
        this.optimizationValues.newlyPredictionColumns.length === 0 &&
        this.optimizationValues.optimizationNumIter === 0 &&
        this.optimizationValues.optimizationProgress === 0
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.status {
  &-wrap {
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-medium;
  }
}
</style>
