<template>
  <div class="c-wrap">
    <sidebar-project-detail
      v-if="!notFoundProject || datasetLoading"
      v-bind="sidebar"
    />
    <sidebar v-else />
    <div class="c-body-wrap">
      <header-tabs
        v-bind="headerTabs"
        @tab-click="$emit('tab-click', $event)"
      />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="datasetLoading" />
          <page-not-found v-else-if="notFoundProject" />
          <dataset-list-main
            v-else
            v-bind="body"
            :projectItems="projectItems"
            :projectId="projectId"
            :accountInfo="accountInfo"
            :showDatasourceList="showDatasourceList"
            :datasetList="datasetList"
            :datasetLoading="datasetLoading"
            :uploadLoading="uploadLoading"
            :datasourceList="datasourceList"
            :datasourceLoading="datasourceLoading"
            :downloadLoading="downloadLoading"
            @add-new-data="$emit('add-new-data')"
            @sort-label="$emit('sort-label', $event)"
            @set-datasource="$emit('set-datasource', $event)"
            @show-datasource="$emit('show-datasource', $event)"
            @show-dataset="$emit('show-dataset', $event)"
            @icon-menu-click="$emit('icon-menu-click', $event)"
            @toggle-datasource-list="$emit('toggle-datasource-list', $event)"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing
      v-if="deleteLoading || body.addDataset.importLoading || disableClick"
    />
    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
    <div class="dataset-list-popup">
      <dataset-list-popup
        v-bind="popup"
        :addDataset="body.addDataset"
        :accountInfo="accountInfo"
        :uploadLoading="uploadLoading"
        :progress="progress"
        :maxProgress="maxProgress"
        :progressStatus="progressStatus"
        :disableClick="disableClick"
        @test-datasource="$emit('test-datasource', $event)"
        @new-datasource="$emit('new-datasource', $event)"
        @close-modal="$emit('close-modal', $event)"
        @show-table="$emit('show-table', $event)"
        @change-tab="$emit('change-tab', $event)"
        @on-file-input="$emit('on-file-input', $event)"
        @datasource-input="$emit('datasource-input', $event)"
        @delete-datasource="$emit('delete-datasource', $event)"
        @set-datasource="$emit('set-datasource', $event)"
        @delete-dataset="$emit('delete-dataset', $event)"
        @add-dataset-to-project="$emit('add-dataset-to-project', $event)"
        @start-uploading="$emit('start-uploading', $event)"
        @import-dataset-loading="$emit('import-dataset-loading', $event)"
        @datasource-items="$emit('datasource-items', $event)"
        @do-import="$emit('do-import', $event)"
        @file-clear="$emit('file-clear')"
        @input-copy-form="$emit('input-copy-form', $event)"
        @input-create-form="$emit('input-create-form', $event)"
        @get-and-set-sql-list="$emit('get-and-set-sql-list', $event)"
        @get-sql-list="$emit('get-sql-list', $event)"
        @show-sql-edit="$emit('show-sql-edit', $event)"
        @select-sql-preview-table="$emit('select-sql-preview-table', $event)"
      />
    </div>
  </div>
</template>

<script>
import sidebarProjectDetail from '@/components/organisms/sidebar-project-detail.vue'
import Sidebar from '@/components/organisms/sidebar.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import datasetListMain from '@/components/organisms/dataset-list/dataset-list-main.vue'
import datasetListPopup from '@/components/organisms/dataset-list/dataset-list-popup.vue'
import transitionPage from '@/components/molecules/transition-page'
import Loading from '@/components/atoms/loading.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import pageNotFound from '@/components/organisms/page-not-found'

export default {
  components: {
    sidebarProjectDetail,
    Sidebar,
    hoverMenu,
    headerTabs,
    datasetListMain,
    datasetListPopup,
    transitionPage,
    Loading,
    loadingProcessing,
    TransitionToggleContents,
    pageNotFound
  },
  props: {
    projectItems: Object,
    projectId: Number,
    accountInfo: Object,
    headerTabs: Object,
    body: Object,
    popup: Object,
    datasetList: Array,
    datasetLoading: Boolean,
    datasourceList: Array,
    datasourceLoading: Boolean,
    showDatasourceList: Boolean,
    uploadLoading: Boolean,
    progress: Number,
    maxProgress: Number,
    progressStatus: String,
    deleteLoading: Boolean,
    notFoundProject: Boolean,
    disableClick: Boolean,
    downloadLoading: Boolean
  },
  computed: {
    sidebar() {
      // サイドバーに表示する情報
      return {
        project: {
          name: '退職予測',
          description:
            'このプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入ります'
        },
        activeLink: 'dataset'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
}
</style>
