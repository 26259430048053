<template>
  <div class="wrap">
    <div class="caution-text">
      <texts
        class="caution-text-inner"
        :text="$t('training.reasons.onlynum')"
        color="emphasis"
      />
      <texts
        v-if="rePreprocess"
        class="caution-text-inner"
        :text="$t('selectTargetColumn.ngColumnPopup.rePreprocess')"
      />
      <texts
        v-else
        class="caution-text-inner"
        :text="$t('selectTargetColumn.ngColumnPopup.reSelect')"
      />
    </div>
    <div class="button-list">
      <button-main
        v-if="rePreprocess"
        :text="$t('selectTargetColumn.ngColumnPopup.buttonRePreprocess')"
        type="emphasis"
        @click="$emit('re-preprocess')"
      />
      <button-main
        :text="$t('selectTargetColumn.ngColumnPopup.buttonClose')"
        type="sub"
        @click="$emit('close-modal')"
      />
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import texts from '@/components/atoms/text'

export default {
  components: {
    buttonMain,
    texts
  },
  props: {
    rePreprocess: Boolean
  }
}
</script>

<style lang="scss">
.button-list {
  margin: $space-medium 0 0;
}

.caution-text-inner {
  margin-bottom: $space-text;
  &:last-of-type {
    margin: 0;
  }
}
</style>
