<template>
  <div class="delete-wrap">
    <div class="delete-list">
      <list-item :items="checkCautionTrainedAiList" :field="field" isToggle>
        <template v-slot:toggleContent="{ entry }">
          <trained-ai-delete-inner class="delete-item" :entry="entry" />
        </template>
      </list-item>
    </div>
    <div class="delete-button">
      <div class="delete-button-inner">
        <button-main
          :text="$t('common.cancel')"
          type="sub"
          :disabled="disableClick"
          @click="$emit('cancel-delete')"
        />
      </div>
      <div class="delete-button-inner">
        <button-main
          :text="$t('common.deleteButton')"
          type="caution"
          :disabled="disableClick"
          @click="deleteClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import listItem from '@/components/molecules/list-item'
import trainedAiDeleteInner from '@/components/organisms/trained-ai-common/trained-ai-delete-inner'

export default {
  components: {
    buttonMain,
    listItem,
    trainedAiDeleteInner
  },
  props: {
    trainedAiList: Array,
    project: Object,
    disableClick: Boolean
  },
  computed: {
    field() {
      return [
        {
          key: 'name',
          label: this.$t('trainedAiList.deleteList')
        },
        {
          key: 'caution',
          label: '',
          isCaution: true
        }
      ]
    },
    checkCautionTrainedAiList() {
      if (!this.trainedAiList) return []
      const target = this.trainedAiList.map((item) => {
        return {
          ...item,
          caution:
            item.services.length > 0
              ? this.$t('trainedAiDetails.popup.serviceDeleteCheck.caution')
              : ''
        }
      })
      return target
    },
    checkServiceExist() {
      if (!this.trainedAiList) return false
      const target = this.trainedAiList.filter((ai) => ai.services.length > 0)
      return target.length > 0
    }
  },
  methods: {
    deleteClick() {
      if (this.checkServiceExist) {
        this.$emit('check-service')
      } else {
        this.$emit(
          'delete',
          this.trainedAiList.map((x) => x.id)
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.delete {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-list {
    overflow-y: auto;
    width: adjustVW(724);
    max-height: 100%;
    padding: 0 $space-small 0 0;
    margin: 0 0 $space-medium;
    @include scrollbar;
  }
  &-item {
    margin-top: -$space-small;
  }
  &-button {
    display: flex;
    justify-content: flex-end;
    padding: 0 $space-small 0 0;
    &-inner {
      margin: 0 $space-small 0 0;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
