var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-new-service-wrap"},[_c('div',{staticClass:"add-new-service-input"},[_c('div',{staticClass:"add-new-service-input-block"},[_c('div',{staticClass:"add-new-service-input-inner"},[_c('input-box-validation',{attrs:{"accept":_vm.validate.accept,"error":_vm.validate.error,"title":_vm.$t('serviceList.popup.addNewService.serviceName'),"placeholder":_vm.$t('serviceList.popup.addNewService.serviceNamePlaceholder'),"popupView":"","isNameValidation":"","nameValidationSuggests":_vm.serviceFormValidate.suggests},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('div',{staticClass:"add-new-service-input-inner"},[_c('input-box',{attrs:{"isTextArea":"","isGray":"","placeholder":_vm.$t(
              'serviceList.popup.addNewService.serviceDescriptionPlaceholder'
            ),"title":_vm.$t('serviceList.popup.addNewService.serviceDescription')},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('div',{staticClass:"add-new-service-input-list"},[_c('div',{staticClass:"add-new-service-input-item"},[_c('select-box',{attrs:{"valueKey":"id","title":_vm.$t('serviceList.popup.addNewService.AItoUse'),"placeholder":_vm.$t('serviceList.popup.addNewService.AItoUsePlaceholder'),"items":_vm.trainedAiList,"isGray":"","scrollBar":""},on:{"select-box-open":function($event){return _vm.$emit('select-box-get-models')},"select-item":function($event){return _vm.$emit('select-box-set-model', $event)}},model:{value:(_vm.trainedAIId),callback:function ($$v) {_vm.trainedAIId=$$v},expression:"trainedAIId"}})],1),(_vm.inferenceTypeOptions.length > 0)?_c('div',{staticClass:"add-new-service-input-item"},[_c('transition-toggle-contents',[(!_vm.loadingTrainedAiDetail)?_c('select-box',{attrs:{"placeholder":_vm.$t('serviceList.popup.addNewService.typePlaceholder'),"items":_vm.inferenceTypeOptions,"isGray":"","scrollBar":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}):_c('loading-icon',{staticClass:"add-new-service-input-loading"})],1)],1):_vm._e()]),_c('div',{staticClass:"add-new-service-preproc"},[_c('checkbox-base',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.disabledTips),expression:"disabledTips"}],staticClass:"add-new-service-preproc-check",class:{
            'add-new-service-preproc-check-disabled': _vm.disabledTips !== null
          },attrs:{"text":_vm.$t('serviceList.popup.addNewService.selectPreprocessing'),"checked":_vm.preprocessingOpen,"isDisabled":_vm.disabledTips !== null},on:{"input":_vm.inputCheck}}),_c('transition-toggle-slot-contents',[(_vm.preprocessingOpen)?_c('div',[_c('div',[_c('select-box',{attrs:{"valueKey":"id","title":_vm.$t(
                    'serviceList.popup.addNewService.PreprocessingToUseOptional'
                  ),"placeholder":_vm.$t(
                    'serviceList.popup.addNewService.PreprocessingToUsePlaceholder'
                  ),"items":_vm.preprocessingList,"isGray":"","scrollBar":"","showTop":""},on:{"select-box-open":function($event){return _vm.$emit('select-box-get-processings')}},model:{value:(_vm.preprocessing),callback:function ($$v) {_vm.preprocessing=$$v},expression:"preprocessing"}})],1)]):_vm._e()])],1)]),_c('div',{staticClass:"add-new-service-input-button"},[_c('button-main',{attrs:{"type":"emphasis","isDisabled":_vm.disabledSubmit || _vm.disableClick,"text":_vm.$t('serviceList.popup.addNewService.submitButton')},on:{"click":_vm.createService}}),_c('button-main',{attrs:{"type":"sub","isDisabled":_vm.disableClick,"text":_vm.$t('common.cancel')},on:{"click":function($event){return _vm.$emit('cancel')}}})],1)]),_c('transition-toggle-contents',[(_vm.isNotCustomblockAvailable)?_c('div',[_c('texts',{staticClass:"add-new-service-caution",attrs:{"text":_vm.$t('common.disabled.NOT_AVAILABLE_CUSTOMBLOCK_FOR_INFERENCE'),"color":"caution","size":"small"}})],1):(_vm.modelInfo && _vm.modelInfo.summary && !_vm.loadingTrainedAiDetail)?_c('div',{staticClass:"add-new-service-use-model"},[_c('model-info',{attrs:{"modelInfo":_vm.modelInfo.summary,"title":_vm.$t('serviceList.popup.addNewService.AItoUse'),"isNoWrap":""}})],1):(_vm.loadingTrainedAiDetail)?_c('loading-icon'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }