<template>
  <!--
    サービス一覧の上部
  -->
  <page-top :title="summary.title" :iconName="summary.iconName" notScroll>
    <div class="top-function-wrap">
      <div class="top-function-tab" :style="{ '--tabs': tabs.length }">
        <tab-list
          class="top-function-tab-list"
          :tabs="tabs"
          :activeTabProps="activeTabProps"
          @change-tab="changeTab"
        />
      </div>
      <menu-list
        v-if="!accountInfoLoading"
        :checked="checked"
        :menus="iconMenus"
        :isMenuShow="checked.length > 0"
        @menu-click="menuClick"
      />
    </div>
  </page-top>
</template>

<script>
import pageTop from '@/components/atoms/page-top'
import TabList from '@/components/molecules/tab-list.vue'
import menuList from '@/components/molecules/menu-list'

export default {
  components: {
    pageTop,
    TabList,
    menuList
  },
  data() {
    return {
      option: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.3,
        keys: [
          {
            name: 'name',
            weight: 0.5
          },
          {
            name: 'serviceOwner',
            weight: 0.5
          }
        ],
        distance: 10000
      }
    }
  },
  props: {
    activeTab: String,
    accountInfo: Object,
    accountInfoLoading: Boolean,
    serviceListFiltered: Array,
    checked: Array
  },
  computed: {
    summary() {
      return {
        title: this.$t('serviceList.title'),
        iconName: 'service'
      }
    },
    iconMenus() {
      return [
        // サービス一覧に表示するアイコンメニュー
        {
          id: 0,
          iconName: 'edit', // edit
          value: 'edit',
          isMultiple: false, // 複数選択可能かどうか
          disabled: this.disableEditService,
          tips: {
            name: this.$t('common.editButton'),
            disabledName: this.$t(
              'common.disabled.organization.otherAccountItem'
            )
          }
        },
        {
          id: 1,
          iconName: 'delete', // delete
          value: 'delete',
          isMultiple: true, // 複数選択可能かどうか
          disabled: this.disableEditService,
          tips: {
            name: this.$t('common.deleteButton'),
            disabledName: this.$t(
              'common.disabled.organization.otherAccountItemDelete'
            )
          }
        }
      ]
    },
    disableEditService() {
      if (!this.checked || this.checked.length === 0) return true
      if (this.accountInfo?.organizationInfo?.role === 'admin') return false
      const services = this.serviceListFiltered.filter((service) =>
        this.checked.some((id) => id === service.id)
      )
      return services.some(
        (service) => service.serviceOwner !== this.accountInfo.accountId
      )
    },
    tabs() {
      return [
        {
          id: 0,
          name: this.$t('service.types.infer'),
          value: 'infer',
          iconName: 'inference'
        },
        {
          id: 1,
          name: this.$t('service.types.learn'),
          value: 'learn',
          iconName: 'train'
        },
        {
          id: 2,
          name: this.$t('service.types.upload'),
          value: 'upload',
          iconName: 'project'
        },
        {
          id: 3,
          name: this.$t('service.types.optimization'),
          value: 'optimization',
          iconName: 'optimization'
        }
      ]
    },
    activeTabProps() {
      return this.tabs.findIndex((item) => item.value === this.activeTab)
    }
  },
  methods: {
    menuClick(event) {
      const resultEvent = {
        name: event.activeMenu,
        checked: this.checked
      }
      this.$emit('menu-click', resultEvent)
    },
    changeTab(e) {
      this.$emit('tab-changed', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.top-function {
  &-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-main {
    display: flex;
  }
  &-button {
    margin: 0 $space-medium 0 0;
  }
  &-search {
    width: adjustVW(320);
  }
  &-tab-list {
    /* stylelint-disable */
    &::v-deep .tab-item[value='infer'] {
      /* stylelint-enable */
      padding-right: $space-medium;
    }
  }
}
</style>
