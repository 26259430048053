<template>
  <div class="service-detail-edit-wrap">
    <div class="service-detail-edit-inner">
      <div class="service-detail-edit-title">
        <texts :text="$t('serviceDetail.editService')" size="large" />
      </div>
      <div class="service-detail-edit-block">
        <input-box-validation
          v-model="name"
          :accept="validate.accept"
          :error="validate.error"
          :title="$t('serviceDetail.serviceName')"
          popupView
          isNameValidation
          :nameValidationSuggests="serviceFormValidate.suggests"
        />
      </div>
      <div class="service-detail-edit-block">
        <input-box
          v-model="description"
          isTextArea
          isGray
          :title="$t('serviceDetail.serviceDetail')"
        />
      </div>
      <div class="service-detail-edit-block-list">
        <div class="service-detail-edit-block-item-column">
          <div class="service-detail-edit-inner-title">
            <texts
              :text="$t('sidebar.train.status.recipe.itemName')"
              size="small"
              color="gray"
            />
          </div>
          <div class="service-detail-edit-block-item-input-wrap">
            <selectBox
              v-model="recipeId"
              class="service-detail-edit-block-item-input"
              valueKey="id"
              :items="recipeList"
              isGray
              scrollBar
              showTop
              :isRequired="checkExceptOptimization"
            />
            <loading-icon
              v-if="loadingRecipeDetail"
              class="service-detail-edit-block-item-input-loading"
            />
            <transition-toggle-contents>
              <text-box
                v-if="errorRecipeText"
                class="service-detail-edit-block-item-input-error"
                :text="errorRecipeText"
                isError
                isFolded
              />
            </transition-toggle-contents>
          </div>
        </div>
      </div>
      <div>
        <button-toggle
          :value="opened"
          :openText="$t('serviceList.popup.addNewService.showPreprocessing')"
          :closeText="$t('serviceList.popup.addNewService.showPreprocessing')"
          @close="preprocessingId = null"
        >
          <div class="service-detail-edit-inner-title">
            <texts
              :text="
                $t('serviceList.popup.addNewService.PreprocessingToUseOptional')
              "
              size="small"
              color="gray"
            />
          </div>
          <select-box
            v-model="preprocessingId"
            class="service-detail-edit-preproc-input"
            isGray
            scrollBar
            showTop
            valueKey="id"
            :items="preprocessingList"
            :placeholder="
              preprocessingList.length
                ? ''
                : $t(
                  'serviceList.popup.addNewService.PreprocessingToUsePlaceholder'
                )
            "
            :title="
              $t('serviceList.popup.addNewService.PreprocessingToUseOptional')
            "
            @select-box-open="$emit('select-box-get-preprocessings')"
          />
        </button-toggle>
      </div>
      <div class="service-detail-edit-button-block">
        <div class="service-detail-edit-button-inner">
          <button-main
            type="emphasis"
            :isDisabled="disabledSubmit"
            :text="$t('serviceDetail.button.save')"
            @click="save"
          />
        </div>
        <div class="service-detail-edit-button-inner">
          <button-main
            type="sub"
            :text="$t('common.cancel')"
            @click="$emit('cancel')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { usedCustomblockList } from '@/lib/inference.js'

import inputBox from '@/components/molecules/input-box'
import inputBoxValidation from '@/components/molecules/input-box-validation'
import buttonMain from '@/components/atoms/button-main.vue'
import buttonToggle from '@/components/molecules/button-toggle'
import selectBox from '@/components/molecules/select-box'
import texts from '@/components/atoms/text'
import textBox from '@/components/atoms/text-box'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import loadingIcon from '@/components/atoms/loading-icon'

export default {
  components: {
    texts,
    textBox,
    selectBox,
    inputBox,
    inputBoxValidation,
    buttonToggle,
    buttonMain,
    transitionToggleContents,
    loadingIcon
  },
  data() {
    return {
      name: this.service.name,
      description: this.service.description,
      recipeId: this.service.recipe?.id,
      preprocessingId: this.service.edaRecipe?.id,
      validate: {
        accept: null,
        error: null
      },

      opened: !!this.service.edaRecipe?.id,
      showColumnTips: null,
      showColumnTipsTimer: null
    }
  },
  props: {
    service: Object,
    serviceFormValidate: Object,
    project: Object,
    recipeDetail: Object,
    loadingRecipeDetail: Boolean,
    customblockList: Array
  },
  watch: {
    name: function (val) {
      this.$emit('input-edit-form', {
        type: 'service',
        form: {
          name: val
        }
      })
    },
    serviceFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    },
    recipeId(newVal) {
      const target = this.recipeList.find((item) => item.id === newVal)
      this.$emit('change-set-recipe', {
        id: target.id,
        accountId: target.accountId
      })
    }
  },
  async mounted() {
    await this.$waitConnected()
    this.$emit('input-edit-form', {
      type: 'service',
      form: {
        name: this.name
      }
    })
  },
  methods: {
    save() {
      this.$emit('save', {
        serviceId: this.service.serviceId,
        name: this.name,
        description: this.description,
        recipeId: this.recipeId,
        edaRecipeId: this.preprocessingId
      })
    }
  },
  computed: {
    disabledSubmit: function () {
      if (this.name === '') return true
      if (this.validate.error) return true
      if (this.checkExceptOptimization) return true
      if (this.checkNotAvailableCustomblock) return true
      return false
    },
    recipeList() {
      if (!this.project || !this.project?.listRecipe) return []
      let recipeList = this.project.listRecipe
      recipeList = recipeList.sort((x, y) =>
        x?.updateTime > y?.updateTime ? -1 : 1
      )
      return recipeList
    },
    preprocessingList() {
      const nullElement = {
        fullId: null,
        name: this.$t('common.notSelected')
      }
      let preprocessingList = this.project?.listEdaRecipe ?? []
      preprocessingList = preprocessingList.sort((x, y) =>
        x?.updateTime > y?.updateTime ? -1 : 1
      )
      return [nullElement, ...preprocessingList]
    },
    checkExceptOptimization() {
      if (!this.recipeDetail) return false
      return !this.recipeDetail?.is_optimization
    },
    errorRecipeText() {
      if (this.checkNotAvailableCustomblock) {
        return this.$t('common.disabled.NOT_AVAILABLE_CUSTOMBLOCK_FOR_RECIPE')
      } else if (this.checkExceptOptimization) {
        return this.$t(
          'serviceList.popup.addNewService.progress.recipe.descOptimization'
        )
      } else {
        return ''
      }
    },
    checkNotAvailableCustomblock() {
      if (!this.recipeDetail) return false
      const usedCustomblockItems =
        usedCustomblockList(this.recipeDetail.body.layers) ?? []
      if (usedCustomblockItems.length === 0) return false
      const checkCustomblock = usedCustomblockItems.some((target) => {
        return !this.customblockList.some((customblock) => {
          return customblock.customblock_id === target.customblock_id
        })
      })
      return checkCustomblock
    }
  },
  destroyed() {
    window.clearTimeout(this.showColumnTipsTimer)
  }
}
</script>
<style lang="scss" scoped>
.service-detail-edit {
  &-wrap {
    width: 100%;
    height: 100%;
    padding: $space-small $space-base;
  }
  &-inner {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding: $space-base $space-small;
    @include scrollbar;
    &-columns {
      overflow-y: auto;
      height: 100%;
      max-height: adjustVW(240);
      @include scrollbar;
    }
  }
  &-block {
    width: adjustVW(720);
    margin: 0 0 $space-medium;
    &-list {
      display: flex;
      width: adjustVW(720);
      margin-bottom: $space-large;
    }
    &-item {
      width: calc(50% - #{$space-sub});
      margin-right: $space-medium;
      &:last-of-type {
        margin: 0;
      }
      &-input {
        height: auto;
        &-wrap {
          position: relative;
        }
        &-loading {
          position: absolute;
          top: 50%;
          right: adjustVW(-48);
          transform: translateY(-50%);
        }
        &-error {
          margin-top: $space-small;
        }
      }
      &-column {
        width: 100%;
      }
    }
  }
  &-title {
    margin: 0 0 $space-medium;
  }
  &-inner-title {
    margin: 0 0 $space-base;
    &-text {
      width: fit-content;
    }
  }
  &-button {
    &-block {
      display: flex;
    }
    &-inner {
      margin: 0 $space-small 0 0;
      &:last-child {
        margin: 0;
      }
    }
  }
  &-preproc-input {
    width: adjustVW(722);
    margin-bottom: $space-medium;
  }
}
</style>
