<template>
  <card-base>
    <div class="config-wrap">
      <div class="config-body">
        <div v-if="columnList && columnList.length > 1" class="config-item">
          <div class="config-item-title">
            <texts
              :text="$t('trainedAi.multi.selectedColumn')"
              size="small"
              color="gray"
            />
          </div>
          <select-box
            v-model="internalColumnName"
            :items="columnList"
            isGray
            :isDisabled="waitTextMiningLoading"
          />
        </div>
        <div class="config-item">
          <div class="config-item-title">
            <texts
              :text="$t('textMining.coOccurrence.config.keyword')"
              size="small"
              color="gray"
            />
          </div>
          <input-box
            v-model="internalValue.keyword"
            isGray
            :isDisabled="waitTextMiningLoading"
          />
        </div>
        <div class="config-item">
          <adjust-slider-input
            v-model.lazy="internalValue.maxShow"
            :max="total"
            :min="1"
            :title="$t('textMining.coOccurrence.config.maxShow')"
            :isDisabled="waitTextMiningLoading"
          />
        </div>
        <div class="config-item config-item-button">
          <button-main
            :text="$t('textMining.coOccurrence.config.button')"
            :isDisabled="!ableCreate || waitTextMiningLoading"
            type="emphasis"
            fontSize="small"
            @click="createNetwork"
          />
        </div>
      </div>
    </div>
  </card-base>
</template>

<script>
import cardBase from '@/components/atoms/card-base'
import buttonMain from '@/components/atoms/button-main'
import inputBox from '@/components/molecules/input-box'
import selectBox from '@/components/molecules/select-box'
import adjustSliderInput from '@/components/molecules/adjust-slider-input'

export default {
  components: {
    cardBase,
    buttonMain,
    inputBox,
    selectBox,
    adjustSliderInput
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    total: {
      type: Number,
      default: 80
    },
    columns: {
      type: Array,
      default: () => []
    },
    columnName: {
      type: String,
      default: ''
    },
    waitTextMiningLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      internalValue: {
        keyword: '',
        maxShow: 20
      },
      internalColumnName: '',
      checkColumnName: ''
    }
  },
  methods: {
    createNetwork() {
      const payload = {
        params: JSON.parse(JSON.stringify(this.internalValue)),
        columnName: this.internalColumnName
      }
      this.checkColumnName = this.internalColumnName

      this.$emit('create-co-occurrence', payload)
    }
  },
  mounted() {
    const params = JSON.parse(JSON.stringify(this.value))
    this.internalValue = params
    this.internalColumnName = this.columnName
    this.checkColumnName = this.columnName
  },
  computed: {
    columnList() {
      return this.columns.map((column) => {
        return {
          value: column,
          name: column
        }
      })
    },
    ableCreate() {
      const checkDefault =
        Object.entries(this.value).some(([key, value]) => {
          return this.internalValue[key] !== value
        }) && this.internalValue.keyword.length > 0
      return checkDefault || this.checkColumnName !== this.internalColumnName
    }
  }
}
</script>

<style lang="scss" scoped>
.config {
  &-body {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(4, adjustVW(280));
    grid-column-gap: $space-medium;
    width: fit-content;
  }
  &-item {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    width: 100%;
    &-title {
      margin-bottom: $space-text;
    }
    &-button {
      align-self: center;
      padding-left: $space-medium;
      border-left: $border-sub;
    }
  }
}
</style>
