<template>
  <card-base
    class="inference-result-text-card"
    @mouseleave.native="isLeaveText"
  >
    <div class="inference-result-text-target">
      <texts
        class="inference-result-text-target-title"
        size="small"
        :text="this.$i18n.t('inference.result.card.analysisTarget')"
      />

      <div class="inference-result-text-target-main">
        <div class="inference-result-text-target-text">
          <description
            class="inference-result-text-target-text-main"
            :text="text"
            noTips
            @click.native="isHoverText"
          />
        </div>

        <div
          class="inference-result-text-target-popup"
          @mouseleave.stop="isLeaveText"
        >
          <popup-balloon-text
            class="inference-result-text-target-popup-inner"
            :class="{
              'inference-result-text-target-popup-inner-right': posRight
            }"
            :showPopup="showPopup"
            :popupContents="popupContents"
            :posBottom="posBottom"
            :posRight="posRight"
          />
        </div>
      </div>

      <texts
        class="inference-result-text-target-name"
        size="small"
        :text="itemName"
      />
    </div>

    <div class="inference-result-text-data">
      <slot />
    </div>
  </card-base>
</template>

<script>
import cardBase from '@/components/atoms/card-base.vue'
import popupBalloonText from '@/components/molecules/popup-balloon-text.vue'
import texts from '@/components/atoms/text.vue'
import Description from '@/components/atoms/description.vue'

export default {
  components: {
    cardBase,
    popupBalloonText,
    texts,
    Description
  },
  data() {
    return {
      showPopup: false
    }
  },
  props: {
    itemName: String,
    text: String,
    posBottom: {
      type: Boolean,
      default: false
    },
    posRight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    popupContents: function () {
      return {
        body: this.text,
        name: this.itemName,
        length: this.text.length
      }
    }
  },
  methods: {
    isHoverText: function () {
      if (!this.showPopup) {
        this.showPopup = true
        this.$emit('show-popup')
      }
    },
    isLeaveText: function () {
      this.showPopup = false
    },
    closePopup() {
      this.showPopup = null
    }
  }
}
</script>

<style lang="scss" scoped>
.inference-result-text-card {
  display: grid;
  grid-template-columns: adjustVW(212) calc(
      100% - #{adjustVW(212) + $space-small}
    );
  grid-template-rows: 100%;
  grid-column-gap: $space-small;
}

.inference-result-text-target {
  width: adjustVW(212);

  &-main {
    position: relative;
  }

  &-title {
    margin-bottom: $space-base;
  }

  &-text {
    width: adjustVW(212);
    height: adjustVH(212);
    padding: $space-base;
    margin-bottom: $space-base;
    border: $border-main;
    cursor: pointer;
  }

  &-name {
    width: 100%;
  }

  &-popup {
    &-inner {
      left: 0;
      width: adjustVW(560);
      &-right {
        right: 0;
        left: inherit;
      }
    }
  }
}

.inference-result-text-target-text-main {
  width: 100%;
  height: 100%;
  white-space: normal;
}

.inference-result-text-data {
  width: 100%;
  height: 100%;
}
</style>
