import { duplicateChecker, duplicateSuggests } from './misc'

/**
 * @param { Object } datasetList
 * @param { Object } newDataset
 * @returns { Object }
 **/
export function datasetValidator(datasets, newDataset) {
  const datasetKeys = Object.keys(datasets)

  const nameList =
    datasetKeys.length > 0 ? datasetKeys.map((id) => datasets[id].name) : []
  const newDatasetName = newDataset.name

  const checkResult = duplicateChecker(nameList, newDatasetName)
  let suggests = []
  if (!checkResult) {
    suggests = duplicateSuggests(nameList, newDatasetName)
  }

  return {
    duplicate: checkResult,
    suggests: suggests
  }
}
