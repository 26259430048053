<template>
  <card-base class="card">
    <texts
      class="title"
      size="large"
      :text="$t('account.setting.accountUsage.title')"
    />
    <div class="user-data">
      <div class="user-data-inner">
        <texts :text="$t('account.setting.accountUsage.dataUsageCapacity')" />
        <div class="user-data-detail">
          <texts class="user-data-use" :text="totalDataSize" />
          <texts
            :text="
              $t('account.setting.accountUsage.MaximumCapacity') +
                maxDataSize +
                ')'
            "
            color="gray"
          />
        </div>
      </div>
      <!-- ここのグラフ別のブランチで修正しました。このまま置いといてください。念のためコメント置いたままにしといてください。岡田が両方マージされた時に確認します -->
      <graph-bar-count
        v-if="!accountInfoLoading"
        class="user-data-graph"
        :count="accountInfo.totalDataSize"
        :maxCount="accountInfo.planDetail.maxDataSize"
      />
    </div>
    <div class="detail">
      <div class="item">
        <texts
          class="item-title"
          :text="$t('account.setting.accountUsage.projects')"
          size="small"
          color="gray"
        />
        <div class="item-detail">
          <texts
            v-if="!accountInfoLoading"
            class="item-count"
            :text="ownProjects"
            size="medium"
          />
        </div>
      </div>
      <div class="item">
        <texts
          class="item-title"
          :text="$t('account.setting.accountUsage.learnedAl')"
          size="small"
          color="gray"
        />
        <div class="item-detail">
          <texts class="item-count" :text="ownModels" size="medium" />
          <texts
            v-if="!accountInfoLoading"
            :text="
              '(' +
                $t('account.setting.accountUsage.maxDataServices') +
                accountInfo.planDetail.numModel +
                ')'
            "
            size="small"
            color="gray"
          />
        </div>
      </div>
      <div class="item">
        <texts
          class="item-title"
          :text="$t('account.setting.accountUsage.services')"
          size="small"
          color="gray"
        />
        <div class="item-detail">
          <texts class="item-count" :text="ownServices" size="medium" />
          <texts
            v-if="!accountInfoLoading"
            :text="
              '(' +
                $t('account.setting.accountUsage.maxDataServices') +
                accountInfo.planDetail.maxServices +
                ')'
            "
            size="small"
            color="gray"
          />
        </div>
      </div>
      <div class="item">
        <texts
          class="item-title"
          :text="$t('account.setting.accountUsage.totalStudyTime')"
          size="small"
          color="gray"
        />
        <div class="item-detail">
          <texts
            v-if="!accountInfoLoading"
            class="item-count"
            :text="formatTime(this.accountInfo.usedLearningTimeSec)"
            size="medium"
          />
        </div>
      </div>
      <div class="item">
        <texts
          class="item-title"
          :text="$t('account.setting.accountUsage.totalGPUStudyTime')"
          size="small"
          color="gray"
        />
        <div class="item-detail">
          <texts
            v-if="!accountInfoLoading"
            class="item-count"
            :text="formatTime(this.accountInfo.usedGPULearningTimeSec)"
            size="medium"
          />
        </div>
      </div>
    </div>
  </card-base>
</template>

<script>
import texts from '@/components/atoms/text.vue'
import cardBase from '@/components/atoms/card-base.vue'
import graphBarCount from '@/components/atoms/graph-bar-count.vue'
import { formatTime } from '@/lib/misc.js'

export default {
  components: {
    graphBarCount,
    cardBase,
    texts
  },
  computed: {
    maxDataSize() {
      return this.formatFileSize(this.accountInfo.planDetail.maxDataSize)
    },
    totalDataSize() {
      if (this.accountInfo.totalDataSize > 1024 * 1024) {
        return this.formatFileSize(this.accountInfo.totalDataSize, 2)
      } else {
        return this.formatFileSize(this.accountInfo.totalDataSize)
      }
    }
  },
  methods: {
    formatTime: formatTime
  },
  props: {
    accountInfo: Object,
    accountInfoLoading: Boolean,
    ownModels: Number,
    ownServices: Number,
    ownProjects: Number
  }
}
</script>

<style lang="scss" scoped>
.card {
  height: fit-content;
}

.title {
  margin: 0 0 $space-sub;
}

.user-data {
  margin: 0 0 $space-medium;
  &-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 $space-base;
  }
  &-detail {
    display: flex;
    align-items: center;
  }
  &-use {
    margin: 0 $space-sub 0 0;
  }
  &-graph {
    height: adjustVW(24);
    z-index: 1;
  }
}

.detail {
  display: flex;
  flex-wrap: wrap;
}

.item {
  width: 50%;
  margin: 0 0 $space-medium;
  &:nth-last-of-type(-n + 2) {
    margin: 0 0 $space-base;
  }
  &-title {
    margin: 0 0 $space-text;
  }
  &-detail {
    display: flex;
    align-items: center;
  }
  &-count {
    margin: 0 $space-base 0 0;
  }
}
</style>
