<template>
  <div class="service-delete-wrap">
    <texts
      class="service-delete-desc"
      :text="$t('trainedAiDetails.popup.serviceDeleteCheck.desc')"
      size="small"
    />
    <div class="service-delete-inner">
      <text-with-title
        class="service-delete-item"
        :text="trainedAi.summary.name"
        :title="$t('trainedAiDetails.popup.serviceDeleteCheck.deleteTrainedAi')"
      />
      <div class="service-delete-item">
        <texts
          class="service-delete-sub-title"
          :text="$t('trainedAiDetails.popup.serviceDeleteCheck.deleteServices')"
          size="small"
          color="gray"
        />
        <div
          v-for="(service, key) in trainedAi.summary.services"
          :key="key"
          class="service-delete-service-list"
        >
          <texts :text="service.name" />
        </div>
      </div>
    </div>
    <div class="service-delete-button-list">
      <button-main
        :text="$t('common.cancel')"
        type="sub"
        @click="$emit('close-modal', 'deleteServiceCheck')"
      />
      <button-main
        :text="$t('trainedAiDetails.popup.serviceDeleteCheck.button')"
        type="caution"
        @click="$emit('delete', trainedAi.summary.id)"
      />
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import textWithTitle from '@/components/molecules/text-with-title'

export default {
  components: {
    buttonMain,
    textWithTitle
  },
  props: {
    trainedAi: Object,
    disableClick: Boolean
  }
}
</script>

<style lang="scss" scoped>
.service-delete {
  &-wrap {
    width: adjustVW(744);
  }
  &-desc {
    margin-bottom: $space-medium;
    white-space: pre-line;
  }
  &-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $space-medium;
    margin-bottom: $space-medium;
  }
  &-item {
    overflow: hidden;
  }
  &-sub-title {
    margin-bottom: $space-min;
  }
  &-service-list {
    margin-bottom: $space-text;
  }
  &-button-list {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
