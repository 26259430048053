<!--
  タブをクリックして表示する要素を切り替える
-->
<template>
  <div class="tab-list" :class="{ 'tab-list-only': checkTabOnly }">
    <template v-for="tab in tabs">
      <div
        v-if="tab.tooltip"
        :key="tab.id"
        v-tooltip="tab.tooltip"
        class="tab-body"
      >
        <button
          :key="tab.id"
          class="tab-item"
          :class="{
            'tab-item-active': activeTab === tab.id,
            'tab-item-disabled': tab.disabled
          }"
          :value="tab.value"
          :disabled="tab.disabled"
          @click="$emit('change-tab', tab.value), changeTab(tab.id)"
        >
          <div v-if="showIcon === true" class="tab-icon">
            <icons :iconName="tab.iconName" color="emphasis" :size="24" />
          </div>

          <texts class="tab-text" :text="tab.name" color="emphasis" />
        </button>
      </div>

      <div v-else :key="tab.id" class="tab-body">
        <button
          :key="tab.id"
          class="tab-item"
          :class="{
            'tab-item-active': activeTab === tab.id,
            'tab-item-disabled': tab.disabled
          }"
          :value="tab.value"
          :disabled="tab.disabled"
          @click="$emit('change-tab', tab.value), changeTab(tab.id)"
        >
          <div v-if="showIcon === true" class="tab-icon">
            <icons :iconName="tab.iconName" color="emphasis" :size="24" />
          </div>

          <texts class="tab-text" :text="tab.name" color="emphasis" />
        </button>
      </div>

      <div v-if="tab.options" :key="`second-${tab.id}`" class="tab-options">
        <select-box
          v-bind="tab.options"
          @select-item="$emit('select-item', $event)"
        />
      </div>
    </template>
  </div>
  <!--tab-->
</template>

<script>
import texts from '@/components/atoms/text'
import icons from '@/components/atoms/icon'
import selectBox from './select-box'

export default {
  components: {
    texts,
    icons,
    selectBox
  },
  data() {
    return {
      activeTab: this.activeTabIndex
    }
  },
  props: {
    tabs: Array, // 表示するタブの一覧
    showIcon: {
      // iconを隠す
      type: Boolean,
      default: true
    },
    activeTabIndex: {
      type: Number,
      default: 0
    }
  },
  computed: {
    checkTabOnly() {
      const target = this.tabs.filter((tab) => tab?.disabled)
      return this.tabs.length - target.length < 2
    }
  },
  methods: {
    changeTab(e) {
      // タブの切替
      this.activeTab = e
    }
  },
  watch: {
    activeTabIndex(num) {
      this.activeTab = num
    }
  }
}
</script>

<style lang="scss" scoped>
.tab {
  &-list {
    overflow: hidden;
    display: flex;
    height: 100%;
    padding: $space-text;
    background: #f5eaf6;
    border-radius: adjustVW(8);
    &-only {
      background: $medium-gray;
    }
  }
  &-body {
    width: 100%;
  }
  &-item {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: fit-content;
    padding: adjustVW(8) $space-small adjustVW(8) $space-small;
    margin: 0 $space-base 0 0;
    border-radius: adjustVW(6);
    transition: background-color $transition-base, box-shadow $transition-base;
    &:last-child {
      margin: 0;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.6);
      box-shadow: 0 adjustVW(2) adjustVW(1) rgba(137, 4, 145, 0.05);
      opacity: 1;
    }
    &-active {
      background: $background;
      box-shadow: 0 adjustVW(2) adjustVW(1) rgba(137, 4, 145, 0.15);
      &:hover {
        background: $background;
        box-shadow: 0 adjustVW(2) adjustVW(1) rgba(137, 4, 145, 0.15);
        opacity: 1;
      }
    }
    &-disabled {
      cursor: not-allowed;
      opacity: 0.3;
      &:hover {
        background: none;
        opacity: 0.3;
      }
    }
  }
  &-icon {
    padding: 0 $space-base 0 0;
  }
}
</style>
