<template>
  <div>
    <texts :text="$t('organization.unable.firstDescription')" />
    <texts>
      <a class="link"> {{ $t('organization.unable.pagenaiton') }} </a>
      <span>{{ $t('organization.unable.lastDescription') }}</span>
    </texts>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import texts from '@/components/atoms/text.vue'

export default {
  components: {
    texts
  },
  props: {
    showPopup: Boolean
  }
}
</script>
