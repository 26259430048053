<template>
  <div
    class="trained-ai-detail-data"
    :class="{
      'trained-ai-detail-data-multi': predictionColumns.length > 1
    }"
  >
    <div class="trained-ai-detail-target">
      <div class="trained-ai-detail-title-sub">
        <texts
          :text="$t('projectDetail.objectiveVariable')"
          color="gray"
          size="small"
        />
      </div>
      <div
        class="
          trained-ai-detail-datas-inner trained-ai-detail-datas-inner-predict
        "
      >
        <div
          v-for="columnName in predictionColumns"
          :key="columnName"
          :class="
            predictionColumns.length > 1
              ? 'trained-ai-detail-datas-item'
              : 'trained-ai-detail-datas-item-one-line'
          "
        >
          <div class="trained-ai-detail-datas-dot" />
          <div class="trained-ai-detail-datas-text">
            <texts :text="columnName" size="small" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="inputColumns.length > 0" class="trained-ai-detail-datas">
      <div class="trained-ai-detail-datas-block">
        <div class="trained-ai-detail-title-sub">
          <texts
            :text="$t('trainedAiDetails.inputVariables')"
            color="gray"
            size="small"
          />
        </div>
        <div class="trained-ai-detail-datas-inner">
          <div
            v-for="columnName in inputColumns"
            :key="columnName"
            :class="
              predictionColumns.length > 1
                ? 'trained-ai-detail-datas-item-one-line'
                : 'trained-ai-detail-datas-item'
            "
          >
            <div class="trained-ai-detail-datas-dot" />
            <div class="trained-ai-detail-datas-text">
              <texts :text="columnName" size="small" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    trainedAi: Object,
    selectedColumnIndex: Number
  },
  computed: {
    inputColumns() {
      const selectedColumn = this.predictionColumns[this.selectedColumnIndex]
      if (
        this.trainedAi?.trainConfig?.overrideInputColumns?.[selectedColumn] !=
        null
      ) {
        return this.trainedAi?.trainConfig?.overrideInputColumns?.[
          selectedColumn
        ]
      }

      if (
        !this.trainedAi?.trainConfig ||
        !this.trainedAi?.trainConfig?.inputColumns ||
        this.trainedAi.trainConfig.inputColumns.length === 0
      ) {
        return []
      }

      return this.trainedAi.trainConfig.inputColumns
    },
    predictionColumns() {
      if (
        !this.trainedAi?.trainConfig ||
        !this.trainedAi?.trainConfig?.predictionColumn ||
        this.trainedAi.trainConfig.predictionColumn.length === 0
      )
        return []
      return this.trainedAi.trainConfig.predictionColumn
    }
  }
}
</script>

<style lang="scss" scoped>
.trained-ai-detail {
  &-data {
    display: flex;
    height: adjustVH(188);
    &-multi {
      .trained-ai-detail-target {
        width: adjustVW(580);
      }
      .trained-ai-detail-datas {
        width: adjustVW(200);
      }
    }
  }
  &-target {
    display: flex;
    flex-direction: column;
    width: adjustVW(200);
    padding: $space-small;
    margin: 0 $space-medium 0 0;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
  }
  &-datas {
    width: adjustVW(580);
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    &-block {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    &-inner {
      overflow-y: scroll;
      display: flex;
      flex-wrap: wrap;
      height: fit-content;
      @include scrollbar;
    }
    &-item {
      display: flex;
      align-items: center;
      width: calc(50% - #{adjustVW(6)});
      margin: 0 $space-sub $space-sub 0;
      &:nth-last-of-type(-n + 2) {
        margin: 0;
        &:nth-of-type(2n + 1) {
          margin: 0 $space-sub 0 0;
        }
      }
      &:nth-child(2n) {
        margin: 0 0 $space-sub;
      }
      &:last-of-type {
        margin: 0;
      }
      &-one-line {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: $space-sub;
        &:last-of-type {
          margin: 0;
        }
      }
    }
    &-dot {
      display: block;
      width: adjustVW(12);
      height: adjustVW(12);
      margin: 0 $space-base 0 0;
      background: $key-color;
      border-radius: 9in;
    }
    &-text {
      width: calc(100% - #{adjustVW(12) + $space-base});
    }
  }
}
</style>
