/*
  学習などの時に定期実行する
*/
export default {
  data() {
    return {
      polling: {
        timer: null
      }
    }
  },
  methods: {
    $polling_stopPolling() {
      if (this.polling.timer != null) {
        clearInterval(this.polling.timer)
        this.polling.timer = null
      }
    },
    $polling_startPolling(callback) {
      this.$polling_stopPolling()
      this.polling.timer = setInterval(callback, 10000)
    }
  },
  mounted() {},
  beforeUnmount() {
    this.polling_stopPolling()
  }
}
