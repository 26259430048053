<template>
  <div class="list-card-wrap">
    <template v-for="(item, index) in items">
      <card-base
        :key="index"
        :row="2"
        class="list-card-item"
        @click.native="$emit('click', index)"
      >
        <list-card-contents
          :id="index + 1"
          :trainedAi="item"
          :modelType="modelType"
          :metricsDefs="metricsDefs"
          :trainedAiInput="trainedAiInput[index]"
          :recipeType="recipeType"
          :sortedFlag="sortedFlag"
          :selectedColumnIndex="selectedColumnIndex"
        />
      </card-base>
    </template>
  </div>
</template>

<script>
import cardBase from '@/components/atoms/card-base'
import listCardContents from './list-card-contents'

export default {
  components: {
    cardBase,
    listCardContents
  },
  data() {
    return {}
  },
  props: {
    items: Array,
    modelType: String,
    metricsDefs: Object,
    trainedAiInput: Array,
    recipeType: String,
    sortedFlag: Boolean,
    selectedColumnIndex: Number
  }
}
</script>

<style lang="scss" scoped>
.list {
  &-card {
    &-wrap {
      overflow-y: auto;
      display: grid;
      grid-template-columns: calc(50% - #{$space-sub}) calc(50% - #{$space-sub});
      grid-template-rows: max-content;
      grid-column-gap: $space-medium;
      grid-row-gap: $space-medium;
      height: 100%;
      padding: 0 1.25vw;
      @include scrollbar;
    }
    &-item {
      width: 100%;
      height: auto;
      cursor: pointer;
      aspect-ratio: 2/1;
      &:hover {
        background: $background-decoration;
        opacity: 1;
      }
    }
  }
}
</style>
