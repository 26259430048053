<template>
  <div class="title-download">
    <template v-for="(obj, key) in buttonList">
      <icon-button
        :key="key"
        v-tooltip="downloadTips(obj)"
        class="title-download-button"
        iconName="download"
        size="min"
        :text="obj.text"
        @click="$emit(obj.event, encodingType)"
      />
    </template>
    <select-box
      v-model="encodingType"
      class="title-download-input"
      isGray
      min
      :items="downloadTypes"
    />
  </div>
</template>

<script>
import iconButton from '@/components/atoms/icon-button'
import selectBox from '@/components/molecules/select-box'

function isWindows() {
  return window.navigator.userAgent.toLowerCase().indexOf('windows') >= 0
}

export default {
  components: {
    iconButton,
    selectBox
  },
  data() {
    return {
      encodingType: isWindows() ? 'cp932' : 'utf8'
    }
  },
  props: {
    /** 学習時のダウンロードボタン用設定値 */
    download: {
      type: Object,
      default: null
    },
    buttonList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    downloadTypes() {
      return [
        {
          id: 0,
          name: 'Windows (Shift-JIS)',
          value: 'cp932'
        },
        {
          id: 1,
          name: 'Mac (UTF-8)',
          value: 'utf8'
        }
      ]
    }
  },
  methods: {
    downloadTips(obj) {
      let tips = obj?.tips?.default ?? ''
      if (this.download?.downloadComp) {
        tips =
          obj?.tips?.comp ??
          this.$t('trainedAiDetails.testDatasetTab.download.comp')
      } else if (this.download?.downloading) {
        tips =
          obj?.tips?.loading ??
          this.$t('trainedAiDetails.testDatasetTab.download.loading')
      }
      return {
        content: tips,
        hideOnTargetClick: false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  &-download {
    display: flex;
    grid-column-gap: $space-sub;
    &-input {
      width: adjustVW(188);
      height: adjustVW(32);
      &::v-deep {
        .c-func-select-button-text {
          font-size: $text-min;
          @include text-crop;
        }
      }
    }
  }
}
</style>
