<template>
  <div class="inference-regression-result-info">
    <transition-toggle-contents>
      <regression-features
        v-if="showFeatures"
        class="inference-regression-result-transition-item"
        :meanShap="meanShap"
        :usedAiInfo="selectedTrainedAi"
        :selectedItem="selectedItem"
        :selectedColumnIndex="selectedColumnIndex"
        :finishColumnsFix="finishColumnsFix"
        :loading="loading"
        @change-column="$emit('change-column', $event)"
      />
      <!--
        TODO 推論実行時のテキストマイニングの結果取得実装後修正
      <text-mining-result
        v-else-if="showTextMining"
        class="inference-regression-result-transition-item"
        :textMiningResult="textMiningResult"
        :textMiningSetting="textMiningSetting"
        @load-text-mining="$emit('load-text-mining', $event)"
        @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
      />
      -->
      <regression-result-info
        v-else
        class="inference-regression-result-transition-item"
        :targetColumns="targetColumns"
        :tableColumns="tableColumns"
        :table="table"
        :graphProb="graphProb"
        :importanceProb="importanceProb"
        :usedAiInfo="selectedTrainedAi"
        :total="total"
        :finishedTotal="finishedTotal"
        :limit="limit"
        :inPageNumber="inPageNumber"
        :selectedItem="selectedItem"
        :finishColumnsFix="finishColumnsFix"
        :loading="loading"
        :selectedColumnIndex="selectedColumnIndex"
        :inferencedColumns="inferencedColumns"
        @change-page="$emit('change-page', $event)"
        @change-column="$emit('change-column', $event)"
      />
    </transition-toggle-contents>
  </div>
</template>

<script>
import regressionResultInfo from '@/components/organisms/inference/result/regression/regression-result-info.vue'
import regressionFeatures from '@/components/organisms/inference/result/regression/regression-features.vue'
// import textMiningResult from '@/components/organisms/inference/result/text-mining/text-mining-result.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    regressionFeatures,
    regressionResultInfo,
    // textMiningResult,
    transitionToggleContents
  },
  props: {
    showFeatures: {
      type: Boolean,
      default: false
    },
    showTextMining: {
      type: Boolean,
      default: false
    },
    targetColumns: Array,
    tableColumns: Array,
    table: Array,
    graphProb: Array,
    importanceProb: Array,
    selectedTrainedAi: Object,
    meanShap: Object,
    total: Number,
    finishedTotal: Number,
    limit: Number,
    inPageNumber: Number,
    waitTable: Boolean,
    selectedItem: String,
    finishColumnsFix: Array,
    loadColumnResult: Boolean,
    selectedColumnIndex: Number,
    inferencedColumns: Array,
    textMiningResult: Object,
    textMiningSetting: Object
  },
  computed: {
    loading() {
      return this.waitTable || this.loadColumnResult
    }
  }
}
</script>

<style lang="scss" scoped>
.inference-regression-info-wrapper {
  width: 100%;
  height: 100%;
}

.inference-regression-result-info {
  height: 100%;
}
</style>
