<!--
  mySQLなどのデータベース管理システムや、将来的にはtableauなどの外部サービスの画像を表示する
-->
<template>
  <div
    class="datasource-type-wrap"
    :class="{ 'datasource-type-wrap-on': isOn }"
  >
    <svg
      :is="type + 'Icon'"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
    />
  </div>
</template>

<script>
import mysqlIcon from '../../assets/icons/mysql.svg'
import postgresqlIcon from '../../assets/icons/postgre.svg'
import oracleIcon from '../../assets/icons/oracle.svg'
import bigqueryIcon from '../../assets/icons/bigquery.svg'
import snowflakeIcon from '../../assets/icons/snowflake.svg'

export default {
  components: {
    mysqlIcon,
    postgresqlIcon,
    oracleIcon,
    bigqueryIcon,
    snowflakeIcon
  },
  props: {
    /** データソースの種類 [mysql , postgresql] */
    type: String,
    /** 選択状態の時背景を紫にする */
    isOn: Boolean
  }
}
</script>

<style lang="scss" scoped>
.datasource-type-wrap {
  overflow: hidden;
  width: 100%;
  height: auto;
  background: $background-sub;
  border-radius: adjustVW(8);
  &-on {
    background: $key-lite;
  }
}
</style>
