<template>
  <tutorial-popup
    :items="items"
    :show="showTutorial"
    type="tutorial-optimization-result"
    @close-tutorial="$emit('close-tutorial')"
  >
    <template #0>
      <texts
        class="tutorial-text"
        :text="$t('trainedAi.optimization.tutorial.optimizationResult.0')"
        size="small"
        color="gray"
      />
    </template>
    <template #1>
      <texts
        class="tutorial-text"
        :text="$t('trainedAi.optimization.tutorial.optimizationResult.1')"
        size="small"
        color="gray"
      />
    </template>
    <template #2>
      <texts
        class="tutorial-text"
        :text="$t('trainedAi.optimization.tutorial.optimizationResult.2')"
        size="small"
        color="gray"
      />
    </template>
    <template #3>
      <texts
        class="tutorial-text"
        :text="$t('trainedAi.optimization.tutorial.optimizationResult.3')"
        size="small"
        color="gray"
      />
    </template>
    <template #4>
      <texts
        class="tutorial-text"
        :text="$t('trainedAi.optimization.tutorial.optimizationResult.4')"
        size="small"
        color="gray"
      />
    </template>
  </tutorial-popup>
</template>

<script>
import tutorialPopup from '@/components/molecules/tutorial-popup.vue'
export default {
  components: {
    tutorialPopup
  },
  props: {
    showTutorial: Boolean
  },
  computed: {
    items() {
      return [
        {
          title: this.$t(
            'trainedAi.optimization.tutorial.optimizationResult.title.0'
          ),
          iconName: 'optimization',
          link: `${this.$urls.manual}train-training/最適化結果の見方/`,
          linkText: this.$t('common.tutorial.openManual'),
          margin: 8,
          marginHeight: 8
        },
        {
          title: this.$t(
            'trainedAi.optimization.tutorial.optimizationResult.title.1'
          ),
          iconName: 'optimization',
          link: `${this.$urls.manual}train-training/最適化結果の見方/`,
          linkText: this.$t('common.tutorial.openManual'),
          margin: 8
        },
        {
          title: this.$t(
            'trainedAi.optimization.tutorial.optimizationResult.title.2'
          ),
          iconName: 'optimization',
          link: `${this.$urls.manual}train-training/最適化結果の見方/`,
          linkText: this.$t('common.tutorial.openManual'),
          margin: 8,
          marginHeight: 8,
          showLeft: true
        },
        {
          title: this.$t(
            'trainedAi.optimization.tutorial.optimizationResult.title.3'
          ),
          iconName: 'optimization',
          link: `${this.$urls.manual}train-training/最適化結果の見方/`,
          linkText: this.$t('common.tutorial.openManual')
        },
        {
          title: this.$t(
            'trainedAi.optimization.tutorial.optimizationResult.title.4'
          ),
          iconName: 'optimization',
          link: `${this.$urls.manual}project-inference/新しい条件で最適化する/`,
          linkText: this.$t('common.tutorial.openManual'),
          margin: 32,
          marginHeight: 32,
          showBottomInner: true
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.tutorial-text {
  width: fit-content;
  white-space: pre-line;
}
</style>
