<template>
  <project-template-edit
    v-bind="data"
    :headerTabs="headerTabs"
    :loading="loading"
    :project="project"
    :sidebar="sidebar"
    @cancel-edit-form="cancelEditForm()"
    @on-file-input="onFileInput($event)"
    @submit-edit-form="submitEditForm($event)"
    @tag-add="templateTagAdd($event)"
    @tag-remove="templateTagRemove($event)"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import setMountedTimer from '@/mixin/set-mounted-timer'

import projectTemplateEdit from '@/components/templates/project-template-edit.vue'

export default {
  components: {
    projectTemplateEdit
  },
  mixins: [setMountedTimer],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loadPage = true

      await vm.$waitConnected()
      await vm.loadProjectTemplateList()
      await vm.loadProjectTemplateDetail({
        projectTemplateId: vm.projectTemplateId
      })

      vm.setProjectTemplateInfo({
        templateId: vm.projectTemplateId
      })
      vm.setSampleDataset({
        templateId: vm.projectTemplateId
      })
      vm.setProjectTemplateEffect({
        templateId: vm.projectTemplateId
      })

      vm.loadPage = false
    })
  },
  data() {
    return {
      data: {
        body: {
          projectTemplateEffect: {},
          projectTemplateInfo: {},
          sampleDataset: {
            sampleDatasetList: [],
            selectedDatasetId: ''
          },
          showEditForm: 'summary',
          submitting: false,
          summaryImages: {
            main: {
              file: {
                item: null,
                name: ''
              },
              uploadErrors: {
                flag: false,
                message: []
              },
              uploadWarnings: []
            },
            sub1: {
              file: {
                item: null,
                name: ''
              },
              uploadErrors: {
                flag: false,
                message: []
              },
              uploadWarnings: []
            },
            sub2: {
              file: {
                item: null,
                name: ''
              },
              uploadErrors: {
                flag: false,
                message: []
              },
              uploadWarnings: []
            }
          }
        },
        popup: {
          showPopup: []
        }
      },
      editInfo: {
        tag: {
          add: [],
          remove: []
        }
      },
      loadPage: false,
      submitData: {
        effect: null,
        sampleDataset: null,
        summary: null
      }
    }
  },
  computed: {
    ...mapGetters('project', ['projectList', 'projectLoading']),
    ...mapGetters('projectTemplate', ['createdProjectTemplate', 'uploadState']),
    ...mapGetters('projectTemplate', {
      projectTemplateList: 'projectList'
    }),

    headerTabs: function () {
      return {
        tabs: [],
        tabSelect: 1
      }
    },
    loading: function () {
      return this.loadPage || this.projectLoading
    },
    project: function () {
      return this.projectList[this.projectId]
    },
    projectId: function () {
      return this.$route.params.projectId
        ? parseInt(this.$route.params.projectId)
        : null
    },
    projectTemplateId: function () {
      return String(this.$route.params.projectTemplateId)
    },
    sidebar() {
      return null
    }
  },
  methods: {
    ...mapActions('projectTemplate', [
      'addProjectTemplateTag',
      'loadProjectList',
      'loadProjectTemplateDetail',
      'removeProjectTemplateTag',
      'updateProjectTemplateThumbnail',
      'updateProjectTemplateEffect',
      'updateProjectTemplateEffectImage',
      'updateProjectTemplateSampleDataset',
      'updateProjectTemplateSummary',
      'uploadImage'
    ]),
    ...mapActions('projectTemplate', {
      loadProjectTemplateList: 'loadProjectList'
    }),

    cancelEditForm: function () {
      this.$router.go(-1)
    },
    closePopup: function (e) {
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
    },
    onFileInput: function ({ file, index }) {
      const info = {
        file: {
          item: file,
          name: file.name.substr(0, file.name.lastIndexOf('.'))
        },
        uploadErrors: {
          flag: false,
          message: []
        },
        uploadWarnings: []
      }

      if (index === 0) {
        this.$set(this.data.body.summaryImages, 'main', info)
      } else if (index === 1) {
        this.$set(this.data.body.summaryImages, 'sub1', info)
      } else if (index === 2) {
        this.$set(this.data.body.summaryImages, 'sub2', info)
      }
    },
    setSampleDataset: function ({ templateId }) {
      const selectedDataset = this.projectTemplateList[templateId].sampleDataset

      this.$set(
        this.data.body.sampleDataset,
        'selectedDatasetId',
        selectedDataset.id
      )

      const originalProject = this.projectTemplateList[templateId].project

      const listDataset =
        originalProject.numData > 0 ? originalProject.listData : []

      this.$set(this.data.body.sampleDataset, 'sampleDatasetList', listDataset)
    },
    setProjectTemplateEffect: function ({ templateId }) {
      const projectTemplate = this.projectTemplateList[templateId]
      const templateEffect = projectTemplate.effects[0]
        ? projectTemplate.effects[0]
        : { name: '', description: '', formula: {} }

      this.$set(this.data.body, 'projectTemplateEffect', templateEffect)
    },
    setProjectTemplateInfo: function ({ templateId }) {
      const keys = Object.keys(this.projectTemplateList)

      keys.forEach((key) => {
        if (this.projectTemplateList[key].id === Number(templateId)) {
          this.$set(
            this.data.body,
            'projectTemplateInfo',
            this.projectTemplateList[key]
          )
        }
      })
    },
    setSubmitData: function ({ form, term }) {
      this.$set(this.submitData, term, form)
    },
    showEditForm: function (formName) {
      this.$set(this.data.body, 'showEditForm', formName)
    },
    showPopup: function (e) {
      this.popup.showPopup.push(e)
    },
    submitTemplateData: async function () {
      try {
        await this.updateTemplateSummary({ form: this.submitData.summary })

        if (this.submitData.sampleDataset !== null) {
          await this.updateTemplateSampleDataset({
            form: this.submitData.sampleDataset
          })
        }

        await this.updateTemplateEffect({ form: this.submitData.effect })
        await this.updateTemplateEffectImage()
      } catch (err) {
        this.log_info(err)
      }
    },
    submitEditForm: async function ({ type, form }) {
      this.data.body.submitting = true

      try {
        switch (type) {
          case 'projectTemplate': {
            this.setSubmitData({ form, term: 'summary' })

            const sampleDataset = this.data.body.sampleDataset.sampleDatasetList

            if (sampleDataset.length > 0) this.showEditForm('sampleDataset')
            else this.showEditForm('effect')

            break
          }
          case 'projectTemplateSampleDataset': {
            this.setSubmitData({ form, term: 'sampleDataset' })
            this.showEditForm('effect')

            break
          }
          case 'projectTemplateEffect': {
            this.setSubmitData({ form, term: 'effect' })
            this.showEditForm('effectImage')

            break
          }
          case 'projectTemplateEffectImage': {
            await this.submitTemplateData()

            break
          }
          default: {
            throw new Error('form type invalid.')
          }
        }
      } catch (err) {
        this.log_info(err)
      } finally {
        this.data.body.submitting = false
      }
    },
    templateTagAdd: function (tag) {
      const newTagList = this.editInfo.tag.remove
      const isNewTag = !newTagList.includes(tag)

      if (isNewTag) {
        this.editInfo.tag.add.push(tag)
      } else {
        this.editInfo.tag.remove = this.editInfo.tag.remove.filter(
          (newTag) => newTag !== tag
        )
      }
    },
    templateTagRemove: function (tag) {
      const newTagList = this.editInfo.tag.add
      const isNewTag = !newTagList.includes(tag)

      if (isNewTag) {
        this.editInfo.tag.remove.push(tag)
      } else {
        this.editInfo.tag.add = this.editInfo.tag.add.filter(
          (newTag) => newTag !== tag
        )
      }
    },
    updateTemplateEffect: async function ({ form }) {
      this.updateProjectTemplateEffect({
        projectTemplateId: this.projectTemplateId,
        ...form
      })

      this.showEditForm('effectImage')
    },
    updateTemplateEffectImage: async function () {
      const mainImage = this.data.body.summaryImages.main.file
      const sub1Image = this.data.body.summaryImages.sub1.file
      const sub2Image = this.data.body.summaryImages.sub2.file

      if (mainImage.item !== null)
        await this.uploadNewTemplateEffectImage(mainImage, 1)
      if (sub1Image.item !== null)
        await this.uploadNewTemplateEffectImage(sub1Image, 2)
      if (sub2Image.item !== null)
        await this.uploadNewTemplateEffectImage(sub2Image, 3)

      await this.loadProjectTemplateList()
      this.$router.push({
        name: 'projectTemplateList'
      })
    },
    updateTemplateSampleDataset: async function ({ form }) {
      const { datasetId } = form
      const req = {
        projectTemplateId: this.projectTemplateId,
        sampleDatasetId: datasetId
      }

      await this.updateProjectTemplateSampleDataset(req)
    },
    updateTemplateSummary: async function ({ form }) {
      await this.updateProjectTemplateSummary({
        projectTemplateId: this.projectTemplateId,
        ...form
      })

      if (this.editInfo.tag.add.length > 0) {
        await this.addProjectTemplateTag({
          projectTemplateId: this.projectTemplateId,
          tags: this.editInfo.tag.add
        })
      }

      if (this.editInfo.tag.remove.length > 0) {
        await this.removeProjectTemplateTag({
          projectTemplateId: this.projectTemplateId,
          tags: this.editInfo.tag.remove
        })
      }

      if (form.thumbnail?.item) {
        await this.updateProjectTemplateThumbnail({
          projectTemplateId: this.projectTemplateId,
          fileName: form.thumbnail.item.name,
          fileSize: form.thumbnail.item.size
        })
        await this.uploadImage({
          action: 'uploadingData',
          messageId: this.uploadState.thumbnail_m_id,
          file: form.thumbnail.item
        })
      }
    },
    uploadNewTemplateEffectImage: async function (file, index) {
      this.data.body.submitting = true
      const projectTemplateId = this.projectTemplateId

      await this.updateProjectTemplateEffectImage({
        imageIndex: index,
        projectTemplateId: projectTemplateId,
        fileName: file.item.name,
        fileSize: file.item.size
      })

      await this.uploadImage({
        action: 'uploadingData',
        messageId: this.uploadState.effect_m_id,
        file: file.item
      })
      this.data.body.submitting = false
    }
  }
}
</script>
