<template>
  <div class="delete-wrap">
    <div class="delete-item-title">
      <texts :text="trainedAi.summary.name" isBold />
      <texts
        v-if="trainedAi.summary.services.length"
        :text="$t('trainedAiDetails.popup.serviceDeleteCheck.caution')"
        color="caution"
      />
    </div>
    <trained-ai-delete-inner
      class="delete-item-body"
      :entry="trainedAi.summary"
      isDetail
    />
    <div class="delete-button-wrap">
      <div class="delete-button-inner">
        <button-main
          :text="$t('common.cancel')"
          type="sub"
          :disabled="disableClick"
          @click="$emit('close-modal', 'deleteTrainedAi')"
        />
      </div>
      <div class="delete-button-inner">
        <button-main
          :text="$t('common.deleteButton')"
          type="caution"
          :disabled="disableClick"
          @click="deleteTrainedAi"
        />
      </div>
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import trainedAiDeleteInner from '@/components/organisms/trained-ai-common/trained-ai-delete-inner'

export default {
  components: {
    buttonMain,
    trainedAiDeleteInner
  },
  props: {
    trainedAi: Object,
    disableClick: Boolean
  },
  methods: {
    deleteTrainedAi() {
      if (this.trainedAi?.summary?.services.length > 0) {
        this.$emit('check-service')
      } else {
        this.$emit('delete', this.trainedAi.summary.id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.delete {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: adjustVW(724);
    height: 100%;
  }
  &-item {
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $space-base;
    }
    &-body {
      margin-bottom: $space-medium;
    }
  }
  &-button {
    &-wrap {
      display: flex;
      justify-content: flex-end;
      padding: 0 $space-small 0 0;
    }
    &-inner {
      margin: 0 $space-small 0 0;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
