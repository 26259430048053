<template>
  <div class="accuracy-card" :class="{ 'accuracy-card-small': isSmall }">
    <metrics
      :name="metrics.name"
      :value="metrics.value"
      :percentage="metrics.percentage"
    />
    <tooltip-popover
      v-if="metrics.info != null"
      class="accuracy-card-inner"
      trigger="hover"
      placement="right-center"
      :autoHide="false"
      :offset="8"
    >
      <template slot="main">
        <icons
          class="accuracy-card-info"
          iconName="info"
          :size="22"
          color="gray"
        />
      </template>
      <template slot="popup">
        <accuracy-tooltip-inner :metrics="metrics" />
      </template>
    </tooltip-popover>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import tooltipPopover from '@/components/molecules/tooltip-popover'
import metrics from '@/components/molecules/graph/metrics'
import accuracyTooltipInner from '@/components/molecules/accuracy-tooltip-inner'

export default {
  components: {
    icons,
    tooltipPopover,
    metrics,
    accuracyTooltipInner
  },
  props: {
    metrics: Object,
    isSmall: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.accuracy {
  &-card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - #{$space-small / 2});
    height: adjustVH(160);
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(8);
    box-shadow: $box-shadow-main;
    &-inner {
      position: absolute;
      top: $space-sub;
      right: $space-sub;
    }
    &-info {
      cursor: help;
    }
    &-small {
      height: adjustVH(144);
    }
  }
}
</style>
