<template>
  <div class="wrap">
    <optimization-result-detail
      class="inner"
      :accountInfo="accountInfo"
      :selectedConditions="selectedConditions"
      :optimizationInfo="optimizationInfo"
      :optimizationResult="optimizationResult"
      isInference
      @close-optimization-result-tutorial="
        $emit('close-optimization-result-tutorial')
      "
      @input-edit-form="$emit('input-edit-optimization-form', $event)"
      @save-edit-optimization-form="
        $emit('save-edit-optimization-form', $event)
      "
      @change-page="$emit('change-optimization-page', $event)"
      @change-filter-value="$emit('change-optimization-filter-value', $event)"
      @download-result="$emit('download-optimization-result', $event)"
    />
  </div>
</template>

<script>
import optimizationResultDetail from '@/components/organisms/optimization/optimization-result-detail'

export default {
  components: {
    optimizationResultDetail
  },
  props: {
    accountInfo: Object,
    optimizationInfo: Object,
    optimizationResult: Object,
    selectedConditions: Object
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100%;
}
.inner {
  height: 100%;
  padding: 0 $space-medium $space-medium;
}
</style>
