<template>
  <!-- v0.4.1 以降で複数の精度指標を表示するための component -->
  <div>
    <toggle-card :title="$t('trainedAi.others.title')" color="gray">
      <div class="accuracy-indexes-wrapper">
        <div
          v-for="(metrics, index) in metricsFix"
          :key="index"
          class="accuracy-indexes-item"
        >
          <texts :text="metrics.name" size="small" color="gray" />
          <metrics-text
            v-if="metrics.value != null"
            :value="metrics.value"
            size="small"
            color="green"
            :percentage="metrics.percentage"
          />
          <texts
            v-else
            :text="$t('trainedAi.others.noneValue')"
            size="small"
            color="gray"
          />
        </div>
      </div>
    </toggle-card>
  </div>
</template>

<script>
import { indexes } from '../../metricsDefs.js'

import toggleCard from '@/components/molecules/toggle-card.vue'
import texts from '@/components/atoms/text'
import metricsText from '@/components/atoms/metrics-text'

export default {
  components: {
    texts,
    metricsText,
    toggleCard
  },
  props: {
    accuracyIndexes: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: 'CLASSIFICATION'
    }
  },
  computed: {
    metricsFix() {
      const typeKey = this.type.toLowerCase()
      const indexesMap = Object.entries(this.accuracyIndexes).map(
        ([key, value]) => {
          const target = indexes(this.$t.bind(this))[typeKey][key]
          return {
            ...target,
            value
          }
        }
      )
      return indexesMap.sort((prev, current) => {
        return prev.index - current.index
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.accuracy-indexes-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: $space-small;
  grid-row-gap: $space-sub;
  height: 100%;
  margin-top: $space-sub;
}

.accuracy-indexes-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: $space-small;
  &:not(:nth-child(2n)) {
    padding-right: $space-small;
    border-right: $border-sub;
  }
}
</style>
