<template>
  <!--
    データソース一覧のポップアップ
  -->
  <div class="popup-show-datasource-wrap">
    <transition-toggle-contents>
      <!-- ↓↓ 登録しているデータソース一覧表示 ↓↓ -->
      <div
        v-if="!isAddTable && !isEditSql"
        key="notAddTable"
        class="popup-show-datasource-list"
      >
        <datasource-contents
          :datasourceInfo="datasourceInfo"
          :dataSourceItems="dataSourceItems"
          :dataSourceSqlItems="dataSourceSqlItems"
          :loadingDatasourceItems="loadingDatasourceItems"
          :connectionError="importErrorOnly"
          @set-datasource="$emit('set-datasource', $event)"
          @show-table="showTable"
          @get-sql-list="$emit('get-sql-list', $event)"
          @show-sql-edit="showSqlEdit"
        />
      </div>
      <!-- ↑↑ 登録しているデータソース一覧表示 ↑↑ -->

      <!-- ↓↓ テーブルの詳細 ↓↓ -->
      <div v-else key="isAddTable" class="popup-show-add-table-wrap">
        <datasource-import-setting
          :datasourceId="datasourceInfo.id"
          :datasetFormValidate="datasetFormValidate"
          :importLoading="importLoading"
          :disableClick="disableClick"
          :datasourceItemsInfo="datasourceItemsInfo"
          :loadingdatasourceItemsInfo="loadingdatasourceItemsInfo"
          :maxRows="maxRows"
          :isAddTable="isAddTable"
          :isEditSql="isEditSql"
          :showSqlValue="showSqlValue"
          :dataSourceSqlItems="dataSourceSqlItems"
          :importFromDataSourceTableName="importFromDataSourceTableName"
          @close-table="closeTable"
          @do-import="doImport"
          @input-create-form="$emit('input-create-form', $event)"
        />
        <transition-toggle-contents>
          <div v-if="datasourceErrorText" class="popup-show-add-table-error">
            <text-box
              isError
              :text="datasourceErrorText"
              color="caution"
              size="min"
            />
          </div>
        </transition-toggle-contents>
      </div>
      <!-- ↑↑ テーブルの詳細 ↑↑ -->
    </transition-toggle-contents>

    <!-- ↓↓ 右側に表示するデータセット ↓↓ -->
    <div class="popup-show-datasource-table">
      <template v-if="!loadingdatasourceItemsInfo">
        <transition-toggle-contents>
          <div v-if="isShowTable" class="popup-show-datasource-table-inner">
            <table-main
              :tableColumns="Object.keys(datasourceItemsInfo.columns)"
              :table="datasourceItemsInfo.data"
              :pagingCount="5"
            />
          </div>
          <datasource-table-column-list
            v-else-if="isEditSql"
            :dataSourceItems="dataSourceItems"
            :sqlTablePreviewData="sqlTablePreviewData"
            :datasourceId="datasourceInfo.id"
            :loadingSqlTablePreview="loadingSqlTablePreview"
            @select-sql-preview-table="
              $emit('select-sql-preview-table', $event)
            "
          />
          <select-dataset-image v-else />
        </transition-toggle-contents>
      </template>
      <template v-else>
        <loading />
      </template>
    </div>
    <!-- ↑↑ 右側に表示するデータセット ↑↑ -->
  </div>
</template>

<script>
import loading from '@/components/atoms/loading'
import textBox from '@/components/atoms/text-box.vue'
import selectDatasetImage from '@/components/molecules/select-dataset-image'
import tableMain from '@/components/molecules/table-main'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import datasourceContents from './datasource-contents.vue'
import datasourceImportSetting from './datasource-import-setting.vue'
import datasourceTableColumnList from './datasource-table-column-list'

export default {
  components: {
    loading,
    textBox,
    selectDatasetImage,
    tableMain,
    transitionToggleContents,
    datasourceContents,
    datasourceImportSetting,
    datasourceTableColumnList
  },
  data() {
    return {
      tableName: '',
      validate: {
        accept: null,
        error: null
      },
      isAddTable: false,
      isEditSql: false,
      isShowTable: false,
      showSqlValue: null
    }
  },
  computed: {
    datasourceErrorText() {
      if (this.importError == null) return null
      return this.$t(
        `datasetList.datasourceList.importError.${this.importError}`
      )
    }
  },
  watch: {
    datasetFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    }
  },
  methods: {
    showTable: function (payload) {
      this.isAddTable = true
      this.isShowTable = true
      this.$emit('show-table', payload)

      if (this.tableName !== payload.table.name)
        this.initTableInfo(payload.table.name)
    },
    initTableInfo: function (selectedTable) {
      this.tableName = selectedTable.name
    },
    closeTable() {
      this.isAddTable = false
      this.isShowTable = false
      this.isEditSql = false
      this.showSqlValue = null
    },
    doImport(values) {
      this.$emit('do-import', values)
    },
    showSqlEdit(id) {
      this.showSqlValue = id ?? null
      this.isEditSql = true
    }
  },
  props: {
    datasourceInfo: Object,
    datasetFormValidate: Object,

    dataSourceItems: Array,
    loadingDatasourceItems: Boolean,

    datasourceItemsInfo: Object,
    loadingdatasourceItemsInfo: Boolean,
    maxRows: Number,
    importLoading: Boolean,
    disableClick: Boolean,

    dataSourceSqlItems: Array,
    importFromDataSourceTableName: String,
    sqlTablePreviewData: Object,
    loadingSqlTablePreview: Boolean,
    importErrorOnly: Boolean,
    importError: String
  }
}
</script>

<style lang="scss" scoped>
.popup-show-datasource {
  &-wrap {
    display: grid;
    grid-template-columns: adjustVW(592) adjustVW(960);
    grid-template-rows: 100%;
    grid-column-gap: adjustVW(24);
    height: 100%;
  }
  &-list {
    height: 100%;
  }
  &-table {
    padding: $space-small;
    background: $background-sub;
    border-radius: adjustVW(16);
    &-title {
      padding: 0 0 $space-base;
      margin: 0 0 $space-base;
      border-bottom: $border-sub;
    }
    &-inner {
      overflow: hidden;
      height: 100%;
      padding: $space-small $space-small $space-small $space-medium;
      background: $background;
      border-radius: adjustVW(16);
      box-shadow: $box-shadow-main;
    }
  }
  &-toggle {
    &-info {
      padding: 0 0 $space-medium;
      margin: 0 0 $space-medium;
      border-bottom: $border-sub;
    }
    &-block {
      display: flex;
      width: 100%;
      margin: 0 0 $space-small;
      &:last-child {
        margin: 0;
      }
    }
    &-inner {
      margin: 0 $space-medium 0 0;
      &:last-child {
        overflow-x: hidden;
        margin: 0;
      }
    }
    &-text {
      margin: 0 0 $space-sub;
      &:last-child {
        margin: 0;
      }
    }
    &-type {
      display: flex;
      align-items: center;
      justify-content: center;
      width: adjustVW(120);
      height: adjustVH(80);
      background: $background-sub;
    }
    &-setting {
      margin: 0 0 0 auto;
    }
  }
}

.popup-show-add-table {
  &-block {
    margin: 0 0 $space-medium;
    &:last-child {
      margin: 0;
    }
    &-checkbox {
      overflow: hidden;
      flex-shrink: 1;
    }
  }
  &-check-wrap {
    overflow: auto;
    max-height: adjustVH(232);
    @include scrollbar;
  }
  &-limit {
    &-columns {
      display: flex;
      align-items: center;
      padding: $space-base 0;
      margin: 0 0 $space-small;
      border-top: $border-sub;
      border-bottom: $border-sub;
    }
    &-input {
      display: flex;
      align-items: center;
      &-box {
        margin: 0 $space-base 0 0;
      }
    }
  }
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  &-error {
    flex-shrink: 0;
    height: fit-content;
    margin-top: $space-small;
  }
}
</style>
