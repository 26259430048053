var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column-info"},[_c('div',{staticClass:"column-info-list"},[_c('card-base',{staticClass:"column-info-card"},[_c('div',{staticClass:"column-info-list-body"},[_c('div',{staticClass:"column-info-top"},[_c('texts',{staticClass:"column-info-title",attrs:{"text":_vm.$t('trainedAi.timeTransformerV2.conditions.title'),"size":"large"}}),_c('texts',{staticClass:"column-info-desc",attrs:{"text":_vm.$t('trainedAi.timeTransformerV2.conditions.description'),"size":"small"}})],1),_c('list-item-slot',{staticClass:"column-info-list-inner",attrs:{"field":_vm.fields,"items":_vm.items},scopedSlots:_vm._u([{key:"isKnownField",fn:function(){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                _vm.$t('trainedAi.timeTransformerV2.conditions.type.isKnown.info')
              ),expression:"\n                $t('trainedAi.timeTransformerV2.conditions.type.isKnown.info')\n              "}]},[_c('text-with-icon',{attrs:{"text":_vm.$t(
                    'trainedAi.timeTransformerV2.conditions.type.isKnown.title'
                  ),"iconName":"info","size":"small","pos":"right","isBold":""}})],1)]},proxy:true},{key:"isTimeVaryingField",fn:function(){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                _vm.$t(
                  'trainedAi.timeTransformerV2.conditions.type.isTimeVarying.info'
                )
              ),expression:"\n                $t(\n                  'trainedAi.timeTransformerV2.conditions.type.isTimeVarying.info'\n                )\n              "}]},[_c('text-with-icon',{attrs:{"text":_vm.$t(
                    'trainedAi.timeTransformerV2.conditions.type.isTimeVarying.title'
                  ),"iconName":"info","size":"small","pos":"right","isBold":""}})],1)]},proxy:true},{key:"isRealField",fn:function(){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                _vm.$t('trainedAi.timeTransformerV2.conditions.type.isReal.info')
              ),expression:"\n                $t('trainedAi.timeTransformerV2.conditions.type.isReal.info')\n              "}]},[_c('text-with-icon',{attrs:{"text":_vm.$t(
                    'trainedAi.timeTransformerV2.conditions.type.isReal.title'
                  ),"iconName":"info","size":"small","pos":"right","isBold":""}})],1)]},proxy:true},{key:"columnName",fn:function(entry){return [_c('texts',{attrs:{"text":entry.item,"size":"small"}})]}},{key:"isKnown",fn:function(entry){return [_c('div',[_c('texts',{attrs:{"text":_vm.conditionText('isKnown', entry.item.value),"size":"small","color":'default'}})],1)]}},{key:"isTimeVarying",fn:function(entry){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                entry.item.auto
                  ? _vm.$t('trainedAi.timeTransformerV2.conditions.autoSetting')
                  : ''
              ),expression:"\n                entry.item.auto\n                  ? $t('trainedAi.timeTransformerV2.conditions.autoSetting')\n                  : ''\n              "}]},[_c('texts',{attrs:{"text":_vm.conditionText('isTimeVarying', entry.item.value),"size":"small","color":entry.item.auto ? 'emphasis' : 'default'}})],1)]}},{key:"isReal",fn:function(entry){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                entry.item.auto
                  ? _vm.$t('trainedAi.timeTransformerV2.conditions.autoSetting')
                  : ''
              ),expression:"\n                entry.item.auto\n                  ? $t('trainedAi.timeTransformerV2.conditions.autoSetting')\n                  : ''\n              "}]},[_c('texts',{attrs:{"text":_vm.conditionText('isReal', entry.item.value),"size":"small","color":entry.item.auto ? 'emphasis' : 'default'}})],1)]}}])})],1)])],1),_c('div',{staticClass:"column-info-side"},[(_vm.checkPeriod)?_c('card-base',[_c('div',{staticClass:"column-info-setting"},[_c('div',[_c('text-with-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
              _vm.$t('trainedAi.timeTransformerV2.duration.predict.description')
            ),expression:"\n              $t('trainedAi.timeTransformerV2.duration.predict.description')\n            "}],staticClass:"column-info-title",attrs:{"text":_vm.$t('trainedAi.timeTransformerV2.duration.predict.title'),"iconName":"info","size":"small","color":"gray","pos":"right"}}),_c('texts',{attrs:{"text":_vm.trainedAi.trainConfig.timeseries.maxPredictionLength}})],1),_c('div',[_c('text-with-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
              _vm.$t('trainedAi.timeTransformerV2.duration.input.description')
            ),expression:"\n              $t('trainedAi.timeTransformerV2.duration.input.description')\n            "}],staticClass:"column-info-title",attrs:{"text":_vm.$t('trainedAi.timeTransformerV2.duration.input.title'),"iconName":"info","size":"small","color":"gray","pos":"right"}}),_c('texts',{attrs:{"text":_vm.trainedAi.trainConfig.timeseries.maxEncoderLength}})],1)])]):_vm._e(),_c('card-base',{staticClass:"column-info-card"},[_c('div',{staticClass:"column-info-side-body"},[_c('setting-list',{attrs:{"settingList":_vm.trainedAi.trainConfig.columnConditions,"predictionColumns":_vm.trainedAi.summary.predictionColumns,"noFilter":""}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }