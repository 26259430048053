<template>
  <div
    class="select-inference-type-wrapper"
    :class="{
      'select-inference-type-wrapper-large': isLargeContent
    }"
  >
    <div
      class="select-inference-type-item"
      :class="{
        'select-inference-type-time':
          isTime || isMfT || isTimeTransformerV2 || isClustering
      }"
    >
      <div class="select-inference-type-item-type">
        <card-base class="c-card-inference">
          <div class="select-inference-type-card-tittle">
            <texts
              :text="
                $t('inference.settings.selectInferenceType.inferenceType.title')
              "
              size="large"
            />
          </div>
          <select-box
            :value="selectedInferenceType"
            :isGray="true"
            :items="makeInferenceTypeList"
            @select-item="selectItem"
          />
        </card-base>
      </div>
      <div v-if="isTime" class="select-inference-type-item-time">
        <card-base class="timeseries-settings-wrap">
          <div class="timeseries-settings">
            <input-box
              :value="settings.time"
              class="timeseries-settings-input"
              number
              :min="1"
              :title="$t('inference.result.timeseries.term')"
              isGray
              @input="setSettings($event, 'time')"
            />
            <div class="timeseries-settings-input">
              <texts
                :text="$t('inference.settings.seasonalityTimeUnit')"
                class="timeseries-settings-title"
                size="small"
                color="gray"
              />
              <select-box
                :value="settings.timeUnit"
                class="unit"
                isGray
                :items="timeUnitOptions"
                scrollBar
                @input="setSettings($event, 'timeUnit')"
              />
            </div>
            <div>
              <texts
                :text="$t('inference.settings.selectPredictTime')"
                size="small"
                color="gray"
                class="timeseries-settings-title"
              />
              <timeseries-first-last-date
                :forecast="trainedAiDetail.forecast"
                :observations="trainedAiDetail.observations"
                :setDateTime="parseInt(settings.time)"
                :timeUnit="settings.timeUnit"
                :type="trainedAi.inferenceType"
              />
            </div>
          </div>
        </card-base>
      </div>
      <div v-if="isClustering" class="select-inference-type-item-clustering">
        <card-base class="clustering-wrap">
          <div>
            <div class="clustering-result-head-title">
              <texts
                :text="$t('clustering.result.displayedClassCount')"
                size="small"
                color="gray"
              />
            </div>
            <select-box-input
              class="clustering-result-head-input"
              :firstSelectedItem="settings.targetClustering.toString()"
              :value="settings.targetClustering"
              :items="clusteringSelectItems"
              isGray
              isSearch
              isScroll
              @select-item="
                inputClusteringSetting($event.selectItem, 'changeClass')
              "
            />
          </div>
        </card-base>
      </div>
      <div class="select-inference-type-item-info">
        <card-base
          class="c-card-inference select-inference-type-item-info-card"
          :class="{
            'select-inference-type-item-info-card-classification':
              displayThreshold || hasConfusionMatrix,
            'select-inference-type-item-info-card-multi':
              !displayThreshold && isMultiple && !isTime && !isTimeTransformerV2
          }"
        >
          <texts
            class="select-inference-type-item-info-card-title"
            size="large"
            :text="
              isMultiple
                ? $t('inference.settings.timeseriesMultiColumn.title')
                : $t(
                  'inference.settings.selectInferenceType.choosedTrainedAi.title'
                )
            "
          />
          <div
            v-if="isMultiple"
            class="select-inference-type-item-info-card-list"
          >
            <model-setting-list
              :trainedAi="trainedAi"
              :trainedAiDetail="trainedAiDetailFullInfo"
              :predictionColumn="predictionColumn"
              :loading="loadColumnInfo"
              :selectedInferenceType="selectedInferenceType"
              :changeableThreshold="changeableThreshold"
              :displayThreshold="displayThreshold"
              :isTimeTransformerV2="isTimeTransformerV2"
              @change-trained-ai-detail="
                $emit('change-trained-ai-detail', $event)
              "
              @input-multi-inference-column="
                $emit('input-multi-inference-column', $event)
              "
            />
          </div>
          <div v-else>
            <model-setting
              :trainedAiDetail="trainedAiDetailFullInfo"
              :changeableThreshold="changeableThreshold"
              :columnSetting="columnSetting"
              :displayThreshold="displayThreshold"
              :hasConfusionMatrix="hasConfusionMatrix"
              :loadChangeColumn="loadColumnInfo"
              @change-threshold="changeThreshold"
              @reverse-positive="reversePositive"
            />
          </div>
        </card-base>
      </div>
    </div>

    <div class="select-inference-type-item-dataset">
      <choosed-items
        v-bind="choosedItems"
        :trainedAi="trainedAi"
        :trainedAiDetail="trainedAiDetail"
        :requiredColumns="requiredColumns"
        :choosedDatasetInfo="choosedDatasetInfo"
        :choosedPreprocessInfo="choosedPreprocessInfo"
        :preprocessingColumns="preprocessingColumns"
        :lackedColumns="lackedColumns"
        :lackedPreprocessingColumns="lackedPreprocessingColumns"
        :selectedInferenceType="selectedInferenceType"
      />
    </div>
  </div>
</template>

<script>
import cardBase from '@/components/atoms/card-base.vue'
import selectBox from '@/components/molecules/select-box.vue'
import selectBoxInput from '@/components/molecules/select-box-input.vue'
import inputBox from '@/components/molecules/input-box.vue'
import choosedItems from '@/components/organisms/inference/menu/infereceType/choosed-items.vue'
import timeseriesFirstLastDate from '@/components/organisms/inference/timeseries-first-last-date'
import modelSettingList from '@/components/organisms/inference/menu/infereceType/model-setting-list'
import modelSetting from '@/components/organisms/inference/menu/infereceType/model-setting'
import texts from '@/components/atoms/text'

import { DEEP_RECIPE_TYPE } from '@/lib/training.js'

export default {
  components: {
    cardBase,
    choosedItems,
    selectBox,
    selectBoxInput,
    inputBox,
    timeseriesFirstLastDate,
    modelSettingList,
    modelSetting,
    texts
  },
  props: {
    selectInferenceType: String,
    selectedInferenceType: String,
    settings: Object,
    inferenceTypeOptions: Array,
    choosedItems: Object,
    trainedAi: Object,
    trainedAiDetail: Object,
    trainedAiDetailFullInfo: Object,
    loadColumnInfo: Boolean,
    choosedDatasetInfo: Object,
    choosedPreprocessInfo: Object,
    preprocessingColumns: Object,
    requiredColumns: Array,
    lackedColumns: Array,
    lackedPreprocessingColumns: Array,
    makeInferenceTypeList: Array
  },
  data() {
    return {
      // model-setting-listでArrayの中身として持つ形式と同様の形式を、単一目的変数用に保持する
      columnSetting: {
        index: 0,
        column: '',
        threshold: 0.5,
        reversePositive: false
      }
    }
  },
  computed: {
    timeUnitOptions() {
      return [
        { value: 'sec', name: this.$t('common.time.sec') },
        { value: 'min', name: this.$t('common.time.min') },
        { value: 'hour', name: this.$t('common.time.hour') },
        { value: 'day', name: this.$t('common.time.day') },
        { value: 'week', name: this.$t('common.time.week') },
        { value: 'month', name: this.$t('common.time.month') },
        { value: 'year', name: this.$t('common.time.year') }
      ]
    },
    predictionColumn() {
      return this.trainedAi?.predictColumn
    },
    modelSettingType() {
      if (this.predictionColumn && this.predictionColumn.length) {
        return this.predictionColumn.length > 1 ? 'multi' : 'single'
      } else {
        return 'single'
      }
    },
    isMultiple() {
      return this.predictionColumn && this.predictionColumn.length > 1
    },
    changeableThreshold() {
      return this.trainedAiDetailFullInfo?.result?.changeable_threshold
    },
    displayThreshold() {
      const type = this.trainedAiDetailFullInfo?.summary?.type
      return type === 'CLASSIFICATION'
    },
    isTime() {
      return this.selectedInferenceType === 'time'
    },
    isMfT() {
      return this.selectedInferenceType === 'time_transformer'
    },
    isClustering() {
      return this.selectedInferenceType === 'clustering'
    },
    isTimeTransformerV2() {
      return this.selectedInferenceType === 'time_transformerV2'
    },
    isLargeContent() {
      const type = this.trainedAiDetailFullInfo?.summary?.type
      if (
        (DEEP_RECIPE_TYPE.indexOf(type) > -1 && !this.hasConfusionMatrix) ||
        type === 'NO_ACCURACY'
      )
        return false
      if (type === 'REGRESSION' && !this.isMultiple) return false
      return true
    },
    hasConfusionMatrix() {
      return !(
        this.trainedAiDetailFullInfo?.result?.test_confusion_matrix == null
      )
    },
    clusteringSelectItems() {
      return this.trainedAi.clusteringInfo.resultList.map((classItem) => {
        const name =
          this.trainedAi.clusteringInfo?.type === 'dbscan'
            ? this.$t('clustering.result.classNameFix', {
                id: classItem.id,
                nClusters: classItem.nClusters
              })
            : classItem.id
        return {
          name: name,
          value: classItem.id
        }
      })
    }
  },
  methods: {
    selectItem({ selectItem: id }) {
      this.$emit('select-item', { value: this.makeInferenceTypeList[id].value })
    },
    setSettings(value, type) {
      const res = JSON.parse(JSON.stringify(this.settings))
      res[type] = value
      this.$emit('set-settings', res)
    },
    changeThreshold(threshold) {
      this.columnSetting.threshold = threshold
      const reversePositive = this.columnSetting.reversePositive
      // model-setting-listで発火させているイベントと同一のイベントを、同一の形式で発火させる
      this.$emit('change-trained-ai-detail', {
        index: 0,
        threshold: threshold,
        reversePositive: reversePositive
      })
      this.$emit('input-multi-inference-column', {
        predictionColumn: this.predictionColumn,
        predictionColumnSetting: [this.columnSetting]
      })
    },
    reversePositive(reversePositive) {
      this.columnSetting.reversePositive = reversePositive
      const threshold = this.columnSetting.threshold
      // model-setting-listで発火させているイベントと同一のイベントを、同一の形式で発火させる
      this.$emit('change-trained-ai-detail', {
        index: 0,
        threshold: threshold,
        reversePositive: reversePositive
      })
    },
    inputClusteringSetting(e) {
      this.$emit('input-clustering-setting', e.value)
    }
  },
  mounted() {
    this.columnSetting.column = this.predictionColumn[0]

    // 単体列の場合、閾値と学習済みAI詳細情報の初期化処理を行う
    if (!this.isMultiple) {
      this.$emit('change-trained-ai-detail', { index: 0, threshold: 0.5 })
      this.$emit('input-multi-inference-column', {
        predictionColumn: this.predictionColumn,
        predictionColumnSetting: [this.columnSetting]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$baseCardWidth: 408 - 24 / 2;
.c-card-inference {
  width: 100%;
  max-width: adjustVW($baseCardWidth);
  max-height: 100%;
}

.select-inference-type {
  &-wrapper {
    display: grid;
    grid-template-columns: minmax(0, 1fr) adjustVW(384);
    grid-template-rows: 100%;
    grid-column-gap: $space-medium;
    width: fit-content;
    max-width: 100%;
    height: 100%;
    &-large {
      width: 100%;
    }
  }
  &-item {
    display: grid;
    grid-template-areas:
      'type'
      'info';
    grid-template-columns: 100%;
    grid-template-rows: auto minmax(0, 1fr);
    grid-row-gap: $space-medium;
    width: fit-content;
    max-width: 100%;
    &-type {
      grid-area: type;
    }
    &-time {
      grid-area: time;
    }
    &-clustering {
      grid-area: time;
    }
    &-info {
      height: 100%;
      grid-area: info;
      &-card {
        display: flex;
        flex-direction: column;
        &-classification {
          width: 100%;
          max-width: 100%;
          max-height: 100%;
        }
        &-multi {
          width: 100%;
          max-width: 100%;
          max-height: 100%;
        }
        &-title {
          margin-bottom: $space-base;
        }
        &-list {
          overflow: auto;
          flex-shrink: 1;
          height: 100%;
          padding: 0 $space-base 0 0;
          @include scrollbar;
        }
      }
    }
    .select-inference-type-wrapper-large & {
      width: 100%;
    }
  }
  &-time {
    display: grid;
    grid-template-areas:
      'type info'
      'time info';
    grid-template-columns: adjustVW($baseCardWidth) minmax(0, 1fr);
    grid-column-gap: $space-medium;
    grid-row-gap: $space-medium;
  }
}

.select-inference-type-card-tittle {
  margin-bottom: $space-base;
}

.timeseries-settings {
  &-input {
    width: 100%;
    margin-bottom: $space-small;
  }
  &-title {
    margin-bottom: $space-base;
  }
}
</style>
