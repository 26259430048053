<template>
  <label
    class="text-toggle-button"
    :class="{
      'text-toggle-button-on': toggleStatus,
      'text-toggle-button-disabled': disabled
    }"
  >
    <input
      type="button"
      style="display: none"
      :disabled="disabled"
      @click="toggleContents($event)"
    >
    <div class="text-toggle-button-inner">
      <div class="text-toggle-button-text">
        <transition mode="out-in" name="text-toggle-button-text-inner">
          <div
            v-if="!toggleStatus"
            key="off"
            class="text-toggle-button-text-inner"
          >
            <texts :text="text" :color="disabled ? 'disabled' : ''" />
          </div>
          <div v-else key="on" class="text-toggle-button-text-inner">
            <texts :text="textOn ? textOn : text" color="emphasis" />
          </div>
        </transition>
      </div>
      <div class="text-toggle-button-icon">
        <icon :iconName="iconName" :color="checkIconColor" />
      </div>
    </div>
  </label>
</template>

<script>
import texts from './text'
import icon from './icon'

export default {
  components: {
    texts,
    icon
  },
  data() {
    return {
      toggleStatus: this.value
    }
  },
  props: {
    /** offの状態のテキスト、textOnがない場合はOnのときにも表示 */
    text: String,
    /** Onの状態のときのテキスト */
    textOn: String,
    /** 表示するアイコンの名前 */
    iconName: String,
    /** v-modelで渡された値 */
    value: Boolean,
    /** 使用不可のとき */
    disabled: Boolean
  },
  methods: {
    toggleContents() {
      this.toggleStatus = !this.toggleStatus
      this.$emit('input', this.toggleStatus)
      this.$emit('change', this.toggleStatus)
    }
  },
  computed: {
    checkIconColor() {
      if (this.disabled) return 'gray'
      if (this.toggleStatus) {
        return 'emphasis'
      } else {
        return 'default'
      }
    }
  },
  watch: {
    value(newVal) {
      this.toggleStatus = newVal
    }
  }
}
</script>

<style lang="scss" scoped>
.text-toggle-button {
  display: block;
  padding: $space-base $space-small $space-base adjustVW(28) + $space-base +
    $space-small;
  border-radius: 9in;
  transition: all $transition-base;
  transition-delay: $transition-base;
  &-inner {
    position: relative;
  }
  &-icon {
    position: absolute;
    top: 50%;
    left: adjustVW(-28) - $space-sub;
    transform: translateY(-50%);
    transition: all $transition-base;
    transition-delay: $transition-base;
  }
  &-text {
    overflow: hidden;
    display: flex;
    align-items: center;
    height: adjustVW(28); // アイコンのサイズ参照のためvw
    &-inner {
      &-enter-active,
      &-leave-active {
        transition: opacity $transition-base;
      }
      &-enter {
        opacity: 0;
      }
      &-leave-to {
        opacity: 0;
      }
    }
  }
  &-on {
    padding: $space-base adjustVW(28) + $space-base + $space-small $space-base
      $space-small;
    background: $key-lite;
  }
  &-disabled {
    cursor: not-allowed;
  }
  &-on & {
    &-icon {
      left: calc(100% + #{$space-sub});
    }
  }
}
</style>
