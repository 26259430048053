<template>
  <div class="project-template-form">
    <input-box
      v-model="name"
      class="project-template-form-term"
      isGray
      :title="$t('projectList.projectName')"
    />
    <input-box
      v-model="description"
      isTextArea
      isGray
      class="project-template-form-term"
      :title="$t('common.description')"
    />
    <input-box
      v-model="wantToAchieve"
      class="project-template-form-term"
      isGray
      :title="$t('projectTemplate.wantToAchieve')"
    />
    <input-box
      v-model="predictedAndClassified"
      class="project-template-form-term"
      isGray
      :title="$t('projectTemplate.predictedAndClassified')"
    />
    <input-box
      v-model="industryOccupation"
      class="project-template-form-term"
      isGray
      :title="$t('projectTemplate.industryOccupation')"
    />
    <tag-editable-list v-model="tags" class="project-template-form-term" edit />

    <div class="project-template-form-term">
      <texts text="会員制限" />
      <checkbox-list
        v-model="restrictionCheked.checked"
        :values="restrictionCheked.values"
      />
    </div>

    <div class="project-template-form-image">
      <div class="project-template-form-image-inner">
        <texts
          class="project-template-form-image-title"
          text="プロジェクトテンプレートのサムネイルに表示する画像"
        />
        <input-dataset-box
          class="project-template-form-image-edit"
          :value="thumbnail.item"
          :accept="['image/png', 'image/jpeg']"
          @on-file-input="onFileInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputBox from '@/components/molecules/input-box.vue'
import InputDatasetBox from '@/components/molecules/input-dataset-box.vue'
import CheckboxList from '@/components/molecules/checkbox-list.vue'
import TagEditableList from '@/components/molecules/tag-editable-list.vue'
import Texts from '@/components/atoms/text.vue'

export default {
  components: {
    InputBox,
    InputDatasetBox,
    CheckboxList,
    TagEditableList,
    Texts
  },
  data() {
    return {
      name: this.project?.name ?? '',
      description: this.project?.description ?? '',
      wantToAchieve: '',
      predictedAndClassified: '',
      industryOccupation: '',
      tags: this.project?.tags ?? [],
      restrictionCheked: {
        checked: ['basic', 'education_1', 'free'],
        values: ['basic', 'education_1', 'free']
      },
      thumbnail: {
        item: null,
        name: null
      }
    }
  },
  computed: {
    formData: function () {
      return {
        name: this.name,
        description: this.description,
        wantToAchieve: this.wantToAchieve,
        predictedAndClassified: this.predictedAndClassified,
        industryOccupation: this.industryOccupation,
        tags: this.tags
      }
    },
    restrictionData: function () {
      const checkList = this.restrictionCheked.checked

      return {
        basic: checkList.includes('basic'),
        education_1: checkList.includes('education_1'),
        free: checkList.includes('free')
      }
    }
  },
  methods: {
    onFileInput(file) {
      this.thumbnail = {
        item: file,
        name: file.name.substr(0, file.name.lastIndexOf('.'))
      }
    }
  },
  watch: {
    formData: {
      deep: true,
      handler: function (form) {
        this.$emit('input-create-form', {
          type: 'projectTemplate',
          form: {
            ...form,
            restriction: this.restrictionData,
            thumbnail: this.thumbnail
          }
        })
      }
    },
    restrictionData: {
      deep: true,
      handler: function (form) {
        this.$emit('input-create-form', {
          type: 'projectTemplate',
          form: {
            ...this.formData,
            restriction: form,
            thumbnail: this.thumbnail
          }
        })
      }
    },
    thumbnail: {
      deep: true,
      handler: function (form) {
        this.$emit('input-create-form', {
          type: 'projectTemplate',
          form: {
            ...this.formData,
            restriction: this.restrictionData,
            thumbnail: form
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.project-template-form {
  width: 100%;
}

.project-template-form-term {
  width: 100%;
  margin-bottom: $space-small;
}

.project-template-form-typical {
  width: 100%;
  margin-bottom: adjustVW(48);
}

.submit-button {
  display: flex;
  justify-content: center;
  margin: 1rem;
}

.project-template-form-image {
  display: flex;
  width: 100%;
  height: adjustVW(280);
  margin-bottom: $space-large;
  &-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-edit {
    width: adjustVW(640);
  }
  &-title {
    margin-bottom: $space-small;
  }
}
</style>
