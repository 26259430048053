<template>
  <div class="select-inference-type">
    <optimization-cards
      v-if="isOptimization"
      :trainedAi="trainedAi"
      :optimizationInfo="optimizationInfo"
      :optimizationConditionsList="optimizationConditionsList"
      :disableOptimization="disableOptimization"
      @set-optimization="$emit('set-optimization', $event)"
      @check-expressions="$emit('check-expressions', $event)"
      @get-optimization-conditions-list="
        $emit('get-optimization-conditions-list')
      "
      @show-optimization-tutorial="$emit('show-optimization-tutorial')"
      @reset-optimization-settings="$emit('reset-optimization-settings')"
    />
    <default-cards
      v-else
      :selectInferenceType="selectInferenceType"
      :selectedInferenceType="selectedInferenceType"
      :settings="settings"
      :inferenceTypeOptions="inferenceTypeOptions"
      :choosedItems="choosedItems"
      :trainedAi="trainedAi"
      :trainedAiDetail="trainedAiDetail"
      :trainedAiDetailFullInfo="trainedAiDetailFullInfo"
      :loadColumnInfo="loadColumnInfo"
      :choosedDatasetInfo="choosedDatasetInfo"
      :choosedPreprocessInfo="choosedPreprocessInfo"
      :preprocessingColumns="preprocessingColumns"
      :requiredColumns="requiredColumns"
      :lackedColumns="lackedColumns"
      :lackedPreprocessingColumns="lackedPreprocessingColumns"
      :makeInferenceTypeList="makeInferenceTypeList"
      @select-item="selectItem"
      @set-settings="setSettings"
      @input-multi-inference-column="
        $emit('input-multi-inference-column', $event)
      "
      @input-clustering-setting="inputClusteringSetting"
      @change-trained-ai-detail="$emit('change-trained-ai-detail', $event)"
    />
  </div>
</template>

<script>
import defaultCards from './infereceType/default-cards'
import optimizationCards from './infereceType/optimization-cards'

export default {
  components: {
    defaultCards,
    optimizationCards
  },
  data() {
    return {
      selectedInferenceType: this.selectInferenceType
        ? this.selectInferenceType
        : 'classification',
      settings: {
        time: 1,
        timeUnit: 'day',
        targetClustering: this.trainedAi?.clusteringInfo?.resultList?.[0]?.id
      }
    }
  },
  mounted() {
    if (
      this.makeInferenceTypeList?.find(
        (item) => item.value === this.selectedInferenceType
      ) == null
    ) {
      this.selectedInferenceType = this.makeInferenceTypeList[0].value
    }
    this.$emit('set-settings', { value: this.settings })
  },
  props: {
    selectInferenceType: String,
    inferenceTypeOptions: Array,
    choosedItems: Object,
    trainedAi: Object,
    trainedAiDetail: Object,
    trainedAiDetailFullInfo: Object,
    loadColumnInfo: Boolean,
    choosedDatasetInfo: Object,
    choosedPreprocessInfo: Object,
    preprocessingColumns: Object,
    requiredColumns: Array,
    lackedColumns: Array,
    lackedPreprocessingColumns: Array,
    isOptimization: Boolean,
    optimizationInfo: Object,
    optimizationConditionsList: Array,
    disableOptimization: Array
  },
  computed: {
    makeInferenceTypeList() {
      const List = this.inferenceTypeOptions.map((item, index) => {
        return {
          id: index,
          name: item.name,
          value: item.value
        }
      })

      return List
    }
  },
  watch: {
    makeInferenceTypeList: {
      handler(list) {
        this.selectedInferenceType = list[0].value
      },
      deep: true
    }
  },
  methods: {
    selectItem(e) {
      this.$emit('select-item', e)
      this.$emit('set-settings', { value: this.settings })
      this.selectedInferenceType = e.value
    },
    setSettings(e) {
      this.settings = e
      this.$emit('set-settings', { value: this.settings })
    },
    inputClusteringSetting(e) {
      this.settings.targetClustering = e
      this.$emit('set-settings', { value: this.settings })
    }
  }
}
</script>

<style lang="scss" scoped>
.c-card {
  width: adjustVW(408);
}

.select-inference-type {
  width: 100%;
  height: 100%;
}
</style>
