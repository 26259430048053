<template>
  <button
    v-tooltip="disabledTips"
    class="button"
    :class="{
      'button-active': value || isActive,
      'button-disabled': isDisabled
    }"
    @click="!isDisabled ? updateValue() : ''"
  >
    <icons
      class="icon"
      :iconName="iconName"
      :size="size"
      :showTips="showTips"
      :showTipsFlag="showTipsFlag"
      :isDisabled="isDisabled"
      :color="isDisabled ? 'gray' : value || isActive ? 'emphasis' : 'default'"
    />
    <texts
      v-if="text"
      class="text"
      :text="text"
      size="small"
      :color="isDisabled ? 'gray' : value || isActive ? 'emphasis' : 'default'"
    />
  </button>
</template>

<script>
import icons from '@/components/atoms/icon'
import { checkErrori18n } from '@/lib/misc.js'

export default {
  components: {
    icons
  },
  props: {
    /** アイコンの名前を入れる（Storybook参照） */
    iconName: {
      type: String,
      default: 'dummy'
    },
    /** アイコンの大きさを指定 [ default, large, small, title, min, huge ] 数値での指定も可能です */
    size: {
      type: [Number, String],
      default: 'default'
    },
    /** tooltipを表示するi18n内にtooltipの情報がない場合は表示されない。common.icon.tooltipContents参照 */
    showTips: {
      type: Boolean,
      default: false,
      require: false
    },
    /** tooltipの表示非表示のフラグ。showTipsを入れた上でtooltipを非表示にする場合があるときに使う */
    showTipsFlag: {
      type: Boolean,
      default: false,
      require: false
    },
    /** 押下不可状態 */
    isDisabled: {
      type: Boolean,
      default: false,
      require: false
    },
    /** disabledの場合の理由 i18nのcommon.disabledに追加またはあるものを使用する */
    disabledReason: {
      type: String,
      default: null,
      require: false
    },
    /** v-modelで渡すvalue */
    value: {
      type: Boolean,
      default: false,
      require: true
    },
    /** v-modelを使わないときのactiveの切り替え */
    isActive: {
      type: Boolean,
      default: false,
      require: false
    },
    text: {
      type: String,
      default: ''
    }
  },
  methods: {
    updateValue() {
      this.$emit('input', !this.value)
      this.$emit('click', !this.value)
    }
  },
  computed: {
    disabledTips() {
      if (!this.isDisabled) return null
      const reason = checkErrori18n.bind(this)(this.disabledReason)
      return {
        content: reason,
        trigger: 'hover',
        delay: { show: 300, hide: 300 }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  display: flex;
  align-items: center;
  grid-column-gap: $space-min;
  background-color: $background-sub;
  border-radius: adjustVW(8);
  &-active {
    background-color: $background-decoration;
  }
  &-disabled {
    cursor: not-allowed;
    &:hover {
      opacity: 1;
    }
  }
}
.icon {
  margin: $space-base;
}
.text {
  margin-right: $space-sub;
}
</style>
