<template>
  <div class="main">
    <select-box
      class="top-select-column"
      :title="$t('trainedAi.multi.selectedSettingColumn')"
      :value="selectedPredictionColumn"
      :items="valiablesSelect"
      isGray
      scrollBar
      @input="changePredictionColumn"
    />
    <div class="top">
      <div class="top-main">
        <div class="top-main-title">
          <texts
            :text="$t('trainedAi.timeTransformerV2.conditions.title')"
            size="large"
          />
        </div>
        <div class="top-main-input">
          <fuse-search
            v-if="!waitMount"
            ref="fuseSearch"
            class="figures-search"
            :placeholder="$t('training.config.detail.searchColumns')"
            :option="fuseOption"
            gray
            :value="conditionsList"
            @input="search($event)"
          />
        </div>
      </div>
      <div class="top-sub">
        <div class="top-sub-toggle">
          <toggle-button
            :text="$t('training.timeTransformerV2.setting.toggleDetail')"
            @input="$emit('show-time-transformer-v2-detail', $event)"
          />
        </div>
        <div class="top-sub-menu">
          <button @click="$emit('show-time-transformer-before-setting')">
            <text-with-icon
              iconName="save"
              :text="
                $t('training.timeTransformerV2.setting.usePreviousSetting')
              "
              size="small"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="list">
      <list-item-slot v-if="!waitMount" :field="field" :items="items">
        <template v-slot:columnNameField>
          <div class="head-main">
            <div class="head-title">
              <texts :text="$t('common.columnName')" size="small" isBold />
            </div>
            <transition-toggle-contents>
              <label v-if="showAllCheckSetting" class="list-name head-name">
                <checkbox-base
                  class="list-name-check"
                  isSmall
                  :checked="isLatherOneCheck"
                  :isShowMinusIcon="!isAllChecked"
                  :isDisabled="isAllNull"
                  @input="checkAll"
                />
                <texts
                  class="list-name-text"
                  :text="$t('common.changeAll')"
                  size="small"
                  :color="isAllNull ? 'gray' : 'emphasis'"
                />
              </label>
            </transition-toggle-contents>
          </div>
        </template>
        <template v-slot:isKnownField>
          <div class="head-body">
            <div
              v-tooltip="
                $t('trainedAi.timeTransformerV2.conditions.type.isKnown.info')
              "
              class="head-title"
            >
              <text-with-icon
                :text="
                  $t(
                    'trainedAi.timeTransformerV2.conditions.type.isKnown.title'
                  )
                "
                iconName="info"
                size="small"
                pos="right"
                isBold
              />
            </div>
            <transition-toggle-contents>
              <div
                v-if="showAllCheckSetting"
                v-tooltip="tipsAll()"
                class="head-inner"
              >
                <div v-if="isAllNotAvailabelSelect" class="caution-box">
                  <texts
                    class="caution-body"
                    :text="
                      $t(
                        'trainedAi.timeTransformerV2.conditions.isAllNotAvailableSelect'
                      )
                    "
                    size="min"
                    color="gray"
                  />
                </div>
                <template v-else>
                  <select-box
                    :value="allCheckItem.isKnown.value"
                    isGray
                    isSeparateBox
                    :margin="40"
                    :items="selectBoxItems.isKnown"
                    @input="updateAllValue($event, 'isKnown')"
                  />
                  <button
                    class="list-first-icon"
                    @click="allCheckItem.showDetail = !allCheckItem.showDetail"
                  >
                    <icons
                      :iconName="allCheckItem.showDetail ? 'minus' : 'plus'"
                      :size="16"
                    />
                  </button>
                </template>
              </div>
            </transition-toggle-contents>
          </div>
        </template>
        <template v-slot:isTimeVaryingField>
          <div class="head-body">
            <div
              v-tooltip="
                $t(
                  'trainedAi.timeTransformerV2.conditions.type.isTimeVarying.info'
                )
              "
              class="head-title"
            >
              <text-with-icon
                :text="
                  $t(
                    'trainedAi.timeTransformerV2.conditions.type.isTimeVarying.title'
                  )
                "
                iconName="info"
                size="small"
                pos="right"
                isBold
              />
            </div>
            <transition-toggle-contents>
              <div
                v-if="showAllCheckSetting && allCheckItem.showDetail"
                class="head-inner"
              >
                <select-box
                  :value="allCheckItem.isTimeVarying.value"
                  isGray
                  isSeparateBox
                  :margin="40"
                  :items="selectBoxItems.isTimeVarying"
                  @input="updateAllValue($event, 'isTimeVarying')"
                />
              </div>
            </transition-toggle-contents>
          </div>
        </template>
        <template v-slot:isRealField>
          <div class="head-body">
            <div
              v-tooltip="
                $t('trainedAi.timeTransformerV2.conditions.type.isReal.info')
              "
              class="head-title"
            >
              <text-with-icon
                :text="
                  $t('trainedAi.timeTransformerV2.conditions.type.isReal.title')
                "
                iconName="info"
                size="small"
                pos="right"
                isBold
              />
            </div>
            <transition-toggle-contents>
              <div
                v-if="showAllCheckSetting && allCheckItem.showDetail"
                class="head-inner"
              >
                <select-box
                  :value="allCheckItem.isReal.value"
                  isGray
                  isSeparateBox
                  :margin="40"
                  :items="selectBoxItems.isReal"
                  @input="updateAllValue($event, 'isReal')"
                />
              </div>
            </transition-toggle-contents>
          </div>
        </template>
        <template v-slot:columnName="entry">
          <label>
            <time-transformer-v2-list-item
              :entry="entry"
              @onCheck="onCheck($event, entry.item.name)"
            />
          </label>
        </template>
        <template v-slot:isKnown="entry">
          <div v-if="entry.entry.isDatetime" class="caution-box">
            <texts
              class="caution-body"
              :text="
                $t('training.timeTransformerV2.setting.dateTimeDescription')
              "
              size="min"
              color="gray"
            />
          </div>
          <div v-else-if="entry.entry.isHasNull" class="caution-box">
            <texts
              class="caution-body"
              :text="$t('trainedAi.timeTransformerV2.conditions.isHasNull')"
              size="min"
              color="gray"
            />
          </div>
          <div v-else v-tooltip="tipsSelf(entry.listIndex)" class="list-first">
            <select-box
              :value="entry.item.value"
              isGray
              isSeparateBox
              :margin="setShowTop(entry.listIndex) ? 12 : 40"
              :items="selectBoxItems.isKnown"
              :isDisabled="!entry.entry.columnName.value"
              :showTop="setShowTop(entry.listIndex)"
              @input="
                updateValue($event, entry.entry, 'isKnown', entry.listIndex)
              "
            />
            <button
              class="list-first-icon"
              :class="{
                'list-first-icon-disabled': !entry.entry.columnName.value
              }"
              @click="
                entry.entry.columnName.value
                  ? toggleDisplay(entry.entry.columnName.name)
                  : ''
              "
            >
              <icons
                :iconName="entry.entry.showDetail ? 'minus' : 'plus'"
                :isDisabled="!entry.entry.columnName.value"
                :size="16"
              />
            </button>
          </div>
        </template>
        <template v-slot:isTimeVarying="entry">
          <transition-toggle-contents>
            <div v-if="entry.entry.showDetail">
              <select-box
                :value="entry.item.value"
                isGray
                isSeparateBox
                :margin="setShowTop(entry.listIndex) ? 12 : 40"
                :items="selectBoxItems.isTimeVarying"
                :isDisabled="!entry.entry.columnName.value"
                :showTop="setShowTop(entry.listIndex)"
                @input="
                  updateValue(
                    $event,
                    entry.entry,
                    'isTimeVarying',
                    entry.listIndex
                  )
                "
              />
            </div>
          </transition-toggle-contents>
        </template>
        <template v-slot:isReal="entry">
          <transition-toggle-contents>
            <div v-if="entry.entry.showDetail">
              <select-box
                :value="entry.item.value"
                isGray
                isSeparateBox
                :margin="setShowTop(entry.listIndex) ? 12 : 40"
                :items="selectBoxItems.isReal"
                :isDisabled="!entry.entry.columnName.value"
                :showTop="setShowTop(entry.listIndex)"
                @input="
                  updateValue($event, entry.entry, 'isReal', entry.listIndex)
                "
              />
            </div>
          </transition-toggle-contents>
        </template>
      </list-item-slot>
    </div>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import fuseSearch from '@/components/molecules/fuse-search.vue'
import checkboxBase from '@/components/atoms/checkbox-base.vue'
import toggleButton from '@/components/atoms/toggle-button.vue'
import listItemSlot from '@/components/molecules/list-item-slot.vue'
import selectBox from '@/components/molecules/select-box.vue'
import textWithIcon from '@/components/molecules/text-with-icon.vue'
import timeTransformerV2ListItem from '@/components/organisms/training/config/list-type/time-transformer-v2-list-item.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    icons,
    fuseSearch,
    checkboxBase,
    toggleButton,
    listItemSlot,
    selectBox,
    textWithIcon,
    timeTransformerV2ListItem,
    transitionToggleContents
  },
  props: {
    fixTimeTransformerV2Setting: {
      type: Array,
      default: () => []
    },
    timeTransformerV2Filter: {
      type: Array,
      default: () => []
    },
    timeTransformerV2Setting: {
      type: Object,
      default: () => {}
    },
    predictionColumns: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      conditionsList: [],
      overridedConditionsList: {},
      selectedPredictionColumn: null,
      searchResults: null,
      noSearchResults: false,
      fuseOption: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.3,
        keys: [
          {
            name: 'columnName.name',
            weight: 1
          }
        ],
        distance: 10000
      },
      waitMount: false,
      allCheckItem: {
        isKnown: {
          value: true,
          auto: false
        },
        isTimeVarying: {
          value: true,
          auto: false
        },
        isReal: {
          value: true,
          auto: false
        },
        showDetail: false
      },
      inputSearchValue: null,
      showTipsSettingError: null,
      showTipsSettingErrorAll: false,
      showTipsSettingErrorFirst: false,
      tipsTimeOut: null
    }
  },
  methods: {
    setValue(setTarget) {
      const itemList = setTarget.reduce((prev, current) => {
        const res = {
          columnName: {
            name: current.columnName,
            value: false
          },
          mapping: current?.mapping ?? [],
          isHasNull: current.isHasNull,
          isDatetime: current.isDatetime,
          isKnown: { ...current.isKnown },
          isTimeVarying: { ...current.isTimeVarying },
          isReal: { ...current.isReal },
          showDetail: false
        }
        prev.push(res)
        return prev
      }, [])
      return itemList
    },
    search(event) {
      if (event.value.length !== 0) {
        this.searchResults = event.result
      } else {
        this.searchResults = this.currentConditionsList
      }
      if (event.noSearchResults) {
        this.noSearchResults = true
      } else {
        this.noSearchResults = false
      }
      this.inputSearchValue = event.value
    },
    // 上手くやる方法が思いつかなかったので、地道にやっています
    updateValue(value, item, type, index) {
      const column = this.selectedPredictionColumn

      const target = item.columnName.name
      if (column == null) {
        const setTarget = this.conditionsList.find((condition) => {
          return condition.columnName.name === target
        })
        setTarget[type].value = value
        setTarget[type].auto = false
      } else {
        if (this.overridedConditionsList[column] == null) {
          this.$set(
            this.overridedConditionsList,
            column,
            JSON.parse(JSON.stringify(this.conditionsList))
          )
        }

        const setTarget = this.overridedConditionsList[column].find(
          (condition) => {
            return condition.columnName.name === target
          }
        )
        setTarget[type].value = value
        setTarget[type].auto = false
      }
      this.setErrorValue(value, item, type, index)
    },
    updateAllValue(value, type) {
      this.items.forEach((result) => {
        if (result.isHasNull || result.isDatetime) return
        result[type].value = value
        result[type].auto = false
        this.setErrorValue(value, result, type)
      })
      this.allCheckItem[type].value = value
    },
    onCheck({ check, name }) {
      const column = this.selectedPredictionColumn
      if (column == null) {
        const setTarget = this.currentConditionsList.find((condition) => {
          return condition.columnName.name === name
        })
        setTarget.columnName.value = check
      } else {
        if (this.overridedConditionsList[column] == null) {
          this.$set(
            this.overridedConditionsList,
            column,
            JSON.parse(JSON.stringify(this.conditionsList))
          )
        }
        const setTarget = this.overridedConditionsList[column].find(
          (condition) => {
            return condition.columnName.name === name
          }
        )
        setTarget.columnName.value = check
      }
    },
    toggleDisplay(target) {
      const setTarget = this.currentConditionsList.find((condition) => {
        return condition.columnName.name === target
      })
      setTarget.showDetail = !setTarget.showDetail
    },
    checkAll(e) {
      this.items.forEach((result) => {
        if (result.isHasNull) return
        result.columnName.value = e
      })
    },
    setPropsValues(newSettings) {
      /*
        前回の設定をロード
      */
      this.waitMount = true
      newSettings = JSON.parse(JSON.stringify(newSettings))
      newSettings.columnConditions.forEach((current) => {
        const target = this.conditionsList.find((condtion) => {
          return condtion.columnName.name === current.columnName
        })
        if (target != null) {
          target.columnName.value = true
          target.isKnown = current.isKnown
          target.isTimeVarying = current.isTimeVarying
          target.isReal = current.isReal
        }
      })
      for (const key in newSettings.overrideColumnConditions) {
        if (this.overridedConditionsList[key] == null) {
          this.$set(
            this.overridedConditionsList,
            key,
            JSON.parse(JSON.stringify(this.conditionsList))
          )
        }
        newSettings.overrideColumnConditions[key].forEach((current) => {
          const target = this.overridedConditionsList[key].find((condtion) => {
            return condtion.columnName.name === current.columnName
          })
          if (target != null) {
            target.columnName.value = true
            target.isKnown = current.isKnown
            target.isTimeVarying = current.isTimeVarying
            target.isReal = current.isReal
          }
        })
      }

      this.$nextTick(() => {
        this.waitMount = false
      })
    },
    tipsSelf(index) {
      if (
        this.showTipsSettingError == null ||
        this.showTipsSettingError !== index
      )
        return ''
      return {
        content: this.$t(
          'training.timeTransformerV2.setting.errorSettingIsKnownIsTimeVarying'
        ),
        trigger: this.showTipsSettingErrorFirst ? 'manual' : 'hover',
        show: this.showTipsSettingErrorFirst,
        autoHide: true
      }
    },
    tipsAll() {
      if (!this.showTipsSettingErrorAll) return ''
      return {
        content: this.$t(
          'training.timeTransformerV2.setting.errorSettingIsKnownIsTimeVarying'
        ),
        trigger: this.showTipsSettingErrorFirst ? 'manual' : 'hover',
        show: this.showTipsSettingErrorFirst,
        autoHide: true
      }
    },
    setErrorValue(value, item, type, index = null) {
      window.clearTimeout(this.tipsTimeOut)
      this.showTipsSettingError = null
      this.showTipsSettingErrorAll = false
      this.showTipsSettingErrorFirst = false

      // 時間で変化しないを選択した際に、予測時に設定できる値かが、設定できないになっている場合、自動的に設定できるに変更する。
      const checkTimeVarying =
        type === 'isTimeVarying' && !value && !item.isKnown.value

      // 予測時に設定できる値かで設定できないを選択した際に、時間で変化しないになっている場合、自動的に時間で変化するに変更する。
      const checkKnown =
        type === 'isKnown' && !value && !item.isTimeVarying.value

      if (!checkTimeVarying && !checkKnown) return

      if (checkTimeVarying) {
        item.isKnown.value = true
      } else if (checkKnown) {
        item.isTimeVarying.value = true
      }

      // tooltipで説明を表示
      this.showTipsSettingErrorFirst = true
      if (index != null) {
        this.showTipsSettingError = index
      } else {
        this.showTipsSettingErrorAll = true
        if (checkTimeVarying) {
          this.allCheckItem.isKnown.value = true
        } else if (checkKnown) {
          this.allCheckItem.isTimeVarying.value = true
        }
      }
      this.tipsTimeOut = window.setTimeout(
        function () {
          this.showTipsSettingErrorFirst = false
        }.bind(this),
        5000
      )
    },
    setShowTop(index) {
      return this.items.length > 3 && this.items.length - index <= 3
    },
    changePredictionColumn(e) {
      this.selectedPredictionColumn = e
    },
    onUpdate() {
      const emitVal = this.conditionsList.reduce((prev, current) => {
        if (current.columnName.value) {
          // isHasNullはemit対象外なので渡さない
          if (current?.mapping != null && current.mapping.length > 0) {
            const resList = current.mapping.map((newColumnName) => {
              const res = {
                columnName: newColumnName,
                isDatetime: current.isDatetime,
                isKnown: current.isKnown,
                isTimeVarying: current.isTimeVarying,
                isReal: current.isReal
              }
              return res
            })

            resList.forEach((res) => prev.push(res))
          } else {
            const res = {
              columnName: current.columnName.name,
              isDatetime: current.isDatetime,
              isKnown: current.isKnown,
              isTimeVarying: current.isTimeVarying,
              isReal: current.isReal
            }
            prev.push(res)
          }
        }
        return prev
      }, [])
      const emitOverrideVal = {}
      for (const key in this.overridedConditionsList) {
        emitOverrideVal[key] = this.overridedConditionsList[key].reduce(
          (prev, current) => {
            if (current.columnName.value) {
              // isHasNullはemit対象外なので渡さない
              if (current?.mapping != null && current.mapping.length > 0) {
                const resList = current.mapping.map((newColumnName) => {
                  const res = {
                    columnName: newColumnName,
                    isDatetime: current.isDatetime,
                    isKnown: current.isKnown,
                    isTimeVarying: current.isTimeVarying,
                    isReal: current.isReal
                  }
                  return res
                })

                resList.forEach((res) => prev.push(res))
              } else {
                const res = {
                  columnName: current.columnName.name,
                  isDatetime: current.isDatetime,
                  isKnown: current.isKnown,
                  isTimeVarying: current.isTimeVarying,
                  isReal: current.isReal
                }
                prev.push(res)
              }
            }
            return prev
          },
          []
        )
      }

      this.$emit('input', {
        value: emitVal,
        valueOverride: emitOverrideVal,
        predictionColumnValue: this.predictionColumnsCondtions,
        type: 'timeTransformerV2'
      })
    }
  },
  computed: {
    field() {
      return [
        {
          key: 'columnName',
          width: 160
        },
        {
          key: 'isKnown',
          width: 296
        },
        {
          key: 'isTimeVarying',
          width: 200
        },
        {
          key: 'isReal',
          width: 200
        }
      ]
    },
    selectBoxItems() {
      return {
        isKnown: [
          {
            name: this.$t(
              'trainedAi.timeTransformerV2.conditions.type.isKnown.on'
            ),
            value: true
          },
          {
            name: this.$t(
              'trainedAi.timeTransformerV2.conditions.type.isKnown.off'
            ),
            value: false
          }
        ],
        isTimeVarying: [
          {
            name: this.$t(
              'trainedAi.timeTransformerV2.conditions.type.isTimeVarying.on'
            ),
            value: true
          },
          {
            name: this.$t(
              'trainedAi.timeTransformerV2.conditions.type.isTimeVarying.off'
            ),
            value: false
          }
        ],
        isReal: [
          {
            name: this.$t(
              'trainedAi.timeTransformerV2.conditions.type.isReal.on'
            ),
            value: true
          },
          {
            name: this.$t(
              'trainedAi.timeTransformerV2.conditions.type.isReal.off'
            ),
            value: false
          }
        ]
      }
    },
    items() {
      let res = this.searchResults
        ? this.searchResults
        : this.currentConditionsList
      if (
        this.timeTransformerV2Filter &&
        this.timeTransformerV2Filter.length > 0
      ) {
        res = res.filter((item) => {
          return this.timeTransformerV2Filter.includes(item.columnName.name)
        })
      }
      res = res.filter((item) => {
        const checkNotPrediction = !this.predictionColumns.includes(
          item.columnName.name
        )
        let checkFilter = true
        if (
          this.timeTransformerV2Filter &&
          this.timeTransformerV2Filter.length > 0
        ) {
          checkFilter = this.timeTransformerV2Filter.includes(
            item.columnName.name
          )
        }
        return checkNotPrediction && checkFilter
      })
      return res
    },
    isAllChecked() {
      if (
        (this.searchResults == null || this.searchResults.length === 0) &&
        this.timeTransformerV2Filter.length === 0
      )
        return false
      return !this.items.some((result) => {
        return !result.columnName.value && !result.isHasNull
      })
    },
    isLatherOneCheck() {
      if (
        (this.searchResults == null || this.searchResults.length === 0) &&
        this.timeTransformerV2Filter.length === 0
      )
        return false
      return this.items.some((result) => {
        return result.columnName.value
      })
    },
    showAllCheckSetting() {
      return (
        (this.inputSearchValue &&
          this.inputSearchValue.length > 0 &&
          this.searchResults.length > 0) ||
        this.timeTransformerV2Filter.length > 0
      )
    },
    predictionColumnsCondtions() {
      return this.predictionColumns.map((column) => {
        return {
          columnName: column,
          isDatetime: false,
          isKnown: {
            value: false,
            auto: true
          },
          isTimeVarying: {
            value: true,
            auto: true
          },
          isReal: {
            value: true,
            auto: true
          }
        }
      })
    },
    isAllNull() {
      return !this.items.some((item) => {
        return !item.isHasNull
      })
    },
    isAllNotAvailabelSelect() {
      return !this.items.some((item) => {
        return !item.isHasNull && !item.isDatetime
      })
    },
    valiablesSelect() {
      const items = [
        {
          id: -1,
          name: '全ての列に共通に適用する',
          value: null
        }
      ]
      // 予測する列の選択
      for (const [key, value] of Object.entries(this.predictionColumns)) {
        items.push({
          id: key,
          name: value,
          value: value
        })
      }
      return items
    },
    currentConditionsList() {
      // 選択中の列でのチェック状況
      const column = this.selectedPredictionColumn
      if (this.overridedConditionsList[column] != null) {
        return this.overridedConditionsList[column]
      } else {
        return this.conditionsList
      }
    }
  },
  mounted() {
    this.waitMount = true
    const fixValue = this.setValue(this.fixTimeTransformerV2Setting)
    this.conditionsList = fixValue
    this.$nextTick(() => {
      this.waitMount = false
    })
  },
  watch: {
    overridedConditionsList: {
      handler(newVal) {
        this.onUpdate()
      },
      deep: true
    },
    conditionsList: {
      handler(newVal) {
        this.onUpdate()
      },
      deep: true
    },
    'timeTransformerV2Setting.selectedPreviousSetting': {
      handler(newVal) {
        this.setPropsValues(newVal)
      },
      deep: true
    },
    'timeTransformerV2Setting.trainingSetting': {
      handler(newVal) {
        if (newVal != null) {
          this.setPropsValues(newVal)
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  grid-row-gap: $space-small;
  height: 100%;
}
.top {
  display: flex;
  justify-content: space-between;
  &-main {
    &-title {
      margin-bottom: $space-base;
    }
    &-input {
      height: adjustVW(48);
    }
  }
  &-sub {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    grid-row-gap: $space-sub;
    &-menu {
      display: flex;
      justify-content: space-between;
      grid-column-gap: $space-medium;
    }
  }
  &-select-column {
    width: adjustVW(320);
    height: fit-content;
    margin: 0 0 $space-sub;
  }
}
.head {
  &-body {
    width: 100%;
  }
  &-title {
    width: fit-content;
    margin-bottom: $space-base;
  }
  &-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: $space-sub;
    width: 100%;
  }
  &-main {
    display: flex;
    flex-direction: column;
  }
  &-name {
    height: 100%;
  }
}

.list {
  overflow-y: scroll;
  height: 100%;
  padding-right: $space-base;
  @include scrollbar;
  &-name {
    display: flex;
    align-items: center;
    grid-column-gap: $space-min;
  }
  &-first {
    display: flex;
    align-items: center;
    grid-column-gap: $space-small;
    &-icon {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: adjustVW(28);
      height: adjustVW(28);
      border: adjustVW(1) $text-main solid;
      border-radius: 9in;
      &-disabled {
        cursor: not-allowed;
        opacity: 0.4;
      }
    }
  }
  &::v-deep {
    .list-item-entry-inner {
      padding-right: 0;
    }
    .list-item-key-inner {
      padding-right: 0;
    }
  }
}

.caution {
  &-body {
    white-space: pre-line;
  }
}
</style>
