<template>
  <div class="trained-ai-detail-path">
    <div class="trained-ai-detail-title-sub">
      <texts v-bind="titleParams" :text="$t('projectDetail.recipeRoute')" />
    </div>
    <recipe-block-list
      v-if="layerInfo"
      class="trained-ai-detail-path-list"
      :blocks="layerInfo"
    />
    <div v-else class="trained-ai-detail-path-list-none">
      <texts :text="$t('trainedAiDetails.noPath')" />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import recipeBlockList from '@/components/molecules/recipe-block-list'
export default {
  name: 'AIDetailInfo',
  components: {
    recipeBlockList,
    texts
  },
  props: {
    trainedAi: Object,
    isOptimization: Boolean
  },
  data() {
    return {}
  },
  computed: {
    layerInfo() {
      return this.trainedAi?.result?.layer_info
    },
    titleParams() {
      if (this.isOptimization) {
        return {
          size: 'small',
          color: 'gray'
        }
      } else {
        return {
          size: 'large',
          isBold: true
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.trained-ai-detail-path {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: $space-small;
  background: $background;
  border-radius: adjustVW(16);
  box-shadow: $box-shadow-main;
  &-list {
    height: fit-content;
    &-none {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
