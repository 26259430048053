<template>
  <div class="topic-detail">
    <div class="topic-detail-top">
      <config-box-topic-detail
        v-model="values"
        :columns="columns"
        :topicCount="topicCount"
        :total="total"
        :columnName="columnName"
        :waitTextMiningLoading="waitTextMiningLoading"
        @change-column-name="$emit('change-column-name', $event)"
      />
    </div>
    <card-base class="topic-detail-graph">
      <transition-toggle-contents>
        <list-bar-graph
          v-if="!waitTextMiningLoading && frequencies.length !== 0"
          key="main"
          sortContent
          :scaleSize="values.maxWords"
          :list="frequencies"
          :tipsLabel="$t('textMining.textTopicDetail.info.tips')"
          :title="$t('textMining.textTopicDetail.info.title')"
          isPercentage
        />
        <loading v-else key="loading" />
      </transition-toggle-contents>
    </card-base>
  </div>
</template>

<script>
import loading from '@/components/atoms/loading'
import cardBase from '@/components/atoms/card-base'
import listBarGraph from '@/components/molecules/list-bar-graph'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import configBoxTopicDetail from './config-box/text-topic-detail.vue'

export default {
  components: {
    loading,
    cardBase,
    listBarGraph,
    transitionToggleContents,
    configBoxTopicDetail
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    topicCount: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    },
    frequencies: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Object,
      default: () => {}
    },
    waitTextMiningLoading: {
      type: Boolean,
      default: false
    },
    columnName: {
      type: String,
      default: ''
    }
  },
  computed: {
    values: {
      get() {
        return JSON.parse(JSON.stringify(this.value))
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.topic-detail {
  display: flex;
  flex-direction: column;
  grid-row-gap: $space-medium;
  height: 100%;
  &-top {
    flex-shrink: 0;
    width: fit-content;
  }
  &-graph {
    overflow: hidden;
    width: adjustVW(516);
    height: 100%;
  }
}
</style>
