<!-- 学習中のみ利用する前処理のヘッダー -->
<template>
  <page-top
    class="apply-top-top"
    :title="$t('preprocessing.applyingDataset.title')"
    iconName="backward"
    notScroll
    isButton
    isTrain
    @click="$emit('page-back')"
  >
    <div class="apply-top-function">
      <button-main
        :text="$t('preprocessing.applyAutoPreprocess')"
        type="emphasis"
        :isDisabled="loading || autoPreprocessingApplied"
        @click="$emit('open-auto-preprocess')"
      />

      <button-main
        :text="$t('preprocessing.applyingDataset.finishButton')"
        :type="autoPreprocessingApplied ? 'emphasis' : 'emphasis-sub'"
        :isDisabled="checkPreprocessingDisabledSave"
        @click="$emit('open-save-dataset', 'saveDataset')"
      />
    </div>
  </page-top>
</template>

<script>
import pageTop from '@/components/atoms/page-top'
import buttonMain from '@/components/atoms/button-main'

export default {
  components: {
    pageTop,
    buttonMain
  },
  props: {
    tabs: Array,
    activeTab: Number,
    preProcHeadVersion: Number,
    preProcLatestVersion: Number,
    loading: Boolean,
    // 0: 予測する列の選択ページ, 1: プロジェクト管理
    transitionType: String,
    autoPreprocessingApplied: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // 予測する列の選択ページから来ている場合、常に前処理を完了できるようにする
    checkPreprocessingDisabledSave() {
      if (this.loading) return true
      if (this.transitionType === '0') return false
      return this.preProcLatestVersion === 0 || this.preProcHeadVersion === 0
    }
  }
}
</script>

<style lang="scss" scoped>
.apply-top {
  &-function {
    display: flex;
  }
  &-tab {
    display: flex;
    justify-content: space-between;
    width: adjustVW(1224);
    &-col {
      display: flex;
      &-title {
        display: flex;
        margin: 0 $space-small $space-base 0;
      }
      &-line {
        width: adjustVW(2);
        height: 100%;
        margin: 0 $space-small 0 0;
        background: $line-gray;
        transform: translateY(adjustVW(-1));
      }
      &-name {
        overflow-x: scroll;
        display: flex;
        max-width: adjustVW(400);
        @include scrollbar;
      }
    }
  }
}
</style>
