<template>
  <div class="select-dataset-upload">
    <input-dataset-box
      :accept="acceptFileType"
      :value="selectedFile"
      :errors="fileError ? [fileError] : []"
      @on-file-input="$emit('on-file-input', $event)"
    />
  </div>
</template>

<script>
import inputDatasetBox from '@/components/molecules/input-dataset-box.vue'

export default {
  components: {
    inputDatasetBox
  },
  data() {
    return {
      acceptFileType: ['.zip', '.txt', '.csv', 'image/png', 'image/jpeg']
    }
  },
  props: {
    selectedFile: File,
    fileError: String
  }
}
</script>

<style lang="scss" scoped>
.select-dataset-upload {
  height: adjustVH(310);
}
</style>
