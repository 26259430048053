<template>
  <page-top
    :title="$t('projectTemplate.selectMode.topTitle')"
    iconName="backward"
    size="min"
    isButton
    @click="$emit('page-back')"
  />
</template>

<script>
import pageTop from '@/components/atoms/page-top'

export default {
  components: {
    pageTop
  }
}
</script>
