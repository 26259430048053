<!--
  画像や自然言語のデータセット表示時に、データの件数の多さを表示するグラフ
-->
<template>
  <div class="c-bar-wrap" :class="{ 'c-bar-wrap-white': bgWhite }">
    <!-- 最大件数からみて、何％かを表示 最大件数の場合100% -->
    <div
      class="c-bar-main"
      :style="{ '--width': (countCrop / (maxCount + 0.1)) * 100 + '%' }"
    />
  </div>
</template>

<script>
export default {
  props: {
    /** データの件数（グラフの紫部分） */
    count: Number,
    /** 最大件数 */
    maxCount: Number,
    /** グラフの背景を白にする */
    bgWhite: Boolean
  },
  computed: {
    countCrop() {
      return Math.min(this.count, this.maxCount)
    }
  }
}
</script>

<style lang="scss" scoped>
.c-bar-wrap {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: adjustVW(12);
    background: $lite-gray;
    border-radius: adjustVW(2);
    box-shadow: inset 1px 1px 2px rgba(173, 173, 173, 0.2),
      inset -1px -1px 2px rgba(235, 235, 235, 0.2);
    z-index: -1;
  }
  &-white::after {
    background: $background;
  }
}

.c-bar-main {
  --width: 100%;
  width: var(--width);
  height: adjustVW(12);
  background: $key-color;
  border-radius: adjustVW(2);
  transform: scaleX(1);
  transform-origin: left;
  transition: width $transition-base ease-in-out;
  animation: showBar ease-in-out 1s;
  will-change: width, transform;
}

@keyframes showBar {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
</style>
