var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"save-comp"},[_c('images',{staticClass:"save-comp-image",attrs:{"imageName":"congratulation"}}),_c('div',{staticClass:"save-comp-button"},[_c('button-main',{staticClass:"save-comp-button-each",attrs:{"text":_vm.checkOptimization
          ? _vm.$t('training.button.backProject')
          : _vm.$t('training.button.goInference'),"type":"emphasis"},on:{"click":_vm.clickMain}}),_c('button-main',{staticClass:"save-comp-button-each",attrs:{"text":_vm.checkOptimization
          ? _vm.$t('training.button.useOtherOptimization')
          : _vm.$t('training.button.retrain'),"type":"sub"},on:{"click":_vm.clickSub}})],1),_c('router-link',{attrs:{"to":{
      name: 'projectDetail',
      params: { projectId: _vm.$route.params.projectId }
    }}},[_c('texts',{staticClass:"save-comp-link",attrs:{"text":_vm.$t('common.trainLeave.popup.leaveButton'),"size":"min","color":"link-default"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }