<template>
  <div>
    <prevent-leave
      ref="preventLeave"
      v-model="isPrevent"
      :title="$t('common.trainLeave.popup.title')"
      :saveButton="
        $t('common.trainLeave.popup.saveButton', { currentPage: currentPage })
      "
      :leaveButton="$t('common.trainLeave.popup.leaveButton')"
      leaveButtonColor="sub"
      @save-event="closePrevent"
    >
      <texts class="prevent-leave-text" isShowAll size="small">
        <span
          v-html="
            $t('common.trainLeave.reason.first', {
              leavePage: leavePage,
              currentPage: currentPage
            })
          "
        />
        <span
          v-html="
            $t('common.trainLeave.reason.second', { currentPage: currentPage })
          "
        />
        <span v-html="$t('common.trainLeave.reason.third')" />
      </texts>
    </prevent-leave>
    <select-target-column
      v-bind="data"
      :incorrectOrder="incorrectOrder"
      :popup="popup"
      :projectInfo="projectInfo"
      :progressTraining="progressTraining"
      :notFound="notFound"
      :checkOtherAccountDataset="checkOtherAccountDataset"
      :rePreprocess="rePreprocess"
      :preventCautionMessage="preventCautionMessage"
      :multiSelectMode="multiSelectMode"
      :trainingDatasetDetail="trainingDatasetDetail"
      :loadingDatasetDetail="loadingDatasetDetail"
      @check-notes="checked($event)"
      @close-modal="closePopup($event)"
      @get-selected-column="getSelectedColumn"
      @submit-target-column="submitTargetColumn"
      @go-preprocess-page="goPreprocessPage"
      @skip-select="goPreprocessPage"
      @re-preprocess="rePreprocessPage"
      @not-prevent="notPrevent"
      @restart-prevent="restartPrevent"
      @page-back="pageBack"
      @change-select-mode="changeSelectMode"
      @go-manual-select-target-column="goManualSelectTargetColumn"
      @show-too-large-data="showTooLargeData"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import setMountedTimer from '@/mixin/set-mounted-timer'

import selectTargetColumn from '@/components/templates/select-target-column.vue'
import preventLeave from '@/components/molecules/prevent-leave'
import { checkTrainingOrder } from '@/lib/progress-training'
import {
  checkOuterTraining,
  checkCorrectBackPage
} from '@/lib/training-pages-correct-behavior'

export default {
  components: {
    selectTargetColumn,
    preventLeave
  },
  mixins: [setMountedTimer],
  async beforeRouteLeave(to, from, next) {
    this.isPrevent = this.checkPrevent(to.name)
    if (this.isPrevent) {
      this.leavePageName = to.name
      const result = await this.$refs.preventLeave.$confirm()
      if (result) {
        this.deleteProgressTraining({ projectId: this.projectId })
        this.setProgressTraining({
          item: this.projectId,
          setType: 'project'
        })

        this.$router.push({
          name: 'projectDetail',
          params: {
            projectId: this.projectId
          }
        })
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  data() {
    return {
      data: {
        sidebar: {
          // サイドバーに表示する情報
          activeLink: 'selectTargetColumn',
          status: 'select'
        },
        headerTabs: {
          // ヘッダーのタブ
          tabs: [],
          tabSelect: 1
        },
        loadingPage: false,
        body: {
          checkNotes: {},
          datasetInfo: {
            columnsList: {
              listItems: [],
              cautionItems: [],
              selectedColumn: []
            },
            selectedDataset: {
              tableColumns: [],
              table: [],
              selectedColumn: []
            },
            statisticalData: {
              tableColumns: [],
              table: [],
              graph: null,
              selectedColumn: []
            }
          },
          targetColumnInfo: {
            selectedColumn: [],
            columnData: {
              lackedDataNum: null,
              uniqueDataNum: null,
              firstItemValue: null
            }
          },
          tutorialFlag: false
        },
        selectedColumn: []
      },
      popup: {
        // ポップアップの情報
        showPopup: [] // 現在表示しているポップアップ
      },
      datasetId: null,
      datasetInfo: null,
      preventCautionMessage: false,
      isPrevent: false,
      correctTransition: false,
      currentPageName: this.$route.name,
      leavePageName: null,
      incorrectOrder: null,
      multiSelectMode: false,
      selectedColumn: [],
      progressTraining: null
    }
  },
  computed: {
    ...mapGetters('project', ['projectList', 'projectLoading']),
    ...mapGetters('datasets', ['datasetLoading']),
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('settings', ['rowsPerPage']),
    ...mapGetters('models', ['modelSettingList']),
    ...mapGetters('trainingDataset', [
      'trainingDatasetId',
      'trainingDatasetAccountId',
      'trainingDatasetDetail',
      'trainingDatasetColumns',
      'loadingDatasetDetail',
      'tooLargeData',
      'trainingDatasetInPreprocess'
    ]),

    projectId() {
      return parseInt(this.$route.params.projectId)
    },
    projectInfo() {
      if (this.projectId) {
        return this.projectList[this.projectId]
      }
      return null
    },
    notFoundProject() {
      return !(this.projectId in this.projectList)
    },
    notFoundDataset() {
      return !(this.datasetId in this.datasetList)
    },
    notFound() {
      return this.notFoundDataset || this.notFoundProject
    },
    checkOtherAccountDataset() {
      if (!this.accountInfo || !this.progressTraining) return false
      if (
        this.accountInfo.accountId !== this.progressTraining.datasetAccountId
      ) {
        return true
      }
      return false
    },
    rePreprocess() {
      return !this.checkOtherAccountDataset
    },
    leavePage() {
      return this.$t('common.trainLeave.titles.' + this.leavePageName)
    },
    currentPage() {
      return this.$t('common.trainLeave.titles.' + this.currentPageName)
    },
    datasetList() {
      const result = {}
      this.projectList[this.projectId]?.listData?.forEach((item) => {
        result[item.id] = item
      })
      return result
    }
  },
  methods: {
    ...mapActions('auth', ['fetchAccountInfo', 'updateTutorialInfo']),
    ...mapActions('project', ['loadProjectDetail']),
    ...mapActions('models', ['deleteProgressTraining', 'getProgressTraining', 'setProgressTraining']),
    ...mapActions('trainingDataset', ['setDatasetColumns', 'setLoadingDatasetDetail', 'setDatasetTooLargeData', 'fetchDatasetDetail', 'fetchDatasetInPreprocessing']),

    checked(e) {
      const columnName = e.key
      const value = e.showNote
      this.data.body.checkNotes[columnName] = value
    },
    showPopup(e) {
      this.popup.showPopup.push(e)
    },
    closePopup(e) {
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
      if (e === 'tutorialSelectTargetColumn') {
        this.data.body.tutorialFlag = true
      }
    },
    countNullValue: function (columnName) {
      if (!Object.keys(this.datasetInfo.numNullUnique).includes(columnName))
        return null
      return this.datasetInfo.numNullUnique[columnName]?.nNull
    },
    checkUniqueNumberOver: function (columnName) {
      const limit = 100
      const counts = this.datasetInfo.numNullUnique[columnName]?.nUnique
      const unique = this.datasetInfo.numNullUnique[columnName]?.unique
      if (
        this.trainingDatasetDetail.detail.dtypes[columnName] === 'object' &&
        !unique
      ) {
        return counts >= limit
      } else if (unique) {
        return true
      } else {
        return null
      }
    },
    checkObjectValue: function (columnName) {
      return this.trainingDatasetDetail.detail.dtypes[columnName] === 'object'
    },
    checkNotNumber(columnName) {
      return (
        this.datasetInfo.predictColumns[columnName] &&
        !this.datasetInfo.predictColumns[columnName].valid &&
        this.datasetInfo.predictColumns[columnName].reasons.includes('onlynum')
      )
    },
    checkNotInt(columnName) {
      return (
        this.datasetInfo.predictColumns[columnName] &&
        !this.datasetInfo.predictColumns[columnName].valid &&
        this.datasetInfo.predictColumns[columnName].reasons.includes(
          'classification_int'
        )
      )
    },

    fetchDetailData: async function (dataId) {
      let data = this.datasetList[dataId]

      if (!data) this.log_info('dataset not found.')
      if (data.type !== 'structured')
        this.log_info("type is only 'structured'. Actual type = " + data.type)

      try {
        // 予測する列の有効/無効に関するチェック内容を取得
        const resValid = await this.$sendMessageAndReceive({
          action: 'validPredictColumns',
          data_id: dataId,
          data_account_id: data.accountId
        })
        const predictColumns = {
          predictColumns: resValid.predict_columns
        }
        data = Object.assign(data, predictColumns)

        // nullやuniqueの個数を取得
        const resNullUnique = await this.$sendMessageAndReceive({
          action: 'getNumNullUnique',
          id: dataId,
          accountId: data.accountId
        })
        const numNullUnique = {
          numNullUnique: resNullUnique.result
        }
        data = Object.assign(data, numNullUnique)

        this.datasetInfo = data
      } catch (err) {
        this.log_info(err)
      }
    },
    getSelectedColumn: function (val) {
      this.setSelectedColumn(val)
    },
    makeCautionItems: function () {
      this.data.body.datasetInfo.columnsList.cautionItems =
        this.data.body.datasetInfo.columnsList.listItems
          .map((columnName) => {
            return {
              name: columnName,
              isObjectValue: this.checkObjectValue(columnName),
              notNumber:
                !this.checkObjectValue(columnName) &&
                this.checkNotNumber(columnName)
            }
          })
          .filter((column) => {
            return column.isObjectValue || column.notNumber
          })
    },
    makeDatasetTable: function () {
      const columns = this.datasetInfo.columns
      this.data.body.datasetInfo.columnsList.listItems = columns
      this.data.body.datasetInfo.selectedDataset.tableColumns = columns
      this.data.body.datasetInfo.statisticalData.tableColumns = ['/'].concat(
        columns
      )
    },
    setModelSettingInfo: async function (projectId) {
      if (projectId) {
        this.progressTraining = await this.getProgressTraining({
          projectId
        })
      } else {
        this.progressTraining = null
      }
    },
    setSelectedColumn: function (val) {
      const columnName = val.value
      const selected = val.selected
      // 通常モードの場合だけ、選択を初期化
      if (!this.multiSelectMode) {
        this.initSelectedColums()
      }

      this.preventCautionMessage = false
      if (selected) {
        // 対象として選択した場合（チェックボックスを外した時以外）
        this.selectedColumn.push(columnName)
        this.data.body.targetColumnInfo.selectedColumn.push(columnName)
        this.data.body.datasetInfo.columnsList.selectedColumn.push(columnName)
        this.data.body.datasetInfo.selectedDataset.selectedColumn.push(
          columnName
        )
        this.data.body.datasetInfo.statisticalData.selectedColumn.push(
          columnName
        )
      } else {
        // 対象として選択しなかった場合（チェックボックスを外した時）
        this.selectedColumn = this.selectedColumn.filter(
          (n) => n !== columnName
        )
        this.data.body.targetColumnInfo.selectedColumn =
          this.data.body.targetColumnInfo.selectedColumn.filter(
            (n) => n !== columnName
          )
        this.data.body.datasetInfo.columnsList.selectedColumn =
          this.data.body.datasetInfo.columnsList.selectedColumn.filter(
            (n) => n !== columnName
          )
        this.data.body.datasetInfo.selectedDataset.selectedColumn =
          this.data.body.datasetInfo.selectedDataset.selectedColumn.filter(
            (n) => n !== columnName
          )
        this.data.body.datasetInfo.statisticalData.selectedColumn =
          this.data.body.datasetInfo.statisticalData.selectedColumn.filter(
            (n) => n !== columnName
          )
      }

      if (!Object.keys(this.datasetInfo.numNullUnique).includes(columnName)) {
        this.data.body.targetColumnInfo.columnData.lackedDataNum = null
        this.data.body.targetColumnInfo.columnData.uniqueDataNum = null
        this.data.body.targetColumnInfo.columnData.firstItemValue = null
        this.data.body.checkNotes[columnName] = false
        this.data.sidebar.status = 'select'
        return
      }

      const missingNumbers = this.countNullValue(columnName)
      this.data.body.targetColumnInfo.columnData.lackedDataNum = missingNumbers

      this.data.body.targetColumnInfo.columnData.firstItemValue = this
        .trainingDatasetDetail?.list
        ? this.trainingDatasetDetail.list[0][columnName]
        : null
      if (!this.datasetInfo.numNullUnique[columnName].unique) {
        this.data.body.targetColumnInfo.columnData.uniqueDataNum =
          this.datasetInfo.numNullUnique[columnName]?.nUnique
      } else {
        this.data.body.targetColumnInfo.columnData.uniqueDataNum = this.$t(
          'selectTargetColumn.datasetInfo.list.caution.reason.uniqueData'
        )
      }
      this.data.sidebar.status = 'confirm'
    },
    submitTargetColumn: function () {
      this.correctTransition = true

      this.setProgressTraining({
        item: this.selectedColumn,
        setType: 'targetColumn',
        projectId: this.projectId
      })

      if (this.progressTraining.laterSelectTargetColumn) {
        this.$router.push({
          name: 'trainRecipeList',
          params: { projectId: this.projectId }
        })
      } else {
        if (this.checkOtherAccountDataset) {
          // 前処理を飛ばすために前処理の通過フラグを付与
          this.setProgressTraining({
            item: null,
            setType: 'preprocessing',
            projectId: this.projectId
          })

          this.$router.push({ name: 'trainRecipeList' })
        } else {
          this.$router.push({
            name: 'trainPreprocessing',
            params: { projectId: this.projectId, type: '0', id: this.datasetId }
          })
        }
      }
    },
    goPreprocessPage: function () {
      this.correctTransition = true

      this.setProgressTraining({
        item: true,
        setType: 'laterSelectTargetColumn',
        projectId: this.projectId
      })
      this.setProgressTraining({
        item: null,
        setType: 'targetColumn',
        projectId: this.projectId
      })

      this.$router.push({
        name: 'trainPreprocessing',
        params: { projectId: this.projectId, type: '0', id: this.datasetId }
      })
    },
    rePreprocessPage() {
      this.correctTransition = true
      this.$router.push({
        name: 'trainPreprocessing',
        params: { projectId: this.projectId, type: '0', id: this.datasetId }
      })
    },
    notPrevent() {
      this.correctTransition = true
    },
    restartPrevent() {
      this.correctTransition = false
    },
    checkPrevent(nextPageName) {
      return (
        !checkOuterTraining(nextPageName) &&
        !checkCorrectBackPage(
          'targetColumn',
          nextPageName,
          this.progressTraining?.laterSelectTargetColumn
        ) &&
        !this.correctTransition
      )
    },
    closePrevent() {
      this.$refs.preventLeave.closePopup()
    },
    pageBack() {
      let toPage = null
      if (this.progressTraining.laterSelectTargetColumn) {
        toPage = {
          name: 'trainPreprocessing',
          params: {
            projectId: this.projectId,
            type: '0',
            id: this.progressTraining.datasetId
          }
        }
      } else {
        toPage = {
          name: 'datasetSetting',
          params: {
            projectId: this.projectId
          }
        }
      }
      this.$router.push(toPage)
    },
    changeSelectMode(mode) {
      this.multiSelectMode = mode
      const columnName = this.selectedColumn[0]

      if (!mode) {
        // 複数選択モードを解除するときの処理
        this.makeCautionItems()
        this.initSelectedColums()
        if (columnName) {
          this.setSelectedColumn({ value: columnName, selected: true })
        }
      } else {
        // 複数選択モードにするときの処理
        this.makeCautionItems()
        const caution =
          this.data.body.datasetInfo.columnsList.cautionItems.filter(
            (n) => n.name === columnName
          )
        if (caution) {
          this.initSelectedColums()
        }
      }
    },
    initSelectedColums() {
      this.selectedColumn = []
      this.data.body.targetColumnInfo.selectedColumn = []
      this.data.body.datasetInfo.columnsList.selectedColumn = []
      this.data.body.datasetInfo.selectedDataset.selectedColumn = []
      this.data.body.datasetInfo.statisticalData.selectedColumn = []

      this.data.body.targetColumnInfo.columnData.lackedDataNum = null
      this.data.body.targetColumnInfo.columnData.uniqueDataNum = null
      this.data.body.targetColumnInfo.columnData.firstItemValue = null
      this.data.sidebar.status = 'select'
    },
    initCheckNotes() {
      const checkNotes = {}
      this.data.body.datasetInfo.columnsList.listItems.forEach((item) => {
        checkNotes[item] = true
      })
      this.data.body.checkNotes = checkNotes
    },
    goManualSelectTargetColumn() {
      const url =
        this.$urls.manual + 'train-select-target-column/予測する列の選択/'
      window.open(url, '_blank')
    },
    async showTooLargeData() {
      this.setLoadingDatasetDetail(true)
      const req = {
        dataId: this.datasetId,
        accountId: this.datasetList[this.datasetId].accountId,
        limit: this.rowsPerPage
      }
      await this.fetchDatasetDetail(req)
      this.setDatasetTooLargeData(false)
      this.setLoadingDatasetDetail(false)
    }
  },
  async mounted() {
    this.data.loadingPage = true

    if (!this.notFoundDataset) {
      await this.fetchDetailData(this.datasetId)
      this.makeDatasetTable()
      this.makeCautionItems()
      if (
        this.trainingDatasetDetail.detail.storage === 's3' &&
        this.trainingDatasetInPreprocess
      ) {
        this.showPopup('caution')
        return
      }
    }

    const tutorialInfo = this.accountInfo.tutorialInfo
    const tutorialFlag = tutorialInfo.selectTargetColumn

    if (!tutorialFlag) {
      this.showPopup('tutorialSelectTargetColumn')

      const newTutorialInfo = Object.assign({}, tutorialInfo, {
        selectTargetColumn: true
      })
      const params = {
        accountId: this.accountInfo.accountId,
        ...newTutorialInfo
      }

      await this.updateTutorialInfo(params)
    }

    this.data.loadingPage = false
    this.initCheckNotes()
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.datasetId = to.params.datasetId

      await vm.fetchAccountInfo()

      await vm.setModelSettingInfo(parseInt(to.params.projectId))
      vm.incorrectOrder = !checkTrainingOrder({
        projectId: vm.projectId,
        stage: 'targetColumn',
        progressList: vm.modelSettingList
      })

      if (vm.incorrectOrder) {
        vm.showPopup('preventTrainingStatus')
      }
      if (vm.projectList[vm.projectId]?.listData == null) {
        await vm.loadProjectDetail(vm.projectId)
      }
      const targetAccountId = vm.projectList[vm.projectId]?.listData?.find(
        (item) => item.id === vm.datasetId
      ).accountId
      if (
        !vm.trainingDatasetId ||
        vm.trainingDatasetId !== vm.datasetId ||
        !vm.trainingDatasetAccountId ||
        vm.trainingDatasetAccountId !== targetAccountId
      ) {
        await vm.fetchDatasetDetail({
          dataId: vm.datasetId,
          accountId: targetAccountId,
          includeGraph: false,
          includeData: false
        })
        await vm.fetchDatasetInPreprocessing({
          dataId: vm.datasetId,
          accountId: targetAccountId
        })
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.prevent-leave-text {
  display: flex;
  flex-direction: column;
  grid-row-gap: $space-sub;
  ::v-deep {
    .prevent-leave-text-emphasis {
      color: $text-decoration;
    }
    .prevent-leave-text-caution {
      color: $text-caution;
    }
  }
}
</style>
