<template>
  <div class="c-wrap">
    <sidebar-train
      v-if="!notFound || loading"
      v-bind="sidebar"
      :incorrectOrder="incorrectOrder"
      :projectInfo="projectInfo"
      :progressTraining="progressTraining"
      @not-prevent="$emit('not-prevent')"
      @restart-prevent="$emit('restart-prevent')"
    />
    <sidebar v-else />
    <div class="c-body-wrap">
      <header-tabs v-bind="headerTabs" />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="loading" />
          <page-not-found v-else-if="notFound" />
          <recipe-list
            v-else
            :isTrain="isTrain"
            :recipes="recipes"
            :accountInfo="accountInfo"
            @menu-click="$emit('menu-click', $event)"
            @get-recipe-template="$emit('get-recipe-template')"
            @page-back="$emit('page-back')"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="disableClick" />
    <hover-menu />
    <recipe-list-popup
      v-bind="popup"
      :isTrain="isTrain"
      :recipeLayers="recipeLayers"
      :recipeTemplates="recipeTemplates"
      :loadingRecipeTemplates="loadingRecipeTemplates"
      :disableClick="disableClick"
      @close-modal="$emit('close-modal', $event)"
      @delete-recipes="$emit('delete-recipes', $event)"
      @save-recipe-template="$emit('save-recipe-template', $event)"
      @not-prevent="$emit('not-prevent')"
    />
  </div>
</template>

<script>
import sidebarTrain from '@/components/organisms/sidebar-train.vue'
import Sidebar from '@/components/organisms/sidebar.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import recipeList from '@/components/organisms/recipe-list/recipe-list-main.vue'
import recipeListPopup from '@/components/organisms/recipe-list/recipe-list-popup.vue'
import TransitionPage from '@/components/molecules/transition-page.vue'
import TransitionToggleContents from '../../molecules/transition-toggle-contents.vue'
import Loading from '../../atoms/loading.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import pageNotFound from '@/components/organisms/page-not-found'

export default {
  components: {
    sidebarTrain,
    Sidebar,
    headerTabs,
    hoverMenu,
    recipeList,
    recipeListPopup,
    TransitionPage,
    TransitionToggleContents,
    Loading,
    loadingProcessing,
    pageNotFound
  },
  props: {
    sidebar: Object,
    headerTabs: Object,
    popup: Object,
    recipes: Object,
    accountInfo: Object,
    recipeLayers: Object,
    recipeTemplates: Object,
    loadingRecipeTemplates: Boolean,
    isTrain: Boolean,
    projectInfo: Object,
    progressTraining: Object,
    loading: Boolean,
    notFound: Boolean,
    disableClick: Boolean,
    incorrectOrder: Boolean
  }
}
</script>

<style lang="scss" scoped>
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
  background: $background;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
  background-color: $bg-train;
}
</style>
