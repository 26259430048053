<template>
  <div class="preprocessing-detail-side-wrap">
    <div v-if="!isPreprocessingEditable">
      <div class="preprocessing-detail-side-summary">
        <div class="preprocessing-detail-side-title">
          <texts :text="name" size="large" />
        </div>
        <div class="preprocessing-detail-side-desc">
          <description-long :text="description ? description : ''" />
        </div>
        <button
          class="preprocessing-detail-side-edit"
          :disabled="disabledEdit"
          :class="{ 'preprocessing-detail-side-edit-disabled': disabledEdit }"
          @click="isPreprocessingEditable = true"
        >
          <text-with-icon
            :text="$t('common.editButton')"
            iconName="edit"
            size="small"
          />
        </button>
      </div>
      <div class="preprocessing-detail-side-info">
        <div class="preprocessing-detail-side-item-block">
          <text-with-title
            class="preprocessing-detail-side-item-text"
            :text="accountId"
            :title="$t('common.owner')"
          />
        </div>
        <div
          v-if="service && service.length > 0"
          class="
            preprocessing-detail-side-item-block
            preprocessing-detail-side-item-block-list
          "
        >
          <div class="preprocessing-detail-side-item-title">
            <texts
              :text="$t('preprocessing.useSevise')"
              color="gray"
              size="small"
            />
          </div>
          <div class="preprocessing-detail-side-item-list">
            <div
              v-for="(item, index) in service"
              :key="index"
              class="preprocessing-detail-side-item-list-item"
            >
              <router-link
                :to="{
                  name: 'serviceProjectDetail',
                  params: {
                    id: item.link,
                    projectId,
                    serviceType: item.serviceType
                  }
                }"
              >
                <text-with-icon
                  class="preprocessing-detail-side-item-text"
                  :text="item.name"
                  iconName="newTab"
                  pos="right"
                  color="link-default"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="preprocessing-detail-side-item-block">
          <div class="preprocessing-detail-side-item-half">
            <text-with-title
              class="preprocessing-detail-side-item-text"
              :title="$t('common.createTime')"
            >
              <a-time :value="createTime" />
            </text-with-title>
          </div>
          <div class="preprocessing-detail-side-item-half">
            <text-with-title
              class="preprocessing-detail-side-item-text"
              :title="$t('common.updateTime')"
            >
              <a-time :value="updateTime" />
            </text-with-title>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="preprocessing-detail-side-summary-edit">
      <div class="preprocessing-detail-side-summary-edit-title">
        <input-box-validation
          v-model="editedName"
          :accept="validate.accept"
          :error="validate.error"
          popupView
          isNameValidation
          :nameValidationSuggests="preprocFormValidate.suggests"
        />
      </div>
      <div class="preprocessing-detail-side-summary-edit-desc">
        <input-box v-model="editedDescription" isTextArea isGray />
      </div>
      <div class="preprocessing-detail-side-summary-edit-button-list">
        <div class="preprocessing-detail-side-summary-edit-button-save">
          <button-main
            class="preprocessing-detail-side-summary-edit-button-save-button"
            type="emphasis"
            :isDisabled="this.disabledSubmit"
            :text="$t('common.saveButton')"
            @click="updateEdit"
          />
        </div>
        <div class="preprocessing-detail-side-summary-edit-button-cancel">
          <button-main
            size="min"
            :text="$t('common.cancel')"
            @click="closelEdit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import descriptionLong from '@/components/atoms/description-long'
import inputBox from '@/components/molecules/input-box'
import inputBoxValidation from '@/components/molecules/input-box-validation'
import texts from '@/components/atoms/text'
import textWithIcon from '@/components/molecules/text-with-icon'
import textWithTitle from '@/components/molecules/text-with-title'

export default {
  components: {
    buttonMain,
    descriptionLong,
    inputBox,
    inputBoxValidation,
    texts,
    textWithIcon,
    textWithTitle
  },
  data() {
    return {
      isPreprocessingEditable: false,
      editedName: this.name,
      editedDescription: this.description,
      validate: {
        accept: null,
        error: null
      }
    }
  },
  props: {
    accountId: String,
    createTime: String,
    description: String,
    disabledEdit: Boolean,
    name: String,
    preprocFormValidate: Object,
    service: Array,
    updateTime: String
  },
  computed: {
    disabledSubmit: function () {
      if (this.editedName === '') return true
      if (this.validate.error) return true

      return false
    },
    projectId() {
      return this.$route.params.projectId
    }
  },
  watch: {
    editedName: {
      handler: function (name) {
        this.$emit('input-edit-form', {
          type: 'preproc',
          form: {
            name
          }
        })
      }
    },
    preprocFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    }
  },
  methods: {
    closelEdit() {
      this.isPreprocessingEditable = false
      this.editedName = this.name
      this.editedDescription = this.description
    },
    updateEdit() {
      this.$emit('update-preprocessing-detail', {
        name: this.editedName,
        description: this.editedDescription
      })
      this.closelEdit()
    }
  },
  mounted: function () {
    this.$emit('input-edit-form', {
      type: 'preproc',
      form: {
        name: this.editedName
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.preprocessing-detail-side {
  &-wrap {
    overflow-x: visible;
    overflow-y: scroll;
    height: 100%;
    @include scrollbar;
  }
  &-summary {
    padding: 0 $space-base $space-small;
  }
  &-info {
    padding: 0 $space-base 0;
  }
  &-title {
    margin: 0 0 $space-base;
  }
  &-desc {
    margin: 0 0 $space-small;
  }
  &-edit {
    position: relative;
    display: block;
    margin: 0 0 0 auto;
    z-index: 1;
    &::after {
      content: '';
      position: absolute;
      top: -$space-text;
      left: -$space-base;
      width: calc(100% + #{$space-base * 2});
      height: calc(100% + #{$space-text * 2});
      background: $medium-gray;
      border-radius: 9in;
      opacity: 0;
      z-index: -1;
      will-change: opacity;
      transition: $transition-base;
    }
    &:hover {
      opacity: 1;
    }
    &:hover::after {
      opacity: 1;
    }
    &-disabled {
      cursor: not-allowed;
      opacity: 0.5;
      &::after {
        content: inherit;
      }
    }
    &-desc {
      height: 100%;
    }
  }
  &-item {
    &-block {
      display: flex;
      margin: 0 0 $space-small;
      &:last-child {
        margin: 0;
      }
      &-list {
        flex-direction: column;
      }
    }
    &-half {
      display: flex;
      width: calc((100% - #{$space-medium}) / 2);
      &:last-child {
        margin: 0;
      }
      > div {
        margin: 0 $space-medium 0 0;
      }
    }
    &-text {
      width: 100%;
    }
    &-list {
      display: flex;
      flex-direction: column;
      &-item {
        margin: 0 0 $space-base;
      }
    }
  }
  &-summary-edit {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $space-base $space-base 0;
    &-title {
      margin: 0 0 $space-small;
    }
    &-desc {
      height: 100%;
      margin: 0 0 $space-small;
    }
    &-button {
      &-list {
        display: flex;
      }
      &-save {
        flex-grow: 1;
        min-width: none;
        margin: 0 $space-base 0 0;
        &-button {
          width: 100%;
          min-width: inherit;
        }
      }
      &-cancel {
        flex-shrink: 0;
      }
    }
  }
}
</style>
