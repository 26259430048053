var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('prevent-leave',{ref:"preventLeave",attrs:{"title":_vm.$t('common.trainLeave.popup.title'),"saveButton":_vm.$t('common.trainLeave.popup.saveButton', { currentPage: _vm.currentPage }),"leaveButton":_vm.$t('common.trainLeave.popup.leaveButton'),"leaveButtonColor":"sub"},on:{"save-event":_vm.closePrevent},model:{value:(_vm.isPrevent),callback:function ($$v) {_vm.isPrevent=$$v},expression:"isPrevent"}},[_c('texts',{staticClass:"prevent-leave-text",attrs:{"isShowAll":"","size":"small"}},[_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.$t('common.trainLeave.reason.first', {
            leavePage: _vm.leavePage,
            currentPage: _vm.currentPage
          })
        )}}),_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.$t('common.trainLeave.reason.second', { currentPage: _vm.currentPage })
        )}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('common.trainLeave.reason.third'))}})])],1),_c('select-target-column',_vm._b({attrs:{"incorrectOrder":_vm.incorrectOrder,"popup":_vm.popup,"projectInfo":_vm.projectInfo,"progressTraining":_vm.progressTraining,"notFound":_vm.notFound,"checkOtherAccountDataset":_vm.checkOtherAccountDataset,"rePreprocess":_vm.rePreprocess,"preventCautionMessage":_vm.preventCautionMessage,"multiSelectMode":_vm.multiSelectMode,"trainingDatasetDetail":_vm.trainingDatasetDetail,"loadingDatasetDetail":_vm.loadingDatasetDetail},on:{"check-notes":function($event){return _vm.checked($event)},"close-modal":function($event){return _vm.closePopup($event)},"get-selected-column":_vm.getSelectedColumn,"submit-target-column":_vm.submitTargetColumn,"go-preprocess-page":_vm.goPreprocessPage,"skip-select":_vm.goPreprocessPage,"re-preprocess":_vm.rePreprocessPage,"not-prevent":_vm.notPrevent,"restart-prevent":_vm.restartPrevent,"page-back":_vm.pageBack,"change-select-mode":_vm.changeSelectMode,"go-manual-select-target-column":_vm.goManualSelectTargetColumn,"show-too-large-data":_vm.showTooLargeData}},'select-target-column',_vm.data,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }