<template>
  <image-scatter-base
    class="image-scatter-wrap"
    :data="result"
    :xLabel="xLabel"
    :yLabel="yLabel"
    :showImage="showImage"
  />
</template>

<script>
import ImageScatterBase from '@/components/organisms/inference/result/dimensionality-reduction/image-scatter-base.vue'

export default {
  components: {
    ImageScatterBase
  },
  props: {
    result: Array,
    xLabel: {
      type: String,
      default: 'X'
    },
    yLabel: {
      type: String,
      default: 'Y'
    },
    showImage: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.image-scatter-wrap {
  width: 100%;
  height: 100%;
}
</style>
