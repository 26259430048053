<template>
  <project-template-create
    v-bind="data"
    :headerTabs="headerTabs"
    :loading="loading"
    :project="project"
    :sidebar="sidebar"
    @add-template-effect="addTemplateEffect($event)"
    @add-template-images="uploadSummaryImages($event)"
    @add-template-sample-dataset="selectSampleDataset($event)"
    @cancel-create-form="cancelCreateForm()"
    @create-template="createTemplate($event)"
    @on-file-input="onFileInput($event)"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import setMountedTimer from '@/mixin/set-mounted-timer'
import projectTemplateCreate from '@/components/templates/project-template-create.vue'

export default {
  components: {
    projectTemplateCreate
  },
  mixins: [setMountedTimer],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loadPage = true

      await vm.$waitConnected()
      await vm.loadProjectDetail(vm.projectId)

      vm.loadPage = false
    })
  },
  data() {
    return {
      data: {
        body: {
          makeProjectTemplate: true,
          openTemplateEffectForm: false,
          openTemplateSampleDatasetForm: false,
          sampleDatasetList: [],
          submitting: false,
          summaryImages: {
            main: {
              file: {
                item: null,
                name: ''
              },
              uploadErrors: {
                flag: false,
                message: []
              },
              uploadWarnings: []
            },
            sub1: {
              file: {
                item: null,
                name: ''
              },
              uploadErrors: {
                flag: false,
                message: []
              },
              uploadWarnings: []
            },
            sub2: {
              file: {
                item: null,
                name: ''
              },
              uploadErrors: {
                flag: false,
                message: []
              },
              uploadWarnings: []
            }
          }
        },
        popup: {
          showPopup: []
        }
      },
      loadPage: false,
      submitting: false
    }
  },
  computed: {
    ...mapGetters('project', ['projectList', 'projectLoading']),
    ...mapGetters('projectTemplate', ['createdProjectTemplate', 'uploadState']),
    ...mapGetters('projectTemplate', {
      projectTemplateList: 'projectList',
      projectTemplateLoading: 'projectLoading'
    }),

    headerTabs: function () {
      return {
        tabs: [],
        tabSelect: 1
      }
    },
    loading: function () {
      return this.loadPage || this.projectLoading || this.projectTemplateLoading
    },
    project: function () {
      return this.projectList[this.projectId]
    },
    projectId: function () {
      return this.$route.params.projectId
        ? parseInt(this.$route.params.projectId)
        : null
    },
    sidebar() {
      return null
    }
  },
  methods: {
    ...mapActions('project', ['loadProjectDetail']),
    ...mapActions('projectTemplate', [
      'activateProjectTemplate',
      'addProjectTemplateEffect',
      'addProjectTemplateSampleDataset',
      'addProjectTemplateUsageRestriction',
      'createProjectTemplate',
      'loadProjectList',
      'loadProjectTemplateDetail',
      'startImageUploading',
      'updateProjectTemplateThumbnail',
      'uploadImage'
    ]),
    activeteTemplate: async function () {
      this.data.body.submitting = true

      const templateId = this.createdProjectTemplate.id

      await this.activateProjectTemplate({
        projectTemplateId: templateId
      })

      this.data.body.submitting = false
    },
    addTemplateEffect: async function (form) {
      this.data.body.submitting = true
      const projectTemplateId = this.createdProjectTemplate.id

      try {
        await this.addProjectTemplateEffect({
          projectTemplateId,
          ...form
        })

        this.data.body.openTemplateEffectForm = false
      } finally {
        this.data.body.submitting = false
      }
    },
    addTemplateEffectImage: async function (file, index) {
      this.data.body.submitting = true
      const projectTemplateId = this.createdProjectTemplate.id

      await this.startImageUploading({
        imageIndex: index,
        projectTemplateId: projectTemplateId,
        fileName: file.item.name,
        fileSize: file.item.size
      })

      await this.uploadImage({
        action: 'uploadingData',
        messageId: this.uploadState.effect_m_id,
        file: file.item
      })
      this.data.body.submitting = false
    },
    cancelCreateForm: function () {
      this.$router.go(-1)
    },
    closePopup: function (e) {
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
    },
    createTemplate: async function ({ form }) {
      this.data.body.submitting = true

      const { thumbnail, restriction, ...template } = form

      try {
        await this.createProjectTemplate({ ...template })
      } catch (err) {
        this.log_info(err)
      }
      const projectTemplateId = this.createdProjectTemplate.id

      try {
        await this.addProjectTemplateUsageRestriction({
          projectTemplateId,
          restriction
        })
      } catch (err) {
        this.log_info(err)
      }

      if (thumbnail?.item) {
        try {
          await this.updateProjectTemplateThumbnail({
            projectTemplateId,
            fileName: thumbnail.item.name,
            fileSize: thumbnail.item.size
          })
        } catch (err) {
          this.log_info(err)
        }

        try {
          await this.uploadImage({
            action: 'uploadingData', // TODO バックエンド側に合わせる
            messageId: this.uploadState.thumbnail_m_id,
            file: thumbnail.item
          })
        } catch (err) {
          this.log_info(err)
        }
      }

      this.data.body.submitting = false

      const projectTemplate = this.createdProjectTemplate
      this.setSampleDataset({ projectTemplate })

      const hasDataset = this.data.body.sampleDatasetList.length > 0

      this.data.body.openTemplateSampleDatasetForm = hasDataset
      this.data.body.openTemplateEffectForm = !hasDataset
      this.data.body.makeProjectTemplate = false
    },
    selectSampleDataset: async function ({ form }) {
      this.data.body.submitting = true

      try {
        const projectTemplateId = this.createdProjectTemplate.id

        await this.addProjectTemplateSampleDataset({
          projectTemplateId,
          ...form
        })
      } catch (err) {
        this.log_info(err)
      }

      this.data.body.submitting = false

      this.data.body.openTemplateEffectForm = true
      this.data.body.openTemplateSampleDatasetForm = false
    },
    setSampleDataset: function ({ projectTemplate }) {
      const originalProject = projectTemplate.project

      const listDataset =
        originalProject.numData > 0 ? originalProject.listData : []

      this.data.body.sampleDatasetList = listDataset
    },
    inputSummaryImageMain: function (file) {
      this.data.body.summaryImages.main.uploadErrors = Object.assign(
        this.data.body.summaryImages.main.uploadErrors,
        {
          flag: false,
          message: []
        }
      )

      this.data.body.summaryImages.main.uploadWarnings = []

      this.data.body.summaryImages.main.file.item = file
      this.data.body.summaryImages.main.file.name = file.name.substr(
        0,
        file.name.lastIndexOf('.')
      )
    },
    inputSummaryImageSub1: function (file) {
      this.data.body.summaryImages.sub1.uploadErrors = Object.assign(
        this.data.body.summaryImages.sub1.uploadErrors,
        {
          flag: false,
          message: []
        }
      )
      this.data.body.summaryImages.sub1.uploadWarnings = []

      this.data.body.summaryImages.sub1.file.item = file
      this.data.body.summaryImages.sub1.file.name = file.name.substr(
        0,
        file.name.lastIndexOf('.')
      )
    },
    inputSummaryImageSub2: function (file) {
      this.data.body.summaryImages.sub2.uploadErrors = Object.assign(
        this.data.body.summaryImages.sub2.uploadErrors,
        {
          flag: false,
          message: []
        }
      )
      this.data.body.summaryImages.sub2.uploadWarnings = []

      this.data.body.summaryImages.sub2.file.item = file
      this.data.body.summaryImages.sub2.file.name = file.name.substr(
        0,
        file.name.lastIndexOf('.')
      )
    },
    onFileInput: function ({ file, index }) {
      if (index === 0) this.inputSummaryImageMain(file)
      else if (index === 1) this.inputSummaryImageSub1(file)
      else if (index === 2) this.inputSummaryImageSub2(file)
    },
    uploadSummaryImages: async function () {
      this.data.body.submitting = true

      const mainImage = this.data.body.summaryImages.main.file
      const sub1Image = this.data.body.summaryImages.sub1.file
      const sub2Image = this.data.body.summaryImages.sub2.file

      if (mainImage.item !== null)
        await this.addTemplateEffectImage(mainImage, 1)
      if (sub1Image.item !== null)
        await this.addTemplateEffectImage(sub1Image, 2)
      if (sub2Image.item !== null)
        await this.addTemplateEffectImage(sub2Image, 3)

      await this.activeteTemplate()
      await this.loadProjectList()

      this.data.body.submitting = false

      this.$router.push({
        name: 'projectList'
      })
    },
    showPopup: function (e) {
      this.popup.showPopup.push(e)
    }
  }
}
</script>
