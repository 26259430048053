<template>
  <div
    v-if="sortedItems && sortedItems.length > 0"
    class="trained-ai-list-card-wrap"
  >
    <template v-for="item in sortedItems">
      <card
        :key="item.id"
        :row="2"
        :isActive="isChecked(item.id)"
        class="trained-ai-list-card"
        @click="linkTo(item)"
      >
        <card-content
          withCheckbox
          :trainedAi="item"
          :value="isChecked(item.id)"
          :project="project"
          @input="setChecked($event, item.id)"
        />
      </card>
    </template>
  </div>
  <div v-else-if="noSearchResults" class="trained-ai-list-no-item">
    <images class="trained-ai-list-no-item-image" imageName="notFound" />
    <texts
      class="trained-ai-list-no-item-text"
      :text="$t('common.noSearchResults')"
    />
  </div>
  <div v-else class="trained-ai-list-no-item">
    <images class="trained-ai-list-no-item-image" imageName="noItem" />
    <texts
      class="trained-ai-list-no-item-text"
      :text="$t('common.listNoItem')"
    />
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import images from '@/components/atoms/images'
import card from '@/components/atoms/card'
import cardContent from './trained-ai-card-content'

export default {
  components: {
    texts,
    images,
    card,
    cardContent
  },
  data() {
    return {
      checked: new Set(this.value),
      showTipsCard: '',
      showTipsItem: ''
    }
  },
  props: {
    field: Array,
    items: Array,
    value: Array,
    project: Object,
    noSearchResults: Boolean
  },
  computed: {
    sortedItems() {
      const sorted = [...this.items].sort((x, y) =>
        x.updateTime < y.updateTime ? 1 : x.updateTime > y.updateTime ? -1 : 0
      )
      return sorted
    }
  },
  methods: {
    linkTo(item) {
      this.$router.push({
        name: 'trainedAiDetail',
        params: {
          id: item.id
        }
      })
    },
    isChecked(id) {
      return this.checked.has(id)
    },
    setChecked(e, id) {
      if (e) {
        this.checked.add(id)
      } else {
        this.checked.delete(id)
      }
      this.checked = new Set(this.checked)
      this.$emit('input', Array.from(this.checked))
    },
    scale(e) {
      return Math.round(e * 10) / 10
    },
    maxScale(id) {
      return Math.ceil(this.items[id].featureImportance.size * 10) / 10
    },
    scaleWidth(e, id) {
      return (this.scale(e) / this.maxScale(id)) * 100
    },
    isHoverGraph(cardId, featureImportanceId) {
      this.showTipsCard = cardId
      this.showTipsItem = featureImportanceId
    },
    isLeaveGraph() {
      this.showTipsCard = ''
      this.showTipsItem = ''
    }
  },
  watch: {
    value(newVal) {
      this.checked = new Set(newVal)
    },
    items(newVal) {
      const itemIds = new Set()
      for (const item of newVal) {
        itemIds.add(item.id)
      }
      let modified = false
      for (const item of this.checked) {
        if (!itemIds.has(item)) {
          this.checked.delete(item)
          modified = true
        }
      }
      if (modified) {
        this.checked = new Set(this.checked)
        this.$emit('input', Array.from(this.checked))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.trained-ai-list-card {
  width: auto;
  &-wrap {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - #{$space-medium / 2}));
    grid-column-gap: $space-medium;
    grid-row-gap: $space-medium;
  }
  &-block {
    display: grid;
    grid-template-columns: adjustVW(284) 1fr;
    grid-column-gap: $space-small;
  }
  &-info {
    padding: 0 adjustVW(14) 0 0;
    border-right: $border-main;
    &-inner {
      margin: 0 0 $space-small;
    }
  }
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 $space-sub;
    &-checkbox {
      width: adjustVW(24);
    }
  }
  &-accuracy {
    overflow: hidden;
    width: 100%;
    &-inner {
      display: flex;
      margin: 0 0 $space-medium;
    }
    &-item {
      display: flex;
      align-items: center;
      margin: 0 $space-large 0 0;
      &:last-child {
        margin: 0;
      }
      &-graph {
        width: adjustVW(64);
        margin: 0 $space-small 0 0;
      }
      &-main {
        width: auto;
      }
    }
  }
}
.trained-ai-list-no-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: $space-large;
  &-image {
    width: adjustVW(560);
    height: auto;
    margin-bottom: $space-large;
  }
}
</style>
