<template>
  <table-img-and-txt
    v-bind="images"
    :items="samples"
    :field="field"
    :maxCount="maxCount"
    @data-image-click="dataImageClick($event)"
    @close-popup="closePopup"
  />
</template>

<script>
import tableImgAndTxt from '@/components/molecules/table-img-and-txt'

export default {
  components: {
    tableImgAndTxt
  },
  computed: {
    field() {
      return [
        {
          key: 'name',
          label: this.$t('datasetDetail.table.column'),
          thClass: 'default',
          sortable: false
        },
        {
          key: 'count',
          label: this.$t('datasetDetail.table.count'),
          thClass: 'default',
          sortable: true
        },
        {
          key: 'size',
          label: this.$t('datasetDetail.table.image'),
          thClass: 'main',
          sortable: false
        }
      ]
    },
    maxCount() {
      // データの総数
      let maxCount = 0
      if (!this.samples) return 0
      for (let i = 0; i < this.samples.length; i++) {
        maxCount = maxCount + this.samples[i].count
      }
      return maxCount
    },
    samples() {
      if (!this.rawSamples) return []
      const res = []
      let i = 0
      for (const key in this.rawSamples) {
        res.push({
          id: i,
          name: key,
          ...this.rawSamples[key]
        })
        i++
      }
      return res
    }
  },
  data() {
    return {
      images: {
        type: 'image',
        popupContents: {},
        isPopupShow: false
      }
    }
  },
  props: {
    image: Array,
    info: Object,
    dataId: String,
    rawSamples: Object
  },
  methods: {
    dataImageClick(e) {
      // 画像のデータセットはダミーでこのコンポーネントにいれているので、メソッドもここに置いています
      this.$set(
        this.images.popupContents,
        'name',
        this.samples[e.labelId].datas[e.imageId].name
      )
      this.$set(
        this.images.popupContents,
        'body',
        this.samples[e.labelId].datas[e.imageId].body
      )
      this.$set(
        this.images.popupContents,
        'width',
        this.samples[e.labelId].datas[e.imageId].width
      )
      this.$set(
        this.images.popupContents,
        'height',
        this.samples[e.labelId].datas[e.imageId].height
      )
      this.$set(this.images.popupContents, 'imageId', e.imageId)
      this.$set(this.images.popupContents, 'labelId', e.labelId)
      this.$set(this.images.popupContents, 'labelCount', e.labelCount)
      // console.log(e.labelCount - e.labelId)
      this.images.isPopupShow = true
    },
    closePopup() {
      this.images.isPopupShow = false
    }
  }
}
</script>
