<template>
  <div class="test-dataset-wrapper">
    <div class="test-dataset-main">
      <card-base class="test-dataset-main-card">
        <numerical-table
          class="test-dataset-main-card-table"
          :targetColumn="targetColumn"
          :tableColumns="includePredictionColumns"
          :table="testData.list"
          :graphProb="testData.graphProb"
          :importanceProb="testData.importanceProb"
          :inPageNumber="testDatasetInfo.inPageNumber"
          :pagingCount="testDatasetInfo.limit"
          :loading="testDatasetInfo.waitTable"
          :usedAiInfo="trainedAi"
          :selectedItem="selectedItem"
          :finishColumnsFix="finishColumns"
          :inferencedColumns="predictionColumns"
          :testInferencedColumn="testInferencedColumn"
          :download="testDatasetInfo.download"
          @change-column="changeColumn"
          @download-test-dataset="$emit('download-test-dataset', $event)"
        />
        <paging
          :value="testDatasetInfo.inPageNumber"
          class="test-dataset-main-card-paging"
          :count="testData.total"
          :pagingCount="testDatasetInfo.limit"
          @input="$emit('change-test-dataset-page', $event)"
        />
      </card-base>
    </div>
    <supplementary-explanation />
  </div>
</template>

<script>
import numericalTable from '@/components/organisms/inference/numerical-table.vue'
import cardBase from '@/components/atoms/card-base.vue'
import paging from '@/components/molecules/paging.vue'
import supplementaryExplanation from './test-dataset/supplementary-explanation.vue'

export default {
  components: {
    cardBase,
    numericalTable,
    paging,
    supplementaryExplanation
  },
  data() {
    return {
      selectedItem: ''
    }
  },
  props: {
    trainedAi: Object,
    selectedColumnIndex: Number,
    testDatasetInfo: Object,
    finishColumns: Array
  },
  methods: {
    changeColumn(columnName) {
      this.selectedItem = columnName
      this.$emit('change-column', columnName)
    }
  },
  computed: {
    predictionColumns() {
      return this.trainedAi.summary.predictionColumns
    },
    targetColumn() {
      if (!this.predictionColumns || this.predictionColumns.length === 0)
        return null
      return this.predictionColumns[this.selectedColumnIndex]
    },
    testInferencedColumn() {
      if (!this.predictionColumns || this.predictionColumns.length === 0)
        return null
      // サーバーからの返却値で、予測結果にはこの接尾辞がついている
      return (
        this.predictionColumns[this.selectedColumnIndex] + '_inference_result'
      )
    },
    includePredictionColumns() {
      // 実測値の列も含めた列の一覧情報を返却する
      const includePredictionColumns = [this.predictionColumns[this.selectedColumnIndex]]
      return includePredictionColumns.concat(this.testData.inputColumns)
    },
    testData() {
      this.trainedAi.result?.testData.graphProb.forEach((rawObject) => {
        // オブジェクトの値を小数点以下3桁にフォーマットして再格納する
        for (const [key, value] of Object.entries(rawObject)) {
          const newValue = parseFloat(value)
          rawObject[key] = newValue.toFixed(3)
        }
      })

      return this.trainedAi.result?.testData
    }
  },
  mounted() {
    this.selectedItem = this.predictionColumns[this.selectedColumnIndex]
  }
}
</script>

<style lang="scss" scoped>
.test-dataset-wrapper {
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(0, 1fr) adjustVW(344);
  grid-template-rows: 100%;
  grid-column-gap: $space-medium;
  grid-row-gap: $space-medium;
  height: 100%;
  padding: 0 $space-medium $space-medium;
}

.test-dataset {
  &-main {
    width: 100%;
    height: 100%;
    grid-column: 1 / 2;
  }

  &-features {
    width: 100%;
    height: 100%;
    grid-column: 2 / 3;
    &-card {
      height: 100%;
    }
    &-inner {
      height: 100%;
      padding: $space-small $space-small $space-base;
      margin: 0;
    }
  }

  &-main-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    &-paging {
      align-self: flex-end;
      margin-top: $space-small;
    }
    &-table {
      flex-shrink: 1;
      height: 100%;
    }
  }
}
</style>
