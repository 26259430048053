<template>
  <div class="apply-body-content-body">
    <texts
      class="apply-body-content-text"
      size="small"
      isShowAll
      :text="$t('preprocessing.message.binning')"
    />
    <div class="bins-header">
      <texts
        class="bins-label"
        size="small"
        :text="$t('preprocessing.binning.numberOfBins')"
      />
      <input-box
        v-model="nBins"
        class="bins-input"
        number
        isGray
      />
    </div>
    <div class="scroll-y">
      <div v-for="(item, index) in categoryNames" :key="index" class="">
        <template v-if="index === 0">
          {{ item }} :
          <i18n path="preprocessing.message.binningRange.small">
            <template #y>
              <input-box
                v-model="binningBorders[index]"
                class="border-input"
                number
                isGray
              />
            </template>
          </i18n>
        </template>
        <template v-else-if="index === nBins - 1">
          {{ item }} :
          <i18n path="preprocessing.message.binningRange.large">
            <template #x>
              <input-box
                v-model="binningBorders[index - 1]"
                class="border-input"
                number
                isGray
              />
            </template>
          </i18n>
        </template>
        <template v-else>
          {{ item }} :
          <i18n path="preprocessing.message.binningRange.middle">
            <template #x>
              <input-box
                v-model="binningBorders[index - 1]"
                class="border-input"
                number
                isGray
              />
            </template>
            <template #y>
              <input-box
                v-model="binningBorders[index]"
                class="border-input"
                number
                isGray
              />
            </template>
          </i18n>
        </template>
      </div>
    </div>
    <button-main
      class="apply-body-content-submit"
      :text="$t('preprocessing.applyingDataset.binningButton')"
      type="emphasis"
      @click="$emit('apply-binning', { binningBorders })"
    />
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import buttonMain from '@/components/atoms/button-main'
import inputBox from '@/components/molecules/input-box'
import { toAlphabetical } from '@/lib/toAlphabetical'

export default {
  components: {
    texts,
    buttonMain,
    inputBox
  },
  props: {
    minValue: Number,
    maxValue: Number
  },
  data() {
    return {
      nBins: 5,
      binningBorders: this.calcNewDefault(5)
    }
  },
  methods: {
    calcNewDefault(newVal) {
      const min = this.minValue
      const max = this.maxValue
      return new Array(newVal - 1)
        .fill(0)
        .map((_, i) => (min + (max - min) / newVal) * (i + 1))
    }
  },
  computed: {
    categoryNames() {
      return new Array(this.nBins).fill(0).map((_, i) => toAlphabetical(i))
    }
  },
  watch: {
    nBins(newVal) {
      this.binningBorders = this.calcNewDefault(newVal)
    },
    maxValue() {
      this.binningBorders = this.calcNewDefault(this.nBins)
    },
    minValue() {
      this.binningBorders = this.calcNewDefault(this.nBins)
    }
  }
}
</script>
<style lang="scss" scoped>
.bins {
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 $space-sub;
  }
  &-label {
    width: calc(50% - #{$space-small});
    margin: auto $space-sub auto 0;
  }
  &-input {
    width: 50%;
  }
}

.border-input {
  display: inline-block;
  width: 30%;
}

.scroll-y {
  overflow-y: scroll;
  height: 100%;
  font-size: $text-small;
}

.apply-body {
  &-content {
    &-body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      height: 100%;
      padding: 0 $space-small $space-small;
      opacity: 1;
      animation: toggle $transition-base;
      will-change: height, opacity;
    }
    &-text {
      flex-shrink: 0;
      padding: 0;
      margin: 0 0 $space-sub;
      white-space: pre-line;
    }
    &-submit {
      flex-shrink: 0;
      width: fit-content;
      margin: 0;
    }
  }
}

@keyframes toggle {
  0% {
    height: 0%;
    opacity: 0;
    transform: translateY(-$space-small);
  }
  100% {
    height: 100%;
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
