<template>
  <div class="image-test-dataset-card-wrapper">
    <div class="image-test-dataset-card-setting">
      <card-base class="image-test-dataset-card-setting-card">
        <icon-button
          v-tooltip="downloadTips"
          class="image-test-dataset-card-setting-card-button"
          iconName="download"
          size="min"
          :text="$t('common.download.all')"
          @click="$emit('download-test-dataset', encodingType)"
        />
        <select-box
          v-model="encodingType"
          class="image-test-dataset-card-setting-card-input"
          isGray
          min
          :items="downloadTypes"
        />
        <paging
          :value="testDatasetInfo.inPageNumber"
          :count="testData.total"
          :pagingCount="testDatasetInfo.limit"
          @input="$emit('change-test-dataset-page', $event)"
        />
      </card-base>
      <transition-toggle-contents>
        <text-box
          v-if="showHeatmapWarning"
          class="image-test-dataset-card-setting-warning"
          :text="$t('trainedAi.finetuning.disableDisplayHeatmap')"
          isError
        />
      </transition-toggle-contents>
    </div>
    <div v-if="!testDatasetInfo.waitTable" class="image-test-dataset-card-list">
      <template>
        <div
          v-for="(item, index) in items"
          :key="index"
          class="image-test-dataset-image-card-block"
        >
          <image-classification-card
            v-bind="imageCardData(index)"
            :modelInfo="modelInfo"
            :posBottom="posBottomCard(index)"
          />
        </div>
      </template>
    </div>
    <div v-else>
      <transition name="toggle-detail" mode="out-in">
        <div class="loading">
          <loading-icon />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import imageClassificationCard from '@/components/organisms/inference/result/classfication/image-classification-card.vue'
import cardBase from '@/components/atoms/card-base.vue'
import iconButton from '@/components/atoms/icon-button'
import selectBox from '@/components/molecules/select-box'
import paging from '@/components/molecules/paging.vue'
import loadingIcon from '@/components/atoms/loading-icon'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import TextBox from '@/components/atoms/text-box.vue'

function isWindows() {
  return window.navigator.userAgent.toLowerCase().indexOf('windows') >= 0
}

export default {
  components: {
    imageClassificationCard,
    cardBase,
    iconButton,
    selectBox,
    paging,
    loadingIcon,
    TransitionToggleContents,
    TextBox
  },
  data() {
    return {
      encodingType: isWindows() ? 'cp932' : 'utf8'
    }
  },
  props: {
    trainedAi: Object,
    selectedColumnIndex: Number,
    testDatasetInfo: Object
  },
  methods: {
    posBottomCard: function (index) {
      const items = this.items
      if (items.length < this.posBottomPopupBorder) return false
      return items.length - index < this.posBottomPopupBorder - 1
    },
    imageCardData(index) {
      return {
        ...this.items[index]
      }
    },
    textCardData(index) {
      return {
        ...this.items[index]
      }
    }
  },
  computed: {
    items() {
      return this.testData.list
    },
    testData() {
      return this.trainedAi.result?.testData
    },
    downloadTypes() {
      return [
        {
          id: 0,
          name: 'Windows (Shift-JIS)',
          value: 'cp932'
        },
        {
          id: 1,
          name: 'Mac (UTF-8)',
          value: 'utf8'
        }
      ]
    },
    downloadTips() {
      let tips = ''
      if (this.testDatasetInfo?.download?.downloadComp) {
        tips = this.$t('trainedAiDetails.testDatasetTab.download.comp')
      } else if (this.testDatasetInfo?.download?.downloading) {
        tips = this.$t('trainedAiDetails.testDatasetTab.download.loading')
      } else {
        tips = this.$t('trainedAiDetails.testDatasetTab.download.wait')
      }
      return {
        content: tips,
        hideOnTargetClick: false
      }
    },
    modelInfo() {
      return this.trainedAi?.summary
    },
    showHeatmapWarning() {
      if (this.items) {
        const isFinetuningDeep = this.modelInfo?.type === 'FINETUNING_DEEP'
        const noHeatmap = this.items.some((item) => {
          return !item.heatmap
        })
        if (isFinetuningDeep && noHeatmap) return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.image-test-dataset-card {
  &-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 $space-medium 0;
  }
  &-setting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $space-small;
    &-card {
      display: flex;
      &-button {
        margin: 0 $space-sub 0 0;
      }
      &-input {
        width: adjustVW(188);
        height: adjustVW(32);
        margin-right: $space-small;
        &::v-deep {
          .c-func-select-button-text {
            font-size: $text-min;
            @include text-crop;
          }
        }
      }
    }
  }
  &-list {
    overflow-y: scroll;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: $space-medium;
    grid-row-gap: $space-medium;
    width: 100%;
    height: 100%;
    padding-right: $space-small;
    margin-bottom: $space-medium;
    @include scrollbar;
  }
}

.image-test-dataset-image-card-block {
  width: 100%;
  aspect-ratio: 16 / 9;
  &:last-of-type {
    margin-bottom: $space-base; // 最後をシャドウが消えない程度の少しのマージンにしている
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
