<template>
  <div class="time-setting-wrap">
    <div class="time-setting-title">
      <texts
        :text="$t('trainedAi.timeTransformerV2.duration.title')"
        size="large"
      />
    </div>
    <div class="time-setting-input">
      <div>
        <div class="time-setting-input-title">
          <texts
            size="small"
            color="gray"
            :text="$t('trainedAi.timeTransformerV2.duration.predict.title')"
          />
          <icons
            v-tooltip="
              $t('trainedAi.timeTransformerV2.duration.predict.description')
            "
            class="time-setting-input-icon"
            iconName="info"
            size="small"
            color="gray"
          />
        </div>
        <div>
          <input-box
            v-model="config.maxPredictionLength"
            isGray
            type="number"
            :isDisabled="trainingFlag"
          />
        </div>
      </div>
      <div>
        <div class="time-setting-input-title">
          <texts
            size="small"
            color="gray"
            :text="$t('trainedAi.timeTransformerV2.duration.input.title')"
          />
          <icons
            v-tooltip="
              $t('trainedAi.timeTransformerV2.duration.input.description')
            "
            class="time-setting-input-icon"
            iconName="info"
            size="small"
            color="gray"
          />
        </div>
        <div>
          <input-box
            v-model="config.maxEncoderLength"
            isGray
            type="number"
            :isDisabled="trainingFlag"
          />
        </div>
      </div>
    </div>
    <div v-if="trainingFlag" class="disabled-box" />
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import inputBox from '@/components/molecules/input-box'

export default {
  components: {
    icons,
    inputBox
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    trainingFlag: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    config: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.time-setting {
  &-wrap {
    position: relative;
  }
  &-title {
    margin-bottom: $space-small;
  }
  &-input {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $space-medium;
    width: 100%;
    &-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: $space-base;
    }
    &-icon {
      cursor: help;
    }
  }
}

.disabled-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
}
</style>
