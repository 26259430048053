<template>
  <div
    class="inference-result-wrap"
    :class="{
      'inference-result-wrap-no-download': !checkDownload
    }"
  >
    <div class="inference-result-title">
      <div class="inference-result-title-inner">
        <text-with-icon
          :iconName="titleProps.iconName"
          size="title"
          :text="titleProps.text"
        />
      </div>
      <transition-toggle-contents>
        <text-box
          v-if="downloadComplete || optimizationDownload.downloadComp"
          class="inference-result-download-complete"
          :text="$t('inference.result.downloadedMessage')"
        />
      </transition-toggle-contents>
      <div class="top-caution-list">
        <transition-toggle-contents>
          <text-box
            v-if="inferenceWarnings && inferenceWarnings.length > 0"
            class="top-caution-inner"
            isError
          >
            <template v-for="item in inferenceWarnings">
              <texts
                :key="item"
                class="top-caution-item"
                :text="getWarningMessage(item)"
                color="caution"
                size="min"
              />
            </template>
          </text-box>
        </transition-toggle-contents>
      </div>
    </div>
    <div v-if="checkDownload" class="inference-result-top-bar">
      <div class="inference-result-download">
        <button-main
          v-if="
            (inferencedColumns && !(inferencedColumns.length > 1)) ||
              optimizationValues.showOptimizationResult
          "
          type="emphasis"
          :text="
            optimizationValues.showOptimizationResult
              ? $t('inference.result.downloadOptimization')
              : $t('inference.result.download')
          "
          :isDisabled="
            downloading ||
              optimizationDownload.downloading ||
              !finishedAllProcesses
          "
          class="download-button"
          @click="clickDownload"
        />
        <div v-else class="download-button">
          <button-main
            class="download-button-timeseries"
            type="emphasis"
            :text="$t('inference.result.downloadOnly')"
            :isDisabled="
              downloading || checkDownloadIndividual || !finishedAllProcesses
            "
            @click="clickDownloadIndividual"
          />
          <button-main
            class="download-button-timeseries"
            type="sub"
            :text="$t('inference.result.downloadAll')"
            :isDisabled="downloading || checkDownloadAll || !finishedAllColumns"
            @click="clickDownloadAll"
          />
        </div>
        <loading-icon
          v-if="inferencedColumns && !finishedAllProcesses"
          class="download-loading"
        />
        <select-box
          isGray
          :items="encodingOptions"
          :firstSelectItem="defaultEncoding"
          class="download-select-encoding"
          @input="setEncoding"
        />
        <div
          v-if="displayProgress"
          class="inference-timeseries-result-multi-count"
        >
          <circle-progress-icon
            class="inference-timeseries-result-multi-icon"
            :progress="progress"
            :progressMax="inferencedColumns.length"
          />
          <texts
            class="inference-timeseries-multi-text"
            :text="timeseriesFinishStatus"
          />
        </div>
      </div>
      <div v-if="meanShap || checkTextMining" class="inference-result-change">
        <div v-if="meanShap" class="inference-result-change-shows">
          <text-toggle-button
            :value="showFeatures"
            iconName="featureImportance"
            :text="$t('inference.result.detail.showFeatureImportance')"
            :textOn="$t('inference.result.detail.showInferenceResult')"
            @input="inputFeatures"
          />
        </div>
        <!--
          TODO 推論実行時のテキストマイニングの結果取得実装後修正
        <div v-if="checkTextMining" class="inference-result-change-shows">
          <text-toggle-button
            :value="showTextMining"
            iconName="textmining"
            :text="$t('textMining.inference.showResult')"
            :textOn="$t('inference.result.detail.showInferenceResult')"
            @input="inputTextMining"
          />
        </div>
        -->
      </div>
      <div
        v-if="optimizationValues.showOptimizationResult"
        class="optimization-info"
      >
        <icons iconName="save" color="gray" />
        <texts size="small" color="gray">
          {{ $t('inference.result.optimization.linkTextFirst') }}
          <router-link
            :to="{
              name: 'trainedAiProjectDetail',
              params: {
                id: selectedTrainedAi.id
              }
            }"
            class="optimization-link"
          >
            {{ selectedTrainedAi.name }}
          </router-link>
          {{ $t('inference.result.optimization.linkTextSecond') }}
        </texts>
      </div>
    </div>

    <div class="inference-result-body">
      <div class="inference-result-inner">
        <regression-result
          v-if="
            predictedTable &&
              predictedTable.length > 0 &&
              !waitSetSelectedColumn
          "
          v-bind="tableDatas"
          :selectedItem="selectedItem"
          :selectedTrainedAi="selectedTrainedAi"
          :targetColumns="predictedColumns"
          :table="predictedTable"
          :meanShap="meanShap"
          :showFeatures="showFeatures"
          :total="total"
          :finishedTotal="finishedTotal"
          :inferencedColumns="inferencedColumns"
          :finishColumnsFix="finishColumnsFix"
          :finishColumnsAllProcessFix="finishColumnsAllProcessFix"
          :loadColumnResult="loadColumnResult"
          :selectedColumnIndex="internalSelectedColumn"
          :showTextMining="showTextMining"
          :textMiningResult="textMiningResult"
          :textMiningSetting="textMiningSetting"
          @change-page="$emit('change-page', $event)"
          @change-column="changeColumn"
          @load-text-mining="$emit('load-text-mining', $event)"
          @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
        />
        <classification-result
          v-else-if="checkClassification"
          :selectedTrainedAi="selectedTrainedAi"
          :imageClassficationResult="imageClassficationResult"
          :textClassificationResult="textClassificationResult"
          :posBottomPopupBorder="posBottomPopupBorder"
          :showTextMining="showTextMining"
          :textMiningResult="textMiningResult"
          :textMiningSetting="textMiningSetting"
          :targetType="
            inferenceType === 'textClassification' ? 'text' : 'image'
          "
          @load-text-mining="$emit('load-text-mining', $event)"
          @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
        />
        <vectorization-result
          v-else-if="checkVectorization"
          :selectedTrainedAi="selectedTrainedAi"
          :vectorizationResult="vectorizationResult"
          :dimRedResult="dimRedResult"
          :posBottomPopupBorder="posBottomPopupBorder"
          :showTextMining="showTextMining"
          :textMiningResult="textMiningResult"
          :textMiningSetting="textMiningSetting"
          :targetType="inferenceType === 'textVectorization' ? 'text' : 'image'"
          @load-text-mining="$emit('load-text-mining', $event)"
          @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
        />
        <timeseries-result
          v-else-if="
            (inferenceType === 'time' ||
              inferenceType === 'time_transformerV2') &&
              !waitSetSelectedColumn
          "
          :selectedItem="selectedItem"
          :forecast="forecast"
          :selectedTrainedAi="selectedTrainedAi"
          :selectedItems="selectedItems"
          :finishColumnsFix="finishColumnsFix"
          :loadColumnResult="loadColumnResult"
          :loadTimeseriesTrainedAiData="loadTimeseriesTrainedAiData"
          :inferencedColumns="inferencedColumns"
          :timeseriesSettings="timeseriesSettings"
          @change-column="changeColumn"
        />
        <dimensionality-reduction-result
          v-else-if="inferenceType === 'dimRed'"
          :selectedTrainedAi="selectedTrainedAi"
          :dimRedResult="dimRedResult"
        />
        <clustering-result
          v-else-if="inferenceType === 'clustering'"
          :clusteringInfo="clusteringInfo"
          :clusteringDistributions="clusteringDistributions"
          :clusteringResult="clusteringResult"
          :clusteringSetting="clusteringSetting"
          @input-clustering-setting="$emit('input-clustering-setting', $event)"
          @download-clustering-result="
            $emit('download-clustering-result', $event)
          "
          @change-clustering-distribution-column="
            $emit('change-clustering-distribution-column', $event)
          "
          @toggle-clustering-show-dimension="
            $emit('toggle-clustering-show-dimension', $event)
          "
        />
        <!--
          TODO 推論実行時のテキストマイニングの結果取得実装後修正
        <text-mining-result
          v-else-if="inferenceType === 'textMining'"
          :textMiningResult="textMiningResult"
          :textMiningSetting="textMiningSetting"
          @load-text-mining="$emit('load-text-mining', $event)"
          @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
        />
        -->
        <optimization-result
          v-else-if="
            optimizationValues.showOptimizationResult &&
              settedConditions &&
              Object.keys(settedConditions).length > 0
          "
          :accountInfo="accountInfo"
          :selectedConditions="settedConditions"
          :optimizationResult="optimizationValues.optimizationResult"
          :optimizationInfo="optimizationInfo"
          @close-optimization-result-tutorial="
            $emit('close-optimization-result-tutorial')
          "
          @input-edit-optimization-form="
            $emit('input-edit-optimization-form', $event)
          "
          @save-edit-optimization-form="
            $emit('save-edit-optimization-form', $event)
          "
          @change-optimization-page="$emit('change-optimization-page', $event)"
          @change-optimization-filter-value="
            $emit('change-optimization-filter-value', $event)
          "
          @download-optimization-result="
            $emit('download-optimization-result', $event)
          "
        />
      </div>
      <!--
        TODO 推論実行時のテキストマイニングの結果取得実装後修正
        ベクトル化の場合のみダウンロードボタンがないため、この位置にテキストマイニングのボタンを設置する
      <div
        v-if="checkTextMining && checkVectorization"
        class="vectorize-text-mining"
      >
        <text-toggle-button
          :value="showTextMining"
          iconName="textmining"
          :text="$t('textMining.inference.showResult')"
          :textOn="$t('inference.result.detail.showInferenceResult')"
          @input="inputTextMining"
        />
      </div>
      -->
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main.vue'
import circleProgressIcon from '@/components/molecules/circle-progress-icon.vue'
import icons from '@/components/atoms/icon.vue'
import TextBox from '@/components/atoms/text-box.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import classificationResult from '@/components/organisms/inference/result/classfication/classification-result.vue'
import regressionResult from '@/components/organisms/inference/result/regression/regression-result.vue'
import textToggleButton from '@/components/atoms/text-toggle-button.vue'
import textWithIcon from '@/components/molecules/text-with-icon.vue'
import timeseriesResult from '@/components/organisms/inference/result/timeseries/timeseries-result.vue'
import vectorizationResult from '@/components/organisms/inference/result/vectorization/vectorization-result.vue'
import dimensionalityReductionResult from '@/components/organisms/inference/result/dimensionality-reduction/dimensionality-reduction-result.vue'
import clusteringResult from '@/components/organisms/inference/result/clustering/clustering-result.vue'
// import textMiningResult from '@/components/organisms/inference/result/text-mining/text-mining-result.vue'
import optimizationResult from '@/components/organisms/inference/result/optimization/optimization-result.vue'
import { mapState } from 'vuex'
import selectBox from '@/components/molecules/select-box'
import loadingIcon from '@/components/atoms/loading-icon'

function isWindows() {
  return window.navigator.userAgent.toLowerCase().indexOf('windows') >= 0
}

export default {
  components: {
    circleProgressIcon,
    buttonMain,
    icons,
    TextBox,
    transitionToggleContents,
    classificationResult,
    dimensionalityReductionResult,
    clusteringResult,
    regressionResult,
    textToggleButton,
    textWithIcon,
    timeseriesResult,
    vectorizationResult,
    // textMiningResult,
    optimizationResult,
    selectBox,
    loadingIcon
  },
  data() {
    return {
      showFeatures: false,
      showTextMining: false,
      posBottomPopupBorder: 6,
      encoding: isWindows() ? 'cp932' : 'utf8',
      selectedItem: null,
      internalSelectedColumn: 0,
      waitSetSelectedColumn: true
    }
  },
  props: {
    accountInfo: Object,
    selectedTrainedAi: Object,
    inputColumns: Array,
    selectedItems: Object,
    downloading: Boolean,
    downloadComplete: Boolean,

    inferenceWarnings: Array,

    predictedColumns: Array,
    predictedTable: Array,
    total: Number,
    finishedTotal: Number,
    tableDatas: Object,

    forecast: Array,
    loadColumnResult: Boolean,
    loadTimeseriesTrainedAiData: Boolean,
    inferencedColumns: Array,
    timeseriesSettings: Object,
    finishColumnsFix: Array,
    finishColumnsAllProcessFix: Array,
    finishedAllColumns: Boolean,
    selectedColumnIndex: Number,
    inferencedColumnsBase: Array,
    optimizationValues: Object,
    optimizationInfo: Object,

    textMiningResult: Object,
    textMiningSetting: Object,
    clusteringInfo: Object,
    clusteringDistributions: Array,
    clusteringResult: Object,
    clusteringSetting: Object
  },
  computed: {
    ...mapState('inference', [
      'inferenceType',
      'imageClassficationResult',
      'textClassificationResult',
      'vectorizationResult',
      'dimRedResult',
      'meanShap',
      'featureImportanceImg'
    ]),
    encodingOptions() {
      return [
        {
          id: 0,
          name: 'Windows (Shift-JIS)',
          value: 'cp932'
        },
        {
          id: 1,
          name: 'Mac (UTF-8)',
          value: 'utf8'
        }
      ]
    },
    defaultEncoding() {
      return isWindows() ? this.encodingOptions[0] : this.encodingOptions[1]
    },
    checkDownload() {
      const isForecast = this.forecast && this.forecast.length > 0
      const isPredictedTable = this.predictedTable.length > 0
      const isTextClassification = this.textClassificationResult.length > 0
      const isImageClassification = this.imageClassficationResult.length > 0
      const isOptimization =
        this.optimizationValues.showOptimizationResult &&
        this.settedConditions &&
        Object.keys(this.settedConditions).length > 0
      return (
        isForecast ||
        isPredictedTable ||
        isTextClassification ||
        isImageClassification ||
        isOptimization
      )
    },
    checkVectorization() {
      return (
        this.inferenceType === 'vectorization' ||
        this.inferenceType === 'textVectorization' ||
        this.selectedItems.inferenceType === 'vectorization'
      )
    },
    checkClassification() {
      return (
        this.inferenceType === 'classification' ||
        this.inferenceType === 'textClassification'
      )
    },
    timeseriesFinishStatus() {
      const progressMax = this.inferencedColumns.length
      if (progressMax > this.progress) {
        return this.$t('inference.result.finishStatus.progress', {
          progress: this.progress,
          progressMax: progressMax
        })
      } else {
        return this.$t('inference.result.finishStatus.comp')
      }
    },
    checkDownloadIndividual() {
      if (!this.inferencedColumns) return false
      return this.inferencedColumns.indexOf(this.selectedItem) === -1
    },
    checkDownloadAll() {
      if (!this.inferencedColumns || !this.finishColumnsAllProcessFix)
        return false
      return (
        this.inferencedColumns.length !== this.finishColumnsAllProcessFix.length
      )
    },
    settedConditions() {
      const optimizationTime = new Date()
      return {
        id: this.optimizationInfo.configItems.optimizationId,
        name: this.optimizationValues.optimizationConditionsInfo?.name,
        description:
          this.optimizationValues.optimizationConditionsInfo?.description,
        createTime: optimizationTime,
        updateTime: optimizationTime,
        conditions: {
          ...this.optimizationValues.optimizationSettings.optimizationConditions
        }
      }
    },
    optimizationDownload() {
      return this.optimizationInfo?.download
    },
    titleProps() {
      if (this.optimizationValues?.showOptimizationResult) {
        return {
          iconName: 'optimization',
          text: this.$t('trainedAi.optimization.table.result.title')
        }
      } else {
        return {
          iconName: 'inferenceComp',
          text: this.$t('inference.result.title')
        }
      }
    },
    finishedAllProcesses() {
      return this.finishedTotal === null || this.finishedTotal >= this.total
    },
    displayProgress() {
      return (
        this.inferencedColumns &&
        (this.inferencedColumns.length > 1 ||
          this.inferenceType === 'classficationStructuredData')
      )
    },
    progress() {
      if (this.inferencedColumns.length > 1) {
        return this.finishColumnsAllProcessFix.length
      } else {
        return this.finishedAllProcesses ? 1 : 0
      }
    },
    checkTextMining() {
      return (
        this.textMiningSetting && Object.keys(this.textMiningSetting).length > 0
      )
    }
  },
  methods: {
    getWarningMessage(name) {
      return this.$t('inference.result.warnings.' + name)
    },
    clickDownload() {
      if (this.optimizationValues?.showOptimizationResult) {
        this.$emit('download-optimization-result', this.encoding)
      } else {
        this.$emit('download-inference-result', {
          encoding: this.encoding
        })
      }
    },
    clickDownloadIndividual() {
      this.$emit('download-multi-result', {
        encoding: this.encoding,
        column: this.selectedItem,
        isAll: false
      })
    },
    clickDownloadAll() {
      this.$emit('download-multi-result', {
        encoding: this.encoding,
        column: null,
        isAll: true
      })
    },
    setEncoding(e) {
      this.encoding = e
    },
    changeColumn(e) {
      if (e === this.selectedItem) return
      this.selectedItem = e

      let columnIndex = this.selectedColumnIndex
      if (this.inferencedColumnsBase && this.inferencedColumnsBase.length > 1) {
        columnIndex = this.inferencedColumnsBase.indexOf(e)
      }
      this.internalSelectedColumn = columnIndex

      this.$emit('change-column', e)
    },
    inputFeatures(e) {
      this.showFeatures = e
      this.showTextMining = false
    },
    inputTextMining(e) {
      this.showTextMining = e
      this.showFeatures = false
    }
  },
  watch: {
    showFeatures() {
      if (this.inferencedColumnsBase && this.inferencedColumnsBase.length > 1) {
        const column = this.inferencedColumnsBase[this.selectedColumnIndex]
        this.selectedItem = column
        const columnIndex = this.inferencedColumnsBase.indexOf(column)
        this.internalSelectedColumn = columnIndex
      }
    }
  },
  mounted() {
    if (!this.inferencedColumnsBase) return
    this.selectedItem = this.inferencedColumnsBase[this.selectedColumnIndex]
    this.internalSelectedColumn = this.selectedColumnIndex
    this.$nextTick(() => {
      this.waitSetSelectedColumn = false
    })
  }
}
</script>

<style lang="scss" scoped>
.inference-result-wrap {
  overflow: hidden;
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
  width: 100%;
  height: 100%;
  padding: adjustVH($headerTabHeight + 24) 0 0;
  &-no-download {
    grid-template-rows: auto minmax(0, 1fr);
  }
}

.inference-result-title {
  display: flex;
  justify-content: flex-start;
  grid-column-gap: $space-medium;
  padding: 0 $space-medium;
  margin-bottom: $space-medium;
  &-inner {
    width: fit-content;
  }
}

.inference-result-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 $space-medium;
  margin-bottom: $space-medium;
}

.inference-result-body {
  position: relative;
  width: 100%;
  height: 100%;
}

.inference-result-inner {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.inference-result-download {
  display: flex;
}

.download-button {
  flex-shrink: 0;
  margin: 0 $space-medium 0 0;
  &-timeseries {
    height: 100%;
  }
}
.download-loading {
  display: flex;
  align-items: center;
}
.download-select-encoding {
  width: adjustVW(220);
  margin: 0 $space-medium 0 0;

  ::v-deep .c-func-select-item {
    white-space: nowrap;
  }
}

.top-caution {
  &-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    height: adjustVW(48); // buttonMainの高さ
  }
  &-item {
    margin: 0 $space-text $space-text 0;
    &:nth-of-type(2n) {
      margin: 0 $space-text 0 0;
    }
    &:last-of-type {
      margin: 0;
    }
  }
  &-inner {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-right: $space-small;
    &:last-of-type {
      margin: 0;
    }
  }
}

.inference-timeseries-result-multi {
  &-count {
    display: flex;
    align-items: center;
    grid-column-gap: $space-sub;
  }
  &-icon {
    width: adjustVW(24);
    height: adjustVW(24);
  }
  &-text {
    flex-shrink: 0;
  }
}

.inference-result-change {
  display: flex;
  grid-column-gap: $space-small;
}

.optimization {
  &-info {
    display: flex;
    align-items: center;
    grid-column-gap: $space-sub;
  }
  &-link {
    color: $text-link;
    text-decoration: underline;
  }
}

.vectorize-text-mining {
  position: absolute;
  top: -$space-base;
  right: $space-small;
}
</style>
