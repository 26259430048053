<template>
  <div class="figures-list-item-wrapper">
    <checkbox-base
      class="figures-list-item-check"
      :checked="isChecked"
      :isDisabled="!checkValid"
      @input="onCheck($event, item)"
    />

    <div v-if="checkMapping" class="mapping-list-item-wrapper">
      <texts
        :text="item.name"
        :isDisabled="!checkValid"
        :color="checkValid ? 'default' : 'disabled'"
      />
      <tooltip-popover noBorder :placement="'top-end'">
        <template v-slot:main>
          <div class="mapping-list-item-icon">
            <icons iconName="preproc" />
          </div>
        </template>

        <template v-slot:popup>
          <div class="mapping-list-item-popup-wrapper">
            <div class="mapping-list-item-popup-title">
              <texts
                :text="$t('training.popup.madeColumns.title')"
                size="small"
              />
            </div>
            <div class="mapping-list-item-popup-overview">
              <texts
                isShowAll
                noTips
                :text="$t('training.popup.madeColumns.overview')"
                size="min"
                color="gray"
                class="mapping-list-item-popup-overview-text"
              />
            </div>

            <div class="mapping-list-item-popup-content-wrapper">
              <template v-for="(column, index) in item.mapping">
                <div :key="index">
                  <dot-text :text="column" />
                </div>
              </template>
            </div>
          </div>
        </template>
      </tooltip-popover>
    </div>

    <div v-else>
      <texts
        class="figures-list-item-content"
        :isDisabled="!checkValid"
        :text="item.name"
        :color="checkValid ? 'default' : 'disabled'"
      />
    </div>
  </div>
</template>

<script>
import checkboxBase from '@/components/atoms/checkbox-base.vue'
import dotText from '@/components/atoms/dot-text.vue'
import icons from '@/components/atoms/icon.vue'
import texts from '@/components/atoms/text.vue'
import tooltipPopover from '@/components/molecules/tooltip-popover.vue'

export default {
  components: {
    checkboxBase,
    dotText,
    icons,
    texts,
    tooltipPopover
  },
  props: {
    checkedItems: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: () => {}
    },
    checkSpecifyColRecipe: Boolean
  },
  methods: {
    onCheck(e, item) {
      this.$emit('onCheck', { check: e, item: item })
    }
  },
  computed: {
    isChecked() {
      if (this.checkedItems.length === 0) return false
      return this.checkedItems.some((check) => {
        return check.name === this.item.name
      })
    },
    checkMapping() {
      return this.item.mapping != null && this.item.mapping.length > 0
    },
    checkValid() {
      if (this.item.valid) {
        return true
      } else {
        return this.checkSpecifyColValid
      }
    },
    checkSpecifyColValid() {
      if (!this.checkSpecifyColRecipe) return false
      return (
        this.item.reasons.length === 1 && this.item.reasons[0] === 'onlynum'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.figures-list-item-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.figures-list-item-check {
  flex-shrink: 0;
  width: adjustVW(24);
  padding: 0;
  margin: 0 $space-sub 0 0;
}

.figures-list-item-content {
  overflow: hidden;
  flex-shrink: 1;
  flex-grow: 1;
  width: 100%;
}

.mapping-list-item-wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.mapping-list-item-icon {
  margin-left: $space-sub;
}

.mapping-list-item-popup-wrapper {
  width: adjustVW(340);
}

.mapping-list-item-popup-title {
  margin-bottom: $space-text;
}
.mapping-list-item-popup-overview {
  margin-bottom: $space-sub;
}

.mapping-list-item-popup-overview-text {
  white-space: pre-line;
}

.mapping-list-item-popup-content-wrapper {
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: adjustVH(200);
  @include scrollbar;
  column-gap: $space-base;
  row-gap: $space-base;
}
</style>
