<template>
  <div>
    <transition-toggle-contents>
      <loading v-if="trainedAILoading" key="loading" />
      <trained-ai-list-card
        v-else-if="layoutType === 'grid'"
        key="grid"
        v-model="checked"
        :field="fields"
        :items="trainedAiList"
        :project="project"
        :noSearchResults="noSearchResults"
      />
      <table-list
        v-else-if="layoutType === 'optimization'"
        key="optimization"
        v-model="checked"
        :field="optimizationFields"
        :items="traineAiListOptimization"
        :noSearchResults="noSearchResults"
        defaultSortedBy="updateTime"
        class="trained-ai-list-list"
      />
      <table-list
        v-else
        key="list"
        v-model="checked"
        :field="fields"
        :items="trainedAiList"
        :noSearchResults="noSearchResults"
        defaultSortedBy="updateTime"
        class="trained-ai-list-list"
      />
    </transition-toggle-contents>
  </div>
</template>

<script>
import tableList from '@/components/molecules/table-list'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import trainedAiListCard from './trained-ai-list-card'

export default {
  components: {
    tableList,
    transitionToggleContents,
    trainedAiListCard
  },
  data() {
    return {
      checked: []
    }
  },
  computed: {
    fields() {
      /**
       * 期待される効果がある場合のみリストに期待される効果を表示する
       * 実際には学習済みAIを取得する時点でわかるようにしたいかも、この関数だと不安、、
       *
       **/
      if ('expectedEffect' in this.project.template) {
        return [
          {
            id: 0,
            key: 'name',
            label: this.$t('trainedAiList.list.name'),
            thClass: 'name',
            listType: 'name',
            sortable: true
          },
          {
            id: 1,
            key: 'expectedEffect',
            label: this.project.template.expectedEffectName, // 使用したテンプレートによって変わる
            thClass: 'default',
            listType: 'accuracy',
            percentage: true,
            sortable: false
          },
          {
            id: 2,
            key: 'test_accuracy',
            label: this.$t('trainedAiDetails.tabs.metrics'),
            thClass: 'default',
            listType: 'accuracy',
            percentage: true,
            sortable: false
          },
          {
            id: 3,
            key: 'featureImportance',
            label: this.$t('trainedAiList.list.featureImportance'),
            thClass: 'large',
            listType: 'featureImportance',
            sortable: false
          },
          {
            id: 4,
            key: 'updateTime',
            label: this.$t('common.updateTime'),
            thClass: 'default',
            listType: 'time',
            sortable: true
          }
        ]
      } else {
        return [
          {
            id: 0,
            key: 'name',
            label: this.$t('trainedAiList.list.name'),
            thClass: 'name',
            listType: 'name',
            sortable: true
          },
          {
            id: 2,
            key: 'test_accuracy',
            label: this.$t('trainedAiDetails.tabs.metrics'),
            thClass: 'default',
            listType: 'accuracy',
            sortable: false
          },
          {
            id: 3,
            key: 'featureImportance',
            label: this.$t('trainedAiList.list.featureImportance'),
            thClass: 'large',
            listType: 'featureImportance',
            sortable: false
          },
          {
            id: 4,
            key: 'updateTime',
            label: this.$t('common.updateTime'),
            thClass: 'default',
            listType: 'time',
            sortable: true
          }
        ]
      }
    },
    optimizationFields() {
      return [
        {
          id: 0,
          key: 'name',
          label: this.$t('trainedAiList.list.name'),
          thClass: 'name',
          listType: 'name',
          sortable: true
        },
        {
          id: 1,
          key: 'updateTime',
          label: this.$t('common.updateTime'),
          thClass: 'default',
          listType: 'time',
          sortable: true
        },
        {
          id: 2,
          key: 'optimizationConditions',
          label: this.$t('trainedAi.optimization.latestHistory'),
          thClass: 'preprocessing',
          listType: 'optimization',
          sortable: false
        }
      ]
    },
    trainedAiList() {
      const trainedAiList = []
      for (const key in this.trainedAIs) {
        let trainedAi = this.trainedAIs[key]
        trainedAi = Object.assign({}, trainedAi, {
          link: { name: 'trainedAiDetail', params: trainedAi.id }
        })
        trainedAiList.push(trainedAi)
      }
      return trainedAiList
    },
    traineAiListOptimization() {
      if (!this.trainedAiList || this.trainedAiList.length === 0) return []
      return this.trainedAiList.filter((ai) => {
        return ai?.is_optimization
      })
    }
  },
  props: {
    trainedAIs: Array,
    trainedAILoading: Boolean,
    layoutType: String,
    project: Object,
    noSearchResults: Boolean
  },
  watch: {
    checked(newVal) {
      this.$emit('input', newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.trained-ai-list-list {
  overflow: hidden;
  height: 100%;
  border-radius: adjustVW(16);
  box-shadow: $box-shadow-main;
}
</style>
