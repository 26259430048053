<template>
  <div class="bottom-wrap">
    <div class="bottom-title">
      <texts :text="$t('preprocessing.bottomTitle')" isBold />
      <div class="bottom-title-menu">
        <button
          :disabled="
            loading || preProcLatestVersion == 0 || preProcHeadVersion == 0
          "
          :class="{
            'no-pointer': preProcLatestVersion == 0 || preProcHeadVersion == 0
          }"
          @click="$emit('open-save-preprocessing', 'savePreprocessing')"
        >
          <text-with-icon
            class="bottom-title-menu-icon"
            :text="$t('preprocessing.applyingDataset.savePreprocessing')"
            :color="
              preProcLatestVersion == 0 || preProcHeadVersion == 0
                ? 'gray'
                : 'default'
            "
            iconName="save"
            size="small"
          />
        </button>
        <button
          :disabled="loading || preProcHeadVersion == 0"
          :class="{ 'no-pointer': preProcHeadVersion == 0 }"
          @click="$emit('back-version')"
        >
          <text-with-icon
            class="bottom-title-menu-icon"
            :text="$t('preprocessing.applyingDataset.undo')"
            :color="preProcHeadVersion == 0 ? 'gray' : 'default'"
            iconName="undo"
            size="small"
          />
        </button>
        <button
          :disabled="loading || preProcHeadVersion == preProcLatestVersion"
          :class="{ 'no-pointer': preProcHeadVersion == preProcLatestVersion }"
          @click="$emit('next-version')"
        >
          <text-with-icon
            class="bottom-title-menu-icon"
            :text="$t('preprocessing.applyingDataset.redo')"
            :color="
              preProcHeadVersion == preProcLatestVersion ? 'gray' : 'default'
            "
            iconName="redo"
            size="small"
            pos="right"
          />
        </button>
        <div class="bottom-title-menu-count no-pointer">
          <texts
            class="bottom-title-menu-text"
            :text="$t('preprocessing.applyingDataset.count')"
            :color="'gray'"
            size="small"
          />
          <texts
            class="bottom-title-menu-icon"
            :text="
              String(preProcHeadVersion + 1) +
                '/' +
                String(preProcLatestVersion + 1)
            "
            :color="'gray'"
            size="small"
          />
        </div>
        <button
          v-tooltip="allReset"
          :disabled="loading || preProcLatestVersion == 0"
          :class="{ 'no-pointer': preProcLatestVersion == 0 }"
          @click="$emit('reset-preprocessing')"
        >
          <text-with-icon
            class="bottom-title-menu-lasticon"
            :text="$t('preprocessing.applyingDataset.reset')"
            :color="preProcLatestVersion == 0 ? 'gray' : 'default'"
            iconName="reset"
            size="small"
          />
        </button>
      </div>
    </div>
    <div class="bottom-list">
      <preprocessing-chart-list
        class="bottom-list-body"
        :layers="layers"
        useScrollX
      />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import preprocessingChartList from '@/components/molecules/preprocessing-chart-list'
import textWithIcon from '@/components/molecules/text-with-icon'
import { getLayers } from '@/lib/preprocessing'

export default {
  components: {
    texts,
    preprocessingChartList,
    textWithIcon
  },
  props: {
    checked: Array,
    preProcHeadVersion: Number,
    preProcLatestVersion: Number,
    loading: Boolean,
    appliedChart: Object,
    isTrain: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    layers() {
      return getLayers(this.appliedChart, false)
    },
    allReset() {
      const content = this.isTrain
        ? this.$t('preprocessing.applyingDataset.backAllTrain')
        : this.$t('preprocessing.applyingDataset.backAll')
      const base = {
        content,
        trigger: ['manual'],
        show:
          !this.loading &&
          this.preProcHeadVersion === 0 &&
          this.preProcLatestVersion > 0,
        delay: { show: 500, hide: 500 }
      }
      return base
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom {
  &-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $space-small $space-small $space-base;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
  }
  &-title {
    display: flex;
    justify-content: space-between;
    padding: 0 0 $space-sub;
    &-menu {
      display: flex;
      &-icon {
        display: flex;
        padding: 0 $space-medium 0 0;
      }
      &-text {
        display: flex;
        padding: 0 $space-base 0 0;
      }
      &-count {
        display: flex;
      }
      &-lasticon {
        padding: 0;
      }
    }
  }
  &-list {
    height: 100%;
  }
}
.no-pointer {
  cursor: default;
  &:hover {
    opacity: 1;
  }
}
</style>
