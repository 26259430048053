<template>
  <div>
    <describe-of-error
      :description="$t('preprocessing.popup.applyAutoPreprocess.desc')"
      :okButton="$t('preprocessing.popup.applyAutoPreprocess.button.apply')"
      :cancelButton="$t('common.cancel')"
      @ok="$emit('apply-auto-preprocess')"
      @cancel="$emit('close-modal')"
    />
  </div>
</template>

<script>
import describeOfError from '@/components/organisms/common-popups/describe-of-error.vue'

export default {
  components: {
    describeOfError
  }
}
</script>

<style lang="scss">
.button-list {
  margin: $space-sub 0 0;
}
</style>
