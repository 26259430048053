<template>
  <div class="apply-body-content-body">
    <select-box
      v-model="selectedType"
      class="apply-body-content-box"
      :items="choices"
      isGray
      min
      :firstSelectItem="choices[0]"
      valueKey="id"
    />
    <standardize
      v-if="selectedType === 0"
      @apply-standardize="$emit('apply-standardize')"
    />
    <yeoJohnson
      v-if="selectedType === 1"
      @apply-yeo-johnson="$emit('apply-yeo-johnson')"
    />
    <binning
      v-if="selectedType === 2"
      :maxValue="maxValue"
      :minValue="minValue"
      @apply-binning="$emit('apply-binning', $event)"
    />
  </div>
</template>

<script>
import selectBox from '@/components/molecules/select-box'
import standardize from './/transform-sub/standardize'
import yeoJohnson from './/transform-sub/yeo-johnson'
import binning from './/transform-sub/binning'

export default {
  components: {
    selectBox,
    standardize,
    yeoJohnson,
    binning
  },
  props: {
    minValue: Number,
    maxValue: Number
  },
  data() {
    return {
      selectedType: 0
    }
  },
  computed: {
    choices() {
      return [
        {
          id: 0,
          name: this.$t('preprocessing.applyingDataset.standardize'),
          value: 'standardize'
        },
        {
          id: 1,
          name: this.$t('preprocessing.applyingDataset.yeoJohnson'),
          value: 'yeoJohnson'
        },
        {
          id: 2,
          name: this.$t('preprocessing.applyingDataset.binning'),
          value: 'binning'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.apply-body-content-box {
  flex-shrink: 0;
  height: adjustVH(48); // Zepplinと違いますがちょっと狭かったので微調整
  margin: 0 0 $space-small;
}
</style>
