<template>
  <card-base>
    <div class="config-wrap">
      <div v-if="columnList && columnList.length > 1" class="config-item">
        <div class="config-item-title">
          <texts
            :text="$t('trainedAi.multi.selectedColumn')"
            size="small"
            color="gray"
          />
        </div>
        <select-box
          v-model="internalColumnName"
          :items="columnList"
          isGray
          :isDisabled="waitTextMiningLoading"
        />
      </div>
      <div v-if="showSentiment" class="config-item">
        <div class="config-item-title">
          <texts
            :text="$t('textMining.textList.config.sentiments')"
            size="small"
            color="gray"
          />
        </div>
        <select-box
          v-model="sentiments"
          :items="sentimentsList"
          isGray
          :isDisabled="waitTextMiningLoading"
        />
      </div>
      <div class="config-item">
        <div class="config-item-title">
          <texts
            :text="$t('textMining.textList.config.sort')"
            size="small"
            color="gray"
          />
        </div>
        <select-box
          v-model="sortTarget"
          :items="sortList"
          isGray
          :isDisabled="waitTextMiningLoading"
        />
      </div>
      <div class="config-item">
        <paging
          v-model="page"
          :count="total"
          :pagingCount="pageCount"
          :isDisabled="waitTextMiningLoading"
        />
      </div>
    </div>
  </card-base>
</template>

<script>
import cardBase from '@/components/atoms/card-base'
import selectBox from '@/components/molecules/select-box'
import paging from '@/components/molecules/paging'

export default {
  components: {
    cardBase,
    selectBox,
    paging
  },
  data() {
    return {
      pageCount: 20 // 20件でページングViewsで持ちたい
    }
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    columns: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: null
    },
    showSentiment: {
      type: Boolean,
      default: false
    },
    columnName: {
      type: String,
      default: ''
    },
    waitTextMiningLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateValue(type, value) {
      const payload = this.value
      payload[type] = value
      this.$emit('input', payload)
    },
    clickDownload() {
      this.$emit('download-result', this.columnName)
    }
  },
  computed: {
    sentiments: {
      get() {
        return this.value.sentiments
      },
      set(newVal) {
        this.updateValue('sentiments', newVal)
      }
    },
    sortTarget: {
      get() {
        return this.value.sortTarget
      },
      set(newVal) {
        this.updateValue('sortTarget', newVal)
      }
    },
    internalColumnName: {
      get() {
        return this.columnName
      },
      set(newVal) {
        this.$emit('change-column-name', newVal)
      }
    },
    page: {
      get() {
        return this.value.page
      },
      set(newVal) {
        this.updateValue('page', newVal)
      }
    },
    sentimentsList() {
      return [
        {
          value: 'negative',
          name: this.$t('textMining.textList.config.sentimentList.negative')
        },
        {
          value: 'positive',
          name: this.$t('textMining.textList.config.sentimentList.positive')
        },
        {
          value: 'neutral',
          name: this.$t('textMining.textList.config.sentimentList.neutral')
        }
      ]
    },
    sortList() {
      const list = [
        {
          value: 'indexDesc',
          name: this.$t('textMining.textList.config.sortList.indexDesc')
        },
        {
          value: 'indexAsc',
          name: this.$t('textMining.textList.config.sortList.indexAsc')
        }
      ]
      if (this.showSentiment) {
        list.unshift({
          value: 'proba',
          name: this.$t('textMining.textList.config.sortList.proba')
        })
      }
      return list
    },
    columnList() {
      return this.columns.map((column) => {
        return {
          value: column,
          name: column
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.config {
  &-wrap {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, adjustVW(224)) 1fr;
    grid-column-gap: $space-medium;
  }
  &-download {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding-left: $space-medium;
    margin-left: $space-medium;
    border-left: $border-sub;
  }
  &-item {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: center;
    width: 100%;
    &-title {
      margin-bottom: $space-text;
    }
  }
}
</style>
