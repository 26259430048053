<template>
  <div class="c-wrap">
    <sidebar-train v-bind="sidebar" />
    <div class="c-body-wrap">
      <header-tabs v-bind="headerTabs" />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="loadingPage" />
          <projectTemplateDetail
            v-else
            :datasetId="datasetId"
            :projectDetail="projectDetail"
            :loadingDatasets="loadingDatasets"
            @select-project="$emit('select-project')"
            @page-back="$emit('page-back')"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="disabledClick" />
    <hover-menu />
    <project-template-detail-popup
      v-bind="popup"
      :disabledClick="disabledClick"
      :projectDetail="projectDetail"
      @close-modal="$emit('close-modal', $event)"
      @create-project="$emit('create-project', $event)"
      @input-create-form="$emit('input-create-form', $event)"
    />
  </div>
</template>

<script>
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import projectTemplateDetail from '@/components/organisms/project-template-detail/project-template-detail.vue'
import sidebarTrain from '@/components/organisms/sidebar-train.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import projectTemplateDetailPopup from '@/components/organisms/project-template-detail/project-template-detail-popup'
import transitionPage from '@/components/molecules/transition-page'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import Loading from '@/components/atoms/loading.vue'

export default {
  components: {
    loadingProcessing,
    projectTemplateDetail,
    sidebarTrain,
    headerTabs,
    hoverMenu,
    projectTemplateDetailPopup,
    transitionPage,
    TransitionToggleContents,
    Loading
  },
  props: {
    datasetId: String,
    disabledClick: {
      type: Boolean,
      default: false
    },
    sidebar: Object,
    headerTabs: Object,
    projectDetail: Object,
    popup: Object,
    loadingPage: {
      type: Boolean,
      default: false
    },
    loadingDatasets: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
  background: $background;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
  background: $background-sub;
}
</style>
