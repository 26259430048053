<template>
  <card-base class="c-card">
    <list-item-slot
      class="preprocess-list"
      type="button"
      :field="field"
      :items="sortedItems"
      :iconButton="true"
      iconName="popup"
      @icon-click="showPreprocessDetail"
      @list-click="listClick"
    >
      <template v-slot:summary="entry">
        <div class="preprocess-summary">
          <texts
            class="preprocess-summary-name"
            :text="entry.item.name"
            isBold
            :class="{
              'preprocess-summary-name-non-desc': !entry.item.description
            }"
          />
          <description
            class="preprocess-summary-about"
            :text="entry.item.description"
            :line="2"
          />
        </div>
      </template>

      <template v-slot:layers="entry">
        <div class="preprocess-chart">
          <preprocessing-chart-list-min :layers="entry.item" noWrap />
        </div>
      </template>
    </list-item-slot>

    <preprocess-detail
      :showPopup="showPopup"
      :title="preprocessDetail.summary.name"
      :about="preprocessDetail.summary.description"
      :layers="preprocessDetail.layers"
      @close-modal="closePreoprocessDetail"
    />
  </card-base>
</template>

<script>
import cardBase from '@/components/atoms/card-base.vue'
import listItemSlot from '../../../molecules/list-item-slot.vue'
import preprocessDetail from '@/components/organisms/inference/menu/preprocess/preprocess-detail.vue'
import preprocessingChartListMin from '@/components/molecules/preprocessing-chart-list-min.vue'
import texts from '@/components/atoms/text.vue'
import description from '@/components/atoms/description.vue'

export default {
  components: {
    cardBase,
    listItemSlot,
    preprocessDetail,
    preprocessingChartListMin,
    texts,
    description
  },
  data() {
    return {
      showPopup: false,
      preprocessDetail: {
        summary: {
          name: '',
          about: ''
        },
        layers: []
      }
    }
  },
  props: {
    field: Array,
    items: Array
  },
  computed: {
    sortedItems() {
      return [...this.items].sort((x, y) =>
        x.updateTime < y.updateTime ? 1 : x.updateTime > y.updateTime ? -1 : 0
      )
    }
  },
  methods: {
    listClick: function ({ id: index }) {
      this.$emit('list-click', { index })
    },
    showPreprocessDetail: function ({ id: index }) {
      this.preprocessDetail = this.items.find((item) => item.id === index)
      this.showPopup = true
    },
    closePreoprocessDetail: function () {
      this.showPopup = false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
}

.preprocess-summary {
  display: flex;
  flex-direction: column;

  &-name {
    margin-bottom: $space-base;
    &-non-desc {
      margin: 0;
    }
  }

  &-about {
    white-space: normal;
  }
}

.preprocess-list {
  overflow-y: scroll;
  padding: 0 $space-base 0 0;
  @include scrollbar;
}
</style>
