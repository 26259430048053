<template>
  <!-- project-list -->
  <div class="project-list-wrap">
    <project-list-top
      v-model="filterSettings"
      :accountInfo="accountInfo"
      :checked="checked"
      :checkTemplateUser="checkTemplateUser"
      :projectList="Object.values(projectListResult)"
      @menu-click="menuClick"
      @input-search="search($event)"
    />
    <!-- project-list -->
    <project-list-card-list
      v-model="checked"
      :items="Object.values(projectListResult)"
      :sortOrder="filterSettings.order"
      :hasTrain="filterSettings.hasTrain"
      :hasService="filterSettings.hasService"
      :hasRecipe="filterSettings.hasRecipe"
      :noSearchResults="noSearchResults"
      :disableCheckProjectId="disableCheckProjectId"
      :plan="accountInfo.plan"
    />
  </div>
</template>

<script>
import projectListCardList from './project-list-card-list.vue'
import projectListTop from './project-list-top'

export default {
  components: {
    projectListCardList,
    projectListTop
  },
  props: {
    accountInfo: Object,
    projectList: Object
  },
  data() {
    return {
      filterSettings: {
        order: 'descending',
        hasTrain: false,
        hasService: false,
        hasRecipe: false
      },
      showCreatePopup: false,
      deletePopupTarget: null,
      checked: [],
      projectListArray: null,
      noSearchResults: false
    }
  },
  methods: {
    search(project) {
      this.projectListArray = project.result
      this.noSearchResults = project.noSearchResults
    },
    menuClick(menu) {
      if (menu.activeMenu === 'delete') {
        this.$emit('open-delete-project', this.checkedProject[0])
      } else if (menu.activeMenu === 'template') {
        this.$emit('open-create-template', this.checkedProject[0])
      }
    }
  },
  computed: {
    projectListResult() {
      if (!this.projectListArray) {
        return this.projectList
      } else {
        return this.projectListArray
      }
    },
    checkedProject() {
      return this.checked.map((id) => {
        return this.projectList[id]
      })
    },
    disableCheckProjectId() {
      // adminとテンプレートユーザー以外は自分のプロジェクトのみをチェック可能
      if (this.accountInfo?.organizationInfo?.role === 'admin') return []
      if (this.checkTemplateUser) return []
      return Object.values(this.projectList)
        .filter((x) => x.accountId !== this.accountInfo.accountId)
        .map((x) => {
          return x.id
        })
    },
    checkTemplateUser() {
      if (!this.accountInfo) return false
      if (
        !this.accountInfo?.planDetail?.options ||
        this.accountInfo?.planDetail.options.length === 0
      )
        return false
      return (
        this.accountInfo?.planDetail.options.filter(
          (option) => option.name === 'project_template_user'
        ).length > 0
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.project-list-wrap {
  display: grid;
  grid-template-rows: adjustVH(168) 1fr;
  grid-row-gap: $space-medium;
  height: auto;
  padding: 0 $space-medium $space-medium;
}

.project-list-body {
  width: 100%;
}
</style>
