<template>
  <div class="wrap">
    <NaviTrainedAI
      :active="activeFlow === 0"
      :isSelected="isSelected"
      :choosedItems="choosedItems"
      :index="0"
      :available="available(0)"
      @click="changeFlow(0)"
    />
    <NaviPreproc
      v-if="!checkTypeOptimization"
      :active="activeFlow === 1"
      :isSelected="isSelected"
      :noPreprocess="noPreprocess"
      :choosedItems="choosedItems"
      :preprocessingAvailable="preprocessingAvailable"
      :index="1"
      :available="available(1)"
      @click="changeFlow(1)"
    />
    <NaviDataset
      v-if="!checkTypeOptimization"
      :active="activeFlow === 2"
      :isSelected="isSelected"
      :choosedItems="choosedItems"
      :index="2"
      :available="available(2)"
      :checkTimeSeries="checkTimeSeries"
      @click="changeFlow(2)"
    />
    <NaviType
      :active="activeFlow === 3"
      :isSelected="isSelected"
      :choosedItems="choosedItems"
      :index="checkTypeOptimization ? 1 : 3"
      :available="available(3)"
      :checkTypeOptimization="checkTypeOptimization"
      @click="changeFlow(3)"
    />
  </div>
</template>

<script>
import NaviTrainedAI from './item/navi-trained-ai.vue'
import NaviPreproc from './item/navi-preprocess.vue'
import NaviDataset from './item/navi-dataset.vue'
import NaviType from './item/navi-type.vue'

export default {
  components: {
    NaviTrainedAI,
    NaviPreproc,
    NaviDataset,
    NaviType
  },
  data() {
    return {
      activeFlow: 0
    }
  },
  props: {
    noPreprocess: Boolean,
    choosedItems: Array,
    activeMenu: Number,
    availableSteps: Array,
    preprocessingAvailable: Boolean,
    checkTimeSeries: Boolean,
    checkTypeOptimization: Boolean
  },
  computed: {
    isSelected: function () {
      return this.choosedItems.map((item) => !!item?.name)
    }
  },
  watch: {
    activeMenu: function (val) {
      this.activeFlow = val
    }
  },
  methods: {
    changeFlow(e) {
      this.activeFlow = e
      this.$emit('click', this.activeFlow)
    },
    available(index) {
      return this?.availableSteps?.indexOf(index) >= 0
    }
  }
}
</script>

<style lang="scss" scoped>
.flow-list-item {
  margin-bottom: $space-base;
}

.choosed-item {
  margin-bottom: $space-base;
}

.item {
  margin: 0 0 $space-medium;
  cursor: pointer;
  user-select: none;

  &.disabled {
    cursor: not-allowed;
  }
}
</style>
