<template>
  <div key="list" class="list-inner">
    <div class="list-top">
      <list-top
        :layoutType="layoutType"
        @show-trained-ai-top="$emit('show-trained-ai-top')"
        @layout-menu-click="layoutType = $event"
      />
    </div>
    <div class="list-body">
      <list-body
        :sortedTrainedAis="sortedTrainedAis"
        :bestTrainedAi="bestTrainedAi"
        :layoutType="layoutType"
        :recipeType="recipeType"
        :trainedAiInput="sortedTrainedAis"
        :sortedFlag="sortedFlag"
        :selectedColumnIndex="selectedColumnIndex"
        @show-detail="$emit('show-detail', $event)"
      />
    </div>
  </div>
</template>

<script>
import listTop from './list/list-top'
import listBody from './list/list-body'

export default {
  components: {
    listTop,
    listBody
  },
  data() {
    return {
      layoutType: 'grid'
    }
  },
  props: {
    sortedTrainedAis: Array,
    bestTrainedAi: Object,
    recipeType: String,
    trainedAiInput: Array,
    sortedFlag: Boolean,
    selectedColumnIndex: Number
  }
}
</script>

<style lang="scss" scoped>
.list {
  &-inner {
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-row-gap: $space-medium;
    height: 100%;
  }
  &-body {
    overflow: hidden;
    padding-bottom: $space-medium;
  }
}
</style>
