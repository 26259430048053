var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-wrap"},[_c('div',{staticClass:"template-main"},[_c('div',{staticClass:"template-item"},[_c('text-with-title',{attrs:{"title":_vm.$t('serviceList.popup.addNewService.datasetSelectedTitle'),"text":_vm.datasetDetail ? _vm.datasetDetail.detail.name : _vm.$t('common.notSelected')}})],1),_c('texts',{staticClass:"template-item-title",attrs:{"text":_vm.$t('service.learn.inputColumns'),"size":"small","color":"gray"}}),_c('div',{staticClass:"template-item template-check-wrap"},[(_vm.columns && _vm.columns.length > 0)?_c('list-item-slot',{staticClass:"template-check-body",attrs:{"field":_vm.field,"items":_vm.columns},scopedSlots:_vm._u([{key:"columnField",fn:function(){return [_c('label',{staticClass:"template-check-name"},[_c('checkbox-base',{staticClass:"template-check-name-check",attrs:{"checked":_vm.isAllCheckOn,"isShowMinusIcon":_vm.isNotAllChecked},on:{"input":function($event){return _vm.allCheck($event)}}}),_c('texts',{staticClass:"template-check-name-text",attrs:{"text":_vm.$t('training.config.detail.field.columnName')}})],1)]},proxy:true},{key:"column",fn:function(entry){return [_c('label',{staticClass:"template-check-name"},[[_c('checkbox-base',{staticClass:"template-check-name-check",attrs:{"checked":_vm.predictionColumns.indexOf(entry.entry.name) !== -1 ||
                    _vm.inputColumns.indexOf(entry.entry.name) !== -1,"isDisabled":_vm.predictionColumns.indexOf(entry.entry.name) !== -1},on:{"input":function($event){return _vm.onCheck($event, entry.entry.name)}}}),_c('texts',{staticClass:"template-check-name-text",attrs:{"text":entry.entry.name}})]],2)]}}],null,false,1183658969)}):_vm._e(),_c('input-box',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('serviceList.popup.addNewService.columnInputError'),
          trigger: ['manual'],
          show: _vm.isInputBoxError,
          delay: { show: 300, hide: 300 }
        }),expression:"{\n          content: $t('serviceList.popup.addNewService.columnInputError'),\n          trigger: ['manual'],\n          show: isInputBoxError,\n          delay: { show: 300, hide: 300 }\n        }"}],staticClass:"template-text-area",class:{
          'template-text-area-error': _vm.isInputBoxError,
          'template-text-area-no-dataset': _vm.columns.length === 0
        },attrs:{"placeholder":_vm.$t('serviceList.popup.addNewService.columnInputPlaceholder'),"isTextArea":"","isGray":""},on:{"input":function($event){return _vm.updateTextArea($event)}},model:{value:(_vm.inputText),callback:function ($$v) {_vm.inputText=$$v},expression:"inputText"}})],1),_c('div',{staticClass:"template-button"},[_c('button-large',{staticClass:"template-button-item",attrs:{"text":_vm.$t('common.checkSetting'),"isDisabled":_vm.inputColumns.length === 0},on:{"click":function($event){return _vm.$emit('select-column', { inputColumns: _vm.inputColumns })}}})],1)],1),_c('dataset-setting-table',{staticClass:"template-detail",attrs:{"table":_vm.checkDatasetDetail.table,"columns":_vm.checkDatasetDetail.tableColumns,"type":_vm.checkDatasetDetail.type,"nLabels":_vm.nLabels}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }