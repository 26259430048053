<template>
  <div
    class="cy"
    droppable="true"
    @drop="itemDropped"
    @dragover="itemDragOver"
  >
    <!--
    <modal id="connection-warning" v-model="connectionWarningFlag" size="lg" hide-footer>
      <template v-slot:modal-title>
        <span class="warning"><b>{{ $t("recipe.connectionWarning.title." + warningTitle) }}</b></span>
      </template>
      <p v-if="warningMessage" class="my-2">{{ $t("recipe.connectionWarning.reason." + warningMessage) }}</p>
      <button class="connection-warning-btn" @click="$bvModal.hide('connection-warning')">{{$t("button.close")}}</button>
    </modal>
    -->
    <div class="warning-wrap">
      <transition-toggle-contents>
        <warning-info-box
          v-if="infoFlag"
          :title="infoTitle"
          :message="infoMessage"
          isInfo
          @click-close="infoFlag = false"
        />
      </transition-toggle-contents>
      <transition-toggle-contents>
        <warning-info-box
          v-if="connectionWarningFlag && checkDispWarningList === 0"
          :title="warningTitle"
          :message="warningMessage"
          @click-close="connectionWarningFlag = false"
        />
      </transition-toggle-contents>
      <transition-group name="toggle-item">
        <text-box
          v-for="(item, index) in warningList"
          :key="index"
          class="warning-item"
          isError
        >
          <texts
            class="warning-title"
            :text="$t('recipe.connectionWarning.title.' + warningTitle)"
            color="caution"
            size="small"
          />
          <texts
            class="warning-text"
            :text="$t('recipe.connectionWarning.reason.' + item.message)"
            color="caution"
            size="small"
          />
        </text-box>
      </transition-group>
    </div>
    <div class="eddit-controller" :class="{ 'eddit-controller-save': isSave }">
      <icon-button
        class="eddit-controller-icon"
        iconName="resetZoom"
        @click="resetZoom"
      />
      <icon-button
        class="eddit-controller-icon"
        iconName="resetMove"
        @click="resetPan"
      />
      <icon-button
        v-if="edittable"
        class="eddit-controller-icon"
        iconName="alignment"
        @click="alignment"
      />
    </div>
    <button
      v-if="showContentsDelete"
      ref="contentsDelete"
      class="contents-delete-wrap"
    >
      <div
        ref="contentsDeleteInner"
        class="contents-delete-inner"
        @click="clickContentsDelete"
      >
        <icons iconName="delete" color="caution" size="small" />
        <texts
          v-if="deleteConfirm"
          class="contents-delete-inner-text"
          size="min"
          color="caution"
          :text="$t('common.deleteButton')"
        />
      </div>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import cytoscape from 'cytoscape'
import cytoscapeStyle from './cytoscapeStyle.js'
import edgehandles from 'cytoscape-edgehandles'
import panzoom from 'cytoscape-panzoom'
import 'cytoscape-panzoom/cytoscape.js-panzoom.css'
import dagre from 'cytoscape-dagre'
import popper from 'cytoscape-popper'
import * as NRCI from '@/lib/notRecommendedComingIn'
import texts from '@/components/atoms/text'
import textBox from '@/components/atoms/text-box'
import icons from '@/components/atoms/icon'
import iconButton from '@/components/molecules/recipe/icon-button'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import { badSeasonalityName } from '@/lib/recipe/trendflow.js'
import warningInfoBox from '@/components/organisms/recipe-detail/editor/warning-info-box'
import { initCustomblockParams } from '@/lib/customblock/initBlock'

// import klay from 'cytoscape-klay'
cytoscape.use(edgehandles)
cytoscape.use(panzoom)
cytoscape.use(dagre)
cytoscape.use(popper)
// cytoscape.use(klay)

const clone = (x) => JSON.parse(JSON.stringify(x))

const recipeVersion = 1

export default {
  name: 'RecipeGraph',
  components: {
    texts,
    textBox,
    icons,
    iconButton,
    transitionToggleContents,
    warningInfoBox
  },
  props: {
    graph: Object,
    edittable: Boolean,
    recipeLayers: Object,
    isSave: Boolean,
    // cytoscapeStyle.js で名前をラベルにするのに使用
    customblockList: Array
  },
  data() {
    return {
      lastNodeId: 0,
      selectedNodeIds: new Set(),
      internalGraph: null,
      connectionWarningFlag: false,
      infoFlag: false,
      warningTitle: null,
      warningMessage: null,
      infoTitle: null,
      infoMessage: null,
      infoNodeId: null,
      notConnect: false,
      warningList: [],
      showedWarningMessageList: [],
      nodeWarningList: [],
      showContentsDelete: null,
      showContentsDeleteType: null,
      deleteConfirm: false,
      popper: null
    }
  },
  mounted() {
    const elem = this.$el
    const cy = cytoscape({
      container: elem,
      selectionType: 'single',
      elements: [],
      wheelSensitivity: 0.2,
      style: cytoscapeStyle(this)
    })
    // cyの情報を用いるために、エッジのオプションを付与するためのメソッドをここで定義
    function makeEdgeOption(sourceNode, targetNode) {
      const option = {}
      if (sourceNode.name === 'specifyCol') {
        const edges = cy.edges()
        for (let i = 0; i < edges.length; i++) {
          const edge = edges[i].data()
          if (edge.source === sourceNode.id && edge?.options?.specificTarget) {
            option.specificTarget = false
            break
          }
          option.specificTarget = true
        }
      }
      return option
    }
    this.eh = cy.edgehandles({
      snap: true,
      handlePosition: (node) => 'middle bottom',
      edgeParams(sourceNode, targetNode) {
        return {
          data: {
            options: makeEdgeOption(sourceNode.data(), targetNode.data())
          }
        }
      }
    })
    this.eh.disable()
    this.eh.hide()

    cy.panzoom({})

    this.cy = cy
    this.cy
      .elements()
      .layout({
        directed: true,
        padding: 10,
        name: 'breadthfirst'
      })
      .run()
    cy.on('select', 'node.realNode', (e) => {
      const sender = e.target.id()
      this.selectedNodeIds.add(sender)
      this.$set(this, 'selectedNodeIds', this.selectedNodeIds)
      this.applyChanges()
      if (this.edittable) {
        this.removeDeleteStyle()
        this.showContentsDelete = sender
        this.showContentsDeleteType = 'node'
        this.$nextTick(() => {
          this.popper = e.target.popper({
            content: () => {
              const div = this.$refs.contentsDelete
              return div
            },
            renderedPosition: () => {
              const pos = e.target.renderedPosition()
              const rectItem =
                this.$refs.contentsDeleteInner.getBoundingClientRect()
              const left = pos.x + rectItem.width + rectItem.width / 2
              const top = pos.y - e.target.renderedOuterHeight() / 2 + 1
              return { x: left, y: top }
            }
          })
          e.target.on('position', () => {
            if (this.popper) {
              this.removeDeleteStyle()
              this.popper.update()
            }
          })
          e.target.on('select', () => {
            if (this.popper) {
              this.removeDeleteStyle()
              this.popper.update()
            }
          })
        })
      }
    })
    cy.on('unselect', 'node.realNode', (e) => {
      const sender = e.target.id()
      this.selectedNodeIds.delete(sender)
      this.$set(this, 'selectedNodeIds', this.selectedNodeIds)

      this.checkNodeWarning(sender)

      this.applyChanges()
    })
    cy.on('select', 'edge', (e) => {
      const cyedge = e.target
      if (this.edittable) {
        this.removeDeleteStyle()
        this.showContentsDelete = e.target.data().id
        this.showContentsDeleteType = 'edge'
        this.$nextTick(() => {
          this.popper = cyedge.popper({
            content: () => {
              const div = this.$refs.contentsDelete
              return div
            },
            renderedPosition: () => {
              const pos = cyedge.renderedMidpoint()
              const rectItem =
                this.$refs.contentsDeleteInner.getBoundingClientRect()
              const left = pos.x + rectItem.width + rectItem.width / 6
              const top = pos.y - cyedge.renderedOuterWidth() / 2
              return { x: left, y: top }
            }
          })
          cyedge.connectedNodes().on('position', () => {
            if (this.popper) {
              this.removeDeleteStyle()
              this.popper.update()
            }
          })
          e.target.on('position', () => {
            if (this.popper) {
              this.removeDeleteStyle()
              this.popper.update()
            }
          })
          e.target.on('select', () => {
            if (this.popper) {
              this.removeDeleteStyle()
              this.popper.update()
            }
          })
        })
      }
    })
    cy.on('taphold', 'node', (e) => {
      if (!this.edittable) return
      const cynode = e.target
      this.removeWarningListbyNode(cynode)
      this.removeInfoByNode(cynode)
      cynode.remove()
      this.showContentsDelete = null
      this.popper = null
      this.deleteConfirm = false
      this.applyChanges()
    })
    cy.on('taphold', 'edge', (e) => {
      if (!this.edittable) return
      const cyedge = e.target
      const sourceId = cyedge.data().source
      const targetId = cyedge.data().target
      this.removeWarningList(sourceId, targetId)
      cyedge.remove()
      this.showContentsDelete = null
      this.popper = null
      this.deleteConfirm = false
      this.applyChanges()
    })
    cy.on('ehcomplete', (e, sourceNode, targetNode, addedEles) => {
      if (
        this.checkConnectionWarning(
          sourceNode.data(),
          targetNode.data(),
          true
        ) === false
      ) {
        addedEles.remove()
      }
      const size = this.getOutputShape(sourceNode.data(), targetNode.data())
      targetNode.data('outputSize', size)
      this.applyChanges()
    })
    cy.on('ehshow', (e, sourceNode) => {
      if (sourceNode.removed()) {
        this.eh.hide()
      }
    })
    cy.on('pan zoom resize', () => {
      if (this.popper) {
        this.removeDeleteStyle()
        this.popper.update()
        const zoom = cy.zoom()
        this.$refs.contentsDeleteInner.style.transform = `scale(${zoom})`
      }
    })

    // HACK: Modify a private property for prolonging taphold duration.
    // Taphold events are binded to actions that remove blocks.
    // To prevent unintended removal, taphold duration is prolonged.
    // This code works with cytoscape.js v3.10.0
    // Ref: https://github.com/cytoscape/cytoscape.js/search?q=tapholdDuration&unscoped_q=tapholdDuration
    cy._private.renderer.tapholdDuration = 2000
  },
  computed: {
    ...mapGetters('settings', ['fallbackMode']),
    typename() {
      return this.fallbackMode ? 'Text' : 'x-matrixflow-layer'
    },
    warningMessageList() {
      const list = []
      this.warningList.forEach((o) => {
        list.push(o.message)
      })
      return list
    },
    checkDispWarningList() {
      return this.warningList.filter((x) => x.message === this.warningMessage)
        .length
    }
  },
  watch: {
    edittable(val, oldVal) {
      this.edittableApply(val)
    },
    '$i18n.locale': function (val) {
      this.cy.nodes().updateStyle()
    },
    connectionWarningFlag(newVal) {
      if (newVal) {
        this.timer = window.setTimeout(
          function () {
            this.connectionWarningFlag = false
          }.bind(this),
          10000
        )
      } else {
        window.clearTimeout(this.timer)
      }
    },
    infoFlag(newVal) {
      if (newVal) {
        this.infoTimer = window.setTimeout(
          function () {
            this.infoFlag = false
          }.bind(this),
          10000
        )
      } else {
        window.clearTimeout(this.infoTimer)
      }
    }
  },
  methods: {
    getLayerSpec(name) {
      if (name === 'customblock') {
        return {}
      }
      return clone(this.recipeLayers[name])
    },
    convertInstance(layer) {
      const instance = {
        type: layer.type,
        params: clone(layer.params),
        version: layer.version || 0
      }
      for (const [key, value] of Object.entries(instance.params)) {
        const params = instance.params[key]
        const numTypes = ['number', 'float', 'number_select']
        if (numTypes.includes(params?.type)) {
          params.defaultValue = params.value
        }
      }
      return instance
    },
    getLayerInstance(name) {
      const layer = this.recipeLayers[name]
      return this.convertInstance(layer)
    },
    isConnectWarning(notRecommendedFix, sourceNode, targetNode) {
      const sourceName = sourceNode.name
      const targetName = targetNode.name
      const info = notRecommendedFix
      if (
        (info.name === sourceName && info.target === 'comingIn') ||
        (info.name === targetName && info.target === 'goesOut')
      ) {
        if (!info.params) {
          return true
        } else {
          const params = info.params
          const sourceParams =
            info.target === 'comingIn' ? sourceNode.params : targetNode.params
          let warningFlag = true
          for (const k in params) {
            const flag = params[k].match
              ? sourceParams[k].value === params[k].value
              : sourceParams[k].value !== params[k].value
            warningFlag = warningFlag && flag
          }
          if (warningFlag) {
            return true
          }
        }
      } else if (info.name === '@func') {
        const s = this.cy.getElementById(sourceNode.id)
        const t = this.cy.getElementById(targetNode.id)
        if (NRCI[info.funcName](s, t)) {
          return true
        }
      }
      return false
    },
    checkConnectionWarning(sourceNode, targetNode, showModal) {
      let isConnectable = true
      const notRecommendedComingIn =
        this.getLayerSpec(targetNode.name).notRecommendedComingIn || []
      const notRecommendedComingInFix = notRecommendedComingIn.map((item) => {
        return {
          ...item,
          target: 'comingIn'
        }
      })
      const notRecommendedGoesOut =
        this.getLayerSpec(sourceNode.name).notRecommendedGoesOut || []
      const notRecommendedGoesOutFix = notRecommendedGoesOut.map((item) => {
        return {
          ...item,
          target: 'goesOut'
        }
      })
      const notRecommendedFix = [...notRecommendedComingInFix, ...notRecommendedGoesOutFix]
      for (let i = 0; i < notRecommendedFix.length; i++) {
        const info = notRecommendedFix[i]
        const isConnectWarning = this.isConnectWarning(
          info,
          sourceNode,
          targetNode
        )
        if (isConnectWarning) {
          const warningLevel = info.level
          if (warningLevel !== 1) {
            const message = info.reason
            if (
              showModal &&
              this.warningMessageList.indexOf(message) === -1 &&
              this.showedWarningMessageList.indexOf(message) === -1
            ) {
              this.warningTitle = warningLevel === 1 ? 'warning' : 'error'
              this.warningMessage = message
              this.connectionWarningFlag = isConnectWarning
            }
            isConnectable = false
            this.$emit('update:warnings', [
              {
                warning: this.warningMessage
              }
            ])
          } else {
            const message = info.reason
            if (
              showModal &&
              this.warningMessageList.indexOf(message) === -1 &&
              this.showedWarningMessageList.indexOf(message) === -1
            ) {
              this.warningTitle = warningLevel === 1 ? 'warning' : 'error'
              this.warningMessage = message
              this.connectionWarningFlag = isConnectWarning
            }
          }
          this.addWarningList(
            info.reason,
            warningLevel,
            sourceNode.id,
            targetNode.id
          )
        } else {
          if (this.warningMessageList.indexOf(info.reason) >= 0) {
            this.connectionWarningFlag = false
            this.warningList.splice(
              this.warningMessageList.indexOf(info.reason),
              1
            )
          }
        }
      }
      return isConnectable
    },
    checkNodeWarning(nodeId) {
      const node = this.cy.getElementById(nodeId)
      if (!node) {
        this.nodeWarningList = this.nodeWarningList.filter(
          (x) => x.node !== nodeId
        )
        this.$emit('update:warnings', this.nodeWarningList)
        return
      }
      const data = node.data()

      const key = data.key
      const params = data.params
      const warnings = []
      if (key === 'TrendFlow') {
        const nameList = params.seasonality.value.map((item) => {
          return item.seasonalityName.value
        })
        for (const item of params.seasonality.value) {
          const validationResult = badSeasonalityName(
            item.seasonalityName.value
          )
          if (validationResult) {
            warnings.push('TRENDFLOW_BAD_NAME')
          } else if (
            nameList.filter((name) => {
              return name === item.seasonalityName.value
            }).length > 1
          ) {
            warnings.push('TRENDFLOW_DUPLICATE_NAME')
          }
        }
      }
      node.data('warnings', warnings)
      this.nodeWarningList = this.nodeWarningList
        .filter((x) => x.node !== nodeId)
        .concat(
          warnings.map((x) => ({
            node: nodeId,
            warning: x
          }))
        )
      this.$emit('update:warnings', this.nodeWarningList)
    },
    addWarningList(warningMessage, warningLevel, sourceNodeId, targetNodeId) {
      if (
        this.warningMessageList.indexOf(warningMessage) === -1 &&
        warningLevel !== 5
      ) {
        const warningObj = {
          message: warningMessage,
          level: warningLevel,
          sourceNodeId: sourceNodeId,
          targetNodeId: targetNodeId
        }
        this.warningList.push(warningObj)
        if (this.showedWarningMessageList.indexOf(warningMessage) === -1) {
          this.showedWarningMessageList.push(warningMessage)
        }
      }
    },
    removeWarningList(sourceNodeId, targetNodeId) {
      let removedIndex = null
      this.warningList.forEach((obj, index) => {
        if (
          obj.sourceNodeId === sourceNodeId &&
          obj.targetNodeId === targetNodeId
        ) {
          removedIndex = index
        }
      })
      if (removedIndex !== null) {
        this.warningList.splice(removedIndex, 1)
      }
    },
    removeWarningListbyNode(cynode) {
      const removeId = cynode.data().id
      const incomeIds = this.getIncomeIds(cynode)
      const outgoIds = this.getOutgoIds(cynode)
      incomeIds.forEach((id) => {
        this.removeWarningList(id, removeId)
      })
      outgoIds.forEach((id) => {
        this.removeWarningList(removeId, id)
      })
      this.nodeWarningList = this.nodeWarningList.filter(
        (x) => x.node !== removeId
      )
      this.$emit('update:warnings', this.nodeWarningList)
    },
    addInfo(name, targetNodeId) {
      const target = this.getLayerSpec(name)
      const info = target?.info
      if (info) {
        this.infoTitle = info.name
        this.infoMessage = info.reason
        this.infoNodeId = targetNodeId
        this.infoFlag = true
      }
    },
    removeInfo(targetNodeId) {
      if (targetNodeId === this.infoNodeId) {
        this.infoTitle = null
        this.infoMessage = null
        this.infoNodeId = null
        this.infoFlag = false
      }
    },
    removeInfoByNode(cynode) {
      const removeId = cynode.data().id
      this.removeInfo(removeId)
    },
    getOutputShapeOfInputType(sourceNode) {
      let size = []
      if (sourceNode && sourceNode.type === 'input') {
        const layerName = sourceNode.name
        switch (layerName) {
          case 'inputData': {
            const h = sourceNode.params.dataHeight.value
            const w = sourceNode.params.dataWidth.value
            const c = sourceNode.params.channel.value
            size = [h, w, c]
            break
          }
          case 'inputLabels':
            size = [sourceNode.params.nClass.value]
            break
          default:
            size = sourceNode.outputSize
        }
        return size
      }
      return size
    },
    getOutputShape(sourceNode, targetNode) {
      let size = null
      if (sourceNode && targetNode && targetNode.type === 'deep') {
        const layerName = targetNode.name
        switch (layerName) {
          case 'conv2d':
          case 'conv2d_transpose':
          case 'max_pool':
            size = this.outputShapeCNNPooling(sourceNode, targetNode)
            break
          case 'fc':
            size = this.outputShapeFC(targetNode)
            break
          case 'flatten':
            size = this.outputShapeFlatten(sourceNode)
            break
          case 'reshape':
            size = this.outputShapeReshape(targetNode)
            break
        }
        if (!size) {
          return null
        }

        if (size.length === 3) {
          if (size[2] === 0) {
            size = [size[0], size[1]]
          }
          if (size[1] === 0) {
            size = [size[0]]
          }
        } else if (size.length === 2) {
          if (size[1] !== 0) {
            size = [size[0], size[1]]
          } else {
            size = [size[0]]
          }
        } else {
          size = [size[0]]
        }
        return size
      }
    },
    calcOutPutSize(inputSize, filterSize, stride, padding) {
      let outputSize = 0
      if (padding === 'same') {
        outputSize = Math.ceil(inputSize / stride)
      } else {
        outputSize = Math.ceil((inputSize - filterSize + 1) / stride)
      }
      return outputSize
    },
    getInputSize(sourceNode) {
      const sourceLayerType = sourceNode.type
      let inputHeight, inputWidth, inputChannelSize
      if (sourceLayerType === 'input') {
        const incomeParams = sourceNode.params
        inputHeight = incomeParams.dataHeight.value
        inputWidth = incomeParams.dataWidth.value
        inputChannelSize = incomeParams.channel.value
      } else {
        const outputSize = sourceNode.outputSize
        if (!outputSize) {
          return null
        }
        inputHeight = outputSize[0]
        inputWidth = outputSize[1] ? outputSize[1] : 0
        inputChannelSize = outputSize[2] ? outputSize[2] : 0
      }
      return [inputHeight, inputWidth, inputChannelSize]
    },
    outputShapeFC(targetNode) {
      const params = targetNode.params
      return [params.outSize.value]
    },
    outputShapeFlatten(sourceNode) {
      let inputHeight, inputWidth, inputChannelSize
      const size = this.getInputSize(sourceNode)
      if (size) {
        [inputHeight, inputWidth, inputChannelSize] = size
      } else {
        return null
      }

      inputWidth = inputWidth !== 0 ? inputWidth : 1
      inputChannelSize = inputChannelSize !== 0 ? inputChannelSize : 1
      return [inputHeight * inputWidth * inputChannelSize]
    },
    outputShapeReshape(targetNode) {
      const params = targetNode.params
      const height = params.height.value
      const width = params.width.value
      const depth = params.depth.value
      return [height, width, depth]
    },
    outputShapeCNNPooling(sourceNode, targetNode) {
      const params = targetNode.params
      const padding = params.padding ? params.padding.value : 'same'

      let filterSizeHeight = 0
      let filterSizeWidth = 0
      let outputHeight = 0
      let outputWidth = 0
      let strideHeight = 1
      let strideWidth = 1
      let outChannelSize = 0

      let inputHeight, inputWidth, inputChannelSize
      const size = this.getInputSize(sourceNode)
      if (size) {
        [inputHeight, inputWidth, inputChannelSize] = size
      } else {
        return null
      }

      if (params.outSize) {
        outChannelSize = params.outSize.value
      } else {
        outChannelSize = inputChannelSize
      }

      if (params.filterSizeHeight) {
        filterSizeHeight = params.filterSizeHeight.value
      } else if (params.poolSizeHeight) {
        filterSizeHeight = params.poolSizeHeight.value
      }
      if (params.filterSizeWidth) {
        filterSizeWidth = params.filterSizeWidth.value
      } else if (params.poolSizeWidth) {
        filterSizeWidth = params.poolSizeWidth.value
      }

      if (params.strideHeight) {
        strideHeight = params.strideHeight.value
      } else if (params.stridesHeight) {
        strideHeight = params.stridesHeight.value
      }

      if (params.strideWidth) {
        strideWidth = params.strideWidth.value
      } else if (params.stridesWidth) {
        strideWidth = params.stridesWidth.value
      }
      outputHeight = this.calcOutPutSize(
        inputHeight,
        filterSizeHeight,
        strideHeight,
        padding
      )
      outputWidth = this.calcOutPutSize(
        inputWidth,
        filterSizeWidth,
        strideWidth,
        padding
      )
      return [outputHeight, outputWidth, outChannelSize]
    },
    applyChanges() {
      const nodes = this.getSelectedNodes()
      // this.cy.userZoomingEnabled(nodes.length > 0)
      this.$emit('select', { nodes })
    },
    edittableApply() {
      this.cy.userZoomingEnabled(this.edittable)
      if (this.edittable) {
        this.enableGraph()
      } else {
        this.freezeGraph()
      }
    },
    getIncomeIds(cynode) {
      const idList = []
      const incomers = cynode.incomers('edge')
      incomers.forEach((edge) => {
        if (!edge.source().hasClass('realNode')) return
        idList.push(edge.source().data().id)
      })
      return idList
    },
    getOutgoIds(cynode) {
      const idList = []
      const outgoers = cynode.outgoers('edge')
      outgoers.forEach((edge) => {
        if (!edge.source().hasClass('realNode')) return
        idList.push(edge.source().data().id)
      })
      return idList
    },
    getNodeById(id) {
      const node = this.cy.getElementById(id)
      if (!node) return null
      if (!node.hasClass('realNode')) return null
      //      console.log(node)
      const neighborNodes = node.neighborhood('node')
      const incomers = node.incomers('edge')
      const outgoers = node.outgoers('edge')
      const neighbors = {}
      const incomeEdges = []
      const outgoEdges = []
      neighborNodes.forEach((neighborNode) => {
        neighbors[neighborNode.id()] = neighborNode.data()
      })
      incomers.forEach((edge) => {
        if (!edge.source().hasClass('realNode')) return
        incomeEdges.push({
          id: edge.id(),
          from: edge.source().data(),
          to: node.data()
        })
      })
      outgoers.forEach((edge) => {
        if (!edge.target().hasClass('realNode')) return
        outgoEdges.push({
          id: edge.id(),
          from: node.data(),
          to: edge.target().data()
        })
      })
      return {
        node: node.data(),
        neighbors,
        incomeEdges,
        outgoEdges
      }
    },
    getSelectedNodes() {
      const res = []
      this.selectedNodeIds.forEach((id) => {
        const node = this.getNodeById(id)
        if (!node) return
        res.push(node)
      })
      return res
    },
    relativeZoom(v) {
      this.cy
        .animation({
          zoom: {
            value: this.cy.zoom() * v,
            renderedPosition: {
              x: this.cy.width() / 2,
              y: this.cy.height() / 2
            }
          }
        })
        .play()
    },
    freezeGraph() {
      if (!this.cy) return
      this.eh.disable()
      this.cy.autolock(true)
      this.cy.userZoomingEnabled(false)
    },
    enableGraph() {
      if (!this.cy) return
      this.eh.enable()
      this.cy.autolock(false)
      this.cy.userZoomingEnabled(true)
    },
    buildGraph() {
      this.cy.elements().forEach((x) => x.remove())
      const layers = this.graph.layers
      const edges = this.graph.edges

      let maxId = -1
      layers.forEach((v) => {
        const id = parseInt(v.id)
        if (v.name === 'customblock') {
          this.addNode(v.id, v.name, v.graph.position, {
            data: this.convertInstance({
              type: v.type,
              params: v.params
            }),
            customblockId: v.customblock_id,
            customblockVersion: v.customblock_version
          })
        } else {
          this.addNode(v.id, v.name, v.graph.position, {
            data: this.convertInstance({
              params: v.params,
              version: v.version
            })
          })
        }
        if (id > maxId) {
          maxId = id
        }
      })
      this.lastNodeId = maxId
      this.lastEdgeId = 0
      edges.forEach((e, i) => {
        const sourceNode = this.cy.getElementById(e.sourceId)
        const targetNode = this.cy.getElementById(e.targetId)
        const size = this.getOutputShape(sourceNode.data(), targetNode.data())
        targetNode.data('outputSize', size)
        this.addEdge(e.sourceId, e.targetId, e?.options)
      })
      this.setPanAndZoom(this.graph.info.graph)
      // */
      this.edittableApply()
    },
    addNode(
      id,
      name,
      position,
      {
        data,
        type,
        displayPosition,
        customblockId = undefined,
        customblockVersion = undefined
      }
    ) {
      const _this = this
      let outputSize = null
      if (name === 'inputData') {
        const h = data.params.dataHeight.value
        const w = data.params.dataWidth.value
        const c = data.params.channel.value
        outputSize = [h, w, c]
      }
      const nodeData = {
        id,
        name,
        get options() {
          if (name === 'customblock') {
            return {}
          }
          return _this.recipeLayers[this.name].params
        },
        get type() {
          if (name === 'customblock') {
            return data.type
          }
          return _this.recipeLayers[this.name].type
        },
        key: name,
        version: data.version || 0,
        params: data.params,
        warnings: [],
        outputSize: outputSize,
        nodeColor: this.themeColor,
        nodeSelectedColor: '#049185',
        nodeShape: 'round-rectangle',
        customblockId,
        customblockVersion
      }
      const node = {
        data: nodeData,
        classes: 'realNode',
        selectable: true,
        grabbable: true
      }
      if (displayPosition) {
        node.renderedPosition = position
      } else {
        node.position = position
      }
      this.cy.add(node)
      this.applyChanges()
      return node
    },
    addEdge(fromId, toId, options) {
      const edge = {
        data: {
          id: 'edge' + this.lastEdgeId++,
          source: fromId,
          target: toId,
          options: options ?? {}
        }
      }
      this.cy.add(edge)
      this.applyChanges()
    },
    removeNode(id) {
      const cynode = this.cy.getElementById(id)
      this.removeWarningListbyNode(cynode)
      this.removeInfoByNode(cynode)
      cynode.remove()
      if (this.showContentsDeleteType === 'node') {
        this.deleteConfirm = false
        this.showContentsDeleteType = null
        this.showContentsDelete = null
        this.popper = null
      }
      this.applyChanges()
    },
    removeEdge(edgeId) {
      const cyedge = this.cy.getElementById(edgeId)
      const sourceId = cyedge.data().source
      const targetId = cyedge.data().target
      this.removeWarningList(sourceId, targetId)
      cyedge.remove()
      if (this.showContentsDeleteType === 'edge') {
        this.deleteConfirm = false
        this.showContentsDeleteType = null
        this.showContentsDelete = null
        this.popper = null
      }
      this.applyChanges()
    },
    itemDragOver(e) {
      const types = [...e.dataTransfer.types] // DomStringList to Array for IE or Edge
      const d = types.includes(this.typename)
      if (d) {
        e.preventDefault()
        e.dataTransfer.dropEffect = 'copy'
        return true
      }
    },
    async itemDropped(e) {
      if (e.stopPropagation) {
        e.stopPropagation()
      }
      const blockId = e.dataTransfer.getData(this.typename)
      if (blockId === 'customblock') {
        const customblockId = e.dataTransfer.getData('x-matrixflow-layer-cb-id')
        const customblockVersion = e.dataTransfer.getData(
          'x-matrixflow-layer-cb-version'
        )
        this.addNodeByDropCustomblock(e, customblockId, customblockVersion)
      } else {
        this.addNodeByDrop(e, blockId)
      }
    },
    async addNodeByDropCustomblock(e, customblockId, customblockVersion) {
      const newNodeId = (++this.lastNodeId).toString()
      const pageX = e.pageX
      const pageY = e.pageY

      const rect = this.$el.getBoundingClientRect()
      const vueThis = this
      function callback(result) {
        const params = result.params

        // カスタムブロック用に、パラメータを展開する
        Object.entries(result.params).forEach((entry) => {
          const [key, item] = entry
          if (item.params != null) {
            for (const paramKey in item.params) {
              item[paramKey] = item.params[paramKey]
            }
            item.params = undefined
          }
          params[key] = item
        })

        const data = {
          params: params,
          type: result.block_type
        }

        const mouseX = pageX - (rect.left + window.pageXOffset)
        const mouseY = pageY - (rect.top + window.pageYOffset)
        const position = { x: mouseX, y: mouseY }
        vueThis.addNode(newNodeId, 'customblock', position, {
          data,
          displayPosition: true,
          customblockId,
          customblockVersion
        })
      }

      this.$emit('drop-customblock', {
        customBlockId: customblockId,
        version: customblockVersion,
        callback
      })
    },
    addNodeByDrop(e, blockId) {
      const name = blockId
      const newNodeId = (++this.lastNodeId).toString()
      const data = this.getLayerInstance(name)

      const rect = this.$el.getBoundingClientRect()
      const mouseX = e.pageX - (rect.left + window.pageXOffset)
      const mouseY = e.pageY - (rect.top + window.pageYOffset)
      const position = { x: mouseX, y: mouseY }
      this.addNode(newNodeId, name, position, { data, displayPosition: true })
      if (name === 'optFlow') {
        const autoName = 'AutoFlow'
        const newAutoId = (++this.lastNodeId).toString()
        const autoData = this.getLayerInstance(autoName)
        autoData.params.type.value = 'regression'
        const autoMouseX = e.pageX - (rect.left + window.pageXOffset)
        const autoMouseY = e.pageY - window.pageYOffset - 24
        const autoPosition = { x: autoMouseX, y: autoMouseY }

        this.addNode(newAutoId, autoName, autoPosition, {
          data: autoData,
          displayPosition: true
        })
        this.addEdge(newNodeId, newAutoId)
      }
      this.addInfo(name, newNodeId)
    },
    resetZoom() {
      this.cy.zoom(1)
    },
    resetPan() {
      this.cy.pan({ x: 0, y: 0 })
    },
    alignment() {
      const layoutConfig = {
        name: 'dagre',
        padding: 100,
        animate: true,
        animationDuration: 500,
        animationEasing: 'ease'
      }
      this.cy.layout(layoutConfig).run()
    },
    setPanAndZoom({ pan = { x: 0, y: 0 }, zoom = 1 }) {
      this.cy.pan(pan)
      this.cy.zoom(zoom)
    },
    updateCustomblockVersion({ blockId, newParams, newVersion }) {
      const node = this.cy.getElementById(blockId)
      node.data('params', newParams)
      node.data('customblockVersion', newVersion)
    },
    updateData() {
      const obj = {
        version: recipeVersion,
        edges: [],
        layers: [],
        info: {
          ...this.graph.info,
          graph: {
            pan: this.cy.pan(),
            zoom: this.cy.zoom()
          }
        }
      }
      this.cy.nodes('.realNode').forEach((x) => {
        const dat = x.data()
        const nodeObj = {
          id: dat.id,
          name: dat.name,
          type: dat.type,
          params: dat.params,
          version: dat.version,
          customblock_id: dat.customblockId,
          customblock_version: dat.customblockVersion,
          graph: {
            position: x.position()
          }
        }
        obj.layers.push(nodeObj)
      })
      this.cy.edges().forEach((x) => {
        const dat = x.data()
        const edgeObj = {
          sourceId: dat.source,
          targetId: dat.target,
          options: dat?.options ?? {}
        }
        obj.edges.push(edgeObj)
      })
      this.$emit('update:graph', obj)
    },
    clickContentsDelete() {
      if (!this.deleteConfirm) {
        this.cy.$id(this.showContentsDelete).select()
        this.$nextTick(() => {
          this.deleteConfirm = true
          this.cy.$id(this.showContentsDelete).addClass('select-delete')
          window.addEventListener('click', this.deleteOuterClick)
        })
        return
      }
      if (this.showContentsDeleteType === 'node') {
        this.removeNode(this.showContentsDelete)
      } else if (this.showContentsDeleteType === 'edge') {
        this.removeEdge(this.showContentsDelete)
      }
      window.removeEventListener('click', this.deleteOuterClick)
    },
    removeDeleteStyle() {
      this.deleteConfirm = false
      this.cy.$id(this.showContentsDelete).removeClass('select-delete')
    },
    deleteOuterClick(e) {
      if (
        this.$refs?.contentsDelete &&
        !this.$refs.contentsDelete.contains(e.target)
      ) {
        this.removeDeleteStyle()
        window.removeEventListener('click', this.deleteOuterClick)
      }
    }
  },
  destroyed() {
    this.cy.removeAllListeners()
  }
}
</script>
<style lang="scss">
.cy {
  position: absolute;
  width: 100%;
  height: 100%;
}

.cy-panzoom {
  z-index: 1;
}

.warning {
  &-wrap {
    position: absolute;
    right: $space-small;
    display: flex;
    flex-direction: column;
    max-width: adjustVW(560);
    z-index: 2;
  }
  &-item {
    margin-bottom: $space-medium;
    &.wrap {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &-title {
    margin: 0 0 $space-base;
  }
  &-text {
    .warning-wrap & {
      white-space: pre-line;
    }
  }
  &-button {
    position: absolute;
    top: $space-sub;
    right: $space-sub;
    transition: opacity $transition-base;
    &:hover {
      opacity: 0.5;
    }
  }
}
.connection-warning-btn {
  float: right;
  margin-right: 5px;
}

$panzoom-height: 197px + 10px;

.eddit-controller {
  position: absolute;
  top: calc(#{$panzoom-height} + #{$space-medium} + #{$space-small});
  left: adjustVW(40 / 2);
  display: flex;
  flex-direction: column;
  z-index: 1;
  &-icon {
    margin-bottom: $space-small;
    &:last-of-type {
      margin: 0;
    }
  }
  &-save {
    left: adjustVW(4);
  }
}

.contents-delete {
  &-wrap {
    cursor: pointer;
    z-index: 10;
  }
  &-inner {
    position: relative;
    display: flex;
    align-items: center;
    grid-column-gap: adjustVW(6);
    padding: $space-base $space-sub;
    border: 1px solid $text-caution;
    background-color: #fceeed;
    border-radius: 9in;
    &-text {
      animation: inTextAnimation $transition-base;
    }
  }
}

@keyframes inTextAnimation {
  0% {
    opacity: 0;
    transform: translateX(-16px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.toggle-item-enter-active,
.toggle-item-leave-active {
  transition: transform $transition-base, opacity $transition-base;
}
.toggle-item-enter,
.toggle-item-leave-to {
  opacity: 0;
  transform: translateY(
    -$space-small
  ); // スクロールバーが下方向へのトランジッションだと見えてしまうため、上方向に変更
  will-change: opacity, transform;
}
</style>
