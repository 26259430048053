<template>
  <div :class="{ 'input-password': isMin }">
    <input-form-box
      name="new-password"
      v-bind="password"
      :explanationText="this.$i18n.t('signup.description.password')"
      :exampleText="this.$i18n.t('signup.example.password')"
      :errorText="this.$i18n.t('signup.error.password')"
      :passwordTooLong="this.$i18n.t('signup.error.passwordTooLong')"
      :title="this.$i18n.t('signup.form.password')"
      @change="_changePassword"
    />

    <input-form-box
      name="new-password"
      v-bind="passwordConfirm"
      :explanationText="this.$i18n.t('signup.description.password')"
      :errorText="this.$i18n.t('signup.error.passwordConfirm')"
      :passwordTooLong="this.$i18n.t('signup.error.passwordTooLong')"
      :title="this.$i18n.t('signup.form.passwordConfirm')"
      @change="_changePasswordConfirm"
    />
  </div>
</template>

<script>
import inputFormBox from '@/components/molecules/signup/input-form-box.vue'
import { validatePassword } from '@/lib/auth'

export default {
  components: {
    inputFormBox
  },
  data() {
    return {
      password: {
        isError: false,
        isOk: false,
        isPass: true,
        value: ''
      },
      passwordConfirm: {
        isError: false,
        isOk: false,
        isPass: true,
        value: ''
      },
      confirmOk: false
    }
  },
  props: {
    backPassword: String,
    isMin: Boolean
  },
  computed: {
    _validatePasswordConfirm: function () {
      return {
        password: this.password.value,
        passwordConfirm: this.passwordConfirm.value
      }
    }
  },
  watch: {
    _validatePasswordConfirm: function ({ password, passwordConfirm }) {
      this.password.isOk = this._validatePassword(password)
      this.password.isError = !this.password.isOk ? password !== '' : false

      this.passwordConfirm.isOk =
        password === passwordConfirm && password.length >= 8
          ? passwordConfirm !== ''
          : false
      this.passwordConfirm.isError = !this.passwordConfirm.isOk
        ? passwordConfirm !== ''
        : false

      this.$emit('change', {
        value: this.passwordConfirm.value,
        isOk: this.passwordConfirm.isOk && this.password.isOk
      })
    }
  },
  methods: {
    _changePassword(val) {
      this.password.value = val
    },
    _changePasswordConfirm(val) {
      this.passwordConfirm.value = val
    },
    _validatePassword: validatePassword
  },
  mounted() {
    if (this.backPassword) {
      this.password.value = this.backPassword
      this.$nextTick(() => {
        this.passwordConfirm.value = this.backPassword
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.input-password {
  display: flex;
  grid-column-gap: adjustVW(28);
}
</style>
