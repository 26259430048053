import { render, staticRenderFns } from "./dataset-size-exceed-caution.vue?vue&type=template&id=e40c595c&scoped=true&"
import script from "./dataset-size-exceed-caution.vue?vue&type=script&lang=js&"
export * from "./dataset-size-exceed-caution.vue?vue&type=script&lang=js&"
import style0 from "./dataset-size-exceed-caution.vue?vue&type=style&index=0&id=e40c595c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e40c595c",
  null
  
)

export default component.exports