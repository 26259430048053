<template>
  <div class="c-wrap">
    <header-tabs v-bind="headerTabs" />
    <sidebar-project-detail v-bind="sidebar" />

    <div class="c-body-wrap">
      <div class="c-body-inner inference-menu">
        <page-title class="inference-page-top" />
        <transition-page>
          <transition-toggle-contents>
            <loading v-if="loadingPage" />
            <inference-menu
              v-else
              v-bind="body"
              :accountInfo="accountInfo"
              :project="project"
              :trainedAiList="trainedAiList"
              :datasetList="datasetList"
              :preprocessingList="preprocessingList"
              :trainedAiDetail="trainedAiDetail"
              :trainedAiDetailFullInfo="trainedAiDetailFullInfo"
              :loadColumnInfo="loadColumnInfo"
              :preprocessingColumns="preprocessingColumns"
              :requiredColumns="requiredColumns"
              :selectedItems="selectedItems"
              :runInference="runInference"
              :disableStartInferece="disableStartInferece"
              :disableOptimization="disableOptimization"
              :optimizationErrorMessage="optimizationErrorMessage"
              :optimizationErrorManual="optimizationErrorManual"
              @getSelectedItems="getSelectedItems"
              @start-inference="startInference"
              @start-optimization="$emit('start-optimization')"
              @input-clustering-setting="
                $emit('input-clustering-setting', $event)
              "
              @input-multi-inference-column="
                $emit('input-multi-inference-column', $event)
              "
              @check-expressions="$emit('check-expressions', $event)"
              @get-optimization-conditions-list="
                $emit('get-optimization-conditions-list')
              "
              @show-optimization-tutorial="$emit('show-optimization-tutorial')"
              @reset-optimization-settings="
                $emit('reset-optimization-settings')
              "
              @change-trained-ai-detail="
                $emit('change-trained-ai-detail', $event)
              "
              @show-inference-enveiroment-setting="
                $emit('show-inference-enveiroment-setting', $event)
              "
            />
          </transition-toggle-contents>
        </transition-page>
        <transition-toggle-fade>
          <div
            v-if="optimizationValues.showOptimizationResult && optimizationFlag"
            class="wait-inference"
          >
            <optimization-status
              :optimizationValues="optimizationValues"
              :optimizationFlag="optimizationFlag"
              @optimization-stop="$emit('optimization-stop')"
            />
          </div>
          <div v-else-if="showInferenceStatus" class="wait-inference-status">
            <inference-status :inferenceStatus="inferenceStatus" />
          </div>
        </transition-toggle-fade>
      </div>
      <div
        v-if="
          showInferenceResult ||
            checkInferenceResultsSet ||
            checkOptimizationResult
        "
        ref="inferenceComp"
        class="c-body-inner"
      >
        <inference-result
          v-bind="body"
          :accountInfo="accountInfo"
          :inputColumns="requiredColumns"
          :selectedTrainedAi="selectedTrainedAi"
          :selectedItems="selectedItems"
          :downloading="downloading"
          :downloadComplete="downloadComplete"
          :inferenceWarnings="body.inferenceWarnings"
          :predictedColumns="predictedColumns"
          :predictedTable="predictedTable"
          :total="total"
          :finishedTotal="finishedTotal"
          :tableDatas="tableDatas"
          :forecast="forecast"
          :loadColumnResult="loadColumnResult"
          :loadTimeseriesTrainedAiData="loadTimeseriesTrainedAiData"
          :timeseriesSettings="timeseriesSettings"
          :inferencedColumns="inferencedColumns"
          :finishColumnsFix="finishColumnsFix"
          :finishColumnsAllProcessFix="finishColumnsAllProcessFix"
          :finishedAllColumns="finishedAllColumns"
          :inferencedColumnsBase="inferencedColumnsBase"
          :optimizationValues="optimizationValues"
          @download-inference-result="
            $emit('download-inference-result', $event)
          "
          @download-multi-result="$emit('download-multi-result', $event)"
          @change-column="$emit('change-column', $event)"
          @change-page="$emit('change-page', $event)"
          @change-optimization-page="$emit('change-optimization-page', $event)"
          @change-optimization-filter-value="
            $emit('change-optimization-filter-value', $event)
          "
          @close-optimization-result-tutorial="
            $emit('close-optimization-result-tutorial')
          "
          @input-edit-optimization-form="
            $emit('input-edit-optimization-form', $event)
          "
          @save-edit-optimization-form="
            $emit('save-edit-optimization-form', $event)
          "
          @download-optimization-result="
            $emit('download-optimization-result', $event)
          "
          @load-text-mining="$emit('load-text-mining', $event)"
          @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
          @input-clustering-setting="$emit('input-clustering-setting', $event)"
          @download-clustering-result="
            $emit('download-clustering-result', $event)
          "
          @change-clustering-distribution-column="
            $emit('change-clustering-distribution-column', $event)
          "
          @toggle-clustering-show-dimension="
            $emit('toggle-clustering-show-dimension', $event)
          "
        />
      </div>
    </div>
    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
    <div class="inference-popup">
      <inference-popup
        v-bind="popup"
        :inferenceEnvironment="selectedItems.inferenceEnvironment"
        @close-modal="$emit('close-modal', $event)"
        @optimization-stop-confirm="$emit('optimization-stop-confirm')"
        @reset-inference-settings="$emit('reset-inference-settings')"
        @getSelectedItems="$emit('getSelectedItems', $event)"
      />
    </div>
    <optimization-tutorial
      :showTutorial="popup.showTutorial.optimization"
      @close-tutorial="$emit('close-optimization-tutorial')"
    />
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import HoverMenu from '@/components/organisms/hover-menu.vue'
import HeaderTabs from '@/components/organisms/header-tabs.vue'
import InferenceMenu from '@/components/organisms/inference/inference-menu.vue'
import InferenceResult from '@/components/organisms/inference/inference-result.vue'
import Loading from '@/components/atoms/loading.vue'
import PageTitle from '@/components/organisms/inference/page-title.vue'
import SidebarProjectDetail from '@/components/organisms/sidebar-project-detail.vue'
import TransitionPage from '@/components/molecules/transition-page.vue'
import TransitionToggleContents from '../molecules/transition-toggle-contents.vue'
import TransitionToggleFade from '../molecules/transition-toggle-fade.vue'
import optimizationStatus from '@/components/organisms/inference/optimization-status.vue'
import inferenceStatus from '@/components/organisms/inference/popup/inference-status.vue'
import inferencePopup from '@/components/organisms/inference/inference-popup.vue'
import optimizationTutorial from '@/components/organisms/optimization/optimization-tutorial'

export default {
  components: {
    HoverMenu,
    HeaderTabs,
    InferenceMenu,
    InferenceResult,
    Loading,
    PageTitle,
    SidebarProjectDetail,
    TransitionPage,
    TransitionToggleContents,
    TransitionToggleFade,
    optimizationStatus,
    inferenceStatus,
    inferencePopup,
    optimizationTutorial
  },
  props: {
    accountInfo: Object,
    body: Object,
    deleteTest: Object,
    headerTabs: Object,
    loadingPage: {
      type: Boolean,
      default: false
    },
    popup: Object,
    disableStartInferece: Boolean,
    showInferenceResult: {
      type: Boolean,
      default: false
    },
    runInference: Boolean,
    inferenceTakingTooLong: Boolean,
    sidebar: Object,
    project: Object,
    trainedAiList: Array,
    datasetList: Array,
    preprocessingList: Array,

    preprocessingColumns: Object,
    requiredColumns: Array,
    selectedTrainedAi: Object,
    trainedAiDetail: Object,
    trainedAiDetailFullInfo: Object,
    loadColumnInfo: Boolean,
    downloading: Boolean,
    downloadLoading: Boolean,
    downloadComplete: Boolean,

    predictedColumns: Array,
    predictedTable: Array,
    total: Number,
    finishedTotal: Number,
    tableDatas: Object,

    selectedItems: Object,
    finishColumnsFix: Array,
    finishColumnsAllProcessFix: Array,
    finishedAllColumns: Boolean,
    forecast: Array,
    loadColumnResult: Boolean,
    inferencedColumns: Array,
    inferencedColumnsBase: Array,
    inferenceStatus: Object,
    loadTimeseriesTrainedAiData: Boolean,
    timeseriesSettings: Object,

    takingTooLongTextItem: String,
    inferenceType: String,
    disableOptimization: Array,
    optimizationValues: Object,
    checkOptimizationResult: Boolean,
    optimizationFlag: Boolean,
    optimizationErrorMessage: String,
    optimizationErrorManual: String
  },
  computed: {
    checkInferenceResultsSet() {
      let check = true
      if (
        this.inferenceType === 'time' ||
        this.inferenceType === 'time_transformerV2'
      ) {
        check = this.forecast && this.forecast.length > 0
      } else {
        check = this.tableDatas.graphProb.length > 0
      }
      return (
        this.inferencedColumnsBase &&
        this.inferencedColumnsBase.length > 1 &&
        this.finishColumnsFix &&
        this.finishColumnsFix.length > 0 &&
        check
      )
    },
    checkLoadingInference() {
      let check = true
      if (!this.runInference) return false
      if (
        this.inferenceType === 'time' ||
        this.inferenceType === 'time_transformerV2'
      ) {
        check = this.forecast && this.forecast.length === 0
      } else {
        check = this.tableDatas.graphProb.length === 0
      }
      return check
    },
    showInferenceStatus() {
      const multiColumn =
        this.inferenceStatus?.columnInfo?.allColumns &&
        this.inferenceStatus?.columnInfo?.allColumns.length > 1
      const notFinishWithMulti =
        this.inferenceStatus &&
        Object.keys(this.inferenceStatus).length > 0 &&
        multiColumn &&
        this.inferenceStatus?.columnInfo?.allColumns.length !==
          this.inferenceStatus?.columnInfo?.finishedColumns.length
      const notFinishWithSingle =
        this.inferenceStatus &&
        Object.keys(this.inferenceStatus).length > 0 &&
        !multiColumn &&
        this.inferenceStatus.finishedPostProcessing != null &&
        !this.inferenceStatus.finishedPostProcessing
      return this.runInference || notFinishWithMulti || notFinishWithSingle
    }
  },
  methods: {
    getSelectedItems({
      trainedAiId,
      preprocess,
      dataset,
      inferenceType,
      inferenceSettings,
      optimizationSettings,
      resolve
    }) {
      this.$emit('getSelectedItems', {
        trainedAiId,
        preprocess,
        dataset,
        inferenceType,
        inferenceSettings,
        optimizationSettings,
        resolve
      })
    },
    startInference: function () {
      this.$emit('start-inference')
    }
  },
  watch: {
    showInferenceResult(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          window.scrollTo({
            top: this.$refs.inferenceComp.offsetTop,
            behavior: 'smooth'
          })
        })
      }
    },
    checkInferenceResultsSet(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          window.scrollTo({
            top: this.$refs.inferenceComp.offsetTop,
            behavior: 'smooth'
          })
        })
      }
    },
    checkOptimizationResult(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          window.scrollTo({
            top: this.$refs.inferenceComp.offsetTop,
            behavior: 'smooth'
          })
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  display: flex;
  flex-direction: column;
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
}

.c-body-inner {
  overflow: hidden;
  height: 100vh;
}

.inference-menu {
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  height: adjustVH($baseHeight - $headerTabHeight);
}

.inference-page-top {
  padding: 0 $space-medium $space-base;
}

.wait-inference {
  position: fixed;
  top: 0;
  left: adjustVW(240);
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - #{adjustVW(240)});
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100;
}
.wait-inference-status {
  position: absolute;
  top: 0;
  left: adjustVW(240);
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - #{adjustVW(240)});
  height: 100%;
  background: linear-gradient(
    rgba(0, 0, 0, 0.2) 85%,
    rgba(0, 0, 0, 0.1) 95%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 100;
}
</style>
