import * as d3 from 'd3'
// time format ref: https://github.com/d3/d3-time-format
const locales = {
  ja: d3.timeFormatLocale({
    dateTime: '%x（%a）%X',
    date: '%Y/%m/%d',
    time: '%H:%M',
    periods: ['午前', '午後'],
    days: [
      '日曜日',
      '月曜日',
      '火曜日',
      '水曜日',
      '木曜日',
      '金曜日',
      '土曜日'
    ],
    shortDays: [
'日',
'月',
'火',
'水',
'木',
'金',
'土'
],
    months: [
      '1月',
      '2月',
      '3月',
      '4月',
      '5月',
      '6月',
      '7月',
      '8月',
      '9月',
      '10月',
      '11月',
      '12月'
    ],
    shortMonths: [
      ' 1',
      ' 2',
      ' 3',
      ' 4',
      ' 5',
      ' 6',
      ' 7',
      ' 8',
      ' 9',
      '10',
      '11',
      '12'
    ]
  })
}

export const timeTooltipFormatters = {
  ja: function (date, distance) {
    const locale = locales.ja
    // 最小最大の日付の差分から表示する日時を決定
    // ex:) 差分が1年以上であれば必ず%-Yを表示
    switch (distance) {
      case 'year':
        return (
          d3.timeSecond(date) < date
            ? locale.format('%-Y/%b/%d（%a） %H:%M:%S.%L')
            : d3.timeMinute(date) < date
            ? locale.format('%-Y/%b/%d（%a） %H:%M:%S')
            : d3.timeHour(date) < date
            ? locale.format('%-Y/%b/%d（%a） %H:%M')
            : d3.timeDay(date) < date
            ? locale.format('%-Y/%b/%d（%a） %H:00')
            : d3.timeMonth(date) < date
            ? locale.format('%-Y/%b/%d（%a）')
            : d3.timeYear(date) < date
            ? locale.format('%-Y/%B')
            : locale.format('%-Y')
        )(date)
      case 'day':
        return (
          d3.timeSecond(date) < date
            ? locale.format('%b/%d（%a） %H:%M:%S.%L')
            : d3.timeMinute(date) < date
            ? locale.format('%b/%d（%a） %H:%M:%S')
            : d3.timeHour(date) < date
            ? locale.format('%b/%d（%a） %H:%M')
            : d3.timeDay(date) < date
            ? locale.format('%b/%d（%a） %H:00')
            : d3.timeMonth(date) < date
            ? locale.format('%b/%d（%a）')
            : locale.format('%B')
        )(date)
      case 'hour':
        return (
          d3.timeSecond(date) < date
            ? locale.format('%H:%M:%S.%L')
            : d3.timeMinute(date) < date
            ? locale.format('%H:%M:%S')
            : locale.format('%H:%M')
        )(date)
      case 'minute':
        return (
          d3.timeSecond(date) < date
            ? locale.format('%M:%S.%L')
            : locale.format('%M:%S')
        )(date)
      case 'second':
        return (
          d3.timeSecond(date) < date
            ? locale.format('%S.%L秒')
            : locale.format('%S秒')
        )(date)
      case 'millisecond':
        return locale.format('.%Lミリ秒')(date)
      default:
        return locale.format('%-Y/%b/%d %H:%M:%S.%L')
    }
  },
  en: function (date, distance) {
    switch (distance) {
      case 'year':
        return (
          d3.timeSecond(date) < date
            ? d3.timeFormat('%-Y/%b/%d (%a) %H:%M:%S.%L')
            : d3.timeMinute(date) < date
            ? d3.timeFormat('%-Y/%b/%d (%a) %H:%M:%S')
            : d3.timeHour(date) < date
            ? d3.timeFormat('%-Y/%b/%d (%a) %H:%M')
            : d3.timeDay(date) < date
            ? d3.timeFormat('%-Y/%b/%d (%a) %H:00')
            : d3.timeMonth(date) < date
            ? d3.timeFormat('%-Y/%b/%d (%a)')
            : d3.timeYear(date) < date
            ? d3.timeFormat('%B')
            : d3.timeFormat('%-Y')
        )(date)
      case 'day':
        return (
          d3.timeSecond(date) < date
            ? d3.timeFormat('%b/%d (%a) %H:%M:%S.%L')
            : d3.timeMinute(date) < date
            ? d3.timeFormat('%b/%d (%a) %H:%M:%S')
            : d3.timeHour(date) < date
            ? d3.timeFormat('%b/%d (%a) %H:%M')
            : d3.timeDay(date) < date
            ? d3.timeFormat('%b/%d (%a) %H:00')
            : d3.timeMonth(date) < date
            ? d3.timeFormat('%b/%d (%a)')
            : d3.timeFormat('%B')
        )(date)
      case 'hour':
        return (
          d3.timeSecond(date) < date
            ? d3.timeFormat('%H:%M:%S.%L')
            : d3.timeMinute(date) < date
            ? d3.timeFormat('%H:%M:%S')
            : d3.timeFormat('%H:%M')
        )(date)
      case 'minute':
        return (
          d3.timeSecond(date) < date
            ? d3.timeFormat('%M:%S.%L')
            : d3.timeFormat('%M:%S')
        )(date)
      case 'second':
        return (
          d3.timeSecond(date) < date
            ? d3.timeFormat('%S.%Ls')
            : d3.timeFormat('%Ss')
        )(date)
      case 'millisecond':
        return d3.timeFormat('.%Lms')(date)
      default:
        return d3.timeFormat('%-Y/%b/%d %H:%M:%S.%L')
    }
  }
}

export const timeFormatters = {
  ja: function (date) {
    const locale = locales.ja
    return (
      d3.timeSecond(date) < date
        ? locale.format('.%L')
        : d3.timeMinute(date) < date
        ? locale.format('%H:%M:%S')
        : d3.timeHour(date) < date
        ? locale.format('%H:%M')
        : d3.timeDay(date) < date
        ? locale.format('%H:00')
        : d3.timeMonth(date) < date
        ? locale.format('%b/%d')
        : d3.timeYear(date) < date
        ? locale.format('%B')
        : locale.format('%-Y')
    )(date)
  },
  en: function (date) {
    return (
      d3.timeSecond(date) < date
        ? d3.timeFormat('.%L')
        : d3.timeMinute(date) < date
        ? d3.timeFormat('%H:%M:%S')
        : d3.timeHour(date) < date
        ? d3.timeFormat('%H:%M')
        : d3.timeDay(date) < date
        ? d3.timeFormat('%H:00')
        : d3.timeMonth(date) < date
        ? d3.timeFormat('%b/%d')
        : d3.timeYear(date) < date
        ? d3.timeFormat('%b')
        : d3.timeFormat('%-Y')
    )(date)
  }
}

export const secondsTooltipFormatter = {
  ja: function (date) {
    const locale = locales.ja
    return (
      d3.timeSecond(date) < date
        ? locale.format('%S.%L秒')
        : locale.format('%S秒')
    )(date)
  },
  en: function (date) {
    return (
      d3.timeSecond(date) < date
        ? d3.timeFormat('%S.%Ls')
        : d3.timeFormat('%Ss')
    )(date)
  }
}

export const secondsFormatter = {
  ja: function (date) {
    const locale = locales.ja
    return (
      d3.timeSecond(date) < date ? locale.format('.%L') : locale.format('%S')
    )(date)
  },
  en: function (date) {
    return (
      d3.timeSecond(date) < date ? d3.timeFormat('.%L') : d3.timeFormat('%S')
    )(date)
  }
}

export const minutelyTooltipFormatter = {
  ja: function (date) {
    const locale = locales.ja
    return (
      d3.timeSecond(date) < date
        ? locale.format('%M:%S.%L')
        : d3.timeMinute(date) < date
        ? locale.format('%M:%S')
        : locale.format('%M:00')
    )(date)
  },
  en: function (date) {
    return (
      d3.timeSecond(date) < date
        ? d3.timeFormat('%M:%S.%L')
        : d3.timeMinute(date) < date
        ? d3.timeFormat('%M:%S')
        : d3.timeFormat('%M:00')
    )(date)
  }
}

export const minutelyFormatter = {
  ja: function (date) {
    const locale = locales.ja
    return (
      d3.timeSecond(date) < date
        ? locale.format('.%L')
        : d3.timeMinute(date) < date
        ? locale.format('%M:%S')
        : locale.format('%M')
    )(date)
  },
  en: function (date) {
    return (
      d3.timeSecond(date) < date
        ? d3.timeFormat('.%L')
        : d3.timeMinute(date) < date
        ? d3.timeFormat('%M:%S')
        : d3.timeFormat('%M')
    )(date)
  }
}

export const hourlyTooltipFormatter = {
  ja: function (date) {
    const locale = locales.ja
    return (
      d3.timeSecond(date) < date
        ? locale.format('%H:%M:%S.%L')
        : d3.timeMinute(date) < date
        ? locale.format('%H:%M:%S')
        : d3.timeHour(date) < date
        ? locale.format('%H:%M:00')
        : locale.format('%H:00:00')
    )(date)
  },
  en: function (date) {
    return (
      d3.timeSecond(date) < date
        ? d3.timeFormat('%H:%M:%S.%L')
        : d3.timeMinute(date) < date
        ? d3.timeFormat('%H:%M:%S')
        : d3.timeHour(date) < date
        ? d3.timeFormat('%H:%M:00')
        : d3.timeFormat('%H:00:00')
    )(date)
  }
}

export const hourlyFormatter = {
  ja: function (date) {
    const locale = locales.ja
    return (
      d3.timeSecond(date) < date
        ? locale.format('.%L')
        : d3.timeMinute(date) < date
        ? locale.format('%H:%M:%S')
        : d3.timeHour(date) < date
        ? locale.format('%H:%M')
        : locale.format('%H:00')
    )(date)
  },
  en: function (date) {
    return (
      d3.timeSecond(date) < date
        ? d3.timeFormat('.%L')
        : d3.timeMinute(date) < date
        ? d3.timeFormat('%H:%M:%S')
        : d3.timeHour(date) < date
        ? d3.timeFormat('%H:%M')
        : d3.timeFormat('%H:00')
    )(date)
  }
}

export const dailyTooltipFormatter = {
  ja: function (date) {
    const locale = locales.ja
    return (
      d3.timeHour(date) < date
        ? locale.format('%H:%M')
        : d3.timeDay(date) < date
        ? locale.format('%H:00')
        : locale.format('00:00')
    )(date)
  },
  en: function (date) {
    return (
      d3.timeHour(date) < date
        ? d3.timeFormat('%H:%M')
        : d3.timeDay(date) < date
        ? d3.timeFormat('%H:00')
        : d3.timeFormat('00:00')
    )(date)
  }
}

export const dailyFormatter = {
  ja: function (date) {
    const locale = locales.ja
    return (
      d3.timeHour(date) < date
        ? locale.format('%H:%M')
        : d3.timeDay(date) < date
        ? locale.format('%H:00')
        : locale.format('%e日')
    )(date)
  },
  en: function (date) {
    return (
      d3.timeHour(date) < date
        ? d3.timeFormat('%H:%M')
        : d3.timeDay(date) < date
        ? d3.timeFormat('%H:00')
        : d3.timeFormat('%e')
    )(date)
  }
}

export const weeklyTooltipFormatter = {
  ja: function (date) {
    const locale = locales.ja
    return (
      d3.timeHour(date) < date
        ? locale.format('%A %H:%M')
        : d3.timeDay(date) < date
        ? locale.format('%A %H:00')
        : locale.format('%A')
    )(date)
  },
  en: function (date) {
    return (
      d3.timeHour(date) < date
        ? d3.timeFormat('%A %H:%M')
        : d3.timeDay(date) < date
        ? d3.timeFormat('%A %H:00')
        : d3.timeFormat('%A')
    )(date)
  }
}

export const weeklyFormatter = {
  ja: function (date) {
    const locale = locales.ja
    return (
      d3.timeHour(date) < date
        ? locale.format('%H:%M')
        : d3.timeDay(date) < date
        ? locale.format('%H:00')
        : locale.format('%A')
    )(date)
  },
  en: function (date) {
    return (
      d3.timeHour(date) < date
        ? d3.timeFormat('%H:%M')
        : d3.timeDay(date) < date
        ? d3.timeFormat('%H:00')
        : d3.timeFormat('%a')
    )(date)
  }
}

export const monthlyTooltipFormatter = {
  ja: function (date) {
    const locale = locales.ja
    return (
      d3.timeHour(date) < date
        ? locale.format('%e日 %H:%M')
        : d3.timeDay(date) < date
        ? locale.format('%e日 %H:00')
        : locale.format('%e日')
    )(date)
  },
  en: function (date) {
    return (
      d3.timeHour(date) < date
        ? d3.timeFormat('%e %H:%M')
        : d3.timeDay(date) < date
        ? d3.timeFormat('%e %H:00')
        : d3.timeFormat('%e')
    )(date)
  }
}

export const monthlyFormatter = {
  ja: function (date) {
    const locale = locales.ja
    return (
      d3.timeHour(date) < date
        ? locale.format('%H:%M')
        : d3.timeDay(date) < date
        ? locale.format('%H:00')
        : locale.format('%e日')
    )(date)
  },
  en: function (date) {
    return (
      d3.timeHour(date) < date
        ? d3.timeFormat('%H:%M')
        : d3.timeDay(date) < date
        ? d3.timeFormat('%H:00')
        : d3.timeFormat('%e')
    )(date)
  }
}

export const yearlyTooltipFormatter = {
  ja: function (date) {
    const locale = locales.ja
    return (
      d3.timeHour(date) < date
        ? locale.format('%b/%d %H:%M')
        : d3.timeDay(date) < date
        ? locale.format('%b/%d %H:00')
        : d3.timeMonth(date) < date
        ? locale.format('%b/%d')
        : locale.format('%b/1')
    )(date)
  },
  en: function (date) {
    return (
      d3.timeHour(date) < date
        ? d3.timeFormat('%b/%d %H:%M')
        : d3.timeDay(date) < date
        ? d3.timeFormat('%b/%d %H:00')
        : d3.timeMonth(date) < date
        ? d3.timeFormat('%b/%d')
        : d3.timeFormat('%b/1')
    )(date)
  }
}

export const yearlyFormatter = {
  ja: function (date) {
    const locale = locales.ja
    return (
      d3.timeHour(date) < date
        ? locale.format('%H:%M')
        : d3.timeDay(date) < date
        ? locale.format('%H:00')
        : d3.timeMonth(date) < date
        ? locale.format('%b/%d')
        : locale.format('%B')
    )(date)
  },
  en: function (date) {
    return (
      d3.timeHour(date) < date
        ? d3.timeFormat('%H:%M')
        : d3.timeDay(date) < date
        ? d3.timeFormat('%H:00')
        : d3.timeMonth(date) < date
        ? d3.timeFormat('%b/%d')
        : d3.timeFormat('%B')
    )(date)
  }
}
