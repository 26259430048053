var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.entry.description)?_c('div',{staticClass:"delete-item-desc"},[_c('description',{attrs:{"text":_vm.entry.description}})],1):_vm._e(),(_vm.entry.is_optimization)?_c('div',{staticClass:"delete-item-optimization"},[_c('div',{staticClass:"delete-item-title-optimization"},[_c('texts',{attrs:{"text":_vm.$t('trainedAi.optimization.latestHistory'),"size":"small","color":"gray"}})],1),_c('optimization-conditions-list',{attrs:{"optimizationConditions":_vm.entry.latestOptimizationConditions,"limit":3}})],1):_vm._e(),(
      (_vm.entry.metrics.expectedEffect ||
        (_vm.entry.metrics && Object.keys(_vm.entry.metrics).length > 0)) &&
        !_vm.entry.is_optimization
    )?_c('div',{staticClass:"delete-item-accuracy"},[(_vm.entry.metrics.expectedEffect)?_c('div',{staticClass:"delete-item-expected-effect"},[_c('text-with-title',{attrs:{"title":_vm.entry.metrics.expectedEffectName,"text":_vm.entry.metrics.expectedEffect}})],1):_vm._e(),(_vm.entry.metricsList)?_c('div',{staticClass:"delete-item-accuracy-wrap"},[(Object.keys(_vm.entry.metrics).length > 1)?_c('texts',{staticClass:"delete-item-accuracy-list-title",attrs:{"text":_vm.limitMetrics[0].name,"size":"small","color":"gray"}}):_vm._e(),_c('div',{staticClass:"delete-item-accuracy-list"},_vm._l((_vm.limitMetrics),function(metric,index){return _c('div',{key:index,staticClass:"delete-item-accuracy-item"},[_c('texts',{attrs:{"size":"small","color":"gray","text":Object.keys(_vm.entry.metrics).length > 1
                ? metric.columnName
                : metric.name}}),_c('metrics-text',{attrs:{"value":metric.value,"percentage":metric.percentage}})],1)}),0)],1):_vm._e()]):_vm._e(),(
      Object.keys(_vm.entry.featureImportance).length > 0 ||
        (_vm.entry.datasets && _vm.entry.datasets.length > 0)
    )?_c('div',{staticClass:"delete-item-detail"},[(
        Object.keys(_vm.entry.featureImportance).length > 0 &&
          !_vm.isDetail &&
          !_vm.entry.is_optimization
      )?_c('div',{staticClass:"delete-item-feature-importance"},[_c('div',{staticClass:"delete-item-title delete-item-title-features"},[_c('texts',{attrs:{"text":_vm.$t('trainedAiDetails.featureImportance'),"size":"small","color":"gray"}})],1),_c('list-features',{attrs:{"id":_vm.entry.id,"predictionColumns":_vm.entry.predictionColumns,"featureImportance":_vm.entry.featureImportance}})],1):_vm._e(),_c('div',{staticClass:"delete-item-detail-sub"},[(_vm.entry.services.length)?_c('div',{staticClass:"delete-item-service"},[_c('texts',{attrs:{"text":_vm.$t('trainedAiDetails.linkedServices'),"size":"small","color":"gray"}}),_c('div',{staticClass:"delete-item-service-item"},_vm._l((_vm.entry.services),function(service,index){return _c('text-with-icon',{key:index,staticClass:"delete-item-service-link",attrs:{"text":service.name,"iconName":"newTab","pos":"right","color":"link-default"},nativeOn:{"click":function($event){return _vm.goService(service)}}})}),1)],1):_vm._e(),(_vm.entry.datasets.length > 0)?_c('text-with-title',{attrs:{"title":_vm.$t('trainedAiDetails.datasetUsed'),"text":_vm.entry.datasets.length > 0 ? _vm.entry.datasets[0].name : ''}}):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"delete-item-time"},[_c('text-with-title',{staticClass:"delete-item-time-inner",attrs:{"title":_vm.$t('common.createTime')}},[_c('a-time',{attrs:{"value":_vm.entry.createTime}})],1),_c('text-with-title',{staticClass:"delete-item-time-inner",attrs:{"title":_vm.$t('common.updateTime')}},[_c('a-time',{attrs:{"value":_vm.entry.updateTime}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }