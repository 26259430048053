<template>
  <div
    :id="`scatter${$_uid}`"
    ref="scatter"
    v-resize:throttle.100="resized"
    class="scatter-plot"
  >
    <slot />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import * as d3 from 'd3'
import d3tip from 'd3-tip'

import resize from 'vue-resize-directive'
export default {
  directives: {
    resize
  },
  name: 'Scatter',
  props: {
    data: Array,
    xLabel: { type: String, default: 'X' },
    yLabel: { type: String, default: 'Y' },
    showImage: { type: Boolean, default: false },
    forceIncludeZero: { type: Boolean, default: true },
    line: { type: Boolean, default: false }
  },
  mounted() {
    this.init()
    this.$nextTick(() => this.show2D())
  },
  data() {
    return {
      zoomRatio: 0.9,
      resizeCooltime: false,

      svg: null,
      root: null,
      area: null,
      tip: null,
      xScale: null,
      yScale: null,
      xAxis: null,
      yAxis: null,
      gx: null,
      gy: null,

      margin: { top: 30, right: 30, bottom: 40, left: 40 },
      computedMarginLeft: 40
    }
  },
  computed: {
    resizeParams() {
      return { data: this.data, xLabel: this.xLabel, yLabel: this.yLabel }
    }
  },
  methods: {
    init() {
      const margin = this.margin
      const el = this.$refs.scatter
      const outerWidth = el.clientWidth
      const outerHeight = el.clientHeight
      const width = Math.max(outerWidth - margin.left - margin.right, 100)
      const height = Math.max(outerHeight - margin.top - margin.bottom, 100)
      const svg = d3
        .select('#scatter' + this.$_uid)
        .append('svg')
        .classed('scatter-main', true)
        .attr('width', outerWidth)
        .attr('height', outerHeight)
        .attr('focusable', true)
        .on('focus', function () {
          svg.style('pointer-events', 'all')
        })
        .on('blur', function () {
          svg.style('pointer-events', 'unset')
        })
      this.svg = svg
      this.root = svg
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

      const xScale = d3.scaleLinear().range([0, width]).nice()
      const yScale = d3.scaleLinear().range([height, 0]).nice()
      this.xScale = xScale
      this.yScale = yScale

      const xAxis = d3.axisBottom(xScale).tickSize(-height)
      const yAxis = d3.axisLeft(yScale).tickSize(-width)
      this.xAxis = xAxis
      this.yAxis = yAxis

      const gx = this.root
        .append('g')
        .classed('x axis d3-axis', true)
        .call(this.xAxis)

      const xLabel = gx.append('text').classed('label', true)

      const gy = this.root
        .append('g')
        .classed('y axis d3-axis', true)
        .call(this.yAxis)
      this.gx = gx
      this.gy = gy

      gx.append('svg:line')
        .classed('axisLine v-axis-line', true)
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', -height)

      gy.append('svg:line')
        .classed('axisLine h-axis-line', true)
        .attr('x1', 0)
        .attr('y1', height)
        .attr('x2', width)
        .attr('y2', height)

      gy.selectAll('.tick')
        .filter((x) => {
          return x === 0
        })
        .attr('class', 'tick scatter-tick-y0')

      const yLabel = gy
        .append('text')
        .classed('label', true)
        .attr('transform', 'rotate(-90)')

      this.tip = d3tip()
        .attr('class', 'd3-tip')
        .offset([-10, 0])
        .html(this.showTip)

      this.zoomBeh = d3
        .zoom()
        .scaleExtent([0, 500])
        .on('zoom', () => this.zoomed())
      this.root.call(this.tip)
      this.root.call(this.zoomBeh)

      this.area = this.root
        .append('svg')
        .classed('area', true)
        .attr('width', width)
        .attr('height', height)
      const dotsGroup = this.area.append('g')
      this.resized()
      this.zoomBeh.scaleTo(this.root, 0.9)
    },
    zoomed() {
      const zoomRatio = (this.zoomRatio = d3.event.transform.k)
      this.gx.call(this.xAxis.scale(d3.event.transform.rescaleX(this.xScale)))
      this.gy.call(this.yAxis.scale(d3.event.transform.rescaleY(this.yScale)))
      this.area.select('g').attr('transform', d3.event.transform)
      this.area.selectAll('circle').attr('r', function (d) {
        return 8 / zoomRatio
      })
      this.recalcYAxis()
    },
    resized() {
      const margin = this.margin
      const el = this.$refs.scatter

      const outerWidth = el.clientWidth
      const outerHeight = el.clientHeight
      const width = Math.max(
        outerWidth - this.computedMarginLeft - margin.right,
        1
      )
      const height = Math.max(outerHeight - margin.top - margin.bottom, 1)

      this.root.attr(
        'transform',
        'translate(' + this.computedMarginLeft + ',' + margin.top + ')'
      )

      this.svg.attr('width', outerWidth).attr('height', outerHeight)

      this.xScale.range([0, width])
      this.yScale.range([height, 0])
      this.xAxis.tickSize(-height)
      this.yAxis.tickSize(-width)

      this.gx.attr('transform', 'translate(0,' + height + ')')

      this.gx
        .select('.v-axis-line')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', -height)

      this.gy
        .select('.h-axis-line')
        .attr('x1', 0)
        .attr('y1', height)
        .attr('x2', width)
        .attr('y2', height)

      this.gx
        .select('.label')
        .attr('x', width / 2)
        .attr('y', margin.bottom)
        .text(this.xLabel)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'text-after-edge')
        .attr('fill', 'black')

      this.recalcYAxis()

      this.area.attr('width', width).attr('height', height)

      this.zoomBeh.translateBy(this.root, 0, 0)
      this.drawPoints(this.data)
    },
    recalcYAxis() {
      const margin = this.margin
      const el = this.$refs.scatter
      const outerHeight = el.clientHeight
      const height = outerHeight - margin.top - margin.bottom
      const yLabel = this.gy.select('.label').text(this.yLabel)
      const yLabelWidth = yLabel.node() ? yLabel.node().getBBox().height : 25 // rotated

      const minMarginLeft =
        d3.max(
          this.gy
            .selectAll('.tick text')
            .nodes()
            .map((x) => x.getBBox().width)
        ) + yLabelWidth
      this.computedMarginLeft = Math.max(margin.left, minMarginLeft)

      yLabel
        .attr('x', -height / 2)
        .attr('y', -this.computedMarginLeft + yLabelWidth)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'text-after-edge')
        .attr('fill', 'black')
    },
    clicked(d) {
      this.$emit('click', d)
    },
    showTip(d) {
      if (d.body) {
        const url = 'data:image/png;base64,' + d.body
        const img = document.createElement('img')
        img.src = url
        img.classList.add('scatter-tip-image')
        const natSize = { width: img.naturalWidth, height: img.naturalHeight }

        return `
           <img src="${url}" class="scatter-tip-image">
           <p>${d.filename} (${natSize.width} x ${natSize.height})</p>
           <p>x:${d.vector[0]}</p>
           <p>y: ${d.vector[1]}</p>
          `
      }
      return `
           <p>x:${d.vector[0]}</p>
           <p>y: ${d.vector[1]}</p>
          `
    },
    drawPoints(data) {
      const vueThis = this
      const x = function (d, i) {
        const xValue = d.vector[0]
        if (xValue == null || Number.isNaN(xValue)) {
          return null
        }
        return vueThis.xScale(xValue)
      }
      const y = function (d, i) {
        const yValue = d.vector[1]
        if (yValue == null || Number.isNaN(yValue)) {
          return null
        }
        return vueThis.yScale(yValue)
      }

      this.area.select('g').selectAll('*').remove()

      const sel = this.area.select('g').selectAll('.dot').data(data)

      if (this.line) {
        this.area
          .select('g')
          .datum(data)
          .append('path')
          .attr('class', 'plot-path')
          .attr('fill', 'none')
          .attr('stroke-width', 1.5)
          .attr('d', d3.line().x(x).y(y).curve(d3.curveCatmullRom))
      }
      if (this.showImage) {
        const imageSize = function (d) {
          return 28 / vueThis.zoomRatio
        }
        sel
          .enter()
          .append('svg:image')
          .attr('xlink:href', function (d) {
            const url = 'data:image/png;base64,' + d.body
            return url
          })
          .classed('dot', true)
          .classed('plot-obj', true)
          .on('mouseover', this.tip.show)
          .on('mouseout', this.tip.hide)
          .on('click', function (...args) {
            vueThis.clicked(...args)
          })
          .attr('x', (d) => x(d) - imageSize(d) / 2)
          .attr('y', (d) => y(d) - imageSize(d) / 2)
          .attr('width', imageSize)
          .attr('height', imageSize)
          .transition()
          .duration(vueThis.transitionDuration)
      } else {
        sel
          .enter()
          .append('circle')
          .on('mouseover', this.tip.show)
          .on('mouseout', this.tip.hide)
          .on('click', function (...args) {
            vueThis.clicked(...args)
          })
          .classed('dot d3-dot', true)
          .classed('plot-obj', true)
          .attr('cx', x)
          .attr('cy', y)
          .attr('r', function (d) {
            return 8 / vueThis.zoomRatio
          })
          .transition()
          .duration(vueThis.transitionDuration)
      }

      sel.exit().remove()

      return sel
    },
    rescale() {
      const data = this.data
      const xMax =
        d3.max(data, function (d) {
          return d.vector[0]
        }) * 1.05
      let xMin = d3.min(data, function (d) {
        return d.vector[0]
      })
      if (this.forceIncludeZero) {
        xMin = xMin > 0 ? 0 : xMin
      }

      const yMax =
        d3.max(data, function (d) {
          return d.vector[1]
        }) * 1.05
      let yMin = d3.min(data, function (d) {
        return d.vector[1]
      })
      if (this.forceIncludeZero) {
        yMin = yMin > 0 ? 0 : yMin
      }

      this.xScale.domain([xMin, xMax])
      this.yScale.domain([yMin, yMax])
    },
    show2D() {
      const vueThis = this
      const data = this.data
      const el = this.$refs.scatter
      const margin = { top: 30, right: 30, bottom: 30, left: 30 }

      const outerWidth = el.clientWidth
      const outerHeight = el.clientHeight
      const width = outerWidth - margin.left - margin.right
      const height = outerHeight - margin.top - margin.bottom

      data.forEach(function (d) {
        d.vector[0] = +d.vector[0]
        d.vector[1] = +d.vector[1]
      })

      this.rescale()

      const sel = this.drawPoints(data)

      this.resized()
    }
  },
  watch: {
    showImage() {
      this.drawPoints(this.data)
    },
    resizeParams() {
      this.rescale()
      this.resized()
    },
    computedMarginLeft(nv, ov) {
      if (nv !== ov) this.resized()
    }
  },
  beforeDestroy() {
    d3.select('.scatter-main').attr('style', 'pointer-events: none;')
  },
  destroyed() {
    this.tip.destroy()
  }
}
</script>
<style scoped>
.scatter-plot {
  min-width: 100px;
  min-height: 100px;
}
</style>
<style lang="scss">
/* stylelint-disable selector-no-qualifying-type */

.scatter-plot {
  .tick {
    user-select: none;
  }

  .h-axis-line {
    opacity: 0;
    fill: none;
    stroke: $border-gray;
  }

  .v-axis-line {
    opacity: 0;
    fill: none;
    stroke: $border-gray;
  }

  text.label {
    font-size: 1.4rem;
    white-space: pre-line;
  }
  .dot:not([fill]) {
    opacity: 0.5;
    fill: $key-color;
  }
  .plot-path {
    opacity: 0.5;
    stroke: $key-color;
  }
}
.scatter-tip-image {
  max-width: 200px;
  max-height: 200px;
}
.scatter-main:focus {
  outline: $key-color 1px auto;
  rect {
    stroke: $gray;
  }
}

.scatter-tick-y0.tick {
  line {
    opacity: 0.5;
    stroke: $key-tone;
    stroke-width: adjustVW(2);
  }
  text {
    color: $black;
    font-weight: bold;
  }
}

.scatter-modal {
  width: min-content;
  max-width: unset;
}
</style>
