<template>
  <div class="recipe-list">
    <div class="top">
      <recipe-list-top
        :checked="checked"
        :recipes="recipes"
        :accountInfo="accountInfo"
        :isTrain="isTrain"
        :recipesFiltered="recipesFiltered"
        @menu-click="$emit('menu-click', $event)"
        @get-recipe-template="$emit('get-recipe-template')"
        @input="search($event)"
        @page-back="$emit('page-back')"
      />
    </div>
    <div v-if="recipes" class="body">
      <!--main-->
      <recipe-list-body
        v-model="checked"
        :recipesFiltered="recipesFiltered"
        :isTrain="isTrain"
        :noSearchResults="noSearchResults"
      />
    </div>
  </div>
</template>

<script>
import recipeListTop from './recipe-list-top'
import recipeListBody from './recipe-list-body'

export default {
  components: {
    recipeListTop,
    recipeListBody
  },
  data() {
    return {
      checked: [],
      recipeArray: null,
      noSearchResults: false
    }
  },
  computed: {
    recipesFiltered() {
      if (!this.recipes) {
        return null
      } else if (!this.recipeArray) {
        return Object.values(this.recipes)
      } else {
        return this.recipeArray
      }
    }
  },
  props: {
    recipes: Object,
    accountInfo: Object,
    isTrain: Boolean
  },
  methods: {
    search(e) {
      this.recipeArray = e.result
      this.noSearchResults = e.noSearchResults
    }
  },
  watch: {
    recipes() {
      const validIds = new Set(Object.keys(this.recipes))
      this.checked = this.checked.filter((item) => validIds.has(item.fullId))
    }
  }
}
</script>

<style lang="scss" scoped>
.recipe-list {
  overflow: hidden;
  display: grid;
  grid-template-areas:
    'top'
    'main';
  grid-template-rows: auto 1fr;
  grid-column-gap: $space-medium;
  grid-row-gap: $space-base;
  height: 100vh - adjustVH($headerTabHeight);
  padding: 0 $space-medium $space-medium;
  .top {
    grid-area: top;
  }
  .body {
    overflow: hidden;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    grid-area: main;
  }
}
</style>
