<template>
  <div class="top">
    <button
      v-if="!editting"
      class="top-title"
      @click="$emit('show-trained-ai-list')"
    >
      <text-with-icon
        class="top-title-main"
        iconName="backward"
        size="title"
        :text="value.name"
      />
      <top-edit-icon
        v-if="!isSaved"
        class="top-title-edit"
        @click-edit="toggleEdit()"
      />
    </button>
    <top-edit-box
      v-else
      v-model="value.name"
      term="name"
      :accept="validate.accept"
      :error="validate.error"
      :nameValidationSuggests="trainedAIFormValidate.suggests"
      @finish-edit="toggleEdit()"
      @input-edit-box="inputCreateForm"
    />

    <div class="top-button-list">
      <div class="top-button-button">
        <button-main
          class="top-button-item"
          type="emphasis"
          :isDisabled="disabledSave"
          :text="$t('training.button.compTraining')"
          @click="$emit('save-trained-ai')"
        />
        <button-main
          class="top-button-item"
          :text="$t('training.button.checkOtherTrainedAi')"
          type="sub"
          @click="$emit('show-trained-ai-list')"
        />
      </div>
      <text-box
        v-if="isSaved"
        isAccept
        size="small"
        :text="$t('training.result.savedText')"
      />
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import textWithIcon from '@/components/molecules/text-with-icon.vue'
import TopEditIcon from '@/components/atoms/top-edit-icon.vue'
import TopEditBox from '@/components/atoms/top-edit-box.vue'
import TextBox from '@/components/atoms/text-box.vue'

export default {
  components: {
    buttonMain,
    textWithIcon,
    TopEditIcon,
    TopEditBox,
    TextBox
  },
  data() {
    return {
      editting: false,
      form: {
        name: this.value.name,
        id: this.value.id,
        revision: this.trainedAi.revision ? this.trainedAi.revision : false
      },
      validate: {
        accept: null,
        error: null
      }
    }
  },
  props: {
    isSaved: Boolean,
    trainedAi: Object,
    trainedAIFormValidate: Object,
    value: Object
  },
  computed: {
    disabledSave: function () {
      if (this.isSaved) return true

      return false
    }
  },
  watch: {
    editting: {
      handler: function (status) {
        if (status) {
          this.setValidateStatus(this.trainedAIFormValidate)
        }
      }
    },
    trainedAIFormValidate: {
      deep: true,
      handler: function (status) {
        this.setValidateStatus(status)

        if (!this.form.revision) {
          this.form.revision = true
        }
      }
    },
    'value.revision': {
      handler: function (status) {
        this.form.revision = !status
      }
    }
  },
  methods: {
    toggleEdit() {
      this.editting = !this.editting
    },
    inputCreateForm: function (box) {
      this.form = Object.assign(this.form, box)
      this.$emit('input-create-form', {
        type: 'trainedAI',
        form: this.form
      })
    },
    setValidateStatus: function (status) {
      const { duplicate } = status

      this.validate.accept = duplicate !== undefined ? duplicate : null
      this.validate.error = duplicate !== undefined ? !duplicate : null
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  padding: $space-medium $space-medium 0;
  &-title {
    display: flex;
    align-items: flex-end;
    max-width: adjustVW(960);
    &-main {
      margin: 0 $space-base 0 0;
    }
    &-edit {
      flex-shrink: 0;
    }
    &:hover {
      opacity: 1;
    }
  }
  &-button {
    &-button {
      margin: 0 $space-medium 0 0;
    }
    &-list {
      display: flex;
      align-items: center;
      margin: $space-medium 0 0;
    }
  }
}
</style>
