<template>
  <div class="trained-ai-timeseries">
    <div class="trained-ai-timeseries-side">
      <div class="trained-ai-timeseries-display-type">
        <template
          v-if="
            trainedAi.trainConfig.predictionColumn &&
              trainedAi.trainConfig.predictionColumn.length > 1
          "
        >
          <div class="trained-ai-timeseries-title-sub">
            <texts
              :text="$t('trainedAiDetails.timeseries.multiResultTilte')"
              color="gray"
              size="small"
            />
          </div>
          <select-column-box
            v-model="selectedItem"
            class="trained-ai-timeseries-select-inner"
            :trainedAi="trainedAi"
            :finishColumns="finishColumns"
          />
        </template>
        <div class="trained-ai-timeseries-title-sub">
          <texts
            :text="$t('trainedAiDetails.featureImportanceTab.togglePage')"
            color="gray"
            size="small"
          />
        </div>
        <!-- 表示するグラフの選択ボタン -->
        <transition-toggle-contents>
          <div
            v-if="!loadChangeColumn && checkTimeseriesColumn"
            class="trained-ai-timeseries-display-type-list"
          >
            <button
              class="trained-ai-timeseries-display-type-item"
              :class="
                displayType === 'result'
                  ? 'trained-ai-timeseries-display-type-item-active'
                  : ''
              "
              @click="displayTypeClick('result')"
            >
              <text-with-icon
                iconName="timeseriesResult"
                :text="$t('graph.timeseries.predictions')"
                size="small"
                :color="displayType === 'result' ? 'emphasis' : 'gray'"
              />
            </button>
            <button
              class="trained-ai-timeseries-display-type-item"
              :class="
                displayType === 'trend'
                  ? 'trained-ai-timeseries-display-type-item-active'
                  : ''
              "
              @click="displayTypeClick('trend')"
            >
              <text-with-icon
                iconName="timeseriesTrend"
                :text="$t('graph.timeseries.timeseriesTrendGraph')"
                size="small"
                :color="displayType === 'trend' ? 'emphasis' : 'gray'"
              />
            </button>
            <button
              class="trained-ai-timeseries-display-type-item"
              :class="
                displayType === 'changingPoint'
                  ? 'trained-ai-timeseries-display-type-item-active'
                  : ''
              "
              @click="displayTypeClick('changingPoint')"
            >
              <text-with-icon
                iconName="timeseriesChangePoint"
                :text="$t('graph.timeseries.changepoints')"
                size="small"
                :color="displayType === 'changingPoint' ? 'emphasis' : 'gray'"
              />
            </button>
            <button
              class="trained-ai-timeseries-display-type-item"
              :class="
                displayType === 'graph'
                  ? 'trained-ai-timeseries-display-type-item-active'
                  : ''
              "
              @click="displayTypeClick('graph')"
            >
              <text-with-icon
                iconName="timeseriesGraph"
                :text="$t('graph.timeseries.correlation')"
                size="small"
                :color="displayType === 'graph' ? 'emphasis' : 'gray'"
              />
            </button>
            <button
              v-for="(value, key) in seasonalities"
              :key="key"
              class="trained-ai-timeseries-display-type-item"
              :class="
                displayType === key
                  ? 'trained-ai-timeseries-display-type-item-active'
                  : ''
              "
              @click="displayTypeClick(key)"
            >
              <text-with-icon
                iconName="timeseriesOthers"
                :text="key"
                size="small"
                :color="displayType === key ? 'emphasis' : 'gray'"
              />
            </button>
            <!-- prophetのデフォルトweekly -->
            <button
              v-if="weekly.length > 0"
              class="trained-ai-timeseries-display-type-item"
              :class="
                displayType === 'weekly'
                  ? 'trained-ai-timeseries-display-type-item-active'
                  : ''
              "
              @click="displayTypeClick('weekly')"
            >
              <text-with-icon
                iconName="timeseriesOthers"
                :text="$t('graph.timeseries.timeseriesWeeklyGraph')"
                size="small"
                :color="displayType === 'weekly' ? 'emphasis' : 'gray'"
              />
            </button>
            <!-- prophetのデフォルトyearly -->
            <button
              v-if="yearly.length > 0"
              class="trained-ai-timeseries-display-type-item"
              :class="
                displayType === 'yearly'
                  ? 'trained-ai-timeseries-display-type-item-active'
                  : ''
              "
              @click="displayTypeClick('yearly')"
            >
              <text-with-icon
                iconName="timeseriesOthers"
                :text="$t('graph.timeseries.timeseriesYearlyGraph')"
                size="small"
                :color="displayType === 'yearly' ? 'emphasis' : 'gray'"
              />
            </button>
          </div>
          <timeseries-detail-loading
            v-else-if="!loadingTabContents"
            class="trained-ai-timeseries-display-type-list-loading"
            size="small"
          />
        </transition-toggle-contents>
      </div>
      <div class="trained-ai-timeseries-metrics">
        <timeseries-metrics
          :trainedAi="trainedAi"
          :loading="loadingTabContents || loadChangeColumn"
          :checkTimeseriesColumn="checkTimeseriesColumn"
          :changeDispType="changeDispType"
        />
      </div>
    </div>
    <template v-if="!loadingTabContents">
      <!-- グラフ表示部 -->
      <transition-toggle-contents>
        <div
          v-if="displayType === 'result'"
          key="result"
          class="trained-ai-timeseries-graph"
        >
          <div class="trained-ai-timeseries-title">
            <texts :text="$t('graph.timeseries.predictions')" size="large" />
            <texts
              v-if="
                checkTimeseriesColumn &&
                  targetResult &&
                  targetResult.seasonality_mode
              "
              :text="
                $t(
                  'training.result.timeseries.' + targetResult.seasonality_mode
                )
              "
              color="gray"
              size="small"
            />
          </div>
          <transition-toggle-contents>
            <timeseries
              v-if="
                prediction &&
                  prediction.length > 0 &&
                  !loadChangeColumn &&
                  checkTimeseriesColumn
              "
              :series="prediction"
              :columnName="selectedItem"
            />
            <timeseries-detail-loading
              v-else
              :loadChangeColumn="loadChangeColumn"
            />
          </transition-toggle-contents>
        </div>
        <div
          v-else-if="displayType === 'trend'"
          key="trend"
          class="trained-ai-timeseries-graph"
        >
          <div class="trained-ai-timeseries-title">
            <texts
              :text="$t('graph.timeseries.timeseriesTrendGraph')"
              size="large"
            />
            <texts
              v-if="
                checkTimeseriesColumn &&
                  targetResult &&
                  targetResult.seasonality_mode
              "
              :text="
                $t(
                  'training.result.timeseries.' + targetResult.seasonality_mode
                )
              "
              color="gray"
              size="small"
            />
          </div>
          <transition-toggle-contents>
            <timeseries
              v-if="targetResult && !loadChangeColumn && checkTimeseriesColumn"
              :series="trend"
              :columnName="selectedItem"
            />
            <timeseries-detail-loading
              v-else
              :loadChangeColumn="loadChangeColumn"
            />
          </transition-toggle-contents>
        </div>
        <div
          v-else-if="displayType === 'changingPoint'"
          key="changingPoint"
          class="trained-ai-timeseries-graph"
        >
          <div class="trained-ai-timeseries-title">
            <texts :text="$t('graph.timeseries.changepoints')" size="large" />
            <texts
              v-if="
                checkTimeseriesColumn &&
                  targetResult &&
                  targetResult.seasonality_mode
              "
              :text="
                $t(
                  'training.result.timeseries.' + targetResult.seasonality_mode
                )
              "
              color="gray"
              size="small"
            />
          </div>
          <transition-toggle-contents>
            <timeseries
              v-if="targetResult && !loadChangeColumn && checkTimeseriesColumn"
              :series="changepoints"
              :columnName="selectedItem"
            />
            <timeseries-detail-loading
              v-else
              :loadChangeColumn="loadChangeColumn"
            />
          </transition-toggle-contents>
        </div>
        <div
          v-else-if="displayType === 'graph'"
          key="graph"
          class="trained-ai-timeseries-graph"
        >
          <transition-toggle-contents>
            <div
              v-if="!loadChangeColumn && checkTimeseriesColumn"
              class="trained-ai-timeseries-graph-inner"
            >
              <div class="trained-ai-timeseries-title">
                <texts :text="$t('graph.timeseries.acf')" size="large" />
                <texts
                  v-if="
                    checkTimeseriesColumn &&
                      targetResult &&
                      targetResult.seasonality_mode
                  "
                  :text="
                    $t(
                      'training.result.timeseries.' +
                        targetResult.seasonality_mode
                    )
                  "
                  color="gray"
                  size="small"
                />
              </div>
              <div class="graph-wrap">
                <bar
                  v-if="acf.length > 0"
                  :data="getGraph($t('graph.timeseries.acfTips'), acf)"
                  class="graph-inner"
                  :showLegends="false"
                />
              </div>
              <div class="trained-ai-timeseries-title">
                <texts :text="$t('graph.timeseries.pacf')" size="large" />
                <texts
                  v-if="
                    checkTimeseriesColumn &&
                      targetResult &&
                      targetResult.seasonality_mode
                  "
                  :text="
                    $t(
                      'training.result.timeseries.' +
                        targetResult.seasonality_mode
                    )
                  "
                  color="gray"
                  size="small"
                />
              </div>
              <div
                class="graph-wrap"
                :class="pacf.length === 0 ? 'graph-none' : ''"
              >
                <bar
                  v-if="
                    pacf.length > 0 &&
                      !loadChangeColumn &&
                      checkTimeseriesColumn
                  "
                  :data="getGraph($t('graph.timeseries.pacfTips'), pacf)"
                  class="graph-inner"
                  :showLegends="false"
                />
                <texts
                  v-else-if="pacf.length === 0"
                  :text="$t('graph.timeseries.pacfNone')"
                  class="graph-none-text"
                  size="small"
                  color="gray"
                />
              </div>
            </div>
            <timeseries-detail-loading
              v-else
              :loadChangeColumn="loadChangeColumn"
            />
          </transition-toggle-contents>
        </div>
        <!-- prophetのデフォルトweekly -->
        <div
          v-else-if="displayType === 'weekly'"
          key="weekly"
          class="trained-ai-timeseries-graph"
        >
          <transition-toggle-contents>
            <div
              v-if="!loadChangeColumn && checkTimeseriesColumn"
              class="trained-ai-timeseries-graph-inner"
            >
              <div class="trained-ai-timeseries-title">
                <texts
                  :text="$t('graph.timeseries.timeseriesWeeklyGraph')"
                  size="large"
                />
              </div>
              <timeseries
                cyclic
                :series="weekly"
                type="week"
                :columnName="selectedItem"
              />
            </div>
            <timeseries-detail-loading
              v-else
              :loadChangeColumn="loadChangeColumn"
            />
          </transition-toggle-contents>
        </div>
        <!-- prophetのデフォルトyearly -->
        <div
          v-else-if="displayType === 'yearly'"
          key="yearly"
          class="trained-ai-timeseries-graph"
        >
          <transition-toggle-contents>
            <div
              v-if="!loadChangeColumn && checkTimeseriesColumn"
              class="trained-ai-timeseries-graph-inner"
            >
              <div class="trained-ai-timeseries-title">
                <texts
                  :text="$t('graph.timeseries.timeseriesYearlyGraph')"
                  size="large"
                />
              </div>
              <timeseries
                cyclic
                :series="yearly"
                type="year"
                :columnName="selectedItem"
              />
            </div>
            <timeseries-detail-loading
              v-else
              :loadChangeColumn="loadChangeColumn"
            />
          </transition-toggle-contents>
        </div>
        <template v-for="(value, key) in seasonalities">
          <div
            v-if="displayType === key"
            :key="key"
            class="trained-ai-timeseries-graph"
          >
            <div class="trained-ai-timeseries-title">
              <texts :text="key" size="large" />
              <texts
                v-if="
                  checkTimeseriesColumn &&
                    targetResult &&
                    targetResult.seasonality_mode
                "
                :text="
                  $t(
                    'training.result.timeseries.' +
                      targetResult.seasonality_mode
                  )
                "
                color="gray"
                size="small"
              />
            </div>
            <transition-toggle-contents>
              <timeseries
                v-if="
                  seasonalities &&
                    Object.keys(seasonalities).length > 0 &&
                    !loadChangeColumn &&
                    checkTimeseriesColumn
                "
                cyclic
                :type="value.timeUnit"
                :series="seasonalityGrapTarget(key)"
                :columnName="selectedItem"
              />
              <timeseries-detail-loading
                v-else
                :loadChangeColumn="loadChangeColumn"
              />
            </transition-toggle-contents>
          </div>
        </template>
      </transition-toggle-contents>
    </template>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import textWithIcon from '@/components/molecules/text-with-icon'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import timeseries from '@/components/organisms/graph/timeseries.vue'
import bar from '@/components/organisms/graph/bar.vue'
import timeseriesDetailLoading from './timeseries-detail-loading'
import selectColumnBox from '@/components/organisms/trained-ai-common/select-column-box'
import timeseriesMetrics from './detail/timeseries-metrics.vue'

const dateDef = {
  sec: 1000,
  min: 1000 * 60,
  hour: 1000 * 60 * 60,
  day: 1000 * 60 * 60 * 24,
  week: 1000 * 60 * 60 * 24 * 7,
  month: 1000 * 60 * 60 * 24 * 31,
  year: 1000 * 60 * 60 * 24 * 365
}

export default {
  components: {
    texts,
    textWithIcon,
    transitionToggleContents,
    timeseries,
    bar,
    timeseriesDetailLoading,
    selectColumnBox,
    timeseriesMetrics
  },
  data() {
    return {
      displayType: 'result',
      themeColor: '#890491',
      subColor: '#049185',
      prediction: [],
      trend: [],
      changepoints: [],
      weekly: [],
      yearly: [],
      seasonalities: {},
      seasonalityGraphs: [],
      selectedItem: '', // MFTransformerには精度評価画面が存在しないので、TrendFlowの場合のみを考慮
      loadingTabContents: true,
      changeDispType: false // ツールチップの再読み込み用フラグ
    }
  },
  mounted() {
    this.prepareTimeSeriesGraph(this.targetResult.forecast)
    const configColumn = this.trainedAi?.trainConfig?.predictionColumn ?? []
    if (configColumn.length === 0) return
    this.selectedItem = configColumn[this.selectedColumnIndex]
    this.$nextTick(() => {
      // 初回の時系列の描画完了まで待機する
      this.loadingTabContents = false
    })
  },
  props: {
    trainedAi: Object,
    finishColumns: Array,
    loadChangeColumn: Boolean,
    selectedColumnIndex: Number
  },
  computed: {
    seasonalityNames() {
      if (!this.targetResult) return []
      if (!this.targetResult.layer_info) return []
      const seasonalityList = []
      for (let i = 0; i < this.targetResult.layer_info.length; i++) {
        const layer = this.targetResult.layer_info[i]
        if (layer.name === 'TrendFlow') {
          const params = layer.params
          const seasonalities = params.seasonality.value
          seasonalities.forEach((v) => {
            const name = v.seasonalityName.value
            const timeUnit = v.seasonalityTimeUnit.value
            const period = v.seasonalityPeriod.value
            seasonalityList.push(name)
            this.seasonalities[name] = {
              list: [],
              timeUnit: timeUnit,
              period: period
            }
          })
          break
        }
      }
      return seasonalityList
    },
    acf() {
      if (this.targetResult?.acf) {
        return this.targetResult.acf
      } else {
        return []
      }
    },
    pacf() {
      if (this.targetResult?.pacf) {
        return this.targetResult.pacf
      } else {
        return []
      }
    },
    targetResult() {
      return this.trainedAi.result
    },
    checkTimeseriesColumn() {
      if (this.trainedAi.trainConfig.predictionColumn.length <= 1) return true
      const target = this.selectedItem
      return this.trainedAi.trainConfig.predictionColumn.indexOf(target) !== -1
    }
  },
  methods: {
    displayTypeClick(e) {
      this.displayType = e
      this.changeDispType = true
      this.$nextTick(() => {
        this.changeDispType = false
      })
    },
    getGraph(name, data) {
      return [{ name: name, values: data, color: this.themeColor }]
    },
    prepareTimeSeriesGraph(forecast) {
      if (forecast && forecast.length > 0) {
        const prediction = []
        const observations = []
        const changepoints = []
        const trend = []
        let weekly = []
        let yearly = []
        const UnixTimeList = Object.keys(this.targetResult.observations)
        let beforeIndex = 0
        this.resetItems()
        forecast.forEach((d, index) => {
          const tz = new Date().getTimezoneOffset() * 60000
          const date = new Date(d.ds + tz) // UTC
          prediction.push({
            x: date,
            y: d.yhat,
            y_lower: d.yhat_lower,
            y_upper: d.yhat_upper
          })
          const ob = this.targetResult.observations[d.ds]
          if (ob != null) {
            observations.push({
              x: date,
              y: ob,
              y_lower: ob,
              y_upper: ob
            })
          }
          const delta = this.targetResult.changepoints[d.ds]
          if (delta) {
            changepoints.push({
              x: date,
              y: delta,
              y_lower: delta,
              y_upper: delta
            })
          } else {
            changepoints.push({
              x: date,
              y: 0,
              y_lower: 0,
              y_upper: 0
            })
          }
          trend.push({
            x: date,
            y: d.trend,
            y_lower: d.trend_lower,
            y_upper: d.trend_upper
          })
          const currentIndex = UnixTimeList.indexOf(String(d.ds))

          let diffWeek = 0
          if (weekly.length > 0) {
            diffWeek = weekly[weekly.length - 1].x - weekly[0].x
          }
          if (currentIndex - beforeIndex > 1) {
            weekly = []
          } else if ('weekly' in d && diffWeek <= dateDef.week) {
            weekly.push({
              x: date,
              y: d.weekly,
              y_lower: d.weekly_lower,
              y_upper: d.weekly_upper
            })
          }
          let diffYear = 0
          if (yearly.length > 0) {
            diffYear = yearly[yearly.length - 1].x - yearly[0].x
          }
          if (currentIndex - beforeIndex > 1) {
            yearly = []
          } else if ('yearly' in d && diffYear <= dateDef.year) {
            yearly.push({
              x: date,
              y: d.yearly,
              y_lower: d.yearly_lower,
              y_upper: d.yearly_upper
            })
          }
          this.seasonalityNames.forEach((name) => {
            const timeUnit = this.seasonalities[name].timeUnit
            const period = this.seasonalities[name].period
            let diff = 0
            let graphList = this.seasonalities[name].list
            if (graphList.length > 0) {
              diff = graphList[graphList.length - 1].x - graphList[0].x
            }
            if (currentIndex - beforeIndex > 1) {
              graphList = []
            } else if (graphList && diff <= dateDef[timeUnit] * period) {
              this.seasonalities[name].list.push({
                x: date,
                y: d[name],
                y_lower: d[name],
                y_upper: d[name]
              })
            }
          })
          beforeIndex = currentIndex
        })
        const fixPrediction = prediction.filter((value) => {
          return value.y != null
        })
        this.prediction = [
          {
            label: this.$i18n.t('graph.timeseries.predictions'),
            data: fixPrediction,
            color: this.themeColor
          },
          {
            label: this.$i18n.t('graph.timeseries.observations'),
            data: observations,
            color: this.subColor
          }
        ]
        this.trend.push({
          label: this.$i18n.t('graph.timeseries.timeseriesTrendGraph'),
          data: trend,
          color: this.themeColor
        })
        this.changepoints.push({
          label: this.$i18n.t('graph.timeseries.changepoints'),
          data: changepoints,
          color: this.themeColor
        })
        if (weekly.length > 0) {
          this.weekly = [
            {
              label: this.$i18n.t('graph.timeseries.timeseriesWeeklyGraph'),
              data: weekly,
              color: this.themeColor
            }
          ]
        }
        if (yearly.length > 0) {
          this.yearly = [
            {
              label: this.$i18n.t('graph.timeseries.timeseriesYearlyGraph'),
              data: yearly,
              color: this.themeColor
            }
          ]
        }

        for (const name in this.seasonalities) {
          const graphData = [
            {
              label: name,
              data: this.seasonalities[name].list,
              color: this.themeColor
            }
          ]
          const obj = {
            graphData: graphData,
            timeUnit: this.seasonalities[name].timeUnit
          }
          this.seasonalityGraphs.push(obj)
        }
      }
    },
    seasonalityGrapTarget(key) {
      return this.seasonalityGraphs.find((graph) => {
        return graph.graphData.find((item) => item.label === key)
      }).graphData
    },
    resetItems() {
      this.prediction = []
      this.trend = []
      this.changepoints = []
      this.weekly = []
      this.yearly = []
      this.seasonalities = {}
      this.seasonalityGraphs = []
    }
  },
  watch: {
    loadChangeColumn(newVal) {
      if (newVal) {
        this.resetItems()
      } else {
        this.prepareTimeSeriesGraph(this.targetResult.forecast)
      }
    },
    selectedItem(newVal) {
      this.$emit('change-column', newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.trained-ai-timeseries {
  overflow: hidden;
  display: grid;
  grid-template-columns: adjustVW(320) 1fr;
  grid-template-rows: 100%;
  grid-column-gap: $space-medium;
  width: 100%;
  height: 100%;
  padding: 0 $space-medium $space-medium;
  &-side {
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-medium;
  }
  &-display-type {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-height: adjustVH(536);
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    &-item {
      width: 100%;
      padding: $space-base $space-sub;
      margin: 0 0 $space-base;
      background: transparent;
      border-radius: 9in;
      &:last-child {
        margin: 0;
      }
      &-active {
        background: $key-lite;
        &:hover {
          opacity: 1;
        }
      }
    }
    &-list {
      overflow: auto;
      padding: 0 $space-base 0 0;
      @include scrollbar;
    }
    &-list-loading {
      height: adjustVH(300);
    }
  }
  &-accuracys {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    &-list {
      overflow: auto;
      padding: 0 $space-min 0 0;
      @include scrollbar;
    }
    &-item {
      margin: 0 0 $space-small;
      &:last-child {
        margin: 0;
      }
    }
  }
  &-graph {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    &-inner {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
  &-autocorrelation {
    margin: 0 0 $space-large;
  }
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-sub {
      margin-bottom: $space-base;
    }
  }
  &-select {
    &-inner {
      margin-bottom: $space-medium;
    }
  }
  &-metrics {
    flex-shrink: 0;
  }
}
.graph {
  &-wrap {
    height: 100%;
    margin: 0 0 $space-large;
    &:last-of-type {
      margin: 0;
    }
  }
  &-inner {
    height: 100%;
  }
  &-none {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &-text {
      text-align: center;
      white-space: pre-line;
    }
  }
}
</style>
