<script>
import Basis from './basis'

function legend() {
  const THIS = function (sel) {
    if (!THIS.targetScale || !THIS.targetScale.domain) return
    const g = sel.selectAll('.legend-entry').data(THIS.targetScale.domain())

    g.enter()
      .append('g')
      .classed('legend-entry', true)
      .call(function (entry, i, nodes) {
        entry
          .append('rect')
          .attr('x', 0)
          .attr('y', 10)
          .attr('width', 10)
          .attr('height', 10)
          .style('fill', function (d, i) {
            return THIS.targetScale(d)
          })
        entry
          .append('text')
          .attr('x', 12)
          .attr('y', 20)
          .text(function (d, i) {
            return d
          })
          .attr('class', 'textselected')
          .style('text-anchor', 'start')
          .style('font-size', 15)
      })

    g.call(function (entry) {
      entry.select('rect').style('fill', function (d, i) {
        return THIS.targetScale(d)
      })
      entry.select('text').text(function (d, i) {
        return d
      })
    })

    g.exit().remove()

    sel
      .selectAll('.legend-entry')
      .data(THIS.targetScale.domain())
      .attr('transform', function (d, i, nodes) {
        let accumValue = 0
        for (let j = 0; j < i; j++) {
          accumValue += nodes[j].getBBox().width
        }
        const sum = accumValue + THIS.padding * i + THIS.paddingFirst
        return `translate(${sum},0)`
      })
  }
  THIS.padding = 20
  THIS.paddingFirst = 8
  THIS.scale = function (scale) {
    THIS.targetScale = scale
    return THIS
  }

  return THIS
}

export default {
  mixins: [Basis],
  mounted() {
    this.initializeLegend()
  },
  updated() {
    this.recalcLegend()
  },
  props: {
    showLegends: {
      type: Boolean,
      default: true
    },
    legendOrient: {
      type: String,
      default: 'horizontal'
    }
  },
  data() {
    return {
      glegend: null
    }
  },
  computed: {
    legend() {
      return legend().scale(this.realColor)
    }
  },
  methods: {
    initializeLegend() {
      this.glegend = this.svg
        .append('g')
        .classed('legends', true)
        .attr('y', 20)
        .call(this.legend)
    },
    recalcLegend() {
      this.glegend
        .style('display', this.showLegends ? 'visible' : 'none')
        .call(this.legend)
    }
  },
  watch: {
    showLegends() {
      this.recalcLegend()
    },
    legend() {
      this.recalcLegend()
    }
  }
}
</script>
