<template>
  <div class="list-top">
    <button class="list-top-button" @click="$emit('show-trained-ai-top')">
      <text-with-icon
        class="list-top-title"
        :text="$t('training.result.otherTrainedAiList')"
        iconName="backward"
        size="title"
      />
    </button>
    <div class="list-top-layout-menu-wrap">
      <div
        class="list-top-layout-menu-inner"
        :class="
          layoutType === 'grid' ? 'list-top-layout-menu-inner-active' : ''
        "
      >
        <icons
          iconName="grid"
          isButton
          class="list-top-layout-menu-icon"
          :color="layoutType === 'grid' ? 'emphasis' : 'default'"
          @icon-click="$emit('layout-menu-click', 'grid')"
        />
      </div>
      <div
        class="list-top-layout-menu-inner"
        :class="
          layoutType === 'list' ? 'list-top-layout-menu-inner-active' : ''
        "
      >
        <icons
          iconName="list"
          isButton
          class="list-top-layout-menu-icon"
          :color="layoutType === 'list' ? 'emphasis' : 'default'"
          @icon-click="$emit('layout-menu-click', 'list')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import textWithIcon from '@/components/molecules/text-with-icon.vue'
export default {
  components: {
    icons,
    textWithIcon
  },
  props: {
    layoutType: String
  }
}
</script>

<style lang="scss" scoped>
.list-top {
  padding: $space-medium $space-medium 0;
  &-button:hover {
    opacity: 1;
  }
  &-title {
    margin: 0 0 $space-medium;
    cursor: pointer;
  }
  &-layout-menu {
    &-wrap {
      display: flex;
    }
    &-inner {
      position: relative;
      width: adjustVW(48);
      height: adjustVW(48);
      margin: 0 $space-base 0 0;
      background: $background-sub;
      border-radius: 9in;
      transition: all $transition-base;
      &:last-child {
        margin: 0;
      }
      &:hover {
        background: $medium-gray;
      }
      &-active {
        background: $key-lite;
        &:hover {
          background: $key-lite;
        }
      }
    }
    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &:hover ::v-deep button {
        opacity: 1;
      }
    }
  }
}
</style>
