<template>
  <div class="result-wrap" :data-tutorial-optimization-result="4">
    <div
      class="result"
      :class="{
        'result-show-detail': showDetail !== null
      }"
      @mouseup="setEvent"
    >
      <div class="result-body">
        <texts
          class="result-title"
          :text="$t('trainedAi.optimization.table.result.title')"
          size="small"
          color="gray"
        />
        <div class="result-pos" :data-tutorial-optimization-result="0">
          <optimization-table-inner
            :data-tutorial-optimization-result="1"
            :table="result.optimizations"
            :columns="optimizationColumns"
            :filters="filters"
            :columnRanges="columnRanges"
            :scrollTop="scrollTop"
            :optimizationConditions="optimizationConditions"
            :showDetailIndex="showDetail"
            :loadPaging="loadPaging"
            type="optimization"
            @click-adjust="clickAdjust"
            @click-list="clickList"
            @get-target-height="getTargetHeight"
            @set-target-hide="setTargetHide"
            @change-filter-value="$emit('change-filter-value', $event)"
          />
        </div>
      </div>
      <div class="result-split" />
      <div class="result-body">
        <texts
          class="result-title result-title-conditions"
          :text="$t('trainedAi.optimization.table.conditions.title')"
          size="small"
          color="gray"
        />
        <div class="result-pos" :data-tutorial-optimization-result="2">
          <optimization-table-inner
            :table="result.conditions"
            :columns="conditionsColumns"
            :filters="filters"
            :columnRanges="columnRanges"
            :scrollTop="scrollTop"
            :optimizationConditions="optimizationConditions"
            :loadPaging="loadPaging"
            type="conditions"
            @click-adjust="clickAdjust"
            @change-filter-value="$emit('change-filter-value', $event)"
          />
        </div>
      </div>
      <transition-toggle-contents>
        <div v-if="showDetail !== null" key="detail" class="result-detail">
          <optimization-table-detail
            :resultOptimization="result.optimizations[showDetail]"
            :resultConditions="result.conditions[showDetail]"
            :optimizationConditions="optimizationConditions.conditions"
            @close="closeDetail"
          />
        </div>
      </transition-toggle-contents>
      <div
        ref="dummyItem"
        class="result-dummy"
        :class="{
          'result-dummy-wait': waitSetHeight,
          'result-dummy-on': !waitSetHeight
        }"
        :style="{
          '--height': scrollWrapHeight + 'px'
        }"
        @scroll="scrollTable"
        @mousedown="removeEvent"
      >
        <div
          class="result-dummy-inner"
          :style="{
            '--height': scrollInnerHeight + 'px'
          }"
        />
        <transition-toggle-fade>
          <div
            v-if="loadPaging"
            class="result-dummy-loading"
            :style="{
              '--height': scrollWrapHeight + 'px'
            }"
          >
            <loading-icon />
          </div>
          <div
            v-else-if="noneItem"
            class="result-dummy-loading"
            :style="{
              '--height': scrollWrapHeight + 'px'
            }"
          >
            <texts
              class="result-dummy-none"
              :text="$t('trainedAi.optimization.table.noneItem')"
              size="small"
              color="gray"
            />
          </div>
        </transition-toggle-fade>
      </div>
    </div>
    <div v-if="total > limit" class="paging-wrap">
      <paging
        :value="inPageNumber"
        :count="total"
        :pagingCount="limit"
        :isDisabled="loadPaging"
        @input="changePage($event)"
      />
    </div>
    <div class="result-download">
      <button @click="showTutorialPopup">
        <text-with-icon
          class="result-help"
          size="min"
          :text="$t('trainedAi.optimization.tutorial.button')"
          iconName="info"
          color="gray"
        />
      </button>
      <icon-button
        v-tooltip="downloadTips"
        iconName="download"
        size="min"
        @click="$emit('download-result', encodingType)"
      />
      <select-box
        v-model="encodingType"
        class="result-download-input"
        isGray
        min
        :items="downloadTypes"
      />
    </div>
    <optimization-result-tutorial
      :showTutorial="showTutorial"
      @close-tutorial="closeTutorial"
    />
  </div>
</template>

<script>
import iconButton from '@/components/atoms/icon-button'
import loadingIcon from '@/components/atoms/loading-icon'
import paging from '@/components/molecules/paging.vue'
import selectBox from '@/components/molecules/select-box'
import textWithIcon from '@/components/molecules/text-with-icon'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import transitionToggleFade from '@/components/molecules/transition-toggle-fade'
import optimizationTableInner from '@/components/organisms/optimization/optimization-table-inner'
import optimizationTableDetail from '@/components/organisms/optimization/optimization-table-detail'
import optimizationResultTutorial from '@/components/organisms/optimization/optimization-result-tutorial'

function isWindows() {
  return window.navigator.userAgent.toLowerCase().indexOf('windows') >= 0
}

export default {
  components: {
    iconButton,
    loadingIcon,
    paging,
    selectBox,
    textWithIcon,
    transitionToggleContents,
    transitionToggleFade,
    optimizationTableInner,
    optimizationTableDetail,
    optimizationResultTutorial
  },
  data() {
    return {
      scrollWrapHeight: 0,
      scrollInnerHeight: 0,
      showDetail: null,
      scrollTop: 0,
      waitSetHeight: false,
      encodingType: isWindows() ? 'cp932' : 'utf8',
      showTutorial: false
    }
  },
  props: {
    accountInfo: Object,
    result: Object,
    optimizationConditions: Object,
    limit: Number,
    total: Number,
    inPageNumber: Number,
    loadPaging: Boolean,
    loadDetail: Boolean,
    filters: Array,
    columnRanges: Array,
    download: Object
  },
  computed: {
    optimizationColumns() {
      if (
        !this.optimizationConditions ||
        !this.optimizationConditions?.conditions?.optimizationColumns
      )
        return []
      return this.optimizationConditions.conditions.optimizationColumns
    },
    conditionsColumns() {
      if (
        !this.columnRanges ||
        this.columnRanges.length === 0 ||
        !this.optimizationColumns
      )
        return []
      const target = this.columnRanges.filter((column) => {
        return !this.optimizationColumns.includes(column.name)
      })
      return target.map((column) => {
        return column.name
      })
    },
    downloadTypes() {
      return [
        {
          id: 0,
          name: 'Windows (Shift-JIS)',
          value: 'cp932'
        },
        {
          id: 1,
          name: 'Mac (UTF-8)',
          value: 'utf8'
        }
      ]
    },
    downloadTips() {
      let tips = ''
      if (this.download?.downloadComp) {
        tips = this.$t('trainedAi.optimization.download.comp')
      } else if (this.download?.downloading) {
        tips = this.$t('trainedAi.optimization.download.loading')
      } else {
        tips = this.$t('trainedAi.optimization.download.wait')
      }
      return {
        content: tips,
        hideOnTargetClick: false
      }
    },
    noneItem() {
      if (!this.result || Object.keys(this.result).length === 0) return false
      return (
        this.result.conditions.length === 0 &&
        this.result.optimizations.length === 0
      )
    }
  },
  methods: {
    getTargetHeight({ wrapHeight, innerHeight }) {
      this.scrollWrapHeight = wrapHeight
      this.scrollInnerHeight = innerHeight
      this.$nextTick(() => {
        this.waitSetHeight = false
      })
    },
    setTargetHide() {
      this.waitSetHeight = true
    },
    scrollTable(e) {
      this.scrollTop = e.target.scrollTop
    },
    removeEvent() {
      this.$refs.dummyItem.style['pointer-events'] = 'none'
    },
    setEvent() {
      this.$refs.dummyItem.style['pointer-events'] = 'auto'
    },
    clickList(index) {
      this.showDetail = index
    },
    clickAdjust() {
      this.closeDetail()
    },
    closeDetail() {
      this.showDetail = null
    },
    changePage(page) {
      const payload = {
        id: this.optimizationConditions.id,
        page: page
      }
      this.showDetail = null
      this.$emit('change-page', payload)
    },
    closeTutorial() {
      this.showTutorial = false

      this.$emit('close-optimization-result-tutorial')
    },
    showTutorialPopup() {
      this.showTutorial = true
    },
    scrollHandler() {
      const windowHeight = window.innerHeight
      const docHeight = document.documentElement.offsetHeight
      if (window.scrollY >= docHeight - windowHeight) {
        this.showTutorial = true
        window.removeEventListener('scroll', this.scrollHandler)
      }
    }
  },
  mounted() {
    const tutorialInfo = this.accountInfo.tutorialInfo
    const checkOptimizationResultTutorial = tutorialInfo.optimizationResult

    if (!checkOptimizationResultTutorial) {
      window.addEventListener('scroll', this.scrollHandler)
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler)
  }
}
</script>

<style lang="scss" scoped>
.result {
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(0, 100%) adjustVW(18) minmax(0, 100%);
  width: 100%;
  height: 100%;
  &-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  &-show-detail {
    .result-dummy {
      width: calc(50% - adjustVW(9));
    }
  }
  &-body {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &-dummy {
    --height: 0;
    position: absolute;
    bottom: $space-base;
    overflow-y: scroll;
    width: 100%;
    height: calc(var(--height) - $space-base * 2 - adjustVW(48));
    @include scrollbar;
    &-inner {
      width: 100%;
      height: var(--height);
    }
    &-wait {
      opacity: 0;
    }
    &-on {
      animation: fade 0.3s;
    }
    &-loading {
      --height: 0;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: calc(var(--height) - $space-base * 2 - adjustVW(48));
      background-color: #fff;
      z-index: $z-optimization-result-base - 1;
    }
    &-none {
      text-align: center;
      white-space: pre-line;
    }
  }
  &-title {
    flex-shrink: 0;
    margin-bottom: adjustVW(4);
    &-conditions {
      margin-left: $space-small;
    }
  }
  &-pos {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  &-split {
    align-self: self-end;
    width: adjustVW(18);
    height: calc(100% - adjustVW(24));
    background-image: linear-gradient(
      to bottom,
      #c8e7e4,
      #c8e7e4 adjustVW(8),
      transparent adjustVW(8),
      transparent adjustVW(4)
    );
    background-repeat: repeat-y;
    background-position: 50% 0;
    background-size: adjustVW(2) adjustVW(14);
  }
  &-detail {
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    width: calc(50% - adjustVW(9));
    height: 100%;
    padding-left: $space-sub;
    background-color: #fff;
    z-index: $z-optimization-result-base - 1;
  }
  &-download {
    position: absolute;
    top: adjustVW(-6);
    right: adjustVW(-6);
    display: flex;
    grid-column-gap: $space-sub;
    z-index: $z-optimization-result-base - 1;
    &-input {
      width: adjustVW(188);
      height: adjustVW(32);
      &::v-deep {
        .c-func-select-button-text {
          font-size: $text-min;
          @include text-crop;
        }
      }
    }
  }
  &-help {
    margin-right: $space-base;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.paging-wrap {
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  margin-top: $space-sub;
  margin-bottom: -$space-text;
}
</style>
