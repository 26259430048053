<template>
  <card-base class="c-card">
    <div class="preprocess-columns-wrapper">
      <div class="preprocess-columns-item preprocess-columns-title">
        <texts
          size="large"
          :text="
            this.$i18n.t('inference.settings.selectDataset.preprocessedColumns')
          "
        />
      </div>

      <bullet-list class="preprocess-columns-scroll" :items="columnsItems" />
    </div>
  </card-base>
</template>

<script>
import bulletList from '@/components/molecules/bullet-list.vue'
import cardBase from '@/components/atoms/card-base.vue'
import texts from '@/components/atoms/text.vue'

export default {
  components: {
    bulletList,
    cardBase,
    texts
  },
  props: {
    columns: Array
  },
  computed: {
    columnsItems() {
      return this.columns.map((item) => {
        return {
          text: item
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.c-card {
  max-height: calc(50% - #{$space-sub});
}

.preprocess-columns {
  &-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  &-scroll {
    overflow-y: auto;
    @include scrollbar;
  }
  &-item {
    width: 100%;
    margin-bottom: $space-small;
  }
}

.preprocess-columns-title {
  margin-bottom: $space-small;
}
</style>
