<template>
  <div class="wrap">
    <div class="main">
      <texts
        class="main-title"
        :text="$t('trainedAi.optimization.tips.title')"
        color="green"
      />
      <texts
        class="main-desc"
        :text="$t('trainedAi.optimization.tips.desc')"
        size="small"
        color="gray"
      />
    </div>
    <div class="image">
      <images imageName="optimization" />
    </div>
  </div>
</template>

<script>
import images from '@/components/atoms/images'

export default {
  components: {
    images
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  grid-column-gap: $space-small;
  width: adjustVW(676);
  padding: $space-small $space-small adjustVW(20);
  background: $background;
  border-radius: adjustVW(16);
  box-shadow: $box-shadow-hover;
}

.main {
  &-title {
    margin-bottom: $space-min;
  }
  &-desc {
    margin-bottom: $space-small;
    white-space: pre-line;
  }
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: adjustVW(12);
}
</style>
