<template>
  <div
    class="wrap"
    :class="{ 'wrap-none-effects': !projectDetail.expectedEffect }"
  >
    <project-template-detail-top
      class="page-top"
      :title="projectDetail.title"
      @page-back="$emit('page-back')"
    />
    <project-template-detail-body
      v-bind="projectDetail"
      :datasetId="datasetId"
      :loadingDatasets="loadingDatasets"
      @select-project="$emit('select-project')"
    />
  </div>
</template>

<script>
import projectTemplateDetailTop from '@/components/organisms/project-template-detail/project-template-detail-top'
import projectTemplateDetailBody from '@/components/organisms/project-template-detail/project-template-detail-body'

export default {
  components: {
    projectTemplateDetailTop,
    projectTemplateDetailBody
  },
  props: {
    datasetId: String,
    projectDetail: Object,
    loadingDatasets: Boolean
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 0 $space-medium adjustVW(120 - 24);
  &-none-effects {
    padding: 0 $space-medium $space-medium;
  }
}
.page-top {
  margin: 0 0 $space-base;
}
</style>
