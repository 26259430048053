var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"title"},[_c('texts',{attrs:{"text":_vm.$t('training.popup.progress.title', { type: _vm.displayTypeText }),"size":"large"}}),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.disabledStopTips),expression:"disabledStopTips"}],class:{
        'button-disabled': _vm.checkDisabled
      },attrs:{"disabled":_vm.checkDisabled},on:{"click":function($event){_vm.checkDisabled ? '' : _vm.$emit('training-stop')}}},[_c('texts',{staticClass:"title-button",class:{ 'title-button-disabled': _vm.checkDisabled },attrs:{"text":_vm.$t('training.popup.progress.stopButton', { type: _vm.displayTypeText }),"color":_vm.checkDisabled ? 'gray' : 'caution'}})],1)],1),_c('div',{staticClass:"list"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-inner"},[_c('transition',{attrs:{"name":"toggle-icon","mode":"out-in"}},[(_vm.status === 'preparingEnvironment')?_c('loading-icon',{key:"environment",staticClass:"icon",attrs:{"size":"small","color":"emphasis"}}):(_vm.status === 'stoppingTraining')?_c('icons',{key:"stop",staticClass:"icon",attrs:{"size":"small","color":"default","iconName":"close"}}):(_vm.status === 'training')?_c('icons',{key:"train",staticClass:"icon",attrs:{"size":"small","color":"emphasis","iconName":"check"}}):_vm._e()],1),_c('texts',{staticClass:"text-pre-line",attrs:{"text":_vm.$t('training.popup.progress.environment', {
              type: _vm.displayTypeText
            }),"color":_vm.status === 'stoppingTraining' ? 'default' : 'emphasis'}})],1)]),(!_vm.hideTrainStatus)?[_c('transition',{attrs:{"name":"toggle-item","mode":"out-in"}},[(
            _vm.status !== 'stoppingTraining' ||
              (_vm.optimizationNumIter > 0 && _vm.status === 'stoppingTraining')
          )?_c('div',{key:"train",staticClass:"item",class:{ 'item-disabled': _vm.status === 'preparingEnvironment' }},[_c('div',{staticClass:"item-inner"},[_c('transition',{attrs:{"name":"toggle-icon","mode":"out-in"}},[(_vm.progress !== _vm.maxProgress)?_c('loading-icon',{staticClass:"icon",attrs:{"size":"small","color":"emphasis"}}):_c('icons',{staticClass:"icon",attrs:{"size":"small","color":"emphasis","iconName":"check"}})],1),_c('div',{staticClass:"item-inner-column"},[_c('texts',{staticClass:"item-inner-column-shrink",attrs:{"text":_vm.trainingColumnStatus
                    ? _vm.trainingColumnStatus.columnName
                    : _vm.$t('training.popup.progress.training'),"color":"emphasis"}}),(_vm.trainingColumnStatus)?_c('texts',{staticClass:"item-inner-column-text",attrs:{"text":_vm.$t('training.popup.progress.trainingColumn'),"color":"emphasis"}}):_vm._e()],1),_c('texts',{staticClass:"item-inner-progress",attrs:{"text":((_vm.progress / _vm.maxProgress) * 100).toFixed() + '%',"color":"emphasis"}}),(_vm.trainingColumnStatus)?_c('texts',{attrs:{"text":_vm.$t('training.result.multiResult.columnProgress', {
                  progress: _vm.trainingColumnStatus.count,
                  progressMax: _vm.trainingColumnStatus.max
                }),"color":"emphasis"}}):_vm._e()],1),_c('div',{staticClass:"item-inner"},[_c('texts',{attrs:{"text":_vm.$t('training.popup.progress.trainingTime')}}),_c('texts',{attrs:{"text":_vm.formatTime(_vm.timeCount)}})],1)]):(_vm.optimizationNumIter === 0)?_c('div',{key:"stop",staticClass:"item"},[_c('div',{staticClass:"item-inner"},[_c('loading-icon',{staticClass:"icon",attrs:{"size":"small","color":"emphasis"}}),_c('texts',{attrs:{"text":_vm.$t('training.popup.progress.stop', { type: _vm.displayTypeText }),"color":"emphasis"}})],1)]):_vm._e()])]:_vm._e(),(_vm.optimizationNumIter > 0)?[_c('transition',{attrs:{"name":"toggle-item","mode":"out-in"}},[(_vm.status !== 'stoppingTraining')?_c('div',{key:"optimization",staticClass:"item",class:{ 'item-disabled': _vm.status === 'preparingEnvironment' }},[_c('div',{staticClass:"item-inner"},[_c('transition',{attrs:{"name":"toggle-icon","mode":"out-in"}},[(_vm.optimizationProgress !== _vm.optimizationNumIter)?_c('loading-icon',{staticClass:"icon",attrs:{"size":"small","color":"emphasis"}}):_c('icons',{staticClass:"icon",attrs:{"size":"small","color":"emphasis","iconName":"check"}})],1),_c('div',{staticClass:"item-inner-column"},[_c('texts',{staticClass:"item-inner-column-shrink",attrs:{"text":_vm.$t('training.popup.progress.optimization'),"color":"emphasis"}})],1),_c('texts',{attrs:{"text":(
                  (_vm.optimizationProgress / _vm.optimizationNumIter) *
                  100
                ).toFixed() + '%',"color":"emphasis"}})],1),_c('div',{staticClass:"item-inner"},[_c('texts',{attrs:{"text":_vm.$t('training.popup.progress.optimizationing')}}),_c('texts',{attrs:{"text":_vm.formatTime(_vm.optimizationTimeCount)}})],1)]):_c('div',{key:"stop",staticClass:"item"},[_c('div',{staticClass:"item-inner"},[_c('loading-icon',{staticClass:"icon",attrs:{"size":"small","color":"emphasis"}}),_c('texts',{attrs:{"text":_vm.$t('training.popup.progress.stop', { type: _vm.displayTypeText }),"color":"emphasis"}})],1)])])]:_vm._e()],2),_c('div',{staticClass:"graph",class:{ 'graph-disabled': _vm.status !== 'training' || _vm.notShowGraph }},[_c('graph-bar-count',{staticClass:"graph-inner",attrs:{"maxCount":_vm.graphMax,"count":_vm.graphProgress}})],1),_c('transition-toggle-contents',[(_vm.status !== 'stoppingTraining')?_c('div',{staticClass:"desc-wrap"},[_c('div',{staticClass:"desc-item"},[_c('texts',{staticClass:"desc-item-text",attrs:{"text":_vm.trainingTips.first,"size":"small","color":"gray"}})],1),_c('div',{staticClass:"desc-item"},[_c('texts',{staticClass:"desc-item-text",attrs:{"text":_vm.trainingTips.second,"size":"small","color":"gray"}}),_c('div',{staticClass:"desc-item-img"},[_c('icons',{attrs:{"iconName":"trainedAi","size":"40","color":"green"}})],1)],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }