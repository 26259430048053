<template>
  <transition-toggle-contents>
    <!--
      TODO 推論実行時のテキストマイニングの結果取得実装後修正
    <text-mining-result
      v-if="showTextMining"
      :textMiningResult="textMiningResult"
      :textMiningSetting="textMiningSetting"
      @load-text-mining="$emit('load-text-mining', $event)"
      @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
    />
    -->
    <div class="inference-classification-result">
      <div class="inference-classification-result-title-wrap">
        <texts
          class="inference-classification-result-title"
          :text="this.$i18n.t('inference.result.classification.title')"
        />
        <paging
          v-model="inPageNumber"
          class="inference-regression-result-main-card-paging"
          :count="allItems.length"
          :pagingCount="pagingCount"
        />
      </div>

      <div class="inference-classification-result-wrapper">
        <div class="inference-classification-result-main">
          <transition name="toggle-detail" mode="out-in">
            <classification-result-card-list
              v-if="!waitTable"
              :modelInfo="selectedTrainedAi"
              :targetType="targetType"
              :items="cropedItems ? cropedItems : cropItems[0]"
              :posBottomPopupBorder="posBottomPopupBorder"
            />
          </transition>
        </div>

        <div class="inference-classification-result-used-ai">
          <card-base class="inference-classification-result-used-ai-card">
            <model-info
              :title="this.$i18n.t('inference.result.card.usedAi')"
              :modelInfo="selectedTrainedAi"
            />
          </card-base>
        </div>
      </div>
    </div>
  </transition-toggle-contents>
</template>

<script>
import cardBase from '@/components/atoms/card-base.vue'
import classificationResultCardList from '@/components/organisms/inference/result/classfication/classification-result-card-list.vue'
// import textMiningResult from '@/components/organisms/inference/result/text-mining/text-mining-result'
import modelInfo from '@/components/organisms/model-info.vue'
import texts from '@/components/atoms/text.vue'
import Paging from '@/components/molecules/paging.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

export default {
  components: {
    cardBase,
    classificationResultCardList,
    // textMiningResult,
    modelInfo,
    texts,
    Paging,
    transitionToggleContents
  },
  data() {
    return {
      inPageNumber: 1,
      pagingCount: 10,
      waitTable: false,
      cropedItems: null
    }
  },
  props: {
    showTextMining: {
      type: Boolean,
      default: false
    },
    targetType: String,
    resultData: Object,
    inferenceInfo: Object,
    imageClassficationResult: Array,
    textClassificationResult: Array,
    selectedTrainedAi: Object,
    posBottomPopupBorder: Number,
    textMiningResult: Object,
    textMiningSetting: Object
  },
  computed: {
    allItems() {
      if (!this.imageClassficationResult || !this.textClassificationResult)
        return []
      return this.imageClassficationResult.length > 0
        ? this.imageClassficationResult
        : this.textClassificationResult
    },
    cropItems() {
      const items = JSON.parse(JSON.stringify(this.allItems))
      let cropTables = []
      if (items.length > this.pagingCount) {
        const length = Math.ceil(items.length / this.pagingCount)
        cropTables = new Array(length)
          .fill()
          .map((_, i) =>
            items.slice(i * this.pagingCount, (i + 1) * this.pagingCount)
          )
      } else {
        cropTables.push(items)
      }
      return cropTables
    }
  },
  methods: {},
  watch: {
    async inPageNumber(newVal) {
      this.waitTable = true
      this.cropedItems = await this.cropItems[newVal - 1]
      this.waitTable = false
    }
  }
}
</script>

<style lang="scss" scoped>
.inference-classification-result-wrapper {
  overflow: hidden;
  display: grid;
  grid-template-columns: adjustVW(1248) minmax(0, #{adjustVW(384)});
  grid-template-rows: auto;
  grid-column-gap: $space-base; // スクロールバーとスクロールバーにくっついたmarginが16px分なので
  height: 100%;
}

.inference-classification-result {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: $space-medium;
  &-main {
    overflow: hidden;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }

  &-used-ai {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    height: 100%;
    &-card {
      width: 100%;
      height: 100%;
    }
  }
}

.inference-classification-result-title {
  &-wrap {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    width: adjustVW(1248);
    padding: 0 $space-medium;
    margin-bottom: $space-small;
  }
}

.toggle-detail-enter-active,
.toggle-detail-leave-active {
  transition: transform $transition-base, opacity $transition-base;
}
.toggle-detail-enter,
.toggle-detail-leave-to {
  opacity: 0;
  transform: translateY($space-small);
  will-change: opacity, transform;
}
</style>
