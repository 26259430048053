<template>
  <div class="wrap">
    <form @submit.prevent>
      <inputBoxValidation
        v-model="accountPW.currentPassWord"
        class="text"
        popupView
        :popupText="
          $t('account.setting.info.popup.password.currentPassWordPopup')
        "
        :title="$t('account.setting.info.popup.password.myPassword')"
        isPass
        :placeholder="
          $t('account.setting.info.popup.password.currentPassWordPopup')
        "
        :value="accountPW.currentPassWord"
        :error="changePassWordError === 'notMatchCurrentPassWord'"
      />
      <input-password @change="validPassword" />
      <!--
        パスワード作成成功,失敗後のポップアップ
      -->
      <div
        v-if="changePassWordError === 'notMatchCurrentPassWord'"
        class="text"
      >
        <text-box
          isError
          :text="$t('account.setting.info.popup.password.errorYourPassword')"
        />
      </div>
      <div v-if="success" class="text">
        <text-box
          :text="
            $t('account.setting.info.popup.password.changeSucceedPassword')
          "
        />
      </div>
      <!--
        ここまでがパスワード作成成功,失敗後のポップアップ
      -->
      <div class="button-wrap">
        <buttons
          :type="accountPW.currentPassWord ? 'emphasis' : 'disabled'"
          :isDisabled="!validCurrentPassWord"
          :text="$t('account.setting.info.popup.password.changePassword')"
          @click="changePassWord"
        />
        <buttons
          type="sub"
          :text="$t('account.setting.info.popup.password.cancel')"
          @click="$emit('close-modal', closePopup)"
        />
      </div>
    </form>
  </div>
</template>

<script>
import textBox from '@/components/atoms/text-box'
import buttons from '@/components/atoms/button-main'
import inputBoxValidation from '@/components/molecules/input-box-validation'
import inputPassword from '@/components/molecules/signup/input-password'

export default {
  components: {
    textBox,
    buttons,
    inputBoxValidation,
    inputPassword
  },
  data() {
    return {
      closePopup: this.showPopup,

      accountPW: {
        currentPassWord: '',
        newPW: {
          value: '',
          isOk: false
        }
      },

      changePassWordError: '',
      success: false
    }
  },
  computed: {
    validCurrentPassWord() {
      return this.accountPW.newPW.isOk
    }
  },
  props: {
    showPopup: Boolean,
    accountInfo: Object
  },
  methods: {
    validPassword(id) {
      this.accountPW.newPW.value = id.value
      this.accountPW.newPW.isOk = id.isOk
    },
    async changePassWord() {
      let res = null
      this.changePassWordError = ''
      this.success = false
      const request = {
        action: 'changePassWord',
        newPassWord: this.accountPW.newPW.value,
        currentPassWord: this.accountPW.currentPassWord,
        confirmPassWord: this.accountPW.newPW.value
      }
      res = await this.$sendMessageAndReceive(request)
      if (res.status === 'error') {
        this.changePassWordError = res.message // notMatchCurrentPassword
      } else {
        this.accountPW.currentPassWord = ''
        this.accountPW.newPW.value = ''
        this.success = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  position: relative;
  width: adjustVW(640);
  form {
    .text {
      margin-bottom: $space-small;
    }
  }
}
.text-wrap-current-password {
  margin-top: $space-medium;
}
.button-wrap {
  margin-top: $space-medium;
}
.button-success {
  min-width: adjustVW(252);
}
.button-close-modal {
  width: adjustVW(200);
}
.button-space {
  margin-right: $space-small;
}
</style>
