<template>
  <div class="no-accuracy-wrap">
    <div v-if="type === 'CLUSTERFLOW'" class="no-accuracy-content">
      <div class="clustering-title">
        <texts :text="$t('trainedAiList.clustering.title')" size="small" />
      </div>
      <div class="clustering-info">
        <text-with-title
          :title="$t('clustering.setting.result.maxClass')"
          :text="checkMaxClusterCount"
        />
        <text-with-title
          :title="$t('clustering.setting.result.technique')"
          :text="clusteringInfo.type"
        />
      </div>
    </div>
    <div v-else class="no-accuracy-content">
      <div>
        <texts
          :text="$t('trainedAiDetails.noAccuracy')"
          size="small"
          color="gray"
        />
      </div>
    </div>
    <div v-if="textMiningTypes.length > 0" class="no-accuracy-content">
      <div class="no-accuracy-title">
        <texts
          :text="$t('textMining.typeList.title')"
          size="small"
          color="gray"
        />
      </div>
      <div class="no-accuracy-list">
        <div
          v-for="(type, index) in textMiningTypes"
          :key="index"
          class="no-accuracy-item"
        >
          <text-with-icon
            :text="$t('trainedAi.textMining.types.' + type)"
            :iconName="type"
            size="small"
            color="emphasis"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import textWithIcon from '@/components/molecules/text-with-icon'
import textWithTitle from '@/components/molecules/text-with-title.vue'

export default {
  components: {
    textWithIcon,
    textWithTitle
  },
  props: {
    textMining: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: ''
    },
    clusteringInfo: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    textMiningTypes() {
      if (this.textMining == null || Object.keys(this.textMining).length === 0)
        return []
      return Object.entries(this.textMining.typeList).reduce(
        (prev, current) => {
          if (!current[1]) return prev
          prev.push(current[0])
          return prev
        },
        []
      )
    },
    checkMaxClusterCount() {
      if (
        this.clusteringInfo == null ||
        Object.keys(this.clusteringInfo).length === 0
      )
        return null
      const sort = Object.values(this.clusteringInfo.resultList).sort(
        (x, y) => {
          return y.nClusters - x.nClusters
        }
      )
      return sort[0].nClusters
    }
  }
}
</script>

<style lang="scss" scoped>
.no-accuracy {
  &-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-content {
    &:not(:last-of-type) {
      padding-bottom: $space-small;
      margin-bottom: $space-small;
      border-bottom: $border-sub;
    }
  }
  &-title {
    margin-bottom: $space-base;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: $space-medium;
    grid-row-gap: $space-sub;
  }
}

.clustering {
  &-title {
    margin-bottom: $space-sub;
  }
  &-info {
    display: flex;
    grid-column-gap: $space-medium;
  }
}
</style>
