<template>
  <!-- account-info-card list -->
  <div>
    <div class="wrap">
      <account-info-card
        v-bind="accountDetailCard"
        :isShowPopupChangeAdress="isShowPopupChangeAdress"
        :isShowPopupChangePassword="isShowPopupChangePassword"
        :isShowPopupOTP="isShowPopupOTP"
        :accountInfo="accountInfo"
        :accountInfoLoading="accountInfoLoading"
        @open-modal-nickname="openNicknameWindow"
        @open-modal-email="openEmailWindow"
        @open-modal-password="openPasswordWindow"
        @open-modal-onetime-password="openOTPWindow"
      />
      <account-info-usage-card
        :accountInfo="accountInfo"
        :accountInfoLoading="accountInfoLoading"
        :ownModels="ownModels"
        :ownServices="ownServices"
        :ownProjects="ownProjects"
      />
      <div>
        <account-info-coupon-toggle
          class="toggle-space"
          :accountInfo="accountInfo"
        />
        <account-info-detail-toggle />
      </div>
    </div>
    <!-- popup list -->
    <account-info-popup-email-modify-adress-card
      :showPopup="isShowPopupChangeAdress"
      :accountInfo="accountInfo"
      @close-modal="openEmailWindow"
    />
    <account-info-popup-change-password
      :showPopup="isShowPopupChangePassword"
      :accountInfo="accountInfo"
      @close-modal="openPasswordWindow"
    />
    <account-info-popup-OTP-card
      :showPopup="isShowPopupOTP"
      :accountInfo="accountInfo"
      @create="$emit('create')"
      @delete="$emit('delete')"
      @close-modal="openOTPWindow"
    />
    <account-info-popup-change-nickname
      :showPopup="isShowPopupNickname"
      :accountInfo="accountInfo"
      @create="$emit('create')"
      @delete="$emit('delete')"
      @close-modal="openNicknameWindow"
    />
  </div>
</template>

<script>
import accountInfoCard from '@/components/organisms/account-info/account-info-card'
import accountInfoUsageCard from '@/components/organisms/account-info/account-info-usage-card'
import accountInfoCouponToggle from '@/components/organisms/account-info/account-info-coupon-toggle'
import accountInfoDetailToggle from '@/components/organisms/account-info/account-info-detail-toggle'
import accountInfoPopupEmailModifyAdressCard from '@/components/organisms/account-info/account-info-popup-reset-email-adress-card'
import accountInfoPopupOTPCard from '@/components/organisms/account-info/account-info-popup-onetime-password'
import accountInfoPopupChangePassword from '@/components/organisms/account-info/account-info-popup-reset-password'
import accountInfoPopupChangeNickname from '@/components/organisms/account-info/account-info-popup-reset-nickname'

export default {
  components: {
    accountInfoCard,
    accountInfoUsageCard,
    accountInfoCouponToggle,
    accountInfoDetailToggle,
    accountInfoPopupEmailModifyAdressCard,
    accountInfoPopupOTPCard,
    accountInfoPopupChangePassword,
    accountInfoPopupChangeNickname
  },
  data() {
    return {
      isShowPopupChangeAdress: false,
      isShowPopupChangePassword: false,
      isShowPopupOTP: false,
      isShowPopupNickname: false
    }
  },
  props: {
    accountDetailCard: Object,
    accountInfo: Object,
    accountInfoLoading: Boolean,
    ownModels: Number,
    ownServices: Number,
    ownProjects: Number
  },
  methods: {
    openEmailWindow() {
      this.isShowPopupChangeAdress = !this.isShowPopupChangeAdress
    },
    openPasswordWindow() {
      this.isShowPopupChangePassword = !this.isShowPopupChangePassword
    },
    openOTPWindow() {
      this.isShowPopupOTP = !this.isShowPopupOTP
    },
    openNicknameWindow() {
      this.isShowPopupNickname = !this.isShowPopupNickname
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: grid;
  grid-template-columns: adjustVW(408) adjustVW(768) adjustVW(408);
  grid-column-gap: $space-medium;
}
.toggle-space {
  margin: 0 0 $space-medium;
}
</style>
