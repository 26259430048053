import { render, staticRenderFns } from "./distribution-graph-detail.vue?vue&type=template&id=f8102f80&scoped=true&"
import script from "./distribution-graph-detail.vue?vue&type=script&lang=js&"
export * from "./distribution-graph-detail.vue?vue&type=script&lang=js&"
import style0 from "./distribution-graph-detail.vue?vue&type=style&index=0&id=f8102f80&scoped=true&lang=css&"
import style1 from "./distribution-graph-detail.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8102f80",
  null
  
)

export default component.exports