<template>
  <toggle-card
    class="preprocess-menu-card-wrapper"
    :title="$t('selectTargetColumn.menu.goPreprocess.title')"
  >
    <div class="preprocess-menu-caution-massage">
      <texts
        class="preprocess-menu-caution-massage-main"
        size="min"
        :text="$t('selectTargetColumn.menu.goPreprocess.message')"
      />
      <texts
        size="min"
        color="gray"
        :text="$t('selectTargetColumn.menu.goPreprocess.caution')"
      />
    </div>

    <!-- <checkbox-base
      :text='$t("selectTargetColumn.menu.goPreprocess.checkbox")'
      @input='changeChekedValue'
    /> -->
    <button-main
      class="preprocess-menu-card-button"
      :type="loadingDatasetDetail ? 'disabled' : 'sub'"
      :isDisabled="loadingDatasetDetail"
      :text="$t('selectTargetColumn.menu.goPreprocess.button')"
      @click="goPreprocessPage"
    />
  </toggle-card>
</template>

<script>
import ButtonMain from '@/components/atoms/button-main.vue'
// import CheckboxBase from '@/components/atoms/checkbox-base.vue'
import Texts from '@/components/atoms/text.vue'
import ToggleCard from '@/components/molecules/toggle-card.vue'

export default {
  components: {
    ButtonMain,
    // CheckboxBase,
    Texts,
    ToggleCard
  },
  props: {
    loadingDatasetDetail: Boolean
  },
  data() {
    return {
      alwaysUsePreprocess: false
    }
  },
  methods: {
    changeChekedValue: function (val) {
      this.alwaysUsePreprocess = val
    },
    goPreprocessPage: function () {
      this.$emit('go-preprocess-page', this.alwaysUsePreprocess)
    }
  }
}
</script>

<style lang="scss" scoped>
.preprocess-menu-card-title {
  margin-bottom: $space-base;
}

.preprocess-menu-caution-massage {
  margin-bottom: $space-sub;

  &-main {
    margin: 0 0 $space-text;
    white-space: normal;
  }
}

.preprocess-menu-card-button {
  width: 100%;
}
</style>
