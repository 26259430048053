<template>
  <div class="c-wrap">
    <sidebar />

    <div class="c-body-wrap">
      <header-tabs v-bind="headerTabs" />
      <transition-page>
        <div class="c-body-inner">
          <change-plan-title class="change-plan-page-top" />
          <change-plan-main
            v-bind="body"
            :selectedPlan="selectedPlan"
            @active-item="activeItem"
            @change-plan="changePlan"
            @change-show-popup-state="changeShowPopupState"
            @return-top-page="returnTopPage"
          />
        </div>
      </transition-page>
    </div>

    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
  </div>
</template>

<script>
import HoverMenu from '@/components/organisms/hover-menu.vue'
import HeaderTabs from '@/components/organisms/header-tabs.vue'
import Sidebar from '@/components/organisms/sidebar.vue'
import transitionPage from '@/components/molecules/transition-page'

import ChangePlanMain from '@/components/organisms/change-plan/change-plan-main.vue'
import ChangePlanTitle from '@/components/organisms/change-plan/change-plan-title.vue'

export default {
  components: {
    ChangePlanMain,
    ChangePlanTitle,
    HoverMenu,
    HeaderTabs,
    Sidebar,
    transitionPage
  },
  props: {
    sidebar: Object,
    headerTabs: Object,
    body: Object,
    popup: Object,
    selectedPlan: String
  },
  methods: {
    activeItem: function ({ id }) {
      this.$emit('active-item', { id })
    },
    changePlan: function ({ planName }) {
      this.$emit('change-plan', { planName })
    },
    changeShowPopupState: function ({ componentName }) {
      this.$emit('change-show-popup-state', { componentName })
    },
    returnTopPage: function () {
      this.$emit('return-top-page')
    }
  }
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
}

.c-body-inner {
  display: grid;
  grid-template-rows: auto 1fr;
  height: calc(100vh - #{adjustVH($headerTabHeight)});
}

.change-plan-page-top {
  padding: 0 $space-medium;
}
</style>
