<template>
  <div class="clustering-select">
    <template v-if="!isInference">
      <div class="clustering-title">
        <texts
          :text="$t('clustering.result.displayedClassCount')"
          size="small"
          color="gray"
        />
      </div>
      <select-box-input
        class="clustering-input"
        :value="clusteringSetting.setting.resultId"
        :items="selectItems"
        isGray
        isSearch
        isScroll
        @select-item="$emit('select-class', $event)"
      />
    </template>
  </div>
</template>
<script>
import selectBoxInput from '@/components/molecules/select-box-input.vue'
export default {
  components: {
    selectBoxInput
  },
  props: {
    clusteringSetting: Object,
    selectItems: Array,
    isInference: Boolean
  }
}
</script>
<style lang="scss" scoped>
.clustering-select {
  &-title {
    margin-bottom: $space-base;
  }
  &-input {
    height: adjustVW(48);
  }
  &-select {
    max-width: adjustVW(280);
  }
}
</style>
