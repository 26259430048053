<template>
  <div class="wrap">
    <texts
      class="desc"
      :text="$t('projectTemplate.popup.projectDescription')"
      isShowAll
      size="small"
    />
    <input-box-validation
      v-model="project.name"
      class="input"
      :accept="validate.accept"
      :error="validate.error"
      :title="$t('projectTemplate.popup.input.name')"
      popupView
      isNameValidation
      :nameValidationSuggests="projectFormValidate.suggests"
    />
    <input-box
      v-model="project.description"
      class="input"
      :title="$t('projectTemplate.popup.input.description')"
      isTextArea
      isGray
    />
    <div class="tags">
      <tag-editable-list v-model="project.tags" edit isWhite />
    </div>
    <button-large
      class="button"
      :isDisabled="disabledSubmit || disabledClick"
      :text="$t('projectTemplate.popup.button')"
      @click.once="$emit('create-project', project)"
    />
  </div>
</template>

<script>
import buttonLarge from '@/components/atoms/button-large.vue'
import inputBox from '@/components/molecules/input-box.vue'
import inputBoxValidation from '@/components/molecules/input-box-validation.vue'
import tagEditableList from '@/components/molecules/tag-editable-list.vue'
import texts from '@/components/atoms/text.vue'

export default {
  components: {
    buttonLarge,
    inputBox,
    inputBoxValidation,
    tagEditableList,
    texts
  },
  data() {
    return {
      project: {
        name: this.title ?? '',
        description: this.summary.description ?? '',
        tags: this.summary.tags ?? ''
      },
      validate: {
        accept: null,
        error: null
      }
    }
  },
  props: {
    disabledClick: Boolean,
    projectFormValidate: Object,
    summary: Object,
    title: String
  },
  computed: {
    disabledSubmit: function () {
      if (this.project.name === '') return true
      if (this.validate.error) return true

      return false
    }
  },
  watch: {
    project: {
      deep: true,
      handler: function (val) {
        this.$emit('input-create-form', {
          type: 'project',
          form: val
        })
      }
    },
    projectFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    }
  },
  mounted: function () {
    this.$emit('input-create-form', {
      type: 'project',
      form: this.project
    })
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  width: adjustVW(640);
}
.desc {
  margin: 0 0 $space-small;
  white-space: pre-line;
}
.input {
  margin: 0 0 $space-medium;
}
.tags {
  margin: 0 0 $space-medium;
}
</style>
