<template>
  <div class="service-detail-edit-wrap">
    <div class="service-detail-edit-inner">
      <div class="service-detail-edit-title">
        <texts :text="$t('serviceDetail.editService')" size="large" />
      </div>
      <div class="service-detail-edit-block">
        <input-box-validation
          v-model="name"
          :accept="validate.accept"
          :error="validate.error"
          :title="$t('serviceDetail.serviceName')"
          popupView
          isNameValidation
          :nameValidationSuggests="serviceFormValidate.suggests"
        />
      </div>
      <div class="service-detail-edit-block">
        <input-box
          v-model="description"
          isTextArea
          isGray
          :title="$t('serviceDetail.serviceDetail')"
        />
      </div>
      <div class="service-detail-edit-button-block">
        <div class="service-detail-edit-button-inner">
          <button-main
            type="emphasis"
            :isDisabled="disabledSubmit"
            :text="$t('serviceDetail.button.save')"
            @click="save"
          />
        </div>
        <div class="service-detail-edit-button-inner">
          <button-main
            type="sub"
            :text="$t('common.cancel')"
            @click="$emit('cancel')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import inputBox from '@/components/molecules/input-box'
import inputBoxValidation from '@/components/molecules/input-box-validation'
import buttonMain from '@/components/atoms/button-main.vue'
import texts from '@/components/atoms/text'

export default {
  components: {
    texts,
    inputBox,
    inputBoxValidation,
    buttonMain
  },
  data() {
    return {
      name: this.service.name,
      description: this.service.description,
      validate: {
        accept: null,
        error: null
      }
    }
  },
  props: {
    service: Object,
    serviceFormValidate: Object
  },
  watch: {
    name: function (val) {
      this.$emit('input-edit-form', {
        type: 'service',
        form: {
          name: val
        }
      })
    },
    serviceFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    }
  },
  mounted() {
    this.$waitConnected()

    this.$emit('input-edit-form', {
      type: 'service',
      form: {
        name: this.name
      }
    })
  },
  methods: {
    save() {
      this.$emit('save', {
        serviceId: this.service.serviceId,
        type: this.type,
        name: this.name,
        description: this.description,
        trainedAIId: this.trainedAI
      })
    }
  },
  computed: {
    disabledSubmit: function () {
      if (this.name === '') return true
      if (this.validate.error) return true

      return false
    }
  }
}
</script>
<style lang="scss" scoped>
.service-detail-edit {
  &-wrap {
    width: 100%;
    height: 100%;
    padding: $space-small $space-base;
  }
  &-inner {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding: $space-base $space-small;
    @include scrollbar;
  }
  &-block {
    width: adjustVW(882);
    margin: 0 0 $space-medium;
    &-list {
      display: flex;
      width: adjustVW(882);
      margin-bottom: $space-medium;
    }
    &-item {
      width: calc(50% - #{$space-sub});
      margin-right: $space-medium;
      &:last-of-type {
        margin: 0;
      }
      &-input {
        height: auto;
      }
    }
  }
  &-title {
    margin: 0 0 $space-medium;
  }
  &-inner-title {
    margin: 0 0 $space-base;
  }
  &-button {
    &-block {
      display: flex;
    }
    &-inner {
      margin: 0 $space-small 0 0;
      &:last-child {
        margin: 0;
      }
    }
  }
  &-preproc-input {
    width: adjustVW(882);
    margin-bottom: $space-medium;
  }
}
</style>
