<template>
  <div class="copy-box">
    <texts :text="text" size="default" />
    <input ref="target" type="hidden" :value="text" >
    <div class="box-inner">
      <icon
        v-tooltip="tooltip"
        iconName="clipboard"
        isButton
        @icon-click="copy"
      />
    </div>
  </div>
</template>

<script>
import icon from '@/components/atoms/icon.vue'
import texts from '@/components/atoms/text'

export default {
  components: {
    texts,
    icon
  },
  props: {
    /** 表示・コピーするテキスト */
    text: {
      type: String
    },
    /** trueにするとツールチップが下側に表示される */
    isTipsBottom: Boolean
  },
  data() {
    return {
      isTipsShow: false
    }
  },
  computed: {
    tooltip() {
      return {
        content: this.$t('common.tooltip.copied'),
        trigger: ['manual'],
        show: this.isTipsShow,
        delay: { show: 500, hide: 500 }
      }
    }
  },
  methods: {
    copy() {
      this.isTipsShow = true
      this.$refs.target.type = 'text'
      this.$refs.target.select()
      document.execCommand('Copy')
      this.$refs.target.type = 'hidden'
      setTimeout(() => {
        this.isTipsShow = false
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.copy-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $space-base $space-small;
  background: $background-sub;
  border-radius: adjustVW(8);
  .box-inner {
    position: relative;
    margin-left: $space-sub;
  }
  .tips {
    position: absolute;
    bottom: -150%;
    width: fit-content;
    height: fit-content;
    z-index: 1;
    transform: translate(-50%, 0);
    &-bottom {
      bottom: calc(-100% - #{$space-large});
      height: auto;
    }
  }
}
</style>
