var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"preprocessing-detail-side-wrap"},[(!_vm.isPreprocessingEditable)?_c('div',[_c('div',{staticClass:"preprocessing-detail-side-summary"},[_c('div',{staticClass:"preprocessing-detail-side-title"},[_c('texts',{attrs:{"text":_vm.name,"size":"large"}})],1),_c('div',{staticClass:"preprocessing-detail-side-desc"},[_c('description-long',{attrs:{"text":_vm.description ? _vm.description : ''}})],1),_c('button',{staticClass:"preprocessing-detail-side-edit",class:{ 'preprocessing-detail-side-edit-disabled': _vm.disabledEdit },attrs:{"disabled":_vm.disabledEdit},on:{"click":function($event){_vm.isPreprocessingEditable = true}}},[_c('text-with-icon',{attrs:{"text":_vm.$t('common.editButton'),"iconName":"edit","size":"small"}})],1)]),_c('div',{staticClass:"preprocessing-detail-side-info"},[_c('div',{staticClass:"preprocessing-detail-side-item-block"},[_c('text-with-title',{staticClass:"preprocessing-detail-side-item-text",attrs:{"text":_vm.accountId,"title":_vm.$t('common.owner')}})],1),(_vm.service && _vm.service.length > 0)?_c('div',{staticClass:"\n          preprocessing-detail-side-item-block\n          preprocessing-detail-side-item-block-list\n        "},[_c('div',{staticClass:"preprocessing-detail-side-item-title"},[_c('texts',{attrs:{"text":_vm.$t('preprocessing.useSevise'),"color":"gray","size":"small"}})],1),_c('div',{staticClass:"preprocessing-detail-side-item-list"},_vm._l((_vm.service),function(item,index){return _c('div',{key:index,staticClass:"preprocessing-detail-side-item-list-item"},[_c('router-link',{attrs:{"to":{
                name: 'serviceProjectDetail',
                params: {
                  id: item.link,
                  projectId: _vm.projectId,
                  serviceType: item.serviceType
                }
              }}},[_c('text-with-icon',{staticClass:"preprocessing-detail-side-item-text",attrs:{"text":item.name,"iconName":"newTab","pos":"right","color":"link-default"}})],1)],1)}),0)]):_vm._e(),_c('div',{staticClass:"preprocessing-detail-side-item-block"},[_c('div',{staticClass:"preprocessing-detail-side-item-half"},[_c('text-with-title',{staticClass:"preprocessing-detail-side-item-text",attrs:{"title":_vm.$t('common.createTime')}},[_c('a-time',{attrs:{"value":_vm.createTime}})],1)],1),_c('div',{staticClass:"preprocessing-detail-side-item-half"},[_c('text-with-title',{staticClass:"preprocessing-detail-side-item-text",attrs:{"title":_vm.$t('common.updateTime')}},[_c('a-time',{attrs:{"value":_vm.updateTime}})],1)],1)])])]):_c('div',{staticClass:"preprocessing-detail-side-summary-edit"},[_c('div',{staticClass:"preprocessing-detail-side-summary-edit-title"},[_c('input-box-validation',{attrs:{"accept":_vm.validate.accept,"error":_vm.validate.error,"popupView":"","isNameValidation":"","nameValidationSuggests":_vm.preprocFormValidate.suggests},model:{value:(_vm.editedName),callback:function ($$v) {_vm.editedName=$$v},expression:"editedName"}})],1),_c('div',{staticClass:"preprocessing-detail-side-summary-edit-desc"},[_c('input-box',{attrs:{"isTextArea":"","isGray":""},model:{value:(_vm.editedDescription),callback:function ($$v) {_vm.editedDescription=$$v},expression:"editedDescription"}})],1),_c('div',{staticClass:"preprocessing-detail-side-summary-edit-button-list"},[_c('div',{staticClass:"preprocessing-detail-side-summary-edit-button-save"},[_c('button-main',{staticClass:"preprocessing-detail-side-summary-edit-button-save-button",attrs:{"type":"emphasis","isDisabled":this.disabledSubmit,"text":_vm.$t('common.saveButton')},on:{"click":_vm.updateEdit}})],1),_c('div',{staticClass:"preprocessing-detail-side-summary-edit-button-cancel"},[_c('button-main',{attrs:{"size":"min","text":_vm.$t('common.cancel')},on:{"click":_vm.closelEdit}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }