<template>
  <div class="wrap">
    <texts
      class="wrap-description"
      size="small"
      :text="$t('projectTemplate.selectMode.popup.description')"
    />
    <div class="wrap-input">
      <input-box-validation
        v-model="projectName"
        isNameValidation
        popupView
        :accept="validate.accept"
        :error="validate.error"
        :nameValidationSuggests="projectFormValidate.suggests"
        :placeholder="
          $t('projectTemplate.selectMode.popup.projectNamePlaceholder')
        "
        :title="$t('projectTemplate.selectMode.popup.name')"
      />
    </div>

    <div class="wrap-input">
      <input-box
        v-model="projectDescription"
        isTextArea
        isGray
        :placeholder="
          $t('projectTemplate.selectMode.popup.projectDescriptionPlaceholder')
        "
        :title="$t('projectTemplate.selectMode.popup.projectDescription')"
      />
    </div>

    <div class="wrap-tag">
      <tag-editable-list v-model="projectTags" edit isWhite />
    </div>
    <button-large
      type="emphasis"
      class="wrap-button"
      :text="$t('projectTemplate.selectMode.popup.createProject')"
      :isDisabled="!validated || submitDisabled"
      @click="submit"
    />
  </div>
</template>

<script>
import buttonLarge from '@/components/atoms/button-large.vue'
import inputBox from '@/components/molecules/input-box.vue'
import inputBoxValidation from '@/components/molecules/input-box-validation.vue'
import tagEditableList from '@/components/molecules/tag-editable-list.vue'
import texts from '@/components/atoms/text.vue'

export default {
  components: {
    buttonLarge,
    inputBox,
    inputBoxValidation,
    tagEditableList,
    texts
  },
  props: {
    projectFormValidate: Object,
    submitDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      projectName: '',
      projectDescription: '',
      projectTags: [],
      validate: {
        accept: null,
        error: null
      }
    }
  },
  computed: {
    projectForm() {
      return {
        name: this.projectName,
        description: this.projectDescription,
        tags: this.projectTags
      }
    },
    validated() {
      if (this.projectName === '') return false
      if (!this.validate.accept) return false

      return true
    }
  },
  watch: {
    projectFormValidate: {
      deep: true,
      handler: function (obj) {
        const { duplicate } = obj

        this.validate.accept = duplicate === undefined ? null : duplicate
        this.validate.error = duplicate === undefined ? null : !duplicate
      }
    },
    projectForm: {
      deep: true,
      handler: function (project) {
        this.$emit('input-create-form', {
          type: 'project',
          form: project
        })
      }
    }
  },
  methods: {
    submit() {
      this.$emit('create-project', {
        name: this.projectName,
        description: this.projectDescription,
        tags: this.projectTags
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  width: adjustVW(640);
  &-text {
    margin: 0 0 $space-small;
  }
  &-description {
    position: relative;
    bottom: $space-base;
    margin: 0 0 $space-base 0;
    white-space: pre;
  }
  &-input {
    margin: 0 0 $space-medium;
  }
  &-tag {
    margin: 0 0 $space-medium;
  }
  &-button {
    width: 100%;
  }
}
</style>
