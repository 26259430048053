<template>
  <div class="change-plan-top-title-wrapper">
    <button @click="pageBack()">
      <text-with-icon size="title" :text="title" :iconName="iconName" />
    </button>
  </div>
</template>

<script>
import textWithIcon from '@/components/molecules/text-with-icon'

export default {
  components: {
    textWithIcon
  },
  data() {
    return {
      title: this.$i18n.t('changePlan.title'),
      iconName: 'backward'
    }
  },
  methods: {
    pageBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.change-plan-top-title-wrapper {
  height: adjustVH(52);
  margin-top: $space-medium;
  margin-bottom: $space-medium;
  background: $background-sub;
  box-shadow: none;
  z-index: $z-page-top;
  > button {
    &:hover {
      opacity: 1;
    }
  }
}
</style>
