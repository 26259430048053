<template>
  <transition-toggle-contents>
    <div v-if="!datasetTableLoading" class="dataset-inner">
      <template v-if="rawSamples !== null">
        <div>
          <texts
            class="dataset-title"
            :text="$t('projectDetail.datasetUsed')"
            size="large"
          />
          <router-link
            class="dataset-link"
            :to="{ name: 'datasetDetail', params: { id: dataset.id } }"
          >
            <text-with-icon
              class="dataset-name"
              :text="dataset.name"
              iconName="newTab"
              pos="right"
            />
          </router-link>
        </div>
        <div class="dataset-table">
          <table-main
            v-if="dataset.type === 'structured' && checkRawSamples"
            :tableColumns="checkRawSamples.tableColumns"
            :table="checkRawSamples.table"
            :pagingCount="5"
            hiddenGraph
          />
          <model-dataset-unstructured
            v-else-if="dataset.type === 'unstructured'"
            v-bind="checkRawSamples"
            :nLabels="nLabels"
          />
        </div>
      </template>
      <div v-else class="dataset-not-found">
        <images class="dataset-not-found-image" imageName="notFound" />
        <texts :text="$t('projectDetail.dataset.notFound')" />
      </div>
    </div>
    <loading v-else />
  </transition-toggle-contents>
</template>

<script>
import texts from '@/components/atoms/text.vue'
import images from '@/components/atoms/images.vue'
import loading from '@/components/atoms/loading.vue'
import tableMain from '@/components/molecules/table-main'
import textWithIcon from '@/components/molecules/text-with-icon'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import ModelDatasetUnstructured from './model-dataset-unstructured.vue'

export default {
  components: {
    texts,
    images,
    loading,
    tableMain,
    textWithIcon,
    TransitionToggleContents,
    ModelDatasetUnstructured
  },
  data() {
    return {
      rawSamples: null,
      datasetTableLoading: true,
      unstructuredField: {
        images: [
          {
            id: 0,
            key: 'name',
            label: '列名',
            width: 160
          },
          {
            id: 1,
            key: 'count',
            label: 'データ件数',
            width: 160
          },
          {
            id: 1,
            key: 'sample',
            label: '画像',
            width: 404
          }
        ],
        texts: [
          {
            id: 0,
            key: 'name',
            label: '列名',
            width: 160
          },
          {
            id: 1,
            key: 'count',
            label: 'データ件数',
            width: 160
          },
          {
            id: 1,
            key: 'sample',
            label: 'テキスト',
            width: 404
          }
        ]
      },
      nLabels: 0,
      datasetNotFound: false
    }
  },
  props: {
    dataset: Object,
    rowsPerPage: Number
  },
  async mounted() {
    this.datasetTableLoading = true
    await this.fetchDetailData(this.dataset)
    this.datasetTableLoading = false
  },
  computed: {
    checkRawSamples() {
      if (this.rawSamples == null) return null
      if (this.rawSamples?.data_type === 'list') {
        return {
          tableColumns: Object.keys(this.rawSamples.detail.dtypes),
          table: this.rawSamples.list,
          type: this.rawSamples.data_type
        }
      } else if (
        this.rawSamples?.data_type === 'images' ||
        this.rawSamples?.data_type === 'texts'
      ) {
        const items = []
        let nLabelsCount = 0
        for (const [key, value] of Object.entries(this.rawSamples.labels)) {
          items.push({
            name: key,
            count: value.count,
            sample: value.datas
          })
          nLabelsCount = nLabelsCount + value.count
        }
        this.nLabelsCount(nLabelsCount)
        return {
          field: this.unstructuredField[this.rawSamples.data_type],
          items: items,
          type: this.rawSamples.data_type
        }
      }
      return null
    }
  },
  methods: {
    async fetchDetailData(data) {
      if (!data) return false
      if (data?.type === 'unstructured') {
        this.$emit('file-info', this.datasetInfo)
        const unstructuredRes = {
          action: 'getDataSample',
          dataId: data.id,
          accountId: data.accountId,
          offset: 0,
          limit: 3
        }
        const res = await this.$sendMessageAndReceive(unstructuredRes)
        if (res.status === 'error') {
          /** データの読み込みエラーの処理を書く */
          this.isDataLoadingError = true
          return
        }
        this.rawSamples = res
      } else if (data?.type === 'structured') {
        const structuredRes = {
          action: 'getData',
          dataId: data.id,
          accountId: data.accountId,
          type: 'unstructured',
          offset: 0,
          limit: this.rowsPerPage
        }
        const res = await this.$sendMessageAndReceive(structuredRes)
        this.rawSamples = res
      } else {
        this.log_info(
          "type is only 'structured' or 'unstructured'. Actual type = " +
            data.type
        )
        this.log_info(data)
        this.datasetNotFound = true
      }
    },
    nLabelsCount(count) {
      this.nLabels = count
    }
  }
}
</script>

<style lang="scss" scoped>
.dataset {
  &-title {
    margin: 0 0 $space-base;
  }
  &-inner {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &-link {
    display: inline-block;
    max-width: 100%;
  }
  &-name {
    width: fit-content;
    max-width: 100%;
    margin: 0 0 $space-sub;
  }
  &-table {
    overflow: hidden;
    flex-shrink: 1;
  }
  &-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    &-image {
      width: adjustVW(400);
      height: auto;
      margin-bottom: $space-large;
    }
  }
}
</style>
