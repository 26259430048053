<template>
  <accountInfo
    v-bind="data"
    :accountInfo="accountInfo"
    :accountInfoLoading="accountInfoLoading"
    :trainedAILoading="trainedAILoading"
    :ownModels="ownModels"
    :ownServices="ownServices"
    :ownProjects="ownProjects"
    @create="otpCreate"
    @delete="otpDelete"
  />
</template>

<script>
import accountInfo from '@/components/templates/account-info.vue'
import { mapGetters, mapActions } from 'vuex'
import setMountedTimer from '@/mixin/set-mounted-timer'

export default {
  components: {
    accountInfo
  },
  mixins: [setMountedTimer],
  beforeRouteEnter(to, from, next) {
    if (from.name === null) {
      next()
    } else {
      next(async (vm) => {
        await vm.$waitConnected()
        vm.data.loading = true

        await vm.fetchAccountInfo()

        vm.setI18nInfo({ language: vm.language })

        vm.data.loading = false
      })
    }
  },
  computed: {
    ...mapGetters('auth', ['accountInfo', 'accountInfoLoading']),
    ...mapGetters('project', ['projectList']),
    ...mapGetters('trainedAi', ['trainedAIs', 'trainedAILoading']),
    ...mapGetters('settings', ['language']),

    ownProjects() {
      if (
        this?.accountInfo?.accountId &&
        Object.keys(this.projectList).length > 0
      ) {
        return Object.values(this.projectList).filter(
          (p) => p.accountId === this.accountInfo.accountId
        ).length
      }
      return 0
    },
    ownModels() {
      return this?.accountInfo?.usedTrainedAI
    },
    ownServices() {
      return this?.accountInfo?.usedService
    }
  },
  data() {
    return {
      data: {
        body: {
          accountDetailCard: {
            i18n: 'ja'
          }
        },
        headerTabs: {
          // ヘッダーのタブ
          tabs: [],
          tabSelect: 1
        },
        hoverMenu: {
          help: {
            searchTarget: {
              name: 'アカウント情報・設定'
            },
            searchResult: [
              {
                id: 1,
                content:
                  '各種アップグレードプランの価格はどこに記載されていますか？',
                link: '#'
              },
              {
                id: 2,
                content: 'AIの新規作成はどのように進めればいいですか？',
                link: '#'
              },
              {
                id: 3,
                content:
                  '最も検索されている内容は、この検索フォームで最も検索されている５件を表示します。テキストは最大３行。',
                link: '#'
              },
              {
                id: 4,
                content:
                  '各種アップグレードプランの価格はどこに記載されていますか？',
                link: '#'
              },
              {
                id: 5,
                content:
                  '各種アップグレードプランの価格はどこに記載されていますか？'
              }
            ]
          },
          showHelp: false
        },
        loading: false
      }
    }
  },
  watch: {
    language: {
      handler: function (language) {
        this.setI18nInfo({ language })
      }
    }
  },
  methods: {
    ...mapActions('auth', ['setOtpEnabled', 'fetchAccountInfo']),
    ...mapActions('services', ['loadServiceList']),
    ...mapActions('trainedAi', ['fetchModelList']),

    otpCreate() {
      // ワンタイムパスワードの作成
      this.setOtpEnabled(true)
    },
    otpDelete() {
      // ワンタイムパスワードの削除
      this.setOtpEnabled(false)
    },
    setI18nInfo: function ({ language }) {
      this.$set(this.data.body.accountDetailCard, 'i18n', language)
    }
  }
}
</script>
