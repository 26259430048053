<template>
  <project-list
    :popup="popup"
    :sidebar="sidebar"
    :headerTabs="headerTabs"
    :projectList="projectList"
    :accountInfo="accountInfo"
    :projectLoading="projectLoading"
    :disableClick="disableClick"
    :isHelpTipsOpen="isHelpTipsOpen"
    @open-clone-project="openCloneProject($event)"
    @open-create-template="openCreateTemplate($event)"
    @open-delete-project="openDeleteProject($event)"
    @click-hidden-community-link="clickHiddenCommunityLink($event)"
    @clone-project="cloneProject($event)"
    @create-template="createTemplate($event)"
    @delete-project="deleteProject($event)"
    @close-modal="closePopup($event)"
    @stop-task="stopTaskPopup"
    @training-stop-confirm="stopTaskConfirm"
    @hide-help-tips="hideHelpTips"
    @delete-optimization-failed="deleteOptimizationFailed"
    @link-to-optimization-failed="linkToOptimizationFailed"
  />
</template>

<script>
import setMountedTimer from '@/mixin/set-mounted-timer'
import projectList from '@/components/templates/project-list.vue'
import { mapGetters, mapActions } from 'vuex'
import { splitRevFullId } from '@/lib/misc'

export default {
  components: {
    projectList
  },
  mixins: [setMountedTimer],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.$waitConnected()
      await vm.loadProjectList()
    })
  },
  mounted: async function () {
    await this.fetchAccountInfo()

    const tutorialInfo = this.accountInfo.tutorialInfo
    const tutorialFlag = tutorialInfo.projectList

    if (!tutorialFlag) {
      this.showPopup('tutorialMigrationProject')

      const newTutorialInfo = Object.assign({}, tutorialInfo, {
        projectList: true
      })
      const params = {
        accountId: this.accountInfo.accountId,
        ...newTutorialInfo
      }

      await this.updateTutorialInfo(params)
    }
  },
  data() {
    return {
      popup: {
        // ポップアップの情報
        showPopup: [], // 現在表示しているポップアップ
        cloneProject: { project: null },
        createTemplate: { project: null },
        deleteProject: { project: null },
        stopTask: null,
        deletingTask: false
      },
      submitDeleting: false,
      submitOther: false,
      isHelpTipsOpen: false,
      deleteItemloading: false
    }
  },
  computed: {
    ...mapGetters('project', ['projectList', 'projectLoading']),
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('trainedAi', ['trainedAIs']),
    sidebar() {
      return null
    },
    headerTabs() {
      return {
        tabs: [],
        tabSelect: 1
      }
    },
    countTrainedAI() {
      if (this.accountInfo && this.trainedAIs) {
        return Object.values(this.trainedAIs).map(
          (x) => x.accountId === this.accountInfo.accountId
        ).length
      }
      return null
    },
    disableClick() {
      return this.submitDeleting || this.submitOther || this.deleteItemloading
    }
  },
  methods: {
    ...mapActions('auth', ['fetchAccountInfo', 'updateTutorialInfo']),
    ...mapActions('project', {
      dispatchDeleteProject: 'deleteProject',
      loadProjectDetail: 'loadProjectDetail',
      loadProjectList: 'loadProjectList'
    }),
    ...mapActions('tasks', ['stopTask', 'getTasks']),
    ...mapActions('models', ['deleteModel']),

    stopTaskPopup(e) {
      this.popup.stopTask = e
      this.showPopup('stopTask')
    },
    async stopTaskConfirm(e) {
      await this.stopTask(e)
      this.closePopup('stopTask')
      this.getTasks()
    },
    showPopup(e) {
      this.popup.showPopup.push(e)
    },
    closePopup(e) {
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
    },
    async openCloneProject(e) {
      if (!this.accountInfo && !this.trainedAIs) return
      const remainingTrainedAIsCount =
        this.accountInfo.planDetail.numModel - this.countTrainedAI
      if (remainingTrainedAIsCount - e.numAIs >= 0) {
        this.popup.cloneProject.project = e

        // load services
        const projectId = e.id
        const serviceList = await Promise.all([
          this.$sendMessageAndReceive({
            action: 'listServicev2',
            serviceType: 'infer',
            projectId: projectId
          }).then((res) =>
            res.list.map((item) => ({ ...item, serviceType: 'infer' }))
          ),
          this.$sendMessageAndReceive({
            action: 'listServicev2',
            serviceType: 'learn',
            projectId: projectId
          }).then((res) =>
            res.list.map((item) => ({ ...item, serviceType: 'learn' }))
          ),
          this.$sendMessageAndReceive({
            action: 'listServicev2',
            serviceType: 'upload',
            projectId: projectId
          }).then((res) =>
            res.list.map((item) => ({ ...item, serviceType: 'upload' }))
          )
        ])
        this.popup.deleteProject.project.listServices = [].concat(
          ...serviceList
        )

        this.showPopup('cloneProject')
      } else {
        this.showPopup('overTrainedAIs')
      }
    },
    openCreateTemplate(e) {
      // 一時的に別ページでテンプレートを作成(デザイン変更の可能性あり)
      this.$router.push({
        name: 'projectTemplateCreate',
        params: {
          projectId: e.id
        }
      })

      // 本来の処理
      this.popup.createTemplate.project = e
      this.showPopup('createTemplate')
    },
    async openDeleteProject(e) {
      const projectId = e.id

      this.deleteItemloading = true

      // set project detail
      await this.loadProjectDetail(projectId)
      this.popup.deleteProject.project = this.projectList[projectId]

      // load services
      const serviceList = await Promise.all([
        this.$sendMessageAndReceive({
          action: 'listServicev2',
          serviceType: 'infer',
          projectId: projectId
        }).then((res) =>
          res.list.map((item) => ({ ...item, serviceType: 'infer' }))
        ),

        this.$sendMessageAndReceive({
          action: 'listServicev2',
          serviceType: 'learn',
          projectId: projectId
        }).then((res) =>
          res.list.map((item) => ({ ...item, serviceType: 'learn' }))
        ),

        this.$sendMessageAndReceive({
          action: 'listServicev2',
          serviceType: 'upload',
          projectId: projectId
        }).then((res) =>
          res.list.map((item) => ({ ...item, serviceType: 'upload' }))
        )
      ])

      this.popup.deleteProject.project.listServices = [].concat(...serviceList)

      this.deleteItemloading = false

      this.showPopup('deleteProject')
    },
    clickHiddenCommunityLink: async function (event) {
      const tutorialInfo = Object.assign({}, this.accountInfo.tutorialInfo, {
        communityLink: true
      })
      const params = {
        accountId: this.accountInfo.accountId,
        ...tutorialInfo
      }

      await this.updateTutorialInfo(params)
    },
    async cloneProject(e) {
      this.submitOther = true
      try {
        await this.$sendMessageAndReceive({
          action: 'projectClone',
          projectId: e.projectId
        })
        await this.loadProjectList()
      } finally {
        this.submitOther = false
      }
      this.closePopup('cloneProject')
    },
    async deleteProject() {
      this.submitDeleting = true
      try {
        await this.dispatchDeleteProject(
          parseInt(this.popup.deleteProject.project.id)
        )
      } finally {
        this.submitDeleting = false
      }
      this.popup.deleteProject.project = null
      this.closePopup('deleteProject')
    },
    async createTemplate(e) {
      this.submitOther = true
      try {
        await this.$sendMessageAndReceive({
          action: 'createProjectTemplate',
          ...e
        })
      } finally {
        this.submitOther = false
      }
      this.closePopup('createTemplate')
    },
    hideHelpTips() {
      this.isHelpTipsOpen = false
    },
    async deleteOptimizationFailed(items) {
      this.popup.deletingTask = true
      for await (const item of items) {
        const splitId = splitRevFullId(item.jobName)
        const payload = {
          modelId: splitId[1],
          accountId: splitId[0]
        }
        await this.deleteModel(payload)
      }
      await this.getTasks()
      this.popup.deletingTask = false
    },
    async linkToOptimizationFailed(item) {
      this.popup.deletingTask = true
      const splitId = splitRevFullId(item.jobName)
      const payload = {
        modelId: splitId[1],
        accountId: splitId[0]
      }
      await this.deleteModel(payload)

      this.$router.push({
        name: 'inferenceProject',
        params: {
          projectId: item.projectId
        },
        query: {
          isOptimization: true
        }
      })
    }
  },
  watch: {
    projectLoading(newVal) {
      const inSession = sessionStorage.getItem('firstDispHelpTips')
      if (!newVal && !inSession) {
        this.isHelpTipsOpen = true
        sessionStorage.setItem('firstDispHelpTips', true)
        window.setTimeout(
          function () {
            this.isHelpTipsOpen = false
          }.bind(this),
          8000
        )
      }
    }
  }
}
</script>
