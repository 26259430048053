import { duplicateChecker, duplicateSuggests } from './misc'

/**
 * @param { Array } serviceList
 * @param { Object } newService
 * @returns { Object }
 **/
export function serviceValidator(serviceList, newService) {
  const nameList =
    serviceList.length > 0 ? serviceList.map((service) => service.name) : []

  const newServiceName = newService.name

  const checkResult = duplicateChecker(nameList, newServiceName)
  let suggests = []
  if (!checkResult) {
    suggests = duplicateSuggests(nameList, newServiceName)
  }

  return {
    duplicate: checkResult,
    suggests: suggests
  }
}
