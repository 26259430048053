<template>
  <popup-array
    :popupDefs="popupDefs"
    :showPopup="showPopup"
    :noClose="disableClick || showPopup.includes('preventTrainingStatus')"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #deleteRecipe>
      <delete-recipe
        v-bind="deleteRecipe"
        :disableClick="disableClick"
        @delete-recipes="$emit('delete-recipes', $event)"
        @close-modal="$emit('close-modal', 'deleteRecipe')"
        @cancel="$emit('close-modal', 'deleteRecipe')"
      />
    </template>
    <template #intoProject>
      <into-project
        :type="type"
        :targets="intoProject.targets"
        :newProject="intoProject.newProject"
        :disableClick="disableClick"
        @cancel="$emit('close-modal', 'intoProject')"
        @add-to-project="$emit('add-to-project', $event)"
      />
    </template>
    <template #getRecipeTemplate>
      <get-recipe-template
        :recipeTemplates="recipeTemplates"
        :recipeLayers="recipeLayers"
        :loadingRecipeTemplates="loadingRecipeTemplates"
        :disableClick="disableClick"
        :customblockList="customblockList"
        @close-modal="$emit('close-modal', 'deleteService')"
        @cancel="$emit('close-modal', 'deleteService')"
        @select-recipe-template="$emit('select-recipe-template', $event)"
        @save-recipe-template="$emit('save-recipe-template', $event)"
      />
    </template>
    <template #selectRecipeError>
      <select-recipe-error
        :selectRecipeErrors="selectRecipeErrors"
        @close-modal="$emit('close-modal', 'selectRecipeError')"
      />
    </template>
    <template #preventTrainingStatus>
      <prevent-training-status @not-prevent="$emit('not-prevent')" />
    </template>
  </popup-array>
</template>

<script>
import { popupArray } from '@/components/molecules/popup'
import deleteRecipe from './popup/delete-recipe'
import getRecipeTemplate from './popup/get-recipe-template'
import IntoProject from '@/components/organisms/common-popups/into-project.vue'
import selectRecipeError from './popup/select-recipe-error'
import preventTrainingStatus from '@/components/organisms/common-popups/prevent-training-status.vue'

export default {
  components: {
    getRecipeTemplate,
    deleteRecipe,
    popupArray,
    IntoProject,
    selectRecipeError,
    preventTrainingStatus
  },
  computed: {
    type() {
      return this.$t('common.recipe')
    },
    popupDefs() {
      let intoProject
      if (this.intoProject?.newProject) {
        intoProject = {
          title: this.$t('common.popup.intoNewProject.title', {
            type: this.type
          })
        }
      } else {
        intoProject = {
          title: this.$t('common.popup.intoProject.title', { type: this.type })
        }
      }
      return {
        deleteRecipe: {
          title: this.$t('common.deleteConfirm')
        },
        getRecipeTemplate: {
          title: this.$t('recipeList.sampleRecipe.get'),
          maxHeight: true,
          maxWidth: true
        },
        intoProject,
        selectRecipeError: {
          title: this.$t('recipeDetail.selectRecipeErrors.title')
        },
        preventTrainingStatus: {
          title: this.$t('common.popup.preventTrainingStatus.title')
        }
      }
    }
  },
  props: {
    showPopup: Array,
    deleteRecipe: Object,
    recipeTemplates: Object,
    recipeLayers: Object,
    loadingRecipeTemplates: Boolean,
    intoProject: Object,
    selectRecipeErrors: Array,
    disableClick: Boolean,
    customblockList: Array
  }
}
</script>
