<template>
  <popup-array
    :showPopup="showPopup"
    :popupDefs="popupDefs"
    :noClose="disableClick"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #addNewData>
      <popup-add-dataset
        v-bind="addDataset"
        :accountInfo="accountInfo"
        :addNewData="addNewData"
        :showDatasource="showDatasource"
        :datasourceList="datasourceList"
        :datasetFormValidate="createInfo.datasetFormValidate"
        :fileInputValue="fileInputValue"
        :uploadLoading="uploadLoading"
        :resetDatasource="resetDatasource"
        :disableClick="disableClick"
        @on-file-input="$emit('on-file-input', $event)"
        @close-modal="$emit('close-modal', $event)"
        @change-tab="$emit('change-tab', [$event, { target: addNewData }])"
        @text-input="$emit($event)"
        @datasource-input="$emit('datasource-input', $event)"
        @show-table="$emit('show-table', $event)"
        @start-uploading="$emit('start-uploading', $event)"
        @finished-uploading="$emit('finished-uploading')"
        @import-dataset-loading="$emit('import-dataset-loading', $event)"
        @datasource-items="$emit('datasource-items', $event)"
        @do-import="$emit('do-import', $event)"
        @file-clear="$emit('file-clear')"
        @test-datasource="$emit('test-datasource', $event)"
        @new-datasource="$emit('new-datasource', $event)"
        @input-create-form="$emit('input-create-form', $event)"
        @get-and-set-sql-list="$emit('get-and-set-sql-list', $event)"
        @get-sql-list="$emit('get-sql-list', $event)"
        @show-sql-edit="$emit('show-sql-edit', $event)"
        @select-sql-preview-table="$emit('select-sql-preview-table', $event)"
        @set-datasource="$emit('set-datasource', $event)"
      />
      <div v-if="uploadLoading">
        <wait-setting-dataset
          :progressSettingDataset="progress"
          :progressSettingDatasetMax="maxProgress"
          :progressStatus="progressStatus"
          :uploadLearningDataWarnings="addDataset.uploadLearningDataWarnings"
          :isLargeCsv="isLargeCsv"
        />
      </div>
    </template>
    <template #deleteData>
      <popup-delete-data
        :targets="deleteData.targets"
        :disableClick="disableClick"
        @close-modal="$emit('close-modal', 'deleteData')"
        @delete-dataset="$emit('delete-dataset', $event)"
      />
    </template>
    <template #intoProject>
      <into-project
        :type="$t('common.dataset')"
        :targets="intoProject.targets"
        :newProject="intoProject.newProject"
        :disableClick="disableClick"
        @cancel="$emit('close-modal', 'intoProject')"
        @add-to-project="$emit('add-dataset-to-project', $event)"
      />
    </template>
    <template #showDatasource>
      <popup-show-datasource
        v-bind="showDatasource"
        :datasetFormValidate="createInfo.datasetFormValidate"
        :disableClick="disableClick"
        :importLoading="addDataset.importLoading"
        :importError="addDataset.importError"
        @close-modal="$emit('close-modal', $event)"
        @do-import="$emit('do-import', $event)"
        @input-create-form="$emit('input-create-form', $event)"
        @set-datasource="$emit('set-datasource', $event)"
        @show-table="$emit('show-table', $event)"
        @get-sql-list="$emit('get-sql-list', $event)"
        @show-sql-edit="$emit('show-sql-edit', $event)"
        @select-sql-preview-table="$emit('select-sql-preview-table', $event)"
      />
    </template>
    <template #resetDatasource>
      <popup-set-datasource
        :resetDatasource="resetDatasource"
        :datasourceFormValidate="createInfo.datasourceFormValidate"
        :disableClick="disableClick"
        @close-modal="$emit('close-modal', $event)"
        @input-create-form="$emit('input-create-form', $event)"
        @new-datasource="$emit('new-datasource', $event)"
        @test-datasource="$emit('test-datasource', $event)"
      />
    </template>
    <template #deleteDatasource>
      <popup-delete-datasource
        :targets="deleteDatasource.targets"
        :disableClick="disableClick"
        @close-modal="$emit('close-modal', 'deleteDatasource')"
        @delete-datasource="$emit('delete-datasource', $event)"
      />
    </template>
    <template #upperLimitTrainedAi>
      <popup-upper-limit-trained-ai
        @close-modal="$emit('close-modal', 'upperLimitTrainedAi')"
      />
    </template>
    <template #upperLimitDataSize>
      <popup-upper-limit-data-size
        :sizeInfo="addDataset.uploadLearningDataErrors.sizeInfo"
        @close-modal="$emit('close-modal', 'upperLimitDataSize')"
      />
    </template>
  </popup-array>
</template>

<script>
import popupAddDataset from './popup/add-dataset'
import popupShowDatasource from './popup/show-datasource'
import popupSetDatasource from './popup/set-datasource'
import popupDeleteDatasource from './popup/delete-datasource'
import popupDeleteData from './popup/delete-data.vue'
import popupUpperLimitTrainedAi from './popup/upper-limit-trained-ai.vue'
import popupUpperLimitDataSize from './popup/upper-limit-data-size.vue'
import PopupArray from '@/components/molecules/popup/popup-array.vue'
import IntoProject from '@/components/organisms/common-popups/into-project.vue'
import waitSettingDataset from '@/components/organisms/wait-setting-dataset'
import { mapGetters } from 'vuex'

export default {
  components: {
    popupAddDataset,
    popupShowDatasource,
    popupSetDatasource,
    popupDeleteDatasource,
    popupDeleteData,
    popupUpperLimitTrainedAi,
    popupUpperLimitDataSize,
    IntoProject,
    PopupArray,
    waitSettingDataset
  },
  props: {
    accountInfo: Object,
    showDatasource: Object,
    addNewData: Object,
    deleteData: Object,
    addDataset: Object,
    intoProject: Object,
    resetDatasource: Object,
    deleteDatasource: Object,
    showPopup: Array,
    uploadLearningDataErrors: Object,
    isLargeCsv: Boolean,

    copyInfo: Object,
    createInfo: Object,
    fileInputValue: Object,
    uploadLoading: Boolean,
    progress: Number,
    maxProgress: Number,
    progressStatus: String,
    disableClick: Boolean
  },
  computed: {
    ...mapGetters('datasource', ['datasourceList']),
    type() {
      return this.$t('common.dataset')
    },
    resetDatasourceTitle() {
      if (this.resetDatasource.resetData) {
        return this.$t('datasetList.popup.resetDatasource.resetDatasourceTitle')
      } else {
        return this.$t('datasetList.popup.resetDatasource.newDatasourceTitle')
      }
    },
    popupDefs() {
      let intoProject
      if (this.intoProject?.newProject) {
        intoProject = {
          title: this.$t('common.popup.intoNewProject.title', {
            type: this.type
          })
        }
      } else {
        intoProject = {
          title: this.$t('common.popup.intoProject.title', { type: this.type })
        }
      }
      return {
        showDatasource: {
          // 登録済みのデータソース一覧ポップアップ
          title: this.showDatasource.datasourceInfo.name,
          maxHeight: true
        },
        deleteData: {
          title: this.$t('datasetList.popup.deleteData.title')
        },
        copyData: {
          title: this.$t('datasetList.popup.copyData.title')
        },
        addNewData: {
          title: this.$t('datasetList.popup.addDataset.title'),
          maxHeight: true
        },
        resetDatasource: {
          title: this.resetDatasourceTitle,
          maxHeight: true
        },
        intoProject,
        deleteDatasource: {
          title: this.$t('datasetList.popup.deleteDatasource.title')
        },
        upperLimitTrainedAi: {
          title: this.$t('trainedAiList.upperLimit.title')
        },
        upperLimitDataSize: {
          title: this.$t('datasetList.popup.upperLimitDataSize.title')
        }
      }
    }
  }
}
</script>
