<template>
  <div class="sample-code">
    <div class="sample-code-tab-wrap">
      <tab-list
        :tabs="tabItems"
        :activeTab="activeTab"
        :showIcon="false"
        @change-tab="selectTab"
      />
    </div>
    <transition-toggle-contents>
      <div
        v-if="activeTab === 'sampleCode'"
        key="sampleCode"
        class="sample-code-select-box-wrap"
      >
        <div class="sample-code-select-box">
          <select-box v-model="selectedLanguage" :items="languageTabItems" />
          <div v-if="selectedLanguage === 'curl'" class="sample-code-comment">
            <texts
              class="sample-code-comment-inner"
              :text="$t('serviceDetail.sampleCode.curlDesc')"
              :size="12"
              color="gray"
            />
          </div>
        </div>
      </div>
      <div v-else key="dummy" />
    </transition-toggle-contents>
    <transition-toggle-contents>
      <div
        v-if="activeTab === 'sampleCode'"
        key="sampleCode"
        class="sample-code-tab-wrap-sample-code"
      >
        <div class="sample-code-wrap">
          <!-- requestHeaders -->
          <div class="sample-code-block">
            <div
              v-if="sampleCode"
              key="python"
              class="sample-code-inner-sample-code"
            >
              <code-block :baseCode="sampleCode" />
            </div>
          </div>
        </div>
        <div v-if="checkMultiPredictionColumn" class="sample-code-side-column">
          <texts
            class="sample-code-side-column-title"
            :text="$t('serviceDetail.selectedColumns')"
            :size="13"
            color="gray"
          />
          <div class="sample-code-side-column-wrap">
            <div class="sample-code-side-column-list">
              <texts
                v-for="(item, key) in trainedAiDetail.trainConfig
                  .predictionColumn"
                :key="key"
                class="sample-code-side-column-item"
                :text="item"
                size="min"
                noTips
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="activeTab === 'requestHeader'"
        key="requestHeader"
        class="sample-code-wrap"
      >
        <!-- requestHeaders -->
        <div class="sample-code-block">
          <div class="sample-code-inner">
            <div class="sample-code-item">
              <texts text="x-matrixflow-secretkey" />
              <texts text="string" size="small" color="gray" />
            </div>
            <div class="sample-code-item">
              <div class="sample-code-item-icon">
                <copy-box
                  class="sample-code-item-icon-copy"
                  :text="requestHeaders.secretKey"
                  isTipsBottom
                />
              </div>
            </div>
          </div>
          <div class="sample-code-inner">
            <div class="sample-code-item">
              <texts text="Content-Type" />
              <texts text="string" size="small" color="gray" />
            </div>
            <div class="sample-code-item">
              <texts :text="requestHeaders.contentType" />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="activeTab === 'requestBody'"
        key="requestBody"
        class="sample-code-wrap"
      >
        <!-- requestBody -->
        <div class="sample-code-block">
          <div class="sample-code-inner">
            <texts :text="requestBody.type" size="large" />
          </div>
          <div
            v-for="(item, index) in requestBody.items"
            :key="index"
            class="sample-code-inner"
          >
            <div class="sample-code-item">
              <texts :text="item.name" />
              <texts :text="item.type" size="small" color="gray" />
            </div>
            <div class="sample-code-item">
              <texts :text="item.contents" />
              <texts
                v-if="item.contentsSub"
                :text="item.contentsSub"
                size="small"
                color="gray"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="activeTab === 'responses'"
        key="responses"
        class="sample-code-wrap"
      >
        <!-- responses -->
        <div class="sample-code-block">
          <div class="sample-code-inner">
            <div class="sample-code-item">
              <texts :text="responses.type" />
            </div>
            <div class="sample-code-item sample-code-code">
              <code-block :baseCode="responses.code" />
            </div>
          </div>
        </div>
      </div>
    </transition-toggle-contents>
  </div>
</template>
<script>
import { isNatualLanguage } from '@/lib/inference.js'
import { serviceResponse } from '@/lib/service.js'
import platform from 'platform'

import texts from '@/components/atoms/text'
import tabList from '@/components/molecules/tab-list'
import codeBlock from '@/components/molecules/code-block'
import selectBox from '@/components/molecules/select-box'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import CopyBox from '@/components/molecules/copy-box.vue'

export default {
  components: {
    texts,
    tabList,
    codeBlock,
    selectBox,
    transitionToggleContents,
    CopyBox
  },
  data() {
    return {
      isTipsShow: false,
      activeTab: 'sampleCode',
      selectedLanguage: 'curl'
    }
  },
  props: {
    apiUrl: String,
    secretKey: String,
    service: Object,
    trainedAiDetail: Object
  },
  methods: {
    selectTab(e) {
      this.activeTab = e
    }
  },
  computed: {
    checkWindows() {
      return platform?.os?.family === 'Windows'
    },
    languages() {
      return ['curl', 'python']
    },
    tabItems() {
      return [
        {
          id: 0,
          name: this.$t('serviceDetail.sampleCode.sampleCode'),
          value: 'sampleCode'
        },
        {
          id: 1,
          name: 'RequestHeader',
          value: 'requestHeader'
        },
        {
          id: 2,
          name: 'RequestBody',
          value: 'requestBody'
        },
        {
          id: 3,
          name: 'Responses',
          value: 'responses'
        }
      ]
    },
    languageTabItems() {
      return [
        {
          id: 0,
          name: 'curl',
          value: 'curl'
        },
        {
          id: 1,
          name: 'python',
          value: 'python'
        }
      ]
    },
    trainedAIType() {
      if (!this.service || !this.service?.trainedAI) return null
      return this.service.trainedAI.type
    },
    sampleCode() {
      if (!this.trainedAIType) return null

      if (this.selectedLanguage === 'curl') {
        let params = '-F "file=@your_file.csv"'
        let timeseriesMultiFile = ''

        if (this.trainedAIType === 'DEEP' || this.checkNatualLanguage) {
          params = '-F "file=@your_file.zip"'
        } else if (this.trainedAIType === 'TIME') {
          if (this.checkTimeseriesMulti) {
            if (this.checkWindows) {
              params = `-H "Content-Type: application/json" -d @post.json`
              timeseriesMultiFile = `

${this.$t('serviceDetail.sampleCode.curlMultiDescFirst')}
${this.$t('serviceDetail.sampleCode.curlMultiDescSecond')}
- post.json
{
  "forecastTime": "7",
  "forecastTimeUnit": "day",
  "predictionColumn": ["${this.$t('common.columnName')}"]
}`
            } else {
              params = `-H "Content-Type: application/json" -d '{"forecastTime":"7","forecastTimeUnit":"day","predictionColumn":["${this.$t(
                'common.columnName'
              )}"]}'`
            }
          } else {
            params = `-d "forecastTime=7&forecastTimeUnit=day"`
          }
        } else if (this.checkMultiPredictionColumn) {
          params =
            params +
            ` -F 'json={"predictionColumn": ["${this.$t(
              'common.columnName'
            )}"]}'`
        }
        return `curl -X POST ${params} -H "x-matrixflow-secretkey: ${this.secretKey}" ${this.apiUrl} ${timeseriesMultiFile}`
      } else if (this.selectedLanguage === 'python') {
        let importDeclare = `import requests # ${this.$t(
          'serviceDetail.sampleCode.installInfo'
        )}
import pprint
`
        let params = 'files = {"file": open("your_file.csv", "rb")}'
        let payload = 'url, files=files, headers=headers'

        if (this.trainedAIType === 'DEEP' || this.checkNatualLanguage) {
          params = 'files = {"file": open("your_file.zip", "rb")}'
        } else if (this.trainedAIType === 'TIME') {
          if (this.checkTimeseriesMulti) {
            params = `params = {
  "forecastTime": "7",
  "forecastTimeUnit": "day",
  "predictionColumn": ["${this.$t('common.columnName')}"]
}`
          } else {
            params = `params = {
  "forecastTime": "7",
  "forecastTimeUnit": "day"
}`
          }
          payload = 'url, json=params, headers=headers'
        } else if (this.checkMultiPredictionColumn) {
          importDeclare = `import requests # ${this.$t(
            'serviceDetail.sampleCode.installInfo'
          )}
import pprint
import json
`
          params = `files = {
  "file": open("your_file.zip", "rb"),
  "json": (None, json.dumps({"predictionColumn": ["${this.$t(
    'common.columnName'
  )}"]}), 'application/json')
}`
        }

        return `${importDeclare}
url = "${this.apiUrl}"

headers = {
    "x-matrixflow-secretkey": "${this.secretKey}",
}

${params}

response = requests.post(${payload})

print("status_code:", response.status_code)

pprint.pprint(response.json())`
      }
      return null
    },
    requestHeaders() {
      const checkContentType =
        this.trainedAIType === 'TIME'
          ? 'application/json'
          : 'multipart/form-data'
      return {
        secretKey: `${this.secretKey}`,
        contentType: checkContentType // 使用する学習済みAIによって変更
      }
    },
    requestBody() {
      let items = {
        type: this.$t('serviceDetail.sampleCode.requestBody.title.file'),
        items: [
          {
            name: 'file',
            contents: this.$t(
              'serviceDetail.sampleCode.requestBody.items.file'
            ),
            contentsSub: 'csv',
            type: 'file'
          }
        ]
      }

      if (this.checkMultiPredictionColumn) {
        items.items.push({
          name: 'predictionColumn',
          contents: this.$t(
            'serviceDetail.sampleCode.requestBody.items.inferenceColumn'
          ),
          type: 'array'
        })
      }

      if (this.trainedAIType === 'DEEP') {
        items = {
          type: this.$t('serviceDetail.sampleCode.requestBody.title.file'),
          items: [
            {
              name: 'file',
              contents: this.$t(
                'serviceDetail.sampleCode.requestBody.items.file'
              ),
              contentsSub: 'zip, single_img',
              type: 'file'
            }
          ]
        }
      } else if (this.trainedAIType === 'TIME') {
        const itemsInner = [
          {
            name: 'forecastTime',
            contents: this.$t(
              'serviceDetail.sampleCode.requestBody.items.forecastTime'
            ),
            type: 'number'
          },
          {
            name: 'forecastTimeUnit',
            contents: this.$t(
              'serviceDetail.sampleCode.requestBody.items.forecastTimeUnit'
            ),
            contentsSub: 'sec, min, hour, day, week, month, year',
            type: 'string'
          }
        ]
        if (this.checkTimeseriesMulti) {
          itemsInner.push({
            name: 'predictionColumn',
            contents: this.$t(
              'serviceDetail.sampleCode.requestBody.items.inferenceColumn'
            ),
            type: 'array'
          })
        }
        items = {
          type: this.$t('serviceDetail.sampleCode.requestBody.title.params'),
          items: itemsInner
        }
      } else if (this.checkNatualLanguage) {
        items = {
          type: this.$t('serviceDetail.sampleCode.requestBody.title.file'),
          items: [
            {
              name: 'file',
              contents: this.$t(
                'serviceDetail.sampleCode.requestBody.items.file'
              ),
              contentsSub: 'zip, txt',
              type: 'file'
            }
          ]
        }
      }
      return items
    },
    responses() {
      let response = serviceResponse.classification
      if (this.checkNatualLanguage || this.trainedAIType === 'DEEP') {
        if (this.service.type === 'vectorization') {
          response = serviceResponse.classificationNotTableDataVectorization
        } else if (this.service.type === 'dimRed') {
          response = serviceResponse.classificationNotTableDataDimRed
        } else {
          response = serviceResponse.classificationNotTableData
        }
      } else if (this.trainedAIType === 'REGRESSION') {
        response = serviceResponse.regression
      } else if (this.trainedAIType === 'TIME') {
        response = serviceResponse.time
      } else if (this.trainedAIType === 'TIME_TRANSFORMER') {
        response = serviceResponse.timeTransformer
      } else if (this.trainedAIType === 'TIME_TRANSFORMER2') {
        response = serviceResponse.timeTransformerV2
      }
      return {
        // 使用する学習済みAIによって変更
        type: 'application/json',
        code: response
      }
    },
    checkNatualLanguage() {
      if (!this.trainedAiDetail?.result) return null
      return isNatualLanguage(this.trainedAiDetail.result)
    },
    checkTimeseriesMulti() {
      if (!this.trainedAiDetail) return false
      if (this.trainedAiDetail.summary.type !== 'TIME') return false
      return this.trainedAiDetail?.trainConfig?.predictionColumn.length > 1
    },
    checkMultiPredictionColumn: function () {
      return this.trainedAiDetail?.trainConfig?.predictionColumn.length > 1
    }
  }
}
</script>
<style lang="scss" scoped>
$base-code-width: 882;

.sample-code {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &-tab-wrap {
    width: adjustVW($base-code-width);
    margin: 0 0 $space-small;
    &-sample-code {
      overflow: hidden;
      display: grid;
      grid-template-columns: adjustVW($base-code-width) 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: $space-medium;
    }
  }
  &-wrap {
    overflow: hidden;
    width: adjustVW($base-code-width);
    padding: $space-medium $space-small $space-medium $space-medium;
    background: $background-sub;
    border-radius: adjustVW(16);
  }
  &-block {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-inner {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 0 $space-medium;
    &:last-child {
      margin: 0;
    }
    &-sample-code {
      height: 100%;
    }
  }
  &-item {
    width: 30%;
    height: 100%;
    margin: 0 $space-small 0 0;
    &:last-child {
      width: calc(70% - #{$space-small});
      margin: 0;
    }
    &-icon {
      display: flex;
      &-wrap {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
      }
      &-tips {
        position: absolute;
        bottom: -$space-small;
        height: 100%;
        z-index: -1;
      }
      &-copy {
        padding: 0;
      }
    }
    &-text {
      margin: 0 $space-small 0 0;
    }
    &-sub {
      white-space: pre-line;
    }
  }
  &-select-box {
    display: grid;
    align-items: center;
    grid-template-columns: adjustVW(240) 1fr;
    height: adjustVH(48);
    margin: 0 $space-base $space-small;
    &-wrap {
      width: adjustVW($base-code-width);
    }
  }
  &-comment {
    &-inner {
      text-align: right;
      white-space: pre-line;
    }
  }
  &-side {
    &-column {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      padding: $space-sub;
      background: $background-sub;
      border-radius: adjustVW(8);
      &-wrap {
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }
      &-title {
        flex-shrink: 0;
        margin-bottom: $space-text;
      }
      &-list {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        grid-row-gap: adjustVW(6);
        padding-right: $space-base;
        @include scrollbar;
      }
      &-item {
        flex-shrink: 0;
        white-space: pre-line;
      }
    }
  }
}
</style>
