<template>
  <div
    :id="`scatter${$_uid}`"
    ref="scatter"
    v-resize:throttle.100="resized"
    class="scatter-plot"
  >
    <slot />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import * as d3 from 'd3'
import d3tip from 'd3-tip'

import resize from 'vue-resize-directive'
export default {
  directives: {
    resize
  },
  name: 'Scatter',
  props: {
    data: Array,
    xLabel: { type: String, default: 'X' },
    yLabel: { type: String, default: 'Y' },
    colorScale: { type: Array, default: () => [] },
    cautionValue: { type: Number, default: null },
    dataCount: { type: Number, default: null },
    showDetailTarget: { type: Number, default: null }
  },
  mounted() {
    this.init()
    this.$nextTick(() => this.show2D())
  },
  data() {
    return {
      zoomRatio: 0.9,
      resizeCooltime: false,

      svg: null,
      root: null,
      area: null,
      tip: null,
      xScale: null,
      yScale: null,
      xAxis: null,
      yAxis: null,
      gx: null,
      gy: null,
      width: null,
      height: null,
      showDetailIndex: null,
      margin: { top: 10, right: 5, bottom: 40, left: 40 }
    }
  },
  computed: {
    resizeParams() {
      return { data: this.data, xLabel: this.xLabel, yLabel: this.yLabel }
    }
  },
  methods: {
    init() {
      const margin = this.margin
      const el = this.$refs.scatter
      const outerWidth = el.clientWidth
      const outerHeight = el.clientHeight
      const width = Math.max(outerWidth - margin.left - margin.right, 100)
      const height = Math.max(outerHeight - margin.top - margin.bottom, 100)
      this.width = width
      this.height = height
      const svg = d3
        .select('#scatter' + this.$_uid)
        .append('svg')
        .classed('scatter-main', true)
        .attr('width', outerWidth)
        .attr('height', outerHeight)
        .attr('focusable', true)
        .on('focus', function () {
          svg.style('pointer-events', 'all')
        })
        .on('blur', function () {
          svg.style('pointer-events', 'unset')
        })
      this.svg = svg
      this.root = svg
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

      const data = this.data
      const yMax = d3.max(data, function (d) {
        return d.residualError
      })
      const yMin = d3.min(data, function (d) {
        return d.residualError
      })
      this.yScale = d3
        .scaleLinear()
        .domain([yMin, yMax])
        .range([0, height])
        .nice()

      this.histogram = d3
        .histogram()
        .value(function (d) {
          return d.residualError
        })
        .domain(this.yScale.domain())
        .thresholds(this.yScale.ticks(10))

      this.bins = this.histogram(data)

      this.xScale = d3
        .scaleLinear()
        .domain([
          0,
          d3.max(this.bins, function (d) {
            return d.length
          })
        ])
        .range([0, width])

      const xAxis = d3.axisBottom(this.xScale).tickSize(-height).ticks(5)
      const yAxis = d3.axisLeft(this.yScale).tickSize(-width)
      this.xAxis = xAxis
      this.yAxis = yAxis

      const gx = this.root
        .append('g')
        .classed('x axis d3-axis', true)
        .call(this.xAxis)

      const xLabel = gx.append('text').classed('label', true)

      const gy = this.root
        .append('g')
        .classed('y axis d3-axis', true)
        .call(this.yAxis)

      this.gx = gx
      this.gy = gy

      this.gx.attr('transform', 'translate(0,' + height + ')')

      this.gx
        .select('.v-axis-line')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', -height)

      this.gy
        .select('.h-axis-line')
        .attr('x1', 0)
        .attr('y1', height)
        .attr('x2', width)
        .attr('y2', height)

      this.gx
        .select('.label')
        .attr('x', width / 2)
        .attr('y', margin.bottom)
        .text(this.xLabel)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'text-after-edge')
        .attr('fill', 'black')

      this.recalcYAxis()

      this.tip = d3tip()
        .attr('class', 'd3-tip')
        .offset([-10, 0])
        .html(this.showTip)
      this.root.call(this.tip)

      this.area = this.root
        .append('svg')
        .classed('area', true)
        .attr('width', width)
        .attr('height', height)
      const dotsGroup = this.area.append('g')
      this.area.attr('width', width).attr('height', height)
      this.drawPoints(this.data)
    },
    resized() {},
    recalcYAxis() {
      const margin = this.margin
      const el = this.$refs.scatter
      const outerHeight = el.clientHeight
      const height = outerHeight - margin.top - margin.bottom
      const yLabel = this.gy.select('.label').text(this.yLabel)
      const yLabelWidth = yLabel.node() ? yLabel.node().getBBox().height : 25 // rotated

      const minMarginLeft =
        d3.max(
          this.gy
            .selectAll('.tick text')
            .nodes()
            .map((x) => x.getBBox().width)
        ) + yLabelWidth

      yLabel
        .attr('x', -height / 2)
        .attr('y', -margin.left + yLabelWidth)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'text-after-edge')
        .attr('fill', 'black')
    },
    clicked(d) {
      this.$emit('click', d)
    },
    showTip(d) {
      this.$emit('set-detail', d)
      this.showDetailIndex = d.x0
      return `
        <p>${this.yLabel}: ${d.x0} ~ ${d.x1}</p>
        <p>${this.xLabel}: ${d.length}</p>
      `
    },
    drawPoints(data) {
      const rect = this.svg.selectAll('rect').data(this.bins)
      const vueThis = this
      rect
        .enter()
        .append('rect')
        .merge(rect)
        .on('mouseover', this.tip.show)
        .on('mouseout', this.tip.hide)
        .attr('x', 1)
        .attr('transform', function (d) {
          return (
            'translate(' +
            vueThis.margin.left +
            ',' +
            (vueThis.yScale(d.x0) + vueThis.margin.top) +
            ')'
          )
        })
        .attr('height', function (d) {
          return vueThis.yScale(d.x1) - vueThis.yScale(d.x0) - 1
        })
        .attr('width', function (d) {
          return vueThis.xScale(d.length)
        })
        .style('fill', function (d) {
          if (vueThis.showDetailIndex === d.x0) {
            return '#850491'
          } else {
            return '#DBB4DE'
          }
        })

      rect.exit().remove()
    },
    setColor() {
      const rect = this.svg.selectAll('rect')
      const vueThis = this
      rect
        .transition()
        .duration(vueThis.transitionDuration)
        .style('fill', function (d) {
          if (
            vueThis.showDetailTarget != null &&
            vueThis.showDetailTarget >= d.x0 &&
            vueThis.showDetailTarget <= d.x1
          ) {
            vueThis.showDetailIndex = d.x0
            return '#850491'
          } else if (
            vueThis.showDetailIndex != null &&
            vueThis.showDetailIndex === d.x0
          ) {
            return '#850491'
          } else {
            return '#DBB4DE'
          }
        })
    },
    rescale() {
      const data = this.data

      const yMax = d3.max(data, function (d) {
        return d.residualError
      })
      const yMin = d3.min(data, function (d) {
        return d.residualError
      })
      this.yScale.domain([yMin, yMax])

      this.histogram = d3
        .histogram()
        .value(function (d) {
          return d.residualError
        })
        .domain(this.yScale.domain())
        .thresholds(this.yScale.ticks(10))

      this.bins = this.histogram(data)
      this.xScale.domain([
        0,
        d3.max(this.bins, function (d) {
          return d.length
        })
      ])
    },
    show2D() {}
  },
  watch: {
    resizeParams() {
      this.rescale()
      this.resized()
    },
    showDetailIndex() {
      this.setColor()
    },
    showDetailTarget() {
      this.setColor()
    }
  },
  beforeDestroy() {
    d3.select('.scatter-main').attr('style', 'pointer-events: none;')
  },
  destroyed() {
    this.tip.destroy()
  }
}
</script>
