<template>
  <!--
    ワンタイムパスワード設定ポップアップ
  -->
  <popup
    :title="$t('account.setting.info.popup.OTP.title')"
    :showPopup="showPopup"
    @close-modal="$emit('close-modal', $event)"
  >
    <link-OTP
      :accountInfo="accountInfo"
      :showPopup="showPopup"
      @create="$emit('create')"
      @delete="$emit('delete')"
      @close-modal="$emit('close-modal', $event)"
    />
  </popup>
</template>

<script>
import { popup } from '@/components/molecules/popup'
import linkOTP from './popup/account-info-link-onetime-password.vue'

export default {
  components: {
    popup,
    linkOTP
  },
  props: {
    showPopup: Boolean,
    accountInfo: Object
  },
  computed: {},
  methods: {}
}
</script>
