<template>
  <div ref="detailTop" class="trained-ai-detail-top">
    <div v-if="!editting" class="trained-ai-detail-top-edit-box">
      <button class="trained-ai-detail-top-title" @click="pageBack()">
        <text-with-icon
          class="trained-ai-detail-top-title-text"
          iconName="backward"
          size="title"
          :text="inputItem.name ? inputItem.name : title"
        />
        <top-edit-icon
          class="trained-ai-detail-top-title-icon"
          :disabled="trainedAiEditDisabled"
          :editLoading="editLoading"
          @click-edit="edit()"
        />
      </button>
      <description-long
        class="trained-ai-detail-top-summary-desc"
        showHorizontal
        :text="inputItem.description ? inputItem.description : description"
        :title="inputItem.name ? inputItem.name : title"
      />
    </div>
    <top-edit-box-with-desc
      v-else
      v-model="inputItem"
      class="trained-ai-detail-top-edit-box"
      :accept="validate.accept"
      :disabledSubmit="disabledSubmit"
      :error="validate.error"
      :nameValidationSuggests="trainedAIFormValidate.suggests"
      @finish-edit="commit()"
    />
  </div>
</template>

<script>
import descriptionLong from '@/components/atoms/description-long'
import TopEditIcon from '@/components/atoms/top-edit-icon.vue'
import TopEditBoxWithDesc from '@/components/molecules/top-edit-box-with-desc.vue'
import textWithIcon from '@/components/molecules/text-with-icon.vue'

export default {
  components: {
    descriptionLong,
    TopEditIcon,
    TopEditBoxWithDesc,
    textWithIcon
  },
  computed: {
    trainedAiEditDisabled() {
      if (this.accountInfo?.organizationInfo?.role === 'admin') return false
      if (this.trainedAi.summary.accountId !== this.accountInfo.accountId) {
        return true
      } else {
        return false
      }
    },
    disabledSubmit: function () {
      if (this.inputItem.name === '') return true
      if (this.validate.error) return true

      return false
    }
  },
  data() {
    return {
      disabledEdit: false,
      editting: false,
      inputItem: {
        name: '',
        description: '',
        id: this.trainedAi.id
      },
      validate: {
        accept: null,
        error: null
      }
    }
  },
  props: {
    accountInfo: Object,
    description: String,
    title: String,
    trainedAi: Object,
    trainedAIFormValidate: Object,
    editLoading: Boolean
  },
  watch: {
    inputItem: {
      deep: true,
      handler: function (form) {
        this.$emit('input-edit-form', {
          type: 'trainedAI',
          form
        })
      }
    },
    trainedAIFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    }
  },
  methods: {
    commit() {
      if (
        this.inputItem.name !== this.title ||
        this.inputItem.description !== this.description
      ) {
        this.$emit('change-description', {
          name: this.inputItem.name,
          description: this.inputItem.description
        })
      }
      this.editting = false
    },
    edit() {
      this.inputItem.name = this.title
      this.inputItem.description = this.description
      this.editting = true
    },
    pageBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.trained-ai-detail-top {
  max-width: adjustVW(960);
  margin: $space-medium 0;
  &-title {
    display: flex;
    align-items: flex-end;
    max-width: adjustVW(960);
    margin: 0 0 $space-small;
    &-text {
      margin: 0 $space-base 0 0;
    }
    &-icon {
      flex-shrink: 0;
    }
    &:hover {
      opacity: 1;
    }
  }
  &-summary {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-left: $space-medium;
    &-desc {
      flex-shrink: 0;
    }
  }
}
</style>
