<template>
  <transition-toggle-contents>
    <trained-ai-detail-tab-detail
      v-if="activeTab === 'detail'"
      key="detail"
      :trainedAi="trainedAi"
      :finishColumns="finishColumns"
      :selectedColumnIndex="selectedColumnIndex"
      :loadChangeColumn="loadChangeColumn"
      @change-column="$emit('change-column', $event)"
      @go-text-mining="goTextMining"
    />
    <trained-ai-detail-tab-expected-effect
      v-else-if="activeTab === 'expectedEffect'"
      key="expectedEffect"
      :expectedEffect="trainedAi.expectedEffect"
    />
    <trained-ai-detail-tab-accuracy
      v-else-if="activeTab === 'accuracy'"
      key="accuracy"
      :trainedAi="trainedAi"
      :metricsDefs="metricsDefs"
      :finishColumns="finishColumns"
      :selectedColumnIndex="selectedColumnIndex"
      :loadChangeColumn="loadChangeColumn"
      :threshold="threshold"
      :reversePositive="reversePositive"
      @change-column="$emit('change-column', $event)"
      @change-threshold="$emit('change-threshold', $event)"
      @reverse-positive="$emit('reverse-positive', $event)"
      @download-confusion-matrix="$emit('download-confusion-matrix', $event)"
    />
    <trained-ai-detail-tab-feature-importance
      v-else-if="activeTab === 'featureImportance'"
      key="featureImportance"
      :trainedAi="trainedAi"
      :featureImportanceScatter="featureImportanceScatter"
      :finishColumns="finishColumns"
      :selectedColumnIndex="selectedColumnIndex"
      :loadChangeColumn="loadChangeColumn"
      @load-feature-importance="$emit('load-feature-importance')"
      @change-column="$emit('change-column', $event)"
    />
    <trained-ai-detail-tab-text-mining
      v-else-if="activeTab === 'textMining'"
      key="textMining"
      :textMining="textMining"
      :textMiningTypeList="textMiningTypeList"
      @load-text-mining="$emit('load-text-mining', $event)"
      @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
    />
    <trained-ai-detail-tab-classic-test-dataset
      v-else-if="activeTab === 'classicTestData'"
      key="classicTestData"
      :trainedAi="trainedAi"
      :featureImportanceScatter="featureImportanceScatter"
      :finishColumns="finishColumns"
      :selectedColumnIndex="selectedColumnIndex"
      :loadChangeColumn="loadChangeColumn"
      :testDatasetInfo="testDatasetInfo"
      @change-column="$emit('change-column', $event)"
      @change-test-dataset-page="$emit('change-test-dataset-page', $event)"
      @download-test-dataset="$emit('download-test-dataset', $event)"
    />
    <trained-ai-detail-tab-trained-info
      v-else-if="activeTab === 'trainedInfo'"
      key="trainedInfo"
      :trainedAi="trainedAi"
      :metricsDefs="metricsDefs"
      :finishColumns="finishColumns"
      :selectedColumnIndex="selectedColumnIndex"
      :loadChangeColumn="loadChangeColumn"
      @change-column="$emit('change-column', $event)"
    />
  </transition-toggle-contents>
</template>

<script>
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import trainedAiDetailTabDetail from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-detail'
import trainedAiDetailTabExpectedEffect from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-expected-effect'
import trainedAiDetailTabAccuracy from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-accuracy'
import trainedAiDetailTabTextMining from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-text-mining'
import trainedAiDetailTabFeatureImportance from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-feature-importance'
import trainedAiDetailTabClassicTestDataset from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-classic-test-dataset'
import trainedAiDetailTabTrainedInfo from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-trained-info'

import { classification } from '../metricsDefs.js'
import {
  TEXT_MINING_PAGE_TYPE,
  TEXT_MINING_TYPE_TO_PAGE_TYPE
} from '@/lib/text-mining'

export default {
  components: {
    transitionToggleContents,
    trainedAiDetailTabDetail,
    trainedAiDetailTabExpectedEffect,
    trainedAiDetailTabAccuracy,
    trainedAiDetailTabTextMining,
    trainedAiDetailTabFeatureImportance,
    trainedAiDetailTabClassicTestDataset,
    trainedAiDetailTabTrainedInfo
  },
  props: {
    trainedAi: Object,
    featureImportanceScatter: Object,
    tabs: Array,
    activeTab: String,
    activeMenu: String,
    finishColumns: Array,
    selectedColumnIndex: Number,
    loadChangeColumn: Boolean,
    threshold: Number,
    reversePositive: Boolean,
    textMining: Object,
    testDatasetInfo: Object
  },
  computed: {
    metricsDefs() {
      return classification(this.$t.bind(this))
    },
    textMiningTypeList() {
      if (this.trainedAi.summary?.textMining == null) return {}
      return this.trainedAi.summary.textMining.typeList
    }
  },
  methods: {
    goTextMining(type) {
      this.$emit('change-tab', 'textMining')
      const pageType = TEXT_MINING_TYPE_TO_PAGE_TYPE[type]
      this.$emit('change-tab-text-mining', pageType)

      const value = this.textMining.params[pageType]
      const columnName = this.textMining.params.columnName
      if (pageType !== TEXT_MINING_PAGE_TYPE.CO_OCCURRENCE) {
        this.$emit('load-text-mining', { pageType, value, columnName })
      }
    }
  }
}
</script>
