import { render, staticRenderFns } from "./trained-ai-detail-tab-feature-importance.vue?vue&type=template&id=b08e302a&scoped=true&"
import script from "./trained-ai-detail-tab-feature-importance.vue?vue&type=script&lang=js&"
export * from "./trained-ai-detail-tab-feature-importance.vue?vue&type=script&lang=js&"
import style0 from "./trained-ai-detail-tab-feature-importance.vue?vue&type=style&index=0&id=b08e302a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b08e302a",
  null
  
)

export default component.exports