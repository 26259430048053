<template>
  <div
    class="item"
    :class="{ disabled: !available }"
    @click="available ? $emit('click') : 0"
  >
    <div class="flow-list-item">
      <text-with-icon
        :text="name"
        :iconName="'num' + (index + 1)"
        :color="active ? 'emphasis' : available ? 'gray' : 'off'"
      />
    </div>
    <div v-if="isSelected[index]" class="choosed-item">
      <texts size="small" color="gray" :text="itemTitle" />
      <texts :text="choosedItems[index].name" />
    </div>
    <div v-else-if="!preprocessingAvailable" class="choosed-item" />
    <div v-else-if="!noPreprocess" class="choosed-item">
      <texts :text="$t('common.notSelected')" color="disabled" />
    </div>
    <div v-else class="choosed-item">
      <texts
        :text="$t('inference.settings.noPreprocessing')"
        color="disabled"
      />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text.vue'
import textWithIcon from '@/components/molecules/text-with-icon'

export default {
  components: {
    texts,
    textWithIcon
  },
  data() {
    return {
      activeFlow: 0
    }
  },
  props: {
    noPreprocess: Boolean,
    preprocessingAvailable: Boolean,
    choosedItems: Array,
    isSelected: Array,
    active: Boolean,
    available: Boolean,
    index: Number
  },
  computed: {
    name() {
      return this.$i18n.t('inference.settings.navi.selectPreProcess')
    },
    itemTitle() {
      return this.$i18n.t('inference.settings.navi.choosedItems.preprocess')
    }
  },
  watch: {
    activeMenu: function (val) {
      this.activeFlow = val
    }
  },
  methods: {
    changeFlow(e) {
      this.activeFlow = e
      this.$emit('click', this.activeFlow)
    }
  }
}
</script>

<style lang="scss" scoped>
.flow-list-item {
  margin-bottom: $space-base;
}

.choosed-item {
  margin-bottom: $space-base;
}

.item {
  margin: 0 0 $space-medium;
  cursor: pointer;
  user-select: none;

  &.disabled {
    cursor: not-allowed;
  }
}
</style>
