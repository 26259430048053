<template>
  <div
    class="trained-ai-trained-info"
    :class="{
      'trained-ai-trained-info-double-graph': convertedCharts.length > 1,
      'trained-ai-trained-info-trials': checkTrials
    }"
  >
    <div class="trained-ai-trained-info-list">
      <div v-if="checkTrials" class="trained-ai-trained-info-item">
        <select-column-box
          v-model="selectedItem"
          class="trained-ai-feature-importance-input"
          :title="$t('trainedAi.multi.selectedColumn')"
          :trainedAi="trainedAi"
          :finishColumns="finishColumns"
        />
      </div>
      <div class="trained-ai-trained-info-item">
        <text-with-title
          :title="$t('trainedAiDetails.testDataRatio')"
          :text="trainConfig.data.ratio"
        />
      </div>
      <div class="trained-ai-trained-info-item">
        <text-with-title
          :title="$t('trainedAiDetails.nDataOfTestTrain')"
          :text="trainConfig.data.nTests + ' / ' + trainConfig.data.nTrains"
        />
      </div>
      <transition-toggle-contents>
        <div
          v-if="selectedColumnIndex === -1"
          key="none-info"
          class="trained-ai-trained-info-none"
        >
          <texts
            class="trained-ai-trained-info-none-text"
            :text="$t('trainedAi.multi.notFoundSeparate')"
            size="small"
            color="gray"
          />
        </div>
        <div v-else-if="loadChangeColumn" class="trained-ai-trained-info-none">
          <loading-icon />
        </div>
        <div v-else class="trained-ai-trained-info-body">
          <div v-if="filteredMetrics" class="trained-ai-trained-info-item">
            <text-with-title
              :title="$t('trainedAiDetails.bestAccuracy')"
              :text="filteredMetrics"
            />
          </div>
          <div
            v-if="trainedAi.trials"
            class="
              trained-ai-trained-info-item trained-ai-trained-info-auto-flow
            "
          >
            <div class="trained-ai-trained-info-auto-flow-title">
              <texts
                :text="$t('trainedAiDetails.bestParams')"
                size="small"
                color="gray"
              />
            </div>
            <div class="trained-ai-trained-info-auto-flow-list">
              <div class="trained-ai-trained-info-auto-flow-item">
                <text-with-title
                  :title="$t('trainedAiDetails.algorithm')"
                  :text="
                    $t(
                      'recipe.layerNames.' +
                        trainedAi.trials.bestParams.classifier
                    )
                  "
                />
              </div>
              <template
                v-for="[key, value] in Object.entries(
                  trainedAi.trials.bestParams
                )"
              >
                <div
                  v-if="key !== 'classifier'"
                  :key="key + value"
                  class="trained-ai-trained-info-auto-flow-item"
                >
                  <text-with-title
                    :title="
                      $t(
                        'recipe.param.' +
                          trainedAi.trials.bestParams.classifier +
                          '.' +
                          key.split('#')[0]
                      )
                    "
                    :text="
                      traialsValue(
                        trainedAi.trials.bestParams.classifier,
                        value
                      )
                    "
                  />
                </div>
              </template>
            </div>
          </div>
        </div>
      </transition-toggle-contents>
    </div>
    <div
      v-if="convertedCharts && checkConvertedCharts"
      class="trained-ai-trained-info-graph"
    >
      <transition-toggle-contents>
        <div
          v-if="selectedColumnIndex === -1"
          key="chart-none"
          class="trained-ai-trained-info-graph-inner"
        >
          <texts :text="$t('trainedAi.multi.notFound')" color="gray" />
        </div>
        <loading
          v-else-if="loadChangeColumn"
          key="chart-loading"
          class="trained-ai-trained-info-graph-inner"
        />
        <div v-else key="chart" class="trained-ai-trained-info-graph-body">
          <div
            v-for="(charts, index) in convertedCharts"
            :key="index"
            class="trained-ai-trained-info-graph-item"
          >
            <div class="trained-ai-trained-info-graph-title">
              <texts :text="charts.title" color="gray" size="small" />
            </div>
            <linePlot
              :data="charts.data"
              class="trained-ai-trained-info-graph-inner"
              :graphMargin="{ top: 40, right: 24, bottom: 20, left: 24 }"
            />
          </div>
        </div>
      </transition-toggle-contents>
    </div>
    <div v-if="trainedAi.trials" class="trained-ai-trained-info-verification">
      <div class="trained-ai-trained-info-verification-block">
        <transition-toggle-contents>
          <div
            v-if="selectedColumnIndex === -1"
            key="none-distribution-info"
            class="trained-ai-trained-info-none"
          >
            <texts
              class="trained-ai-trained-info-none-text"
              :text="$t('trainedAi.multi.notFound')"
              color="gray"
            />
          </div>
          <loading
            v-else-if="loadChangeColumn"
            key="distribution"
            class="trained-ai-trained-info-none"
          />
          <div v-else class="trained-ai-trained-info-verification-list">
            <div
              v-for="(items, index) in sortedTrials"
              :key="index"
              class="trained-ai-trained-info-verification-item"
            >
              <div class="trained-ai-trained-info-verification-inner">
                <text-with-title
                  :title="$t('trainedAiDetails.trialCount')"
                  :text="items.iter"
                />
              </div>
              <div
                class="
                  trained-ai-trained-info-verification-inner
                  trained-ai-trained-info-verification-inner-classifier
                "
              >
                <text-with-title
                  :title="$t('trainedAiDetails.algorithm')"
                  :text="$t('recipe.layerNames.' + items.params.classifier)"
                />
              </div>
              <template v-for="(paramValue, paramKey) in items.params">
                <div
                  v-if="paramKey !== 'classifier'"
                  :key="paramKey"
                  class="trained-ai-trained-info-verification-inner"
                >
                  <text-with-title
                    :title="
                      $t(
                        'recipe.param.' +
                          items.params.classifier +
                          '.' +
                          paramKey.split('#')[0]
                      )
                    "
                    :text="traialsValue(items.params.classifier, paramValue)"
                  />
                </div>
              </template>
            </div>
          </div>
        </transition-toggle-contents>
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import loading from '@/components/atoms/loading'
import loadingIcon from '@/components/atoms/loading-icon'
import textWithTitle from '@/components/molecules/text-with-title'
import linePlot from '@/components/organisms/graph/LinePlot.vue'
import selectColumnBox from '@/components/organisms/trained-ai-common/select-column-box'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

import { toRounded } from '@/lib/misc.js'

function keyValue(ks, vs) {
  const res = {}
  ks.forEach((k, i) => {
    res[k] = vs[i]
  })
  return res
}

export default {
  components: {
    texts,
    loading,
    loadingIcon,
    textWithTitle,
    linePlot,
    selectColumnBox,
    transitionToggleContents
  },
  data() {
    return {
      selectedItem: ''
    }
  },
  props: {
    trainedAi: Object,
    metricsDefs: Object,
    selectedColumnIndex: Number,
    finishColumns: Array,
    loadChangeColumn: Boolean
  },
  computed: {
    trainConfig() {
      return this.trainedAi.trainConfig
    },
    convertedCharts() {
      const charts = []
      if (
        this.trainedAi.chartData &&
        Object.keys(this.trainedAi.chartData).length
      ) {
        if (
          this.trainedAi.chartData.train?.accuracy &&
          this.trainedAi.chartData.train?.accuracy.length > 0
        ) {
          charts.push({
            title: this.$t('trainedAi.graph.accuracy'),
            data: [
              {
                values: keyValue(
                  this.trainedAi.chartData.train.step,
                  this.trainedAi.chartData.train.accuracy
                ),
                name: this.$t('trainedAi.metrics.train_accuracy'),
                color: '#049185'
              },
              {
                values: keyValue(
                  this.trainedAi.chartData.test.step,
                  this.trainedAi.chartData.test.accuracy
                ),
                name: this.$t('trainedAi.metrics.test_accuracy'),
                color: '#850491'
              }
            ]
          })
        }
        if (
          this.trainedAi.chartData.train?.loss &&
          this.trainedAi.chartData.train?.loss.length > 0
        ) {
          charts.push({
            title: this.$t('trainedAi.graph.loss'),
            data: [
              {
                values: keyValue(
                  this.trainedAi.chartData.train.step,
                  this.trainedAi.chartData.train.loss
                ),
                name: this.$t('trainedAi.metrics.train_loss'),
                color: '#049185'
              },
              {
                values: keyValue(
                  this.trainedAi.chartData.test.step,
                  this.trainedAi.chartData.test.loss
                ),
                name: this.$t('trainedAi.metrics.test_loss'),
                color: '#850491'
              }
            ]
          })
        }
      } else if (this.trainedAi.trials) {
        const res = {}
        const algorithms = []
        for (const item of this.trainedAi.trials.trials) {
          res[item.iter] = item.value
          algorithms[item.iter] = item.params.classifier
        }
        let name = ''
        const layer = this.trainedAi.result.layer_info.find(
          (x) => x.name === 'AutoFlow'
        )
        if (layer) {
          name = this.getMetricsName(layer)
        }
        charts.push({
          title: this.$t('trainedAi.graph.default'),
          data: [
            {
              values: res,
              name: name,
              color: '#049185',
              algorithms: algorithms
            }
          ]
        })
      }
      return charts
    },
    checkConvertedCharts() {
      if (!this.convertedCharts && this.convertedCharts.legnth === 0)
        return false
      const checkTarget = []
      for (const charts of this.convertedCharts) {
        if (charts.data.some((chart) => Object.keys(chart.values).length > 0)) {
          checkTarget.push(charts)
        }
      }
      return checkTarget.length > 0
    },
    trainedAiType() {
      if (!this.trainedAi) return null
      return this.trainedAi.summary.type
    },
    filteredMetrics() {
      if (!this.trainedAi || !this.trainedAi?.trials?.bestAcc) return null
      return toRounded(this.trainedAi.trials.bestAcc)
    },
    checkTrials() {
      if (!this.trainedAi) return false
      const predictionColumns =
        this.trainedAi?.trainConfig?.predictionColumn &&
        this.trainedAi.trainConfig.predictionColumn.length > 1
      const checkTypes = ['REGRESSION', 'CLASSIFICATION']
      return (
        predictionColumns &&
        this.trainedAi?.trials &&
        checkTypes.includes(this.trainedAi.summary.type)
      )
    },
    sortedTrials() {
      if (this.trainedAi?.trials?.trials == null) return []
      const res = JSON.parse(JSON.stringify(this.trainedAi.trials.trials))
      return res.sort((x, y) => {
        return y.iter - x.iter
      })
    }
  },
  methods: {
    traialsValue(classifier, value) {
      if (this.$te('recipe.param.' + classifier + '.' + value)) {
        return this.$t('recipe.param.' + classifier + '.' + value)
      } else {
        if (!isNaN(value)) {
          return Math.ceil(value * 1000) / 1000
        }
        return value
      }
    },
    getMetricsName(layer) {
      let name = ''
      if (this.trainedAiType === 'REGRESSION') {
        name = this.$t('trainedAi.metrics.val_accuracy_r2')
      } else {
        if (layer.params?.classMetrics) {
          if (layer.params.classMetrics.value !== 'default') {
            name = this.$t(
              'trainedAi.metrics.val_' + layer.params.classMetrics.value
            )
          } else {
            name = this.$t('trainedAi.metrics.val_accuracy')
          }
        } else {
          name = this.$t('trainedAi.metrics.val_accuracy')
        }
      }
      return name
    }
  },
  mounted() {
    const configColumn = this.trainedAi?.trainConfig?.predictionColumn ?? []
    if (configColumn.length === 0) return
    this.selectedItem = configColumn[this.selectedColumnIndex]
  },
  watch: {
    selectedItem(newVal) {
      this.$emit('change-column', newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.trained-ai-trained-info {
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(auto, adjustVW(320)) minmax(auto, adjustVW(632)) minmax(
      auto,
      adjustVW(632)
    );
  grid-template-rows: 100%;
  grid-column-gap: $space-medium;
  padding: 0 $space-medium $space-medium;
  &-double-graph {
    grid-template-columns: minmax(auto, adjustVW(320)) 1fr;
  }
  &-trials {
    height: 100%;
  }
  &-list {
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-height: 100%;
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    .trained-ai-trained-info-trials & {
      height: 100%;
    }
  }
  &-body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    max-height: 100%;
  }
  &-item {
    margin: 0 0 $space-small;
    &:last-child {
      margin: 0;
    }
  }
  &-auto-flow {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    max-height: 100%;
    &-title {
      margin: 0 0 $space-base;
    }
    &-list {
      overflow: auto;
      display: flex;
      flex-direction: column;
      max-height: 100%;
      padding-right: $space-base;
      @include scrollbar;
    }
    &-item {
      padding: 0 0 $space-sub;
      margin: 0 0 $space-sub;
      border-bottom: $border-sub;
      &:last-child {
        padding: 0;
        margin: 0;
        border: none;
      }
    }
  }
  &-graph {
    height: fit-content;
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    &-body {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: $space-medium;
      height: fit-content;
      .trained-ai-trained-info-double-graph & {
        grid-template-columns: 1fr 1fr;
      }
    }
    &-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      height: adjustVW(500);
    }
    &-title {
      margin-bottom: $space-base;
    }
  }
  &-verification {
    height: 100%;
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    &-block {
      overflow-y: scroll;
      height: 100%;
      max-height: 100%;
      @include scrollbar;
    }
    &-list {
      margin: 0 $space-base 0 0;
    }
    &-item {
      display: flex;
      flex-wrap: wrap;
      padding: 0 0 $space-small;
      margin: 0 0 $space-small;
      border-bottom: $border-sub;
      &:last-child {
        padding: 0;
        margin: 0;
        border: none;
      }
    }
    &-inner {
      width: adjustVW(176);
      margin: 0 $space-small $space-small 0;
      &:nth-last-of-type(-n + 2) {
        margin: 0 $space-small 0 0;
      }
      &-classifier {
        width: calc(100% - #{adjustVW(176) + $space-small});
        margin: 0 0 $space-small;
        &:nth-last-of-type(-n + 3) {
          margin: 0 0 $space-small;
        }
        &:nth-last-of-type(-n + 2) {
          margin: 0 0 $space-small;
        }
        &:nth-last-of-type(-n + 1) {
          margin: 0;
        }
      }
    }
  }
  &-none {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    &-text {
      text-align: center;
      white-space: pre-line;
    }
  }
}
</style>
