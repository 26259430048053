<template>
  <div class="preprocessing-list-delete-item">
    <div v-if="entry.description" class="preprocessing-list-delete-item-block">
      <description :text="entry.description" />
    </div>
    <div class="preprocessing-list-delete-item-block">
      <div class="preprocessing-list-delete-item-inner">
        <texts
          class="preprocessing-list-delete-item-title"
          :text="$t('preprocessing.preprocessingContent')"
          size="small"
          color="gray"
        />
        <div class="preprocessing-list-delete-item-content">
          <preprocessign-chart-list-min
            class="preprocessing-list-delete-item-list"
            :layers="preprocChart ? preprocChart.layers : entry.layers"
            isShowAll
          />
        </div>
      </div>
    </div>
    <div class="preprocessing-list-delete-item-block">
      <div
        v-if="entry.service && entry.service.length > 0"
        class="preprocessing-list-delete-item-inner"
      >
        <texts
          class="preprocessing-list-delete-item-title"
          :text="$t('preprocessing.useSevise')"
          size="small"
          color="gray"
        />
        <div
          v-for="(item, key) in entry.service"
          :key="key"
          class="preprocessing-list-delete-item-content"
        >
          <router-link
            class="preprocessing-list-delete-item-content-link"
            :to="{
              name: 'serviceProjectDetail',
              params: {
                id: item.link,
                projectId,
                serviceType: item.serviceType
              }
            }"
          >
            <text-with-icon
              :text="item.name"
              iconName="newTab"
              pos="right"
              color="link-default"
            />
          </router-link>
        </div>
      </div>
      <div class="preprocessing-list-delete-item-inner">
        <text-with-title :title="$t('common.owner')" :text="entry.accountId" />
      </div>
    </div>
    <div class="preprocessing-list-delete-item-block">
      <div class="preprocessing-list-delete-item-inner">
        <text-with-title :title="$t('common.createTime')">
          <a-time :value="entry.createTime" />
        </text-with-title>
      </div>
      <div class="preprocessing-list-delete-item-inner">
        <text-with-title :title="$t('common.updateTime')">
          <a-time :value="entry.updateTime" />
        </text-with-title>
      </div>
    </div>
  </div>
</template>

<script>
import description from '@/components/atoms/description'
import textWithTitle from '@/components/molecules/text-with-title'
import textWithIcon from '@/components/molecules/text-with-icon.vue'
import preprocessignChartListMin from '@/components/molecules/preprocessing-chart-list-min'

export default {
  components: {
    description,
    textWithTitle,
    textWithIcon,
    preprocessignChartListMin
  },
  props: {
    entry: Object,
    preprocChart: Object
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    }
  }
}
</script>

<style lang="scss" scoped>
.preprocessing-list-delete {
  &-item {
    &-block {
      display: flex;
      margin: 0 0 $space-small;
    }
    &-inner {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 $space-small 0 0;
      &:last-child {
        margin: 0;
      }
    }
    &-content {
      margin: 0 0 $space-base;
      &-link {
        display: inline-block;
        max-width: 100%;
      }
    }
    &-title {
      margin: 0 0 $space-base;
    }
    &-list {
      overflow-y: auto;
      padding: 0 $space-sub 0 0;
      @include scrollbar;
    }
  }
}
</style>
