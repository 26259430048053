<template>
  <div class="dependency">
    <card-base class="dependency-side">
      <div>
        <texts
          :text="$t('trainedAiDetails.featureImportanceTab.selectedVariable')"
          color="gray"
          size="small"
        />
        <select-box
          v-model="selectedColumn"
          class="select-variable"
          :items="valiablesSelect"
          isGray
          scrollBar
        />
      </div>
    </card-base>
    <card-base class="dependency-body">
      <scatter
        v-if="chartData"
        :data="chartData"
        class="plot"
        :graphMargin="{ top: 40, right: 24, bottom: 20, left: 24 }"
        :forceIncludeZero="false"
        :xLabel="this.selectedColumn"
        :yLabel="this.selectedPredictionColumn"
        line
      />
    </card-base>
  </div>
</template>

<script>
import cardBase from '@/components/atoms/card-base'
import scatter from '@/components/organisms/graph/scatter.vue'
import selectBox from '@/components/molecules/select-box'

export default {
  mounted() {
    this.selectedColumn = this.columnList?.[0]
  },
  data() {
    return {
      selectedColumn: null
    }
  },
  props: {
    trainedAi: Object,
    selectedColumnIndex: Number
  },
  components: {
    cardBase,
    scatter,

    selectBox
  },
  computed: {
    columnList() {
      return Object.keys(this.trainedAi?.result?.dependency_dict)
    },
    selectedPredictionColumn() {
      return this.trainedAi?.trainConfig?.predictionColumn?.[
        this.selectedColumnIndex
      ]
    },
    valiablesSelect() {
      const valiables = this.columnList
      return valiables.map((x) => ({
        name: x,
        value: x
      }))
    },
    dependencyDict() {
      return this.trainedAi?.result?.dependency_dict?.[this.selectedColumn]
    },
    chartData() {
      if (this.dependencyDict == null) return null
      const res = []
      this.dependencyDict.forEach((item) => {
        res.push({
          vector: [item[this.selectedColumn], item.prediction]
        })
      })
      return res
    }
  },
  watch: {
    columnList() {
      this.selectedColumn = this.columnList?.[0]
    }
  }
}
</script>

<style lang="scss" scoped>
.dependency {
  overflow: hidden;

  display: grid;
  grid-template-columns: adjustVW(320) 1fr;
  grid-template-rows: 100%;
  grid-column-gap: $space-medium;
  height: 100%;
  padding: 0 $space-medium $space-medium;

  &-side {
    height: 100%;
  }
  &-body {
    .plot {
      height: 100%;
    }
  }

  .select-variable {
    padding: 0 0 $space-small;
    margin: 0 0 $space-sub;
    border-bottom: $border-main;
  }
}
</style>
