export function initCustomblockParams(resultParams) {
  const params = {}

  // カスタムブロック用に、パラメータを展開する
  Object.entries(resultParams).forEach((entry) => {
    const [key, item] = entry
    if (item.params != null) {
      for (const paramKey in item.params) {
        item[paramKey] = item.params[paramKey]
      }
      item.params = undefined
    }
    params[key] = item
  })

  return params
}
