<template>
  <popup-array
    :showPopup="showPopup"
    :popupDefs="popupDefs"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #ProjectTemplateCreate>
      <project-template-create-form
        :projectFormValidate="createInfo.projectFormValidate"
        :submitDisabled="submitDisabled"
        @create-project="$emit('create-project', $event)"
        @input-create-form="$emit('input-create-form', $event)"
      />
    </template>
  </popup-array>
</template>

<script>
import popupArray from '@/components/molecules/popup/popup-array.vue'
import projectTemplateCreateForm from './popup/project-template-create.vue'

export default {
  components: {
    popupArray,
    projectTemplateCreateForm
  },
  props: {
    showPopup: Array,
    submitDisabled: Boolean,
    createInfo: Object
  },
  computed: {
    popupDefs() {
      return {
        ProjectTemplateCreate: {
          title: this.$t('projectTemplate.selectMode.popup.title')
        }
      }
    }
  }
}
</script>
