<template>
  <div class="trained-ai-wordcloud">
    <div
      class="trained-ai-wordcloud-side"
      :class="{ 'trained-ai-wordcloud-side-none': !scaleMax }"
    >
      <template v-if="scaleMax">
        <div class="trained-ai-wordcloud-display-count">
          <div
            class="
              trained-ai-detail-title-sub
              trained-ai-wordcloud-display-count-title
            "
          >
            <texts :text="$t('common.nDataShown')" color="gray" size="small" />
            <div class="trained-ai-wordcloud-display-count-box">
              <div class="trained-ai-wordcloud-display-count-box-max">
                <input
                  v-model="scaleSize"
                  type="number"
                  min="0"
                  :max="scaleMax"
                >
              </div>
            </div>
          </div>
          <div class="trained-ai-wordcloud-display-count-scale">
            <div class="trained-ai-wordcloud-display-count-scale-inner">
              <input
                v-model="scaleSize"
                class="trained-ai-wordcloud-display-count-scale-range"
                type="range"
                name="speed"
                min="0"
                :max="Object.keys(this.frequencies).length"
              >
              <div
                class="trained-ai-wordcloud-display-count-scale-range-status"
                :style="{ '--width': (scaleSize / scaleMax) * 100 + '%' }"
              />
            </div>
          </div>
          <div class="trained-ai-detail-title-sub">
            <texts
              :text="$t('trainedAiDetails.frequencies')"
              color="gray"
              size="small"
              isShowAll
            />
          </div>
          <div class="trained-ai-wordcloud-words-block">
            <div class="trained-ai-wordcloud-words-space">
              <div class="trained-ai-wordcloud-words-list">
                <transition-group name="toggle-words">
                  <div
                    v-for="([word, value], index) in limitCountTest"
                    :key="index + word"
                    class="trained-ai-wordcloud-words-item"
                  >
                    <div class="trained-ai-wordcloud-words-item-name">
                      <texts :text="word" size="small" />
                    </div>
                    <div
                      class="trained-ai-wordcloud-words-item-bar"
                      @mouseenter="isHoverGraph(index)"
                      @mouseleave="isLeaveGraph()"
                    >
                      <div
                        class="trained-ai-wordcloud-words-item-bar-inner"
                        :style="{ '--width': scaleWidth(value) + '%' }"
                      >
                        <div
                          v-tooltip="{
                            content:
                              word +
                              $t('trainedAiDetails.frequenciesTip') +
                              value,
                            trigger: ['manual'],
                            show: isTipsShow === index,
                            delay: { show: 500, hide: 500 }
                          }"
                          class="trained-ai-wordcloud-words-item-tooltip-wrap"
                          :class="{
                            'trained-ai-wordcloud-words-item-tooltip-wrap-bottom':
                              index === 0
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </transition-group>
              </div>
            </div>
            <div class="trained-ai-wordcloud-words-bg">
              <div class="trained-ai-wordcloud-words-bg-scale">
                <div class="trained-ai-wordcloud-words-bg-scale-item">
                  0
                </div>
                <div class="trained-ai-wordcloud-words-bg-scale-item">
                  {{ Math.ceil(maxScale() * 0.25) }}
                </div>
                <div class="trained-ai-wordcloud-words-bg-scale-item">
                  {{ Math.ceil(maxScale() * 0.5) }}
                </div>
                <div class="trained-ai-wordcloud-words-bg-scale-item">
                  {{ Math.ceil(maxScale() * 0.75) }}
                </div>
                <div class="trained-ai-wordcloud-words-bg-scale-item">
                  {{ Math.ceil(maxScale() * 1) }}
                </div>
              </div>
              <div class="trained-ai-wordcloud-words-bg-line">
                <div class="trained-ai-wordcloud-words-bg-line-item" />
                <div class="trained-ai-wordcloud-words-bg-line-item" />
                <div class="trained-ai-wordcloud-words-bg-line-item" />
                <div class="trained-ai-wordcloud-words-bg-line-item" />
                <div class="trained-ai-wordcloud-words-bg-line-item" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="trained-ai-wordcloud-none">
          <texts
            class="trained-ai-wordcloud-none-text"
            :text="$t('trainedAiDetails.noFrequencies')"
            color="gray"
            size="small"
          />
        </div>
      </template>
    </div>
    <div
      class="trained-ai-wordcloud-main"
      :class="{ 'trained-ai-wordcloud-main-none': !wordcloud }"
    >
      <template v-if="wordcloud">
        <div class="trained-ai-wordcloud-container">
          <img
            class="trained-ai-wordcloud-img"
            :src="'data:image/png;base64,' + wordcloud"
          >
        </div>
      </template>
      <template v-else>
        <texts
          class="trained-ai-wordcloud-none-text"
          :text="$t('trainedAiDetails.noWordCloud')"
          color="gray"
          size="small"
        />
      </template>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'

export default {
  components: {
    texts
  },
  data() {
    return {
      isTipsShow: '',
      displayMax: Math.min(20, Object.keys(this.frequencies).length),
      scaleSize: Math.min(20, Object.keys(this.frequencies).length),
      scaleMax: Object.keys(this.frequencies).length
    }
  },
  props: {
    wordcloud: String,
    frequencies: Object
  },
  methods: {
    scale(e) {
      return Math.round(e * 10) / 10
    },
    maxScale() {
      const digits = String(Object.values(this.frequencies)[0]).length - 1
      return (
        Math.ceil(Object.values(this.frequencies)[0] / 10 ** digits) *
        10 ** digits
      )
    },
    scaleWidth(e) {
      return (this.scale(e) / this.maxScale()) * 100
    },
    isHoverGraph(e) {
      this.isTipsShow = e
    },
    isLeaveGraph() {
      this.isTipsShow = ''
    }
  },
  computed: {
    limitCountTest() {
      // 現状のMatrixflowの仕様に合わせてください。これは表示テスト用です
      return Object.entries(this.frequencies).slice(0, this.displayMax)
    }
  },
  watch: {
    scaleSize(newVal) {
      this.displayMax = newVal
    },
    displayMax(newVal) {
      if (newVal < 0) {
        this.displayMax = 0
        this.scaleSize = 0
      } else if (newVal > this.scaleMax) {
        this.displayMax = this.scaleMax
        this.scaleSize = this.scaleMax
      } else {
        this.scaleSize = newVal
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.trained-ai-wordcloud {
  overflow: hidden;
  display: grid;
  grid-template-columns: adjustVW(480) 1fr;
  grid-template-rows: 100%;
  grid-column-gap: $space-medium;
  height: 100%;
  padding: 0 $space-medium $space-medium;
  &-side {
    display: grid;
    grid-template-rows: 1fr;
    &-none {
      grid-template-rows: minmax(adjustVH(160), min-content);
      .trained-ai-wordcloud-none {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $space-small;
        background: $background;
        border-radius: adjustVW(16);
        box-shadow: $box-shadow-main;
        &-text {
          white-space: pre-line;
        }
      }
    }
  }
  &-main {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    &-none {
      display: flex;
      align-items: center;
      justify-content: center;
      height: adjustVH(160);
      .trained-ai-wordcloud-none-text {
        white-space: pre-line;
      }
    }
  }
  &-container {
    height: 100%;
  }
  &-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &-display-count {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    &-box {
      display: flex;
      align-items: center;
      &-max {
        width: adjustVW(56);
        padding: $space-min;
        border: $border-emphasis;
        font-size: $text-base;
        border-radius: adjustVW(4);
        > input {
          appearance: textfield;
          width: 100%;
          text-align: center;
          &::-webkit-outer-spin-button {
            appearance: none;
            margin: 0;
          }
          &::-webkit-inner-spin-button {
            appearance: none;
            margin: 0;
          }
        }
      }
    }
    &-scale {
      padding-bottom: $space-medium;
      margin-bottom: $space-small;
      border-bottom: $border-main;
      &-inner {
        position: relative;
      }
      &-range {
        appearance: none;
        width: 100%;
        height: adjustVH(8);
        border: $border-sub;
        background: $background-sub;
        border-radius: adjustVW(4);
        cursor: pointer;
        outline: none;
        &::-webkit-slider-thumb {
          position: relative;
          appearance: none;
          width: adjustVW(24);
          height: adjustVW(24);
          border: $border-emphasis;
          background: $background;
          border-radius: 9in;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
          z-index: 2;
        }
        &::-moz-range-thumb {
          width: adjustVW(24);
          height: adjustVW(24);
          border: $border-emphasis;
          background: $background;
          border-radius: 9in;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
        }
        &-status {
          --width: 50;
          position: absolute;
          top: 50%;
          width: var(--width);
          height: adjustVH(8);
          background: $key-color;
          border-radius: 9in 0 0 9in;
          z-index: 1;
          transform: translateY(-50%);
        }
      }
    }
  }
  &-words {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    &-block {
      position: relative;
      overflow: hidden;
      height: 100%;
    }
    &-space {
      height: 100%;
      margin: $space-large 0 0;
    }
    &-list {
      position: relative;
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - #{$space-large});
      padding: 0 $space-medium $space-small 0;
      z-index: 3;
      @include scrollbar;
    }
    &-item {
      display: inline-flex;
      align-items: center;
      width: 100%;
      margin: 0 0 $space-base;
      &:first-child {
        margin: $space-base 0 $space-base;
      }
      &:last-child:not(.toggle-words-leave-active, .toggle-words-enter-active) {
        margin: 0;
      }
      &-name {
        overflow: hidden;
        display: flex;
        align-items: center;
        width: adjustVW(120);
        margin: 0 $space-small 0 0;
      }
      &-bar {
        width: calc(100% - #{adjustVW(120) + $space-small});
        @include enterBar;
        &-inner {
          --width: 100%;
          position: relative;
          width: calc(var(--width));
          height: adjustVH(16);
          background: $line-emphasis;
          border-radius: adjustVW(4);
        }
        .toggle-words-leave-active & {
          @include leaveBar;
        }
      }
      &-tooltip {
        &-inner {
          width: fit-content;
          max-width: 100%;
        }
      }
    }
    &-bg {
      position: absolute;
      top: 0;
      right: $space-small;
      width: calc(100% - #{adjustVW(120) + $space-small});
      height: 100%;
      z-index: 2;
      &-scale {
        position: absolute;
        top: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0 0 $space-min;
        &-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: adjustVW(32);
          height: adjustVH(24);
          color: $text-sub;
          font-size: $text-min;
          @include text-crop;
        }
      }
      &-line {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: calc(100% - #{$space-large});
        margin: $space-large 0 0;
        &-item {
          position: relative;
          width: adjustVW(32);
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 1px;
            height: 100%;
            background: $line-gray;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}
.toggle-words-enter-active,
.toggle-words-leave-active {
  transition: transform $transition-base, opacity $transition-base;
}
.toggle-words-enter,
.toggle-words-leave-to {
  opacity: 0;
  transform: translateX(-$space-small);
  will-change: opacity, transform;
}
</style>
