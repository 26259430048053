<!--
  学習に使用する列の選択
-->
<template>
  <div class="figures-wrap">
    <div class="figures-top">
      <select-box
        class="figures-top-select-column"
        :title="$t('trainedAi.multi.selectedSettingColumn')"
        :value="selectedPredictionColumn"
        :items="valiablesSelect"
        isGray
        scrollBar
        @input="changePredictionColumn"
      />
      <texts
        class="figures-top-title"
        :text="$t('training.config.detail.selectInputColumns')"
        size="large"
      />
      <div class="figures-inner">
        <fuse-search
          ref="fuseSearch"
          class="figures-search"
          :placeholder="$t('training.config.detail.searchColumns')"
          :option="option"
          gray
          :value="items"
          @input="search($event)"
        />
        <div class="figures-target">
          <texts
            class="figures-target-title"
            :text="$t('training.config.detail.targetColumn')"
            size="small"
            color="gray"
          />
          <div class="figures-target-list">
            <texts
              v-for="column in predictionColumns"
              :key="column"
              :text="column"
              class="figures-target-item"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="figures-list">
      <list-item-slot
        class="figures-list-body"
        :field="field"
        :items="serachResults ? serachResults : items"
        :noSearchResults="noSearchResults"
      >
        <template v-slot:columnField>
          <label class="figures-list-name">
            <checkbox-base
              class="figures-list-name-check"
              :checked="checkedItems.length > 0 && validColumns.length > 0"
              :isShowMinusIcon="isNotAllChecked"
              @input="allCheck($event)"
            />
            <texts
              class="figures-list-name-text"
              :text="$t('training.config.detail.field.columnName')"
            />
          </label>
        </template>
        <template v-slot:column="entry">
          <label>
            <template>
              <figures-list-item
                :item="entry.item"
                :checkSpecifyColRecipe="checkSpecifyColRecipe"
                :checkedItems="currentCheckedItems"
                @onCheck="onCheck($event)"
              />
            </template>
          </label>
        </template>

        <template v-slot:statistics="entry">
          <div class="statistics">
            <div>
              <list-statistics
                :describe="selectedTrainingData.describe"
                :columnName="entry.entry.column.name"
              />
            </div>
            <div class="statistics-button">
              <div
                v-if="
                  entry.entry.column.reasons &&
                    entry.entry.column.reasons.length > 0 &&
                    !checkSpecifyColValid(entry.entry.column)
                "
                class="statistics-button-caution"
              >
                <icons
                  iconName="error"
                  color="caution"
                  @mouseenter.native="onShowReason(entry.entry.column.id)"
                  @mouseleave.native="onLeaveReason()"
                />
                <popup-balloon-input
                  :showPopup="showReason === entry.entry.column.id"
                  :isBottom="itemsCount - entry.entry.column.id < 3"
                >
                  <texts
                    v-if="checkValid(entry.entry.column)"
                    :text="$t('training.customblockConvertWarning')"
                    class="figures-list-reason-message"
                    color="caution"
                    size="small"
                  />
                  <template
                    v-for="(reason, index) in entry.entry.column.reasons"
                  >
                    <texts
                      :key="index"
                      :text="
                        checkShowReason(reason, checkValid(entry.entry.column))
                      "
                      class="figures-list-reason-message"
                      color="caution"
                      size="small"
                    />
                  </template>
                </popup-balloon-input>
              </div>
              <!--
              TODO graphデータの情報を早く取得できるようになったら修正 https://trello.com/c/9gkhqjLK
              <div>
                <icon-button
                  iconName="statistics"
                  :isDisabled='disabledStatistics(entry.entry.column.name) ? true : false'
                  :disabledReason="disabledStatistics(entry.entry.column.name)"
                  @click="$emit('show-statistics', entry.entry.column.name)"
                />
              </div>
              -->
            </div>
          </div>
        </template>
        <template
          v-if="Object.keys(featureImportance).length > 0"
          v-slot:featureImportance="entry"
        >
          <graph-bar-count
            v-if="entry.item && Object.keys(entry.item).length > 0"
            class="figures-feature"
            :count="checkFeature(entry.item, entry.entry.column.name)"
            :maxCount="firstFeatureImportance"
          />
        </template>
      </list-item-slot>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text.vue'
import icons from '@/components/atoms/icon.vue'
// import iconButton from '@/components/atoms/icon-button.vue'
// import images from '@/components/atoms/images.vue'
import figuresListItem from '@/components/organisms/training/config/list-type/figures-list-item.vue'
import fuseSearch from '@/components/molecules/fuse-search.vue'
import graphBarCount from '@/components/atoms/graph-bar-count'
import CheckboxBase from '@/components/atoms/checkbox-base.vue'
import popupBalloonInput from '@/components/atoms/popup-balloon-input.vue'
import listItemSlot from '@/components/molecules/list-item-slot'
import listStatistics from '@/components/molecules/list-statistics'
import selectBox from '@/components/molecules/select-box'

export default {
  components: {
    texts,
    icons,
    // iconButton,
    // images,
    figuresListItem,
    fuseSearch,
    graphBarCount,
    CheckboxBase,
    popupBalloonInput,
    listItemSlot,
    listStatistics,
    selectBox
  },
  data() {
    return {
      checkedItems: [],
      overridedItems: {},
      showReason: null,
      serachResults: null,
      noSearchResults: false,
      option: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.3,
        keys: [
          {
            name: 'column.name',
            weight: 1
          }
        ],
        distance: 10000
      },
      selectedPredictionColumn: null
    }
  },
  computed: {
    field() {
      // 重要度がない場合は重要度を表示しない
      if (Object.keys(this.featureImportance).length > 0) {
        return [
          {
            key: 'column',
            width: 320
          },
          {
            label: this.$t('training.config.detail.field.featureImportance'),
            key: 'featureImportance',
            width: 208
          },
          {
            label: this.$t('common.statistics.info.title'),
            key: 'statistics',
            width: 480
          }
        ]
      } else {
        return [
          {
            key: 'column',
            width: 420
          },
          {
            label: this.$t('common.statistics.info.title'),
            key: 'statistics',
            width: 480
          }
        ]
      }
    },
    // リスト系で表示できる形に変換するのと、学習に使用できない理由を紐づけ
    items() {
      const items = []
      for (const [key, value] of Object.entries(
        this.rawPredictionColumnOptions
      )) {
        if (!this.predictionColumns.includes(key)) {
          if (Object.keys(this.featureImportance).length > 0) {
            items.push({
              column: {
                name: key,
                valid: value.valid,
                reasons: value.reasons,
                mapping: value?.mapping ?? [],
                id: Object.keys(this.rawPredictionColumnOptions).indexOf(key)
              },
              featureImportance: this.featureImportance
            })
          } else {
            items.push({
              column: {
                name: key,
                valid: value.valid,
                reasons: value.reasons,
                mapping: value?.mapping ?? [],
                id: Object.keys(this.rawPredictionColumnOptions).indexOf(key)
              }
            })
          }
        }
      }
      return items
    },
    itemsCount() {
      return this.items.length
    },
    // 使用できない値を除外し、columnのみを対象にする
    validColumns() {
      const res = this.items.filter((item) => {
        return (
          this.checkValid(item.column) &&
          !this.predictionColumns.includes(item.column.name)
        )
      })
      return res.map((columnItem) => {
        return columnItem.column
      })
    },
    valiablesSelect() {
      const items = [
        {
          id: -1,
          name: '全ての列に共通に適用する',
          value: null
        }
      ]
      // 予測する列の選択
      for (const [key, value] of Object.entries(this.predictionColumns)) {
        items.push({
          id: key,
          name: value,
          value: value
        })
      }
      return items
    },
    featureImportance() {
      if (this.sortedTrainedAis.length === 0 || this.selectedColumnIndex === -1)
        return {}
      if (
        Object.keys(this.sortedTrainedAis[0]?.summary?.featureImportance)
          .length > 0
      ) {
        return this.sortedTrainedAis[0].summary.featureImportance
      } else {
        return {}
      }
    },
    firstFeatureImportance() {
      if (Object.keys(this.featureImportance).length > 0) {
        return Object.entries(this.featureImportance).sort(
          (x, y) => y[1] - x[1]
        )[0][1]
      } else {
        return null
      }
    },
    isNotAllChecked() {
      return this.validColumns.length !== this.checkedItems.length
    },
    checkSpecifyColRecipe() {
      if (!this.selectedRecipe?.body?.layers) return false
      const check = this.selectedRecipe.body.layers.some((item) => {
        return (
          item.name === 'specifyCol' && item.params.targetType.value === 'text'
        )
      })
      return check
    },
    currentCheckedItems() {
      // 選択中の列でのチェック状況
      const column = this.selectedPredictionColumn
      if (this.overridedItems[column] != null) {
        return this.overridedItems[column]
      } else {
        return this.checkedItems
      }
    }
  },
  methods: {
    onCheck({ check, item }) {
      const column = this.selectedPredictionColumn
      if (check) {
        // チェック入れる
        if (column == null) {
          this.checkedItems.push(item)
          for (const key in this.overridedItems) {
            this.overridedItems[key].push(item)
          }
        } else {
          if (this.overridedItems[column] == null) {
            this.$set(this.overridedItems, column, [...this.checkedItems])
          }
          this.overridedItems[column].push(item)
        }
      } else {
        // チェックはずす
        if (column == null) {
          const checkIndex = this.checkedItems.findIndex((check) => {
            return check.name === item.name
          })
          this.checkedItems.splice(checkIndex, 1)
          for (const key in this.overridedItems) {
            const index = this.overridedItems[key].findIndex((check) => {
              return check.name === item.name
            })
            if (index !== -1) {
              this.overridedItems[key].splice(index, 1)
            }
          }
        } else {
          if (this.overridedItems[column] == null) {
            this.$set(this.overridedItems, column, [...this.checkedItems])
          }
          const index = this.overridedItems[column].findIndex((check) => {
            return check.name === item.name
          })
          if (index !== -1) {
            this.overridedItems[column].splice(index, 1)
          }
        }
      }
      this.onUpdate()
    },
    allCheck(e) {
      const column = this.selectedPredictionColumn
      if (e) {
        // チェック入れる
        if (column == null) {
          this.checkedItems.splice(0, this.checkedItems.length)
          this.checkedItems.push(...this.validColumns)
          this.overridedItems = {}
        } else {
          if (this.overridedItems[column] == null) {
            this.$set(this.overridedItems, column, [...this.checkedItems])
          }
          this.overridedItems[column].splice(0, this.checkedItems.length)
          this.overridedItems[column].push(...this.validColumns)
        }
      } else {
        // チェックはずす
        if (this.selectedPredictionColumn == null) {
          this.checkedItems.splice(0, this.checkedItems.length)
        } else {
          if (this.overridedItems[column] == null) {
            this.$set(this.overridedItems, column, [])
          }
        }
      }
      this.onUpdate()
    },
    onShowReason(id) {
      this.showReason = id
    },
    onLeaveReason() {
      this.showReason = null
    },
    search(event) {
      if (event.value.length !== 0) {
        this.serachResults = event.result
      } else {
        this.serachResults = this.items
      }
      if (event.noSearchResults) {
        this.noSearchResults = true
      } else {
        this.noSearchResults = false
      }
    },
    checkValid(check) {
      if (check.valid) {
        return true
      } else {
        const checkSpecifyCol = this.checkSpecifyColValid(check)
        return checkSpecifyCol
      }
    },
    checkSpecifyColValid(check) {
      if (!this.checkSpecifyColRecipe) return false
      return check.reasons.length === 1 && check.reasons[0] === 'onlynum'
    },
    checkShowReason(reason, isWarning = false) {
      if (this.checkSpecifyColRecipe && reason === 'onlynum') {
        return null
      }
      if (isWarning) {
        return this.$t('training.warnings.' + reason)
      }
      return this.$t('training.reasons.' + reason)
    },
    checkFeature(feature, name) {
      return feature[name] ?? 0
    },
    changePredictionColumn(e) {
      this.selectedPredictionColumn = e
    },
    onUpdate() {
      const res = this.checkedItems.map((item) => {
        if (item.mapping != null && item.mapping.length > 0) {
          return [...item.mapping]
        } else {
          return item.name
        }
      })
      const resOverride = {}
      for (const key in this.overridedItems) {
        resOverride[key] = this.overridedItems[key]
          .map((item) => {
            if (item.mapping != null && item.mapping.length > 0) {
              return [...item.mapping]
            } else {
              return item.name
            }
          })
          .flat()
      }
      this.$emit('input', {
        value: res.flat(),
        valueOverride: resOverride,
        type: 'figure'
      })
    }
  },
  props: {
    selectedTrainingData: Object,
    rawPredictionColumnOptions: Object,
    sortedTrainedAis: Array,
    selectedRecipe: Object,
    selectedColumnIndex: Number,
    predictionColumns: Array
  }
}
</script>

<style lang="scss" scoped>
.figures {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-top {
    flex-shrink: 0;
    margin: 0 0 $space-sub;
    &-title {
      margin: 0 0 $space-sub;
    }
    &-select-column {
      width: adjustVW(320);
      height: fit-content;
      margin: 0 0 $space-sub;
    }
  }
  &-list {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    flex-shrink: 1;
    height: 100%;
    padding: 0 $space-base 0 0;
    @include scrollbar;
    &-name {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      &-check {
        flex-shrink: 0;
        width: adjustVW(24);
        padding: 0;
        margin: 0 $space-sub 0 0;
      }
      &-text {
        overflow: hidden;
        flex-shrink: 1;
        flex-grow: 1;
        width: 100%;
      }
    }
    &-body ::v-deep .list-item-entry-inner {
      overflow: visible;
    }
    &-reason-message {
      word-break: keep-all;
      white-space: pre-line;
    }
  }
  &-inner {
    display: grid;
    grid-template-columns: adjustVW(286) 1fr;
    grid-column-gap: $space-medium;
  }
  &-search {
    max-height: adjustVW(48);
    &::v-deep div {
      height: 100%;
    }
  }
  &-button {
    width: 100%;
    min-width: auto;
    &-list {
      overflow: hidden;
      display: flex;
    }
  }
  &-feature {
    width: 100%;
    z-index: 1;
  }
  &-target {
    overflow: hidden;
    width: 100%;
    &-title {
      margin-bottom: $space-text;
    }
    &-list {
      overflow-x: auto;
      display: flex;
      grid-column-gap: $space-small;
      padding-bottom: $space-text;
      @include scrollbar;
    }
    &-item {
      flex-shrink: 0;
      max-width: adjustVW(160);
    }
  }
}
.statistics {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: $space-small;
  &-button {
    display: flex;
    align-items: center;
    grid-column-gap: $space-small;
    &-caution {
      position: relative;
      cursor: help;
      &::v-deep .balloon {
        right: adjustVW(-20);
      }
    }
  }
  &-pop {
    &-caution {
      flex-shrink: 0;
      white-space: nowrap;
    }
  }
}
</style>
