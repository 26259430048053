var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clustering-distribution-wrapper"},[_c('transition-toggle-contents',[(_vm.selectItems.length > 1)?_c('div',{key:"on",staticClass:"clustering-distribution-item"},[_c('div',{staticClass:"clustering-distribution-item-body"},[_c('div',{staticClass:"clustering-distribution-item-list"},[_c('div',[_c('div',{staticClass:"clustering-distribution-item-title"},[_c('texts',{attrs:{"text":_vm.inputBoxText.xTarget,"size":"small","color":"gray"}})],1),_c('select-box-input',{staticClass:"clustering-distribution-item-input",attrs:{"value":_vm.setting.xTarget,"items":_vm.distributionItems(_vm.setting.yTarget),"placeholder":_vm.placeholderText,"isGray":"","isSearch":"","isScroll":""},on:{"select-item":function($event){return _vm.changeClusteringDistributionColumn(
                  $event.selectItem,
                  'xTarget'
                )}}})],1),_c('div',[_c('div',{staticClass:"clustering-distribution-item-title"},[_c('texts',{attrs:{"text":_vm.inputBoxText.yTarget,"size":"small","color":"gray"}})],1),_c('select-box-input',{staticClass:"clustering-distribution-item-input",attrs:{"value":_vm.setting.yTarget,"items":_vm.distributionItems(_vm.setting.xTarget),"placeholder":_vm.placeholderText,"isGray":"","isSearch":"","isScroll":""},on:{"select-item":function($event){return _vm.changeClusteringDistributionColumn(
                  $event.selectItem,
                  'yTarget'
                )}}})],1)]),_c('transition-toggle-contents',[(_vm.items && _vm.items.result && _vm.items.result.eps)?_c('div',{staticClass:"clustering-distribution-item-info"},[_c('texts',{attrs:{"text":_vm.$t('clustering.setting.distribution.eps') + _vm.items.result.eps,"size":"small","color":"gray"}}),_c('texts',{attrs:{"text":_vm.$t('clustering.setting.distribution.minSamples') +
                  _vm.items.result.minSamples,"size":"small","color":"gray"}})],1):_vm._e()])],1),_c('div',{staticClass:"clustering-distribution-item-graph"},[_c('clusterflow-distribution',{attrs:{"items":_vm.checkResult,"classes":_vm.classes,"xLabel":_vm.setting.xTarget,"yLabel":_vm.setting.yTarget,"loading":_vm.loading}})],1)]):_c('div',{key:"off",staticClass:"clustering-distribution-none"},[_c('texts',{staticClass:"clustering-distribution-none-text",attrs:{"text":_vm.noneText,"size":"small","color":"gray"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }