var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trained-ai-detail-main",class:{
    'trained-ai-detail-main-text-mining-multi':
      _vm.trainedAi.summary.textMining != null &&
      _vm.trainedAi.trainConfig.predictionColumn &&
      _vm.trainedAi.trainConfig.predictionColumn.length > 1
  }},[(_vm.noFeatureImportance)?[_c('DetailMetrics',{staticClass:"trained-ai-detail-accuracy l-column t-row",attrs:{"trainedAi":_vm.trainedAi}}),_c('DetailInfo',{staticClass:"l-column b-row trained-ai-detail-info-no-feature-importance",attrs:{"trainedAi":_vm.trainedAi}}),_c('div',{staticClass:"trained-ai-detail-sub r-column tb-row"},[_c('DetailPath',{attrs:{"trainedAi":_vm.trainedAi}})],1)]:(
      _vm.trainedAi.summary.textMining &&
        Object.keys(_vm.trainedAi.summary.textMining).length > 0
    )?[_c('div',{staticClass:"trained-ai-detail-text-mining-metrics",class:{
        'trained-ai-detail-text-mining-multi-metrics':
          _vm.trainedAi.trainConfig.predictionColumn &&
          _vm.trainedAi.trainConfig.predictionColumn.length > 1
      }},[(
          _vm.trainedAi.trainConfig.predictionColumn &&
            _vm.trainedAi.trainConfig.predictionColumn.length > 1
        )?_c('div',{staticClass:"trained-ai-detail-text-mining-multi-input"},[_c('select-column-box',{attrs:{"title":_vm.$t('trainedAi.multi.selectedColumnWordCloud'),"trainedAi":_vm.trainedAi,"finishColumns":_vm.finishColumns},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}})],1):_vm._e(),_c('DetailMetrics',{staticClass:"trained-ai-detail-accuracy l-column t-row",attrs:{"trainedAi":_vm.trainedAi,"selectedColumnIndex":_vm.selectedColumnIndex,"loadChangeColumn":_vm.loadChangeColumn,"isMulti":_vm.trainedAi.trainConfig.predictionColumn &&
            _vm.trainedAi.trainConfig.predictionColumn.length > 1}})],1),_c('div',{staticClass:"trained-ai-detail-text-mining"},[_c('DetailTextMiningList',{attrs:{"typeList":_vm.textMiningTypeList},on:{"go-text-mining":function($event){return _vm.$emit('go-text-mining', $event)}}})],1),_c('div',{staticClass:"trained-ai-detail-sub r-column tb-row"},[_c('DetailPath',{attrs:{"trainedAi":_vm.trainedAi}}),_c('DetailInfo',{attrs:{"trainedAi":_vm.trainedAi}})],1)]:(_vm.pageName === 'timeseries')?[_c('div',{staticClass:"trained-ai-detail-timeseries l-column tb-row"},[_c('div',{staticClass:"trained-ai-detail-timeseries-body"},[_c('div',{staticClass:"\n            trained-ai-detail-title-sub trained-ai-detail-title-sub-timeseries\n          "},[_c('texts',{attrs:{"text":_vm.$t('trainedAiDetails.timeseries.predictionResult'),"size":"large","isBold":""}})],1),(
            _vm.trainedAi.trainConfig.predictionColumn &&
              _vm.trainedAi.trainConfig.predictionColumn.length > 1
          )?_c('div',{staticClass:"trained-ai-detail-timeseries-select-box"},[_c('select-column-box',{attrs:{"finishColumns":_vm.finishColumns,"trainedAi":_vm.trainedAi},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}})],1):_vm._e(),_c('transition-toggle-contents',[(
              _vm.prediction &&
                _vm.prediction.length > 0 &&
                !_vm.loadChangeColumn &&
                _vm.checkColumn
            )?_c('timeseries',{attrs:{"series":_vm.prediction,"columnName":_vm.selectedItem,"height":_vm.trainedAi.trainConfig.predictionColumn.length > 0 ? 424 : 480}}):(_vm.loadChangeColumn)?_c('div',{staticClass:"trained-ai-detail-timeseries-loading"},[_c('loading')],1):(!_vm.loadingTabContent)?_c('div',{staticClass:"trained-ai-detail-timeseries-no-item"},[_c('texts',{attrs:{"text":_vm.$t('inference.result.timeseries.notFound'),"color":"gray"}})],1):_vm._e()],1)],1),(_vm.$recipeType.timeTransformer.includes(_vm.trainedAi.summary.type))?_c('div',{staticClass:"trained-ai-detail-timeseries-metrics"},[_c('timeseries-metrics',{attrs:{"trainedAi":_vm.trainedAi,"checkTimeseriesColumn":""}})],1):_vm._e()]),_c('div',{staticClass:"trained-ai-detail-sub r-column tb-row"},[(
          _vm.trainedAi.trainConfig &&
            _vm.trainedAi.trainConfig.predictionColumn &&
            _vm.trainedAi.trainConfig.predictionColumn.length > 0
        )?_c('DetailColumns',{attrs:{"trainedAi":_vm.trainedAi,"selectedColumnIndex":_vm.selectedColumnIndex}}):_vm._e(),_c('DetailPath',{staticClass:"trained-ai-detail-sub-path",attrs:{"trainedAi":_vm.trainedAi}}),_c('DetailInfo',{attrs:{"trainedAi":_vm.trainedAi}})],1)]:[(
        _vm.trainedAi.trainConfig &&
          _vm.trainedAi.trainConfig.predictionColumn &&
          _vm.trainedAi.trainConfig.predictionColumn.length > 1
      )?[_c('card-base',{staticClass:"trained-ai-detail-multi"},[_c('div',{staticClass:"trained-ai-detail-multi-input"},[_c('select-column-box',{attrs:{"title":_vm.$t('trainedAi.multi.selectedColumn'),"trainedAi":_vm.trainedAi,"finishColumns":_vm.finishColumns},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}})],1),_c('DetailMetrics',{staticClass:"trained-ai-detail-multi-metrics",attrs:{"trainedAi":_vm.trainedAi,"selectedColumnIndex":_vm.selectedColumnIndex,"loadChangeColumn":_vm.loadChangeColumn,"isMulti":""}}),_c('div',{staticClass:"trained-ai-detail-multi-line"}),_c('feature-importance',{staticClass:"trained-ai-detail-multi-importance",attrs:{"predictionColumns":_vm.trainedAi.summary.predictionColumns,"featureImportance":_vm.trainedAi.summary.featureImportance,"selectedColumnIndex":_vm.selectedColumnIndex,"loadFeatureImportance":_vm.loadChangeColumn,"maxItem":8}})],1)]:[_c('DetailMetrics',{attrs:{"trainedAi":_vm.trainedAi}}),_c('feature-importance',{staticClass:"l-column b-row trained-ai-detail-feature-importance",attrs:{"featureImportance":_vm.trainedAi.summary.featureImportance,"maxItem":7}})],_c('div',{staticClass:"trained-ai-detail-sub r-column tb-row",class:{
        'trained-ai-detail-sub-no-data': !(
          _vm.trainedAi.trainConfig &&
          _vm.trainedAi.trainConfig.predictionColumn &&
          _vm.trainedAi.trainConfig.predictionColumn.length > 0
        )
      }},[(
          _vm.trainedAi.trainConfig &&
            _vm.trainedAi.trainConfig.predictionColumn &&
            _vm.trainedAi.trainConfig.predictionColumn.length > 0
        )?_c('DetailColumns',{attrs:{"trainedAi":_vm.trainedAi,"selectedColumnIndex":_vm.selectedColumnIndex}}):_vm._e(),_c('DetailPath',{attrs:{"trainedAi":_vm.trainedAi}}),_c('DetailInfo',{attrs:{"trainedAi":_vm.trainedAi}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }