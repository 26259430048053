<template>
  <not-found :headerTabs="headerTabs" />
</template>

<script>
import notFound from '@/components/templates/not-found'

export default {
  components: {
    notFound
  },
  computed: {
    headerTabs() {
      return {
        // ヘッダーのタブ
        tabs: [],
        tabSelect: 1
      }
    }
  }
}
</script>
