<template>
  <card-base>
    <div class="config-wrap">
      <div class="config-body">
        <div v-if="columnList && columnList.length > 1" class="config-item">
          <div class="config-item-title">
            <texts
              :text="$t('trainedAi.multi.selectedColumn')"
              size="small"
              color="gray"
            />
          </div>
          <select-box
            v-model="internalColumnName"
            :items="columnList"
            isGray
            :isDisabled="waitTextMiningLoading"
          />
        </div>
        <div v-if="showFrequencies" class="config-item">
          <adjust-slider-input
            v-model.lazy="totalCount"
            :title="$t('common.nDataShown')"
            :max="maxCount"
            :min="1"
            :isDisabled="waitTextMiningLoading"
          />
        </div>
      </div>
    </div>
  </card-base>
</template>

<script>
import cardBase from '@/components/atoms/card-base'
import selectBox from '@/components/molecules/select-box'
import adjustSliderInput from '@/components/molecules/adjust-slider-input'

export default {
  components: {
    cardBase,
    selectBox,
    adjustSliderInput
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    columns: {
      type: Array,
      default: () => []
    },
    maxCount: {
      type: Number,
      default: 0
    },
    showFrequencies: {
      type: Boolean,
      default: false
    },
    columnName: {
      type: String,
      default: ''
    },
    waitTextMiningLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateValue(type, value) {
      const payload = this.value
      payload[type] = value
      this.$emit('input', payload)
    }
  },
  computed: {
    internalColumnName: {
      get() {
        return this.columnName
      },
      set(newVal) {
        this.$emit('change-column-name', newVal)
      }
    },
    totalCount: {
      get() {
        return this.value.count
      },
      set(newVal) {
        this.updateValue('count', newVal)
      }
    },
    columnList() {
      return this.columns.map((column) => {
        return {
          value: column,
          name: column
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.config {
  &-wrap {
    width: fit-content;
  }
  &-body {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(2, minmax(max-content, adjustVW(264)));
    grid-column-gap: $space-medium;
  }
  &-item {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    width: 100%;
    &-title {
      margin-bottom: $space-text;
    }
  }
}
</style>
