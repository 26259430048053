<template>
  <div
    :class="{
      'model-wrap': type === 'single' && displayThreshold,
      'model-wrap-multi': type === 'multi' && displayThreshold,
      'model-wrap-cm':
        type === 'single' && !displayThreshold && hasConfusionMatrix,
      'model-wrap-no-setting':
        type === 'single' && !(displayThreshold || hasConfusionMatrix)
    }"
  >
    <div class="model-setting">
      <accuracy-setting-block
        v-model.number="dataThreshold"
        :isMultiColumn="isMultiColumn"
        :changeableThreshold="changeableThreshold"
        :displayThreshold="displayThreshold"
        :trainedAi="trainedAiDetail"
        :selectedColumnIndex="selectedIndex"
        :loadChangeColumn="loadChangeColumn"
        :reversePositive="dataReversePositive"
        @change-threshold="changeThreshold"
        @reverse-positive="reversePositive"
      />
    </div>
    <div v-if="type === 'single'" class="model-info">
      <div class="model-info-name">
        <texts :text="summary.name" isBold />
      </div>
      <div class="model-info-description">
        <description :text="summary.description" />
      </div>
      <div
        v-if="summary.predictionColumns[selectedIndex]"
        class="model-info-target"
      >
        <text-with-title
          :title="$t('projectDetail.objectiveVariable')"
          :text="summary.predictionColumns[selectedIndex]"
        />
      </div>
      <!-- confusion matrixがなければ、名前などの情報と精度値をまとめて出す -->
      <div v-if="!displayConfusion && metricsList.length > 0">
        <div class="model-accuracy-list">
          <div
            v-for="(metrics, index) in metricsList"
            :key="index"
            class="model-accuracy-item"
          >
            <div class="model-accuracy-item-title">
              <texts size="small" color="gray" :text="metrics.name" />
            </div>
            <metrics-text
              :value="metrics.value"
              :percentage="metrics.percentage"
            />
          </div>
        </div>
      </div>
      <div
        v-if="trainedAiDetail.summary.type === 'NO_ACCURACY'"
        class="model-accuracy-none"
      >
        <texts
          :text="$t('trainedAiDetails.noAccuracy')"
          size="small"
          color="gray"
        />
      </div>
      <div class="model-info-feature">
        <card-fi
          checkShowFeatureImportance
          :predictionColumns="summary.predictionColumns"
          :featureImportance="summary.featureImportance"
          :type="summary.type"
          notCard
          :maxItem="4"
          minSize
        />
      </div>
    </div>
    <!-- confusion matrixがあれば、confusion matrixと精度値をまとめて出す -->
    <div v-if="displayConfusion" class="model-accuracy">
      <div class="model-accuracy-list">
        <div
          v-for="(metrics, index) in metricsList"
          :key="index"
          class="model-accuracy-item"
        >
          <div class="model-accuracy-item-title">
            <texts size="small" color="gray" :text="metrics.name" />
          </div>
          <metrics-text
            :value="metrics.value"
            :percentage="metrics.percentage"
          />
        </div>
      </div>
      <div class="model-accuracy-graph">
        <confusionPlot
          class="model-accuracy-graph-inner"
          :data="trainedAiDetail.result.test_confusion_matrix"
          :labels="confusionLabels"
          :propsWidth="264"
          :propsHeight="264"
        />
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import description from '@/components/atoms/description'
import metricsText from '@/components/atoms/metrics-text'

import textWithTitle from '@/components/molecules/text-with-title'

import cardFi from '@/components/organisms/card-fi'
import confusionPlot from '@/components/organisms/graph/confusion.vue'
import accuracySettingBlock from '@/components/organisms/trained-ai-common/accuracy-setting-block'

import { getModelType } from '@/lib/trainedAI.js'
import * as metricsDefsByModelType from '@/components/organisms/trained-ai-detail/metricsDefs.js'

export default {
  data() {
    return {
      dataThreshold: 0.5,
      dataReversePositive: false
    }
  },
  components: {
    texts,
    description,
    metricsText,
    textWithTitle,
    cardFi,
    confusionPlot,
    accuracySettingBlock
  },
  props: {
    trainedAiDetail: {
      type: Object,
      default: () => {}
    },
    selectedIndex: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'single'
    },
    columnSetting: {
      type: Object,
      default: () => {}
    },
    changeableThreshold: Boolean,
    displayThreshold: Boolean,
    hasConfusionMatrix: Boolean,
    loadChangeColumn: Boolean,
    isMultiColumn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    summary() {
      return this.trainedAiDetail?.summary
    },
    modelType() {
      const recipeType = this.trainedAiDetail?.summary?.type
      return getModelType(recipeType)
    },
    // components/organisms/trained-ai-detail/trained-ai-detai-tab-inner/trained-ai-detail-tab-accuracy.vue と同様に精度の設定
    metricsList() {
      let metricsDefs = metricsDefsByModelType[this.modelType](
        this.$t.bind(this)
      )

      if (this.modelType === 'images') {
        const isMultiClass = this.trainedAiDetail.mapping?.length > 2

        // 分類が 2 種類か、それ以上かで表示する精度評価を変更する。
        metricsDefs = isMultiClass
          ? metricsDefsByModelType.imagesMultiClass(this.$t.bind(this))
          : metricsDefsByModelType.images(this.$t.bind(this))

        // 推論のときは表示したくない精度指標があるので除去
        const metricsDefsKeyList = Object.keys(metricsDefs)
        const removeAccuracyList = ['train_accuracy', 'train_loss']

        metricsDefs = metricsDefsKeyList.reduce((acc, key) => {
          if (!removeAccuracyList.includes(key)) acc[key] = metricsDefs[key]

          return acc
        }, {})
      }

      const targetMetrics = this.summary.metrics[this.selectedIndex]

      const res = Object.entries(metricsDefs).reduce((prev, [key, value]) => {
        if (!targetMetrics[key]) return [...prev]
        const res = {
          name: value.name,
          value: targetMetrics[key],
          percentage: value.percentage
        }
        return [...prev, res]
      }, [])
      return res
    },
    displayConfusion() {
      return Boolean(this.trainedAiDetail?.result?.test_confusion_matrix)
    },
    confusionLabels() {
      let labels = null

      // 分類の場合の、混同行列のラベル情報をdeep copyで取得
      if (this.trainedAiDetail.mapping) {
        // ZIPテキストの場合
        labels = JSON.parse(JSON.stringify(this.trainedAiDetail.mapping))
      } else if (this.trainedAiDetail.result.test_confusion_matrix_label) {
        // テーブルデータの場合
        labels = JSON.parse(
          JSON.stringify(
            this.trainedAiDetail.result.test_confusion_matrix_label
          )
        )
      }

      if (labels) {
        // 陽性対象列変更時の順番変更
        if (this.dataReversePositive) {
          return labels.reverse()
        } else {
          return labels
        }
      }

      return null
    }
  },
  methods: {
    changeThreshold(e) {
      this.$emit('change-threshold', e)
    },
    reversePositive(e) {
      this.dataReversePositive = e
      this.$emit('reverse-positive', e)
    }
  },
  mounted() {
    this.dataThreshold = this.columnSetting.threshold
    this.dataReversePositive = this.columnSetting.reversePositive
  }
}
</script>

<style lang="scss" scoped>
$sideWidth: 312;

.model {
  &-wrap {
    display: grid;
    grid-template-areas:
      'setting setting'
      'info accuracy';
    grid-template-columns: adjustVW($sideWidth) 1fr;
    grid-column-gap: $space-medium;
    grid-row-gap: $space-large;
    &-multi {
      display: grid;
      grid-template-areas:
        'setting setting'
        'accuracy accuracy';
      grid-template-columns: adjustVW($sideWidth) 1fr;
      grid-column-gap: 0;
      grid-row-gap: $space-large;
    }
    &-cm {
      display: grid;
      grid-template-areas: 'info accuracy';
      grid-template-columns: adjustVW($sideWidth) 1fr;
      grid-column-gap: $space-medium;
    }
    &-no-setting {
      width: adjustVW($sideWidth);
    }
  }
  &-setting {
    grid-area: setting;
    &-title {
      display: flex;
      align-items: center;
      grid-column-gap: $space-sub;
      margin-bottom: $space-base;
    }
  }
  &-info {
    grid-area: info;
    &-name {
      margin-bottom: adjustVW(2);
    }
    &-description {
      margin-bottom: $space-sub;
    }
    &-target {
      margin-bottom: $space-small;
    }
  }
  &-accuracy {
    grid-area: accuracy;
    &-list {
      display: grid;
      grid-template-columns: repeat(2, calc((100% - #{$space-small}) / 2));
      grid-column-gap: $space-small;
      grid-row-gap: $space-small;
      height: fit-content;
      margin-bottom: $space-medium;
    }
    &-none {
      margin-bottom: $space-medium;
    }
    .model-wrap-multi & {
      display: grid;
      grid-template-columns: adjustVW($sideWidth) minmax(0, 1fr);
      grid-column-gap: $space-medium;
      width: 100%;
    }
    &-graph {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $space-small;
      background-color: $background-sub;
      border-radius: adjustVW(16);
    }
  }
}
</style>
