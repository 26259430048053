import { duplicateChecker, duplicateSuggests } from './misc'

/**
 * @param { Object } optimizationConditions
 * @param { Object } newOptimizationConditions
 * @returns { Object }
 **/
export function optimizationConditionsValidator(
  optimizationConditions,
  newOptimizationConditions
) {
  const nameList =
    optimizationConditions.length > 0
      ? optimizationConditions.map((condition) => condition.name)
      : []
  const newOptimizationConditionsName = newOptimizationConditions.name

  const checkResult = duplicateChecker(nameList, newOptimizationConditionsName)
  let suggests = []
  if (!checkResult) {
    suggests = duplicateSuggests(nameList, newOptimizationConditionsName)
  }

  return {
    duplicate: checkResult,
    suggests: suggests
  }
}
