<template>
  <div>
    <page-top
      :title="this.title"
      :iconName="this.iconName"
      notScroll
      size="min"
    />
  </div>
</template>

<script>
import pageTop from '@/components/atoms/page-top.vue'

export default {
  components: { pageTop },
  data() {
    return {
      title: this.$t('sidebar.project.titles.prediction'),
      iconName: 'inference'
    }
  }
}
</script>
