<template>
  <!--
    パスワード再設定ポップアップ
  -->
  <popup
    :title="$t('account.setting.info.popup.password.title')"
    :showPopup="showPopup"
    @close-modal="$emit('close-modal', $event)"
  >
    <reset-password
      :accountInfo="accountInfo"
      :showPopup="showPopup"
      @close-modal="$emit('close-modal', $event)"
    />
  </popup>
</template>

<script>
import { popup } from '@/components/molecules/popup'
import resetPassword from './popup/account-info-reset-password.vue'

export default {
  components: {
    popup,
    resetPassword
  },
  props: {
    showPopup: Boolean,
    accountInfo: Object
  },
  computed: {},
  methods: {}
}
</script>
