<template>
  <div class="datasource-code-wrap">
    <div>
      <texts
        :text="$t('datasetList.popup.showDatasource.sqlInputTitle')"
        size="small"
        color="gray"
      />
    </div>
    <label class="datasource-code-body">
      <PrismEditor
        class="datasource-code-inner"
        :value="value"
        :highlight="highlighter"
        :placeholder="$t('datasetList.popup.showDatasource.sqlInputTitle')"
        @input="$emit('input', $event)"
      />
    </label>
  </div>
</template>

<script>
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-sql'
import 'prismjs/themes/prism-coy.min.css'

export default {
  components: {
    PrismEditor
  },
  props: {
    value: String
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.sql)
    }
  }
}
</script>

<style lang="scss" scoped>
.datasource-code {
  &-wrap {
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-base;
    height: 100%;
  }
  &-body {
    height: 100%;
    padding: $space-small;
    background-color: $background-sub;
    border-radius: adjustVW(8);
    cursor: text;
  }
  &-inner {
    overflow-y: scroll;
    height: 100%;
    padding-right: $space-base;
    line-height: 1.5;
    font-family: Menlo, Consolas, 'DejaVu Sans Mono', monospace;
    font-size: $text-base;
    @include scrollbar;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: $white !important;
    border-radius: 9in;
  }
}
</style>
