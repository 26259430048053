var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"radio-button-list-wrap"},[(!_vm.noSearchResults)?[_c('transition-toggle-contents',[(!_vm.toggleMulti)?_c('div',{staticClass:"radio-button-list-body"},[(_vm.multiSelectMode)?_c('div',{staticClass:"radio-button-list-all-wrap"},[_c('checkbox-base',{staticClass:"radio-button-list-all",attrs:{"isLarge":"","text":_vm.$t('common.selectAll'),"checked":_vm.checked.length > 0,"isShowMinusIcon":_vm.isNotAllChecked,"isDisabled":_vm.checkDispCautionList && _vm.checkDispCautionList.length > 0},on:{"input":function($event){return _vm.allCheck($event)}}}),_c('div',{staticClass:"caution-popup-wrapper"},[_c('popup-balloon',{staticClass:"caution-popup-inner",attrs:{"isShow":_vm.checkDispCautionList && _vm.checkDispCautionList.length > 1,"showBottom":"","showCloseButton":false}},[_c('texts',{staticClass:"caution-popup-title",attrs:{"text":_vm.$t(
                    'selectTargetColumn.datasetInfo.list.caution.multiColumn'
                  )}}),_c('div',{staticClass:"caution-popup-main-item"},[_c('div',{staticClass:"caution-popup-main-item-column"},_vm._l((_vm.checkDispCautionList),function(item,key){return _c('texts',{key:key + '_objectValue',staticClass:"caution-popup-main-item-column-item",attrs:{"text":item,"color":"caution"}})}),1)]),_c('button-main',{staticClass:"caution-popup-checked-button",attrs:{"type":"emphasis","text":_vm.$t('selectTargetColumn.datasetInfo.list.caution.button')},on:{"click":_vm.confirmCautionAll}})],1)],1)],1):_vm._e(),_c('div',{staticClass:"radio-button-list-inner"},[_vm._l((_vm.values),function(value,index){return [(index % 3 === 0)?[_c('div',{key:index,staticClass:"radio-button-list"},[_vm._l((3),function(n,i){return [(!_vm.multiSelectMode)?[(_vm.values[index + i])?_c('div',{key:index + n,staticClass:"radio-button-list-item"},[_c('radio-button-base',{attrs:{"text":_vm.values[index + i],"name":"radioButton","checked":_vm.checkedItem[0] === _vm.values[index + i],"caution":_vm.cautionItems.indexOf(_vm.values[index + i]) !== -1},on:{"input":function($event){return _vm.updateValues(_vm.values[index + i])}}}),_c('div',{staticClass:"caution-popup-wrapper"},[_c('popup-balloon',{staticClass:"caution-popup-inner",attrs:{"isShow":_vm.showCautionMessage(index + i),"showTop":_vm.checkTop(index),"showBottom":!_vm.checkTop(index),"showCloseButton":false}},[_c('texts',{staticClass:"caution-popup-title",attrs:{"text":_vm.$t(
                                'selectTargetColumn.datasetInfo.list.caution.title'
                              )}}),_c('texts',{staticClass:"caution-popup-title-sub",attrs:{"color":"gray","size":"small","text":_vm.$t(
                                'selectTargetColumn.datasetInfo.list.caution.titleSub'
                              )}}),_c('div',{staticClass:"caution-popup-reason-message-wrapper"},[_vm._l((_vm.cautionMessage),function(item){return [(
                                  _vm.checkReason(_vm.values[index + i], item.value)
                                )?_c('div',{key:item.index,staticClass:"caution-popup-reason-message-item"},[_c('div',{staticClass:"caution-popup-reason-message-marker"}),_c('texts',{staticClass:"caution-popup-reason-message-text",attrs:{"size":"small","text":item.text}})],1):_vm._e()]})],2),_c('button-main',{staticClass:"caution-popup-checked-button",attrs:{"type":"emphasis","text":_vm.$t(
                                'selectTargetColumn.datasetInfo.list.caution.button'
                              )},on:{"click":function($event){return _vm.confirmCaution(_vm.values[index + i])}}})],1)],1)],1):_vm._e()]:[(_vm.values[index + i])?_c('div',{key:index + n,staticClass:"radio-button-list-checkbox"},[_c('checkbox-base',{attrs:{"isLarge":"","text":_vm.values[index + i],"checked":_vm.checkedItem.indexOf(_vm.values[index + i]) >= 0,"caution":_vm.cautionItems.indexOf(_vm.values[index + i]) !== -1,"isDisabled":_vm.checkCautionNotSelf(_vm.values[index + i])},on:{"input":function($event){return _vm.updateValues(_vm.values[index + i], $event)}}}),(
                          _vm.checkDispCautionList &&
                            !(_vm.checkDispCautionList.length > 1)
                        )?_c('div',{staticClass:"caution-popup-wrapper"},[_c('popup-balloon',{staticClass:"caution-popup-inner",attrs:{"isShow":_vm.showCautionMessage(index + i),"showTop":_vm.checkTop(index),"showBottom":!_vm.checkTop(index),"showCloseButton":false}},[_c('texts',{staticClass:"caution-popup-title",attrs:{"text":_vm.$t(
                                'selectTargetColumn.datasetInfo.list.caution.title'
                              )}}),_c('texts',{staticClass:"caution-popup-title-sub",attrs:{"color":"gray","size":"small","text":_vm.$t(
                                'selectTargetColumn.datasetInfo.list.caution.titleSub'
                              )}}),_c('div',{staticClass:"caution-popup-reason-message-wrapper"},[_vm._l((_vm.cautionMessage),function(item){return [(
                                  _vm.checkReason(_vm.values[index + i], item.value)
                                )?_c('div',{key:item.index,staticClass:"caution-popup-reason-message-item"},[_c('div',{staticClass:"caution-popup-reason-message-marker"}),_c('texts',{staticClass:"caution-popup-reason-message-text",attrs:{"size":"small","text":item.text}})],1):_vm._e()]})],2),_c('button-main',{staticClass:"caution-popup-checked-button",attrs:{"type":"emphasis","text":_vm.$t(
                                'selectTargetColumn.datasetInfo.list.caution.button'
                              )},on:{"click":function($event){return _vm.confirmCaution(_vm.values[index + i])}}})],1)],1):_vm._e()],1):_vm._e()]]})],2)]:_vm._e()]})],2)]):_vm._e()])]:_c('div',{staticClass:"radio-button-list-no-search-results"},[_c('images',{staticClass:"radio-button-list-no-search-results-image",attrs:{"imageName":"notFound"}}),_c('texts',{attrs:{"text":_vm.$t('selectTargetColumn.datasetInfo.noSearchResults')}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }