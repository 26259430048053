<template>
  <div class="inference-list">
    <div class="inference-list-body">
      <card-base class="inference-list-table">
        <transition-toggle-contents>
          <div v-if="inferenceListInfo.loading" class="inference-list-loading">
            <loading />
          </div>
          <div v-else>
            <table-list
              class="dataset-list-list"
              :items="inferenceListResult"
              :field="inferenceListFields"
              isButton
              defaultSortedBy="updatedAt"
              buttonIcon="popup"
              @list-click="$emit('show-inference', { inference: $event })"
            />
          </div>
        </transition-toggle-contents>
      </card-base>
    </div>
  </div>
</template>

<script>
import cardBase from '@/components/atoms/card-base'
import loading from '@/components/atoms/loading'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import tableList from '@/components/molecules/table-list'

export default {
  mounted() {
    this.$emit('load-inference-info')
  },
  props: {
    trainedAi: Object,
    inferenceListInfo: Object
  },
  components: {
    cardBase,
    loading,
    transitionToggleContents,
    tableList
  },
  computed: {
    inferenceListResult() {
      return this.inferenceListInfo.inferenceList
    },
    inferenceListFields() {
      return [
        {
          id: 0,
          key: 'name',
          label: this.$t('trainedAiDetails.inferenceList.name'),
          thClass: 'name',
          listType: 'name',
          sortable: true
        },
        {
          id: 1,
          key: 'updatedAt',
          label: this.$t('trainedAiDetails.inferenceList.updateTime'),
          thClass: 'default',
          listType: 'time',
          withTime: true,
          sortable: true
        },
        {
          id: 2,
          key: 'accountId',
          label: this.$t('trainedAiDetails.inferenceList.accountId'),
          thClass: 'large',
          listType: 'large',
          sortable: true
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.inference-list {
  overflow: hidden;
  height: 100%;
  &-body {
    display: grid;
    grid-template-areas:
      'table table'
      'recipe info';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(0, 1fr) adjustVW(148);
    grid-column-gap: $space-medium;
    grid-row-gap: $space-medium;
    height: 100%;
    padding-bottom: $space-medium;
    margin: 0 $space-medium;
  }
  &-table {
    grid-area: table;
  }
  &-recipe {
    grid-area: recipe;
  }
  &-info {
    grid-area: info;
  }
  &-loading {
    height: 100%;
  }
}
</style>
