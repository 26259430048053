<template>
  <div>
    <div class="sidebar">
      <div class="top">
        <div class="top-link">
          <button @click="goBack">
            <text-with-icon
              :text="
                projectId != null
                  ? $t('common.trainLeave.popup.leaveButton')
                  : $t('sidebar.train.backward')
              "
              iconName="backward"
              size="small"
            />
          </button>
        </div>
        <div v-if="projectInfo" class="detail">
          <texts class="title" :text="projectInfo.name" size="large" />
          <description :text="projectInfo.description" line="2" />
        </div>
        <div v-else-if="activeItem === 0" />
        <div v-else class="detail">
          <loading-icon />
        </div>
      </div>
      <div v-if="projectInfo || activeItem === 0" class="flow">
        <texts
          class="flow-title"
          :text="$t('sidebar.train.status.title')"
          size="small"
          color="gray"
        />
        <div class="list">
          <button
            v-tooltip.right="activeItem === 0 ? toolTipContents : null"
            class="item"
            :class="{
              'item-active': activeItem === 0,
              'item-passed': activeItem >= 0,
              'item-disabled': checkNotFound
            }"
            :event="activeItem <= 0 ? '' : 'click'"
            :disabled="checkNotFound"
            @click="sideberLinkClick({ name: 'selectMode' }, 0)"
          >
            <div class="item-main-wrap">
              <div class="item-count" :class="'item-count-' + checkColor(0)">
                <texts
                  v-if="activeItem <= 0"
                  text="1"
                  :color="activeItem === 0 ? 'function' : 'gray'"
                />
                <icons
                  v-else
                  class="item-icon-check"
                  iconName="check"
                  :size="16"
                  color="green"
                />
              </div>
              <div class="item-title-wrap">
                <texts
                  class="item-title"
                  :text="$t('sidebar.train.status.project.title')"
                  size="small"
                  :color="
                    activeItem === 0
                      ? 'emphasis'
                      : activeItem > 0
                        ? 'default'
                        : 'gray'
                  "
                  isBold
                />
                <icons
                  v-if="activeItem === 0"
                  iconName="info"
                  color="emphasis"
                  :size="22"
                />
              </div>
            </div>
            <div
              class="item-sub-wrap"
              :class="'item-sub-wrap-' + checkColor(0)"
            >
              <div v-if="activeItem === 0" class="item-inner-active">
                <texts
                  class="item-bar-title"
                  :text="statusList[activeLink][status].name"
                  size="min"
                  color="gray"
                />
                <div class="item-bar">
                  <graph-bar-count
                    :count="statusList[activeLink][status].count"
                    :maxCount="100"
                  />
                </div>
              </div>
              <div v-if="activeItem > 0" class="item-inner">
                <texts
                  class="item-inner-text"
                  :text="$t('sidebar.train.status.project.itemName')"
                  size="min"
                  color="gray"
                />
                <texts
                  :text="
                    projectInfo.sourceTemplate
                      ? projectInfo.sourceTemplate.name
                      : 'ー'
                  "
                  size="small"
                />
              </div>
            </div>
          </button>
          <button
            v-tooltip.right="activeItem === 1 ? toolTipContents : null"
            class="item"
            :class="{
              'item-active': activeItem === 1,
              'item-passed': activeItem >= 1,
              'item-disabled': checkNotFound
            }"
            :event="activeItem <= 1 ? '' : 'click'"
            :disabled="checkNotFound"
            @click="
              sideberLinkClick(
                { name: 'datasetSetting', params: { projectId: projectId } },
                1
              )
            "
          >
            <div class="item-main-wrap">
              <div class="item-count" :class="'item-count-' + checkColor(1)">
                <texts
                  v-if="activeItem <= 1"
                  text="2"
                  :color="activeItem === 1 ? 'function' : 'gray'"
                />
                <icons
                  v-else
                  class="item-icon-check"
                  iconName="check"
                  :size="16"
                  color="green"
                />
              </div>
              <div class="item-title-wrap">
                <texts
                  class="item-title"
                  :text="$t('sidebar.train.status.dataset.title')"
                  size="small"
                  :color="
                    activeItem === 1
                      ? 'emphasis'
                      : activeItem > 1
                        ? 'default'
                        : 'gray'
                  "
                  isBold
                />
                <icons
                  v-if="activeItem === 1"
                  iconName="info"
                  color="emphasis"
                  :size="22"
                />
              </div>
            </div>
            <div
              class="item-sub-wrap"
              :class="'item-sub-wrap-' + checkColor(1)"
            >
              <div v-if="activeItem === 1" class="item-inner-active">
                <texts
                  class="item-bar-title"
                  :text="statusList[activeLink][status].name"
                  size="min"
                  color="gray"
                />
                <div class="item-bar">
                  <graph-bar-count
                    :count="statusList[activeLink][status].count"
                    :maxCount="100"
                  />
                </div>
              </div>
              <div v-if="activeItem > 1" class="item-inner">
                <texts
                  class="item-inner-text"
                  :text="$t('sidebar.train.status.dataset.itemName')"
                  size="min"
                  color="gray"
                />
                <texts
                  v-if="progressTraining && !datasetLoading"
                  :text="getDatasetName(progressTraining.datasetId)"
                  size="small"
                />
                <div v-else-if="datasetLoading" class="item-inner-text-loading">
                  <loading-icon />
                </div>
                <texts v-else text="ー" size="small" />
              </div>
            </div>
          </button>
          <button
            v-if="!checklaterSelectTargetColumn"
            v-tooltip.right="activeItem === 2 ? toolTipContents : null"
            class="item"
            :class="{
              'item-active': activeItem === 2,
              'item-passed': activeItem >= 2,
              'item-disabled': checkDataset || checkNotFound,
              'item-passed-multi-column':
                progressTraining &&
                progressTraining.targetColumn &&
                progressTraining.targetColumn.length > 1
            }"
            :event="activeItem <= 2 ? '' : 'click'"
            :disabled="checkDataset || checkNotFound"
            @click="
              sideberLinkClick(
                {
                  name: 'selectTargetColumn',
                  params: {
                    projectId: projectId,
                    datasetId: progressTraining
                      ? progressTraining.datasetId
                      : ''
                  }
                },
                2
              )
            "
          >
            <div class="item-main-wrap">
              <div class="item-count" :class="'item-count-' + checkColor(2)">
                <texts
                  v-if="activeItem <= 2"
                  text="3"
                  :color="activeItem === 2 ? 'function' : 'gray'"
                />
                <icons
                  v-else
                  class="item-icon-check"
                  iconName="check"
                  :size="16"
                  color="green"
                />
              </div>
              <div class="item-title-wrap">
                <texts
                  class="item-title"
                  :text="$t('sidebar.train.status.selectTargetColumn.title')"
                  iconName="target"
                  size="small"
                  :color="
                    activeItem === 2
                      ? 'emphasis'
                      : activeItem > 2
                        ? 'default'
                        : 'gray'
                  "
                  isBold
                />
                <icons
                  v-if="activeItem === 2"
                  iconName="info"
                  color="emphasis"
                  :size="22"
                />
              </div>
            </div>
            <div
              class="item-sub-wrap"
              :class="'item-sub-wrap-' + checkColor(2)"
            >
              <div v-if="activeItem === 2" class="item-inner-active">
                <texts
                  class="item-bar-title"
                  :text="statusList[activeLink][status].name"
                  size="min"
                  color="gray"
                />
                <div class="item-bar">
                  <graph-bar-count
                    :count="statusList[activeLink][status].count"
                    :maxCount="100"
                  />
                </div>
              </div>
              <div v-if="activeItem > 2" class="item-inner">
                <texts
                  class="item-inner-text"
                  :text="$t('sidebar.train.status.selectTargetColumn.itemName')"
                  size="min"
                  color="gray"
                />
                <div v-if="progressTraining && progressTraining.targetColumn">
                  <div
                    v-for="index of Math.min(
                      progressTraining.targetColumn.length,
                      3
                    )"
                    :key="index"
                  >
                    <texts
                      :text="progressTraining.targetColumn[index - 1]"
                      size="small"
                    />
                  </div>
                  <texts
                    v-if="progressTraining.targetColumn.length > 3"
                    class="item-inner-other-columns"
                    :text="
                      $t(
                        'sidebar.train.status.selectTargetColumn.otherColumn',
                        { count: progressTraining.targetColumn.length - 3 }
                      )
                    "
                    size="min"
                    color="gray"
                  />
                </div>
                <texts v-else text="ー" size="small" />
              </div>
            </div>
          </button>
          <!-- ↓ サンプルデータセットを使用する場合は前処理を飛ばす -->
          <button
            v-tooltip.right="
              activeItem === preprocessingIndex ? toolTipContents : null
            "
            class="item"
            :class="{
              'item-active': activeItem === preprocessingIndex,
              'item-passed': activeItem >= preprocessingIndex,
              'item-disabled':
                checkDataset || checkOtherAccountDataset || checkNotFound
            }"
            :event="activeItem <= preprocessingIndex ? '' : 'click'"
            :disabled="
              checkDataset || checkOtherAccountDataset || checkNotFound
            "
            @click="
              sideberLinkClick(
                {
                  name: 'trainPreprocessing',
                  params: {
                    projectId: projectId,
                    type: '0',
                    id: progressTraining ? progressTraining.datasetId : ''
                  }
                },
                preprocessingIndex
              )
            "
          >
            <div class="item-main-wrap">
              <div
                class="item-count"
                :class="'item-count-' + checkColor(preprocessingIndex)"
              >
                <texts
                  v-if="activeItem <= preprocessingIndex"
                  :text="preprocessingIndex + 1"
                  :color="
                    activeItem === preprocessingIndex ? 'function' : 'gray'
                  "
                />
                <icons
                  v-else
                  class="item-icon-check"
                  iconName="check"
                  :size="16"
                  color="green"
                />
              </div>
              <div class="item-title-wrap">
                <texts
                  class="item-title"
                  :text="$t('sidebar.train.status.preprocessing.title')"
                  size="small"
                  :color="activeItem >= preprocessingIndex ? 'default' : 'gray'"
                  isBold
                />
                <icons
                  v-if="activeItem === preprocessingIndex"
                  iconName="info"
                  color="emphasis"
                  :size="22"
                />
              </div>
            </div>
            <div
              class="item-sub-wrap"
              :class="'item-sub-wrap-' + checkColor(preprocessingIndex)"
            >
              <div
                v-if="activeItem === preprocessingIndex"
                class="item-inner-active"
              >
                <texts
                  class="item-bar-title"
                  :text="statusList[activeLink][status].name"
                  size="min"
                  color="gray"
                />
                <div class="item-bar">
                  <graph-bar-count
                    :count="statusList[activeLink][status].count"
                    :maxCount="100"
                    bgWhite
                  />
                </div>
              </div>
              <div v-if="activeItem > preprocessingIndex" class="item-inner">
                <texts
                  class="item-inner-text"
                  :text="$t('sidebar.train.status.preprocessing.itemName')"
                  size="min"
                  color="gray"
                />
                <texts
                  v-if="
                    progressTraining &&
                      !datasetLoading &&
                      !checkOtherAccountDataset
                  "
                  :text="
                    getDatasetName(progressTraining.preprocessingDatasetId)
                  "
                  size="small"
                />
                <div
                  v-else-if="datasetLoading && !checkOtherAccountDataset"
                  class="item-inner-text-loading"
                >
                  <loading-icon />
                </div>
                <texts v-else text="ー" size="small" />
              </div>
            </div>
          </button>
          <button
            v-if="checklaterSelectTargetColumn"
            v-tooltip.right="activeItem === 3 ? toolTipContents : null"
            class="item"
            :class="{
              'item-active': activeItem === 3,
              'item-passed': activeItem >= 3,
              'item-disabled': checkDataset || checkNotFound,
              'item-passed-multi-column':
                progressTraining &&
                progressTraining.targetColumn &&
                progressTraining.targetColumn.length > 1
            }"
            :event="activeItem <= 3 ? '' : 'click'"
            :disabled="checkDataset || checkNotFound"
            @click="
              sideberLinkClick(
                {
                  name: 'selectTargetColumn',
                  params: {
                    projectId: projectId,
                    datasetId: progressTraining
                      ? progressTraining.preprocessingDatasetId
                        ? progressTraining.preprocessingDatasetId
                        : progressTraining.datasetId
                      : ''
                  }
                },
                3
              )
            "
          >
            <div class="item-main-wrap">
              <div class="item-count" :class="'item-count-' + checkColor(3)">
                <texts
                  v-if="activeItem <= 3"
                  text="4"
                  :color="activeItem === 3 ? 'function' : 'gray'"
                />
                <icons
                  v-else
                  class="item-icon-check"
                  iconName="check"
                  :size="16"
                  color="green"
                />
              </div>
              <div class="item-title-wrap">
                <texts
                  class="item-title"
                  :text="$t('sidebar.train.status.selectTargetColumn.title')"
                  iconName="target"
                  size="small"
                  :color="
                    activeItem === 3
                      ? 'emphasis'
                      : activeItem > 3
                        ? 'default'
                        : 'gray'
                  "
                  isBold
                />
                <icons
                  v-if="activeItem === 3"
                  iconName="info"
                  color="emphasis"
                  :size="22"
                />
              </div>
            </div>
            <div
              class="item-sub-wrap"
              :class="'item-sub-wrap-' + checkColor(3)"
            >
              <div v-if="activeItem === 3" class="item-inner-active">
                <texts
                  class="item-bar-title"
                  :text="statusList[activeLink][status].name"
                  size="min"
                  color="gray"
                />
                <div class="item-bar">
                  <graph-bar-count
                    :count="statusList[activeLink][status].count"
                    :maxCount="100"
                  />
                </div>
              </div>
              <div v-if="activeItem > 3" class="item-inner">
                <texts
                  class="item-inner-text"
                  :text="$t('sidebar.train.status.selectTargetColumn.itemName')"
                  size="min"
                  color="gray"
                />
                <div v-if="progressTraining">
                  <div
                    v-for="index of Math.min(
                      progressTraining.targetColumn.length,
                      3
                    )"
                    :key="index"
                  >
                    <texts
                      :text="progressTraining.targetColumn[index - 1]"
                      size="small"
                    />
                  </div>
                  <texts
                    v-if="progressTraining.targetColumn.length > 3"
                    class="item-inner-other-columns"
                    :text="
                      $t(
                        'sidebar.train.status.selectTargetColumn.otherColumn',
                        { count: progressTraining.targetColumn.length - 3 }
                      )
                    "
                    size="min"
                    color="gray"
                  />
                </div>
                <texts v-else text="ー" size="small" />
              </div>
            </div>
          </button>
          <!-- レシピがプロジェクト内に一つしかない場合はここを非表示（レシピ作成後に修正） -->
          <!-- 自分でAIを作成する場合は、レシピは必ず表示する(プロジェクト作成時にレシピを表示するフラグをもらう) -->
          <button
            v-tooltip.right="activeItem === 4 ? toolTipContents : null"
            class="item"
            :class="{
              'item-active': activeItem === 4,
              'item-passed': activeItem >= 4,
              'item-disabled': checkNotFound
            }"
            :event="activeItem <= 4 ? '' : 'click'"
            :disabled="checkNotFound"
            @click="
              sideberLinkClick(
                { name: 'trainRecipeList', params: { projectId: projectId } },
                4
              )
            "
          >
            <div class="item-main-wrap">
              <div class="item-count" :class="'item-count-' + checkColor(4)">
                <texts
                  v-if="activeItem <= 4"
                  text="5"
                  :color="activeItem === 4 ? 'function' : 'gray'"
                />
                <icons
                  v-else
                  class="item-icon-check"
                  iconName="check"
                  :size="16"
                  color="green"
                />
              </div>
              <div class="item-title-wrap">
                <texts
                  class="item-title"
                  :text="$t('sidebar.train.status.recipe.title')"
                  size="small"
                  :color="
                    activeItem === 4
                      ? 'emphasis'
                      : activeItem > 4
                        ? 'default'
                        : 'gray'
                  "
                  isBold
                />
                <icons
                  v-if="activeItem === 4"
                  iconName="info"
                  color="emphasis"
                  :size="22"
                />
              </div>
            </div>
            <div
              class="item-sub-wrap"
              :class="'item-sub-wrap-' + checkColor(4)"
            >
              <div v-if="activeItem === 4" class="item-inner-active">
                <texts
                  class="item-bar-title"
                  :text="statusList[activeLink][status].name"
                  size="min"
                  color="gray"
                />
                <div class="item-bar">
                  <graph-bar-count
                    :count="statusList[activeLink][status].count"
                    :maxCount="100"
                  />
                </div>
              </div>
              <div v-if="activeItem > 4" class="item-inner">
                <texts
                  class="item-inner-text"
                  :text="$t('sidebar.train.status.recipe.itemName')"
                  size="min"
                  color="gray"
                />
                <texts
                  v-if="progressTraining && !recipeLoading"
                  :text="getRecipeName(progressTraining.recipeId)"
                  size="small"
                />
                <div v-else-if="recipeLoading" class="item-inner-text-loading">
                  <loading-icon />
                </div>
                <texts v-else text="ー" size="small" />
              </div>
            </div>
          </button>
          <button
            v-tooltip.right="activeItem === 5 ? toolTipContents : null"
            class="item item-last"
            :class="{
              'item-active': activeItem === 5,
              'item-passed': activeItem >= 5,
              'item-disabled': checkNotFound
            }"
            :disabled="checkNotFound"
          >
            <div class="item-main-wrap">
              <div class="item-count" :class="'item-count-' + checkColor(5)">
                <texts
                  v-if="activeItem <= 5"
                  text="6"
                  :color="activeItem === 5 ? 'function' : 'gray'"
                />
                <icons
                  v-else
                  class="item-icon-check"
                  iconName="check"
                  :size="16"
                  color="green"
                />
              </div>
              <div class="item-title-wrap">
                <texts
                  class="item-title"
                  :text="$t('sidebar.train.status.train.title')"
                  size="small"
                  :color="
                    activeItem === 5
                      ? 'emphasis'
                      : activeItem > 5
                        ? 'default'
                        : 'gray'
                  "
                  isBold
                />
                <icons
                  v-if="activeItem === 5"
                  iconName="info"
                  color="emphasis"
                  :size="22"
                />
              </div>
            </div>
            <div
              class="item-sub-wrap"
              :class="'item-sub-wrap-' + checkColor(5)"
            >
              <div v-if="activeItem === 5" class="item-inner-active">
                <texts
                  class="item-bar-title"
                  :text="statusList[activeLink][status].name"
                  size="min"
                  color="gray"
                />
                <div class="item-bar">
                  <graph-bar-count
                    :count="statusList[activeLink][status].count"
                    :maxCount="100"
                  />
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
    <popup-array
      :showPopup="notFound ? ['preventTrainingStatus'] : showPopupItems"
      :popupDefs="popupDefs"
      :noClose="notFound"
      @close-modal="closePopup($event)"
    >
      <template #trainPreventLeave>
        <train-prevent-leave
          :linkTo="linkTo"
          :progressTraining="progressTraining"
          @not-prevent="$emit('not-prevent')"
          @close-modal="
            closePopup('trainPreventLeave'), $emit('restart-prevent')
          "
        />
      </template>
      <template #preventTrainingStatus>
        <prevent-training-status @not-prevent="$emit('not-prevent')" />
      </template>
    </popup-array>
  </div>
</template>

<script>
import texts from '@/components/atoms/text.vue'
import icons from '@/components/atoms/icon.vue'
import description from '@/components/atoms/description.vue'
import textWithIcon from '@/components/molecules/text-with-icon.vue'
import graphBarCount from '@/components/atoms/graph-bar-count'
import LoadingIcon from '@/components/atoms/loading-icon.vue'
import PopupArray from '@/components/molecules/popup/popup-array.vue'
import trainPreventLeave from '@/components/organisms/common-popups/train-prevent-leave.vue'
import preventTrainingStatus from '@/components/organisms/common-popups/prevent-training-status.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    texts,
    icons,
    description,
    textWithIcon,
    graphBarCount,
    LoadingIcon,
    PopupArray,
    trainPreventLeave,
    preventTrainingStatus
  },
  data() {
    const manualBaseUrl = this.$urls.manual
    return {
      // info の中身はダミー 直す予定
      // statusの渡し方に何かほかにいい案があればそれを採用したい、、今現在は特定のStringを渡す形式になっています、、
      /** propsのstatusの値はここを参照 */
      statusList: {
        /** 渡してほしい値 (modeSelect, select, confirm, setting) */
        projectTemplate: {
          /** プロジェクトの作成方法選択画面の時 */
          modeSelect: {
            name: this.$t('sidebar.train.status.project.modeSelect.name'),
            info: this.$t('sidebar.train.status.project.modeSelect.info'),
            count: 25,
            manual: manualBaseUrl + 'project-create' // マニュアルのページIDなどページを識別するための値が入ります
          },
          /** プロジェクトテンプレート一覧画面 */
          select: {
            name: this.$t('sidebar.train.status.project.select.name'),
            info: this.$t('sidebar.train.status.project.select.info'),
            count: 50,
            manual: manualBaseUrl + 'project-create' // マニュアルのページIDなどページを識別するための値が入ります
          },
          /** プロジェクト詳細画面 */
          confirm: {
            name: this.$t('sidebar.train.status.project.confirm.name'),
            info: this.$t('sidebar.train.status.project.confirm.info'),
            count: 75,
            manual: manualBaseUrl + 'project-create' // マニュアルのページIDなどページを識別するための値が入ります
          },
          /** プロジェクト設定のポップアップが出ているとき */
          setting: {
            name: this.$t('sidebar.train.status.project.setting.name'),
            info: this.$t('sidebar.train.status.project.setting.info'),
            count: 100,
            manual: manualBaseUrl + 'project-create' // マニュアルのページIDなどページを識別するための値が入ります
          }
        },
        /** 渡してほしい値 (select, confirm, upload) */
        datasetSetting: {
          /** データセット未選択状態 */
          select: {
            name: this.$t('sidebar.train.status.dataset.select.name'),
            info: this.$t('sidebar.train.status.dataset.select.info'),
            count: 33,
            manual: manualBaseUrl + 'train-dataset-setting' // マニュアルのページIDなどページを識別するための値が入ります
          },
          /** データセット選択状態 */
          confirm: {
            name: this.$t('sidebar.train.status.dataset.confirm.name'),
            info: this.$t('sidebar.train.status.dataset.confirm.info'),
            count: 100,
            manual: manualBaseUrl + 'train-dataset-setting' // マニュアルのページIDなどページを識別するための値が入ります
          },
          /** データセット選択状態 */
          import: {
            name: this.$t('sidebar.train.status.dataset.confirm.name'),
            info: this.$t('sidebar.train.status.dataset.import.info'),
            count: 66,
            manual: manualBaseUrl + 'train-dataset-setting' // マニュアルのページIDなどページを識別するための値が入ります
          },
          /** データセットインポート中 */
          importing: {
            name: this.$t('sidebar.train.status.dataset.importing.name'),
            info: this.$t('sidebar.train.status.dataset.importing.info'),
            count: 100,
            manual: manualBaseUrl + 'train-dataset-setting' // マニュアルのページIDなどページを識別するための値が入ります
          },
          /** データセットをアップロードまたはインポートしているとき */
          upload: {
            name: this.$t('sidebar.train.status.dataset.upload.name'),
            info: this.$t('sidebar.train.status.dataset.upload.info'),
            count: 100,
            manual: manualBaseUrl + 'train-dataset-setting' // マニュアルのページIDなどページを識別するための値が入ります
          }
        },
        /** 渡してほしい値 (select, confirm) */
        selectTargetColumn: {
          /** 予測する列未選択状態 */
          select: {
            name: this.$t(
              'sidebar.train.status.selectTargetColumn.select.name'
            ),
            info: this.$t(
              'sidebar.train.status.selectTargetColumn.select.info'
            ),
            count: 50,
            manual: manualBaseUrl + 'train-select-target-column' // マニュアルのページIDなどページを識別するための値が入ります
          },
          /** 予測する列選択状態 */
          confirm: {
            name: this.$t(
              'sidebar.train.status.selectTargetColumn.confirm.name'
            ),
            info: this.$t(
              'sidebar.train.status.selectTargetColumn.confirm.info'
            ),
            count: 100,
            manual: manualBaseUrl + 'train-select-target-column' // マニュアルのページIDなどページを識別するための値が入ります
          }
        },
        /** 渡してほしい値 (setting, confirm) */
        preprocessingSetting: {
          /** 前処理画面上で表示 */
          setting: {
            name: this.$t('sidebar.train.status.preprocessing.setting.name'),
            info: this.$t('sidebar.train.status.preprocessing.setting.info'),
            count: 50,
            manual: manualBaseUrl + 'train-preprocessing' // マニュアルのページIDなどページを識別するための値が入ります
          },
          /** 前処理を完了するポップアップが表示されている時 */
          confirm: {
            name: this.$t('sidebar.train.status.preprocessing.confirm.name'),
            info: this.$t('sidebar.train.status.preprocessing.confirm.info'),
            count: 100,
            manual: manualBaseUrl + 'train-preprocessing' // マニュアルのページIDなどページを識別するための値が入ります
          }
        },
        /** 渡してほしい値 (select, create, confirm) */
        recipeSetting: {
          /** レシピ一覧表示のとき */
          select: {
            name: this.$t('sidebar.train.status.recipe.select.name'),
            info: this.$t('sidebar.train.status.recipe.select.info'),
            count: 33,
            manual: manualBaseUrl + 'train-recipe' // マニュアルのページIDなどページを識別するための値が入ります
          },
          /** レシピを新規作成または編集中のとき */
          create: {
            name: this.$t('sidebar.train.status.recipe.create.name'),
            info: this.$t('sidebar.train.status.recipe.create.info'),
            count: 66,
            manual: manualBaseUrl + 'train-recipe' // マニュアルのページIDなどページを識別するための値が入ります
          },
          /** レシピ詳細画面のとき */
          confirm: {
            name: this.$t('sidebar.train.status.recipe.confirm.name'),
            info: this.$t('sidebar.train.status.recipe.confirm.info'),
            count: 100,
            manual: manualBaseUrl + 'train-recipe' // マニュアルのページIDなどページを識別するための値が入ります
          }
        },
        /** 渡してほしい値 (setting, train, select, confirm) */
        train: {
          /** 学習を実行するボタンを押していないとき */
          setting: {
            name: this.$t('sidebar.train.status.train.setting.name'),
            info: this.$t('sidebar.train.status.train.setting.info'),
            count: 25,
            manual: manualBaseUrl + 'train-training' // マニュアルのページIDなどページを識別するための値が入ります
          },
          /** 学習を実行するボタンを押して学習中の間 */
          train: {
            name: this.$t('sidebar.train.status.train.train.name'),
            info: this.$t('sidebar.train.status.train.train.info'),
            count: 50,
            manual: manualBaseUrl + 'train-training' // マニュアルのページIDなどページを識別するための値が入ります
          },
          /** 学習済みAIが作成されたときまたは、再学習のために使用する列を再選択しているとき */
          select: {
            name: this.$t('sidebar.train.status.train.select.name'),
            info: this.$t('sidebar.train.status.train.select.info'),
            count: 75,
            manual: manualBaseUrl + 'train-training' // マニュアルのページIDなどページを識別するための値が入ります
          },
          /** 学習済みAIを保存するポップアップが表示されているとき */
          confirm: {
            name: this.$t('sidebar.train.status.train.confirm.name'),
            info: this.$t('sidebar.train.status.train.confirm.info'),
            count: 100,
            manual: manualBaseUrl + 'train-training' // マニュアルのページIDなどページを識別するための値が入ります
          }
        }
      },
      infoActive: false,
      datasetName: '',
      showPopupItems: [],
      linkTo: 'selectMode'
    }
  },
  props: {
    /** ページ名（'projectTemplate', 'datasetSetting', 'selectTargetColumn', 'preprocessingSetting', 'recipeSetting', 'train'）を渡す */
    activeLink: String,
    /** 現在の進行状況を渡す dataの値を参照 */
    status: String,
    incorrectOrder: Boolean,
    projectInfo: Object,
    progressTraining: Object
  },
  computed: {
    ...mapGetters('recipes', ['recipes', 'recipeLoading']),
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('project', ['projectList', 'projectLoading']),
    activeItem() {
      let linkNames = []
      if (!this?.progressTraining?.laterSelectTargetColumn) {
        linkNames = [
          'projectTemplate',
          'datasetSetting',
          'selectTargetColumn',
          'preprocessingSetting',
          'recipeSetting',
          'train'
        ]
      } else {
        linkNames = [
          'projectTemplate',
          'datasetSetting',
          'preprocessingSetting',
          'selectTargetColumn',
          'recipeSetting',
          'train'
        ]
      }
      return linkNames.indexOf(this.activeLink)
    },
    preprocessingIndex() {
      if (!this?.progressTraining?.laterSelectTargetColumn) {
        return 3
      } else {
        return 2
      }
    },
    projectId() {
      if (this.$route?.params?.projectId == null) return null
      return parseInt(this.$route.params.projectId)
    },
    checkDataset() {
      if (!this.progressTraining) return false
      return this.progressTraining.unstructuredDataset
    },
    checklaterSelectTargetColumn() {
      if (!this.progressTraining) return false
      if (this.progressTraining?.laterSelectTargetColumn) {
        return true
      } else {
        return false
      }
    },
    checkOtherAccountDataset() {
      if (!this.accountInfo || !this.progressTraining) return false
      if (
        this.accountInfo.accountId !== this.progressTraining.datasetAccountId
      ) {
        return true
      }
      return false
    },
    toolTipContents() {
      return {
        content: `<div class="tooltip-item-mergin-small">${
          this.statusList[this.activeLink][this.status].info
        }</div><a class="tooltip-item-link" href="${
          this.statusList[this.activeLink][this.status].manual
        }" target="_blank" rel="noreferrer">${this.$t(
          'sidebar.train.status.manual.text'
        )}</a>
        `,
        classes: 'tooltip-long-text tooltip-sidebar',
        trigger: 'hover',
        show: this.infoActive,
        delay: { show: 200, hide: 200 },
        autoHide: false
      }
    },
    trainPreventLeaveTitle() {
      return this.$t('sidebar.train.linkTo.' + this.linkTo.name + '.title')
    },
    datasetId() {
      if (!this.progressTraining) return null
      if (this.progressTraining.preprocessingDatasetId) {
        return this.progressTraining.preprocessingDatasetId
      } else {
        return this.progressTraining.datasetId
      }
    },
    recipeId() {
      if (!this.progressTraining) return null
      return this.progressTraining.recipeId
    },
    notFoundDataset() {
      if (
        this.activeLink === 'projectTemplate' ||
        this.activeLink === 'datasetSetting'
      )
        return false
      if (Object.keys(this.datasetList).length === 0) return false
      return !(this.datasetId in this.datasetList)
    },
    notFoundRecipe() {
      if (this.activeLink !== 'train') return false
      if (Object.keys(this.recipes).length === 0) return false
      return !Object.values(this.recipes).find(
        (recipe) => recipe.id === this.recipeId
      )
    },
    notFound() {
      if (!this.progressTraining) return false
      return this.notFoundDataset || this.notFoundRecipe
    },
    checkNotFound() {
      return this.incorrectOrder || this.notFound
    },
    popupDefs() {
      return {
        trainPreventLeave: {
          title: this.trainPreventLeaveTitle
        },
        preventTrainingStatus: {
          title: this.$t('common.popup.preventTrainingStatus.title')
        }
      }
    },
    datasetList() {
      const result = {}
      this.projectList[this.projectId]?.listData?.forEach((item) => {
        result[item.id] = item
      })
      return result
    },
    datasetLoading() {
      return this.projectLoading
    }
  },
  methods: {
    closePopup(e) {
      // ポップアップを閉じる
      this.showPopupItems = this.showPopupItems.filter((n) => n !== e)
    },
    showPopup(e) {
      this.showPopupItems.push(e)
    },
    getDatasetName(id) {
      if (!id || Object.keys(this.datasetList).length < 1) return 'ー'
      const target = Object.values(this.datasetList).find((x) => x.id === id)
      if (!target) return 'ー'
      return target.name
    },
    getRecipeName(id) {
      if (!id || Object.keys(this.recipes).length < 1) return 'ー'
      const target = Object.values(this.recipes).find((x) => x.id === id)
      if (!target) return 'ー'
      return target.name
    },
    checkColor(index) {
      if (this.activeItem > index) {
        return 'green'
      } else if (this.activeItem === index) {
        return 'emphasis'
      } else {
        return 'gray'
      }
    },
    sideberLinkClick(linkTo, index) {
      if (this.activeItem === index) return
      this.showPopup('trainPreventLeave')
      this.linkTo = linkTo
    },
    goBack() {
      if (this.projectId != null) {
        this.$router.push({
          name: 'projectDetail',
          params: {
            projectId: this.projectId
          }
        })
      } else {
        this.$router.push({ name: 'projectList' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  position: sticky;
  top: 0;
  width: adjustVW(240); // 12.5%
  height: 100vh;
  background: $background;
  z-index: 1000;
}
.top {
  padding: 0 $space-small;
  &-link {
    display: flex;
    align-items: center;
    height: adjustVH(80);
    > a {
      overflow: hidden;
    }
  }
}
.detail {
  margin: 0 0 $space-medium;
}
.title {
  margin: 0 0 $space-text;
}
.flow-title {
  padding: 0 $space-small;
  margin: 0 0 $space-sub;
}
.item {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: adjustVW(96);
  padding: $space-small $space-small 0;
  text-align: left;
  pointer-events: none;
  cursor: pointer;
  transition: opacity $transition-base;
  &:last-of-type {
    .item-sub-wrap::before {
      content: inherit;
    }
  }
  &-main-wrap {
    display: flex;
    align-items: center;
    grid-column-gap: $space-sub;
    margin-bottom: $space-text;
  }
  &-sub-wrap {
    position: relative;
    padding-left: adjustVW(44);

    --multiHeight: 0;
    &::before {
      content: '';
      position: absolute;
      top: adjustVW(-4);
      left: adjustVW(15);
      width: adjustVW(2);
      height: adjustVW(64);
      background: $medium-gray;
    }
    &-green::before {
      background: $green;
    }
    &-emphasis::before {
      background: $key-color;
    }
    .item-passed-multi-column & {
      &::before {
        height: calc(100% + #{$space-small + adjustVW(4)});
      }
    }
  }
  &-count {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: adjustVW(32);
    height: adjustVW(32);
    background: $medium-gray;
    border-radius: 9in;
    &-green {
      background: $green-lite;
      box-shadow: 0 0 0 adjustVW(2) $green inset;
    }
    &-emphasis {
      background: $key-color;
    }
  }
  &-icon-check {
    &::v-deep svg path {
      stroke: $green;
      stroke-width: adjustVW(1);
    }
  }
  &-passed {
    pointer-events: auto;
    &:hover {
      opacity: 0.5;
    }
    &.item-disabled {
      &:hover {
        opacity: 1;
      }
    }
    &-multi-column {
      height: fit-content;
    }
  }
  &-active {
    cursor: help;
    &:hover {
      opacity: 1;
    }
  }
  &-last {
    margin: 0;
    &.item-passed:hover {
      background: $background;
    }
    &.item-active {
      cursor: pointer;
      &.item-disabled {
        cursor: not-allowed;
      }
    }
  }
  &-disabled {
    cursor: not-allowed;
  }
  &-bar {
    position: relative;
    z-index: 1;
    &-title {
      margin: 0 0 $space-text;
    }
  }
  &-inner {
    &-active {
      position: relative;
    }
    &-text {
      margin: 0 0 $space-min;
    }
    &-other-columns {
      margin-top: adjustVW(4);
    }
  }
  &-title {
    &-wrap {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
  &-link {
    display: block;
  }
  &-popup {
    top: calc(-100% - #{$space-text});
    left: calc(100% + #{adjustVW(32)});
    &::after {
      z-index: 0;
    }
    &-text {
      margin: 0 0 $space-sub;
      white-space: pre;
    }
    &-link {
      display: inline-block;
    }
  }
}
</style>
