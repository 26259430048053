import { render, staticRenderFns } from "./recipe-block.vue?vue&type=template&id=d2088b0e&scoped=true&"
import script from "./recipe-block.vue?vue&type=script&lang=js&"
export * from "./recipe-block.vue?vue&type=script&lang=js&"
import style0 from "./recipe-block.vue?vue&type=style&index=0&id=d2088b0e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2088b0e",
  null
  
)

export default component.exports