<template>
  <div
    class="detail"
    :class="{
      'detail-confidence-wrap': checkGraphProb,
      'detail-in-train': testInferencedColumn
    }"
  >
    <button class="backward" @click="$emit('close-detail')">
      <icons iconName="backward" size="small" />
      <texts :text="$t('inference.result.titleDetail')" size="small" />
    </button>
    <div class="title">
      <div class="title-inner">
        <text-with-title
          class="title-item"
          :title="$t('inference.result.detail.target')"
          :text="targetColumn"
        />
        <text-with-title
          v-if="testInferencedColumn"
          class="title-item"
          :title="$t('trainedAi.metrics.observations')"
          :text="table[showId][targetColumn]"
        />
        <text-with-title
          class="title-item"
          :title="
            testInferencedColumn
              ? $t('trainedAi.metrics.predictions')
              : $t('inference.result.detail.result')
          "
          :text="
            testInferencedColumn
              ? table[showId][testInferencedColumn]
              : table[showId][targetColumn]
          "
        />
      </div>
    </div>
    <div v-if="checkGraphProb" class="confidence">
      <texts
        class="confidence-title"
        :text="$t('inference.result.detail.confidenceDetail')"
        isBold
      />
      <div class="confidence-inner">
        <template v-for="(name, index) in Object.keys(graphProb[showId])">
          <div :key="index" class="confidence-item">
            <texts class="confidence-column" :text="name" size="small" />
            <graph-bar-count
              class="confidence-bar"
              :count="graphProb[showId][name]"
              :maxCount="1"
            />
            <texts
              :text="Math.ceil(graphProb[showId][name] * 10000) / 100 + '%'"
              size="small"
            />
          </div>
        </template>
      </div>
    </div>
    <div v-if="Object.keys(importances).length > 0" class="feature-importance">
      <div class="feature-importance-label">
        <texts
          class="feature-importance-label-item"
          :text="$t('inference.result.detail.featureImportance.column')"
          isBold
        />
        <texts
          class="feature-importance-label-item"
          :text="$t('inference.result.detail.featureImportance.value')"
          isBold
        />
        <texts
          class="feature-importance-label-item"
          :text="$t('inference.result.detail.featureImportance.Impact')"
          isBold
        />
      </div>
      <div class="feature-importance-list">
        <div
          v-for="([key, value], index) in Object.entries(importances)"
          :key="index"
          class="feature-importance-item"
        >
          <texts :text="key" size="small" />
          <texts :text="table[showId][key]" size="small" />
          <div class="feature-importance-graph">
            <div
              v-tooltip="{
                content: value,
                trigger: 'hover',
                delay: { show: 300, hide: 300 }
              }"
              class="feature-importance-graph-inner"
              :style="{
                '--width': scaleWidth(value) * -1,
                '--orign': value > 0 ? 'left' : 'right'
              }"
            />
          </div>
        </div>
      </div>
      <div class="feature-importance-grid">
        <div class="feature-importance-grid-line">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
        <div class="feature-importance-grid-scale">
          <texts
            class="feature-importance-grid-scale-item"
            :text="scaleTick(-3)"
            color="gray"
            size="13"
          />
          <texts
            class="feature-importance-grid-scale-item"
            :text="scaleTick(-2)"
            color="gray"
            size="13"
          />
          <texts
            class="feature-importance-grid-scale-item"
            :text="scaleTick(-1)"
            color="gray"
            size="13"
          />
          <texts
            class="feature-importance-grid-scale-item"
            text="0"
            color="gray"
            size="13"
          />
          <texts
            class="feature-importance-grid-scale-item"
            :text="scaleTick(1)"
            color="gray"
            size="13"
          />
          <texts
            class="feature-importance-grid-scale-item"
            :text="scaleTick(2)"
            color="gray"
            size="13"
          />
          <texts
            class="feature-importance-grid-scale-item"
            :text="scaleTick(3)"
            color="gray"
            size="13"
          />
        </div>
      </div>
    </div>
    <div v-else class="feature-importance">
      <texts
        class="feature-importance-text"
        :text="
          testInferencedColumn
            ? $t('graph.noShapValueTestData')
            : $t('graph.noShapValue')
        "
        size="small"
        color="gray"
        isShowAll
      />
    </div>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import texts from '@/components/atoms/text'
import graphBarCount from '@/components/atoms/graph-bar-count'
import textWithTitle from '@/components/molecules/text-with-title'
export default {
  components: {
    icons,
    texts,
    graphBarCount,
    textWithTitle
  },
  props: {
    targetColumn: String,
    table: Array,
    showId: Number,
    graphProb: Array,
    checkGraphProb: Boolean,
    importanceProb: Array,
    testInferencedColumn: String
  },
  computed: {
    sortedEntries() {
      return Object.entries(this.importanceProb[this.showId]).sort(
        (x, y) => Math.abs(y[1]) - Math.abs(x[1])
      )
    },
    maxScale() {
      return this.sortedEntries.length === 0
        ? 0
        : Math.ceil(Math.abs(this.sortedEntries[0][1] * 10)) / 10
    },
    importances() {
      return this.importanceProb[this.showId]
    }
  },
  methods: {
    scaleWidth(e) {
      if (e !== 0) {
        return e / this.maxScale
      } else {
        return 0
      }
    },
    scaleTick(e) {
      if (Math.abs(this.maxScale) < 0.45) {
        return Math.round((this.maxScale / 3) * e * 100) / 100
      } else {
        return Math.round((this.maxScale / 3) * e * 10) / 10
      }
    }
  }
}
</script>

<style scoped lang="scss">
$feature-graph-width: adjustVW(360);
$feature-graph-train-width: adjustVW(264);
$select-width: 184;
$base-grid: 1fr 1fr $feature-graph-width;
$train-grid: 1fr 1fr $feature-graph-train-width;

.detail {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: calc(
    100% - #{adjustVW(176) + adjustVW(80) + adjustVW($select-width) +
      $space-small * 2}
  );
  height: 100%;
  margin: 0 $space-small;
  &-confidence-wrap {
    width: calc(
      100% - #{adjustVW(176) + adjustVW(80) * 2 + adjustVW($select-width) +
        $space-small * 2}
    );
  }
  &.detail-in-train {
    width: calc(
      100% - #{adjustVW(176) * 2 + adjustVW(80) + adjustVW($select-width) +
        $space-small * 2}
    );
    &.detail-confidence-wrap {
      width: calc(
        100% - #{adjustVW(176) * 2 + adjustVW(80) * 2 + adjustVW($select-width) +
          $space-small * 2}
      );
    }
  }
}

.backward {
  display: flex;
  align-items: center;
  grid-column-gap: $space-base;
  margin-left: $space-base;
}

.title {
  padding: $space-sub $space-base;
  border-bottom: $border-main;
  &-inner {
    display: grid;
    grid-template-columns: $base-grid;
    grid-column-gap: $space-small;
    margin-right: $space-small;
    .detail-in-train & {
      grid-template-columns: $train-grid;
      grid-column-gap: $space-medium;
    }
  }
}
.confidence {
  display: flex;
  flex-basis: adjustVW(170);
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 1;
  min-height: adjustVW(170);
  padding: $space-sub $space-base;
  border-bottom: $border-main;
  &-title {
    flex-shrink: 0;
    margin: 0 0 $space-sub;
  }
  &-inner {
    overflow-y: scroll;
    height: 100%;
    padding: 0 $space-base 0 0;
    @include scrollbar;
  }
  &-item {
    display: grid;
    align-items: center;
    grid-template-columns: $base-grid;
    grid-column-gap: $space-small;
    margin: 0 0 $space-sub;
    .detail-in-train & {
      grid-template-columns: $train-grid;
    }
  }
  &-bar {
    z-index: 1;
  }
}
.feature-importance {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  min-height: fit-content;
  padding: $space-sub $space-base;
  &-label {
    display: grid;
    grid-template-columns: $base-grid;
    grid-column-gap: $space-small;
    margin: 0 $space-small $space-base 0;
    .detail-in-train & {
      grid-template-columns: $train-grid;
    }
  }
  &-list {
    position: relative;
    overflow-y: scroll;
    height: 100%;
    padding: 0 $space-base 0 0;
    @include scrollbar;
  }
  &-item {
    display: grid;
    grid-template-columns: $base-grid;
    grid-column-gap: $space-small;
    margin: 0 0 $space-large;
    .detail-in-train & {
      grid-template-columns: $train-grid;
    }
    &:first-of-type {
      margin: $space-small 0 $space-large;
    }
    &:last-of-type {
      margin: 0 0 $space-medium;
    }
  }
  &-graph {
    position: relative;
    z-index: 1;
    &-inner {
      --width: 0;
      --origin: right;
      position: absolute;
      top: 50%;
      left: $feature-graph-width / 9 / 2;
      width: calc(50% - #{$feature-graph-width / 9 / 2});
      height: adjustVW(16);
      background: $key-color;
      transform: translateY(-50%) scaleX(var(--width));
      transform-origin: var(--origin);
      transition: transform $transition-base;
      animation: showBar ease-in-out 1s;
      .detail-in-train & {
        left: $feature-graph-train-width / 9 / 2;
        width: calc(50% - #{$feature-graph-train-width / 9 / 2});
      }
    }
  }
  &-grid {
    position: absolute;
    top: adjustVW(46);
    right: $space-small + $space-base;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: $feature-graph-width;
    height: calc(100% - #{adjustVW(46)});
    .detail-in-train & {
      width: $feature-graph-train-width;
    }
    &-line {
      display: flex;
      justify-content: space-between;
      height: 100%;
      > div {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: $feature-graph-width / 9;
        height: 100%;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          width: adjustVW(1);
          height: 100%;
          background: $line-gray;
          transform: translateX(-50%);
        }
        .detail-in-train & {
          width: $feature-graph-train-width / 9;
        }
      }
    }
    &-scale {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: $space-base 0 0;
      background: #fff;
      z-index: 2;
      &-item {
        width: $feature-graph-width / 9;
        text-align: center;
        .detail-in-train & {
          width: $feature-graph-train-width / 9;
        }
      }
    }
  }
  &-text {
    white-space: pre-line;
  }
}

@keyframes showBar {
  0% {
    transform: translateY(-50%) scaleX(0);
  }
  100% {
    transform: translateY(-50%) scaleX(var(--width));
  }
}
</style>
