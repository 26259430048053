<template>
  <text-box :isError="!isInfo" :isAccept="isInfo" class="box-item">
    <texts
      class="box-title"
      :text="showTitle"
      :color="isInfo ? 'accept' : 'caution'"
      size="small"
    />
    <texts
      v-if="showMessage"
      class="box-text"
      :text="showMessage"
      :color="isInfo ? 'accept' : 'caution'"
      size="small"
    />
    <icons
      class="box-button"
      iconName="close"
      :color="isInfo ? 'accept' : 'caution'"
      size="small"
      isButton
      @icon-click="$emit('click-close')"
    />
  </text-box>
</template>

<script>
import textBox from '@/components/atoms/text-box'
import icons from '@/components/atoms/icon'

export default {
  components: {
    textBox,
    icons
  },
  props: {
    isInfo: Boolean,
    title: String,
    message: String
  },
  computed: {
    showTitle() {
      if (this.isInfo) {
        return this.$t('recipe.layerInfo.title.' + this.title)
      } else {
        return this.$t('recipe.connectionWarning.title.' + this.title)
      }
    },
    showMessage() {
      if (!this.message) return null
      if (this.isInfo) {
        return this.$t('recipe.layerInfo.reason.' + this.message)
      } else {
        return this.$t('recipe.connectionWarning.reason.' + this.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  &-item {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: $space-medium;
  }
  &-title {
    margin: 0 0 $space-base;
  }
  &-text {
    white-space: pre-line;
  }
  &-button {
    position: absolute;
    top: $space-sub;
    right: $space-sub;
    transition: opacity $transition-base;
    &:hover {
      opacity: 0.5;
    }
  }
}
</style>
