<template>
  <transition-toggle-contents>
    <trained-ai-detail-tab-detail
      v-if="activeTab === 'detail'"
      key="detail"
      :trainedAi="trainedAi"
      :selectedColumnIndex="selectedColumnIndex"
    />
    <trained-ai-detail-tab-expected-effect
      v-else-if="activeTab === 'expectedEffect'"
      key="expectedEffect"
      :expectedEffect="trainedAi.expectedEffect"
    />
    <trained-ai-detail-tab-accuracy
      v-else-if="activeTab === 'accuracy'"
      key="accuracy"
      :pageName="pageName"
      :trainedAi="trainedAi"
      :metricsDefs="metricsDefs"
      :selectedColumnIndex="selectedColumnIndex"
      @download-confusion-matrix="$emit('download-confusion-matrix', $event)"
    />
    <trained-ai-detail-tab-image-test-dataset
      v-else-if="activeTab === 'imageTestData'"
      key="imageTestData"
      :trainedAi="trainedAi"
      :testDatasetInfo="testDatasetInfo"
      @change-column="$emit('change-column', $event)"
      @change-test-dataset-page="$emit('change-test-dataset-page', $event)"
      @download-test-dataset="$emit('download-test-dataset', $event)"
    />
    <trained-ai-detail-tab-trained-info
      v-else-if="activeTab === 'trainedInfo'"
      key="trainedInfo"
      :trainedAi="trainedAi"
      :metricsDefs="metricsDefs"
      :loadChangeColumn="loadChangeColumn"
    />
  </transition-toggle-contents>
</template>

<script>
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import trainedAiDetailTabDetail from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-detail'
import trainedAiDetailTabExpectedEffect from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-expected-effect'
import trainedAiDetailTabAccuracy from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-accuracy'
import trainedAiDetailTabImageTestDataset from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-image-test-dataset'
import trainedAiDetailTabTrainedInfo from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-trained-info'

import { images, imagesMultiClass } from '../metricsDefs.js'

export default {
  components: {
    transitionToggleContents,
    trainedAiDetailTabDetail,
    trainedAiDetailTabExpectedEffect,
    trainedAiDetailTabAccuracy,
    trainedAiDetailTabImageTestDataset,
    trainedAiDetailTabTrainedInfo
  },
  props: {
    trainedAi: Object,
    tabs: Array,
    pageName: String,
    activeTab: String,
    activeMenu: String,
    selectedColumnIndex: Number,
    loadChangeColumn: Boolean,
    testDatasetInfo: Object
  },
  computed: {
    metricsDefs() {
      // 画像の分類の対象が 3 種類以上かを判定する。
      const isMultiClass = this.trainedAi.mapping.length > 2

      // 分類が 2 種類か、それ以上かで表示する精度評価を変更する。
      return isMultiClass
        ? imagesMultiClass(this.$t.bind(this))
        : images(this.$t.bind(this))
    }
  }
}
</script>
