<template>
  <div class="c-wrap">
    <sidebar v-bind="sidebar" />
    <div class="c-body-wrap">
      <header-tabs v-bind="headerTabs" />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="loadingSomething" />
          <account-info
            v-else
            v-bind="body"
            :accountInfo="accountInfo"
            :accountInfoLoading="accountInfoLoading"
            :ownModels="ownModels"
            :ownServices="ownServices"
            :ownProjects="ownProjects"
            @create="$emit('create')"
            @delete="$emit('delete')"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <hover-menu v-bind="hoverMenu" />
  </div>
</template>

<script>
import accountInfo from '@/components/organisms/account-info/account-info.vue'
import sidebar from '@/components/organisms/sidebar.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import transitionPage from '@/components/molecules/transition-page'
import loading from '@/components/atoms/loading.vue'
import TransitionToggleContents from '../molecules/transition-toggle-contents.vue'

export default {
  components: {
    accountInfo,
    sidebar,
    headerTabs,
    hoverMenu,
    transitionPage,
    loading,
    TransitionToggleContents
  },
  computed: {
    loadingSomething() {
      return (
        this.accountInfoLoading ||
        this.trainedAILoading ||
        this.loadingServices ||
        this.loading
      )
    }
  },
  props: {
    body: Object,
    sidebar: Object,
    headerTabs: Object,
    hoverMenu: Object,
    accountInfo: Object,
    accountInfoLoading: Boolean,
    loadingServices: Boolean,
    loading: Boolean,
    trainedAILoading: Boolean,
    ownModels: Number,
    ownServices: Number,
    ownProjects: Number
  }
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
}
</style>
