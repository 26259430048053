<template>
  <div class="trained-ai-test-dataset-graph-info">
    <text-with-icon
      class="trained-ai-test-dataset-graph-info-title"
      :text="$t('trainedAiDetails.testDatasetTab.title')"
      iconName="testDataset"
      color="green"
    />
    <div class="trained-ai-test-dataset-graph-info-desc-wrap">
      <texts
        v-for="(desc, key) in distributionDescs"
        :key="key"
        class="trained-ai-test-dataset-graph-info-desc"
        :text="desc"
        size="small"
        color="gray"
      />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import textWithIcon from '@/components/molecules/text-with-icon'

export default {
  components: {
    texts,
    textWithIcon
  },
  computed: {
    distributionDescs() {
      const target = Object.values(
        this.$t('trainedAiDetails.testDatasetTab.description')
      )
      return target
    }
  }
}
</script>

<style lang="scss" scoped>
.trained-ai-test-dataset-graph-info {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  width: 100%;
  height: fit-content;
  max-height: 100%;
  padding: $space-small;
  border: $border-green;
  background: $background;
  border-radius: adjustVW(12);
  box-shadow: $box-shadow-main;

  &-title {
    margin-bottom: $space-sub;
  }

  &-desc {
    margin-bottom: $space-sub;
    line-height: $line-height-base;
    word-break: break-all;
    white-space: pre-line;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-wrap {
      overflow-y: auto;
      margin-bottom: $space-sub;
      @include scrollbar;
    }
  }
}
</style>
