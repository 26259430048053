<template>
  <div class="result">
    <template v-if="textMining.activeTab != null">
      <text-mining-wrapper
        v-bind="textMining"
        :typeList="typeList"
        @load-text-mining="$emit('load-text-mining', $event)"
        @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
      />
    </template>
  </div>
</template>

<script>
import textMiningWrapper from '@/components/organisms/text-mining/wrapper'
import {
  TEXT_MINING_TYPE,
  TEXT_MINING_PAGE_TYPE,
  TEXT_MINING_TYPE_TO_PAGE_TYPE
} from '@/lib/text-mining'

export default {
  components: {
    textMiningWrapper
  },
  props: {
    textMining: Object,
    textMiningTypeList: Object
  },
  computed: {
    // 表示するテキストマイニングの種類をサイドバーの種類に変換する
    typeList() {
      return Object.entries(this.textMiningTypeList).reduce((prev, current) => {
        if (current[1]) {
          prev.push(current[0])
        }
        return prev
      }, [])
    }
  },
  mounted() {
    // 最初に表示されるタブの情報を、実行したテキストマイニングの種類に合わせて取得する
    if (this.textMining.activeTab != null) return

    // ダッシュボードから来ている場合はViewsでアクションを叩くため、実行しない
    if (this.textMining.fromDashboad) return

    const target = this.typeList
    let pageType = TEXT_MINING_PAGE_TYPE.FREQUENCIES_WORDCLOUD
    if (target.includes(TEXT_MINING_TYPE.SENTIMENTS)) {
      pageType = TEXT_MINING_TYPE_TO_PAGE_TYPE[TEXT_MINING_TYPE.SENTIMENTS]
    } else if (target.includes(TEXT_MINING_TYPE.LDA)) {
      pageType = TEXT_MINING_TYPE_TO_PAGE_TYPE[TEXT_MINING_TYPE.LDA]
    } else if (target.includes(TEXT_MINING_TYPE.CO_OCCURRENCE)) {
      pageType = TEXT_MINING_TYPE_TO_PAGE_TYPE[TEXT_MINING_TYPE.CO_OCCURRENCE]
      this.$emit('change-tab-text-mining', pageType)
      return
    }
    this.$emit('change-tab-text-mining', pageType)

    const value = this.textMining.params[pageType]
    const columnName = this.textMining.params.columnName
    this.$emit('load-text-mining', { pageType, value, columnName })
  }
}
</script>

<style lang="scss" scoped>
.result {
  overflow: hidden;
  height: 100%;
  padding: 0 $space-medium $space-medium;
}
</style>
