<template>
  <div class="service-detail-edit-wrap">
    <div class="service-detail-edit-inner">
      <div class="service-detail-edit-title">
        <texts :text="$t('serviceDetail.editService')" size="large" />
      </div>
      <div class="service-detail-edit-block">
        <input-box-validation
          v-model="name"
          :accept="validate.accept"
          :error="validate.error"
          :title="$t('serviceDetail.serviceName')"
          popupView
          isNameValidation
          :nameValidationSuggests="serviceFormValidate.suggests"
        />
      </div>
      <div class="service-detail-edit-block">
        <input-box
          v-model="description"
          isTextArea
          isGray
          :title="$t('serviceDetail.serviceDetail')"
        />
      </div>
      <div class="service-detail-edit-block-list">
        <div class="service-detail-edit-block-item">
          <div class="service-detail-edit-inner-title">
            <texts
              :text="$t('serviceList.popup.addNewService.AItoUse')"
              size="small"
              color="gray"
            />
          </div>
          <selectBox
            v-model="trainedAI"
            class="service-detail-edit-block-item-input"
            valueKey="id"
            :items="modelList"
            isGray
            scrollBar
            showTop
          />
        </div>
        <div class="service-detail-edit-block-item">
          <transition-toggle-contents>
            <div v-if="!isNotAvailableTrainedAi" key="loading">
              <div class="service-detail-edit-inner-title">
                <texts
                  :text="
                    $t(
                      'inference.settings.selectInferenceType.inferenceType.title'
                    )
                  "
                  size="small"
                  color="gray"
                />
              </div>
              <transition-toggle-contents>
                <select-box
                  v-if="
                    inferenceTypeOptions.length > 0 && !loadingTrainedAiDetail
                  "
                  v-model="type"
                  class="service-detail-edit-block-item-input"
                  :placeholder="
                    $t('serviceList.popup.addNewService.typePlaceholder')
                  "
                  :items="inferenceTypeOptions"
                  isGray
                  scrollBar
                  showTop
                />
                <loading-icon v-else />
              </transition-toggle-contents>
            </div>
            <div v-else key="caution" class="service-detail-edit-caution">
              <texts
                class="service-detail-edit-caution-text"
                :text="
                  $t('common.disabled.NOT_AVAILABLE_CUSTOMBLOCK_FOR_INFERENCE')
                "
                color="caution"
                size="min"
              />
            </div>
          </transition-toggle-contents>
        </div>
      </div>
      <div class="service-detail-edit-preproc">
        <checkbox-base
          v-tooltip="disabledTips"
          :text="$t('serviceList.popup.addNewService.selectPreprocessing')"
          class="service-detail-edit-preproc-check"
          :class="{
            'service-detail-edit-preproc-check-disabled': disabledTips !== null
          }"
          :checked="opened"
          :isDisabled="disabledTips !== null"
          @input="inputCheck"
        />
        <transition-toggle-slot-contents>
          <div v-if="opened" class="service-detail-edit-preproc-input-wrap">
            <div class="service-detail-edit-inner-title">
              <texts
                :text="
                  $t(
                    'serviceList.popup.addNewService.PreprocessingToUseOptional'
                  )
                "
                size="small"
                color="gray"
              />
            </div>
            <div>
              <select-box
                v-model="preprocessing"
                class="service-detail-edit-preproc-input"
                isGray
                scrollBar
                showTop
                valueKey="id"
                :items="preprocessingList"
                :placeholder="
                  $t(
                    'serviceList.popup.addNewService.PreprocessingToUsePlaceholder'
                  )
                "
                :title="
                  $t(
                    'serviceList.popup.addNewService.PreprocessingToUseOptional'
                  )
                "
                @select-box-open="$emit('select-box-get-preprocessings')"
              />
            </div>
          </div>
        </transition-toggle-slot-contents>
      </div>
      <div class="service-detail-edit-button-block">
        <div class="service-detail-edit-button-inner">
          <button-main
            type="emphasis"
            :isDisabled="disabledSubmit"
            :text="$t('serviceDetail.button.save')"
            @click="save"
          />
        </div>
        <div class="service-detail-edit-button-inner">
          <button-main
            type="sub"
            :text="$t('common.cancel')"
            @click="$emit('cancel')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import {
  inferenceTypeOptions,
  isNatualLanguage,
  preprocessAvailable,
  usedCustomblockList
} from '@/lib/inference.js'

import inputBox from '@/components/molecules/input-box'
import inputBoxValidation from '@/components/molecules/input-box-validation'
import buttonMain from '@/components/atoms/button-main.vue'
import transitionToggleSlotContents from '@/components/molecules/transition-toggle-slot-contents.vue'
import checkboxBase from '@/components/atoms/checkbox-base'
import selectBox from '@/components/molecules/select-box'
import texts from '@/components/atoms/text'
import LoadingIcon from '@/components/atoms/loading-icon.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    texts,
    selectBox,
    inputBox,
    inputBoxValidation,
    transitionToggleSlotContents,
    checkboxBase,
    buttonMain,
    LoadingIcon,
    transitionToggleContents
  },
  data() {
    return {
      name: this.service.name,
      description: this.service.description,
      trainedAI: this.service.trainedAI.id,
      trainedAIDetail: null,
      loadingTrainedAiDetail: false,
      preprocessing: this.service.preprocessing?.id,
      type: this.service.type,
      validate: {
        accept: null,
        error: null
      },
      opened: !!this.service.preprocessing?.id,
      isNotAvailableTrainedAi: false
    }
  },
  props: {
    service: Object,
    serviceFormValidate: Object,
    customblockList: Array
  },
  watch: {
    name: function (val) {
      this.$emit('input-edit-form', {
        type: 'service',
        form: {
          name: val
        }
      })
    },
    serviceFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    },
    trainedAI: function (val) {
      if (val == null) return
      this.loadTrainedAiDetail(val)
      this.type = this.inferenceTypeOptions[0].value
    },
    disableSelectPreprocess: function (boolean) {
      // 前処理を選択できないモデルの場合、無理やり前処理を選択できないようにする
      if (boolean) {
        this.opened = false
        this.preprocessing = null
      }
    }
  },
  async mounted() {
    await this.$waitConnected()
    if (!this.EDARecipes && !this.loadingEDARecipes) {
      this.fetchEDARecipeList()
    }
    this.loadTrainedAiDetail(this.trainedAI)

    this.$emit('input-edit-form', {
      type: 'service',
      form: {
        name: this.name
      }
    })
  },
  methods: {
    // TODO 本来はViewsでやるべき処理。他の処理がすべてこの階層で行われてしまっているためやむを得ずここで実装。リファクタリング対象
    ...mapActions('preprocessings', ['fetchEDARecipeList']),

    reset() {
      this.name = this.service.name
      this.description = this.service.description
      this.trainedAI = this.service.trainedAI
      this.preprocessing = this.service.preprocessing?.id
      this.type = this.service.type
    },
    save() {
      let preproc = null
      if (this.preprocessing) {
        preproc = {
          id: this.preprocessing,
          accountId: this.preprocessingList.find(
            (x) => x.id === this.preprocessing
          ).accountId
        }
      }
      this.$emit('save', {
        serviceId: this.service.serviceId,
        type: this.type,
        name: this.name,
        description: this.description,
        trainedAIId: this.trainedAI,
        edaRecipe: preproc
      })
    },
    // TODO 本来はViewsでやるべき処理。他の処理がすべてこの階層で行われてしまっているためやむを得ずここで実装。リファクタリング対象
    async loadTrainedAiDetail(id) {
      this.loadingTrainedAiDetail = true
      this.isNotAvailableTrainedAi = false
      const res = await this.$sendMessageAndReceive({
        action: 'getTrainedAIDetail',
        id: id,
        columnIndex: 0
      })
      const checkCustomblock = await this.checkCustomblockAvailable(res.value)
      if (checkCustomblock) {
        this.trainedAIDetail = null
        this.trainedAI = null
        this.loadingTrainedAiDetail = false
        this.isNotAvailableTrainedAi = true
        return
      }
      this.trainedAIDetail = res.value.result
      this.loadingTrainedAiDetail = false
    },
    inputCheck(val) {
      if (!val) this.preprocessing = null
      this.opened = val
    },
    async checkCustomblockAvailable(trainedAi) {
      const layerInfo = trainedAi?.result?.layer_info
      const usedCustomblockItems =
        layerInfo != null ? usedCustomblockList(layerInfo) : []
      if (usedCustomblockItems.length === 0) return false
      const checkCustomblock = usedCustomblockItems.some((target) => {
        return !this.customblockList.some((customblock) => {
          return customblock.customblock_id === target.customblock_id
        })
      })
      return checkCustomblock
    }
  },
  computed: {
    // TODO 本来はViewsでやるべき処理。他の処理がすべてこの階層で行われてしまっているためやむを得ずここで実装。リファクタリング対象
    ...mapGetters('preprocessings', ['EDARecipes', 'loadingEDARecipes']),
    ...mapGetters('project', ['projectList']),
    ...mapGetters('trainedAi', { getterTrainedAIs: 'trainedAIs' }),

    disabledSubmit: function () {
      if (this.name === '') return true
      if (this.validate.error) return true
      if (this.isNotAvailableTrainedAi) return true
      return false
    },
    modelList() {
      if (!this.trainedAIs) return []
      return Object.values(this.trainedAIs).sort((x, y) =>
        x.updateTime > y.updateTime ? -1 : 1
      )
    },
    preprocessingList() {
      let preprocessings = null
      if (this.project) {
        preprocessings = this.project.listEdaRecipe
      } else {
        preprocessings = this.EDARecipes ? Object.values(this.EDARecipes) : []
      }
      preprocessings = preprocessings.sort((x, y) =>
        x.updateTime > y.updateTime ? -1 : 1
      )
      return preprocessings
    },
    selectedTrainedAI() {
      if (!this.trainedAIs) return null
      if (!this.trainedAI) return null
      return this.trainedAIs[this.trainedAI]
    },
    inferenceTypeOptions() {
      if (!this.selectedTrainedAI) return []
      return this.selectedTrainedAI
        ? inferenceTypeOptions.bind(this)(
            this.selectedTrainedAI,
            this.checkNatualLanguage
          )
        : []
    },
    checkNatualLanguage() {
      if (!this.trainedAIDetail) return null
      return isNatualLanguage(this.trainedAIDetail)
    },
    project() {
      const projectId = this.$route.params?.projectId
      if (!projectId) return null
      return this.projectList[projectId]
    },
    trainedAIs() {
      if (this.project) {
        const res = {}
        this.project.listAIs?.forEach((item) => {
          res[item.id] = item
        })
        return res
      } else {
        return this.getterTrainedAIs
      }
    },
    disableSelectPreprocess() {
      return (
        this.selectedTrainedAI &&
        !preprocessAvailable(this.selectedTrainedAI, this.trainedAIDetail)
      )
    },
    disabledTips() {
      if (this.disableSelectPreprocess) {
        return {
          content: this.$t(
            'serviceList.popup.addNewService.enablePreprocessings'
          )
        }
      } else if (!this.checkPreprocessings) {
        return {
          content: this.$t('serviceList.popup.addNewService.noPreprocessings')
        }
      } else {
        return null
      }
    },
    checkPreprocessings() {
      return this.preprocessingList && this.preprocessingList.length > 0
    }
  }
}
</script>
<style lang="scss" scoped>
.service-detail-edit {
  &-wrap {
    width: 100%;
    height: 100%;
    padding: $space-small $space-base;
  }
  &-button-disabled {
    width: fit-content;
  }
  &-inner {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding: $space-base $space-small;
    @include scrollbar;
  }
  &-block {
    width: adjustVW(882);
    margin: 0 0 $space-medium;
    &-list {
      display: flex;
      width: adjustVW(882);
      margin-bottom: $space-medium;
    }
    &-item {
      width: calc(50% - #{$space-sub});
      margin-right: $space-medium;
      &:last-of-type {
        margin: 0;
      }
      &-input {
        height: auto;
      }
    }
  }
  &-title {
    margin: 0 0 $space-medium;
  }
  &-inner-title {
    margin: 0 0 $space-base;
  }
  &-button {
    &-block {
      display: flex;
    }
    &-inner {
      margin: 0 $space-small 0 0;
      &:last-child {
        margin: 0;
      }
    }
  }
  &-preproc {
    margin-bottom: $space-medium;
    &-check {
      width: fit-content;
    }
    &-input {
      width: adjustVW(882);
    }
  }
  &-caution {
    display: flex;
    align-items: center;
    height: 100%;
    &-text {
      white-space: pre-line;
    }
  }
}
</style>
