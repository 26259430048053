<template>
  <div class="tooltip">
    <texts
      class="tooltip-title"
      :text="
        $t('trainedAiDetails.metrics.whatIsMetrics', { name: metrics.name })
      "
      isBold
    />
    <texts
      class="tooltip-desc"
      color="gray"
      :size="15"
      :text="$t('trainedAiDetails.metrics.metricsInfo', { info: metrics.info })"
    />
    <!--
      TODO 精度評価の説明画像の設置
      https://trello.com/c/YmyXO0rV
    -->
    <template v-if="checkKey">
      <images class="tooltip-img" :imageName="checkKey" />
    </template>
    <div class="tooltip-button">
      <!--
      TODO 精度の上げ方を記載したブログ記事の作成
      https://trello.com/c/NDythXit
      <button-main
        v-close-popover
        class="tooltip-button-item"
        type="emphasis"
        :text="$t('trainedAiDetails.metrics.learnMetrics')"
        size="thin"
        :fontSize="14"
        isEllipse
      />
      -->
      <button-main
        v-close-popover
        class="tooltip-button-item"
        type="emphasis"
        :text="$t('common.contact.consultant')"
        size="thin"
        :fontSize="14"
        isEllipse
        @click="goContact"
      />
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main.vue'
import images from '@/components/atoms/images'

export default {
  components: {
    buttonMain,
    images
  },
  props: {
    /** metricsDefs内の各精度のオブジェクト */
    metrics: Object
  },
  methods: {
    goContact() {
      window.open(this.$urls.contactLink, '_blank')
    }
  },
  computed: {
    checkKey() {
      if (!this.metrics?.image) return false
      return this.metrics.image
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  width: adjustVW(496);
  &-title {
    margin-bottom: $space-base;
  }
  &-desc {
    margin-bottom: $space-sub;
    line-height: $line-height-medium;
    white-space: pre-line;
  }
  &-img {
    width: 100%;
    margin-bottom: $space-small;
  }
  &-button {
    &-item {
      height: adjustVW(32);
      line-height: 1;
      + .tooltip-button-item {
        margin-left: $space-small;
      }
    }
  }
}
</style>
