<!-- 学習中の画面でプロジェクト作成に戻るときに表示するポップアップの中身 -->
<template>
  <div class="reave-wrap">
    <texts class="reave-text" :text="linkToText" size="small" />
    <div v-if="preventText" class="reave-text">
      <texts
        v-for="(text, key) in preventText"
        :key="key"
        class="reave-text-prevent"
        :text="text.item"
        :color="text.type"
        size="small"
      />
    </div>
    <div class="reave-button">
      <button-main :text="linkToButton" type="emphasis" @click="goNextPage" />
      <button-main
        :text="$t('common.cancel')"
        type="sub"
        @click="$emit('close-modal')"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import texts from '@/components/atoms/text.vue'
import buttonMain from '@/components/atoms/button-main.vue'

export default {
  components: {
    texts,
    buttonMain
  },
  props: {
    /** 遷移先
     * name: routerのページ名
     * params: パラメータ
     */
    linkTo: Object,
    /** Localstorageに入れている学習の進行状況 */
    progressTraining: Object
  },
  computed: {
    linkToButton() {
      return this.$t('sidebar.train.linkTo.' + this.linkTo.name + '.button')
    },
    linkToText() {
      return this.$t('sidebar.train.linkTo.' + this.linkTo.name + '.text')
    },
    preventText() {
      const pageName = this.$route.name
      if (pageName === 'trainPreprocessing') {
        const preventText = []
        if (this.linkTo.name === 'selectTargetColumn') {
          preventText.push(
            {
              item: this.$t(
                'sidebar.train.preventText.trainPreprocessing.last'
              ),
              type: 'default'
            },
            {
              item: this.$t('preprocessing.applyingDataset.cautionPopup.reset'),
              type: 'caution'
            }
          )
        } else {
          preventText.push(
            {
              item: this.$t(
                'sidebar.train.preventText.trainPreprocessing.first'
              ),
              type: 'default'
            },
            {
              item: this.$t(
                'sidebar.train.preventText.trainPreprocessing.caution'
              ),
              type: 'caution'
            },
            {
              item: this.$t(
                'sidebar.train.preventText.trainPreprocessing.last'
              ),
              type: 'default'
            }
          )
        }
        return preventText
      } else if (this.$te('sidebar.train.preventText.' + pageName)) {
        return [
          {
            item: this.$t('sidebar.train.preventText.' + pageName),
            type: 'default'
          }
        ]
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapActions('models', ['deleteProgressTraining', 'setProgressTraining', 'resetTrainingStatus']),

    goNextPage() {
      if (this.linkTo.name === 'selectMode') {
        this.deleteProgressTraining({
          projectId: this.progressTraining.projectId
        })
      } else {
        this.resetTrainingStatus({
          status: this.progressTraining,
          setType: this.linkTo.name
        })
      }

      this.$emit('not-prevent')
      this.$router.push({
        name: this.linkTo.name,
        params: this.linkTo.params
      })
    }
  }
}
</script>

<style scoped lang="scss">
.reave {
  &-wrap {
    width: adjustVW(800);
  }
  &-text {
    margin: 0 0 $space-medium;
    white-space: pre-line;
    &-prevent {
      margin-bottom: $space-min;
      white-space: pre-line;
    }
  }
}
</style>
