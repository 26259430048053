<template>
  <transition-toggle-contents>
    <trained-ai-detail-tab-optimization-detail
      v-if="activeTab === 'detail'"
      key="detail"
      :accountInfo="accountInfo"
      :trainedAi="trainedAi"
      :optimizationInfo="optimizationInfo"
      :optimizationResult="optimizationResult"
      @change-page="$emit('change-page', $event)"
      @change-filter-value="$emit('change-filter-value', $event)"
      @close-optimization-result-tutorial="
        $emit('close-optimization-result-tutorial')
      "
      @download-result="$emit('download-result', $event)"
    />
    <trained-ai-detail-tab-optimization-result
      v-else-if="activeTab === 'optimizationResult'"
      key="expectedEffect"
      :accountInfo="accountInfo"
      :optimizationInfo="optimizationInfo"
      :optimizationResult="optimizationResult"
      :optimizationConditions="optimizationConditions"
      :isTraining="isTraining"
      @input-edit-form="$emit('input-edit-form', $event)"
      @save-edit-optimization-form="
        $emit('save-edit-optimization-form', $event)
      "
      @show-detail-optimization="$emit('show-detail-optimization', $event)"
      @show-delete-optimization="$emit('show-delete-optimization')"
      @change-page="$emit('change-page', $event)"
      @change-filter-value="$emit('change-filter-value', $event)"
      @download-result="$emit('download-result', $event)"
    />
    <trained-ai-detail-tab-accuracy
      v-else-if="activeTab === 'accuracy'"
      key="accuracy"
      :trainedAi="trainedAi"
      :metricsDefs="metricsDefs"
      :finishColumns="finishColumns"
      :selectedColumnIndex="selectedColumnIndex"
      :loadChangeColumn="loadChangeColumn"
      :regressionGraph="regressionGraph"
      @change-column="$emit('change-column', $event)"
      @select-regression-graph="$emit('select-regression-graph', $event)"
      @download-confusion-matrix="$emit('download-confusion-matrix', $event)"
    />
    <trained-ai-detail-tab-feature-importance
      v-else-if="activeTab === 'featureImportance'"
      key="featureImportance"
      :trainedAi="trainedAi"
      :featureImportanceScatter="featureImportanceScatter"
      :finishColumns="finishColumns"
      :selectedColumnIndex="selectedColumnIndex"
      @load-feature-importance="$emit('load-feature-importance')"
      @change-column="$emit('change-column', $event)"
    />
    <trained-ai-detail-tab-classic-test-dataset
      v-else-if="activeTab === 'classicTestData'"
      key="classicTestData"
      :trainedAi="trainedAi"
      :featureImportanceScatter="featureImportanceScatter"
      :finishColumns="finishColumns"
      :selectedColumnIndex="selectedColumnIndex"
      :loadChangeColumn="loadChangeColumn"
      :testDatasetInfo="testDatasetInfo"
      @change-column="$emit('change-column', $event)"
      @change-test-dataset-page="$emit('change-test-dataset-page', $event)"
      @download-test-dataset="$emit('download-test-dataset', $event)"
    />
    <trained-ai-detail-tab-trained-info
      v-else-if="activeTab === 'trainedInfo'"
      key="trainedInfo"
      :trainedAi="trainedAi"
      :metricsDefs="metricsDefs"
      :finishColumns="finishColumns"
      :selectedColumnIndex="selectedColumnIndex"
      :loadChangeColumn="loadChangeColumn"
      @change-column="$emit('change-column', $event)"
    />
  </transition-toggle-contents>
</template>

<script>
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import trainedAiDetailTabOptimizationDetail from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-optimization-detail'
import trainedAiDetailTabOptimizationResult from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-optimization-result'
import trainedAiDetailTabAccuracy from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-accuracy'
import trainedAiDetailTabFeatureImportance from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-feature-importance'
import trainedAiDetailTabClassicTestDataset from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-classic-test-dataset'
import trainedAiDetailTabTrainedInfo from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-trained-info'

import { regression } from '../metricsDefs.js'

export default {
  components: {
    transitionToggleContents,
    trainedAiDetailTabOptimizationDetail,
    trainedAiDetailTabOptimizationResult,
    trainedAiDetailTabAccuracy,
    trainedAiDetailTabFeatureImportance,
    trainedAiDetailTabClassicTestDataset,
    trainedAiDetailTabTrainedInfo
  },
  props: {
    accountInfo: Object,
    trainedAi: Object,
    featureImportanceScatter: Object,
    tabs: Array,
    activeTab: String,
    activeMenu: String,
    finishColumns: Array,
    selectedColumnIndex: Number,
    loadChangeColumn: Boolean,
    optimizationInfo: Object,
    optimizationResult: Object,
    optimizationConditions: Array,
    isTraining: Boolean,
    testDatasetInfo: Object,
    regressionGraph: Object
  },
  computed: {
    metricsDefs() {
      return regression(this.$t.bind(this))
    }
  }
}
</script>
