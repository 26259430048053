<template>
  <div class="timeseries-graph-wrapper">
    <timeseries :series="prediction" :columnName="selectedItem" />
  </div>
</template>

<script>
import timeseries from '@/components/organisms/graph/timeseries.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    timeseries
  },
  props: {
    forecast: Array,
    changepoints: Object,
    layerInfo: Array,
    acf: Array,
    pacf: Array,
    observations: Object,
    selectedItem: String
  },
  computed: {
    ...mapGetters('settings', ['themeColor']),

    prediction() {
      const forecast = this.forecast
      const observations = []
      let observationsFromForecast = false

      if (!forecast) return null

      if (forecast[0].y != null) {
        observationsFromForecast = true
      }

      const prediction = forecast.map((d, index) => {
        const tz = new Date().getTimezoneOffset() * 60000
        const date = new Date(d.ds + tz) // UTC
        const ob = this.observations[d.ds]

        if (observationsFromForecast) {
          observations.push({
            x: date,
            y: d.y,
            y_lower: d.y,
            y_upper: d.y
          })
        } else if (ob != null) {
          observations.push({
            x: date,
            y: ob,
            y_lower: ob,
            y_upper: ob
          })
        }
        let yhatLower, yhatUpper
        if (d.yhat_lower == null) {
          yhatLower = d.yhat
        } else {
          yhatLower = d.yhat_lower
        }
        if (d.yhat_lower == null) {
          yhatUpper = d.yhat
        } else {
          yhatUpper = d.yhat_upper
        }

        return {
          x: date,
          y: d.yhat,
          y_lower: yhatLower,
          y_upper: yhatUpper
        }
      })

      const fixPrediction = prediction.filter((value) => {
        return value.y != null
      })
      return [
        {
          label: this.$i18n.t('graph.timeseries.predictions'),
          data: fixPrediction,
          color: this.themeColor
        },
        {
          label: this.$i18n.t('graph.timeseries.observations'),
          data: observations,
          color: '#049185'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.timeseries-graph-wrapper {
  width: 100%;
  height: 100%;
}
</style>
