<template>
  <transition-toggle-contents>
    <div v-if="loadChangeColumn" class="timeseries-loading">
      <loading />
    </div>
    <div v-else class="timeseries-no-item">
      <texts
        :text="$t('inference.result.timeseries.notFound')"
        color="gray"
        :size="size"
      />
    </div>
  </transition-toggle-contents>
</template>

<script>
import loading from '@/components/atoms/loading.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  props: {
    loadChangeColumn: Boolean,
    size: {
      type: String,
      default: 'default'
    }
  },
  components: {
    loading,
    transitionToggleContents
  }
}
</script>

<style lang="scss" scoped>
.timeseries {
  &-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  &-no-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
</style>
