<template>
  <div class="clustering">
    <div class="clustering-tab">
      <tab-list
        :activeTabIndex="activeTabIndex"
        :tabs="tabs"
        @change-tab="changeTab"
      />
      <!--
        TODO
        テキストマイニング用の表示切替が入る
        現在は実行できないためなし
      -->
    </div>
    <div class="clustering-content">
      <transition-toggle-contents>
        <trained-ai-detail-tab-clustering-result
          v-if="activeTab === 'clusteringResult'"
          key="clusteringResult"
          :clusteringInfo="clusteringInfo"
          :clusteringSetting="clusteringSetting"
          :clusteringResult="clusteringResult"
          :clusteringDistributions="clusteringDistributions"
          :distributionItems="distributionItems"
          is-inference
          @input-clustering-setting="$emit('input-clustering-setting', $event)"
          @download-clustering-result="
            $emit('download-clustering-result', $event)
          "
          @change-clustering-distribution-column="
            $emit('change-clustering-distribution-column', $event)
          "
          @toggle-clustering-show-dimension="
            $emit('toggle-clustering-show-dimension', $event)
          "
        />
        <trained-ai-detail-tab-clustering-distribution
          v-else-if="activeTab === 'clusteringDistribution'"
          key="clusteringDistribution"
          :clusteringInfo="clusteringInfo"
          :clusteringSetting="clusteringSetting"
          :clusteringResult="clusteringResult"
          :clusteringDistributions="clusteringDistributions"
          :distributionItems="distributionItems"
          is-inference
          @input-clustering-setting="$emit('input-clustering-setting', $event)"
          @change-clustering-distribution-column="
            $emit('change-clustering-distribution-column', $event)
          "
          @toggle-clustering-show-dimension="
            $emit('toggle-clustering-show-dimension', $event)
          "
        />
      </transition-toggle-contents>
    </div>
  </div>
</template>

<script>
import tabList from '@/components/molecules/tab-list.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import trainedAiDetailTabClusteringResult from '@/components/organisms/trained-ai-detail/trained-ai-detai-tab-inner/trained-ai-detail-tab-clustering-result'
import trainedAiDetailTabClusteringDistribution from '@/components/organisms/trained-ai-detail/trained-ai-detai-tab-inner/trained-ai-detail-tab-clustering-distribution'

export default {
  components: {
    tabList,
    transitionToggleContents,
    trainedAiDetailTabClusteringResult,
    trainedAiDetailTabClusteringDistribution
  },
  data() {
    return {
      activeTab: 'clusteringResult',
      activeTabIndex: 0
    }
  },
  props: {
    clusteringInfo: Object,
    clusteringDistributions: Array,
    clusteringResult: Object,
    clusteringSetting: Object
  },
  computed: {
    tabs() {
      return [
        {
          id: 0,
          name: this.$t('clustering.result.tab.result'),
          value: 'clusteringResult',
          iconName: 'clusteringResult'
        },
        {
          id: 1,
          name: this.$t('clustering.result.tab.distribution'),
          value: 'clusteringDistribution',
          iconName: 'clusteringDistribution'
        }
      ]
    },
    distributionItems() {
      if (
        this.clusteringInfo == null ||
        (this.clusteringInfo.distributionInputList.length === 0 &&
          this.clusteringInfo.dimensionList.length === 0)
      )
        return {}
      let items = []
      if (this.clusteringSetting.setting.showDimension) {
        items = this.clusteringInfo.dimensionList.map((item) => {
          const splitedValue = item.split('dim_')[1]
          const name = this.$t('clustering.result.dimension', {
            dimension: splitedValue
          })
          return {
            name,
            value: item
          }
        })
      } else if (this.clusteringInfo.distributionInputList.length > 0) {
        items = this.clusteringInfo.distributionInputList.map((item) => {
          return {
            name: item,
            value: item
          }
        })
      }
      return {
        items,
        hasDimensionValue:
          this.clusteringInfo.dimensionList &&
          this.clusteringInfo.dimensionList.length > 0
      }
    }
  },
  methods: {
    changeTab(e) {
      this.activeTab = e
      this.activeTabIndex = this.tabs.find((tab) => {
        return tab.value === e
      }).id
    }
  }
}
</script>

<style lang="scss" scoped>
.clustering {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-row-gap: $space-small;
  height: 100%;
  &-tab {
    width: fit-content;
    padding: 0 $space-medium;
  }
}
</style>
