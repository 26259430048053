<template>
  <div class="nav-wrap" :class="{ 'nav-wrap-show': footerNavShow }">
    <div class="nav-inner">
      <div class="nav-title">
        <texts :text="title" size="sub-title" />
      </div>
      <div class="nav-item">
        <text-with-title
          :title="$t('projectTemplate.detail.wantToAchieve')"
          :text="goal"
        />
      </div>
      <div class="nav-item">
        <text-with-title
          :title="$t('projectTemplate.detail.predictedAndClassified')"
          :text="target"
        />
      </div>
      <div class="nav-item nav-item-last">
        <text-with-title
          :title="$t('projectTemplate.detail.industryOccupation')"
          :text="industry"
        />
      </div>
      <button-main
        class="nav-button"
        :text="$t('projectTemplate.detail.templateButton')"
        type="emphasis"
        @click="$emit('select-project')"
      />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import buttonMain from '@/components/atoms/button-main.vue'
import textWithTitle from '@/components/molecules/text-with-title'

export default {
  components: {
    texts,
    buttonMain,
    textWithTitle
  },
  props: {
    title: String,
    description: String,
    tags: Array,
    goal: String,
    target: String,
    industry: String,
    footerNavShow: Boolean
  }
}
</script>

<style lang="scss" scoped>
.nav {
  &-wrap {
    position: fixed;
    bottom: 0;
    left: adjustVW(240);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc(100% - #{adjustVW(240)});
    height: adjustVH(96);
    padding: 0 $space-medium;
    border-left: $border-main;
    background: $background;
    box-shadow: $box-shadow-hover;
    opacity: 0;
    z-index: 100;
    transform: translateY(adjustVH(96));
    transition: transform $transition-base ease-in-out, opacity $transition-base;
    &-show {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &-inner {
    display: flex;
    align-items: center;
    width: 100%;
  }
  &-title {
    overflow: hidden;
    margin: 0 $space-large 0 0;
  }
  &-item {
    width: adjustVW(256);
    padding: $space-base $space-small;
    margin: 0 $space-small 0 0;
    border-radius: adjustVW(8);
    box-shadow: 0 0 0 adjustVW(1) $line-gray;
    &-last {
      margin: 0 $space-medium 0 0;
    }
  }
  &-button {
    flex-shrink: 0;
  }
}
</style>
