<!-- 未使用ポップアップ 削除予定 -->
<template>
  <div class="wrap">
    <div class="caution-text">
      <texts
        class="caution-text-main"
        :text="
          $t('preprocessing.applyingDataset.cautionPopup.text', {
            columnName: columnName
          })
        "
      />
      <texts
        class="caution-text-item"
        :text="
          $t('preprocessing.applyingDataset.cautionPopup.number', {
            columnName: columnName
          })
        "
        size="small"
      />
      <texts
        class="caution-text-item"
        :text="$t('preprocessing.applyingDataset.cautionPopup.endText')"
        size="small"
      />
      <texts
        class="caution-text-item"
        :text="$t('preprocessing.applyingDataset.cautionPopup.reset')"
        color="caution"
        size="small"
      />
    </div>
    <div class="button-list">
      <button-main
        :text="
          $t('preprocessing.applyingDataset.cautionPopup.keepPreprocessing')
        "
        type="emphasis"
        @click="$emit('close-modal')"
      />
      <button-main
        :text="$t('preprocessing.applyingDataset.cautionPopup.backSelect')"
        type="sub"
        @click="$emit('back-column-select')"
      />
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import texts from '@/components/atoms/text'

export default {
  components: {
    buttonMain,
    texts
  },
  props: {
    columnName: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.button-list {
  margin: $space-sub 0 0;
}

.caution-text {
  margin-bottom: $space-medium;
  &-main {
    padding-bottom: $space-sub;
    margin-bottom: $space-sub;
    border-bottom: $border-main;
  }
  &-item {
    margin-bottom: $space-text;
    &:last-of-type {
      margin: 0;
    }
  }
}
</style>
