<template>
  <div v-if="projectInfo" class="top-body">
    <div class="top-summary">
      <div v-if="!editting" class="top-edit-box">
        <button class="top-title" @click="pageBack()">
          <text-with-icon
            class="top-title-text"
            :text="inputItem.name ? inputItem.name : title"
            iconName="backward"
            size="title"
          />
          <top-edit-icon
            v-tooltip="disabledTip"
            class="top-title-icon"
            :disabled="disabledEdit"
            :editLoading="editLoading"
            @click-edit="edit()"
          />
        </button>
        <description-long
          class="top-summary-desc"
          :text="inputItem.description ? inputItem.description : description"
          :title="inputItem.name ? inputItem.name : title"
          showHorizontal
        />
      </div>
      <top-edit-box-with-desc
        v-else
        v-model="inputItem"
        :accept="validate.accept"
        :error="validate.error"
        :nameValidationSuggests="projectFormValidate.suggests"
        :disabledSubmit="disabledSubmit"
        class="top-edit-box"
        @finish-edit="commit()"
      />
      <div class="top-tag-wrap">
        <button
          class="top-tag-edit-button"
          :disabled="disabledEdit"
          :class="{ 'top-tag-edit-button-disabled': disabledEdit }"
          @click="tagEditting = !tagEditting"
        >
          <text-with-icon
            v-tooltip="disabledTip"
            iconName="edit"
            :text="
              tagEditting
                ? $t('projectDetail.editFinishTag')
                : $t('projectDetail.editTag')
            "
            size="small"
            isButton
            :color="tagEditButtonColor"
          />
        </button>
        <tag-editable-list
          v-model="projectInfo.tags"
          class="top-tag-list"
          :edit="tagEditting && !disabledEdit"
          @add="$emit('tag-add', $event)"
          @remove="$emit('tag-remove', $event)"
        />
      </div>
    </div>
    <div class="top-detail">
      <text-button
        v-tooltip="disabledTip"
        class="top-delete"
        :text="$t('projectDetail.deleteProject')"
        iconName="delete"
        :isDisabled="disabledEdit"
        @click="$emit('delete-project')"
      />
      <card-base class="top-detail-card">
        <div class="top-detail-inner">
          <text-with-title
            v-if="projectInfo.sourceTemplate"
            class="top-detail-template"
            :title="$t('projectDetail.templateUsed')"
            :text="projectInfo.sourceTemplate.name"
          />
          <text-with-title
            v-else
            class="top-detail-template"
            :title="$t('projectDetail.templateUsed')"
            :text="$t('projectDetail.nonUseTemplate')"
          />
          <div class="top-detail-date">
            <text-with-title
              class="top-detail-date-item"
              :title="$t('common.createTime')"
            >
              <a-time :value="projectInfo.createTime" />
            </text-with-title>
            <text-with-title
              class="top-detail-date-item"
              :title="$t('common.updateTime')"
            >
              <a-time :value="projectInfo.updateTime" />
            </text-with-title>
          </div>
        </div>
        <div class="top-detail-inner">
          <text-with-title
            class="top-detail-author"
            :title="$t('common.owner')"
            :text="projectInfo.accountId"
          />
          <text-with-title
            v-if="accountInfo.organizationInfo"
            class="top-detail-author"
            :title="$t('common.organization')"
            :text="organization"
          />
        </div>
      </card-base>
    </div>
  </div>
</template>

<script>
import descriptionLong from '@/components/atoms/description-long.vue'
import cardBase from '@/components/atoms/card-base'
import TopEditIcon from '@/components/atoms/top-edit-icon.vue'
import textButton from '@/components/atoms/text-button.vue'
import textWithIcon from '@/components/molecules/text-with-icon.vue'
import textWithTitle from '@/components/molecules/text-with-title'
import tagEditableList from '@/components/molecules/tag-editable-list'
import TopEditBoxWithDesc from '@/components/molecules/top-edit-box-with-desc.vue'

export default {
  components: {
    descriptionLong,
    cardBase,
    TopEditIcon,
    textButton,
    textWithIcon,
    textWithTitle,
    tagEditableList,
    TopEditBoxWithDesc
  },
  data() {
    return {
      editting: false,
      edittingName: '',
      edittingDescription: '',
      tagEditting: false,
      inputItem: {
        name: '',
        description: ''
      },
      validate: {
        accept: null,
        error: null
      }
    }
  },
  props: {
    accountInfo: Object,
    projectFormValidate: Object,
    projectInfo: Object,
    editLoading: Boolean
  },
  computed: {
    organization() {
      if (this?.accountInfo?.organizationInfo?.name != null) {
        return this.accountInfo.organizationInfo.name
      } else {
        return this.$t('projectDetail.nonOrganization')
      }
    },
    title() {
      return this.projectInfo?.name
    },
    description() {
      return this.projectInfo?.description
    },
    disabledEdit() {
      if (this.accountInfo?.organizationInfo?.role === 'admin') return false
      if (this.accountInfo?.accountId && this.projectInfo?.accountId) {
        return this.accountInfo.accountId !== this.projectInfo.accountId
      } else {
        return false
      }
    },
    disabledSubmit: function () {
      if (this.inputItem.name === '') return true
      if (this.validate.error) return true

      return false
    },
    disabledTip() {
      return {
        content: this.$t('common.disabled.organization.otherAccountItem'),
        trigger: this.disabledEdit ? 'hover' : '',
        delay: { show: 200, hide: 200 }
      }
    },
    tagEditButtonColor() {
      if (this.disabledEdit) {
        return 'gray'
      } else {
        if (this.tagEditting) {
          return 'emphasis'
        } else {
          return 'default'
        }
      }
    }
  },
  watch: {
    inputItem: {
      deep: true,
      handler: function (form) {
        this.$emit('input-edit-form', {
          type: 'project',
          form
        })
      }
    },
    projectFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    }
  },
  methods: {
    edit() {
      this.inputItem.name = this.title
      this.inputItem.description = this.description
      this.editting = true

      this.$emit('input-edit-form', {
        type: 'project',
        form: this.inputItem
      })
    },
    commit() {
      if (
        this.inputItem.name !== this.title ||
        this.inputItem.description !== this.description
      ) {
        this.$emit('change-description', {
          name: this.inputItem.name,
          description: this.inputItem.description
        })
      }
      this.editting = false
    },
    pageBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  &-title {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    grid-column-gap: $space-base;
    width: 100%;
    max-width: adjustVW(960);
    margin: 0 0 $space-small;
    &:hover {
      opacity: 1;
    }
    &-text {
      overflow: hidden;
      width: auto;
    }
    &-icon {
      flex-shrink: 0;
    }
  }
  &-body {
    display: grid;
    grid-template-columns: minmax(auto, adjustVW(972)) minmax(auto, 1fr);
    grid-column-gap: $space-medium;
    padding-top: $space-medium;
    padding-right: $space-medium;
  }
  &-summary {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-sub;
    padding-left: $space-medium;
    &-desc {
      flex-shrink: 0;
    }
  }
  &-edit-box {
    margin-bottom: $space-base;
  }
  &-tag {
    &-wrap {
      overflow: hidden;
      display: flex;
      flex-shrink: 1;
      flex-grow: 1;
      align-items: flex-start;
      max-height: calc(#{adjustVH(32 * 2)} + #{$space-sub * 2 + $space-text});
      padding: 0 0 $space-min;
    }
    &-edit-button {
      margin-right: $space-small;
      &-disabled {
        cursor: not-allowed;
      }
    }
    &-list {
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      height: 100%;
      max-height: calc(#{adjustVH(32 * 2)} + #{$space-sub * 2 + $space-text});
      @include scrollbar;
    }
  }
  &-detail {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    &-card {
      display: flex;
      width: 100%;
      height: fit-content;
    }
    &-inner {
      width: calc(50% - #{$space-sub});
      margin-right: $space-medium;
      &:last-of-type {
        margin: 0;
      }
    }
    &-template {
      margin: 0 0 $space-small;
    }
    &-date {
      display: flex;
      &-item {
        width: 50%;
      }
    }
    &-author {
      margin-bottom: $space-small;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  &-delete {
    margin-right: adjustVW(64); // ヘルプアイコン分左に寄せている
  }
}
</style>
