<template>
  <div
    v-if="
      isObjectiveColumn &&
        graph[showColumn.name] &&
        graph[showColumn.name].unique &&
        showColumn.nNull == 0
    "
    class="apply-body-wrap"
  >
    <texts
      class="apply-body-text"
      color="caution"
      :text="$t('preprocessing.message.notAblePreprocessing')"
    />
  </div>
  <div v-else class="apply-body-wrap">
    <!-- 数値変換 -->
    <div
      v-if="
        !isObjectiveColumn ||
          !graph[showColumn.name] ||
          !graph[showColumn.name].unique
      "
      class="apply-body-content"
      :class="{ 'apply-body-content-on': toggleTarget === 'changeValues' }"
    >
      <button
        class="apply-body-content-button"
        @click="toggleItem('changeValues')"
      >
        <texts
          class="apply-body-content-button-text"
          :text="$t('preprocessing.applyingDataset.changeValues')"
        />
        <icons class="apply-body-content-button-icon" iconName="toggle" />
      </button>
      <div
        v-if="toggleTarget === 'changeValues'"
        class="apply-body-content-body"
      >
        <texts
          class="apply-body-content-text"
          size="small"
          isShowAll
          :text="$t('preprocessing.message.changeValues')"
        />
        <select-box
          v-model="selectedChangeNumsId"
          class="apply-body-content-box"
          :items="changeNumsChoices"
          isGray
          min
          :firstSelectItem="changeNumsChoices[0]"
          valueKey="id"
        />
        <!-- ラベルエンコーディング -->
        <div v-if="selectedChangeNumsId === 2" class="apply-body-content-label">
          <transition-toggle-contents>
            <div v-if="loadingDetailPage">
              <loading-icon />
            </div>
            <div v-else-if="targetGraph.unique">
              <texts
                class="apply-body-error-text"
                :text="$t('preprocessing.applyingDataset.labelValidUnique')"
                size="small"
                color="gray"
              />
            </div>
            <div v-else-if="targetGraph.lotUnique">
              <texts
                class="apply-body-error-text"
                :text="$t('preprocessing.applyingDataset.labelValidLotUnique')"
                size="small"
                color="gray"
              />
            </div>
            <div v-else class="apply-body-content-label-inner">
              <!-- 欠損値用 -->
              <div
                v-if="showColumn.nNull > 0"
                class="apply-body-content-label-row"
              >
                <div class="apply-body-content-label-row-index">
                  <texts
                    size="small"
                    :text="$t('preprocessing.applyingDataset.lacks')"
                    color="caution"
                  />
                </div>
                <div class="apply-body-content-label-row-input">
                  <input-box v-model="lackNum" number isGray />
                </div>
              </div>
              <!-- 欠損値以外 -->
              <div
                v-for="(value, key) in labelEncodingKey"
                :key="value"
                class="apply-body-content-label-row"
              >
                <div class="apply-body-content-label-row-index">
                  <texts size="small" :text="value" />
                </div>
                <div class="apply-body-content-label-row-input">
                  <input-box v-model="labelEncodingValue[key]" number isGray />
                </div>
              </div>
            </div>
          </transition-toggle-contents>
        </div>
        <button-main
          class="apply-body-content-submit"
          :text="$t('preprocessing.applyingDataset.changeButton')"
          :type="checkDisabled ? 'disabled' : 'emphasis'"
          :disabled="checkDisabled"
          @click="applyChangeNums()"
        />
      </div>
    </div>
    <!-- 欠損値変換 -->
    <div
      v-if="showColumn.nNull > 0"
      class="apply-body-content"
      :class="{ 'apply-body-content-on': toggleTarget === 'changeLacks' }"
    >
      <button
        class="apply-body-content-button"
        @click="toggleItem('changeLacks')"
      >
        <texts
          class="apply-body-content-button-text"
          :text="$t('preprocessing.applyingDataset.changeLacks')"
          color="caution"
        />
        <icons class="apply-body-content-button-icon" iconName="toggle" />
      </button>
      <div
        v-if="toggleTarget === 'changeLacks'"
        class="apply-body-content-body"
      >
        <div class="apply-body-content-missing-value">
          <texts
            :text="$t('preprocessing.applyingDataset.quantity') + ' : '"
            size="small"
          />
          <texts
            class="apply-body-content-missing-value-count"
            :text="showColumn.nNull"
            size="small"
            color="caution"
          />
        </div>
        <texts
          class="apply-body-content-text"
          size="small"
          isShowAll
          :text="$t('preprocessing.message.dealNull')"
        />
        <select-box
          class="apply-body-content-box"
          :items="changeLacks"
          isGray
          min
          @select-item="selectLacks($event)"
        />
        <div v-if="changeLacksId === 0">
          <button-main
            class="apply-body-content-submit"
            :text="$t('preprocessing.applyingDataset.dropNull')"
            type="emphasis"
            @click="$emit('drop-null')"
          />
        </div>
        <div v-else-if="changeLacksId === 1">
          <texts
            class="apply-body-content-text"
            size="small"
            :text="$t('preprocessing.applyingDataset.fillNull')"
            isBold
          />
          <input-box
            v-model="paddingNum"
            class="apply-body-content-input"
            number
            isGray
          />
          <button-main
            class="apply-body-content-submit"
            :text="$t('preprocessing.applyingDataset.changeButton')"
            type="emphasis"
            @click="$emit('change-null', paddingNum)"
          />
        </div>
      </div>
    </div>

    <!-- 外れ値除去 -->
    <div
      class="apply-body-content"
      :class="{ 'apply-body-content-on': toggleTarget === 'dropOutliers' }"
    >
      <button
        class="apply-body-content-button"
        @click="toggleItem('dropOutliers')"
      >
        <div class="apply-body-content-button-inner">
          <loading-icon v-if="loadingDetailPage" />
          <texts
            class="apply-body-content-button-text"
            :text="$t('preprocessing.applyingDataset.dropOutliers')"
            :color="showColumn.outlierNums > 0 ? 'green' : 'default'"
          />
        </div>
        <icons class="apply-body-content-button-icon" iconName="toggle" />
      </button>

      <div
        v-if="toggleTarget === 'dropOutliers'"
        class="apply-body-content-body"
      >
        <template v-if="showColumn.outlierNums > 0 && !loadingDetailPage">
          <div class="apply-body-content-missing-value">
            <texts
              :text="
                $t('preprocessing.applyingDataset.outlierQuantity') + ' : '
              "
              size="small"
            />
            <texts
              class="apply-body-content-missing-value-count"
              :text="showColumn.outlierNums"
              size="small"
              color="green"
            />
          </div>
          <texts
            class="apply-body-content-text"
            size="small"
            isShowAll
            :text="$t('preprocessing.message.dropOutliers')"
          />
        </template>
        <div
          v-else-if="showColumn.outlierNums === 0 && !loadingDetailPage"
          class="apply-body-error-wrap"
        >
          <texts
            class="apply-body-error-text"
            :text="$t('preprocessing.noneOutlier')"
            size="small"
            color="gray"
          />
        </div>

        <button-main
          class="apply-body-content-submit"
          :text="$t('preprocessing.applyingDataset.dropOutliers')"
          :disabled="checkDisabledOutlier"
          :type="checkDisabledOutlier ? 'disabled' : 'emphasis'"
          @click="$emit('drop-outlier')"
        />
      </div>
    </div>

    <!-- 列削除 -->
    <div
      v-if="!isObjectiveColumn"
      class="apply-body-content"
      :class="{ 'apply-body-content-on': toggleTarget === 'dropCol' }"
    >
      <button class="apply-body-content-button" @click="toggleItem('dropCol')">
        <texts
          class="apply-body-content-button-text"
          :text="$t('preprocessing.applyingDataset.dropCol')"
        />
        <icons class="apply-body-content-button-icon" iconName="toggle" />
      </button>
      <div v-if="toggleTarget === 'dropCol'" class="apply-body-content-body">
        <texts
          class="apply-body-content-text"
          size="small"
          isShowAll
          :text="$t('preprocessing.message.dropCol')"
        />
        <button-main
          class="apply-body-content-submit"
          :text="$t('preprocessing.applyingDataset.dropCol')"
          type="emphasis"
          @click="$emit('drop-column')"
        />
      </div>
    </div>

    <div
      class="apply-body-content"
      :class="{ 'apply-body-content-on': toggleTarget === 'transform' }"
    >
      <button
        class="apply-body-content-button"
        @click="toggleItem('transform')"
      >
        <texts
          class="apply-body-content-button-text"
          :text="$t('preprocessing.applyingDataset.transform')"
        />
        <icons class="apply-body-content-button-icon" iconName="toggle" />
      </button>
      <groupTransform
        v-if="toggleTarget === 'transform'"
        class="apply-body-content-body"
        :minValue="minValue"
        :maxValue="maxValue"
        @apply-binning="$emit('apply-binning', $event)"
        @apply-standardize="$emit('apply-standardize')"
        @apply-yeo-johnson="$emit('apply-yeo-johnson')"
      />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import icons from '@/components/atoms/icon'
import loadingIcon from '@/components/atoms/loading-icon'
import selectBox from '@/components/molecules/select-box'
import inputBox from '@/components/molecules/input-box'
import buttonMain from '@/components/atoms/button-main'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import groupTransform from './groups/transform'

export default {
  components: {
    texts,
    icons,
    loadingIcon,
    selectBox,
    inputBox,
    buttonMain,
    transitionToggleContents,
    groupTransform
  },
  props: {
    showColumn: Object,
    graph: Object,
    objectiveColumn: Array,
    boxPlotData: {
      type: Object,
      default: () => {}
    },
    loadingDetailPage: Boolean,
    statistics: Object
  },
  data() {
    return {
      changeNums: [
        {
          id: 0,
          name: this.$t('preprocessing.encoding.onehot'),
          value: 'onehot'
        },
        {
          id: 1,
          name: this.$t('preprocessing.encoding.dummy'),
          value: 'dummy'
        },
        {
          id: 2,
          name: this.$t('preprocessing.encoding.label'),
          value: 'label'
        }
      ],
      changeNumsObjective: [
        {
          id: 2,
          name: this.$t('preprocessing.encoding.label'),
          value: 'label'
        }
      ],
      changeNumsId: null,
      changeLacksId: 0,
      labelEncodingValue: [],
      labelEncodingKey: [],
      paddingNum: 0,
      lackNum: 0,
      toggleTarget: ''
    }
  },
  methods: {
    toggleItem(name) {
      this.changeNumsId = this.changeNumsChoices[0].id
      this.changeLacksId = 0
      if (this.toggleTarget !== name) {
        this.toggleTarget = name
      } else {
        this.toggleTarget = ''
      }
    },
    selectLacks(selectId) {
      this.changeLacksId = selectId.id
    },
    applyChangeNums() {
      if (this.selectedChangeNumsId === 0) {
        this.$emit('apply-one-hot')
      } else if (this.selectedChangeNumsId === 1) {
        this.$emit('apply-dummy')
      } else if (this.selectedChangeNumsId === 2) {
        this.$emit('apply-label', {
          key: this.labelEncodingKey,
          value: this.labelEncodingValue.map((item) => Number(item)),
          filling: this.showColumn.nNull > 0 ? this.lackNum : null
        })
      }
    },
    setLabelEncoding() {
      let value = this.showColumn.nNull > 0 ? 1 : 0
      for (const key in this.graph[this.showColumn.name]?.counts) {
        this.labelEncodingValue.push(value)
        this.labelEncodingKey.push(key)
        value += 1
      }
    },
    firstSetLabel() {
      // ラベルエンコーディングが可能な場合のみ、ラベルエンコーディング用の変数を保持
      if (
        !this.graph[this.showColumn.name]?.unique &&
        !this.graph[this.showColumn.name]?.lotUnique
      ) {
        this.setLabelEncoding()
        // ラベルエンコーディングのみ指定可能な場合は、最初からラベルエンコーディング用の表示をさせておく
        if (this.isObjectiveColumn) {
          this.changeNumsId = 2
        }
        this.changeLacksId = this.changeLacks[0].id
      }
    }
  },
  computed: {
    changeLacks() {
      const isNumber = this.showColumn?.dtype
        ? this.showColumn.dtype
            .split('_')
            .some((x) => ['int', 'float'].indexOf(x) >= 0)
        : false
      if (isNumber) {
        return [
          {
            id: 0,
            name: this.$t('preprocessing.applyingDataset.dropNull'),
            value: 'drop'
          },
          {
            id: 1,
            name: this.$t('preprocessing.layerNames.FillNull'),
            value: 'filling'
          }
        ]
      } else {
        return [
          {
            id: 0,
            name: this.$t('preprocessing.applyingDataset.dropNull'),
            value: 'drop'
          }
        ]
      }
    },
    isObjectiveColumn() {
      return this.objectiveColumn?.includes(this.showColumn.name) ?? false
    },
    changeNumsChoices() {
      if (this.isObjectiveColumn) return this.changeNumsObjective
      return this.changeNums
    },
    targetGraph() {
      if (!this.graph || this.graph[this.showColumn.name] == null) return {}
      return this.graph[this.showColumn.name]
    },
    selectedChangeNumsId: {
      get: function () {
        if (this.changeNumsId === null) {
          return this.changeNumsChoices[0].id
        } else {
          return this.changeNumsId
        }
      },
      set: function (newVal) {
        this.changeNumsId = newVal
      }
    },
    checkDisabled() {
      // ラベルエンコーディングの場合に、判定を行う
      if (this.selectedChangeNumsId !== 2) return false
      return (
        this.targetGraph.unique ||
        this.targetGraph.lotUnique ||
        this.loadingDetailPage
      )
    },
    checkDisabledOutlier() {
      return (
        this.showColumn.outlierNums == null ||
        this.showColumn.outlierNums === 0 ||
        this.loadingDetailPage
      )
    },
    maxValue() {
      const colName = this.showColumn?.name
      return this.statistics.table?.find((item) => item['/'] === 'max')[colName]
    },
    minValue() {
      const colName = this.showColumn?.name
      return this.statistics.table?.find((item) => item['/'] === 'min')[colName]
    }
  },
  mounted() {
    this.firstSetLabel()
  },
  watch: {
    graph() {
      this.firstSetLabel()
    }
  }
}
</script>

<style lang="scss" scoped>
.apply-body {
  &-wrap {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-text {
    padding: $space-small;
  }
  &-content {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;
    max-height: 100%;
    transition: flex-grow $transition-base;
    will-change: flex-grow;
    &::after {
      content: '';
      position: absolute;
      bottom: adjustVW(1);
      left: 50%;
      width: calc(100% - #{$space-small * 2});
      border-bottom: $border-sub;
      transform: translateX(-50%);
    }
    &:first-of-type {
      margin: adjustVW(2) 0 0; // 若干上に寄ってしまうのは下向きのボーダーしかないからなため、ボーダー分微調整
    }
    &:last-of-type {
      &::after {
        content: inherit;
      }
    }
    &-missing-value {
      display: flex;
      margin: 0 0 $space-small;
      &-count {
        margin-left: $space-base;
      }
    }
    &-on {
      flex-shrink: 1;
      flex-grow: 1;
      .apply-body-content-button-icon {
        transform: rotate(180deg);
      }
    }
    &-button {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: $space-small;
      &-icon {
        transition: transform $transition-base;
        will-change: transform;
      }
      &:hover {
        opacity: 1;
      }
      &-inner {
        display: flex;
        grid-column-gap: $space-base;
      }
    }
    &-body {
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      height: 100%;
      padding: 0 $space-small $space-small;
      opacity: 1;
      animation: toggle $transition-base;
      will-change: height, opacity;
    }
    &.wrap {
      padding: 0;
      border-radius: 0;
      box-shadow: none;
    }
    &-text {
      flex-shrink: 0;
      padding: 0;
      margin: 0 0 $space-sub;
      white-space: pre-line;
    }
    &-box {
      flex-shrink: 0;
      height: adjustVH(48); // Zepplinと違いますがちょっと狭かったので微調整
      margin: 0 0 $space-small;
    }
    &-submit {
      flex-shrink: 0;
      width: fit-content;
      margin: 0;
    }
    &-input {
      height: auto;
      margin: 0 0 $space-sub;
    }
    &-label {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      height: fit-content;
      max-height: 100%;
      padding: $space-sub $space-base $space-sub $space-sub;
      margin: 0 0 $space-small;
      border: $border-sub;
      border-radius: adjustVW(8);
      &-inner {
        overflow-y: auto;
        height: 100%;
        padding: 0 $space-base 0 0;
        @include scrollbar;
      }
      &-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 0 $space-sub;
        &:last-of-type {
          margin: 0;
        }
        &-index {
          width: calc(50% - #{$space-small});
          margin: auto $space-sub auto 0;
        }
        &-input {
          width: 50%;
        }
      }
    }
  }
  &-line {
    width: 100%;
    height: adjustVH(1);
    margin: 0 0 $space-small;
    background: $border-gray;
    z-index: 0;
  }
  &-error {
    &-text {
      white-space: pre-line;
    }
    &-wrap {
      margin-bottom: $space-small;
    }
  }
}

@keyframes toggle {
  0% {
    height: 0%;
    opacity: 0;
    transform: translateY(-$space-small);
  }
  100% {
    height: 100%;
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
