<template>
  <div class="table-wrap">
    <div class="table-limit-wrap">
      <checkbox-base
        :checked="limitsColumn"
        :text="$t('datasetList.popup.addDataset.limitColumn')"
        @change="changeLimitColumns"
      />
      <div v-if="limitsColumn" class="table-limit-input">
        <input-box
          v-model="internalValue.columns"
          v-tooltip="upperMaxTips"
          class="limit-input-box"
          number
          :max="displayMaxRows"
          :min="minsRow"
          isGray
          @input="inputValue"
          @blur="fixValue"
        />
        <texts :text="'/ ' + displayMaxRows" />
      </div>
    </div>
    <div v-if="!loadingdatasourceItemsInfo" class="table-list">
      <checkbox-list
        v-model="internalValue.checked"
        :values="Object.keys(datasourceItemsInfo.columns)"
      />
    </div>
  </div>
</template>

<script>
import checkboxBase from '@/components/atoms/checkbox-base'
import checkboxList from '@/components/molecules/checkbox-list'
import inputBox from '@/components/molecules/input-box'

export default {
  components: {
    checkboxBase,
    checkboxList,
    inputBox
  },
  props: {
    datasourceItemsInfo: Object,
    loadingdatasourceItemsInfo: Boolean,
    maxRows: Number,
    value: Object
  },
  data() {
    return {
      limitsColumn: false,
      minsRow: 0, // 固定値
      maxRowsDefault: 50000,
      showUpperMax: false
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value ?? []
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    upperMaxTips() {
      return {
        content: this.$t('datasetList.datasourceList.upperLimitOver'),
        show: this.showUpperMax,
        trigger: 'manual'
      }
    },
    displayMaxRows() {
      return Math.min(this.maxRowsDefault, this.maxRows)
    }
  },
  methods: {
    changeLimitColumns(e) {
      if (!e) {
        this.internalValue.columns = null
      } else {
        this.internalValue.columns = this.displayMaxRows
      }
      this.limitsColumn = !this.limitsColumn
    },
    inputValue(e) {
      this.showUpperMax = false
      if (e > this.displayMaxRows) {
        this.showUpperMax = true
      } else {
        this.internalValue.columns = e
      }
    },
    fixValue(e) {
      if (this.internalValue.columns > this.displayMaxRows) {
        this.internalValue.columns = this.displayMaxRows
      }
      this.showUpperMax = false
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  &-wrap {
    overflow: hidden;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    height: 100%;
  }
  &-limit {
    &-wrap {
      display: flex;
      align-items: center;
      padding: $space-base 0;
      margin: 0 0 $space-small;
      border-top: $border-sub;
      border-bottom: $border-sub;
    }
    &-input {
      display: flex;
      align-items: center;
      &-box {
        margin: 0 $space-base 0 0;
      }
    }
  }
  &-list {
    overflow: auto;
    height: 100%;
    @include scrollbar;
  }
}
</style>
