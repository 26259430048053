<template>
  <div
    v-if="!hideFeatureImportance"
    class="card-feature-importance-root"
    :class="{
      'card-feature-importance-root-not-card': notCard,
      'card-feature-importance-root-min-size': minSize
    }"
  >
    <texts
      class="card-feature-importance-title"
      :text="title"
      isBold
      :size="notCard ? 'default' : 'large'"
    />
    <transition-toggle-contents>
      <template v-if="!loadFeatureImportance">
        <transition-toggle-contents>
          <div
            v-if="!existPredictionColumn"
            key="notExist"
            class="card-feature-importance-none"
          >
            <texts
              class="card-feature-importance-none-text"
              :text="$t('trainedAi.multi.notFound')"
              size="small"
              color="gray"
            />
          </div>
          <div
            v-else-if="sortedEntries.length > 0"
            key="feature"
            class="card-feature-importance-body"
          >
            <div class="card-feature-importance-block">
              <div
                v-for="[featureImportanceName, data] in sortedEntries"
                :key="featureImportanceName"
                class="card-feature-importance-inner"
              >
                <div class="card-feature-importance-name">
                  <texts :text="featureImportanceName" size="small" />
                </div>
                <div
                  class="card-feature-importance-bar"
                  @mouseenter="isHoverGraph(featureImportanceName)"
                  @mouseleave="isLeaveGraph()"
                >
                  <div
                    class="card-feature-importance-bar-inner"
                    :style="{ '--width': scaleWidth(data) + '%' }"
                  >
                    <div
                      v-tooltip="{
                        content: data.toFixed(3),
                        trigger: ['manual'],
                        show: showTipsItem === featureImportanceName,
                        delay: { show: 500, hide: 500 }
                      }"
                      class="card-feature-importance-tooltip-wrap"
                    />
                  </div>
                </div>
              </div>
              <div class="card-feature-importance-bg">
                <div class="card-feature-importance-bg-scale">
                  <div class="card-feature-importance-bg-scale-item">
                    0
                  </div>
                  <div class="card-feature-importance-bg-scale-item">
                    {{ scaleTick(1) }}
                  </div>
                  <div class="card-feature-importance-bg-scale-item">
                    {{ scaleTick(2) }}
                  </div>
                  <div class="card-feature-importance-bg-scale-item">
                    {{ scaleTick(3) }}
                  </div>
                  <div class="card-feature-importance-bg-scale-item">
                    {{ scaleTick(4) }}
                  </div>
                  <div class="card-feature-importance-bg-scale-item">
                    {{ scaleTick(5) }}
                  </div>
                </div>
                <div class="card-feature-importance-bg-line">
                  <div class="card-feature-importance-bg-line-item" />
                  <div class="card-feature-importance-bg-line-item" />
                  <div class="card-feature-importance-bg-line-item" />
                  <div class="card-feature-importance-bg-line-item" />
                  <div class="card-feature-importance-bg-line-item" />
                  <div class="card-feature-importance-bg-line-item" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-else-if="sortedEntries.length === 0"
            key="none"
            class="card-feature-importance-none"
          >
            <texts
              class="card-feature-importance-none-text"
              :text="$t('trainedAiDetails.noFeatureImportance')"
              size="small"
              color="gray"
            />
          </div>
        </transition-toggle-contents>
      </template>
      <div v-else key="loading" class="card-feature-importance-loading">
        <loading />
      </div>
    </transition-toggle-contents>
  </div>
</template>
<script>
import texts from '@/components/atoms/text'
import loading from '@/components/atoms/loading'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

export default {
  components: {
    texts,
    loading,
    transitionToggleContents
  },
  data() {
    return {
      showTipsCard: '',
      showTipsItem: ''
    }
  },
  props: {
    /** 重要度 */
    featureImportance: Object,
    /** 最大表示件数 */
    maxItem: Number,
    /** 背景にカードを置かない */
    notCard: Boolean,
    /** 最小化表示 */
    minSize: Boolean,
    /** タイトルの表示を推論用に切り替える */
    inferenceTitle: Boolean,
    /** 予測する列（表示の出し分け用） */
    predictionColumns: Array,
    /** 学習済みAIのtype */
    type: String,
    /** 重要度を表示するしないの判定が必要かどうか */
    checkShowFeatureImportance: Boolean,
    /** 表示する予測する列のindex */
    selectedColumnIndex: Number,
    /** 重要度を取得中かどうか */
    loadFeatureImportance: Boolean
  },
  computed: {
    sortedEntries() {
      if (
        !this.featureImportance ||
        Object.keys(this.featureImportance).length === 0
      )
        return []
      return Object.entries(this.featureImportance)
        .sort((x, y) => y[1] - x[1])
        .slice(0, this.maxItem)
    },
    maxScale() {
      return this.sortedEntries.length === 0
        ? 0
        : Math.ceil(this.sortedEntries[0][1] * 10) / 10
    },
    title() {
      if (this.inferenceTitle) {
        return this.$t('inference.inferenceFeatureImportance')
      } else {
        return this.$t('projectDetail.featureImportance')
      }
    },
    hideFeatureImportance() {
      if (!this.checkShowFeatureImportance) return false
      if (!this.predictionColumns || !this.type) return false
      return (
        this.predictionColumns.length > 1 &&
        !this.$recipeType.time.includes(this.type)
      )
    },
    existPredictionColumn() {
      if (
        this.selectedColumnIndex === null ||
        this.selectedColumnIndex === undefined
      )
        return true
      return this.selectedColumnIndex >= 0
    }
  },
  methods: {
    scaleWidth(e) {
      if (e !== 0) {
        return (e / this.maxScale) * 100
      } else {
        return 0
      }
    },
    scaleTick(e) {
      if (this.maxScale < 0.45) {
        return (this.maxScale / 5) * e
      } else {
        return Math.round((this.maxScale / 5) * e * 10) / 10
      }
    },
    isHoverGraph(featureImportanceId) {
      this.showTipsItem = featureImportanceId
    },
    isLeaveGraph() {
      this.showTipsItem = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.card-feature-importance {
  &-root {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    transition: $transition-base;
    &-not-card {
      height: auto;
      padding: 0;
      background: inherit;
      border-radius: 0;
      box-shadow: none;
      .card-feature-importance {
        &-title {
          margin: 0;
        }
        &-inner {
          margin: 0 0 $space-sub;
          &:first-child {
            margin: $space-base 0 $space-sub;
          }
        }
        &-body {
          height: auto;
        }
        &-none {
          justify-content: flex-start;
        }
      }
    }
    &-min-size {
      .card-feature-importance {
        &-title {
          margin: 0;
        }
        &-inner {
          margin: 0 0 $space-sub;
          &:first-child {
            margin: $space-base 0 $space-sub;
          }
        }
        &-body {
          overflow: hidden;
          flex-shrink: 1;
          height: 100%;
        }
        &-block {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 100%;
          padding: $space-text 0 $space-medium;
        }
        &-name {
          width: adjustVW(96);
        }
        &-bg {
          width: calc(100% - #{adjustVW(96)});
        }
        &-bar {
          width: calc(100% - #{adjustVW(96) + $space-small});
        }
      }
    }
  }
  &-body {
    height: 100%;
  }
  &-title {
    flex-shrink: 0;
    margin: 0 0 $space-base;
  }
  &-wrap {
    margin: $space-large 0 0;
  }
  &-block {
    position: relative;
    width: 100%;
    height: 100%;
    padding: $space-min 0 $space-medium;
    z-index: 1;
  }
  &-inner {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 0 $space-medium;
    &:first-child {
      margin: $space-base 0 $space-medium;
    }
    &:nth-last-child(2) {
      margin: 0 0 $space-base;
    }
  }
  &-name {
    overflow: hidden;
    width: adjustVW(168);
    margin: 0 $space-small 0 0;
  }
  &-bar {
    width: calc(100% - #{adjustVW(168) + $space-small});
    &-inner {
      --width: 100%;
      position: relative;
      width: calc(var(--width) - #{$space-small});
      height: adjustVH(16);
      background: $line-emphasis;
      border-radius: adjustVW(4);
    }
  }
  &-tooltip {
    &-inner {
      width: fit-content;
      max-width: 100%;
    }
  }
  &-bg {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - #{adjustVW(168)});
    height: 100%;
    z-index: -1;
    &-scale {
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 0 0 $space-min;
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: adjustVW(32);
        height: adjustVH(24);
        color: $text-sub;
        font-size: $text-min;
        @include text-crop;
      }
    }
    &-line {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: calc(100% - #{adjustVW(24)});
      &-item {
        position: relative;
        width: adjustVW(32);
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          width: 1px;
          height: 100%;
          background: $line-gray;
          transform: translateX(-50%);
        }
      }
    }
  }
  &-none {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: $space-text;
    &-text {
      white-space: pre-line;
    }
  }
  &-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
</style>
