<template>
  <div class="c-wrap">
    <sidebar />
    <div class="c-body-wrap">
      <header-tabs v-bind="headerTabs" />
      <transition-page>
        <page-not-found />
      </transition-page>
    </div>
  </div>
</template>

<script>
import pageNotFound from '@/components/organisms/page-not-found'
import sidebar from '@/components/organisms/sidebar.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import transitionPage from '@/components/molecules/transition-page'
export default {
  components: {
    pageNotFound,
    sidebar,
    headerTabs,
    transitionPage
  },
  props: {
    headerTabs: Object
  }
}
</script>

<style lang="scss" scoped>
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
  height: 100vh;
  background: $background;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  height: calc(100% - #{adjustVH($headerTabHeight)});
  margin: adjustVH($headerTabHeight) 0 0;
  background: $background-sub;
}
</style>
