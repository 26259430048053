<template>
  <div
    class="detail-params-wrap"
    :class="{ 'detail-params-wrap-deep': deepRecipe }"
  >
    <div class="detail-params-list">
      <input-box
        v-model="config.trainConfig.data.ratio"
        v-tooltip.bottom-start="tooltip"
        class="detail-params-item"
        :title="$t('training.config.dataRatio')"
        :step="0.01"
        :min="0"
        :max="1"
        number
        isGray
      />
      <div class="detail-params-item">
        <texts
          class="detail-params-item-title"
          :text="$t('training.config.testTrainingRatio')"
          size="small"
          color="gray"
        />
        <texts
          class="detail-params-item-count"
          :text="nTestTrain[0] + '/' + nTestTrain[1]"
        />
      </div>
      <div class="detail-params-item">
        <texts
          class="detail-params-item-title"
          :text="$t('training.environments.environments')"
          size="small"
          color="gray"
        />
        <select-box
          v-model="config.trainConfig.computingEnvironment"
          isGray
          :items="computingEnvironmentTypes"
        />
      </div>
    </div>
    <template v-if="deepRecipe">
      <div class="detail-params-list">
        <input-box
          v-model="config.trainConfig.learning_rate"
          class="detail-params-item"
          :title="$t('training.config.learningRate')"
          :min="0"
          :step="0.001"
          number
          isGray
        />
      </div>
      <div class="detail-params-list">
        <input-box
          v-model="config.trainConfig.batch_size"
          class="detail-params-item"
          :title="$t('training.config.batchSize')"
          :step="1"
          :min="1"
          number
          isGray
        />
        <input-box
          v-model="config.trainConfig.epoch"
          class="detail-params-item"
          :title="$t('training.config.epoch')"
          :step="1"
          :min="1"
          number
          isGray
        />
        <div class="detail-params-item" @mouseleave="showMessage = null">
          <div class="detail-params-item-title-wrap">
            <text-with-icon
              class="detail-params-item-title-icon"
              :text="$t('training.config.nIters')"
              size="small"
              color="gray"
              iconName="info"
              pos="right"
              isButton
              :tooltip="{
                content: $t('training.message.nIters'),
                trigger: ['manual'],
                show: showMessage === 'nIters',
                delay: { show: 500, hide: 500 },
                arrowSelector: 'tooltip-arrow-end'
              }"
              @icon-click="showMessage = 'nIters'"
            />
          </div>
          <texts class="detail-params-item-count" :text="nIters" />
        </div>
      </div>
      <div class="detail-params-list">
        <div class="detail-params-item" @mouseleave="showMessage = null">
          <div class="detail-params-item-title-wrap">
            <text-with-icon
              class="detail-params-item-title-icon"
              :text="$t('training.config.evaluateEveryTrain')"
              size="small"
              color="gray"
              iconName="info"
              pos="right"
              isButton
              :tooltip="{
                content: $t('training.message.evaluateEveryTrain'),
                trigger: ['manual'],
                show: showMessage === 'evaluateEveryTrain',
                delay: { show: 500, hide: 500 },
                arrowSelector: 'tooltip-arrow-end'
              }"
              @icon-click="showMessage = 'evaluateEveryTrain'"
            />
          </div>
          <input-box
            v-model="config.trainConfig.saver.evaluate_every.train"
            class="detail-params-item-input"
            :min="1"
            :step="5"
            number
            isGray
          />
        </div>
        <div class="detail-params-item" @mouseleave="showMessage = null">
          <div class="detail-params-item-title-wrap">
            <text-with-icon
              class="detail-params-item-title-icon"
              :text="$t('training.config.evaluateEveryTest')"
              size="small"
              color="gray"
              iconName="info"
              pos="right"
              isButton
              :tooltip="{
                content: $t('training.message.evaluateEveryTest'),
                trigger: ['manual'],
                show: showMessage === 'evaluateEveryTest',
                delay: { show: 500, hide: 500 },
                arrowSelector: 'tooltip-arrow-end'
              }"
              @icon-click="showMessage = 'evaluateEveryTest'"
            />
          </div>
          <input-box
            v-model="config.trainConfig.saver.evaluate_every.test"
            class="detail-params-item-input"
            :min="1"
            :step="5"
            number
            isGray
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import InputBox from '@/components/molecules/input-box.vue'
import TextWithIcon from '@/components/molecules/text-with-icon.vue'
import selectBox from '@/components/molecules/select-box.vue'

import { DEEP_RECIPE_TYPE } from '@/lib/training.js'

export default {
  components: {
    texts,
    InputBox,
    TextWithIcon,
    selectBox
  },
  data() {
    return {
      showMessage: null
    }
  },
  props: {
    value: Object,
    nTestTrain: Array,
    nIters: Number,
    TOO_FEW_TH: Number,
    recipeType: String,
    tooFewTestData: Boolean,
    tooFewTrainData: Boolean,

    accountInfo: Object
  },
  computed: {
    config: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    tooltip() {
      return {
        content: this.tooltipText,
        trigger: ['manual'],
        show: this.tooFewTrainData || this.tooFewTestData,
        delay: { show: 500, hide: 500 }
      }
    },
    tooltipText() {
      if (this.tooFewTrainData) {
        return this.$t('training.errors.tooFewTrainData', {
          num: this.TOO_FEW_TH
        })
      } else if (this.tooFewTestData) {
        return this.$t('training.errors.tooFewTestData', {
          num: this.TOO_FEW_TH
        })
      } else {
        return ''
      }
    },
    deepRecipe() {
      return DEEP_RECIPE_TYPE.indexOf(this.recipeType) !== -1
    },
    computingEnvironmentTypes() {
      return [
        {
          id: 0,
          name: this.$t('inference.popup.environmentSetting.auto'),
          value: 'auto'
        },
        {
          id: 1,
          name: this.$t('training.environments.cpu'),
          value: 'cpu'
        },
        {
          id: 2,
          name: this.$t('training.environments.gpuSmall'),
          value: 'gpuSmall',
          disabled: this.accountInfo?.planDetail?.options?.find(
            (x) => x.name === 'gpuSmall'
          ) ?? { reasons: ['requireGpuOption'] }
        },
        {
          id: 3,
          name: this.$t('training.environments.gpuLarge'),
          value: 'gpuLarge',

          disabled: this.accountInfo?.planDetail?.options?.find(
            (x) => x.name === 'gpuLarge'
          ) ?? { reasons: ['requireGpuOption'] }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-params {
  &-list {
    display: flex;
    align-items: flex-start;
    margin: 0 0 $space-small;
    &:last-of-type {
      margin: 0;
    }
  }
  &-item {
    display: flex;
    flex-direction: column;
    width: calc((100% - #{$space-medium * 2}) / 2);
    margin: 0 $space-medium 0 0;
    &:last-of-type {
      margin: 0;
    }
    &-count {
      display: flex;
      align-items: center;
      min-height: adjustVH(48); // input-box-gray の高さ
    }
    &-title {
      margin: 0 0 $space-base;
      &-icon.right {
        margin: 0 0 $space-base;
      }
      &-wrap {
        position: relative;
      }
    }
  }
  &-wrap {
    width: adjustVW(608 - 24);
    &-deep {
      width: adjustVW(914 - 48);
      .detail-params-item {
        width: calc((100% - #{$space-medium * 2}) / 3);
      }
    }
  }
  &-popup {
    top: adjustVW(40);
    max-width: 100%;
    &-text {
      white-space: pre-line;
    }
  }
}
</style>
