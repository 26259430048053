<!-- 利用されていないコンポーネント -->
<template>
  <div style="height: 100%">
    <transition-toggle-contents>
      <table-main
        v-if="visibleContents === 'dataset'"
        v-bind="dataset"
        key="dataset"
        :pagingCount="7"
      />
      <table-main
        v-else-if="visibleContents === 'statistics'"
        v-bind="statistics"
        key="statistics"
        :pagingCount="7"
      />
    </transition-toggle-contents>
  </div>
</template>

<script>
import tableMain from '@/components/molecules/table-main'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

export default {
  components: {
    tableMain,
    transitionToggleContents
  },
  props: {
    dataset: Object,
    statistics: Object,
    visibleContents: String
  }
}
</script>
