<template>
  <div class="graph-wrap">
    <div
      :id="`coOccurrence${$_uid}`"
      v-resize:throttle.100="resized"
      class="co-occurrence-plot"
      :style="styles"
    />
    <div class="graph-keyword">
      <div class="graph-keyword-title">
        <texts
          :text="$t('textMining.coOccurrence.graph.usageGuide')"
          size="min"
          color="gray"
        />
      </div>
      <div
        :id="`colorChart${$_uid}`"
        v-resize:throttle.100="resized"
        class="color-chart-plot"
      />
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import resize from 'vue-resize-directive'

export default {
  directives: {
    resize
  },
  props: {
    /** グラフのデータ [0]: 単語 [1]: 単語 [2]: 0番目の単語の頻度 [3]: 1番目の単語の頻度 [4]: 単語同士の関連性 */
    graph: {
      type: Array,
      default: () => {}
    },
    /** 共起ネットワークで表示するキーワードに指定したキーワード */
    targetValue: { type: String, default: '' },
    /** 共起ネットワークで表示するキーワードに指定したキーワードだけに絞ったgraph */
    targetKeywordRelation: { type: Object, default: () => {} },
    forceLink: {
      type: Object,
      default: () => {
        return {
          distance: 80
        }
      }
    },
    forceNode: {
      default: () => {
        return {
          strength: -650,
          minDistance: 100,
          maxDistance: 200
        }
      }
    }
  },
  computed: {
    // 初期値のスタイル。上層で幅と高さが指定される前提。
    styles() {
      return {
        width: '100%',
        height: '100%',
        'min-height': '100%'
      }
    },
    // propsで受け渡されたgraphをkeywordだけにした重複なしの配列
    keywordsList() {
      const keywords = this.graph.reduce((prev, current) => {
        prev.push(current[0])
        prev.push(current[1])
        return prev
      }, [])
      return Array.from(new Set(keywords))
    },
    /**
     * d3で利用できる形への変換
     * name: 単語
     * value: 単語の出現頻度
     * id: 単語のID
     * keywordRelation: 共起ネットワークのキーワードに指定した単語との関連度
     */
    fixData() {
      const nodes = new Map()
      const links = []
      const MAX_LINKS = 3
      this.graph.forEach((content) => {
        const target1Id = this.keywordsList.findIndex(
          (target) => target === content[0]
        )
        const target2Id = this.keywordsList.findIndex(
          (target) => target === content[1]
        )

        const target1 = {
          name: content[0],
          value: content[2],
          id: target1Id,
          keywordRelation: this.targetKeywordRelation[content[0]]?.value ?? 0
        }
        const target2 = {
          name: content[1],
          value: content[3],
          id: target2Id,
          keywordRelation: this.targetKeywordRelation[content[1]]?.value ?? 0
        }

        nodes.set(content[0], target1)
        nodes.set(content[1], target2)
        links.push({
          source: target2Id,
          target: target1Id,
          value: (content[4] * 100) / 2
        })
      })
      // 関連度が大きい方から3つに絞る
      const filterLinks = links.reduce((prev, current) => {
        if (!prev[current.source]) {
          prev[current.source] = []
          prev[current.source].push(current)
        } else {
          prev[current.source].push(current)
          prev[current.source] = prev[current.source].sort(
            (prevInner, currentInner) => {
              return currentInner.value - prevInner.value
            }
          )
          prev[current.source] = prev[current.source].slice(0, MAX_LINKS)
        }

        // 逆方向
        if (!prev[current.target]) {
          prev[current.target] = []
          prev[current.target].push(current)
        } else {
          prev[current.target].push(current)
          prev[current.target] = prev[current.target].sort(
            (prevInner, currentInner) => {
              return currentInner.value - prevInner.value
            }
          )
          prev[current.target] = prev[current.target].slice(0, MAX_LINKS)
        }
        return prev
      }, {})
      const resLinks = Object.values(filterLinks).reduce((prev, links) => {
        links.map((link) => prev.add(link))
        return prev
      }, new Set())
      const data = {
        nodes: Array.from(nodes.values(nodes)),
        links: Array.from(resLinks)
      }

      return data
    }
  },
  mounted() {
    this.drawGraph()
  },
  methods: {
    resized() {
      this.drawGraph()
    },
    // 物理演算の設定
    forceSimulation({ nodes, links, strength, minDistance, maxDistance }) {
      return d3
        .forceSimulation(nodes)
        .force(
          'link',
          d3
            .forceLink(links)
            .distance(this.forceLink.distance)
            .id((d) => d.id)
        )
        .force(
          'charge',
          d3
            .forceManyBody()
            .strength(strength)
            .distanceMin(minDistance)
            .distanceMax(maxDistance)
        )
        .force('center', d3.forceCenter())
    },
    // グラフの描画
    drawGraph() {
      const links = this.fixData.links.map((d) => Object.create(d))
      const nodes = this.fixData.nodes.map((d, index) => Object.create(d))
      const params = {
        nodes,
        links,
        strength: this.forceNode.strength,
        minDistance: this.forceNode.minDistance,
        maxDistance: this.forceNode.maxDistance
      }

      const simulation = this.forceSimulation(params).on('tick', ticked)

      simulation
        .force('link')
        .links(links)
        .id(function (d) {
          return d.index
        })

      const el = document.getElementById(`coOccurrence${this.$_uid}`)

      d3.select(`#coOccurrence${this.$_uid}`).selectAll('svg').remove()

      // svg全体の描画
      const svg = d3
        .select(`#coOccurrence${this.$_uid}`)
        .append('svg')
        .attr('width', Math.max(100, el.clientWidth))
        .attr('height', Math.max(100, el.clientHeight))
        .attr('viewBox', [-Math.max(100, el.clientWidth) / 2, -Math.max(100, el.clientHeight) / 2, Math.max(100, el.clientWidth), Math.max(100, el.clientHeight)])

      const link = svg
        .append('g')
        .attr('stroke', '#c2c2c2')
        .attr('stroke-opacity', 0.6)
        .selectAll('line')
        .data(links)
        .enter()
        .append('line')
        .attr('stroke-width', (d) => d.value)

      const node = svg
        .append('g')
        .attr('stroke', '#fff')
        .attr('stroke-width', 1.5)
        .selectAll('circle')
        .data(nodes)
        .enter()
        .append('circle')
        .attr('r', this.numFrequencies())
        .attr('fill', this.colorRelevance())
        .attr('opacity', 0.8)
        .attr('cursor', 'pointer')
        .call(this.drag(simulation))

      const texts = svg
        .append('g')
        .selectAll('.texts')
        .data(nodes)
        .enter()
        .append('text')
        .attr('font-family', 'Noto Sans JP')
        .attr('dx', 0)
        .attr('dy', '0.35em')
        .attr('text-anchor', 'middle')
        .attr('fill', '#ffffff')
        .attr('font-size', '1.4rem')
        .attr('stroke', '#74717a')
        .attr('stroke-width', '0.2rem')
        .attr('paint-order', 'stroke')
        .attr('pointer-events', 'none')
        .text((d) => d.name)

      // 関連度の強さを表す色の凡例の設定
      const colorChartEl = document.getElementById(`colorChart${this.$_uid}`)
      const colorChartData = [...Array(10)].map((_, i) => i)

      d3.select(`#colorChart${this.$_uid}`).selectAll('svg').remove()

      d3.select(`#colorChart${this.$_uid}`)
        .append('svg')
        .attr('width', colorChartEl.clientWidth)
        .attr('height', colorChartEl.clientHeight)
        .append('g')
        .selectAll('rect')
        .data(colorChartData)
        .enter()
        .append('rect')
        .attr('width', '100%')
        .attr('height', '10%')
        .attr('y', (d) => d * 10 + '%')
        .attr('fill', this.colorRelevanceChart())

      // 単語のノードの初期位置
      function ticked() {
        link
          .attr('x1', (d) => d.source.x)
          .attr('y1', (d) => d.source.y)
          .attr('x2', (d) => d.target.x)
          .attr('y2', (d) => d.target.y)

        node.attr('cx', (d) => d.x).attr('cy', (d) => d.y)

        texts.attr('x', (d) => d.x).attr('y', (d) => d.y)
      }

      return svg.node()
    },
    // 頻度の個数からノードの大きさを指定
    numFrequencies() {
      const max = this.fixData.nodes.sort((x, y) => {
        return y.value - x.value
      })
      const width = d3
        .scaleQuantize()
        .domain([1, max[0].value])
        .range([
16,
20,
24,
28,
32,
36
])
      return (d) => width(d.value)
    },
    // 関連度を表す色の設定
    colorRelevance() {
      const max = this.fixData.nodes.sort((x, y) => {
        return y.keywordRelation - x.keywordRelation
      })
      const color = d3
        .scaleLinear()
        .domain([0, max[0].keywordRelation])
        .range(['#045791', '#910456'])
      return (d) => color(d.keywordRelation)
    },
    // 関連度を表す色の凡例の設定
    colorRelevanceChart() {
      const color = d3
        .scaleLinear()
        .domain([0, 10])
        .range(['#910456', '#045791'])
      return (d) => color(d)
    },
    // ノードを引っ張って動かす関数
    drag(simulation) {
      function dragStarted(d) {
        if (!d3.event.active) simulation.alphaTarget(0.3).restart()
        d.fx = d.x
        d.fy = d.y
      }

      function dragged(d) {
        d.fx = d3.event.x
        d.fy = d3.event.y
      }

      function dragEnded(d) {
        if (!d3.event.active) simulation.alphaTarget(0)
        d.fx = null
        d.fy = null
      }

      return d3
        .drag()
        .on('start', dragStarted)
        .on('drag', dragged)
        .on('end', dragEnded)
    }
  }
}
</script>

<style lang="scss" scoped>
.graph {
  &-wrap {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr adjustVW(40);
    grid-template-rows: 100%;
    grid-column-gap: $space-medium;
    width: 100%;
    height: 100%;
    border: $border-sub;
  }
  &-keyword {
    position: relative;
    height: 100%;
    margin: 0 $space-small;
    &-title {
      position: absolute;
      top: $space-sub;
      right: 0;
      height: adjustVW(24);
    }
  }
}
.color-chart-plot {
  overflow: hidden;
  height: calc(100% - adjustVW(24 + 16 + 12));
  margin: adjustVW(24 + 16) 0 $space-sub;
  border-radius: adjustVW(8);
}
</style>
