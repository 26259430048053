<template>
  <div class="service-detail-wrap">
    <div class="service-detail-top">
      <service-detail-top
        :active-menu.sync="activeMenu"
        :active-tab.sync="activeTab"
        :service="service"
        :serviceMenu="serviceMenu"
        @menu-click="$emit('menu-click', $event)"
      />
    </div>
    <div v-if="service" class="service-detail-body">
      <!--main-->
      <component
        :is="bodyComponent"
        :activeMenu.sync="activeMenu"
        :activeTab="activeTab"
        :editInfo="pageBody.editInfo"
        :isEditting="isEditting"
        :service="service"
        :trainedAi="service.trainedAI"
        :trainedAiDetail="pageBody.trainedAiDetail"
        :project="project"
        :recipeDetail="pageBody.recipeDetail"
        :loadingRecipeDetail="pageBody.loadingRecipeDetail"
        :customblockList="customblockList"
        @change-tab="$emit('change-tab', $event)"
        @input-edit-form="$emit('input-edit-form', $event)"
        @save="$emit('save-preproc', $event)"
        @select-item="$emit('select-item', $event)"
        @change-set-recipe="$emit('change-set-recipe', $event)"
      />
    </div>
    <div
      v-if="serviceType === 'infer'"
      class="service-detail-side"
      :class="{ 'service-detail-side-disabled': activeMenu }"
    >
      <!--side-->
      <div class="service-detail-side-inner">
        <model-info
          v-if="pageBody.trainedAiDetail"
          :title="$t('serviceList.popup.addNewService.AItoUse')"
          :modelInfo="pageBody.trainedAiDetail.summary"
          :link="{
            name: 'trainedAiDetail',
            params: { id: pageBody.trainedAiDetail.summary.id }
          }"
        />
      </div>
    </div>
    <div
      v-if="serviceType === 'learn' || serviceType === 'optimization'"
      class="service-detail-side service-detail-side-learn"
      :class="{ 'service-detail-side-disabled': activeMenu }"
    >
      <!--side-->
      <div
        class="service-detail-side-inner"
        :class="{
          'service-detail-side-inner-optimization':
            serviceType === 'optimization'
        }"
      >
        <service-detail-body-learn-side
          :service="service"
          :serviceType="serviceType"
          :project="project"
        />
      </div>
    </div>
  </div>
</template>

<script>
import serviceDetailTop from './service-detail-top'
import serviceDetailBodyInfer from './body/infer'
import serviceDetailBodyLearn from './body/learn'
import serviceDetailBodyLearnSide from './body/learn-side'
import serviceDetailBodyUpload from './body/upload'
import serviceDetailBodyOptimization from './body/optimization'
import modelInfo from '../model-info'

export default {
  components: {
    serviceDetailTop,
    serviceDetailBodyInfer,
    serviceDetailBodyLearn,
    serviceDetailBodyLearnSide,
    serviceDetailBodyUpload,
    serviceDetailBodyOptimization,
    modelInfo
  },
  data() {
    return {
      activeTab: 'detail',
      activeMenu: this.isEditting ? 'edit' : ''
    }
  },
  props: {
    serviceType: String,
    service: Object,
    serviceMenu: Array,
    project: Object,
    pageBody: Object,
    pageName: String,
    isEditting: Boolean,
    customblockList: Array
  },
  computed: {
    bodyComponent() {
      const components = {
        infer: serviceDetailBodyInfer,
        learn: serviceDetailBodyLearn,
        upload: serviceDetailBodyUpload,
        optimization: serviceDetailBodyOptimization
      }
      return components[this.serviceType]
    }
  },
  watch: {
    isEditting(newVal) {
      this.activeMenu = newVal ? 'edit' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.service-detail {
  &-wrap {
    display: grid;
    grid-template-areas:
      'top top'
      'main side';
    grid-template-columns: adjustVW(1224) adjustVW(384);
    grid-template-rows: auto 1fr;
    grid-column-gap: $space-medium;
    grid-row-gap: $space-base;
    height: 100vh - adjustVH($headerTabHeight);
    padding: 0 $space-medium $space-medium;
  }
  &-top {
    grid-area: top;
  }
  &-body {
    overflow: hidden;
    grid-area: main;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
  }
  &-side {
    position: relative;
    grid-area: side;
    padding: $space-small $space-sub $space-small $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0%;
      background: rgba(0, 0, 0, 0.2);
      border-radius: adjustVW(16);
      opacity: 0;
      z-index: 100;
      transition: $transition-base;
    }
    &-disabled {
      position: relative;
      &::after {
        content: '';
        height: 100%;
        opacity: 1;
      }
    }
    &-inner {
      overflow-y: auto;
      height: 100%;
      padding: 0 $space-base 0 0;
      @include scrollbar;
      &-optimization {
        overflow-y: visible;
      }
    }
    &-learn {
      overflow: hidden;
    }
  }
}
</style>
