<template>
  <div class="dataset-size-exceed-caution-wrap">
    <div class="dataset-size-exceed-caution-message-wrapper">
      <div class="dataset-size-exceed-caution-message-main">
        <texts
          class="dataset-size-exceed-caution-message-main-text"
          size="small"
          :text="popupDesc"
        />
      </div>

      <div v-if="showDatasetSizeDetail">
        <div class="dataset-size-exceed-caution-message-info">
          <text-with-title
            :title="$t('preprocessing.popup.datasetSizeExceedCaution.current')"
            :text="totalDatasetSizeMessage"
          />
          <text-with-title
            :title="$t('preprocessing.popup.datasetSizeExceedCaution.maxSize')"
            :text="maxDatasetSizeMessage"
          />
        </div>

        <text-with-title
          :title="$t('preprocessing.popup.datasetSizeExceedCaution.formula')"
        >
          <span class="dataset-size-exceed-caution-message-formula">
            {{ displayFormula }}
            <texts
              :text="overDatasetSizeMessage"
              color="caution"
              size="small"
            />
          </span>
        </text-with-title>
      </div>
    </div>

    <div class="dataset-size-exceed-caution-button-wrapper">
      <button-main
        :text="$t('preprocessing.popup.datasetSizeExceedCaution.checkDataset')"
        type="emphasis"
        @click="$emit('go-dataset-list-page')"
      />
      <button-main
        :text="$t('preprocessing.popup.datasetSizeExceedCaution.continue')"
        type="sub"
        @click="$emit('close-modal')"
      />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import textWithTitle from '@/components/molecules/text-with-title.vue'
import buttonMain from '@/components/atoms/button-main'

export default {
  components: {
    buttonMain,
    texts,
    textWithTitle
  },
  props: {
    afterSave: Boolean,
    datasetSize: Number,
    maxDataSize: Number,
    totalDataSize: Number
  },
  computed: {
    datasetSizeMessage: function () {
      return this.formatFileSize(this.datasetSize)
    },
    displayFormula: function () {
      return (
        this.datasetSizeMessage +
        ' + ' +
        this.totalDatasetSizeMessage +
        ' = ' +
        this.newTotalDatasetSizeMessage
      )
    },
    maxDatasetSizeMessage: function () {
      return this.formatFileSize(this.maxDataSize)
    },
    newTotalDatasetSizeMessage: function () {
      return this.formatFileSize(this.totalDataSize + this.datasetSize)
    },
    lackDatasetSizeMessage: function () {
      const requireSize =
        this.totalDataSize + this.datasetSize - this.maxDataSize
      const requireSizeMessage =
        requireSize > 1024 * 1024
          ? this.formatFileSize(requireSize, 2)
          : this.formatFileSize(requireSize)

      return requireSizeMessage
    },
    overDatasetSizeMessage: function () {
      const overSize = this.totalDataSize + this.datasetSize - this.maxDataSize

      return (
        '(' +
        this.formatFileSize(overSize) +
        this.$t('datasetList.popup.upperLimitDataSize.overCount')
      )
    },
    popupDesc: function () {
      const desc = this.afterSave
        ? this.$t('preprocessing.popup.datasetSizeExceedCaution.afterSave.desc')
        : this.$t(
            'preprocessing.popup.datasetSizeExceedCaution.beforeSave.desc'
          )
      return desc
    },
    showDatasetSizeDetail: function () {
      const isExist = {
        datasetSize: this.datasetSize ?? false,
        maxDataSize: this.maxDataSize ?? false,
        totalDataSize: this.totalDataSize ?? false
      }

      return (
        isExist.datasetSize !== false &&
        isExist.maxDataSize !== false &&
        isExist.totalDataSize !== false
      )
    },
    totalDatasetSizeMessage: function () {
      return this.formatFileSize(this.totalDataSize)
    }
  }
}
</script>

<style lang="scss" scoped>
.dataset-size-exceed-caution-wrapper {
  width: adjustVW(760);
}

.dataset-size-exceed-caution-message {
  &-wrapper {
    margin-bottom: $space-medium;
  }

  &-main {
    margin-bottom: $space-small;

    &-text {
      white-space: pre-line;
    }
  }

  &-info {
    display: flex;
    grid-column-gap: $space-medium;
    margin-bottom: $space-small;
  }

  &-formula {
    display: flex;
    align-items: center;
    grid-column-gap: $space-base;
  }
}

.dataset-size-exceed-caution-button-wrapper {
  display: flex;
  align-items: center;
}
</style>
