<template>
  <div :class="{ 'list-scroll-x': useScrollX }">
    <transition-toggle-contents>
      <button
        v-if="showLeftButton"
        class="scroll-button scroll-button-left"
        @click="leftScroll"
      >
        <icons iconName="backward" size="large" color="emphasis" />
      </button>
    </transition-toggle-contents>
    <div ref="targetList" class="list" @scroll="test($event)">
      <template v-for="(layer, index) in layers">
        <preprocessing-chart-block
          :id="index"
          :key="index"
          :layer="layer"
          :activeBlock="activeBlock"
          :useScrollX="useScrollX"
          :scrollX="scrollX"
          class="item"
          @block-click="blockClick($event)"
          @popup-close="popupClose()"
        />
        <div
          v-if="index !== layers.length - 1"
          :key="index + 'line'"
          class="line"
        />
      </template>
    </div>
    <transition-toggle-contents>
      <button
        v-if="showRightButton"
        class="scroll-button scroll-button-right"
        @click="rightScroll"
      >
        <icons iconName="forward" size="large" color="emphasis" />
      </button>
    </transition-toggle-contents>
  </div>
</template>

<script>
import preprocessingChartBlock from '@/components/atoms/preprocessing-chart-block'
import icons from '@/components/atoms/icon.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    preprocessingChartBlock,
    icons,
    transitionToggleContents
  },
  data() {
    return {
      activeBlock: null,
      scrollX: 0,
      scrollLeft: 0,
      scrollWidth: 0,
      offsetWidth: 0
    }
  },
  props: {
    /** 前処理のブロックの配列 */
    layers: Array,
    /** x軸でのスクロールをできるようにする。また、改行をなくす */
    useScrollX: Boolean
  },
  methods: {
    blockClick(id) {
      this.activeBlock = id
    },
    popupClose() {
      this.activeBlock = null
    },
    test(e) {
      this.scrollX = e.target.scrollLeft
    },
    leftScroll() {
      this.scrollLeft = this.scrollLeft - 480
      this.$refs.targetList.scrollLeft = this.scrollLeft
    },
    rightScroll() {
      this.scrollLeft = this.scrollLeft + 480
      this.$refs.targetList.scrollLeft = this.scrollLeft
    }
  },
  computed: {
    showLeftButton() {
      return this.scrollX !== 0
    },
    showRightButton() {
      return (
        this.scrollWidth > this.offsetWidth &&
        this.scrollX + this.offsetWidth !== this.scrollWidth
      )
    }
  },
  mounted() {
    this.scrollWidth = this.$refs.targetList.scrollWidth
    this.offsetWidth = this.$refs.targetList.offsetWidth
  }
}
</script>

<style scoped lang="scss">
.list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  scroll-behavior: smooth;
  &-scroll-x {
    position: relative;
    height: 100%;
    .list {
      position: initial;
      overflow-x: auto;
      flex-wrap: nowrap;
      height: 100%;
      padding: 0 0 $space-base;
      @include scrollbar;
      > .item {
        margin: 0;
      }
      .line {
        &:nth-of-type(3n) {
          flex-grow: 0;
        }
      }
    }
  }
}

.item {
  margin: 0 0 $space-medium 0;
}

.line {
  width: auto;
  min-width: $space-small;
  height: adjustVW(2);
  margin: 0 0 $space-medium 0;
  background: $border-gray;
  z-index: 0;
  &:nth-of-type(3n) {
    flex-grow: 1;
  }
}

.scroll-button {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: adjustVW(48);
  height: adjustVW(48);
  background: $key-lite;
  border-radius: 9in;
  box-shadow: $box-shadow-hover;
  transform: translateY(-50%);
  transition: opacity $transition-base;
  &:hover {
    opacity: 0.7;
  }
  &-right {
    right: 0;
  }
  &-left {
    left: 0;
  }
}
</style>
