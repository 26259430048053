<template>
  <div class="adjust-slider">
    <div class="adjust-slider-title">
      <div class="adjust-slider-title-inner">
        <texts :text="title" color="gray" size="small" />
        <icons
          v-if="tipsText"
          v-tooltip="tipsText"
          iconName="info"
          size="small"
          color="gray"
        />
      </div>
      <div
        class="adjust-slider-count"
        :class="{
          'adjust-slider-count-disabled': disabled
        }"
      >
        <input
          v-model.number="internalValue"
          type="number"
          :min="min"
          :max="max"
          :step="step"
          :disabled="disabled"
          @change="changeValue"
        >
      </div>
    </div>
    <div
      class="adjust-slider-scale"
      :class="{
        'adjust-slider-scale-disabled': disabled
      }"
    >
      <div class="adjust-slider-scale-inner">
        <input
          v-model.number="internalValue"
          class="adjust-slider-scale-range"
          type="range"
          name="speed"
          :min="min"
          :max="max"
          :step="step"
          :disabled="disabled"
          @change="changeValue"
        >
        <div
          class="adjust-slider-scale-range-status"
          :style="{ '--width': (internalValue / max) * 100 + '%' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'

export default {
  components: {
    icons
  },
  props: {
    /** v-modelで受け渡す値 */
    value: {
      type: Number,
      default: 0
    },
    /** inputに表示するタイトル */
    title: {
      type: String,
      default: ''
    },
    /** 説明が必要な場合のprops */
    tipsText: {
      type: String,
      default: ''
    },
    /** 利用不可状態 */
    disabled: {
      type: Boolean,
      default: false
    },
    /** 最小値 */
    min: {
      type: Number,
      default: 0
    },
    /** 最大値 */
    max: {
      type: Number,
      default: 100
    },
    /** ステップ */
    step: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      internalValue: this.value
    }
  },
  methods: {
    changeValue() {
      this.$emit('change', this.internalValue)
    }
  },
  mounted() {
    this.internalValue = this.value
  },
  watch: {
    internalValue(newVal) {
      this.$emit('input', newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.adjust-slider {
  display: flex;
  flex-direction: column;
  height: 100%;
  &-title {
    display: flex;
    justify-content: space-between;
    &-inner {
      display: flex;
      align-items: flex-start;
      grid-column-gap: $space-base;
    }
  }
  &-inner {
    width: 100%;
  }
  &-count {
    display: flex;
    align-items: center;
    width: adjustVW(64);
    padding: $space-min $space-min adjustVW(3);
    border: $border-emphasis;
    font-size: $text-base;
    border-radius: adjustVW(4);
    transition: $transition-base background-color, $transition-base border-color;
    > input {
      appearance: textfield;
      width: 100%;
      text-align: center;
      transition: $transition-base color;
      &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
      }
      &::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }
    }
    &-disabled {
      border-color: $border-gray;
      background-color: $background-sub;
      > input {
        color: $border-gray;
        cursor: not-allowed;
      }
    }
  }
  &-scale {
    grid-area: range;
    display: flex;
    align-items: center;
    height: 100%;
    &-inner {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
    &-range {
      appearance: none;
      width: 100%;
      height: adjustVH(8);
      background: $line-gray;
      border-radius: adjustVW(4);
      cursor: pointer;
      outline: none;
      &::-webkit-slider-thumb {
        position: relative;
        appearance: none;
        width: adjustVW(24);
        height: adjustVW(24);
        border: $border-emphasis;
        background: $background;
        border-radius: 9in;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
        z-index: 2;
        transition: $transition-base border-color,
          $transition-base background-color;
      }
      &::-moz-range-thumb {
        width: adjustVW(24);
        height: adjustVW(24);
        border: $border-emphasis;
        background: $background;
        border-radius: 9in;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
        transition: $transition-base border-color,
          $transition-base background-color;
      }
      &-status {
        --width: 50;
        position: absolute;
        top: 50%;
        width: var(--width);
        height: adjustVH(8);
        background: $key-color;
        border-radius: 9in 0 0 9in;
        z-index: 1;
        transform: translateY(-50%);
        transition: $transition-base border-color,
          $transition-base background-color;
      }
    }
    &-disabled {
      .trained-ai-setting-scale-range {
        &::-webkit-slider-thumb {
          border-color: $border-gray;
          background-color: $medium-gray;
        }
        &::-moz-range-thumb {
          border-color: $border-gray;
          background-color: $medium-gray;
        }
        &-status {
          background-color: $border-gray;
        }
      }
    }
  }
}
</style>
