<template>
  <div>
    <div v-if="entry.description" class="delete-item-desc">
      <description :text="entry.description" />
    </div>
    <div v-if="entry.is_optimization" class="delete-item-optimization">
      <div class="delete-item-title-optimization">
        <texts
          :text="$t('trainedAi.optimization.latestHistory')"
          size="small"
          color="gray"
        />
      </div>
      <optimization-conditions-list
        :optimizationConditions="entry.latestOptimizationConditions"
        :limit="3"
      />
    </div>
    <div
      v-if="
        (entry.metrics.expectedEffect ||
          (entry.metrics && Object.keys(entry.metrics).length > 0)) &&
          !entry.is_optimization
      "
      class="delete-item-accuracy"
    >
      <div
        v-if="entry.metrics.expectedEffect"
        class="delete-item-expected-effect"
      >
        <text-with-title
          :title="entry.metrics.expectedEffectName"
          :text="entry.metrics.expectedEffect"
        />
      </div>
      <div v-if="entry.metricsList" class="delete-item-accuracy-wrap">
        <texts
          v-if="Object.keys(entry.metrics).length > 1"
          class="delete-item-accuracy-list-title"
          :text="limitMetrics[0].name"
          size="small"
          color="gray"
        />
        <div class="delete-item-accuracy-list">
          <div
            v-for="(metric, index) in limitMetrics"
            :key="index"
            class="delete-item-accuracy-item"
          >
            <texts
              size="small"
              color="gray"
              :text="
                Object.keys(entry.metrics).length > 1
                  ? metric.columnName
                  : metric.name
              "
            />
            <metrics-text
              :value="metric.value"
              :percentage="metric.percentage"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        Object.keys(entry.featureImportance).length > 0 ||
          (entry.datasets && entry.datasets.length > 0)
      "
      class="delete-item-detail"
    >
      <div
        v-if="
          Object.keys(entry.featureImportance).length > 0 &&
            !isDetail &&
            !entry.is_optimization
        "
        class="delete-item-feature-importance"
      >
        <div class="delete-item-title delete-item-title-features">
          <texts
            :text="$t('trainedAiDetails.featureImportance')"
            size="small"
            color="gray"
          />
        </div>
        <list-features
          :id="entry.id"
          :predictionColumns="entry.predictionColumns"
          :featureImportance="entry.featureImportance"
        />
      </div>
      <div class="delete-item-detail-sub">
        <div v-if="entry.services.length" class="delete-item-service">
          <texts
            :text="$t('trainedAiDetails.linkedServices')"
            size="small"
            color="gray"
          />
          <div class="delete-item-service-item">
            <text-with-icon
              v-for="(service, index) in entry.services"
              :key="index"
              class="delete-item-service-link"
              :text="service.name"
              iconName="newTab"
              pos="right"
              color="link-default"
              @click.native="goService(service)"
            />
          </div>
        </div>
        <text-with-title
          v-if="entry.datasets.length > 0"
          :title="$t('trainedAiDetails.datasetUsed')"
          :text="entry.datasets.length > 0 ? entry.datasets[0].name : ''"
        />
      </div>
    </div>
    <div class="delete-item-time">
      <text-with-title
        class="delete-item-time-inner"
        :title="$t('common.createTime')"
      >
        <a-time :value="entry.createTime" />
      </text-with-title>
      <text-with-title
        class="delete-item-time-inner"
        :title="$t('common.updateTime')"
      >
        <a-time :value="entry.updateTime" />
      </text-with-title>
    </div>
  </div>
</template>

<script>
import description from '@/components/atoms/description.vue'
import metricsText from '@/components/atoms/metrics-text'
import textWithTitle from '@/components/molecules/text-with-title.vue'
import textWithIcon from '@/components/molecules/text-with-icon.vue'
import optimizationConditionsList from '@/components/organisms/optimization/optimization-conditions-list'
import listFeatures from './list-features'

export default {
  components: {
    description,
    metricsText,
    textWithTitle,
    textWithIcon,
    optimizationConditionsList,
    listFeatures
  },
  props: {
    entry: Object,
    isDetail: Boolean
  },
  methods: {
    clipFeatureImportance(items) {
      const keyList = Object.keys(items)
      const clipObject = []
      for (const key in keyList) {
        if (key > 2) {
          return clipObject
        } else {
          clipObject.push(keyList[key])
        }
      }
      return clipObject
    },
    goService(service) {
      this.$router.push({
        name: 'serviceProjectDetail',
        params: {
          projectId: this.$route.params.projectId,
          serviceType: 'infer',
          id: service.serviceId
        }
      })
    }
  },
  computed: {
    limitMetrics() {
      return this.entry.metricsList.slice(0, 8)
    }
  }
}
</script>

<style lang="scss" scoped>
.delete {
  &-item {
    &-title {
      &-features {
        margin-bottom: $space-min;
      }
      &-optimization {
        margin-bottom: adjustVW(6);
      }
    }
    &-desc {
      margin: 0 0 $space-small;
    }
    &-expected-effect {
      margin: 0 $space-medium 0 0;
    }
    &-accuracy {
      display: flex;
      margin: 0 0 $space-small;
      &-wrap {
        width: 100%;
      }
      &-list {
        display: flex;
        flex-wrap: wrap;
        grid-column-gap: $space-medium;
        grid-row-gap: $space-base;
        width: 100%;
        &-title {
          margin-bottom: $space-text;
        }
      }
      &-item {
        flex-shrink: 0;
        width: calc(100% / 4 - #{$space-medium * 3 / 4});
      }
    }
    &-optimization {
      margin-bottom: adjustVW(20);
    }
    &-detail {
      display: flex;
      margin: 0 0 $space-small;
      &-sub {
        width: calc(50% - #{$space-sub});
      }
    }
    &-inner {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 $space-small 0 0;
      &:last-child {
        margin: 0;
      }
    }
    &-feature-importance {
      width: calc(50% - #{$space-sub});
      margin: 0 $space-medium 0 0;
      &-list {
        margin: $space-base 0 0;
      }
      &-item {
        display: flex;
        align-items: center;
        margin: 0 0 $space-base;
        &::before {
          content: '';
          display: block;
          width: adjustVW(12);
          height: adjustVW(12);
          margin: 0 $space-base 0 0;
          background: $key-color;
          border-radius: 9in;
        }
        &:last-of-type {
          margin: 0;
        }
        &-inner {
          overflow: hidden;
          width: calc(100% - #{adjustVW(20)});
          color: $text-main;
          font-size: $text-base;
          text-overflow: ellipsis;
          white-space: nowrap;
          @include text-crop;
        }
      }
    }
    &-service {
      margin: 0 0 $space-small;
      &-item {
        margin-top: $space-min;
      }
      &-link {
        width: fit-content;
        max-width: 100%;
        margin-bottom: $space-min;
        cursor: pointer;
        @include buttonHover;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    &-time {
      display: flex;
      &-inner {
        width: calc(50% - #{$space-sub});
        margin: 0 $space-medium 0 0;
        &:last-of-type {
          margin: 0;
        }
      }
    }
  }
}
</style>
