<template>
  <tutorial-popup
    :items="items"
    :show="showTutorial"
    type="tutorial-optimization"
    @close-tutorial="$emit('close-tutorial')"
  >
    <template #0>
      <texts
        class="tutorial-text"
        :text="$t('trainedAi.optimization.tutorial.optimization.0')"
        size="small"
        color="gray"
      />
    </template>
    <template #1>
      <texts
        class="tutorial-text"
        :text="$t('trainedAi.optimization.tutorial.optimization.1')"
        size="small"
        color="gray"
      />
      <div class="tutorial-box">
        <div class="tutorial-list">
          <div
            v-for="(item, key) in condtionsDescs"
            :key="key"
            class="tutorial-item"
          >
            <div class="tutorial-item-icon">
              <icons
                class="tutorial-item-icon-inner"
                color="green"
                :iconName="key"
                :size="key === 'closer' ? 'small' : 'default'"
              />
            </div>
            <div class="tutorial-item-text">
              <texts
                class="tutorial-item-text-title"
                color="green"
                size="small"
                :text="item.title"
              />
              <texts
                class="tutorial-item-text-desc"
                :text="item.desc"
                size="small"
                color="gray"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #2>
      <texts
        class="tutorial-text"
        :text="$t('trainedAi.optimization.tutorial.optimization.2')"
        size="small"
        color="gray"
      />
    </template>
    <template #3>
      <texts
        class="tutorial-text"
        :text="$t('trainedAi.optimization.tutorial.optimization.3')"
        size="small"
        color="gray"
      />
    </template>
    <template #4>
      <texts
        class="tutorial-text"
        :text="$t('trainedAi.optimization.tutorial.optimization.4')"
        size="small"
        color="gray"
      />
    </template>
  </tutorial-popup>
</template>

<script>
import icons from '@/components/atoms/icon'
import tutorialPopup from '@/components/molecules/tutorial-popup.vue'

export default {
  components: {
    icons,
    tutorialPopup
  },
  data() {
    return {
      showedItem: null
    }
  },
  props: {
    showTutorial: Boolean
  },
  computed: {
    items() {
      return [
        {
          title: this.$t(
            'trainedAi.optimization.tutorial.optimization.title.0'
          ),
          iconName: 'optimization',
          link: `${this.$urls.manual}train-training/最適化の条件設定/`,
          linkText: this.$t('common.tutorial.openManual')
        },
        {
          title: this.$t(
            'trainedAi.optimization.tutorial.optimization.title.1'
          ),
          iconName: 'optimization',
          link: `${this.$urls.manual}train-training/最適化の条件設定/`,
          linkText: this.$t('common.tutorial.openManual')
        },
        {
          title: this.$t(
            'trainedAi.optimization.tutorial.optimization.title.2'
          ),
          iconName: 'optimization',
          link: `${this.$urls.manual}train-training/最適化の条件設定/`,
          linkText: this.$t('common.tutorial.openManual'),
          margin: 12,
          marginHeight: 8
        },
        {
          title: this.$t(
            'trainedAi.optimization.tutorial.optimization.title.3'
          ),
          iconName: 'optimization',
          link: `${this.$urls.manual}train-training/最適化の式での条件設定/`,
          linkText: this.$t('common.tutorial.openManual'),
          showLeft: true
        },
        {
          title: this.$t(
            'trainedAi.optimization.tutorial.optimization.title.4'
          ),
          iconName: 'optimization',
          link: `${this.$urls.manual}train-training/最適化の条件設定/`,
          linkText: this.$t('common.tutorial.openManual')
        }
      ]
    },
    condtionsDescs() {
      return {
        closer: {
          title: this.$t('trainedAi.optimization.conditions.closer'),
          desc: this.$t(
            'trainedAi.optimization.tutorial.optimization.detail.closer'
          )
        },
        maximize: {
          title: this.$t('trainedAi.optimization.conditions.maximize'),
          desc: this.$t(
            'trainedAi.optimization.tutorial.optimization.detail.maximize'
          )
        },
        minimize: {
          title: this.$t('trainedAi.optimization.conditions.minimize'),
          desc: this.$t(
            'trainedAi.optimization.tutorial.optimization.detail.minimize'
          )
        }
      }
    }
  },
  methods: {
    showItem(key) {
      this.showedItem = null
      this.$nextTick(() => {
        this.showedItem = key
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tutorial {
  &-text {
    white-space: pre-line;
  }
  &-box {
    display: flex;
    grid-column-gap: $space-medium;
    margin-top: $space-small;
  }
  &-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-row-gap: $space-small;
    margin-bottom: $space-base;
  }
  &-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: adjustVW(400);
    min-height: adjustVW(186);
    padding: $space-sub $space-small;
    background-color: $background-sub;
    border-radius: adjustVW(8);
    &-desc {
      margin-bottom: $space-sub;
      white-space: pre-line;
    }
    &-img {
      height: adjustVW(96);
      background-color: #fff;
      border-radius: adjustVW(8);
    }
    &-none {
      text-align: center;
      white-space: pre-line;
    }
  }
  &-item {
    display: flex;
    grid-column-gap: $space-sub;
    width: 100%;
    padding-bottom: $space-small;
    border-bottom: $border-sub;
    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }
    &-icon {
      position: relative;
      width: adjustVW(48);
      height: adjustVW(48);
      background-color: $green-bg;
      border-radius: adjustVW(6);
      &-inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &-text {
      &-desc {
        white-space: pre-line;
      }
    }
  }
}
</style>
