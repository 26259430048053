<template>
  <card-base
    class="summary-wrap"
    :style="imagePath ? { 'background-image': `url(${imagePath})` } : ''"
  >
    <div class="summary">
      <div class="summary-main">
        <texts
          class="summary-desc"
          size="small"
          :text="description"
          color="gray"
        />
      </div>
      <div class="summary-detail">
        <div class="summary-list">
          <div class="summary-item">
            <textWithTitle
              :title="$t('projectTemplate.detail.wantToAchieve')"
              :text="goal"
            />
          </div>
          <div class="summary-item">
            <textWithTitle
              :title="$t('projectTemplate.detail.predictedAndClassified')"
              :text="target"
            />
          </div>
          <div class="summary-item">
            <textWithTitle
              :title="$t('projectTemplate.detail.industryOccupation')"
              :text="industry"
            />
          </div>
        </div>
        <tag-block class="summary-tag" :tags="tags" />
      </div>
    </div>
    <buttonMain
      id="summaryButton"
      class="summary-button"
      :text="$t('projectTemplate.detail.templateButton')"
      type="emphasis"
      @click="$emit('select-project')"
    />
  </card-base>
</template>

<script>
import buttonMain from '@/components/atoms/button-main.vue'
import cardBase from '@/components/atoms/card-base.vue'
import texts from '@/components/atoms/text.vue'
import textWithTitle from '@/components/molecules/text-with-title.vue'
import tagBlock from '@/components/molecules/tag-block'

export default {
  components: {
    buttonMain,
    cardBase,
    texts,
    textWithTitle,
    tagBlock
  },
  props: {
    showPopup: Boolean,
    description: String,
    tags: Array,
    goal: String,
    target: String,
    industry: String,
    thumbnailUrl: String
  },
  computed: {
    imagePath() {
      if (!this.thumbnailUrl) return null
      return this.thumbnailUrl
    }
  },
  mounted() {
    const options = {
      root: null,
      threshold: 0
    }
    const target = document.getElementById('summaryButton')
    const observer = new IntersectionObserver(
      function (entries) {
        this.$emit('show-nav', !entries[0].isIntersecting)
      }.bind(this),
      options
    )

    observer.observe(target)
  }
}
</script>

<style lang="scss" scoped>
.summary {
  position: relative;
  display: grid;
  grid-template-columns: adjustVW(596) 1fr;
  grid-column-gap: $space-large;
  width: 100%;
  margin: 0 0 $space-sub;
  z-index: 1;
  &-list {
    display: flex;
    margin: 0 0 $space-small;
  }
  &-item {
    width: adjustVW(240);
    margin: 0 $space-medium 0 0;
    &:last-of-type {
      margin: 0;
    }
  }
  &-desc {
    line-height: 1.8;
    white-space: pre-line;
  }
  &-tag {
    height: adjustVW(38);
  }
  &-wrap {
    position: relative;
    background-position-x: adjustVW(400);
    background-position-y: center;
    background-size: 100%;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        105deg,
        #fff 0%,
        #fff 75%,
        rgba(255, 255, 255, 0.75) 100%
      );
      border-radius: adjustVW(15.5);
      z-index: 0;
    }
  }
  &-button {
    position: relative;
    z-index: 1;
  }
}
</style>
