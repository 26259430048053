<template>
  <div class="regression-graph">
    <div class="regression-graph-main">
      <div class="regression-graph-tabs">
        <tab-list
          :tabs="tabsRegressionGraph"
          @change-tab="selectRegressionTab = $event"
        />
      </div>
      <div class="regression-graph-top">
        <text-with-icon v-bind="titleText" class="regression-graph-title" />
        <transition-toggle-contents>
          <div
            v-if="
              selectRegressionTab !== 'optimization' &&
                regressionGraph.error == null
            "
            class="regression-graph-input"
          >
            <checkbox-base
              v-model="setResidualError"
              class="regression-graph-input-box"
            />
            <include-input-text
              v-model.lazy="selectedResidualError"
              :firstText="$t('trainedAi.regressionGraph.input.first')"
              :secondText="$t('trainedAi.regressionGraph.input.second')"
              type="number"
              :step="residualErrorStep"
            />
          </div>
        </transition-toggle-contents>
      </div>
      <transition-toggle-contents>
        <div
          v-if="regressionGraph.loadGraph"
          key="loading"
          class="regression-graph-none"
        >
          <loading />
        </div>
        <div
          v-else-if="selectRegressionTab === 'optimization'"
          key="optimization"
          class="regression-graph-optimization"
        >
          <div class="regression-graph-optimization-inner">
            <texts
              class="regression-graph-optimization-text"
              :text="$t('trainedAi.optimization.tipsTrainedAi.first')"
              size="small"
              color="gray"
            />
            <texts
              class="regression-graph-optimization-text"
              :text="$t('trainedAi.optimization.tipsTrainedAi.second')"
              size="small"
              color="gray"
            />
            <div class="regression-graph-optimization-img">
              <images imageName="optimization" />
            </div>
          </div>
        </div>
        <div v-else-if="regressionGraph.error" class="regression-graph-none">
          <texts
            class="regression-graph-none-text"
            :text="regressionGraph.error"
            size="small"
            color="gray"
          />
        </div>
        <div v-else-if="selectedColumnIndex < 0" class="regression-graph-none">
          <texts
            class="regression-graph-none-text"
            :text="$t('trainedAi.regressionGraph.noColumn')"
            size="small"
            color="gray"
          />
        </div>
        <div v-else-if="selectRegressionTab === 'comparison'" key="comparison">
          <comparison-graph
            :list="regressionGraph.graph.list"
            :dataCount="regressionGraph.graph.dataCount"
            :loadGraphDetail="regressionGraph.loadGraphDetail"
            :selectedRegressionData="regressionGraph.selectedRegressionData"
            :graphDetail="regressionGraph.graphDetail"
            :selectedResidualError="selectedResidualError"
            :setResidualError="setResidualError"
            :inputColumns="testData.inputColumns"
            @select-regression-graph="clickShowDetail"
          />
        </div>
        <div
          v-else-if="selectRegressionTab === 'distribution'"
          key="distribution"
          class="regression-graph-distribution"
        >
          <distribution-graph
            class="regression-graph-distribution-inner"
            :list="regressionGraph.graph.list"
            :dataCount="regressionGraph.graph.dataCount"
            :selectedResidualError="selectedResidualError"
            :setResidualError="setResidualError"
          />
        </div>
      </transition-toggle-contents>
    </div>
  </div>
</template>

<script>
import images from '@/components/atoms/images'
import checkboxBase from '@/components/atoms/checkbox-base.vue'
import loading from '@/components/atoms/loading'
import tabList from '@/components/molecules/tab-list'
import includeInputText from '@/components/molecules/include-input-text.vue'
import textWithIcon from '@/components/molecules/text-with-icon'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import comparisonGraph from '../regression-graph/comparison-graph'
import distributionGraph from '../regression-graph/distribution-graph'

export default {
  components: {
    images,
    checkboxBase,
    loading,
    tabList,
    includeInputText,
    textWithIcon,
    transitionToggleContents,
    comparisonGraph,
    distributionGraph
  },
  data() {
    return {
      selectedResidualError: null,
      setResidualError: false,
      selectRegressionTab: 'comparison',
      residualErrorStep: 1
    }
  },
  props: {
    regressionGraph: Object,
    selectedColumnIndex: Number,
    isOptimization: Boolean,
    testData: Object
  },
  computed: {
    tabsRegressionGraph() {
      const base = [
        {
          name: this.$t('trainedAi.regressionGraph.tab.comparison'),
          value: 'comparison',
          iconName: 'compareChart'
        },
        {
          name: this.$t('trainedAi.regressionGraph.tab.distribution'),
          value: 'distribution',
          iconName: 'bubbleChart'
        }
      ]
      if (this.isOptimization) {
        base.unshift({
          name: this.$t('trainedAi.optimization.tipsTrainedAi.title'),
          value: 'optimization',
          iconName: 'optimization'
        })
      }
      return base.map((item, index) => {
        return {
          id: index,
          ...item
        }
      })
    },
    titleText() {
      const base = {
        text: this.$t(
          `trainedAi.regressionGraph.title.${this.selectRegressionTab}.text`
        ),
        iconName: 'info',
        pos: 'right',
        tooltip: {
          content: this.$t(
            `trainedAi.regressionGraph.title.${this.selectRegressionTab}.tips`
          )
        },
        isBold: true
      }

      if (this.selectRegressionTab === 'optimization') {
        base.iconName = ''
      }

      return base
    }
  },
  methods: {
    clickShowDetail(e) {
      this.$emit('select-regression-graph', e)
    },
    setStep() {
      const checkedValue = this.checkedValue(
        this.regressionGraph?.graph?.largeResidualErrorValue
      )
      const baseCount = 1

      // 整数や数値以外の値の場合は1
      if (
        !checkedValue ||
        isNaN(checkedValue) ||
        Number.isInteger(checkedValue)
      )
        return baseCount
      const countDecimal = checkedValue
        ? checkedValue.toString().split('.')[1]
        : 0
      // 0.1以下の場合だけその桁数に合わせてstepを変更
      if (countDecimal.length < 2) return baseCount
      return 1 / 10 ** countDecimal.length
    },
    checkedValue(targetValue) {
      if (targetValue == null) return null
      return Math.ceil(targetValue * 10000) / 10000
    }
  },
  mounted() {
    if (this.isOptimization) {
      this.selectRegressionTab = 'optimization'
    }
    const checkedValue = this.checkedValue(
      this.regressionGraph?.graph?.largeResidualErrorValue
    )
    this.selectedResidualError = checkedValue ?? null
    this.residualErrorStep = this.setStep()
  },
  watch: {
    'regressionGraph.graph.largeResidualErrorValue': {
      handler(newVal) {
        const checkedValue = this.checkedValue(newVal)
        this.selectedResidualError = checkedValue ?? null
        this.residualErrorStep = this.setStep()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.regression-graph {
  height: fit-content;
  padding: $space-small;
  background: $background;
  border-radius: adjustVW(8);
  box-shadow: $box-shadow-main;
  grid-area: side;
  &-main {
    display: flex;
    flex-direction: column;
    grid-column-gap: $space-large;
    grid-row-gap: $space-small;
  }
  &-tabs {
    width: fit-content;
  }
  &-top {
    display: flex;
    justify-content: space-between;
    height: adjustVW(48);
  }
  &-title {
    width: fit-content;
  }
  &-input {
    display: flex;
    grid-column-gap: $space-base;
    width: adjustVW(440);
    &-box {
      padding: 0 $space-sub;
      margin: 0;
    }
  }
  &-inner {
    width: 100%;
    height: 100%;
  }
  &-none {
    display: flex;
    align-items: center;
    justify-content: center;
    height: adjustVW(440);
    &-text {
      text-align: center;
      white-space: pre-line;
    }
  }
  &-distribution {
    height: adjustVW(440);
    &-inner {
      height: 100%;
    }
  }
  &-optimization {
    width: 100%;
    margin-top: -$space-small;
    &-title {
      margin-bottom: $space-base;
    }
    &-text {
      margin-bottom: $space-base;
      white-space: pre-line;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &-img {
      margin-top: $space-medium;
    }
  }
}
</style>
