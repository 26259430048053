<template>
  <div class="before-setting-wrap">
    <list-item-slot :field="fields" :items="items">
      <template v-slot:title="entry">
        <div class="before-setting-main">
          <template v-if="entry.entry.result.isTrainedAi">
            <texts class="before-setting-title">
              <a-time :value="entry.item" withTime />
              <span>{{
                $t('training.timeTransformerV2.setting.createdDate')
              }}</span>
            </texts>
          </template>
          <template v-else>
            <texts class="before-setting-title" :text="entry.item" />
          </template>
          <button-main
            class="before-setting-button"
            :text="$t('training.timeTransformerV2.setting.usePreviousSubmit')"
            size="thin"
            type="emphasis"
            :fontSize="16"
            @click="selectSetting(entry.listIndex)"
          />
        </div>
      </template>
      <template v-slot:result="entry">
        <div class="before-setting-result">
          <text-with-title :title="$t('trainedAi.metrics.test_rmse')">
            <texts v-if="!entry.item.isTrainedAi" :text="entry.item.rmse" />
            <metrics-text v-else :value="entry.item.rmse" :percentage="false" />
          </text-with-title>
          <text-with-title :title="$t('trainedAi.metrics.test_mae')">
            <texts v-if="!entry.item.isTrainedAi" :text="entry.item.rmse" />
            <metrics-text v-else :value="entry.item.rmse" :percentage="false" />
          </text-with-title>
        </div>
      </template>
    </list-item-slot>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main.vue'
import metricsText from '@/components/atoms/metrics-text.vue'
import listItemSlot from '@/components/molecules/list-item-slot.vue'
import textWithTitle from '@/components/molecules/text-with-title.vue'
import aTime from '@/components/atoms/a-time.vue'
export default {
  components: {
    buttonMain,
    metricsText,
    listItemSlot,
    textWithTitle,
    aTime
  },
  props: {
    timeTransformerV2Settings: Array
  },
  computed: {
    fields() {
      return [
        {
          label: this.$t('training.timeTransformerV2.setting.previousTitle'),
          key: 'title',
          width: 360
        },
        {
          label: this.$t('training.timeTransformerV2.setting.previousResult'),
          key: 'result',
          width: 320
        }
      ]
    },
    items() {
      return this.timeTransformerV2Settings.map((setting) => {
        return {
          title: setting.isTrainedAi
            ? setting.createdAt
            : this.$t('training.timeTransformerV2.setting.previousSetting'),
          result: {
            rmse: setting?.rmse ?? '-',
            mae: setting?.mae ?? '-',
            isTrainedAi: setting.isTrainedAi
          }
        }
      })
    }
  },
  methods: {
    selectSetting(index) {
      const newSetting = this.timeTransformerV2Settings[index]
      this.$emit('select-time-transformer-setting', newSetting)
    }
  }
}
</script>

<style lang="scss" scoped>
.before-setting {
  &-wrap {
    overflow-y: scroll;
    padding-right: $space-base;
    @include scrollbar;
  }
  &-title {
    margin-bottom: $space-sub;
  }
  &-button {
    height: adjustVW(36);
  }
  &-result {
    display: flex;
    align-items: flex-start;
    grid-column-gap: $space-medium;
  }
}
</style>
