<template>
  <div class="select-box-list-wrap">
    <div ref="scrollWrap" class="select-box-list-scroll" @scroll="scroll">
      <template v-for="(item, index) in selectItems">
        <div :key="index" class="select-box-list-item">
          <select-box-input
            :ref="'selectBoxInput' + item.id"
            class="select-box-list-item-box"
            :placeholder="
              $t('selectTargetColumn.menu.targetColumnInfo.selectColumn')
            "
            :value="item.value"
            :items="items"
            isGray
            isScroll
            :noDisabledItem="noDisabledItem"
            isSeparateBox
            :isCaution="item.caution"
            :isNotExistItem="item.notExistItem"
            :isCloseItem="item.id !== isCloseItem"
            :marginLeft="marginLeft"
            :firstSelectedItem="item.value ? item.value : null"
            :tipsContents="cautionTips(item)"
            :noIcon="noIcon"
            :option="option"
            @input="updateValues($event, item.id)"
            @select-item="selectItem($event, item.id)"
            @select-box-open="openItem(item.id)"
          />
          <button
            class="select-box-list-item-icon"
            @click="removeInput(item.id, item.value)"
          >
            <icons iconName="delete" :size="24" color="function" />
          </button>
        </div>
      </template>
    </div>
    <div v-tooltip="disabledTips" class="select-box-list-add">
      <button-main
        class="select-box-list-add-button"
        :type="checkDisabled ? 'disabled' : 'dashed-sub'"
        :text="$t('selectTargetColumn.menu.targetColumnInfo.addButton')"
        :disabled="checkDisabled"
        @click="addInput"
      />
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import selectBoxInput from '@/components/molecules/select-box-input.vue'
import icons from '@/components/atoms/icon'

export default {
  components: {
    buttonMain,
    selectBoxInput,
    icons
  },
  data() {
    return {
      addCount: 0,
      selectItems: [],
      isCloseItem: null,
      scrollWrapTop: 0
    }
  },
  props: {
    /** 選択項目として表示する対象 */
    items: Array,
    /** 現在選択しているアイテム */
    selectedItems: Array,
    /** 各種Filterで判定する対象 */
    baseColumns: Array,
    /** 予測する列を追加ボタンを押せないときに渡す判定 */
    disabledAdd: Boolean,
    /** cautionを表示するアイテムを追加したいときに指定 */
    filterItem: String,
    /** 使用不可の列であることを通知する必要がない場合にtrue */
    noDisabledItem: Boolean,
    /** セレクトボックスの選択項目がないことを通知 */
    noSelectItems: Boolean,
    /** 全てのセレクトボックスを閉じる処理を検知 */
    closeOther: Boolean,
    /** セレクトボックスを切られたくないときに、どれだけ左の余白をあけて表示するかの数値 */
    marginLeft: {
      type: Number,
      default: 0
    },
    /** アイコンを非表示 */
    noIcon: {
      type: Boolean,
      default: false
    },
    /** fuse-searchのオプションを置き換える */
    option: {
      type: Object,
      default: () => {
        return {
          includeScore: true,
          shouldSort: true,
          threshold: 0.2,
          keys: [
            {
              name: 'name',
              weight: 0.5
            }
          ],
          distance: 10000
        }
      }
    }
  },
  computed: {
    // IDを含めずに配列だけemitするためのフィルター
    filteredSelectItem() {
      const target = [...this.selectItems]
      const filter = target.filter((item) => {
        let checkExist = true
        if (!this.noDisabledItem) {
          // 手動入力での選択項目にない項目の入力を許容するかどうか
          checkExist = this.baseColumns.some((base) => {
            return base === item.value
          })
        }
        return checkExist && !item.caution && item.value
      })
      return filter.map((item) => {
        return item.value
      })
    },
    /**
     * disabledになるパターン
     * 選択項目が存在しないかつ、追加カウントが選択している項目数より多いとき
     * 上からdisabledが与えられているとき
     * なにも入力されていないセレクトボックスあるとき
     * 手動入力を許容していないときに、選択項目の数がセレクトボックスの追加カウントと一致するとき
     */
    checkDisabled() {
      const checkNullItem = this.selectItems.some((item) => {
        return item.value.length === 0
      })
      return (
        (this.addCount > this.selectItems.length && this.noSelectItems) ||
        this.disabledAdd ||
        checkNullItem ||
        (this.baseColumns.length - 1 === this.addCount && !this.noDisabledItem)
      )
    },
    disabledTips() {
      let tipsText = this.$t(
        'molecules.selectInputList.caution.notSelectColumnExist'
      )
      if (
        this.baseColumns.length - 1 === this.addCount &&
        !this.noDisabledItem
      ) {
        tipsText = this.$t('molecules.selectInputList.caution.upperLimit')
      }
      return {
        content: this.checkDisabled ? tipsText : '',
        trigger: 'hover',
        delay: { show: 300, hide: 300 }
      }
    }
  },
  methods: {
    updateValues(e, id) {
      const target = this.selectItems.find((item) => {
        return item.id === id
      })
      if (this.noDisabledItem && !this.noSelectItems) {
        // 手動入力で選択項目以外を入力していいとき、かつ選択項目が０ではないときに、グレーで表示するかを判定する
        const filterNoItem = !this.baseColumns.some((item) => {
          return e !== '' && item === e
        })
        if (filterNoItem) {
          target.notExistItem = true
        } else {
          target.notExistItem = false
        }
      }
      target.value = e

      // 重複チェック
      this.selectItems.forEach((item, index) => {
        if (item.value === this.filterItem) {
          item.caution = true
        } else {
          const indexCheck = this.selectItems.findIndex(
            (indexItem) => item.value === indexItem.value
          )
          if (index > indexCheck) {
            item.caution = true
          } else {
            item.caution = false
          }
        }
      })

      this.$nextTick(() => {
        this.$emit('input', this.filteredSelectItem)
      })
    },
    // ボタンで選択する場合はそもそも重複を選択できないので判定なし
    selectItem(e, id) {
      const target = this.selectItems.find((item) => {
        return item.id === id
      })
      target.value = e.selectItem
      this.$nextTick(() => {
        this.$emit('select-item', this.filteredSelectItem)
      })
    },
    addInput() {
      if (
        this.baseColumns.length - 1 !== this.addCount ||
        this.noDisabledItem ||
        this.noSelectItems
      ) {
        this.selectItems.push({
          id: this.getUniqueId(),
          value: '',
          caution: false,
          disabled: false,
          notExistItem: false
        })
        this.addCount++
        this.$nextTick(() => {
          this.$refs.scrollWrap.lastChild.scrollIntoView({
            behavior: 'smooth'
          })
        })
      }
    },
    removeInput(id, val) {
      this.selectItems = this.selectItems.filter((item) => {
        return item.id !== id
      })
      // 重複した要素を削除した場合、最初に見つかった重複していた値のcautionをfalseにする
      const checkDupliCateItemDelete = this.selectItems.find((item) => {
        return item.value === val
      })
      if (checkDupliCateItemDelete && this.filterItem !== val) {
        checkDupliCateItemDelete.caution = false
      }
      this.addCount--
      this.isCloseItem = null
      this.$nextTick(() => {
        this.$emit('select-item', this.filteredSelectItem)
        this.$emit('input', this.filteredSelectItem)
      })
    },
    // addCountで管理すると[0, 1, 2]から1を削除して、再度追加したときに[0, 2, 2]になりうる。そのため、タイムスタンプでユニークなIDを生成
    getUniqueId() {
      const strong = 1000
      return (
        new Date().getTime().toString(16) +
        Math.floor(strong * Math.random()).toString(16)
      )
    },
    cautionTips(item) {
      return item?.caution
        ? this.$t('molecules.selectInputList.caution.duplicate')
        : item?.notExistItem
        ? this.$t('molecules.selectInputList.caution.noItem')
        : null
    },
    openItem(id) {
      this.isCloseItem = id
      this.$emit('select-box-open')
    },
    // scrollイベント発火時に下層のselect-box-inputのセレクトボックスの位置調整イベントを発火
    scroll() {
      if (this.isCloseItem) {
        this.$refs['selectBoxInput' + this.isCloseItem][0].checkPos()
      }
    }
  },
  watch: {
    closeOther(newVal) {
      if (newVal) {
        this.isCloseItem = null
      }
    },
    selectedItems(newVal) {
      const checkItems = this.selectItems.filter((item) => {
        return item?.value
      })
      if (newVal.length > checkItems.length) {
        this.selectItems = []
        this.addCount = 0
        this.isCloseItem = null
        this.selectItems = newVal.map((item, index) => {
          return {
            id: this.getUniqueId() + index,
            value: item,
            caution: false,
            disabled: false,
            notExistItem: false
          }
        })
        this.addCount = this.selectItems.length
        this.$nextTick(() => {
          this.$refs.scrollWrap.lastChild.scrollIntoView({
            behavior: 'smooth'
          })
        })
      }
    },
    filterItem(newVal) {
      this.selectItems.forEach((item, index) => {
        if (item.value === newVal) {
          item.caution = true
        } else {
          const indexCheck = this.selectItems.findIndex(
            (indexItem) => item.value === indexItem.value
          )
          if (index > indexCheck) {
            item.caution = true
          } else {
            item.caution = false
          }
        }
      })
      this.$nextTick(() => {
        this.$emit('select-item', this.filteredSelectItem)
        this.$emit('input', this.filteredSelectItem)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.select-box-list {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    height: 100%;
  }
  &-scroll {
    overflow-y: auto;
    flex-shrink: 1;
    margin-bottom: $space-medium;
    @include scrollbar;
  }
  &-item {
    display: flex;
    align-items: center;
    margin-bottom: $space-small;
    &:last-of-type {
      margin-bottom: 0;
    }
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: adjustVW(36);
      height: adjustVW(56);
      background-color: $red-bg;
      border-radius: 0 adjustVW(4) adjustVW(4) 0;
      cursor: pointer;
    }
    &-box {
      &::v-deep .c-func-select-button {
        border-radius: adjustVW(4) 0 0 adjustVW(4);
      }
    }
  }
  &-add {
    width: 100%;
    &-button {
      width: 100%;
      height: adjustVW(56);
    }
  }
}
</style>
