<template>
  <div class="wrap">
    <div class="items-wrap">
      <list-item :items="recipes" :field="field" isToggle>
        <template v-slot:toggleContent="{ entry }">
          <div class="title-wrap">
            <div>
              <description :text="entry.description" />
            </div>
          </div>
          <!-- 使用した学習済みAI 表示できるようになるまでコメントアウト https://trello.com/c/7tsGk4h5/243-%E3%83%AC%E3%82%B7%E3%83%94%E6%AE%8B%E3%82%BF%E3%82%B9%E3%82%AF -->
          <!-- <div class="block">
            <div class="inner">
              <texts class="title" text="使用した学習済みAI" color="gray" size="small"/>
              <template >
                <div class="list">
                  <text-with-icon class="item" text="text" iconName="newTab" pos="right"/>
                  <text-with-icon class="item" text="text" iconName="newTab" pos="right"/>
                  <text-with-icon class="item" text="text" iconName="newTab" pos="right"/>
                  <text-with-icon class="item" text="text" iconName="newTab" pos="right"/>
                </div>
              </template>
            </div>
          </div> -->
          <div class="block">
            <div class="inner">
              <text-with-title
                :title="$t('common.owner')"
                :text="entry.accountId"
              />
            </div>
          </div>
          <div class="block">
            <div class="inner">
              <text-with-title :title="$t('common.createTime')">
                <a-time :value="entry.createTime" />
              </text-with-title>
            </div>
            <div class="inner">
              <text-with-title :title="$t('common.updateTime')">
                <a-time :value="entry.updateTime" />
              </text-with-title>
            </div>
          </div>
        </template>
      </list-item>
    </div>
    <div class="footer">
      <div class="button">
        <button-main
          type="sub"
          :text="$t('common.cancel')"
          :disabled="disableClick"
          @click="$emit('cancel')"
        />
      </div>
      <div class="button">
        <button-main
          type="caution"
          :text="$t('common.deleteButton')"
          :disabled="disableClick"
          @click="deleteService"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import texts from '@/components/atoms/text'
import description from '@/components/atoms/description'
import buttonMain from '@/components/atoms/button-main'
import textWithTitle from '@/components/molecules/text-with-title'
// import textWithIcon from '@/components/molecules/text-with-icon'
import listItem from '@/components/molecules/list-item'

export default {
  components: {
    // texts,
    description,
    buttonMain,
    textWithTitle,
    // textWithIcon,
    listItem
  },
  data() {
    return {
      field: [
        {
          label: this.$t('recipeList.field.delete'),
          key: 'name'
        }
      ]
    }
  },
  props: {
    recipes: Array,
    disableClick: Boolean
  },
  methods: {
    deleteService() {
      this.$emit('delete-recipes', this.recipes)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: adjustVW(720);
  height: 100%;
  margin: $space-base 0 0;
  .items-wrap {
    overflow-y: scroll;
    padding: 0 $space-small 0 0;
    margin: 0 0 $space-medium;
    @include scrollbar;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
  }
  .button {
    margin: 0 $space-medium 0 0;
    &:last-of-type {
      margin: 0;
    }
  }
}
.block {
  display: flex;
  margin: 0 0 $space-medium;
  &:last-of-type {
    margin: 0;
  }
}
.title {
  margin: 0 0 $space-text;
}
.list {
  display: flex;
  flex-wrap: wrap;
}
.item {
  width: calc(50% - #{$space-base});
  margin: 0 $space-small $space-base 0;
  &:nth-of-type(2n) {
    margin: 0 0 $space-base;
  }
  &:nth-last-of-type(-n + 2) {
    margin-bottom: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}
.inner {
  overflow: hidden;
  width: 100%;
  margin: 0 $space-small 0 0;
  &:last-child {
    margin: 0;
  }
}
.title-wrap {
  margin: -$space-base 0 $space-medium;
}
</style>
