<template>
  <div class="inference-regression-result-wrapper">
    <div class="inference-regression-result-main">
      <card-base class="inference-regression-result-main-card">
        <numerical-table
          class="inference-regression-result-main-card-table"
          :targetColumn="targetColumn"
          :tableColumns="tableColumns"
          :table="table"
          :graphProb="graphProb"
          :importanceProb="importanceProb"
          :inPageNumber="inPageNumber"
          :pagingCount="limit"
          :loading="loading"
          :usedAiInfo="usedAiInfo"
          :selectedItem="selectedItem"
          :finishColumnsFix="finishColumnsFix"
          :inferencedColumns="inferencedColumns"
          @change-column="$emit('change-column', $event)"
        />
        <paging
          :value="inPageNumber"
          class="inference-regression-result-main-card-paging"
          :count="finishedAll ? total : finishedTotal"
          :pagingCount="limit"
          :isDisabled="loading"
          :isLoading="!finishedAll"
          @input="$emit('change-page', $event)"
        />
      </card-base>
    </div>

    <div class="inference-regression-result-used-ai">
      <card-base class="inference-regression-result-used-ai-card">
        <model-info
          :title="$t('inference.result.card.usedAi')"
          :modelInfo="usedAiInfo"
        />
      </card-base>
    </div>
  </div>
</template>

<script>
import cardBase from '@/components/atoms/card-base.vue'
import numericalTable from '@/components/organisms/inference/numerical-table.vue'
import modelInfo from '@/components/organisms/model-info.vue'
import Paging from '@/components/molecules/paging.vue'

export default {
  components: {
    cardBase,
    numericalTable,
    modelInfo,
    Paging
  },
  props: {
    usedAiInfo: Object,
    targetColumns: Array,
    tableColumns: Array,
    table: Array,
    graphProb: Array,
    importanceProb: Array,
    total: Number,
    finishedTotal: Number,
    limit: Number,
    inPageNumber: Number,
    selectedItem: String,
    finishColumnsFix: Array,
    selectedColumnIndex: Number,
    loading: Boolean,
    inferencedColumns: Array
  },
  computed: {
    targetColumn() {
      if (!this.targetColumns || this.targetColumns.length === 0) return null
      return this.targetColumns[this.selectedColumnIndex]
    },
    finishedAll() {
      return this.finishedTotal === null || this.finishedTotal >= this.total
    }
  }
}
</script>

<style lang="scss" scoped>
.inference-regression-result-wrapper {
  display: grid;
  grid-template-columns: adjustVW(1224) minmax(0, #{adjustVW(384)});
  grid-template-rows: 1fr;
  grid-column-gap: $space-medium;
  grid-row-gap: $space-medium;
  height: 100%;
  padding: 0 $space-medium $space-medium;
}

.inference-regression-result {
  &-main {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }

  &-features {
    width: 100%;
    height: 100%;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    &-card {
      height: 100%;
    }
    &-inner {
      height: 100%;
      padding: $space-small $space-small $space-base;
      margin: 0;
    }
  }

  &-used-ai {
    width: 100%;
    height: 100%;
  }

  &-main-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    &-paging {
      align-self: flex-end;
      margin-top: $space-small;
    }
    &-table {
      flex-shrink: 1;
      height: 100%;
    }
  }

  &-used-ai-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    max-height: 100%;
    padding: $space-small;
  }
}
</style>
