var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"preprocessing-list-delete-item"},[(_vm.entry.description)?_c('div',{staticClass:"preprocessing-list-delete-item-block"},[_c('description',{attrs:{"text":_vm.entry.description}})],1):_vm._e(),_c('div',{staticClass:"preprocessing-list-delete-item-block"},[_c('div',{staticClass:"preprocessing-list-delete-item-inner"},[_c('texts',{staticClass:"preprocessing-list-delete-item-title",attrs:{"text":_vm.$t('preprocessing.preprocessingContent'),"size":"small","color":"gray"}}),_c('div',{staticClass:"preprocessing-list-delete-item-content"},[_c('preprocessign-chart-list-min',{staticClass:"preprocessing-list-delete-item-list",attrs:{"layers":_vm.preprocChart ? _vm.preprocChart.layers : _vm.entry.layers,"isShowAll":""}})],1)],1)]),_c('div',{staticClass:"preprocessing-list-delete-item-block"},[(_vm.entry.service && _vm.entry.service.length > 0)?_c('div',{staticClass:"preprocessing-list-delete-item-inner"},[_c('texts',{staticClass:"preprocessing-list-delete-item-title",attrs:{"text":_vm.$t('preprocessing.useSevise'),"size":"small","color":"gray"}}),_vm._l((_vm.entry.service),function(item,key){return _c('div',{key:key,staticClass:"preprocessing-list-delete-item-content"},[_c('router-link',{staticClass:"preprocessing-list-delete-item-content-link",attrs:{"to":{
            name: 'serviceProjectDetail',
            params: {
              id: item.link,
              projectId: _vm.projectId,
              serviceType: item.serviceType
            }
          }}},[_c('text-with-icon',{attrs:{"text":item.name,"iconName":"newTab","pos":"right","color":"link-default"}})],1)],1)})],2):_vm._e(),_c('div',{staticClass:"preprocessing-list-delete-item-inner"},[_c('text-with-title',{attrs:{"title":_vm.$t('common.owner'),"text":_vm.entry.accountId}})],1)]),_c('div',{staticClass:"preprocessing-list-delete-item-block"},[_c('div',{staticClass:"preprocessing-list-delete-item-inner"},[_c('text-with-title',{attrs:{"title":_vm.$t('common.createTime')}},[_c('a-time',{attrs:{"value":_vm.entry.createTime}})],1)],1),_c('div',{staticClass:"preprocessing-list-delete-item-inner"},[_c('text-with-title',{attrs:{"title":_vm.$t('common.updateTime')}},[_c('a-time',{attrs:{"value":_vm.entry.updateTime}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }