<template>
  <div class="clustering-result">
    <card-base>
      <div class="clustering-result-inner">
        <div class="clustering-result-head">
          <clustering-class-select
            :clusteringSetting="clusteringSetting"
            :selectItems="selectItems"
            :isInference="isInference"
            @select-class="
              inputClusteringSetting($event.selectItem.value, 'changeClass')
            "
          />
          <div class="clustering-result-head-download">
            <download-button-table
              :download="clusteringSetting.download"
              :buttonList="downloadButtonList"
              @download-all-result="downloadClustering('all', $event)"
              @download-filtered-result="downloadClustering('filtered', $event)"
            />
          </div>
        </div>
        <div class="clustering-result-body">
          <clusterflow-table
            :clusteringInfo="clusteringInfo"
            :clusteringResult="clusteringResult"
            :sort="clusteringSetting.setting.sort"
            :loading="clusteringSetting.loadingTable"
            :resultId="clusteringSetting.setting.resultId"
            :inPageNumber="clusteringSetting.setting.inPageNumber"
            :limit="clusteringSetting.setting.limit"
            @change-sort="inputClusteringSetting($event, 'changeSort')"
          />
          <paging
            :value="clusteringSetting.setting.inPageNumber"
            class="clustering-result-paging"
            :count="clusteringResult.total"
            :pagingCount="clusteringSetting.setting.limit"
            @input="inputClusteringSetting($event, 'changePage')"
          />
        </div>
      </div>
    </card-base>
    <card-base>
      <div class="clustering-distribution">
        <div class="clustering-distribution-top">
          <div class="clustering-distribution-title">
            <texts
              :text="$t('clustering.result.tab.distribution')"
              size="large"
            />
            <icons
              v-tooltip="$t('clustering.result.distribution.tips')"
              iconName="info"
              color="gray"
              size="small"
            />
          </div>
          <div
            v-if="distributionItems.hasDimensionValue"
            class="clustering-distribution-toggle"
          >
            <toggle-button
              v-tooltip="
                $t('clustering.result.distribution.showDimension.tips')
              "
              :checked="clusteringSetting.setting.showDimension"
              :text="$t('clustering.result.distribution.showDimension.button')"
              @input="$emit('toggle-clustering-show-dimension', $event)"
            />
          </div>
        </div>
        <div>
          <clusterflow-distribution-wrap
            :setting="clusteringSetting.distributionSetting[0]"
            :classes="clusteringResult.classes"
            :items="clusteringDistributions[0]"
            :clusteringInfo="clusteringInfo"
            :loading="clusteringSetting.loadingDistribution"
            :selectItems="distributionItems.items"
            :showDimension="clusteringSetting.setting.showDimension"
            :hasDimensionValue="distributionItems.hasDimensionValue"
            @change-clustering-distribution-column="
              changeClusteringDistributionColumn($event, 0)
            "
          />
        </div>
      </div>
    </card-base>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon.vue'
import cardBase from '@/components/atoms/card-base'
import toggleButton from '@/components/atoms/toggle-button'
import paging from '@/components/molecules/paging.vue'
import downloadButtonTable from '@/components/molecules/download-button-table.vue'
import clusterflowTable from '@/components/organisms/clusterflow/clusterflow-table.vue'
import clusterflowDistributionWrap from '@/components/organisms/clusterflow/clusterflow-distribution-wrap.vue'
import ClusteringClassSelect from './cards/clustering-class-select.vue'

export default {
  components: {
    icons,
    cardBase,
    toggleButton,
    paging,
    downloadButtonTable,
    clusterflowTable,
    clusterflowDistributionWrap,
    ClusteringClassSelect
  },
  props: {
    clusteringInfo: Object,
    clusteringSetting: Object,
    clusteringResult: Object,
    clusteringDistributions: Array,
    distributionItems: Object,
    isInference: Boolean
  },
  computed: {
    downloadButtonList() {
      return [
        {
          text: this.$t('common.download.all'),
          event: 'download-all-result',
          tips: {
            default: this.$t('clustering.download.description.all')
          }
        },
        {
          text: this.$t('common.download.displayed'),
          event: 'download-filtered-result',
          tips: {
            default: this.$t('clustering.download.description.displayed')
          }
        }
      ]
    },
    selectItems() {
      return this.clusteringInfo.resultList.map((classItem) => {
        const name =
          this.clusteringInfo?.type === 'dbscan'
            ? this.$t('clustering.result.classNameFix', {
                id: classItem.id,
                nClusters: classItem.nClusters
              })
            : classItem.id
        return {
          name: name,
          value: classItem.id
        }
      })
    }
  },
  methods: {
    inputClusteringSetting(value, type) {
      this.$emit('input-clustering-setting', {
        value,
        type
      })
    },
    downloadClustering(type, encoding) {
      this.$emit('download-clustering-result', {
        type,
        encoding
      })
    },
    changeClusteringDistributionColumn(event, index) {
      this.$emit('change-clustering-distribution-column', {
        ...event,
        index
      })
    }
  },
  mounted() {
    if (this.clusteringSetting.setting.resultId == null) {
      const resultId = this.clusteringInfo.resultList[0].id
      this.inputClusteringSetting(resultId, 'changeClass')
    }
  }
}
</script>

<style lang="scss" scoped>
.clustering-result {
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(0, 1fr) adjustVW(544);
  grid-template-rows: minmax(0, 1fr);
  grid-column-gap: $space-medium;
  height: 100%;
  padding: 0 $space-medium $space-medium;
  &-inner {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-sub;
    height: 100%;
  }
  &-body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-small;
    height: 100%;
  }
  &-head {
    display: grid;
    align-items: flex-start;
    justify-content: space-between;
    grid-template-columns: adjustVW(264) 1fr;
    &-download {
      justify-self: flex-end;
    }
    &-title {
      margin-bottom: $space-base;
    }
    &-input {
      height: adjustVW(48);
    }
  }
  &-paging {
    align-self: flex-end;
  }
}
.clustering-distribution {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  height: 100%;
  &-title {
    display: flex;
    justify-content: space-between;
    grid-column-gap: $space-sub;
  }
  &-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: $space-sub;
  }
}
</style>
