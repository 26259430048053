import { duplicateChecker, duplicateSuggests } from './misc'

/**
 * @param { Object[] } trainedAIList
 * @param { Object } newAI
 * @returns { Object }
 **/
export function trainedAIValidator(trainedAIs, newAI) {
  const trainedAIKeys = Object.keys(trainedAIs)

  const nameList = trainedAIKeys.map((id) => trainedAIs[id].name)
  const newAIName = newAI.name

  const checkResult = duplicateChecker(nameList, newAIName)
  let suggests = []
  if (!checkResult) {
    suggests = duplicateSuggests(nameList, newAIName)
  }

  return {
    duplicate: checkResult,
    suggests: suggests
  }
}
