var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"side-wrap"},[_c('div',{staticClass:"side-dataset"},[_c('div',{staticClass:"side-dataset-top"},[_c('texts',{staticClass:"side-title",attrs:{"text":_vm.$t('preprocessing.sideTopTitle'),"size":"small","color":"gray"}}),_c('texts',{staticClass:"side-dataset-name",attrs:{"text":_vm.firstSelectDataset.name}})],1),_c('div',{staticClass:"side-dataset-bottom"},[(_vm.transitionType == '0')?_c('div',{staticClass:"side-dataset-bottom-col-wrap"},[_c('texts',{staticClass:"side-dataset-bottom-col-title",attrs:{"text":_vm.$t('preprocessing.applyingDataset.objectiveVariable'),"size":"small","color":"gray"}}),(_vm.objectiveColumn.length !== 0)?_c('div',{staticClass:"side-dataset-bottom-col",class:{
            'side-dataset-bottom-col-multi': _vm.objectiveColumn.length > 1
          }},_vm._l((_vm.objectiveColumn),function(column){return _c('div',{key:column,staticClass:"side-dataset-bottom-col-text"},[_c('texts',{staticClass:"side-dataset-bottom-col-text-item",attrs:{"text":column,"size":_vm.objectiveColumn.length > 1 ? 'small' : 'default'}})],1)}),0):_c('texts',{staticClass:"side-dataset-bottom-col-no-item",attrs:{"text":_vm.$t('preprocessing.applyingDataset.noneObjectiveVariable'),"color":"caution"}})],1):_vm._e(),_c('div',{staticClass:"side-dataset-bottom-bottom"},[_c('div',{staticClass:"side-dataset-bottom-bottom-num"},[_c('text-with-title',{attrs:{"title":_vm.$t('preprocessing.applyingDataset.datasetNum'),"text":_vm.nData}})],1),_c('div',{staticClass:"side-dataset-bottom-bottom-detail"},[_c('button',{on:{"click":function($event){return _vm.$emit('go-detail')}}},[_c('text-with-icon',{attrs:{"text":_vm.$t('common.openDetail'),"iconName":"newTab","pos":"right","color":"link-default","size":"small"}})],1)])])])]),(_vm.preprocessingArray.length > 0)?_c('div',{staticClass:"side-preprocessing"},[_c('div',{staticClass:"side-preprocessing-top"},[_c('texts',{staticClass:"side-title",attrs:{"text":_vm.$t('preprocessing.sideBottomTitle'),"isBold":""}}),_c('div',{staticClass:"side-box"},[_c('select-box',{attrs:{"items":_vm.preprocessingArray,"placeholder":_vm.existsPreprocessing
              ? ''
              : _vm.$t('preprocessing.applyingDataset.placeholderPreprocessing'),"firstSelectItem":_vm.firstSelectPreprocessing,"isGray":"","min":"","scrollBar":""},on:{"select-item":function($event){return _vm.$emit(
              'select-preprocessing',
              _vm.preprocessingArray[$event.selectItem].id
            )}}})],1),(_vm.existsPreprocessing)?_c('div',{staticClass:"side-chart-wrap"},[_c('texts',{staticClass:"side-title side-title-min",attrs:{"text":_vm.$t('preprocessing.preprocessingContent'),"color":"gray","size":"small"}}),_c('div',{staticClass:"side-chart"},[_c('preprocessing-chart-list-min',{staticClass:"side-chart-list",attrs:{"layers":_vm.layers,"isUsed":_vm.isUsed,"line":2}}),(_vm.isUsed)?_c('div',{staticClass:"side-text"},[_c('texts',{attrs:{"size":"small","color":"emphasis","isBold":"","text":_vm.$t('preprocessing.applied')}})],1):_vm._e()],1)],1):_vm._e()],1),_c('div',{staticClass:"side-preprocessing-bottom"},[_c('popup-balloon',{staticClass:"side-popup",attrs:{"isShow":_vm.errorPopup.isShow,"showLeft":"","error":""},on:{"close":function($event){return _vm.$emit('close-error-popup')}}},[_c('texts',{staticClass:"side-popup-inner",attrs:{"isShowAll":""}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.errorPopup.text)}})])],1),_c('div',{staticClass:"side-button"},[_c('button-main',{attrs:{"text":_vm.$t('preprocessing.applyingDataset.applyButton'),"type":_vm.isUsed || !_vm.existsPreprocessing ? 'disabled' : 'emphasis',"isDisabled":_vm.isUsed || !_vm.existsPreprocessing},on:{"click":function($event){return _vm.$emit('apply-preprocessing')}}})],1)],1)]):_c('div')])}
var staticRenderFns = []

export { render, staticRenderFns }