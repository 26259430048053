<template>
  <div class="list-wrap">
    <div
      class="list-body"
      :class="{
        'list-body-training': trainingFlag
      }"
    >
      <components
        :is="checkDisplayType"
        :selectedTrainingData="selectedTrainingData"
        :selectedTrainingDataSample="selectedTrainingDataSample"
        :rawPredictionColumnOptions="rawPredictionColumnOptions"
        :sortedTrainedAis="sortedTrainedAis"
        :selectedRecipe="selectedRecipe"
        :selectedColumnIndex="selectedColumnIndex"
        :predictionColumns="predictionColumns"
        :optimizationColumns="value.optimizationConditions.optimizationColumns"
        :disableOptimization="disableOptimization"
        :timeTransformerV2Filter="timeTransformerV2Filter"
        :timeTransformerV2Setting="timeTransformerV2Setting"
        :fixTimeTransformerV2Setting="fixTimeTransformerV2Setting"
        @input="updateValue($event)"
        @show-optimization-tutorial="$emit('show-optimization-tutorial')"
        @show-time-transformer-v2-detail="
          $emit('show-time-transformer-v2-detail', $event)
        "
        @show-time-transformer-before-setting="
          $emit('show-time-transformer-before-setting')
        "
      />
    </div>
    <div class="training-status">
      <transition-toggle-contents>
        <training-status
          v-if="trainingFlag"
          :status="trainingProgressStatus"
          :time="elapsedTime"
          :maxProgress="checkTimeseriesNumIter"
          :progress="checkTimeseriesProgress"
          :trainingColumnStatus="trainingColumnStatus"
          :checkOptimization="checkOptimization"
          :optimizationNumIter="optimizationNumIter"
          :optimizationProgress="optimizationProgress"
          @training-stop="$emit('training-stop')"
        />
      </transition-toggle-contents>
      <transition-toggle-contents>
        <train-tips-optimization
          v-if="trainingFlag && checkOptimization"
          class="training-status-tips"
        />
      </transition-toggle-contents>
    </div>
  </div>
</template>

<script>
import trainingStatus from '@/components/organisms/training/popup/training-status'
import trainTipsOptimization from '@/components/organisms/training/popup/train-tips-optimization'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

import figures from './list-type/figures'
import naturalLanguages from './list-type/natural-languages'
import images from './list-type/images'
import optimization from './list-type/optimization'
import timeTransformerV2 from './list-type/time-transformer-v2'

export default {
  components: {
    trainingStatus,
    trainTipsOptimization,
    transitionToggleContents,
    figures,
    naturalLanguages,
    images,
    optimization,
    timeTransformerV2
  },
  props: {
    value: Object,
    rawPredictionColumnOptions: Object,
    selectedTrainingData: Object,
    selectedTrainingDataSample: Object,
    selectedTrainingDataType: String,
    selectedRecipe: Object,
    recipeType: String,
    trainingProgressStatus: String,
    elapsedTime: Number,
    trainingNumIter: Number,
    trainingProgress: Number,
    optimizationNumIter: Number,
    optimizationProgress: Number,
    sortedTrainedAis: Array,
    finishColumns: Array,
    selectedColumnIndex: Number,
    trainingColumnStatus: Object,
    checkOptimization: Boolean,
    trainingFlag: Boolean,
    predictionColumns: Array,
    disableOptimization: Array,
    learningPredictionColumns: Array,
    timeTransformerV2Filter: Array,
    timeTransformerV2Setting: Object,
    fixTimeTransformerV2Setting: Array
  },
  computed: {
    // component の箇所をデータセットの形式によって出しわけ
    checkDisplayType() {
      let displayType = null
      if (!this.selectedTrainingDataType) {
        displayType = null
      } else if (this.checkOptimization) {
        displayType = 'optimization'
      } else if (this.recipeType === 'TIME_TRANSFORMER2') {
        displayType = 'timeTransformerV2'
      } else if (this.selectedTrainingDataType === 'figures') {
        displayType = 'figures'
      } else if (this.selectedTrainingDataType === 'images') {
        displayType = 'images'
      } else if (this.selectedTrainingDataType === 'texts') {
        displayType = 'natural-languages'
      }
      return displayType
    },
    checkTimeseriesNumIter() {
      let count = this.trainingNumIter
      if (
        this.recipeType &&
        this.$recipeType.timeExceptPreviousTransformer.includes(this.recipeType)
      ) {
        count = this.learningPredictionColumns.length
      } else if (
        this.learningPredictionColumns &&
        this.learningPredictionColumns.length > 1 &&
        !this.$recipeType.timeExceptPreviousTransformer.includes(
          this.recipeType
        )
      ) {
        count = this.learningPredictionColumns.length * this.trainingNumIter
      }
      if (
        this.learningPredictionColumns &&
        this.learningPredictionColumns.length > 0 &&
        this.learningPredictionColumns.length === this.finishColumns.length
      ) {
        count = 1
      }
      return count
    },
    checkTimeseriesProgress() {
      let count = this.trainingProgress
      if (!this.learningPredictionColumns) return count
      if (
        this.recipeType &&
        this.$recipeType.timeExceptPreviousTransformer.includes(this.recipeType)
      ) {
        count = this.finishColumns.length
      } else if (
        this.learningPredictionColumns &&
        this.learningPredictionColumns.length > 1 &&
        !this.$recipeType.timeExceptPreviousTransformer.includes(
          this.recipeType
        )
      ) {
        count =
          this.finishColumns.length * this.trainingNumIter +
          this.trainingProgress
      }
      if (
        this.learningPredictionColumns &&
        this.learningPredictionColumns.length > 0 &&
        this.learningPredictionColumns.length === this.finishColumns.length
      ) {
        count = 1
      }
      return count
    }
  },
  methods: {
    updateValue(e) {
      const emitVal = JSON.parse(JSON.stringify(this.value))
      if (e.type === 'figure') {
        emitVal.trainConfig.inputColumns = e.value
        emitVal.trainConfig.overrideInputColumns = e.valueOverride
      } else if (e.type === 'optimization') {
        emitVal.optimizationConditions.columnConditions = e.value
      } else if (e.type === 'timeTransformerV2') {
        emitVal.trainConfig.columnConditions = e.value
        emitVal.trainConfig.overrideColumnConditions = e.valueOverride
        const overrideInputColumns = {}
        for (const key in e.valueOverride) {
          overrideInputColumns[key] = e.valueOverride[key].map(
            (item) => item.columnName
          )
        }
        emitVal.trainConfig.overrideInputColumns = overrideInputColumns
        emitVal.trainConfig.inputColumns = e.value.map(
          (item) => item.columnName
        )
        emitVal.trainConfig.columnConditions.push(...e.predictionColumnValue)
      }
      this.$emit('input', emitVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  &-wrap {
    position: relative;
    height: 100%;
  }
  &-body {
    height: 100%;
    &-training {
      pointer-events: none;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        z-index: 12;
      }
    }
  }
}

.training-status {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  &-tips {
    margin-top: $space-medium;
  }
}
</style>
