<template>
  <card-base class="target-column-card-wrapper">
    <div class="target-column-card-title-wrap">
      <texts
        class="target-column-card-title"
        size="large"
        :text="$t('selectTargetColumn.menu.targetColumnInfo.title')"
      />
      <icons
        v-tooltip.right="helpTips"
        class="target-column-card-title-icon"
        iconName="info"
        size="small"
      />
    </div>
    <transition-toggle-contents>
      <div v-if="!multiSelectMode" key="base">
        <select-box-input
          ref="selectBoxTarget"
          class="target-column-card-box"
          :placeholder="
            $t('selectTargetColumn.menu.targetColumnInfo.selectColumn')
          "
          :value="columnList.selectedColumn[0]"
          :items="items"
          :firstSelectedItem="columnList.selectedColumn[0]"
          isGray
          isScroll
          openStart
          @select-item="updateValues($event)"
        />
        <target-column-info
          v-bind="columnData"
          :noneDatasetDetail="noneDatasetDetail"
        />
      </div>
      <div v-else key="multi" class="target-column-list">
        <transition-toggle-contents>
          <template
            v-if="
              columnList.selectedColumn && columnList.selectedColumn.length > 0
            "
          >
            <transition-group tag="div" name="list-items">
              <bullet-item
                v-for="(item, key) in columnList.selectedColumn"
                :key="key"
                class="target-column-item"
                :text="item"
              />
            </transition-group>
          </template>
          <texts
            v-else
            class="target-column-no-item"
            :text="$t('selectTargetColumn.menu.targetColumnInfo.noMultiItem')"
            size="small"
            color="gray"
          />
        </transition-toggle-contents>
      </div>
    </transition-toggle-contents>
    <button-main
      class="target-column-card-button"
      type="emphasis"
      :text="$t('selectTargetColumn.menu.targetColumnInfo.button')"
      :isDisabled="disabled"
      @click="submitTargetColumn"
    />
  </card-base>
</template>

<script>
import ButtonMain from '@/components/atoms/button-main.vue'
import CardBase from '@/components/atoms/card-base.vue'
import Icons from '@/components/atoms/icon.vue'
import SelectBoxInput from '@/components/molecules/select-box-input.vue'
import TargetColumnInfo from '@/components/organisms/select-target-column/menu/target-column-info.vue'
import Texts from '@/components/atoms/text.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import BulletItem from '@/components/atoms/bullet-item.vue'

export default {
  components: {
    ButtonMain,
    CardBase,
    Icons,
    SelectBoxInput,
    TargetColumnInfo,
    Texts,
    transitionToggleContents,
    BulletItem
  },
  computed: {
    disabled() {
      if (
        this.columnList.selectedColumn.length === 0 ||
        this.loadingDatasetDetail
      )
        return true
      const checkTarget = this.columnList.selectedColumn.filter((item) => {
        return !this.checkNotes[item]
      })
      return checkTarget.length > 0
    },
    items() {
      if (!this.columnList || this.columnList.listItems.length === 0) return []
      return this.columnList.listItems.map((item) => {
        const checkCaution = this.columnList.cautionItems.some(
          (caution) => caution.name === item
        )
        return {
          name: item,
          value: item,
          caution: checkCaution
        }
      })
    },
    helpTips() {
      return {
        content: this.$t('selectTargetColumn.help'),
        trigger: 'hover',
        delay: { show: 200, hide: 200 }
      }
    }
  },
  props: {
    checkNotes: Object,
    columnData: Object,
    columnList: Object,
    tutorialFlag: Boolean,
    multiSelectMode: Boolean,
    loadingDatasetDetail: Boolean,
    noneDatasetDetail: Boolean
  },
  watch: {
    tutorialFlag(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.$refs.selectBoxTarget.focusInput()
        })
      }
    }
  },
  methods: {
    submitTargetColumn: function () {
      this.$emit('submit-target-column')
    },
    updateValues(value) {
      const columnName = value.selectItem.name
      const caution = this.columnList.cautionItems.find(
        (x) => x.name === columnName
      )
      this.$emit('get-selected-column', {
        value: columnName,
        selected: true
      })
      if (caution) {
        this.$emit('check-notes', { key: columnName, showNote: false })
      } else {
        this.$emit('check-notes', { key: columnName, showNote: true })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.target-column-card-wrapper {
  width: 100%;
  height: 100%;
}

.target-column-card-title {
  line-height: 1;
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $space-small;
  }
  &-icon {
    cursor: help;
  }
}

.target-column-card-list {
  overflow-y: scroll;
  max-height: adjustVH(550);
  padding: 0 $space-base 0 0;
  margin-bottom: $space-small;
  @include scrollbar;
}

.target-column-card-box {
  height: auto;
  margin-bottom: $space-small;
  &-add {
    display: flex;
    flex-direction: row;
    margin-bottom: $space-small;
    &-box {
      width: 95%;
      height: auto;
    }
    &-icon {
      height: auto;
      background: $background-sub;
    }
  }
}

.target-column-list {
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  max-height: adjustVH(550);
  padding: 0 $space-base 0 0;
  margin-top: adjustVW(20);
  margin-bottom: $space-medium;
  @include scrollbar;
}

.target-column-item {
  width: 100%;
  margin-bottom: $space-sub;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.target-column-no-item {
  white-space: pre-line;
}

.target-column-info-wrapper {
  margin-bottom: $space-small;
}

.target-column-card-toggle-content {
  margin-bottom: $space-small;
}

.target-column-card-button {
  width: 100%;
}
.target-column-card-addbutton {
  width: 100%;
  margin: 0 0 $space-small;
}

.list-items-enter-active,
.list-items-leave-active {
  transition: all $transition-base;
}
.list-items-enter,
.list-items-leave-to {
  opacity: 0;
  transform: translateX(-$space-small);
  will-change: opacity, transform;
}
</style>
