<template>
  <div
    class="tag-editable-item"
    :class="{ 'tag-editable-item-white': isWhite }"
  >
    <texts :text="name" size="min" color="emphasis" />
    <transition name="toggle-icon">
      <icons
        v-if="edit"
        class="tag-editable-item-icon"
        :text="name"
        size="min"
        iconName="close"
        isButton
        color="emphasis"
        @icon-click="$emit('tag-close', { tagId: id })"
      />
    </transition>
  </div>
</template>

<script>
import texts from './text'
import icons from './icon'

export default {
  components: {
    texts,
    icons
  },
  props: {
    /** id */
    id: Number,
    /** タグに表示する名前 */
    name: String,
    /** 編集中かどうか */
    edit: Boolean,
    /** 背景が白の時用 */
    isWhite: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-editable-item {
  display: inline-flex;
  align-items: center;
  max-width: adjustVW(200);
  height: adjustVH(32);
  padding: $space-text $space-base;
  background: $background;
  border-radius: adjustVW(2);
  &-white {
    background: $background-decoration;
  }
  &-icon {
    width: adjustVW(16);
    margin-left: $space-sub;
    transition: width $transition-base, transform $transition-base,
      margin-left $transition-base, opacity $transition-base;
    will-change: opacity, margin-left, transform, width;
  }
}

.toggle-icon-enter,
.toggle-icon-leave-to {
  width: 0;
  margin-left: 0;
  opacity: 0;
  transform: scale(0);
}
</style>
