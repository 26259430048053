<template>
  <div class="wrap">
    <div class="title">
      <texts
        :text="$t('training.popup.progress.title', { type: displayTypeText })"
        size="large"
      />
      <button
        v-tooltip="disabledStopTips"
        :class="{
          'button-disabled': checkDisabled
        }"
        :disabled="checkDisabled"
        @click="checkDisabled ? '' : $emit('training-stop')"
      >
        <texts
          class="title-button"
          :text="
            $t('training.popup.progress.stopButton', { type: displayTypeText })
          "
          :color="checkDisabled ? 'gray' : 'caution'"
          :class="{ 'title-button-disabled': checkDisabled }"
        />
      </button>
    </div>
    <div class="list">
      <div class="item">
        <div class="item-inner">
          <transition name="toggle-icon" mode="out-in">
            <loading-icon
              v-if="status === 'preparingEnvironment'"
              key="environment"
              class="icon"
              size="small"
              color="emphasis"
            />
            <icons
              v-else-if="status === 'stoppingTraining'"
              key="stop"
              class="icon"
              size="small"
              color="default"
              iconName="close"
            />
            <icons
              v-else-if="status === 'training'"
              key="train"
              class="icon"
              size="small"
              color="emphasis"
              iconName="check"
            />
          </transition>
          <texts
            class="text-pre-line"
            :text="
              $t('training.popup.progress.environment', {
                type: displayTypeText
              })
            "
            :color="status === 'stoppingTraining' ? 'default' : 'emphasis'"
          />
        </div>
      </div>
      <template v-if="!hideTrainStatus">
        <transition name="toggle-item" mode="out-in">
          <div
            v-if="
              status !== 'stoppingTraining' ||
                (optimizationNumIter > 0 && status === 'stoppingTraining')
            "
            key="train"
            class="item"
            :class="{ 'item-disabled': status === 'preparingEnvironment' }"
          >
            <div class="item-inner">
              <transition name="toggle-icon" mode="out-in">
                <loading-icon
                  v-if="progress !== maxProgress"
                  class="icon"
                  size="small"
                  color="emphasis"
                />
                <icons
                  v-else
                  class="icon"
                  size="small"
                  color="emphasis"
                  iconName="check"
                />
              </transition>
              <div class="item-inner-column">
                <texts
                  class="item-inner-column-shrink"
                  :text="
                    trainingColumnStatus
                      ? trainingColumnStatus.columnName
                      : $t('training.popup.progress.training')
                  "
                  color="emphasis"
                />
                <texts
                  v-if="trainingColumnStatus"
                  class="item-inner-column-text"
                  :text="$t('training.popup.progress.trainingColumn')"
                  color="emphasis"
                />
              </div>
              <texts
                class="item-inner-progress"
                :text="((progress / maxProgress) * 100).toFixed() + '%'"
                color="emphasis"
              />
              <texts
                v-if="trainingColumnStatus"
                :text="
                  $t('training.result.multiResult.columnProgress', {
                    progress: trainingColumnStatus.count,
                    progressMax: trainingColumnStatus.max
                  })
                "
                color="emphasis"
              />
            </div>
            <div class="item-inner">
              <texts :text="$t('training.popup.progress.trainingTime')" />
              <texts :text="formatTime(timeCount)" />
            </div>
          </div>
          <div v-else-if="optimizationNumIter === 0" key="stop" class="item">
            <div class="item-inner">
              <loading-icon class="icon" size="small" color="emphasis" />
              <texts
                :text="
                  $t('training.popup.progress.stop', { type: displayTypeText })
                "
                color="emphasis"
              />
            </div>
          </div>
        </transition>
      </template>
      <template v-if="optimizationNumIter > 0">
        <transition name="toggle-item" mode="out-in">
          <div
            v-if="status !== 'stoppingTraining'"
            key="optimization"
            class="item"
            :class="{ 'item-disabled': status === 'preparingEnvironment' }"
          >
            <div class="item-inner">
              <transition name="toggle-icon" mode="out-in">
                <loading-icon
                  v-if="optimizationProgress !== optimizationNumIter"
                  class="icon"
                  size="small"
                  color="emphasis"
                />
                <icons
                  v-else
                  class="icon"
                  size="small"
                  color="emphasis"
                  iconName="check"
                />
              </transition>
              <div class="item-inner-column">
                <texts
                  class="item-inner-column-shrink"
                  :text="$t('training.popup.progress.optimization')"
                  color="emphasis"
                />
              </div>
              <texts
                :text="
                  (
                    (optimizationProgress / optimizationNumIter) *
                    100
                  ).toFixed() + '%'
                "
                color="emphasis"
              />
            </div>
            <div class="item-inner">
              <texts :text="$t('training.popup.progress.optimizationing')" />
              <texts :text="formatTime(optimizationTimeCount)" />
            </div>
          </div>
          <div v-else key="stop" class="item">
            <div class="item-inner">
              <loading-icon class="icon" size="small" color="emphasis" />
              <texts
                :text="
                  $t('training.popup.progress.stop', { type: displayTypeText })
                "
                color="emphasis"
              />
            </div>
          </div>
        </transition>
      </template>
    </div>
    <div
      class="graph"
      :class="{ 'graph-disabled': status !== 'training' || notShowGraph }"
    >
      <graph-bar-count
        class="graph-inner"
        :maxCount="graphMax"
        :count="graphProgress"
      />
    </div>
    <transition-toggle-contents>
      <div v-if="status !== 'stoppingTraining'" class="desc-wrap">
        <div class="desc-item">
          <texts
            class="desc-item-text"
            :text="trainingTips.first"
            size="small"
            color="gray"
          />
        </div>
        <div class="desc-item">
          <texts
            class="desc-item-text"
            :text="trainingTips.second"
            size="small"
            color="gray"
          />
          <div class="desc-item-img">
            <icons iconName="trainedAi" size="40" color="green" />
          </div>
        </div>
      </div>
    </transition-toggle-contents>
  </div>
</template>

<script>
import { formatTime } from '@/lib/misc'

import texts from '@/components/atoms/text'
import icons from '@/components/atoms/icon'
import graphBarCount from '@/components/atoms/graph-bar-count'
import loadingIcon from '@/components/atoms/loading-icon'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

export default {
  components: {
    texts,
    icons,
    graphBarCount,
    loadingIcon,
    transitionToggleContents
  },
  data() {
    return {
      timeCount: 0,
      optimizationTimeCount: 0
    }
  },
  props: {
    /** 学習の進行状況 preparingEnvironment(環境構築) training(学習中) stoppingTraining(学習中止) */
    status: String,
    /** 学習の経過時間(使ってない？？) */
    time: Number,
    /** 学習の進行度 */
    progress: Number,
    /** イテレーターの最大数 */
    maxProgress: Number,
    trainingColumnStatus: Object,
    optimizationNumIter: Number,
    optimizationProgress: Number,
    hideTrainStatus: Boolean,
    disabledStop: Boolean,
    disabledStopTips: String,
    isDifferentOptimizationConditions: Boolean,
    notShowGraph: Boolean
  },
  computed: {
    graphMax() {
      if (this.maxProgress === 0 && this.optimizationNumIter === 0) return 1
      return this.optimizationNumIter > 0
        ? this.optimizationNumIter
        : this.maxProgress
    },
    graphProgress() {
      if (this.progress === 0 && this.optimizationProgress === 0) return 0
      return this.optimizationProgress > 0
        ? this.optimizationProgress
        : this.progress
    },
    checkDisabled() {
      return this.status === 'stoppingTraining' || this.disabledStop
    },
    trainingTips() {
      if (this.isDifferentOptimizationConditions) {
        return {
          first: this.$t('inference.optimization.tips.first'),
          second: this.$t('inference.optimization.tips.second')
        }
      } else {
        return {
          first: this.$t('training.progressStatus.leaveTraining.first'),
          second: this.$t('training.progressStatus.leaveTraining.second')
        }
      }
    },
    displayTypeText() {
      if (this.isDifferentOptimizationConditions) {
        return this.$t('training.message.type.optimization')
      } else {
        return this.$t('training.message.type.training')
      }
    }
  },
  methods: {
    formatTime: formatTime,
    trainingTimer(bool) {
      if (bool) {
        this.timer = window.setInterval(
          function () {
            this.timeCount = this.timeCount + 1
          }.bind(this),
          1000
        )
      } else {
        window.clearTimeout(this.timer)
      }
    },
    optimizationTimer(bool) {
      if (bool) {
        this.timerOptimization = window.setInterval(
          function () {
            this.optimizationTimeCount = this.optimizationTimeCount + 1
          }.bind(this),
          1000
        )
      } else {
        window.clearTimeout(this.timerOptimization)
      }
    }
  },
  watch: {
    status(newVal) {
      if (newVal === 'preparingEnvironment') {
        this.timeCount = 0
        this.optimizationTimeCount = 0
        this.trainingTimer(false)
      } else if (newVal === 'training') {
        this.trainingTimer(true)
      } else if (newVal === 'stoppingTraining' || newVal === 'finishTraining') {
        this.timeCount = 0
        this.optimizationTimeCount = 0
        this.trainingTimer(false)
        this.optimizationTimer(false)
      }
    },
    optimizationNumIter(num) {
      if (num > 0) {
        this.trainingTimer(false)
        this.optimizationTimer(true)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  width: adjustVW(676);
  padding: $space-small $space-small adjustVW(20);
  background: $background;
  border-radius: adjustVW(16);
  box-shadow: $box-shadow-hover;
}
.button-disabled {
  cursor: not-allowed;
}

.text-pre-line {
  white-space: pre-line;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 $space-sub;
  &-button {
    text-decoration: underline;
    cursor: pointer;
    user-select: none;
    transition: opacity $transition-base;
    &:hover {
      opacity: 0.5;
    }
    &-disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
.desc {
  &-wrap {
    padding: $space-sub;
    margin-top: adjustVW(20);
    background: $background-sub;
    border-radius: adjustVW(8);
  }
  &-item {
    display: flex;
    align-items: flex-end;
    grid-column-gap: $space-medium;
    width: 100%;
    margin-bottom: $space-sub;
    &:last-of-type {
      margin-bottom: 0;
    }
    &-text {
      white-space: pre-line;
    }
    &-img {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: adjustVW(64);
      height: adjustVW(64);
      border-radius: 9in;
      box-shadow: $box-shadow-hover;
    }
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 $space-small;
  opacity: 1;
  transform: translateX(0);
  transition: opacity $transition-base, transform $transition-base;
  will-change: opacity, transform;
  &:last-of-type {
    margin: 0;
  }
  &-inner {
    display: flex;
    align-items: center;
    &-column {
      display: flex;
      max-width: adjustVW(328);
      margin-right: $space-small;
      &-shrink {
        overflow: hidden;
        flex-shrink: 1;
      }
      &-text {
        flex-shrink: 0;
      }
    }
    &-progress {
      margin-right: $space-base;
    }
  }
  &-disabled {
    height: 0;
    opacity: 0;
    transform: translateX(-$space-small);
  }
}
.icon {
  margin: 0 $space-base 0 0;
}
.graph {
  margin-top: $space-sub;
  opacity: 1;
  transform: translateX(0);
  transition: opacity $transition-base, transform $transition-base;
  will-change: opacity, transform;
  &-disabled {
    height: 0;
    margin-top: 0;
    opacity: 0;
    transform: translateX(-$space-small);
  }
}

.toggle-item-enter-active,
.toggle-item-leave-active {
  transition: transform $transition-base, opacity $transition-base;
}
.toggle-item-enter,
.toggle-item-leave-to {
  opacity: 0;
  transform: translateX(-$space-small);
  will-change: opacity, transform;
}

.toggle-icon-enter-active,
.toggle-icon-leave-active {
  transition: transform $transition-base, opacity $transition-base;
}
.toggle-icon-enter,
.toggle-icon-leave-to {
  opacity: 0;
  transform: scale(0);
  will-change: opacity, transform;
}
</style>
