<template>
  <div class="samples-wrap">
    <list-item-slot class="samples-list-body" :field="field" :items="items">
      <template v-slot:name="entry">
        <texts :text="entry.item" size="small" />
      </template>
      <template v-slot:count="entry">
        <div class="images-item-count-wrap">
          <texts
            class="images-item-count-text"
            :text="entry.item"
            size="small"
          />
          <texts
            class="images-item-count-percentage"
            :text="Math.ceil((entry.item / nLabels) * 100) + '%'"
            size="min"
            color="gray"
          />
        </div>
        <graph-bar-count
          class="images-item-bar"
          :count="entry.item"
          :maxCount="nLabels"
        />
      </template>
      <template v-slot:sample="entry">
        <div v-if="type === 'images'" class="images-item-inner">
          <button
            v-for="(data, index) in entry.item"
            :key="index"
            class="images-item-data"
            @click="showPopup(data.label, index)"
            @mouseleave="closePopup"
          >
            <image-wrap :url="data.body" />
            <popup-balloon-image
              class="images-item-balloon"
              :showPopup="popupId === index && popupLabel === data.label"
              :posRight="index > 1"
              :posBottom="checkPosImg(items, entry)"
              :popupContents="data"
              :style="{ '--image-position': index % 4 }"
              :class="{
                'images-item-balloon-right': index > 2
              }"
            />
          </button>
        </div>
        <div
          v-if="type === 'texts'"
          class="texts-item-inner"
          @mouseleave="closePopup"
        >
          <button
            v-for="(data, index) in entry.item"
            :key="index"
            class="texts-item-data"
            @click="showPopup(data.label, index)"
          >
            <texts class="texts-data-text" :text="data.body" noTips />
            <popup-balloon-text
              class="texts-item-balloon"
              :showPopup="popupId === index && popupLabel === data.label"
              :popupContents="data"
              :posBottom="checkPosText(items, entry)"
              minSize
            />
          </button>
        </div>
      </template>
    </list-item-slot>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import listItemSlot from '@/components/molecules/list-item-slot.vue'
import GraphBarCount from '@/components/atoms/graph-bar-count.vue'
import ImageWrap from '@/components/atoms/image-wrap.vue'
import popupBalloonImage from '@/components/molecules/popup-balloon-image'
import popupBalloonText from '@/components/molecules/popup-balloon-text'

export default {
  components: {
    texts,
    listItemSlot,
    GraphBarCount,
    ImageWrap,
    popupBalloonImage,
    popupBalloonText
  },
  data() {
    return {
      popupId: null,
      popupLabel: null
    }
  },
  methods: {
    showPopup(label, index) {
      if (this.popupId !== index) {
        this.popupId = null
        this.popupLabel = null
        this.popupId = index
        this.popupLabel = label
      }
    },
    closePopup() {
      this.popupId = null
      this.popupLabel = null
    },
    checkPosImg(items, entry) {
      if (items.length < 4) {
        return false
      } else if (items.length < 6) {
        return items.length - (entry.listIndex + 1) < items.length - 3
      } else {
        return items.length - (entry.listIndex + 1) < 3
      }
    },
    checkPosText(items, entry) {
      if (items.length < 4) {
        return false
      } else if (items.length === 4) {
        return 4 - (entry.listIndex + 1) < 1
      } else {
        return items.length - (entry.listIndex + 1) < 2
      }
    }
  },
  props: {
    field: Array,
    items: Array,
    type: String,
    nLabels: Number
  }
}
</script>

<style lang="scss" scoped>
.samples {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-top {
    flex-shrink: 0;
    padding: 0 0 $space-small;
    margin: 0 0 $space-small;
    border-bottom: $border-sub;
    &-title {
      margin: 0 0 $space-small;
    }
  }
  &-inner {
    display: flex;
    &-item {
      margin: 0 $space-medium 0 0;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  &-list {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 1;
    height: 100%;
    ::v-deep .add-new-service-button {
      margin: 0 0 $space-sub;
    }
    /* stylelint-disable */
    ::v-deep > div {
      height: 100% !important;
      padding: 0 $space-base 0 0;
      overflow-y: scroll;
      @include scrollbar;
    }
    /* stylelint-enable */
    &-body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: 100%;
      ::v-deep .list-item-key-block {
        padding-right: $space-medium;
      }
      ::v-deep .list-item-entry-list {
        overflow-y: scroll;
        flex-shrink: 1;
        height: 100%;
        @include scrollbar;
      }
      ::v-deep .list-item-entry-inner {
        overflow: visible;
      }
    }
  }
}
.images {
  &-item {
    &-count {
      &-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 $space-base;
      }
      &-percentage {
        flex-shrink: 0;
      }
    }
    &-bar {
      z-index: 1;
    }
    &-inner {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
    }
    &-data {
      position: relative;
      width: adjustVW(116);
      height: adjustVW(66);
      margin-right: $space-small;
      &:last-of-type {
        margin: 0;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}

.texts {
  &-item {
    &-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    &-data {
      position: relative;
      display: block;
      width: 100%;
      margin: 0 0 $space-base;
      &-text {
        overflow: hidden;
        width: 100%;
      }
      &:last-of-type {
        margin: 0;
      }
      &:hover {
        opacity: 1;
      }
    }
    &-balloon {
      top: calc(100% + #{adjustVW(20)});
      &.wrap-bottom {
        top: auto;
        bottom: calc(100% + #{adjustVW(20)});
      }
    }
  }
}
</style>
