<template>
  <v-popover
    class="popover-wrap"
    :trigger="trigger"
    :auto-hide="autoHide"
    :placement="placement"
    :offset="offset"
    :open="open"
    :boundariesElement="boundariesElement"
    :popoverArrowClass="arrowClass"
    :popoverInnerClass="innerClass"
    :disabled="disabled"
    @show="$emit('show')"
    @apply-show="$emit('apply-show')"
    @hide="$emit('hide')"
    @apply-hide="$emit('apply-hide')"
    @auto-hide="$emit('auto-hide')"
    @close-group="$emit('close-group')"
  >
    <slot name="main" />
    <template slot="popover">
      <div class="popover-tooltip">
        <slot name="popup" />
      </div>
    </template>
  </v-popover>
</template>

<script>
export default {
  props: {
    /** ポップアップ表示位置変更 */
    placement: {
      type: String,
      default: 'left-start'
    },
    /** ポップアップ表示位置の微調整（px） */
    offset: {
      type: Number,
      default: 0
    },
    /** ポップアップを表示するイベントを指定 */
    trigger: {
      type: [String, Array],
      default: 'hover'
    },
    /** クリックなどでポップアップが閉じるかどうか */
    autoHide: {
      type: Boolean,
      default: true
    },
    /** triggerでmanualを指定した時のポップアップを表示するかどうかのboolean */
    open: {
      type: Boolean,
      default: false
    },
    /** ポップアップを表示するDOMを指定する 基本window */
    boundariesElement: {
      type: String,
      default: 'window'
    },
    /** 矢印に特別なCSSを指定したい場合にClass名を設定 */
    popoverArrowClass: {
      type: String,
      default: ''
    },
    /** ポップアップに特別なCSSを指定したい場合にClass名を設定 */
    popoverInnerClass: {
      type: String,
      default: ''
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    adjustScroll: {
      type: Boolean,
      default: false
    },
    /** ツールチップを閉じて、使用不可の状態にする */
    disabled: {
      type: Boolean,
      default: false
    },
    /** 表示する形式ボーダーの色など調整する [main, green] */
    type: {
      type: String,
      default: 'main'
    }
  },
  computed: {
    arrowClass() {
      let classes = this.popoverArrowClass
      if (this.adjustScroll) {
        classes = classes + ' popover-arrow-adjust-scroll'
      }
      return classes + ' tooltip-arrow popover-arrow'
    },
    innerClass() {
      if (this.noBorder) {
        return (
          this.popoverInnerClass +
          ' tooltip-inner popover-inner popover-no-border'
        )
      } else if (this.type === 'green') {
        return (
          this.popoverInnerClass + ' tooltip-inner popover-inner popover-green'
        )
      } else {
        return this.popoverInnerClass + ' tooltip-inner popover-inner'
      }
    }
  }
}
</script>

<style lang="scss">
.tooltip.popover {
  .popover-inner {
    padding: adjustVW(16);
    border: 2px solid $key-color;
    background: #fff;
    color: $black;
    border-radius: adjustVW(8);
    box-shadow: 0 adjustVW(8) 30px rgba($black, 0.1);
    &.popover-no-border {
      border: none;
      box-shadow: $box-shadow-hover;
    }
    &.popover-green {
      border-color: $green;
    }
  }
  .trigger {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .popover-arrow {
    left: adjustVW(-48);
    width: adjustVW(56);
    height: adjustVW(88);
    border-color: transparent;
  }
  &[x-placement^='left'] {
    .popover-arrow {
      right: adjustVW(-48);
      left: inherit;
      width: adjustVW(56);
      height: adjustVW(88);
      border-color: transparent;
    }
  }
}

.popover-arrow-adjust-scroll {
  .tooltip[x-placement$='start'] & {
    height: adjustVW(24);
  }
  .tooltip[x-placement$='end'] & {
    height: adjustVW(24);
  }
}
</style>
