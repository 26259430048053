<template>
  <transition-group name="tag-toggle" tag="div" class="tag-editable-list-inner">
    <div v-for="(tag, index) in value" :key="tag" class="tag-editable-block">
      <tag-editable
        :name="tag"
        :edit="edit"
        :isWhite="isWhite"
        @tag-close="removeTag(tag, index)"
      />
    </div>
    <div v-if="edit" key="add" class="tag-editable-add-wrap">
      <addTagBox
        class="tag-editable-add-inner"
        isButton
        :placeholder="placeholder"
        @add="addTag"
      >{{
        addButtonText
          ? addButtonText
          : $t('molecules.tagEdittableList.addTagButton')
      }}</addTagBox>
    </div>
  </transition-group>
</template>

<script>
import tagEditable from '@/components/atoms/tag-editable'
import addTagBox from './add-tag.vue'

export default {
  name: 'tagEditableList',
  components: {
    tagEditable,
    addTagBox
  },
  data() {
    return {}
  },
  props: {
    /** v-modelで渡すタグの名前の配列 */
    value: Array,
    /** 編集できるようにする */
    edit: Boolean,
    /** プロジェクト設定など、背景が白い時 */
    isWhite: {
      type: Boolean,
      default: false
    },
    /** タグを追加するボタンに表示するテキスト */
    addButtonText: {
      type: String,
      default: ''
    },
    /** タグを追加するinputに表示するプレースホルダー */
    placeholder: {
      type: String,
      default: ''
    }
  },
  methods: {
    removeTag(tag, index) {
      const tags = this.value.slice()
      tags.splice(index, 1)
      this.$emit('input', tags)
      this.$emit('remove', tag)
    },
    addTag(tagName) {
      const tags = this.value.slice()
      const tag = tagName
      if (tags.indexOf(tag) === -1) {
        tags.push(tag)
        this.$emit('input', tags)
        this.$emit('add', tag)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-editable {
  &-add {
    &-wrap {
      position: relative;
      display: inline-block;
      width: adjustVW(200);
      height: adjustVH(32);
      margin: 0 0 $space-sub 0;
      vertical-align: top;
      transition: all $transition-base; // FLIPの使用のため、allで指定
    }
  }
  &-list {
    &-inner {
      position: relative;
      padding: 0 0 $space-min;
      margin: 0 0 #{-$space-sub};
    }
  }
  &-block {
    display: inline-block;
    height: adjustVH(32);
    margin: 0 $space-sub $space-sub 0;
    vertical-align: top;
    transition: all $transition-base; // FLIPの使用のため、allで指定
  }
}

.tag-toggle-leave-active {
  position: absolute;
}
.tag-toggle-enter,
.tag-toggle-leave-to {
  opacity: 0;
  transform: translateX(-$space-small);
  will-change: opacity, transform;
}
</style>
