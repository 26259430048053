<template>
  <div class="lside-wrap">
    <table-main
      key="dataset"
      :table="dataset.table"
      :tableColumns="[columnName]"
      noPaging
    />
  </div>
</template>

<script>
import tableMain from '@/components/molecules/table-main'

export default {
  components: {
    tableMain
  },
  props: {
    dataset: Object,
    columnName: String
  }
}
</script>

<style lang="scss" scoped>
.lside {
  &-wrap {
    position: relative;
    height: 100%;
    padding: $space-base $space-base $space-small $space-small;
  }
}
</style>
