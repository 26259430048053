<template>
  <card-base
    class="inference-result-image-card"
    @mouseleave.native="isLeaveImage"
  >
    <div class="inference-result-image-target">
      <div class="inference-result-image-target-base">
        <div class="inference-result-image-target-top">
          <texts
            class="inference-result-image-target-title"
            size="small"
            :text="$t('inference.result.card.analysisTarget')"
          />
          <div
            v-tooltip="
              existHeatmap ? $t('inference.result.detail.imageHeatMapTips') : ''
            "
          >
            <checkbox-base
              v-if="displayHeatmapCheckBox"
              :checked="checkedHeatmap"
              :isDisabled="!existHeatmap"
              :text="$t('inference.result.detail.showFeatureImportance')"
              isSmall
              @input="$emit('check-heatmap', $event)"
            />
          </div>
        </div>

        <div class="inference-result-image-target-main">
          <div class="inference-result-image-target-image">
            <image-wrap
              class="inference-result-image-target-image-inner"
              :url="imageUrl"
              @click.native="isHoverImage"
            />
          </div>
          <popup-balloon-image
            class="inference-result-image-target-popup"
            :class="{ 'inference-result-image-target-popup-right': posRight }"
            :showPopup="showPopup"
            :popupContents="popupContents"
            :posBottom="posBottom"
            :posRight="posRight"
            :existHeatmap="existHeatmap"
            :displayHeatmapCheckBox="displayHeatmapCheckBox"
            :disabledCheckBox="!existHeatmap"
            :checkedHeatmap="checkedHeatmap"
            @mouseleave.native="isLeaveImage"
            @check-heatmap="$emit('check-heatmap', $event)"
          />
        </div>

        <texts
          class="inference-result-image-target-name"
          :class="{
            'inference-result-image-target-name-info': labelInferenceResult
          }"
          size="small"
          :text="imageName"
        />
      </div>
      <div v-if="labelInferenceResult">
        <texts
          size="min"
          color="gray"
          :text="$t('inference.result.classification.actualLabel')"
        />
        <texts size="small" :text="label" />
      </div>
    </div>

    <div
      class="inference-result-image-data"
      :class="{
        'inference-result-image-data-classification': isClassification
      }"
    >
      <slot />
    </div>
  </card-base>
</template>

<script>
import cardBase from '@/components/atoms/card-base.vue'
import imageWrap from '@/components/atoms/image-wrap.vue'
import texts from '@/components/atoms/text.vue'
import popupBalloonImage from '@/components/molecules/popup-balloon-image.vue'
import checkboxBase from '@/components/atoms/checkbox-base'

export default {
  components: {
    cardBase,
    imageWrap,
    popupBalloonImage,
    texts,
    checkboxBase
  },
  data() {
    return {
      maxCount: 1.0,
      showPopup: false,
      imageWidth: 0,
      imageHeight: 0,
      element: null
    }
  },
  props: {
    modelInfo: Object,
    imageUrl: String,
    imageName: String,
    label: String,
    labelInferenceResult: String,
    reliabilities: Object,
    posBottom: {
      type: Boolean,
      default: false
    },
    posRight: {
      type: Boolean,
      default: false
    },
    isClassification: {
      type: Boolean,
      default: false
    },
    existHeatmap: {
      type: Boolean,
      default: false
    },
    checkedHeatmap: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    popupContents: function () {
      return {
        body: this.imageUrl,
        name: this.imageName,
        width: this.imageWidth,
        height: this.imageHeight
      }
    },
    displayHeatmapCheckBox() {
      const isFinetuningDeep = this.modelInfo?.type === 'FINETUNING_DEEP'
      return this.existHeatmap || isFinetuningDeep
    }
  },
  methods: {
    isHoverImage() {
      if (!this.showPopup) {
        this.showPopup = true
        this.$emit('show-popup')
      }
    },
    isLeaveImage() {
      this.showPopup = false
    },
    closePopup() {
      this.showPopup = null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.element = new Image()
      this.element.onload = function () {
        this.imageWidth = this.element.naturalWidth
        this.imageHeight = this.element.naturalHeight
      }.bind(this)
      this.element.src = 'data:image/png;base64,' + this.imageUrl
    })
  }
}
</script>

<style lang="scss" scoped>
.inference-result-image-card {
  display: grid;
  grid-template-columns: adjustVW(212) calc(
      100% - #{adjustVW(212) + $space-small}
    );
  grid-template-rows: 100%;
  grid-column-gap: $space-small;
}

.inference-result-image-main {
  width: adjustVW(212);
  height: adjustVW(119);
  margin-bottom: $space-base;

  &-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.inference-result-image-target {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: adjustVW(212);

  &-base {
    display: flex;
    flex-direction: column;
  }

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: $space-sub;
    margin-bottom: $space-base;
  }

  &-main {
    position: relative;
  }

  &-image {
    width: adjustVW(212);
    height: adjustVW(119);
    margin-bottom: $space-base;
    cursor: pointer;
    &-inner {
      object-fit: contain;
    }
  }

  &-name {
    width: 100%;
    &-info {
      margin-bottom: $space-small;
    }
  }

  &-popup {
    left: 0;
    &-right {
      right: 0;
      left: inherit;
    }
  }
}

.inference-result-image-data {
  width: 100%;
  height: 100%;
  &-classification {
    max-height: adjustVH(272);
  }
}
</style>
