<template>
  <preprocessing-list
    :accountInfo="accountInfo"
    :sidebar="sidebar"
    :headerTabs="headerTabs"
    :popup="popup"
    :checked="checked"
    :dataset="dataset"
    :preprocessings="preprocessings"
    :loading="loading"
    :notFoundProject="notFoundProject"
    :disableClick="disableClick"
    @delete-menu-click="
      setSelectedList($event), showPopup('deletePreprocessing')
    "
    @dataset-menu-click="selectDataset($event)"
    @project-menu-click="projectMenuClick"
    @close-modal="closePopup($event)"
    @cancel-delete="cancelDelete()"
    @add-to-project="applyProjectAdd"
    @delete-preprocessing="deletePreprocessing()"
    @set-checked="setChecked($event)"
    @apply-dataset="applyDataset($event)"
    @click-dataset="clickDataset($event)"
  />
</template>

<script>
import preprocessingList from '@/components/templates/preprocessing-list.vue'
import { mapGetters, mapActions } from 'vuex'
import setMountedTimer from '@/mixin/set-mounted-timer'

export default {
  components: {
    preprocessingList
  },
  mixins: [setMountedTimer],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.$waitConnected()
      if (vm.projectId !== null) {
        await vm.loadProjectDataset()
      } else {
        throw new Error('deprecated')
      }
      await vm.loadProjectEDARecipes()
    })
  },
  data() {
    return {
      sidebar: {
        // サイドバーに表示する情報
        project: {
          name: '退職予測',
          description:
            'このプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入ります'
        },
        activeLink: 'preprocessing'
      },
      headerTabs: {
        // ヘッダーのタブ
        tabs: [],
        tabSelect: 0
      },
      projectItems: null,
      projectDataset: {},
      projectItemsLoading: true,
      projectDatasetLoading: false,
      popup: {
        // ポップアップの情報
        items: [],
        showPopup: [], // 現在表示しているポップアップ
        checkDataset: {
          name: '',
          description: ''
        },
        intoProject: {
          targets: []
        }
      },
      checked: [],
      submitDeleting: false,
      submitOther: false
    }
  },
  computed: {
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('preprocessings', ['EDARecipes', 'loadingEDARecipes', 'loadingEDARecipeDetail', 'loadingEDARecipeDelete']),
    ...mapGetters('datasets', ['datasetList', 'datasetLoading']),
    ...mapGetters('preprocessings', {
      allPreprocessings: 'EDARecipes',
      allPreprocessingsLoading: 'loadingEDARecipes'
    }),
    ...mapGetters('project', ['projectList']),
    projectId() {
      if (this.$route.params.projectId) {
        return parseInt(this.$route.params.projectId)
      }
      return null
    },
    notFoundProject() {
      if (this.projectId === null) {
        return false
      } else {
        return !(this.projectId in this.projectList)
      }
    },
    preprocessings() {
      if (this.projectId) {
        return this.projectItems
      } else {
        return this.allPreprocessings
      }
    },
    datasetObject() {
      if (this.projectId) {
        return this.projectDataset
      } else {
        return this.datasetList
      }
    },
    preprocessingsLoading() {
      if (this.projectId) {
        return this.projectItemsLoading
      } else {
        return this.allPreprocessingsLoading
      }
    },
    dataset() {
      const accountId = this.accountInfo.accountId
      const result = []
      Object.entries(this.datasetObject).forEach(function (item) {
        const data = item[1]
        // structured、自分が所有者のデータだけ対象
        if (data.type === 'structured' && data.accountId === accountId) {
          result.push(data)
        }
      })
      // 最終更新日の降順
      result.sort((x, y) => (x.updateTime > y.updateTime ? -1 : 1))
      return result
    },
    loading() {
      return (
        this.preprocessingsLoading ||
        this.loadingEDARecipeDelete ||
        this.datasetLoading ||
        this.projectDatasetLoading
      )
    },
    disableClick() {
      return this.submitDeleting || this.submitOther
    }
  },
  methods: {
    ...mapActions('preprocessings', ['deleteEDARecipeMulti', 'setLoadingStatus']),
    ...mapActions('datasets', ['loadDatasetList', 'loadDatasetDetail']),
    ...mapActions('project', ['projectAddEDARecipe']),
    async loadProjectEDARecipes() {
      if (this.projectId) {
        this.projectItemsLoading = true
        try {
          const response = await this.$sendMessageAndReceive({
            action: 'listEDARecipes',
            projectId: this.projectId
          })
          if (response.status !== 'error') {
            const obj = {}
            response.result.forEach((item) => {
              const fullId = item.id + '-' + item.accountId
              item.fullId = fullId
              item.link = {
                name: 'preprocessingDetail',
                params: item.id
              }
              obj[item.id] = item
            })
            this.projectItems = obj
          } else {
            throw response
          }
        } catch (e) {
          console.log(e)
        } finally {
          this.projectItemsLoading = false
        }
      }
    },
    async loadProjectDataset() {
      this.projectDatasetLoading = true
      try {
        const response = await this.$sendMessageAndReceive({
          action: 'getDataList',
          projectId: this.projectId
        })
        if (response.status !== 'error') {
          const obj = {}
          response.list.forEach((item) => {
            const fullId = item.id + '-' + item.accountId
            item.fullId = fullId
            obj[item.id] = item
          })
          this.projectDataset = obj
        } else {
          throw response
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.projectDatasetLoading = false
      }
    },
    showPopup(e) {
      // ポップアップを表示
      this.popup.showPopup.push(e)
    },
    closePopup(e) {
      // ポップアップを閉じる
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
    },
    selectDataset(e) {
      this.setSelectedList(e)
      this.showPopup('selectDataset')
    },
    projectMenuClick({ target, newProject = false }) {
      this.popup.intoProject = {
        targets: target.map((id) => this.preprocessings[id]),
        newProject
      }
      this.showPopup('intoProject')
    },
    async applyProjectAdd(target) {
      this.submitOther = true
      if (target.project.new) {
        await this.projectAddEDARecipe({
          targets: target.targets,
          project: target.project
        })
        this.$router.push({
          name: 'projectList'
        })
      } else {
        await this.projectAddEDARecipe({
          targets: target.targets,
          projectId: target.project.id
        })
      }
      await this.loadProjectEDARecipes()
      this.submitOther = false
      this.closePopup('intoProject')
    },
    setSelectedList(e) {
      // 削除する前処理の一覧をセット
      this.popup.items = []
      // eslint-disable-next-line
      for (const [index, deleteTarget] of e.target.entries()) {
        if (this.preprocessings[deleteTarget] == null) continue
        this.popup.items.push(this.preprocessings[deleteTarget])
        if (
          this.preprocessings[deleteTarget].service &&
          this.preprocessings[deleteTarget].service.length > 0
        ) {
          this.$set(
            this.preprocessings[deleteTarget],
            'caution',
            this.$t('trainedAiDetails.popup.serviceDeleteCheck.caution')
          )
        }
      }
    },
    cancelDelete() {
      // 削除する前処理の一覧をセット
      this.closePopup('deletePreprocessing')
    },
    applyDataset(e) {
      const preprocessingId = this.popup.items[0].id
      const datasetId = e.id
      let name = ''
      let params = ''
      if (this.projectId) {
        name = 'preprocessingProjectApply'
        params = {
          projectId: this.projectId,
          preprocessingId: preprocessingId,
          datasetId: datasetId
        }
      } else {
        name = 'preprocessingApply'
        params = {
          preprocessingId: preprocessingId,
          datasetId: datasetId
        }
      }
      this.$router.push({
        name: name,
        params: params
      })
    },
    clickDataset(e) {
      this.popup.checkDataset = this.dataset[e.index]
      this.showPopup('checkColumn')
    },
    async deletePreprocessing() {
      // 削除処理の実行
      this.submitDeleting = true
      const deleteList = this.popup.items.map((item) => ({
        id: item.id,
        accountId: item.accountId
      }))
      try {
        await this.deleteEDARecipeMulti(deleteList)
        if (this.projectId) {
          await this.loadProjectEDARecipes()
        }
      } finally {
        this.submitDeleting = false
      }
      // 選択アイテムの初期化
      this.popup.items = []
      this.checked = []

      this.closePopup('deletePreprocessing')
    },
    setChecked(e) {
      // テーブルの選択項目をセット
      this.checked = e
    }
  }
}
</script>
