<template>
  <div class="template-wrap">
    <div class="template-main">
      <div class="template-item">
        <text-with-title
          :title="$t('serviceList.popup.addNewService.datasetSelectedTitle')"
          :text="
            datasetDetail ? datasetDetail.detail.name : $t('common.notSelected')
          "
        />
      </div>

      <texts
        class="template-item-title"
        :text="$t('service.learn.inputColumns')"
        size="small"
        color="gray"
      />
      <div class="template-item template-check-wrap">
        <list-item-slot
          v-if="columns && columns.length > 0"
          class="template-check-body"
          :field="field"
          :items="columns"
        >
          <template v-slot:columnField>
            <label class="template-check-name">
              <checkbox-base
                class="template-check-name-check"
                :checked="isAllCheckOn"
                :isShowMinusIcon="isNotAllChecked"
                @input="allCheck($event)"
              />
              <texts
                class="template-check-name-text"
                :text="$t('training.config.detail.field.columnName')"
              />
            </label>
          </template>
          <template v-slot:column="entry">
            <label class="template-check-name">
              <template>
                <checkbox-base
                  class="template-check-name-check"
                  :checked="
                    predictionColumns.indexOf(entry.entry.name) !== -1 ||
                      inputColumns.indexOf(entry.entry.name) !== -1
                  "
                  :isDisabled="
                    predictionColumns.indexOf(entry.entry.name) !== -1
                  "
                  @input="onCheck($event, entry.entry.name)"
                />
                <texts
                  class="template-check-name-text"
                  :text="entry.entry.name"
                />
              </template>
            </label>
          </template>
        </list-item-slot>
        <input-box
          v-model="inputText"
          v-tooltip="{
            content: $t('serviceList.popup.addNewService.columnInputError'),
            trigger: ['manual'],
            show: isInputBoxError,
            delay: { show: 300, hide: 300 }
          }"
          class="template-text-area"
          :class="{
            'template-text-area-error': isInputBoxError,
            'template-text-area-no-dataset': columns.length === 0
          }"
          :placeholder="
            $t('serviceList.popup.addNewService.columnInputPlaceholder')
          "
          isTextArea
          isGray
          @input="updateTextArea($event)"
        />
      </div>
      <div class="template-button">
        <button-large
          class="template-button-item"
          :text="$t('common.checkSetting')"
          :isDisabled="inputColumns.length === 0"
          @click="$emit('select-column', { inputColumns })"
        />
      </div>
    </div>
    <dataset-setting-table
      class="template-detail"
      :table="checkDatasetDetail.table"
      :columns="checkDatasetDetail.tableColumns"
      :type="checkDatasetDetail.type"
      :nLabels="nLabels"
    />
  </div>
</template>

<script>
import buttonLarge from '@/components/atoms/button-large'
import textWithTitle from '@/components/molecules/text-with-title'
import listItemSlot from '@/components/molecules/list-item-slot'
import checkboxBase from '@/components/atoms/checkbox-base'
import inputBox from '@/components/molecules/input-box'
import datasetSettingTable from '@/components/organisms/dataset-setting/dataset-setting-table'

export default {
  components: {
    buttonLarge,
    textWithTitle,
    listItemSlot,
    checkboxBase,
    inputBox,
    datasetSettingTable
  },
  data() {
    return {
      inputText: '',
      inputColumns: [],
      nLabels: 0
    }
  },
  props: {
    projectId: Number,
    datasetColumns: Array,
    datasetDetail: Object,
    predictionColumns: Array
  },
  methods: {
    updateValues(e) {
      const checkIndex = this.inputColumns.indexOf(e)
      if (checkIndex !== -1) {
        this.inputColumns.splice(checkIndex, 1)
        this.inputText = this.inputColumns.join('\n')
      }
    },
    updateTextArea(e) {
      let text = e.split(/[\r|\n|\r\n|,]/)
      text = text.filter((item) => item)
      text = new Set(text)
      this.inputColumns.splice(0, this.inputColumns.length)
      this.inputColumns.push(...text)
    },
    onCheck(e, name) {
      if (e) {
        this.inputColumns.push(name)
        this.inputText = this.inputColumns.join('\n')
      } else {
        const target = this.inputColumns.filter((item) => item !== name)
        this.inputColumns = target
        this.inputText = target.join('\n')
      }
    },
    allCheck(e) {
      if (e) {
        let target = this.datasetColumns

        target = target.filter((item) => {
          return this.predictionColumns.indexOf(item) === -1
        })

        const others = this.inputColumns.filter((item) => {
          return this.datasetColumns.indexOf(item) === -1
        })
        this.inputColumns.splice(0, this.inputColumns.length)
        this.inputColumns.push(...target, ...others)
        this.inputText = this.inputColumns.join('\n')
      } else {
        this.inputColumns = this.inputColumns.filter((item) => {
          return this.datasetColumns.indexOf(item) === -1
        })
        this.inputText = this.inputColumns.join('\n')
      }
    }
  },
  computed: {
    disabledSubmit() {
      return !(this.inputColumns.length > 0)
    },
    checkDatasetDetail() {
      if (this.datasetDetail == null) return {}
      if (this.datasetDetail?.data_type === 'list') {
        return {
          tableColumns: Object.keys(this.datasetDetail.detail.dtypes),
          table: this.datasetDetail.list,
          type: this.datasetDetail.data_type
        }
      } else if (
        this.datasetDetail?.data_type === 'images' ||
        this.datasetDetail?.data_type === 'texts'
      ) {
        const items = []
        let nLabelsCount = 0
        for (const [key, value] of Object.entries(this.datasetDetail.labels)) {
          items.push({
            name: key,
            count: value.count,
            sample: value.datas
          })
          nLabelsCount = nLabelsCount + value.count
        }
        this.nLabelsCount(nLabelsCount)
        return {
          tableColumns: this.unstructuredField[this.datasetDetail.data_type],
          table: items,
          type: this.datasetDetail.data_type
        }
      }
      return {}
    },
    columns() {
      if (!this.datasetColumns) return []
      const res = this.datasetColumns.map((item) => {
        return {
          name: item,
          value: item
        }
      })
      return res
    },
    field() {
      return [
        {
          key: 'column',
          width: 320
        }
      ]
    },
    isNotAllChecked() {
      const items = this.inputColumns.filter((item) => {
        return this.datasetColumns.indexOf(item) !== -1
      })
      return (
        this.columns.length - this.predictionColumns.length !== items.length
      )
    },
    isAllCheckOn() {
      const items = this.inputColumns.filter((item) => {
        return this.datasetColumns.indexOf(item) !== -1
      })
      return items.length > 0
    },
    isInputBoxError() {
      const items = this.inputColumns.filter((item) => {
        return this.predictionColumns.indexOf(item) !== -1
      })
      return items.length > 0
    }
  }
}
</script>
<style lang="scss" scoped>
.template {
  &-wrap {
    display: grid;
    grid-template-columns: minmax(adjustVW(594), 1fr) adjustVW(966);
    grid-column-gap: $space-medium;
    width: 100%;
    height: 100%;
  }
  &-main {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  &-item {
    flex-shrink: 0;
    margin-bottom: $space-medium;
    &:nth-of-type(2) {
      margin-bottom: $space-large;
    }
    &-title {
      flex-shrink: 0;
      margin-bottom: $space-base;
    }
  }
  &-detail {
    overflow: hidden;
  }
  &-check {
    &-wrap {
      overflow: hidden;
      display: flex;
      flex-shrink: 1;
      flex-grow: 1;
      grid-column-gap: $space-medium;
      margin-bottom: $space-large;
    }
    &-body {
      overflow-y: scroll;
      @include scrollbar;
    }
    &-name {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      &-check {
        flex-shrink: 0;
        width: adjustVW(24);
        padding: 0;
        margin: 0 $space-small 0 0;
      }
      &-text {
        overflow: hidden;
        flex-shrink: 1;
        flex-grow: 1;
        width: 100%;
      }
    }
  }
  &-text-area {
    height: 100%;
    &::v-deep > div {
      height: 100%;
    }
    &-no-dataset {
      width: 100%;
    }
  }
}
</style>
