<template>
  <div class="inference-timeseries-result">
    <div class="inference-timeseries-result-term">
      <texts
        class="inference-timeseries-result-term-item1"
        :text="
          defaultType !== 'TIME_TRANSFORMER'
            ? $t('inference.result.timeseries.term')
            : $t('inference.result.timeseries.termTransFormer')
        "
      />
      <timeseries-first-last-date
        v-if="timePrediction"
        :forecast="forecast"
        :observations="timePrediction.observations"
        :setDateTime="defaultTimseSet"
        :timeUnit="defaultTimeUnit"
        :type="defaultType"
      />
    </div>

    <div class="inference-timeseries-result-wrapper">
      <div class="inference-timeseries-result-main">
        <card-base class="inference-timeseries-result-main-card">
          <div class="inference-timeseries-result-title">
            <texts
              size="large"
              :text="$t('inference.result.timeseries.title')"
            />
            <texts
              v-if="
                checkTimeseriesColumn &&
                  timeseriesSettings &&
                  timeseriesSettings.seasonality_mode
              "
              :text="
                $t(
                  'training.result.timeseries.' +
                    timeseriesSettings.seasonality_mode
                )
              "
              color="gray"
              size="small"
            />
          </div>
          <div
            v-if="inferencedColumns && inferencedColumns.length > 1"
            class="inference-timeseries-result-multi-select"
          >
            <select-column-box
              :finishColumns="finishColumnsFix"
              :value="selectedItem"
              @input="$emit('change-column', $event)"
            />
          </div>
          <transition-toggle-contents>
            <timeseries-graph
              v-if="
                timePrediction &&
                  !loadColumnResult &&
                  checkTimeseriesColumn &&
                  !loadTimeseriesTrainedAiData
              "
              class="inference-timeseries-result-graph"
              :forecast="timePrediction.forecast"
              :observations="timePrediction.observations"
              :selectedItem="selectedItem"
            />
            <div
              v-else-if="loadColumnResult || loadTimeseriesTrainedAiData"
              class="inference-timeseries-result-multi-loading"
            >
              <loading />
            </div>
            <div v-else class="inference-timeseries-result-multi-no-item">
              <texts
                :text="$t('inference.result.timeseries.notFound')"
                color="gray"
              />
            </div>
          </transition-toggle-contents>
        </card-base>
      </div>

      <div class="inference-timeseries-result-used-ai">
        <card-base class="inference-timeseries-result-used-ai-card">
          <model-info
            :title="$t('inference.result.card.usedAi')"
            :modelInfo="selectedTrainedAi"
          />
        </card-base>
      </div>
    </div>
  </div>
</template>

<script>
import cardBase from '@/components/atoms/card-base.vue'
import modelInfo from '@/components/organisms/model-info.vue'
import texts from '@/components/atoms/text.vue'
import timeseriesGraph from '@/components/organisms/inference/result/timeseries/timeseries-graph.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import Loading from '@/components/atoms/loading.vue'
import timeseriesFirstLastDate from '@/components/organisms/inference/timeseries-first-last-date'
import selectColumnBox from '@/components/organisms/trained-ai-common/select-column-box'

export default {
  components: {
    cardBase,
    modelInfo,
    texts,
    transitionToggleContents,
    timeseriesGraph,
    Loading,
    timeseriesFirstLastDate,
    selectColumnBox
  },
  data() {
    return {
      defaultTimeUnit: null,
      defaultTimseSet: null,
      defaultType: null
    }
  },
  props: {
    forecast: Array,
    selectedTrainedAi: Object,
    selectedItems: Object,
    loadColumnResult: Boolean,
    loadTimeseriesTrainedAiData: Boolean,
    finishColumnsFix: Array,
    selectedItem: String,
    inferencedColumns: Array,
    timeseriesSettings: Object
  },
  computed: {
    timePrediction() {
      if (
        this.timeseriesSettings &&
        this.timeseriesSettings?.observations &&
        this.forecast &&
        this.forecast.length > 0
      ) {
        return {
          forecast: this.forecast,
          observations: this.timeseriesSettings.observations,
          changepoints: this.timeseriesSettings.changepoints,
          acf: this.timeseriesSettings.observations,
          pacf: this.timeseriesSettings.observations
        }
      } else {
        return null
      }
    },
    timeseriesColumns() {
      if (this.inferencedColumns.length > 1) {
        return this.finishColumnsFix.map((item) => {
          return {
            name: item,
            value: item
          }
        })
      } else {
        return []
      }
    },
    // 存在しない列を選択していないかをチェックしている
    checkTimeseriesColumn() {
      if (!this.inferencedColumns) return false
      if (this.inferencedColumns.length <= 1) return true
      const target = this.selectedItem
      return this.inferencedColumns.indexOf(target) !== -1
    }
  },
  mounted() {
    this.defaultTimeUnit =
      this.selectedItems.inferenceSettings?.timeUnit ?? null
    this.defaultTimseSet =
      Number(this.selectedItems.inferenceSettings?.time) ?? null
    this.defaultType = this.selectedTrainedAi?.inferenceType
  },
  destroyed() {
    this.defaultTimeUnit = null
    this.defaultTimseSet = null
    this.defaultType = null
  }
}
</script>

<style lang="scss" scoped>
.inference-timeseries-result-wrapper {
  overflow: hidden;
  display: grid;
  grid-template-columns: adjustVW(1248) minmax(0, #{adjustVW(384)});
  grid-template-rows: adjustVH(456) 1fr;
  grid-column-gap: $space-medium;
  height: 100%;
  padding: 0 $space-medium $space-medium;
}

.inference-timeseries-result {
  display: flex;
  flex-direction: column;
  height: 100%;
  &-main {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 100%;
  }

  &-used-ai {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    height: fit-content;
  }

  &-main-card {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-used-ai-card {
    width: 100%;
    height: 100%;
  }

  &-graph {
    overflow: hidden;
    flex-shrink: 1;
    width: 100%;
    height: 100%;
  }

  &-title {
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    margin-bottom: $space-sub;
  }
}

.inference-timeseries-result-term {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 0 $space-medium;
  margin-bottom: $space-small;
  &-item1 {
    margin-right: $space-base;
  }
}

.inference-timeseries-result-multi {
  &-select {
    width: adjustVW(320);
    height: adjustVW(56);
    margin-bottom: $space-sub;
  }
  &-loading {
    height: 100%;
  }
  &-no-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
</style>
