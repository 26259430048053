<template>
  <div>
    <button
      v-bind:disabled="isDisabled"
      class="add-new-service-button"
      :class="{
        'add-new-service-button-disabled': isDisabled
      }"
      @click="isDisabled ? '' : itemToggle()"
    >
      <text-with-icon
        v-if="!isShow"
        key="plus"
        :text="openText"
        iconName="plus"
        size="small"
      />
      <text-with-icon
        v-else
        key="minus"
        :text="closeText"
        iconName="minus"
        size="small"
      />
    </button>
    <transition-toggle-slot-contents>
      <div v-if="isShow">
        <div>
          <slot />
        </div>
      </div>
    </transition-toggle-slot-contents>
  </div>
</template>

<script>
import textWithIcon from './text-with-icon'
import transitionToggleSlotContents from './transition-toggle-slot-contents.vue'

export default {
  components: {
    textWithIcon,
    transitionToggleSlotContents
  },
  data() {
    return {
      isShow: this.value
    }
  },
  props: {
    /** 閉じているときの表示テキスト */
    openText: String,
    /** 開いているときの表示テキスト */
    closeText: String,
    /** 開閉の状態を上の階層から渡すときの値 */
    value: { type: Boolean, default: false },
    /** 使用不可にする */
    isDisabled: { type: Boolean, default: false }
  },
  methods: {
    itemToggle() {
      this.isShow = !this.isShow
      if (this.isShow) {
        this.$emit('open')
      } else {
        this.$emit('close')
      }
    }
  },
  watch: {
    value(nv) {
      this.isShow = nv
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-service-button {
  margin: 0 0 $space-medium;
  &-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
</style>
