<template>
  <div>
    <div v-if="show" class="body">
      <vue-slider
        :value="internalValue"
        :min="min"
        :max="max"
        :dotSize="dotSize"
        :height="height"
        contained
        :enableCross="false"
        :interval="interval"
        tooltip="none"
        :lazy="lazy"
        @change="changeValue"
        @drag-start="dragStart"
        @drag-end="changeValueDragEnd"
      />
    </div>
    <div class="input-list">
      <input-box
        v-model.number="internalMin"
        v-tooltip="minTips"
        class="input-item"
        :min="min"
        :max="internalValue[1]"
        :step="interval"
        number
        isGray
        @blur="minBlur"
      />
      <texts text="ー" size="small" color="gray" />
      <input-box
        v-model.number="internalMax"
        v-tooltip="maxTips"
        class="input-item"
        :min="internalValue[0]"
        :max="max"
        :step="interval"
        number
        isGray
        @blur="maxBlur"
      />
    </div>
  </div>
</template>

<script>
import inputBox from '@/components/molecules/input-box'
import VueSlider from 'vue-slider-component'

export default {
  components: {
    inputBox,
    VueSlider
  },
  data() {
    return {
      dotSize: (24 * 1920) / 1920,
      height: (2 * 1920) / 1920,
      internalValue: [0, 100],
      internalMin: 0,
      internalMax: 100,
      show: false,
      isMinLargerMax: null,
      isMinSmallerMin: null,
      isMaxSmallerMin: null,
      isMaxLargerMax: null,
      isInputting: false
    }
  },
  props: {
    /** v-modelで渡す値 [設定した最小値, 設定した最大値] */
    value: Array,
    /** とりうる最小値 */
    min: Number,
    /** とりうる最大値 */
    max: Number,
    /** eventの発生を間引き */
    lazy: Boolean
  },
  computed: {
    // 小数点がある場合その桁数をstepにする
    interval() {
      const minDecimal = String(this.min).split('.')
      const maxDecimal = String(this.max).split('.')
      const minLen = minDecimal[1] ? minDecimal[1].length : 0
      const maxLen = maxDecimal[1] ? maxDecimal[1].length : 0

      const len = Math.max(minLen, maxLen)
      return 1 / 10 ** len
    },
    minTips() {
      return {
        content: this.isMinLargerMax ?? this.isMinSmallerMin,
        tigger: ['manual'],
        show: this.isMinLargerMax || this.isMinSmallerMin
      }
    },
    maxTips() {
      return {
        content: this.isMaxSmallerMin ?? this.isMaxLargerMax,
        tigger: ['manual'],
        show: this.isMaxSmallerMin || this.isMaxLargerMax
      }
    }
  },
  methods: {
    // VueSliderにはpxでサイズを渡す必要があるため、リサイズごとに変更
    updateWidth() {
      this.dotSize = (24 * window.innerWidth) / 1920
      this.height = (2 * window.innerWidth) / 1920
    },
    changeValue(val) {
      this.internalMin = val[0]
      this.internalMax = val[1]
      if (!this.isInputting) {
        this.$emit('change-all', val)
      }
    },
    changeValueDragEnd() {
      this.isInputting = false
      this.$emit('change-all', this.internalValue)
    },
    minBlur() {
      if (this.isMinLargerMax) {
        this.internalMin = this.internalMax - this.interval
      } else if (this.isMinSmallerMin) {
        this.internalMin = this.min
      }
      this.$emit('change-all', this.internalValue)
    },
    maxBlur() {
      if (this.isMaxSmallerMin) {
        this.internalMax = this.internalMin + this.interval
      } else if (this.isMaxLargerMax) {
        this.internalMax = this.max
      }
      this.$emit('change-all', this.internalValue)
    },
    dragStart() {
      this.isInputting = true
    }
  },
  mounted() {
    this.updateWidth()
    window.addEventListener('resize', this.updateWidth)
    this.internalValue = this.value
    this.internalMin = this.value[0]
    this.internalMax = this.value[1]
    this.$nextTick(() => {
      this.show = true
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWidth)
  },
  watch: {
    /**
     * internalMin または internalMaxに値が入るとinternalValueに変更が入る
     * その変更を検知してinternalValueをemitするようにしている
     * これはVueSliderによる最大値最小値の書き換えを防ぐため
     */
    internalValue(newVal) {
      this.$emit('input', newVal)
    },
    internalMin(newVal) {
      this.isMinLargerMax = null
      this.isMinSmallerMin = null
      if (newVal > this.internalValue[1]) {
        this.isMinLargerMax = this.$t(
          'trainedAi.optimization.table.adjustError.minLargerMax'
        )
      } else if (this.min > newVal) {
        this.isMinSmallerMin = this.$t(
          'trainedAi.optimization.table.adjustError.minSmallerMin',
          { min: this.min }
        )
      } else {
        this.internalValue = [newVal, this.internalMax]
      }
    },
    internalMax(newVal) {
      this.isMaxSmallerMin = null
      this.isMaxLargerMax = null
      if (this.internalValue[0] > newVal) {
        this.isMaxSmallerMin = this.$t(
          'trainedAi.optimization.table.adjustError.maxSmallerMin'
        )
      } else if (newVal > this.max) {
        this.isMaxLargerMax = this.$t(
          'trainedAi.optimization.table.adjustError.maxLargerMax',
          { max: this.max }
        )
      } else {
        this.internalValue = [this.internalMin, newVal]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  height: adjustVW(24);
  margin-bottom: $space-small;
  &::v-deep {
    /* component style */
    .vue-slider-disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    /* rail style */
    .vue-slider-rail {
      height: adjustVW(2);
      background-color: $border-gray;
      border-radius: 9in;
    }

    /* process style */
    .vue-slider-process {
      background-color: $green;
      border-radius: 9in;
    }

    /* mark style */
    .vue-slider-mark {
      z-index: 4;
    }
    .vue-slider-mark:first-child .vue-slider-mark-step,
    .vue-slider-mark:last-child .vue-slider-mark-step {
      display: none;
    }
    .vue-slider-mark-step {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.16);
      border-radius: 9in;
    }
    .vue-slider-mark-label {
      font-size: 14px;
      white-space: nowrap;
    }

    /* dot style */
    .vue-slider-dot-handle {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      background-color: #fff;
      border-radius: 9in;
      cursor: pointer;
      box-shadow: 0 4px 16px 4px rgba(43, 43, 44, 0),
        0 0 0 adjustVW(1) $border-gray;
      transition: box-shadow $transition-base;
    }
    .vue-slider-dot-handle-focus {
      box-shadow: $box-shadow-main, 0 0 0 adjustVW(1) $border-gray;
    }

    .vue-slider-dot-handle-disabled {
      background-color: $border-gray;
      cursor: not-allowed;
    }

    .vue-slider-dot-tooltip-inner {
      box-sizing: content-box;
      min-width: 20px;
      padding: adjustVW(4) adjustVW(12) adjustVW(3);
      border-color: $black;
      background-color: $black;
      color: #fff;
      font-size: $text-base;
      text-align: center;
      white-space: nowrap;
      border-radius: adjustVW(4);
      @include text-crop;
    }
    .vue-slider-dot-tooltip-inner::after {
      content: '';
      position: absolute;
    }
    .vue-slider-dot-tooltip-inner-top::after {
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 5px;
      border-top-color: inherit;
      transform: translate(-50%, 0);
    }
    .vue-slider-dot-tooltip-inner-bottom::after {
      bottom: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 5px;
      border-bottom-color: inherit;
      transform: translate(-50%, 0);
    }
    .vue-slider-dot-tooltip-inner-left::after {
      top: 50%;
      left: 100%;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 5px;
      border-left-color: inherit;
      transform: translate(0, -50%);
    }
    .vue-slider-dot-tooltip-inner-right::after {
      top: 50%;
      right: 100%;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 5px;
      border-right-color: inherit;
      transform: translate(0, -50%);
    }

    .vue-slider-dot-tooltip-wrapper {
      opacity: 0;
      transition: all 0.3s;
    }
    .vue-slider-dot-tooltip-wrapper-show {
      opacity: 1;
    }
  }
}
.input {
  &-list {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr max-content 1fr;
    grid-column-gap: $space-sub;
  }
}
</style>
