<template>
  <div
    class="body"
    :class="{
      'body-deep': recipeType === 'deep',
      'body-optimization': checkOptimization,
      'body-optimization-train': trainingFlag && checkOptimization
    }"
  >
    <div class="list">
      <card-base class="list-card" :data-tutorial-optimization="0">
        <config-list
          v-model="config"
          :rawPredictionColumnOptions="rawPredictionColumnOptions"
          :selectedTrainingData="selectedTrainingData"
          :selectedTrainingDataSample="selectedTrainingDataSample"
          :selectedTrainingDataType="selectedTrainingDataType"
          :selectedRecipe="selectedRecipe"
          :recipeType="recipeType"
          :trainingProgressStatus="trainingProgressStatus"
          :elapsedTime="elapsedTime"
          :trainingNumIter="trainingNumIter"
          :trainingProgress="trainingProgress"
          :optimizationNumIter="optimizationNumIter"
          :optimizationProgress="optimizationProgress"
          :sortedTrainedAis="sortedTrainedAis"
          :finishColumns="finishColumns"
          :selectedColumnIndex="selectedColumnIndex"
          :trainingColumnStatus="trainingColumnStatus"
          :checkOptimization="checkOptimization"
          :trainingFlag="trainingFlag"
          :predictionColumns="predictionColumns"
          :disableOptimization="disableOptimization"
          :learningPredictionColumns="learningPredictionColumns"
          :timeTransformerV2Filter="timeTransformerV2Filter"
          :timeTransformerV2Setting="timeTransformerV2Setting"
          :fixTimeTransformerV2Setting="fixTimeTransformerV2Setting"
          @training-stop="$emit('training-stop')"
          @show-optimization-tutorial="$emit('show-optimization-tutorial')"
          @show-time-transformer-v2-detail="
            $emit('show-time-transformer-v2-detail', $event)
          "
          @show-time-transformer-before-setting="
            $emit('show-time-transformer-before-setting')
          "
        />
      </card-base>
    </div>
    <div
      v-if="recipeType === 'TIME_TRANSFORMER2'"
      class="graph-item graph-item-time-transformer-v2"
    >
      <card-base key="time" class="graph-card-min">
        <time-transformer-v2-sub
          v-model="config.trainConfig.timeseries"
          :trainingFlag="trainingFlag"
        />
      </card-base>
      <transition-toggle-contents>
        <card-base
          v-if="showTimeTransformerV2DetailSetting"
          key="detail"
          class="graph-card"
        >
          <time-transformer-v2-setting-list
            :settingList="config.trainConfig.columnConditions"
            :predictionColumns="predictionColumns"
            @click-time-transformer-v2-filter="
              $emit('click-time-transformer-v2-filter', $event)
            "
          />
        </card-base>
      </transition-toggle-contents>
    </div>
    <div v-else-if="recipeType !== 'TIME'" class="graph-item">
      <transition name="optimization-block">
        <card-base
          v-show="checkOptimization && !trainingFlag"
          class="graph-card-optimization"
          :data-tutorial-optimization="3"
        >
          <config-optimization-expression
            v-model="config.optimizationConditions.expressions"
            :selectedTrainingData="selectedTrainingData"
            :validExpressions="optimizationInfo.validExpressions"
            :optimizationColumns="
              config.optimizationConditions.optimizationColumns
            "
            :validColumns="rawPredictionColumnOptions"
            @check-expressions="$emit('check-expressions', $event)"
          />
        </card-base>
      </transition>
      <card-base class="graph-card">
        <config-graph
          v-model="config"
          :charts="charts"
          :chartsData="chartsData"
          :selectedTrainingDataType="selectedTrainingDataType"
          :recipeType="recipeType"
          :nTestTrain="nTestTrain"
          :nIters="nIters"
          :TOO_FEW_TH="TOO_FEW_TH"
          :tooFewTestData="tooFewTestData"
          :tooFewTrainData="tooFewTrainData"
          :bestParams="bestParams"
          :trials="trials"
          :trainingProgressStatus="trainingProgressStatus"
          :trainingColumnStatus="trainingColumnStatus"
          :checkOptimization="checkOptimization"
          :trainingFlag="trainingFlag"
          :accountInfo="accountInfo"
          @show-graph="$emit('show-graph', $event)"
        />
      </card-base>
    </div>
  </div>
</template>

<script>
import cardBase from '@/components/atoms/card-base'
import configList from './config-list'
import configGraph from './config-graph'
import configOptimizationExpression from './config-optimization-expression'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import timeTransformerV2SettingList from '@/components/organisms/time-transformer-v2-settings/setting-list.vue'
import timeTransformerV2Sub from './list-type/time-transformer-v2-sub.vue'

export default {
  components: {
    cardBase,
    configList,
    configGraph,
    configOptimizationExpression,
    timeTransformerV2SettingList,
    transitionToggleContents,
    timeTransformerV2Sub
  },
  props: {
    rawPredictionColumnOptions: Object,
    selectedTrainingData: Object,
    selectedTrainingDataSample: Object,
    selectedTrainingDataType: String,
    selectedRecipe: Object,
    recipeType: String,
    value: Object,
    nTestTrain: Array,
    nIters: Number,
    TOO_FEW_TH: Number,
    tooFewTestData: Boolean,
    tooFewTrainData: Boolean,
    charts: Object,
    chartsData: Object,
    trainingProgressStatus: String,
    elapsedTime: Number,
    trainingNumIter: Number,
    trainingProgress: Number,
    optimizationNumIter: Number,
    optimizationProgress: Number,
    sortedTrainedAis: Array,
    bestParams: Object,
    trials: Array,
    finishColumns: Array,
    selectedColumnIndex: Number,
    optimizationInfo: Object,
    checkOptimization: Boolean,
    isNotSaved: Boolean,
    disableOptimization: Array,
    learningPredictionColumns: Array,
    timeTransformerV2Filter: Array,
    showTimeTransformerV2DetailSetting: Boolean,
    timeTransformerV2Setting: Object,
    accountInfo: Object
  },
  computed: {
    config: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    predictionColumns() {
      return this.config?.trainConfig?.predictionColumn ?? []
    },
    trainingColumnStatus() {
      if (
        !this.learningPredictionColumns ||
        this.learningPredictionColumns.length < 2
      )
        return null
      if (this.learningPredictionColumns.length === this.finishColumns.length) {
        return {
          columnName: this.finishColumns[this.finishColumns.length - 1],
          count: this.finishColumns.length,
          max: this.learningPredictionColumns.length
        }
      } else {
        return {
          columnName: this.learningPredictionColumns[this.finishColumns.length],
          count: this.finishColumns.length,
          max: this.learningPredictionColumns.length
        }
      }
    },
    trainingFlag() {
      return (
        ['training', 'preparingEnvironment', 'stoppingTraining'].indexOf(
          this.trainingProgressStatus
        ) >= 0
      )
    },
    // TODO Views階層に移動する
    fixTimeTransformerV2Setting() {
      if (this.recipeType !== 'TIME_TRANSFORMER2') return []
      const targets = Object.entries(this.rawPredictionColumnOptions).reduce(
        (prev, [key, value]) => {
          let checkHasNull = false
          if (value?.reasons) {
            // MFTransformerV2の場合nullのみのチェックが必要なので、nullのみをチェックする
            checkHasNull = value.reasons.some((reason) => {
              return reason === 'null'
            })
          }

          const res = {
            columnName: key,
            mapping: value?.mapping,
            isDatetime: value.mftransformerCondition.isDatetime,
            isHasNull: !value.valid && checkHasNull,
            isKnown: { ...value.mftransformerCondition.isKnown },
            isTimeVarying: { ...value.mftransformerCondition.isTimeVarying },
            isReal: { ...value.mftransformerCondition.isReal }
          }
          prev.push(res)
          return prev
        },
        []
      )
      return targets
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  display: grid;
  grid-template-areas:
    'list graph'
    'list graph';
  grid-template-columns: adjustVW(996) minmax(0, adjustVW(612));
  grid-template-rows: 1fr;
  grid-column-gap: $space-medium;
  grid-row-gap: $space-medium;
  height: 100%;
  &-deep {
    grid-template-rows: minmax(0, auto) adjustVH(294);
  }
  &-optimization {
    grid-template-columns: minmax(0, adjustVW(980)) adjustVW(628);
  }
  &-time {
    grid-template-rows: minmax(auto, 1fr);
    grid-row-gap: 0;
    .list-card {
      overflow: hidden;
      height: min-content;
      max-height: 100%;
    }
  }
}
.list {
  grid-area: list;
  height: 100%;
  &-card {
    height: 100%;
  }
}
.graph {
  &-item {
    grid-area: graph;
    height: 100%;
    .body-optimization & {
      display: flex;
      flex-direction: column;
    }
    &-time-transformer-v2 {
      display: grid;
      grid-template-rows: auto minmax(0, 1fr);
      grid-row-gap: $space-medium;
    }
  }
  &-card {
    height: 100%;
    .body-optimization & {
      height: adjustVW(176);
    }
    .body-optimization-train & {
      height: 100%;
    }
    &-optimization {
      overflow: hidden;
      height: 100%;
      margin-bottom: $space-medium;
      will-change: opacity, height, margin-bottom;
    }
  }
}
.detail {
  grid-area: 'detail';
  height: 100%;
  &-card {
    height: 100%;
  }
}

.optimization-block {
  &-enter-active,
  &-leave-active {
    transition: opacity $transition-base, height $transition-base,
      margin-bottom $transition-base;
  }
  &-enter,
  &-leave-to {
    height: 0;
    margin-bottom: 0;
    opacity: 0;
  }
}
</style>
