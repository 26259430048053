<template>
  <div class="images-wrap">
    <div class="images-top">
      <texts
        class="images-top-title"
        :text="$t('training.config.detail.trainingDataset')"
        size="large"
      />
      <div class="images-inner">
        <text-with-title
          class="images-inner-item"
          :title="$t('training.config.detail.dataCount')"
          :text="selectedTrainingData.nImages"
        />
        <text-with-title
          class="images-inner-item"
          :title="$t('training.config.detail.labelCount')"
          :text="selectedTrainingData.nLabels"
        />
        <text-with-title
          class="images-inner-item"
          :title="$t('training.config.detail.classCount')"
          :text="selectedTrainingData.nClasses"
        />
      </div>
    </div>
    <button-toggle
      class="images-list"
      :openText="$t('training.config.detail.datasetDetail')"
      :closeText="$t('training.config.detail.datasetDetail')"
    >
      <list-item-slot class="images-list-body" :field="field" :items="items">
        <template v-slot:name="entry">
          <texts :text="entry.item" size="small" />
        </template>
        <template v-slot:count="entry">
          <div class="images-item-count-wrap">
            <texts
              class="images-item-count-text"
              :text="entry.item"
              size="small"
            />
            <texts
              class="images-item-count-percentage"
              :text="
                Math.ceil((entry.item / selectedTrainingData.nLabels) * 100) +
                  '%'
              "
              size="min"
              color="gray"
            />
          </div>
          <graph-bar-count
            class="images-item-bar"
            :count="entry.item"
            :maxCount="selectedTrainingData.nLabels"
          />
        </template>
        <template v-slot:image="entry">
          <div class="images-item-inner">
            <button
              v-for="(data, index) in entry.item.datas"
              :key="index"
              class="images-item-data"
              @click="showPopup(data.label, index)"
              @mouseleave="closePopup"
            >
              <image-wrap :url="data.body" />
              <popup-balloon-image
                class="images-item-balloon"
                :showPopup="popupId === index && popupLabel === data.label"
                :posRight="index > 1"
                :posBottom="
                  Object.keys(selectedTrainingData.statistics).length -
                    Object.keys(selectedTrainingData.statistics).indexOf(
                      data.label.toString()
                    ) <
                    2 && Object.keys(selectedTrainingData.statistics).length > 3
                "
                :popupContents="data"
                :style="{ '--image-position': index % 4 }"
                :class="{
                  'images-item-balloon-right': index > 2
                }"
              />
            </button>
          </div>
        </template>
      </list-item-slot>
    </button-toggle>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import textWithTitle from '@/components/molecules/text-with-title'
import buttonToggle from '@/components/molecules/button-toggle'
import listItemSlot from '@/components/molecules/list-item-slot.vue'
import GraphBarCount from '@/components/atoms/graph-bar-count.vue'
import ImageWrap from '@/components/atoms/image-wrap.vue'
import popupBalloonImage from '@/components/molecules/popup-balloon-image'

export default {
  components: {
    texts,
    textWithTitle,
    buttonToggle,
    listItemSlot,
    GraphBarCount,
    ImageWrap,
    popupBalloonImage
  },
  data() {
    return {
      type: 'image',
      popupId: null,
      popupLabel: null
    }
  },
  computed: {
    field() {
      return [
        {
          key: 'name',
          label: this.$t('training.config.detail.labelCount'),
          width: 178
        },
        {
          key: 'count',
          label: this.$t('training.config.detail.dataCount'),
          width: 136
        },
        {
          key: 'image',
          label: this.$t('training.config.detail.images'),
          width: 664
        }
      ]
    },
    items() {
      const items = []
      for (const [key, value] of Object.entries(
        this.selectedTrainingData.statistics
      )) {
        items.push({
          name: key,
          count: value,
          image: this.selectedTrainingDataSample[key]
        })
      }
      return items
    }
  },
  methods: {
    showPopup(label, index) {
      if (this.popupId !== index) {
        this.popupId = null
        this.popupLabel = null
        this.popupId = index
        this.popupLabel = label
      }
    },
    closePopup() {
      this.popupId = null
      this.popupLabel = null
    }
  },
  props: {
    selectedTrainingData: Object,
    selectedTrainingDataSample: Object
  }
}
</script>

<style lang="scss" scoped>
.images {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-top {
    flex-shrink: 0;
    padding: 0 0 $space-small;
    margin: 0 0 $space-small;
    border-bottom: $border-sub;
    &-title {
      margin: 0 0 $space-small;
    }
  }
  &-inner {
    display: flex;
    &-item {
      margin: 0 $space-medium 0 0;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  &-list {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 1;
    height: 100%;
    ::v-deep .add-new-service-button {
      margin: 0 0 $space-sub;
    }
    /* stylelint-disable */
    ::v-deep > div {
      height: 100% !important;
      padding: 0 $space-base 0 0;
      overflow-y: scroll;
      @include scrollbar;
    }
    /* stylelint-enable */
    &-body ::v-deep .list-item-entry-inner {
      overflow: visible;
    }
  }
  &-item {
    &-count {
      &-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 $space-base;
      }
      &-percentage {
        flex-shrink: 0;
      }
    }
    &-bar {
      z-index: 1;
    }
    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    &-data {
      position: relative;
      width: adjustVW(148);
      height: adjustVW(84);
      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
