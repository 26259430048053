<template>
  <component
    :is="displayType"
    class="trained-ai-setting-wrap"
    :class="{ 'trained-ai-setting-wrap-card': displayType === 'cardBase' }"
  >
    <div
      class="trained-ai-setting-inner"
      :class="`trained-ai-setting-inner-${wrapperClass}`"
    >
      <template v-if="isMultiColumn">
        <div class="trained-ai-setting-title trained-ai-setting-title-multi">
          <div class="trained-ai-setting-title-inner">
            <texts
              :text="$t('trainedAi.multi.selectedColumn')"
              color="gray"
              size="small"
            />
          </div>
        </div>
        <select-column-box
          v-model="selectedItem"
          :trainedAi="trainedAi"
          :finishColumns="finishColumns"
        />
      </template>
      <template v-if="displayThreshold">
        <div
          v-if="changeableThreshold"
          class="trained-ai-setting-title trained-ai-setting-title-positive"
        >
          <div class="trained-ai-setting-title-inner">
            <texts
              :text="$t('trainedAi.reversePositive.title')"
              color="gray"
              size="small"
            />
            <icons
              v-tooltip="$t('trainedAi.reversePositive.description')"
              iconName="info"
              size="small"
              color="gray"
            />
          </div>
        </div>
        <div
          v-if="changeableThreshold"
          class="trained-ai-setting-title-positive-select"
        >
          <select-box
            v-model="selectedPositive"
            isGray
            :items="positiveOption"
            :isDisabled="selectedColumnIndex === -1 || loadChangeColumn"
            :firstSelectItem="positiveOption[selectedPositive]"
            @select-item="selectPositive"
          />
        </div>
        <div
          class="trained-ai-setting-title trained-ai-setting-title-threshold"
        >
          <div class="trained-ai-setting-title-inner">
            <texts
              :text="$t('trainedAi.threshold.title')"
              color="gray"
              size="small"
            />
            <icons
              v-tooltip="
                changeableThreshold
                  ? $t('trainedAi.threshold.description')
                  : $t('trainedAi.threshold.descriptionDisable')
              "
              iconName="info"
              size="small"
              color="gray"
            />
          </div>
          <div
            class="trained-ai-setting-threshold-count"
            :class="{
              'trained-ai-setting-threshold-count-disabled':
                !changeableThreshold ||
                selectedColumnIndex === -1 ||
                loadChangeColumn
            }"
          >
            <input
              v-model.number="dataThreshold"
              type="number"
              :min="minThreshold"
              :max="maxThreshold"
              :step="stepThreshold"
              :disabled="
                !changeableThreshold ||
                  selectedColumnIndex === -1 ||
                  loadChangeColumn
              "
              @input="emitThreshold"
            >
          </div>
        </div>
        <div
          class="trained-ai-setting-threshold-scale"
          :class="{
            'trained-ai-setting-threshold-scale-disabled':
              !changeableThreshold ||
              selectedColumnIndex === -1 ||
              loadChangeColumn
          }"
        >
          <div class="trained-ai-setting-threshold-scale-inner">
            <input
              v-model.number="dataThreshold"
              class="trained-ai-setting-threshold-scale-range"
              type="range"
              name="speed"
              :min="minThreshold"
              :max="maxThreshold"
              :step="stepThreshold"
              :disabled="
                !changeableThreshold ||
                  selectedColumnIndex === -1 ||
                  loadChangeColumn
              "
              @change="emitThreshold"
            >
            <div
              class="trained-ai-setting-threshold-scale-range-status"
              :style="{ '--width': (dataThreshold / maxThreshold) * 100 + '%' }"
            />
          </div>
        </div>
      </template>
    </div>
  </component>
</template>

<script>
import cardBase from '@/components/atoms/card-base'
import icons from '@/components/atoms/icon'
import selectColumnBox from '@/components/organisms/trained-ai-common/select-column-box'
import selectBox from '@/components/molecules/select-box.vue'

export default {
  components: {
    cardBase,
    icons,
    selectColumnBox,
    selectBox
  },
  data() {
    return {
      selectedItem: '',
      dataThreshold: 0.5,
      minThreshold: 0.01,
      maxThreshold: 0.99,
      stepThreshold: 0.01,
      dataReversePositive: false,
      positiveOption: [],
      selectedPositive: 0,

      // 内部的に持っているindex番号。この番号が変わらない限りthresholdを0.5に戻さない
      internalIndex: null
    }
  },
  props: {
    // 複数列かどうか
    isMultiColumn: {
      type: Boolean,
      default: false
    },
    // しきい値を変更できるかどうか
    changeableThreshold: {
      type: Boolean,
      default: false
    },
    // TODO 上の階層から渡してもらう しきい値を表示するかどうか
    displayThreshold: {
      type: Boolean,
      default: true
    },
    trainedAi: {
      type: Object,
      required: true
    },
    finishColumns: {
      type: Array,
      default: () => []
    },
    selectedColumnIndex: {
      type: Number,
      required: true
    },
    // カードを背景に表示するかどうか
    showCard: {
      type: Boolean,
      default: false
    },
    loadChangeColumn: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      required: true
    },
    reversePositive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    displayType() {
      if (this.showCard) return 'cardBase'
      return 'div'
    },
    wrapperClass() {
      const CLASS_LIST = {
        SEPARATE: 'separate',
        SEPARATE_POSITIVE: 'separate-positive',
        SMALL: 'small',
        LARGE: 'large',
        FULL: 'full'
      }

      if (this.isMultiColumn && this.displayThreshold) {
        if (this.changeableThreshold) {
          return CLASS_LIST.SEPARATE_POSITIVE
        } else {
          return CLASS_LIST.SEPARATE
        }
      } else if (this.isMultiColumn && !this.displayThreshold) {
        return CLASS_LIST.SMALL
      } else if (!this.isMultiColumn && this.displayThreshold) {
        if (this.changeableThreshold) {
          return CLASS_LIST.FULL
        } else {
          return CLASS_LIST.LARGE
        }
      } else {
        return ''
      }
    }
  },
  mounted() {
    const configColumn = this.trainedAi?.trainConfig?.predictionColumn ?? []
    if (configColumn.length === 0) return
    this.selectedItem = configColumn[this.selectedColumnIndex]
    this.internalIndex = this.selectedColumnIndex

    this.setConfusionMatrixLabel()

    // タブを切り替えてこのタブに入るときは、元の閾値を保持する
    this.$nextTick(() => {
      this.dataThreshold = this.value
      this.selectedPositive = this.reversePositive ? 1 : 0
    })
  },
  watch: {
    selectedItem(newVal) {
      this.$emit('change-column', newVal)
      const internalIndex =
        this.trainedAi.trainConfig.predictionColumn.findIndex(
          (column) => column === newVal
        )
      if (internalIndex === -1) return
      if (this.internalIndex !== internalIndex) {
        this.threshold = 0.5
        this.internalIndex = internalIndex
      }
    },
    dataThreshold(newVal) {
      if (newVal < this.minThreshold) {
        this.dataThreshold = this.minThreshold
      } else if (newVal > this.maxThreshold) {
        this.dataThreshold = this.maxThreshold
      }
    },
    value(newVal) {
      this.dataThreshold = newVal
    },
    reversePositive(newVal) {
      this.selectedPositive = newVal ? 1 : 0
    },
    loadChangeColumn(loading) {
      if (!loading) this.setConfusionMatrixLabel()
    }
  },
  methods: {
    emitThreshold() {
      // 不正な値が入っていた場合はemitさせない
      if (
        typeof this.dataThreshold === 'number' &&
        this.dataThreshold > 0 &&
        this.dataThreshold < 1
      ) {
        this.$emit('change-threshold', this.dataThreshold)
      }
    },
    selectPositive(newVal) {
      this.dataReversePositive = newVal.id === 1
      this.$emit('reverse-positive', this.dataReversePositive)
    },
    setConfusionMatrixLabel() {
      // confusion matrix用の凡例作成
      const confusionMatrixLabel =
        this.trainedAi?.result?.test_confusion_matrix_label ?? []
      const positiveOption = []
      confusionMatrixLabel.forEach((item, key) => {
        positiveOption.push({
          id: key,
          name: item,
          value: key
        })
      })
      this.positiveOption = positiveOption
    }
  }
}
</script>

<style lang="scss" scoped>
$largeWidth: 460;
$smallWidth: 320;
$inputTitleHeight: 34;
$inputBoxHeight: 56;

.trained-ai-setting {
  &-wrap {
    width: 100%;
    &-card {
      border: $border-main;
      border-radius: adjustVW(8);
    }
  }
  &-inner {
    display: grid;
    &-large {
      grid-template-areas:
        'threshold'
        'range';
      grid-template-columns: adjustVW($largeWidth);
      grid-template-rows: adjustVW($inputTitleHeight) adjustVW($inputBoxHeight);
      grid-row-gap: $space-base;
    }
    &-full {
      grid-template-areas:
        'positive threshold'
        'select range';
      grid-template-columns: 1fr adjustVW($largeWidth);
      grid-template-rows: adjustVW($inputTitleHeight) adjustVW($inputBoxHeight);
      grid-column-gap: $space-medium;
      grid-row-gap: $space-base;
      .trained-ai-setting-title {
        &-positive {
          grid-area: positive;
          &-select {
            grid-area: select;
          }
        }
        &-threshold {
          grid-area: threshold;
        }
        &-inner {
          width: 100%;
        }
      }
    }
    &-small {
      grid-template-columns: adjustVW($smallWidth);
      grid-template-rows: fit-content adjustVW($inputBoxHeight);
      grid-row-gap: $space-sub;
    }
    &-separate {
      grid-template-areas:
        'multi threshold'
        'input range';
      grid-template-columns: 1fr adjustVW($largeWidth);
      grid-template-rows: adjustVW($inputTitleHeight) adjustVW($inputBoxHeight);
      grid-column-gap: $space-medium;
      grid-row-gap: $space-base;
      .trained-ai-setting-title {
        &-multi {
          grid-area: multi;
        }
        &-positive {
          grid-area: positive;
          &-select {
            grid-area: select;
          }
        }
        &-threshold {
          grid-area: threshold;
        }
      }
    }
    &-separate-positive {
      grid-template-areas:
        'multi multi'
        'input input'
        'positive threshold'
        'select range';
      grid-template-columns: calc(adjustVW($smallWidth) - $space-medium) adjustVW(
          $largeWidth
        );
      grid-template-rows:
        adjustVW($inputTitleHeight) adjustVW($inputBoxHeight) calc(
          adjustVW($inputTitleHeight) + $space-small
        )
        adjustVW($inputBoxHeight);
      grid-column-gap: $space-medium;
      grid-row-gap: $space-base;
      .trained-ai-setting-title {
        &-multi {
          grid-area: multi;
        }
        &-positive {
          grid-area: positive;
          margin-top: $space-small;
          &-select {
            grid-area: select;
          }
        }
        &-threshold {
          grid-area: threshold;
          margin-top: $space-small;
        }
      }
    }
  }
  &-title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &-inner {
      display: flex;
      grid-column-gap: $space-sub;
    }
  }
  &-threshold {
    display: flex;
    flex-direction: column;
    &-inner {
      width: adjustVW($largeWidth);
    }
    &-count {
      display: flex;
      align-items: center;
      width: adjustVW(64);
      height: adjustVW($inputTitleHeight);
      padding: $space-min $space-min adjustVW(3);
      border: $border-emphasis;
      font-size: $text-base;
      border-radius: adjustVW(4);
      transition: $transition-base background-color,
        $transition-base border-color;
      > input {
        appearance: textfield;
        width: 100%;
        text-align: center;
        transition: $transition-base color;
        &::-webkit-outer-spin-button {
          appearance: none;
          margin: 0;
        }
        &::-webkit-inner-spin-button {
          appearance: none;
          margin: 0;
        }
      }
      &-disabled {
        border-color: $border-gray;
        background-color: $background-sub;
        > input {
          color: $border-gray;
          cursor: not-allowed;
        }
      }
    }
    &-scale {
      grid-area: range;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 $space-small;
      background: $background-sub;
      border-radius: adjustVW(8);
      &-inner {
        position: relative;
        width: 100%;
      }
      &-range {
        appearance: none;
        width: 100%;
        height: adjustVH(8);
        background: $line-gray;
        border-radius: adjustVW(4);
        cursor: pointer;
        outline: none;
        &::-webkit-slider-thumb {
          position: relative;
          appearance: none;
          width: adjustVW(24);
          height: adjustVW(24);
          border: $border-emphasis;
          background: $background;
          border-radius: 9in;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
          z-index: 2;
          transition: $transition-base border-color,
            $transition-base background-color;
        }
        &::-moz-range-thumb {
          width: adjustVW(24);
          height: adjustVW(24);
          border: $border-emphasis;
          background: $background;
          border-radius: 9in;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
          transition: $transition-base border-color,
            $transition-base background-color;
        }
        &-status {
          --width: 50;
          position: absolute;
          top: 50%;
          width: var(--width);
          height: adjustVH(8);
          background: $key-color;
          border-radius: 9in 0 0 9in;
          z-index: 1;
          transform: translateY(-50%);
          transition: $transition-base border-color,
            $transition-base background-color;
        }
      }
      &-disabled {
        .trained-ai-setting-threshold-scale-range {
          &::-webkit-slider-thumb {
            border-color: $border-gray;
            background-color: $medium-gray;
          }
          &::-moz-range-thumb {
            border-color: $border-gray;
            background-color: $medium-gray;
          }
          &-status {
            background-color: $border-gray;
          }
        }
      }
    }
  }
}
</style>
