<template>
  <page-top :title="$t('preprocessing.title')" iconName="preproc" notScroll>
    <div class="top-function-wrap">
      <transition-toggle-contents>
        <div class="top-function-inner">
          <fuse-search
            class="top-function-search"
            :option="option"
            :placeholder="$t('preprocessing.search')"
            :value.sync="preprocessingsFiltered"
            @input="$emit('input', $event)"
          />
        </div>
      </transition-toggle-contents>
      <div class="top-function-menus">
        <div class="top-function-menus-inner">
          <menu-list
            :checked="checked"
            :menus="menu"
            :isMenuShow="checked.length > 0"
            @menu-click="
              $emit($event.activeMenu + '-menu-click', { target: checked })
            "
          />
        </div>
      </div>
    </div>
  </page-top>
</template>

<script>
import pageTop from '@/components/atoms/page-top'
import fuseSearch from '@/components/molecules/fuse-search'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import menuList from '@/components/molecules/menu-list'

export default {
  components: {
    pageTop,
    fuseSearch,
    transitionToggleContents,
    menuList
  },
  data() {
    return {
      option: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.2,
        keys: [
          {
            name: 'name',
            weight: 0.5
          },
          {
            name: 'accountId',
            weight: 0.5
          }
        ],
        distance: 10000
      }
    }
  },
  props: {
    accountInfo: Object,
    checked: Array,
    preprocessingsFiltered: Array,
    iconMenus: Object
  },
  computed: {
    preprocessingsDeleteDisabled() {
      if (
        !this.accountInfo ||
        !this.preprocessingsFiltered ||
        this.preprocessingsFiltered.length === 0
      )
        return false
      if (!this.checked || this.checked.length === 0) return false
      if (this.accountInfo?.organizationInfo?.role === 'admin') return false
      const preprocessings = this.preprocessingsFiltered.filter((preprocess) =>
        this.checked.some((id) => id === preprocess.id)
      )
      if (
        preprocessings.some(
          (preprocess) => preprocess.accountId !== this.accountInfo.accountId
        )
      ) {
        return true
      } else {
        return false
      }
    },
    menu() {
      return [
        // 前処理管理画面に表示するアイコンメニュー
        {
          id: 0,
          iconName: 'dataset',
          value: 'dataset',
          isMultiple: false, // 複数選択可能かどうか
          tips: {
            name: this.$t('preprocessing.selectDataset')
          }
        },
        {
          id: 1,
          iconName: 'projectMove', // project
          value: 'project',
          isMultiple: true, // 複数選択可能かどうか
          tips: {
            name: this.$t('common.menu.projectMove')
          }
        },
        {
          id: 2,
          iconName: 'projectAdd', // project
          value: 'project-create',
          isMultiple: true, // 複数選択可能かどうか
          tips: {
            name: this.$t('common.menu.projectCreateMove')
          }
        },
        {
          id: 3,
          iconName: 'delete', // delete
          value: 'delete',
          isMultiple: true, // 複数選択可能かどうか
          disabled: this.preprocessingsDeleteDisabled,
          tips: {
            name: this.$t('common.deleteButton'),
            disabledName: this.$t(
              'common.disabled.organization.otherAccountItemDelete'
            )
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.top-function {
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-inner {
    display: flex;
  }
  &-button {
    margin: 0 $space-medium 0 0;
  }
  &-search {
    width: adjustVW(320);
    height: adjustVH(48);
  }
  &-menus {
    display: flex;
    align-items: center;
    &-inner {
      padding: 0 $space-small 0 0;
      margin: 0 $space-small 0 0;
      border-right: $border-main;
    }
  }
}
</style>
