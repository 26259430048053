<template>
  <div key="preprocList" class="preprocessing-list-block">
    <table-list
      v-if="preprocessingsFiltered"
      v-model="checkedModel"
      class="preprocessing-list-list"
      :items="sortedPreprocessings"
      :field="preprocessingListFields"
      :noSearchResults="noSearchResults"
      :isNameIcon="false"
      id-column="id"
      defaultSortedBy="updateTime"
    />
  </div>
</template>

<script>
import tableList from '@/components/molecules/table-list'

export default {
  components: {
    tableList
  },
  props: {
    preprocessingsFiltered: Array,
    checked: Array,
    noSearchResults: Boolean
  },
  computed: {
    preprocessingListFields() {
      return [
        {
          id: 0,
          key: 'name',
          label: this.$t('preprocessing.preprocessingName'), // '前処理名/説明',
          thClass: 'name',
          listType: 'name',
          sortable: true
        },
        {
          id: 1,
          key: 'layers',
          label: this.$t('preprocessing.preprocessingContent'), // '前処理内容',
          thClass: 'preprocessing',
          listType: 'preprocessing',
          sortable: false
        },
        {
          id: 2,
          key: 'accountId',
          label: this.$t('common.owner'), // '所有者',
          thClass: 'default',
          listType: 'default',
          sortable: true
        },
        {
          id: 3,
          key: 'updateTime',
          label: this.$t('common.updateTime'), // '最終更新日',
          thClass: 'default',
          listType: 'time',
          sortable: true
        }
      ]
    },
    sortedPreprocessings() {
      const preprocessings = Object.values(this.preprocessingsFiltered)
      return preprocessings.sort((a, b) => {
        return a.updateTime > b.updateTime ? -1 : 1
      })
    },
    checkedModel: {
      get: function () {
        return this.checked
      },
      set: function (newVal) {
        this.$emit('set-checked', newVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.preprocessing-list {
  &-block {
    position: relative;
    height: 100%;
  }
  &-list {
    height: 100%;
  }
}
</style>
