<template>
  <page-top
    :title="(service && service.name) || ''"
    iconName="backward"
    notScroll
    isBackButton
  >
    <div class="dataset-top-func">
      <!--tabkei-->
      <tab-list :tabs="tabs" @change-tab="changeTab" />
      <text-menu-list
        :menus="serviceMenu"
        :activeMenu="activeMenu"
        @menu-click="menuClick"
      />
    </div>
  </page-top>
</template>

<script>
import pageTop from '@/components/atoms/page-top'
import tabList from '@/components/molecules/tab-list'
import textMenuList from '@/components/molecules/text-menu-list'

export default {
  components: {
    pageTop,
    tabList,
    textMenuList
  },
  props: {
    service: Object,
    activeTab: String,
    activeMenu: String,
    serviceMenu: Array
  },
  data() {
    return {
      visibleContents: 'dataset'
    }
  },
  methods: {
    changeTab(e) {
      this.$emit('change-tab', e)
      this.$emit('update:active-tab', e)
    },
    menuClick(e) {
      this.$emit('menu-click', e)
      if (e.activeMenu !== 'delete') {
        this.$emit('update:active-menu', e.activeMenu)
      }
    }
  },
  computed: {
    tabs() {
      return [
        // ページ上部のタブ
        {
          id: 0,
          name: this.$t('serviceDetail.tab.serviceInfo'),
          value: 'detail',
          iconName: 'detail'
        },
        {
          id: 1,
          name: this.$t('serviceDetail.tab.serviceUsageInfo'),
          value: 'status',
          iconName: 'serviceStatus'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.dataset-top-func {
  display: flex;
  justify-content: space-between;
}
</style>
