<template>
  <div class="target-column-info-wrapper">
    <div class="target-column-info-data-num">
      <div class="target-column-info-item">
        <texts
          class="target-column-info-item-title"
          color="gray"
          size="small"
          :text="
            this.$i18n.t(
              'selectTargetColumn.menu.targetColumnInfo.detail.uniqueNumber'
            )
          "
        />
        <texts
          class="target-column-info-item-value"
          :text="uniqueDataNum ? uniqueDataNum : '-'"
        />
      </div>

      <div class="target-column-info-item">
        <texts
          class="target-column-info-item-title"
          color="gray"
          size="small"
          :text="
            this.$i18n.t(
              'selectTargetColumn.menu.targetColumnInfo.detail.missingValue'
            )
          "
        />
        <texts
          class="target-column-info-item-value"
          :text="lackedDataNum ? lackedDataNum : '-'"
        />
      </div>
    </div>

    <transition-toggle-contents>
      <div v-if="!noneDatasetDetail" class="target-column-info-item">
        <texts
          class="target-column-info-item-title"
          color="gray"
          size="small"
          :text="
            this.$i18n.t(
              'selectTargetColumn.menu.targetColumnInfo.detail.firstValue'
            )
          "
        />
        <div class="target-column-info-item-value">
          <texts
            :text="
              firstItemValue || firstItemValue === 0 ? firstItemValue : '-'
            "
          />
        </div>
      </div>
    </transition-toggle-contents>
  </div>
</template>

<script>
import Texts from '@/components/atoms/text.vue'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    Texts,
    TransitionToggleContents
  },
  data() {
    return {}
  },
  props: {
    lackedDataNum: Number,
    uniqueDataNum: [String, Number],
    firstItemValue: [String, Number],
    noneDatasetDetail: Boolean
  }
}
</script>

<style lang="scss" scoped>
.target-column-info-wrapper {
  display: flex;
  flex-direction: column;
}

.target-column-info-data-num {
  display: flex;
  margin-bottom: $space-small;
}

.target-column-info-item {
  width: calc(50% - #{$space-sub / 2});
  margin-right: $space-sub;
  &:last-of-type {
    margin-right: 0;
  }
  &-bottom {
    margin-bottom: 0;
  }
}

.target-column-info-item-value {
  height: adjustVH(28);
}
</style>
