<template>
  <div class="conditions-wrap">
    <div
      v-for="(setting, key) in conditions"
      :key="key"
      class="conditions-list"
    >
      <div>
        <dot-text
          class="conditions-text"
          :text="$t(`trainedAi.timeTransformerV2.conditions.type.${key}.on`)"
          :color="setting ? 'green' : 'disabled'"
          :size="13"
        />
      </div>
      <div>
        <dot-text
          class="conditions-text"
          :text="$t(`trainedAi.timeTransformerV2.conditions.type.${key}.off`)"
          :color="!setting ? 'green' : 'disabled'"
          :size="13"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dotText from '@/components/atoms/dot-text.vue'
export default {
  components: { dotText },
  props: {
    conditions: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.conditions {
  &-wrap {
    overflow: hidden;
    display: flex;
    grid-column-gap: $space-base;
    width: 100%;
  }
  &-list {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-base;
  }
  &-text {
    text-align: center;
  }
}
</style>
