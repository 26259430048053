<template>
  <div class="optimization">
    <div class="optimization-body">
      <card-base class="optimization-table">
        <transition-toggle-contents>
          <div
            v-if="optimizationInfo.loadOptimizationDetail"
            class="optimization-loading"
          >
            <loading />
          </div>
          <template
            v-else-if="
              trainedAi.summary.latestOptimizationConditions &&
                Object.keys(trainedAi.summary.latestOptimizationConditions)
                  .length > 0
            "
          >
            <optimization-table
              :result="optimizationResult.result"
              :optimizationConditions="
                trainedAi.summary.latestOptimizationConditions
              "
              :accountInfo="accountInfo"
              :columnRanges="optimizationResult.columnRanges"
              :limit="optimizationResult.limit"
              :total="optimizationResult.total"
              :inPageNumber="optimizationResult.inPageNumber"
              :loadPaging="optimizationInfo.loadOptimizationPaging"
              :filters="optimizationResult.filters"
              :download="optimizationInfo.download"
              @change-page="$emit('change-page', $event)"
              @change-filter-value="$emit('change-filter-value', $event)"
              @close-optimization-result-tutorial="
                $emit('close-optimization-result-tutorial')
              "
              @download-result="$emit('download-result', $event)"
            />
          </template>
          <optimization-no-item v-else :trainedAi="trainedAi" />
        </transition-toggle-contents>
      </card-base>
      <detail-path
        class="optimization-recipe"
        :trainedAi="trainedAi"
        isOptimization
      />
      <detail-info class="optimization-info" :trainedAi="trainedAi" />
    </div>
  </div>
</template>

<script>
import cardBase from '@/components/atoms/card-base'
import loading from '@/components/atoms/loading'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import optimizationTable from '@/components/organisms/optimization/optimization-table'
import optimizationNoItem from '@/components/organisms/optimization/optimization-no-item'
import detailInfo from '@/components/organisms/trained-ai-detail/trained-ai-detai-tab-inner/detail/detail-info.vue'
import detailPath from '@/components/organisms/trained-ai-detail/trained-ai-detai-tab-inner/detail/detail-path.vue'

export default {
  props: {
    accountInfo: Object,
    trainedAi: Object,
    optimizationInfo: Object,
    optimizationResult: Object
  },
  components: {
    cardBase,
    loading,
    transitionToggleContents,
    optimizationTable,
    optimizationNoItem,
    detailInfo,
    detailPath
  }
}
</script>

<style lang="scss" scoped>
.optimization {
  overflow: hidden;
  height: 100%;
  &-body {
    display: grid;
    grid-template-areas:
      'table table'
      'recipe info';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(0, 1fr) adjustVW(148);
    grid-column-gap: $space-medium;
    grid-row-gap: $space-medium;
    height: 100%;
    padding-bottom: $space-medium;
    margin: 0 $space-medium;
  }
  &-table {
    grid-area: table;
  }
  &-recipe {
    grid-area: recipe;
  }
  &-info {
    grid-area: info;
  }
  &-loading {
    height: 100%;
  }
}
</style>
