<template>
  <transition name="balloon-toggle" mode="out-in">
    <div
      v-if="showPopup"
      class="wrap"
      :class="{
        'wrap-right': posRight,
        'wrap-bottom': posBottom
      }"
    >
      <div class="inner">
        <div v-if="displayHeatmapCheckBox" class="heat-map">
          <div class="name">
            <texts :text="popupContents.name" isBold size="small" />
          </div>
          <div
            v-tooltip="
              existHeatmap ? $t('inference.result.detail.imageHeatMapTips') : ''
            "
          >
            <checkbox-base
              :checked="checkedHeatmap"
              :text="$t('inference.result.detail.showFeatureImportance')"
              :isDisabled="disabledCheckBox"
              isSmall
              @input="$emit('check-heatmap', $event)"
            />
          </div>
        </div>
        <div class="img">
          <image-wrap :url="popupContents.body" />
        </div>
        <div class="block">
          <div v-if="!existHeatmap" class="name">
            <texts :text="popupContents.name" isBold size="small" />
          </div>
          <div class="detail">
            <texts
              class="text"
              :text="
                $t('datasetDetail.balloon.width') + popupContents.width + 'px'
              "
              size="small"
            />
            <texts
              :text="
                $t('datasetDetail.balloon.height') + popupContents.height + 'px'
              "
              size="small"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import texts from '@/components/atoms/text'
import imageWrap from '@/components/atoms/image-wrap'
import checkboxBase from '@/components/atoms/checkbox-base'

export default {
  components: {
    texts,
    imageWrap,
    checkboxBase
  },
  props: {
    /** ポップアップを表示するかどうか */
    showPopup: {
      type: Boolean,
      default: false
    },
    /** ポップアップを右側に表示 */
    posRight: {
      type: Boolean,
      default: false
    },
    /** ポップアップを下向きに表示 */
    posBottom: {
      type: Boolean,
      default: false
    },
    /** ポップアップの中身
     *  name: 画像名 {string}
     *  body: 画像をbase64で変換した値 {string}
     *  width: 画像の横幅 {number}
     *  height: 画像の縦幅 {number}
     */
    popupContents: Object,
    /** 重要度のヒートマップをが存在するかどうか */
    existHeatmap: {
      type: Boolean,
      default: false
    },
    /** 重要度のヒートマップ表示用のチェックボックスを表示するかどうか */
    displayHeatmapCheckBox: {
      type: Boolean,
      default: false
    },
    /** 重要度のヒートマップ表示を変更できるか */
    disabledCheckBox: {
      type: Boolean,
      default: false
    },
    /** 重要度のヒートマップのチェックボックスの状態 */
    checkedHeatmap: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  position: absolute;
  top: calc(100% + #{$space-small});
  left: -$space-medium;
  width: adjustVW(400) + ($space-small * 2);
  background: $background;
  border-radius: adjustVW(16);
  box-shadow: $box-shadow-hover;
  z-index: 10;
  &-right {
    right: -$space-medium;
    left: auto;
  }
  &-bottom {
    top: auto;
    bottom: calc(100% + #{$space-small});
  }
}
.inner {
  padding: $space-small;
  background: $background;
  border-radius: adjustVW(16);
  &::after {
    content: '';
    position: absolute;
    top: adjustVW(-20);
    left: adjustVW(32);
    width: adjustVW(56);
    height: adjustVW(56);
    background: $background;
    border-radius: adjustVW(8);
    box-shadow: $box-shadow-hover;
    z-index: -1;
    transform: rotate(45deg);
    .wrap-right & {
      right: adjustVW(32);
      left: auto;
    }
    .wrap-bottom & {
      top: auto;
      bottom: adjustVW(-20);
    }
  }
}
.img {
  width: adjustVW(400);
  height: adjustVW(225);
  margin: 0 0 $space-small;
}
.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.name {
  overflow: hidden;
  padding: 0 $space-small 0 0;
}
.detail {
  display: flex;
  align-items: center;
  > .text {
    margin: 0 $space-small 0 0;
  }
}
.heat-map {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $space-sub;
}
.balloon-toggle-enter {
  opacity: 0;
  transform: translateY(-$space-small);
  &-active {
    transition: opacity $transition-base, transform ease-in-out $transition-base;
    will-change: opacity transform;
  }
  &-to {
    opacity: 1;
    transform: translateY(1);
  }
}
.balloon-toggle-leave {
  opacity: 1;
  transform: translateY(1);
  &-active {
    transition: opacity $transition-base, transform ease-in-out $transition-base;
    will-change: opacity transform;
  }
  &-to {
    opacity: 0;
    transform: translateY(-$space-small);
  }
}
</style>
