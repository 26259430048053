<template>
  <popup-array
    :popupDefs="popupDefs"
    :showPopup="showPopup"
    :noClose="
      !inTurorialLast ||
        showPopup.includes('caution') ||
        showPopup.includes('preventTrainingStatus')
    "
    @close-modal="$emit('close-modal', $event)"
  >
    <template #tutorialSelectTargetColumn>
      <tutorial-select-target-column
        @close-modal="$emit('close-modal', 'tutorialSelectTargetColumn')"
        @in-last-page="changePage($event)"
      />
    </template>
    <template #caution>
      <popup-caution @skip-select="$emit('skip-select')" />
    </template>
    <template #ngColumn>
      <popup-ng-column
        :rePreprocess="rePreprocess"
        @close-modal="$emit('close-modal', 'ngColumn')"
        @re-preprocess="$emit('re-preprocess')"
      />
    </template>
    <template #preventTrainingStatus>
      <prevent-training-status @not-prevent="$emit('not-prevent')" />
    </template>
  </popup-array>
</template>

<script>
import { popupArray } from '@/components/molecules/popup'
import tutorialSelectTargetColumn from './popup/tutorial-select-target-column'
import popupCaution from './popup/caution'
import popupNgColumn from './popup/ng-column'
import preventTrainingStatus from '@/components/organisms/common-popups/prevent-training-status.vue'

export default {
  components: {
    tutorialSelectTargetColumn,
    popupArray,
    popupCaution,
    popupNgColumn,
    preventTrainingStatus
  },
  data() {
    return {
      inTurorialLast: false
    }
  },
  computed: {
    popupDefs() {
      return {
        tutorialSelectTargetColumn: {
          title: this.$t('selectTargetColumn.popup.tutorial.title')
        },
        caution: {
          title: this.$t('selectTargetColumn.cautionPopup.title')
        },
        ngColumn: {
          title: this.$t('selectTargetColumn.ngColumnPopup.title')
        },
        preventTrainingStatus: {
          title: this.$t('common.popup.preventTrainingStatus.title')
        }
      }
    }
  },
  methods: {
    changePage(bool) {
      this.inTurorialLast = bool
    }
  },
  props: {
    showPopup: Array,
    rePreprocess: Boolean
  },
  watch: {
    showPopup(newVal) {
      this.inTurorialLast = !newVal.includes('tutorialSelectTargetColumn')
    }
  }
}
</script>
