<template>
  <card-base>
    <detail-text-mining-list
      :typeList="typeList"
      @go-text-mining="goTextMining"
    />
  </card-base>
</template>

<script>
import cardBase from '@/components/atoms/card-base'
import detailTextMiningList from '@/components/organisms/trained-ai-detail/trained-ai-detai-tab-inner/detail/detail-text-mining-list'
import { TEXT_MINING_TYPE_TO_PAGE_TYPE } from '@/lib/text-mining'

export default {
  components: {
    cardBase,
    detailTextMiningList
  },
  props: {
    trainedAi: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    typeList() {
      return this.trainedAi?.summary?.textMining?.typeList ?? {}
    }
  },
  methods: {
    goTextMining(type) {
      const pageType = TEXT_MINING_TYPE_TO_PAGE_TYPE[type]
      this.$emit('go-text-mining', pageType)
    }
  }
}
</script>
