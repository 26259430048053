<template>
  <div class="preprocessing-list-wrap">
    <div class="preprocessing-list-top">
      <preprocessing-list-top
        :checked="checked"
        :accountInfo="accountInfo"
        :preprocessingsFiltered="preprocessingsFiltered"
        @input="search($event)"
        @delete-menu-click="$emit('delete-menu-click', $event)"
        @dataset-menu-click="$emit('dataset-menu-click', $event)"
        @project-menu-click="$emit('project-menu-click', $event)"
        @project-create-menu-click="
          $emit('project-menu-click', { ...$event, newProject: true })
        "
      />
    </div>
    <div class="preprocessing-list-list">
      <preprocessing-list-list
        :preprocessingsFiltered="preprocessingsFiltered"
        :noSearchResults="noSearchResults"
        :checked="checked"
        @set-checked="$emit('set-checked', $event)"
      />
    </div>
  </div>
</template>

<script>
import preprocessingListTop from './preprocessing-list-top'
import preprocessingListList from './preprocessing-list-list'

export default {
  components: {
    preprocessingListTop,
    preprocessingListList
  },
  data() {
    return {
      preprocessingObject: null,
      noSearchResults: false
    }
  },
  methods: {
    search(e) {
      this.preprocessingObject = e.result
      this.noSearchResults = e.noSearchResults
    }
  },
  computed: {
    preprocessingsFiltered() {
      if (!this.preprocessingObject) {
        return Object.values(this.preprocessings)
      } else {
        return this.preprocessingObject
      }
    }
  },
  props: {
    accountInfo: Object,
    checked: Array,
    preprocessings: Object
  }
}
</script>

<style lang="scss" scoped>
.preprocessing-list-wrap {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: $space-base;
  height: calc(100vh - #{adjustVH($headerTabHeight)});
  padding: 0 $space-medium $space-medium;
}
.preprocessing-list-list {
  overflow: hidden;
  max-height: 100%;
  border-radius: adjustVW(16);
  box-shadow: $box-shadow-main;
}
</style>
