<template>
  <div style="height: 100%">
    <transition-toggle-contents>
      <div v-if="activeTab === 'detail'" key="detail" style="height: 100%">
        <transition-toggle-contents>
          <div
            v-if="!isEditting"
            key="default"
            class="service-detail-body-wrap"
          >
            <div class="service-detail-body-main">
              <!--top-main-->
              <div class="service-detail-body-main-block">
                <texts
                  size="small"
                  color="gray"
                  :text="
                    $t('serviceList.popup.addNewService.serviceDescription')
                  "
                />
                <description :text="service.description" line="3" isMore />
              </div>
              <div class="service-detail-body-main-block">
                <div class="service-detail-body-main-inner-title">
                  <texts text="API URL" size="small" color="gray" />
                </div>
                <copy-box
                  :text="apiUrl"
                  class="service-detail-body-main-inner"
                />
              </div>
              <div class="service-detail-body-main-block">
                <div class="service-detail-body-main-inner-title">
                  <texts
                    :text="$t('serviceDetail.secretKey')"
                    size="small"
                    color="gray"
                  />
                </div>
                <copy-box
                  :text="service.secretKey"
                  class="service-detail-body-main-inner"
                />
              </div>
            </div>
            <div class="service-detail-body-side">
              <!--top-side-->
              <router-link
                v-if="preprocessingInfo"
                class="service-detail-body-side-link"
                :to="preprocessingInfo.link"
              >
                <text-with-title
                  :title="
                    $t('serviceList.popup.addNewService.PreprocessingToUse')
                  "
                  :text="preprocessingInfo && preprocessingInfo.name"
                  color="link"
                  iconName="newTab"
                  pos="right"
                />
              </router-link>
              <div class="service-detail-body-side-block">
                <div class="service-detail-body-side-inner">
                  <text-with-title :title="$t('common.createTime')">
                    <a-time :value="service.createdAt" />
                  </text-with-title>
                </div>
                <div class="service-detail-body-side-inner">
                  <text-with-title :title="$t('common.updateTime')">
                    <a-time :value="service.updatedAt" />
                  </text-with-title>
                </div>
              </div>
            </div>
            <sample-code
              class="service-detail-body-bottom"
              :apiUrl="apiUrl"
              :secretKey="service.secretKey"
              :service="service"
              :trainedAiDetail="trainedAiDetail"
            />
          </div>
          <service-edit
            v-else
            key="edit"
            :service="service"
            :serviceFormValidate="editInfo.serviceFormValidate"
            :customblockList="customblockList"
            @cancel="cancelEdit"
            @input-edit-form="$emit('input-edit-form', $event)"
            @save="$emit('save', $event)"
            @select-box-get-preprocessings="
              $emit('select-box-get-preprocessings')
            "
          />
        </transition-toggle-contents>
      </div>
      <div
        v-else-if="activeTab === 'status'"
        key="status"
        style="height: 100%"
        class="service-detail-status"
      >
        <images imageName="nowDeveloping" class="service-detail-status-image" />
        <texts :text="$t('common.nowDeveloping')" :size="20" />
      </div>
    </transition-toggle-contents>
  </div>
</template>

<script>
import { isNatualLanguage } from '@/lib/inference.js'

import texts from '@/components/atoms/text'
import images from '@/components/atoms/images'
import description from '@/components/atoms/description'
import textWithTitle from '@/components/molecules/text-with-title'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import sampleCode from '../sample-codes/infer'
import serviceEdit from '../edit/infer'

import CopyBox from '@/components/molecules/copy-box.vue'

export default {
  components: {
    texts,
    images,
    description,
    textWithTitle,
    transitionToggleContents,
    sampleCode,
    CopyBox,
    serviceEdit
  },
  data() {
    return {
      isTipsShow: ''
    }
  },
  computed: {
    apiUrl() {
      let ver = 'v1'
      // テーブルデータの分類/回帰用の推論のみ、v2を利用
      if (
        ['REGRESSION', 'CLASSIFICATION'].indexOf(this.trainedAIType) > -1 &&
        !this.checkNatualLanguage
      ) {
        ver = 'v2'
      }
      return (
        location.origin + '/api/' + ver + '/inference/' + this.service.serviceId
      )
    },
    preprocessingInfo() {
      if (this.service.preprocessing) {
        return {
          name: this.service.preprocessing.name,
          link: {
            name: 'preprocessingDetail',
            params: {
              id: this.service.preprocessing.id
            }
          }
        }
      } else {
        return null
      }
    },
    trainedAIType() {
      if (!this.service || !this.service?.trainedAI) return null
      return this.service.trainedAI.type
    },
    checkNatualLanguage() {
      if (!this.trainedAiDetail?.result) return null
      return isNatualLanguage(this.trainedAiDetail.result)
    }
  },
  props: {
    activeMenu: String,
    activeTab: String,
    editInfo: Object,
    isEditting: Boolean,
    preprocessings: {
      type: Object,
      default: () => ({})
    },
    service: Object,
    trainedAiDetail: Object,
    customblockList: Array
  },
  methods: {
    cancelEdit() {
      this.$emit('update:active-menu', '')
      if (this.$route.params.projectId) {
        this.$router.replace(
          {
            name: 'serviceProjectDetail',
            params: this.$route.params
          },
          () => {},
          () => {}
        )
      } else {
        this.$router.replace(
          {
            name: 'serviceDetail',
            params: this.$route.params
          },
          () => {},
          () => {}
        )
      }
    },
    copy() {
      this.isTipsShow = 'apiUrl'
    }
  }
}
</script>

<style lang="scss" scoped>
.service-detail-body {
  &-wrap {
    display: grid;
    grid-template-columns: adjustVW(735) 1fr;
    grid-template-rows: min-content 1fr;
    grid-gap: adjustVW(32);
    height: 100%;
    padding: $space-medium;
    margin: 0 0 $space-large;
  }
  &-main {
    grid-row: 1;
    grid-column: 1;
    &-block {
      margin: 0 0 $space-small;
    }
    &-inner {
      position: relative;
      &-title {
        margin: 0 0 $space-base;
      }
      &-tips {
        position: absolute;
        top: -100%;
        right: adjustVW(14) + $space-small;
      }
    }
  }
  &-side {
    grid-row: 1;
    grid-column: 2;
    overflow: hidden;
    &-block {
      overflow: hidden;
      display: flex;
    }
    &-inner {
      width: 100%;
    }
    &-link {
      display: block;
      width: fit-content;
      max-width: 100%;
      margin: 0 0 $space-small;
    }
    &-column {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      margin-bottom: $space-small;
      &-wrap {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        max-height: adjustVW(136);
        padding: $space-sub;
        background: $background-sub;
        border-radius: adjustVW(8);
      }
      &-title {
        margin-bottom: $space-text;
      }
      &-list {
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        grid-column-gap: $space-base;
        grid-row-gap: $space-base;
        padding-right: $space-base;
        @include scrollbar;
      }
      &-item {
        max-width: adjustVW(144);
      }
    }
  }
  &-bottom {
    grid-row: 2;
    grid-column: 1/3;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    &-tab-wrap {
      margin: 0 0 $space-small;
      &-sample-code {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }
    &-wrap {
      overflow: hidden;
      padding: $space-medium;
      background: $background-sub;
      border-radius: adjustVW(16);
    }
    &-block {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    &-inner {
      display: flex;
      align-items: center;
      height: 100%;
      margin: 0 0 $space-medium;
      &:last-child {
        margin: 0;
      }
      &-sample-code {
        height: 100%;
      }
    }
    &-item {
      width: 30%;
      height: 100%;
      margin: 0 $space-small 0 0;
      &:last-child {
        width: calc(70% - #{$space-small});
        margin: 0;
      }
      &-icon {
        display: flex;
        &-wrap {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
        }
        &-tips {
          position: absolute;
          bottom: -$space-small;
          height: 100%;
          z-index: -1;
        }
      }
      &-text {
        margin: 0 $space-small 0 0;
      }
    }
    &-select-box {
      width: adjustVW(240);
      height: adjustVH(48);
      margin: 0 $space-base $space-small;
    }
  }
}

.service-detail-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-image {
    width: adjustVW(480);
    height: auto;
    margin-bottom: $space-large;
  }
}
</style>
