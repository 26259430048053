<template>
  <div class="model-info-wrap" :class="{ 'model-info-no-wrap': isNoWrap }">
    <div class="model-info-title">
      <texts :text="title" size="large" />
      <router-link v-if="link" :to="link">
        <text-with-icon
          :text="$t('common.showDetail')"
          size="small"
          type="link"
          iconName="newTab"
          pos="right"
          color="link"
        />
      </router-link>
    </div>
    <div class="model-info-body">
      <div class="model-info-block">
        <div class="model-info-inner">
          <texts class="model-info-name" :text="modelInfo.name" isBold />
          <description :text="modelInfo.description" />
        </div>
      </div>
      <div
        v-if="setPredictionColumn && setPredictionColumn.length > 0"
        class="model-info-block"
      >
        <div class="model-info-predict-column">
          <texts
            class="model-info-predict-column-title"
            :text="$t('projectDetail.objectiveVariable')"
            size="small"
            color="gray"
          />
          <div class="model-info-predict-column-list">
            <div
              v-for="column in setPredictionColumn"
              :key="column"
              class="model-info-predict-column-item"
            >
              <texts :text="column" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="showMetrics" class="model-info-metrics-wrap">
        <texts
          v-if="Object.keys(modelInfo.metrics).length > 1"
          class="model-info-metrics-block-title"
          :text="limitMetrics[0].name"
          size="small"
          color="gray"
        />
        <div class="model-info-metrics-block">
          <div
            v-for="(metric, index) in limitMetrics"
            :key="index"
            class="model-info-metrics-item"
          >
            <texts
              size="small"
              color="gray"
              :text="
                Object.keys(modelInfo.metrics).length > 1
                  ? metric.columnName
                  : metric.name
              "
            />
            <metrics-text
              :value="metric.value"
              :percentage="metric.percentage"
            />
          </div>
        </div>
      </div>
      <div
        v-if="!showMetrics && modelInfo.type !== 'NO_ACCURACY'"
        class="model-info-metrics-none"
      >
        <texts
          :text="$t('trainedAiDetails.noAccuracy')"
          size="small"
          color="gray"
        />
      </div>
      <div v-if="modelInfo.seasonalityMode" class="model-info-block">
        <div class="model-info-inner">
          <text-with-title
            :title="$t('training.result.timeseries.title')"
            :text="
              $t('training.result.timeseries.type.' + modelInfo.seasonalityMode)
            "
          />
        </div>
      </div>
      <!--
      <div class="model-info-block" v-if="showAccuracy && modelInfo.metrics">
        <div class="model-info-inner">
          <text-with-title
            v-if='modelInfo.metrics.effect'
            :title='modelInfo.metrics.effect.name'
            :text='modelInfo.metrics.effect.value + "%"'
          />
        </div>
        <div class="model-info-inner">
          <text-with-title
            v-if='modelInfo.metrics.firstAccuracy'
            :title='modelInfo.metrics.firstAccuracy.name'
            :text='modelInfo.metrics.firstAccuracy.value + "%"'
          />
        </div>
      </div>
      -->
      <card-fi
        checkShowFeatureImportance
        :predictionColumns="setPredictionColumn"
        :featureImportance="modelInfo.featureImportance"
        :type="modelInfo.type"
        notCard
        :maxItem="4"
        minSize
      />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import description from '@/components/atoms/description'
import metricsText from '@/components/atoms/metrics-text'
import textWithIcon from '@/components/molecules/text-with-icon'
import textWithTitle from '@/components/molecules/text-with-title'
import cardFi from '@/components/organisms/card-fi'

export default {
  components: {
    texts,
    description,
    metricsText,
    textWithIcon,
    textWithTitle,
    cardFi
  },
  data() {
    return {
      isTipsShow: ''
    }
  },
  props: {
    /**
     * 学習済みAIの情報
     * name: 名前
     * description: 説明
     * featureImportance: 重要度
     * id: id
     * metrics: 精度
     * owner: 所有者
     * predictColumn: 予測する列
     * type: 学習タイプ
     * updatedAt: 最終更新日
     */
    modelInfo: Object,
    /** カードのタイトルに表示するテキスト */
    title: String,
    /** 使用していない削除予定 */
    isNoWrap: Boolean,
    /** 詳細を見るボタンのリンク先 */
    link: Object
  },
  computed: {
    showMetrics() {
      if (!this.modelInfo?.metricsList) return false
      return this.modelInfo.metricsList.length > 0
    },
    showAccuracy() {
      return (
        this.modelInfo?.metrics &&
        Object.keys(this.modelInfo.metrics).length > 0
      )
    },
    showSeasenality() {
      if (!this.$props.modelInfo?.seasenalityMode) return false
      return this.$props.modelInfo.seasenalityMode
    },
    /**
     * サービスに紐づくpredictColumnはpredictColumnsで、
     * trainConfigに紐づくpredictColumnはpredictColumn
     * 他にもtargetColumnやpredictionColumnが存在するため
     * それらの統一が必要。
     * 実装時にこのcomputedは不要になる。
     * https://trello.com/c/Ay40DFlj
     */
    setPredictionColumn() {
      if (this.modelInfo?.predictColumn) {
        return this.modelInfo.predictColumn
      } else if (this.modelInfo?.predictColumns) {
        return this.modelInfo.predictColumns
      } else if (this.modelInfo?.predictionColumns) {
        return this.modelInfo.predictionColumns
      } else {
        return []
      }
    },
    limitMetrics() {
      if (!this.modelInfo?.metricsList) return []
      return this.modelInfo.metricsList.slice(0, 6)
    }
  }
}
</script>

<style lang="scss" scoped>
.model-info {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-block {
    display: flex;
    align-items: center;
    margin: 0 0 $space-small;
  }
  &-inner {
    width: 100%;
    margin: 0 $space-medium 0 0;
    &:last-child {
      margin: 0;
    }
  }
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 $space-small;
  }
  &-body {
    overflow-x: hidden;
    overflow-y: auto;
    @include scrollbar;
  }
  &-name {
    margin: 0 0 $space-min;
  }
  &-feature-importance {
    &-title {
      margin: 0 0 $space-small;
    }
    &-wrap {
      margin: $space-large 0 0;
    }
    &-block {
      position: relative;
      width: 100%;
      padding: $space-min 0 $space-medium;
      z-index: 1;
    }
    &-inner {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0 0 $space-medium;
      &:first-child {
        margin: $space-base 0 $space-medium;
      }
      &:nth-last-child(2) {
        margin: 0 0 $space-base;
      }
    }
    &-name {
      overflow: hidden;
      width: 100%;
      margin: 0 $space-small 0 0;
    }
    &-tooltip {
      &-wrap {
        position: absolute;
        top: -200%;
        left: 50%;
        width: adjustVW(240);
        text-align: center;
        transform: translateX(-50%);
      }
      &-inner {
        width: fit-content;
        max-width: 100%;
      }
    }
    &-graph {
      min-width: adjustVW(240);
      &-inner {
        --width: 100%;
        position: relative;
        width: calc(var(--width) - #{$space-small});
        height: adjustVH(16);
        margin: 0 0 0 $space-small;
        background: $line-emphasis;
        border-radius: adjustVW(4);
      }
      &-bg {
        position: absolute;
        top: 0;
        right: 0;
        width: adjustVW(240);
        height: 100%;
        z-index: -1;
      }
      &-scale {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0 0 $space-min;
        &-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: adjustVW(32);
          height: adjustVH(24);
          color: $text-sub;
          font-size: $text-min;
          @include text-crop;
        }
      }
      &-line {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: calc(100% - #{adjustVW(24)});
        &-item {
          position: relative;
          width: adjustVW(32);
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 1px;
            height: 100%;
            background: $line-gray;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
  &-predict-column {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: adjustVW(240);
    &-title {
      flex-shrink: 0;
      margin-bottom: adjustVW(2);
    }
    &-list {
      overflow: auto;
      display: flex;
      flex-direction: column;
      grid-row-gap: adjustVW(2);
      @include scrollbar;
    }
  }
}

.model-info-metrics {
  &-wrap {
    margin-bottom: $space-medium;
  }
  &-block {
    display: flex;
    flex-wrap: wrap;
    &-title {
      margin-bottom: $space-text;
    }
  }

  &-item {
    width: calc(50% - #{$space-small});
    margin-right: $space-small;
    margin-bottom: $space-text;
  }
  &-none {
    margin-bottom: $space-medium;
  }
}

.model-info-metrics-item:nth-of-type(even) {
  margin-right: 0;
}

.model-info-metrics-item:nth-last-of-type(-n + 2) {
  margin-bottom: 0;
}
</style>
