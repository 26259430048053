<template>
  <preprocessing-detail
    v-bind="data"
    :dataset="dataset"
    :disabledEdit="disabledEdit"
    :loading="data.loading"
    :loadingInit="loadingInit"
    :notFound="notFound"
    :disableClick="submitDeleting"
    :editInfo="editInfo"
    :popup="popup"
    :preprocessingMenu="preprocessingMenuDefault"
    @apply-dataset="applyDataset($event)"
    @click-dataset="clickDataset($event)"
    @close-modal="closePopup($event)"
    @delete-menu-click="showPopup('deletePreprocessing')"
    @delete-preprocessing="deletePreprocessing()"
    @input-edit-form="inputEditForm($event)"
    @select-dataset="selectDataset()"
    @update-preprocessing-detail="doUpdatePreprocessing($event)"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { preprocValidator } from '@/lib/validator/preproc.js'
import setMountedTimer from '@/mixin/set-mounted-timer'

import preprocessingDetail from '@/components/templates/preprocessing-detail.vue'

export default {
  components: {
    preprocessingDetail
  },
  mixins: [setMountedTimer],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loadingInit = true

      await vm.$waitConnected()

      // リロード時に消えてるので、再取得
      await vm.fetchEDARecipeList()

      if (vm.projectId !== null) {
        await vm.loadProjectDataset()
        await vm.loadProjectDetail(vm.projectId)
      } else {
        throw new Error('deprecated')
      }

      if (!vm.notFoundPreprocessing) {
        await vm.loadEDARecipeDetail({
          EDARecipeId: to.params.id,
          EDARecipeAccountId: vm.EDARecipes[to.params.id].accountId
        })
      }

      vm.loadingInit = false
    })
  },
  computed: {
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('datasets', ['datasetList', 'datasetLoading']),
    ...mapGetters('preprocessings', ['EDARecipeDetail', 'EDARecipes', 'loadingEDARecipes', 'loadingEDARecipeDetail', 'loadingEDARecipeDelete']),
    ...mapGetters('project', ['projectList', 'loadingProjectList']),

    // ページ上部のメニュー
    preprocessingMenuDefault() {
      return [
        {
          id: 1,
          name: this.$t('common.deleteButton'),
          iconName: 'delete',
          value: 'delete',
          disabled: this.disabledEdit,
          disabledTips: this.$t(
            'common.disabled.organization.otherAccountItemDelete'
          )
        }
      ]
    },
    disabledEdit() {
      if (this.accountInfo?.organizationInfo?.role === 'admin') return false
      if (!this.targetEDARecipe) return true
      if (
        this.accountInfo?.accountId !== this.data.body.pageBody.info.accountId
      ) {
        return true
      } else {
        return false
      }
    },
    targetEDARecipe() {
      if (!this.EDARecipes) return null
      return this.EDARecipes[this.$route.params.id]
    },
    projectId() {
      if (this.$route.params.projectId) {
        return parseInt(this.$route.params.projectId)
      }
      return null
    },
    notFoundProject() {
      if (this.projectId === null) {
        return false
      } else {
        return !(this.projectId in this.projectList)
      }
    },
    notFoundPreprocessing() {
      return !(this.$route.params.id in this.EDARecipes)
    },
    notFound() {
      return this.notFoundProject || this.notFoundPreprocessing
    },
    datasetObject() {
      if (this.projectId) {
        return this.projectDataset
      } else {
        return this.datasetList
      }
    },
    dataset() {
      const accountId = this.accountInfo.accountId
      const result = []
      Object.entries(this.datasetObject).forEach(function (item) {
        const data = item[1]
        // structured、自分が所有者のデータだけ対象
        if (data.type === 'structured' && data.accountId === accountId) {
          result.push(data)
        }
      })
      // 最終更新日の降順
      result.sort((x, y) => (x.updateTime > y.updateTime ? -1 : 1))
      return result
    },
    recipeId: function () {
      if (this.$route.params.id) return this.$route.params.id
      return null
    },
    data() {
      return {
        // サイドバーに表示する情報
        sidebar: {
          project: {
            name: '退職予測',
            description:
              'このプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入ります'
          },
          activeLink: 'preprocessing'
        },
        // ヘッダーのタブ
        headerTabs: {
          tabs: [],
          tabSelect: 0
        },
        // ヘルプに表示される情報
        hoverMenu: {
          help: {
            searchTarget: {
              name: this.$t('help.categories.preprocessingDetail')
            },
            searchResult: [
              {
                id: 1,
                content:
                  '各種アップグレードプランの価格はどこに記載されていますか？',
                link: '#'
              },
              {
                id: 2,
                content: 'AIの新規作成はどのように進めればいいですか？',
                link: '#'
              },
              {
                id: 3,
                content:
                  '最も検索されている内容は、この検索フォームで最も検索されている５件を表示します。テキストは最大３行。',
                link: '#'
              },
              {
                id: 4,
                content:
                  '各種アップグレードプランの価格はどこに記載されていますか？',
                link: '#'
              },
              {
                id: 5,
                content:
                  '各種アップグレードプランの価格はどこに記載されていますか？'
              }
            ]
          },
          showHelp: false
        },
        body: {
          // ページ上部に表示する情報
          pageTop: {
            summary: {
              title: this.targetEDARecipe?.name,
              iconName: 'backward'
            }
          },
          pageBody: {
            preprocChart: this.targetEDARecipe,
            info: this.targetEDARecipe
          }
        },
        loading:
          this.loadingEDARecipes ||
          this.loadingEDARecipeDetail ||
          this.loadingEDARecipeDelete ||
          this.datasetLoading ||
          this.loadingProjectList ||
          this.projectDatasetLoading ||
          this.loadingUpdate
      }
    }
  },
  data() {
    return {
      editInfo: {
        preprocFormValidate: {
          duplicate: undefined
        }
      },
      popup: {
        // ポップアップの情報
        showPopup: [], // 現在表示しているポップアップ
        checkDataset: {
          name: '',
          description: ''
        }
      },
      loadingInit: false,
      submitDeleting: false,
      projectDataset: {},
      projectDatasetLoading: false,
      loadingUpdate: false
    }
  },
  methods: {
    ...mapActions('datasets', ['loadDatasetDetail']),
    ...mapActions('preprocessings', ['deleteEDARecipe', 'fetchEDARecipeList', 'loadEDARecipeDetail', 'setLoadingStatus', 'updatePreprocessing']),
    ...mapActions('project', ['loadProjectDetail']),

    async loadProjectDataset() {
      this.projectDatasetLoading = true
      try {
        const response = await this.$sendMessageAndReceive({
          action: 'getDataList',
          projectId: this.projectId
        })
        if (response.status !== 'error') {
          const obj = {}
          response.list.forEach((item) => {
            const fullId = item.id + '-' + item.accountId
            item.fullId = fullId
            obj[item.id] = item
          })
          this.projectDataset = obj
        } else {
          throw response
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.projectDatasetLoading = false
      }
    },
    showPopup(e) {
      this.popup.showPopup.push(e)
    },
    closePopup(e) {
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
    },
    selectDataset() {
      this.showPopup('selectDataset')
    },
    applyDataset(e) {
      const preprocessingId = this.$route.params.id
      const datasetId = e.id
      let name = ''
      let params = ''
      if (this.projectId) {
        name = 'preprocessingProjectApply'
        params = {
          projectId: this.projectId,
          preprocessingId: preprocessingId,
          datasetId: datasetId
        }
      } else {
        name = 'preprocessingApply'
        params = {
          preprocessingId: preprocessingId,
          datasetId: datasetId
        }
      }
      this.$router.push({
        name: name,
        params: params
      })
    },
    clickDataset(e) {
      this.popup.checkDataset = this.dataset[e.index]
      this.showPopup('checkColumn')
    },
    async deletePreprocessing() {
      this.submitDeleting = true
      try {
        await this.deleteEDARecipe({
          EDARecipeAccountId: this.targetEDARecipe?.accountId,
          EDARecipeId: this.$route.params.id
        })
      } finally {
        this.submitDeleting = false
      }

      let name = ''
      let params = ''
      if (this.projectId) {
        name = 'preprocessingProjectList'
        params = {
          projectId: this.projectId
        }
      } else {
        name = 'preprocessingList'
        params = {}
      }
      this.$router.push({
        name: name,
        params: params
      })
    },
    doUpdatePreprocessing: async function (e) {
      this.loadingUpdate = true
      try {
        await this.updatePreprocessing({
          id: this.$route.params.id,
          accountId: this.targetEDARecipe?.accountId,
          name: e.name,
          description: e.description
        })
        if (this.projectId) await this.loadProjectDetail(this.projectId)
      } finally {
        this.loadingUpdate = false
      }
    },
    inputEditForm: function (obj) {
      const { type, form } = obj

      if (type === 'preproc') {
        const edaRecipes =
          this.projectId !== null
            ? this.projectList[this.projectId].listEdaRecipe
            : []
        const edaRecipeList = edaRecipes.filter(
          (recipe) => recipe.id !== this.recipeId
        )

        this.editInfo.preprocFormValidate = preprocValidator(
          edaRecipeList,
          form
        )
      }
    }
  }
}
</script>
