<template>
  <div class="text-list">
    <div class="text-list-config">
      <config-box-text-list
        v-model="values"
        :total="total"
        :columns="columns"
        :showSentiment="showSentiment"
        :columnName="columnName"
        :waitTextMiningLoading="waitTextMiningLoading"
        @download-result="$emit('download-result', $event)"
        @change-column-name="$emit('change-column-name', $event)"
      />
    </div>
    <div class="text-list-body">
      <transition-toggle-contents>
        <div
          v-if="!waitTextMiningLoading && datas != null && datas.length > 0"
          key="contents"
          class="text-list-inner"
        >
          <template v-for="(item, index) in datas">
            <text-card-item
              v-bind="item"
              :key="index"
              :showSentiment="showSentiment"
              :showLda="showLda"
              @go-text-detail="$emit('go-text-detail', $event)"
            />
          </template>
        </div>
        <div v-else key="loading" class="loading">
          <loading />
        </div>
      </transition-toggle-contents>
    </div>
  </div>
</template>

<script>
import configBoxTextList from './config-box/text-list'
import loading from '@/components/atoms/loading'
import textCardItem from '@/components/molecules/text-mining/card-item'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import { TEXT_MINING_TYPE } from '@/lib/text-mining'

export default {
  props: {
    total: {
      type: Number,
      default: 0
    },
    columns: {
      type: Array,
      default: () => []
    },
    datas: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {}
    },
    waitTextMiningLoading: {
      type: Boolean,
      default: false
    },
    typeList: {
      type: Array,
      default: () => []
    },
    columnName: {
      type: String,
      default: ''
    }
  },
  components: {
    configBoxTextList,
    loading,
    textCardItem,
    transitionToggleContents
  },
  computed: {
    values: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    showSentiment() {
      return this.typeList.includes(TEXT_MINING_TYPE.SENTIMENTS)
    },
    showLda() {
      return this.typeList.includes(TEXT_MINING_TYPE.LDA)
    }
  }
}
</script>

<style lang="scss" scoped>
.text-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  &-body {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    padding-right: $space-base;
    @include scrollbar;
  }
  &-config {
    margin-bottom: $space-medium;
  }
  &-inner {
    display: grid;
    grid-template-columns: repeat(4, calc((100% - #{$space-small * 3}) / 4));
    grid-auto-rows: adjustVW(324);
    grid-column-gap: $space-small;
    grid-row-gap: $space-small;
  }
}
.loading {
  height: 100%;
}
</style>
