<template>
  <div class="delete-wrap">
    <div class="delete-item-title">
      <texts :text="$t('alerts.modelDownloadError.message')" isShowAll />
    </div>
    <div class="delete-button-wrap">
      <div class="delete-button-inner">
        <button-main
          :text="$t('common.close')"
          type="sub"
          :disabled="disableClick"
          @click="$emit('close-modal', 'modelDownloadError')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import buttonMain from '@/components/atoms/button-main'

export default {
  components: {
    texts,
    buttonMain
  },
  props: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.delete {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: adjustVW(724);
    height: 100%;
  }
  &-item {
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $space-base;
    }
    &-body {
      margin-bottom: $space-medium;
    }
  }
  &-button {
    &-wrap {
      display: flex;
      justify-content: flex-end;
      padding: 0 $space-small 0 0;
    }
    &-inner {
      margin: 0 $space-small 0 0;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
