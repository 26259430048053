<template>
  <label
    class="wrap"
    :class="{
      'wrap-disabled': isDisabled
    }"
  >
    <input
      class="toggle-button"
      type="checkbox"
      :class="{
        'toggle-button-on': isChecked,
        'toggle-button-wait': wait && isChecked,
        'toggle-button-caution': caution && !isChecked
      }"
      :checked="isChecked"
      :disabled="isDisabled"
      @change="updateValue($event)"
    >
    <texts
      class="text"
      :text="text"
      :color="isDisabled ? 'disabled' : 'default'"
    >
      <slot />
    </texts>
  </label>
</template>

<script>
import texts from '@/components/atoms/text'
export default {
  components: {
    texts
  },
  data() {
    return {
      // トグルボタンがonかどうか
      isChecked: this.checked ?? false
    }
  },
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    /** トグルスイッチに表示するテキスト */
    text: String,
    /** 紫のスイッチ（まだonになっていない状態） */
    wait: {
      type: Boolean,
      default: false
    },
    /** オフにしたときに警告を表示する場合 */
    caution: {
      type: Boolean,
      default: false
    },
    /** チェックしているかどうか受け取る値 */
    checked: {
      type: Boolean,
      default: false
    },
    /** 使用不可の場合 */
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateValue(e) {
      this.isChecked = e.target.checked
      this.$emit('input', this.isChecked)
      this.$emit('change', this.isChecked)
    }
  },
  watch: {
    checked(newVal) {
      this.isChecked = newVal
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  align-items: center;
  &-disabled {
    cursor: not-allowed;
  }
}
.text {
  margin: 0 0 0 $space-sub;
  user-select: none;
}
.toggle-button {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: adjustVW(44);
  height: adjustVW(24);
  background: $border-gray;
  border-radius: 9in;
  cursor: pointer;
  transition: $transition-base;
  &::after {
    content: '';
    position: absolute;
    top: adjustVW(2);
    left: adjustVW(2);
    width: adjustVW(20);
    height: adjustVW(20);
    background: $text-func;
    border-radius: 9in;
    z-index: 2;
    transition: $transition-base;
  }
  &-on {
    background-color: #049185;
    &::after {
      left: adjustVW(22);
    }
  }
  &-wait {
    background-color: $key-color;
  }
  &-caution {
    background-color: $red;
  }
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
</style>
