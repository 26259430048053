<template>
  <div v-if="trainedAi.summary" class="trained-ai-detail-info">
    <div class="trained-ai-detail-info-inner">
      <div
        v-if="
          trainedAi.summary.services && trainedAi.summary.services.length > 0
        "
        class="trained-ai-detail-info-item"
      >
        <texts
          class="trained-ai-detail-info-item-title"
          :text="$t('trainedAiDetails.linkedServices')"
          size="small"
          color="gray"
        />
        <div class="trained-ai-detail-info-item-inner">
          <!-- タブ機能が実装で来たらタブに直す。現状はリンク -->
          <button
            v-for="service in trainedAi.summary.services"
            :key="service.id + 'service'"
            class="trained-ai-detail-info-item-text"
            @click="goService(service.serviceId)"
          >
            <text-with-icon
              :text="service.name"
              iconName="newTab"
              pos="right"
              color="link-default"
            />
          </button>
        </div>
      </div>
      <div
        v-if="trainedAi.summary.datasets"
        class="trained-ai-detail-info-item"
      >
        <texts
          class="trained-ai-detail-info-item-title"
          :text="$t('trainedAiDetails.datasetUsed')"
          size="small"
          color="gray"
        />
        <div class="trained-ai-detail-info-item-inner">
          <button
            v-for="(dataset, index) in trainedAi.summary.datasets"
            :key="index + 'dataset'"
            class="trained-ai-detail-info-item-text"
            @click="goDataset(dataset.id)"
          >
            <text-with-icon
              :text="dataset.name"
              iconName="newTab"
              pos="right"
              color="link-default"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="trained-ai-detail-info-inner">
      <div class="trained-ai-detail-info-sub">
        <text-with-title
          class="trained-ai-detail-info-sub-item"
          :title="$t('common.createTime')"
        >
          <a-time :value="trainedAi.summary.createTime" />
        </text-with-title>
        <text-with-title
          class="trained-ai-detail-info-sub-item"
          :title="$t('common.updateTime')"
        >
          <a-time :value="trainedAi.summary.updateTime" />
        </text-with-title>
      </div>
      <div
        v-if="trainedAi.summary.accountId"
        class="trained-ai-detail-info-sub"
      >
        <text-with-title
          class="trained-ai-detail-info-sub-item"
          :title="$t('datasetList.datasetList.accountId')"
          :text="trainedAi.summary.accountId"
        />
      </div>
    </div>
  </div>
</template>
<script>
import texts from '@/components/atoms/text'
import textWithTitle from '@/components/molecules/text-with-title'
import textWithIcon from '@/components/molecules/text-with-icon'
export default {
  name: 'AIDetailInfo',
  components: {
    texts,
    textWithTitle,
    textWithIcon
  },
  props: {
    trainedAi: Object
  },
  methods: {
    goService(id) {
      if (this.$route.params.projectId) {
        this.$router.push({
          name: 'serviceProjectDetail',
          params: {
            projectId: this.$route.params.projectId,
            id: id,
            serviceType: 'infer'
          }
        })
      } else {
        this.$router.push({
          name: 'serviceDetail',
          params: {
            id: id,
            serviceType: 'infer'
          }
        })
      }
    },
    goDataset(id) {
      if (this.$route.params.projectId) {
        this.$router.push({
          name: 'datasetProjectDetail',
          params: {
            projectId: this.$route.params.projectId,
            id: id
          }
        })
      } else {
        this.$router.push({
          name: 'datasetDetail',
          params: {
            id: id
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.trained-ai-detail-info {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $space-small;
  background: $background;
  border-radius: adjustVW(16);
  box-shadow: $box-shadow-main;
  &-inner {
    display: flex;
    margin: 0 0 $space-small;
    &:last-child {
      flex-shrink: 0;
      margin: 0;
    }
  }
  &-item {
    display: flex;
    flex-direction: column;
    width: calc(50% - #{$space-medium});
    margin: 0 $space-medium 0 0;
    &:last-child {
      margin: 0;
    }
    &-title {
      flex-shrink: 0;
      margin: 0 0 $space-min;
    }
    &-text {
      display: block;
      width: fit-content;
      max-width: 100%;
      margin: 0 0 $space-min;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  &-sub {
    display: flex;
    width: calc(50% - #{$space-medium});
    margin: 0 $space-medium 0 0;
    &-item {
      overflow: hidden;
      margin: 0 $space-medium 0 0;
      &:last-child {
        margin: 0;
      }
    }
    &:last-child {
      margin: 0;
    }
  }
}
</style>
