<template>
  <card-base class="preview-card">
    <div class="preview-wrap">
      <div class="preview-list">
        <list-item
          class="preview-list-body"
          :field="field"
          :items="dataSourceItems"
          isSelect
          @list-click="selectPreview($event.id)"
        />
      </div>
      <div class="preview-item">
        <div>
          <texts
            :text="$t('datasetList.popup.showDatasource.sqlPreviewRaw')"
            isBold
          />
        </div>
        <div class="preview-content">
          <transition-toggle-contents>
            <div
              v-if="!loadingSqlTablePreview && items.length > 0"
              key="content"
              class="preview-content-wrap"
            >
              <bullet-list :items="items" />
            </div>
            <div
              v-else-if="items.length === 0"
              class="preview-content-wrap preview-content-wrap-none"
            >
              <texts
                class="preview-note"
                :text="$t('datasetList.popup.showDatasource.sqlPreviewNote')"
                size="small"
                color="gray"
              />
            </div>
            <div v-else key="loading" class="preview-content-wrap">
              <loading />
            </div>
          </transition-toggle-contents>
        </div>
      </div>
    </div>
  </card-base>
</template>

<script>
import loading from '@/components/atoms/loading.vue'
import cardBase from '@/components/atoms/card-base.vue'
import listItem from '@/components/molecules/list-item.vue'
import bulletList from '@/components/molecules/bullet-list.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    loading,
    cardBase,
    listItem,
    bulletList,
    transitionToggleContents
  },
  props: {
    datasourceId: Number,
    dataSourceItems: Array,
    sqlTablePreviewData: Object,
    loadingSqlTablePreview: Boolean
  },
  computed: {
    field() {
      return [
        {
          id: 0,
          key: 'name',
          label: this.$t(
            'datasetList.popup.addDataset.datasource.namePlaceholder'
          )
        },
        {
          id: 1,
          key: 'n_rows',
          label: this.$t('datasetList.popup.addDataset.datasource.nData')
        }
      ]
    },
    items() {
      if (
        this.sqlTablePreviewData?.columns == null ||
        this.sqlTablePreviewData.columns.length === 0
      )
        return []
      return this.sqlTablePreviewData.columns.map((column, index) => {
        return {
          id: index,
          text: column
        }
      })
    }
  },
  methods: {
    selectPreview(e) {
      const payload = {
        id: this.datasourceId,
        tableName: this.dataSourceItems[e].name
      }
      this.$emit('select-sql-preview-table', payload)
    }
  }
}
</script>

<style lang="scss" scoped>
.preview {
  &-card {
    height: 100%;
  }
  &-wrap {
    display: grid;
    grid-template-columns: minmax(0, 1fr) adjustVW(320);
    grid-column-gap: $space-medium;
    height: 100%;
  }
  &-list {
    overflow-y: auto;
    @include scrollbar;
    &-body {
      &::v-deep {
        .list-item-entry-name {
          overflow: hidden;
          height: auto;
        }
        .list-item-entry-name-text {
          margin-bottom: 0;
        }
        .list-item-entry-inner,
        .list-item-key-inner {
          &:not(:last-of-type) {
            margin-right: $space-sub;
          }
        }
      }
    }
  }
  &-item {
    overflow: hidden;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto minmax(0, 1fr);
    grid-row-gap: $space-base;
    height: 100%;
    padding: $space-small;
    background-color: $background-sub;
    border-radius: adjustVW(12);
  }
  &-note {
    white-space: pre-line;
  }
  &-content {
    overflow: hidden;
    height: 100%;
    &-wrap {
      overflow-y: scroll;
      height: 100%;
      padding-right: $space-base;
      @include scrollbar;
      &-none {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
