<template>
  <div class="preprocessing-list-delete-wrap">
    <div class="preprocessing-list-delete-list-wrap">
      <div class="preprocessing-list-delete-item">
        <div class="preprocessing-list-delete-title">
          <texts :text="info.name" size="large" />
          <texts
            v-if="info.service && info.service.length > 0"
            class="preprocessing-list-delete-title-caution"
            :text="$t('trainedAiDetails.popup.serviceDeleteCheck.caution')"
            color="caution"
          />
        </div>
        <preprocessing-delete-inner
          :entry="info"
          :preprocChart="preprocChart"
        />
      </div>
    </div>
    <div class="preprocessing-list-delete-button-wrap">
      <div class="preprocessing-list-delete-button-inner">
        <button-main
          :text="$t('common.cancel')"
          type="sub"
          :disabled="disableClick"
          @click="$emit('cancel-delete')"
        />
      </div>
      <div class="preprocessing-list-delete-button-inner">
        <button-main
          :text="$t('common.deleteButton')"
          type="caution"
          :disabled="disableClick"
          @click="$emit('delete-preprocessing')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import preprocessingDeleteInner from '@/components/organisms/preprocessing-common/preprocessing-delete-inner'

export default {
  components: {
    buttonMain,
    preprocessingDeleteInner
  },
  props: {
    info: Object,
    preprocChart: Object,
    disableClick: Boolean
  }
}
</script>

<style lang="scss" scoped>
.preprocessing-list-delete {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-list-wrap {
    overflow-y: auto;
    width: adjustVW(724);
    padding: 0 $space-small 0 0;
    margin: 0 0 $space-medium;
    @include scrollbar;
  }
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: $space-medium;
    margin-bottom: $space-sub;
    &-caution {
      flex-shrink: 0;
    }
  }
  &-button {
    &-wrap {
      display: flex;
      justify-content: flex-end;
    }
    &-inner {
      margin: 0 $space-small 0 0;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
