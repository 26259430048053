<template>
  <card-base class="plan-card-base-wrapper">
    <div v-if="isPlanCard" class="plan-card-name-wrapper">
      <texts :isBold="true" :text="planName" />
      <texts
        v-if="isSelected"
        class="plan-card-name-selected"
        color="gray"
        size="min"
        :text="this.$i18n.t('changePlan.planSelected')"
      />
    </div>

    <div
      v-for="(term, index) in menuColumn"
      :key="index"
      class="plan-card-menu-item-base"
      :class="{
        'plan-card-menu-item-wrapper': isPlanCard,
        'term-card-menu-item-wrapper': isTermsCard,
        'plan-card-menu-selected-item': activeTerm === term.id,
        'plan-card-menu-selected-top-item':
          activeTerm === term.id && index === 0
      }"
      @mouseover="activeItem({ id: term.id })"
    >
      <div
        class="plan-card-menu-item-base-inner"
        :class="{
          'plan-card-menu-item-base-inner-sprit-option': term.isSpritOption
        }"
      >
        <icon
          v-if="term.isIcon"
          class="plan-card-menu-item-base-icon"
          :iconName="term.value"
          size="small"
          :color="term.value === 'check' ? 'emphasis' : 'gray'"
        />

        <texts
          v-else
          class="plan-card-menu-item-base-text"
          :text="term.value"
          :color="term.color"
          :size="17"
        />

        <texts
          v-if="term.option"
          class="plan-card-menu-item-base-text"
          color="gray"
          :size="12"
          :text="'※' + term.option"
        />
      </div>
    </div>

    <div
      class="plan-card-menu-bottom-item-base"
      :class="{
        'plan-card-menu-item-wrapper': isPlanCard,
        'term-card-menu-item-wrapper': isTermsCard,
        'plan-card-menu-selected-item': activeTerm === 'price'
      }"
      @mouseover="activeItem({ id: 'price' })"
    >
      <slot />
    </div>
  </card-base>
</template>

<script>
import CardBase from '@/components/atoms/card-base.vue'
import Icon from '@/components/atoms/icon.vue'
import Texts from '@/components/atoms/text.vue'

export default {
  components: {
    CardBase,
    Icon,
    Texts
  },
  props: {
    isPlanCard: {
      type: Boolean,
      default: true
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isTermsCard: {
      type: Boolean,
      default: false
    },
    planName: String,
    menuItems: Array,
    activeTerm: String
  },
  computed: {
    menuColumn: function () {
      const menuList = this.menuItems
      const items = menuList.sort((term1, term2) => {
        if (term1.index < term2.index) return -1
        else return 1
      })

      return items
    }
  },
  methods: {
    activeItem: function ({ id }) {
      this.$emit('active-item', { id })
    }
  }
}
</script>

<style lang="scss" scoped>
.plan-card-base-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0;
}

.plan-card-name-wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: adjustVH(84);
  border-bottom: $border-sub;
}

.plan-card-name-selected {
  margin-top: $space-min;
}

.plan-card-menu-item-base {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: adjustVH(84);
  padding: 0 $space-small;
  background: $background;
  transition: background-color $transition-base;
  &-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 $space-base;
    border-bottom: $border-sub;
    .term-card-menu-item-wrapper & {
      align-items: flex-start;
    }
    &-sprit-option {
      display: grid;
      flex-direction: row;
      align-items: center;
      grid-template-columns: 50% calc(50% - #{$space-medium + adjustVW(28)});
      grid-column-gap: $space-medium;
      > .plan-card-menu-item-base-icon {
        justify-content: flex-end;
        + .plan-card-menu-item-base-text {
          margin-top: 0;
          line-height: 1.3;
          white-space: pre-line;
        }
      }
    }
  }
  &-icon + .plan-card-menu-item-base-text {
    margin-top: $space-base;
    margin-bottom: adjustVW(-1);
  }
}

.plan-card-menu-selected-item {
  background: $key-sub;
}

.plan-card-menu-item-wrapper {
  align-content: center;
  align-items: center;
}

.plan-card-menu-bottom-item-base {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: adjustVH(104);
  padding: 0 $space-medium;
  border-bottom-right-radius: adjustVW(16);
  border-bottom-left-radius: adjustVW(16);
  background: transparent;
  transition: background-color $transition-base;
}
</style>
