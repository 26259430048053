<!--
  データセットとして画像やテキストを表示する場合のテーブル
-->
<template>
  <div class="list-body">
    <div class="list-name-wrap">
      <div class="list-name-block">
        <div
          v-for="title in field"
          :key="title.index"
          class="list-name-inner"
          :class="'list-name-inner-' + title.thClass"
        >
          <div v-if="!title.sortable" class="list-name-item">
            <texts :text="title.label" />
          </div>
          <button v-else class="list-name-item">
            <texts :text="title.label" />
          </button>
        </div>
      </div>
    </div>
    <div v-if="type === 'text'" class="list-data-wrap">
      <div v-for="item in items" :key="item.id" class="list-data-block">
        <div class="list-data-inner">
          <div class="list-data-item">
            <texts :text="item.name" />
          </div>
        </div>
        <div class="list-data-inner">
          <div class="list-data-item">
            <div class="list-data-item-count">
              <texts :text="item.count" />
              <texts
                :text="Math.ceil((item.count / maxCount) * 100) + '%'"
                size="small"
                color="gray"
              />
            </div>
            <graph-bar-count
              class="list-data-item-count-bar"
              :count="item.count"
              :maxCount="maxCount"
            />
          </div>
        </div>
        <div
          class="list-data-inner list-data-inner-main"
          @mouseleave="$emit('close-popup')"
        >
          <!-- APIの改修が終わるまで除外<div class="list-data-inner-main-control list-data-inner-main-control-prev">
            <icons iconName="dummy" size="small"/>
          </div>-->
          <div class="list-data-inner-main-list list-data-inner-main-list-text">
            <button
              v-for="(text, index) in item.datas"
              :key="text.index"
              class="list-data-inner-main-text-wrap"
              @click="
                $emit('data-text-click', {
                  labelId: item.id,
                  textId: index,
                  labelCount: items.length
                })
              "
            >
              <div class="list-data-inner-main-circle" />
              <texts
                class="list-data-inner-main-text"
                :text="text.body"
                size="small"
                :color="
                  item.id === popupContents.labelId &&
                    index === popupContents.textId &&
                    isPopupShow
                    ? 'emphasis'
                    : 'default'
                "
                noTips
              />
              <popup-balloon-text
                class="
                  list-data-inner-main-balloon list-data-inner-main-balloon-text
                "
                :popupContents="popupContents"
                :showPopup="
                  isPopupShow &&
                    popupContents.textId === index &&
                    item.id === popupContents.labelId
                "
                :posBottom="
                  popupContents.labelId > 2 &&
                    popupContents.labelCount - popupContents.labelId < 4
                "
                :class="{
                  'list-data-inner-main-balloon-text-bottom':
                    popupContents.labelId > 2 &&
                    popupContents.labelCount - popupContents.labelId < 4
                }"
              />
            </button>
          </div>
          <!-- APIの改修が終わるまで除外<div class="list-data-inner-main-control list-data-inner-main-control-next">
            <icons iconName="dummy"  size="small"/>
          </div>-->
        </div>
      </div>
    </div>
    <div v-else-if="type === 'image'" class="list-data-wrap">
      <div
        v-for="item in items"
        :key="item.id"
        class="list-data-block"
        @mouseleave="$emit('close-popup')"
      >
        <div class="list-data-inner">
          <div class="list-data-item">
            <texts :text="item.name" />
          </div>
        </div>
        <div class="list-data-inner">
          <div class="list-data-item">
            <div class="list-data-item-count">
              <texts :text="item.count" />
              <texts
                :text="Math.ceil((item.count / maxCount) * 100) + '%'"
                size="small"
                color="gray"
              />
            </div>
            <graph-bar-count
              class="list-data-item-count-bar"
              :count="item.count"
              :maxCount="maxCount"
            />
          </div>
        </div>
        <div class="list-data-inner list-data-inner-main">
          <!-- APIの改修が終わるまで除外<div class="list-data-inner-main-control list-data-inner-main-control-prev">
            <icons iconName="dummy" size="small"/>
          </div>-->
          <div
            class="list-data-inner-main-list list-data-inner-main-list-image"
          >
            <button
              v-for="(image, index) in item.datas"
              :key="image.index"
              class="list-data-inner-main-image"
              @click="
                $emit('data-image-click', {
                  labelId: item.id,
                  imageId: index,
                  labelCount: items.length
                })
              "
            >
              <image-wrap :url="image.body" />
              <popup-balloon-image
                class="list-data-inner-main-balloon"
                :showPopup="
                  isPopupShow &&
                    popupContents.imageId === index &&
                    item.id === popupContents.labelId
                "
                :popupContents="popupContents"
                :posRight="popupContents.imageId > 2"
                :posBottom="
                  popupContents.labelId > 2 &&
                    popupContents.labelCount - popupContents.labelId < 4
                "
                :style="{ '--image-position': popupContents.imageId % 5 }"
                :class="{
                  'list-data-inner-main-balloon-right':
                    popupContents.imageId > 2
                }"
              />
            </button>
          </div>
          <!-- APIの改修が終わるまで除外<div class="list-data-inner-main-control list-data-inner-main-control-next">
            <icons iconName="dummy"  size="small"/>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import graphBarCount from '@/components/atoms/graph-bar-count'
import imageWrap from '@/components/atoms/image-wrap'
import popupBalloonImage from '@/components/molecules/popup-balloon-image'
import popupBalloonText from '@/components/molecules/popup-balloon-text.vue'

export default {
  components: {
    texts,
    graphBarCount,
    imageWrap,
    popupBalloonImage,
    popupBalloonText
  },
  props: {
    /** テーブルのラベル */
    field: Array,
    /** テーブル本体 */
    items: Array,
    /** 列ごとのデータの件数の最大値 */
    maxCount: Number,
    /** 画像か自然言語かの判定 */
    type: String,
    /** 画像やテキストをクリックした際に表示されるポップアップ */
    popupContents: Object,
    /** ポップアップが表示されているかどうか */
    isPopupShow: Boolean
  }
}
</script>

<style lang="scss" scoped>
.list {
  &-body {
    position: relative;
    height: 100%;
    background: $background;
    border-radius: adjustVW(16);
  }
  &-name {
    &-wrap {
      position: sticky;
      top: 0;
      height: adjustVH(80);
      padding: 0 $space-sub 0 0;
      border-bottom: $border-title-gray;
      background: $background;
      border-radius: adjustVW(16) adjustVW(16) 0 0;
      z-index: 100;
    }
    &-block {
      display: grid;
      grid-template-columns: adjustVW(160) adjustVW(160) 1fr;
      grid-gap: $space-medium;
      padding: 0 $space-base;
    }
    &-item {
      display: block;
      width: 100%;
      padding: $space-small 0;
      text-align: left;
      .list-name-item-center & {
        text-align: center;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  &-data {
    &-wrap {
      overflow-y: auto;
      height: calc(100% - #{adjustVH(80)} - #{$space-small});
      padding: 0 $space-sub 0 0;
      margin: $space-small 0;
      @include scrollbar;
    }
    &-block {
      position: relative;
      display: grid;
      align-items: center;
      grid-template-columns: adjustVW(160) adjustVW(160) calc(
          100% - #{adjustVW(160 * 2) + $space-medium * 2}
        );
      width: 100%;
      padding: 0 $space-base;
      margin: 0 0 $space-small;
      border-bottom: $border-sub;
      transition: $transition-base;
      grid-gap: $space-medium;
      &:last-child {
        margin: 0;
        border: none;
      }
    }
    &-inner {
      display: flex;
      align-items: center;
      height: 100%;
      padding-bottom: $space-small;
      &-main {
        display: flex;
        align-items: center;
        width: 100%;
        &-list {
          &-image {
            display: flex;
          }
          &-text {
            width: 100%;
          }
        }
        &-control {
          &-prev {
            margin: 0 $space-small 0 0;
          }
          &-next {
            margin: 0 0 0 $space-small;
          }
        }
        &-circle {
          flex-shrink: 0;
          width: adjustVW(12);
          height: adjustVW(12);
          margin: 0 $space-sub 0 0;
          background: $key-color;
          border-radius: 9in;
        }
        &-item {
          display: flex;
          align-items: center;
          margin: 0 0 $space-base;
          &:last-child {
            margin: 0;
          }
        }
        &-text-item {
          width: 100%;
        }
        &-text-wrap {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          margin: 0 0 $space-base;
          text-align: left;
          &:last-of-type {
            margin: 0;
          }
          &:hover {
            opacity: 1;
          }
        }
        &-image {
          position: relative;
          width: adjustVW(132);
          height: adjustVW(74);
          margin: 0 $space-large 0 0;
          &:last-of-type {
            margin: 0;
          }
          &:hover {
            opacity: 1;
          }
        }
        &-balloon {
          --image-position: 0;
          left: 0;
          &-right {
            right: 0;
            left: inherit;
          }
          &-text {
            top: adjustVW(40) + $space-text;
            &-bottom {
              top: inherit;
              bottom: adjustVW(40) + $space-text;
            }
          }
        }
      }
    }
    &-item {
      display: block;
      width: 100%;
      padding: $space-small 0;
      text-align: left;
      &-center {
        text-align: center;
      }
      &-count {
        display: flex;
        justify-content: space-between;
        margin: 0 0 $space-base;
        &-bar {
          z-index: 1;
        }
      }
    }
    &-popup {
      &-images {
        &-wrap {
          --imagePostition: 0;
          position: absolute;
          top: calc(100% + #{$space-small});
          background: $background;
          border-radius: adjustVW(16);
          box-shadow: $box-shadow-hover;
          z-index: 10;
          &-right {
            left: auto;
          }
          &-bottom {
            top: auto;
            bottom: calc(100% + #{$space-small * 2});
          }
        }
        &-inner {
          padding: $space-medium;
          background: $background;
          border-radius: adjustVW(16);
          &::after {
            content: '';
            position: absolute;
            top: adjustVW(-20);
            left: adjustVW(132) / 2 - adjustVW(20);
            width: adjustVW(40);
            height: adjustVW(40);
            background: $background;
            border-radius: adjustVW(4);
            box-shadow: $box-shadow-hover;
            z-index: -1;
            transform: rotate(45deg);
            .list-data-popup-images-wrap-right & {
              right: adjustVW(132) / 2 - adjustVW(20);
              left: auto;
            }
            .list-data-popup-images-wrap-bottom & {
              top: auto;
              bottom: adjustVW(-20);
            }
          }
        }
        &-image {
          &-block {
            width: adjustVW(400);
            height: adjustVW(225);
            margin: 0 0 $space-small;
          }
        }
        &-text {
          &-block {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          &-inner {
            display: flex;
            > div {
              margin: 0 $space-small 0 0;
              &:last-child {
                margin: 0;
              }
            }
          }
        }
      }
      &-texts {
        &-wrap {
          position: absolute;
          top: calc(100% + #{$space-small});
          left: calc(
            #{$space-base + adjustVW(160) + adjustVW(160) + ($space-medium * 2) +
              adjustVW(20) + $space-small}
          );
          background: $background;
          border-radius: adjustVW(16);
          box-shadow: $box-shadow-hover;
          z-index: 10;
          &-bottom {
            top: auto;
            bottom: calc(100% + #{$space-small * 2});
          }
        }
        &-inner {
          padding: $space-medium;
          background: $background;
          border-radius: adjustVW(16);
          &::after {
            content: '';
            position: absolute;
            top: adjustVW(-20);
            left: adjustVW(132) / 2 - adjustVW(20);
            width: adjustVW(40);
            height: adjustVW(40);
            background: $background;
            border-radius: adjustVW(4);
            box-shadow: $box-shadow-hover;
            z-index: -1;
            transform: rotate(45deg);
            .list-data-popup-texts-wrap-bottom & {
              top: auto;
              bottom: adjustVW(-20);
            }
          }
        }
        &-main {
          &-block {
            overflow-y: auto;
            max-height: adjustVH(320);
            margin: 0 0 $space-medium;
            @include scrollbar;
          }
          &-inner {
            padding: 0 $space-base 0 0;
          }
        }
        &-text {
          &-block {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          &-inner {
            display: flex;
            > div {
              margin: 0 $space-small 0 0;
              &:last-child {
                margin: 0;
              }
            }
          }
        }
      }
      &-enter {
        opacity: 0;
        transform: translateY(-$space-small);
        &-active {
          transition: opacity $transition-base,
            transform ease-in-out $transition-base;
        }
        &-to {
          opacity: 1;
          transform: translateY(1);
        }
      }
      &-leave {
        opacity: 1;
        transform: translateY(1);
        &-active {
          transition: opacity $transition-base,
            transform ease-in-out $transition-base;
        }
        &-to {
          opacity: 0;
          transform: translateY(-$space-small);
        }
      }
    }
  }
}
</style>
