<template>
  <div class="apply-wrap">
    <div class="apply-side">
      <div class="apply-side-inputbox">
        <input-box-validation
          v-model="name"
          :accept="validate.accept"
          :error="validate.error"
          :placeholder="
            $t('preprocessing.applyingDataset.preprocessingNamePlaceholder')
          "
          :title="$t('preprocessing.applyingDataset.preprocessingNameToSave')"
          popupView
          isNameValidation
          :nameValidationSuggests="preprocFormValidate.suggests"
        />
      </div>
      <div class="apply-side-inputbox">
        <input-box
          v-model="description"
          isGray
          isTextArea
          :placeholder="
            $t(
              'preprocessing.applyingDataset.preprocessingDescriptionPlaceholder'
            )
          "
          :title="
            $t('preprocessing.applyingDataset.preprocessingDescriptionToSave')
          "
        />
      </div>
      <button-large
        class="apply-side-button"
        :isDisabled="name === '' || validate.error || disableClick"
        :text="$t('preprocessing.applyingDataset.savePreprocessingButton')"
        :type="'emphasis'"
        @click="
          $emit('save-preprocessing', { name: name, description: description })
        "
      />
    </div>
    <div class="apply-list">
      <div class="apply-list-inner">
        <preprocessing-chart-list ref="preprocessingList" :layers="layers" />
      </div>
    </div>
  </div>
</template>

<script>
import { getLayers } from '@/lib/preprocessing'

import buttonLarge from '@/components/atoms/button-large'
import inputBox from '@/components/molecules/input-box'
import inputBoxValidation from '@/components/molecules/input-box-validation.vue'
import preprocessingChartList from '@/components/molecules/preprocessing-chart-list'

export default {
  components: {
    buttonLarge,
    inputBox,
    inputBoxValidation,
    preprocessingChartList
  },
  data() {
    const edaRecipeNameSuffix = this.autoPreprocessingApplied
      ? this.$t('preprocessing.popup.edaRecipe.appliedAutoPreprocessingSuffix')
      : ''
    const edaRecipeName = this.datasetName + edaRecipeNameSuffix

    return {
      name: edaRecipeName,
      description: '',
      validate: {
        accept: null,
        error: null
      }
    }
  },
  props: {
    appliedChart: Object,
    autoPreprocessingApplied: {
      type: Boolean,
      default: false
    },
    datasetName: String,
    preprocFormValidate: Object,
    preProcHeadVersion: Number,
    disableClick: Boolean
  },
  mounted: function () {
    this.$emit('input-create-form', {
      type: 'preproc',
      form: {
        name: this.name
      }
    })
  },
  computed: {
    layers() {
      return getLayers(this.appliedChart)
    }
  },
  methods: {
    closePreprocessingDetail() {
      this.$refs.preprocessingList.popupClose()
    }
  },
  watch: {
    preprocFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    },
    name(val) {
      this.$emit('input-create-form', {
        type: 'preproc',
        form: {
          name: val
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.apply {
  &-wrap {
    display: grid;
    grid-template-areas: 'side list';
    grid-template-columns: 1fr adjustVW(1064);
    grid-template-rows: 100%;
    grid-column-gap: $space-medium;
    width: adjustVW(1632 - 24 * 2); // 左右幅引いてます
    height: 100%;
  }
  &-side {
    height: adjustVH(828);
    padding: 0 $space-base 0 0;
    grid-area: side;
    @include scrollbar;
    &-text {
      padding: 0 0 $space-sub;
    }
    &-inputbox {
      margin: 0 0 $space-medium;
    }
    &-button {
      width: 100%;
    }
  }
  &-list {
    grid-area: list;
    padding: $space-small $space-base $space-small $space-small;
    background: $background-sub;
    border-radius: adjustVW(16);
    &-inner {
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      height: 100%;
      padding: adjustVW(20) $space-base $space-base 0;
      @include scrollbar;
    }
  }
}
</style>
