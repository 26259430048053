import axios from 'axios'
export async function sendVerifyEMail(payload) {
  const res = await axios.post('/api/send_verify', {})
  return res.data
}
export async function sendVerifyToken({ token }) {
  const res = await axios.post('/api/email_verified', {
    token
  })
  return res.data
}
export async function sendVerifySAMLCode({ code, orgId }) {
  const res = await axios.post('/api/callback', { code, org_id: orgId })
  return res.data
}
export async function sendPasswordReset({ email }) {
  const res = await axios.post('/api/send_pw_reset', {
    email: email
  })
  return res.data
}
export async function sendNewPassword({ token, password, passwordConfirm }) {
  const res = await axios.post('/api/password_reset', {
    token,
    password,
    password_confirm: passwordConfirm
  })
  return res.data
}
export function validatePassword(password) {
  if (password.length < 8) return false
  if (password.length > 72) return false
  if (!/[0-9]/.test(password)) return false
  if (!/[A-Za-z]/.test(password)) return false
  return true
}
export function errorPassword(password) {
  if (password.length < 8) return this.$t('signup.error.password')
  if (password.length > 72) return this.$t('signup.error.passwordTooLong')
  if (!/[0-9]/.test(password))
    return this.$t('passwordReset.errors.mustContainAlphabet')
  if (!/[A-Za-z]/.test(password))
    return this.$t('passwordReset.errors.mustContainNumber')
  return null
}
