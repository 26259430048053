var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-card-contents",class:{
    'list-card-contents-optimization': _vm.checkOptimization
  }},[(!_vm.isPopup)?_c('div',{staticClass:"list-card-contents-title"},[_c('texts',{staticClass:"list-card-contents-title-main",attrs:{"text":'#' + _vm.id + '\xa0' + _vm.trainedAi.name,"size":"big"}}),(_vm.id === 1 && _vm.recipeType !== 'TIME' && _vm.sortedFlag)?[_c('icons',{staticClass:"list-card-contents-title-icon",attrs:{"iconName":"trainTop","color":"gray"}}),_c('texts',{staticClass:"list-card-contents-title-desc",attrs:{"text":_vm.$t('training.result.best.badge'),"size":"small","color":"gray"}})]:(_vm.recipeType === 'TIME')?_c('div',{staticClass:"list-card-contents-title-timeseries"},[(_vm.trainedAi.result.seasonality_mode)?_c('texts',{attrs:{"text":_vm.$t(
            'training.result.timeseries.' + _vm.trainedAi.result.seasonality_mode
          ),"color":"gray","size":"small"}}):_vm._e()],1):_vm._e()],2):_vm._e(),_c('div',{staticClass:"list-card-contents-main",class:{ 'list-card-contents-main-popup': _vm.isPopup }},[_c('div',{staticClass:"list-card-contents-info"},[(_vm.checkOptimization)?_c('div',{staticClass:"list-card-contents-metrics-wrap"},[_c('optimization-list-card-contents',{attrs:{"latestOptimizationConditions":_vm.trainedAi.summary.latestOptimizationConditions,"optimizationColumns":_vm.trainedAi.summary.optimizationColumns}})],1):(
          _vm.trainedAi.summary.metrics &&
            Object.keys(_vm.trainedAi.summary.metrics).length > 0
        )?_c('div',{staticClass:"list-card-contents-metrics-wrap"},[(
            _vm.trainedAi.summary.predictionColumns &&
              _vm.trainedAi.summary.predictionColumns.length > 1
          )?[_c('div',{staticClass:"list-card-contents-metrics-multi"},[_c('texts',{staticClass:"list-card-contents-metrics-multi-title",attrs:{"text":_vm.fixMetrics[0].name,"size":"large"}}),_c('div',{staticClass:"list-card-contents-metrics-multi-list"},_vm._l((_vm.limitMetrics),function(metric){return _c('metrics',{key:metric.columnName,staticClass:"list-card-contents-metrics-multi-item",attrs:{"name":metric.columnName,"value":metric.value,"percentage":metric.percentage,"isMinTitle":""}})}),1)],1)]:[('expectedEffect' in _vm.trainedAi.summary.metrics[0])?_c('div',{staticClass:"list-card-contents-metrics-inner"},[_c('texts',{attrs:{"text":_vm.trainedAi.summary.metrics.expectedEffectName,"size":40,"isBold":""}}),_c('texts',{attrs:{"text":_vm.trainedAi.summary.metrics.expectedEffect,"color":"emphasis","size":"large"}})],1):_vm._e(),_vm._l((_vm.fixMetrics),function(metric,index){return _c('metrics',{key:index,staticClass:"list-card-contents-metrics-inner",attrs:{"name":metric.name,"value":metric.value,"percentage":metric.percentage,"isCard":""}})}),_c('div',{staticClass:"list-card-contents-metrics-other"},_vm._l((_vm.otherMetrics),function(metrics,index){return _c('div',{key:index,staticClass:"list-card-contents-metrics-other-inner"},[_c('texts',{staticClass:"list-card-contents-metrics-other-name",attrs:{"text":metrics.name,"size":"small","color":"emphasis"}}),_c('metrics-text',{staticClass:"list-card-contents-metrics-other-count",attrs:{"value":metrics.value,"percentage":metrics.percentage,"size":"small"}})],1)}),0)]],2):_vm._e(),(_vm.trainedAi.summary.featureImportance && !_vm.checkOptimization)?_c('div',{staticClass:"list-card-contents-feature-wrap",class:{
          'list-card-contents-feature-wrap-none':
            !_vm.trainedAi.summary.featureImportance
        }},[_c('div',{staticClass:"list-card-contents-feature-inner"},[_c('feature-importance',{attrs:{"maxItem":4,"predictionColumns":_vm.trainedAi.summary.predictionColumns,"selectedColumnIndex":_vm.selectedColumnIndex,"featureImportance":_vm.trainedAi.summary.FeatureImportance,"notCard":"","checkShowFeatureImportance":"","type":_vm.trainedAi.summary.type}})],1)]):_vm._e()]),(!_vm.isPopup)?_c('div',{staticClass:"list-card-contents-recipe"},[_c('recipe-block-list-vertical',{staticClass:"list-card-contents-recipe-inner",attrs:{"blocks":_vm.trainedAi.result.layer_info}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }