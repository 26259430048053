<template>
  <div class="recipe-block-list">
    <recipe-block
      v-for="block in blocks"
      :key="block.id"
      class="recipe-block-item"
      :block="block"
    />
  </div>
</template>

<script>
import recipeBlock from '@/components/atoms/recipe-block'

export default {
  components: {
    recipeBlock
  },
  props: {
    /** 学習に使用したレシピ getTrainedAIDetail で取得した result.layer_info */
    blocks: Array
  }
}
</script>

<style lang="scss" scoped>
.recipe-block-list {
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  @include scrollbar;
}

.recipe-block-item {
  background: $background;
  &::after {
    right: -1000%;
    width: 1000%;
    z-index: 0;
  }
  &:last-of-type {
    &::after {
      content: '';
      right: calc(-1000% - 1px);
      height: 3px;
      background: $background;
    }
  }
}
</style>
