<template>
  <div
    class="inference-menu-wrap"
    :class="{
      'inference-menu-wrap-optimization':
        isOptimization && this.$data.menuId === 3
    }"
  >
    <div class="inference-menu-navi-wrapper">
      <div class="inference-menu-navi">
        <setting-navi
          :disableStartInferece="disableStartInferece"
          :choosedDatasetInfo="choosedDatasetInfo"
          :choosedPreprocessInfo="choosedPreprocessInfo"
          :activeMenu="menuId"
          :availableSteps="availableSteps"
          :preprocessingAvailable="preprocessingAvailable"
          :isTimeSeries="isTimeSeries"
          :runInference="runInference"
          :inferenceErrors="inferenceErrors"
          :inferenceWarnings="inferenceWarnings"
          :lackedColumns="checkColumns.lackedColumns"
          :lackedPreprocessingColumns="checkColumns.lackedPreprocessingColumns"
          :selectedItems="selectedItems"
          :selectedTrainedAiType="selectedTrainedAiType"
          :disableOptimization="disableOptimization"
          :optimizationErrorMessage="optimizationErrorMessage"
          :optimizationErrorManual="optimizationErrorManual"
          @click="changeMenu"
          @start-inference="startInference"
          @start-optimization="$emit('start-optimization')"
          @show-inference-enveiroment-setting="
            $emit('show-inference-enveiroment-setting', $event)
          "
        />
      </div>
    </div>

    <div class="inference-menu-body-wrapper">
      <div class="inference-menu-body">
        <transition-toggle-contents @after-enter="afterEnter">
          <div
            v-if="selectedItems.waitSelectItem"
            class="inference-menu-loading"
          >
            <loading />
          </div>
          <select-trained-ai
            v-else-if="this.$data.menuId === 0"
            class="inference-menu-item"
            :project="project"
            :items="sortedTrainedAi"
            :field="trainedAiField"
            :selectedTrainedAiType="selectedTrainedAiType"
            @list-click="getTrainedAi"
            @change-trained-ai-tab="changeTrainedAiTab"
          />
          <select-preprocess
            v-else-if="this.$data.menuId === 1"
            class="inference-menu-item"
            :items="preprocessingListWithNull"
            :field="preprocessingsField"
            @list-click="getPreprocess"
          />
          <select-dataset
            v-else-if="this.$data.menuId === 2"
            class="inference-menu-item"
            :items="datasetList"
            :field="datasetsField"
            :preprocessingColumns="preprocessingColumns"
            :preprocessingAvailable="preprocessingAvailable"
            :requiredColumns="requiredColumns"
            :selectedDataset="selectedItems.dataset"
            :datasetSelectAvailable="datasetSelectAvailable"
            :timeTransformerV2Settings="timeTransformerV2Settings"
            :fileError="fileError"
            @list-click="getDataset"
            @on-file-input="uploadFile"
          />
          <select-inference-type
            v-else-if="this.$data.menuId === 3"
            class="inference-menu-item"
            :selectInferenceType="selectInferenceType"
            :inferenceTypeOptions="inferenceTypeOptions"
            :trainedAi="selectedTrainedAi"
            :trainedAiDetail="trainedAiDetail"
            :trainedAiDetailFullInfo="trainedAiDetailFullInfo"
            :loadColumnInfo="loadColumnInfo"
            :requiredColumns="requiredColumns"
            :preprocessingColumns="preprocessingColumns"
            :choosedDatasetInfo="choosedDatasetInfo"
            :choosedPreprocessInfo="choosedPreprocessInfo"
            :lackedColumns="checkColumns.lackedColumns"
            :lackedPreprocessingColumns="
              checkColumns.lackedPreprocessingColumns
            "
            :isOptimization="isOptimization"
            :optimizationInfo="optimizationInfo"
            :optimizationConditionsList="optimizationConditionsList"
            :disableOptimization="disableOptimization"
            @select-item="getInferenceType"
            @set-settings="setInferenceSettings"
            @input-clustering-setting="
              $emit('input-clustering-setting', $event)
            "
            @input-multi-inference-column="
              $emit('input-multi-inference-column', $event)
            "
            @set-optimization="setOptimizationSettings"
            @check-expressions="$emit('check-expressions', $event)"
            @get-optimization-conditions-list="
              $emit('get-optimization-conditions-list')
            "
            @show-optimization-tutorial="$emit('show-optimization-tutorial')"
            @reset-optimization-settings="$emit('reset-optimization-settings')"
            @change-trained-ai-detail="
              $emit('change-trained-ai-detail', $event)
            "
          />
        </transition-toggle-contents>
      </div>
    </div>
  </div>
</template>

<script>
import loading from '@/components/atoms/loading'
import settingNavi from '@/components/organisms/inference/menu/setting-navi.vue'
import selectDataset from '@/components/organisms/inference/menu/select-dataset.vue'
import selectInferenceType from '@/components/organisms/inference/menu/select-inference-type.vue'
import selectTrainedAi from '@/components/organisms/inference/menu/select-trained-ai.vue'
import selectPreprocess from '@/components/organisms/inference/menu/select-preprocess.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import {
  inferenceTypeOptions,
  preprocessAvailable,
  datasetAvailable,
  isNatualLanguage,
  datasetSelectAvailable
} from '@/lib/inference'
import { getSamples } from '@/lib/csvPreview.js'

export default {
  components: {
    loading,
    selectDataset,
    selectInferenceType,
    selectPreprocess,
    selectTrainedAi,
    settingNavi,
    transitionToggleContents
  },
  data() {
    const checkAllOptimization = this.trainedAiList.some((item) => {
      return !item?.is_optimization
    })
    return {
      checked: [],
      menuId: 0,
      preview: null,
      fileError: null,
      selectedTrainedAiType:
        !checkAllOptimization || this.optimizationInfo.firstSelectOptimization
          ? 'optimization'
          : 'inference'
    }
  },
  mounted() {
    this.goNextStep()
  },
  props: {
    accountInfo: Object,
    project: Object,
    selectedItems: Object,

    trainedAiList: Array,
    datasetList: Array,
    preprocessingList: Array,

    trainedAiDetail: Object,
    trainedAiDetailFullInfo: Object,
    loadColumnInfo: Boolean,

    inferenceType: Object,

    preprocessingColumns: Object,
    requiredColumns: Array,

    runInference: Boolean,
    disableStartInferece: Boolean,

    inferenceErrors: Object,
    inferenceWarnings: Array,

    optimizationInfo: Object,
    optimizationConditionsList: Array,
    disableOptimization: Array,
    optimizationErrorMessage: String,
    optimizationErrorManual: String,

    reset: Boolean
  },
  computed: {
    trainedAiField() {
      return {
        trainedAi: [
          {
            index: 0,
            key: 'summary',
            label: this.$t('trainedAiList.list.name'),
            width: 424
          },
          {
            index: 2,
            key: 'metrics',
            label: this.$t('trainedAiDetails.tabs.metrics'),
            width: 280
          },
          {
            index: 3,
            key: 'featureImportance',
            label: this.$t('trainedAiList.list.featureImportance'),
            width: 352
          }
        ],
        optimization: [
          {
            index: 0,
            key: 'summary',
            label: this.$t('trainedAi.optimization.result.list.name'),
            width: 280
          },
          {
            index: 1,
            key: 'createTime',
            label: this.$t('common.createTime'),
            width: 144
          },
          {
            index: 2,
            key: 'conditions',
            label: this.$t('trainedAi.optimization.latestHistory'),
            width: 1108
          }
        ]
      }
    },
    preprocessingsField() {
      return [
        {
          index: 0,
          key: 'summary',
          label: this.$t('inference.preprocessingNameAndDescription'),
          width: 464
        },
        {
          index: 1,
          key: 'layers',
          label: this.$t('inference.preprocessingDetail'),
          width: 674
        }
      ]
    },
    datasetsField() {
      return [
        {
          index: 0,
          key: 'summary',
          label: this.$t('datasetList.datasetList.datasetName'),
          width: 366
        },
        {
          index: 1,
          key: 'nData',
          label: this.$t('datasetList.nData'),
          width: 106
        },
        {
          index: 2,
          key: 'columns',
          label: this.$t('inference.settings.selectDataset.includeedColumuns'),
          width: 276
        }
      ]
    },
    preprocessingListWithNull() {
      return [
        {
          summary: {
            name: this.$t('preprocessing.noPreprocessing'),
            description: ''
          },
          fullId: null,
          id: null,
          layers: []
        },
        ...this.preprocessingList
      ]
    },
    nextStep() {
      if (!this.selectedItems.trainedAi) {
        return 0
      }
      if (
        !this.selectedItems?.noPreprocess &&
        this.preprocessingAvailable &&
        !this.selectedItems?.preprocess &&
        !this.isOptimization
      ) {
        return 1
      }
      if (
        this.datasetAvailable &&
        this.selectedItems?.dataset == null &&
        !this.isOptimization
      ) {
        return 2
      }
      return 3
    },
    availableSteps() {
      const res = [0]
      if (this.selectedItems.trainedAi) {
        if (this.preprocessingAvailable) {
          res.push(1)
        }
        if (!this.isTimeSeries && !this.isOptimization) {
          res.push(2)
        }
        if (
          this.selectedItems.dataset ||
          this.isTimeSeries ||
          !this.isOptimization
        ) {
          res.push(3)
        }
      }
      return res
    },
    preprocessingAvailable() {
      if (!this.selectedItems.trainedAi) return null
      if (!this.trainedAiDetail) return null
      return preprocessAvailable(
        this.selectedItems.trainedAi,
        this.trainedAiDetail
      )
    },
    datasetAvailable() {
      if (!this.selectedItems.trainedAi) return null
      return datasetAvailable(this.selectedItems.trainedAi)
    },
    datasetSelectAvailable() {
      return (
        datasetSelectAvailable(this.selectedItems.trainedAi) &&
        !this.checkNaturalLanguages
      )
    },
    checkNaturalLanguages() {
      if (!this.trainedAiDetail || !this.trainedAiDetail?.layer_info)
        return null
      return isNatualLanguage(this.trainedAiDetail)
    },
    isTimeSeries() {
      if (!this.selectedItems.trainedAi) return null
      const trainedAi = this.selectedItems.trainedAi
      return trainedAi?.inferenceType === 'TIME'
    },
    isOptimization() {
      if (!this.selectedItems.trainedAi) return null
      const trainedAi = this.selectedItems.trainedAi
      return (
        trainedAi?.is_optimization &&
        this.selectedTrainedAiType === 'optimization'
      )
    },
    inferenceTypeOptions() {
      return this.selectedTrainedAi
        ? inferenceTypeOptions.bind(this)(
            this.selectedTrainedAi,
            this.checkNaturalLanguages
          )
        : []
    },
    selectedTrainedAi() {
      return this.selectedItems.trainedAi
    },
    choosedDatasetInfo() {
      if (this.selectedItems?.dataset) {
        const dataset = this.selectedItems?.dataset
        if (dataset.upload) {
          return {
            name: dataset.file.name,
            columns: this.preview?.columns.map((x, i) => ({
              id: i,
              text: x
            }))
          }
        } else {
          return this.selectedItems.dataset.item
        }
      }
      return null
    },
    choosedPreprocessInfo() {
      if (this.selectedItems?.preprocess) {
        const preprocess = this.selectedItems?.preprocess
        return preprocess
      }
      return null
    },
    sortedTrainedAi() {
      if (!this.trainedAiList) return []
      if (this.trainedAiList.length === 0) return []
      return [...this.trainedAiList].sort((x, y) =>
        x.updateTime < y.updateTime ? 1 : x.updateTime > y.updateTime ? -1 : 0
      )
    },
    checkColumns() {
      if (
        this?.choosedDatasetInfo?.columns == null ||
        !this.selectedTrainedAi
      ) {
        return {
          lackedColumns: [],
          lackedPreprocessingColumns: []
        }
      }
      const lackedColumns = []
      const lackedPreprocessingColumns = []
      const datasetColumns = new Set(
        this.choosedDatasetInfo.columns.map((item) => item.text)
      )
      if (this.preprocessingColumns && this.preprocessingColumns?.input) {
        this.preprocessingColumns.input.forEach((item) => {
          if (datasetColumns.has(item)) {
            datasetColumns.delete(item)
          } else if (!this.selectedTrainedAi.predictColumn.includes(item)) {
            lackedPreprocessingColumns.push(item)
          }
        })
        this.preprocessingColumns.output.forEach((item) => {
          datasetColumns.add(item)
        })
      }

      if (this.requiredColumns) {
        this.requiredColumns.forEach((item) => {
          if (datasetColumns.has(item)) {
            datasetColumns.delete(item)
          } else {
            lackedColumns.push(item)
          }
        })
      }

      return {
        lackedColumns: [...lackedColumns].map((item) => ({
          text: item,
          caution: true
        })),
        lackedPreprocessingColumns: [...lackedPreprocessingColumns].map(
          (item) => ({
            text: item,
            caution: true
          })
        )
      }
    },
    selectInferenceType() {
      if (
        !this.inferenceType?.inferenceTypeList ||
        this.inferenceType.inferenceTypeList.length === 0
      )
        return null
      return this.inferenceType.inferenceTypeList[0]
    },
    timeTransformerV2Settings() {
      const initialValue = {
        isKnown: [],
        isNotKnown: []
      }
      if (
        this.trainedAiDetailFullInfo == null ||
        this.trainedAiDetailFullInfo?.summary?.type !== 'TIME_TRANSFORMER2'
      )
        return initialValue
      const conditions =
        this.trainedAiDetailFullInfo.trainConfig.columnConditions
      return conditions.reduce((prev, current) => {
        if (current.isKnown.value) {
          prev.isKnown.push(current.columnName)
        } else {
          prev.isNotKnown.push(current.columnName)
        }
        return prev
      }, initialValue)
    }
  },
  watch: {
    inferenceTypeOptions(list) {
      if (!list || list.length === 0) return
      this.$emit('getSelectedItems', { inferenceType: list[0].value })
    },
    reset(bool) {
      if (bool) {
        this.menuId = 0
      }
    }
  },
  methods: {
    async uploadFile(e) {
      this.fileError = null
      this.preview = null

      const ext = e.name.split('.').pop().toLowerCase()
      if (ext === 'csv') {
        try {
          this.preview = await getSamples(e, 1)
          if (this.preview?.error != null) {
            throw new Error(this.preview.error)
          }
        } catch (error) {
          this.fileError = error.message
          return
        }
      }
      this.$emit('getSelectedItems', {
        dataset: {
          upload: true,
          file: e
        }
      })

      this.goNextStep()
    },
    changeMenu({ id }) {
      this.$data.menuId = id
    },
    async getTrainedAi({ id }) {
      await new Promise((resolve) =>
        this.$emit('getSelectedItems', { trainedAiId: id, resolve })
      )
      this.goNextStep()
    },
    async getPreprocess({ index }) {
      this.$emit('getSelectedItems', { preprocess: index })
      this.$nextTick(() => this.goNextStep())
    },
    async getDataset({ index }) {
      this.$emit('getSelectedItems', {
        dataset: {
          upload: null,
          id: index
        }
      })
      this.$nextTick(() => this.goNextStep())
    },
    getInferenceType: function ({ value }) {
      this.$emit('getSelectedItems', { inferenceType: value })
      this.$nextTick(() => this.goNextStep())
    },
    setInferenceSettings: function ({ value }) {
      this.$emit('getSelectedItems', { inferenceSettings: value })
    },
    setOptimizationSettings(value) {
      this.$emit('getSelectedItems', { optimizationSettings: value })
    },
    goNextStep: function () {
      this.menuId = this.nextStep
    },
    startInference: function () {
      this.$emit('start-inference')
    },
    changeTrainedAiTab(e) {
      this.selectedTrainedAiType = e
    },
    afterEnter() {
      if (this.selectedTrainedAiType === 'optimization' && this.menuId === 3) {
        const tutorialInfo = this.accountInfo.tutorialInfo
        const checkTutorial = tutorialInfo.optimizationResult

        if (!checkTutorial) {
          this.$emit('show-optimization-tutorial')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.inference-menu-wrap {
  overflow: hidden;
  display: grid;
  grid-template-columns: adjustVW(384) minmax(0, 1fr);
  grid-template-rows: 100%;
  grid-column-gap: $space-medium;
  width: 100%;
  height: 100%;
  padding: 0 $space-medium $space-medium $space-medium;
  &-optimization {
    grid-template-columns: adjustVW(256) minmax(0, 1fr);
  }
}

.inference-menu {
  &-navi-wrapper {
    height: 100%;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
  &-body-wrapper {
    height: 100%;
    grid-row: 1 / 2;
    grid-column: 2 / 3;
  }
  &-navi {
    width: 100%;
  }
  &-body {
    width: 100%;
    height: 100%;
  }
  &-item {
    height: 100%;
  }
  &-loading {
    height: 100%;
  }
}

.inference-menu-transition-wrapper {
  height: 100%;
}
</style>
