<template>
  <div class="wrap">
    <toggle-contents :title="title" :color="color" :isBold="isBold">
      <slot />
    </toggle-contents>
  </div>
</template>

<script>
import toggleContents from './toggle-contents.vue'
export default {
  components: {
    toggleContents
  },
  props: {
    /** トグルするカードの開閉ボタンのテキスト */
    title: String,
    /** トグルボタンの開閉ボタンのテキストとアイコンの色 */
    color: String,
    /** トグルボタンの開閉ボタンのテキストを太字にするかどうか */
    isBold: Boolean
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: $space-small;
  background: $background;
  border-radius: adjustVW(16);
  box-shadow: $box-shadow-main;
  transform-origin: top;
}
</style>
