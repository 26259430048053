<template>
  <transition mode="out-in" name="toggle-result">
    <result-best-main
      v-if="showTrainedAiType === constShowType.BEST && bestTrainedAi != null"
      key="best"
      v-model="bestTrainedAi"
      :accountInfo="accountInfo"
      :isSaved="isSaved"
      :recipeType="recipeType"
      :resetKey="resetKey"
      :sortedFlag="sortedFlag"
      :trainedAi="bestTrainedAi"
      :trainedAiCount="sortedTrainedAis.length"
      :trainedAIFormValidate="createInfo.trainedAIFormValidate"
      :finishColumns="finishColumns"
      :loadChangeColumn="loadChangeColumn"
      :trainingProgressStatus="trainingProgressStatus"
      :selectedColumnIndex="selectedColumnIndex"
      :checkTrainingFinish="checkTrainingFinish"
      :optimizationInfo="optimizationInfo"
      :optimizationResult="optimizationResult"
      :textMining="textMining"
      :threshold="threshold"
      :reversePositive="reversePositive"
      :testDatasetInfo="testDatasetInfo"
      :checkOptimization="checkOptimization"
      :clusteringSetting="clusteringSetting"
      :clusteringResult="clusteringResult"
      :clusteringDistributions="clusteringDistributions"
      :regressionGraph="regressionGraph"
      @delete-trained-ai="$emit('delete-trained-ai')"
      @input-create-form="$emit('input-create-form', $event)"
      @show-trained-ai-list="changeShowType('trainedAiList')"
      @save-trained-ai="$emit('save-trained-ai')"
      @change-column="$emit('change-column', $event)"
      @save-edit-optimization-form="
        $emit('save-edit-optimization-form', $event)
      "
      @change-page="$emit('change-page', $event)"
      @change-test-dataset-page="$emit('change-test-dataset-page', $event)"
      @change-filter-value="$emit('change-filter-value', $event)"
      @show-detail-optimization="$emit('show-detail-optimization', $event)"
      @download-result="$emit('download-result', $event)"
      @download-test-dataset="$emit('download-test-dataset', $event)"
      @change-tab="$emit('change-tab')"
      @close-optimization-result-tutorial="
        $emit('close-optimization-result-tutorial')
      "
      @change-threshold="$emit('change-threshold', $event)"
      @reverse-positive="$emit('reverse-positive', $event)"
      @load-text-mining="$emit('load-text-mining', $event)"
      @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
      @input-clustering-setting="$emit('input-clustering-setting', $event)"
      @download-clustering-result="$emit('download-clustering-result', $event)"
      @change-clustering-distribution-column="
        $emit('change-clustering-distribution-column', $event)
      "
      @toggle-clustering-show-dimension="
        $emit('toggle-clustering-show-dimension', $event)
      "
      @select-regression-graph="$emit('select-regression-graph', $event)"
      @download-confusion-matrix="$emit('download-confusion-matrix', $event)"
    />
    <result-list-main
      v-else-if="showTrainedAiType === constShowType.LIST"
      key="list"
      :bestTrainedAi="bestTrainedAi"
      :sortedFlag="sortedFlag"
      :sortedTrainedAis="sortedTrainedAis"
      :recipeType="recipeType"
      :trainedAiInput="trainedAiInput"
      :selectedColumnIndex="selectedColumnIndex"
      @show-trained-ai-top="changeShowType('trainedAiBest')"
      @show-detail="showDetail($event)"
    />
    <result-detail-main
      v-else-if="showTrainedAiType === constShowType.DETAIL"
      key="detail"
      v-model="sortedTrainedAis[showTrainedAiIndex]"
      :accountInfo="accountInfo"
      :isSaved="isSaved"
      :recipeType="recipeType"
      :resetKey="resetKey"
      :trainedAi="sortedTrainedAis[showTrainedAiIndex]"
      :trainedAIFormValidate="createInfo.trainedAIFormValidate"
      :selectedColumnIndex="selectedColumnIndex"
      :textMining="textMining"
      :threshold="threshold"
      :reversePositive="reversePositive"
      :testDatasetInfo="testDatasetInfo"
      :regressionGraph="regressionGraph"
      @delete-trained-ai="$emit('delete-trained-ai')"
      @input-create-form="$emit('input-create-form', $event)"
      @show-trained-ai-list="changeShowType('trainedAiList')"
      @save-trained-ai="$emit('save-trained-ai')"
      @change-tab="$emit('change-tab')"
      @change-test-dataset-page="$emit('change-test-dataset-page', $event)"
      @download-test-dataset="$emit('download-test-dataset', $event)"
      @change-threshold="$emit('change-threshold', $event)"
      @reverse-positive="$emit('reverse-positive', $event)"
      @load-text-mining="$emit('load-text-mining', $event)"
      @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
      @select-regression-graph="$emit('select-regression-graph', $event)"
    />
  </transition>
</template>

<script>
import resultBestMain from './result/result-best-main'
import resultListMain from './result/result-list-main'
import resultDetailMain from './result/result-detail-main'
import { SHOW_TRAINED_AI_TYPE } from '@/lib/training.js'

export default {
  components: {
    resultBestMain,
    resultListMain,
    resultDetailMain
  },
  /**
   * 学習結果に表示されるAIにはまだ名前がない。そのため仮の名前を入れている
   * この仮の名前は名前横の鉛筆マークから変更できて、保存確認時にも表示される
   * ただし、再学習を行った際に、sortedTrainedAisの順番が変わる可能性があるため、再学習を行った際は一度中身を空にして、再学習後のsortedTrainedAisに名前を入力できるようにしている。
   * もっといい方法があればそれに変更していいです。
   *
   * やりたいことは、精度が高い順にAIを並び変えて、それぞれに仮の名前をつけて、ユーザーが保存対象を選ぶときに、どのAIが保存したかったものか、識別できるようにすることです。
   */
  data() {
    const arr = [...Array(this.sortedTrainedAis.length)].map((_, i) => {
      return {
        name: this.$t('training.result.defaultName'),
        description: '',
        id: this.sortedTrainedAis[i].summary.id
      }
    })
    return {
      constShowType: SHOW_TRAINED_AI_TYPE,
      trainedAiInput: arr
    }
  },
  computed: {
    bestTrainedAi() {
      return this.sortedTrainedAis[0]
    },
    checkTrainingFinish() {
      return (
        ['training', 'preparingEnvironment', 'stoppingTraining'].indexOf(
          this.trainingProgressStatus
        ) === -1
      )
    }
  },
  methods: {
    showDetail(e) {
      this.changeShowType('trainedAiDetail')
      this.changeShowTrainedAi(e)
    },
    changeShowType(type) {
      this.$emit('update-show-type', type)
    },
    changeShowTrainedAi(index) {
      this.$emit('update-show-trained-ai', index)
    }
  },
  props: {
    accountInfo: Object,
    createInfo: Object,
    isSaved: Boolean,
    recipeType: String,
    resetKey: Number,
    sortedFlag: Boolean,
    sortedTrainedAis: Array,
    finishColumns: Array,
    loadChangeColumn: Boolean,
    trainingProgressStatus: String,
    selectedColumnIndex: Number,
    optimizationInfo: Object,
    optimizationResult: Object,
    textMining: Object,
    threshold: Number,
    reversePositive: Boolean,
    testDatasetInfo: Object,
    showTrainedAiType: String,
    showTrainedAiIndex: Number,
    checkOptimization: Boolean,
    clusteringSetting: Object,
    clusteringResult: Object,
    clusteringDistributions: Array,
    regressionGraph: Object
  },
  watch: {
    sortedTrainedAis() {
      const arr = [...Array(this.sortedTrainedAis.length)].map((_, i) => {
        return {
          name: this.$t('training.result.defaultName'),
          description: '',
          id: this.sortedTrainedAis[i].summary.id
        }
      })
      this.trainedAiInput = arr
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-result-enter-active,
.toggle-result-leave-active {
  transition: transform $transition-base, opacity $transition-base;
}
.toggle-result-enter,
.toggle-result-leave-to {
  opacity: 0;
  transform: translateX(-$space-small);
  will-change: opacity, transform;
}
</style>
