<template>
  <div class="template-wrap">
    <div class="template-main">
      <div class="template-item">
        <text-with-title
          :title="$t('serviceList.popup.addNewService.datasetSelectedTitle')"
          :text="
            datasetDetail ? datasetDetail.detail.name : $t('common.notSelected')
          "
        />
      </div>
      <div class="template-item template-item-scroll">
        <texts
          class="template-item-title"
          :text="$t('selectTargetColumn.menu.targetColumnInfo.title')"
          size="small"
          color="gray"
        />
        <select-box-input
          ref="selectBoxInputMain"
          class="template-item-box"
          :placeholder="
            $t('selectTargetColumn.menu.targetColumnInfo.selectColumn')
          "
          :value="inputText"
          :items="columns"
          isGray
          isScroll
          noDisabledItem
          isSeparateBox
          :isCloseItem="isCloseItem"
          :marginLeft="setMarginLeft"
          :noIcon="!this.datasetColumns || !this.datasetDetail"
          :option="option"
          :isNotExistItem="checkNotExist"
          :tipsContents="
            checkNotExist
              ? this.$t('molecules.selectInputList.caution.noItem')
              : null
          "
          @input="updateValues($event)"
          @select-box-open="closeOtherBox"
        />
        <select-box-input-list
          :selectedItems="inputColumns"
          :items="columns"
          :baseColumns="baseColumns"
          :disabledAdd="inputText.length === 0"
          :filterItem="inputText"
          :marginLeft="setMarginLeft"
          noDisabledItem
          :noSelectItems="!datasetColumns || datasetColumns.length === 0"
          :closeOther="closeOther"
          :noIcon="!this.datasetColumns || !this.datasetDetail"
          :option="option"
          @input="selectItem($event)"
          @select-box-open="closeMainBox"
        />
        <button-main
          v-if="datasetColumns && datasetColumns.length > 0"
          class="template-button-all"
          :text="$t('common.selectAllColumn')"
          :isDisabled="checkSelectAll"
          type="sub"
          @click="selectAll"
        />
        <text-box
          v-if="checkTimeItem"
          class="template-item-caution"
          isError
          isFolded
          :text="
            $t(
              'serviceList.popup.addNewService.timeseriesMulti.cautionMessage',
              [checkTimeItem]
            )
          "
        />
      </div>
      <div class="template-button">
        <button-large
          class="template-button-item"
          :text="$t('selectTargetColumn.menu.targetColumnInfo.button')"
          :isDisabled="disabledSubmit"
          @click="confirmSelectColumns"
        />
      </div>
    </div>
    <dataset-setting-table
      class="template-detail"
      :table="checkDatasetDetail.table"
      :columns="checkDatasetDetail.tableColumns"
      :type="checkDatasetDetail.type"
      :nLabels="nLabels"
    />
  </div>
</template>
<script>
import buttonMain from '@/components/atoms/button-main'
import buttonLarge from '@/components/atoms/button-large'
import selectBoxInput from '@/components/molecules/select-box-input.vue'
import selectBoxInputList from '@/components/molecules/select-box-input-list.vue'
import textBox from '@/components/atoms/text-box'
import textWithTitle from '@/components/molecules/text-with-title'
import datasetSettingTable from '@/components/organisms/dataset-setting/dataset-setting-table'

export default {
  components: {
    buttonMain,
    buttonLarge,
    selectBoxInput,
    selectBoxInputList,
    textBox,
    textWithTitle,
    datasetSettingTable
  },
  data() {
    return {
      inputText: '',
      inputColumns: [],
      nLabels: 0,
      isCloseItem: false,
      closeOther: false,
      option: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.2,
        keys: [
          {
            name: 'value',
            weight: 0.5
          }
        ],
        distance: 10000
      }
    }
  },
  props: {
    projectId: Number,
    datasetColumns: Array,
    datasetDetail: Object
  },
  methods: {
    updateValues(e) {
      this.inputText = e
    },
    selectItem(e) {
      this.inputColumns = e
    },
    closeMainBox() {
      this.$refs.selectBoxInputMain.closeItem()
      this.closeOther = false
    },
    closeOtherBox() {
      this.closeOther = true
    },
    selectAll() {
      const otherColumns = this.predictionColumns.filter((item) => {
        return !this.baseColumns.includes(item)
      })
      const baseColumns = this.baseColumns.slice(1, this.baseColumns.length)
      // 予測する列の選択項目にないものも選べるので、それを末尾にセット
      this.inputColumns = [...baseColumns, ...otherColumns]
      this.inputText = this.baseColumns[0]
    },
    confirmSelectColumns() {
      let target = this.predictionColumns
      if (this.checkTimeItem) {
        target = target.filter((item) => {
          return this.datasetDetail.detail.dtypes[item] !== 'datetime_prob'
        })
      }
      this.$emit('select-column', { predictionColumns: target })
    }
  },
  computed: {
    disabledSubmit() {
      const onlyTimeColumn =
        this.predictionColumns.length === 1 && this.checkTimeItem
      return !(this.predictionColumns.length > 0 && !onlyTimeColumn)
    },
    checkDatasetDetail() {
      if (this.datasetDetail == null) return {}
      if (this.datasetDetail?.data_type === 'list') {
        return {
          tableColumns: Object.keys(this.datasetDetail.detail.dtypes),
          table: this.datasetDetail.list,
          type: this.datasetDetail.data_type
        }
      }
      return {}
    },
    columns() {
      if (!this.datasetColumns || !this.datasetColumns.length === 0) return []
      const target = this.datasetColumns.filter((item) => {
        const checkSelect = !this.predictionColumns.includes(item)
        return checkSelect
      })
      const res = target.map((item) => {
        const checkTime =
          this.datasetDetail.detail.dtypes[item] === 'datetime_prob'
        const checkTimeVal = checkTime
          ? ' ' +
            this.$t(
              'serviceList.popup.addNewService.timeseriesMulti.cautionItem'
            )
          : ''
        return {
          name: item + checkTimeVal,
          value: item,
          disabled: checkTime
        }
      })
      return res
    },
    predictionColumns() {
      if (this.inputText.length > 0) {
        return [this.inputText, ...this.inputColumns]
      } else {
        return [...this.inputColumns]
      }
    },
    setMarginLeft() {
      return (((240 + 24) / 1920) * 100 * window.innerWidth) / 100
    },
    baseColumns() {
      if (!this.datasetColumns || this.datasetColumns.length === 0) return []
      return this.datasetColumns.filter((item) => {
        return this.datasetDetail.detail.dtypes[item] !== 'datetime_prob'
      })
    },
    checkSelectAll() {
      if (!this.baseColumns || this.baseColumns.lenght === 0) return false
      const inputBaseColumns = this.predictionColumns.filter((item) => {
        return this.baseColumns.includes(item)
      })
      return inputBaseColumns.length >= this.baseColumns.length
    },
    checkTimeItem() {
      if (!this.datasetDetail) return null
      return this.predictionColumns.find((item) => {
        return this.datasetDetail.detail.dtypes[item] === 'datetime_prob'
      })
    },
    checkNotExist() {
      if (!this.inputText) return false
      return !this.baseColumns.includes(this.inputText)
    }
  }
}
</script>
<style lang="scss" scoped>
.template {
  &-wrap {
    display: grid;
    grid-template-columns: minmax(adjustVW(594), 1fr) adjustVW(966);
    grid-column-gap: $space-medium;
    width: 100%;
    height: 100%;
  }
  &-main {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  &-item {
    flex-shrink: 0;
    margin-bottom: $space-medium;
    &-title {
      flex-shrink: 0;
      margin-bottom: $space-base;
    }
    &-caution {
      margin-top: $space-small;
    }
    &-scroll {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      margin-bottom: $space-large;
    }
    &-box {
      margin-bottom: $space-small;
    }
  }
  &-detail {
    overflow: hidden;
  }
  &-check {
    &-wrap {
      overflow: hidden;
      display: flex;
      flex-shrink: 1;
      flex-grow: 1;
      grid-column-gap: $space-medium;
    }
    &-body {
      overflow-y: scroll;
      @include scrollbar;
    }
    &-name {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      &-check {
        flex-shrink: 0;
        width: adjustVW(24);
        padding: 0;
        margin: 0 $space-small 0 0;
      }
      &-text {
        overflow: hidden;
        flex-shrink: 1;
        flex-grow: 1;
        width: 100%;
      }
    }
  }
  &-text-area {
    height: 100%;
    &::v-deep > div {
      height: 100%;
    }
    &-no-dataset {
      width: 100%;
    }
  }
  &-button {
    margin-top: auto;
    &-all {
      flex-shrink: 0;
      height: adjustVW(56);
      margin-top: $space-small;
    }
  }
}
</style>
