<template>
  <div class="preprocessing-detail-wrap">
    <div class="preprocessing-detail-top">
      <preprocessing-detail-top
        v-bind="pageTop"
        :preprocessingMenu="preprocessingMenu"
        @delete-menu-click="$emit('delete-menu-click', $event)"
        @select-dataset="$emit('select-dataset', $event)"
      />
    </div>
    <div class="preprocessing-detail-body">
      <preprocessing-detail-body v-bind="pageBody.preprocChart" />
    </div>
    <div class="preprocessing-detail-side">
      <preprocessing-detail-side
        v-bind="pageBody.info"
        :disabledEdit="disabledEdit"
        :preprocFormValidate="editInfo.preprocFormValidate"
        @input-edit-form="$emit('input-edit-form', $event)"
        @update-preprocessing-detail="
          $emit('update-preprocessing-detail', $event)
        "
      />
    </div>
  </div>
</template>

<script>
import preprocessingDetailTop from './preprocessing-detail-top'
import preprocessingDetailBody from './preprocessing-detail-body'
import preprocessingDetailSide from './preprocessing-detail-side'

export default {
  components: {
    preprocessingDetailTop,
    preprocessingDetailBody,
    preprocessingDetailSide
  },
  props: {
    disabledEdit: Boolean,
    editInfo: Object,
    pageBody: Object,
    pageTop: Object,
    preprocessingMenu: Array
  }
}
</script>

<style lang="scss" scoped>
.preprocessing-detail {
  &-wrap {
    display: grid;
    grid-template-areas:
      'top top'
      'body side';
    grid-template-columns: adjustVW(1224) adjustVW(384);
    grid-template-rows: auto 1fr;
    grid-column-gap: $space-medium;
    grid-row-gap: $space-base;
    height: 100vh - adjustVH($headerTabHeight);
    padding: 0 $space-medium $space-medium;
  }
  &-top {
    grid-area: top;
  }
  &-body {
    overflow: hidden;
    grid-area: body;
    height: 100%;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
  }
  &-side {
    grid-area: side;
    padding: $space-small $space-base;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
  }
}
</style>
