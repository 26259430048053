<template>
  <div class="trained-ai-accuracy">
    <!-- accuracy -->
    <div class="trained-ai-accuracy-block">
      <!-- 表示する列と閾値の変更用 -->
      <div
        v-if="isMultiColumn || displayThreshold"
        class="trained-ai-accuracy-block-setting"
      >
        <accuracy-setting-block
          v-model.number="dataThreshold"
          showCard
          :isMultiColumn="isMultiColumn"
          :changeableThreshold="changeableThreshold"
          :displayThreshold="displayThreshold"
          :trainedAi="trainedAi"
          :finishColumns="finishColumns"
          :selectedColumnIndex="selectedColumnIndex"
          :loadChangeColumn="loadChangeColumn"
          :reversePositive="reversePositive"
          @change-column="$emit('change-column', $event)"
          @change-threshold="changeThreshold"
          @reverse-positive="changeReversePositive"
        />
      </div>
      <!--
        画像のみテストデータと学習データの損失関数をaccuracyとして表示しているため、画像の場合のみタブ切り替えできるようにする。それ以外の場合は、保持しているaccuracyのパターンをすべて表示(分類であれば正解率・適合率・再現率・F値)
        基本的にはテストデータと学習データそれぞれにaccuracyがある場合の対応なので、ない場合は精度すべてを表示するだけでよい
      -->
      <div v-if="tabs.length < 2" class="trained-ai-accuracy-item-wrap">
        <transition-toggle-contents>
          <div v-if="accuracy.size > 0" class="trained-ai-accuracy-item-inner">
            <accuracy-tooltip-wrap
              v-for="(item, index) in metricsInfo.get(selectImageTab)"
              :key="index"
              :metrics="item"
            />
          </div>
        </transition-toggle-contents>
        <div
          v-if="accuracy.size === 0 && deepTrainedAi"
          class="trained-ai-accuracy-item"
        >
          <texts
            class="trained-ai-accuracy-item-no-item"
            :text="$t('trainedAiDetails.metrics.noMetrics')"
            size="small"
            color="gray"
          />
        </div>
        <!-- 回帰または最適化の場合 グラフ表示領域があるため、位置を精度直下に移動 -->
        <template v-if="isRegression">
          <transition-toggle-contents>
            <accuracy-indexes
              v-if="trainedAi.result.accuracy_indexes && accuracy.size > 0"
              :accuracyIndexes="trainedAi.result.accuracy_indexes"
              :type="trainedAiType"
            />
          </transition-toggle-contents>
          <contact-block :isSmallMargin="checkConfusionAndOptimization" />
        </template>
      </div>
      <div v-else class="trained-ai-accuracy-image-wrap">
        <div class="trained-ai-accuracy-image-tab">
          <tab-list
            :tabs="tabs"
            :showIcon="false"
            @change-tab="selectImageTab = $event"
          />
        </div>
        <transition-toggle-contents>
          <div :key="selectImageTab" class="trained-ai-accuracy-item-inner">
            <accuracy-tooltip-wrap
              v-for="(item, index) in metricsInfo.get(selectImageTab)"
              :key="index"
              :metrics="item"
              :isSmall="metricsInfo.get(selectImageTab).length > 6"
            />
          </div>
        </transition-toggle-contents>
      </div>
    </div>
    <!-- graph -->
    <div v-if="displayConfusionMatrix" class="trained-ai-accuracy-graph">
      <div class="trained-ai-accuracy-graph-body">
        <div class="trained-ai-accuracy-graph-title">
          <texts
            :text="$t('trainedAiDetails.confusionMatrix')"
            size="large"
            isBold
          />
          <download-button
            v-if="canDownloadConfusions"
            class="confusion-download-button"
            @download="$emit('download-confusion-matrix', $event)"
          />
        </div>

        <transition-toggle-contents>
          <div
            v-if="selectedColumnIndex === -1"
            key="notSet"
            class="trained-ai-accuracy-graph-not-set"
          >
            <texts
              class="trained-ai-accuracy-graph-not-set-text"
              :text="$t('trainedAi.multi.notFoundSeparate')"
              color="gray"
            />
          </div>
          <div
            v-else-if="loadChangeColumn"
            key="loading"
            class="trained-ai-accuracy-graph-not-set"
          >
            <loading />
          </div>
          <confusionPlot
            v-else
            class="trained-ai-accuracy-graph-main"
            :data="trainedAi.result.test_confusion_matrix"
            :labels="confusionLabels"
          />
        </transition-toggle-contents>
      </div>
      <!-- v0.4.1 以降で精度指標を複数持つ場合 -->
      <div v-if="smallContactBlock">
        <contact-block isSmall />
      </div>
    </div>
    <!-- 回帰の場合 グラフ表示領域を表示 -->
    <div v-if="isRegression">
      <detail-regression-graph
        :regressionGraph="regressionGraph"
        :selectedColumnIndex="selectedColumnIndex"
        :isOptimization="trainedAi.summary.is_optimization"
        :testData="trainedAi.result.testData"
        @select-regression-graph="$emit('select-regression-graph', $event)"
      />
    </div>
    <!-- contact or other accuracy -->
    <div class="trained-ai-accuracy-contact">
      <contact-block
        v-if="!smallContactBlock && !isRegression"
        :isSmallMargin="checkConfusionAndOptimization"
      />
      <!-- v0.4.1 以降で回帰または最適化以外で、精度指標を複数持つ場合 -->
      <template v-if="displayDetailAccuracy && !isRegression">
        <transition-toggle-contents>
          <accuracy-indexes
            v-if="trainedAi.result.accuracy_indexes && accuracy.size > 0"
            :accuracyIndexes="trainedAi.result.accuracy_indexes"
            :type="trainedAiType"
          />
        </transition-toggle-contents>
      </template>
    </div>
  </div>
</template>

<script>
import loading from '@/components/atoms/loading.vue'
import tabList from '@/components/molecules/tab-list'
import confusionPlot from '@/components/organisms/graph/confusion.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import accuracyTooltipWrap from './accuracy-tooltip-wrap.vue'
import accuracySettingBlock from '@/components/organisms/trained-ai-common/accuracy-setting-block'
import contactBlock from '@/components/organisms/trained-ai-detail/trained-ai-detai-tab-inner/accuracy/contact-block.vue'
import accuracyIndexes from '@/components/organisms/trained-ai-detail/trained-ai-detai-tab-inner/accuracy/accuracy-indexes.vue'
import detailRegressionGraph from './detail/detail-regression-graph.vue'
import downloadButton from '@/components/molecules/download-button'

import { DEEP_RECIPE_TYPE } from '@/lib/training.js'

export default {
  components: {
    loading,
    tabList,
    confusionPlot,
    transitionToggleContents,
    accuracyTooltipWrap,
    accuracySettingBlock,
    contactBlock,
    accuracyIndexes,
    detailRegressionGraph,
    downloadButton
  },
  data() {
    return {
      selectImageTab: 'test',
      selectRegressionTab: 'comparison',
      selectedItem: '',
      dataThreshold: 0.5,
      dataReversePositive: false
    }
  },
  props: {
    trainedAi: Object,
    pageName: String,
    metricsDefs: Object,
    selectedColumnIndex: Number,
    loadChangeColumn: Boolean,
    finishColumns: Array,
    threshold: Number,
    reversePositive: Boolean,
    regressionGraph: Object
  },
  computed: {
    tabs() {
      // テストデータ,学習データ
      return Array.from(this.metricsInfo.keys()).map((tabName, i) => ({
        id: i,
        name: this.$t(`trainedAi.metricsTab.${tabName}`),
        value: tabName
      }))
    },
    trainedAiType() {
      return this.trainedAi?.summary?.type
    },
    accuracy() {
      const sortedAccuracy = new Map()
      if (
        !(
          this.selectedColumnIndex >= 0 &&
          Object.keys(this.trainedAi.summary.metrics).length > 0 &&
          !this.loadChangeColumn
        )
      )
        return sortedAccuracy
      const targetMetrics =
        this.trainedAi.summary.metrics[this.selectedColumnIndex]

      const metricsDefs = Object.keys(this.metricsDefs)
      for (const key of metricsDefs) {
        if (targetMetrics[key]) {
          sortedAccuracy.set(key, targetMetrics[key])
        }
      }
      return sortedAccuracy
    },
    metricsInfo() {
      const tabs = new Map()
      for (const [key] of this.accuracy) {
        // 精度ではないpropertyを弾く
        if (this.metricsDefs[key]) {
          const def = this.metricsDefs[key]
          const tabName = def?.tab ?? 'other'
          if (!tabs.has(tabName)) {
            tabs.set(tabName, [])
          }
          tabs.get(tabName).push({
            name: def?.name ?? key,
            value: this.accuracy.get(key),
            ...def
          })
        }
      }
      return tabs
    },
    checkConfusionAndOptimization() {
      if (!this.trainedAi?.result) return true
      return (
        !this.trainedAi.result?.test_confusion_matrix &&
        !this.trainedAi.summary?.is_optimization
      )
    },
    isMultiColumn() {
      return (
        this.trainedAi.trainConfig.predictionColumn &&
        this.trainedAi.trainConfig.predictionColumn.length > 1
      )
    },
    changeableThreshold() {
      return this.trainedAi.result.changeable_threshold
    },
    displayThreshold() {
      const type = this.trainedAi?.summary?.type
      return type === 'CLASSIFICATION'
    },
    confusionLabels() {
      let labels = null

      // 分類の場合の、混同行列のラベル情報をdeep copyで取得
      if (this.trainedAi.mapping) {
        // ZIPテキストの場合
        labels = JSON.parse(JSON.stringify(this.trainedAi.mapping))
      } else if (this.trainedAi.result.test_confusion_matrix_label) {
        // テーブルデータの場合
        labels = JSON.parse(
          JSON.stringify(this.trainedAi.result.test_confusion_matrix_label)
        )
      }

      if (labels) {
        // 陽性対象列変更時の順番変更
        if (this.reversePositive) {
          return labels.reverse()
        } else {
          return labels
        }
      }

      return null
    },
    canDownloadConfusions() {
      if (this.confusionLabels?.length !== 2) {
        return false
      }
      const isClassificationAi =
        this.trainedAi.summary.type === 'CLASSIFICATION'
      if (!isClassificationAi) {
        return false
      }
      return true
    },
    hasAccuracyIndexes() {
      return Boolean(this.trainedAi.result?.accuracy_indexes)
    },
    hasClassificationAccuracyIndexes() {
      // v0.4.1 以降の分類で複数の精度指標を持つかを判定
      const isClassificationAi =
        this.trainedAi.summary.type === 'CLASSIFICATION'
      return isClassificationAi && this.hasAccuracyIndexes
    },
    hasRegressionAccuracyIndexes() {
      // v0.4.1 以降の回帰で複数の精度指標を持つかを判定
      const isRegressionAi = this.trainedAi.summary.type === 'REGRESSION'
      return isRegressionAi && this.hasAccuracyIndexes
    },
    hasImageClassificationAccuracyIndexes() {
      // v0.4.2 以降の画像分類で複数の精度指標を持つかを判定
      return this.deepTrainedAi && this.hasAccuracyIndexes
    },
    displayConfusionMatrix() {
      const hasConfusionMatrix =
        this.trainedAi.result && this.trainedAi.result.test_confusion_matrix
      return (
        hasConfusionMatrix &&
        (this.tabs.length < 2 ||
          (this.tabs.length > 1 && this.selectImageTab === 'test'))
      )
    },
    displayDetailAccuracy() {
      return (
        (this.hasClassificationAccuracyIndexes ||
          this.hasRegressionAccuracyIndexes ||
          (this.hasImageClassificationAccuracyIndexes &&
            this.selectImageTab === 'test')) &&
        !this.trainedAi?.summary?.is_optimization
      )
    },
    smallContactBlock() {
      return (
        this.hasClassificationAccuracyIndexes ||
        (this.hasImageClassificationAccuracyIndexes &&
          this.selectImageTab === 'test')
      )
    },
    deepTrainedAi() {
      return DEEP_RECIPE_TYPE.indexOf(this.trainedAiType) !== -1
    },
    isRegression() {
      return this.trainedAi.summary.type === 'REGRESSION'
    }
  },
  methods: {
    goContact() {
      window.open(this.$urls.contactLink, '_blank')
    },
    changeThreshold(threshold) {
      this.dataThreshold = threshold
      this.$emit('change-threshold', threshold)
    },
    changeReversePositive(reversePositive) {
      this.dataReversePositive = reversePositive
      this.$emit('reverse-positive', reversePositive)
    }
  },
  mounted() {
    const configColumn = this.trainedAi?.trainConfig?.predictionColumn ?? []
    if (configColumn.length === 0) return
    this.selectedItem = configColumn[this.selectedColumnIndex]
    this.dataThreshold = this.threshold ?? this.dataThreshold
  },
  watch: {
    selectedItem(newVal) {
      this.$emit('change-column', newVal)
    },
    threshold(newVal) {
      this.dataThreshold = newVal
    }
  }
}
</script>

<style lang="scss" scoped>
.trained-ai-accuracy {
  display: grid;
  grid-template-areas:
    'accuracy side'
    'accuracy contact';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-column-gap: $space-medium;
  grid-row-gap: $space-medium;
  height: 100%;
  padding: 0 $space-medium $space-medium;
  &-block {
    grid-area: accuracy;
    &-setting {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: 0 0 $space-small;
    }
  }
  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - #{$space-small / 2});
    height: adjustVH(160);
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(8);
    box-shadow: $box-shadow-main;
    &-wrap {
      display: flex;
      flex-direction: column;
      grid-column-gap: $space-small;
      grid-row-gap: $space-small;
    }
    &-inner {
      display: flex;
      flex-wrap: wrap;
      grid-column-gap: $space-small;
      grid-row-gap: $space-small;
      width: 100%;

      &-default {
        display: flex;
        grid-column-gap: $space-small;
        width: 100%;
      }

      &-detail {
        width: 100%;
      }
    }
    &-graph {
      width: adjustVW(72);
      margin: 0 0 0 adjustVW(56);
    }
    &-no-item {
      white-space: pre-line;
    }
  }
  &-graph {
    display: grid;
    grid-template-columns: 1fr minmax(0, auto);
    grid-column-gap: $space-large;
    height: fit-content;
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(8);
    box-shadow: $box-shadow-main;
    grid-area: side;
    &-title {
      display: flex;
      margin: 0 0 $space-large;
    }
    &-main {
      width: 100%;
    }
    &-not-set {
      display: flex;
      align-items: center;
      justify-content: center;
      height: adjustVW(262);
      padding-left: $space-small;
      &-text {
        text-align: center;
        white-space: pre-line;
      }
    }
  }
  &-image {
    // 画像の精度表示用
    &-wrap {
      width: 100%;
    }
    &-tab {
      width: adjustVW(156) * 2 + $space-medium;
      margin: 0 0 $space-medium;
    }
  }
  &-contact {
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-small;
    &-title {
      margin-bottom: $space-base;
    }
    &-optimization {
      margin-bottom: $space-sub;
      word-break: break-all;
      white-space: pre-line;
      &-img {
        margin-top: $space-small;
      }
    }
  }
  &-optimization {
    grid-area: side;
    padding: $space-small;
    border: $border-green;
    background: $background;
    border-radius: adjustVW(8);
    box-shadow: $box-shadow-main;
  }
}
.margin-left {
  margin: 0 0 0 $space-medium;
}
</style>
