<template>
  <result-text-card
    class="text-classification-card"
    :itemName="filename"
    :text="body"
    :reliabilities="probability"
    :posBottom="posBottom"
  >
    <div class="classification-reliability-wrap">
      <div class="classification-reliability-title">
        <texts
          class="classification-reliability-block1"
          size="small"
          :text="this.$i18n.t('inference.result.classification.label')"
        />
        <texts
          class="classification-reliability-block2"
          size="small"
          :text="this.$i18n.t('inference.result.classification.reliabiliry')"
        />
      </div>

      <div class="classification-reliability-graph">
        <div
          v-for="([key, value], index) in reliabilitiesArray"
          :key="index"
          class="classification-reliability-graph-wrapper"
        >
          <div class="classification-reliability-block1">
            <texts
              class="classification-reliability-block1-inner"
              size="small"
              :text="key"
            />
          </div>
          <div
            class="
              classification-reliability-block2
              classification-reliability-graph-bar-wrapper
            "
          >
            <graph-bar-count
              class="classification-reliability-graph-bar-line"
              :count="value"
              :maxCount="maxCount"
            />
            <texts
              class="classification-reliability-graph-bar-count"
              size="min"
              :text="Math.round(value * 1000) / 10 + '%'"
            />
          </div>
        </div>
      </div>
    </div>
  </result-text-card>
</template>

<script>
import resultTextCard from '@/components/molecules/inference/result/result-text-card.vue'
import texts from '@/components/atoms/text.vue'
import graphBarCount from '@/components/atoms/graph-bar-count.vue'

export default {
  components: {
    graphBarCount,
    resultTextCard,
    texts
  },
  data() {
    return {
      maxCount: 1.0
    }
  },
  computed: {
    reliabilitiesArray() {
      return Object.entries(this.probability).sort((x, y) => {
        return y[1] - x[1]
      })
    }
  },
  props: {
    filename: String,
    body: String,
    label: String,
    probability: Object,
    posBottom: Boolean
  }
}
</script>

<style lang="scss" scoped>
.text-classification-card {
  width: 100%;
  height: 100%;
}

.classification-reliability {
  &-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &-block1 {
    flex-shrink: 0;
    width: adjustVW(108);
    margin-right: $space-small;
  }

  &-block2 {
    flex-shrink: 1;
    width: 100%;
  }
}

.classification-reliability-title {
  display: flex;
  align-items: center;
  margin-bottom: $space-base;
}

.classification-reliability-graph {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 0 $space-base 0 0;
  @include scrollbar;

  &-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: $space-sub;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.classification-reliability-graph-bar {
  &-wrapper {
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  &-line {
    flex-shrink: 1;
    width: adjustVW(144);
    margin-right: $space-sub;
    z-index: 1;
  }

  &-count {
    flex-shrink: 0;
    width: adjustVW(44);
  }
}
</style>
