<!--
  table-mainを学習画面ように改修したもの
-->
<template>
  <div class="list-wrap">
    <div class="list-body">
      <div class="list-name-block">
        <div
          v-for="key in field"
          :key="key.id"
          class="list-name-inner"
          :style="{ '--width': checkWidth(key.width) }"
        >
          <div
            v-if="key.listType === 'id'"
            class="list-name-item list-name-item-id"
          >
            <texts class="list-name-item-text" :text="key.label" />
          </div>
          <div
            v-else-if="key.listType === 'otherMetrics'"
            class="list-name-item list-name-item-select"
          >
            <select-box
              :items="otherMetrics"
              isGray
              min
              @input="changeOtherMetrics($event)"
            />
          </div>
          <div v-else class="list-name-item">
            <texts :text="key.label" />
          </div>
        </div>
        <div class="list-menu-icon">
          <div class="list-menu-icon-link">
            <icons iconName="forward" />
          </div>
        </div>
      </div>
      <div class="list-data-wrap">
        <button
          v-for="(item, index) in items"
          :key="item[idColumn]"
          class="list-data-button"
          @click="$emit('click', index)"
        >
          <div class="list-data-button-inner">
            <div
              v-for="key in field"
              :key="key.id"
              class="list-data-inner"
              :style="{ '--width': checkWidth(key.width) }"
            >
              <template v-if="key.listType === 'name'">
                <div class="list-data-item">
                  <texts :text="trainedAiInput[index].name" />
                </div>
              </template>

              <!-- メインの精度の場合 -->
              <template v-if="key.listType === 'accuracy'">
                <div class="list-data-item">
                  <metrics-text
                    :value="item.summary.metrics[selectedColumnIndex][key.key]"
                    :percentage="metricsDefs[key.key].percentage"
                    size="sub-title"
                  />
                </div>
              </template>

              <!-- ほかの精度の場合 -->
              <template v-if="key.listType === 'otherMetrics'">
                <div class="list-data-item">
                  <metrics-text
                    :value="item.summary.metrics[selectedColumnIndex][key.key]"
                    :percentage="metricsDefs[key.key].percentage"
                    size="sub-title"
                  />
                </div>
              </template>

              <!-- 重要度の場合(リスト系だがObjectで渡されるため) -->
              <template v-if="key.listType === 'featureImportance'">
                <template
                  v-if="
                    item.summary.featureImportance &&
                      Object.keys(item.summary.featureImportance).length > 0
                  "
                >
                  <template
                    v-for="(list, index) in clipObject(
                      item.summary.featureImportance
                    )"
                  >
                    <div :key="index" class="list-data-list">
                      <div class="list-data-list-inner">
                        {{ list }}
                      </div>
                    </div>
                  </template>
                </template>
                <texts
                  v-else
                  class="list-data-item"
                  :text="$t('training.result.list.noFeatureImportance')"
                  size="small"
                  color="gray"
                />
              </template>

              <!-- レシピの経路の場合 -->
              <template v-else-if="key.listType === 'route'">
                <div>
                  <div class="list-data-item-route">
                    <recipe-chart-list-min :layers="item.result.layer_info" />
                  </div>
                </div>
              </template>

              <!-- idの場合 -->
              <template v-else-if="key.listType === 'id'">
                <div class="list-data-item list-data-item-id">
                  <texts :text="'#' + (index + 1)" />
                </div>
              </template>
            </div>
            <!-- リストに表示されるアイコンメニュー -->
            <div class="list-menu-icon">
              <div class="list-menu-icon-link">
                <icons iconName="forward" />
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import icons from '@/components/atoms/icon'
import metricsText from '@/components/atoms/metrics-text'
import RecipeChartListMin from '@/components/molecules/recipe-chart-list-min.vue'
import SelectBox from '@/components/molecules/select-box.vue'

export default {
  components: {
    texts,
    icons,
    metricsText,
    RecipeChartListMin,
    SelectBox
  },
  data() {
    return {
      selectedMetrics: ''
    }
  },
  props: {
    items: Array, // 表示するリスト本体
    bestTrainedAi: Object,
    idColumn: { type: String, default: 'id' },
    mainMetrics: String,
    modelType: String,
    metricsDefs: Object,
    trainedAiInput: Array,
    selectedColumnIndex: Number
  },
  methods: {
    clipList(e) {
      // リスト表示は最大３つ
      return e.slice(0, 3)
    },
    clipObject(items) {
      const keyList = Object.keys(items)
      const clipObject = []
      for (const key in keyList) {
        if (key > 2) {
          return clipObject
        } else {
          clipObject.push(keyList[key])
        }
      }
      return clipObject
    },
    checkWidth(width) {
      return (width / 1920) * 100 + 'vw'
    },
    changeOtherMetrics(e) {
      this.selectedMetrics = e
    }
  },
  computed: {
    field() {
      if (!this.mainMetrics) {
        return [
          {
            id: 0,
            key: 'id',
            label: '/',
            width: 24,
            listType: 'id'
          },
          {
            id: 1,
            key: 'name',
            label: this.$t('training.result.list.field.name'),
            width: 208,
            listType: 'name'
          },
          {
            id: 2,
            key: 'featureImportance',
            label: this.$t('training.result.list.field.featureImportance'),
            width: 280,
            listType: 'featureImportance'
          },
          {
            id: 3,
            key: 'route',
            label: this.$t('training.result.list.field.route'),
            width: 424,
            listType: 'route'
          }
        ]
      } else if (this.project?.template?.expectedEffectName) {
        return [
          {
            id: 0,
            key: 'id',
            label: '/',
            width: 24,
            listType: 'id'
          },
          {
            id: 1,
            key: 'name',
            label: this.$t('training.result.list.field.name'),
            width: 208,
            listType: 'name'
          },
          {
            id: 2,
            key: 'expectedEffect',
            label: this.project.template.expectedEffectName,
            width: 160
          },
          {
            id: 3,
            key: this.mainMetrics,
            label: this.metricsDefs[this.mainMetrics].name,
            width: 232
          },
          {
            id: 4,
            key: this.changeOtherMetrics(this.otherMetrics).value,
            label: this.changeOtherMetrics().name,
            width: 240
          },
          {
            id: 5,
            key: 'featureImportance',
            label: this.$t('training.result.list.field.featureImportance'),
            width: 280,
            listType: 'featureImportance'
          },
          {
            id: 6,
            key: 'route',
            label: this.$t('training.result.list.field.route'),
            width: 424,
            listType: 'route'
          }
        ]
      } else if (!this.selectedOtherMetrics) {
        return [
          {
            id: 0,
            key: 'id',
            label: '/',
            width: 48,
            listType: 'id'
          },
          {
            id: 1,
            key: 'name',
            label: this.$t('training.result.list.field.name'),
            width: 232,
            listType: 'name'
          },
          {
            id: 2,
            key: this.mainMetrics,
            label: this.metricsDefs[this.mainMetrics].name,
            width: 232,
            listType: 'accuracy'
          },
          {
            id: 4,
            key: 'featureImportance',
            label: this.$t('training.result.list.field.featureImportance'),
            width: 304,
            listType: 'featureImportance'
          },
          {
            id: 5,
            key: 'route',
            label: this.$t('training.result.list.field.route'),
            width: 424 + 160,
            listType: 'route'
          }
        ]
      } else {
        return [
          {
            id: 0,
            key: 'id',
            label: '/',
            width: 48,
            listType: 'id'
          },
          {
            id: 1,
            key: 'name',
            label: this.$t('training.result.list.field.name'),
            width: 232,
            listType: 'name'
          },
          {
            id: 2,
            key: this.mainMetrics,
            label: this.metricsDefs[this.mainMetrics].name,
            width: 232,
            listType: 'accuracy'
          },
          {
            id: 3,
            key: this.selectedOtherMetrics.value,
            label: this.selectedOtherMetrics.name,
            width: 240,
            listType: 'otherMetrics'
          },
          {
            id: 4,
            key: 'featureImportance',
            label: this.$t('training.result.list.field.featureImportance'),
            width: 304,
            listType: 'featureImportance'
          },
          {
            id: 5,
            key: 'route',
            label: this.$t('training.result.list.field.route'),
            width: 424 + 160,
            listType: 'route'
          }
        ]
      }
    },
    otherMetrics() {
      if (!this.bestTrainedAi.summary?.metrics) return []
      const res = []
      for (const key in this.bestTrainedAi.summary.metrics[
        this.selectedColumnIndex
      ]) {
        if (Object.keys(this.metricsDefs).indexOf(key) < 0) continue
        const isTemplateMetrics =
          this.project?.template?.metrics &&
          this.project?.template?.metrics.indexOf(key) >= 0
        if (isTemplateMetrics !== key && !this.metricsDefs[key]?.showList) {
          res.push({
            name: this.metricsDefs[key].name,
            value: key,
            def: this.metricsDefs[key]
          })
        }
      }
      return res
    },
    selectedOtherMetrics() {
      if (this.selectedMetrics.length) {
        return this.otherMetrics.find((x) => x.value === this.selectedMetrics)
      } else {
        return this.otherMetrics[0]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  &-wrap {
    overflow: hidden;
    height: calc(100% - #{$space-medium});
    padding: $space-small;
    margin: 0 $space-medium $space-medium;
    background: #fff;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
  }
  &-body {
    width: 100%;
    height: 100%;
    margin: 0 0 $space-large;
  }
  &-name {
    &-block {
      position: sticky;
      top: 0;
      display: flex;
      align-items: center;
      height: adjustVH(64);
      padding: 0 $space-medium;
      margin: 0 $space-sub + $space-base 0 0;
      background: $background;
      z-index: 1;
    }
    &-inner {
      --width: adjustVW(120);
      flex-grow: 1;
      width: var(--width);
      padding: 0 $space-medium 0 0;
    }
    &-item {
      display: flex;
      width: 100%;
      padding: $space-small 0;
      padding-left: 0;
      text-align: left;
      transition: $transition-base;
      &-id {
        .list-name-item-text {
          width: 100%;
          text-align: center;
        }
      }
      &-icon {
        margin: 0 0 0 $space-base;
        opacity: 0;
        transition: $transition-base;
        .list-name-item:hover & {
          opacity: 1;
        }
      }
      &-select {
        height: adjustVH(32);
        padding: 0;
      }
    }
  }
  &-data {
    &-wrap {
      overflow-y: scroll;
      height: calc(100% - #{adjustVH(64)});
      padding: 0 $space-sub 0 0;
      @include scrollbar;
    }
    &-inner {
      --width: adjustVW(120);
      overflow: hidden;
      flex-grow: 1;
      width: var(--width);
      padding: 0 $space-medium 0 0;
    }
    &-item {
      display: block;
      width: 100%;
      padding: $space-small 0;
      padding-left: 0;
      text-align: left;
      &-title {
        margin: 0 0 $space-base;
      }
      &-center {
        text-align: center;
      }
      &-route {
        overflow-y: scroll;
        padding: 0 $space-sub 0 0;
        text-align: left;
        @include scrollbar;
      }
      &-id {
        text-align: center;
      }
    }
    &-button {
      position: relative;
      display: block;
      width: 100%;
      margin: 0 0 $space-small;
      &:hover {
        opacity: 1;
      }
      &-inner {
        display: flex;
        align-items: center;
        padding: $space-small $space-medium;
        background: $background;
        transition: $transition-base;
        &:hover {
          background: $key-sub;
          border-radius: adjustVW(16);
        }
        &:last-child {
          margin: 0;
        }
      }
      &:last-child {
        margin: 0;
      }
    }
    &-list {
      display: flex;
      align-items: center;
      margin: 0 0 $space-base;
      &::before {
        content: '';
        display: block;
        width: adjustVW(12);
        height: adjustVW(12);
        margin: 0 $space-base 0 0;
        background: $key-color;
        border-radius: 9in;
      }
      &:last-child {
        margin: 0;
      }
      &-inner {
        overflow: hidden;
        width: calc(100% - #{adjustVW(20)});
        color: $text-main;
        font-size: $text-base;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include text-crop;
      }
    }
  }
  &-menu-icon {
    display: flex;
    align-items: center;
    opacity: 0;
    transition: $transition-base;
    .list-data-block:hover &,
    .list-data-button:hover & {
      opacity: 1;
    }
    &-item {
      padding: $space-sub;
      margin: 0 $space-small 0 0;
      background: $background;
      border-radius: adjustVW(8);
      transition: $transition-base;
      &:hover {
        background: $key-lite;
      }
    }
    &-link {
      display: inline-block;
    }
  }
}
</style>
