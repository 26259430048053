<template>
  <div
    class="trained-ai-list-wrap"
    :class="
      layoutType === 'list' || layoutType === 'optimization'
        ? 'trained-ai-list-wrap-list'
        : ''
    "
  >
    <trained-ai-list-top
      v-bind="pageTop"
      :accountInfo="accountInfo"
      :layoutType="layoutType"
      :checked="checked"
      :project="project"
      :projectId="projectId"
      :allTrainedAILoading="allTrainedAILoading"
      :trainedAiFiltered="trainedAiFiltered"
      :checkIncludeOptimization="checkIncludeOptimization"
      @layout-menu-click="layoutMenuClick"
      @delete-menu-click="$emit('delete-menu-click', $event)"
      @add-new-trained-ai="$emit('add-new-trained-ai')"
      @project-menu-click="$emit('project-menu-click', $event)"
      @inference-menu-click="$emit('inference-menu-click', $event)"
      @service-menu-click="$emit('service-menu-click', $event)"
      @project-create-menu-click="
        $emit('project-menu-click', { ...$event, newProject: true })
      "
      @input="search"
    />
    <div
      class="trained-ai-list-table"
      :class="{
        'trained-ai-list-table-list':
          layoutType === 'list' || layoutType === 'optimization'
      }"
    >
      <trained-ai-list-list
        v-model="checked"
        class="trained-ai-list-table-block"
        :trainedAIs="trainedAiFiltered"
        :trainedAILoading="trainedAILoading"
        :layoutType="layoutType"
        :project="project"
        :noSearchResults="noSearchResults"
      />
    </div>
  </div>
</template>

<script>
import trainedAiListTop from './trained-ai-list-top'
import trainedAiListList from './trained-ai-list-list'

export default {
  components: {
    trainedAiListTop,
    trainedAiListList
  },
  data() {
    return {
      layoutType: 'grid',
      checked: [],
      trainedAiResults: null,
      noSearchResults: false
    }
  },
  props: {
    accountInfo: Object,
    pageTop: Object,
    pageBody: Object,
    trainedAIs: Object,
    trainedAILoading: Boolean,
    allTrainedAILoading: Boolean,
    project: Object,
    projectId: Number
  },
  methods: {
    layoutMenuClick(e) {
      this.layoutType = e
      this.$emit('layout-menu-click', e)
    },
    search(e) {
      this.trainedAiResults = e.result
      this.noSearchResults = e.noSearchResults
    }
  },
  computed: {
    trainedAiFiltered() {
      if (!this.trainedAiResults) {
        return Object.values(this.trainedAIs)
      } else {
        return this.trainedAiResults
      }
    },
    checkIncludeOptimization() {
      if (!this.trainedAIs) return false
      return Object.values(this.trainedAIs).some((ai) => {
        return ai?.is_optimization
      })
    }
  },
  watch: {
    checked(newVal) {
      this.$emit('input', newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.trained-ai-list-wrap {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  grid-row-gap: $space-base;
  height: auto;
  padding: 0 $space-medium $space-medium;
  &-list {
    grid-template-rows: auto 1fr;
    height: calc(100vh - #{adjustVH($headerTabHeight)});
  }
}
.trained-ai-list-table {
  height: 100%;
  &-block {
    height: 100%;
  }
  &-list {
    overflow: hidden;
    box-shadow: $box-shadow-main;
  }
}
</style>
