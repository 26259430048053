<template>
  <div class="config-wrap">
    <div class="config-top">
      <config-top
        :config="config"
        :disableReasons="disableReasons"
        :disableOptimization="disableOptimization"
        :trainingProgressStatus="trainingProgressStatus"
        :message="message"
        :errorManual="errorManual"
        :errorDetail="errorDetail"
        :isSaved="isSaved"
        :isStopped="isStopped"
        :isNotSaved="isNotSaved"
        :inTraining="inTraining"
        @training-start="trainingStart"
        @training-stop="$emit('training-stop')"
        @background-training-stop="$emit('background-training-stop')"
        @save-trained-ai="$emit('save-trained-ai')"
        @page-back="$emit('page-back')"
        @open-error-detail="$emit('open-error-detail')"
      />
    </div>
    <div class="config-body">
      <config-body
        v-model="config"
        :rawPredictionColumnOptions="rawPredictionColumnOptions"
        :selectedTrainingData="selectedTrainingData"
        :selectedTrainingDataSample="selectedTrainingDataSample"
        :selectedTrainingDataType="selectedTrainingDataType"
        :selectedRecipe="selectedRecipe"
        :recipeType="recipeType"
        :nTestTrain="nTestTrain"
        :nIters="nIters"
        :TOO_FEW_TH="TOO_FEW_TH"
        :tooFewTestData="tooFewTestData"
        :tooFewTrainData="tooFewTrainData"
        :charts="charts"
        :chartsData="chartsData"
        :trainingProgressStatus="trainingProgressStatus"
        :elapsedTime="elapsedTime"
        :trainingNumIter="trainingNumIter"
        :trainingProgress="trainingProgress"
        :optimizationNumIter="optimizationNumIter"
        :optimizationProgress="optimizationProgress"
        :sortedTrainedAis="sortedTrainedAis"
        :bestParams="bestParams"
        :trials="trials"
        :finishColumns="finishColumns"
        :selectedColumnIndex="selectedColumnIndex"
        :optimizationInfo="optimizationInfo"
        :checkOptimization="checkOptimization"
        :isNotSaved="isNotSaved"
        :disableOptimization="disableOptimization"
        :learningPredictionColumns="learningPredictionColumns"
        :timeTransformerV2Filter="timeTransformerV2Filter"
        :showTimeTransformerV2DetailSetting="showTimeTransformerV2DetailSetting"
        :timeTransformerV2Setting="timeTransformerV2Setting"
        :accountInfo="accountInfo"
        @training-stop="$emit('training-stop')"
        @show-graph="$emit('show-graph', $event)"
        @check-expressions="$emit('check-expressions', $event)"
        @show-optimization-tutorial="$emit('show-optimization-tutorial')"
        @click-time-transformer-v2-filter="clickTimeTransformerV2Filter"
        @show-time-transformer-v2-detail="showTimeTransformerV2Detail"
        @show-time-transformer-before-setting="
          $emit('show-time-transformer-before-setting')
        "
      />
    </div>
  </div>
</template>

<script>
import configTop from './config/config-top'
import configBody from './config/config-body'
import { validateInputColumns } from '@/lib/training.js'

import {
  setByColumnConditions,
  setByExpressions,
  validConditions
} from '@/lib/optimization'

export default {
  components: {
    configTop,
    configBody
  },
  data() {
    return {
      config: {
        trainConfig: {
          learning_rate: 0.0001,
          batch_size: 64,
          epoch: 5,
          data: {
            ratio: 0.1
          },
          predictionColumn: this.checkOptimization ? [] : this.targetColumn,
          inputColumns: this.checkOptimization ? this.targetColumn : [],
          saver: {
            evaluate_every: {
              train: 10,
              test: 30
            },
            num_checkpoints: 5
          },
          columnConditions: [],
          overrideInputColumns: {},
          overrideColumnConditions: {},
          timeseries: {
            forecastTimeUnitCount: null,
            forecastTimeUnit: null,
            maxEncoderLength: 14, // 入力する列の長さ
            maxPredictionLength: 7 // 予測する列の長さ
          },
          computingEnvironment: null
        },
        optimizationConditions: {
          columnConditions: [],
          expressions: [],
          optimizationColumns: this.targetColumn
        }
      },
      columnConditionsSet: [],
      expressionsSet: [],
      timeTransformerV2Filter: [],
      showTimeTransformerV2DetailSetting: false
    }
  },
  computed: {
    // 学習に使用するデータセットのタイプ判定
    selectedTrainingDataType() {
      let dataType = null
      if (this.selectedTrainingData?.nRows > 0) {
        if (dataType != null) return null
        dataType = 'figures'
      }
      if (this.selectedTrainingData?.nImages > 0) {
        if (dataType != null) return null
        dataType = 'images'
      }
      if (this.selectedTrainingData?.nTexts > 0) {
        if (dataType != null) return null
        dataType = 'texts'
      }
      return dataType
    },
    // テストデータが少なすぎるかのテスト
    tooFewTestData() {
      return this.nTestTrain[0] < this.TOO_FEW_TH
    },
    // 学習データが少なすぎるかのテスト
    tooFewTrainData() {
      return this.nTestTrain[1] < this.TOO_FEW_TH
    },
    // テストデータの比率を変更する関数
    nTestTrain() {
      if (!this.selectedTrainingData) {
        return [0, 0]
      }
      let nData
      if (this.selectedTrainingData.type === 'unstructured') {
        nData = parseInt(this.selectedTrainingData.nLabels)
      } else {
        nData = parseInt(this.selectedTrainingData.nRows)
      }
      const ratio = parseFloat(this.config.trainConfig.data.ratio)

      const test = Math.ceil(nData * ratio)
      const train = nData - test
      return [test, train]
    },
    // 学習データの数が少ない場合に表示するエラーの閾値２で固定
    TOO_FEW_TH() {
      return 2
    },
    // 学習回数を計算
    nIters() {
      const epoch = this.config.trainConfig.epoch
      const batchSize = this.config.trainConfig.batch_size
      let nTrains
      if (this.config.trainConfig.data.nTrains) {
        nTrains = this.config.trainConfig.data.nTrains
      } else {
        nTrains = this.nTestTrain[1] ? this.nTestTrain[1] : 0
      }
      const nIters = Math.floor((epoch * nTrains) / batchSize)
      return nIters
    },
    // エラー文言
    disableReasons() {
      const res = []
      // 数値の場合で、一つも学習データを選択していない場合
      if (
        this.selectedTrainingDataType === 'figures' &&
        !this.checkOptimization
      ) {
        if (
          !validateInputColumns(
            this.config.trainConfig.inputColumns,
            this.config.trainConfig.overrideInputColumns,
            this.config.trainConfig.predictionColumn
          ) &&
          !this.isNotSaved &&
          !this.inTraining
        ) {
          res.push(this.$t('training.errors.noInputColumn'))
        }
      }
      if (this.selectedTrainingDataType != null) {
        // 学習データと訓練データの数が小さすぎる場合
        if (this.tooFewTrainData) {
          res.push(
            this.$t('training.errors.tooFewTrainData', { num: this.TOO_FEW_TH })
          )
        }
        if (this.tooFewTestData) {
          res.push(
            this.$t('training.errors.tooFewTestData', { num: this.TOO_FEW_TH })
          )
        }
      }
      if (
        this.recipeType === 'TIME' &&
        this.config.trainConfig.inputColumns.length > 1
      ) {
        res.push(this.$t('training.message.timeseriesColumnsLength'))
      }
      /**
       * 学習済みモデルの数が最大数に達しているかチェック
       * 実装時に対応いただければと思います。
      if (this.accountInfo.planDetail.numModel > 0 && this.ownModels.length >= this.accountInfo.planDetail.numModel) {
        res.push(this.$t('learning.message.learnRestriction'))
      }
      */
      return res
    },
    disableOptimization() {
      const res = []
      if (this.checkOptimization) {
        const optimizationValid = validConditions(
          this.config.optimizationConditions,
          this.optimizationInfo.validExpressions,
          this.isNotSaved,
          this.inTraining
        )
        if (optimizationValid.length > 0) {
          res.push(...optimizationValid)
        }
      }
      return res
    },
    cautionTraining() {
      /**
       * 学習を実行すると学習済みモデルの最大数を超えてしまう場合のチェック
       * 例として現在の学習済みモデルの数が４で、これから作成する学習済みモデルの数が、
       * ３件の場合、学習するを押下してポップアップを出す。
       * trueの場合はポップアップが出るようになっています
       * 実装時に対応いただければと思います。
       */
      return false
    },
    isNotSaved() {
      return (
        this.trainingProgressStatus === 'finishTraining' &&
        !this.isStopped &&
        !this.isSaved
      )
    },
    inTraining() {
      return (
        ['training', 'preparingEnvironment', 'stoppingTraining'].indexOf(
          this.trainingProgressStatus
        ) >= 0
      )
    }
  },
  methods: {
    trainingStart() {
      const emitConfig = JSON.parse(JSON.stringify(this.config))
      if (this.checkOptimization) {
        emitConfig.optimizationConditions.columnConditions =
          emitConfig.optimizationConditions.columnConditions.map(
            (condition) => {
              return {
                column: condition.column,
                condition: condition.condition,
                integer: condition.disabledInteger ? false : condition.integer
              }
            }
          )
      }
      this.$emit('training-start', emitConfig)
    },
    clickTimeTransformerV2Filter(columns) {
      this.timeTransformerV2Filter = columns
    },
    showTimeTransformerV2Detail(e) {
      this.showTimeTransformerV2DetailSetting = e
      this.timeTransformerV2Filter = []
    }
  },
  props: {
    rawPredictionColumnOptions: Object,
    targetColumn: Array,
    selectedTrainingData: Object,
    selectedTrainingDataSample: Object,
    selectedRecipe: Object,
    recipeType: String,
    accountInfo: Object,
    charts: Object,
    chartsData: Object,
    trainingProgressStatus: String,
    elapsedTime: Number,
    trainingNumIter: Number,
    trainingProgress: Number,
    optimizationNumIter: Number,
    optimizationProgress: Number,
    sortedTrainedAis: Array,
    message: String,
    errorManual: String,
    errorDetail: Object,
    isSaved: Boolean,
    isStopped: Boolean,
    bestParams: Object,
    trials: Array,
    finishColumns: Array,
    selectedColumnIndex: Number,
    optimizationInfo: Object,
    checkOptimization: Boolean,
    learningPredictionColumns: Array,
    timeTransformerV2Setting: Object
  },
  watch: {
    'config.optimizationConditions.columnConditions': {
      handler(newVal) {
        if (!this.checkOptimization) return
        this.columnConditionsSet = setByColumnConditions(
          newVal,
          this.targetColumn
        )

        const set = new Set([...this.columnConditionsSet, ...this.expressionsSet])

        this.config.trainConfig.predictionColumn = Array.from(set)
      },
      deep: true
    },
    'config.optimizationConditions.expressions': {
      handler(newVal) {
        if (!this.checkOptimization) return
        this.expressionsSet = setByExpressions(
          newVal,
          this.targetColumn,
          this.selectedTrainingData.dtypes
        )

        const set = new Set([...this.columnConditionsSet, ...this.expressionsSet])

        this.config.trainConfig.predictionColumn = Array.from(set)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.config {
  &-wrap {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-row-gap: $space-base;
    height: 100%;
  }
  &-top {
    padding: 0 $space-medium;
  }
  &-body {
    overflow: hidden;
    padding: 0 $space-medium $space-medium;
  }
}
</style>
