<template>
  <div class="dots-list">
    <template v-for="index in totalCount">
      <button
        :key="index"
        class="dots-item"
        :class="{ 'dots-item-active': index === value }"
        @click="updateValue(index)"
      />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    /** v-modelで渡す現在いるページ */
    value: Number,
    /** トータルのページ数 */
    totalCount: Number
  },
  methods: {
    updateValue(index) {
      this.$emit('input', index)
    }
  }
}
</script>

<style lang="scss" scoped>
.dots {
  &-list {
    display: flex;
    justify-content: center;
    grid-column-gap: $space-sub;
  }
  &-item {
    flex-shrink: 1;
    width: adjustVW(12);
    height: adjustVW(12);
    background: $border-gray;
    border-radius: 9in;
    transition: background-color $transition-base;
    &-active {
      background: $text-decoration;
    }
    &-caution {
      background: $text-caution;
    }
    &-sub {
      background: $green;
    }
  }
}
</style>
