<template>
  <div class="wrap">
    <div class="inner">
      <img class="image" :src="encodedImage" >
    </div>
    <button class="icon" @click="showImageDetail()">
      <icons iconName="popup" color="function" />
    </button>
    <popup-array
      :showPopup="showPopup"
      :popupDefs="popupDefs"
      @close-modal="closeModal($event)"
    >
      <template #preview>
        <div class="popup-wrap">
          <div class="popup-inner" :style="{ 'background-image': urlImage }" />
        </div>
      </template>
    </popup-array>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import popupArray from '@/components/molecules/popup/popup-array.vue'

export default {
  components: {
    icons,
    popupArray
  },
  data() {
    return {
      showPopup: []
    }
  },
  props: {
    /** 画像パスまたはbase64で変換された画像 */
    image: String
  },
  methods: {
    showImageDetail() {
      this.showPopup.push('preview')
    },
    closeModal(e) {
      // ポップアップを閉じる
      this.showPopup = this.showPopup.filter((n) => n !== e)
    }
  },
  computed: {
    encodedImage() {
      return encodeURI(this.image)
    },
    urlImage() {
      return 'url("' + encodeURI(this.image) + '")'
    },
    popupDefs() {
      return {
        preview: {
          title: this.$t('common.imagePreview'),
          maxHeight: true
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  width: adjustVW(552);
  height: adjustVW(311);
  padding: $space-base;
  background: $background-sub;
  border-radius: adjustVW(16);
}
.inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: $background;
  border-radius: adjustVW(16);
}
.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.icon {
  position: absolute;
  right: $space-medium;
  bottom: $space-medium;
  display: flex;
  align-items: center;
  justify-content: center;
  width: adjustVW(48);
  height: adjustVW(48);
  background: $button-primary;
  border-radius: 9in;
  cursor: pointer;
  box-shadow: $box-shadow-hover;
  transition: opacity $transition-base;
  will-change: opacity;
  &:hover {
    opacity: 0.8;
  }
}
.popup {
  &-wrap {
    width: 100vw - (adjustVW(240) + $space-small * 1 + $space-medium * 4);
    height: 100%;
    padding: $space-medium;
    background-color: $background-sub;
  }
  &-inner {
    width: 100%;
    height: 100%;
    filter: drop-shadow(0 4px 16px rgba(43, 43, 44, 0.05));
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
</style>
