export function checkOutputShape(sourceNode, targetNode) {
  // sourcNode and targetNode are cy objects.
  const sourceData = sourceNode.data()
  const outputSize = sourceData.outputSize
  if (outputSize && outputSize.length > 1) {
    return true
  }
  return false
}

export function checkMatchLabelSize(sourceNode, targetNode) {
  // sourcNode and targetNode are cy objects.
  const incomers = targetNode.incomers('edge')
  let nClass = null
  let outputSize = null
  incomers.forEach((edge) => {
    const sourceData = edge.source().data()
    const sourceName = sourceData.name
    if (sourceName === 'fc') {
      outputSize = sourceData.params.outSize.value
    } else if (sourceName === 'inputLabels') {
      nClass = sourceData.params.nClass.value
    }
  })
  if (nClass && outputSize && nClass !== outputSize) {
    return true
  }
  return false
}

export function checkNeedRegression(sourceNode, targetNode) {
  // sourcNode and targetNode are cy objects.
  const targetNodeData = targetNode.data()
  const type = targetNodeData?.type
  if (type === 'auto') {
    const check = targetNodeData?.params?.type?.value !== 'regression'
    if (check && targetNode.incomers('edge').length > 0) {
      targetNode.incomers('edge').remove()
    }
    return check
  } else {
    return targetNodeData?.type !== 'regression'
  }
}

export function checkNeedClassification(sourceNode, targetNode) {
  // sourcNode and targetNode are cy objects.
  const targetNodeData = targetNode.data()
  const type = targetNodeData?.type

  if (type === 'auto') {
    const check = targetNodeData?.params?.type?.value !== 'classification'

    if (check && targetNode.incomers('edge').length > 0) {
      targetNode.incomers('edge').remove()
    }

    return check
  } else {
    return targetNodeData?.type !== 'classification'
  }
}

export function checkMultiBlocs(sourceNode, targetNode) {
  // sourcNode and targetNode are cy objects.
  const edges = sourceNode.outgoers('edges')
  let count = 0
  edges.forEach((edge) => {
    const classes = edge.classes()
    if (!classes.includes('eh-ghost')) {
      count = ++count
    }
  })
  return count > 1
}
