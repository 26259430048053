<template>
  <table-list
    v-model="_checked"
    is-button
    :field="field"
    :items="recipeList"
    :noSearchResults="noSearchResults"
    defaultSortedBy="updateTime"
    id-column="fullId"
    @list-click="listClicked"
    @icon-menu-click="$emit('icon-menu-click', $event)"
  />
</template>
<script>
import tableList from '@/components/molecules/table-list'

export default {
  components: {
    tableList
  },
  data() {
    return {}
  },
  props: {
    recipesFiltered: Array,
    checked: Array,
    isTrain: Boolean,
    noSearchResults: Boolean
  },
  model: { prop: 'checked', event: 'input' }, // v-modelにしたとき、結びつける先のpropとイベント
  computed: {
    _checked: {
      get() {
        return this.checked
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    recipeList() {
      const recipeList = []
      for (const value of this.recipesFiltered) {
        recipeList.push({
          fullId: value.fullId,
          name: value.name,
          description: value.description,
          accountId: value.accountId,
          updateTime: value.updateTime
          // notImplemented: this.$t('common.inDevelopment')
        })
      }
      return recipeList
    },
    field() {
      return [
        {
          key: 'name',
          label: this.$t('recipeList.field.name'),
          thClass: 'name',
          listType: 'name',
          sortable: true
        },
        // レシピのDBの改修が終わるまで待ち（https://trello.com/c/7tsGk4h5/243-%E3%83%AC%E3%82%B7%E3%83%94%E6%AE%8B%E3%82%BF%E3%82%B9%E3%82%AF）
        /*
        {
          key: 'notImplemented',
          label: this.$t('recipeList.field.beingUsed')
        },
        */
        {
          key: 'accountId',
          label: this.$t('common.owner'),
          thClass: 'default',
          listType: 'default',
          sortable: true
        },
        {
          key: 'updateTime',
          label: this.$t('common.updateTime'),
          thClass: 'default',
          listType: 'time',
          sortable: true
        }
      ]
    }
  },
  methods: {
    listClicked(card) {
      if (!this.isTrain) {
        this.$router.push({ name: 'recipeDetail', params: { id: card.fullId } })
      } else {
        this.$router.push({
          name: 'trainRecipeDetail',
          params: { id: card.fullId }
        })
      }
    }
  }
}
</script>
