<template>
  <popup
    :showPopup="showPopup"
    :title="title"
    maxWidth
    @close-modal="$emit('close-modal', $event)"
    @popup-inner-click="closePreprocessingDetail"
  >
    <div class="preprocess-detail-wrapper">
      <div class="preprocess-detail-about">
        <texts :text="about" />
      </div>

      <div class="preprocess-detail-chart">
        <preprocessing-chart-list ref="preprocessingList" :layers="layers" />
      </div>
    </div>
  </popup>
</template>

<script>
import { popup } from '@/components/molecules/popup'
import preprocessingChartList from '@/components/molecules/preprocessing-chart-list.vue'
import texts from '@/components/atoms/text.vue'

export default {
  components: {
    popup,
    preprocessingChartList,
    texts
  },
  props: {
    about: String,
    layers: Array,
    showPopup: {
      type: Boolean,
      default: false
    },
    title: String
  },
  computed: {},
  methods: {
    closePreprocessingDetail() {
      this.$refs.preprocessingList.popupClose()
    }
  }
}
</script>

<style lang="scss" scoped>
.preprocess-detail {
  &-wrapper {
    min-width: adjustVW($baseWidth - 240) - ($space-medium * 4);
  }

  &-about {
    margin-bottom: $space-medium;
  }

  &-chart {
    width: 100%;
  }
}
</style>
