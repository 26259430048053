<template>
  <popup-array
    :showPopup="showPopup"
    :popupDefs="popupDefs"
    :noClose="disableClick"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #deletePreprocessing>
      <popup-delete-preprocessing
        v-bind="preprocessing"
        :disableClick="disableClick"
        @cancel-delete="$emit('close-modal', 'deletePreprocessing')"
        @delete-preprocessing="$emit('delete-preprocessing')"
      />
    </template>
    <template #selectDataset>
      <popup-select-dataset
        :datasetList="datasetList"
        :preprocessing="preprocessing"
        @apply-dataset="$emit('apply-dataset', $event)"
        @click-dataset="$emit('click-dataset', $event)"
      />
    </template>
    <template #checkColumn>
      <popup-check-column
        :checkDataset="checkDataset"
        :preprocessing="preprocessing"
      />
    </template>
  </popup-array>
</template>

<script>
import popupDeletePreprocessing from './popup/delete-preprocessing.vue'
import popupSelectDataset from './popup/select-dataset.vue'
import popupCheckColumn from './popup/check-column.vue'
import PopupArray from '@/components/molecules/popup/popup-array.vue'

export default {
  components: {
    popupDeletePreprocessing,
    popupSelectDataset,
    popupCheckColumn,
    PopupArray
  },
  props: {
    preprocessing: Object,
    datasetList: Array,
    showPopup: Array,
    checkDataset: Object,
    disableClick: Boolean
  },
  computed: {
    popupDefs() {
      return {
        deletePreprocessing: {
          title: this.$t('common.deleteConfirm')
        },
        selectDataset: {
          title: this.$t('preprocessing.selectDatasetTitle')
        },
        checkColumn: {
          title: this.checkDataset.name
        }
      }
    }
  }
}
</script>
