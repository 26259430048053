import { render, staticRenderFns } from "./project-template-select-mode.vue?vue&type=template&id=5b86f387&scoped=true&"
import script from "./project-template-select-mode.vue?vue&type=script&lang=js&"
export * from "./project-template-select-mode.vue?vue&type=script&lang=js&"
import style0 from "./project-template-select-mode.vue?vue&type=style&index=0&id=5b86f387&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b86f387",
  null
  
)

export default component.exports