<template>
  <card-base>
    <div class="config-wrap">
      <div class="config-body">
        <div v-if="columnList && columnList.length > 1" class="config-item">
          <div class="config-item-title">
            <texts
              :text="$t('trainedAi.multi.selectedColumn')"
              size="small"
              color="gray"
            />
          </div>
          <select-box
            v-model="internalColumnName"
            :items="columnList"
            isGray
            scrollBar
            :isDisabled="waitTextMiningLoading"
          />
        </div>
        <div class="config-item">
          <div class="config-item-title">
            <texts
              :text="$t('textMining.textTopic.config.topic')"
              size="small"
              color="gray"
            />
          </div>
          <select-box
            v-model="targetTopic"
            :items="topicList"
            isGray
            scrollBar
            :isDisabled="waitTextMiningLoading"
          />
        </div>
        <div class="config-item">
          <adjust-slider-input
            :value="totalCount"
            :max="maxCount"
            :min="1"
            :title="$t('textMining.coOccurrence.config.maxShow')"
            :isDisabled="waitTextMiningLoading"
            @change="updateCount"
          />
        </div>
      </div>
    </div>
  </card-base>
</template>

<script>
import cardBase from '@/components/atoms/card-base'
import selectBox from '@/components/molecules/select-box'
import adjustSliderInput from '@/components/molecules/adjust-slider-input'

export default {
  components: {
    cardBase,
    selectBox,
    adjustSliderInput
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    topicCount: {
      type: Number,
      default: 0
    },
    columns: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: null
    },
    columnName: {
      type: String,
      default: ''
    },
    waitTextMiningLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fixTotal: 50
    }
  },
  methods: {
    updateValue(type, value) {
      const payload = this.value
      payload[type] = value
      this.$emit('input', payload)
    },
    updateCount(value) {
      this.totalCount = value
    }
  },
  computed: {
    internalColumnName: {
      get() {
        return this.columnName
      },
      set(newVal) {
        this.$emit('change-column-name', newVal)
      }
    },
    targetTopic: {
      get() {
        return this.value.targetTopic ?? 1
      },
      set(newVal) {
        this.updateValue('targetTopic', newVal)
      }
    },
    totalCount: {
      get() {
        return this.value?.maxWords ?? Math.min(50, this.fixTotal)
      },
      set(newVal) {
        this.updateValue('maxWords', newVal)
      }
    },
    columnList() {
      return this.columns.map((column) => {
        return {
          value: column,
          name: column
        }
      })
    },
    topicList() {
      const list = [...Array(this.topicCount)].map((_, index) => {
        const count = index + 1
        return {
          value: count.toString(),
          name: count.toString()
        }
      })
      return list
    },
    maxCount() {
      return Math.min(200, this.fixTotal)
    }
  },
  mounted() {
    this.fixTotal = this.total
  },
  watch: {
    total(newVal) {
      if (this.waitTextMiningLoading) return
      this.fixTotal = newVal
    }
  }
}
</script>

<style lang="scss" scoped>
.config {
  &-body {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(3, adjustVW(264));
    grid-column-gap: $space-medium;
  }
  &-item {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    width: 100%;
    &-title {
      margin-bottom: $space-text;
    }
  }
}
</style>
