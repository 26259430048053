<template>
  <div class="wrap">
    <organization-top size="min" />
    <organization-card-list :accountInfo="accountInfo" />
  </div>
</template>

<script>
import organizationTop from '@/components/organisms/organization/organization-top'
import organizationCardList from './organization-card-list'

export default {
  components: {
    organizationTop,
    organizationCardList
  },
  props: {
    accountInfo: Object
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: $space-base;
  height: calc(100vh - #{adjustVH($headerTabHeight)});
}
</style>
