<!--
  推論ページで推論結果を表示する際に使用するページング
  もっといいv-ifのだしわけの方法がわかる方は修正いただけると助かります。
-->

<template>
  <div
    class="wrap"
    :class="{
      'wrap-disabled': isDisabled
    }"
  >
    <icons
      iconName="backward"
      :isButton="currentPage > 1"
      size="small"
      :color="currentPage > 1 ? '' : 'gray'"
      class="icon-backward button"
      @icon-click="changePage(currentPage - 1)"
    />
    <div class="list">
      <!-- 400 以上 -->
      <template v-for="(page, index) in checkPages">
        <div
          v-if="checkNumber(page)"
          :key="index"
          class="item button"
          @click="page === currentPage ? '' : changePage(page)"
        >
          <texts
            :text="checkPage(page)"
            :color="page === currentPage ? 'emphasis' : 'gray'"
          />
        </div>
        <div v-else :key="index" class="item">
          <texts :text="checkPage(page)" />
        </div>
      </template>
    </div>
    <icons
      iconName="forward"
      :isButton="pageCount - currentPage > 0"
      size="small"
      :color="pageCount - currentPage > 0 ? '' : 'gray'"
      class="icon-forward button"
      @icon-click="changePage(currentPage + 1)"
    />
    <loading-icon v-if="isLoading" class="icon-loading" />
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import texts from '@/components/atoms/text'
import loadingIcon from '@/components/atoms/loading-icon'

export default {
  components: {
    icons,
    texts,
    loadingIcon
  },
  props: {
    /** 取得したデータの件数 */
    count: Number,
    /** v-modelで返す値 */
    value: Number,
    /** ページングで表示する件数 */
    pagingCount: Number,
    /** 使用不可にする */
    isDisabled: Boolean,
    /** 読み込み中としてloadingアイコンを表示する */
    isLoading: { type: Boolean, default: false }
  },
  computed: {
    /** 現在いるページ */
    currentPage: {
      get() {
        return this.value ? this.value : 1
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    /** 最大ページ数 */
    pageCount() {
      return Math.ceil(this.count / this.pagingCount)
    },
    /** 現在表示しているページ */
    checkPages() {
      if (this.pageCount > 5) {
        if (this.pageCount - this.currentPage === 0) {
          // 最終ページ
          return [1, '…', this.pageCount - 2, this.pageCount - 1, this.pageCount]
        } else if (this.currentPage === 1) {
          // 1ページ目
          return [1, 2, 3, '…', this.pageCount]
        } else if (this.currentPage === 2) {
          // 2ページ目
          return [1, 2, 3, '…', this.pageCount]
        } else if (this.currentPage === 3) {
          // 2ページ目

          // prettier-ignore
          /* eslint-disable */
          /* format で崩れるので、一時的に無効 */
          return [
            1,
            2,
            3,
            4,
            '…',
            this.pageCount
          ]
          /* eslint-enable */
        } else if (this.pageCount - this.currentPage === 1) {
          // 最終ページ１ページ前
          return [1, '…', this.pageCount - 2, this.pageCount - 1, this.pageCount]
        } else if (this.pageCount - this.currentPage === 2) {
          // 最終ページ２ページ前
          return [
            1,
            '…',
            this.currentPage - 1,
            this.currentPage,
            this.currentPage + 1,
            this.pageCount
          ]
        } else if (this.currentPage > 3) {
          // 2ページ目以降
          return [
            1,
            '…',
            this.currentPage - 1,
            this.currentPage,
            this.currentPage + 1,
            '…',
            this.pageCount
          ]
        }
        return [1, 2, 3, '…', this.pageCount]
      } else {
        // データ数が400以下の場合
        const arr = []
        Array(6)
          .fill()
          .forEach((e, i) => {
            // ページの存在チェック
            if (this.pageCount - i > -1 && i > 0) {
              arr.push(i)
            }
          })
        return arr
      }
    }
  },
  methods: {
    /** ページの切り替え */
    changePage(n) {
      if (this.isDisabled) return
      this.currentPage = n
      this.$emit('change-page', n)
    },
    /** 現在表示しているページを文字列に置き換え */
    checkPage(n) {
      if (isNaN(n)) {
        return n
      } else {
        if (n === this.pageCount) {
          return (
            n * this.pagingCount - (this.pagingCount - 1) + '-' + this.count
          )
        }
        return (
          n * this.pagingCount -
          (this.pagingCount - 1) +
          '-' +
          n * this.pagingCount
        )
      }
    },
    /** 数値の判定 */
    checkNumber(n) {
      if (isNaN(n)) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  align-items: center;
  transition: opacity $transition-base;
  &-disabled {
    opacity: 0.5;
    .button {
      cursor: not-allowed;
    }
  }
}

.list {
  display: flex;
  align-items: center;
}

.item {
  margin: 0 $space-sub 0 0;
  cursor: pointer;
  user-select: none;
  &:last-of-type {
    margin: 0;
  }
  &.dots {
    cursor: auto;
  }
  &.active {
    cursor: auto;
  }
}

.icon {
  &-backward {
    margin: 0 $space-base 0 0;
  }
  &-forward {
    margin: 0 0 0 $space-base;
  }
  &-loading {
    margin: 0 0 0 $space-base;
  }
}
</style>
