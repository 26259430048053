<template>
  <card-base class="text-mining-sidebar">
    <div class="text-mining-sidebar-title">
      <texts
        :text="$t('trainedAiDetails.featureImportanceTab.togglePage')"
        size="small"
        color="gray"
      />
    </div>
    <div class="text-mining-sidebar-body">
      <div
        v-if="checkSentiments || checkLDA"
        class="text-mining-sidebar-content"
      >
        <component
          :is="checkSentiments ? 'div' : 'button'"
          class="text-mining-sidebar-button"
          :class="{
            'text-mining-sidebar-button-active':
              pageTypeList.TEXT_LIST === displayType && !checkSentiments,
            'text-mining-sidebar-button-disabled': waitTextMiningLoading
          }"
          @click="
            checkSentiments || waitTextMiningLoading
              ? ''
              : changeTab(pageTypeList.TEXT_LIST)
          "
        >
          <text-with-icon
            :text="$t('textMining.typeList.textList')"
            iconName="textList"
            size="small"
            :color="
              pageTypeList.TEXT_LIST === displayType && !checkSentiments
                ? 'emphasis'
                : 'gray'
            "
          />
        </component>
        <div class="text-mining-sidebar-list">
          <button
            v-if="checkSentiments"
            class="text-mining-sidebar-button"
            :class="{
              'text-mining-sidebar-button-active':
                pageTypeList.TEXT_LIST === displayType,
              'text-mining-sidebar-button-disabled': waitTextMiningLoading
            }"
            @click="
              waitTextMiningLoading ? '' : changeTab(pageTypeList.TEXT_LIST)
            "
          >
            <text-with-icon
              :text="$t('trainedAi.textMining.types.sentiments')"
              iconName="sentiments"
              size="small"
              :color="
                pageTypeList.TEXT_LIST === displayType ? 'emphasis' : 'gray'
              "
            />
          </button>
          <button
            v-if="checkLDA"
            class="text-mining-sidebar-button"
            :class="{
              'text-mining-sidebar-button-active':
                pageTypeList.TEXT_TOPIC === displayType,
              'text-mining-sidebar-button-disabled': waitTextMiningLoading
            }"
            @click="
              waitTextMiningLoading ? '' : changeTab(pageTypeList.TEXT_TOPIC)
            "
          >
            <text-with-icon
              :text="$t('textMining.typeList.textTopic')"
              iconName="textTopicDetail"
              size="small"
              :color="
                pageTypeList.TEXT_TOPIC === displayType ? 'emphasis' : 'gray'
              "
            />
          </button>
        </div>
      </div>
      <div v-if="checkLDA" class="text-mining-sidebar-content">
        <button
          class="text-mining-sidebar-button"
          :class="{
            'text-mining-sidebar-button-active':
              pageTypeList.TEXT_TOPIC_DETAIL === displayType,
            'text-mining-sidebar-button-disabled': waitTextMiningLoading
          }"
          @click="
            waitTextMiningLoading
              ? ''
              : changeTab(pageTypeList.TEXT_TOPIC_DETAIL)
          "
        >
          <text-with-icon
            :text="$t('textMining.typeList.textTopicDetail')"
            iconName="textTopic"
            size="small"
            :color="
              pageTypeList.TEXT_TOPIC_DETAIL === displayType
                ? 'emphasis'
                : 'gray'
            "
          />
        </button>
      </div>
      <div v-if="checkCoOccurrence" class="text-mining-sidebar-content">
        <button
          class="text-mining-sidebar-button"
          :class="{
            'text-mining-sidebar-button-active':
              pageTypeList.CO_OCCURRENCE === displayType,
            'text-mining-sidebar-button-disabled': waitTextMiningLoading
          }"
          @click="
            waitTextMiningLoading ? '' : changeTab(pageTypeList.CO_OCCURRENCE)
          "
        >
          <text-with-icon
            :text="$t('trainedAi.textMining.types.coOccurrence')"
            iconName="coOccurrence"
            size="small"
            :color="
              pageTypeList.CO_OCCURRENCE === displayType ? 'emphasis' : 'gray'
            "
          />
        </button>
      </div>
      <div
        v-if="checkWordCloud || checkFrequencies"
        class="text-mining-sidebar-content"
      >
        <button
          class="text-mining-sidebar-button"
          :class="{
            'text-mining-sidebar-button-active':
              pageTypeList.FREQUENCIES_WORDCLOUD === displayType,
            'text-mining-sidebar-button-disabled': waitTextMiningLoading
          }"
          @click="
            waitTextMiningLoading
              ? ''
              : changeTab(pageTypeList.FREQUENCIES_WORDCLOUD)
          "
        >
          <text-with-icon
            :text="$t('textMining.typeList.wordcroudFrequencies')"
            iconName="frequenciesWordcloud"
            size="small"
            :color="
              pageTypeList.FREQUENCIES_WORDCLOUD === displayType
                ? 'emphasis'
                : 'gray'
            "
          />
        </button>
      </div>
    </div>
  </card-base>
</template>

<script>
import cardBase from '@/components/atoms/card-base'
import textWithIcon from '@/components/molecules/text-with-icon'
import { TEXT_MINING_TYPE, TEXT_MINING_PAGE_TYPE } from '@/lib/text-mining'

export default {
  components: {
    cardBase,
    textWithIcon
  },
  props: {
    displayType: {
      type: String,
      default: ''
    },
    typeList: {
      type: Array,
      default: () => []
    },
    waitTextMiningLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    checkSentiments() {
      return this.typeList.includes(TEXT_MINING_TYPE.SENTIMENTS)
    },
    checkCoOccurrence() {
      return this.typeList.includes(TEXT_MINING_TYPE.CO_OCCURRENCE)
    },
    checkLDA() {
      return this.typeList.includes(TEXT_MINING_TYPE.LDA)
    },
    checkFrequencies() {
      return this.typeList.includes(TEXT_MINING_TYPE.FREQUENCIES)
    },
    checkWordCloud() {
      return this.typeList.includes(TEXT_MINING_TYPE.WORDCLOUD)
    },
    pageTypeList() {
      return TEXT_MINING_PAGE_TYPE
    }
  },
  methods: {
    changeTab(value) {
      if (value === this.displayType) return
      this.$emit('change', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.text-mining-sidebar {
  height: 100%;
  &-title {
    margin-bottom: $space-sub;
  }
  &-body {
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-base;
  }
  &-list {
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-base;
    margin-top: $space-base;
    margin-left: $space-sub;
  }
  &-button {
    width: 100%;
    padding: adjustVW(6) $space-sub;
    background-color: $white;
    border-radius: 9in;
    transition: background-color $transition-base, opacity $transition-base;
    &button:hover {
      background-color: $background-sub;
    }
    &-active {
      background-color: $key-lite;
    }
    &-disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}
</style>
