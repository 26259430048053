<template>
  <div
    v-tooltip="inputTooltip"
    class="c-func-select"
    :class="{
      'c-func-select-gray': isGray,
      'c-func-select-min': min,
      'c-func-select-no-icon': noIcon
    }"
  >
    <template v-if="!noIcon">
      <icon
        v-if="!showCloseButton"
        class="c-func-select-icon"
        iconName="toggle"
        :class="{ active: isActive }"
        isButton
        @icon-click="onFocusBox(), $emit('select-box-open')"
      />
      <icon
        v-else-if="showCloseButton"
        ref="iconTarget"
        class="c-func-select-icon c-func-select-icon-close"
        :class="{
          'c-func-select-icon-hide': !isActive || searchKey === ''
        }"
        iconName="close"
        isButton
        :size="18"
        @icon-click="deleteInput(), $emit('select-box-open')"
      />
      <icon
        class="c-func-select-icon-search"
        iconName="search"
        size="small"
        color="gray"
      />
    </template>
    <!-- select-box-open をemitして、viewsで表示する要素を取得する -->
    <input
      ref="inputTarget"
      v-model="searchKey"
      class="c-func-select-button"
      :placeholder="placeholder"
      :class="{
        'c-func-select-button-caution': checkCaution(searchKey) || isCaution,
        'c-func-select-button-disabled':
          checkDisabled(searchKey) || isNotExistItem
      }"
      @click="toggleSelectBox(), $emit('select-box-open')"
      @focus="isInputting = true"
      @blur="isInputting = false"
    >
    <div class="c-func-underline" />
    <div
      class="c-func-select-inner"
      :class="{
        active: isActive && showItems.length > 0,
        scroll: isScroll,
        'show-top': showTop || showTopInList,
        'show-separate': isSeparateBox
      }"
      :style="{
        '--offsetTop': offsetTop + 'px',
        '--offsetLeft': offsetLeft + 'px',
        '--width': inputWidth + 'px'
      }"
      v-on:close="showMenu = false"
    >
      <button
        v-for="(item, index) in showItems"
        :key="index"
        class="c-func-select-item"
        :class="{
          'c-func-select-item-disabled': item.disabled
        }"
        :value="item[valueKey]"
        :disabled="item.disabled"
        @click="toggleSelectBox(item, index)"
      >
        <texts
          size="small"
          :color="item.disabled ? 'gray' : item.caution ? 'caution' : 'default'"
        >
          {{ recipeFallback(item) }}
        </texts>
      </button>
    </div>
  </div>
</template>

<script>
import icon from '@/components/atoms/icon.vue'
import Fuse from 'fuse.js'

export default {
  components: {
    icon
  },
  data() {
    return {
      isActive: false, // 選択項目が開いているかどうか
      selectItem: '', // 選択項目のID
      isInputting: false,
      searchKey: '',
      offsetTop: 0,
      offsetLeft: 0,
      inputWidth: 0,
      inputHeight: 0,
      showTopInList: false
    }
  },
  mounted() {
    this.outerClick(
      window,
      'click',
      function (e) {
        if (
          !this.$el.contains(e.target) &&
          !this.$refs?.iconTarget?.$el !== e.target
        ) {
          this.isActive = false
        }
      }.bind(this)
    )
    if (this.openStart) {
      this.focusInput()
    }
    if (this.value != null) {
      this.setValue(this.value)
    }
    if (this.firstSelectedItem) {
      this.setValue(this.firstSelectedItem)
      this.searchKey = this.firstSelectedItem
    }
    if (this.isSeparateBox) {
      const target = this.$refs.inputTarget.getBoundingClientRect()
      this.inputWidth = target.right - target.left
      this.inputHeight = target.bottom - target.top
      const margin = this.margin
        ? this.margin
        : ((16 / 1920) * 100 * window.innerWidth) / 100 // adjustVW(16)を画面の大きさで再計算
      this.offsetTop = target.top + this.inputHeight / 2 + margin
      this.offsetLeft = target.left - this.marginLeft
    }
  },
  destroyed() {
    if (this._eventRemovers) {
      this._eventRemovers.forEach(function (eventRemover) {
        eventRemover.remove()
      })
    }
  },
  props: {
    /** v-modelなどで渡す値 */
    value: {},
    /** 選択項目として表示する要素 cautionをつけることで赤色にできる */
    items: Array,
    /** スクロールバーを表示 */
    isScroll: Boolean,
    /** 背景が白いときに使用する */
    isGray: Boolean,
    /** 選択項目を取得するときに、初期状態で表示するテキスト */
    placeholder: String,
    /** 高さを指定して小さく表示したいとき */
    min: Boolean,
    /** 上方向にセレクトのポップアップを表示 */
    showTop: Boolean,
    /** emitする対象がvalueではなく他のkeyが必要であれば渡す */
    valueKey: {
      type: String,
      default: () => 'value'
    },
    /** 最初から開いた状態で始める場合 */
    openStart: Boolean,
    /** セレクトボックス内の要素以外も許容する場合 */
    noDisabledItem: Boolean,
    /** 初期状態で選択項目がある場合に、選択項目名を渡す */
    firstSelectedItem: String,
    /** select-box-input-list用 入力内容が重複しているときのエラー判定 */
    isCaution: Boolean,
    /** select-box-input-list用 スクロール領域でセレクトボックスを切られたくないときにtrue */
    isSeparateBox: Boolean,
    /** select-box-input-list用 複数ある入力欄でセレクトボックスを閉じる処理を検知 */
    isCloseItem: Boolean,
    /** select-box-input-list用 セレクトボックスを切られたくないときに、どれだけ余白をあけて表示するかの数値 */
    margin: Number,
    /** select-box-input-list用 セレクトボックスを切られたくないときに、どれだけ左の余白をあけて表示するかの数値 */
    marginLeft: {
      type: Number,
      default: 0
    },
    /** select-box-input-list用 選択項目にない項目が入力されているときにグレーにするかどうか */
    isNotExistItem: Boolean,
    /** ツールチップに表示するテキスト nullであればツールチップは表示されない */
    tipsContents: String,
    /** アイコンを非表示 */
    noIcon: {
      type: Boolean,
      default: false
    },
    /** fuse-searchのオプションを置き換える */
    option: {
      type: Object,
      default: () => {
        return {
          includeScore: true,
          shouldSort: true,
          threshold: 0.2,
          keys: [
            {
              name: 'name',
              weight: 0.5
            }
          ],
          distance: 10000
        }
      }
    },
    /** トグルボタンではなく、入力を消去するボタンを表示（最初から入力がある場合に使用する） */
    showCloseButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleSelectBox(e, index) {
      if (!this.isInputting) {
        this.isActive = false
      } else {
        if (this.isSeparateBox) {
          this.checkPos()
        }
        this.$nextTick(() => {
          this.isActive = true
        })
      }
      if (e !== undefined) {
        this.setValue(e[this.valueKey])
        this.$emit('select-item', { selectItem: e })
        this.$emit('input', e[this.valueKey])
      }
    },
    checkPos() {
      // スクロールバーでセレクトボックスが切り取られないように、fixedで固定する位置の調整
      // このタイミングでは左方向のマージンは固定されているので再計算されない。何かしらの理由で再計算が必要になるなら、岡田に相談して追加して下さい
      const target = this.$refs.inputTarget.getBoundingClientRect()
      const margin = this.margin
        ? this.margin
        : ((16 / 1920) * 100 * window.innerWidth) / 100 // adjustVW(16)を画面の大きさで再計算
      this.offsetTop = target.top + this.inputHeight / 2 + margin
      this.showTopInList = false
    },
    setValue(nv) {
      const i = this.items.findIndex((x) => x[this.valueKey] === nv)
      if (i >= 0) {
        this.selectItem = i
      }
      const keyValue = this.items.find((item) => {
        return item[this.valueKey] === nv
      })
      if (keyValue == null) return
      this.searchKey = keyValue?.name ? keyValue.name : keyValue?.text
    },
    // レシピブロックの中身がtextでvalueを渡してくることがあるので、念のためフォールバック
    recipeFallback(item) {
      if (item?.name != null) {
        return item.name
      } else if (item?.text != null) {
        return item.text
      } else {
        return ''
      }
    },
    outerClick(target, eventType, callback) {
      if (!this._eventRemovers) {
        this._eventRemovers = []
      }
      target.addEventListener(eventType, callback)
      this._eventRemovers.push({
        remove() {
          target.removeEventListener(eventType, callback)
        }
      })
    },
    checkCaution(name) {
      if (!this.items.find((item) => item.name === name)?.caution) return false
      return this.items.find((item) => item.name === name).caution
    },
    checkDisabled(name) {
      if (this.noDisabledItem) return false
      if (this.items.some((item) => item.name === name)) return false
      return true
    },
    focusInput() {
      this.isActive = true
      this.$nextTick(() => {
        this.$refs.inputTarget.focus()
      })
    },
    closeItem() {
      this.isActive = false
    },
    onFocusBox() {
      this.isActive = !this.isActive
      this.$nextTick(() => {
        if (this.isActive) {
          this.$refs.inputTarget.focus()
        }
      })
    },
    deleteInput() {
      this.searchKey = ''
      this.selectItem = ''
      this.$emit('select-item', { selectItem: '' })
      this.$emit('input', '')
      this.$nextTick(() => {
        this.$refs.inputTarget.focus()
      })
    }
  },
  computed: {
    fuse() {
      return new Fuse(this.items, this.option)
    },
    showItems() {
      if (this.searchKey == null || this.searchKey.length === 0)
        return this.items
      const results = this.fuse.search(this.searchKey)
      const res = results.map((result) => result.item)
      let filteredItems = this.items
      if (res != null && res.length > 0) {
        filteredItems = this.items.filter((item) => {
          return !res.some((target) => {
            return target.value === item.value
          })
        })
      }

      return [...res, ...filteredItems]
    },
    inputTooltip() {
      return {
        content: this.tipsContents ? this.tipsContents : '',
        trigger: 'hover',
        delay: { show: 300, hide: 300 }
      }
    }
  },
  watch: {
    value(nv) {
      this.setValue(nv)
    },
    items() {
      this.setValue(this.value)
    },
    searchKey(newVal) {
      this.$emit('input', newVal)
    },
    isCloseItem(newVal) {
      // 複数inputが並ぶときに、セレクトボックスを閉じることを検知
      if (newVal) {
        this.closeItem()
      }
    },
    isSeparateBox(newVal) {
      if (newVal) {
        const target = this.$refs.inputTarget.getBoundingClientRect()
        this.inputWidth = target.right - target.left
        this.inputHeight = target.bottom - target.top
        const margin = this.margin
          ? this.margin
          : ((16 / 1920) * 100 * window.innerWidth) / 100 // adjustVW(16)を画面の大きさで再計算
        this.offsetTop = target.top + this.inputHeight / 2 + margin
        this.offsetLeft = target.left - this.marginLeft
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-func-select {
  position: relative;
  width: 100%;
  &-button {
    display: block;
    width: 100%;
    height: 100%;
    padding: $space-sub adjustVW(32);
    border-bottom: $border-title-gray;
    font-size: $text-base;
    &-disabled {
      color: $gray;
    }
    &-caution {
      color: $text-caution;
    }
  }
  &-icon {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 10;
    transform: translateY(-50%);
    transition: $transition-base;
    &.active {
      transform: translateY(-50%) rotate(180deg);
    }
    &-search {
      position: absolute;
      top: calc(50% + 1px);
      left: 0;
      transform: translateY(-50%);
    }
    &-hide {
      visibility: hidden;
      opacity: 0;
    }
  }
  &-inner {
    position: absolute;
    top: calc(100% + #{$space-small});
    left: 0;
    width: 100%;
    padding: $space-small 0;
    margin-top: -10px;
    background: $background;
    font-size: $text-base;
    border-radius: adjustVW(8);
    box-shadow: $box-shadow-hover;
    opacity: 0;
    z-index: 100;
    transform: scaleY(0);
    transform-origin: top;
    transition: $transition-base;
    @include text-crop;
    &.active {
      margin: 0 0 $space-medium;
      opacity: 1;
      transform: scaleY(1);
      animation: 0.2s ease-in-out openItem;
    }
    &.scroll {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: adjustVH(296);
      padding-right: $space-base;
      @include scrollbar;
    }
    &.show-top {
      top: inherit;
      bottom: calc(100% - #{$space-base});
      transform-origin: bottom;
    }
    &.show-separate {
      --offsetTop: 0;
      --offsetLeft: 0;
      --width: 0;
      position: fixed;
      top: var(--offsetTop);
      left: var(--offsetLeft);
      width: var(--width);
      &.show-top {
        --offsetTop: 0;
        top: calc(var(--offsetTop));
        bottom: inherit;
      }
    }
  }
  &-item {
    display: block;
    width: 100%;
    padding: $space-small;
    margin: 0 0 $space-sub;
    text-align: left;
    word-break: break-all;
    opacity: 0;
    transform: translateY(-100%);
    transition: $transition-base;
    &:last-child {
      margin: 0;
      border: none;
    }
    &:hover {
      background: $background-sub;
      opacity: 1;
    }
    .active & {
      opacity: 1;
      transform: translateY(0);
      animation: 0.2s ease-in-out openMenuList;
    }
    &-disabled {
      cursor: not-allowed;
    }
  }
  &-gray {
    .c-func-select-button {
      overflow: hidden;
      padding: $space-small adjustVW(40) $space-small adjustVW(48);
      border: none;
      background: $background-sub;
      border-radius: adjustVW(8);
      transition: $transition-base;
      &:focus {
        background: $background;
        box-shadow: $border-radius-emphasis inset, $box-shadow-hover;
      }
    }
    .c-func-select-icon {
      right: $space-sub;
      &-search {
        left: $space-small;
      }
      &-close {
        right: $space-small;
      }
    }
  }
  &-min {
    .c-func-select-button {
      padding: $space-base adjustVW(32);
    }
    &.c-func-select-gray {
      .c-func-select-button {
        padding: 0 adjustVW(40) 0 adjustVW(48);
      }
    }
  }
  &-no-icon {
    .c-func-select-button {
      padding: $space-sub 0;
    }
    &.c-func-select-gray {
      .c-func-select-button {
        padding: $space-small;
      }
    }
    &.c-func-select-min {
      .c-func-select-button {
        padding: $space-base 0;
      }
      &.c-func-select-gray {
        .c-func-select-button {
          padding: 0 $space-small;
        }
      }
    }
  }
}

@keyframes openItem {
  0% {
    margin-top: -10px;
    opacity: 0;
    transform: scaleY(0);
  }
  100% {
    margin-top: 0;
    opacity: 1;
    transform: scaleY(1);
  }
}
@keyframes openItemList {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
