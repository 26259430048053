<template>
  <div class="list">
    <template v-for="(layer, index) in layers">
      <span :key="index" class="item" :class="{ 'item-no-wrap': noWrap }">
        <recipe-chart-block-min :layer="layer" />
        <span v-if="index !== layers.length - 1" class="line" />
      </span>
    </template>
  </div>
</template>

<script>
import recipeChartBlockMin from '@/components/atoms/recipe-chart-block-min'

export default {
  components: {
    recipeChartBlockMin
  },
  props: {
    /** 学習に使用したレシピ getTrainedAIDetail で取得した result.layer_info */
    layers: Array,
    /** レシピを複数行に表示しない */
    noWrap: {
      type: Boolean,
      default: false,
      require: false
    }
  }
}
</script>

<style scoped lang="scss">
.list {
  display: -webkit-box;
  color: $background-decoration;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.item-no-wrap {
  white-space: nowrap;
}

.line {
  display: inline-block;
  width: adjustVW(16);
  height: adjustVW(2);
  background: $line-emphasis;
  vertical-align: middle;
  transform: translateY(adjustVW(-1));
}
</style>
