export function toAlphabetical(index) {
  /** A,B, ... Z, AA, AB ... AZ, BA... なる順序に変換 */
  const alphabets = 'ABCDEFGHJKLMNPRSTUVWXYZ'
  const nAlphabets = alphabets.length
  let n = index
  let numpat = nAlphabets
  let digits = 1
  while (n >= numpat) {
    n -= numpat
    numpat *= nAlphabets
    digits += 1
  }
  let res = ''
  for (let i = 0; i < digits; i++) {
    res = alphabets.charAt(n % nAlphabets) + res
    n = Math.floor(n / nAlphabets)
  }
  return res
}
