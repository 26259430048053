<template>
  <div class="expression-top">
    <texts :text="$t('trainedAi.optimization.expression.settingTitle')" />
    <text-with-icon
      v-tooltip="$t('trainedAi.optimization.expression.help.desc')"
      class="expression-top-help"
      size="min"
      :text="$t('trainedAi.optimization.expression.help.title')"
      iconName="info"
      color="gray"
    />
  </div>
</template>

<script>
import textWithIcon from '@/components/molecules/text-with-icon'
export default {
  components: {
    textWithIcon
  }
}
</script>

<style lang="scss" scoped>
.expression {
  &-top {
    display: flex;
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: $space-small;
    &-help {
      width: fit-content;
      cursor: help;
      &::v-deep .c-text {
        margin-bottom: adjustVW(-1);
      }
    }
  }
}
</style>
