<template>
  <change-plan
    v-bind="data"
    :selectedPlan="selectedPlan"
    @active-item="activeItem"
    @change-plan="changePlan"
    @change-show-popup-state="changeShowPopupState"
    @return-top-page="returnTopPage"
  />
</template>

<script>
import ChangePlan from '@/components/templates/change-plan.vue'
import { mapGetters } from 'vuex'
import setMountedTimer from '@/mixin/set-mounted-timer'

export default {
  components: {
    ChangePlan
  },
  mixins: [setMountedTimer],
  data() {
    return {
      data: {
        sidebar: {
          // サイドバーに表示する情報
        },
        headerTabs: {
          // ヘッダーのタブ
          tabs: [],
          tabSelect: 1
        },
        body: {
          basicPlanData: {
            activeTerm: '',
            menuItems: [
              {
                id: 'recipe',
                index: 0,
                isIcon: true,
                value: 'check'
              },
              {
                id: 'dataCapacity',
                index: 1,
                value: this.$i18n.t('changePlan.basicPlanInfo.dataCapacity'),
                option: this.$i18n.t('changePlan.basicPlanInfo.option'),
                color: 'emphasis'
              },
              {
                id: 'dataUpload',
                index: 2,
                isIcon: true,
                value: 'check'
              },
              {
                id: 'trainedAIs',
                index: 3,
                value: this.$i18n.t('changePlan.basicPlanInfo.trainedAIs'),
                option: this.$i18n.t('changePlan.basicPlanInfo.option'),
                color: 'emphasis'
              },
              {
                id: 'organization',
                index: 4,
                isIcon: true,
                value: 'check'
              },
              {
                id: 'serverUpgrade',
                index: 5,
                isIcon: true,
                value: 'check'
              },
              {
                id: 'apiService',
                index: 6,
                isIcon: true,
                value: 'check'
              },
              {
                id: 'trendflowHumanResourcesRecipe',
                index: 7,
                isIcon: true,
                value: 'check'
              },
              {
                id: 'customblock',
                index: 8,
                isIcon: true,
                value: 'check'
              }
            ]
          },
          freePlanData: {
            activeTerm: '',
            menuItems: [
              {
                id: 'recipe',
                index: 0,
                isIcon: true,
                value: 'close',
                option: this.$i18n.t('changePlan.freePlanInfo.educationOption')
              },
              {
                id: 'dataCapacity',
                index: 1,
                value: this.$i18n.t('changePlan.freePlanInfo.dataCapacity'),
                color: 'gray'
              },
              {
                id: 'dataUpload',
                index: 2,
                isIcon: true,
                value: 'close',
                option: this.$t('changePlan.freePlanInfo.educationOption')
              },
              {
                id: 'trainedAIs',
                index: 3,
                value: this.$i18n.t('changePlan.freePlanInfo.trainedAIs'),
                color: 'gray',
                option: this.$i18n.t('changePlan.freePlanInfo.educationOption')
              },
              {
                id: 'organization',
                index: 4,
                isIcon: true,
                value: 'close'
              },
              {
                id: 'serverUpgrade',
                index: 5,
                isIcon: true,
                value: 'close'
              },
              {
                id: 'apiService',
                index: 6,
                isIcon: true,
                value: 'close'
              },
              {
                id: 'trendflowHumanResourcesRecipe',
                index: 7,
                isIcon: true,
                value: 'close'
              },
              {
                id: 'customblock',
                index: 8,
                isIcon: true,
                value: 'close'
              }
            ]
          },
          lightPlanData: {
            activeTerm: '',
            isSelected: false
          },
          planTermsData: {
            activeTerm: '',
            menuItems: [
              {
                id: 'recipe',
                index: 0
              },
              {
                id: 'dataCapacity',
                index: 1
              },
              {
                id: 'dataUpload',
                index: 2
              },
              {
                id: 'trainedAIs',
                index: 3
              },
              {
                id: 'organization',
                index: 4
              },
              {
                id: 'serverUpgrade',
                index: 5
              },
              {
                id: 'apiService',
                index: 6
              },
              {
                id: 'trendflowHumanResourcesRecipe',
                index: 7
              },
              {
                id: 'customblock',
                index: 8
              },
              {
                id: 'price',
                index: 9
              }
            ]
          },
          purchasePlan: null,
          showPopupContent: {
            finishPurchase: false
          }
        }
      },
      popup: {
        showPopup: [] // 現在表示しているポップアップ
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['accountInfo', 'accountInfoLoading']),
    selectedPlan() {
      if (!this.accountInfo) return null
      return this.accountInfo.plan
    }
  },
  methods: {
    activeItem: function ({ id }) {
      this.data.body.freePlanData.activeTerm = id
      this.data.body.lightPlanData.activeTerm = id
      this.data.body.basicPlanData.activeTerm = id
      this.data.body.planTermsData.activeTerm = id
    },
    changePlan: function ({ planName }) {
      this.data.body.purchasePlan = planName
    },
    changeShowPopupState: function ({ componentName }) {
      const val = !this.data.body.showPopupContent[componentName]
      this.$set(this.data.body.showPopupContent, componentName, val)
    },
    returnTopPage: function () {
      this.$router.push({ path: 'projects' })
    }
  }
}
</script>
