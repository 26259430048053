<template>
  <div class="c-wrap">
    <sidebar-project-detail v-bind="sidebar" />
    <div class="c-body-wrap">
      <header-tabs v-bind="headerTabs" />
      <transition-page>
        <transition-toggle-contents>
          <!-- 小さいポップアップだとくるくるが被るので特別対応 -->
          <div v-if="(loading || loadingInit) && disableClick" />
          <loading v-else-if="loading || loadingInit" />
          <preprocessing-apply-detail-main
            v-else
            v-bind="body"
            :showData="showData"
            :columnData="columnData"
            :mainData="mainData"
            @change-tab="$emit('change-tab', $event)"
            @open-save-preprocessing="$emit('open-save-preprocessing', $event)"
            @reset-preprocessing="$emit('reset-preprocessing')"
            @back-version="$emit('back-version')"
            @next-version="$emit('next-version')"
            @apply-one-hot="$emit('apply-one-hot')"
            @apply-dummy="$emit('apply-dummy')"
            @apply-label="$emit('apply-label', $event)"
            @change-null="$emit('change-null', $event)"
            @drop-null="$emit('drop-null')"
            @drop-column="$emit('drop-column')"
            @drop-outlier="$emit('drop-outlier')"
            @back-main="$emit('back-main')"
            @apply-standardize="$emit('apply-standardize')"
            @apply-yeo-johnson="$emit('apply-yeo-johnson')"
            @apply-binning="$emit('apply-binning', $event)"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="disableClick" />
    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
    <div class="preprocessing-apply-detail-popup">
      <preprocessing-apply-popup
        v-bind="mainData.popup"
        :dataset="body.pageBody.dataset"
        :appliedChart="body.appliedChart"
        :datasetName="datasetName"
        :preProcHeadVersion="body.pageBottom.preProcHeadVersion"
        :disableClick="disableClick"
        :preprocessingList="body.pageBody.preprocessingList"
        :isLargeCSV="isLargeCSV"
        @close-modal="$emit('close-modal', $event)"
        @input-create-form="$emit('input-create-form', $event)"
        @open-save-close="$emit('open-save-close')"
        @reset-preprocessing-confirm="$emit('reset-preprocessing-confirm')"
        @save-dataset="$emit('save-dataset', $event)"
        @save-preprocessing="$emit('save-preprocessing', $event)"
      />
    </div>
  </div>
</template>

<script>
import sidebarProjectDetail from '@/components/organisms/sidebar-project-detail'
import hoverMenu from '@/components/organisms/hover-menu'
import headerTabs from '@/components/organisms/header-tabs'
import preprocessingApplyDetailMain from '@/components/organisms/preprocessing-apply-detail/preprocessing-apply-detail-main'
import preprocessingApplyPopup from '@/components/organisms/preprocessing-apply/preprocessing-apply-popup'
import transitionPage from '@/components/molecules/transition-page'
import loading from '@/components/atoms/loading.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import TransitionToggleContents from '../molecules/transition-toggle-contents.vue'

export default {
  components: {
    sidebarProjectDetail,
    hoverMenu,
    headerTabs,
    preprocessingApplyDetailMain,
    preprocessingApplyPopup,
    transitionPage,
    loading,
    loadingProcessing,
    TransitionToggleContents
  },
  props: {
    sidebar: Object,
    headerTabs: Object,
    hoverMenu: Object,
    body: Object,
    popup: Object,
    loading: Boolean,
    loadingInit: Boolean,
    mainData: Object,
    showData: Object,
    columnData: Object,
    disableClick: Boolean,
    isLargeCSV: Boolean
  },
  computed: {
    datasetName() {
      if (this.body.pageBody.datasetList) {
        return this.body.pageBody.datasetList[this.mainData.datasetId]?.name
      } else {
        return null
      }
    }
  }
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
}
</style>
