<template>
  <div class="add-new-service-wrap">
    <nav-page
      :page="page"
      :pageList="pageList"
      :descText="descText"
      serviceType="optimization"
      @page-back="pageBack"
    />
    <div class="add-new-service-inner">
      <transition-toggle-contents>
        <recipe-page
          v-if="page == 'recipe'"
          :recipes="recipes"
          :recipeLayers="recipeLayers"
          :loadRecipeDetail="loadRecipeDetail"
          :recipeDetail="recipeDetail"
          :customblockList="customblockList"
          isOptimizationService
          @select-recipe="selectRecipe"
          @list-click="$emit('get-recipe-detail', $event)"
        />
        <preprocessing-page
          v-if="page == 'preprocessing'"
          :preprocessingList="preprocessingList"
          @select-eda-recipe="selectEdaRecipe"
        />
        <final-page
          v-if="page == 'final'"
          :disableClick="disableClick"
          :inputColumns="inputColumns"
          :predictionColumns="predictionColumns"
          :recipeDetail="recipeDetail"
          :serviceFormValidate="serviceFormValidate"
          :targetEdaRecipe="targetEdaRecipe"
          @input-create-form="$emit('input-create-form', $event)"
          @select-box-get-processings="
            $emit('select-box-get-processings', $event)
          "
          @cancel="$emit('cancel', $event)"
          @create-service="createService"
        />
      </transition-toggle-contents>
    </div>
  </div>
</template>

<script>
import recipePage from './learn-pages/recipe.vue'
import preprocessingPage from './learn-pages/preprocessing.vue'
import finalPage from './learn-pages/final.vue'
import navPage from './learn-pages/nav.vue'

import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
export default {
  components: {
    recipePage,
    preprocessingPage,
    finalPage,
    navPage,
    transitionToggleContents
  },
  data() {
    return {
      page: 'recipe',
      pageList: ['recipe', 'preprocessing', 'final'],

      inputColumns: [],
      predictionColumns: [],
      recipeId: null,
      dataset: null,
      edaRecipeId: null,
      info: {}
    }
  },
  props: {
    preprocessingList: Array,
    recipes: Object,
    recipeLayers: Object,
    recipeDetail: Object,
    loadRecipeDetail: Boolean,
    serviceFormValidate: Object,
    disableClick: Boolean,
    customblockList: Array
  },
  computed: {
    targetEdaRecipe() {
      if (!this.edaRecipeId) return null
      return this.preprocessingList.find((item) => item.id === this.edaRecipeId)
    },
    descText() {
      switch (this.page) {
        case 'recipe':
          return this.$t(
            'serviceList.popup.addNewService.progress.recipe.descOptimization'
          )
        case 'preprocessing':
          return this.$t(
            'serviceList.popup.addNewService.progress.preproc.descOptimization'
          )
        case 'final':
          return this.$t('serviceList.popup.addNewService.progress.result.desc')
        default:
          return this.$t(
            'serviceList.popup.addNewService.progress.recipe.descOptimization'
          )
      }
    }
  },
  methods: {
    selectRecipe(e) {
      this.recipeId = e
      this.page = 'preprocessing'
    },
    selectEdaRecipe(e) {
      this.edaRecipeId = e
      this.page = 'final'
    },
    createService(e) {
      const res = {
        recipeId: this.recipeId,
        edaRecipeId: this.edaRecipeId,
        ...e
      }
      this.$emit('create-service', res)
    },
    pageBack(e) {
      switch (e) {
        case 'recipe':
          this.recipeId = null
          this.$emit('reset-create-service')
          break
        case 'preprocessing':
          this.edaRecipeId = null
          break
        default:
          return
      }
      this.page = e
    }
  },
  destroyed() {
    this.$emit('reset-create-service')
  }
}
</script>

<style lang="scss" scoped>
.add-new-service {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    grid-row-gap: adjustVW(28);
    width: 100%;
    height: 100%;
  }
  &-inner {
    overflow: hidden;
    flex-shrink: 1;
    height: 100%;
  }
}
</style>
