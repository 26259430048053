<template>
  <card-base class="trained-ai">
    <div class="trained-ai-tab">
      <tab-list
        :tabs="tabs"
        :activeTabProps="selectedTrainedAiType === 'optimization' ? 1 : 0"
        @change-tab="changeTab"
      />
    </div>
    <div class="trained-ai-list">
      <transition-toggle-contents>
        <trained-ai-list
          v-if="selectedTrainedAiType === 'inference'"
          key="infenrence"
          class="trained-ai-list-content"
          :project="project"
          :field="field.trainedAi"
          :items="items"
          @list-click="$emit('list-click', $event)"
        />
        <optimization-list
          v-else
          key="optimization"
          class="trained-ai-list-content"
          :field="field.optimization"
          :items="items"
          @list-click="$emit('list-click', $event)"
        />
      </transition-toggle-contents>
    </div>
  </card-base>
</template>

<script>
import cardBase from '@/components/atoms/card-base.vue'
import tabList from '@/components/molecules/tab-list.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import trainedAiList from './trained-ai/trained-ai-list.vue'
import optimizationList from './trained-ai/optimization-list.vue'

export default {
  components: {
    cardBase,
    tabList,
    transitionToggleContents,
    trainedAiList,
    optimizationList
  },
  props: {
    project: Object,
    field: Object,
    items: Array,
    selectedTrainedAiType: String
  },
  computed: {
    tabs() {
      return [
        {
          id: 0,
          value: 'inference',
          name: this.$t('inference.pageTitle'),
          iconName: 'inference'
        },
        {
          id: 1,
          value: 'optimization',
          name: this.$t('trainedAi.optimization.title'),
          iconName: 'optimization',
          disabled: !this.checkOptimization,
          tooltip: this.checkOptimization
            ? ''
            : this.$t('inference.settings.noOptimization')
        }
      ]
    },
    checkOptimization() {
      return this.items.some((item) => {
        return item?.is_optimization
      })
    }
  },
  methods: {
    changeTab(e) {
      this.$emit('change-trained-ai-tab', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.c-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
}

.trained-ai {
  &-tab {
    flex-shrink: 0;
    width: adjustVW(320);
    height: fit-content;
    margin-bottom: $space-small;
  }
  &-list {
    overflow: hidden;
    height: 100%;
    &-content {
      overflow-y: scroll;
      @include scrollbar;
    }
  }
}
</style>
