import * as d3 from 'd3'

const japaneseLargeNumerals = [[1e20, '垓'], [1e16, '京'], [1e12, '兆'], [1e8, '億'], [1e4, '万']]

/**
 * ラベルを切り詰める関数を返す。
 * 渡された文字列が数値の場合は丸めによって切り詰める。
 * 渡された文字列がテキストの場合は省略記号「…」で切り詰める。
 * @param {number} maxLabelLength 切り詰める文字数。返される文字列の長さはこれ以下になる。
 * @param {str} [locale]
 * @returns {(str: string) => string} ラベルを `maxLabelLength` で指定された文字数以内に切り詰める関数
 */
export function truncateLabel(maxLabelLength, locale = 'en') {
  const numeralFormatter1 = d3.format(`.${Math.max(maxLabelLength - 1, 0)}~r`)
  const numeralFormatter2 = d3.format(`.${Math.max(maxLabelLength - 2, 0)}~r`)
  const numeralFormatter3 = d3.format(`.${Math.max(maxLabelLength - 3, 0)}~r`)
  const numeralFixedPointFormatter1 = d3.format(
    `.${Math.max(maxLabelLength - 2, 0)}~f`
  )
  const numeralFixedPointFormatter2 = d3.format(
    `.${Math.max(maxLabelLength - 3, 0)}~f`
  )
  const numeralPrefixFormatter1 = d3.format(
    `.${Math.max(maxLabelLength - 2, 0)}~s`
  )
  const numeralPrefixFormatter2 = d3.format(
    `.${Math.max(maxLabelLength - 3, 0)}~s`
  )

  /**
   * @param {string} str
   */
  function isNumeral(str) {
    return /^-?\d+(?:\.\d+)?(?:[eE][-+]?\d+)?$/.test(str)
  }

  /**
   * @param {string} str
   */
  function truncateText(str) {
    return str.substr(0, maxLabelLength - 1) + '…'
  }

  /**
   * @param {string} str
   * @param {number} length
   */
  function truncateNumeral(str) {
    const value = parseFloat(str)
    if (!Number.isFinite(value)) {
      return truncateText(str)
    }
    if (locale === 'ja') {
      for (const [base, numeral] of japaneseLargeNumerals) {
        if (value >= base) {
          return `${numeralFormatter2(value / base)}${numeral}`
        }
        if (value <= -base) {
          return `${numeralFormatter3(value / base)}${numeral}`
        }
      }
    } else {
      if (value >= 1e3) {
        return numeralPrefixFormatter1(value)
      }
      if (value <= -1e3) {
        return numeralPrefixFormatter2(value)
      }
    }
    if (value >= 1) {
      return numeralFormatter1(value)
    }
    if (value <= -1) {
      return numeralFormatter2(value)
    }
    if (value < 0) {
      return numeralFixedPointFormatter2(value)
    }
    return numeralFixedPointFormatter1(value)
  }

  return (str) => {
    if (str.length <= maxLabelLength) {
      return str
    }
    if (isNumeral(str)) {
      return truncateNumeral(str)
    }
    return truncateText(str)
  }
}
