<template>
  <div class="popup-into-project-wrap">
    <div class="popup-into-project-main">
      <!--
        <toggle-button v-model="newProject" @change="validate">新規作成</toggle-button>
      -->
      <div v-if="newProject" class="popup-into-project-new-project">
        <input-box-validation
          v-model="newName"
          class="popup-into-project-new-project-title"
          :accept="validate.accept"
          :error="validate.error"
          :title="$t('datasetList.popup.intoProject.newProjectName')"
          popupView
          isNameValidation
          :nameValidationSuggests="suggests"
          @input="validation"
        />

        <input-box
          v-model="description"
          class="popup-into-project-new-project-desc"
          isTextArea
          isGray
          :title="$t('datasetList.popup.intoProject.description')"
        />

        <tag-editable-list v-model="tags" isWhite edit />

        <div class="popup-into-project-button-block">
          <button-large
            type="emphasis"
            :text="$t('datasetList.popup.intoProject.submitNewProject')"
            :isDisabled="disabledSubmit || disableClick"
            @click="Ok"
          />
        </div>
      </div>

      <div v-else class="popup-into-project-into-project">
        <texts
          class="popup-into-project-into-project-title"
          :text="$t('common.popup.moveProject.confirmText', { type })"
          size="small"
          color="gray"
        />

        <select-box
          v-model="project"
          :items="projectList"
          isGray
          scrollBar
          @input="validation"
        />

        <div class="popup-into-project-button-block">
          <button-large
            type="emphasis"
            :isDisabled="validate.error || disableMoveToProject || disableClick"
            :text="$t('datasetList.popup.intoProject.submit')"
            @click="Ok"
          />
        </div>

        <texts
          v-if="disableMoveToProject"
          isShowAll
          class="popup-into-project-restriction-message"
          color="caution"
          :text="restrictionErrorMessage"
        />
      </div>
    </div>

    <div class="popup-into-project-list-wrap">
      <list-item
        class="popup-into-project-list"
        :field="field"
        :items="targets"
        isToggle
      >
        <template v-slot:toggleContent="entry">
          <div class="popup-into-project-item">
            <div class="popup-into-project-block">
              <description :text="entry.entry.description" />
            </div>

            <div class="popup-into-project-block">
              <div class="popup-into-project-inner">
                <text-with-title :title="$t('common.createTime')">
                  <a-time :value="entry.createTime" />
                </text-with-title>
              </div>

              <div class="popup-into-project-inner">
                <text-with-title :title="$t('common.updateTime')">
                  <a-time :value="entry.updateTime" />
                </text-with-title>
              </div>
            </div>
          </div>
        </template>
      </list-item>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapGetters } from 'vuex'
import { duplicateSuggests } from '@/lib/validator/misc'

import buttonLarge from '@/components/atoms/button-large'
import description from '@/components/atoms/description'
import inputBox from '@/components/molecules/input-box.vue'
import inputBoxValidation from '@/components/molecules/input-box-validation.vue'
import listItem from '@/components/molecules/list-item'
import SelectBox from '@/components/molecules/select-box.vue'
import TagEditableList from '@/components/molecules/tag-editable-list.vue'
import texts from '@/components/atoms/text'
import textWithTitle from '@/components/molecules/text-with-title'

export default {
  components: {
    buttonLarge,
    description,
    inputBox,
    inputBoxValidation,
    listItem,
    SelectBox,
    TagEditableList,
    texts,
    textWithTitle
  },
  props: {
    /** 移動する対象の配列 */
    targets: Array,
    /** datasetなどの移行元の種類 */
    type: String,
    /** 新規プロジェクトを作成する場合 */
    newProject: Boolean,
    /** 押下不可状態 */
    disableClick: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      project: null,
      newName: '',
      description: '',
      tags: [],
      validate: {
        accept: null,
        error: null
      },
      pushed: false,
      suggests: []
    }
  },
  mounted() {
    if (this.projectList && this.projectList.length > 0) {
      // ありえないが一応判定
      this.project = this.projectList[0].value
    }
  },
  computed: {
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('project', { projects: 'projectList' }),
    ...mapGetters('settings', ['language']),

    restrictionErrorMessage: function () {
      const type = this.language === 'en' ? 'a dataset' : this.type

      return this.$i18n.t('common.popup.intoProject.restrictionError', { type })
    },
    disableMoveToProject: function () {
      if (this.type !== this.$i18n.t('common.dataset') || this.isNewProject)
        return false

      if (this.project === null) return false

      const plan = this.accountInfo.plan
      const sourceTemplate = this.project.sourceTemplate
        ? this.project.sourceTemplate
        : {}

      const enableCopy = sourceTemplate.usageRestriction
        ? sourceTemplate.usageRestriction[plan]
        : true

      return !enableCopy
    },
    disabledSubmit: function () {
      if (this.newName === '') return true
      if (this.validate.error) return true
      if (this.pushed) return true

      return false
    },
    projectList: function () {
      if (!this.projects) return []

      let targetProjects = Object.values(this.projects).sort((x, y) =>
        x.updateTime < y.updateTime ? 1 : x.updateTime > y.updateTime ? -1 : 0
      )

      if (this.$route.params?.projectId) {
        targetProjects = targetProjects.filter(
          (x) => x.id !== parseInt(this.$route.params.projectId)
        )
      }

      return targetProjects.map((project, index) => ({
        id: index,
        name: project.name,
        value: project
      }))
    },
    field() {
      return [
        {
          id: 0,
          key: 'name',
          label: this.$t('datasetList.popup.intoProject.field.title', {
            type: this.type
          })
        }
      ]
    }
  },
  methods: {
    validation() {
      this.validate.accept = null
      this.validate.error = null
      this.$nextTick(() => this.validateAsync())
    },
    validateAsync: debounce(async function () {
      if (!this.newProject) {
        this.validate.accept = this.project != null
        this.validate.error = !(this.project != null)
        return
      }
      if (this.newName === '') {
        this.validate.accept = null
        this.validate.error = null

        return
      }
      const res = await this.$sendMessageAndReceive({
        action: 'checkProjectName',
        name: this.newName
      })
      if (res.status !== 'error' && !res.exists) {
        this.validate.accept = true
        this.validate.error = false
      } else {
        this.validate.accept = false
        this.validate.error = true
        const nameList =
          this.projectList.length > 0
            ? this.projectList.map((item) => item.name)
            : []
        this.suggests = duplicateSuggests(nameList, this.newName)
      }
    }, 500),
    Ok() {
      const targets = this.targets.map((item) => ({
        id: item.id,
        accountId: item.accountId
      }))
      if (this.newProject) {
        this.$emit('add-to-project', {
          targets: targets,
          project: {
            new: true,
            name: this.newName,
            description: this.description,
            tags: this.tags
          }
        })
      } else {
        this.$emit('add-to-project', {
          targets: targets,
          project: {
            new: false,
            id: this.project.id
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-into-project {
  &-wrap {
    overflow: hidden;
    display: grid;
    grid-template-columns: adjustVW(480) adjustVW(720);
    grid-template-rows: 100%;
    grid-column-gap: $space-medium;
    min-height: adjustVH(424);
  }

  &-list-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
  }
  &-list {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    ::v-deep .list-item-entry-list {
      overflow-y: auto;
      @include scrollbar;
    }
  }

  &-item {
    margin: -$space-small 0 0;
  }

  &-block {
    display: flex;
    margin: 0 0 $space-medium;
    &:last-of-type {
      margin: 0;
    }
  }

  &-inner {
    overflow: hidden;
    width: 100%;
    margin: 0 $space-small 0 0;
    &:last-child {
      margin: 0;
    }
  }

  &-into-project-title {
    margin-bottom: $space-base;
  }

  &-new-project {
    &-title {
      margin-bottom: $space-medium;
      &-caution {
        margin: -$space-small 0 $space-medium;
      }
    }
    &-desc {
      margin-bottom: $space-medium;
    }
  }

  &-button {
    margin: 0 $space-small 0 0;
    &:last-child {
      margin: 0;
    }
    &-block {
      display: flex;
      margin-top: $space-medium;
    }
  }

  &-title-wrap {
    margin: 0 0 $space-medium;
  }
}

.popup-into-project-restriction-message {
  margin-top: $space-medium;
  white-space: pre-wrap;
}
</style>
