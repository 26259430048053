import { uploadViaSocket } from '@/lib/upload.js'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      mixin_datasetList: {
        projectItems: null,
        projectItemsLoading: true,
        sortKey: null,
        top: {
          usageRestriction: {
            basic: true,
            education_1: true,
            free: true
          }
        },
        checkedClear: false,
        addDataset: {
          uploadLoading: false,
          importLoading: false,
          importError: null,
          uploadLearningDataErrors: { flag: false, errors: [] },
          uploadLearningDataWarnings: {
            fileType: null,
            reasonList: []
          },
          progressStatus: '', // データセットの登録状況の設定
          progressSettingDataset: 0, // データセットの登録の進捗状況
          progressSettingDatasetMax: 0, // これがmaxまでいけば
          isLargeCsv: false,
          uploadFile: {},
          fileInputValue: {
            // ファイルでインポートする際の値が入ります
            fileName: '',
            description: '',
            isDisabled: true,
            resetFileValue: false
          },
          datasetFormValidate: {
            duplicate: null
          }
        }
      }
    }
  },
  computed: {
    __projectId() {
      return this.mainData?.projectId ?? this.projectId
    },
    datasetListBody() {
      return {
        projectItems: this.mixin_datasetList.projectItems,
        projectId: this.__projectId,
        accountInfo: this.accountInfo,
        datasetList: this.sortDatasetList,
        checkedClear: null,
        datasetLoading: this.mixin_datasetList.projectItemsLoading,
        uploadLoading: null,
        downloadLoading: null,
        top: this.mixin_datasetList.top,

        datasourceLoading: null,
        datasourceList: null,
        showDatasourceList: false,
        addDataset: this.mixin_datasetList.addDataset
      }
    },
    sortDatasetList() {
      /*
      テーブルのラベル選択することによってソート機能が動きます。
      一回押すとデータが降順、同じラベルを再度押すと昇順にデータがならびます。
      */
      const datasetList = this.mixin_datasetList?.projectItems
      if (!datasetList) return null
      const data = Object.values(datasetList)
      const key = this.mixin_datasetList.sortKey
      const self = this
      if (!key) {
        return data
      } else {
        return data.sort(function (a, b) {
          if (self.reverse === key) {
            if (key === 'createTime') {
              return a.id > b.id ? 1 : -1
            }
            if (key === 'size') {
              return a.size > b.size ? 1 : -1
            }
          } else {
            if (key === 'createTime') {
              return a.id < b.id ? 1 : -1
            }
            if (key === 'size') {
              return a.size < b.size ? 1 : -1
            }
          }
          return console.error()
        })
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setUsageRestriction({
        projectId: this.__projectId?.toString()
      })
    })
  },
  methods: {
    ...mapActions('auth', ['fetchAccountInfo']),
    async loadProjectDatasets() {
      if (this.__projectId) {
        this.mixin_datasetList.projectItemsLoading = true
        try {
          const response = await this.$sendMessageAndReceive({
            action: 'getDataList',
            projectId: this.__projectId
          })
          if (response.status !== 'error') {
            const obj = {}
            response.list.forEach((item) => {
              const fullId = item.id + '-' + item.accountId
              item.fullId = fullId
              obj[item.id] = item
            })
            this.mixin_datasetList.projectItems = obj
          } else {
            throw response
          }
        } catch (e) {
          console.log(e)
        } finally {
          this.mixin_datasetList.projectItemsLoading = false
        }
      }
    },
    setUsageRestriction({ projectId }) {
      const projectInfo = this.projectList[projectId]
      if (!projectInfo) return
      const sourceTemplateInfo = projectInfo.sourceTemplate

      const usageRestriction =
        sourceTemplateInfo !== null
          ? sourceTemplateInfo.usageRestriction
          : {
              basic: true,
              education_1: true,
              free: true
            }

      this.$set(
        this.mixin_datasetList.top,
        'usageRestriction',
        usageRestriction
      )
    },
    // 新規データセットの追加でファイルをアップしたときの処理
    onFileInput(file) {
      this.mixin_datasetList.addDataset.fileInputValue.resetFileValue = false
      this.initUploadState()

      if (
        this.accountInfo.totalDataSize + file.size >
        this.accountInfo.planDetail.maxDataSize
      ) {
        this.mixin_datasetList.addDataset.uploadLearningDataErrors.flag = true
        this.mixin_datasetList.addDataset.uploadLearningDataErrors.sizeInfo = {
          currentTotalSize: this.accountInfo.totalDataSize,
          fileSize: file.size,
          newTotalSize: this.accountInfo.totalDataSize + file.size,
          maxSize: this.accountInfo.planDetail.maxDataSize
        }
        // this.$refs.uploadLearningData.reset();
        this.$nextTick(() => {
          this.mixin_datasetList.addDataset.uploadFile = null
          this.mixin_datasetList.addDataset.fileInputValue.resetFileValue = true
          this.showPopup('upperLimitDataSize')
        })
      } else {
        this.mixin_datasetList.addDataset.uploadFile = file
        this.mixin_datasetList.addDataset.fileInputValue.fileName =
          file.name.substr(0, file.name.lastIndexOf('.'))
      }
    },
    initUploadState: function () {
      this.mixin_datasetList.addDataset.uploadLearningDataErrors =
        Object.assign(
          this.mixin_datasetList.addDataset.uploadLearningDataErrors,
          {
            flag: false,
            errors: []
          }
        )

      this.mixin_datasetList.addDataset.uploadLearningDataWarnings.fileType =
        null
      this.mixin_datasetList.addDataset.uploadLearningDataWarnings.reasonList =
        []
    },
    async uploadFileRAG(file, _RAGSettings) {
      this.mixin_datasetList.addDataset.uploadLoading = true

      const fileSize = this.mixin_datasetList.addDataset.uploadFile.size
      const fileName = this.mixin_datasetList.addDataset.uploadFile.name

      this.maxProgress = fileSize
      this.mixin_datasetList.addDataset.progressSettingDataset = 0
      this.mixin_datasetList.addDataset.progressStatus = 'startUploading'
      this.mixin_datasetList.addDataset.uploadLearningDataErrors.errors = []
      this.mixin_datasetList.addDataset.uploadLearningDataWarnings.fileType =
        null
      this.mixin_datasetList.addDataset.uploadLearningDataWarnings.reasonList =
        []

      const RAGSettings = {
        ..._RAGSettings,
        uploadConfig: {
          removeHTML: file.removeHTML
        }
      }

      const request = {
        action: 'startUploading',
        name: file.name,
        description: file.description,
        fileSize: fileSize,
        fileName: fileName,
        projectId: this.__projectId,
        forRAG: true,
        RAGSettings
      }

      // let uploadDataset = null

      try {
        const startRes = await this.$sendMessageAndReceive(request)
        const messageId = startRes.m_id

        const { status, message } = startRes
        if (status === 'error') throw new Error(message)

        const uploadTask = uploadViaSocket(
          this.$socket,
          this.mixin_datasetList.addDataset.uploadFile,
          {
            chunkSize: 1e5,
            header: {
              action: 'uploadingData',
              m_id: messageId
            }
          }
        )
        await Promise.all([
          uploadTask,
          this.$watchProgress(messageId, {
            getTotalProgress: (res) => {
              this.mixin_datasetList.addDataset.progressStatus = 'preparingData'
              this.mixin_datasetList.addDataset.progress = 0
              this.mixin_datasetList.addDataset.maxProgress = res.total
            },
            startPreparingZipData: (res) => {
              this.mixin_datasetList.addDataset.progressStatus = 'preparingData'
              this.mixin_datasetList.addDataset.progress = 0
              this.mixin_datasetList.addDataset.maxProgress = 1
            },
            uploadingLearningData: (res) => {
              this.mixin_datasetList.addDataset.progressStatus =
                'uploadingLearningData'
              this.mixin_datasetList.addDataset.maxProgress = res.totalSize
              this.mixin_datasetList.addDataset.progress = res.loadedSize
            },
            uploaded: (res) => {},
            startPreparingData: (res) => {
              this.mixin_datasetList.addDataset.progressStatus = 'preparingData'
              this.mixin_datasetList.addDataset.progress = 0
              this.mixin_datasetList.addDataset.maxProgress = 1
              this.mixin_datasetList.addDataset.isLargeCsv =
                res?.isLarge ?? false
            },
            preparingData: (res) => {
              this.mixin_datasetList.addDataset.progress = res.progress
            },
            dataWarning: (res) => {
              this.mixin_datasetList.addDataset.uploadLearningDataWarnings.fileType =
                res.fileType
              this.mixin_datasetList.addDataset.uploadLearningDataWarnings.reasonList =
                res.warnings
            }
          })
        ])
        this.progress = this.maxProgress

        if (this.__projectId) {
          await this.loadProjectDatasets()
        } else {
          throw new Error('deprecated')
        }
        await this.fetchAccountInfo()

        this.closePopup('addNewData')
        this.mixin_datasetList.uploadLoading = false

        this.clearForms()
        this.progressStatus = null
        this.progress = 0
        this.maxProgress = 1
      } catch (ex) {
        this.log_info(ex)

        this.progressStatus = null
        this.mixin_datasetList.addDataset.uploadLearningDataErrors.errors.push({
          message: ex.message,
          file: ex?.error_info?.file
        })
        this.mixin_datasetList.addDataset.uploadLoading = false
      } finally {
        this.mixin_datasetList.addDataset.isLargeCsv = false
      }
    }
  }
}
