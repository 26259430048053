<template>
  <div key="list" class="best-inner">
    <div class="best-top">
      <best-top
        :key="resetKey"
        v-model="trainedAiInput"
        :isSaved="isSaved"
        :recipeType="recipeType"
        :sortedFlag="sortedFlag"
        :trainedAi="trainedAi"
        :trainedAiCount="trainedAiCount"
        :trainedAIFormValidate="trainedAIFormValidate"
        :finishColumns="finishColumns"
        :trainingProgressStatus="trainingProgressStatus"
        :checkTrainingFinish="checkTrainingFinish"
        @input-create-form="$emit('input-create-form', $event)"
        @show-trained-ai-list="$emit('show-trained-ai-list')"
        @save-trained-ai="$emit('save-trained-ai')"
      />
    </div>
    <div class="best-body">
      <best-body
        :accountInfo="accountInfo"
        :isSaved="isSaved"
        :recipeType="recipeType"
        :trainedAi="trainedAi"
        :loadChangeColumn="loadChangeColumn"
        :finishColumns="finishColumns"
        :trainingProgressStatus="trainingProgressStatus"
        :selectedColumnIndex="selectedColumnIndex"
        :checkTrainingFinish="checkTrainingFinish"
        :optimizationInfo="optimizationInfo"
        :optimizationResult="optimizationResult"
        :textMining="textMining"
        :threshold="threshold"
        :reversePositive="reversePositive"
        :testDatasetInfo="testDatasetInfo"
        :checkOptimization="checkOptimization"
        :clusteringSetting="clusteringSetting"
        :clusteringResult="clusteringResult"
        :clusteringDistributions="clusteringDistributions"
        :regressionGraph="regressionGraph"
        @delete-trained-ai="$emit('delete-trained-ai')"
        @change-column="$emit('change-column', $event)"
        @save-edit-optimization-form="
          $emit('save-edit-optimization-form', $event)
        "
        @change-tab-optimization="$emit('change-tab-optimization')"
        @change-page="$emit('change-page', $event)"
        @change-test-dataset-page="$emit('change-test-dataset-page', $event)"
        @change-filter-value="$emit('change-filter-value', $event)"
        @show-detail-optimization="$emit('show-detail-optimization', $event)"
        @download-result="$emit('download-result', $event)"
        @download-test-dataset="$emit('download-test-dataset', $event)"
        @change-tab="$emit('change-tab')"
        @close-optimization-result-tutorial="
          $emit('close-optimization-result-tutorial')
        "
        @change-threshold="$emit('change-threshold', $event)"
        @reverse-positive="$emit('reverse-positive', $event)"
        @load-text-mining="$emit('load-text-mining', $event)"
        @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
        @input-clustering-setting="$emit('input-clustering-setting', $event)"
        @download-clustering-result="
          $emit('download-clustering-result', $event)
        "
        @download-confusion-matrix="$emit('download-confusion-matrix', $event)"
        @change-clustering-distribution-column="
          $emit('change-clustering-distribution-column', $event)
        "
        @toggle-clustering-show-dimension="
          $emit('toggle-clustering-show-dimension', $event)
        "
        @select-regression-graph="$emit('select-regression-graph', $event)"
      />
    </div>
  </div>
</template>

<script>
import bestTop from './best/best-top'
import bestBody from './best/best-body'

export default {
  components: {
    bestTop,
    bestBody
  },
  props: {
    accountInfo: Object,
    isSaved: Boolean,
    recipeType: String,
    resetKey: Number,
    sortedFlag: Boolean,
    trainedAi: Object,
    trainedAiCount: Number,
    trainedAIFormValidate: Object,
    value: Object,
    finishColumns: Array,
    loadChangeColumn: Boolean,
    trainingProgressStatus: String,
    selectedColumnIndex: Number,
    checkTrainingFinish: Boolean,
    optimizationInfo: Object,
    optimizationResult: Object,
    textMining: Object,
    threshold: Number,
    reversePositive: Boolean,
    testDatasetInfo: Object,
    checkOptimization: Boolean,
    clusteringSetting: Object,
    clusteringResult: Object,
    clusteringDistributions: Array,
    regressionGraph: Object
  },
  computed: {
    trainedAiInput: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.best {
  &-inner {
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-row-gap: $space-medium;
    height: 100%;
  }
  &-body {
    overflow: hidden;
  }
}
</style>
