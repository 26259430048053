<template>
  <popup-array
    :popupDefs="popupDefs"
    :showPopup="showPopup"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #projectSetting>
      <project-setting
        :disabledClick="disabledClick"
        :projectFormValidate="createInfo.projectFormValidate"
        :summary="projectDetail.summary"
        :title="projectTitle"
        @cancel="$emit('close-modal', 'projectSetting')"
        @close-modal="$emit('close-modal', 'projectSetting')"
        @create-project="$emit('create-project', $event)"
        @input-create-form="$emit('input-create-form', $event)"
      />
    </template>

    <template #templateNotFound>
      <template-not-found
        @close-modal="goBackListPage"
        @click="goBackListPage"
      />
    </template>
  </popup-array>
</template>

<script>
import popupArray from '@/components/molecules/popup/popup-array'
import projectSetting from '@/components/organisms/project-template-detail/popup/project-setting'
import TemplateNotFound from '@/components/organisms/project-template-detail/popup/template-not-found.vue'

export default {
  components: {
    popupArray,
    projectSetting,
    TemplateNotFound
  },
  props: {
    createInfo: Object,
    disabledClick: Boolean,
    projectDetail: Object,
    showPopup: Array,
    projectTitle: String
  },
  computed: {
    popupDefs() {
      return {
        projectSetting: {
          title: this.$t('projectTemplate.popup.title')
        },
        templateNotFound: {
          title: this.$t('projectTemplate.popup.templateNotFound.title')
        }
      }
    }
  },
  methods: {
    goBackListPage: function () {
      this.$router.go(-1)
    }
  }
}
</script>
