<template>
  <div class="add-new-service-wrap">
    <div class="add-new-service-input">
      <div class="add-new-service-input-block">
        <div class="add-new-service-input-inner">
          <input-box-validation
            v-model="name"
            :accept="validate.accept"
            :error="validate.error"
            :title="$t('serviceList.popup.addNewService.serviceName')"
            :placeholder="
              $t('serviceList.popup.addNewService.serviceNamePlaceholder')
            "
            popupView
            isNameValidation
            :nameValidationSuggests="serviceFormValidate.suggests"
          />
        </div>
        <div class="add-new-service-input-inner">
          <input-box
            v-model="description"
            isTextArea
            isGray
            :placeholder="
              $t(
                'serviceList.popup.addNewService.serviceDescriptionPlaceholder'
              )
            "
            :title="$t('serviceList.popup.addNewService.serviceDescription')"
          />
        </div>
        <div class="add-new-service-input-list">
          <div class="add-new-service-input-item">
            <select-box
              v-model="trainedAIId"
              valueKey="id"
              :title="$t('serviceList.popup.addNewService.AItoUse')"
              :placeholder="
                $t('serviceList.popup.addNewService.AItoUsePlaceholder')
              "
              :items="trainedAiList"
              isGray
              scrollBar
              @select-box-open="$emit('select-box-get-models')"
              @select-item="$emit('select-box-set-model', $event)"
            />
          </div>
          <div
            v-if="inferenceTypeOptions.length > 0"
            class="add-new-service-input-item"
          >
            <transition-toggle-contents>
              <select-box
                v-if="!loadingTrainedAiDetail"
                v-model="type"
                :placeholder="
                  $t('serviceList.popup.addNewService.typePlaceholder')
                "
                :items="inferenceTypeOptions"
                isGray
                scrollBar
              />
              <loading-icon v-else class="add-new-service-input-loading" />
            </transition-toggle-contents>
          </div>
        </div>
        <div class="add-new-service-preproc">
          <checkbox-base
            v-tooltip="disabledTips"
            :text="$t('serviceList.popup.addNewService.selectPreprocessing')"
            class="add-new-service-preproc-check"
            :class="{
              'add-new-service-preproc-check-disabled': disabledTips !== null
            }"
            :checked="preprocessingOpen"
            :isDisabled="disabledTips !== null"
            @input="inputCheck"
          />
          <transition-toggle-slot-contents>
            <div v-if="preprocessingOpen">
              <div>
                <select-box
                  v-model="preprocessing"
                  valueKey="id"
                  :title="
                    $t(
                      'serviceList.popup.addNewService.PreprocessingToUseOptional'
                    )
                  "
                  :placeholder="
                    $t(
                      'serviceList.popup.addNewService.PreprocessingToUsePlaceholder'
                    )
                  "
                  :items="preprocessingList"
                  isGray
                  scrollBar
                  showTop
                  @select-box-open="$emit('select-box-get-processings')"
                />
              </div>
            </div>
          </transition-toggle-slot-contents>
        </div>
      </div>
      <div class="add-new-service-input-button">
        <button-main
          type="emphasis"
          :isDisabled="disabledSubmit || disableClick"
          :text="$t('serviceList.popup.addNewService.submitButton')"
          @click="createService"
        />
        <button-main
          type="sub"
          :isDisabled="disableClick"
          :text="$t('common.cancel')"
          @click="$emit('cancel')"
        />
      </div>
    </div>
    <transition-toggle-contents>
      <div v-if="isNotCustomblockAvailable">
        <texts
          class="add-new-service-caution"
          :text="$t('common.disabled.NOT_AVAILABLE_CUSTOMBLOCK_FOR_INFERENCE')"
          color="caution"
          size="small"
        />
      </div>
      <div
        v-else-if="modelInfo && modelInfo.summary && !loadingTrainedAiDetail"
        class="add-new-service-use-model"
      >
        <model-info
          :modelInfo="modelInfo.summary"
          :title="$t('serviceList.popup.addNewService.AItoUse')"
          isNoWrap
        />
      </div>
      <loading-icon v-else-if="loadingTrainedAiDetail" />
    </transition-toggle-contents>
  </div>
</template>

<script>
import {
  inferenceTypeOptions,
  isNatualLanguage,
  preprocessAvailable
} from '@/lib/inference.js'

import buttonMain from '@/components/atoms/button-main'
import transitionToggleSlotContents from '@/components/molecules/transition-toggle-slot-contents.vue'
import checkboxBase from '@/components/atoms/checkbox-base'
import inputBox from '@/components/molecules/input-box'
import inputBoxValidation from '@/components/molecules/input-box-validation.vue'
import ModelInfo from '../../model-info.vue'
import selectBox from '@/components/molecules/select-box'
import loadingIcon from '@/components/atoms/loading-icon'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    buttonMain,
    transitionToggleSlotContents,
    checkboxBase,
    inputBox,
    inputBoxValidation,
    ModelInfo,
    selectBox,
    loadingIcon,
    TransitionToggleContents
  },
  data() {
    return {
      name: '',
      description: '',
      type: 'classification',
      trainedAIId: '',
      preprocessing: '',
      validate: {
        accept: null,
        error: null
      },
      preprocessingOpen: false
    }
  },
  props: {
    modelInfo: Object,
    loadingTrainedAiDetail: Boolean,
    preprocessingList: Array,
    serviceFormValidate: Object,
    trainedAIs: Object,
    disableClick: Boolean,
    isNotCustomblockAvailable: Boolean
  },
  computed: {
    trainedAiList() {
      if (!this.trainedAIs) return []
      return Object.values(this.trainedAIs).sort((x, y) =>
        x.updateTime > y.updateTime ? -1 : 1
      )
    },
    disabledSubmit() {
      if (this.name === '') return true
      if (this.trainedAIId === '') return true
      if (this.validate.error) return true

      return false
    },
    selectedTrainedAI() {
      if (!this.trainedAIId) return null
      if (!this.trainedAIs) return null
      return this.trainedAIs[this.trainedAIId]
    },
    inferenceTypeOptions() {
      return this.selectedTrainedAI
        ? inferenceTypeOptions.bind(this)(
            this.selectedTrainedAI,
            this.checkNatualLanguage
          )
        : []
    },
    checkNatualLanguage() {
      if (!this.modelInfo) return false
      return isNatualLanguage(this.modelInfo?.result)
    },
    disableSelectPreprocess() {
      return (
        this.selectedTrainedAI &&
        !preprocessAvailable(this.selectedTrainedAI, this.modelInfo?.result)
      )
    },
    disabledTips() {
      if (this.disableSelectPreprocess) {
        return {
          content: this.$t(
            'serviceList.popup.addNewService.enablePreprocessings'
          )
        }
      } else if (!this.checkPreprocessings) {
        return {
          content: this.$t('serviceList.popup.addNewService.noPreprocessings')
        }
      } else {
        return null
      }
    },
    checkPreprocessings() {
      return this.preprocessingList && this.preprocessingList.length > 0
    }
  },
  watch: {
    name: function (val) {
      this.$emit('input-create-form', {
        type: 'service',
        form: {
          name: val
        }
      })
    },
    serviceFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    },
    inferenceTypeOptions: function (val) {
      if (!val || val.length === 0) return
      this.type = val[0].value
    },
    disableSelectPreprocess: function (boolean) {
      // 前処理を選択できないモデルの場合、無理やり前処理を選択できないようにする
      if (boolean) {
        this.preprocessingOpen = false
        this.preprocessing = null
      }
    },
    isNotCustomblockAvailable(bool) {
      if (bool) {
        this.trainedAIId = ''
        this.type = 'classification'
      }
    }
  },
  methods: {
    createService() {
      const res = {
        name: this.name,
        description: this.description,
        type: this.type,
        trainedAIId: this.trainedAIId
      }
      if (this.preprocessing) {
        const targetPreprocessing = this.preprocessingList.find(
          (x) => x.id === this.preprocessing
        )
        const accountId = targetPreprocessing.accountId
        res.edaRecipe = {
          id: this.preprocessing,
          accountId: accountId
        }
      }
      this.$emit('create-service', res)
    },
    inputCheck(val) {
      if (!val) this.preprocessing = null
      this.preprocessingOpen = val
    }
  },
  mounted: function () {
    this.$emit('input-create-form', {
      type: 'service',
      form: {
        name: this.name
      }
    })
  },
  destroyed() {
    this.$emit('reset-create-service')
  }
}
</script>

<style lang="scss" scoped>
.add-new-service {
  &-wrap {
    display: grid;
    grid-template-columns: adjustVW(768) adjustVW(384);
    grid-column-gap: adjustVW(24);
    height: 100%;
  }
  &-input {
    padding: 0 $space-medium 0 0;
    border-right: $border-sub;
    &-inner {
      margin: 0 0 $space-medium;
    }
    &-list {
      display: flex;
      margin-bottom: $space-medium;
    }
    &-item {
      width: calc(50% - #{$space-sub});
      margin-right: $space-medium;
      &:last-of-type {
        margin: 0;
      }
    }
    &-loading {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
  &-preproc {
    margin-bottom: $space-medium;
    &-check {
      width: fit-content;
    }
  }
  &-caution {
    white-space: pre-line;
  }
}
</style>
