<template>
  <div class="c-wrap">
    <sidebar-train v-bind="sidebar" />
    <div class="c-body-wrap">
      <header-tabs v-bind="headerTabs" />
      <transition-page>
        <project-template-select-mode
          :accountInfo="accountInfo"
          @use-recipe="$emit('use-recipe', $event)"
          @use-template="$emit('use-template')"
          @page-back="$emit('page-back')"
        />
      </transition-page>
    </div>
    <loading-processing v-if="popup.submitDisabled" />
    <hover-menu />
    <select-template-mode-popup-recipe
      v-bind="popup"
      @close-modal="$emit('close-modal', $event)"
      @create-project="$emit('create-project', $event)"
      @input-create-form="$emit('input-create-form', $event)"
    />
  </div>
</template>

<script>
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import projectTemplateSelectMode from '@/components/organisms/project-template-select-mode/project-template-select-mode'
import selectTemplateModePopupRecipe from '@/components/organisms/project-template-select-mode/project-template-select-mode-popup-recipe-setting.vue'
import sidebarTrain from '@/components/organisms/sidebar-train'
import headerTabs from '@/components/organisms/header-tabs'
import hoverMenu from '@/components/organisms/hover-menu'
import transitionPage from '@/components/molecules/transition-page'

export default {
  components: {
    loadingProcessing,
    sidebarTrain,
    headerTabs,
    projectTemplateSelectMode,
    hoverMenu,
    transitionPage,
    selectTemplateModePopupRecipe
  },
  props: {
    popup: Object,
    sidebar: Object,
    headerTabs: Object,
    accountInfo: Object
  }
}
</script>

<style lang="scss" scoped>
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
  background: $background;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
  background: $background-sub;
}
</style>
