<template>
  <span class="block">
    <span class="content">
      {{ $t('recipe.layerNames.' + layer.name) }}
    </span>
  </span>
</template>

<script>
export default {
  props: {
    /** レシピのブロック */
    layer: Object
  }
}
</script>

<style lang="scss" scoped>
.block {
  padding: $space-text $space-sub adjustVW(3);
  background: $background-decoration;
  line-height: adjustVW(30);
  text-align: center;
  vertical-align: middle;
  border-radius: 9in;
}

.content {
  color: $text-decoration;
  font-size: $text-min;
  word-break: break-all;
  white-space: inherit;
}
</style>
