<template>
  <div>
    <div class="trained-ai-list-card-title">
      <texts :text="trainedAi.name" size="big" />
      <div
        v-if="withCheckbox"
        class="trained-ai-list-card-title-checkbox"
        @click.stop
      >
        <checkbox-base :checked="value" @input="$emit('input', $event)" />
      </div>
    </div>
    <div
      class="trained-ai-list-card-block"
      :class="{
        'trained-ai-list-card-block-no-accuracy':
          !trainedAi.metrics && !trainedAi.featureImportance
      }"
    >
      <div class="trained-ai-list-card-info">
        <div
          v-if="trainedAi.description && trainedAi.description.length > 0"
          class="trained-ai-list-card-info-inner"
        >
          <description :text="trainedAi.description" />
        </div>
        <div v-if="serviceList.length" class="trained-ai-list-card-info-inner">
          <texts
            class="trained-ai-list-card-info-title"
            :text="$t('trainedAiDetails.linkedServices')"
            size="small"
            color="gray"
          />
          <texts
            v-for="(service, index) in limitServices"
            :key="index"
            class="trained-ai-list-card-info-item"
            :text="service.name"
          />
        </div>
        <div class="trained-ai-list-card-info-inner">
          <texts
            class="trained-ai-list-card-info-title"
            :text="$t('trainedAiDetails.datasetUsed')"
            size="small"
            color="gray"
          />
          <texts
            v-for="(dataset, index) in datasetList"
            :key="index"
            :text="dataset.name"
          />
          <!--
            ほんとはこうしたいです。
            けどdatasetを配列で渡したいなら、諦めます
            <text-with-title title='使用したデータセット' :text='trainedAi.dataset.name'/>
          -->
        </div>
        <div class="trained-ai-list-card-info-inner">
          <text-with-title :title="$t('common.updateTime')">
            <a-time :value="trainedAi.updateTime" />
          </text-with-title>
        </div>
      </div>
      <template v-if="trainedAi.is_optimization">
        <optimization-list-card-contents
          :latestOptimizationConditions="trainedAi.latestOptimizationConditions"
          :optimizationColumns="trainedAi.optimizationColumns"
        />
      </template>
      <template
        v-else-if="
          trainedAi.type === 'NO_ACCURACY' || trainedAi.type === 'CLUSTERFLOW'
        "
      >
        <no-accuracy-list-card-contents
          :textMining="trainedAi.textMining"
          :type="trainedAi.type"
          :clusteringInfo="trainedAi.clusteringInfo"
        />
      </template>
      <template v-else>
        <div
          v-if="trainedAi.metrics || trainedAi.featureImportance"
          class="trained-ai-list-card-accuracy"
        >
          <div
            v-if="trainedAi.metrics && Object.keys(trainedAi.metrics).length"
            class="trained-ai-list-card-accuracy-inner"
          >
            <!--<div
              v-if="trainedAi.metrics.expectedEffect"
              class="trained-ai-list-card-accuracy-trainedAi"
            >
              <div class="trained-ai-list-card-accuracy-trainedAi-main">
                <texts
                  :text="trainedAi.metrics.expectedEffectName"
                  size="title"
                  isBold
                />
                <texts
                  :text="trainedAi.metrics.expectedEffect"
                  size="small"
                  color="emphasis"
                  isBold
                />
              </div>
            </div> -->
            <!--
              テンプレートがある場合はテンプレートごとに一番表示したい精度指標をとってきたいです
              テンプレートがない場合は、
              * CLASSIFICATION → accuracy (正解率)
              * REGRESSION → r2 (精度 (R2))
              * DEEP（画像） → test_accuracy (テストデータ正解率)
              * テキストなど複数の分類 → overall_accuracy (全体正解率)
              * TIME → mse (MSE)
            -->

            <template v-if="trainedAi.metricsList.length > 1">
              <div class="trained-ai-list-card-accuracy-multi">
                <texts
                  class="trained-ai-list-card-accuracy-multi-title"
                  :text="trainedAi.metricsList[0].name"
                  size="large"
                />
                <div class="trained-ai-list-card-accuracy-multi-list">
                  <metrics
                    v-for="metric in limitMetrics"
                    :key="metric.columnName"
                    class="trained-ai-list-card-accuracy-multi-item"
                    :name="metric.columnName"
                    :value="metric.value"
                    :percentage="metric.percentage"
                    isMinTitle
                  />
                </div>
              </div>
            </template>
            <template v-else>
              <metrics
                v-for="metric in trainedAi.metricsList"
                :key="metric.name"
                class="trained-ai-list-card-accuracy-trainedAi"
                :name="metric.name"
                :value="metric.value"
                :percentage="metric.percentage"
                isCard
              />
            </template>
          </div>
          <feature-importance
            :maxItem="4"
            checkShowFeatureImportance
            :type="trainedAi.type"
            :predictionColumns="trainedAi.predictionColumns"
            :featureImportance="trainedAi.featureImportance"
            notCard
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import description from '@/components/atoms/description'
import metrics from '@/components/molecules/graph/metrics.vue'
import checkboxBase from '@/components/atoms/checkbox-base'
import textWithTitle from '@/components/molecules/text-with-title'
import FeatureImportance from '@/components/organisms/card-fi.vue'
import optimizationListCardContents from '@/components/organisms/optimization/optimization-list-card-contents.vue'
import noAccuracyListCardContents from '@/components/organisms/no-accuracy/no-accuracy-list-card-contents.vue'

export default {
  components: {
    texts,
    description,
    metrics,
    checkboxBase,
    textWithTitle,
    FeatureImportance,
    optimizationListCardContents,
    noAccuracyListCardContents
  },
  data() {
    return {
      showTipsCard: '',
      showTipsItem: ''
    }
  },
  props: {
    trainedAi: Object,
    withCheckbox: Boolean,
    value: Boolean,
    project: Object
  },
  computed: {
    limitServices() {
      if (this.trainedAI?.services.length > 3) {
        return this.trainedAI?.services.slice(0, 2)
      } else {
        return this.trainedAi?.services
      }
    },
    datasetList() {
      return this.trainedAi?.datasets ?? []
    },
    serviceList() {
      return this.trainedAi?.services ?? []
    },
    limitMetrics() {
      return this.trainedAi.metricsList.slice(0, 6)
    }
  },
  methods: {
    linkTo(trainedAi) {
      this.$router.push({
        name: 'trainedAiDetail',
        params: {
          id: trainedAi.id
        }
      })
    },
    scale(e) {
      return Math.round(e * 10) / 10
    },
    maxScale(id) {
      return Math.ceil(this.items[id].featureImportance.size * 10) / 10
    },
    scaleWidth(e, id) {
      return (this.scale(e) / this.maxScale(id)) * 100
    },
    isHoverGraph(cardId, featureImportanceId) {
      this.showTipsCard = cardId
      this.showTipsItem = featureImportanceId
    },
    isLeaveGraph() {
      this.showTipsCard = ''
      this.showTipsItem = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.trained-ai-list-card {
  &-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &-block {
    display: grid;
    grid-template-columns: adjustVW(284) 1fr;
    grid-column-gap: $space-small;
    &-no-accuracy {
      grid-template-columns: 1fr;
      .trained-ai-list-card {
        &-info {
          border: none;
        }
      }
    }
  }
  &-info {
    padding: 0 $space-small 0 0;
    border-right: $border-main;
    &-inner {
      margin: 0 0 $space-small;
    }
    &-title {
      margin: 0 0 $space-min;
    }
    &-item {
      margin: 0 0 $space-min;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 $space-sub;
    &-checkbox {
      width: adjustVW(24);
    }
  }
  &-accuracy {
    overflow: hidden;
    width: 100%;
    &-inner {
      display: flex;
      margin: 0 0 $space-medium;
    }
    &-item {
      &-inner {
        width: 100%;
      }
    }
    &-trainedAi {
      display: flex;
      align-items: center;
      margin: 0 $space-large 0 0;
      &:last-child {
        margin: 0;
      }
      &-graph {
        width: adjustVW(64);
        margin: 0 $space-small 0 0;
      }
      &-main {
        width: auto;
      }
    }
    &-multi {
      width: 100%;
      &-title {
        margin-bottom: $space-small;
      }
      &-list {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-column-gap: $space-medium;
        grid-row-gap: $space-large;
      }
      &-item {
        overflow: hidden;
      }
    }
  }
}
</style>
