<template>
  <div class="clustering-distribution-wrapper">
    <transition-toggle-contents>
      <div
        v-if="selectItems.length > 1"
        key="on"
        class="clustering-distribution-item"
      >
        <div class="clustering-distribution-item-body">
          <div class="clustering-distribution-item-list">
            <div>
              <div class="clustering-distribution-item-title">
                <texts :text="inputBoxText.xTarget" size="small" color="gray" />
              </div>
              <select-box-input
                class="clustering-distribution-item-input"
                :value="setting.xTarget"
                :items="distributionItems(setting.yTarget)"
                :placeholder="placeholderText"
                isGray
                isSearch
                isScroll
                @select-item="
                  changeClusteringDistributionColumn(
                    $event.selectItem,
                    'xTarget'
                  )
                "
              />
            </div>
            <div>
              <div class="clustering-distribution-item-title">
                <texts :text="inputBoxText.yTarget" size="small" color="gray" />
              </div>
              <select-box-input
                class="clustering-distribution-item-input"
                :value="setting.yTarget"
                :items="distributionItems(setting.xTarget)"
                :placeholder="placeholderText"
                isGray
                isSearch
                isScroll
                @select-item="
                  changeClusteringDistributionColumn(
                    $event.selectItem,
                    'yTarget'
                  )
                "
              />
            </div>
          </div>
          <transition-toggle-contents>
            <div
              v-if="items && items.result && items.result.eps"
              class="clustering-distribution-item-info"
            >
              <texts
                :text="
                  $t('clustering.setting.distribution.eps') + items.result.eps
                "
                size="small"
                color="gray"
              />
              <texts
                :text="
                  $t('clustering.setting.distribution.minSamples') +
                    items.result.minSamples
                "
                size="small"
                color="gray"
              />
            </div>
          </transition-toggle-contents>
        </div>
        <div class="clustering-distribution-item-graph">
          <clusterflow-distribution
            :items="checkResult"
            :classes="classes"
            :xLabel="setting.xTarget"
            :yLabel="setting.yTarget"
            :loading="loading"
          />
        </div>
      </div>
      <!-- selectItemsが２つ未満の場合、グラフを表示できないので文言を表示 -->
      <div v-else key="off" class="clustering-distribution-none">
        <texts
          class="clustering-distribution-none-text"
          :text="noneText"
          size="small"
          color="gray"
        />
      </div>
    </transition-toggle-contents>
  </div>
</template>

<script>
import selectBoxInput from '@/components/molecules/select-box-input.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import clusterflowDistribution from '@/components/organisms/clusterflow/distribution.vue'

export default {
  components: {
    selectBoxInput,
    transitionToggleContents,
    clusterflowDistribution
  },
  props: {
    setting: {
      type: Object,
      default: () => {}
    },
    classes: {
      type: Array,
      default: () => []
    },
    items: {
      type: Object,
      default: () => {}
    },
    clusteringInfo: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    selectItems: {
      type: Array,
      default: () => []
    },
    showDimension: {
      type: Boolean,
      default: false
    },
    hasDimensionValue: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    checkResult() {
      return this.items?.result?.result ?? []
    },
    placeholderText() {
      const showDimension = this.showDimension ? 'dimension' : 'default'
      return this.$t(
        `clustering.setting.distribution.${showDimension}.placeholder`
      )
    },
    inputBoxText() {
      const showDimension = this.showDimension ? 'dimension' : 'default'
      return {
        xTarget: this.$t(
          `clustering.setting.distribution.${showDimension}.title.xTarget`
        ),
        yTarget: this.$t(
          `clustering.setting.distribution.${showDimension}.title.yTarget`
        )
      }
    },
    noneText() {
      if (this.selectItems.length > 1) return null
      if (this.hasDimensionValue) {
        return this.$t('clustering.setting.distribution.none.hasDimension')
      } else {
        return this.$t('clustering.setting.distribution.none.noDimension')
      }
    }
  },
  methods: {
    distributionItems(comparisonValue) {
      return this.selectItems.map((item) => {
        return {
          name: item.name,
          value: item.value,
          disabled: item.value === comparisonValue
        }
      })
    },
    changeClusteringDistributionColumn(value, target) {
      this.$emit('change-clustering-distribution-column', {
        value: value.value,
        target
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.clustering-distribution {
  &-wrapper {
    height: 100%;
  }
  &-item {
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    grid-row-gap: $space-sub;
    height: 100%;
    &-list {
      display: flex;
      grid-column-gap: $space-small;
    }
    &-title {
      margin-bottom: $space-text;
    }
    &-input {
      height: adjustVW(48);
    }
    &-info {
      display: flex;
      justify-content: flex-end;
      grid-column-gap: $space-small;
      margin-top: $space-sub;
    }
  }
  &-none {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: $space-sub;
    &-text {
      white-space: pre-line;
    }
  }
}
</style>
