<template>
  <page-top
    :title="summary.title"
    iconName="backward"
    notScroll
    isBackButton
  >
    <div class="preprocessing-top-func">
      <button-main
        :text="$t('preprocessing.selectDataset')"
        type="emphasis"
        @click="$emit('select-dataset')"
      />
      <text-menu-list
        :menus="preprocessingMenu"
        :activeMenu="activeMenu"
        @menu-click="$emit($event.activeMenu + '-menu-click')"
      />
    </div>
  </page-top>
</template>

<script>
import pageTop from '@/components/atoms/page-top'
import buttonMain from '@/components/atoms/button-main'
import textMenuList from '@/components/molecules/text-menu-list'

export default {
  components: {
    pageTop,
    buttonMain,
    textMenuList
  },
  props: {
    summary: Object,
    pageName: String,
    tabs: Array,
    activeTab: String,
    activeMenu: String,
    preprocessingMenu: Array
  }
}
</script>

<style lang="scss" scoped>
.preprocessing-top-func {
  display: flex;
  justify-content: space-between;
}
</style>
