<template>
  <div class="apply-detail-wrap">
    <div class="apply-detail-top">
      <preprocessing-apply-detail-top
        v-bind="pageTop"
        :columnName="showData.showColumn.name"
        @back-main="$emit('back-main')"
      />
    </div>
    <div class="apply-detail-lside">
      <preprocessing-apply-detail-lside
        v-bind="pageBody.info"
        :dataset="pageBody.dataset"
        :columnName="columnName"
      />
    </div>
    <div class="apply-detail-body">
      <preprocessing-apply-detail-body
        v-bind="pageBody"
        :showColumn="showData.showColumn"
        @apply-one-hot="$emit('apply-one-hot')"
        @apply-dummy="$emit('apply-dummy')"
        @apply-label="$emit('apply-label', $event)"
        @change-null="$emit('change-null', $event)"
        @drop-null="$emit('drop-null')"
        @drop-column="$emit('drop-column')"
        @drop-outlier="$emit('drop-outlier')"
        @apply-standardize="$emit('apply-standardize')"
        @apply-yeo-johnson="$emit('apply-yeo-johnson')"
        @apply-binning="$emit('apply-binning', $event)"
      />
    </div>
    <div class="apply-detail-rside">
      <preprocessing-apply-detail-rside
        v-bind="pageBody"
        :columnName="columnName"
        :tabs="columnData.tabs"
        :activeTab="columnData.activeTab"
        :visibleContents="columnData.visibleContents"
        :loadingHistogram="mainData.loadingDetailPage"
        @change-tab="$emit('change-tab', $event)"
      />
    </div>
    <div class="apply-detail-bottom">
      <preprocessing-apply-bottom
        v-bind="pageBottom"
        :loading="loading"
        :checked="checked"
        :appliedChart="appliedChart"
        @open-save-preprocessing="$emit('open-save-preprocessing', $event)"
        @reset-preprocessing="$emit('reset-preprocessing')"
        @back-version="$emit('back-version')"
        @next-version="$emit('next-version')"
      />
    </div>
  </div>
</template>

<script>
import preprocessingApplyDetailTop from './preprocessing-apply-detail-top'
import preprocessingApplyDetailBody from './preprocessing-apply-detail-body'
import preprocessingApplyDetailLside from './preprocessing-apply-detail-lside'
import preprocessingApplyDetailRside from './preprocessing-apply-detail-rside'
import preprocessingApplyBottom from '../preprocessing-apply/preprocessing-apply-bottom'

export default {
  components: {
    preprocessingApplyDetailTop,
    preprocessingApplyDetailBody,
    preprocessingApplyDetailLside,
    preprocessingApplyDetailRside,
    preprocessingApplyBottom
  },
  data() {
    return {
      checked: []
    }
  },
  props: {
    pageTop: Object,
    pageBody: Object,
    pageBottom: Object,
    loading: Boolean,
    loadingInit: Boolean,
    appliedChart: Object,
    showData: Object,
    columnData: Object,
    mainData: Object
  },
  computed: {
    columnName() {
      return this.showData.showColumn?.name
    }
  }
}
</script>

<style lang="scss" scoped>
.apply-detail {
  &-wrap {
    overflow: hidden;
    display: grid;
    grid-template-areas:
      'top top top'
      'lside body rside'
      'bottom bottom bottom';
    grid-template-columns: adjustVW(232) adjustVW(384) adjustVW(968);
    grid-template-rows: min-content 1fr minmax(adjustVH(208), auto);
    height: calc(
      100vh - #{adjustVH($headerTabHeight)}
    ); // ヘッダーサイズ分だけ小さくしている
    padding: 0 $space-medium $space-medium;
    column-gap: $space-medium;
    row-gap: $space-medium;
  }
  &-top {
    grid-area: top;
    margin-bottom: #{-$space-small};
  }
  &-lside {
    overflow: hidden;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    grid-area: lside;
  }
  &-body {
    overflow: hidden;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    grid-area: body;
  }
  &-rside {
    overflow: hidden;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    grid-area: rside;
  }
  &-bottom {
    grid-area: bottom;
    flex-shrink: 0;
    width: 100%;
  }
}
</style>
