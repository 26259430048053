<template>
  <div class="c-wrap">
    <sidebar-project-detail v-if="!notFound || loading" v-bind="sidebar" />
    <sidebar v-else />
    <div class="c-body-wrap">
      <header-tabs
        v-bind="headerTabs"
        @tab-click="$emit('tab-click', $event)"
      />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="loading" />
          <page-not-found v-else-if="notFound" />
          <service-list-main
            v-else
            :activeTab="activeTab"
            :accountInfo="accountInfo"
            :serviceList="serviceList"
            :accountInfoLoading="accountInfoLoading"
            :servicesLoading="servicesLoading"
            :projectId="projectId"
            :customblockList="customblockList"
            v-bind="body"
            @add-new-service="$emit('add-new-service', $event)"
            @close-delete-popup="$emit('close-delete-popup')"
            @cancel-deleted="$emit('cancel-deleted')"
            @tab-changed="$emit('tab-changed', $event)"
            @menu-click="$emit('menu-click', $event)"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="disableClick" />
    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
    <service-list-popup
      v-bind="popup"
      :trainedAIs="trainedAIs"
      :preprocessingList="preprocessingList"
      :disableClick="disableClick"
      :projectId="projectId"
      :recipes="recipes"
      :recipeLoading="recipeLoading"
      :recipeLayers="recipeLayers"
      :datasetList="datasetList"
      :datasetLoading="datasetLoading"
      :customblockList="customblockList"
      @close-modal="$emit('close-modal', $event)"
      @create-service="$emit('create-service', $event)"
      @delete-services="$emit('delete-services', $event)"
      @input-create-form="$emit('input-create-form', $event)"
      @select-box-get-models="$emit('select-box-get-models')"
      @select-box-get-processings="$emit('select-box-get-processings')"
      @select-box-set-model="$emit('select-box-set-model', $event)"
      @get-recipe-detail="$emit('get-recipe-detail', $event)"
      @get-dataset-detail="$emit('get-dataset-detail', $event)"
      @reset-create-service="$emit('reset-create-service')"
      @reset-create-service-dataset="$emit('reset-create-service-dataset')"
    />
  </div>
</template>

<script>
import sidebarProjectDetail from '@/components/organisms/sidebar-project-detail.vue'
import Sidebar from '@/components/organisms/sidebar.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import serviceListMain from '@/components/organisms/service-list/service-list-main.vue'
import serviceListPopup from '@/components/organisms/service-list/service-list-popup'
import transitionPage from '@/components/molecules/transition-page'
import loading from '@/components/atoms/loading.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import TransitionToggleContents from '../molecules/transition-toggle-contents.vue'
import pageNotFound from '@/components/organisms/page-not-found'

export default {
  components: {
    sidebarProjectDetail,
    Sidebar,
    hoverMenu,
    headerTabs,
    serviceListMain,
    serviceListPopup,
    transitionPage,
    loading,
    loadingProcessing,
    TransitionToggleContents,
    pageNotFound
  },
  props: {
    activeTab: String,
    accountInfo: Object,
    accountInfoLoading: Boolean,
    sidebar: Object,
    headerTabs: Object,
    body: Object,
    popup: Object,
    deleteTest: Object,
    serviceList: Array,
    servicesLoading: Boolean,
    preprocessingList: Array,
    trainedAIs: Object,
    loading: Boolean,
    notFound: Boolean,
    disableClick: Boolean,
    projectId: Number,
    recipes: Object,
    recipeLoading: Boolean,
    recipeLayers: Object,
    datasetList: Object,
    datasetLoading: Boolean,
    customblockList: Array
  }
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
}
</style>
