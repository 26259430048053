<template>
  <div class="sample-code">
    <div class="sample-code-tab-wrap">
      <tab-list
        :tabs="tabItems"
        :activeTab="activeTab"
        :showIcon="false"
        @change-tab="selectTab"
      />
    </div>
    <transition-toggle-contents>
      <div
        v-if="activeTab === 'sampleCode'"
        key="sampleCode"
        class="sample-code-select-box"
      >
        <select-box v-model="selectedLanguage" :items="languageTabItems" />
        <div v-if="selectedLanguage === 'curl'" class="sample-code-comment">
          <texts
            class="sample-code-comment-inner"
            :text="$t('serviceDetail.sampleCode.curlDesc')"
            :size="12"
            color="gray"
          />
        </div>
      </div>
      <div v-else key="dummy" />
    </transition-toggle-contents>
    <transition-toggle-contents>
      <div
        v-if="activeTab === 'sampleCode'"
        key="sampleCode"
        class="sample-code-tab-wrap-sample-code"
      >
        <div class="sample-code-wrap">
          <!-- requestHeaders -->
          <div class="sample-code-block">
            <div
              v-if="showedSampleCode"
              key="python"
              class="sample-code-inner-sample-code"
            >
              <code-block :baseCode="showedSampleCode" />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="activeTab === 'requestHeader'"
        key="requestHeader"
        class="sample-code-wrap"
      >
        <!-- requestHeaders -->
        <div class="sample-code-block">
          <div class="sample-code-inner">
            <div class="sample-code-item">
              <texts text="x-matrixflow-secretkey" />
              <texts text="string" size="small" color="gray" />
            </div>
            <div class="sample-code-item">
              <div class="sample-code-item-icon">
                <copy-box
                  class="sample-code-item-icon-copy"
                  :text="requestHeaders.secretKey"
                  isTipsBottom
                />
              </div>
            </div>
          </div>
          <div class="sample-code-inner">
            <div class="sample-code-item">
              <texts text="Content-Type" />
              <texts text="string" size="small" color="gray" />
            </div>
            <div class="sample-code-item">
              <texts :text="requestHeaders.contentType" />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="activeTab === 'requestBody'"
        key="requestBody"
        class="sample-code-wrap"
      >
        <!-- requestBody -->
        <div class="sample-code-block">
          <div
            v-for="(item, index) in requestBody.items"
            :key="index"
            class="sample-code-inner"
          >
            <div class="sample-code-item">
              <texts :text="item.name" />
              <texts :text="item.type" size="small" color="gray" />
            </div>
            <div class="sample-code-item">
              <texts :text="item.contents" />
              <texts
                v-if="item.contentsSub"
                class="sample-code-item-sub"
                :text="item.contentsSub"
                size="small"
                color="gray"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="activeTab === 'responses'"
        key="responses"
        class="sample-code-wrap"
      >
        <!-- responses -->
        <div class="sample-code-block">
          <div class="sample-code-inner">
            <div class="sample-code-item">
              <texts :text="responses.type" />
            </div>
            <div class="sample-code-item sample-code-code">
              <code-block :baseCode="responses.code" />
            </div>
          </div>
        </div>
      </div>
    </transition-toggle-contents>
  </div>
</template>
<script>
import platform from 'platform'

import texts from '@/components/atoms/text'
import tabList from '@/components/molecules/tab-list'
import codeBlock from '@/components/molecules/code-block'
import selectBox from '@/components/molecules/select-box'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import CopyBox from '@/components/molecules/copy-box.vue'

export default {
  components: {
    texts,
    tabList,
    codeBlock,
    selectBox,
    transitionToggleContents,
    CopyBox
  },
  data() {
    return {
      isTipsShow: false,
      activeTab: 'sampleCode',
      selectedLanguage: 'curl',
      responsesType: 'num'
    }
  },
  props: {
    apiUrl: String,
    secretKey: String,
    service: Object,
    selectedApiType: String
  },
  methods: {
    selectTab(e) {
      this.activeTab = e
    }
  },
  computed: {
    checkWindows() {
      return platform?.os?.family === 'Windows'
    },
    languages() {
      return ['curl', 'python']
    },
    tabItems() {
      const res = [
        {
          id: 0,
          name: this.$t('serviceDetail.sampleCode.sampleCode'),
          value: 'sampleCode'
        },
        {
          id: 1,
          name: 'RequestHeader',
          value: 'requestHeader'
        }
      ]
      if (this.checkShowStartApi) {
        res.push(
          {
            id: 2,
            name: 'RequestBody',
            value: 'requestBody'
          },
          {
            id: 3,
            name: 'Responses',
            value: 'responses'
          }
        )
      } else {
        res.push({
          id: 2,
          name: 'Responses',
          value: 'responses'
        })
      }
      return res
    },
    languageTabItems() {
      return [
        {
          id: 0,
          name: 'curl',
          value: 'curl'
        },
        {
          id: 1,
          name: 'python',
          value: 'python'
        }
      ]
    },
    responsesTabItems() {
      return [
        {
          id: 0,
          name: this.$t('serviceDetail.sampleCode.responses.tabs.num'),
          value: 'num'
        },
        {
          id: 1,
          name: this.$t('serviceDetail.sampleCode.responses.tabs.text'),
          value: 'text'
        }
      ]
    },
    recipeType() {
      if (!this.service || !this.service?.recipe) return null
      return this.service.recipe.type
    },
    checkShowStartApi() {
      return this.selectedApiType === 'start'
    },
    showedSampleCode() {
      if (this.checkShowStartApi) {
        return this.sampleCode
      } else {
        return this.sampleCodeStatusStop
      }
    },
    sampleCode() {
      let trainConfig
      if (this.recipeType === 'DEEP') {
        trainConfig = {
          learning_rate: 0.0001,
          batch_size: 64,
          epoch: 5,
          data: { ratio: 0.01 },
          saver: { evaluate_every: { train: 10, test: 30 }, num_checkpoints: 5 }
        }
      } else if (this.recipeType === 'TIME_TRANSFORMER2') {
        const columnConditions = [
          ...this.service.inputColumns.map((columnName) => ({
            columnName,
            isDatetime: false,
            isKnown: true,
            isTimeVarying: true,
            isReal: true
          })),
          ...this.service.predictionColumns.map((columnName) => ({
            columnName,
            isDatetime: false,
            isKnown: false,
            isTimeVarying: true,
            isReal: true
          }))
        ]
        trainConfig = {
          data: {
            ratio: 0.1
          },
          columnConditions,
          timeseries: {
            forecastTimeUnitCount: 7,
            forecastTimeUnit: 'day',
            maxEncoderLength: 14,
            maxPredictionLength: 7
          }
        }
      } else {
        trainConfig = {
          data: {
            ratio: 0.1
          }
        }
      }

      const json = {
        data_name: '@your_data_name',
        train_config: trainConfig,
        trained_ai: {
          name: this.$t('serviceDetail.sampleCode.trainedAiInfo.trainedAiName'),
          description: this.$t(
            'serviceDetail.sampleCode.trainedAiInfo.trainedAiDescription'
          )
        }
      }

      if (this.selectedLanguage === 'curl') {
        let params = ''
        let postFile = null
        if (this.checkWindows) {
          params = `-H "Content-Type: application/json" -d @post.json`
          postFile = `

${this.$t('serviceDetail.sampleCode.curlMultiDescFirst')}
${this.$t('serviceDetail.sampleCode.curlMultiDescSecond')}
- post.json
{
  "data_name": "@your_data_name",
  "train_config": ${JSON.stringify(trainConfig, null, 2)},
  "trained_ai": {
    "name": "${this.$t(
      'serviceDetail.sampleCode.trainedAiInfo.trainedAiName'
    )}",
    "description": "${this.$t(
      'serviceDetail.sampleCode.trainedAiInfo.trainedAiDescription'
    )}"
  }
}`
        } else {
          params = `-H "Content-Type: application/json" -d '${JSON.stringify(
            json
          )}'`
        }

        return `curl -X POST ${params} -H "x-matrixflow-secretkey: ${this.secretKey}" ${this.apiUrl} ${postFile}`
      } else if (this.selectedLanguage === 'python') {
        return `import requests # ${this.$t(
          'serviceDetail.sampleCode.installInfo'
        )}
import pprint

url = "${this.apiUrl}"

headers = {
    "x-matrixflow-secretkey": "${this.secretKey}",
}

params = ${JSON.stringify(
          json,
          (key, value) => {
            if (value === true) {
              return 'True'
            } else if (value === false) {
              return 'False'
            } else {
              return value
            }
          },
          2
        )
          .replace(/"True"/g, 'True')
          .replace(/"False"/g, 'False')}

response = requests.post(url, json=params, headers=headers)

print("status_code:", response.status_code)

pprint.pprint(response.json())`
      }
      return null
    },
    sampleCodeStatusStop() {
      let type = 'GET'
      switch (this.selectedApiType) {
        case 'start':
          return null
        case 'status':
          type = 'GET'
          break
        case 'stop':
          type = 'POST'
          break
        default:
          return null
      }

      const jobIDDescription = this.$t(
        'serviceDetail.sampleCode.jobIdDescription'
      )
      if (this.selectedLanguage === 'curl') {
        const base = `curl -X ${type} -H "x-matrixflow-secretkey: ${this.secretKey}" ${this.apiUrl}`
        return `${base}\n${jobIDDescription}`
      } else {
        const typeLowerCase = type.toLowerCase()
        const base = `import requests # ${this.$t(
          'serviceDetail.sampleCode.installInfo'
        )}
import pprint

# ${jobIDDescription}
url = "${this.apiUrl}"

headers = {
    "x-matrixflow-secretkey": "${this.secretKey}",
}

response = requests.${typeLowerCase}(url, headers=headers)

print("status_code:", response.status_code)

pprint.pprint(response.json())`
        return base
      }
    },
    requestHeaders() {
      const checkContentType = 'application/json'
      return {
        secretKey: `${this.secretKey}`,
        contentType: checkContentType // 使用する学習済みAIによって変更
      }
    },
    requestBody() {
      const items = {
        items: [
          {
            name: 'data_name',
            contents: this.$t(
              'serviceDetail.sampleCode.requestBody.learnItems.dataName'
            ),
            contentsSub: this.$t(
              'serviceDetail.sampleCode.requestBody.learnItems.dataNameSub'
            ),
            type: 'string'
          },
          {
            name: 'train_config',
            contents: this.$t(
              'serviceDetail.sampleCode.requestBody.learnItems.config'
            ),
            contentsSub: this.$t(
              'serviceDetail.sampleCode.requestBody.learnItems.configSub'
            ),
            type: 'string'
          },
          {
            name: 'trained_ai.name',
            contents: this.$t(
              'serviceDetail.sampleCode.requestBody.learnItems.trainedAiName'
            ),
            contentsSub: this.$t(
              'serviceDetail.sampleCode.requestBody.learnItems.optional'
            ),
            type: 'string'
          },
          {
            name: 'trained_ai.description',
            contents: this.$t(
              'serviceDetail.sampleCode.requestBody.learnItems.trainedAiDescription'
            ),
            contentsSub: this.$t(
              'serviceDetail.sampleCode.requestBody.learnItems.optional'
            ),
            type: 'string'
          }
        ]
      }
      return items
    },
    responses() {
      let response = `{
    "job_id": "${this.$t(
      'serviceDetail.sampleCode.responses.learnItems.jobId'
    )}",
    "data_name": "${this.$t(
      'serviceDetail.sampleCode.responses.learnItems.dataName'
    )}",
    "train_config": {
        "data": {
            "ratio": ${this.$t('trainedAiDetails.testDataRatio')}
        },
        "predictionColumn": "${this.$t(
          'selectTargetColumn.menu.targetColumnInfo.title'
        )}",
        "inputColumns": [ ${this.$t('service.learn.inputColumns')} ]
    }
}`
      if (this.checkShowStartApi) {
        if (this.recipeType === 'DEEP') {
          response = `{
      "job_id": "${this.$t(
        'serviceDetail.sampleCode.responses.learnItems.jobId'
      )}",
      "data_name": "${this.$t(
        'serviceDetail.sampleCode.responses.learnItems.dataName'
      )}",
      "train_config": {
          "learning_rate": ${this.$t('training.config.learningRate')},
          "batch_size": ${this.$t('training.config.batchSize')},
          "epoch": ${this.$t('training.config.epoch')},
          "data": {
              "ratio": ${this.$t('trainedAiDetails.testDataRatio')}
          },
          "saver": {
              "evaluate_every": {
                  "train": ${this.$t('training.config.evaluateEveryTrain')},
                  "test": ${this.$t('training.config.evaluateEveryTest')}
              },
              "num_checkpoints": 5
          },
          "inputColumns": []
      }
  }`
        }
      } else {
        if (this.selectedApiType === 'status') {
          response = `{
    "status": "${this.$t(
      'serviceDetail.sampleCode.responses.learningStatusItems.status'
    )}",
    "n_iter": ${this.$t(
      'serviceDetail.sampleCode.responses.learningStatusItems.n_iter'
    )},
    "cur_iter": ${this.$t(
      'serviceDetail.sampleCode.responses.learningStatusItems.cur_iter'
    )},
    "n_prediction_column": ${this.$t(
      'serviceDetail.sampleCode.responses.learningStatusItems.n_prediction_column'
    )},
    "cur_prediction_column": ${this.$t(
      'serviceDetail.sampleCode.responses.learningStatusItems.cur_prediction_column'
    )}
    "generated_trained_ai_id_list": ["${this.$t(
      'serviceDetail.sampleCode.responses.learningStatusItems.generated_trained_ai_id_list'
    )}"]
}
${this.$t('serviceDetail.sampleCode.responses.learningStatusItems.statusInfo')}`
        } else if (this.selectedApiType === 'stop') {
          response = `{
        "job_id": "${this.$t(
          'serviceDetail.sampleCode.responses.learningStopItmes.job_id'
        )}"
}`
        }
      }

      return {
        // 使用する学習済みAIによって変更
        type: 'application/json',
        code: response
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.sample-code {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: adjustVW(882);
  height: 100%;
  &-tab-wrap {
    margin: 0 0 $space-small;
    &-sample-code {
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
  }
  &-wrap {
    overflow: hidden;
    padding: $space-medium;
    background: $background-sub;
    border-radius: adjustVW(16);
  }
  &-block {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-inner {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 0 $space-medium;
    &:last-child {
      margin: 0;
    }
    &-sample-code {
      height: 100%;
    }
  }
  &-item {
    width: 30%;
    height: 100%;
    margin: 0 $space-small 0 0;
    &:last-child {
      width: calc(70% - #{$space-small});
      margin: 0;
    }
    &-icon {
      display: flex;
      &-wrap {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
      }
      &-tips {
        position: absolute;
        bottom: -$space-small;
        height: 100%;
        z-index: -1;
      }
      &-copy {
        padding: 0;
      }
    }
    &-text {
      margin: 0 $space-small 0 0;
    }
    &-sub {
      white-space: pre-line;
    }
  }
  &-select-box {
    display: grid;
    align-items: center;
    grid-template-columns: adjustVW(240) 1fr;
    height: adjustVH(48);
    margin: 0 $space-base $space-small;
  }
  &-comment {
    &-inner {
      text-align: right;
      white-space: pre-line;
    }
  }
}
</style>
