<template>
  <div class="selected-target-column-main">
    <select-target-column-top
      class="selected-target-column-top"
      @page-back="$emit('page-back')"
    />

    <div class="selected-target-column-information">
      <div class="selected-target-column-menu">
        <div class="selected-dataset-target-column">
          <target-column-card
            v-bind="targetColumnInfo"
            :checkNotes="checkNotes"
            :columnList="datasetInfo.columnsList"
            :tutorialFlag="tutorialFlag"
            :multiSelectMode="multiSelectMode"
            :loadingDatasetDetail="loadingDatasetDetail"
            :noneDatasetDetail="!trainingDatasetDetail.list"
            @submit-target-column="submitTargetColumn"
            @check-notes="$emit('check-notes', $event)"
            @get-selected-column="getSelectedColumn"
          />
        </div>
        <preprocess-menu-card
          v-if="
            !checkOtherAccountDataset &&
              progressTraining &&
              !progressTraining.laterSelectTargetColumn
          "
          class="selected-dataset-preprocess-menu"
          v-bind="menuInfo"
          :loadingDatasetDetail="loadingDatasetDetail"
          @go-preprocess-page="$emit('go-preprocess-page')"
        />
      </div>

      <selected-dataset-card
        class="selected-dataset-detail-info"
        v-bind="datasetInfo"
        :checkNotes="checkNotes"
        :preventCautionMessage="preventCautionMessage"
        :multiSelectMode="multiSelectMode"
        :trainingDatasetDetail="trainingDatasetDetail"
        :loadingDatasetDetail="loadingDatasetDetail"
        @check-notes="$emit('check-notes', $event)"
        @get-selected-column="getSelectedColumn"
        @change-added-selectbox="$emit('change-added-selectbox', $event)"
        @change-select-mode="$emit('change-select-mode', $event)"
        @go-manual-select-target-column="
          $emit('go-manual-select-target-column')
        "
        @show-too-large-data="$emit('show-too-large-data')"
      />
    </div>
  </div>
</template>

<script>
import preprocessMenuCard from '@/components/organisms/select-target-column/menu/preprocess-menu-card.vue'
import selectedDatasetCard from '@/components/organisms/select-target-column/dataset-info/seleced-dataset-card.vue'
import selectTargetColumnTop from '@/components/organisms/select-target-column/select-target-column-top.vue'
import targetColumnCard from '@/components/organisms/select-target-column/menu/target-column-card.vue'

export default {
  components: {
    preprocessMenuCard,
    selectedDatasetCard,
    selectTargetColumnTop,
    targetColumnCard
  },
  props: {
    checkNotes: Object,
    datasetInfo: Object,
    menuInfo: Object,
    targetColumnInfo: Object,
    tutorialFlag: Boolean,
    checkOtherAccountDataset: Boolean,
    progressTraining: Object,
    preventCautionMessage: Boolean,
    multiSelectMode: Boolean,
    trainingDatasetDetail: Object,
    loadingDatasetDetail: Boolean
  },
  methods: {
    getSelectedColumn: function (val) {
      this.$emit('get-selected-column', val)
    },
    submitTargetColumn: function () {
      this.$emit('submit-target-column')
    }
  }
}
</script>

<style lang="scss" scoped>
.selected-target-column-main {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.selected-target-column-top {
  padding-right: $space-medium;
  padding-left: $space-medium;
  margin-bottom: $space-base;
}

.selected-target-column-information {
  overflow: hidden;
  display: flex;
  justify-content: center;
  height: 100%;
  z-index: 102;
}

.selected-target-column-menu {
  display: flex;
  flex-direction: column;
  width: adjustVW(384);
  margin-right: $space-medium;
}

.selected-dataset-target-column {
  width: adjustVW(384);
  margin-bottom: $space-medium;
}

.selected-dataset-detail-info {
  display: flex;
  flex-direction: column;
  width: adjustVW(1224);
  height: calc(100% - #{$space-medium});
}
</style>
