<template>
  <div class="text-scroll">
    <div class="text-scroll-inner">
      <texts
        class="text-scroll-content"
        :text="contents"
        size="small"
        color="gray"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contents: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.text-scroll {
  overflow: hidden;
  height: 100%;
  padding: $space-sub $space-base $space-sub $space-sub;
  background-color: $background-sub;
  border-radius: adjustVW(8);
  &-inner {
    overflow-y: scroll;
    height: 100%;
    padding-right: $space-base;
    @include scrollbar;
  }
  &-content {
    word-break: break-all;
    white-space: pre-line;
  }
}
</style>
