<template>
  <!--
    アカウント情報・設定ページの右下にあたるカードです
  -->
  <div class="toggle-card-wrap">
    <!--
      アカウントの詳細のページです
    -->
    <toggle-card
      class="item"
      :title="$t('account.setting.accountdetail.title')"
    >
      <slot>
        <div class="detail">
          <texts
            class="detail-title"
            :text="$t('account.setting.accountdetail.language')"
            size="small"
            color="gray"
          />
          <select-box
            isGray
            :items="selectLangage"
            :value.sync="language"
            @input="setLanguage"
          />
        </div>
        <div class="detail">
          <texts
            class="detail-title"
            :text="$t('account.setting.accountdetail.timezone')"
            size="small"
            color="gray"
          />
          <select-box
            isGray
            scrollBar
            :items="selectTimezone"
            :value.sync="timezone"
            @input="setTimezoneDiff"
          />
        </div>
        <div class="detail">
          <texts
            class="detail-title"
            :text="$t('account.setting.accountdetail.textlines')"
            size="small"
            color="gray"
          />
          <input-box
            number
            :min="1"
            :step="1"
            :value="rowsPerPage"
            isGray
            @input="setRowsPerPage"
          />
        </div>
      </slot>
    </toggle-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import texts from '@/components/atoms/text.vue'
import toggleCard from '@/components/molecules/toggle-card.vue'
import inputBox from '@/components/molecules/input-box.vue'
import selectBox from '@/components/molecules/select-box.vue'

export default {
  components: {
    texts,
    toggleCard,
    inputBox,
    selectBox
  },
  computed: {
    ...mapGetters('settings', ['timezoneDiff', 'imagesPerPage', 'rowsPerPage', 'language']),
    languageOptions() {
      return Object.values(this.$i18n.availableLocales)
    },
    timezone() {
      return Number(this.timezoneDiff)
    },
    selectLangage() {
      return [
        {
          id: 0,
          name: 'English',
          value: this.languageOptions[0]
        },
        {
          id: 1,
          name: '日本語',
          value: this.languageOptions[1]
        }
      ]
    },
    selectTimezone() {
      return [
        {
          id: 0,
          name: '-12',
          value: -12
        },
        {
          id: 1,
          name: '-11',
          value: -11
        },
        {
          id: 2,
          name: '-10',
          value: -10
        },
        {
          id: 3,
          name: '-9',
          value: -9
        },
        {
          id: 4,
          name: '-8',
          value: -8
        },
        {
          id: 5,
          name: '-7',
          value: -7
        },
        {
          id: 6,
          name: '-6',
          value: -6
        },
        {
          id: 7,
          name: '-5',
          value: -5
        },
        {
          id: 8,
          name: '-4',
          value: -4
        },
        {
          id: 9,
          name: '-3',
          value: -3
        },
        {
          id: 10,
          name: '-2',
          value: -2
        },
        {
          id: 11,
          name: '-1',
          value: -1
        },
        {
          id: 1,
          name: '0',
          value: 0
        },
        {
          id: 12,
          name: '+1',
          value: +1
        },
        {
          id: 13,
          name: '+2',
          value: +2
        },
        {
          id: 14,
          name: '+3',
          value: +3
        },
        {
          id: 15,
          name: '+4',
          value: +4
        },
        {
          id: 16,
          name: '+5',
          value: +5
        },
        {
          id: 17,
          name: '+6',
          value: +6
        },
        {
          id: 18,
          name: '+7',
          value: +7
        },
        {
          id: 19,
          name: '+8',
          value: +8
        },
        {
          id: 20,
          name: '+9',
          value: +9
        },
        {
          id: 21,
          name: '+10',
          value: +10
        },
        {
          id: 22,
          name: '+11',
          value: +11
        },
        {
          id: 23,
          name: '+12',
          value: +12
        },
        {
          id: 24,
          name: '+13',
          value: +13
        }
      ]
    }
  },
  methods: {
    ...mapActions('auth', ['fetchAccountInfo']),
    ...mapActions('settings', ['setTimezoneDiff', 'setImagesPerPage', 'setRowsPerPage', 'changeLanguage']),
    setLanguage(lang) {
      this.changeLanguage({ value: lang, i18n: this.$i18n })
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-card {
  &-input {
    &-button {
      margin-top: $space-small;
    }
  }
  &-between {
    margin-top: $space-small;
    &:first-of-type {
      margin: 0;
    }
  }
  &-info {
    margin-top: $space-small;
  }
}

.item {
  margin: 0 0 $space-medium;
  &:last-of-type {
    margin: 0;
  }
}

.input {
  padding: 0 adjustVW(2);
  margin: 0 0 $space-small;
}
.status {
  margin: 0 0 $space-small;
}
.dummy {
  opacity: 0;
}

.detail {
  padding: 0 adjustVW(2);
  margin: 0 0 $space-small;
  &-title {
    margin: 0 0 $space-text;
  }
  &:last-of-type {
    margin: 0 0 adjustVW(2);
  }
}
</style>
