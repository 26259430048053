<template>
  <transition-toggle-contents>
    <list-card
      v-if="layoutType === 'grid'"
      key="grid"
      :items="sortedTrainedAis"
      :project="project"
      :modelType="modelType"
      :metricsDefs="metricsDefs"
      :trainedAiInput="trainedAiInput"
      :recipeType="recipeType"
      :sortedFlag="sortedFlag"
      :selectedColumnIndex="selectedColumnIndex"
      @click="$emit('show-detail', $event)"
    />
    <list-list
      v-else
      key="list"
      :items="sortedTrainedAis"
      :project="project"
      :mainMetrics="mainMetrics"
      :modelType="modelType"
      :metricsDefs="metricsDefs"
      :bestTrainedAi="bestTrainedAi"
      :trainedAiInput="trainedAiInput"
      :selectedColumnIndex="selectedColumnIndex"
      @click="$emit('show-detail', $event)"
    />
  </transition-toggle-contents>
</template>

<script>
import listCard from './list-card'
import listList from './list-list'
import { getModelType } from '@/lib/trainedAI.js'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

import * as metricsDefsByModelType from '@/components/organisms/trained-ai-detail/metricsDefs'

export default {
  components: {
    listCard,
    listList,
    transitionToggleContents
  },
  props: {
    layoutType: String,
    sortedTrainedAis: Array,
    bestTrainedAi: Object,
    project: Object,
    recipeType: String,
    trainedAiInput: Array,
    sortedFlag: Boolean,
    selectedColumnIndex: Number
  },
  computed: {
    mainMetrics() {
      const isTemplateMetrics = this.project?.template?.metrics
      if (isTemplateMetrics) {
        return isTemplateMetrics
      } else if (
        'test_accuracy' in (this.bestTrainedAi.summary?.metrics[0] ?? {})
      ) {
        return 'test_accuracy'
      } else if (
        'test_overall_accuracy' in
        (this.bestTrainedAi.summary?.metrics[0] ?? {})
      ) {
        return 'test_overall_accuracy'
      } else {
        return null
      }
    },
    modelType() {
      return getModelType(this.recipeType)
    },
    metricsDefs() {
      let metricsInfo = metricsDefsByModelType[this.modelType](
        this.$t.bind(this)
      )

      if (this.modelType === 'images') {
        const trainedAiInfo = this.sortedTrainedAis[0]

        // 特定の精度指標が含まれるかどうかで、分類が 2 種類 or それ以上かをチェックする
        const isMultiClass = Object.keys(
          trainedAiInfo.summary.metrics[0]
        ).includes('test_overall_accuracy')

        // 分類の種類数に応じて精度評価を変更する。
        metricsInfo = isMultiClass
          ? metricsDefsByModelType.imagesMultiClass(this.$t.bind(this))
          : metricsDefsByModelType.images(this.$t.bind(this))
      }

      // popup に表示したくない精度指標があるので除去
      const metricsDefsKeyList = Object.keys(metricsInfo)
      const removeAccuracyList = ['train_accuracy', 'train_loss']

      metricsInfo = metricsDefsKeyList.reduce((acc, key) => {
        if (!removeAccuracyList.includes(key)) acc[key] = metricsInfo[key]

        return acc
      }, {})

      return metricsInfo
    }
  }
}
</script>

<style lang="scss" scoped>
.list-table {
  width: 100%;
  height: 100%;
  padding: 0 $space-medium $space-medium;
}
</style>
