<template>
  <div class="feature-importance-graph">
    <div class="feature-importance-graph-wrap">
      <div class="feature-importance-graph-block">
        <transition-group name="toggle-items">
          <div
            v-for="[key, data] in limitCount"
            :key="key"
            class="feature-importance-graph-inner"
          >
            <div class="feature-importance-graph-item-name">
              <description :text="key" size="small" line="2" />
            </div>

            <div
              class="feature-importance-graph-item-bar"
              @mouseenter="isHoverGraph(key)"
              @mouseleave="isLeaveGraph()"
            >
              <div
                class="feature-importance-graph-item-bar-inner"
                :style="{ '--width': scaleWidth(data) + '%' }"
              >
                <div
                  v-tooltip="{
                    content: Math.round(data * 1000) / 1000,
                    trigger: ['manual'],
                    show: isTipsShow === key,
                    delay: { show: 500, hide: 500 }
                  }"
                  class="feature-importance-graph-item-tooltip-wrap"
                />
              </div>
            </div>
          </div>
        </transition-group>
      </div>

      <div class="feature-importance-graph-bg">
        <div class="feature-importance-graph-bg-scale">
          <div class="feature-importance-graph-bg-scale-item">
            0
          </div>
          <div class="feature-importance-graph-bg-scale-item">
            {{ scaleTick(1) }}
          </div>
          <div class="feature-importance-graph-bg-scale-item">
            {{ scaleTick(2) }}
          </div>
          <div class="feature-importance-graph-bg-scale-item">
            {{ scaleTick(3) }}
          </div>
          <div class="feature-importance-graph-bg-scale-item">
            {{ scaleTick(4) }}
          </div>
          <div class="feature-importance-graph-bg-scale-item">
            {{ scaleTick(5) }}
          </div>
        </div>
        <div class="feature-importance-graph-bg-line">
          <div class="feature-importance-graph-bg-line-item" />
          <div class="feature-importance-graph-bg-line-item" />
          <div class="feature-importance-graph-bg-line-item" />
          <div class="feature-importance-graph-bg-line-item" />
          <div class="feature-importance-graph-bg-line-item" />
          <div class="feature-importance-graph-bg-line-item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import description from '@/components/atoms/description'

export default {
  components: {
    description
  },
  data() {
    return {
      isTipsShow: ''
    }
  },
  props: {
    /** 表示する重要度のオブジェクト。数値の大きい順に並べ替えられます */
    features: Object,
    /** 表示する列の数 */
    maxCount: Number
  },
  methods: {
    scaleWidth(e) {
      if (e !== 0) {
        return (e / this.maxScale) * 100
      } else {
        return 0
      }
    },
    scaleTick(e) {
      if (this.maxScale < 0.45) {
        return (this.maxScale / 5) * e
      } else {
        return Math.round((this.maxScale / 5) * e * 10) / 10
      }
    },
    isHoverGraph(e) {
      this.isTipsShow = e
    },
    isLeaveGraph() {
      this.isTipsShow = ''
    }
  },
  computed: {
    maxScale() {
      return this.sortedEntries.length === 0
        ? 0
        : Math.ceil(this.sortedEntries[0][1] * 10) / 10
    },
    sortedEntries() {
      return Object.entries(this.features).sort((x, y) => y[1] - x[1])
    },
    limitCount() {
      if (this.sortedEntries.length > this.$props.maxCount) {
        return this.sortedEntries.slice(0, this.$props.maxCount)
      } else {
        return this.sortedEntries
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.feature-importance-graph {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.feature-importance-graph-wrap {
  position: relative;
  height: 100%;
}

.feature-importance-graph-block {
  position: relative;
  overflow-y: scroll;
  width: 100%;
  height: calc(100% - #{$space-large});
  padding: $space-small $space-base $space-small 0;
  margin: $space-large 0 0;
  z-index: 3;
  @include scrollbar;
}

.feature-importance-graph-inner {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 0 $space-large;
  &:last-child:not(.toggle-items-leave-active, .toggle-items-enter-active) {
    margin: 0;
  }
}

.feature-importance-graph-item {
  &-name {
    overflow: hidden;
    display: flex;
    align-items: center;
    width: adjustVW(200);
    height: adjustVH(48);
    margin: 0 $space-small 0 0;
  }

  &-bar {
    width: calc(100% - #{adjustVW(200) + $space-small});
    @include enterBar;
    &-inner {
      --width: 100%;
      position: relative;
      width: calc(var(--width) - #{$space-small});
      height: adjustVH(16);
      background: $line-emphasis;
      border-radius: adjustVW(4);
    }
    .toggle-items-leave-active & {
      @include leaveBar;
    }
  }
}

.feature-importance-graph-item-tooltip {
  &-inner {
    width: fit-content;
    max-width: 100%;
  }
}

.feature-importance-graph-bg {
  position: absolute;
  top: 0;
  right: $space-base;
  width: calc(100% - #{adjustVW(200) + $space-base});
  height: 100%;
  z-index: 2;

  &-scale {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 $space-min;

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: adjustVW(32);
      height: adjustVH(24);
      color: $text-sub;
      font-size: $text-min;
      @include text-crop;
    }
  }

  &-line {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - #{$space-large});
    margin-top: $space-large;

    &-item {
      position: relative;
      width: adjustVW(32);
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 1px;
        height: 100%;
        background: $line-gray;
        transform: translateX(-50%);
      }
    }
  }
}
.toggle-items-enter-active,
.toggle-items-leave-active {
  transition: transform $transition-base, opacity $transition-base;
}
.toggle-items-enter,
.toggle-items-leave-to {
  opacity: 0;
  transform: translateX(-$space-small);
  will-change: opacity, transform;
}
</style>
