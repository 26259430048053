<template>
  <div class="inference-dim-red-result">
    <div class="inference-dim-red-result-term">
      <texts
        class="inference-dim-red-result-term-item1"
        :text="$t('inference.result.dimRed.title')"
      />
    </div>

    <div class="inference-dim-red-result-wrapper">
      <div class="inference-dim-red-result-main">
        <card-base class="inference-dim-red-result-main-card">
          <dimensionality-reduction-graph
            class="inference-dim-red-result-graph"
            :result="dimRedResult"
            :showImage="true"
          />
        </card-base>
      </div>

      <div class="inference-dim-red-result-used-ai">
        <card-base class="inference-dim-red-result-used-ai-card">
          <model-info
            :title="$t('inference.result.card.usedAi')"
            :modelInfo="selectedTrainedAi"
          />
        </card-base>
      </div>
    </div>
  </div>
</template>

<script>
import cardBase from '@/components/atoms/card-base.vue'
import modelInfo from '@/components/organisms/model-info.vue'
import texts from '@/components/atoms/text.vue'
import DimensionalityReductionGraph from './dimensionality-reduction-graph.vue'

import demoData from './demoData'

export default {
  components: {
    cardBase,
    DimensionalityReductionGraph,
    modelInfo,
    texts
  },

  data() {
    return {
      inferenceResult: {
        result: demoData
      }
    }
  },
  props: {
    dimRedResult: Array,
    selectedTrainedAi: Object
  }
}
</script>

<style lang="scss" scoped>
.inference-dim-red-result-wrapper {
  overflow: hidden;
  display: grid;
  grid-template-columns: adjustVW(1248) minmax(0, #{adjustVW(384)});
  grid-template-rows: 100%;
  grid-column-gap: $space-medium;
  height: 100%;
  padding-bottom: $space-medium;
}

.inference-dim-red-result {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &-main {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 100%;
    padding-left: $space-medium;
  }

  &-used-ai {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    height: fit-content;
    padding-right: $space-medium;
  }

  &-main-card {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-used-ai-card {
    width: 100%;
    height: 100%;
  }

  &-graph {
    width: 100%;
    height: 100%;
  }
}

.inference-dim-red-result-term {
  display: flex;
  padding: 0 $space-medium;
  margin-bottom: $space-sub;

  &-item1 {
    margin-right: $space-base;
  }
}
</style>
