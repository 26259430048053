<template>
  <div class="delete-project-wrap">
    <div class="delete-project-list-wrap">
      <div class="delete-project-item">
        <texts :text="project.name" size="large" />
        <div class="delete-project-item-block">
          <description :text="project.description" />
        </div>
        <div class="delete-project-item-block">
          <div class="delete-project-item-inner">
            <text-with-title
              v-if="project.sourceTemplate"
              class="top-detail-template"
              :title="$t('projectDetail.templateUsed')"
              :text="project.sourceTemplate.name"
            />
            <text-with-title
              v-else
              class="top-detail-template"
              :title="$t('projectDetail.templateUsed')"
              :text="$t('projectDetail.nonUseTemplate')"
            />
          </div>
        </div>
        <div
          v-if="project.numAIs > 0 || project.numData > 0"
          class="delete-project-item-block"
        >
          <div v-if="project.numAIs > 0" class="delete-project-item-inner">
            <div class="delete-project-item-title">
              <texts
                :text="$t('projectDetail.popup.delete.trainedAis')"
                size="small"
                color="gray"
              />
            </div>
            <div class="delete-project-item-list">
              <div
                v-for="(item, index) in limitCount(project.listAIs)"
                :key="index"
                class="delete-project-item-list-item"
              >
                <router-link
                  :to="{
                    name: 'trainedAiProjectDetail',
                    params: { projectId: project.id, id: item.id }
                  }"
                >
                  <text-with-icon
                    :text="item.name"
                    iconName="newTab"
                    pos="right"
                    color="link-default"
                  />
                </router-link>
              </div>
            </div>
          </div>
          <div v-if="project.numData > 0" class="delete-project-item-inner">
            <div class="delete-project-item-title">
              <texts
                :text="$t('projectDetail.popup.delete.dataset')"
                size="small"
                color="gray"
              />
            </div>
            <div class="delete-project-item-list">
              <div
                v-for="(item, index) in limitCount(project.listData)"
                :key="index"
                class="delete-project-item-list-item"
              >
                <router-link
                  :to="{
                    name: 'datasetProjectDetail',
                    params: { projectId: project.id, id: item.id }
                  }"
                >
                  <text-with-icon
                    :text="item.name"
                    iconName="newTab"
                    pos="right"
                    color="link-default"
                  />
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="project.nServices.total > 0 || project.numRecipe > 0"
          class="delete-project-item-block"
        >
          <div
            v-if="project.nServices.total > 0"
            class="delete-project-item-inner"
          >
            <div class="delete-project-item-title">
              <texts
                :text="$t('projectDetail.popup.delete.service')"
                size="small"
                color="gray"
              />
            </div>
            <div class="delete-project-item-list">
              <div
                v-for="(item, index) in limitCount(project.listServices)"
                :key="index"
                class="delete-project-item-list-item"
              >
                <router-link
                  :to="{
                    name: 'serviceProjectDetail',
                    params: {
                      serviceType: item.serviceType,
                      projectId: project.id,
                      id: item.id
                    }
                  }"
                >
                  <text-with-icon
                    :text="item.name"
                    iconName="newTab"
                    pos="right"
                    color="link-default"
                  />
                </router-link>
              </div>
            </div>
          </div>
          <div v-if="project.numRecipe > 0" class="delete-project-item-inner">
            <div class="delete-project-item-title">
              <texts
                :text="$t('projectDetail.popup.delete.recipe')"
                size="small"
                color="gray"
              />
            </div>
            <div class="delete-project-item-list">
              <div
                v-for="(item, index) in limitCount(project.listRecipe)"
                :key="index"
                class="delete-project-item-list-item"
              >
                <router-link
                  :to="{
                    name: 'recipeProjectDetail',
                    params: {
                      projectId: project.id,
                      id: item.id + '-' + item.accountId
                    }
                  }"
                >
                  <text-with-icon
                    :text="item.name"
                    iconName="newTab"
                    pos="right"
                    color="link-default"
                  />
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="delete-project-item-block">
          <div class="delete-project-item-inner">
            <text-with-title :title="$t('common.createTime')">
              <a-time :value="project.createTime" />
            </text-with-title>
          </div>
          <div class="delete-project-item-inner">
            <text-with-title :title="$t('common.updateTime')">
              <a-time :value="project.updateTime" />
            </text-with-title>
          </div>
        </div>
      </div>
    </div>
    <div class="delete-project-button-wrap">
      <div class="delete-project-button-inner">
        <button-main
          :text="$t('common.cancel')"
          type="sub"
          :disabled="disableClick"
          @click="$emit('close-modal')"
        />
      </div>
      <div class="delete-project-button-inner">
        <button-main
          :text="$t('common.deleteButton')"
          type="caution"
          :disabled="disableClick"
          @click="$emit('delete-project')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import description from '@/components/atoms/description'
import buttonMain from '@/components/atoms/button-main'
import textWithTitle from '@/components/molecules/text-with-title'
import textWithIcon from '@/components/molecules/text-with-icon'

export default {
  components: {
    texts,
    description,
    buttonMain,
    textWithTitle,
    textWithIcon
  },
  props: {
    project: Object,
    disableClick: Boolean
  },
  methods: {
    limitCount(arr) {
      if (!arr || arr.length === 0) return []
      if (arr.length > 4) {
        return arr.slice(0, 4)
      } else {
        return arr
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.delete-project {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-list-wrap {
    overflow-y: auto;
    width: adjustVW(744);
    padding: 0 $space-small 0 0;
    margin: 0 0 $space-medium;
    @include scrollbar;
  }
  &-item {
    &-block {
      display: flex;
      margin: 0 0 $space-medium;
      &:last-of-type {
        margin: 0;
      }
      &-list {
        flex-direction: column;
      }
    }
    &-inner {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      width: calc(50% - #{$space-sub});
      &:last-child {
        margin: 0;
      }
      + .delete-project-item-inner {
        padding: 0 0 0 $space-medium;
        margin: 0 0 0 $space-medium;
        border-left: $border-sub;
      }
    }
    &-content {
      margin: 0 0 $space-base;
    }
    &-list {
      display: flex;
      flex-direction: column;
      &-item {
        margin: 0 0 $space-sub;
        transition: opacity $transition-base;
        &:hover {
          opacity: 0.5;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
        > a {
          display: inline-block;
          max-width: 100%;
        }
      }
    }
    &-title {
      margin: 0 0 $space-base;
    }
  }
  &-button {
    &-wrap {
      display: flex;
      justify-content: flex-end;
    }
    &-inner {
      margin: 0 $space-small 0 0;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
