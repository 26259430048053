<template>
  <div class="project-template-create-wrap">
    <loading-processing v-if="submitting" />
    <project-template-edit-top />

    <transition-toggle-contents name="toggle-contents" mode="out-in">
      <div v-if="showEditForm === 'summary'">
        <form class="template-form" @submit.prevent="submitSummaryForm">
          <div class="template-form-main">
            <template-info
              :project="projectTemplateInfo"
              @input-edit-form="inputEditForm($event)"
              @tag-add="$emit('tag-add', $event)"
              @tag-remove="$emit('tag-remove', $event)"
            />
          </div>

          <div class="submit-button">
            <button-main
              type="sub"
              :disabled="submitting"
              :text="$t('common.cancel')"
              @click="$emit('cancel-edit-form')"
            />
            <button-main
              button-type="submit"
              type="emphasis"
              :disabled="submitting"
              :text="$t('common.button.next')"
            />
          </div>
        </form>
      </div>

      <div v-else-if="showEditForm === 'sampleDataset'">
        <div class="template-form">
          <div class="template-form-main">
            <select-sample-dataset
              v-bind="sampleDataset"
              @input-edit-form="inputEditForm($event)"
            />
          </div>

          <div class="submit-button">
            <button-main
              type="sub"
              :disabled="submitting"
              :text="$t('common.cancel')"
              @click="$emit('cancel-edit-form')"
            />
            <button-main
              button-type="submit"
              type="emphasis"
              :disabled="submitting"
              :text="$t('common.button.next')"
              @click="submitSampleDataset"
            />
          </div>
        </div>
      </div>

      <div v-else-if="showEditForm === 'effect'">
        <form class="template-form" @submit.prevent="submitEffectForm">
          <div class="template-form-main">
            <template-effect-info
              :projectTemplateEffect="projectTemplateEffect"
              @input-edit-form="inputEditForm($event)"
            />
          </div>

          <div class="submit-button">
            <button-main
              type="sub"
              :disabled="submitting"
              :text="$t('common.cancel')"
              @click="$emit('cancel-edit-form')"
            />
            <button-main
              button-type="submit"
              type="emphasis"
              :disabled="submitting"
              :text="$t('common.button.next')"
            />
          </div>
        </form>
      </div>

      <div v-else-if="showEditForm === 'effectImage'">
        <form class="template-form" @submit.prevent="submitEffectImage">
          <div>
            <upload-summary-image
              v-bind="summaryImages.main"
              :index="0"
              @on-file-input="$emit('on-file-input', $event)"
            />
            <upload-summary-image
              v-bind="summaryImages.sub1"
              :index="1"
              @on-file-input="$emit('on-file-input', $event)"
            />
            <upload-summary-image
              v-bind="summaryImages.sub2"
              :index="2"
              @on-file-input="$emit('on-file-input', $event)"
            />
          </div>

          <div class="submit-button">
            <button-main
              type="sub"
              :disabled="submitting"
              :text="$t('common.cancel')"
              @click="$emit('cancel-edit-form')"
            />
            <button-main
              button-type="submit"
              type="emphasis"
              :disabled="submitting"
              :text="$t('common.button.confirm')"
            />
          </div>
        </form>
      </div>
    </transition-toggle-contents>
  </div>
</template>

<script>
import ButtonMain from '@/components/atoms/button-main.vue'
import LoadingProcessing from '@/components/atoms/loading-processing.vue'
import ProjectTemplateEditTop from '@/components/organisms/project-template-edit/project-template-edit-top.vue'
import SelectSampleDataset from '@/components/organisms/project-template-edit/edit-form/select-sample-dataset.vue'
import TemplateEffectInfo from '@/components/organisms/project-template-edit/edit-form/template-effect-info.vue'
import TemplateInfo from '@/components/organisms/project-template-edit/edit-form/template-info.vue'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import UploadSummaryImage from '@/components/organisms/project-template-edit/edit-form/upload-summary-image.vue'

export default {
  components: {
    ButtonMain,
    LoadingProcessing,
    ProjectTemplateEditTop,
    SelectSampleDataset,
    TemplateEffectInfo,
    TemplateInfo,
    TransitionToggleContents,
    UploadSummaryImage
  },
  props: {
    project: Object,
    projectTemplateEffect: Object,
    projectTemplateInfo: Object,
    sampleDataset: Object,
    showEditForm: String,
    submitting: {
      type: Boolean,
      default: false
    },
    summaryImages: Object
  },
  data() {
    return {
      effectForm: {
        name: '',
        description: '',
        formula: {}
      },
      sampleDatasetForm: {
        datasetId: this.sampleDataset.selectedDatasetId
      },
      summaryForm: {
        name: this.projectTemplateInfo.name,
        description: this.projectTemplateInfo.description,
        wantToAchieve: this.projectTemplateInfo.wantToAchieve,
        predictedAndClassified: this.projectTemplateInfo.predictedAndClassified,
        industryOccupation: this.projectTemplateInfo.industryOccupation,
        tags: this.projectTemplateInfo.tags
      }
    }
  },
  methods: {
    inputEditForm: function (obj) {
      const { form, type } = obj
      const keys = Object.keys(form)

      if (type === 'projectTemplate') {
        keys.forEach((key) => {
          this.summaryForm[key] = form[key]
        })
      } else if (type === 'projectTemplateSampleDataset') {
        keys.forEach((key) => {
          this.sampleDatasetForm[key] = form[key]
        })
      } else if (type === 'projectTemplateEffect') {
        keys.forEach((key) => {
          this.effectForm[key] = form[key]
        })
      }
    },
    submitEffectForm: function () {
      this.$emit('submit-edit-form', {
        type: 'projectTemplateEffect',
        form: {
          ...this.effectForm
        }
      })
    },
    submitEffectImage: function () {
      this.$emit('submit-edit-form', {
        type: 'projectTemplateEffectImage',
        form: {}
      })
    },
    submitSummaryForm: function () {
      this.$emit('submit-edit-form', {
        type: 'projectTemplate',
        form: {
          ...this.summaryForm
        }
      })
    },
    submitSampleDataset: function () {
      this.$emit('submit-edit-form', {
        type: 'projectTemplateSampleDataset',
        form: {
          ...this.sampleDatasetForm
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.template-form {
  padding-top: $space-small;
  padding-right: $space-medium;
  padding-bottom: $space-small;
  padding-left: $space-medium;
  border-color: $border-gray;
  border-style: solid;
  background-color: $white;
  border-radius: adjustVW(16);
}

.template-form-main {
  margin-bottom: $space-medium;
}

.template-form-check-box {
  margin-top: $space-small;
  margin-bottom: $space-small;
}

.project-template-create-wrap {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: $space-base;
  height: calc(100vh - #{adjustVH($headerTabHeight)});
  padding: 0 $space-medium $space-medium;
}

.project-template-create-form {
  overflow: hidden;
  max-height: 100%;
  margin-top: $space-medium;
}
</style>
