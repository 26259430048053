var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"images-wrap"},[_c('div',{staticClass:"images-top"},[_c('texts',{staticClass:"images-top-title",attrs:{"text":_vm.$t('training.config.detail.trainingDataset'),"size":"large"}}),_c('div',{staticClass:"images-inner"},[_c('text-with-title',{staticClass:"images-inner-item",attrs:{"title":_vm.$t('training.config.detail.dataCount'),"text":_vm.selectedTrainingData.nImages}}),_c('text-with-title',{staticClass:"images-inner-item",attrs:{"title":_vm.$t('training.config.detail.labelCount'),"text":_vm.selectedTrainingData.nLabels}}),_c('text-with-title',{staticClass:"images-inner-item",attrs:{"title":_vm.$t('training.config.detail.classCount'),"text":_vm.selectedTrainingData.nClasses}})],1)],1),_c('button-toggle',{staticClass:"images-list",attrs:{"openText":_vm.$t('training.config.detail.datasetDetail'),"closeText":_vm.$t('training.config.detail.datasetDetail')}},[_c('list-item-slot',{staticClass:"images-list-body",attrs:{"field":_vm.field,"items":_vm.items},scopedSlots:_vm._u([{key:"name",fn:function(entry){return [_c('texts',{attrs:{"text":entry.item,"size":"small"}})]}},{key:"count",fn:function(entry){return [_c('div',{staticClass:"images-item-count-wrap"},[_c('texts',{staticClass:"images-item-count-text",attrs:{"text":entry.item,"size":"small"}}),_c('texts',{staticClass:"images-item-count-percentage",attrs:{"text":Math.ceil((entry.item / _vm.selectedTrainingData.nLabels) * 100) +
                '%',"size":"min","color":"gray"}})],1),_c('graph-bar-count',{staticClass:"images-item-bar",attrs:{"count":entry.item,"maxCount":_vm.selectedTrainingData.nLabels}})]}},{key:"image",fn:function(entry){return [_c('div',{staticClass:"images-item-inner"},_vm._l((entry.item.datas),function(data,index){return _c('button',{key:index,staticClass:"images-item-data",on:{"click":function($event){return _vm.showPopup(data.label, index)},"mouseleave":_vm.closePopup}},[_c('image-wrap',{attrs:{"url":data.body}}),_c('popup-balloon-image',{staticClass:"images-item-balloon",class:{
                'images-item-balloon-right': index > 2
              },style:({ '--image-position': index % 4 }),attrs:{"showPopup":_vm.popupId === index && _vm.popupLabel === data.label,"posRight":index > 1,"posBottom":Object.keys(_vm.selectedTrainingData.statistics).length -
                  Object.keys(_vm.selectedTrainingData.statistics).indexOf(
                    data.label.toString()
                  ) <
                  2 && Object.keys(_vm.selectedTrainingData.statistics).length > 3,"popupContents":data}})],1)}),0)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }