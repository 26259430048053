<template>
  <plan-card-base
    class="basic-plan-card-wrapper"
    v-bind="planData"
    :planName="this.$i18n.t('changePlan.basicPlanInfo.planName')"
    :isSelected="isSelected"
    @active-item="activeItem"
  >
    <a :href="$urls.changePlan" target="_blank">
      <texts
        class="basic-plan-card-price"
        size="link"
        :text="this.$i18n.t('changePlan.basicPlanInfo.price')"
      />
    </a>
  </plan-card-base>
</template>

<script>
import PlanCardBase from '@/components/molecules/change-plan/plan-card-base.vue'
import Texts from '@/components/atoms/text.vue'

export default {
  components: {
    PlanCardBase,
    Texts
  },
  props: {
    planData: Object,
    isSelected: Boolean
  },
  methods: {
    activeItem: function ({ id }) {
      this.$emit('active-item', { id })
    }
  }
}
</script>

<style lang="scss" scoped>
.basic-plan-card-wrapper {
  padding: 0 adjustVW(2);
  box-shadow: 0 0 0 adjustVW(2) $line-emphasis inset, $box-shadow-main;
}

.basic-plan-card-price {
  color: $key-color;
  font-size: $text-large;
}
</style>
