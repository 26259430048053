<template>
  <select-mode
    :accountInfo="accountInfo"
    @use-template="$emit('use-template')"
    @use-recipe="$emit('use-recipe', $event)"
  />
</template>

<script>
import selectMode from '@/components/organisms/select-template/select-mode.vue'

export default {
  components: {
    selectMode
  },
  props: {
    accountInfo: Object
  }
}
</script>
