<template>
  <div class="top-editting-box">
    <top-edit-box
      v-model="inputItem.name"
      class="top-editting-box-inner"
      :accept="accept"
      :error="error"
      :disabledSubmit="disabledSubmit"
      :nameValidationSuggests="nameValidationSuggests"
      isNameValidation
      @click-finish-edit="$emit('finish-edit')"
    />
    <top-edit-box
      v-model="inputItem.description"
      isTextArea
      size="description"
    />
  </div>
</template>

<script>
import TopEditBox from '@/components/atoms/top-edit-box.vue'

export default {
  components: {
    TopEditBox
  },
  props: {
    /** タイトル重複チェックに合格したかどうか */
    accept: {
      type: Boolean,
      default: null
    },
    /** タイトル重複チェックでエラーの場合 */
    error: {
      type: Boolean,
      default: null
    },
    /** 保存不可状態 */
    disabledSubmit: {
      type: Boolean,
      default: false
    },
    /** v-modelで渡す値
     * name: タイトル部分のテキスト
     * description: 説明部分のテキスト
     */
    value: Object,
    /** タイトル重複チェック時の変更先のサジェスト */
    nameValidationSuggests: Array
  },
  data() {
    return {
      closeFlag: true
    }
  },
  computed: {
    inputItem: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  methods: {
    monitorMouseEvent(target, eventType, callback) {
      if (!this._eventRemovers) {
        this._eventRemovers = []
      }
      target.addEventListener(eventType, callback)
      this._eventRemovers.push({
        remove() {
          target.removeEventListener(eventType, callback)
        }
      })
    }
  },
  mounted() {
    this.monitorMouseEvent(
      window,
      'mousedown',
      function (e) {
        this.closeFlag = !this.$el.contains(e.target)
      }.bind(this)
    )

    this.monitorMouseEvent(
      window,
      'mouseup',
      function (e) {
        if (!this.$el.contains(e.target) && !this.error && this.closeFlag) {
          this.$emit('finish-edit')
        }
      }.bind(this)
    )
  },
  destroyed() {
    if (this._eventRemovers) {
      this._eventRemovers.forEach(function (eventRemover) {
        eventRemover.remove()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.top-editting-box {
  width: fit-content;
  z-index: 1;
  &-inner {
    margin-bottom: $space-sub;
  }
}
</style>
