<template>
  <div class="signup-form-item">
    <input-box-validation
      v-model="inputValue"
      class="signup-form-inputbox"
      :accept="isOk"
      :error="isError"
      :isPass="isPass"
      :placeholder="exampleText"
      :popupText="popupText"
      popupView
      :title="title"
      :emailSuggest="emailSuggest"
      :nameValidationSuggests="nameValidationSuggests"
      :name="name"
      isBottom
    />
  </div>
</template>

<script>
import inputBoxValidation from '@/components/molecules/input-box-validation.vue'

export default {
  components: {
    inputBoxValidation
  },
  props: {
    explanationText: String,
    errorText: String,
    passwordTooLong: String,
    title: String,
    value: String,
    isOk: Boolean,
    isError: Boolean,
    isPass: {
      type: Boolean,
      default: false
    },
    popupView: {
      type: Boolean,
      default: true
    },
    exampleText: {
      type: String,
      default: ''
    },
    emailSuggest: {
      type: Boolean,
      default: false
    },
    nameValidationSuggests: {
      type: Array,
      default: () => null
    },
    name: String,
    focusStart: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    popupText() {
      if (this.isError) {
        if (this.passwordTooLong && this.value.length > 72) {
          return this.passwordTooLong
        } else {
          return this.errorText
        }
      } else {
        return this.explanationText
      }
    },
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('change', value)
      }
    },
    inSignUp() {
      return this.$route.name === 'signup'
    }
  }
}
</script>

<style lang="scss" scoped>
.signup-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $background;

  &-item {
    width: 100%;
    margin-bottom: adjustVW(40);
    font-size: $text-page-title;
    @include text-crop;
  }

  &-inputbox {
    width: 100%;
  }
}
</style>
