var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-base',{staticClass:"plan-card-base-wrapper"},[(_vm.isPlanCard)?_c('div',{staticClass:"plan-card-name-wrapper"},[_c('texts',{attrs:{"isBold":true,"text":_vm.planName}}),(_vm.isSelected)?_c('texts',{staticClass:"plan-card-name-selected",attrs:{"color":"gray","size":"min","text":this.$i18n.t('changePlan.planSelected')}}):_vm._e()],1):_vm._e(),_vm._l((_vm.menuColumn),function(term,index){return _c('div',{key:index,staticClass:"plan-card-menu-item-base",class:{
      'plan-card-menu-item-wrapper': _vm.isPlanCard,
      'term-card-menu-item-wrapper': _vm.isTermsCard,
      'plan-card-menu-selected-item': _vm.activeTerm === term.id,
      'plan-card-menu-selected-top-item':
        _vm.activeTerm === term.id && index === 0
    },on:{"mouseover":function($event){return _vm.activeItem({ id: term.id })}}},[_c('div',{staticClass:"plan-card-menu-item-base-inner",class:{
        'plan-card-menu-item-base-inner-sprit-option': term.isSpritOption
      }},[(term.isIcon)?_c('icon',{staticClass:"plan-card-menu-item-base-icon",attrs:{"iconName":term.value,"size":"small","color":term.value === 'check' ? 'emphasis' : 'gray'}}):_c('texts',{staticClass:"plan-card-menu-item-base-text",attrs:{"text":term.value,"color":term.color,"size":17}}),(term.option)?_c('texts',{staticClass:"plan-card-menu-item-base-text",attrs:{"color":"gray","size":12,"text":'※' + term.option}}):_vm._e()],1)])}),_c('div',{staticClass:"plan-card-menu-bottom-item-base",class:{
      'plan-card-menu-item-wrapper': _vm.isPlanCard,
      'term-card-menu-item-wrapper': _vm.isTermsCard,
      'plan-card-menu-selected-item': _vm.activeTerm === 'price'
    },on:{"mouseover":function($event){return _vm.activeItem({ id: 'price' })}}},[_vm._t("default")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }