<template>
  <tr v-if="graph != null" class="table-graph">
    <td class="table-graph-inner">
      <texts :text="$t('common.table.graph.title')" size="small" />
    </td>
    <td
      v-for="(column, key) in fixColumns"
      :key="key"
      class="table-graph-inner"
      :class="[
        cellStatus(key + 1),
        { 'table-graph-inner-button': isCellButton }
      ]"
      @mouseover="$emit('mouseover', key + 1)"
      @click="$emit('click', key + 1)"
    >
      <div
        v-if="!checkUnique(graph[column]) && !checkLotUnique(graph[column])"
        class="table-graph-icon"
      >
        <icons
          iconName="histogram"
          isButton
          color="emphasis"
          @icon-click="showGraph(column)"
          @click.native.stop
        />
      </div>
      <texts
        v-else-if="checkLotUnique(graph[column])"
        :text="$t('common.table.graph.lotUnique')"
        size="min"
        color="gray"
        isShowAll
      />
      <texts
        v-else
        :text="$t('common.table.graph.unique')"
        size="min"
        color="gray"
        isShowAll
      />
      <template v-if="graphData">
        <popup
          :showPopup="showPopup && showGraphName === column"
          :title="popupTitle"
          maxHeight
          @close-modal="showGraph(null)"
        >
          <graph-bar
            class="graph-contents"
            :showLegends="false"
            :data="graphData"
          />
        </popup>
      </template>
    </td>
  </tr>
</template>

<script>
import graphBar from '@/components/molecules/bar'
import popup from '@/components/molecules/popup/popup.vue'
import icons from '@/components/atoms/icon'

export default {
  components: {
    graphBar,
    popup,
    icons
  },
  data() {
    return {
      showGraphName: null,
      showPopup: false
    }
  },
  props: {
    /** テーブルの列名の配列 */
    tableColumns: Array,
    /** グラフボタンをクリックで表示されるグラフ */
    graph: Object,
    hoverTarget: Number,
    selectedColumn: Array,
    disabledItems: Array,
    isCellButton: Boolean
  },
  computed: {
    convertedTableColumns() {
      const tableColumns = this.tableColumns.slice()
      tableColumns.splice(tableColumns.indexOf('/'), 1)
      return tableColumns
    },
    popupTitle() {
      if (this.showGraphName == null) return null
      return this.showGraphName
    },
    graphData() {
      if (this.showGraphName == null) return null
      return [
        {
          name: this.showGraphName,
          values: this.graph[this.showGraphName].counts,
          color: '#850491'
        }
      ]
    },
    fixColumns() {
      return this.tableColumns.slice(1, this.tableColumns.length)
    }
  },
  methods: {
    cellStatus(index) {
      if (
        this.disabledItems &&
        this.disabledItems.includes(this.tableColumns[index])
      ) {
        return 'table-graph-inner-disabled'
      } else if (
        this.selectedColumn &&
        this.selectedColumn.includes(this.tableColumns[index])
      ) {
        return 'table-graph-inner-selected'
      } else if (index === this.hoverTarget) {
        return 'table-graph-inner-hover'
      } else {
        return ''
      }
    },
    showGraph(column) {
      if (column !== null && this.graph[column] != null) {
        this.showGraphName = column
        this.showPopup = true
      } else {
        this.showGraphName = null
        this.showPopup = false
      }
    },
    checkLotUnique(data) {
      return data?.lotUnique
    },
    checkUnique(data) {
      return data?.unique
    }
  }
}
</script>

<style lang="scss" scoped>
.table-graph {
  &-inner {
    position: relative;
    overflow: hidden;
    height: adjustVH(80);
    padding: 0 $space-small;
    font-size: $text-base;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: background-color $transition-base;
    will-change: background-color;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 1px;
      width: 100%;
      height: 1px;
      background: $line-gray;
    }
    &:first-child {
      position: sticky;
      left: 0;
      overflow: visible;
      background: $background;
      z-index: 20;
      &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 1px;
        width: 100%;
        height: 1px;
        background: $line-gray;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 1px;
        height: 100%;
        background: $border-gray;
      }
    }
    &-button {
      cursor: pointer;
    }
    &-hover {
      background: $background-decoration;
    }
    &-selected {
      background: $key-lite;
    }
    &-large-csv {
      white-space: pre;
    }
    &-disabled {
      background: $background-sub;
      cursor: not-allowed;
    }
  }
  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.graph {
  &-contents {
    width: adjustVW(1000);
    height: 100%;
  }
}
</style>
