<template>
  <div class="wrap">
    <div
      v-if="expressionList && expressionList.length > 0"
      ref="scrollWrap"
      class="list"
      @scroll="scroll"
    >
      <div v-for="item in expressionList" :key="item.id" class="item">
        <optimization-expression-item
          v-model="item.expression"
          class="item-expression"
          :columns="filteredColumns"
          :count="item.count"
          :isError="item.isError"
          @delete-expression-item="deleteItem(item.id)"
          @check-expressions="checkExpressions"
        />
        <div class="item-conditions">
          <optimization-select-box
            :ref="'selectBox' + item.id"
            v-model="item.condition"
            isSpaceMin
            isExpression
            :isInference="isInference"
            @select-box-open="openBox(item.id)"
            @select-box-close="closeBox"
            @select-type="checkExpressions"
          />
        </div>
      </div>
    </div>
    <button-main
      class="button"
      :text="$t('trainedAi.optimization.expression.addButton')"
      type="dashed-sub"
      :isDisabled="checkDisabled"
      @click="addExpressionItem"
    />
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import optimizationExpressionItem from '@/components/organisms/optimization/optimization-expression-item'
import optimizationSelectBox from '@/components/organisms/optimization/optimization-select-box'

export default {
  components: {
    buttonMain,
    optimizationExpressionItem,
    optimizationSelectBox
  },
  props: {
    columns: Array,
    value: Array,
    isInference: Boolean,
    validExpressions: Array,
    validColumns: Object
  },
  data() {
    return {
      count: 0,
      expressionList: [],
      opendItem: null
    }
  },
  computed: {
    checkDisabled() {
      return this.expressionList.some((item) => {
        return !item?.expression
      })
    },
    filteredColumns() {
      if (
        !this.validColumns ||
        Object.keys(this.validColumns).length === 0 ||
        !this.columns ||
        this.columns.length === 0
      )
        return []
      return this.columns.filter((column) => {
        if (!this.validColumns[column]?.reasons) return true
        return this.validColumns[column].reasons.some((reason) => {
          return reason !== 'onlynum' && reason !== 'null'
        })
      })
    }
  },
  methods: {
    addExpressionItem() {
      this.count++
      const id = this.getUniqueId()
      this.expressionList.push({
        id: id,
        count: this.count,
        expression: null,
        condition: {
          type: null,
          params: null
        },
        isError: false
      })
      this.$nextTick(() => {
        this.$refs.scrollWrap.lastChild.scrollIntoView({
          behavior: 'smooth'
        })
      })
    },
    // addCountで管理すると[0, 1, 2]から1を削除して、再度追加したときに[0, 2, 2]になりうる。そのため、タイムスタンプでユニークなIDを生成
    getUniqueId() {
      const strong = 1000
      return (
        new Date().getTime().toString(16) +
        Math.floor(strong * Math.random()).toString(16)
      )
    },
    deleteItem(id) {
      this.expressionList = this.expressionList.filter((item) => {
        return item.id !== id
      })
    },
    scroll() {
      if (this.openedItem) {
        const target = 'selectBox' + this.openedItem
        this.$refs[target][0].checkPos()
      }
    },
    openBox(id) {
      this.openedItem = id
    },
    closeBox() {
      this.openedItem = null
    },
    checkExpressions() {
      this.$emit('check-expressions', {
        expressions: this.expressionList,
        columns: this.filteredColumns
      })
    }
  },
  watch: {
    expressionList: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    value(newVal) {
      if (newVal.length > this.count) {
        this.count = 0
        const set = newVal.map((item) => {
          this.count++
          const id = this.getUniqueId()
          return {
            id: id,
            count: this.count,
            expression: item?.expression ?? null,
            condition: {
              type: item?.condition?.type ?? null,
              params: item?.condition?.params ?? null
            },
            isError: false
          }
        })
        this.expressionList = set
        this.$nextTick(() => {
          this.$refs.scrollWrap.lastChild.scrollIntoView({
            behavior: 'smooth'
          })
        })
      }
    },
    validExpressions(newVal) {
      if (!newVal) return
      this.expressionList.forEach((expression, index) => {
        expression.isError = newVal.includes(index)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.list {
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding-right: $space-sub;
  margin-bottom: $space-medium;
  @include scrollbar;
}

.item {
  padding-bottom: $space-medium;
  margin-bottom: $space-medium;
  border-bottom: $border-sub;
  animation: show ease-in-out $transition-base;
  &:last-of-type {
    padding: 0;
    margin: 0;
    border-bottom: inherit;
  }
  &-expression {
    margin-bottom: $space-medium;
  }
  &-conditions {
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: adjustVW(232);
      margin-bottom: $space-sub;
    }
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.button {
  flex-shrink: 0;
}
</style>
