<template>
  <div class="save-comp">
    <images class="save-comp-image" imageName="congratulation" />
    <div class="save-comp-button">
      <button-main
        class="save-comp-button-each"
        :text="
          checkOptimization
            ? $t('training.button.backProject')
            : $t('training.button.goInference')
        "
        type="emphasis"
        @click="clickMain"
      />
      <button-main
        class="save-comp-button-each"
        :text="
          checkOptimization
            ? $t('training.button.useOtherOptimization')
            : $t('training.button.retrain')
        "
        type="sub"
        @click="clickSub"
      />
    </div>
    <router-link
      :to="{
        name: 'projectDetail',
        params: { projectId: $route.params.projectId }
      }"
    >
      <texts
        class="save-comp-link"
        :text="$t('common.trainLeave.popup.leaveButton')"
        size="min"
        color="link-default"
      />
    </router-link>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import images from '@/components/atoms/images'
import buttonMain from '@/components/atoms/button-main'

export default {
  components: {
    texts,
    images,
    buttonMain
  },
  props: {
    checkOptimization: Boolean
  },
  methods: {
    clickMain() {
      if (!this.checkOptimization) {
        this.$emit('move-inference')
      } else {
        this.$emit('move-this-project')
      }
    },
    clickSub() {
      if (!this.checkOptimization) {
        this.$emit('re-train')
      } else {
        this.$emit('move-optimization')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.save-comp {
  display: flex;
  flex-direction: column;
  align-items: center;
  &-image {
    width: adjustVW(560);
    height: auto;
    margin: 0 0 $space-medium;
  }
  &-button {
    width: 100%;
    margin-bottom: $space-small;
    &-each {
      width: calc(50% - #{$space-sub});
    }
  }
}
</style>
