var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inference-timeseries-result"},[_c('div',{staticClass:"inference-timeseries-result-term"},[_c('texts',{staticClass:"inference-timeseries-result-term-item1",attrs:{"text":_vm.defaultType !== 'TIME_TRANSFORMER'
          ? _vm.$t('inference.result.timeseries.term')
          : _vm.$t('inference.result.timeseries.termTransFormer')}}),(_vm.timePrediction)?_c('timeseries-first-last-date',{attrs:{"forecast":_vm.forecast,"observations":_vm.timePrediction.observations,"setDateTime":_vm.defaultTimseSet,"timeUnit":_vm.defaultTimeUnit,"type":_vm.defaultType}}):_vm._e()],1),_c('div',{staticClass:"inference-timeseries-result-wrapper"},[_c('div',{staticClass:"inference-timeseries-result-main"},[_c('card-base',{staticClass:"inference-timeseries-result-main-card"},[_c('div',{staticClass:"inference-timeseries-result-title"},[_c('texts',{attrs:{"size":"large","text":_vm.$t('inference.result.timeseries.title')}}),(
              _vm.checkTimeseriesColumn &&
                _vm.timeseriesSettings &&
                _vm.timeseriesSettings.seasonality_mode
            )?_c('texts',{attrs:{"text":_vm.$t(
                'training.result.timeseries.' +
                  _vm.timeseriesSettings.seasonality_mode
              ),"color":"gray","size":"small"}}):_vm._e()],1),(_vm.inferencedColumns && _vm.inferencedColumns.length > 1)?_c('div',{staticClass:"inference-timeseries-result-multi-select"},[_c('select-column-box',{attrs:{"finishColumns":_vm.finishColumnsFix,"value":_vm.selectedItem},on:{"input":function($event){return _vm.$emit('change-column', $event)}}})],1):_vm._e(),_c('transition-toggle-contents',[(
              _vm.timePrediction &&
                !_vm.loadColumnResult &&
                _vm.checkTimeseriesColumn &&
                !_vm.loadTimeseriesTrainedAiData
            )?_c('timeseries-graph',{staticClass:"inference-timeseries-result-graph",attrs:{"forecast":_vm.timePrediction.forecast,"observations":_vm.timePrediction.observations,"selectedItem":_vm.selectedItem}}):(_vm.loadColumnResult || _vm.loadTimeseriesTrainedAiData)?_c('div',{staticClass:"inference-timeseries-result-multi-loading"},[_c('loading')],1):_c('div',{staticClass:"inference-timeseries-result-multi-no-item"},[_c('texts',{attrs:{"text":_vm.$t('inference.result.timeseries.notFound'),"color":"gray"}})],1)],1)],1)],1),_c('div',{staticClass:"inference-timeseries-result-used-ai"},[_c('card-base',{staticClass:"inference-timeseries-result-used-ai-card"},[_c('model-info',{attrs:{"title":_vm.$t('inference.result.card.usedAi'),"modelInfo":_vm.selectedTrainedAi}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }