<template>
  <div class="distribution">
    <transition-toggle-contents>
      <div v-if="items.length === 0" key="none" class="distribution-none">
        <texts
          class="distribution-none-text"
          :text="$t('clustering.result.distribution.none')"
          size="small"
          color="gray"
        />
      </div>
      <div v-else-if="changeData || loading" key="loading" class="loading">
        <loading />
      </div>
      <div v-else key="content" class="distribution-content">
        <!--
          描画するデータの個数が5000を超えたらWebGLを利用して描画する
          約10000個ほどまでSVGでも描画できそうだが、安全を取って5000にしている
        -->
        <distribution-graph-large
          v-if="data.length > 5000"
          class="distribution-content-inner"
          :data="data"
          :colorScale="classes"
          :xLabel="xLabel.toString()"
          :yLabel="yLabel.toString()"
        />
        <distribution-graph
          v-else
          class="distribution-content-inner"
          :data="data"
          :colorScale="classes"
          :xLabel="xLabel.toString()"
          :yLabel="yLabel.toString()"
        />
      </div>
    </transition-toggle-contents>
  </div>
</template>

<script>
import loading from '@/components/atoms/loading'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import distributionGraph from './distribution-graph.vue'
import distributionGraphLarge from './distribution-graph-large.vue'
export default {
  components: {
    loading,
    transitionToggleContents,
    distributionGraph,
    distributionGraphLarge
  },
  data() {
    return {
      data: [],
      classes: [],
      changeData: true
    }
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    xLabel: {
      type: [String, Number],
      default: ''
    },
    yLabel: {
      type: [String, Number],
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    calcData() {
      const dataLength = this.items.length
      const res = new Array(dataLength)
      const classes = new Set()
      for (let i = 0; i < dataLength; i++) {
        res[i] = {
          xValue: this.items[i][0],
          yValue: this.items[i][1],
          class: this.items[i][2],
          index: i
        }
        classes.add(this.items[i][2])
      }
      const fixValue = res.sort((xValue, yValue) => {
        if (xValue.class == null && yValue.class != null) {
          return -1
        } else if (xValue.class != null && yValue.class == null) {
          return 1
        } else {
          return 0
        }
      })
      this.data = Object.freeze(fixValue)
      this.classes = Array.from(classes).sort((x, y) => {
        return y - x
      })
    }
  },
  mounted() {
    if (this.items.length > 0) {
      this.changeData = true
      this.calcData()
      this.$nextTick(() => {
        this.changeData = false
      })
    }
  },
  watch: {
    items() {
      this.changeData = true
      this.calcData()
      this.$nextTick(() => {
        this.changeData = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.distribution {
  height: 100%;
  &-content {
    width: 100%;
    height: 100%;
    &-inner {
      width: 100%;
      height: 100%;
    }
  }
  &-none {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: $space-sub;
    &-text {
      white-space: pre-line;
    }
  }
}
.loading {
  height: 100%;
}
</style>
