<template>
  <div class="c-text-tags-item">
    {{ tag }}
  </div>
</template>

<script>
export default {
  props: {
    tag: String
  }
}
</script>

<style lang="scss" scoped>
.c-text-tags-item {
  overflow: hidden;
  display: inline-block;
  max-width: calc(50% - #{$space-sub});
  padding: $space-text $space-base;
  background: $background-decoration;
  color: $text-decoration;
  font-size: $text-min;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: adjustVW(2);
  @include text-crop;
}
</style>
