<template>
  <organization
    v-bind="data"
    :accountInfo="accountInfo"
    :accountInfoLoading="accountInfoLoading"
  />
</template>

<script>
import organization from '@/components/templates/organization.vue'
import { mapGetters } from 'vuex'
import setMountedTimer from '@/mixin/set-mounted-timer'

export default {
  components: {
    organization
  },
  mixins: [setMountedTimer],
  computed: {
    ...mapGetters('auth', ['accountInfo', 'accountInfoLoading'])
  },
  data() {
    return {
      data: {
        headerTabs: {
          // ヘッダーのタブ
          tabs: [],
          tabSelect: 1
        }
      }
    }
  }
}
</script>
