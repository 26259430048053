<template>
  <div
    class="result-inner-wrap"
    :class="{
      'result-inner-show-table-large': showTableLarge,
      'result-inner-show-edit': editing
    }"
  >
    <card-base class="result-inner-detail">
      <transition-toggle-contents>
        <div
          v-if="optimizationInfo.loadOptimizationConditionsUpdate"
          key="loading"
        >
          <loading-icon />
        </div>
        <div v-else-if="!editing" key="info" class="result-inner-info">
          <div class="result-inner-info-title">
            <button v-if="!isInference" @click="$emit('close-detail')">
              <text-with-icon
                :text="selectedConditions.name"
                iconName="backward"
              />
            </button>
            <texts v-else :text="selectedConditions.name" />
            <transition name="fade">
              <icon-button
                v-if="!showTableLarge"
                class="result-inner-info-title-icon"
                iconName="edit"
                size="small"
                isButton
                @click="editInfo"
              />
            </transition>
          </div>
          <transition name="fade">
            <div v-if="!showTableLarge">
              <description-long
                class="result-inner-info-desc"
                :title="selectedConditions.name"
                :text="selectedConditions.description"
                :maxCount="64"
                isGray
              />
              <div class="result-inner-info-date">
                <div class="result-inner-info-date-item">
                  <texts
                    class="result-inner-info-date-title"
                    :text="$t('common.updateTime')"
                    size="min"
                    color="gray"
                  />
                  <texts size="small">
                    <a-time :value="selectedConditions.updateTime" />
                  </texts>
                </div>
                <div class="result-inner-info-date-item">
                  <texts
                    class="result-inner-info-date-title"
                    :text="$t('common.createTime')"
                    size="min"
                    color="gray"
                  />
                  <texts size="small">
                    <a-time :value="selectedConditions.createTime" />
                  </texts>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div v-else key="edit" class="result-inner-edit">
          <input-box-validation
            v-model="internalValue.name"
            class="result-inner-edit-name"
            :accept="validate.accept"
            :error="validate.error"
            :nameValidationSuggests="suggest"
            popupView
            isNameValidation
          />
          <input-box
            v-model="internalValue.description"
            class="result-inner-edit-desc"
            isTextArea
            isGray
          />
          <div class="result-inner-edit-button">
            <button-main
              class="result-inner-edit-button-save"
              :text="$t('common.saveButton')"
              type="emphasis"
              size="thin"
              fontSize="small"
              :disabled="validate.error"
              @click="saveEdit"
            />
            <button-main
              class="result-inner-edit-button-cancel"
              :text="$t('common.cancel')"
              type="sub"
              size="thin"
              fontSize="small"
              @click="resetEdit"
            />
          </div>
        </div>
      </transition-toggle-contents>
      <transition name="fade">
        <div v-if="!showTableLarge" class="result-inner-conditions">
          <div class="result-inner-conditions-title">
            <texts
              class="result-inner-conditions-title-inner"
              :text="$t('trainedAi.optimization.conditions.titleResult')"
              size="small"
              color="gray"
            />
            <icon-button
              v-if="showDelete"
              class="result-inner-conditions-title-icon"
              iconName="delete"
              size="small"
              isButton
              @click="$emit('show-delete-optimization')"
            />
          </div>
          <optimization-conditions-list
            class="result-inner-conditions-list"
            :optimizationConditions="selectedConditions"
            :limit="-1"
          />
        </div>
      </transition>
      <button class="result-inner-toggle-button" @click="toggleSize">
        <icons
          class="result-inner-toggle-icon"
          iconName="toggle"
          size="large"
        />
      </button>
    </card-base>
    <card-base class="result-inner-table">
      <transition-toggle-contents>
        <optimization-table
          v-if="!optimizationInfo.loadOptimizationDetail"
          :accountInfo="accountInfo"
          :result="optimizationResult.result"
          :optimizationConditions="selectedConditions"
          :columnRanges="optimizationResult.columnRanges"
          :limit="optimizationResult.limit"
          :total="optimizationResult.total"
          :inPageNumber="optimizationResult.inPageNumber"
          :loadPaging="optimizationInfo.loadOptimizationPaging"
          :filters="optimizationResult.filters"
          :download="optimizationInfo.download"
          @close-optimization-result-tutorial="
            $emit('close-optimization-result-tutorial')
          "
          @change-page="$emit('change-page', $event)"
          @change-filter-value="$emit('change-filter-value', $event)"
          @download-result="$emit('download-result', $event)"
        />
        <loading v-else />
      </transition-toggle-contents>
    </card-base>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import cardBase from '@/components/atoms/card-base'
import descriptionLong from '@/components/atoms/description-long'
import icons from '@/components/atoms/icon'
import iconButton from '@/components/atoms/icon-button'
import loading from '@/components/atoms/loading'
import loadingIcon from '@/components/atoms/loading-icon'
import inputBox from '@/components/molecules/input-box'
import inputBoxValidation from '@/components/molecules/input-box-validation'
import textWithIcon from '@/components/molecules/text-with-icon'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import optimizationTable from '@/components/organisms/optimization/optimization-table'
import optimizationConditionsList from '@/components/organisms/optimization/optimization-conditions-list'

export default {
  components: {
    buttonMain,
    cardBase,
    descriptionLong,
    icons,
    iconButton,
    loading,
    loadingIcon,
    inputBox,
    inputBoxValidation,
    textWithIcon,
    transitionToggleContents,
    optimizationTable,
    optimizationConditionsList
  },
  data() {
    return {
      showTableLarge: false,
      editing: false,
      internalValue: {
        name: '',
        description: ''
      },
      validate: {
        accept: null,
        error: null
      }
    }
  },
  props: {
    accountInfo: Object,
    editValue: Object,
    selectedConditions: Object,
    optimizationFormValidate: Object,
    showDelete: Boolean,
    optimizationResult: Object,
    optimizationInfo: Object,
    isInference: Boolean
  },
  computed: {
    suggest() {
      return this.optimizationInfo?.optimizationFormValidate?.suggests ?? []
    }
  },
  methods: {
    editInfo() {
      this.editing = true
    },
    toggleSize() {
      this.showTableLarge = !this.showTableLarge
      this.resetEdit()
    },
    resetEdit() {
      this.editing = false
      this.internalValue = {
        name: this.selectedConditions.name,
        description: this.selectedConditions.description
      }
    },
    saveEdit() {
      this.editing = false
      if (
        this.selectedConditions.name === this.internalValue.name &&
        this.selectedConditions.description === this.internalValue.description
      )
        return
      this.$emit('save-edit-optimization-form', this.internalValue)
    }
  },
  mounted() {
    this.internalValue.name =
      this.editValue?.name ?? this.selectedConditions.name
    this.internalValue.description =
      this.editValue?.description ?? this.selectedConditions.description
  },
  watch: {
    'optimizationInfo.optimizationFormValidate': {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    },
    internalValue: {
      deep: true,
      handler(e) {
        const res = {
          type: 'optimization',
          form: e
        }
        this.$emit('input-edit-form', res)
      }
    },
    selectedConditions: {
      deep: true,
      handler(newVal) {
        this.internalValue = {
          name: newVal.name,
          description: newVal.description
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.result-inner {
  &-wrap {
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-medium;
  }
  &-detail {
    position: relative;
    display: grid;
    grid-template-columns: adjustVW(400 + 24 * 2) 1fr;
    height: adjustVW(208);
    z-index: $z-optimization-result-base;
  }
  &-table {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    &-detail {
      overflow: hidden;
    }
  }
  &-info {
    padding-right: $space-medium;
    margin-right: $space-medium;
    border-right: $border-sub;
    &-title {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $space-sub;
      &-icon {
        position: absolute;
        right: 0;
      }
    }
    &-desc {
      margin-bottom: $space-small;
      &::v-deep {
        .c-text-description-long-inner {
          white-space: inherit;
        }
      }
    }
    &-date {
      display: flex;
      grid-column-gap: $space-medium;
    }
  }
  &-conditions {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &-title {
      position: relative;
      display: flex;
      flex-shrink: 0;
      justify-content: space-between;
      margin-bottom: $space-sub;
      &-icon {
        position: absolute;
        right: 0;
      }
    }
    &-list {
      overflow-y: auto;
      @include scrollbar;
    }
  }
  &-toggle {
    &-button {
      position: absolute;
      bottom: -($space-small + $space-medium + $space-min);
      left: 50%;
      width: adjustVW(34);
      height: adjustVW(34);
      background-color: #fff;
      border-radius: adjustVW(8);
      box-shadow: $box-shadow-hover;
      transform: translateX(-50%);
    }
    &-icon {
      transform: rotate(180deg);
      transition: transform $transition-base;
    }
  }
  &-show-table-large {
    .result-inner {
      &-detail {
        height: adjustVW(28 + 16 + 16);
      }
      &-toggle-icon {
        transform: rotate(0);
      }
    }
  }
  &-show-edit {
    .result-inner {
      &-detail {
        height: adjustVW(200 + 60);
      }
    }
  }
  &-edit {
    padding-right: $space-large;
    margin-right: $space-large;
    border-right: $border-sub;
    &-name {
      margin-bottom: $space-sub;
    }
    &-desc {
      height: adjustVW(112);
      margin-bottom: $space-small;
    }
    &-button {
      display: flex;
      height: adjustVW(40);
      &-save {
        &.c-button {
          width: adjustVW(148);
        }
      }
      &-cancel {
        &.c-button {
          width: adjustVW(148);
          margin-left: $space-small;
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  overflow: hidden;
  transition: opacity $transition-base;
}

.fade-enter-to,
.fade-leave-active {
  overflow: hidden;
}

.fade-enter,
.fade-leave-to {
  overflow: hidden;
  opacity: 0;
  will-change: opacity;
}
</style>
