<template>
  <div class="dataset-info-columns-list-wrapper">
    <div class="dataset-info-columns-list-search-box">
      <fuse-search
        gray
        :title="$t('selectTargetColumn.datasetInfo.search')"
        :placeholder="$t('selectTargetColumn.datasetInfo.search')"
        :value.sync="listItems"
        @input="
          search($event.result), checkSearchResults($event.noSearchResults)
        "
      />
    </div>

    <div class="dataset-info-columns-list-top">
      <texts
        class="dataset-info-columns-list-main-title"
        color="gray"
        size="small"
        :text="$t('selectTargetColumn.datasetInfo.table')"
      />
      <div>
        <tooltip-popover type="green">
          <template v-slot:main>
            <div class="dataset-info-columns-list-popup-help">
              <text-with-icon
                :text="$t('selectTargetColumn.noAccuracyInfo.title')"
                iconName="info"
                size="small"
                color="green"
              />
            </div>
          </template>
          <template v-slot:popup>
            <div class="dataset-info-columns-list-popup">
              <texts
                class="dataset-info-columns-list-popup-text"
                :text="$t('selectTargetColumn.noAccuracyInfo.info')"
                size="small"
                color="gray"
              />
              <button-main
                v-close-popover
                class="dataset-info-columns-list-popup-button"
                type="green"
                :text="$t('common.goManual')"
                size="thin"
                :fontSize="14"
                isEllipse
                @click="$emit('go-manual-select-target-column')"
              />
            </div>
          </template>
        </tooltip-popover>
      </div>
    </div>
    <target-column-list
      class="dataset-info-columns-list-main-table"
      :values="datasetColumnsList"
      :cautions="cautionItems"
      :cautionMessage="cautionMessage"
      :checked="selectedColumn"
      :checkNotes="checkNotes"
      :noSearchResults="noSearchResults"
      :multiSelectMode="multiSelectMode"
      @check-notes="$emit('check-notes', $event)"
      @select-item="getSelectedColumn"
    />
  </div>
</template>

<script>
import FuseSearch from '@/components/molecules/fuse-search.vue'
import tooltipPopover from '@/components/molecules/tooltip-popover'
import textWithIcon from '@/components/molecules/text-with-icon'
import buttonMain from '@/components/atoms/button-main'
import targetColumnList from '@/components/organisms/select-target-column/target-column-list.vue'
import Texts from '@/components/atoms/text.vue'

export default {
  components: {
    targetColumnList,
    Texts,
    FuseSearch,
    tooltipPopover,
    textWithIcon,
    buttonMain
  },
  data() {
    return {
      result: null,
      noSearchResults: false
    }
  },
  props: {
    listItems: Array,
    cautionItems: Array,
    cautionMessage: Array,
    checkNotes: Object,
    selectedColumn: Array,
    multiSelectMode: Boolean
  },
  computed: {
    datasetColumnsList() {
      if (!this.result) {
        return this.listItems
      } else {
        return this.result
      }
    }
  },
  methods: {
    search(result) {
      this.result = result
    },
    checkSearchResults(bool) {
      this.noSearchResults = bool
    },
    getSelectedColumn: function (val) {
      this.$emit('get-selected-column', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.dataset-info-columns-list-wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dataset-info-columns-list-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $space-base;
}

.dataset-info-columns-list-search-box {
  flex-shrink: 0;
  width: adjustVW(380);
  height: auto;
  margin-bottom: $space-medium;
}

.dataset-info-columns-list-main-title {
  flex-shrink: 0;
}

.dataset-info-columns-list-popup {
  &-text {
    max-width: adjustVW(480);
    margin-bottom: $space-sub;
    white-space: pre-line;
  }
  &-button {
    height: adjustVW(32);
  }
  &-help {
    cursor: help;
  }
}
</style>
