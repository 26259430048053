<template>
  <div class="clustering-distribution-wrap">
    <card-base class="clustering-distribution-head">
      <clustering-class-select
        :clusteringSetting="clusteringSetting"
        :selectItems="selectItems"
        :isInference="isInference"
        @select-class="
          inputClusteringSetting($event.selectItem.value, 'changeClass')
        "
      />
      <div
        v-if="distributionItems.hasDimensionValue"
        class="clustering-distribution-toggle"
      >
        <toggle-button
          v-tooltip="$t('clustering.result.distribution.showDimension.tips')"
          :checked="clusteringSetting.setting.showDimension"
          :text="$t('clustering.result.distribution.showDimension.button')"
          @input="$emit('toggle-clustering-show-dimension', $event)"
        />
      </div>
    </card-base>
    <div class="clustering-distribution-list">
      <template v-for="(value, index) in clusteringSetting.distributionSetting">
        <transition-toggle-contents :key="index">
          <card-base v-if="checkDisplay(index)">
            <clusterflow-distribution-wrap
              :setting="value"
              :classes="clusteringResult.classes"
              :items="clusteringDistributions[index]"
              :clusteringInfo="clusteringInfo"
              :loading="clusteringSetting.loadingDistribution"
              :selectItems="distributionItems.items"
              :showDimension="clusteringSetting.setting.showDimension"
              :hasDimensionValue="distributionItems.hasDimensionValue"
              @change-clustering-distribution-column="
                changeClusteringDistributionColumn($event, index)
              "
            />
          </card-base>
        </transition-toggle-contents>
      </template>
    </div>
  </div>
</template>

<script>
import cardBase from '@/components/atoms/card-base.vue'
import toggleButton from '@/components/atoms/toggle-button.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import clusterflowDistributionWrap from '@/components/organisms/clusterflow/clusterflow-distribution-wrap.vue'
import ClusteringClassSelect from './cards/clustering-class-select.vue'

export default {
  components: {
    cardBase,
    toggleButton,
    ClusteringClassSelect,
    transitionToggleContents,
    clusterflowDistributionWrap
  },
  props: {
    clusteringInfo: Object,
    clusteringSetting: Object,
    clusteringResult: Object,
    clusteringDistributions: Array,
    distributionItems: Object,
    isInference: Boolean
  },
  computed: {
    selectItems() {
      return this.clusteringInfo.resultList.map((classItem) => {
        const name =
          this.clusteringInfo?.type === 'dbscan'
            ? this.$t('clustering.result.classNameFix', {
                id: classItem.id,
                nClusters: classItem.nClusters
              })
            : classItem.id
        return {
          name: name,
          value: classItem.id
        }
      })
    }
  },
  methods: {
    inputClusteringSetting(value, type) {
      this.$emit('input-clustering-setting', {
        value,
        type
      })
    },
    changeClusteringDistributionColumn(event, index) {
      this.$emit('change-clustering-distribution-column', {
        ...event,
        index
      })
    },
    checkDisplay(index) {
      if (
        this.distributionItems?.items != null &&
        this.distributionItems.items.length > 1
      )
        return true
      return index === 0
    }
  }
}
</script>

<style lang="scss" scoped>
.clustering-distribution {
  &-wrap {
    overflow: hidden;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    grid-row-gap: $space-small;
    width: 100%;
    height: 100%;
    padding: 0 $space-medium $space-medium;
  }
  &-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: $space-medium;
  }
  &-head {
    display: flex;
    align-items: center;
    grid-column-gap: $space-medium;
    width: fit-content;
  }
  &-title {
    margin-bottom: $space-base;
  }
  &-input {
    height: adjustVW(48);
  }
  &-select {
    max-width: adjustVW(280);
  }
  &-toggle {
    margin-right: $space-small;
  }
}
</style>
