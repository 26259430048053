<template>
  <div class="include-input-text">
    <texts
      v-if="firstText"
      :text="firstText"
      size="small"
      class="include-input-text-text"
    />
    <input-box
      v-model="internalValue"
      isGray
      :type="typeCheck"
      :number="typeNumberCheck"
      :isTextArea="typeTextAreaCheck"
      :max="max"
      :min="min"
      :step="step"
      class="include-input-text-input"
    />
    <texts
      v-if="secondText"
      :text="secondText"
      size="small"
      class="include-input-text-text"
    />
  </div>
</template>

<script>
import inputBox from '@/components/molecules/input-box'

export default {
  components: {
    inputBox
  },
  props: {
    firstText: {
      type: String,
      default: ''
    },
    secondText: {
      type: String,
      default: ''
    },
    value: {
      type: [Number, String],
      default: ''
    },
    number: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    step: {
      type: Number,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    typeCheck() {
      if (this.typeNumberCheck || this.typeTextAreaCheck || this.type == null)
        return null
      return this.type
    },
    // inputのnumber・textareaのみ個別のBooleanで扱っているので判定
    typeNumberCheck() {
      return this.type === 'number'
    },
    typeTextAreaCheck() {
      return this.type === 'textarea'
    }
  }
}
</script>

<style lang="scss" scoped>
.include-input-text {
  display: flex;
  align-items: center;
  grid-column-gap: $space-base;
  &-text {
    flex-shrink: 0;
    width: fit-content;
  }
}
</style>
