<template>
  <div class="contact-wrapper" :class="checkClass">
    <div class="contact-block">
      <texts
        v-if="isSmall"
        class="contact-title-small"
        :text="$t('trainedAiDetails.metrics.askAccuracyRateLong')"
        color="green"
        size="small"
      />
      <text-with-icon
        v-else
        class="contact-title"
        iconName="accuracy"
        :text="$t('trainedAiDetails.metrics.askAccuracyRateLong')"
        color="green"
      />
      <texts
        class="contact-desc"
        :text="$t('trainedAiDetails.metrics.askAccuracyRateDescription')"
        :size="14.5"
        color="gray"
      />
      <div class="contact-button">
        <!--
          TODO 精度の上げ方を記載したブログ記事の作成
          https://trello.com/c/NDythXit
          <button-main
            class="contact-button-item"
            :text="$t('trainedAiDetails.metrics.learnMetrics')"
            type="green"
            :fontSize="14.5"
            size="thin"
            isEllipse
          />
          -->
        <button-main
          class="contact-button-item"
          :text="$t('common.contact.consultant')"
          type="green"
          :fontSize="14.5"
          size="thin"
          isEllipse
          @click="goContact"
        />
      </div>
    </div>

    <div class="contact-img">
      <images imageName="askSupport" />
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main.vue'
import images from '@/components/atoms/images'
import textWithIcon from '@/components/molecules/text-with-icon'
import texts from '@/components/atoms/text'

export default {
  components: {
    buttonMain,
    images,
    texts,
    textWithIcon
  },
  props: {
    isSmallMargin: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goContact() {
      window.open(this.$urls.contactLink, '_blank')
    }
  },
  computed: {
    checkClass() {
      let classType = 'default'
      if (this.isSmall) {
        classType = 'small'
      } else if (this.isSmallMargin) {
        classType = 'small-margin'
      }
      return `contact-wrapper-${classType}`
    }
  }
}
</script>

<style lang="scss" scoped>
.contact {
  &-wrapper {
    padding: $space-small;
    border-radius: adjustVW(8);
    &-default {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      grid-column-gap: $space-base;
      border: $border-green;
      background: $background;
      box-shadow: $box-shadow-main;
    }
    &-small-margin {
      @extend .contact-wrapper-default;
      // margin-top: -$space-medium; TODO いらなかったら消す
    }
    &-small {
      position: relative;
      max-width: adjustVW(400);
      background: $background-sub;
    }
  }
  &-block {
    position: relative;
    width: 100%;
    z-index: 1;
  }

  &-title {
    margin-bottom: $space-base;
    &-small {
      margin-bottom: $space-text;
      white-space: pre-line;
    }
  }

  &-desc {
    margin-bottom: $space-sub;
    line-height: $line-height-base;
    white-space: pre-line;
  }

  &-button {
    &-item {
      height: adjustVW(32);
      + .contact-button-item {
        margin-left: $space-small;
      }
    }
  }

  &-img {
    width: adjustVW(168);
    .contact-wrapper-small & {
      position: absolute;
      right: $space-small;
      bottom: $space-base;
      opacity: 0.4;
      z-index: 0;
    }
  }
}

.margin-left {
  margin: 0 0 0 $space-medium;
}
</style>
