import { duplicateChecker, duplicateSuggests } from './misc'

/**
 * @param { Object[] } datasourceList
 * @param { Object } newDataset
 * @returns { Object }
 **/
export function datasourceValidator(datasources, newDatasource) {
  const datasourceKeys = Object.keys(datasources)

  const nameList =
    datasourceKeys.length > 0
      ? datasourceKeys.map((id) => datasources[id].name)
      : []
  const newDatasourceName = newDatasource.name

  const checkResult = duplicateChecker(nameList, newDatasourceName)
  let suggests = []
  if (!checkResult) {
    suggests = duplicateSuggests(nameList, newDatasourceName)
  }

  return {
    duplicate: checkResult,
    suggests: suggests
  }
}
