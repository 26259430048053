<template>
  <div class="wrap">
    <texts
      class="item"
      :text="text"
      :size="size"
      color="gray"
    />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      default: 'min',
      type: String
    },
    text: {
      default: '',
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: $space-sub $space-small;
  background-color: $background-sub;
  border-radius: adjustVW(8);
  box-shadow: 0 0 0 adjustVW(2) $white inset;
}
.item {
  overflow-y: scroll;
  width: 100%;
  max-height: adjustVW(112);
  padding-right: $space-sub;
  white-space: pre-line;
  @include scrollbar;
}
</style>
