<template>
  <div class="top">
    <div class="top-title">
      <texts
        class="top-title-sub"
        :text="$t('training.result.best.trainingResult')"
        color="gray"
      />
      <div class="top-title-main">
        <div v-if="!editting" class="top-title-main-block">
          <texts
            class="top-title-main-name"
            size="sub-title"
            :text="value.name"
          />
          <top-edit-icon v-if="!isSaved" @click-edit="toggleEdit()" />
        </div>
        <top-edit-box
          v-else
          v-model="value.name"
          class="top-title-main-input"
          size="sub-title"
          term="name"
          :accept="validate.accept"
          :error="validate.error"
          :nameValidationSuggests="trainedAIFormValidate.suggests"
          @finish-edit="toggleEdit()"
          @input-edit-box="inputCreateForm"
        />
        <div
          v-if="recipeType !== 'TIME' && sortedFlag"
          class="top-title-main-badge"
        >
          <icons class="top-title-main-icon" color="gray" iconName="trainTop" />
          <texts
            class="top-title-main-desc"
            color="gray"
            size="small"
            :text="$t('training.result.best.badge')"
          />
        </div>
      </div>
    </div>
    <div class="top-button-list">
      <div class="top-button-button">
        <button-main
          class="top-button-item"
          type="emphasis"
          :isDisabled="disabledSave"
          :text="$t('training.button.compTraining')"
          @click="$emit('save-trained-ai')"
        />
        <button-main
          v-if="trainedAiCount > 1"
          class="top-button-item"
          type="sub"
          :text="$t('training.button.checkOtherTrainedAi')"
          @click="$emit('show-trained-ai-list')"
        />
      </div>
      <transition-toggle-contents>
        <text-box
          v-if="isSaved"
          size="small"
          :text="$t('training.result.savedText')"
          isAccept
        />
        <div
          v-else-if="
            trainedAi.trainConfig.predictionColumn &&
              trainedAi.trainConfig.predictionColumn.length > 1 &&
              !checkOptimization
          "
          class="top-multi"
        >
          <circle-progress-icon
            class="top-multi-icon"
            :progress="finishColumns.length"
            :progressMax="trainedAi.trainConfig.predictionColumn.length"
          />
          <texts class="top-multi-text" :text="finishStatus" />
        </div>
      </transition-toggle-contents>
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import texts from '@/components/atoms/text.vue'
import icons from '@/components/atoms/icon.vue'
import TopEditIcon from '@/components/atoms/top-edit-icon.vue'
import TopEditBox from '@/components/atoms/top-edit-box.vue'
import TextBox from '@/components/atoms/text-box.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import circleProgressIcon from '@/components/molecules/circle-progress-icon.vue'

export default {
  components: {
    buttonMain,
    texts,
    icons,
    TopEditIcon,
    TopEditBox,
    TextBox,
    transitionToggleContents,
    circleProgressIcon
  },
  data() {
    return {
      editting: false,
      form: {
        name: '',
        id: this.value.id,
        revision: false
      },
      validate: {
        accept: null,
        error: null
      }
    }
  },
  props: {
    isSaved: Boolean,
    recipeType: String,
    sortedFlag: Boolean,
    trainedAi: Object,
    trainedAiCount: Number,
    trainedAIFormValidate: Object,
    value: Object,
    finishColumns: Array,
    trainingProgressStatus: String,
    checkTrainingFinish: Boolean
  },
  computed: {
    disabledSave() {
      if (this.isSaved) return true
      return (
        !this.checkTrainingFinish ||
        (this.trainedAi.trainConfig.predictionColumn.length !==
          this.finishColumns.length &&
          // MfTransformerの場合、finishColumnsに列が入ってこない
          !this.$recipeType.timeTransformer.includes(
            this.trainedAi.summary.type
          ))
      )
    },
    finishStatus() {
      if (!this.checkProgress) return null
      const progress = this.finishColumns.length
      const progressMax = this.trainedAi.trainConfig.predictionColumn.length
      if (progressMax > progress) {
        return this.$t('training.result.multiResult.progress', {
          progress: progress,
          progressMax: progressMax
        })
      } else {
        return this.$t('training.result.multiResult.comp')
      }
    },
    checkProgress() {
      const progress = this.finishColumns.length
      const predictionColumn = this.trainedAi.trainConfig?.predictionColumn
      return progress > 0 && predictionColumn && predictionColumn.length > 0
    },
    checkOptimization() {
      if (!this.trainedAi) return false
      return this.trainedAi?.summary?.is_optimization
    }
  },
  watch: {
    editting: {
      handler: function (status) {
        if (status) {
          this.setValidateStatus(this.trainedAIFormValidate)
        }
      }
    },
    trainedAIFormValidate: {
      deep: true,
      handler: function (status) {
        this.setValidateStatus(status)

        if (!this.form.revision) {
          this.form.revision = true
        }
      }
    },
    'value.revision': {
      handler: function (status) {
        if (!status) this.form.revision = true
      }
    }
  },
  methods: {
    toggleEdit() {
      this.editting = !this.editting
    },
    inputCreateForm: function (box) {
      this.form = Object.assign(this.form, box)
      this.$emit('input-create-form', {
        type: 'trainedAI',
        form: this.form
      })
    },
    setValidateStatus: function (status) {
      const { duplicate } = status

      this.validate.accept = duplicate !== undefined ? duplicate : null
      this.validate.error = duplicate !== undefined ? !duplicate : null
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  padding: $space-medium $space-medium 0;
  &-title {
    margin: 0 0 $space-small;
    &-main {
      display: flex;
      align-items: center;
      width: 100%;
      &-name {
        overflow: hidden;
        margin: 0 $space-base 0 0;
      }
      &-badge {
        display: flex;
        align-items: center;
      }
      &-icon {
        margin: 0 $space-text 0 0;
      }
      &-block {
        display: flex;
        align-items: flex-end;
        max-width: adjustVW(960);
        margin: 0 $space-medium 0 0;
      }
      &-input {
        margin-right: $space-medium;
      }
    }
    &-sub {
      margin: 0 0 $space-text;
    }
    &-edit {
      flex-shrink: 0;
    }
  }
  &-button {
    &-button {
      margin: 0 $space-medium 0 0;
    }
    &-list {
      display: flex;
      align-items: center;
      margin: $space-medium 0 0;
    }
  }
  &-multi {
    display: flex;
    align-items: center;
    grid-column-gap: $space-sub;
    height: adjustVW(32);
    margin-left: $space-base; // ボタン側に24のマージンが入っているので+8
    &-icon {
      width: fit-content;
    }
    &-text {
      flex-shrink: 0;
    }
  }
}
</style>
