<template>
  <div class="recipe-block">
    <div class="recipe-block-icon">
      <recipe-icon :block="block" :isCustomblock="isCustomblock" allEmphasis />
    </div>
    <div class="recipe-block-text">
      <texts :text="blockname" />
    </div>
  </div>
</template>

<script>
import recipeIcon from './recipe-icon'
import texts from './text'

export default {
  components: {
    recipeIcon,
    texts
  },
  props: {
    block: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    blockname() {
      const name = this.block.name
      if (this.block.displayName) {
        return this.block.displayName
      } else if (this.isCustomblock) {
        return this.$t('customblock.error.unknownBlock')
      }
      return this.$t('recipe.layerNames.' + name)
    },
    nameConvert() {
      return this.block.name.replace(/([A-Z])/g, function (s) {
        return s.charAt(0).toUpperCase()
      })
    },
    isCustomblock() {
      return this.block?.customblock_id != null
    }
  }
}
</script>

<style lang="scss" scoped>
.recipe-block {
  position: relative;
  display: flex;
  align-items: center;
  height: adjustVW(48);
  margin: 0 $space-medium $space-small 0;
  border: $border-emphasis;
  border-radius: adjustVW(8);
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: adjustVW(-26);
    width: adjustVW(26);
    height: 1px;
    background: $line-emphasis;
    transform: translateY(-50%);
  }
  &:last-child {
    margin: 0 0 $space-small;
    &::after {
      content: inherit;
    }
  }
  &-text {
    padding: 0 $space-small 0 0;
  }
  &-icon {
    padding: $space-sub;
    margin: 0 $space-small 0 0;
    border-right: $border-emphasis;
    background: $key-sub;
    border-radius: adjustVW(8) 0 0 adjustVW(8);
  }
}
</style>
