<template>
  <div class="choosed-items-wrapper">
    <div
      class="choosed-items-blocks1"
      :class="{
        'choosed-items-blocks1-multi': predictColumnLength > 1
      }"
    >
      <!-- TrendFlow以外の場合に、必要な列を表示 -->
      <choosed-dataset
        v-if="selectedInferenceType !== 'time'"
        class="choosed-items-blocks1-block"
        :choosedDatasetInfo="choosedDatasetInfo"
        :preprocessingColumns="preprocessingColumns"
        :requiredColumns="requiredColumns"
        :lackedColumns="lackedColumns"
        :lackedPreprocessingColumns="lackedPreprocessingColumns"
      />
    </div>

    <div v-if="choosedPreprocessInfo" class="choosed-items-blocks2">
      <choosed-preprocess :choosedPreprocessInfo="choosedPreprocessInfo" />
    </div>
  </div>
</template>

<script>
import choosedDataset from '@/components/organisms/inference/menu/infereceType/choosed-dataset.vue'
import choosedPreprocess from '@/components/organisms/inference/menu/infereceType/choosed-preprocess.vue'

export default {
  components: {
    choosedDataset,
    choosedPreprocess
  },
  props: {
    trainedAi: Object,
    choosedDatasetInfo: Object,
    choosedPreprocessInfo: Object,
    preprocessingColumns: Object,
    requiredColumns: Array,
    lackedColumns: Array,
    lackedPreprocessingColumns: Array,
    selectedInferenceType: String
  },
  computed: {
    // MFTransformerの場合、推論に必要な列の表示が必要なため、TrendFlowの場合のみ判定
    checkShowTrainedAi() {
      return (
        this.selectedInferenceType !== 'time' &&
        this.trainedAi.predictColumn.length > 1
      )
    },
    predictColumnLength() {
      if (!this.trainedAi || !this.trainedAi?.predictColumn) return 0
      return this.trainedAi.predictColumn.length
    }
  }
}
</script>

<style lang="scss" scoped>
.choosed-items {
  &-wrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: minmax(0, max-content) auto;
    height: 100%;
  }
}

.choosed-items-blocks1 {
  display: flex;
  flex-shrink: 1;
  width: 100%;
  height: fit-content;
  max-height: 100%;

  &-block {
    overflow-y: auto;
    width: 100%;
    height: auto;
    margin: 0 $space-medium 0 0;
    @include scrollbar;
    &:last-of-type {
      margin: 0;
    }
  }
  &-multi {
    height: auto;
    max-height: 100%; // 時系列で前処理を使用しない前提なので、100% 前処理を使うようになったら修正
    margin-bottom: 0;
    &-block {
      width: calc(50% - #{$space-sub});
      margin: 0 $space-medium 0 0;
    }
    &-inner {
      height: 100%;
    }
    .choosed-items-blocks1-block {
      max-height: 100%;
    }
    &-card {
      height: 100%;
    }
  }
}

.choosed-items-blocks2 {
  flex-shrink: 0;
  width: 100%;
  height: adjustVH(240);
  margin-top: $space-medium;
}
</style>
