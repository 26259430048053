<template>
  <list-item-slot
    class="optimization-list"
    :field="field"
    :items="fixOptimizations"
    type="button"
    @list-click="listClick"
  >
    <template #summary="entry">
      <div class="optimization-summary">
        <texts
          class="optimization-summary-name"
          :text="entry.item.name"
          isBold
          :class="{
            'optimization-summary-name-non-desc': !entry.item.description
          }"
        />
        <description
          class="optimization-summary-description"
          :text="entry.item.description"
          :line="2"
        />
      </div>
    </template>
    <template #createTime="entry">
      <texts size="small">
        <a-time :value="entry.item" />
      </texts>
    </template>
    <template #conditions="entry">
      <optimization-conditions-list
        :optimizationConditions="entry.entry.latestOptimizationConditions"
        :limit="3"
      />
    </template>
  </list-item-slot>
</template>

<script>
import description from '@/components/atoms/description.vue'
import listItemSlot from '@/components/molecules/list-item-slot.vue'
import optimizationConditionsList from '@/components/organisms/optimization/optimization-conditions-list'

export default {
  components: {
    description,
    listItemSlot,
    optimizationConditionsList
  },
  props: {
    field: Array,
    items: Array
  },
  methods: {
    listClick({ id }) {
      this.$emit('list-click', { id })
    }
  },
  computed: {
    fixOptimizations() {
      if (!this.items || this.items.length === 0) return []
      const target = this.items.filter((item) => {
        return item?.is_optimization
      })
      /**
       * データセットのみを判定しているが、レシピを削除した場合のことも記載している
       * これはレシピが削除された場合も、最適化に失敗するため。
       * そして学習済みAIにレシピのIDを紐づける実装も行われていないため。
       * データセットを削除するユーザーの場合、レシピを削除する可能性もあるため、合わせて記載している。
       */
      return target.map((item) => {
        return {
          ...item,
          disabled: !(item?.datasets && item.datasets.length > 0),
          disabledTips: this.$t('trainedAi.optimization.error.noDataOrRecipe')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.optimization {
  &-list {
    height: 100%;
  }
  &-name {
    margin-bottom: $space-base;
    &-non-desc {
      margin: 0;
    }
  }

  &-description {
    white-space: normal;
  }
}
</style>
