<template>
  <card-base class="regression-features-graph-card">
    <transition-toggle-contents>
      <loading
        v-if="loading"
        key="distribution"
        class="regression-features-graph-loading"
      />
      <div
        v-else-if="selectedColumnIndex === -1"
        key="none-distribution-info"
        class="regression-features-graph-none"
      >
        <texts
          class="regression-features-graph-none-text"
          :text="$t('trainedAi.multi.notFound')"
          color="gray"
        />
      </div>
      <feature-importance-bar-graph
        v-else
        :features="features"
        :maxCount="maxCount"
      />
    </transition-toggle-contents>
  </card-base>
</template>

<script>
import loading from '@/components/atoms/loading.vue'
import cardBase from '@/components/atoms/card-base.vue'
import featureImportanceBarGraph from '@/components/molecules/feature-importance-bar-graph.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    loading,
    cardBase,
    featureImportanceBarGraph,
    transitionToggleContents
  },
  props: {
    features: Object,
    maxCount: Number,
    loading: Boolean,
    selectedColumnIndex: Number
  }
}
</script>

<style lang="scss" scoped>
.regression-features-graph {
  &-none {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
</style>
