<template>
  <div class="slider-wrap">
    <div ref="targetList" class="slider-inner" @scroll="scroll($event)">
      <transition-toggle-contents>
        <button
          v-if="showLeftButton"
          class="slider-scroll-button slider-scroll-button-left"
          @click="leftScroll"
        >
          <icons iconName="backward" size="large" color="emphasis" />
        </button>
      </transition-toggle-contents>
      <slot />
      <transition-toggle-contents>
        <button
          v-if="showRightButton"
          class="slider-scroll-button slider-scroll-button-right"
          @click="rightScroll"
        >
          <icons iconName="forward" size="large" color="emphasis" />
        </button>
      </transition-toggle-contents>
    </div>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    icons,
    transitionToggleContents
  },
  data() {
    return {
      scrollX: 0,
      scrollLeft: 0,
      scrollWidth: 0,
      offsetWidth: 0
    }
  },
  computed: {
    showLeftButton() {
      return this.scrollX !== 0
    },
    showRightButton() {
      return (
        this.scrollWidth > this.offsetWidth &&
        Math.round(this.scrollX) + this.offsetWidth !== this.scrollWidth
      )
    }
  },
  methods: {
    leftScroll() {
      this.scrollLeft = this.scrollLeft - 480
      this.$refs.targetList.scrollLeft = this.scrollLeft
    },
    rightScroll() {
      this.scrollLeft = this.scrollLeft + 480
      this.$refs.targetList.scrollLeft = this.scrollLeft
    },
    scroll(e) {
      this.scrollX = e.target.scrollLeft
    },
    getWidth() {
      this.scrollWidth = this.$refs.targetList.scrollWidth
      this.offsetWidth = this.$refs.targetList.offsetWidth
      this.scrollX = this.$refs.targetList.scrollLeft
    }
  },
  mounted() {
    this.getWidth()
    window.addEventListener(
      'resize',
      function () {
        this.getWidth()
      }.bind(this)
    )
  }
}
</script>

<style lang="scss" scoped>
.slider {
  &-wrap {
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  &-inner {
    overflow-x: scroll;
    width: auto;
    padding-bottom: $space-base;
    scroll-behavior: smooth;
    @include scrollbar;
    &::-webkit-scrollbar:horizontal {
      height: adjustVW(4);
    }
    &:hover {
      .slider-scroll-button {
        opacity: 1;
      }
    }
  }
  &-scroll-button {
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: adjustVW(48);
    height: adjustVW(48);
    background: $key-lite;
    border-radius: 9in;
    box-shadow: $box-shadow-hover;
    opacity: 0;
    transform: translateY(-50%);
    transition: opacity $transition-base;
    &:hover {
      opacity: 0.7;
    }
    &-right {
      right: 0;
    }
    &-left {
      left: 0;
    }
  }
}
</style>
