<template>
  <div
    ref="scrollTarget"
    v-resize="setSizeHide"
    v-resize:debounce="getSize"
    class="table"
    :class="'table-' + type"
  >
    <table class="table-inner">
      <thead>
        <tr class="table-head">
          <th
            v-for="(columnName, index) in columns"
            :key="index"
            class="table-head-cell"
          >
            <div class="table-cell">
              <texts
                class="table-head-text"
                :text="columnName"
                size="small"
                :color="type === 'optimization' ? 'green' : 'gray'"
              />
              <tooltip-popover
                trigger="click"
                placement="top"
                popoverInnerClass="popover-no-border"
              >
                <template #main>
                  <button
                    class="table-head-adjust"
                    :class="{
                      'table-head-adjust-active': checkFilterActive(columnName)
                    }"
                    @click="$emit('click-adjust')"
                  >
                    <icons
                      class="table-head-adjust-icon"
                      iconName="adjust"
                      size="small"
                      :color="checkFilterActive(columnName) ? 'green' : 'gray'"
                    />
                  </button>
                </template>
                <template #popup>
                  <div class="popup">
                    <div class="popup-title">
                      <texts
                        class="popup-title-text"
                        :text="$t('trainedAi.optimization.table.tips.range')"
                        size="small"
                        color="gray"
                      />
                    </div>
                    <div class="popup-adjust">
                      <adjust-slider
                        v-if="fixMinMax[columnName]"
                        :ref="'adjust' + columnName + index"
                        :value="[
                          fixMinMax[columnName].min,
                          fixMinMax[columnName].max
                        ]"
                        :min="fixMinMax[columnName].min"
                        :max="fixMinMax[columnName].max"
                        @change-all="changeFilterValue(columnName, $event)"
                      />
                    </div>
                    <div class="popup-bottom">
                      <optimization-conditions-item
                        class="popup-conditions"
                        :conditionsItem="findConditionsItem(columnName)"
                        noColumn
                        showNoItem
                      />
                      <button
                        class="popup-reset"
                        @click="
                          resetAdjust(fixMinMax[columnName], columnName, index)
                        "
                      >
                        <texts
                          class="popup-reset-text"
                          :text="$t('trainedAi.optimization.table.tips.reset')"
                          size="min"
                          color="gray"
                        />
                      </button>
                    </div>
                    <transition-toggle-fade>
                      <div v-if="loadPaging" class="popup-disabled" @click.stop>
                        <div class="popup-disabled-inner" />
                      </div>
                    </transition-toggle-fade>
                  </div>
                </template>
              </tooltip-popover>
            </div>
          </th>
        </tr>
      </thead>
      <tbody ref="scrollInner">
        <tr v-for="(dataList, index) in table" :key="index" class="table-body">
          <td
            v-for="(columnName, columnIndex) in columns"
            :key="columnIndex"
            class="table-body-cell"
          >
            <transition-toggle-fade>
              <div v-show="!loadPaging" class="table-cell">
                <texts :text="dataList[columnName]" />
              </div>
            </transition-toggle-fade>
          </td>
          <div v-if="type === 'optimization'" class="table-button">
            <transition-toggle-fade>
              <icon-button
                v-if="!loadPaging"
                iconName="search"
                size="small"
                :isActive="showDetailIndex === index"
                :data-tutorial-optimization-result="index === 0 ? 3 : null"
                @click="$emit('click-list', index)"
              />
            </transition-toggle-fade>
          </div>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import iconButton from '@/components/atoms/icon-button'
import adjustSlider from '@/components/molecules/adjust-slider'
import tooltipPopover from '@/components/molecules/tooltip-popover'
import transitionToggleFade from '@/components/molecules/transition-toggle-fade'
import optimizationConditionsItem from '@/components/organisms/optimization/optimization-conditions-item'

import resize from 'vue-resize-directive'

export default {
  directives: {
    resize
  },
  components: {
    icons,
    iconButton,
    adjustSlider,
    tooltipPopover,
    transitionToggleFade,
    optimizationConditionsItem
  },
  props: {
    table: Array,
    columns: Array,
    filters: Array,
    columnRanges: Array,
    type: String,
    scrollTop: Number,
    optimizationConditions: Object,
    showDetailIndex: Number,
    loadPaging: Boolean
  },
  methods: {
    checkFilterActive(columnName) {
      const filterItem = this.filters.find((item) => {
        return item.name === columnName
      })
      const limitItem = this.columnRanges.find((item) => {
        return item.name === columnName
      })
      if (!filterItem || !limitItem) return false
      return (
        filterItem.min !== limitItem.min || filterItem.max !== limitItem.max
      )
    },
    resetAdjust(limitItem, columnName, index) {
      const item = [limitItem.min, limitItem.max]
      this.$refs['adjust' + columnName + index][0].changeValue(item)
      this.changeFilterValue(columnName, item)
    },
    findConditionsItem(columnName) {
      if (!this.optimizationConditions?.conditions?.columnConditions) return {}
      return this.optimizationConditions.conditions.columnConditions.find(
        (item) => {
          return item?.column === columnName
        }
      )
    },
    getSize() {
      const wrapHeight = this.$refs.scrollTarget.getBoundingClientRect().height
      const innerHeight = this.$refs.scrollInner.getBoundingClientRect().height
      this.$emit('get-target-height', {
        wrapHeight: wrapHeight,
        innerHeight: innerHeight
      })
    },
    setSizeHide() {
      this.$emit('set-target-hide')
    },
    changeFilterValue(columnName, value) {
      const payload = {
        id: this.optimizationConditions.id,
        filter: {
          name: columnName,
          min: value[0],
          max: value[1]
        }
      }
      this.$emit('change-filter-value', payload)
    }
  },
  computed: {
    fixMinMax() {
      if (!this.columnRanges || this.columnRanges.length === 0) return []
      const res = []
      this.columnRanges.forEach((item) => {
        res[item.name] = {
          min: item.min,
          max: item.max
        }
      })
      return res
    }
  },
  mounted() {
    if (this.type === 'optimization') {
      this.getSize()
    }
    this.$refs.scrollTarget.scrollTop = this.scrollTop
  },
  watch: {
    scrollTop(newVal) {
      this.$refs.scrollTarget.scrollTop = newVal
    },
    table() {
      if (this.type === 'optimization') {
        this.$nextTick(() => {
          this.getSize()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  overflow-y: hidden;
  height: 100%;
  @include scrollbar;
  &-inner {
    min-width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: collapse;
  }
  &-head {
    position: sticky;
    top: 0;
    height: adjustVW(48);
    background-color: #fff;
    z-index: $z-optimization-result-base - 1;
    &-adjust {
      position: relative;
      flex-shrink: 0;
      &-icon {
        position: relative;
        z-index: 1;
      }
      &::after {
        content: '';
        position: absolute;
        top: adjustVW(-6);
        left: adjustVW(-6);
        width: adjustVW(32);
        height: adjustVW(32);
        background-color: $green-lite;
        border-radius: 9in;
        opacity: 0;
        transition: opacity $transition-base;
      }
      &-active {
        &::after {
          opacity: 1;
        }
      }
    }
    &-cell {
      position: relative;
      min-width: adjustVW(144);
      padding: adjustVW(2) $space-large 0 0;
      &::after {
        content: '';
        position: absolute;
        bottom: adjustVW(-2);
        left: 0;
        width: 100%;
        height: adjustVW(2);
        background-color: $green-lite;
        z-index: 1;
      }
      &:last-of-type {
        padding-right: 0;
      }
    }
    &-text {
      margin-top: adjustVW(-4);
    }
  }
  &-body {
    position: relative;
    height: adjustVW(48);
    border-bottom: $border-sub;
    &:last-of-type {
      border-bottom: none;
    }
    &-cell {
      padding: adjustVW(2) $space-large 0 0;
      &:last-of-type {
        padding-right: 0;
      }
    }
  }
  &-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: $space-base;
    width: adjustVW(144);
  }
  &-button {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: $z-optimization-result-base - 2;
    transform: translateY(-50%);
  }
  &-optimization {
    .table-head-cell:last-of-type {
      padding-right: $space-large;
    }
    .table-body-cell:last-of-type {
      padding-right: $space-large;
    }
    .table-head-cell:first-of-type {
      padding-left: $space-small + adjustVW(36);
    }
    .table-body-cell:first-of-type {
      padding-left: $space-small + adjustVW(36);
    }
  }
  &-conditions {
    .table-head-cell:first-of-type {
      padding-left: $space-medium;
    }
    .table-body-cell:first-of-type {
      padding-left: $space-medium;
    }
  }
}

.popup {
  position: relative;
  width: adjustVW(344);
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $space-small;
  }
  &-reset {
    &-text {
      text-decoration: underline;
    }
  }
  &-adjust {
    padding: 0 $space-sub $space-small;
    margin-bottom: $space-small;
    border-bottom: $border-sub;
  }
  &-bottom {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    grid-column-gap: $space-small;
  }
  &-conditions {
    flex-wrap: wrap;
    grid-row-gap: $space-base;
  }
  &-disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    &-inner {
      width: 100%;
      height: 100%;
      background: #fff;
      opacity: 0.5;
    }
  }
}
</style>
