<template>
  <div class="graph">
    <line-plot class="graph-inner" :data="showData" />
  </div>
</template>

<script>
import linePlot from '@/components/organisms/graph/LinePlot'

export default {
  components: {
    linePlot
  },
  props: {
    graph: String,
    chartsData: Object
  },
  computed: {
    showData() {
      return this.chartsData[this.graph]?.data ?? []
    }
  }
}
</script>

<style lang="scss" scoped>
.graph {
  width: calc(100vw - #{adjustVW(240) + $space-medium * 4});
  height: 100%;
  &-inner {
    width: 100%;
    height: 100%;
  }
}
</style>
