<template>
  <div class="apply-wrap">
    <div class="apply-side">
      <div class="apply-side-dataset">
        <div class="apply-side-inputbox">
          <input-box-validation
            v-model="datasetOption.name"
            :accept="validate.dataset.accept"
            :error="validate.dataset.error"
            :title="$t('preprocessing.applyingDataset.datasetNameToSave')"
            :placeholder="
              $t('preprocessing.applyingDataset.datasetNamePlaceholder')
            "
            popupView
            isNameValidation
            :nameValidationSuggests="datasetFormValidate.suggests"
          />
        </div>
        <div class="apply-side-inputbox">
          <input-box
            v-model="datasetOption.description"
            isTextArea
            isGray
            :title="
              $t('preprocessing.applyingDataset.datasetDescriptionToSave')
            "
            :placeholder="
              $t('preprocessing.applyingDataset.datasetDescriptionPlaceholder')
            "
          />
        </div>
        <button-large
          class="apply-side-button"
          :isDisabled="disabledSubmit || disableClick"
          :text="$t('preprocessing.applyingDataset.saveDataset')"
          :type="'emphasis'"
          @click="
            $emit('save-dataset', {
              dataset: datasetOption,
              preprocessing: preprocessing,
              preprocessingSave: checked
            })
          "
        />
      </div>
      <div class="apply-side-preprocessing">
        <div class="apply-side-text">
          <texts
            :text="$t('preprocessing.applyingDataset.settingsSave')"
            isBold
          />
        </div>
        <div>
          <checkbox
            class="apply-side-checkbox"
            :text="$t('preprocessing.applyingDataset.saveSameTIme')"
            :checked="checked"
            @input="checked = $event"
          />
        </div>
        <div v-show="checked">
          <div class="apply-side-text">
            <texts
              size="small"
              :text="$t('preprocessing.applyingDataset.preprocessingContent')"
              isBold
            />
          </div>
          <preprocessign-chart-list-min
            class="apply-side-chart"
            :layers="layers"
          />
          <button-toggle
            :closeText="$t('preprocessing.applyingDataset.settingsHowSave')"
            :openText="$t('preprocessing.applyingDataset.settingsHowSave')"
            :value="checkPreprocName"
          >
            <div class="apply-side-inputbox">
              <input-box-validation
                v-model="preprocessing.name"
                :accept="validate.preproc.accept"
                :error="validate.preproc.error"
                :placeholder="
                  $t(
                    'preprocessing.applyingDataset.preprocessingNamePlaceholder'
                  )
                "
                :title="
                  $t('preprocessing.applyingDataset.preprocessingNameToSave')
                "
                popupView
                isNameValidation
                :nameValidationSuggests="preprocFormValidate.suggests"
              />
            </div>
            <div class="apply-side-inputbox">
              <input-box
                v-model="preprocessing.description"
                isGray
                isTextArea
                :placeholder="
                  $t(
                    'preprocessing.applyingDataset.preprocessingDescriptionPlaceholder'
                  )
                "
                :title="
                  $t(
                    'preprocessing.applyingDataset.preprocessingDescriptionToSave'
                  )
                "
              />
            </div>
          </button-toggle>
        </div>
      </div>
    </div>
    <div class="apply-table">
      <div class="apply-table-inner">
        <table-main
          key="dataset"
          class="apply-table-main"
          :tableColumns="dataset.tableColumns.map((item) => item.name)"
          :table="dataset.table"
          :pagingCount="5"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getLayers } from '@/lib/preprocessing'

import texts from '@/components/atoms/text'
import buttonLarge from '@/components/atoms/button-large'
import preprocessignChartListMin from '@/components/molecules/preprocessing-chart-list-min'
import inputBox from '@/components/molecules/input-box'
import checkbox from '@/components/atoms/checkbox-base'
import buttonToggle from '@/components/molecules/button-toggle'
import tableMain from '@/components/molecules/table-main'
import inputBoxValidation from '@/components/molecules/input-box-validation.vue'

export default {
  components: {
    buttonLarge,
    buttonToggle,
    checkbox,
    inputBox,
    inputBoxValidation,
    preprocessignChartListMin,
    tableMain,
    texts
  },
  data() {
    const edaRecipeNameSuffix = this.autoPreprocessingApplied
      ? this.$t('preprocessing.popup.edaRecipe.appliedAutoPreprocessingSuffix')
      : ''
    const edaRecipeName = this.datasetName + edaRecipeNameSuffix

    return {
      datasetOption: {
        name:
          this.datasetName +
          this.$t('preprocessing.applyingDataset.datasetNameSuffix'),
        description: ''
      },
      preprocessing: {
        name: edaRecipeName,
        description: ''
      },
      checked: true,
      checkPreprocName: false,
      validate: {
        dataset: {
          accept: null,
          error: null
        },
        preproc: {
          accept: null,
          error: null
        }
      }
    }
  },
  props: {
    appliedChart: Object,
    autoPreprocessingApplied: {
      type: Boolean,
      default: false
    },
    dataset: Object,
    datasetFormValidate: Object,
    datasetName: String,
    preprocFormValidate: Object,
    preProcHeadVersion: Number,
    disableClick: Boolean,
    preprocessingList: Object
  },
  computed: {
    layers() {
      return getLayers(this.appliedChart)
    },
    disabledSubmit() {
      if (this.datasetOption.name === '') return true
      if (this.validate.dataset.error) return true
      if (this.checked && this.preprocessing.name === '') return true
      if (this.checked && this.validate.preproc.error) return true

      return false
    }
  },
  methods: {
    checkDuplicateLayer() {
      const layers = []
      this.layers.forEach((block) => {
        delete block.alias_table
        block.type = block.type.replace('Sql', '')
        layers.push(block)
      })
      const saveLayer = JSON.stringify(layers)
      for (const item of Object.values(this.preprocessingList)) {
        const target = []
        item.layers.forEach((block) => {
          delete block.alias_table
          block.type = block.type.replace('Sql', '')
          target.push(block)
        })
        if (JSON.stringify(target) === saveLayer) {
          return true
        }
      }
      return false
    }
  },
  mounted: function () {
    this.$emit('input-create-form', {
      type: 'dataset',
      form: this.datasetOption
    })

    this.$emit('input-create-form', {
      type: 'preproc',
      form: this.preprocessing
    })

    const { duplicate } = this.preprocFormValidate
    this.checkPreprocName = !duplicate
    this.checked = !this.checkDuplicateLayer()
  },
  watch: {
    datasetFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.dataset.accept =
          duplicate !== undefined ? duplicate : null
        this.validate.dataset.error =
          duplicate !== undefined ? !duplicate : null
      }
    },
    datasetOption: {
      deep: true,
      handler: function (form) {
        this.$emit('input-create-form', {
          type: 'dataset',
          form
        })
      }
    },
    preprocessing: {
      deep: true,
      handler: function (form) {
        this.$emit('input-create-form', {
          type: 'preproc',
          form
        })
      }
    },
    preprocFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.preproc.accept =
          duplicate !== undefined ? duplicate : null
        this.validate.preproc.error =
          duplicate !== undefined ? !duplicate : null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.apply {
  &-wrap {
    display: grid;
    grid-template-areas: 'side table';
    grid-template-columns: 1fr adjustVW(966);
    grid-template-rows: 100%;
    grid-column-gap: $space-medium;
    width: adjustVW(1632 - 24 * 2); // 左右幅引いてます
    height: 100%;
  }
  &-side {
    display: flex;
    flex-direction: column;
    grid-area: side;
    &-dataset {
      flex-shrink: 0;
      padding: 0 0 $space-medium;
      margin: 0 0 $space-medium;
      border-bottom: $border-sub;
    }
    &-preprocessing {
      overflow-y: auto;
      flex-shrink: 1;
      padding: 0 $space-base 0 0;
      @include scrollbar;
    }
    &-text {
      padding: 0 0 $space-sub;
    }
    &-inputbox {
      margin: 0 0 $space-medium;
    }
    &-checkbox {
      margin: 0 0 $space-small;
    }
    &-button {
      width: 100%;
    }
    &-line {
      width: 100%;
      height: adjustVH(1);
      margin: 0 0 $space-small;
      background: $border-gray;
      z-index: 0;
    }
    &-chart {
      overflow-y: scroll;
      padding: 0 $space-base 0 0;
      margin: 0 0 $space-medium;
      @include scrollbar;
    }
  }
  &-table {
    overflow: hidden;
    grid-area: table;
    padding: $space-small;
    background: $background-sub;
    border-radius: adjustVW(16);
    &-inner {
      overflow: hidden;
      width: 100%;
      height: 100%;
      padding: $space-base $space-base $space-base $space-small;
      background: $background;
      border-radius: adjustVW(16);
      box-shadow: $box-shadow-main;
    }
    &-main {
      background: $background;
    }
  }
}
</style>
