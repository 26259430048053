<template>
  <div class="detail">
    <button class="detail-back" @click="$emit('close')">
      <text-with-icon
        :text="$t('trainedAi.optimization.table.detail.title')"
        iconName="backward"
        color="gray"
        size="small"
      />
    </button>
    <div class="detail-main">
      <texts
        class="detail-main-title"
        :text="$t('trainedAi.optimization.table.result.title')"
        color="green-sub"
      />
      <div class="detail-main-border" />
      <div class="detail-main-list">
        <div
          v-for="(result, column) in resultOptimization"
          :key="column"
          class="detail-main-item"
        >
          <texts
            class="detail-main-column"
            :text="column"
            size="small"
            color="green-sub"
          />
          <texts
            class="detail-main-value"
            :text="result"
            size="large"
            color="green"
          />
        </div>
      </div>
    </div>
    <div class="detail-conditions">
      <div class="detail-conditions-main">
        <list-item-slot
          class="detail-conditions-column-list"
          :field="fieldColumn"
          :items="conditionsList"
        >
          <template #columnField>
            <texts
              :text="$t('trainedAi.optimization.table.conditions.title')"
              size="small"
              color="gray"
            />
          </template>
          <template #valueField>
            <texts
              :text="$t('trainedAi.optimization.table.detail.optimizationVal')"
              size="small"
              color="gray"
            />
          </template>
          <template #column="entry">
            <div class="detail-conditions-item">
              <texts
                class="detail-conditions-item-text"
                :text="entry.item"
                size="small"
              />
            </div>
          </template>
          <template #value="entry">
            <div class="detail-conditions-item detail-conditions-item-vertical">
              <texts
                class="detail-conditions-item-text"
                :text="entry.item"
                size="small"
              />
              <optimization-table-detail-calc :entry="entry.entry" />
            </div>
          </template>
        </list-item-slot>
        <div class="detail-conditions-border" />
        <list-item-slot
          class="detail-conditions-conditions-list"
          :field="fieldConditions"
          :items="conditionsList"
        >
          <template #conditionsField>
            <texts
              :text="$t('trainedAi.optimization.conditions.title')"
              size="small"
              color="gray"
            />
          </template>
          <template #conditions="entry">
            <optimization-conditions-item
              class="detail-conditions-item"
              :conditionsItem="entry.item"
              noColumn
              showNoItem
            />
          </template>
        </list-item-slot>
      </div>
      <div
        v-if="conditionsOthers && conditionsOthers.length > 0"
        class="detail-conditions-sub"
      >
        <texts
          class="detail-conditions-sub-title"
          :text="$t('trainedAi.optimization.table.detail.otherConditions')"
          size="small"
          color="gray"
        />
        <div class="detail-conditions-sub-list">
          <optimization-conditions-item
            v-for="(others, index) in conditionsOthers"
            :key="index"
            :conditionsItem="others"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import textWithIcon from '@/components/molecules/text-with-icon'
import listItemSlot from '@/components/molecules/list-item-slot'
import optimizationConditionsItem from '@/components/organisms/optimization/optimization-conditions-item'
import optimizationTableDetailCalc from '@/components/organisms/optimization/optimization-table-detail-calc'

export default {
  components: {
    textWithIcon,
    listItemSlot,
    optimizationConditionsItem,
    optimizationTableDetailCalc
  },
  props: {
    resultOptimization: Object,
    resultConditions: Object,
    optimizationConditions: Object
  },
  computed: {
    fieldColumn() {
      return [
        {
          key: 'column',
          width: 168
        },
        {
          key: 'value',
          width: 168
        }
      ]
    },
    fieldConditions() {
      return [
        {
          key: 'conditions',
          width: 960
        }
      ]
    },
    conditionsList() {
      if (!this.resultConditions || !this.optimizationConditions) return []
      if (
        Object.keys(this.resultConditions).length === 0 ||
        this.optimizationConditions.columnConditions.length === 0
      )
        return []
      const res = Object.entries(this.resultConditions).map((item) => {
        const columnConditions =
          this.optimizationConditions.columnConditions.find((target) => {
            return target?.column === item[0]
          })
        return {
          column: item[0],
          value: item[1],
          conditions: columnConditions
        }
      })
      return res
    },
    conditionsOthers() {
      if (
        !this.optimizationConditions ||
        (this.optimizationConditions.columnConditions.length === 0 &&
          this.optimizationConditions.expressions.length === 0)
      )
        return []
      const optimizationCondtions =
        this.optimizationConditions.columnConditions.filter((target) => {
          const check = Object.keys(this.resultOptimization).some((column) => {
            return column === target?.column
          })
          return !target?.column || check
        })
      const res = [...optimizationCondtions, ...this.optimizationConditions.expressions]
      return res
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  &-main {
    display: flex;
    align-items: stretch;
    grid-column-gap: $space-medium;
    padding: $space-base $space-medium;
    margin-bottom: $space-medium;
    background-color: $green-bg;
    &-title {
      display: flex;
      flex-shrink: 0;
      align-items: center;
    }
    &-border {
      flex-shrink: 0;
      align-self: stretch;
      width: adjustVW(1);
      background-color: $green-lite;
    }
    &-list {
      overflow-x: auto;
      display: flex;
      grid-column-gap: $space-large;
      padding: $space-base 0 adjustVW(6);
      @include scrollbarGreen;
    }
    &-item {
      max-width: adjustVW(144);
    }
  }
  &-conditions {
    overflow-y: scroll;
    height: 100%;
    padding-right: $space-base;
    @include scrollbar;
    &-main {
      overflow: hidden;
      display: flex;
      padding-bottom: $space-medium;
      margin-bottom: $space-medium;
      border-bottom: $border-sub;
    }
    &-item {
      overflow: hidden;
      display: flex;
      align-items: center;
      grid-column-gap: $space-base;
      height: adjustVW(40);
      &-vertical {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        grid-row-gap: 0;
      }
      &-text {
        flex-shrink: 0;
      }
    }
    &-column-list {
      flex-shrink: 0;
      z-index: 0;
      &::v-deep {
        .list-item-key-block {
          border-bottom: adjustVW(2) $key-lite solid;
        }
        .list-item-entry-block:hover {
          background-color: #fff;
        }
      }
    }
    &-conditions-list {
      overflow: hidden;
      flex-shrink: 1;
      z-index: 0;
      &::v-deep {
        .list-item-key-block,
        .list-item-entry-block {
          padding-left: $space-medium;
        }
        .list-item-key-block {
          border-bottom: adjustVW(2) $key-lite solid;
        }
        .list-item-entry-block:hover {
          background-color: #fff;
        }
      }
    }
    &-border {
      flex-shrink: 0;
      width: adjustVW(2);
      background-color: $key-lite;
      z-index: 1;
    }
    &-sub {
      &-title {
        margin-bottom: $space-sub;
      }
      &-list {
        display: flex;
        flex-direction: column;
        grid-row-gap: $space-sub;
      }
    }
  }
  &-back {
    margin-bottom: $space-small;
  }
}
</style>
