<template>
  <div>
    <prevent-leave
      ref="preventLeave"
      v-model="isPrevent"
      :title="$t('common.trainLeave.popup.title')"
      :saveButton="
        $t('common.trainLeave.popup.saveButton', { currentPage: currentPage })
      "
      :leaveButton="$t('common.trainLeave.popup.leaveButton')"
      leaveButtonColor="sub"
      @save-event="closePrevent"
    >
      <texts class="prevent-leave-text" isShowAll size="small">
        <span
          v-html="
            $t('common.trainLeave.reason.first', {
              leavePage: leavePage,
              currentPage: currentPage
            })
          "
        />
        <span
          v-html="
            $t('common.trainLeave.reason.second', { currentPage: currentPage })
          "
        />
        <span v-html="$t('common.trainLeave.reason.third')" />
      </texts>
    </prevent-leave>
    <recipe-list
      isTrain
      :sidebar="sidebar"
      :projectInfo="projectInfo"
      :progressTraining="progressTraining"
      :headerTabs="headerTabs"
      :popup="popup"
      :recipes="projectRecipes"
      :accountInfo="accountInfo"
      :recipeLayers="recipeLayers"
      :recipeTemplates="recipeTemplates"
      :loadingRecipeTemplates="loadingRecipeTemplates"
      :loading="loading"
      :notFound="notFoundProject"
      :disableClick="disableClick"
      :incorrectOrder="incorrectOrder"
      @menu-click="iconMenuClick"
      @close-modal="closePopup"
      @delete-recipes="deleteRecipe"
      @get-recipe-template="getRecipeTemplate()"
      @save-recipe-template="saveRecipeTemplate($event)"
      @not-prevent="notPrevent"
      @restart-prevent="restartPrevent"
      @page-back="pageBack"
    />
  </div>
</template>

<script>
import recipeList from '@/components/templates/train/recipe-list'
import preventLeave from '@/components/molecules/prevent-leave'

import { mapActions, mapGetters } from 'vuex'
import { splitFullId } from '@/lib/misc.js'
import { checkTrainingOrder } from '@/lib/progress-training'
import { recipeValidator } from '@/lib/validator/recipe.js'
import { checkReasons } from '@/lib/checkReasons.js'
import {
  checkOuterTraining,
  checkCorrectBackPage
} from '@/lib/training-pages-correct-behavior'
import setMountedTimer from '@/mixin/set-mounted-timer'

export default {
  components: {
    recipeList,
    preventLeave
  },
  mixins: [setMountedTimer],
  async beforeRouteLeave(to, from, next) {
    this.isPrevent = this.checkPrevent(to.name)
    if (this.isPrevent) {
      this.leavePageName = to.name
      const result = await this.$refs.preventLeave.$confirm()
      if (result) {
        this.deleteProgressTraining({ projectId: this.projectId })
        this.setProgressTraining({
          item: this.projectId,
          setType: 'project'
        })

        this.$router.push({
          name: 'projectDetail',
          params: {
            projectId: this.projectId
          }
        })
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  computed: {
    ...mapGetters('recipes', ['recipes', 'recipeTemplates', 'loadingRecipeTemplates', 'recipeLayers']),
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('project', ['projectList', 'loadingProjectList']),
    ...mapGetters('datasets', ['datasetLoading']),
    ...mapGetters('trainings', ['predictionColumnOptions']),
    ...mapGetters('models', ['modelSettingList']),
    ...mapGetters('trainingDataset', ['trainingDataset', 'trainingDatasetId', 'trainingDatasetAccountId']),
    ...mapGetters('customblock', {
      customblockList: 'customblockList',
      loadingCutomblockList: 'loading'
    }),

    projectId() {
      return parseInt(this.$route.params.projectId)
    },
    notFoundProject() {
      if (this.projectId === null) {
        return false
      } else {
        return !(this.projectId in this.projectList)
      }
    },
    datasetList() {
      const result = {}
      this.projectList[this.projectId]?.listData?.forEach((item) => {
        result[item.id] = item
      })
      return result
    },
    projectInfo() {
      if (this.projectId) {
        return this.projectList[this.projectId]
      }
      return null
    },
    sidebar() {
      return {
        // サイドバーに表示する情報
        activeLink: 'recipeSetting',
        status: 'select'
      }
    },
    datasetId() {
      if (!this.progressTraining) return null
      if (this.progressTraining.preprocessingDatasetId) {
        return this.progressTraining.preprocessingDatasetId
      } else {
        return this.progressTraining.datasetId
      }
    },
    loading() {
      return (
        this.loadingRecipeTemplates ||
        this.loadingUseTraining ||
        this.loadingProjectList ||
        this.projectRecipesLoading ||
        !this.projectRecipes
      )
    },
    disableClick() {
      return this.submitDeleting || this.submitOther
    },
    leavePage() {
      return this.$t('common.trainLeave.titles.' + this.leavePageName)
    },
    currentPage() {
      return this.$t('common.trainLeave.titles.' + this.currentPageName)
    },
    disabledPreprocessingStructuredData() {
      if (!this.datasetId || !this.datasetList) return false
      const target = this.datasetList[this.datasetId]
      return (
        target?.type === 'structured' ||
        target?.accountId !== this.accountInfo.accountId
      )
    }
  },
  methods: {
    ...mapActions('recipes', [
      'loadTemplate',
      'deleteRecipes',
      'loadRecipeList',
      'fetchLayers',
      'fetchTemplateList',
      'setEditRecipe'
    ]),
    ...mapActions('project', ['projectAddRecipe']),
    ...mapActions('trainings', ['fetchValidColumns']),
    ...mapActions('auth', ['fetchAccountInfo']),
    ...mapActions('models', ['deleteProgressTraining', 'getProgressTraining', 'setProgressTraining']),
    ...mapActions('trainingDataset', ['fetchDatasetDetail']),
    ...mapActions('customblock', ['fetchCustomblocks']),

    closePopup(e) {
      // ポップアップを閉じる
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
    },
    showPopup(e) {
      this.popup.showPopup.push(e)
    },
    async loadProjectRecipes() {
      this.projectRecipesLoading = true
      if (this.projectId) {
        try {
          const response = await this.$sendMessageAndReceive({
            action: 'getRecipeList',
            projectId: this.projectId
          })
          if (response.status !== 'error') {
            const obj = {}
            response.list.forEach((item) => {
              const fullId = item.id + '-' + item.accountId
              item.fullId = fullId
              obj[fullId] = item
            })
            this.projectRecipes = obj
          } else {
            throw response
          }
        } catch (e) {
          console.log(e)
        } finally {
          this.projectRecipesLoading = false
        }
      } else {
        await this.loadRecipeList()
        this.projectRecipes = this.recipes
      }
      this.projectRecipesLoading = false
    },
    async loadDetail(fullId) {
      const [recipeId, recipeAccountId] = splitFullId(fullId)
      const res = await this.$sendMessageAndReceive({
        action: 'getRecipeDetail',
        recipe_id: recipeId,
        recipe_account_id: recipeAccountId
      })
      return res.detail
    },
    async deleteRecipe(targets) {
      this.submitDeleting = true
      const recipes = targets.map((x) => ({ id: x.id, accountId: x.accountId }))
      try {
        await this.deleteRecipes(recipes)
        if (this.projectId) {
          await this.loadProjectRecipes()
        }
      } finally {
        this.submitDeleting = false
      }
      this.closePopup('deleteRecipe')
    },
    async iconMenuClick({ name, checked }) {
      switch (name) {
        case 'useTraining': {
          await this.selectRecipe(checked[0])
          break
        }
        case 'copyNewRecipe': {
          const recipeFullId = checked[0]
          const recipeData = await this.loadDetail(recipeFullId)

          this.setEditRecipe({
            recipe: recipeData,
            from: null
          })

          this.$router.push({
            name: 'trainRecipeEdit'
          })
          break
        }
        case 'delete':
          this.popup.deleteRecipe = {
            recipes: checked.map((id) => this.projectRecipes[id])
          }
          this.showPopup('deleteRecipe')
          break
      }
    },
    async selectRecipe(id) {
      this.loadingUseTraining = true

      const recipe = await this.loadDetail(id)
      this.recipe = recipe

      if (this.progressTraining && !this.datasetLoading) {
        this.validColumnCheck = await this.checkValidColumn(recipe)
        this.checkTemplate = this.projectRecipes[id].fromTemplate
        this.popup.selectRecipeErrors = checkReasons.bind(this)(recipe)
        if (this.popup.selectRecipeErrors.length > 0) {
          this.loadingUseTraining = false
          this.showPopup('selectRecipeError')
          return
        }

        this.correctTransition = true

        this.setProgressTraining({
          item: recipe.id,
          setType: 'recipe',
          projectId: this.projectId
        })
        this.setProgressTraining({
          item: null,
          setType: 'tmpRecipe',
          projectId: this.projectId
        })

        this.$router.push({
          name: 'training',
          params: { projectId: this.projectId }
        })
        this.loadingUseTraining = false
      }
    },
    selectedLearningDataType() {
      if (!this.trainingDataset) return null
      let dataType = null
      if (this.trainingDataset.nRows > 0) {
        if (dataType != null) return null
        dataType = 'figures'
      }
      if (this.trainingDataset.nImages > 0) {
        if (dataType != null) return null
        dataType = 'images'
      }
      if (this.trainingDataset.nTexts > 0) {
        if (dataType != null) return null
        dataType = 'texts'
      }
      return dataType
    },
    setModelSettingInfo: async function (projectId) {
      if (projectId) {
        this.progressTraining = await this.getProgressTraining({
          projectId
        })
      } else {
        this.progressTraining = null
      }
    },
    inputDataType(recipe) {
      if (!recipe) return null
      const input = recipe.body.layers.find((x) => x.name === 'inputData')
      return input?.params?.dataType?.value
    },
    recipeNumClassMatch(recipe) {
      if (!recipe) return null
      if (!this.trainingDataset) return null
      if (!this.trainingDataset.nClasses) return null
      const body = recipe.body
      if (!body) return null
      const layers = body.layers
      let nClass = null
      layers.forEach((v) => {
        if (v.name === 'inputLabels') {
          nClass = v.params.nClass.value
        }
      })
      return nClass === this.trainingDataset.nClasses
    },
    async getRecipeTemplate() {
      this.showPopup('getRecipeTemplate')
      await this.fetchLayers()
      await this.fetchTemplateList()
    },
    saveRecipeTemplate: async function (id) {
      this.submitOther = true
      const targetRecipeTemplate = this.recipeTemplates[id]
      const recipeTemplateValidate = recipeValidator(
        this.projectRecipes,
        targetRecipeTemplate
      )
      if (!recipeTemplateValidate?.duplicate) {
        targetRecipeTemplate.body.info.name =
          recipeTemplateValidate?.suggests[0]
      }
      try {
        const res = await this.loadTemplate({
          type: 'recipe',
          id: targetRecipeTemplate.id,
          name: targetRecipeTemplate.body.info.name,
          projectId: this.projectId
        })
        if (res.status !== 'error') {
          await this.loadProjectRecipes()
        }
      } finally {
        this.submitOther = false
      }
      this.closePopup('getRecipeTemplate')
    },
    async checkValidColumn(recipe) {
      const datasetType = this.selectedLearningDataType()
      if (datasetType === 'figures') {
        const req = {
          data_id: this.datasetId,
          data_account_id: this.datasetList[this.datasetId].accountId,
          recipe_id: recipe.id,
          recipe_account_id: recipe.accountId
        }
        await this.fetchValidColumns(req)
        return this.progressTraining.targetColumn.map((item) => {
          return {
            ...this.predictionColumnOptions[item],
            columnName: item
          }
        })
      } else {
        return { valid: true }
      }
    },
    notPrevent() {
      this.correctTransition = true
    },
    restartPrevent() {
      this.correctTransition = false
    },
    checkPrevent(nextPageName) {
      const datasetType = {
        unstructured: this.progressTraining?.unstructuredDataset,
        disabledPreprocessing: this.disabledPreprocessingStructuredData
      }
      return (
        !checkOuterTraining(nextPageName) &&
        !checkCorrectBackPage(
          'recipe',
          nextPageName,
          this.progressTraining?.laterSelectTargetColumn,
          datasetType
        ) &&
        !this.correctTransition
      )
    },
    closePrevent() {
      this.$refs.preventLeave.closePopup()
    },
    pageBack() {
      let toPage = null
      if (this.progressTraining.unstructuredDataset) {
        toPage = {
          name: 'datasetSetting',
          params: {
            projectId: this.projectId
          }
        }
      } else if (
        this.progressTraining.laterSelectTargetColumn ||
        this.disabledPreprocessingStructuredData
      ) {
        toPage = {
          name: 'selectTargetColumn',
          params: {
            projectId: this.projectId,
            datasetId: this.progressTraining.datasetId
          }
        }
      } else {
        toPage = {
          name: 'trainPreprocessing',
          params: {
            projectId: this.projectId,
            id: this.progressTraining.datasetId,
            type: '0'
          }
        }
      }
      this.$router.push(toPage)
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.$waitConnected()
      vm.loadProjectRecipes()
      await vm.fetchAccountInfo()

      await vm.setModelSettingInfo(parseInt(to.params.projectId))
      vm.incorrectOrder = !checkTrainingOrder({
        projectId: vm.projectId,
        stage: 'recipe',
        progressList: vm.modelSettingList
      })

      if (vm.incorrectOrder) {
        vm.showPopup('preventTrainingStatus')
      }

      const modelSetting = await vm.getProgressTraining({
        projectId: vm.projectId
      })

      await vm.fetchCustomblocks()

      const datasetId =
        modelSetting.preprocessingDatasetId ?? modelSetting.datasetId
      const accountId = vm.datasetList[datasetId].accountId

      if (
        !vm.trainingDatasetId ||
        vm.trainingDatasetId !== datasetId ||
        !vm.trainingDatasetAccountId ||
        vm.trainingDatasetAccountId !== accountId
      ) {
        await vm.fetchDatasetDetail({
          dataId: datasetId,
          accountId: accountId,
          includeGraph: false,
          includeData: false
        })
      }
    })
  },
  data() {
    return {
      headerTabs: {
        // ヘッダーのタブ
        tabs: [],
        tabSelect: 1
      },
      popup: {
        showPopup: [],
        deleteRecipe: null,
        selectRecipeErrors: []
      },
      detailTemplateLoading: false,
      projectRecipes: null,
      projectRecipesLoading: false,
      detailLoading: false,
      loadingUseTraining: false,
      submitDeleting: false,
      submitOther: false,
      validColumnCheck: [], // チェック対象が複数になる場合があるため、複数前提
      isPrevent: false,
      correctTransition: false,
      currentPageName: this.$route.name,
      leavePageName: null,
      checkTemplate: false,
      incorrectOrder: null,
      recipe: null, // アイコンメニューから学習する場合に使用する、レシピのバリデーション用,
      progressTraining: null
    }
  }
}
</script>

<style lang="scss" scoped>
.prevent-leave-text {
  display: flex;
  flex-direction: column;
  grid-row-gap: $space-sub;
  ::v-deep {
    .prevent-leave-text-emphasis {
      color: $text-decoration;
    }
    .prevent-leave-text-caution {
      color: $text-caution;
    }
  }
}
</style>
