<template>
  <!--
    アカウント情報・設定ページの右上にあたるカードです
  -->
  <div class="toggle-card-wrap">
    <!--
      クーポンコードの利用
    -->
    <toggle-card class="item" :title="$t('account.setting.coupon.title')">
      <div>
        <input-box
          v-model="couponCode"
          class="input"
          :title="$t('account.setting.coupon.code')"
          isGray
        />
        <div v-if="useCouponSuccess === true" class="status">
          <text-box
            :text="$t('account.setting.coupon.updateEducationPlan')"
            isFolded
            isAccept
          />
        </div>
        <div v-else class="status">
          <text-box
            v-if="useCouponError.length > 0"
            :text="$t('account.setting.coupon.' + useCouponError)"
            isFolded
            isError
          />
        </div>
        <div v-if="!useCouponSuccess && !useCouponError" class="success status">
          <text-box
            :text="$t('account.setting.coupon.updateEducationPlan')"
            isFolded
            isAccept
          />
        </div>
        <buttons
          class="button"
          type="emphasis"
          size="small"
          :isDisabled="couponCode.length === 0"
          :text="$t('account.setting.coupon.button')"
          @click="useCoupon"
        />
      </div>
    </toggle-card>
  </div>
</template>

<script>
import buttons from '@/components/atoms/button-main.vue'
import textBox from '@/components/atoms/text-box'
import toggleCard from '@/components/molecules/toggle-card.vue'
import inputBox from '@/components/molecules/input-box.vue'

export default {
  components: {
    buttons,
    textBox,
    toggleCard,
    inputBox
  },
  data() {
    return {
      couponCode: '',
      useCouponError: '',
      useCouponSuccess: false
    }
  },
  props: {
    accountInfo: Object
  },
  methods: {
    async useCoupon() {
      if (this.accountInfo.plan === 'education_1') {
        this.useCouponError = 'ALREADY_EDUCATION_PLAN'
      } else {
        const request = {
          action: 'useCoupon',
          code: this.couponCode
        }
        const res = await this.$sendMessageAndReceive(request)
        if (res.status === 'error') {
          this.useCouponError = res.message
        } else {
          this.couponCode = ''
          this.useCouponError = ''
          this.useCouponSuccess = true
          this.fetchAccountInfo()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-card {
  &-input {
    &-button {
      margin-top: $space-small;
    }
  }
  &-between {
    margin-top: $space-small;
    &:first-of-type {
      margin: 0;
    }
  }
  &-info {
    margin-top: $space-small;
  }
}

.item {
  margin: 0 0 $space-medium;
  &:last-of-type {
    margin: 0;
  }
}

.input {
  padding: 0 adjustVW(2);
  margin: 0 0 $space-small;
}
.status {
  margin: 0 0 $space-small;
}
.success {
  opacity: 0;
}

.detail {
  padding: 0 adjustVW(2);
  margin: 0 0 $space-small;
  &-title {
    margin: 0 0 $space-text;
  }
  &:last-of-type {
    margin: 0 0 adjustVW(2);
  }
}
</style>
