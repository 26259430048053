<template>
  <div class="preprocessing-list-select-dataset-wrap">
    <div class="preprocessing-list-select-dataset-list-wrap">
      <list-item-slot
        :field="field"
        :items="datasetList"
        iconName="popup"
        type="button"
        isNameDescription
        iconButton
        @icon-click="$emit('click-dataset', $event)"
        @list-click="$emit('apply-dataset', $event)"
      >
        <template
          v-slot:name
          class="preprocessing-list-select-dataset-list-name"
        />
        <template v-slot:nData="entry">
          <texts :text="entry.item" />
        </template>
        <template
          v-slot:columns="entry"
          class="preprocessing-list-select-dataset-list-col"
        >
          <div class="preprocessing-list-select-dataset-list-row">
            <div
              v-for="(item, key) in entry.item.slice(0, 6)"
              :key="key"
              class="preprocessing-list-select-dataset-list-col"
            >
              <bullet-item
                :text="item"
                class="preprocessing-list-select-dataset-list-col-item"
              />
            </div>
          </div>
        </template>
      </list-item-slot>
    </div>
    <div class="preprocessing-list-select-dataset-column-wrap">
      <div class="preprocessing-list-select-dataset-column-title">
        <texts :text="$t('preprocessing.detail.applyColumn')" size="large" />
      </div>
      <div class="preprocessing-list-select-dataset-column-list">
        <div v-for="(item, key) in preprocessing[0].layers" :key="key">
          <bullet-item
            v-if="Boolean(item.column)"
            :text="item.column"
            class="preprocessing-list-select-dataset-column-list-item"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import listItemSlot from '@/components/molecules/list-item-slot'
import bulletItem from '@/components/atoms/bullet-item'

export default {
  components: {
    texts,
    listItemSlot,
    bulletItem
  },
  props: {
    datasetList: Array,
    preprocessing: Array
  },
  computed: {
    field() {
      return [
        {
          id: 0,
          key: 'name',
          label: this.$t('preprocessing.applyingDataset.datasetName'),
          width: 448
        },
        {
          id: 1,
          key: 'nData',
          label: this.$t('preprocessing.applyingDataset.datasetNum'),
          width: 120
        },
        {
          id: 2,
          key: 'columns',
          label: this.$t('preprocessing.applyingDataset.datasetColumns'),
          width: 566
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.preprocessing-list-select-dataset {
  &-wrap {
    display: grid;
    grid-template-areas: 'list column';
    grid-template-columns: 1fr adjustVW(384);
    grid-template-rows: calc(
      100vh - #{$space-medium * 4 + adjustVW(64 + $headerTabHeight)}
    );
    grid-column-gap: $space-medium;
    width: 100%;
  }
  &-list {
    &-wrap {
      overflow-y: auto;
      padding: 0 $space-base 0 0;
      grid-area: list;
      @include scrollbar;
    }
    &-table-list {
      padding: 0;
    }
    &-name {
      display: flex;
      flex-direction: column;
      &-name {
        margin: 0 0 $space-base;
      }
    }
    &-row {
      display: flex;
      flex-wrap: wrap;
    }
    &-col {
      width: 50%;
      padding: 0 $space-small $space-sub 0;
      &:last-child {
        width: 50%;
        padding: 0 $space-small 0 0;
      }
      &:nth-last-of-type(2) {
        width: 50%;
        padding: 0 $space-small 0 0;
      }
      &-item {
        display: flex;
        text-overflow: ellipsis;
      }
    }
  }
  &-column {
    &-wrap {
      display: grid;
      grid-template-areas:
        'title'
        'list';
      grid-template-rows: auto 1fr;
      padding: $space-small;
      background: $background;
      border-radius: adjustVW(16);
      box-shadow: $box-shadow-main;
    }
    &-title {
      grid-area: title;
      padding: 0 0 $space-sub;
    }
    &-list {
      overflow-y: auto;
      max-height: 100%;
      padding: 0;
      grid-area: list;
      @include scrollbar;
      &-item {
        padding: 0 $space-sub $space-sub 0;
      }
    }
  }
}
</style>
