import { duplicateChecker, duplicateSuggests } from './misc'

/**
 * @param { Object[] } preprocList
 * @param { Object } newPreproc
 * @returns { Object }
 **/
export function preprocValidator(preprocs, newPreproc) {
  const preprocKeys = Object.keys(preprocs)

  const nameList =
    preprocKeys.length > 0 ? preprocKeys.map((key) => preprocs[key].name) : []

  const newPreprocName = newPreproc.name

  const checkResult = duplicateChecker(nameList, newPreprocName)
  let suggests = []
  if (!checkResult) {
    suggests = duplicateSuggests(nameList, newPreprocName)
  }

  return {
    duplicate: checkResult,
    suggests: suggests
  }
}
