<template>
  <!--
    組織管理ページの左側にあたるページです
  -->
  <cardBase class="organization-wrap">
    <texts
      class="organization-first"
      size="large"
      :text="$t('organization.info.title')"
    />
    <div class="organization-info">
      <textWithTitle
        :title="$t('organization.info.name')"
        :text="accountInfo.organizationInfo.name"
      />
    </div>
    <div class="organization-info">
      <textWithTitle
        :title="$t('organization.info.plan')"
        :text="$t('account.info.plan')"
      />
    </div>
    <div class="organization-info">
      <textWithTitle
        :title="$t('organization.info.people')"
        :text="accountInfo.organizationInfo.members.length"
      />
    </div>
    <div class="organization-info">
      <texts size="small" color="gray" class="organization-info-desc">
        <span class="organization-description-first">{{
          $t('organization.info.firstDescription')
        }}</span>
        <a
          class="organization-info-link"
          href="mailto:support@matrixflow.jp"
        ><span>{{ $t('organization.info.email') }}</span></a>
        <!--ここにリンクが入ります-->
        <span>{{ $t('organization.info.lastDescription') }}</span>
      </texts>
    </div>
  </cardBase>
</template>

<script>
import cardBase from '@/components/atoms/card-base.vue'
import texts from '@/components/atoms/text.vue'

import textWithTitle from '@/components/molecules/text-with-title.vue'

export default {
  components: {
    texts,
    cardBase,
    textWithTitle
  },
  props: {
    accountInfo: Object
  }
}
</script>

<style lang="scss" scoped>
.organization {
  &-first {
    margin-bottom: $space-small;
  }
  &-wrap {
    height: fit-content;
  }
  &-info {
    margin-bottom: $space-medium;
    &:last-of-type {
      margin: 0;
    }
    &-link {
      text-decoration: underline;
    }
    &-desc {
      white-space: pre-line;
    }
  }
}
</style>
