<template>
  <div class="select-mode-wrap">
    <project-template-select-mode-top
      @close-modal="$emit('close-modal', $event)"
      @create-project="$emit('create-project', $event)"
      @page-back="$emit('page-back')"
    />
    <project-template-select-mode-card
      :accountInfo="accountInfo"
      @use-recipe="$emit('use-recipe', $event)"
      @use-template="$emit('use-template')"
    />
  </div>
</template>

<script>
import projectTemplateSelectModeTop from '@/components/organisms/project-template-select-mode/project-template-select-mode-top'
import projectTemplateSelectModeCard from '@/components/organisms/project-template-select-mode/project-template-select-mode-card'

export default {
  components: {
    projectTemplateSelectModeTop,
    projectTemplateSelectModeCard
  },
  props: {
    accountInfo: Object
  }
}
</script>

<style lang="scss" scoped>
.select-mode-wrap {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: $space-base;
  height: 100%;
  padding: 0 $space-medium $space-medium;
}
</style>
