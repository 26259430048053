<template>
  <div class="list">
    <tag
      v-for="tag in tagCrop"
      :key="tag"
      class="item"
      :tag="tag"
    />
  </div>
</template>

<script>
import tag from '@/components/atoms/tag.vue'

export default {
  components: {
    tag
  },
  props: {
    /** 表示するタグの名前の配列 */
    tags: Array,
    /** 表示するタグの個数 */
    cropCount: Number
  },
  computed: {
    tagCrop() {
      if (this.cropCount) {
        return this.tags.slice(0, this.cropCount)
      } else {
        return this.tags
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 -#{$space-base} 0;
}
.item {
  margin: 0 $space-base $space-base 0;
}
</style>
