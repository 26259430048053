<template>
  <div class="wrap">
    <div class="text">
      <texts :text="$t('selectTargetColumn.cautionPopup.text')" />
    </div>
    <div class="button-list">
      <button-main
        :text="$t('selectTargetColumn.cautionPopup.button')"
        type="emphasis"
        @click="$emit('skip-select')"
      />
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import texts from '@/components/atoms/text'

export default {
  components: {
    buttonMain,
    texts
  },
  props: {
    columnName: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.button-list {
  margin: $space-medium 0 0;
}
</style>
