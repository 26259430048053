import { i18n } from '@/main'

export const serviceResponse = {
  classification: `{
    "inferenceType": "${i18n.t(
      'serviceDetail.sampleCode.responses.item.inferenceType'
    )}",
    "id": "${i18n.t('serviceDetail.sampleCode.responses.item.id')}",
    "inputColumns": ["${i18n.t(
      'serviceDetail.sampleCode.responses.item.inputColumns'
    )}"],
    "predictionColumn": "${i18n.t(
      'serviceDetail.sampleCode.responses.item.predictionColumn'
    )}",
    "list": [
      {
        "classes": ["${i18n.t(
          'serviceDetail.sampleCode.responses.item.classificationClasses'
        )}"],
        "probability": ${i18n.t(
          'serviceDetail.sampleCode.responses.item.probabilityExist'
        )},
        "list": [
          {
            "${i18n.t(
              'serviceDetail.sampleCode.responses.item.columnName'
            )}": ${i18n.t('serviceDetail.sampleCode.responses.item.inputData')},
            "${i18n.t(
              'serviceDetail.sampleCode.responses.item.predictionColumn'
            )}": ${i18n.t(
    'serviceDetail.sampleCode.responses.item.predictionResult'
  )},
            "${i18n.t(
              'serviceDetail.sampleCode.responses.item.className'
            )}_probability": ${i18n.t(
    'serviceDetail.sampleCode.responses.item.classProbability'
  )},
            "${i18n.t(
              'serviceDetail.sampleCode.responses.item.columnName'
            )}_importance": ${i18n.t(
    'serviceDetail.sampleCode.responses.item.columnImportance'
  )}
          }
        ],
        "total": ${i18n.t('serviceDetail.sampleCode.responses.item.total')},
        "feature_importance": {
          "shap_values": [
            [${i18n.t('serviceDetail.sampleCode.responses.item.shapValues')}]
          ],
          "x_test": [
            [${i18n.t('serviceDetail.sampleCode.responses.item.xTest')}]
          ],
          "input_columns": ["${i18n.t(
            'serviceDetail.sampleCode.responses.item.inputColumns'
          )}"]
        },
        "mean_shap": {
          "${i18n.t(
            'serviceDetail.sampleCode.responses.item.columnName'
          )}": ${i18n.t('serviceDetail.sampleCode.responses.item.meanShap')}
        },
        "columnName": "${i18n.t('common.columnName')}"
      }
    ]
  }`,
  classificationNotTableData: `{
    "action": "finishInferring",
    "inferenceType": "${i18n.t(
      'serviceDetail.sampleCode.responses.item.inferenceType'
    )}",
    "id": "${i18n.t('serviceDetail.sampleCode.responses.item.id')}",
    "classes": ["${i18n.t(
      'serviceDetail.sampleCode.responses.item.className'
    )}"],
    "probability": ${i18n.t(
      'serviceDetail.sampleCode.responses.item.probabilityExist'
    )},
    "list": [
      {
        "filename": "${i18n.t(
          'serviceDetail.sampleCode.responses.item.fileName'
        )}",
        "body": "${i18n.t('serviceDetail.sampleCode.responses.item.fileBody')}",
        "label": "${i18n.t(
          'serviceDetail.sampleCode.responses.item.filePredictLabel'
        )}",
        "probability": {
          "${i18n.t(
            'serviceDetail.sampleCode.responses.item.className'
          )}": ${i18n.t(
    'serviceDetail.sampleCode.responses.item.fileLableProbability'
  )}
        }
      }
    ]
  }`,
  classificationNotTableDataVectorization: `{
    "action": "finishInferring",
    "inferenceType": "${i18n.t(
      'serviceDetail.sampleCode.responses.item.inferenceType'
    )}",
    "id": "${i18n.t('serviceDetail.sampleCode.responses.item.id')}",
    "probability": ${i18n.t(
      'serviceDetail.sampleCode.responses.item.probabilityExist'
    )},
    "list": [
      {
        "filename": "${i18n.t(
          'serviceDetail.sampleCode.responses.item.fileName'
        )}",
        "body": "${i18n.t('serviceDetail.sampleCode.responses.item.fileBody')}",
        "vector": [
          ${i18n.t('serviceDetail.sampleCode.responses.vectorization.vector')}
        ],
        "similarities": [ ${i18n.t(
          'serviceDetail.sampleCode.responses.vectorization.similarities'
        )}
          {
            "index": ${i18n.t(
              'serviceDetail.sampleCode.responses.vectorization.similaritiesIndex'
            )},
            "filename": "${i18n.t(
              'serviceDetail.sampleCode.responses.vectorization.similaritiesFilename'
            )}",
            "distance": ${i18n.t(
              'serviceDetail.sampleCode.responses.vectorization.similaritiesDistance'
            )}
          }
        ]
      }
    ]
  }`,
  classificationNotTableDataDimRed: `{
    "action": "finishInferring",
    "inferenceType": "${i18n.t(
      'serviceDetail.sampleCode.responses.item.inferenceType'
    )}",
    "id": "${i18n.t('serviceDetail.sampleCode.responses.item.id')}",
    "probability": ${i18n.t(
      'serviceDetail.sampleCode.responses.item.probabilityExist'
    )},
    "list": [
      {
        "filename": "${i18n.t(
          'serviceDetail.sampleCode.responses.item.fileName'
        )}",
        "body": "${i18n.t('serviceDetail.sampleCode.responses.item.fileBody')}",
        "vector": [
          ${i18n.t('serviceDetail.sampleCode.responses.vectorization.vector')}
        ]
      }
    ]
  }`,
  regression: `{
    "inferenceType": "${i18n.t(
      'serviceDetail.sampleCode.responses.item.inferenceType'
    )}",
    "id": "${i18n.t('serviceDetail.sampleCode.responses.item.id')}",
    "inputColumns": ["${i18n.t(
      'serviceDetail.sampleCode.responses.item.inputColumns'
    )}"],
    "predictionColumn": "${i18n.t(
      'serviceDetail.sampleCode.responses.item.predictionColumn'
    )}",
    "list": [
      {
        "classes": [],
        "probability": ${i18n.t(
          'serviceDetail.sampleCode.responses.item.probabilityExist'
        )},
        "list": [
          {
            "${i18n.t(
              'serviceDetail.sampleCode.responses.item.columnName'
            )}": ${i18n.t('serviceDetail.sampleCode.responses.item.inputData')},
            "${i18n.t(
              'serviceDetail.sampleCode.responses.item.predictionColumn'
            )}": ${i18n.t(
    'serviceDetail.sampleCode.responses.item.predictionResult'
  )},
            "${i18n.t(
              'serviceDetail.sampleCode.responses.item.columnName'
            )}_importance": ${i18n.t(
    'serviceDetail.sampleCode.responses.item.columnImportance'
  )}
          }
        ],
        "total": ${i18n.t('serviceDetail.sampleCode.responses.item.total')},
        "feature_importance": {
          "shap_values": [
            [${i18n.t('serviceDetail.sampleCode.responses.item.shapValues')}]
          ],
          "x_test": [
            [${i18n.t('serviceDetail.sampleCode.responses.item.xTest')}]
          ],
          "input_columns": ["${i18n.t(
            'serviceDetail.sampleCode.responses.item.inputColumns'
          )}"]
        },
        "mean_shap": {
          "${i18n.t(
            'serviceDetail.sampleCode.responses.item.columnName'
          )}": ${i18n.t('serviceDetail.sampleCode.responses.item.meanShap')}
        }
      }
    ]
  }`,
  time: `{
    "inferenceType":"${i18n.t(
      'serviceDetail.sampleCode.responses.item.inferenceType'
    )}",
    "id":"${i18n.t('serviceDetail.sampleCode.responses.item.id')}",
    "list": [
      {
        "forecast":[
          {
            "ds":${i18n.t(
              'serviceDetail.sampleCode.responses.item.forecastDate'
            )},
            "yhat":${i18n.t(
              'serviceDetail.sampleCode.responses.item.forecastValue'
            )},
            "yhat_lower":${i18n.t(
              'serviceDetail.sampleCode.responses.item.forecastValue'
            )}${i18n.t('serviceDetail.sampleCode.responses.item.lowerLimit')},
            "yhat_upper":${i18n.t(
              'serviceDetail.sampleCode.responses.item.forecastValue'
            )}${i18n.t('serviceDetail.sampleCode.responses.item.upperLimit')},
            "trend":${i18n.t('serviceDetail.sampleCode.responses.item.trend')}
            "trend_lower":${i18n.t(
              'serviceDetail.sampleCode.responses.item.trend'
            )}${i18n.t('serviceDetail.sampleCode.responses.item.lowerLimit')},
            "trend_upper":${i18n.t(
              'serviceDetail.sampleCode.responses.item.trend'
            )}${i18n.t('serviceDetail.sampleCode.responses.item.upperLimit')},
            "additive_terms":${i18n.t(
              'serviceDetail.sampleCode.responses.item.additive'
            )}
            "additive_terms_lower":${i18n.t(
              'serviceDetail.sampleCode.responses.item.additive'
            )}${i18n.t('serviceDetail.sampleCode.responses.item.lowerLimit')},
            "additive_terms_upper":${i18n.t(
              'serviceDetail.sampleCode.responses.item.additive'
            )}${i18n.t('serviceDetail.sampleCode.responses.item.upperLimit')},
            "multiplicative_terms":${i18n.t(
              'serviceDetail.sampleCode.responses.item.multiplicative'
            )},
            "multiplicative_terms_lower":${i18n.t(
              'serviceDetail.sampleCode.responses.item.multiplicative'
            )}${i18n.t('serviceDetail.sampleCode.responses.item.lowerLimit')},
            "multiplicative_terms_upper":${i18n.t(
              'serviceDetail.sampleCode.responses.item.multiplicative'
            )}${i18n.t('serviceDetail.sampleCode.responses.item.upperLimit')},
            "weekly":${i18n.t(
              'serviceDetail.sampleCode.responses.item.others'
            )},
            "weekly_lower":${i18n.t(
              'serviceDetail.sampleCode.responses.item.others'
            )}${i18n.t('serviceDetail.sampleCode.responses.item.lowerLimit')},
            "weekly_upper":${i18n.t(
              'serviceDetail.sampleCode.responses.item.others'
            )}${i18n.t('serviceDetail.sampleCode.responses.item.upperLimit')}
          }
        ],
        "coloumnName": "${i18n.t('common.columnName')}"
      }
    ]
  }`,
  timeTransformer: `{
    "inferenceType":"${i18n.t(
      'serviceDetail.sampleCode.responses.item.inferenceType'
    )}",
    "id":"${i18n.t('serviceDetail.sampleCode.responses.item.id')}",
    "forecast":[
      {
        "ds":${i18n.t('serviceDetail.sampleCode.responses.item.forecastDate')},
        "yhat":${i18n.t(
          'serviceDetail.sampleCode.responses.item.forecastValue'
        )},
        "y":${i18n.t('serviceDetail.sampleCode.responses.item.observedValue')}
      }
    ]
  }`,
  timeTransformerV2: `{
    "inferenceType":"${i18n.t(
      'serviceDetail.sampleCode.responses.item.inferenceType'
    )}",
    "id":"${i18n.t('serviceDetail.sampleCode.responses.item.id')}",
    "list": [
      {
        "forecast":[
          {
            "ds":${i18n.t(
              'serviceDetail.sampleCode.responses.item.forecastDate'
            )},
            "y":${i18n.t(
              'serviceDetail.sampleCode.responses.item.observedValue'
            )},
            "yhat":${i18n.t(
              'serviceDetail.sampleCode.responses.item.forecastValue'
            )},
            "yhat_lower":${i18n.t(
              'serviceDetail.sampleCode.responses.item.forecastValue'
            )}${i18n.t('serviceDetail.sampleCode.responses.item.lowerLimit')},
            "yhat_upper":${i18n.t(
              'serviceDetail.sampleCode.responses.item.forecastValue'
            )}${i18n.t('serviceDetail.sampleCode.responses.item.upperLimit')},
          }
        ],
        "coloumnName": "${i18n.t('common.columnName')}"
      }
    ]
  }`
}
