<!-- 将来的に仕様変更したときとかのために、学習に使用するものがエラーとかになったらこのポップアップを出す -->
<template>
  <div class="train-error-wrap">
    <div class="train-error-inner">
      <texts :text="errorText" class="train-error-text" />
    </div>
    <div class="train-error-button-list">
      <button-main
        :text="buttonText"
        type="emphasis"
        @click="$emit('close-modal', 'trainSettingError')"
      />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import ButtonMain from '@/components/atoms/button-main'

export default {
  components: {
    texts,
    ButtonMain
  },
  props: {
    type: String
  },
  computed: {
    // 他にもなにか学習に使用するものが壊れているときはここで分岐させる
    errorText() {
      if (this.type === 'preprocessing') {
        return this.$t(
          'training.popup.trainSettingError.inPreprocessingDataset.desc'
        )
      } else {
        return null
      }
    },
    buttonText() {
      if (this.type === 'preprocessing') {
        return this.$t(
          'training.popup.trainSettingError.inPreprocessingDataset.button'
        )
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.train-error {
  &-wrap {
    width: 100%;
  }
  &-inner {
    margin: 0 0 $space-medium;
  }
  &-button-list {
    width: 100%;
  }
  &-text {
    white-space: pre-line;
  }
}
</style>
