<template>
  <div class="no-training-data-wrap">
    <div class="no-training-data-inner">
      <texts
        class="no-training-data-inner-text"
        :text="$t('common.popup.preventTrainingStatus.cautionText')"
        color="caution"
      />
      <texts
        class="no-training-data-inner-text"
        :text="$t('common.popup.preventTrainingStatus.subText')"
      />
    </div>
    <div class="no-training-data-button-list">
      <button-main
        :text="$t('common.popup.preventTrainingStatus.button')"
        type="emphasis"
        @click="goDatasetSetting"
      />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import ButtonMain from '@/components/atoms/button-main'
import { mapActions } from 'vuex'

export default {
  components: {
    texts,
    ButtonMain
  },
  computed: {
    projectId() {
      return parseInt(this.$route.params.projectId)
    }
  },
  methods: {
    ...mapActions('models', ['deleteProgressTraining', 'setProgressTraining']),

    goDatasetSetting() {
      this.$emit('not-prevent')

      this.deleteProgressTraining({ projectId: this.projectId })
      this.setProgressTraining({
        item: this.projectId,
        setType: 'project'
      })

      if (this.$route.name === 'datasetSetting') {
        this.$router.go()
      } else {
        this.$router.push({
          name: 'datasetSetting',
          params: {
            projectId: this.projectId
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.no-training-data {
  &-wrap {
    width: 100%;
  }
  &-inner {
    margin: 0 0 $space-medium;
    &-text {
      margin-bottom: $space-sub;
      white-space: pre-line;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &-button-list {
    width: 100%;
  }
}
</style>
