import { checkExpiredEducation } from './misc.js'

function recipeNumClassMatch() {
  if (!this.recipe) return null
  if (!this.trainingDataset) return null
  if (!this.trainingDataset.nClasses) return null
  const body = this.recipe.body
  if (!body) return null
  const layers = body.layers
  let nClass = null
  let isFinetuning = false
  layers.forEach((v) => {
    if (v.name === 'inputLabels') {
      nClass = v.params.nClass.value
    } else if (v.name === 'finetuning') {
      // ファインチューニング用のレシピの場合、ラベル入り口ブロックのラベル数によるチェックを行わない
      isFinetuning = true
    }
  })
  return nClass === this.trainingDataset.nClasses || isFinetuning
}

function checkTrendFlow(targetRecipe) {
  const layers = targetRecipe.body.layers
  return layers.some((layer) => {
    return layer.name === 'TrendFlow'
  })
}

function checkMfTransformerTrend(targetRecipe) {
  const layers = targetRecipe.body.layers
  return layers.some((layer) => {
    return layer.name === 'MfTransformerTrend'
  })
}

function checkMinMaxZero(describe, columnName) {
  const minZero = describe.some((item) => {
    return item['/'] === 'min' && item[columnName] === 0
  })
  const maxZero = describe.some((item) => {
    return item['/'] === 'max' && item[columnName] === 0
  })
  return minZero && maxZero
}

function checkBlocksLength(targetRecipe) {
  const layers = targetRecipe.body.layers
  const regTarget = /classification|regression|hr|auto/
  return layers.filter((layer) => {
    return layer.type.match(regTarget)
  }).length
}

function customblockConvertDataset(targetRecipe) {
  const layers = targetRecipe.body.layers
  const check = layers.some((layer) => {
    return layer.type === 'convert_dataset'
  })
  return check
}

// カスタムブロックの存在判定と、存在しないものを返却
function customblockExistCheck(targetRecipe, customblockList) {
  if (customblockList == null || customblockList.length === 0) return true
  const target = targetRecipe.body.layers.filter((layer) => {
    return layer.name === 'customblock'
  })
  if (target.length === 0) return true
  return target.every((layer) => {
    return customblockList.some((block) => {
      return block.customblock_id === layer.customblock_id
    })
  })
}

export function checkReasons(recipe) {
  let targetRecipe = null
  if (recipe) {
    targetRecipe = recipe
  } else {
    targetRecipe = this.recipe
  }
  const datasetType = this.selectedLearningDataType()
  const inputDataType = this.inputDataType(recipe)
  const columnType = this.validColumnCheck
  const recipeType = this.saveRecipeType
  const isTemplate = this.checkTemplate ? this.checkTemplate : false
  const res = []
  const checkTimeseriesResult = checkTrendFlow(targetRecipe)
  const checkCustomblockConvertDataset = customblockConvertDataset(targetRecipe)
  const checkCustomblockExist = customblockExistCheck(
    targetRecipe,
    this.customblockList
  )

  // データセット未選択
  if (!this.trainingDataset) {
    res.push({
      errorDesc: this.$t('recipeDetail.selectRecipeErrors.noneDataset')
    })
    return res
  }
  // エデュケーションプランの時間超過
  if (targetRecipe && !isTemplate && checkExpiredEducation(this.accountInfo)) {
    res.push({
      errorDesc: this.$t(
        'recipeDetail.selectRecipeErrors.exceededEducationPlanTime'
      )
    })
    return res
  }
  // データ入り口と入力データのtypeが相違
  if (datasetType !== inputDataType) {
    res.push({
      errorDesc: this.$t('recipeDetail.selectRecipeErrors.typeMismatch', {
        recipeType: this.$t('recipeDetail.dataType.' + inputDataType),
        dataType: this.$t('recipeDetail.dataType.' + datasetType)
      })
    })
    return res
  }
  // 画像でクラス数の不一致
  if (
    inputDataType === 'images' &&
    recipeNumClassMatch.bind(this)() === false
  ) {
    res.push({
      errorDesc: this.$t('recipeDetail.selectRecipeErrors.nClassesMismatch')
    })
    return res
  }
  // 存在しないカスタムブロックを利用
  if (!checkCustomblockExist) {
    res.push({
      errorDesc: this.$t('recipeDetail.selectRecipeErrors.customblockDeleted')
    })
    return res
  }
  if (columnType.length > 0) {
    const listError = columnType.filter((column) => {
      return !column.valid
    })

    // エラーがある場合の処理
    if (listError.length > 0) {
      const onlynumColumns = []
      const nullColumns = []
      const multiValueClassificationColumns = []
      const classificationIntColumns = []
      const withoutNegativeValue = []

      for (const error of listError) {
        if (
          error.reasons.includes('onlynum') &&
          !checkCustomblockConvertDataset
        ) {
          onlynumColumns.push(error.columnName)
        }
        if (error.reasons.includes('multi_value')) {
          multiValueClassificationColumns.push(error.columnName)
        }

        if (
          error.reasons.includes('null') &&
          !(recipeType && recipeType === 'time')
        ) {
          nullColumns.push(error.columnName)
        }

        if (
          error.reasons.includes('classification_int') &&
          !(recipeType && recipeType !== 'classification') &&
          !checkCustomblockConvertDataset
        ) {
          classificationIntColumns.push(error.columnName)
        }

        if (error.reasons.includes('without_negative_value')) {
          withoutNegativeValue.push(error.columnName)
        }
      }

      // 文字列は使用不可
      if (onlynumColumns.length > 0) {
        res.push({
          errorDesc: this.$t(
            'recipeDetail.selectRecipeErrors.multiColumnOnlynum'
          ),
          columns: onlynumColumns
        })
      }
      // 欠損値がある場合使用不可 時系列の場合は許容
      if (nullColumns.length > 0) {
        res.push({
          errorDesc: this.$t('recipeDetail.selectRecipeErrors.multiColumnNull'),
          columns: nullColumns
        })
      }
      // 分類に使用できる予測する列は整数値のみ。欠損値がある場合もこれになるので注意書きを入れる
      if (classificationIntColumns.length > 0) {
        res.push({
          errorDesc: this.$t(
            'recipeDetail.selectRecipeErrors.multiColumnClassificationInt'
          ),
          columns: classificationIntColumns
        })
      }
      // 多値分類の場合は、正解率のみ使える
      if (multiValueClassificationColumns.length > 0) {
        res.push({
          errorDesc: this.$t(
            'recipeDetail.selectRecipeErrors.multiValueClassificationMetrics'
          )
        })
      }

      if (withoutNegativeValue.length > 0) {
        res.push({
          errorDesc: this.$t(
            'recipeDetail.selectRecipeErrors.withoutNegativeValue'
          ),
          columns: withoutNegativeValue
        })
      }
    }
    // 時系列の場合、全て0の値の列を予測することができないことの説明
    if (checkTimeseriesResult) {
      const checkMinMax = []
      const describe = this.trainingDataset.describe
      columnType.forEach((column) => {
        const check = checkMinMaxZero(describe, column.columnName)
        if (check) checkMinMax.push(column.columnName)
      })
      if (checkMinMax.length > 0) {
        res.push({
          errorDesc: this.$t(
            'selectTargetColumn.datasetInfo.list.caution.trendflowSingularError'
          ),
          columns: checkMinMax
        })
      }
    }
    // 複数の予測する列を設定している場合の処理
    if (columnType.length > 1) {
      // 複数の予測する列の学習では単一ブロックのみ許容する
      if (checkBlocksLength(targetRecipe) > 1) {
        res.push({
          errorDesc: this.$t(
            'recipeDetail.selectRecipeErrors.multiColumnMultiBlocks'
          )
        })
      }
      // MFTransformer以外で複数の予測する列を使用
      // TODO https://trello.com/c/M5sUoCVW 使用できるように改修予定
      if (checkMfTransformerTrend(targetRecipe)) {
        res.push({
          errorDesc: this.$t(
            'recipeDetail.selectRecipeErrors.multiColumnMFTransformer'
          )
        })
      }
    }
  }
  return res
}
