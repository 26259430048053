<template>
  <popup-array
    :popupDefs="popupDefs"
    :showPopup="showPopup"
    :noClose="disableClick || !inTurorialLast"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #cloneProject>
      <popup-clone
        :project="cloneProject.project"
        :disableClick="disableClick"
        @cancel="$emit('close-modal', 'cloneProject')"
        @clone-project="$emit('clone-project', $event)"
        @close-modal="$emit('close-modal', $event)"
      />
    </template>
    <template #createTemplate>
      <popup-template
        :project="createTemplate.project"
        :disableClick="disableClick"
        @cancel="$emit('close-modal', 'createTemplate')"
        @create-template="$emit('create-template', $event)"
        @close-modal="$emit('close-modal', $event)"
      />
    </template>
    <template #deleteProject>
      <project-delete-confirm
        :project="deleteProject.project"
        :disableClick="disableClick"
        @close-modal="$emit('close-modal', 'deleteProject')"
        @delete-project="$emit('delete-project', $event)"
      />
    </template>
    <template #overTrainedAIs>
      <over-trained-ais @close-modal="$emit('close-modal', 'overTrainedAIs')" />
    </template>
    <template #tutorialMigrationProject>
      <tutorial-migration-project
        @close-modal="$emit('close-modal', 'tutorialMigrationProject')"
        @in-last-page="changePage($event)"
      />
    </template>
    <template #stopTask>
      <stop-task
        :task="stopTask"
        @training-stop-confirm="$emit('training-stop-confirm', stopTask)"
        @close-modal="$emit('close-modal', 'stopTask')"
      />
    </template>
  </popup-array>
</template>

<script>
import { popupArray } from '@/components/molecules/popup'
import PopupClone from './popup/popup-clone.vue'
import PopupTemplate from './popup/popup-template.vue'
import ProjectDeleteConfirm from './popup/project-delete-confirm.vue'
import overTrainedAis from './popup/over-trained-ais'
import tutorialMigrationProject from './popup/tutorial-migration-project'
import stopTask from '@/components/molecules/popup/training-stop.vue'

export default {
  components: {
    popupArray,
    PopupClone,
    PopupTemplate,
    ProjectDeleteConfirm,
    overTrainedAis,
    tutorialMigrationProject,
    stopTask
  },
  data() {
    return {
      inTurorialLast: false
    }
  },
  computed: {
    popupDefs() {
      return {
        cloneProject: {
          title: this.$t('projectList.cloneProject')
        },
        createTemplate: {
          title: this.$t('projectList.createTemplate')
        },
        deleteProject: {
          title: this.$t('common.deleteConfirm')
        },
        overTrainedAIs: {
          title: this.$t('projectList.popup.overTrainedAis.title')
        },
        tutorialMigrationProject: {
          title: this.$t('projectList.popup.tutorialMigration')
        },
        stopTask: {
          title: this.$t('training.button.stopTraining')
        }
      }
    }
  },
  methods: {
    changePage(bool) {
      this.inTurorialLast = bool
    }
  },
  props: {
    showPopup: Array,
    cloneProject: Object,
    createTemplate: Object,
    deleteProject: Object,
    disableClick: Boolean,

    stopTask: Object
  },
  watch: {
    showPopup(newVal) {
      this.inTurorialLast = !newVal.includes('tutorialMigrationProject')
    }
  }
}
</script>
