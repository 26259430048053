import { duplicateChecker, duplicateSuggests } from './misc'

/**
 * @param { Object } projects
 * @param { Object } newProject
 * @returns { Object }
 **/
export function projectValidator(projects, newProject) {
  const projectKeys = Object.keys(projects)

  const nameList =
    projectKeys.length > 0 ? projectKeys.map((key) => projects[key].name) : []
  const newProjectName = newProject.name

  const checkResult = duplicateChecker(nameList, newProjectName)
  let suggests = []
  if (!checkResult) {
    suggests = duplicateSuggests(nameList, newProjectName)
  }

  return {
    duplicate: checkResult,
    suggests: suggests
  }
}
