<template>
  <div class="service-list-wrap">
    <div class="service-list-top">
      <service-list-top
        :activeTab="activeTab"
        :accountInfo="accountInfo"
        :accountInfoLoading="accountInfoLoading"
        :deleteTest="deleteTest"
        :serviceListFiltered="serviceListFiltered"
        :checked="checked"
        @close-delete-popup="$emit('close-delete-popup')"
        @cancel-deleted="$emit('cancel-deleted')"
        @menu-click="menuClick($event)"
        @tab-changed="changeTab($event)"
        @input="search($event)"
      />
    </div>
    <div class="service-list-table">
      <transition-toggle-contents>
        <tab-infer
          v-if="activeTab == 'infer'"
          v-model="checked"
          :option="option"
          :projectId="projectId"
          :services="serviceList"
          :servicesLoading="servicesLoading"
          @add-new-service="
            $emit('add-new-service', {
              type: 'infer'
            })
          "
        />
        <tab-learning
          v-if="activeTab == 'learn'"
          v-model="checked"
          :option="option"
          :projectId="projectId"
          :services="serviceList"
          :servicesLoading="servicesLoading"
          @add-new-service="
            $emit('add-new-service', {
              type: 'learn'
            })
          "
        />
        <tab-upload
          v-if="activeTab == 'upload'"
          v-model="checked"
          :option="option"
          :projectId="projectId"
          :servicesList="serviceList"
          :servicesLoading="servicesLoading"
          @add-new-service="
            $emit('add-new-service', {
              type: 'upload'
            })
          "
        />
        <tab-optimization
          v-if="activeTab == 'optimization'"
          v-model="checked"
          :option="option"
          :projectId="projectId"
          :services="serviceList"
          :servicesLoading="servicesLoading"
          @add-new-service="
            $emit('add-new-service', {
              type: 'optimization'
            })
          "
        />
      </transition-toggle-contents>
    </div>
  </div>
</template>

<script>
import serviceListTop from './service-list-top'
import TabInfer from './tabs/infer.vue'
import TabLearning from './tabs/learning.vue'
import TabUpload from './tabs/upload.vue'
import TabOptimization from './tabs/optimization.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

export default {
  components: {
    serviceListTop,
    TabInfer,
    TabLearning,
    TabUpload,
    TabOptimization,
    transitionToggleContents
  },
  data() {
    return {
      checked: [],
      searchArray: null,
      noSearchResults: false,
      option: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.3,
        keys: [
          {
            name: 'name',
            weight: 0.5
          },
          {
            name: 'serviceOwner',
            weight: 0.5
          }
        ],
        distance: 10000
      }
    }
  },
  props: {
    activeTab: String,
    accountInfo: Object,
    accountInfoLoading: Boolean,

    serviceList: Array,
    servicesLoading: Boolean,

    pageBody: Object,
    deleteTest: Object,
    projectId: Number
  },
  methods: {
    search(e) {
      this.searchArray = e.result
      this.noSearchResults = e.noSearchResults
    },
    changeTab(e) {
      this.checked = []
      this.$emit('tab-changed', e)
    },
    menuClick(e) {
      this.$emit('menu-click', {
        name: e.name,
        checked: e.checked,
        type: this.activeTab
      })
    }
  },
  computed: {
    serviceListFiltered() {
      if (!this.searchArray) {
        return this.serviceList
      }
      return this.searchArray
    }
  },
  watch: {
    serviceList() {
      this.checked = []
    }
  }
}
</script>

<style lang="scss" scoped>
.service-list-wrap {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: $space-base;
  height: 100vh - adjustVH($headerTabHeight);
  padding: 0 $space-medium $space-medium;
}

.service-list-table {
  overflow: hidden;
  border-radius: adjustVW(16);
  box-shadow: $box-shadow-main;
}
</style>
