<template>
  <project-detail
    v-bind="data"
    :popup="popup"
    :projectInfo="projectInfo"
    :projectLoading="projectLoading"
    :sortedTrainedAi="sortedTrainedAi"
    :accountInfo="accountInfo"
    :disableClick="disableClick"
    :rowsPerPage="rowsPerPage"
    :runningTasks="runningTasks"
    @reenter-click="restartLearning"
    @change-description="changeDescription"
    @close-modal="closePopup($event)"
    @delete-project="openDeleteProject"
    @delete-project-confirm="deleteProjectConfirm"
    @input-edit-form="inputEditForm($event)"
    @tag-add="tagAdd"
    @tag-remove="tagRemove"
    @change-column="changeColumn"
    @load-optimization-result="loadOptimizationResult"
    @change-page="changePageOptimization"
    @change-filter-value="changeFilterValue"
    @download-result="downloadResultOptimization"
    @go-text-mining="goTextMining"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { projectValidator } from '@/lib/validator/project.js'
import { restartTraining } from '@/lib/progress-training'
import setMountedTimer from '@/mixin/set-mounted-timer'

import projectDetail from '@/components/templates/project-detail.vue'

export default {
  components: {
    projectDetail
  },
  mixins: [setMountedTimer],
  computed: {
    ...mapGetters('tasks', ['getProjectTasks']),
    ...mapGetters('project', ['projectList', 'projectLoading']),
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('settings', ['rowsPerPage']),

    projectId() {
      return parseInt(this.$route.params.projectId)
    },
    projectInfo() {
      if (this.projectId) {
        return this.projectList[this.projectId]
      }
      return null
    },
    disableClick() {
      return (
        this.submitDeleting ||
        this.data.optimizationInfo.download.downloadLoading ||
        this.data.loading.deleteItemloading
      )
    },
    runningTasks() {
      const tasks = this.getProjectTasks(this.projectId)
      const fixTasks = JSON.parse(JSON.stringify(tasks))
      return fixTasks.filter((item) => {
        return !item?.isDifferentOptimization && item.status !== 'FAILED'
      })
    }
    // listAIs内の学習済みAIの中で最も最近作成したものの詳細
    // listAIsが空のしかできなかったので、紐づけが終わったら実装
    // structured 20200915060950 tdual
    // gazou 20200721064648 YumaKigami
    // text 20200929044019 okada_demotest
  },
  methods: {
    ...mapActions('project', ['addTag', 'removeTag', 'updateProject', 'deleteProject', 'loadProjectDetail']),
    ...mapActions('tasks', ['getTasks']),
    ...mapActions('trainedAi', ['fetchTrainedAiDetail', 'fetchOptimizationResultById', 'fetchOptimizationResult', 'downloadOptimizationResult']),

    async restartLearning(progressTraining) {
      await this.getTasks()
      if (this.runningTasks.length > 0) {
        // TODO: 複数タスクが実行できるようになったら対応
        this.$gtmDataLayer.sendEvent(
          'プロジェクトページ - 進行中の学習に戻るボタンクリック'
        )
        const item = this.runningTasks[0]
        const query = {}
        if (item?.isOptimization) {
          query.isOptimization = true
        }

        if (!item?.isDifferentOptimization) {
          this.$router.push({
            name: 'trainingTask',
            params: {
              projectId: item.projectId,
              taskId: item.jobId
            },
            query: { ...query }
          })
        }
      } else {
        restartTraining(progressTraining)
      }
    },
    async loadTrainedAiDetail(id) {
      if (!id) return
      const res = await this.fetchTrainedAiDetail({
        id,
        columnIndex: this.data.selectedColumnIndex
      })

      this.sortedTrainedAi = res
    },
    sortTrainedAi() {
      if (this.projectInfo?.listAIs == null) return
      if (this.projectInfo.listAIs.length === 0) {
        this.sortedTrainedAiId = null
        return
      }
      const tranindAis = this.projectInfo.listAIs
      tranindAis.sort((x, y) =>
        x.createTime < y.createTime ? 1 : x.createTime > y.createTime ? -1 : 0
      )
      this.sortedTrainedAiId = tranindAis[0]?.id
    },
    tagAdd(tag) {
      if (this.projectId) {
        this.addTag({ projectId: this.projectId, tag })
      }
    },
    tagRemove(tag) {
      if (this.projectId) {
        this.removeTag({ projectId: this.projectId, tag })
      }
    },
    async changeDescription({ name, description }) {
      if (this.projectId) {
        this.data.loading.editLoading = true
        await this.updateProject({
          projectId: this.projectId,
          name,
          description
        })
        this.data.loading.editLoading = false
      }
    },
    async openDeleteProject() {
      // load services
      const projectId = this.projectInfo.id
      const deleteTarget = this.popup.deleteProject
      this.data.loading.deleteItemloading = true
      if (
        !deleteTarget.project ||
        Object.keys(deleteTarget.project).length === 0
      ) {
        deleteTarget.project = { ...this.projectInfo }
        const serviceList = await Promise.all([
          this.$sendMessageAndReceive({
            action: 'listServicev2',
            serviceType: 'infer',
            projectId: projectId
          }).then((res) =>
            res.list.map((item) => ({ ...item, serviceType: 'infer' }))
          ),
          this.$sendMessageAndReceive({
            action: 'listServicev2',
            serviceType: 'learn',
            projectId: projectId
          }).then((res) =>
            res.list.map((item) => ({ ...item, serviceType: 'learn' }))
          ),
          this.$sendMessageAndReceive({
            action: 'listServicev2',
            serviceType: 'upload',
            projectId: projectId
          }).then((res) =>
            res.list.map((item) => ({ ...item, serviceType: 'upload' }))
          )
        ])
        deleteTarget.project.listServices = [].concat(...serviceList)
      }

      this.data.loading.deleteItemloading = false
      this.showPopup('deleteProject')
    },
    async deleteProjectConfirm() {
      this.submitDeleting = true
      try {
        if (this.projectId) {
          await this.deleteProject(parseInt(this.projectId))
        }
      } finally {
        this.submitDeleting = false
      }
      this.$router.push({ name: 'projectList' })
    },
    showPopup(e) {
      // ポップアップを表示
      if (this.popup.showPopup.length > 0) {
        this.popup.showPopup = []
        this.popup.showPopup.push(e)
      } else {
        this.popup.showPopup.push(e)
      }
    },
    closePopup(e) {
      // ポップアップを閉じる
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
    },
    inputEditForm(obj) {
      const { type, form } = obj

      if (type === 'project') {
        const projectIds = Object.keys(this.projectList)
        const projects = projectIds
          .filter((projectId) => parseInt(projectId) !== this.projectId)
          .reduce((result, id) => {
            result[id] = this.projectList[id]
            return result
          }, {})

        this.data.editInfo.projectFormValidate = projectValidator(
          projects,
          form
        )
      }
    },
    async changeColumn(column) {
      const index =
        this.sortedTrainedAi.trainConfig.predictionColumn.indexOf(column)
      if (this.data.selectedColumnIndex === index) return
      if (index === -1) return

      this.data.selectedColumnIndex = index

      this.data.loading.changeColumn = true
      const res = await this.fetchTrainedAiDetail({
        id: this.sortedTrainedAiId,
        columnIndex: this.data.selectedColumnIndex
      })
      this.sortedTrainedAi = res
      this.data.loading.changeColumn = false
    },
    async loadOptimizationResult(id, config) {
      this.data.optimizationInfo.download.downloadComp = false
      if (this.data.optimizationInfo.loadOptimizationDetail) return

      if (!config) {
        this.data.optimizationInfo.loadOptimizationDetail = true
      } else {
        this.data.optimizationInfo.loadOptimizationPaging = true
      }

      this.data.optimizationResult.optimizationId = id

      let payload = {
        trainedAiId: this.sortedTrainedAiId,
        optimizationConditionsId: this.data.optimizationResult.optimizationId
      }

      if (config) {
        this.data.optimizationResult.filters = config.filters
        this.data.optimizationResult.inPageNumber = config.inPageNumber

        payload = {
          ...payload,
          limit: this.data.optimizationResult.limit,
          resultRanges: this.data.optimizationResult.filters,
          offset:
            (this.data.optimizationResult.inPageNumber - 1) *
            this.data.optimizationResult.limit
        }
      }

      const res = await this.fetchOptimizationResult(payload)

      if (!config) {
        this.data.optimizationResult.columnRanges = res.columnRanges
      }
      this.data.optimizationResult.result = res.result
      this.data.optimizationResult.total = res.total

      this.data.optimizationInfo.loadOptimizationDetail = false
      this.data.optimizationInfo.loadOptimizationPaging = false
    },
    async changePageOptimization({ id, page }) {
      const config = {
        inPageNumber: page,
        filters: this.data.optimizationResult.filters
      }
      await this.loadOptimizationResult(id, config)
    },
    async changeFilterValue({ id, filter }) {
      const filters = this.data.optimizationResult.filters
      const checkSettedFilter = filters.findIndex((item) => {
        return item.name === filter.name
      })

      if (checkSettedFilter >= 0) {
        filters[checkSettedFilter] = filter
      } else {
        filters.push(filter)
      }

      const config = {
        inPageNumber: 1,
        filters: filters
      }
      await this.loadOptimizationResult(id, config)
    },
    async downloadResultOptimization(encoding) {
      if (this.data.optimizationInfo.download.downloading) return
      this.data.optimizationInfo.download.downloading = true
      this.data.optimizationInfo.download.downloadComp = false

      // すぐにダウンロードできるものは、黒い背景が一瞬しか見えないので、setTimeoutを入れている
      this.timeoutDownload = window.setTimeout(
        function () {
          this.data.optimizationInfo.download.downloadLoading = true
        }.bind(this),
        100
      )

      const payload = {
        trainedAiId: this.sortedTrainedAiId,
        optimizationConditionsId: this.data.optimizationResult.optimizationId,
        encoding: encoding
      }

      try {
        this.downloadOptimizationResult(payload)
      } finally {
        this.data.optimizationInfo.download.downloading = false
        window.clearTimeout(this.timeoutDownload)
        this.data.optimizationInfo.download.downloadLoading = false
        this.data.optimizationInfo.download.downloadComp = true
      }
    },
    goTextMining(pageType) {
      this.$router.push({
        name: 'trainedAiProjectDetail',
        params: {
          projectId: this.projectId,
          id: this.sortedTrainedAiId,
          textMiningType: pageType
        },
        props: {
          textMiningType: pageType
        }
      })
    }
  },
  data() {
    return {
      sortedTrainedAi: null,
      sortedTrainedAiId: null,
      data: {
        sidebar: {
          project: {
            name: '退職予測',
            description:
              'このプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入ります'
          },
          activeLink: 'dashboard'
        },
        headerTabs: {
          tabs: [],
          tabSelect: 1
        },
        editInfo: {
          projectFormValidate: {
            duplicate: undefined
          }
        },
        loading: {
          pageLoading: false,
          editLoading: false,
          changeColumn: false,
          deleteItemloading: false
        },
        selectedColumnIndex: 0,
        optimizationInfo: {
          loadOptimizationDetail: false,
          loadOptimizationPaging: false,
          download: {
            downloading: false,
            downloadLoading: false,
            downloadComp: false
          }
        },
        optimizationResult: {
          optimizationId: null,
          result: {},
          columnRanges: [],
          filters: [],
          limit: 50,
          total: 0,
          inPageNumber: 1
        }
      },
      popup: {
        showPopup: [],
        deleteProject: {
          project: null
        }
      },
      submitDeleting: false
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.data.loading.pageLoading = true
      await vm.loadProjectDetail(vm.projectId)
      await vm.sortTrainedAi()
      await vm.getTasks()
      if (vm.sortedTrainedAiId) {
        vm.loadTrainedAiDetail(vm.sortedTrainedAiId)
      }
    })
  }
}
</script>
