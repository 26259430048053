<template>
  <cardBase class="organization-member-card">
    <texts
      class="organization-first"
      size="large"
      :text="$t('organizationMember.info.title')"
    />
    <listItem
      class="organization-list"
      :field="field"
      :items="organizationInfo"
      nonIcon
    />
  </cardBase>
</template>

<script>
import cardBase from '@/components/atoms/card-base.vue'
import texts from '@/components/atoms/text.vue'

import listItem from '@/components/molecules/list-item.vue'

export default {
  components: {
    cardBase,
    listItem,
    texts
  },
  computed: {
    organizationInfo() {
      const data = Object.values(this.accountInfo.organizationInfo.members)
      return data.sort(function (a) {
        if (a.role === 'admin') {
          return -1
        }
        return data
      })
    },
    field() {
      return [
        {
          id: 0,
          key: 'accountId',
          label: this.$t('organizationMember.info.fields.accountId')
        },
        {
          id: 1,
          key: 'name',
          label: this.$t('organizationMember.info.fields.nickName')
        },
        {
          id: 2,
          key: 'role',
          label: this.$t('organizationMember.info.fields.role')
        },
        {
          id: 3,
          key: 'auth_services',
          label: this.$t('organizationMember.info.fields.auth_services')
        },
        {
          id: 4,
          key: 'email',
          label: this.$t('organizationMember.info.fields.email')
        }
      ]
    }
  },
  props: {
    accountInfo: Object
  }
}
</script>

<style lang="scss" scoped>
.organization {
  &-member-card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-first {
    margin-bottom: $space-small;
  }
  &-list {
    overflow-y: auto;
    @include scrollbar;
  }
}
</style>
