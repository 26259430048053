var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"delete-project-wrap"},[_c('div',{staticClass:"delete-project-list-wrap"},[(_vm.projectInfo)?_c('div',{staticClass:"delete-project-item"},[_c('texts',{attrs:{"text":_vm.projectInfo.name,"size":"large"}}),_c('div',{staticClass:"delete-project-item-block"},[_c('description',{attrs:{"text":_vm.projectInfo.description}})],1),_c('div',{staticClass:"delete-project-item-block"},[_c('div',{staticClass:"delete-project-item-inner"},[(_vm.projectInfo.sourceTemplate)?_c('text-with-title',{staticClass:"top-detail-template",attrs:{"title":_vm.$t('projectDetail.templateUsed'),"text":_vm.projectInfo.sourceTemplate.name}}):_c('text-with-title',{staticClass:"top-detail-template",attrs:{"title":_vm.$t('projectDetail.templateUsed'),"text":_vm.$t('projectDetail.nonUseTemplate')}})],1)]),(_vm.projectInfo.numAIs > 0 || _vm.projectInfo.numData > 0)?_c('div',{staticClass:"delete-project-item-block"},[(_vm.projectInfo.numAIs > 0)?_c('div',{staticClass:"delete-project-item-inner"},[_c('div',{staticClass:"delete-project-item-title"},[_c('texts',{attrs:{"text":_vm.$t('projectDetail.popup.delete.trainedAis'),"size":"small","color":"gray"}})],1),_c('div',{staticClass:"delete-project-item-list"},_vm._l((_vm.limitCount(_vm.projectInfo.listAIs)),function(item,index){return _c('div',{key:index,staticClass:"delete-project-item-list-item"},[_c('router-link',{attrs:{"to":{
                  name: 'trainedAiProjectDetail',
                  params: { projectId: _vm.$route.params.projectId, id: item.id }
                }}},[_c('text-with-icon',{attrs:{"text":item.name,"iconName":"newTab","pos":"right","color":"link-default"}})],1)],1)}),0)]):_vm._e(),(_vm.projectInfo.numData > 0)?_c('div',{staticClass:"delete-project-item-inner"},[_c('div',{staticClass:"delete-project-item-title"},[_c('texts',{attrs:{"text":_vm.$t('projectDetail.popup.delete.dataset'),"size":"small","color":"gray"}})],1),_c('div',{staticClass:"delete-project-item-list"},_vm._l((_vm.limitCount(_vm.projectInfo.listData)),function(item,index){return _c('div',{key:index,staticClass:"delete-project-item-list-item"},[_c('router-link',{attrs:{"to":{
                  name: 'datasetProjectDetail',
                  params: { projectId: _vm.$route.params.projectId, id: item.id }
                }}},[_c('text-with-icon',{attrs:{"text":item.name,"iconName":"newTab","pos":"right","color":"link-default"}})],1)],1)}),0)]):_vm._e()]):_vm._e(),(_vm.projectInfo.nServices.total > 0 || _vm.projectInfo.numRecipe > 0)?_c('div',{staticClass:"delete-project-item-block"},[(_vm.projectInfo.nServices.total > 0)?_c('div',{staticClass:"delete-project-item-inner"},[_c('div',{staticClass:"delete-project-item-title"},[_c('texts',{attrs:{"text":_vm.$t('projectDetail.popup.delete.service'),"size":"small","color":"gray"}})],1),_c('div',{staticClass:"delete-project-item-list"},_vm._l((_vm.limitCount(_vm.projectInfo.listServices)),function(item,index){return _c('div',{key:index,staticClass:"delete-project-item-list-item"},[_c('router-link',{attrs:{"to":{
                  name: 'serviceProjectDetail',
                  params: {
                    serviceType: item.serviceType,
                    projectId: _vm.$route.params.projectId,
                    id: item.id
                  }
                }}},[_c('text-with-icon',{attrs:{"text":item.name,"iconName":"newTab","pos":"right","color":"link-default"}})],1)],1)}),0)]):_vm._e(),(_vm.projectInfo.numRecipe > 0)?_c('div',{staticClass:"delete-project-item-inner"},[_c('div',{staticClass:"delete-project-item-title"},[_c('texts',{attrs:{"text":_vm.$t('projectDetail.popup.delete.recipe'),"size":"small","color":"gray"}})],1),_c('div',{staticClass:"delete-project-item-list"},_vm._l((_vm.limitCount(_vm.projectInfo.listRecipe)),function(item,index){return _c('div',{key:index,staticClass:"delete-project-item-list-item"},[_c('router-link',{attrs:{"to":{
                  name: 'recipeProjectDetail',
                  params: {
                    projectId: _vm.$route.params.projectId,
                    id: item.id + '-' + item.accountId
                  }
                }}},[_c('text-with-icon',{attrs:{"text":item.name,"iconName":"newTab","pos":"right","color":"link-default"}})],1)],1)}),0)]):_vm._e()]):_vm._e(),_c('div',{staticClass:"delete-project-item-block"},[_c('div',{staticClass:"delete-project-item-inner"},[_c('text-with-title',{attrs:{"title":_vm.$t('common.createTime')}},[_c('a-time',{attrs:{"value":_vm.projectInfo.createTime}})],1)],1),_c('div',{staticClass:"delete-project-item-inner"},[_c('text-with-title',{attrs:{"title":_vm.$t('common.updateTime')}},[_c('a-time',{attrs:{"value":_vm.projectInfo.updateTime}})],1)],1)])],1):_vm._e()]),_c('div',{staticClass:"delete-project-button-wrap"},[_c('div',{staticClass:"delete-project-button-inner"},[_c('button-main',{attrs:{"text":_vm.$t('common.cancel'),"type":"sub","disabled":_vm.disableClick},on:{"click":function($event){return _vm.$emit('close-modal')}}})],1),_c('div',{staticClass:"delete-project-button-inner"},[_c('button-main',{attrs:{"text":_vm.$t('common.deleteButton'),"type":"caution","disabled":_vm.disableClick},on:{"click":function($event){return _vm.$emit('delete-project-confirm')}}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }