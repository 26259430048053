<!-- Slotで入れたい特殊なパターン用基本的にはlist-itemを使用して同じフォーマットのリストを使う -->
<!-- TODO list-itemとほぼ同じなので共通化する -->
<template>
  <div class="list-item-wrap">
    <div class="list-item-key-block">
      <div
        v-for="(key, index) in field"
        :key="index + 'key-head'"
        class="list-item-key-inner"
        :style="{ '--width': checkWidth(index) }"
        :class="{ 'list-item-key-inner-last': index === field.length - 1 }"
      >
        <template v-if="key.label">
          <texts :text="key.label" isBold size="small" />
        </template>
        <template v-else>
          <slot v-bind:item="key" :name="key.key + 'Field'" />
        </template>
      </div>
      <div v-if="type === 'toggle'">
        <icons class="list-item-key-icon-inner" iconName="toggle" />
      </div>
      <div v-else-if="iconName" class="list-item-key-icon">
        <icons
          class="list-item-key-icon-inner"
          :iconName="iconName"
          :size="iconSize"
        />
      </div>
    </div>
    <div class="list-item-entry-list">
      <template v-if="items && items.length > 0">
        <div
          v-for="(entry, listIndex) in items"
          :key="listIndex"
          class="list-item-entry-item"
          :class="{
            'list-item-entry-item-disabled': entry.disabled
          }"
        >
          <!-- 使用不可の場合 -->
          <div
            v-if="entry.disabled"
            v-tooltip="entry.disabledTips"
            class="list-item-entry-block"
          >
            <div
              v-for="(key, index) in field"
              :key="index + 'key'"
              class="list-item-entry-inner"
              :style="{ '--width': checkWidth(index) }"
              :class="{
                'list-item-entry-inner-last': index === field.length - 1
              }"
            >
              <div v-if="isNameDescription">
                <div v-if="key.key !== 'name'">
                  <slot
                    v-bind:item="entry[key.key]"
                    v-bind:entry="entry"
                    v-bind:listIndex="listIndex"
                    :name="key.key"
                  />
                </div>
                <div v-else class="list-item-entry-inner-name">
                  <div class="list-item-entry-inner-name-name">
                    <text-with-icon
                      :text="entry[key.key]"
                      iconName="detail"
                      isBold
                    />
                  </div>
                  <div>
                    <description :text="entry.description" line="2" />
                  </div>
                </div>
              </div>
              <div v-else>
                <slot
                  v-bind:item="entry[key.key]"
                  v-bind:entry="entry"
                  v-bind:listIndex="listIndex"
                  :name="key.key"
                />
              </div>
            </div>
            <div v-if="iconName && !iconButton" class="list-item-entry-icon">
              <icons :iconName="iconName" :size="iconSize" />
            </div>
            <button
              v-else-if="iconName && iconButton"
              class="list-item-entry-icon"
              @click.stop.prevent="
                $emit('icon-click', { index: listIndex, id: entry.id })
              "
            >
              <icons :iconName="iconName" :size="iconSize" />
            </button>
          </div>
          <!-- ボタンまたはトグルとして使用する場合 -->
          <button
            v-else-if="type === 'button' || type === 'toggle'"
            class="list-item-entry-block"
            @click="
              listToggle(listIndex),
              $emit('list-click', { index: listIndex, id: entry.id })
            "
          >
            <div
              v-for="(key, index) in field"
              :key="index + 'key'"
              class="list-item-entry-inner"
              :style="{ '--width': checkWidth(index) }"
              :class="{
                'list-item-entry-inner-last': index === field.length - 1
              }"
            >
              <div v-if="isNameDescription">
                <div v-if="key.key !== 'name'">
                  <slot
                    v-bind:item="entry[key.key]"
                    v-bind:entry="entry"
                    v-bind:listIndex="listIndex"
                    :name="key.key"
                  />
                </div>
                <div v-else class="list-item-entry-inner-name">
                  <div class="list-item-entry-inner-name-name">
                    <text-with-icon
                      :text="entry[key.key]"
                      iconName="detail"
                      isBold
                    />
                  </div>
                  <div>
                    <description :text="entry.description" line="2" />
                  </div>
                </div>
              </div>
              <div v-else>
                <slot
                  v-bind:item="entry[key.key]"
                  v-bind:entry="entry"
                  v-bind:listIndex="listIndex"
                  :name="key.key"
                />
              </div>
            </div>
            <div v-if="type === 'toggle'">
              <icons
                class="list-item-entry-icon-toggle"
                iconName="toggle"
                :class="{
                  'list-item-entry-icon-toggle-on': showItems.some(
                    (item) => item === listIndex
                  )
                }"
              />
            </div>
            <div
              v-else-if="iconName && !iconButton"
              class="list-item-entry-icon"
            >
              <icons :iconName="iconName" :size="iconSize" />
            </div>
            <button
              v-else-if="iconName && iconButton"
              class="list-item-entry-icon"
              @click.stop.prevent="
                $emit('icon-click', { index: listIndex, id: entry.id })
              "
            >
              <icons :iconName="iconName" :size="iconSize" />
            </button>
          </button>
          <!-- リンクとして使用する場合 -->
          <router-link
            v-else-if="type === 'link'"
            class="list-item-entry-block"
            :to="{ name: entry.link.name, params: { id: entry.link.param } }"
          >
            <div
              v-for="(key, index) in field"
              :key="index + 'key'"
              class="list-item-entry-inner"
              :style="{ '--width': checkWidth(index) }"
              :class="{
                'list-item-entry-inner-last': index === field.length - 1
              }"
            >
              <slot
                v-bind:item="entry[key.key]"
                v-bind:entry="entry"
                v-bind:listIndex="listIndex"
                :name="key.key"
              />
            </div>
            <div v-if="iconName && !iconButton" class="list-item-entry-icon">
              <icons :iconName="iconName" :size="iconSize" />
            </div>
            <button
              v-else-if="iconName && iconButton"
              class="list-item-entry-icon"
              @click.stop.prevent="
                $emit('icon-click', { index: listIndex, id: entry.id })
              "
            >
              <icons :iconName="iconName" :size="iconSize" />
            </button>
          </router-link>
          <div v-else class="list-item-entry-block">
            <div
              v-for="(key, index) in field"
              :key="index + 'key'"
              class="list-item-entry-inner"
              :style="{ '--width': checkWidth(index) }"
              :class="{
                'list-item-entry-inner-last': index === field.length - 1
              }"
            >
              <slot
                v-bind:item="entry[key.key]"
                v-bind:entry="entry"
                v-bind:listIndex="listIndex"
                :name="key.key"
              />
            </div>
            <div v-if="iconName && !iconButton" class="list-item-entry-icon">
              <icons :iconName="iconName" :size="iconSize" />
            </div>
            <button
              v-else-if="iconName && iconButton"
              class="list-item-entry-icon"
              @click.stop.prevent="
                $emit('icon-click', { index: listIndex, id: entry.id })
              "
            >
              <icons :iconName="iconName" :size="iconSize" />
            </button>
          </div>
          <!-- トグルして要素を表示した際に、表示される要素 -->
          <transition
            name="list-toggle"
            @before-enter="beforeEnter"
            @enter="enter"
            @before-leave="beforeLeave"
            @leave="leave"
          >
            <div
              v-if="
                type === 'toggle' &&
                  showItems.some((item) => item === listIndex)
              "
              class="list-item-entry-toggle"
            >
              <!-- 表示される要素は親コンポーネント側で自由に設定 -->
              <div class="list-item-entry-toggle-inner">
                <slot
                  name="toggleContent"
                  v-bind:entry="entry"
                  v-bind:listIndex="listIndex"
                />
              </div>
            </div>
          </transition>
        </div>
      </template>
      <div v-else-if="noSearchResults" class="list-no-item">
        <images class="list-no-item-image" imageName="notFound" />
        <texts
          class="list-no-item-text"
          :text="$t('common.noSearchResults')"
          size="small"
        />
      </div>
      <div v-else class="list-no-item">
        <images class="list-no-item-image" imageName="noItem" />
        <texts
          class="list-no-item-text"
          :text="$t('common.listNoItemSub')"
          size="small"
        />
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import images from '@/components/atoms/images'
import icons from '@/components/atoms/icon'
import description from '@/components/atoms/description'
import textWithIcon from '@/components/molecules/text-with-icon'

export default {
  components: {
    texts,
    images,
    icons,
    description,
    textWithIcon
  },
  data() {
    return {
      showItems: []
    }
  },
  props: {
    /** リストのラベル 必須項目は key label width(表示幅) */
    field: Array,
    /** リスト本体 必須項目は width(表示幅) */
    items: Array,
    /** リストの形式（button or link or default(なし)） */
    type: String,
    /** アイコンを表示したいときにアイコン名を入力 */
    iconName: {
      type: String,
      required: false
    },
    /** アイコンを表示したいときにアイコンサイズを変えたい時 */
    iconSize: {
      type: [Number, String],
      default: 'small'
    },
    /** アイコンのみ違う挙動をしたいとき（例: type="link"のときにアイコンクリック時は別の挙動をしたいとき。この場合ページ遷移はアイコンクリック時には起きません） */
    iconButton: {
      type: Boolean,
      default: false,
      required: false
    },
    /** 名前と説明を一つの列に表示させたいとき（項目名がnameの場合のみだけ表示させる） */
    isNameDescription: {
      type: Boolean,
      default: false,
      required: false
    },
    /** 検索結果が見つからないときにイラストを表示 */
    noSearchResults: {
      type: Boolean,
      default: false,
      required: false
    },
    /** 一つの要素だけを開くようにする場合 */
    onlyToggle: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    checkWidth(id) {
      return (this.field[id].width / 1920) * 100 + 'vw'
    },
    listToggle(e) {
      if (this.type !== 'toggle') return

      // リストの開閉
      const targetIndex = this.showItems.findIndex((i) => i === e)
      if (targetIndex !== -1) {
        this.showItems.splice(targetIndex, 1)
      } else {
        if (this.onlyToggle) {
          this.showItems = []
        }
        this.$emit('open-toggle', e)
        this.showItems.push(e)
      }
    },
    beforeEnter(el) {
      el.style.maxHeight = 0 + 'vh'
    },
    enter(el) {
      el.style.maxHeight = 300 + 'vh'
    },
    beforeLeave(el) {
      el.style.maxHeight = (el.scrollHeight / window.innerHeight) * 100 + 'vh'
    },
    leave(el) {
      el.style.maxHeight = 0 + 'vh'
    }
  }
}
</script>

<style lang="scss" scoped>
.list-item {
  &-wrap {
    position: relative;
  }
  &-key {
    &-block {
      position: sticky;
      top: 0;
      display: flex;
      justify-content: space-between;
      padding: 0 $space-sub;
      border-bottom: $border-title-gray;
      background: $background;
      box-shadow: 0 0 adjustVW(2) adjustVW(2) rgb(255, 255, 255);
      z-index: 11;
    }
    &-inner {
      --width: 160;
      overflow: hidden;
      display: flex;
      width: var(--width);
      padding: $space-sub $space-medium $space-sub 0;
      text-align: left;
      &-last {
        padding: $space-sub 0;
      }
    }
    &-icon {
      padding: $space-sub $space-sub $space-sub $space-small;
      margin: 0 #{-$space-sub} 0 0;
      &-inner {
        visibility: hidden;
      }
    }
  }
  &-entry {
    &-list {
      display: flex;
      flex-direction: column;
    }
    &-item {
      width: 100%;
      border-bottom: $border-sub;
      &:last-child {
        border: none;
      }
      &-disabled {
        .list-item-entry-block {
          background: $medium-gray;
          cursor: not-allowed;
          &:hover {
            background: $medium-gray;
            opacity: 1;
          }
        }
      }
    }
    &-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 $space-sub;
      &:hover {
        background: $background-sub;
        opacity: 1;
      }
    }
    &-inner {
      --width: 160;
      overflow: hidden;
      width: var(--width);
      padding: $space-small $space-medium $space-small 0;
      text-align: left;
      &-last {
        padding: $space-small 0;
      }
      &-name {
        display: flex;
        flex-direction: column;
        &-name {
          margin: 0 0 $space-base;
        }
      }
    }
    &-toggle {
      height: auto;
      padding: 0 $space-sub;
      transition: all $transition-base;
      &-inner {
        padding: $space-small 0;
      }
    }
    &-icon {
      display: flex;
      align-items: center;
      align-self: stretch;
      padding: $space-small $space-sub $space-small $space-small;
      margin: 0 #{-$space-sub} 0 0;
      &-toggle {
        transition: transform $transition-base;
        &-on {
          transform: rotate(-180deg);
        }
      }
    }
  }
}
.list {
  &-no-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: $space-medium 0 $space-base;
    &-image {
      width: adjustVW(320);
      height: auto;
      margin-bottom: $space-medium;
    }
    &-text {
      width: 100%;
      text-align: center;
    }
  }
}
.list-toggle-enter-active,
.list-toggle-leave-active {
  overflow: hidden;
}
.list-toggle-enter,
.list-toggle-leave-to {
  opacity: 0;
}
</style>
