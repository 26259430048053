<template>
  <div>
    <project-template-detail-summary
      class="detail-card"
      v-bind="summary"
      @select-project="$emit('select-project')"
      @show-nav="showNav($event)"
    />
    <project-template-detail-created-ai
      v-if="showTemplateEffect"
      class="detail-card"
      v-bind="expectedEffect"
    />
    <project-template-detail-sample-dataset
      v-if="showSampleDataset"
      class="detail-card"
      :class="{ 'detail-card-none-effects': !expectedEffect }"
      v-bind="sampleDataset"
      :loadingDatasets="loadingDatasets"
    />
    <project-template-footer-nav
      :title="title"
      :footerNavShow="footerNavShow"
      v-bind="summary"
      @select-project="$emit('select-project')"
    />
  </div>
</template>

<script>
import projectTemplateDetailSummary from '@/components/organisms/project-template-detail/project-template-detail-summary'
import projectTemplateDetailCreatedAi from '@/components/organisms/project-template-detail/project-template-detail-created-ai'
import projectTemplateDetailSampleDataset from '@/components/organisms/project-template-detail/project-template-detail-sample-dataset'
import projectTemplateFooterNav from '@/components/organisms/project-template-detail/project-template-footer-nav.vue'

export default {
  components: {
    projectTemplateDetailSummary,
    projectTemplateDetailCreatedAi,
    projectTemplateDetailSampleDataset,
    projectTemplateFooterNav
  },
  data() {
    return {
      footerNavShow: false
    }
  },
  props: {
    id: String,
    title: String,
    summary: Object,
    expectedEffect: Object,
    datasetId: String,
    sampleDataset: Object,
    loadingDatasets: Boolean
  },
  computed: {
    showTemplateEffect: function () {
      const description = this.expectedEffect?.description
      const images = this.expectedEffect?.images

      const hasDescription = description?.length > 0
      const hasImages = images?.length > 0

      return hasDescription || hasImages
    },
    showSampleDataset: function () {
      const hasSampleDataset = this.datasetId?.length > 0

      return hasSampleDataset
    }
  },
  methods: {
    showNav(e) {
      this.footerNavShow = e
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-card {
  margin: 0 0 $space-medium;
  &:last-of-type {
    margin: 0;
  }
  &-none-effects {
    margin: 0;
  }
}
</style>
