<template>
  <div class="service-detail-popup-delete-wrap">
    <div class="service-detail-popup-delete-title-wrap">
      <div class="service-detail-popup-delete-title">
        <texts :text="service.name" isBold />
      </div>
      <div>
        <description :text="service.description" />
      </div>
    </div>
    <div class="service-detail-popup-delete-block">
      <div v-if="service.trainedAI" class="service-detail-popup-delete-inner">
        <text-with-title
          :title="$t('serviceList.header.trainedAI')"
          :text="service.trainedAI.name"
        />
      </div>
      <div
        v-if="service.preprocessing"
        class="service-detail-popup-delete-inner"
      >
        <text-with-title
          :title="$t('serviceList.popup.addNewService.PreprocessingToUse')"
          :text="service.preprocessing.name"
        />
      </div>
    </div>
    <div class="service-detail-popup-delete-block">
      <div class="service-detail-popup-delete-inner">
        <text-with-title :title="$t('common.createTime')">
          <a-time :value="service.createdAt" />
        </text-with-title>
      </div>
      <div class="service-detail-popup-delete-inner">
        <text-with-title :title="$t('common.updateTime')">
          <a-time :value="service.updatedAt" />
        </text-with-title>
      </div>
    </div>
    <div class="service-detail-popup-delete-button-block">
      <div class="service-detail-popup-delete-button">
        <button-main
          type="sub"
          :text="$t('common.cancel')"
          :disabled="disableClick"
          @click="$emit('cancel')"
        />
      </div>
      <div class="service-detail-popup-delete-button">
        <button-main
          type="caution"
          :text="$t('common.deleteButton')"
          :disabled="disableClick"
          @click="deleteService"
        />
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import description from '@/components/atoms/description'
import buttonMain from '@/components/atoms/button-main'
import textWithTitle from '@/components/molecules/text-with-title'

export default {
  name: 'ServiceDeletePopup',
  components: {
    texts,
    description,
    buttonMain,
    textWithTitle
  },
  props: {
    service: Object,
    disableClick: Boolean
  },
  methods: {
    deleteService() {
      this.$emit('delete-service', this.service)
    }
  }
}
</script>

<style lang="scss" scoped>
.service-detail-popup-delete {
  &-wrap {
    width: adjustVW(720);
  }
  &-block {
    display: flex;
    margin: 0 0 $space-medium;
  }
  &-inner {
    overflow: hidden;
    width: 100%;
    margin: 0 $space-small 0 0;
    &:last-child {
      margin: 0;
    }
  }
  &-button {
    margin: 0 $space-small 0 0;
    &:last-child {
      margin: 0;
    }
    &-block {
      display: flex;
      justify-content: flex-end;
    }
  }
  &-title-wrap {
    margin: 0 0 $space-medium;
  }
}
</style>
