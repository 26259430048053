<template>
  <popup-array
    :showPopup="showPopup"
    :popupDefs="popupDefs"
    :noClose="disableClick || showPopup.includes('notAvailableTrainedAi')"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #stopOptimization>
      <optimization-stop
        @optimization-stop-confirm="$emit('optimization-stop-confirm')"
        @close-modal="$emit('close-modal', $event)"
      />
    </template>
    <template #notAvailableTrainedAi>
      <describe-of-error
        :description="
          $t('common.disabled.NOT_AVAILABLE_CUSTOMBLOCK_FOR_INFERENCE')
        "
        :okButton="$t('common.close')"
        @ok="$emit('reset-inference-settings')"
      />
    </template>
    <template #inferenceEnvironmentSetting>
      <inference-environment-setting
        :value="inferenceEnvironment"
        @inference-environment-set="
          $emit('getSelectedItems', { inferenceEnvironment: $event })
        "
        @close-modal="$emit('close-modal', 'inferenceEnvironmentSetting')"
      />
    </template>
  </popup-array>
</template>

<script>
import optimizationStop from './popup/optimization-stop'
import inferenceEnvironmentSetting from './popup/inference-environment-setting'
import popupArray from '@/components/molecules/popup/popup-array.vue'
import describeOfError from '@/components/organisms/common-popups/describe-of-error'

export default {
  components: {
    optimizationStop,
    inferenceEnvironmentSetting,
    popupArray,
    describeOfError
  },
  props: {
    showPopup: Array,
    disableClick: Boolean,
    inferenceEnvironment: String
  },
  computed: {
    popupDefs() {
      return {
        stopOptimization: {
          title: this.$t('inference.optimization.stop')
        },
        notAvailableTrainedAi: {
          title: this.$t('inference.notAvailableInference')
        },
        inferenceEnvironmentSetting: {
          title: this.$t('inference.popup.environmentSetting.title')
        }
      }
    }
  }
}
</script>
