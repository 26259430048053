<template>
  <table-img-and-txt
    v-bind="texts"
    :items="samples"
    :field="field"
    :maxCount="maxCount"
    @data-text-click="dataTextClick($event)"
    @close-popup="closePopup"
  />
</template>

<script>
import tableImgAndTxt from '@/components/molecules/table-img-and-txt'

export default {
  components: {
    tableImgAndTxt
  },
  data() {
    return {
      texts: {
        type: 'text',
        popupContents: {},
        isPopupShow: false
      }
    }
  },
  props: {
    info: Object,
    naturalLanguage: Array,
    dataId: String,
    rawSamples: Object
  },
  methods: {
    dataTextClick(e) {
      // 自然言語のデータセットはダミーでこのコンポーネントにいれているので、メソッドもここに置いています
      this.$set(
        this.texts.popupContents,
        'name',
        this.samples[e.labelId].datas[e.textId].name
      )
      this.$set(
        this.texts.popupContents,
        'body',
        this.samples[e.labelId].datas[e.textId].body
      )
      this.$set(
        this.texts.popupContents,
        'length',
        this.samples[e.labelId].datas[e.textId].length
      )
      this.$set(this.texts.popupContents, 'textId', e.textId)
      this.$set(this.texts.popupContents, 'labelId', e.labelId)
      this.$set(this.texts.popupContents, 'labelCount', e.labelCount)
      this.texts.isPopupShow = true
    },
    closePopup() {
      this.texts.isPopupShow = false
    }
  },
  computed: {
    field() {
      return [
        {
          key: 'name',
          label: this.$t('datasetDetail.table.column'),
          thClass: 'default',
          sortable: false
        },
        {
          key: 'count',
          label: this.$t('datasetDetail.table.count'),
          thClass: 'default',
          sortable: true
        },
        {
          key: 'size',
          label: this.$t('datasetDetail.table.text'),
          thClass: 'main',
          sortable: false
        }
      ]
    },
    maxCount() {
      // データの総数
      let maxCount = 0
      if (!this.samples) return 0
      for (let i = 0; i < this.samples.length; i++) {
        maxCount = maxCount + this.samples[i].count
      }
      return maxCount
    },
    samples() {
      if (!this.rawSamples) return []
      const res = []
      let i = 0
      for (const key in this.rawSamples) {
        res.push({
          id: i,
          name: key,
          ...this.rawSamples[key]
        })
        i++
      }
      return res
    }
  }
}
</script>
