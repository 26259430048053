<template>
  <div class="text-mining-body">
    <div class="text-mining-side">
      <sidebar
        :typeList="typeList"
        :displayType="activeTab"
        :waitTextMiningLoading="waitTextMiningLoading"
        @change="changeTab($event)"
      />
    </div>
    <transition-toggle-contents>
      <div v-if="!changeTabLoading" key="body" class="text-mining-main">
        <components
          :is="activeTab"
          v-bind="content"
          v-model="internalValue"
          :columns="columns"
          :waitTextMiningLoading="waitTextMiningLoading"
          :typeList="typeList"
          :columnName="params.columnName"
          @select-topic="selectTopic"
          @go-text-detail="goTextDetail"
          @go-topic="goTopic"
          @change-column-name="changeColumnName"
          @create-co-occurrence="createCoOccurrence"
        />
      </div>
      <loading v-else key="loading" />
    </transition-toggle-contents>
  </div>
</template>

<script>
import { TEXT_MINING_PAGE_TYPE } from '@/lib/text-mining'

import textList from './text-list.vue'
import textTopicDetail from './text-topic-detail.vue'
import textTopic from './text-topic.vue'
import coOccurrence from './co-occurrence.vue'
import frequenciesWordCloud from './frequencies-wordcloud.vue'
import sidebar from './sidebar.vue'

import loading from '@/components/atoms/loading'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

export default {
  components: {
    textList,
    textTopicDetail,
    textTopic,
    coOccurrence,
    frequenciesWordCloud,
    sidebar,
    loading,
    transitionToggleContents
  },
  props: {
    textList: {
      type: Object,
      default: () => {}
    },
    textTopic: {
      type: Object,
      default: () => {}
    },
    textTopicDetail: {
      type: Object,
      default: () => {}
    },
    coOccurrence: {
      type: Object,
      default: () => {}
    },
    frequencies: {
      type: Object,
      default: () => {}
    },
    wordcloud: {
      type: String,
      default: () => {}
    },
    params: {
      type: Object,
      default: () => {}
    },
    waitTextMiningLoading: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default: () => []
    },
    typeList: {
      type: Array,
      default: () => []
    },
    activeTab: {
      type: String,
      default: TEXT_MINING_PAGE_TYPE.TEXT_LIST
    }
  },
  data() {
    return {
      displayType: TEXT_MINING_PAGE_TYPE.TEXT_LIST,
      changeTabLoading: false
    }
  },
  computed: {
    content() {
      const list = {
        [TEXT_MINING_PAGE_TYPE.TEXT_LIST]: {
          ...this.textList
        },
        [TEXT_MINING_PAGE_TYPE.TEXT_TOPIC_DETAIL]: this.textTopicDetail,
        [TEXT_MINING_PAGE_TYPE.TEXT_TOPIC]: {
          ...this.textTopic,
          ...this.textTopicDetail
        },
        [TEXT_MINING_PAGE_TYPE.CO_OCCURRENCE]: this.coOccurrence,
        [TEXT_MINING_PAGE_TYPE.FREQUENCIES_WORDCLOUD]: {
          frequencies: this.frequencies,
          wordcloud: this.wordcloud
        }
      }

      return list[this.activeTab]
    },
    internalValue: {
      get() {
        return JSON.parse(JSON.stringify(this.params[this.activeTab]))
      },
      set(newVal) {
        this.loadTextMining(this.activeTab, newVal)
      }
    }
  },
  methods: {
    changeTab(tab, noLoading) {
      this.changeTabLoading = true
      this.$emit('change-tab-text-mining', tab)
      if (tab !== TEXT_MINING_PAGE_TYPE.CO_OCCURRENCE && !noLoading) {
        this.loadTextMining(tab, this.params[tab])
      }
      this.$nextTick(() => {
        this.changeTabLoading = false
      })
    },
    loadTextMining(pageType, value, newColumnName = null) {
      if (
        pageType === TEXT_MINING_PAGE_TYPE.TEXT_TOPIC &&
        value.targetName == null
      ) {
        value.targetName = this.textList?.datas
          ? this.textList.datas[0].fileName.toString()
          : null
      }
      const columnName = newColumnName ?? this.params.columnName
      this.$emit('load-text-mining', { pageType, value, columnName })
    },
    selectTopic(topic) {
      const target = JSON.parse(
        JSON.stringify(this.params[TEXT_MINING_PAGE_TYPE.TEXT_TOPIC_DETAIL])
      )
      target.targetTopic = topic
      target.offset = 20
      this.loadTextMining(TEXT_MINING_PAGE_TYPE.TEXT_TOPIC_DETAIL, target)
    },
    goTextDetail(fileName) {
      const target = JSON.parse(
        JSON.stringify(this.params[TEXT_MINING_PAGE_TYPE.TEXT_TOPIC])
      )
      target.targetName = fileName.toString()
      this.loadTextMining(TEXT_MINING_PAGE_TYPE.TEXT_TOPIC, target)
      this.changeTab(TEXT_MINING_PAGE_TYPE.TEXT_TOPIC, true)
    },
    goTopic(selectTopic) {
      const target = JSON.parse(
        JSON.stringify(this.params[TEXT_MINING_PAGE_TYPE.TEXT_TOPIC_DETAIL])
      )
      target.targetTopic = selectTopic
      this.loadTextMining(TEXT_MINING_PAGE_TYPE.TEXT_TOPIC_DETAIL, target)
      this.changeTab(TEXT_MINING_PAGE_TYPE.TEXT_TOPIC_DETAIL, true)
    },
    changeColumnName(columnName) {
      const value = this.params[this.activeTab]
      this.loadTextMining(this.activeTab, value, columnName)
    },
    createCoOccurrence({ params, columnName }) {
      this.loadTextMining(this.activeTab, params, columnName)
    }
  }
}
</script>

<style lang="scss" scoped>
.text-mining {
  &-body {
    display: grid;
    grid-template-columns: adjustVW(240) minmax(0, 1fr);
    grid-template-rows: 100%;
    grid-column-gap: $space-medium;
    height: 100%;
  }
}
</style>
