<template>
  <service-list
    v-bind="data"
    :activeTab="activeTab"
    :accountInfo="accountInfo"
    :accountInfoLoading="accountInfoLoading"
    :popup="popup"
    :serviceList="serviceList"
    :servicesLoading="loadingServices"
    :preprocessingList="preprocessingList"
    :trainedAIs="trainedAIs"
    :loading="loading"
    :notFound="notFoundProject"
    :disableClick="disableClick"
    :projectId="projectId"
    :recipes="recipes"
    :recipeLoading="recipeLoading"
    :recipeLayers="recipeLayers"
    :datasetList="datasetList"
    :datasetLoading="datasetLoading"
    :customblockList="customblockList"
    :loadingCutomblockList="loadingCutomblockList"
    @hover-menu-click="hoverMenuClick()"
    @close-modal="closePopup($event)"
    @menu-click="iconMenuClick"
    @input-create-form="inputCreateForm($event)"
    @tab-changed="tabChanged"
    @add-new-service="openAddService"
    @create-service="createService"
    @delete-services="deleteServicesHandler"
    @close-delete-popup="closeDeletePopup()"
    @cancel-deleted="closeDeletePopup()"
    @select-box-get-models="selectBoxGetModels()"
    @select-box-set-model="selectBoxSetModel($event)"
    @select-box-get-preprocessings="selectBoxGetPreprocAndEda()"
    @get-recipe-detail="getRecipeDetail($event)"
    @get-dataset-detail="getDatasetDetail($event)"
    @reset-create-service="resetCreateService"
    @reset-create-service-dataset="resetCreateServiceDataset"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { serviceValidator } from '@/lib/validator/service.js'
import { usedCustomblockList } from '@/lib/inference.js'
import setMountedTimer from '@/mixin/set-mounted-timer'

import serviceList from '@/components/templates/service-list.vue'

const type2popup = {
  infer: 'addNewService',
  upload: 'addNewServiceUpload',
  learn: 'addNewServiceLearn',
  optimization: 'addNewServiceOptimization'
}

export default {
  components: {
    serviceList
  },
  mixins: [setMountedTimer],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.pageLoading = true
      const serviceType = from?.params?.serviceType ?? 'infer'
      vm.activeTab = serviceType
      await vm.loadProjectDetail(vm.projectId)
      /*
        TODO uploadServiceがプロジェクト管理サービスに変わったのでserviceTypeをprojectなどにしたい
        合わせて関連箇所の修正が必要
      */
      await vm.loadServiceList({
        projectId: vm.projectId,
        serviceType: serviceType
      })

      await vm.fetchCustomblocks()

      vm.popup.freePlan.projectId = vm.projectId

      if (
        vm.accountInfo.plan === 'free' ||
        vm.accountInfo.plan === 'education_1'
      ) {
        await vm.showPopup('freePlan')
      }
      vm.pageLoading = false
    })
  },
  data() {
    return {
      popup: {
        showPopup: [],
        deleteService: {
          services: {
            items: []
          }
        },
        freePlan: {
          projectId: null
        },
        createInfo: {
          serviceFormValidate: {
            duplicate: undefined
          }
        },
        addNewService: {
          // 初期状態では、選択した学習済みモデルに使用した前処理が入っている
          preprocAndEdas: [],
          modelInfo: {},
          loadingTrainedAiDetail: false,
          recipeDetail: null,
          loadRecipeDetail: false,
          datasetDetail: null,
          loadDatasetDetail: false,
          isNotCustomblockAvailable: false
        },
        intoProject: null
      },
      data: {
        sidebar: {
          // サイドバーに表示する情報
          project: {
            name: '退職予測',
            description:
              'このプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入ります'
          },
          activeLink: 'service'
        },
        headerTabs: {
          // ヘッダーのタブ
          tabs: [],
          tabSelect: 1
        }
      },
      submitCreate: false,
      submitDeleting: false,
      submitOther: false,

      activeTab: 'infer',
      pageLoading: false
    }
  },
  computed: {
    ...mapGetters('preprocessings', ['EDARecipes', 'loadingEDARecipes']),
    ...mapGetters('trainedAi', { getterTrainedAIs: 'trainedAIs' }),
    ...mapGetters('auth', ['accountInfo', 'accountInfoLoading']),
    ...mapGetters('project', ['projectList', 'projectLoading']),
    ...mapGetters('recipes', ['recipes', 'recipeLoading', 'recipeLayers']),
    ...mapGetters('datasets', ['datasetList', 'datasetLoading']),
    ...mapGetters('settings', ['rowsPerPage']),
    ...mapGetters('customblock', {
      customblockList: 'customblockList',
      loadingCutomblockList: 'loading'
    }),

    serviceType() {
      return this.activeTab
    },
    services() {
      return this.$store.getters[`services/${this.serviceType}/services`]
    },
    loadingServices() {
      return this.$store.getters[`services/${this.serviceType}/loadingServices`]
    },
    project() {
      const projectId = this.$route.params?.projectId
      return this.projectList[projectId]
    },
    projectId() {
      if (this.$route.params.projectId) {
        return parseInt(this.$route.params.projectId)
      }
      return null
    },
    notFoundProject() {
      if (this.projectId === null) {
        return false
      } else {
        return !(this.projectId in this.projectList)
      }
    },
    serviceList() {
      return this.services ? Object.values(this.services) : null
    },
    // TODO project内の学習済みAIを使用しているがtrainedAiとどちらを使用するか統一する
    // https://trello.com/c/G6lnTqQ2
    trainedAIs() {
      if (this.project) {
        const res = {}
        this.project.listAIs?.forEach((item) => {
          res[item.id] = item
          const setItem = res[item.id]

          res[item.id].disabled = {
            reasons: []
          }

          if (setItem?.is_optimization) {
            res[item.id].disabled.reasons.push(
              'INFERENCE_SERVICE_NOT_AVAILABLE_OPTIMIZATION'
            )
          }
        })
        return res
      } else {
        return this.getterTrainedAIs
      }
    },
    preprocessingList() {
      let res
      if (this.project?.listEdaRecipe) {
        res = this.project.listEdaRecipe
      } else {
        res = this.EDARecipes ? Object.values(this.EDARecipes) : []
      }
      res = res.sort((x, y) => (x.updateTime > y.updateTime ? -1 : 1))
      return res
    },
    loading() {
      return this.pageLoading
    },
    disableClick() {
      return this.submitCreate || this.submitDeleting || this.submitOther
    }
  },
  methods: {
    ...mapActions('preprocessings', ['fetchEDARecipeList']),
    ...mapActions('services', ['addService', 'loadServiceList', 'deleteServices']),
    ...mapActions('project', ['loadProjectDetail', 'projectAddService']),
    ...mapActions('recipes', ['loadRecipeList', 'fetchLayers']),
    ...mapActions('datasets', ['loadDatasetList']),
    ...mapActions('trainedAi', ['fetchTrainedAiDetail']),
    ...mapActions('customblock', ['fetchCustomblocks']),

    showPopup(e) {
      // ポップアップを表示
      if (this.popup.showPopup.length > 0) {
        this.popup.showPopup = []
        this.popup.showPopup.push(e)
      } else {
        this.popup.showPopup.push(e)
      }
    },
    // ポップアップを閉じる
    closePopup(e) {
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)

      if (e === 'freePlan') {
        this.$router.push({
          name: 'projectDetail',
          params: {
            projectId: this.projectId
          }
        })
      }
    },
    listToggle(e) {
      // リストの開閉
      const tagetIndex = e.showItems.findIndex((i) => i === e.id)
      if (tagetIndex !== -1) {
        e.showItems.splice(tagetIndex)
      } else {
        e.showItems.push(e.id)
      }
    },
    async tabChanged(e) {
      await this.loadServiceList({ projectId: this.projectId, serviceType: e })
      this.activeTab = e
    },
    onFileInput(e) {
      // 新規サービスの追加でファイルをアップしたときの処理
      this.popup.addDataset.file.fileName = e.target.value.substr(12)
      this.popup.addDataset.file.isDisabled = false
      this.popup.addDataset.showTable = true
    },
    datasourceInput() {
      // 新規サービスの追加でデータソースを入力したときのテスト
      this.popup.addDataset.outside.isDisabled = false
    },
    setDatasource(e) {
      // 新規サービスの追加でデータソースを入力したときのテスト
      this.popup.datasourceResetting = e.datasourceInfo
    },
    hoverMenuClick() {
      // ヘルプメニュー押下時の処理
    },
    closeDeletePopup() {
      this.popup.deleteService = null
      this.closePopup('deleteService')
    },
    cancelDeleted() {
      this.popup.deleteService = null
      this.closePopup('deleteService')
    },
    async deleteServicesHandler(e) {
      this.submitDeleting = true
      const ids = e.map((x) => x.serviceId)
      try {
        await this.deleteServices({
          serviceType: this.activeTab,
          projectId: this.projectId,
          payload: ids
        })
      } finally {
        this.submitDeleting = false
      }
      this.closePopup('deleteService')
    },
    async createService(e) {
      this.submitCreate = true
      const req = Object.assign(e, { projectId: this.projectId })
      try {
        await this.addService(req)
      } finally {
        this.submitCreate = false
      }
      this.closePopup(type2popup[e.serviceType])
    },
    selectBoxGetModels() {},
    selectBoxSetModel(e) {
      this.loadTrainedAiDetail(e.id)
    },
    async loadTrainedAiDetail(id) {
      this.popup.addNewService.loadingTrainedAiDetail = true
      this.popup.addNewService.isNotCustomblockAvailable = false

      const res = await this.fetchTrainedAiDetail({
        id,
        columnIndex: 0
      })
      const checkCustomblock = await this.checkCustomblockAvailable(res)
      if (checkCustomblock) {
        this.popup.addNewService.modelInfo = {}
        this.popup.addNewService.isNotCustomblockAvailable = true
        this.popup.addNewService.loadingTrainedAiDetail = false
        return
      }
      this.popup.addNewService.modelInfo = res

      this.popup.addNewService.loadingTrainedAiDetail = false
    },
    selectBoxGetPreprocAndEda() {},
    inputCreateForm: function (obj) {
      const { type, form } = obj

      if (type === 'service') {
        const serviceList = this.serviceList
        this.popup.createInfo.serviceFormValidate = serviceValidator(
          serviceList,
          form
        )
      }
    },
    async openAddService({ type }) {
      const OwnServicesLength = this.accountInfo.usedService
      if (OwnServicesLength >= this.accountInfo.planDetail.maxServices) {
        this.showPopup('limitServices')
        return
      }
      this.showPopup(type2popup[type])
      if (!this.EDARecipes && !this.loadingEDARecipes) {
        this.fetchEDARecipeList()
      }
      this.loadRecipeList(this.projectId)
      this.fetchLayers()
      this.loadDatasetList(this.projectId)
    },
    iconMenuClick({ name, checked, type }) {
      switch (name) {
        case 'edit':
          this.$router.push(
            {
              name: 'serviceProjectDetailEdit',
              params: {
                id: checked[0],
                serviceType: type,
                projectId: this.projectId
              }
            },
            () => {},
            () => {}
          )
          break
        case 'delete':
          this.popup.deleteService.services.items = checked.map(
            (id) => this.services[id]
          )
          this.showPopup('deleteService')
          break
      }
    },
    async getRecipeDetail(e) {
      if (e.id === this.popup.addNewService.recipeDetail?.id) return
      this.popup.addNewService.loadRecipeDetail = true
      await this.loadRecipeDetail(e)
      this.popup.addNewService.loadRecipeDetail = false
    },
    async loadRecipeDetail(e) {
      const res = await this.$sendMessageAndReceive({
        action: 'getRecipeDetail',
        recipe_id: e.id,
        recipe_account_id: e.accountId
      })
      this.popup.addNewService.recipeDetail = res.detail
    },
    async getDatasetDetail(e) {
      if (e.id === this.popup.addNewService.datasetDetail?.dataId) return
      this.popup.addNewService.loadDatasetDetail = true
      await this.loadDatasetDetail(e)
      this.popup.addNewService.loadDatasetDetail = false
    },
    async loadDatasetDetail(e) {
      const targetData = this.datasetList[e.id]
      if (targetData.type === 'unstructured') {
        const req = {
          action: 'getDataSample',
          dataId: targetData.id,
          accountId: targetData.accountId,
          offset: 0,
          limit: 4
        }
        try {
          const res = await this.$sendMessageAndReceive(req)
          this.popup.addNewService.datasetDetail = res
        } catch (ex) {
          this.log_info(ex)
        }
      } else if (targetData.type === 'structured') {
        const req = {
          action: 'getData',
          dataId: targetData.id,
          accountId: targetData.accountId,
          offset: 0,
          limit: this.rowsPerPage
        }
        try {
          const res = await this.$sendMessageAndReceive(req)
          this.popup.addNewService.datasetDetail = res
        } catch (ex) {
          this.log_info(ex)
        }
      } else {
        this.log_info(
          "type is only 'structured' or 'unstructured'. Actual type = " +
            targetData.type
        )
        this.log_info(targetData)
      }
    },
    async checkCustomblockAvailable(trainedAi) {
      const layerInfo = trainedAi?.result?.layer_info
      const usedCustomblockItems =
        layerInfo != null ? usedCustomblockList(layerInfo) : []

      if (usedCustomblockItems.length === 0) return false
      if (!this.customblockList || this.customblockList.length === 0) {
        await this.fetchCustomblocks()
      }
      const checkCustomblock = usedCustomblockItems.some((target) => {
        return !this.customblockList.some((customblock) => {
          return customblock.customblock_id === target.customblock_id
        })
      })
      return checkCustomblock
    },
    resetCreateService() {
      this.popup.addNewService.recipeDetail = null
      this.popup.addNewService.datasetDetail = null
      this.popup.addNewService.modelInfo = null
    },
    resetCreateServiceDataset() {
      this.popup.addNewService.datasetDetail = null
    }
  }
}
</script>
