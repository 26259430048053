<template>
  <div class="dataset-detail-wrap">
    <div class="dataset-detail-top">
      <dataset-detail-top
        v-bind="detail.detailData.info"
        :datasetMenu="datasetMenu"
        :activeMenu="pageTop.activeMenu"
        :componentPageName="componentPageName"
        :tableChangeTabs="tableChangeTabs"
        @change-tab="$emit('change-tab', [$event, { target: pageTop }])"
        @change-data-tab="$emit('change-data-tab', $event)"
        @dataset-menu-click="$emit('dataset-menu-click', $event)"
        @select-item="$emit('select-item', $event)"
      />
    </div>
    <div class="dataset-detail-table">
      <!--main-->
      <transition-toggle-contents>
        <components
          v-bind="detail.detailData"
          :is="componentPageName"
          v-if="!loadingTable"
          :dataId="dataId"
          :visibleContents="detail.visibleContents"
          :rawSamples="rawSamples"
          @data-img-click="$emit('data-img-click', $event)"
          @data-text-click="$emit('data-text-click', $event)"
        />
        <div v-else class="loading-wrap">
          <loading />
        </div>
      </transition-toggle-contents>
    </div>
    <div class="dataset-detail-side">
      <!--side-->
      <div class="dataset-detail-side-inner">
        <dataset-detail-table-side
          v-bind="detail.detailData.info"
          :disabledEdit="disabledEdit"
          :componentPageName="componentPageName"
          :infoTabs="pageBody.infoTabs"
          :sidebarTab="sidebarTab"
          :datasetFormValidate="pageBody.editInfo.datasetFormValidate"
          @change-tab="
            $emit('change-tab', [$event, { target: pageBody.infoTabs }])
          "
          @edit-dataset-detail="$emit('edit-dataset-detail')"
          @input-edit-form="$emit('input-edit-form', $event)"
          @save-dataset-detail="$emit('save-dataset-detail', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import loading from '@/components/atoms/loading'
import datasetDetailTop from './dataset-detail-top'

import datasetDetailTableSide from './dataset-detail-table-side'
import datasetDetailTableDefault from './dataset-detail-table-default'
import datasetDetailTableImg from './dataset-detail-table-img'
import datasetDetailTableTxt from './dataset-detail-table-txt'
import datasetDetailTableTimeseries from './dataset-detail-table-timeseries'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    loading,
    datasetDetailTop,
    datasetDetailTableSide,
    TransitionToggleContents,
    default: datasetDetailTableDefault,
    outside: datasetDetailTableDefault,
    images: datasetDetailTableImg,
    naturalLanguage: datasetDetailTableTxt,
    timeSeries: datasetDetailTableTimeseries
  },
  data() {
    return {
      rawSamples: null,
      loadingTable: false
    }
  },
  mounted() {
    this.fetchDetailData(this.datasetInfo)
  },
  props: {
    detail: Object,
    dataId: String,
    disabledEdit: Boolean,
    datasetInfo: Object,
    datasetList: Object,
    rowsPerPage: Number,
    pageTop: Object,
    pageBody: Object,
    datasetMenu: Object,
    tableChangeTabs: Object,
    sidebarTab: Object,
    componentPageName: String,
    datasetLoading: Boolean
  },
  methods: {
    async fetchDetailData(data) {
      if (!data) return false
      if (data.type === 'unstructured') {
        // 詳細を確認しているデータがunstructuredの時に走ります
        this.$emit('file-info', this.datasetInfo)
        const unstructuredRes = {
          action: 'getDataSample',
          dataId: data.id,
          accountId: data.accountId,
          offset: 0,
          limit: 5 // 要確認
        }
        this.loadingTable = true
        const res = await this.$sendMessageAndReceive(unstructuredRes)
        if (res.status === 'error') {
          /** データの読み込みエラーの処理を書く */
          this.isDataLoadingError = true
          return
        }
        this.rawSamples = res.labels
        if (res.data_type !== 'images') {
          // 詳細を確認しているデータがunstructuredの自然言語の時に走ります。
          this.$emit('component-page-name', 'naturalLanguage')
        } else {
          // unstructuredの写真の時に走ります。
          this.$emit('component-page-name', 'images')
        }
        this.loadingTable = false
      } else if (data.type === 'structured') {
        this.loadingTable = true
        const structuredRes = {
          action: 'getData', // clientのdata/fetchdataと同じ意味です
          dataId: data.id,
          accountId: data.accountId,
          type: 'structured',
          offset: 0,
          limit: this.rowsPerPage
        }
        const res = await this.$sendMessageAndReceive(structuredRes)
        this.$emit('component-page-name', 'default')
        this.$emit('learning-data-table', res.list)
        /** S3とDBで返り値の形式がことなるため、それを統一化する処理 */
        if (res?.describe && res.detail.describe.length === 0) {
          res.detail.describe = res.describe
        }
        this.$emit('file-info', res.detail)
        this.loadingTable = false
      } else {
        this.log_info(
          "type is only 'structured' or 'unstructured'. Actual type = " +
            data.type
        )
        this.log_info(data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dataset-detail {
  &-wrap {
    display: grid;
    grid-template-areas:
      'top top'
      'main side';
    grid-template-columns: adjustVW(1224) adjustVW(384);
    grid-template-rows: auto 1fr;
    grid-column-gap: $space-medium;
    grid-row-gap: $space-base;
    height: 100vh - adjustVH($headerTabHeight);
    padding: 0 $space-medium $space-medium;
  }
  &-top {
    grid-area: top;
  }
  &-table {
    overflow: hidden;
    grid-area: main;
    padding: $space-small $space-sub $space-sub $space-medium;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
  }
  &-side {
    overflow: hidden;
    grid-area: side;
    padding: $space-small $space-sub $space-small $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    &-inner {
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
      padding: 0 $space-base 0 0;
      @include scrollbar;
    }
  }
}
.loading-wrap {
  width: 100%;
  height: 100%;
}
</style>
