<template>
  <list-item-slot
    class="list-body"
    :field="field"
    :items="value"
    @scroll.native="scroll"
  >
    <template #column="entry">
      <div class="list-column">
        <transition-toggle-contents>
          <icons
            v-if="checkConditionError(entry.entry)"
            v-tooltip="$t('trainedAi.optimization.conditions.preaseSetValue')"
            class="list-column-icon"
            iconName="error"
            color="caution"
            size="small"
          />
        </transition-toggle-contents>
        <texts :text="entry.entry.column" size="small" />
      </div>
    </template>
    <template #condition="entry">
      <div class="list-func">
        <optimization-select-box
          :ref="'selectBox' + entry.listIndex"
          :value="entry.entry.condition"
          :isSpaceMin="isInference"
          :disabledReason="disabledReason(entry.entry.column)"
          :showTutorial="entry.listIndex === 0"
          @select-box-open="openBox(entry.listIndex)"
          @select-box-close="closeBox"
          @input="updateValue($event, entry.entry)"
        />
        <icon-button
          iconName="statistics"
          isDisabled
          disabledReason="OPTIMIZATION_STATISTICS_AVAILABLE"
        />
      </div>
      <div class="list-detail">
        <div class="list-statistics">
          <texts
            class="list-statistics-title"
            :text="$t('common.statistics.info.title')"
            size="min"
            color="gray"
          />
          <list-statistics
            :describe="describe"
            :columnName="entry.entry.column"
          />
        </div>
        <toggle-button
          v-tooltip="
            checkDisabledInteger(entry)
              ? $t('trainedAi.optimization.conditions.integerTips.disabled')
              : $t('trainedAi.optimization.conditions.integerTips.tips')
          "
          :checked="entry.entry.integer"
          :isDisabled="checkDisabledInteger(entry)"
          class="list-integer"
          :text="$t('trainedAi.optimization.conditions.integer')"
          :data-tutorial-optimization="entry.listIndex === 0 ? 2 : null"
          @change="updateInteger($event, entry.entry)"
        />
      </div>
    </template>
  </list-item-slot>
</template>

<script>
import icons from '@/components/atoms/icon'
import toggleButton from '@/components/atoms/toggle-button'
import iconButton from '@/components/atoms/icon-button'
import listItemSlot from '@/components/molecules/list-item-slot'
import listStatistics from '@/components/molecules/list-statistics'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import optimizationSelectBox from '@/components/organisms/optimization/optimization-select-box'

export default {
  components: {
    icons,
    toggleButton,
    iconButton,
    listItemSlot,
    listStatistics,
    transitionToggleContents,
    optimizationSelectBox
  },
  data() {
    return {
      openedItem: null
    }
  },
  props: {
    value: Array,
    describe: Array,
    graph: Object,
    isInference: Boolean,
    validColumns: Object,
    disableOptimization: Array
  },
  computed: {
    field() {
      return [
        {
          key: 'column',
          label: this.$t('common.columnName'),
          width: 144
        },
        {
          key: 'condition',
          label: this.$t('trainedAi.optimization.conditions.title'),
          width: 752
        }
      ]
    },
    paramsNotSetColumns() {
      const target = this.disableOptimization.find((reason) => {
        return reason?.type === 'notSetParams'
      })
      if (!target) return []
      return target.errorTarget.map((condition) => {
        return condition.column
      })
    }
  },
  methods: {
    scroll() {
      if (this.openedItem) {
        const target = 'selectBox' + this.openedItem
        this.$refs[target].checkPos()
      }
    },
    openBox(id) {
      this.openedItem = id
    },
    closeBox() {
      this.openedItem = null
    },
    updateValue(e, entry) {
      if (e.type !== null) {
        entry.disabledInteger = false
      } else {
        entry.disabledInteger = true
      }
      this.$emit('input', {
        condition: e,
        column: entry.column,
        type: 'condition'
      })
    },
    updateInteger(e, entry) {
      this.$emit('input', { integer: e, column: entry.column, type: 'integer' })
    },
    checkDisabledInteger(entry) {
      return entry?.entry?.disabledInteger
    },
    disabledReason(column) {
      if (!this.validColumns || Object.keys(this.validColumns).length === 0)
        return []
      const reasons = this.validColumns[column]?.reasons ?? []
      const fixResasons = []
      reasons.forEach((reason) => {
        if (reason === 'onlynum' || reason === 'null') {
          fixResasons.push(reason)
        }
      })
      return fixResasons
    },
    checkConditionError(entry) {
      if (!this.paramsNotSetColumns || this.paramsNotSetColumns.length === 0)
        return false
      return this.paramsNotSetColumns.includes(entry.column)
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  &-body {
    overflow-y: scroll;
    height: 100%;
    padding-right: $space-sub;
    @include scrollbar;
    &::v-deep {
      .list-item-entry-inner {
        &:not(.list-item-entry-inner-last) {
          flex-shrink: 0;
        }
      }
      .list-item-key-inner:not(.list-item-entry-inner-last) {
        flex-shrink: 0;
      }
      .list-item-entry-block:hover {
        background: #fff;
      }
    }
  }
  &-column {
    display: flex;
    grid-column-gap: $space-base;
  }
  &-func {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    grid-column-gap: $space-medium;
    margin-bottom: $space-sub;
  }
  &-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: adjustVW(-4);
  }
  &-statistics {
    overflow: hidden;
    display: flex;
    align-items: center;
    &-title {
      flex-shrink: 0;
      margin-right: $space-medium;
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      grid-column-gap: $space-small;
      grid-row-gap: adjustVW(2);
    }
    &-item {
      display: flex;
      grid-column-gap: adjustVW(6);
    }
  }
  &-integer {
    flex-shrink: 0;
  }
}
</style>
