<template>
  <div class="c-wrap">
    <sidebar-project-detail
      v-if="!notFound || loading || loadingInit"
      v-bind="sidebar"
    />
    <sidebar v-else />
    <div class="c-body-wrap">
      <header-tabs
        v-bind="headerTabs"
        @tab-click="$emit('tab-click', $event)"
      />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="loading || loadingInit" />
          <page-not-found v-else-if="notFound" />
          <preprocessing-detail-main
            v-else
            v-bind="body"
            :disabledEdit="disabledEdit"
            :editInfo="editInfo"
            :preprocessingMenu="preprocessingMenu"
            @delete-menu-click="$emit('delete-menu-click', $event)"
            @input-edit-form="$emit('input-edit-form', $event)"
            @select-dataset="$emit('select-dataset', $event)"
            @update-preprocessing-detail="
              $emit('update-preprocessing-detail', $event)
            "
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="disableClick" />
    <hover-menu v-bind="hoverMenu" />
    <preprocessing-detail-popup
      v-bind="popup"
      :datasetList="dataset"
      :preprocessing="body.pageBody"
      :disableClick="disableClick"
      @close-modal="$emit('close-modal', $event)"
      @delete-preprocessing="$emit('delete-preprocessing')"
      @apply-dataset="$emit('apply-dataset', $event)"
      @click-dataset="$emit('click-dataset', $event)"
    />
  </div>
</template>

<script>
import sidebarProjectDetail from '@/components/organisms/sidebar-project-detail.vue'
import Sidebar from '@/components/organisms/sidebar.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import preprocessingDetailMain from '@/components/organisms/preprocessing-detail/preprocessing-detail-main.vue'
import preprocessingDetailPopup from '@/components/organisms/preprocessing-detail/preprocessing-detail-popup.vue'
import transitionPage from '@/components/molecules/transition-page'
import loading from '@/components/atoms/loading.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import TransitionToggleContents from '../molecules/transition-toggle-contents.vue'
import pageNotFound from '@/components/organisms/page-not-found'

export default {
  components: {
    sidebarProjectDetail,
    Sidebar,
    hoverMenu,
    headerTabs,
    preprocessingDetailMain,
    preprocessingDetailPopup,
    transitionPage,
    loading,
    loadingProcessing,
    TransitionToggleContents,
    pageNotFound
  },
  props: {
    body: Object,
    dataset: Array,
    disabledEdit: Boolean,
    editInfo: Object,
    headerTabs: Object,
    hoverMenu: Object,
    loading: Boolean,
    loadingInit: Boolean,
    notFound: Boolean,
    disableClick: Boolean,
    popup: Object,
    preprocessingMenu: Array,
    sidebar: Object
  }
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
}
</style>
