<template>
  <popup-array
    :popupDefs="popupDefs"
    :showPopup="showPopup"
    :noClose="disableClick"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #deleteService>
      <delete-service
        v-bind="deleteService"
        :disableClick="disableClick"
        @delete-services="$emit('delete-services', $event)"
        @close-modal="$emit('close-modal', 'deleteService')"
        @cancel="$emit('close-modal', 'deleteService')"
      />
    </template>
    <template #addNewService>
      <add-service
        v-bind="addNewService"
        :preprocessingList="preprocessingList"
        :serviceFormValidate="createInfo.serviceFormValidate"
        :trainedAIs="trainedAIs"
        :disableClick="disableClick"
        @cancel="$emit('close-modal', 'addNewService')"
        @close-modal="$emit('close-modal', $event)"
        @create-service="
          $emit('create-service', { serviceType: 'infer', ...$event })
        "
        @input-create-form="$emit('input-create-form', $event)"
        @select-box-get-models="$emit('select-box-get-models')"
        @select-box-set-model="$emit('select-box-set-model', $event)"
        @select-box-get-processings="$emit('select-box-get-processings')"
        @reset-create-service="$emit('reset-create-service')"
      />
    </template>
    <template #addNewServiceUpload>
      <add-service-upload
        v-bind="addNewService"
        :preprocessingList="preprocessingList"
        :serviceFormValidate="createInfo.serviceFormValidate"
        :disableClick="disableClick"
        @cancel="$emit('close-modal', 'addNewServiceUpload')"
        @close-modal="$emit('close-modal', 'addNewServiceUpload')"
        @create-service="
          $emit('create-service', { serviceType: 'upload', ...$event })
        "
        @input-create-form="$emit('input-create-form', $event)"
        @select-box-get-models="$emit('select-box-get-models')"
        @select-box-set-model="$emit('select-box-set-model', $event)"
        @select-box-get-processings="$emit('select-box-get-processings')"
      />
    </template>
    <template #addNewServiceLearn>
      <add-service-learn
        v-bind="addNewService"
        :projectId="projectId"
        :preprocessingList="preprocessingList"
        :serviceFormValidate="createInfo.serviceFormValidate"
        :disableClick="disableClick"
        :recipes="recipes"
        :recipeLoading="recipeLoading"
        :recipeLayers="recipeLayers"
        :datasetList="datasetList"
        :datasetLoading="datasetLoading"
        :customblockList="customblockList"
        @cancel="$emit('close-modal', 'addNewServiceLearn')"
        @close-modal="$emit('close-modal', 'addNewServiceLearn')"
        @create-service="
          $emit('create-service', { serviceType: 'learn', ...$event })
        "
        @input-create-form="$emit('input-create-form', $event)"
        @select-box-get-models="$emit('select-box-get-models')"
        @select-box-set-model="$emit('select-box-set-model', $event)"
        @select-box-get-processings="$emit('select-box-get-processings')"
        @get-recipe-detail="$emit('get-recipe-detail', $event)"
        @get-dataset-detail="$emit('get-dataset-detail', $event)"
        @reset-create-service="$emit('reset-create-service')"
        @reset-create-service-dataset="$emit('reset-create-service-dataset')"
      />
    </template>
    <template #addNewServiceOptimization>
      <add-service-optimization
        v-bind="addNewService"
        :projectId="projectId"
        :preprocessingList="preprocessingList"
        :serviceFormValidate="createInfo.serviceFormValidate"
        :disableClick="disableClick"
        :recipes="recipes"
        :recipeLoading="recipeLoading"
        :recipeLayers="recipeLayers"
        :datasetList="datasetList"
        :datasetLoading="datasetLoading"
        :customblockList="customblockList"
        @cancel="$emit('close-modal', 'addNewServiceOptimization')"
        @close-modal="$emit('close-modal', 'addNewServiceOptimization')"
        @create-service="
          $emit('create-service', { serviceType: 'optimization', ...$event })
        "
        @input-create-form="$emit('input-create-form', $event)"
        @select-box-get-models="$emit('select-box-get-models')"
        @select-box-set-model="$emit('select-box-set-model', $event)"
        @select-box-get-processings="$emit('select-box-get-processings')"
        @get-recipe-detail="$emit('get-recipe-detail', $event)"
        @get-dataset-detail="$emit('get-dataset-detail', $event)"
        @reset-create-service="$emit('reset-create-service')"
        @reset-create-service-dataset="$emit('reset-create-service-dataset')"
      />
    </template>
    <template #freePlan>
      <free-plan v-bind="freePlan" />
    </template>
    <template #limitServices>
      <limit-services @close-modal="$emit('close-modal', 'limitServices')" />
    </template>
  </popup-array>
</template>

<script>
import addService from './popup/add-service'
import addServiceUpload from './popup/add-service-upload'
import addServiceLearn from './popup/add-service-learn'
import addServiceOptimization from './popup/add-service-optimization'
import { popupArray } from '@/components/molecules/popup'
import deleteService from './popup/delete-service'
import FreePlan from './popup/free-plan.vue'
import limitServices from './popup/limit-services.vue'

export default {
  components: {
    addService,
    addServiceUpload,
    addServiceLearn,
    addServiceOptimization,
    popupArray,
    deleteService,
    FreePlan,
    limitServices
  },
  computed: {
    type() {
      return this.$t('common.service')
    },
    popupDefs() {
      let intoProject
      if (this.intoProject?.newProject) {
        intoProject = {
          title: this.$t('common.popup.intoNewProject.title', {
            type: this.type
          })
        }
      } else {
        intoProject = {
          title: this.$t('common.popup.intoProject.title', { type: this.type })
        }
      }
      return {
        addNewService: {
          title: this.$t('serviceList.popup.addNewService.title.infer')
        },
        addNewServiceUpload: {
          title: this.$t('serviceList.popup.addNewService.title.upload')
        },
        addNewServiceLearn: {
          title: this.$t('serviceList.popup.addNewService.title.learn'),
          maxWidth: true,
          maxHeight: true
        },
        addNewServiceOptimization: {
          title: this.$t('serviceList.popup.addNewService.title.optimization'),
          maxWidth: true,
          maxHeight: true
        },
        deleteService: {
          title: this.$t('common.deleteConfirm')
        },
        freePlan: {
          title: this.$t('serviceList.popup.freePlan.title'),
          noClose: true
        },
        intoProject,
        limitServices: {
          title: this.$t('serviceList.popup.limitServices.title')
        }
      }
    }
  },
  props: {
    addNewService: Object,
    createInfo: Object,
    deleteService: Object,
    freePlan: Object,
    intoProject: Object,
    preprocessingList: Array,
    showPopup: Array,
    trainedAIs: Object,
    disableClick: Boolean,
    projectId: Number,
    recipes: Object,
    recipeLoading: Boolean,
    recipeLayers: Object,
    datasetList: Object,
    datasetLoading: Boolean,
    customblockList: Array
  }
}
</script>
