<template>
  <div
    class="input-box"
    draggable="true"
    @dragenter.prevent="onDragEnter"
    @dragover.prevent="onDragOver"
    @dragleave.prevent="onDragLeave"
    @drop.prevent="onDrop"
  >
    <div class="input-box-head">
      <div class="input-box-head-title">
        <texts :text="title" size="small" color="gray" />
        <div v-if="info" v-tooltip="info" class="input-box-head-info">
          <icons iconName="info" size="small" color="gray" />
        </div>
      </div>
      <button
        v-if="inputFileName"
        class="input-box-head-cancel"
        @click="cancelUpload"
      >
        <text-with-icon
          iconName="delete"
          size="small"
          :text="$t('customblock.button.deleteInput')"
          color="caution"
        />
      </button>
    </div>
    <div class="input-box-main">
      <label class="input-box-main-inner">
        <input
          class="input-box-main-hidden"
          type="file"
          :value="internalValue"
          :accept="accept"
          @change="inputFile($event.target.files[0])"
        >
        <transition-toggle-contents>
          <texts
            v-if="enter || drag"
            key="drag"
            :text="$t('common.inputDragAndDrop')"
            size="small"
            color="gray"
          />
          <texts
            v-else-if="inputFileName"
            key="file"
            :text="inputFileName"
            size="small"
            :color="errorMessage ? 'caution' : 'default'"
          />
          <texts
            v-else
            key="placeholder"
            :text="placeholder"
            size="small"
            color="gray"
          />
        </transition-toggle-contents>
      </label>
      <transition-toggle-contents>
        <div v-if="errorMessage" class="input-box-error">
          <text-box :text="errorMessage" isError />
        </div>
      </transition-toggle-contents>
    </div>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon.vue'
import textBox from '@/components/atoms/text-box.vue'
import textWithIcon from '@/components/molecules/text-with-icon.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    icons,
    textBox,
    textWithIcon,
    transitionToggleContents
  },
  data() {
    return {
      inputFileName: null,
      internalValue: null,
      enter: false,
      drag: false
    }
  },
  props: {
    value: {
      type: File,
      default: null
    },
    accept: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: ''
    },
    info: {
      type: String,
      default: ''
    }
  },
  computed: {
    active() {
      return this.drag || this.fileName
    }
  },
  methods: {
    inputFile(file) {
      this.inputFileName = file.name
      this.$emit('input', file)
    },
    cancelUpload() {
      this.inputFileName = null
      this.internalValue = null
      this.$emit('input', null)
    },
    onDragEnter() {
      this.enter = true
    },
    onDragOver(e) {
      const types = [...e.dataTransfer.types] // DomStringList to Array for IE or Edge
      if (types.includes('Files')) {
        e.preventDefault()
        e.dataTransfer.dropEffect = 'copy'
        this.enter = false // enterのフラグを消す
        this.drag = true
      } else {
        e.dataTransfer.dropEffect = 'none'
      }
    },
    onDragLeave() {
      if (this.value) {
        // すでにファイルをinputしている場合
        if (this.enter) {
          // enterフラグがある場合はフラグを消す
          this.enter = false
          return false
        } else {
          // enterフラグがない場合はドラッグ状態だけを外す
          this.drag = false
          return false
        }
      }
      if (this.enter) {
        // enterフラグがある場合はフラグを消すのみ
        this.enter = false
      } else {
        this.drag = false
      }
    },
    onDrop(event) {
      this.drag = false
      const files = event.dataTransfer.files

      const fileName = files[0].name
      const fileType = files[0].type
      const fileExt = '.' + fileName.split('.').pop().toLowerCase()

      const includeAcceptList =
        this.accept.includes(fileExt) || this.accept.includes(fileType)

      if (includeAcceptList) {
        this.inputFile(files[0])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-box {
  &-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $space-sub;
    &-title {
      display: flex;
      align-items: center;
      grid-column-gap: $space-small;
      width: 100%;
    }
    &-info {
      cursor: help;
    }
  }
  &-main {
    display: flex;
    grid-column-gap: $space-small;
    margin-bottom: $space-small;
    &-inner {
      display: block;
      appearance: none;
      width: 100%;
      height: 100%;
      min-height: adjustVH(48);
      padding: $space-sub;
      background: $background-sub;
      border-radius: adjustVW(8);
      transition: box-shadow $transition-base, background-color $transition-base;
      &-error {
        background-color: $red-lite;
        box-shadow: $border-radius-caution inset;
      }
    }
    &-hidden {
      display: none;
    }
  }
}
</style>
