<template>
  <div class="template-wrap">
    <div class="template-main">
      <div class="template-title">
        <texts :text="$t('recipeList.sampleRecipe.select')" />
      </div>
      <transition-toggle-contents>
        <div v-if="!loadingRecipeTemplates" class="template-list-wrap">
          <div class="template-search">
            <fuse-search
              :placeholder="$t('recipeList.searchBox')"
              :option="option"
              :value="items"
              gray
              @input="inputSearch"
            />
          </div>
          <div class="template-list">
            <list-item-radio
              v-model="checked"
              :items="showItems ? showItems : items"
              :field="field"
              notCheck
              isLongDescription
            />
          </div>
        </div>
        <loading v-else class="template-list-wrap" />
      </transition-toggle-contents>
      <div class="template-button">
        <button-large
          :text="$t('recipeList.sampleRecipe.getSelected')"
          :isDisabled="!checked || disableClick"
          @click="$emit('save-recipe-template', checked)"
        />
      </div>
    </div>
    <div class="template-detail">
      <div class="template-detail-inner">
        <Graph
          ref="graph"
          class="graph"
          :recipeLayers="recipeLayers"
          :graph="graph"
          :customblockList="customblockList"
          :edittable="null"
          isSave
        />
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import listItemRadio from '@/components/molecules/list-item-radio.vue'
import fuseSearch from '@/components/molecules/fuse-search.vue'
import ButtonLarge from '../../../atoms/button-large.vue'
import Graph from '@/components/organisms/recipe-detail/editor/graph.vue'
import Loading from '@/components/atoms/loading.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    texts,
    listItemRadio,
    fuseSearch,
    ButtonLarge,
    Graph,
    Loading,
    transitionToggleContents
  },
  props: {
    recipeTemplates: Object,
    recipeLayers: Object,
    loadingRecipeTemplates: Boolean,
    disableClick: Boolean,
    customblockList: Array
  },
  data() {
    return {
      field: [
        {
          label: this.$t('recipeList.field.name'),
          key: 'name'
        }
      ],
      option: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.2,
        keys: [
          {
            name: 'name.name',
            weight: 1
          }
        ]
      },
      checked: null,
      graph: null,
      showItems: null
    }
  },
  computed: {
    items() {
      const items = []
      const lists = Object.values(this.recipeTemplates)
      for (const k in lists) {
        const item = {
          name: {
            name: lists[k].name,
            description: lists[k].description
          },
          value: lists[k].id
        }
        items.push(item)
      }
      return items
    }
  },
  methods: {
    inputSearch(item) {
      this.showItems = item.result
    }
  },
  watch: {
    checked(newVal) {
      if (newVal) {
        this.graph = this.recipeTemplates[newVal].body
        this.$nextTick(() => this.$refs.graph.buildGraph())
      }
    },
    showItems() {
      this.$nextTick(function () {
        this.checked = null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.template {
  &-wrap {
    display: grid;
    grid-template-columns: minmax(adjustVW(594), 1fr) adjustVW(966);
    grid-column-gap: $space-medium;
    width: 100%;
    height: 100%;
  }
  &-main {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  &-title {
    flex-shrink: 0;
    margin: 0 0 $space-base;
  }
  &-search {
    flex-shrink: 0;
    margin: 0 0 $space-medium;
  }
  &-list {
    overflow-y: auto;
    flex-shrink: 1;
    flex-grow: 1;
    height: 100%;
    padding: 0 $space-base 0 0;
    margin: 0 0 $space-medium;
    @include scrollbar;
    &-wrap {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
  &-button {
    flex-shrink: 0;
  }
  &-detail {
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: $space-small;
    background: $background-sub;
    border-radius: adjustVW(16);
    &-inner {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      padding: $space-small;
      background-color: $background;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Ccircle%20cx%3D%221%22%20cy%3D%221%22%20r%3D%221%22%20style%3D%22fill%3A%23e2e2e2%22%2F%3E%3C%2Fsvg%3E');
      background-repeat: space;
      background-position: adjustVW(16);
      background-size: adjustVW(16);
      border-radius: adjustVW(16);
      box-shadow: $box-shadow-main;
    }
  }
}
</style>
