<template>
  <result-image-card
    ref="parent"
    class="image-vectorization-card"
    :imageUrl="body"
    :imageName="filename"
    :posBottom="posBottom"
    :posRight="posRight"
    @show-popup="closeListPopup"
  >
    <div class="vectorization-similarity-wrapper">
      <texts
        class="vectorization-similarity-title"
        size="small"
        :text="this.$i18n.t('inference.result.vectorization.image.similarity')"
      />

      <div
        class="vectorization-similarity-image-wrapper"
        @mouseleave="isLeaveImage()"
      >
        <div
          v-for="(item, index) in similarities"
          :key="index"
          class="vectorization-similarity-image-item"
        >
          <div
            class="vectorization-similarity-image-rank"
            :class="'vectorization-similarity-image-rank-' + (index + 1)"
          >
            <texts
              class="vectorization-similarity-image-rank-text"
              :text="index + 1"
              size="min"
              color="function"
            />
          </div>
          <image-wrap
            class="inference-result-image-target-image"
            :url="findImage(item.index)"
            @click.native="isHoverImage({ index: index, itemId: item.index })"
          />

          <div class="inference-result-image-target-popup">
            <popup-balloon-image
              :posBottom="posBottom"
              :posRight="posRight"
              :showPopup="showPopup === index"
              :popupContents="popupContents"
            />
          </div>
        </div>
      </div>
    </div>
  </result-image-card>
</template>

<script>
import resultImageCard from '@/components/molecules/inference/result/result-image-card.vue'
import texts from '@/components/atoms/text.vue'
import imageWrap from '@/components/atoms/image-wrap.vue'
import popupBalloonImage from '@/components/molecules/popup-balloon-image.vue'

export default {
  components: {
    imageWrap,
    popupBalloonImage,
    resultImageCard,
    texts
  },
  data() {
    return {
      showPopup: null,
      popupNumber: 1,
      popupContents: {
        body: '',
        name: '',
        width: 0,
        height: 0
      },
      element: null,
      imageWidth: 0,
      imageHeight: 0
    }
  },
  props: {
    filename: String,
    body: String,
    width: [Number, String],
    height: [Number, String],
    similarities: Array,
    posBottom: {
      type: Boolean,
      default: false
    },
    posRight: {
      type: Boolean,
      default: false
    },
    bodyList: Array
  },
  methods: {
    async isHoverImage({ index, itemId }) {
      const imageUrl = this.bodyList[itemId]
      this.element = new Image()
      this.element.onload = function () {
        this.imageWidth = this.element.naturalWidth
        this.imageHeight = this.element.naturalHeight
        this.showPopup = index
        this.popupNumber = index
        this.popupContents = {
          body: this.bodyList[itemId],
          name: this.similarities[index].filename,
          width: this.imageWidth,
          height: this.imageHeight
        }
      }.bind(this)
      this.closeParentPopup()
      this.element.src = 'data:image/png;base64,' + imageUrl
    },
    isLeaveImage() {
      this.showPopup = null
    },
    findImage(index) {
      return this.bodyList[index]
    },
    closeParentPopup() {
      this.$refs.parent.closePopup()
    },
    closeListPopup() {
      this.showPopup = null
    }
  }
}
</script>

<style lang="scss" scoped>
.image-vectorization-card {
  width: 100%;
  height: 100%;
}

.vectorization-similarity {
  &-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}

.vectorization-similarity-title {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-bottom: $space-base;
}

.vectorization-similarity-image-wrapper {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: $space-sub;
  grid-row-gap: $space-sub;
  width: 100%;
  height: 100%;
}

.vectorization-similarity-image-item {
  position: relative;
  width: adjustVW(100);
  height: adjustVW(56);
}

.inference-result-image-target-image {
  cursor: pointer;
}

.vectorization-similarity-image-rank {
  position: absolute;
  top: adjustVW(3);
  left: adjustVW(3);
  display: flex;
  align-items: center;
  justify-content: center;
  width: adjustVW(24);
  height: adjustVW(24);
  background-color: $border-gray;
  border-radius: 9in;
  &-1 {
    background-color: $key-color;
  }
  &-2 {
    background-color: #a94fb2;
  }
  &-3 {
    background-color: #ce9bd3;
  }
  &-text {
    line-height: 1;
  }
}
</style>
