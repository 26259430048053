<template>
  <page-top
    :title="pageTitle"
    :iconName="pageIcon"
    notScroll
    :isButton="isTrain ? true : false"
    :isTrain="isTrain"
    @click="$emit('page-back')"
  >
    <!-- page-top-sub -->
    <div class="top-function-wrap">
      <template v-if="checkEditable">
        <div class="top-function-button">
          <button-main
            :text="$t('recipeList.createRecipe.new')"
            type="emphasis"
            @click="newRecipe"
          />
        </div>
        <div class="top-function-button">
          <button-main
            :text="$t('recipeList.sampleRecipe.get')"
            type="sub"
            @click="$emit('get-recipe-template')"
          />
        </div>
      </template>
      <template v-else>
        <div class="top-function-button">
          <button-main
            :text="$t('recipeList.sampleRecipe.get')"
            type="emphasis"
            @click="$emit('get-recipe-template')"
          />
        </div>
        <div class="top-function-button">
          <button-main
            :text="$t('recipeList.createRecipe.demo')"
            type="sub"
            @click="newRecipe"
          />
        </div>
      </template>
      <div class="top-function-search">
        <fuse-search
          :placeholder="$t('recipeList.searchBox')"
          :option="option"
          :value="recipesFiltered"
          @input="$emit('input', $event)"
        />
      </div>
      <div class="top-function-menu">
        <menu-list
          :checked="checked"
          :menus="iconMenus"
          :isMenuShow="checked.length > 0"
          @menu-click="menuClick"
        />
      </div>
    </div>
  </page-top>
</template>

<script>
import pageTop from '@/components/atoms/page-top'
import fuseSearch from '@/components/molecules/fuse-search'
import ButtonMain from '@/components/atoms/button-main.vue'
import menuList from '@/components/molecules/menu-list'
import { checkExpiredEducation } from '@/lib/misc.js'
import { mapActions } from 'vuex'

export default {
  components: {
    pageTop,
    fuseSearch,
    ButtonMain,
    menuList
  },
  props: {
    checked: Array,
    recipesFiltered: Array,
    accountInfo: Object,
    isTrain: Boolean
  },
  data() {
    return {
      option: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.2,
        keys: [
          {
            name: 'name',
            weight: 0.5
          },
          {
            name: 'accountId',
            weight: 0.5
          }
        ],
        distance: 10000
      }
    }
  },
  methods: {
    ...mapActions('recipes', ['setEditRecipe']),
    changeTab(e) {
      this.$emit('change-tab', e)
      this.$emit('update:active-tab', e)
    },
    menuClick(e) {
      // これってここでやっていいんでしたっけ？
      this.$emit('menu-click', {
        name: e.activeMenu,
        checked: this.checked
      })
    },
    newRecipe() {
      this.setEditRecipe({
        recipe: null,
        from: null
      })
      if (!this.isTrain) {
        this.$router.push({
          name: 'recipeEdit'
        })
      } else {
        this.$router.push({
          name: 'trainRecipeEdit'
        })
      }
    }
  },
  computed: {
    disabledDeleteRecipe() {
      if (!this.checked || this.checked.length === 0) return true
      if (this.accountInfo?.organizationInfo?.role === 'admin') return false
      const recipes = this.recipesFiltered.filter((recipe) =>
        this.checked.some((id) => id === recipe.fullId)
      )
      if (
        recipes.some(
          (recipe) => recipe.accountId !== this.accountInfo.accountId
        )
      ) {
        return true
      } else {
        return false
      }
    },
    iconMenus() {
      return [
        {
          id: 0,
          iconName: 'train',
          value: 'useTraining',
          isMultiple: false,
          tips: {
            name: this.$t('recipeList.menu.useTraining')
          },
          disabled: !this.isTrain,
          hideItem: !this.isTrain
        },
        {
          id: 1,
          iconName: 'copy',
          value: 'copyNewRecipe',
          isMultiple: false,
          tips: {
            name: this.$t('recipeList.menu.copyNewRecipe')
          }
        },
        {
          id: 2,
          iconName: 'delete',
          value: 'delete',
          isMultiple: true,
          disabled: this.disabledDeleteRecipe,
          tips: {
            name: this.$t('common.deleteButton'),
            disabledName: this.$t(
              'common.disabled.organization.otherAccountItemDelete'
            )
          }
        },
        {
          id: 3,
          iconName: 'projectMove', // project
          value: 'project',
          isMultiple: true, // 複数選択可能かどうか
          tips: {
            name: this.$t('common.menu.projectMove')
          },
          disabled: this.isTrain,
          hideItem: this.isTrain
        },
        {
          id: 4,
          iconName: 'projectAdd', // project
          value: 'project-create',
          isMultiple: true, // 複数選択可能かどうか
          tips: {
            name: this.$t('common.menu.projectCreateMove')
          },
          disabled: this.isTrain,
          hideItem: this.isTrain
        }
      ]
    },
    pageTitle() {
      if (this.isTrain) {
        return this.$t('recipeList.inTraining')
      } else {
        return this.$t('recipeList.title')
      }
    },
    pageIcon() {
      if (this.isTrain) {
        return 'backward'
      } else {
        return 'recipe'
      }
    },
    checkEditable() {
      if (this.accountInfo.plan === 'free') return false
      if (checkExpiredEducation(this.accountInfo)) return false
      return true
    }
  }
}
</script>
<style lang="scss" scoped>
.top-function {
  &-wrap {
    position: relative;
    display: flex;
    align-items: center;
  }
  &-main {
    display: flex;
  }
  &-button {
    margin: 0 $space-medium 0 0;
  }
  &-search {
    width: adjustVW(320);
  }
  &-menu {
    margin-left: auto;
  }
}
</style>
