<template>
  <transition-toggle-contents>
    <div v-if="hasMetrics && !loading && checkTimeseriesColumn" class="metrics">
      <div class="metrics-title">
        <texts
          :text="$t('trainedAiDetails.timeseries.metricsTitle')"
          color="gray"
          size="small"
        />
      </div>
      <div class="metrics-list">
        <div class="metrics-value">
          <div class="metrics-value-title">
            <texts
              class="metrics-value-name"
              :text="$t('trainedAi.metrics.test_rmse')"
              color="gray"
              size="small"
            />
            <div v-if="!toggleTipsAfter" class="metrics-popper">
              <tooltip-popover
                trigger="hover"
                placement="right-end"
                :autoHide="false"
                :offset="12"
                :disabled="toggleTips"
              >
                <template slot="main">
                  <icon iconName="info" size="min" />
                </template>
                <template slot="popup">
                  <accuracy-tooltip-inner :metrics="rmseInfo" />
                </template>
              </tooltip-popover>
            </div>
          </div>
          <metrics-text :value="rmseValue" />
        </div>
        <div class="metrics-value metrics-value-last">
          <div class="metrics-value-title">
            <texts
              class="metrics-value-name"
              :text="$t('trainedAi.metrics.test_mae')"
              color="gray"
              size="small"
            />
            <div v-if="!toggleTipsAfter" class="metrics-popper">
              <tooltip-popover
                trigger="hover"
                placement="right-end"
                :autoHide="false"
                :offset="12"
                :disabled="toggleTips"
              >
                <template slot="main">
                  <icon iconName="info" size="min" />
                </template>
                <template slot="popup">
                  <accuracy-tooltip-inner :metrics="maeInfo" />
                </template>
              </tooltip-popover>
            </div>
          </div>
          <metrics-text :value="maeValue" />
        </div>
      </div>
    </div>
  </transition-toggle-contents>
</template>

<script>
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import metricsText from '@/components/atoms/metrics-text'
import icon from '@/components/atoms/icon.vue'
import tooltipPopover from '@/components/molecules/tooltip-popover'
import accuracyTooltipInner from '@/components/molecules/accuracy-tooltip-inner.vue'
import * as metricsDefsByModelType from '@/components/organisms/trained-ai-detail/metricsDefs'

export default {
  components: {
    transitionToggleContents,
    metricsText,
    icon,
    tooltipPopover,
    accuracyTooltipInner
  },
  props: {
    trainedAi: Object,
    loading: Boolean,
    checkTimeseriesColumn: Boolean,
    changeDispType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      toggleTips: false,
      toggleTipsAfter: false,
      isOpen: false
    }
  },
  computed: {
    hasMetrics() {
      // 時系列予測で指標として設定されうるのはRMSEとMAEだけ
      return (
        this.trainedAi.result &&
        ((this.trainedAi.result?.rmse &&
          typeof this.trainedAi.result.rmse === 'number') ||
          (this.trainedAi.result?.mae &&
            typeof this.trainedAi.result.mae === 'number'))
      )
    },
    rmseValue() {
      return this.trainedAi.result.rmse
    },
    rmseInfo() {
      const metricsDefs = metricsDefsByModelType.timeseries(this.$t.bind(this))
      return metricsDefs.test_rmse
    },
    maeValue() {
      return this.trainedAi.result.mae
    },
    maeInfo() {
      const metricsDefs = metricsDefsByModelType.timeseries(this.$t.bind(this))
      return metricsDefs.test_mae
    }
  },
  watch: {
    changeDispType(bool) {
      if (bool) {
        this.toggleTips = true
        this.$nextTick(() => {
          this.toggleTipsAfter = true
          this.$nextTick(() => {
            this.toggleTipsAfter = false
            this.$nextTick(() => {
              this.toggleTips = false
            })
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.metrics {
  padding: $space-small;
  background: $background;
  border-radius: adjustVW(16);
  box-shadow: $box-shadow-main;
  &-title {
    display: flex;
    justify-content: space-between;
    margin: 0 0 $space-base;
  }
  &-list {
    display: flex;
    flex-direction: row;
  }
  &-value {
    margin: 0 $space-medium 0 0;
    &-name {
      margin: 0 $space-small 0 0;
    }
    &-last {
      margin: 0;
    }
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &-popper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0; // display:inline-blockの調整
  }
}
</style>
