<template>
  <popup-array
    :popupDefs="popupDefs"
    :showPopup="showPopup"
    :noClose="disableClick"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #deleteProject>
      <delete-project
        :projectInfo="deleteProject.project"
        :disableClick="disableClick"
        @delete-project-confirm="$emit('delete-project-confirm', $event)"
        @close-modal="$emit('close-modal', 'deleteProject')"
      />
    </template>
  </popup-array>
</template>

<script>
import popupArray from '@/components/molecules/popup/popup-array'
import deleteProject from './popup/delete-project'

export default {
  components: {
    popupArray,
    deleteProject
  },
  props: {
    showPopup: Array,
    deleteProject: Object,
    projectInfo: Object,
    disableClick: Boolean
  },
  computed: {
    popupDefs() {
      return {
        deleteProject: {
          title: this.$t('common.deleteConfirm')
        }
      }
    }
  }
}
</script>
