<template>
  <div class="template-wrap">
    <div class="template-main">
      <div class="template-title">
        <texts
          :text="$t('serviceList.popup.addNewService.recipeSelectTitle')"
        />
      </div>
      <transition-toggle-contents>
        <div v-if="recipes" class="template-list-wrap">
          <div class="template-search">
            <fuse-search
              :placeholder="$t('recipeList.searchBox')"
              :option="option"
              :value="recipeList"
              gray
              @input="inputSearch"
            />
          </div>
          <div class="template-list">
            <list-item-slot
              :items="showItems ? showItems : recipeList"
              :field="field"
              type="button"
              @list-click="listClick"
            >
              <template v-slot:name="entry">
                <texts
                  class="template-item-name"
                  :text="entry.entry.name"
                  isBold
                />
                <description-tooltip
                  :description="entry.entry.description"
                  :index="entry.listIndex"
                />
              </template>
            </list-item-slot>
          </div>
        </div>
        <loading v-else class="template-list-wrap" />
      </transition-toggle-contents>
      <div class="template-button">
        <button-large
          :text="$t('recipeDetail.selectRecipe')"
          :isDisabled="
            !checked ||
              disableClick ||
              checkOptimizationRecipeUse ||
              checkNotAvailableCustomblock
          "
          @click="$emit('select-recipe', checked)"
        />
      </div>
    </div>
    <div class="template-detail">
      <div class="template-detail-inner">
        <transition-toggle-contents>
          <text-box
            v-if="checked && errorRecipeText"
            class="template-detail-error"
            :text="errorRecipeText"
            isError
            isFolded
          />
        </transition-toggle-contents>
        <Graph
          ref="graph"
          class="graph"
          :recipeLayers="recipeLayers"
          :graph="graph"
          :customblockList="customblockList"
          isSave
        />
      </div>
    </div>
  </div>
</template>
<script>
import descriptionTooltip from '@/components/molecules/description-tooltip'
import texts from '@/components/atoms/text'
import textBox from '@/components/atoms/text-box'
import listItemSlot from '@/components/molecules/list-item-slot'
import fuseSearch from '@/components/molecules/fuse-search.vue'
import ButtonLarge from '@/components/atoms/button-large.vue'
import Graph from '@/components/organisms/recipe-detail/editor/graph.vue'
import Loading from '@/components/atoms/loading.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

import { usedCustomblockList } from '@/lib/inference.js'

export default {
  components: {
    descriptionTooltip,
    texts,
    textBox,
    listItemSlot,
    fuseSearch,
    ButtonLarge,
    Graph,
    Loading,
    transitionToggleContents
  },
  data() {
    return {
      checked: null,
      graph: null,
      showItems: null,
      option: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.2,
        keys: [
          {
            name: 'name',
            weight: 1
          }
        ]
      }
    }
  },
  props: {
    recipeDetail: Object,
    recipes: Object,
    recipeLayers: Object,
    disableClick: Boolean,
    loadRecipeDetail: Boolean,
    isOptimizationService: Boolean,
    customblockList: Array
  },
  methods: {
    listClick(e) {
      const id = e.id
      const accountId = this.recipeList.find((item) => item.id === id).accountId
      this.checked = id
      this.$emit('list-click', { id: id, accountId: accountId })
    },
    inputSearch(item) {
      this.showItems = item.result
    }
  },
  computed: {
    recipeList() {
      let target = Object.values(this.recipes)
      target = target.sort((x, y) => (x.updateTime > y.updateTime ? -1 : 1))
      return target
    },
    field() {
      return [
        {
          label: this.$t('recipeList.field.name'),
          key: 'name'
        }
      ]
    },
    checkOptimization() {
      if (!this.recipeDetail) return false
      return this.recipeDetail?.is_optimization
    },
    checkOptimizationRecipeUse() {
      if (this.isOptimizationService) {
        return !this.checkOptimization
      } else {
        return this.checkOptimization
      }
    },
    checkNotAvailableCustomblock() {
      if (!this.recipeDetail) return false
      const targets = usedCustomblockList(this.recipeDetail.body.layers)
      if (targets.length === 0) return false
      const check = !targets.some((target) => {
        return this.customblockList.some((customblock) => {
          return target.customblock_id === customblock.customblock_id
        })
      })
      return check
    },
    checkTimeTransformerV2() {
      if (!this.recipes) return false
      const targetRecipe = Object.values(this.recipes).find((recipe) => {
        return recipe.id === this.checked
      })
      return targetRecipe.type === 'TIME_TRANSFORMER2'
    },
    errorRecipeText() {
      if (this.checkNotAvailableCustomblock) {
        return this.$t('common.disabled.NOT_AVAILABLE_CUSTOMBLOCK_FOR_RECIPE')
      } else if (this.isOptimizationService && !this.checkOptimization) {
        return this.$t(
          'serviceList.popup.addNewService.progress.recipe.descOptimization'
        )
      } else if (!this.isOptimizationService && this.checkOptimization) {
        return this.$t(
          'serviceList.popup.addNewService.progress.recipe.descNotUseOptimization'
        )
      } else {
        return ''
      }
    }
  },
  watch: {
    recipeDetail(newVal) {
      this.graph = newVal.body
      this.$nextTick(() => this.$refs.graph.buildGraph())
    }
  }
}
</script>

<style lang="scss" scoped>
.template {
  &-wrap {
    display: grid;
    grid-template-columns: minmax(adjustVW(594), 1fr) adjustVW(966);
    grid-column-gap: $space-medium;
    width: 100%;
    height: 100%;
  }
  &-main {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  &-title {
    flex-shrink: 0;
    margin: 0 0 $space-base;
  }
  &-search {
    flex-shrink: 0;
    margin: 0 0 $space-medium;
  }
  &-list {
    overflow-y: auto;
    flex-shrink: 1;
    flex-grow: 1;
    height: 100%;
    padding: 0 $space-base 0 0;
    margin: 0 0 $space-medium;
    @include scrollbar;
    &-wrap {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
  &-button {
    flex-shrink: 0;
  }
  &-detail {
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: $space-small;
    background: $background-sub;
    border-radius: adjustVW(16);
    &-inner {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      padding: $space-small;
      background-color: $background;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Ccircle%20cx%3D%221%22%20cy%3D%221%22%20r%3D%221%22%20style%3D%22fill%3A%23e2e2e2%22%2F%3E%3C%2Fsvg%3E');
      background-repeat: space;
      background-position: adjustVW(16);
      background-size: adjustVW(16);
      border-radius: adjustVW(16);
      box-shadow: $box-shadow-main;
    }
    &-error {
      position: absolute;
      top: $space-small;
      right: $space-small;
      max-width: adjustVW(480);
    }
  }
  &-item {
    &-name {
      overflow: hidden;
      margin-bottom: $space-text;
    }
  }
}
.graph {
  cursor: not-allowed;
}
</style>
