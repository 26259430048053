import { render, staticRenderFns } from "./plan-card-base.vue?vue&type=template&id=998ce760&scoped=true&"
import script from "./plan-card-base.vue?vue&type=script&lang=js&"
export * from "./plan-card-base.vue?vue&type=script&lang=js&"
import style0 from "./plan-card-base.vue?vue&type=style&index=0&id=998ce760&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "998ce760",
  null
  
)

export default component.exports