<template>
  <div>
    <popup-array
      :popupDefs="popupDefs"
      :showPopup="showPopup"
      :noClose="disableClick"
      @close-modal="$emit('close-modal', $event)"
    >
      <template #deleteTrainedAi>
        <trained-ai-list-popup-delete-trained-ai
          v-bind="deleteTrainedAi"
          :disableClick="disableClick"
          @close-modal="$emit('close-modal', $event)"
          @delete="$emit('delete', $event)"
          @cancel-delete="$emit('close-modal', 'deleteTrainedAi')"
          @check-service="$emit('check-service')"
        />
      </template>
      <template #deleteServiceCheck>
        <trained-ai-list-popup-delete-service-check
          v-bind="deleteTrainedAi"
          :disableClick="disableClick"
          @close-modal="$emit('close-modal', $event)"
          @delete="$emit('delete', $event)"
        />
      </template>
      <template #upperLimitTrainedAis>
        <trained-ai-list-popup-upper-limit-trained-ai
          @close-modal="$emit('close-modal', $event)"
        />
      </template>
      <template #intoProject>
        <into-project
          :type="$t('common.trainedAI')"
          :targets="intoProject.targets"
          :newProject="intoProject.newProject"
          :disableClick="disableClick"
          @cancel="$emit('close-modal', 'intoProject')"
          @add-to-project="$emit('add-to-project', $event)"
        />
      </template>
    </popup-array>
  </div>
</template>

<script>
import trainedAiListPopupDeleteTrainedAi from './trained-ai-list-popup-delete-trained-ai'
import trainedAiListPopupDeleteServiceCheck from './trained-ai-list-popup-delete-service-check'
import trainedAiListPopupUpperLimitTrainedAi from './trained-ai-list-popup-upper-limit-trained-ai'
import { popupArray } from '@/components/molecules/popup'
import IntoProject from '@/components/organisms/common-popups/into-project.vue'

export default {
  components: {
    trainedAiListPopupDeleteTrainedAi,
    trainedAiListPopupDeleteServiceCheck,
    trainedAiListPopupUpperLimitTrainedAi,
    IntoProject,
    popupArray
  },
  computed: {
    type() {
      return this.$t('common.trainedAI')
    },
    popupDefs() {
      let intoProject
      if (this.intoProject?.newProject) {
        intoProject = {
          title: this.$t('common.popup.intoNewProject.title', {
            type: this.type
          })
        }
      } else {
        intoProject = {
          title: this.$t('common.popup.intoProject.title', { type: this.type })
        }
      }
      return {
        deleteTrainedAi: {
          title: this.$t('common.deleteConfirm')
        },
        deleteServiceCheck: {
          title: this.$t('trainedAiDetails.popup.serviceDeleteCheck.title')
        },
        upperLimitTrainedAis: {
          title: this.$t('trainedAiList.upperLimit.title')
        },
        intoProject
      }
    }
  },
  props: {
    showPopup: Array,
    deleteTrainedAi: Object,
    project: Object,
    intoProject: Object,
    disableClick: Boolean
  }
}
</script>
