<template>
  <div class="rside-wrap">
    <div class="rside-tab">
      <tab-list
        class="rside-tab-inner"
        :tabs="tabs"
        :activeTabProps="activeTab"
        @change-tab="$emit('change-tab', $event)"
      />
      <slider-with-scrollbutton v-if="columnStatistics">
        <div class="rside-tab-statistics-inner">
          <template v-for="(item, key) in columnStatistics">
            <div :key="key" class="rside-tab-statistics-item">
              <texts
                :text="$t('statistic.' + item.name)"
                size="min"
                color="gray"
              />
              <texts :text="makeStaticsValue(item)" size="small" />
            </div>
          </template>
        </div>
      </slider-with-scrollbutton>
    </div>
    <div class="rside-graph">
      <div
        v-show="
          visibleContents == 'histogram' && !loadingTabs && !loadingHistogram
        "
        class="rside-graph-body"
      >
        <div
          v-if="graph[columnName] && graph[columnName].unique"
          class="rside-no-graph"
        >
          <images imageName="showGraph" class="rside-no-graph-image" />
          <texts
            class="rside-no-graph-text"
            :text="
              $t('preprocessing.applyingDataset.noGraph', {
                columnName: columnName
              })
            "
            isShowAll
          />
        </div>
        <div
          v-else-if="graph[columnName] && graph[columnName].lotUnique"
          class="rside-no-graph"
        >
          <images imageName="showGraph" class="rside-no-graph-image" />
          <texts
            class="rside-no-graph-text"
            :text="
              $t('preprocessing.applyingDataset.lotUniqueGraph', {
                columnName: columnName
              })
            "
            isShowAll
          />
        </div>
        <graph-bar
          v-else-if="graph[columnName]"
          ref="barchart"
          class="rside-graph-plot"
          :show-legends="false"
          :data="graphData"
        />
      </div>

      <div
        v-show="visibleContents == 'boxPlot' && !loadingGraph && !loadingTabs"
        class="rside-graph-body"
      >
        <box-plot
          v-if="showBoxPlotGraph"
          class="rside-graph-plot"
          :data="boxPlotData"
        />

        <div v-else class="rside-no-graph">
          <images imageName="showGraph" class="rside-no-graph-image" />
          <texts
            class="rside-no-graph-text"
            :text="
              $t('preprocessing.applyingDataset.noBoxplot', {
                columnName: columnName
              })
            "
            isShowAll
          />
        </div>
      </div>

      <div
        v-if="loadingHistogram || loadingGraph || loadingTabs"
        class="rside-graph-loading"
      >
        <loading class="rside-graph-loading-item" />
        <texts
          :text="$t('preprocessing.applyingDataset.graphLoading')"
          size="small"
          color="gray"
        />
      </div>
    </div>
  </div>
</template>

<script>
import loading from '@/components/atoms/loading'
import tabList from '@/components/molecules/tab-list'
import GraphBar from '@/components/molecules/bar'
import boxPlot from '@/components/organisms/graph/boxplot.vue'
import sliderWithScrollbutton from '@/components/molecules/slider-with-scrollbutton'
import texts from '@/components/atoms/text'
import Images from '@/components/atoms/images.vue'

export default {
  components: {
    loading,
    tabList,
    GraphBar,
    boxPlot,
    sliderWithScrollbutton,
    texts,
    Images
  },
  data() {
    return {
      loadingTabs: false
    }
  },
  props: {
    columnName: String,
    tabs: Array,
    activeTab: Number,
    visibleContents: String,
    graph: Object,
    boxPlotData: Object,
    statistics: Object,
    loadingGraph: Boolean,
    loadingHistogram: Boolean
  },
  computed: {
    graphData() {
      return [
        {
          name: this.columnName,
          values: this.graph[this.columnName]?.counts,
          color: '#850491'
        }
      ]
    },
    columnStatistics() {
      const array = this.statistics.table.map((item) => {
        return {
          name: item['/'],
          value: item[this.columnName]
        }
      })
      if (!array.find((item) => item.value)) {
        return null
      }
      return array
    },
    showBoxPlotGraph() {
      const onlyUniqueValues = this.graph[this.columnName]?.unique
      const hasBoxPlotInfo = Object.keys(this.boxPlotData).length > 0

      return !onlyUniqueValues && hasBoxPlotInfo
    }
  },
  methods: {
    makeStaticsValue: function (info) {
      return info.value ?? 'ー'
    }
  },
  watch: {
    activeTab() {
      this.loadingTabs = true
      this.$nextTick(() => {
        this.loadingTabs = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.rside {
  &-wrap {
    display: grid;
    grid-template-areas:
      'tab'
      'graph';
    grid-template-rows: auto 1fr;
    height: 100%;
    padding: $space-small;
    row-gap: 0;
  }
  &-tab {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    grid-column-gap: $space-medium;
    width: 100%;
    grid-area: tab;
    &-inner {
      flex-shrink: 0;
      width: fit-content;
    }
    &-statistics {
      &-inner {
        display: flex;
        grid-column-gap: $space-sub;
      }
      &-item {
        overflow: hidden;
        flex-shrink: 0;
        width: adjustVW(96);
        padding-right: $space-sub;
        border-right: $border-main;
        &:last-of-type {
          padding: 0;
          border: none;
        }
      }
    }
  }
  &-graph {
    position: relative;
    overflow: hidden;
    height: 100%;
    grid-area: graph;
    &-text {
      padding: $space-small;
    }
    &-plot {
      width: 100%;
      height: 100%;
    }
    &-body {
      width: 100%;
      height: 100%;
      animation: showContent $transition-base;
    }
    &-loading {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-row-gap: $space-medium;
      width: 100%;
      height: 100%;
      background-color: #fff;
      animation: showContent $transition-base;
      &-item {
        height: fit-content;
      }
    }
  }
  &-no-graph {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    &-image {
      width: adjustVW(360);
      margin-bottom: $space-medium;
    }
    &-text {
      text-align: center;
    }
  }
}

@keyframes showContent {
  0% {
    opacity: 0;
    transform: translateX($space-base);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
