<template>
  <div class="sample-code">
    <div class="sample-code-tab-wrap">
      <tab-list
        :tabs="tabItems"
        :activeTab="activeTab"
        :showIcon="false"
        @change-tab="selectTab"
      />
    </div>
    <transition-toggle-contents>
      <div
        v-if="activeTab === 'sampleCode'"
        key="sampleCode"
        class="sample-code-select-box"
      >
        <select-box v-model="selectedLanguage" :items="languageTabItems" />
      </div>
      <div v-else key="dummy" />
    </transition-toggle-contents>
    <transition-toggle-contents>
      <div
        v-if="activeTab === 'sampleCode'"
        key="sampleCode"
        class="sample-code-tab-wrap-sample-code"
      >
        <div class="sample-code-wrap">
          <!-- requestHeaders -->
          <div class="sample-code-block">
            <div
              v-if="showedSampleCode"
              key="python"
              class="sample-code-inner-sample-code"
            >
              <code-block :baseCode="showedSampleCode" />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="activeTab === 'requestHeader'"
        key="requestHeader"
        class="sample-code-wrap"
      >
        <!-- requestHeaders -->
        <div class="sample-code-block">
          <div class="sample-code-inner">
            <div class="sample-code-item">
              <texts text="x-matrixflow-secretkey" />
              <texts text="string" size="small" color="gray" />
            </div>
            <div class="sample-code-item">
              <div class="sample-code-item-icon">
                <copy-box
                  class="sample-code-item-icon-copy"
                  :text="requestHeaders.secretKey"
                  isTipsBottom
                />
              </div>
            </div>
          </div>
          <div class="sample-code-inner">
            <div class="sample-code-item">
              <texts text="Content-Type" />
              <texts text="string" size="small" color="gray" />
            </div>
            <div class="sample-code-item">
              <texts :text="requestHeaders.contentType" />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="activeTab === 'requestBody'"
        key="requestBody"
        class="sample-code-wrap"
      >
        <!-- requestBody -->
        <div class="sample-code-block sample-code-block-scroll">
          <div
            v-for="(item, index) in requestBody.items"
            :key="index"
            class="sample-code-inner"
          >
            <div class="sample-code-item">
              <texts :text="item.name" />
              <texts :text="item.type" size="small" color="gray" />
            </div>
            <div class="sample-code-item">
              <texts :text="item.contents" />
              <texts
                v-if="item.contentsSub"
                class="sample-code-item-sub"
                :text="item.contentsSub"
                size="small"
                color="gray"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="activeTab === 'responses'"
        key="responses"
        class="sample-code-wrap"
      >
        <!-- responses -->
        <div class="sample-code-block">
          <div class="sample-code-inner">
            <div class="sample-code-item">
              <texts :text="responses.type" />
            </div>
            <div class="sample-code-item sample-code-code">
              <code-block :baseCode="responses.code" />
            </div>
          </div>
        </div>
      </div>
    </transition-toggle-contents>
  </div>
</template>
<script>
import texts from '@/components/atoms/text'
import tabList from '@/components/molecules/tab-list'
import codeBlock from '@/components/molecules/code-block'
import selectBox from '@/components/molecules/select-box'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import CopyBox from '@/components/molecules/copy-box.vue'

export default {
  components: {
    texts,
    tabList,
    codeBlock,
    selectBox,
    transitionToggleContents,
    CopyBox
  },
  data() {
    return {
      isTipsShow: false,
      activeTab: 'sampleCode',
      selectedLanguage: 'curl',
      responsesType: 'num'
    }
  },
  props: {
    apiUrl: String,
    secretKey: String,
    service: Object,
    selectedApiType: String
  },
  methods: {
    selectTab(e) {
      this.activeTab = e
    }
  },
  computed: {
    languages() {
      return ['curl', 'python']
    },
    tabItems() {
      const res = [
        {
          id: 0,
          name: this.$t('serviceDetail.sampleCode.sampleCode'),
          value: 'sampleCode'
        },
        {
          id: 1,
          name: 'RequestHeader',
          value: 'requestHeader'
        }
      ]
      if (this.checkShowStartApi) {
        res.push(
          {
            id: 2,
            name: 'RequestBody',
            value: 'requestBody'
          },
          {
            id: 3,
            name: 'Responses',
            value: 'responses'
          }
        )
      } else {
        res.push({
          id: 2,
          name: 'Responses',
          value: 'responses'
        })
      }
      return res
    },
    languageTabItems() {
      return [
        {
          id: 0,
          name: 'curl',
          value: 'curl'
        },
        {
          id: 1,
          name: 'python',
          value: 'python'
        }
      ]
    },
    responsesTabItems() {
      return [
        {
          id: 0,
          name: this.$t('serviceDetail.sampleCode.responses.tabs.num'),
          value: 'num'
        },
        {
          id: 1,
          name: this.$t('serviceDetail.sampleCode.responses.tabs.text'),
          value: 'text'
        }
      ]
    },
    recipeType() {
      if (!this.service || !this.service?.recipe) return null
      return this.service.recipe.type
    },
    checkShowStartApi() {
      return (
        this.selectedApiType === 'start' || this.selectedApiType === 'restart'
      )
    },
    showedSampleCode() {
      if (this.checkShowStartApi) {
        return this.sampleCode
      } else {
        return this.sampleCodeStatusStop
      }
    },
    sampleCode() {
      const contentNone = this.selectedLanguage === 'curl' ? 'null' : 'None'
      const contentFalse = this.selectedLanguage === 'curl' ? 'false' : 'False'
      const contentComment = this.selectedLanguage === 'curl' ? '//' : '#'

      let trainedAiInfo = ''
      let trainedAiIdText = ''
      if (this.selectedApiType === 'start') {
        trainedAiInfo = `
  "trained_ai": {
    "name": "${this.$t(
      'serviceDetail.sampleCode.optimizationInfo.trainedAiName'
    )}",
    "description": "${this.$t(
      'serviceDetail.sampleCode.optimizationInfo.trainedAiDescription'
    )}"
  },`
      }
      if (this.selectedApiType === 'restart') {
        trainedAiIdText = `,
  "trained_ai_id": "${this.$t(
    'serviceDetail.sampleCode.optimizationInfo.trainedAiId'
  )}"`
      }

      const json = `{
  "data_name": "@your_data_name",
  "train_config": {
    "data": {
      "ratio": 0.1
    }
  },${trainedAiInfo}
  "optimization_conditions": {
    "column_conditions": [ ${contentComment} ${this.$t(
        'serviceDetail.sampleCode.optimizationInfo.columnConditions.title'
      )}
      {
        "column": "${this.$t(
          'serviceDetail.sampleCode.optimizationInfo.columnConditions.minimize.column'
        )}",
        "condition": {
          "type": "minimize", ${contentComment} ${this.$t(
        'serviceDetail.sampleCode.optimizationInfo.columnConditions.minimize.desc'
      )}
          "params": ${contentNone}
        },
        "integer": ${contentFalse} ${contentComment} ${this.$t(
        'serviceDetail.sampleCode.optimizationInfo.columnConditions.integer'
      )}
      },
      {
        "column": "${this.$t(
          'serviceDetail.sampleCode.optimizationInfo.columnConditions.maximize.column'
        )}",
        "condition": {
          "type": "maximize", ${contentComment} ${this.$t(
        'serviceDetail.sampleCode.optimizationInfo.columnConditions.maximize.desc'
      )}
          "params": ${contentNone}
        },
        "integer": ${contentFalse}
      },
      {
        "column": "${this.$t(
          'serviceDetail.sampleCode.optimizationInfo.columnConditions.closer.column'
        )}",
        "condition": {
          "type": "closer", ${contentComment} ${this.$t(
        'serviceDetail.sampleCode.optimizationInfo.columnConditions.closer.desc'
      )}
          "params": {
            "target": 12 ${contentComment} ${this.$t(
        'trainedAi.optimization.conditions.closerSelect'
      )}
          }
        },
        "integer": ${contentFalse}
      }
    ],
    "expressions": [ ${contentComment} ${this.$t(
        'serviceDetail.sampleCode.optimizationInfo.expressions.title'
      )}
      {
        ${contentComment} ${this.$t(
        'serviceDetail.sampleCode.optimizationInfo.expressions.conditions'
      )}
        "condition": {
          "type": "maximize",
          "params": ${contentNone}
        },
        ${contentComment} ${this.$t(
        'serviceDetail.sampleCode.optimizationInfo.expressions.desc'
      )}
        "expression": "\\"${this.$t(
          'serviceDetail.sampleCode.optimizationInfo.expressions.columnName1'
        )}\\" + \\"${this.$t(
        'serviceDetail.sampleCode.optimizationInfo.expressions.columnName2'
      )}\\""
      }
    ],
    ${contentComment} ${this.$t(
        'serviceDetail.sampleCode.optimizationInfo.optimizationColumns.title'
      )}
    "optimization_columns": ["${this.$t(
      'serviceDetail.sampleCode.optimizationInfo.optimizationColumns.column1'
    )}", "${this.$t(
        'serviceDetail.sampleCode.optimizationInfo.optimizationColumns.column2'
      )}"]
  }${trainedAiIdText}
}`

      if (this.selectedLanguage === 'curl') {
        const params = `-H "Content-Type: application/json" -d @post.json`
        const postFile = `

- post.json
${JSON.parse(JSON.stringify(json))}`

        return `curl -X POST ${params} -H "x-matrixflow-secretkey: ${this.secretKey}" ${this.apiUrl} ${postFile}`
      } else if (this.selectedLanguage === 'python') {
        return `import requests # ${this.$t(
          'serviceDetail.sampleCode.installInfo'
        )}
import pprint

url = "${this.apiUrl}"

headers = {
    "x-matrixflow-secretkey": "${this.secretKey}",
}

params = ${JSON.parse(JSON.stringify(json))}

response = requests.post(url, json=params, headers=headers)

print("status_code:", response.status_code)

pprint.pprint(response.json())`
      }
      return null
    },
    sampleCodeStatusStop() {
      let type = 'GET'
      switch (this.selectedApiType) {
        case 'start':
          return null
        case 'restart':
          return null
        case 'status':
          type = 'GET'
          break
        case 'stop':
          type = 'POST'
          break
        default:
          return null
      }

      const jobIDDescription = this.$t(
        'serviceDetail.sampleCode.jobIdDescriptionOptimization'
      )
      if (this.selectedLanguage === 'curl') {
        const base = `curl -X ${type} -H "x-matrixflow-secretkey: ${this.secretKey}" ${this.apiUrl}`
        return `${base}\n${jobIDDescription}`
      } else {
        const typeLowerCase = type.toLowerCase()
        const base = `import requests # ${this.$t(
          'serviceDetail.sampleCode.installInfo'
        )}
import pprint

# ${jobIDDescription}
url = "${this.apiUrl}"

headers = {
    "x-matrixflow-secretkey": "${this.secretKey}",
}

response = requests.${typeLowerCase}(url, headers=headers)

print("status_code:", response.status_code)

pprint.pprint(response.json())`
        return base
      }
    },
    requestHeaders() {
      return {
        secretKey: `${this.secretKey}`,
        contentType: 'application/json'
      }
    },
    requestBody() {
      const items = {
        items: [
          {
            name: 'data_name',
            contents: this.$t(
              'serviceDetail.sampleCode.requestBody.optimizationItems.dataName.main'
            ),
            contentsSub: this.$t(
              'serviceDetail.sampleCode.requestBody.learnItems.dataNameSub'
            ),
            type: 'string'
          },
          {
            name: 'train_config',
            contents: this.$t(
              'serviceDetail.sampleCode.requestBody.learnItems.config'
            ),
            contentsSub: this.$t(
              'serviceDetail.sampleCode.requestBody.learnItems.configSub'
            ),
            type: 'string'
          },
          {
            name: 'optimization_conditions',
            contents: this.$t('trainedAi.optimization.conditions.titleResult'),
            contentsSub: this.$t(
              'serviceDetail.sampleCode.requestBody.learnItems.configSub'
            ),
            type: 'string'
          }
        ]
      }
      if (this.selectedApiType === 'start') {
        const trainParams = [
          {
            name: 'trained_ai.name',
            contents: this.$t(
              'serviceDetail.sampleCode.optimizationInfo.trainedAiName'
            ),
            contentsSub: this.$t(
              'serviceDetail.sampleCode.requestBody.learnItems.optional'
            ),
            type: 'string'
          },
          {
            name: 'trained_ai.description',
            contents: this.$t(
              'serviceDetail.sampleCode.optimizationInfo.trainedAiDescription'
            ),
            contentsSub: this.$t(
              'serviceDetail.sampleCode.requestBody.learnItems.optional'
            ),
            type: 'string'
          }
        ]
        items.items.push(...trainParams)
      }
      if (this.selectedApiType === 'restart') {
        items.items.push({
          name: 'trained_ai_id',
          contents: this.$t(
            'serviceDetail.sampleCode.requestBody.optimizationItems.trainedAiId.main'
          ),
          contentsSub: this.$t(
            'serviceDetail.sampleCode.requestBody.optimizationItems.trainedAiId.sub'
          ),
          type: 'string'
        })
      }
      return items
    },
    responses() {
      let response = `{
  "name": "${this.$t(
    'serviceDetail.sampleCode.optimizationInfo.trainedAiName'
  )}", 
  "description": "${this.$t(
    'serviceDetail.sampleCode.optimizationInfo.trainedAiDescription'
  )}", 
  "job_id": "${this.$t(
    'serviceDetail.sampleCode.responses.optimizationStartItem.jobId'
  )}",
}`
      if (this.selectedApiType === 'status') {
        response = `{
  "status": "${this.$t(
    'serviceDetail.sampleCode.responses.optimizationStatusItems.status'
  )}",
  "n_iter": ${this.$t(
    'serviceDetail.sampleCode.responses.learningStatusItems.n_iter'
  )},
  "cur_iter": ${this.$t(
    'serviceDetail.sampleCode.responses.learningStatusItems.cur_iter'
  )},
  "n_prediction_column": ${this.$t(
    'serviceDetail.sampleCode.responses.optimizationStatusItems.n_prediction_column'
  )},
  "cur_prediction_column": ${this.$t(
    'serviceDetail.sampleCode.responses.optimizationStatusItems.cur_prediction_column'
  )},
  "trained_ai_id": "${this.$t(
    'serviceDetail.sampleCode.responses.optimizationStatusItems.trained_ai_id'
  )}",
  "optimization_conditions_id": "${this.$t(
    'serviceDetail.sampleCode.responses.optimizationStatusItems.optimization_conditions_id'
  )}"
}
${this.$t(
  'serviceDetail.sampleCode.responses.optimizationStatusItems.statusInfo'
)}`
      } else if (this.selectedApiType === 'stop') {
        response = `{
      "job_id": "${this.$t(
        'serviceDetail.sampleCode.responses.optimizationStopItmes.job_id'
      )}"
}`
      }

      return {
        type: 'application/json',
        code: response
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.sample-code {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: adjustVW(882);
  height: 100%;
  &-tab-wrap {
    margin: 0 0 $space-small;
    &-sample-code {
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
  }
  &-wrap {
    overflow: hidden;
    padding: $space-medium;
    background: $background-sub;
    border-radius: adjustVW(16);
  }
  &-block {
    display: flex;
    flex-direction: column;
    height: 100%;
    &-scroll {
      overflow-y: auto;
      padding-right: $space-base;
      @include scrollbar;
    }
  }
  &-inner {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 0 $space-medium;
    &:last-child {
      margin: 0;
    }
    &-sample-code {
      height: 100%;
    }
  }
  &-item {
    width: 30%;
    height: 100%;
    margin: 0 $space-small 0 0;
    &:last-child {
      width: calc(70% - #{$space-small});
      margin: 0;
    }
    &-icon {
      display: flex;
      &-wrap {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
      }
      &-tips {
        position: absolute;
        bottom: -$space-small;
        height: 100%;
        z-index: -1;
      }
      &-copy {
        padding: 0;
      }
    }
    &-text {
      margin: 0 $space-small 0 0;
    }
    &-sub {
      white-space: pre-line;
    }
  }
  &-select-box {
    display: grid;
    align-items: center;
    grid-template-columns: adjustVW(240) 1fr;
    height: adjustVH(48);
    margin: 0 $space-base $space-small;
  }
  &-comment {
    &-inner {
      text-align: right;
      white-space: pre-line;
    }
  }
}
</style>
