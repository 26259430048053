<template>
  <div class="expression-wrap">
    <optimization-expression-desc />
    <optimization-expression-list
      v-model="internalValue"
      class="expression-list"
      :columns="columns"
      :validExpressions="validExpressions"
      :validColumns="validColumns"
      @check-expressions="$emit('check-expressions', $event)"
    />
  </div>
</template>

<script>
import optimizationExpressionList from '@/components/organisms/optimization/optimization-expression-list'
import optimizationExpressionDesc from '@/components/organisms/optimization/optimization-expression-desc'

export default {
  components: {
    optimizationExpressionList,
    optimizationExpressionDesc
  },
  data() {
    return {
      internalValue: []
    }
  },
  props: {
    value: Array,
    selectedTrainingData: Object,
    validExpressions: Array,
    optimizationColumns: Array,
    validColumns: Object
  },
  computed: {
    columns() {
      if (!this.selectedTrainingData || !this.optimizationColumns) return []
      if (
        !this.selectedTrainingData?.dtypes ||
        Object.keys(this.selectedTrainingData.dtypes).length === 0
      )
        return []
      const target = Object.keys(this.selectedTrainingData.dtypes)
      return target.filter((column) => {
        return !this.optimizationColumns.includes(column)
      })
    }
  },
  watch: {
    internalValue: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.expression {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    &-help {
      width: fit-content;
      cursor: pointer;
      @include buttonHover;
      &::v-deep {
        .c-text {
          margin-bottom: adjustVW(-1);
        }
      }
    }
  }
  &-list {
    overflow: hidden;
  }
}
</style>
