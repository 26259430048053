<!-- 現在の choosed-items.vue を置き換える形で利用する-->
<!-- また、複数列かの判定は上の階層で行う -->
<template>
  <div>
    <list-item-slot
      v-if="predictionColumn && predictionColumn.length > 0"
      :field="field"
      :items="predictionColumnSetting"
      :type="selectedInferenceType === 'classification' ? 'toggle' : ''"
      onlyToggle
      @list-click="clickToggle"
    >
      <template v-slot:columnField>
        <label class="inference-column">
          <checkbox-base
            class="inference-column-check"
            :checked="checkedItems.length > 0"
            :isShowMinusIcon="isNotAllChecked"
            @input="allCheck($event)"
          />
          <texts
            class="inference-column-text"
            :text="$t('training.config.detail.field.columnName')"
          />
        </label>
      </template>
      <template v-slot:column="entry">
        <div class="inference-column">
          <checkbox-base
            class="inference-column-check"
            :checked="checkedItems.indexOf(entry.item) !== -1"
            @input="onCheck($event, entry.item)"
          />
          <texts class="inference-column-text" :text="entry.item" />
        </div>
      </template>
      <template v-slot:thresholdField>
        <div class="inference-column">
          <texts
            class="inference-column-text"
            :text="$t('inference.settings.threshold')"
          />
        </div>
      </template>
      <template v-slot:threshold="entry">
        <texts :text="entry.item" />
      </template>
      <template v-slot:accuracyField>
        <div class="inference-column">
          <!-- TODO: 現状表示するパターンはR2のみなので文字で一旦入れている -->
          <texts
            class="inference-column-text"
            :text="$t('trainedAiDetails.metrics.test_accuracy_r2')"
          />
        </div>
      </template>
      <template v-slot:accuracy="entry">
        <metrics-text :value="entry.item" />
      </template>
      <template v-slot:toggleContent="entry">
        <transition-toggle-contents>
          <div
            v-if="loading"
            key="loading"
            class="inference-trained-ai-loading"
          >
            <loading />
          </div>
          <div v-else key="item" class="inference-trained-ai">
            <!-- entry.listIndexが今開いているリストのindex -->
            <model-setting
              type="multi"
              :trainedAiDetail="trainedAiDetail"
              :selectedIndex="entry.listIndex"
              :columnSetting="entry.entry"
              :changeableThreshold="changeableThreshold"
              :displayThreshold="displayThreshold"
              :loadChangeColumn="loading"
              @change-threshold="changeThreshold($event, entry)"
              @reverse-positive="reversePositive($event, entry)"
            />
          </div>
        </transition-toggle-contents>
      </template>
    </list-item-slot>
  </div>
</template>

<script>
import loading from '@/components/atoms/loading'
import checkboxBase from '@/components/atoms/checkbox-base'
import listItemSlot from '@/components/molecules/list-item-slot'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import modelSetting from './model-setting'
import metricsText from '@/components/atoms/metrics-text'

export default {
  components: {
    loading,
    checkboxBase,
    listItemSlot,
    modelSetting,
    transitionToggleContents,
    metricsText
  },
  data() {
    return {
      checkedItems: [],
      predictionColumnSetting: []
    }
  },
  props: {
    trainedAi: {
      type: Object,
      default: () => {}
    },
    trainedAiDetail: {
      type: Object,
      default: () => {}
    },
    predictionColumn: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    selectedInferenceType: {
      type: String,
      default: ''
    },
    changeableThreshold: {
      type: Boolean,
      default: false
    },
    displayThreshold: {
      type: Boolean,
      default: false
    },
    isTimeTransformerV2: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    field() {
      const base = 792
      if (this.selectedInferenceType === 'classification') {
        return [
          {
            key: 'column',
            label: '',
            width: base / 2
          },
          /**
           * TODO 陽性の値変更を実装したら変更 
          {
            key: 'class',
            width: base / 3
          },
           */
          {
            key: 'threshold',
            width: base / 2
          }
        ]
      } else if (
        this.selectedInferenceType === 'time' ||
        this.isTimeTransformerV2
      ) {
        return [
          {
            key: 'column',
            label: '',
            width: base
          }
        ]
      } else {
        return [
          {
            key: 'column',
            label: '',
            width: base / 2
          },
          {
            key: 'accuracy',
            label: '',
            width: base / 2
          }
        ]
      }
    },
    isNotAllChecked() {
      return this.predictionColumn.length !== this.checkedItems.length
    }
  },
  methods: {
    onCheck(e, name) {
      if (e) {
        this.checkedItems.push(name)
      } else {
        this.checkedItems.splice(this.checkedItems.indexOf(name), 1)
      }
    },
    allCheck(e) {
      if (e) {
        this.checkedItems.splice(0, this.checkedItems.length)
        const predictionTarget = this.predictionColumnSetting.map(
          (column) => column.column
        )
        this.checkedItems.push(...predictionTarget)
      } else {
        this.checkedItems.splice(0, this.checkedItems.length)
      }
    },
    changeThreshold(threshold, entry) {
      const predictionColumnIndex = entry.listIndex
      this.predictionColumnSetting[predictionColumnIndex].threshold = threshold
      const reversePositive =
        this.predictionColumnSetting[predictionColumnIndex].reversePositive
      this.$emit('change-trained-ai-detail', {
        index: predictionColumnIndex,
        threshold: threshold,
        reversePositive: reversePositive
      })
      this.$emit('input-multi-inference-column', {
        predictionColumn: this.checkedItems,
        predictionColumnSetting: this.predictionColumnSetting
      })
    },
    reversePositive(reversePositive, entry) {
      const predictionColumnIndex = entry.listIndex
      const threshold =
        this.predictionColumnSetting[predictionColumnIndex].threshold
      this.predictionColumnSetting[predictionColumnIndex].reversePositive =
        reversePositive
      this.$emit('change-trained-ai-detail', {
        index: predictionColumnIndex,
        threshold: threshold,
        reversePositive: reversePositive
      })
      this.$emit('input-multi-inference-column', {
        predictionColumn: this.checkedItems,
        predictionColumnSetting: this.predictionColumnSetting
      })
    },
    clickToggle(e) {
      const predictionColumnIndex = e.index
      const threshold =
        this.predictionColumnSetting[predictionColumnIndex].threshold
      const reversePositive =
        this.predictionColumnSetting[predictionColumnIndex].reversePositive
      this.$emit('change-trained-ai-detail', {
        index: predictionColumnIndex,
        threshold: threshold,
        reversePositive: reversePositive
      })
    }
  },
  watch: {
    checkedItems: {
      handler(newVal) {
        const target = newVal.filter((item) => {
          return this.predictionColumn.includes(item)
        })
        this.$emit('input-multi-inference-column', {
          predictionColumn: target,
          predictionColumnSetting: this.predictionColumnSetting
        })
      },
      deep: true
    }
  },
  mounted() {
    // 選択している列情報の初期化
    this.predictionColumn.forEach((item, index) => {
      this.checkedItems.push(item)
      const setting = {
        index: index,
        column: item,
        threshold: 0.5,
        reversePositive: false
      }

      // 回帰の場合には精度を表示するから、そのための設定
      try {
        const accuracy = this.trainedAi.metrics[index].test_accuracy
        setting.accuracy = accuracy
      } catch (e) {
        // エラーは無視する（精度がなかったら表示しない）
      }
      this.predictionColumnSetting.push(setting)
    })
    // 画面側で初期化されるから、views階層で持ってる情報も初期化
    this.$emit('input-multi-inference-column', {
      predictionColumn: this.checkedItems,
      predictionColumnSetting: this.predictionColumnSetting
    })
  }
}
</script>

<style lang="scss" scoped>
.inference {
  &-column {
    display: flex;
    align-items: center;
    grid-column-gap: $space-sub;
    &-check {
      flex-shrink: 0;
      width: adjustVW(24);
      padding: 0;
    }
  }
  &-trained-ai {
    &-loading {
      padding: $space-large 0;
    }
  }
}
</style>
