<template>
  <div class="c-wrap">
    <sidebar-project-detail
      v-if="projectInfo || projectLoading"
      v-bind="sidebar"
    />
    <sidebar v-else />
    <div class="c-body-wrap">
      <header-tabs
        v-bind="headerTabs"
        @tab-click="$emit('tab-click', $event)"
      />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="!projectInfo && loading.pageLoading" />
          <page-not-found v-else-if="!projectInfo && !projectLoading" />
          <project-detail
            v-else
            :accountInfo="accountInfo"
            :editInfo="editInfo"
            :projectInfo="projectInfo"
            :sortedTrainedAi="sortedTrainedAi"
            :rowsPerPage="rowsPerPage"
            :runningTasks="runningTasks"
            :loading="loading"
            :selectedColumnIndex="selectedColumnIndex"
            :optimizationInfo="optimizationInfo"
            :optimizationResult="optimizationResult"
            @reenter-click="$emit('reenter-click', $event)"
            @change-description="$emit('change-description', $event)"
            @delete-project="$emit('delete-project')"
            @delete-project-confirm="$emit('delete-project-confirm')"
            @input-edit-form="$emit('input-edit-form', $event)"
            @tag-add="$emit('tag-add', $event)"
            @tag-remove="$emit('tag-remove', $event)"
            @change-column="$emit('change-column', $event)"
            @change-page="$emit('change-page', $event)"
            @change-filter-value="$emit('change-filter-value', $event)"
            @download-result="$emit('download-result', $event)"
            @load-optimization-result="
              $emit('load-optimization-result', $event)
            "
            @go-text-mining="$emit('go-text-mining', $event)"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="disableClick" />
    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
    <project-detail-popup
      v-bind="popup"
      :projectInfo="projectInfo"
      :disableClick="disableClick"
      @delete-project-confirm="$emit('delete-project-confirm')"
      @close-modal="$emit('close-modal', $event)"
    />
  </div>
</template>

<script>
import Loading from '@/components/atoms/loading.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import transitionPage from '@/components/molecules/transition-page'
import TransitionToggleContents from '../molecules/transition-toggle-contents.vue'
import sidebarProjectDetail from '@/components/organisms/sidebar-project-detail.vue'
import Sidebar from '@/components/organisms/sidebar.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import projectDetail from '@/components/organisms/project-detail/project-detail.vue'
import projectDetailPopup from '@/components/organisms/project-detail/project-detail-popup.vue'
import pageNotFound from '@/components/organisms/page-not-found'

export default {
  components: {
    Loading,
    loadingProcessing,
    transitionPage,
    TransitionToggleContents,
    sidebarProjectDetail,
    Sidebar,
    hoverMenu,
    headerTabs,
    projectDetail,
    projectDetailPopup,
    pageNotFound
  },
  props: {
    sidebar: Object,
    headerTabs: Object,
    popup: Object,
    projectInfo: Object,
    projectLoading: Boolean,
    sortedTrainedAi: Object,
    accountInfo: Object,
    disableClick: Boolean,
    editInfo: Object,
    rowsPerPage: Number,
    runningTasks: Array,
    loading: Object,
    selectedColumnIndex: Number,
    optimizationInfo: Object,
    optimizationResult: Object
  },
  computed: {}
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
  background: $background;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  height: 100vh - adjustVH($headerTabHeight);
  margin: adjustVH($headerTabHeight) 0 0;
  background: $background-sub;
}
</style>
