<template>
  <div v-if="tabs && tabs.length > 0" class="c-header-tab-wrap">
    <div class="c-header-tab-body">
      <a
        v-for="tab in tabs"
        :key="tab.id"
        class="c-header-tab-block"
        :class="{ 'c-header-tab-block-selected': tabSelect === tab.id }"
        @click="$emit('tab-click', { tabId: tab.id })"
      >
        <div class="c-header-tab-inner">
          <div class="c-header-tab-text-wrap">
            <text-with-icon
              :text="tab.text"
              :iconName="tab.icon"
              :color="tabSelect === tab.id ? 'default' : 'gray'"
            />
          </div>
          <icons iconName="close" size="small" />
        </div>
      </a>
      <div class="c-header-tab-block">
        <icons iconName="plus" size="min" />
      </div>
    </div>
  </div>
</template>

<script>
import textWithIcon from '@/components/molecules/text-with-icon.vue'
import icons from '@/components/atoms/icon.vue'

export default {
  components: {
    textWithIcon,
    icons
  },
  props: {
    tabs: Array,
    tabSelect: Number
  }
}
</script>

<style scoped lang="scss">
// top tabs
.c-header-tab {
  &-wrap {
    position: fixed;
    top: 0;
    left: adjustVW(240);
    background: $background;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
    z-index: $z-header-tab;
  }
  &-body {
    display: flex;
    width: 100vw - adjustVW(240); // サイドバーとスクロールバーを引いた値
    height: adjustVH($headerTabHeight);
  }
  &-block {
    display: flex;
    align-items: center;
    width: calc((100% - 5% - #{$space-small}) / 5 - #{$space-small});
    padding: 0 0 0 $space-small;
    margin: -1px;
    cursor: pointer;
    transition: $transition-base;
    &:first-child {
      padding: 0 0 0 $space-small;
      margin: 0;
    }
    &:last-child {
      width: 5%;
      border: none;
    }
  }
  &-inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 $space-small 0 0;
    border-right: $border-main;
    transition: $transition-base;
  }
  &-text-wrap {
    width: calc(100% - #{adjustVW(28)});
  }
}
</style>
