<template>
  <button @click="$emit('back-main')">
    <page-top
      class="page-top"
      :title="$t('preprocessing.columnTitle') + columnName"
      iconName="backward"
      notScroll
      isButton
      :isTrain="isTrain"
      size="min"
    />
  </button>
</template>

<script>
import pageTop from '@/components/atoms/page-top'

export default {
  components: {
    pageTop
  },
  props: {
    columnName: String,
    isTrain: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.page-top {
  /* stylelint-disable */
  ::v-deep .c-page-top-title {
    /* stylelint-enable */
    margin: 0;
  }
}
</style>
