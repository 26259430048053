<template>
  <transition name="balloon-toggle" mode="out-in">
    <div
      v-if="showPopup"
      class="wrap"
      :class="{
        'wrap-right': posRight,
        'wrap-bottom': posBottom,
        'wrap-min': minSize
      }"
    >
      <div class="inner">
        <div class="text-content">
          <div class="text-content-inner">
            <description
              class="text-content-item"
              :text="popupContents.body"
              line="none"
            />
          </div>
        </div>
        <div class="block">
          <div class="name">
            <texts :text="popupContents.name" isBold />
          </div>
          <div class="detail">
            <texts :text="'文字数 : ' + popupContents.length" size="small" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import texts from '@/components/atoms/text'
import description from '@/components/atoms/description'

export default {
  components: {
    texts,
    description
  },
  props: {
    /** ポップアップを表示するかどうか */
    showPopup: {
      type: Boolean,
      default: false
    },
    /** ポップアップを右側に表示 */
    posRight: {
      type: Boolean,
      default: false
    },
    /** ポップアップを下向きに表示 */
    posBottom: {
      type: Boolean,
      default: false
    },
    /** ポップアップの中身
     *  name: テキスト名 {string}
     *  body: テキスト全文 {string}
     *  length: 文字数 {number}
     */
    popupContents: Object,
    /** 縦幅を狭く表示 */
    minSize: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  position: absolute;
  top: calc(100% + #{$space-small * 2});
  left: -$space-medium;
  background: $background;
  border-radius: adjustVW(16);
  box-shadow: $box-shadow-hover;
  z-index: 10;
  &-right {
    right: -$space-medium;
    left: auto;
  }
  &-bottom {
    top: auto;
    bottom: calc(100% + #{$space-small * 2});
  }
  &-min {
    .text-content {
      &-inner {
        max-height: adjustVW(160);
      }
    }
  }
}
.inner {
  padding: $space-small;
  background: $background;
  border-radius: adjustVW(16);
  &::after {
    content: '';
    position: absolute;
    top: adjustVW(-20);
    left: adjustVW(32);
    width: adjustVW(56);
    height: adjustVW(56);
    background: $background;
    border-radius: adjustVW(8);
    box-shadow: $box-shadow-hover;
    z-index: -1;
    transform: rotate(45deg);
    .wrap-right & {
      right: adjustVW(32);
      left: auto;
    }
    .wrap-bottom & {
      top: auto;
      bottom: adjustVW(-20);
    }
  }
}
.img {
  width: adjustVW(400);
  height: adjustVW(225);
  margin: 0 0 $space-small;
}
.text-content {
  overflow: hidden;
  width: 100%;
  padding: $space-base;
  margin: 0 0 $space-small;
  border: $border-main;
  &-inner {
    overflow-y: auto;
    max-width: adjustVW(480);
    max-height: adjustVW(220);
    padding: 0 $space-base 0 0;
    @include scrollbar;
  }
  &-item {
    text-align: left;
  }
}
.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detail {
  display: flex;
  align-items: center;
  > .text {
    margin: 0 $space-small 0 0;
  }
}
.balloon-toggle-enter {
  opacity: 0;
  transform: translateY(-$space-small);
  &-active {
    transition: opacity $transition-base, transform ease-in-out $transition-base;
    will-change: opacity transform;
  }
  &-to {
    opacity: 1;
    transform: translateY(1);
  }
}
.balloon-toggle-leave {
  opacity: 1;
  transform: translateY(1);
  &-active {
    transition: opacity $transition-base, transform ease-in-out $transition-base;
    will-change: opacity transform;
  }
  &-to {
    opacity: 0;
    transform: translateY(-$space-small);
  }
}
</style>
