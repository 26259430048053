<template>
  <transition-toggle-contents>
    <div class="wrap">
      <div class="title">
        <texts :text="$t('inference.popup.progress.title')" size="large" />
      </div>
      <div class="list">
        <!-- 1. 環境構築中 -->
        <div class="item">
          <div class="item-inner">
            <transition name="toggle-icon" mode="out-in">
              <loading-icon
                v-if="!finishPreparing"
                key="environment"
                class="icon"
                size="small"
                color="emphasis"
              />
              <icons
                v-else
                key="train"
                class="icon"
                size="small"
                color="emphasis"
                iconName="check"
              />
            </transition>
            <texts
              class="text-pre-line"
              :text="$t('inference.popup.progress.environment')"
              color="emphasis"
            />
          </div>
        </div>
        <!-- 2. 列状態 -->
        <div v-if="showColumnInfo" class="item-wrap">
          <div class="item">
            <div class="item-inner">
              <div class="item-inner-column">
                <transition name="toggle-icon" mode="out-in">
                  <loading-icon
                    v-if="maxColumns !== columnProgress"
                    class="icon"
                    size="small"
                    color="emphasis"
                  />
                  <icons
                    v-else
                    class="icon"
                    size="small"
                    color="emphasis"
                    iconName="check"
                  />
                </transition>
                <texts
                  class="item-inner-column-shrink"
                  :text="inferenceStatus.columnInfo.inferenceColumn"
                  color="emphasis"
                />
                <texts
                  class="item-inner-column-text"
                  :text="$t('inference.popup.progress.inferenceColumn')"
                  color="emphasis"
                />
                <texts
                  class="item-inner-progress"
                  :text="((columnProgress / maxColumns) * 100).toFixed() + '%'"
                  color="emphasis"
                />
                <texts
                  :text="
                    $t('training.result.multiResult.columnProgress', {
                      progress: columnProgress,
                      progressMax: maxColumns
                    })
                  "
                  color="emphasis"
                />
              </div>
            </div>
          </div>
          <div class="graph">
            <graph-bar-count
              class="graph-inner"
              :maxCount="inferenceStatus.columnInfo.allColumns.length"
              :count="inferenceStatus.columnInfo.finishedColumns.length"
            />
          </div>
        </div>
        <div :class="{ 'inner-progress': showColumnInfo }">
          <!-- 3. 推論中 -->
          <div v-if="finishPreparing" class="item-wrap">
            <div class="item">
              <div class="item-inner">
                <transition name="toggle-item" mode="out-in">
                  <div class="item-inner">
                    <transition name="toggle-icon" mode="out-in">
                      <loading-icon
                        v-if="inferenceProgress !== maxProgress"
                        class="icon"
                        size="small"
                        color="emphasis"
                      />
                      <icons
                        v-else
                        class="icon"
                        size="small"
                        color="emphasis"
                        iconName="check"
                      />
                    </transition>
                    <texts
                      :text="$t('inference.popup.progress.inferenceProcessing')"
                      color="emphasis"
                    />
                    <texts
                      class="item-inner-progress"
                      :text="
                        ((inferenceProgress / maxProgress) * 100).toFixed() +
                          '%'
                      "
                      color="emphasis"
                    />
                    <texts
                      :text="
                        $t('training.result.multiResult.columnProgress', {
                          progress: inferenceProgress,
                          progressMax: maxProgress
                        })
                      "
                      color="emphasis"
                    />
                  </div>
                </transition>
              </div>
            </div>
            <div v-if="inferenceProgress !== maxProgress" class="graph">
              <graph-bar-count
                class="graph-inner"
                :maxCount="inferenceStatus.dataNum"
                :count="inferenceProgress"
              />
            </div>
          </div>
          <!-- 4. 重要度 -->
          <div
            v-if="finishPreparing"
            :class="{ 'item-wrap': displayPostProcessing }"
          >
            <template v-if="inferenceStatus.doImportance">
              <div class="item">
                <div class="item-inner">
                  <transition name="toggle-item" mode="out-in">
                    <div class="item-inner">
                      <transition name="toggle-icon" mode="out-in">
                        <loading-icon
                          v-if="importanceProgress !== maxProgress"
                          class="icon"
                          size="small"
                          color="emphasis"
                        />
                        <icons
                          v-else
                          class="icon"
                          size="small"
                          color="emphasis"
                          iconName="check"
                        />
                      </transition>
                      <texts
                        :text="
                          $t('inference.popup.progress.importanceProcessing')
                        "
                        color="emphasis"
                      />
                      <texts
                        class="item-inner-progress"
                        :text="
                          ((importanceProgress / maxProgress) * 100).toFixed() +
                            '%'
                        "
                        color="emphasis"
                      />
                      <texts
                        :text="
                          $t('training.result.multiResult.columnProgress', {
                            progress: importanceProgress,
                            progressMax: maxProgress
                          })
                        "
                        color="emphasis"
                      />
                    </div>
                  </transition>
                </div>
              </div>
              <div v-if="importanceProgress !== maxProgress" class="graph">
                <graph-bar-count
                  class="graph-inner"
                  :maxCount="inferenceStatus.dataNum"
                  :count="importanceProgress"
                />
              </div>
            </template>
            <template v-else>
              <div class="item">
                <div class="item-inner">
                  <transition name="toggle-item" mode="out-in">
                    <texts
                      :text="$t('trainedAiDetails.noFeatureImportance')"
                      color="gray"
                    />
                  </transition>
                </div>
              </div>
            </template>
          </div>
          <!-- 5. 後処理 -->
          <div v-if="displayPostProcessing" class="item">
            <div class="item-inner">
              <transition name="toggle-icon" mode="out-in">
                <loading-icon
                  v-if="!inferenceStatus.finishedPostProcessing"
                  key="environment"
                  class="icon"
                  size="small"
                  color="emphasis"
                />
                <icons
                  v-else
                  key="train"
                  class="icon"
                  size="small"
                  color="emphasis"
                  iconName="check"
                />
              </transition>
              <texts
                :text="$t('inference.popup.progress.postProcessing')"
                color="emphasis"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition-toggle-contents>
</template>

<script>
import texts from '@/components/atoms/text'
import icons from '@/components/atoms/icon'
import graphBarCount from '@/components/atoms/graph-bar-count'
import loadingIcon from '@/components/atoms/loading-icon'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

export default {
  components: {
    texts,
    icons,
    graphBarCount,
    loadingIcon,
    transitionToggleContents
  },
  props: {
    inferenceStatus: Object
  },
  computed: {
    showColumnInfo() {
      return (
        this.finishPreparing &&
        this.inferenceStatus?.columnInfo?.allColumns &&
        this.inferenceStatus?.columnInfo?.allColumns.length > 1
      )
    },
    maxColumns() {
      return (
        this.inferenceStatus &&
        this.inferenceStatus.columnInfo.allColumns.length
      )
    },
    columnProgress() {
      return (
        this.inferenceStatus &&
        this.inferenceStatus.columnInfo.finishedColumns.length
      )
    },
    maxProgress() {
      return this.inferenceStatus && this.inferenceStatus.dataNum
    },
    inferenceProgress() {
      return this.inferenceStatus && this.inferenceStatus.finishedInferenceNum
    },
    importanceProgress() {
      return this.inferenceStatus && this.inferenceStatus.finishedImportanceNum
    },
    finishPreparing() {
      return this.inferenceStatus && this.inferenceStatus.finishedPreparing
    },
    finishedInference() {
      return (
        this.inferenceStatus &&
        this.inferenceStatus.dataNum === this.inferenceProgress
      )
    },
    finishedImportance() {
      return (
        this.inferenceStatus &&
        this.inferenceStatus.dataNum === this.importanceProgress
      )
    },
    displayPostProcessing() {
      return (
        this.finishPreparing &&
        this.finishedInference &&
        (!this.inferenceStatus.doImportance || this.finishedImportance)
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  width: adjustVW(676);
  padding: $space-small $space-small adjustVW(20);
  background: $background;
  border-radius: adjustVW(16);
  box-shadow: $box-shadow-hover;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 $space-sub;
  &-button {
    text-decoration: underline;
    cursor: pointer;
    user-select: none;
    transition: opacity $transition-base;
    &:hover {
      opacity: 0.5;
    }
    &-disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 $space-sub;
  opacity: 1;
  transform: translateX(0);
  transition: opacity $transition-base, transform $transition-base;
  will-change: opacity, transform;
  &:last-of-type {
    margin: 0;
  }
  &-inner {
    display: flex;
    align-items: center;
    &-column {
      display: flex;
      margin-right: $space-small;
      &-shrink {
        overflow: hidden;
        flex-shrink: 1;
        max-width: adjustVW(328);
      }
      &-text {
        flex-shrink: 0;
      }
    }
    &-progress {
      margin-right: $space-base;
    }
  }
  &-disabled {
    height: 0;
    opacity: 0;
    transform: translateX(-$space-small);
  }
  &-wrap {
    margin: 0 0 $space-small;
  }
}
.icon {
  margin: 0 $space-base 0 0;
}
.graph {
  opacity: 1;
  transform: translateX(0);
  transition: opacity $transition-base, transform $transition-base;
  will-change: opacity, transform;
  &-disabled {
    height: 0;
    margin-top: 0;
    opacity: 0;
    transform: translateX(-$space-small);
  }
}
.inner-progress {
  padding: $space-sub;
  background: $background-sub;
  border-radius: adjustVW(10);
}
.text-pre-line {
  white-space: pre-line;
}

.toggle-item-enter-active,
.toggle-item-leave-active {
  transition: transform $transition-base, opacity $transition-base;
}
.toggle-item-enter,
.toggle-item-leave-to {
  opacity: 0;
  transform: translateX(-$space-small);
  will-change: opacity, transform;
}

.toggle-icon-enter-active,
.toggle-icon-leave-active {
  transition: transform $transition-base, opacity $transition-base;
}
.toggle-icon-enter,
.toggle-icon-leave-to {
  opacity: 0;
  transform: scale(0);
  will-change: opacity, transform;
}
</style>
