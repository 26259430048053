<!-- データセット設定画面のテーブル表示領域 -->
<template>
  <div class="table">
    <transition-toggle-contents>
      <card-base
        v-if="
          table &&
            table.length &&
            !datasetTableLoading &&
            !trainingDatasetTooLarge
        "
        key="table"
        class="card"
      >
        <table-main
          v-if="type === 'list'"
          :table="table"
          :tableColumns="columns"
          :pagingCount="5"
        />
        <dataset-setting-table-unstructured
          v-else-if="type === 'images' || type === 'texts'"
          :items="Object.values(table)"
          :field="columns"
          :nLabels="nLabels"
          :type="type"
        />
      </card-base>
      <div v-else-if="datasetTableLoading" key="loading" class="loading">
        <loading />
      </div>
      <div v-else-if="trainingDatasetTooLarge" key="large" class="large">
        <texts
          class="large-title"
          :text="$t('datasetSetting.selectDataset.tooLarge')"
          size="small"
          color="gray"
        />
        <button-main
          :text="$t('datasetSetting.selectDataset.tooLargeButton')"
          type="sub"
          @click="$emit('show-too-large-data')"
        />
      </div>
      <select-dataset-image v-else />
    </transition-toggle-contents>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main.vue'
import loading from '@/components/atoms/loading'
import cardBase from '@/components/atoms/card-base'
import tableMain from '@/components/molecules/table-main'
import selectDatasetImage from '@/components/molecules/select-dataset-image'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import datasetSettingTableUnstructured from './dataset-setting-table-unstructured'

export default {
  components: {
    buttonMain,
    loading,
    cardBase,
    tableMain,
    selectDatasetImage,
    transitionToggleContents,
    datasetSettingTableUnstructured
  },
  props: {
    /** 表示するテーブル */
    table: Array,
    columns: Array,
    type: {
      type: String,
      default: 'list'
    },
    /** テーブルをローディング中かどうか */
    datasetTableLoading: Boolean,
    nLabels: Number,
    trainingDatasetTooLarge: Boolean
  }
}
</script>

<style lang="scss" scoped>
.table {
  height: 100%;
  padding: $space-small;
  background: $background-sub;
  border-radius: $space-small;
}
.card {
  height: 100%;
}
.loading {
  position: relative;
  display: flex;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.large {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-row-gap: $space-small;
  height: 100%;
  &-title {
    text-align: center;
    white-space: pre-wrap;
  }
}
</style>
