import { render, staticRenderFns } from "./comparison-graph-detail.vue?vue&type=template&id=3796f968&scoped=true&"
import script from "./comparison-graph-detail.vue?vue&type=script&lang=js&"
export * from "./comparison-graph-detail.vue?vue&type=script&lang=js&"
import style0 from "./comparison-graph-detail.vue?vue&type=style&index=0&id=3796f968&scoped=true&lang=css&"
import style1 from "./comparison-graph-detail.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3796f968",
  null
  
)

export default component.exports