<template>
  <div class="c-wrap">
    <sidebar v-bind="sidebar" />
    <div class="c-body-wrap">
      <header-tabs
        v-bind="headerTabs"
        @tab-click="$emit('tab-click', $event)"
      />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="projectLoading" />
          <project-list
            v-else
            :accountInfo="accountInfo"
            :projectList="projectList"
            @open-clone-project="$emit('open-clone-project', $event)"
            @open-create-template="$emit('open-create-template', $event)"
            @open-delete-project="$emit('open-delete-project', $event)"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="disableClick" />
    <hover-menu
      :popup="popup"
      :isHelpTipsOpen="isHelpTipsOpen"
      showTask
      @on-hover-help="$emit('hide-help-tips')"
      @stop-task="$emit('stop-task', $event)"
      @link-to-optimization-failed="
        $emit('link-to-optimization-failed', $event)
      "
      @delete-optimization-failed="$emit('delete-optimization-failed', $event)"
    />
    <project-list-popups
      v-bind="popup"
      :disableClick="disableClick"
      @clone-project="$emit('clone-project', $event)"
      @create-template="$emit('create-template', $event)"
      @close-modal="$emit('close-modal', $event)"
      @delete-project="$emit('delete-project', $event)"
      @training-stop-confirm="$emit('training-stop-confirm', $event)"
    />
    <transition-page>
      <link-community-box
        class="community-wrap"
        :accountInfo="accountInfo"
        @click-hidden-community-link="$emit('click-hidden-community-link')"
      />
    </transition-page>
  </div>
</template>

<script>
import Loading from '@/components/atoms/loading.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import transitionPage from '@/components/molecules/transition-page'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import projectList from '@/components/organisms/project/project-list.vue'
import sidebar from '@/components/organisms/sidebar.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import ProjectListPopups from '@/components/organisms/project/project-list-popups.vue'
import linkCommunityBox from '@/components/molecules/link-community-box'

export default {
  components: {
    Loading,
    loadingProcessing,
    transitionPage,
    TransitionToggleContents,
    projectList,
    sidebar,
    headerTabs,
    hoverMenu,
    ProjectListPopups,
    linkCommunityBox
  },
  props: {
    sidebar: Object,
    headerTabs: Object,
    popup: Object,
    accountInfo: Object,
    projectList: Object,
    projectLoading: Boolean,
    disableClick: Boolean,
    isHelpTipsOpen: Boolean
  }
}
</script>

<style lang="scss" scoped>
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
  background: $background;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
  background: $background-sub;
}

.community-wrap {
  position: fixed;
  bottom: $space-small;
  left: adjustVW(240) + $space-small;
}
</style>
