<template>
  <list-item-slot
    class="trained-ai-list"
    type="button"
    :field="field"
    :items="items"
    @list-click="listClick"
  >
    <template v-slot:summary="entry">
      <div class="trained-ai-summary">
        <texts
          class="trained-ai-summary-name"
          :text="entry.item.name"
          isBold
          :class="{
            'trained-ai-summary-name-non-desc': !entry.item.description
          }"
        />
        <description
          class="trained-ai-summary-description"
          :text="entry.item.description"
          :line="2"
        />
      </div>
    </template>

    <template v-slot:expectedEffect="entry">
      <texts v-if="entry.item" size="big" :text="entry.item" />
    </template>

    <template v-slot:metrics="entry">
      <template v-if="entry.entry.inferenceType === 'NO_ACCURACY'">
        <texts
          class="trained-ai-accuracy-none"
          :text="$t('trainedAiDetails.noAccuracy')"
          :size="15"
          color="gray"
        />
      </template>
      <template v-else-if="checkEntry(entry.entry)">
        <metrics-multi
          v-if="Object.keys(entry.entry.metrics).length > 1"
          :metricsList="entry.entry.metricsList"
        />
        <template v-else>
          <metrics
            v-for="metric in entry.entry.metricsList"
            :key="metric.name"
            class="trained-ai-accuracy"
            :name="metric.name"
            :value="metric.value"
            :percentage="metric.percentage"
            isMinTitle
          />
        </template>
      </template>
      <div v-else>
        <texts
          class="trained-ai-accuracy-none"
          :text="$t('common.noMetrics')"
          :size="15"
          color="gray"
        />
      </div>
    </template>

    <template v-slot:featureImportance="entry">
      <list-features
        :id="entry.entry.id"
        :predictionColumns="entry.entry.predictionColumns"
        :featureImportance="entry.item"
      />
    </template>
  </list-item-slot>
</template>

<script>
import listItemSlot from '@/components/molecules/list-item-slot.vue'
import texts from '@/components/atoms/text.vue'
import description from '@/components/atoms/description.vue'
import Metrics from '@/components/molecules/graph/metrics.vue'
import MetricsMulti from '@/components/molecules/graph/metrics-multi.vue'
import listFeatures from '@/components/organisms/trained-ai-common/list-features.vue'

export default {
  components: {
    listItemSlot,
    texts,
    description,
    Metrics,
    MetricsMulti,
    listFeatures
  },
  props: {
    project: Object,
    field: Array,
    items: Array
  },
  methods: {
    checkEntry(trainedAi) {
      if (!trainedAi?.metrics) return false
      return Object.keys(trainedAi.metrics).length > 0
    },
    listClick: function ({ id }) {
      this.$emit('list-click', { id })
    }
  }
}
</script>

<style lang="scss" scoped>
.trained-ai-summary {
  display: flex;
  flex-direction: column;

  &-name {
    margin-bottom: $space-base;
    &-non-desc {
      margin: 0;
    }
  }

  &-description {
    white-space: normal;
  }
}

/** 前処理画面の方でリストのkeyを固定にしてスクロールするのがはいるので、この状態でおいて置けば、keyが固定になると思います。ほかの作業が終わってマージ前に確認したいので、コメント残しておいて下さい */
.trained-ai-list {
  overflow-y: auto;
  height: 100%;
  padding: 0 $space-base 0 0;
  @include scrollbar;
}

.trained-ai-accuracy {
  display: flex;
  align-items: center;
  margin: 0 $space-large 0 0;
  &:last-child {
    margin: 0;
  }
  &-wrap {
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: 0 0 $space-medium;
  }
  &-none {
    white-space: pre-line;
  }
}
</style>
