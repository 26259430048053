<template>
  <card-base class="c-card">
    <div class="choosed-preprocess-wrapper">
      <div class="choosed-preprocess-item choosed-preprocess-title">
        <texts
          size="large"
          :text="
            this.$i18n.t(
              'inference.settings.selectInferenceType.choosedPreprocess.title'
            )
          "
        />
      </div>

      <div class="choosed-preprocess-item">
        <texts :text="choosedPreprocessInfo.name" isBold />
        <description
          class="choosed-preprocess-about"
          :text="choosedPreprocessInfo.description"
          :line="2"
        />
      </div>

      <div class="choosed-preprocess-item choosed-preprocess-item-chart">
        <preprocessing-chart-list-min
          class="choosed-preprocess-chart"
          :layers="choosedPreprocessInfo.layers"
        />
      </div>
    </div>
  </card-base>
</template>

<script>
import cardBase from '@/components/atoms/card-base.vue'
import preprocessingChartListMin from '@/components/molecules/preprocessing-chart-list-min.vue'
import texts from '@/components/atoms/text.vue'
import description from '@/components/atoms/description.vue'

export default {
  components: {
    cardBase,
    preprocessingChartListMin,
    texts,
    description
  },
  props: {
    choosedPreprocessInfo: Object
  }
}
</script>

<style lang="scss" scoped>
.c-card {
  width: 100%;
  height: 100%;
}

.choosed-preprocess {
  &-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-item {
    width: 100%;
    margin-bottom: $space-base;
    &:last-of-type {
      margin-bottom: 0;
    }
    &-chart {
      flex-grow: 1;
      max-height: 100%;
    }
  }
  &-chart {
    overflow-y: auto;
    max-height: 100%;
    padding: 0 $space-sub 0 0;
    @include scrollbar;
  }
}

.choosed-preprocess-title {
  margin-bottom: $space-sub;
}

.choosed-preprocess-columns-title {
  margin-bottom: $space-base;
}
</style>
