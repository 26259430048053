<template>
  <div class="change-plan-wrap">
    <div key="field" class="change-plan-first-column">
      <terms-card :menuTerms="planTermsData" @active-item="activeItem" />
    </div>

    <div key="free" class="change-plan-column-main">
      <free-plan-card
        :planData="freePlanData"
        :isSelected="selectedPlan === 'free' || selectedPlan === 'education_1'"
        @active-item="activeItem"
        @change-plan="changePlan"
      />
    </div>
    <div key="basic" class="change-plan-column-main">
      <basic-plan-card
        :planData="basicPlanData"
        :isSelected="selectedPlan === 'basic'"
        @active-item="activeItem"
      />
    </div>
  </div>
</template>

<script>
import BasicPlanCard from '@/components/organisms/change-plan/basic-plan-card.vue'
import FreePlanCard from '@/components/organisms/change-plan/free-plan-card.vue'
import TermsCard from '@/components/organisms/change-plan/terms-card.vue'

export default {
  components: {
    BasicPlanCard,
    FreePlanCard,
    TermsCard
  },
  props: {
    basicPlanData: Object,
    freePlanData: Object,
    lightPlanData: Object,
    planTermsData: Object,
    purchasePlan: String,
    showPopupContent: Object,
    selectedPlan: String
  },
  methods: {
    activeItem: function ({ id }) {
      this.$emit('active-item', { id })
    },
    changePlan: function ({ planName }) {
      this.$emit('change-plan', { planName })
    }
  }
}
</script>

<style lang="scss" scoped>
.change-plan-wrap {
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, adjustVW(528));
  grid-column-gap: $space-medium;
  height: 100%;
  padding: 0 $space-medium $space-medium;
}

.change-plan-column-main {
  height: auto;
  &:last-of-type {
    margin-right: 0;
  }
}
</style>
