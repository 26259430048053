<template>
  <result-image-card
    class="image-classification-card"
    :modelInfo="modelInfo"
    :imageUrl="url"
    :imageName="filename"
    :label="label"
    :labelInferenceResult="label_inference_result"
    :posBottom="posBottom"
    :existHeatmap="existHeatmap"
    :checkedHeatmap="checkedHeatmap"
    isClassification
    @check-heatmap="checkedHeatmap = $event"
  >
    <div class="classification-reliability-wrapper">
      <div class="classification-reliability-title">
        <texts
          class="classification-reliability-block1"
          size="small"
          :text="$t('inference.result.classification.label')"
        />
        <texts
          class="classification-reliability-block2"
          size="small"
          :text="$t('inference.result.classification.reliabiliry')"
        />
      </div>

      <div class="classification-reliability-graph">
        <div
          v-for="([key, value], index) in reliabilitiesArray"
          :key="index"
          class="classification-reliability-graph-wrapper"
        >
          <texts
            class="classification-reliability-block1"
            size="small"
            :text="key"
          />
          <div
            class="
              classification-reliability-block2
              classification-reliability-graph-bar-wrapper
            "
          >
            <graph-bar-count
              class="classification-reliability-graph-bar-line"
              :count="value"
              :maxCount="maxCount"
            />
            <texts
              class="classification-reliability-graph-bar-count"
              size="min"
              :text="Math.round(value * 1000) / 10 + '%'"
            />
          </div>
        </div>
      </div>
    </div>
  </result-image-card>
</template>

<script>
import graphBarCount from '@/components/atoms/graph-bar-count.vue'
import resultImageCard from '@/components/molecules/inference/result/result-image-card.vue'
import texts from '@/components/atoms/text.vue'

export default {
  components: {
    graphBarCount,
    resultImageCard,
    texts
  },
  data() {
    return {
      maxCount: 1.0,
      checkedHeatmap: false
    }
  },
  computed: {
    reliabilitiesArray() {
      return Object.entries(this.probability).sort((x, y) => {
        return y[1] - x[1]
      })
    },
    url() {
      return this.checkedHeatmap ? this.heatmap : this.body
    },
    existHeatmap() {
      return !(this.heatmap == null)
    }
  },
  props: {
    modelInfo: Object,
    filename: String,
    body: String,
    label: String,
    label_inference_result: String,
    probability: Object,
    posBottom: {
      type: Boolean,
      default: false
    },
    heatmap: String
  }
}
</script>

<style lang="scss" scoped>
.image-classification-card {
  width: 100%;
  height: 100%;
}

.classification-reliability {
  &-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &-block1 {
    flex-shrink: 0;
    width: adjustVW(108);
    margin-right: $space-small;
  }

  &-block2 {
    flex-shrink: 1;
    width: 100%;
  }
}

.classification-reliability-title {
  display: flex;
  align-items: center;
  margin-bottom: $space-base;
}

.classification-reliability-graph {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 0 $space-base 0 0;
  @include scrollbar;

  &-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: $space-sub;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.classification-reliability-graph-bar {
  &-wrapper {
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  &-line {
    flex-shrink: 1;
    width: adjustVW(144);
    margin-right: $space-sub;
    z-index: 1;
  }

  &-count {
    flex-shrink: 0;
    width: adjustVW(44.5);
  }
}
</style>
