<template>
  <div class="wrap">
    <toggle-button
      class="toggle-space"
      :checked="otpEnabled"
      :text="$t('account.setting.info.popup.OTP.activate')"
      :caution="showConfirmDelete && !confirmDestroyOTP"
      :isDisabled="destroyDisabledSwitch"
      @change="generateAndDeleteOTP"
    />
    <!--
      トグルがoffで、ワンタイムパスワードが無効の時の画面
    -->
    <div v-if="switchState === false">
      <buttons
        class="button-close-modal"
        type="sub"
        :text="$t('account.setting.info.popup.OTP.cancel')"
        @click="$emit('close-modal', closePopup)"
      />
    </div>
    <!--
      連結されていたワンタイムパスワードが解除された時の画面
    -->
    <div v-else-if="destroyOTP === true" class="OTP-destroy">
      <text-box
        class="text-box"
        :text="$t('account.setting.info.popup.OTP.unlinkOTP')"
      />
      <buttons type="sub" :text="$t('common.close')" @click="closeModal" />
    </div>
    <div v-else>
      <!--
        ローディング画面
      -->
      <div v-if="loading" class="now-loading">
        <loading />
      </div>
      <!--
        トグルがonになってワンタイムパスワードを入力する時の画面
      -->
      <div
        v-if="
          switchState === true &&
            loading === false &&
            accountInfo.otpEnabled === false
        "
        class="OTP-password"
      >
        <div class="qr-text-box">
          <qrcode
            class="qr-icon"
            target="_blank"
            :value="url"
            foreground="#1B101F"
            renderAs="svg"
          />
          <div class="qr-text">
            <texts
              size="small"
              color="caution"
              :text="$t('account.setting.info.popup.OTP.unlinkOTPError')"
            />
            <div class="qr-desc">
              <texts class="qr-desc-item" size="small">
                <span>{{
                  $t('account.setting.info.popup.OTP.descriptionTop')
                }}</span>
                <a
                  class="qr-link"
                  target="_blank"
                  rel="noreferrer"
                  :href="$urls.googleAuthenticator"
                >{{ linkText }}</a>
                <span>{{
                  $t('account.setting.info.popup.OTP.descriptionBottom')
                }}</span>
              </texts>
            </div>
          </div>
        </div>
        <div>
          <input-box-validation
            v-model="userToken"
            class="text-box"
            :title="$t('account.setting.info.popup.OTP.authenticationTest')"
            :value.sync="userToken"
            :placeholder="$t('account.setting.info.popup.OTP.placeholder')"
          />
          <!--
            ワンタイムパスワードの入力を失敗した時にあわられるテキスト
          -->
          <div v-if="this.testOk === false" class="text-box">
            <text-box
              isError
              :text="$t('account.setting.info.popup.OTP.errorOTP')"
            />
          </div>

          <div>
            <buttons
              class="button-success"
              buttonType="submit"
              type="emphasis"
              :text="$t('account.setting.info.popup.OTP.testRun')"
              @click="testOTP"
            />
            <buttons
              class="button-close-modal"
              type="sub"
              :text="$t('account.setting.info.popup.OTP.cancel')"
              @click="closeModal"
            />
          </div>
        </div>
      </div>
      <!--
        ワンタイムパスワードを入力を成功した時に表示される画面
      -->
      <div
        v-else-if="testOk === true || otpEnabled === true"
        class="OTP-linking-success"
      >
        <div>
          <text-box
            v-if="accountInfo.otpEnabled && userToken !== ''"
            :text="$t('account.setting.info.popup.OTP.successOTP')"
            class="text-box"
          />
        </div>
        <buttons
          class="button-close-modal"
          type="sub"
          :text="$t('common.close')"
          @click="closeModal"
        />
      </div>
      <!--
        ワンタイムパスワードの連結を解除する時に現れる警告画面
      -->
      <div
        v-else-if="
          (accountInfo.otpEnabled === true && otpEnabled === false) ||
            showConfirmDelete === true
        "
        class="OTP-linking-caution"
      >
        <texts
          class="text-box"
          size="small"
          :text="caution"
          color="caution"
        />
        <buttons
          class="button-delete-OTP"
          type="caution"
          :text="$t('account.setting.info.popup.OTP.unlink')"
          @click="deleteOTP"
        />
        <buttons
          class="button-close-modal"
          type="sub"
          :text="$t('account.setting.info.popup.OTP.cancel')"
          @click="closeModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import qrcode from 'qrcode.vue'
import loading from '@/components/atoms/loading'
import buttons from '@/components/atoms/button-main'
import texts from '@/components/atoms/text'
import textBox from '@/components/atoms/text-box'
import toggleButton from '@/components/atoms/toggle-button'
import inputBoxValidation from '@/components/molecules/input-box-validation'

const ENABLED = true
const ENABLING = true
const DISABLED = false
const DISABLING = false

export default {
  name: 'OtpGenerator',
  components: {
    qrcode,
    loading,
    buttons,
    texts,
    textBox,
    toggleButton,
    inputBoxValidation
  },
  data() {
    return {
      closePopup: this.showPopup,
      url: null,
      loading: false,
      requestToken: null,
      testOk: null,

      /** otpEnabledをcomputedに移すと挙動が変わるのでとりあえず現在はここにおいときます。 **/
      otpEnabled: this.accountInfo.otpEnabled,

      showConfirmDelete: false,
      destroyOTP: false,
      confirmDestroyOTP: false,
      destroyDisabledSwitch: false,

      switchState: this.accountInfo.otpEnabled ? ENABLED : DISABLED,
      unlinkedOTP: false,
      createdOTPError: false,
      userToken: '',

      /** jaでもenでも同じ値を扱うのでここに置いておきます **/
      linkText: 'Google Authenticator',
      link: '#'
    }
  },
  props: {
    showPopup: Boolean,
    accountInfo: Object
  },
  methods: {
    async generateAndDeleteOTP() {
      this.loading = true
      if (
        /** ワンタイムパスワードが有効時、トグルボタンがonになった時に発火するイベント **/
        this.accountInfo.otpEnabled === true &&
        this.otpEnabled === DISABLED
      ) {
        this.switchState = ENABLED
        this.otpEnabled = true
        this.loading = false
        return
      } else if (
        /** ワンタイムパスワードが無効時、トグルボタンがonになった時に発火するイベント **/
        this.switchState === false &&
        this.otpEnabled === DISABLED
      ) {
        this.switchState = ENABLED
        const res = await this.$sendMessageAndReceive({
          action: 'generateTOTP'
        })
        this.url = res.generatedURL
        this.requestToken = res.request_token
        this.loading = false
      } else if (
        /** ワンタイムパスワードが無効時、トグルボタンがoffになった時に発火するイベント **/
        this.switchState === true &&
        this.otpEnabled === DISABLED
      ) {
        this.switchState = DISABLED
      } else if (
        /** ワンタイムパスワードが有効時、トグルボタンがoffになった時に発火するイベント **/
        this.otpEnabled === true ||
        this.testOk === true
      ) {
        this.testOk = false
        this.showConfirmDelete = true
        this.otpEnabled = DISABLING
        this.loading = false
        return
      }
      if (
        /** ワンタイムパスワードが無効時、トグルボタンを複数回切り替えると起こるバグを修正する発火イベント **/
        this.otpEnabled === true
      ) {
        this.showConfirmDelete = true
        return
      }
      this.loading = false
    },
    async testOTP() {
      this.testOk = null
      const res = await this.$sendMessageAndReceive({
        action: 'testTOTP',
        token: this.userToken,
        request_token: this.requestToken
      })
      switch (res.status) {
        case 'success':
          this.testOk = true
          this.url = null
          this.requestToken = null
          this.switchState = ENABLING
          this.$emit('create')
          break
        case 'error':
          this.testOk = false
          break
      }
    },
    closeModal() {
      this.unlinkedOTP = false
      this.createdOTPError = false
      this.destroyDisabledSwitch = false
      this.$emit('close-modal', this.closePopup)
    },
    async deleteOTP() {
      this.loading = true
      this.url = null
      this.requestToken = null
      const res = this.$sendMessageAndReceive({
        action: 'deleteTOTP'
      })
      if (res.status !== 'error') {
        this.destroyOTP = true
        this.confirmDestroyOTP = true
        this.destroyDisabledSwitch = true
        this.$emit('delete')
      }
      this.loading = ENABLING
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  width: adjustVW(640);
}
.toggle-space {
  margin: $space-text 0 $space-medium;
}
.text-wrap {
  margin: $space-small 0;
}
.text-box {
  margin-bottom: $space-medium;
}
.qr-text-box {
  display: flex;
  margin: 0 0 $space-medium;
}
.qr-text {
  margin-left: $space-medium;
}
.qr-desc {
  &-item {
    word-break: normal;
    white-space: pre-line;
  }
}
.qr-link {
  color: $text-main;
  text-decoration: underline;
}
.qr-icon {
  flex-shrink: 0;
  width: adjustVW(100);
  height: adjustVW(100);
}

/* stylelint-disable */
.qr-icon ::v-deep svg {
  width: 100% !important; // CSS ハックです！！他では絶対にimportantは使わないで！
  height: 100% !important; // CSS ハックです！！他では絶対にimportantは使わないで！
}
/* stylelint-enable */
</style>
