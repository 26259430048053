<template>
  <div class="delete">
    <transition-toggle-contents>
      <div
        v-if="!optimizationInfo.submitDeletingOptimization"
        key="delete"
        class="delete-body"
      >
        <div class="delete-inner-title">
          <texts :text="deleteOptimization.name" />
          <description
            v-if="deleteOptimization.description"
            class="delete-desc"
            :text="deleteOptimization.description"
          />
        </div>
        <div class="delete-inner">
          <div class="delete-title">
            <texts
              :text="$t('trainedAi.optimization.delete.conditions')"
              size="small"
              color="gray"
            />
          </div>
          <optimization-conditions-list
            :optimizationConditions="deleteOptimization"
            :limit="3"
          />
        </div>
        <div class="delete-inner delete-time">
          <text-with-title
            class="delete-time-content"
            :title="$t('common.createTime')"
          >
            <a-time :value="deleteOptimization.createTime" />
          </text-with-title>
          <text-with-title
            class="delete-time-content"
            :title="$t('common.updateTime')"
          >
            <a-time :value="deleteOptimization.updateTime" />
          </text-with-title>
        </div>
      </div>
      <div v-else key="loading" class="delete-body">
        <loading />
      </div>
    </transition-toggle-contents>
    <div class="delete-button">
      <div>
        <button-main
          :text="$t('common.cancel')"
          type="sub"
          :disabled="disableClick"
          @click="$emit('close-modal', 'deleteOptimization')"
        />
      </div>
      <div>
        <button-main
          :text="$t('common.deleteButton')"
          type="caution"
          :disabled="disableClick"
          @click="clickDeleteOptimization"
        />
      </div>
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import description from '@/components/atoms/description'
import loading from '@/components/atoms/loading'
import textWithTitle from '@/components/molecules/text-with-title'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import optimizationConditionsList from '@/components/organisms/optimization/optimization-conditions-list'

export default {
  components: {
    buttonMain,
    description,
    loading,
    textWithTitle,
    transitionToggleContents,
    optimizationConditionsList
  },
  props: {
    optimizationInfo: Object,
    optimizationResult: Object,
    optimizationConditions: Array,
    trainedAi: Object,
    disableClick: Boolean
  },
  computed: {
    deleteOptimization() {
      return this.optimizationConditions.find((condition) => {
        return condition.id === this.optimizationResult.optimizationId
      })
    }
  },
  methods: {
    clickDeleteOptimization() {
      this.$emit('delete-optimization', this.optimizationResult.optimizationId)
    }
  }
}
</script>

<style lang="scss" scoped>
.delete {
  width: adjustVW(724);
  &-body {
    margin-bottom: $space-medium;
  }
  &-inner {
    margin-bottom: $space-medium;
    &:last-of-type {
      margin-bottom: 0;
    }
    &-title {
      margin-bottom: $space-small;
    }
  }
  &-desc {
    margin-top: $space-text;
  }
  &-title {
    margin-bottom: adjustVW(6);
  }
  &-time {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $space-medium;
  }
  &-button {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: $space-small;
  }
}
</style>
