<template>
  <page-top
    :title="$t('selectTargetColumn.title')"
    iconName="backward"
    notScroll
    isButton
    isTrain
    size="min"
    @click="$emit('page-back')"
  />
</template>

<script>
import pageTop from '@/components/atoms/page-top'

export default {
  components: {
    pageTop
  },
  props: {}
}
</script>

<style lang="scss" scoped>
.preprocessing-top-func {
  display: flex;
  justify-content: space-between;
}
</style>
