<template>
  <page-top
    :title="$t('training.page.title')"
    iconName="backward"
    notScroll
    isButton
    isTrain
    @click="$emit('page-back')"
  >
    <div class="top-inner">
      <div class="top-button">
        <button-main
          class="top-button-button"
          :text="$t('training.button.startTraining')"
          type="emphasis"
          :isDisabled="isDisabled"
          @click="$emit('training-start')"
        />
        <button-main
          v-if="trainingProgressStatus === 'ERROR_JOB_LIMIT'"
          class="top-button-button"
          :text="$t('training.button.cancelTraining')"
          type="sub"
          @click="$emit('background-training-stop')"
        />
        <button-main
          v-if="isNotSaved"
          class="top-button-button"
          :text="$t('training.button.compTraining')"
          type="sub"
          @click="saveTrainedAi()"
        />
      </div>
      <div class="top-caution-list">
        <transition-toggle-contents>
          <text-box
            v-if="disableReasons && disableReasons.length > 0"
            class="top-caution-inner"
            isError
          >
            <template v-for="(reason, index) in disableReasons">
              <texts
                :key="index"
                class="top-caution-item"
                :text="reason"
                color="caution"
                size="min"
              />
            </template>
          </text-box>
        </transition-toggle-contents>
        <transition-toggle-contents>
          <text-box
            v-if="isOptimizationNotSet"
            class="top-caution-inner"
            :text="$t('trainedAi.optimization.error.notSet')"
            color="caution"
            size="min"
            isError
          />
          <text-box
            v-if="isOptimizationError"
            class="top-caution-inner"
            :text="$t('trainedAi.optimization.error.settingError')"
            color="caution"
            size="min"
            isError
          />
        </transition-toggle-contents>
        <transition-toggle-contents>
          <text-box
            v-if="message"
            class="top-caution-inner"
            color="caution"
            size="min"
            isError
          >
            <div class="top-caution-main">
              <texts :text="message" color="caution" size="min" />
              <a
                v-if="errorManual"
                class="top-caution-link"
                :href="
                  $t(
                    'training.progressStatus.errorManual.' +
                      errorManual +
                      '.link'
                  )
                "
                target="_blank"
                rel="noopener noreferrer"
              >
                <texts
                  :text="
                    $t(
                      'training.progressStatus.errorManual.' +
                        errorManual +
                        '.displayText'
                    )
                  "
                  color="link-caution"
                  size="min"
                />
              </a>
            </div>
          </text-box>
          <text-box
            v-if="isStoppedTraining"
            class="top-caution-inner"
            :text="
              $t('training.message.stoppedTraining', {
                type: $t('training.message.type.training')
              })
            "
            size="min"
          />
          <text-box
            v-if="isNotSaved"
            class="top-caution-inner"
            :text="$t('training.message.finishedTraining')"
            color="caution"
            size="min"
          />
        </transition-toggle-contents>
      </div>
    </div>
  </page-top>
</template>

<script>
import pageTop from '@/components/atoms/page-top'
import buttonMain from '@/components/atoms/button-main'
import Texts from '@/components/atoms/text.vue'
import TextBox from '@/components/atoms/text-box.vue'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    pageTop,
    buttonMain,
    Texts,
    TextBox,
    TransitionToggleContents
  },
  props: {
    config: Object,
    disableReasons: Array,
    disableOptimization: Array,
    trainingProgressStatus: String,
    message: String,
    errorManual: String,
    errorDetail: Object,
    isStopped: Boolean,
    isNotSaved: Boolean,
    inTraining: Boolean
  },
  computed: {
    // 学習の設定に誤りがある場合、学習中の場合、学習の停止中、保存完了か破棄されるまでは学習実行ボタンをdisabled
    isDisabled() {
      if (
        this.disableReasons.length > 0 ||
        this.disableOptimization.length > 0 ||
        this.inTraining ||
        this.isNotSaved
      ) {
        return true
      } else {
        return false
      }
    },
    isStoppedTraining() {
      return (
        this.isStopped && this.trainingProgressStatus !== 'stoppingTraining'
      )
    },
    isOptimizationNotSet() {
      if (!this.disableOptimization || this.disableOptimization.length === 0)
        return
      return this.disableOptimization.some((item) => {
        return item?.type === 'notSet'
      })
    },
    isOptimizationError() {
      if (!this.disableOptimization || this.disableOptimization.length === 0)
        return
      return this.disableOptimization.some((item) => {
        return item?.type && item.type !== 'notSet'
      })
    }
  },
  methods: {
    saveTrainedAi() {
      // 下部へスクロール
      const element = document.documentElement
      const bottom = element.scrollHeight - element.clientHeight
      window.scrollTo({
        top: bottom,
        behavior: 'smooth'
      })
      this.$emit('save-trained-ai')
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  &-inner {
    display: flex;
    align-items: flex-start;
  }
  &-button {
    margin: 0 $space-medium 0 0;
  }
  &-caution {
    &-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      height: adjustVW(48); // buttonMainの高さ
    }
    &-item {
      margin: 0 $space-text $space-text 0;
      &:nth-of-type(2n) {
        margin: 0 $space-text 0 0;
      }
      &:last-of-type {
        margin: 0;
      }
    }
    &-inner {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      margin-right: $space-small;
      &:last-of-type {
        margin: 0;
      }
    }
    &-main {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }
}
</style>
