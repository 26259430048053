<template>
  <div class="button-wrap" @mouseleave="removeTips">
    <button
      v-tooltip="tooltip"
      class="button"
      :disabled="isDisabled"
      @click="$emit('click', iconName)"
      @mouseover="showTips"
    >
      <icons
        :iconName="iconName"
        :color="isDisabled ? 'gray' : 'default'"
        :size="22"
      />
    </button>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'

export default {
  components: {
    icons
  },
  data() {
    return {
      isTipsShow: false
    }
  },
  props: {
    /** レシピの編集に使用するボタン 'undo', 'redo', 'alignment', 'changeHorizontal', 'changeVertical', 'resetMove' */
    iconName: String,
    /** 使用不可の時 undo と redoの時は戻る行く先がない時があるので使用 */
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tooltip() {
      return {
        content: this.$t('recipeEdit.tool.' + this.iconName),
        trigger: ['manual'],
        show: this.isTipsShow,
        delay: { show: 500, hide: 500 }
      }
    }
  },
  methods: {
    showTips() {
      this.isTipsShow = true
    },
    removeTips() {
      this.isTipsShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  position: relative;
  width: adjustVW(40);
  height: adjustVW(40);
  padding: $space-base;
  background: $background;
  border-radius: adjustVW(8);
  box-shadow: $box-shadow-hover;
  z-index: 1;
  transition: opacity $transition-base, background-color $transition-base,
    box-shadow $transition-base;
  will-change: background-color box-shadow;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    background-color: $background-decoration;
    box-shadow: $box-shadow-hover, $border-radius-emphasis inset;
    opacity: 1;
  }
  &-wrap {
    position: relative;
  }
}
</style>
