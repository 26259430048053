var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"sidebar"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"top-link"},[_c('button',{on:{"click":_vm.goBack}},[_c('text-with-icon',{attrs:{"text":_vm.projectId != null
                ? _vm.$t('common.trainLeave.popup.leaveButton')
                : _vm.$t('sidebar.train.backward'),"iconName":"backward","size":"small"}})],1)]),(_vm.projectInfo)?_c('div',{staticClass:"detail"},[_c('texts',{staticClass:"title",attrs:{"text":_vm.projectInfo.name,"size":"large"}}),_c('description',{attrs:{"text":_vm.projectInfo.description,"line":"2"}})],1):(_vm.activeItem === 0)?_c('div'):_c('div',{staticClass:"detail"},[_c('loading-icon')],1)]),(_vm.projectInfo || _vm.activeItem === 0)?_c('div',{staticClass:"flow"},[_c('texts',{staticClass:"flow-title",attrs:{"text":_vm.$t('sidebar.train.status.title'),"size":"small","color":"gray"}}),_c('div',{staticClass:"list"},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.activeItem === 0 ? _vm.toolTipContents : null),expression:"activeItem === 0 ? toolTipContents : null",modifiers:{"right":true}}],staticClass:"item",class:{
            'item-active': _vm.activeItem === 0,
            'item-passed': _vm.activeItem >= 0,
            'item-disabled': _vm.checkNotFound
          },attrs:{"event":_vm.activeItem <= 0 ? '' : 'click',"disabled":_vm.checkNotFound},on:{"click":function($event){return _vm.sideberLinkClick({ name: 'selectMode' }, 0)}}},[_c('div',{staticClass:"item-main-wrap"},[_c('div',{staticClass:"item-count",class:'item-count-' + _vm.checkColor(0)},[(_vm.activeItem <= 0)?_c('texts',{attrs:{"text":"1","color":_vm.activeItem === 0 ? 'function' : 'gray'}}):_c('icons',{staticClass:"item-icon-check",attrs:{"iconName":"check","size":16,"color":"green"}})],1),_c('div',{staticClass:"item-title-wrap"},[_c('texts',{staticClass:"item-title",attrs:{"text":_vm.$t('sidebar.train.status.project.title'),"size":"small","color":_vm.activeItem === 0
                    ? 'emphasis'
                    : _vm.activeItem > 0
                      ? 'default'
                      : 'gray',"isBold":""}}),(_vm.activeItem === 0)?_c('icons',{attrs:{"iconName":"info","color":"emphasis","size":22}}):_vm._e()],1)]),_c('div',{staticClass:"item-sub-wrap",class:'item-sub-wrap-' + _vm.checkColor(0)},[(_vm.activeItem === 0)?_c('div',{staticClass:"item-inner-active"},[_c('texts',{staticClass:"item-bar-title",attrs:{"text":_vm.statusList[_vm.activeLink][_vm.status].name,"size":"min","color":"gray"}}),_c('div',{staticClass:"item-bar"},[_c('graph-bar-count',{attrs:{"count":_vm.statusList[_vm.activeLink][_vm.status].count,"maxCount":100}})],1)],1):_vm._e(),(_vm.activeItem > 0)?_c('div',{staticClass:"item-inner"},[_c('texts',{staticClass:"item-inner-text",attrs:{"text":_vm.$t('sidebar.train.status.project.itemName'),"size":"min","color":"gray"}}),_c('texts',{attrs:{"text":_vm.projectInfo.sourceTemplate
                    ? _vm.projectInfo.sourceTemplate.name
                    : 'ー',"size":"small"}})],1):_vm._e()])]),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.activeItem === 1 ? _vm.toolTipContents : null),expression:"activeItem === 1 ? toolTipContents : null",modifiers:{"right":true}}],staticClass:"item",class:{
            'item-active': _vm.activeItem === 1,
            'item-passed': _vm.activeItem >= 1,
            'item-disabled': _vm.checkNotFound
          },attrs:{"event":_vm.activeItem <= 1 ? '' : 'click',"disabled":_vm.checkNotFound},on:{"click":function($event){return _vm.sideberLinkClick(
              { name: 'datasetSetting', params: { projectId: _vm.projectId } },
              1
            )}}},[_c('div',{staticClass:"item-main-wrap"},[_c('div',{staticClass:"item-count",class:'item-count-' + _vm.checkColor(1)},[(_vm.activeItem <= 1)?_c('texts',{attrs:{"text":"2","color":_vm.activeItem === 1 ? 'function' : 'gray'}}):_c('icons',{staticClass:"item-icon-check",attrs:{"iconName":"check","size":16,"color":"green"}})],1),_c('div',{staticClass:"item-title-wrap"},[_c('texts',{staticClass:"item-title",attrs:{"text":_vm.$t('sidebar.train.status.dataset.title'),"size":"small","color":_vm.activeItem === 1
                    ? 'emphasis'
                    : _vm.activeItem > 1
                      ? 'default'
                      : 'gray',"isBold":""}}),(_vm.activeItem === 1)?_c('icons',{attrs:{"iconName":"info","color":"emphasis","size":22}}):_vm._e()],1)]),_c('div',{staticClass:"item-sub-wrap",class:'item-sub-wrap-' + _vm.checkColor(1)},[(_vm.activeItem === 1)?_c('div',{staticClass:"item-inner-active"},[_c('texts',{staticClass:"item-bar-title",attrs:{"text":_vm.statusList[_vm.activeLink][_vm.status].name,"size":"min","color":"gray"}}),_c('div',{staticClass:"item-bar"},[_c('graph-bar-count',{attrs:{"count":_vm.statusList[_vm.activeLink][_vm.status].count,"maxCount":100}})],1)],1):_vm._e(),(_vm.activeItem > 1)?_c('div',{staticClass:"item-inner"},[_c('texts',{staticClass:"item-inner-text",attrs:{"text":_vm.$t('sidebar.train.status.dataset.itemName'),"size":"min","color":"gray"}}),(_vm.progressTraining && !_vm.datasetLoading)?_c('texts',{attrs:{"text":_vm.getDatasetName(_vm.progressTraining.datasetId),"size":"small"}}):(_vm.datasetLoading)?_c('div',{staticClass:"item-inner-text-loading"},[_c('loading-icon')],1):_c('texts',{attrs:{"text":"ー","size":"small"}})],1):_vm._e()])]),(!_vm.checklaterSelectTargetColumn)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.activeItem === 2 ? _vm.toolTipContents : null),expression:"activeItem === 2 ? toolTipContents : null",modifiers:{"right":true}}],staticClass:"item",class:{
            'item-active': _vm.activeItem === 2,
            'item-passed': _vm.activeItem >= 2,
            'item-disabled': _vm.checkDataset || _vm.checkNotFound,
            'item-passed-multi-column':
              _vm.progressTraining &&
              _vm.progressTraining.targetColumn &&
              _vm.progressTraining.targetColumn.length > 1
          },attrs:{"event":_vm.activeItem <= 2 ? '' : 'click',"disabled":_vm.checkDataset || _vm.checkNotFound},on:{"click":function($event){return _vm.sideberLinkClick(
              {
                name: 'selectTargetColumn',
                params: {
                  projectId: _vm.projectId,
                  datasetId: _vm.progressTraining
                    ? _vm.progressTraining.datasetId
                    : ''
                }
              },
              2
            )}}},[_c('div',{staticClass:"item-main-wrap"},[_c('div',{staticClass:"item-count",class:'item-count-' + _vm.checkColor(2)},[(_vm.activeItem <= 2)?_c('texts',{attrs:{"text":"3","color":_vm.activeItem === 2 ? 'function' : 'gray'}}):_c('icons',{staticClass:"item-icon-check",attrs:{"iconName":"check","size":16,"color":"green"}})],1),_c('div',{staticClass:"item-title-wrap"},[_c('texts',{staticClass:"item-title",attrs:{"text":_vm.$t('sidebar.train.status.selectTargetColumn.title'),"iconName":"target","size":"small","color":_vm.activeItem === 2
                    ? 'emphasis'
                    : _vm.activeItem > 2
                      ? 'default'
                      : 'gray',"isBold":""}}),(_vm.activeItem === 2)?_c('icons',{attrs:{"iconName":"info","color":"emphasis","size":22}}):_vm._e()],1)]),_c('div',{staticClass:"item-sub-wrap",class:'item-sub-wrap-' + _vm.checkColor(2)},[(_vm.activeItem === 2)?_c('div',{staticClass:"item-inner-active"},[_c('texts',{staticClass:"item-bar-title",attrs:{"text":_vm.statusList[_vm.activeLink][_vm.status].name,"size":"min","color":"gray"}}),_c('div',{staticClass:"item-bar"},[_c('graph-bar-count',{attrs:{"count":_vm.statusList[_vm.activeLink][_vm.status].count,"maxCount":100}})],1)],1):_vm._e(),(_vm.activeItem > 2)?_c('div',{staticClass:"item-inner"},[_c('texts',{staticClass:"item-inner-text",attrs:{"text":_vm.$t('sidebar.train.status.selectTargetColumn.itemName'),"size":"min","color":"gray"}}),(_vm.progressTraining && _vm.progressTraining.targetColumn)?_c('div',[_vm._l((Math.min(
                    _vm.progressTraining.targetColumn.length,
                    3
                  )),function(index){return _c('div',{key:index},[_c('texts',{attrs:{"text":_vm.progressTraining.targetColumn[index - 1],"size":"small"}})],1)}),(_vm.progressTraining.targetColumn.length > 3)?_c('texts',{staticClass:"item-inner-other-columns",attrs:{"text":_vm.$t(
                      'sidebar.train.status.selectTargetColumn.otherColumn',
                      { count: _vm.progressTraining.targetColumn.length - 3 }
                    ),"size":"min","color":"gray"}}):_vm._e()],2):_c('texts',{attrs:{"text":"ー","size":"small"}})],1):_vm._e()])]):_vm._e(),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(
            _vm.activeItem === _vm.preprocessingIndex ? _vm.toolTipContents : null
          ),expression:"\n            activeItem === preprocessingIndex ? toolTipContents : null\n          ",modifiers:{"right":true}}],staticClass:"item",class:{
            'item-active': _vm.activeItem === _vm.preprocessingIndex,
            'item-passed': _vm.activeItem >= _vm.preprocessingIndex,
            'item-disabled':
              _vm.checkDataset || _vm.checkOtherAccountDataset || _vm.checkNotFound
          },attrs:{"event":_vm.activeItem <= _vm.preprocessingIndex ? '' : 'click',"disabled":_vm.checkDataset || _vm.checkOtherAccountDataset || _vm.checkNotFound},on:{"click":function($event){return _vm.sideberLinkClick(
              {
                name: 'trainPreprocessing',
                params: {
                  projectId: _vm.projectId,
                  type: '0',
                  id: _vm.progressTraining ? _vm.progressTraining.datasetId : ''
                }
              },
              _vm.preprocessingIndex
            )}}},[_c('div',{staticClass:"item-main-wrap"},[_c('div',{staticClass:"item-count",class:'item-count-' + _vm.checkColor(_vm.preprocessingIndex)},[(_vm.activeItem <= _vm.preprocessingIndex)?_c('texts',{attrs:{"text":_vm.preprocessingIndex + 1,"color":_vm.activeItem === _vm.preprocessingIndex ? 'function' : 'gray'}}):_c('icons',{staticClass:"item-icon-check",attrs:{"iconName":"check","size":16,"color":"green"}})],1),_c('div',{staticClass:"item-title-wrap"},[_c('texts',{staticClass:"item-title",attrs:{"text":_vm.$t('sidebar.train.status.preprocessing.title'),"size":"small","color":_vm.activeItem >= _vm.preprocessingIndex ? 'default' : 'gray',"isBold":""}}),(_vm.activeItem === _vm.preprocessingIndex)?_c('icons',{attrs:{"iconName":"info","color":"emphasis","size":22}}):_vm._e()],1)]),_c('div',{staticClass:"item-sub-wrap",class:'item-sub-wrap-' + _vm.checkColor(_vm.preprocessingIndex)},[(_vm.activeItem === _vm.preprocessingIndex)?_c('div',{staticClass:"item-inner-active"},[_c('texts',{staticClass:"item-bar-title",attrs:{"text":_vm.statusList[_vm.activeLink][_vm.status].name,"size":"min","color":"gray"}}),_c('div',{staticClass:"item-bar"},[_c('graph-bar-count',{attrs:{"count":_vm.statusList[_vm.activeLink][_vm.status].count,"maxCount":100,"bgWhite":""}})],1)],1):_vm._e(),(_vm.activeItem > _vm.preprocessingIndex)?_c('div',{staticClass:"item-inner"},[_c('texts',{staticClass:"item-inner-text",attrs:{"text":_vm.$t('sidebar.train.status.preprocessing.itemName'),"size":"min","color":"gray"}}),(
                  _vm.progressTraining &&
                    !_vm.datasetLoading &&
                    !_vm.checkOtherAccountDataset
                )?_c('texts',{attrs:{"text":_vm.getDatasetName(_vm.progressTraining.preprocessingDatasetId),"size":"small"}}):(_vm.datasetLoading && !_vm.checkOtherAccountDataset)?_c('div',{staticClass:"item-inner-text-loading"},[_c('loading-icon')],1):_c('texts',{attrs:{"text":"ー","size":"small"}})],1):_vm._e()])]),(_vm.checklaterSelectTargetColumn)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.activeItem === 3 ? _vm.toolTipContents : null),expression:"activeItem === 3 ? toolTipContents : null",modifiers:{"right":true}}],staticClass:"item",class:{
            'item-active': _vm.activeItem === 3,
            'item-passed': _vm.activeItem >= 3,
            'item-disabled': _vm.checkDataset || _vm.checkNotFound,
            'item-passed-multi-column':
              _vm.progressTraining &&
              _vm.progressTraining.targetColumn &&
              _vm.progressTraining.targetColumn.length > 1
          },attrs:{"event":_vm.activeItem <= 3 ? '' : 'click',"disabled":_vm.checkDataset || _vm.checkNotFound},on:{"click":function($event){return _vm.sideberLinkClick(
              {
                name: 'selectTargetColumn',
                params: {
                  projectId: _vm.projectId,
                  datasetId: _vm.progressTraining
                    ? _vm.progressTraining.preprocessingDatasetId
                      ? _vm.progressTraining.preprocessingDatasetId
                      : _vm.progressTraining.datasetId
                    : ''
                }
              },
              3
            )}}},[_c('div',{staticClass:"item-main-wrap"},[_c('div',{staticClass:"item-count",class:'item-count-' + _vm.checkColor(3)},[(_vm.activeItem <= 3)?_c('texts',{attrs:{"text":"4","color":_vm.activeItem === 3 ? 'function' : 'gray'}}):_c('icons',{staticClass:"item-icon-check",attrs:{"iconName":"check","size":16,"color":"green"}})],1),_c('div',{staticClass:"item-title-wrap"},[_c('texts',{staticClass:"item-title",attrs:{"text":_vm.$t('sidebar.train.status.selectTargetColumn.title'),"iconName":"target","size":"small","color":_vm.activeItem === 3
                    ? 'emphasis'
                    : _vm.activeItem > 3
                      ? 'default'
                      : 'gray',"isBold":""}}),(_vm.activeItem === 3)?_c('icons',{attrs:{"iconName":"info","color":"emphasis","size":22}}):_vm._e()],1)]),_c('div',{staticClass:"item-sub-wrap",class:'item-sub-wrap-' + _vm.checkColor(3)},[(_vm.activeItem === 3)?_c('div',{staticClass:"item-inner-active"},[_c('texts',{staticClass:"item-bar-title",attrs:{"text":_vm.statusList[_vm.activeLink][_vm.status].name,"size":"min","color":"gray"}}),_c('div',{staticClass:"item-bar"},[_c('graph-bar-count',{attrs:{"count":_vm.statusList[_vm.activeLink][_vm.status].count,"maxCount":100}})],1)],1):_vm._e(),(_vm.activeItem > 3)?_c('div',{staticClass:"item-inner"},[_c('texts',{staticClass:"item-inner-text",attrs:{"text":_vm.$t('sidebar.train.status.selectTargetColumn.itemName'),"size":"min","color":"gray"}}),(_vm.progressTraining)?_c('div',[_vm._l((Math.min(
                    _vm.progressTraining.targetColumn.length,
                    3
                  )),function(index){return _c('div',{key:index},[_c('texts',{attrs:{"text":_vm.progressTraining.targetColumn[index - 1],"size":"small"}})],1)}),(_vm.progressTraining.targetColumn.length > 3)?_c('texts',{staticClass:"item-inner-other-columns",attrs:{"text":_vm.$t(
                      'sidebar.train.status.selectTargetColumn.otherColumn',
                      { count: _vm.progressTraining.targetColumn.length - 3 }
                    ),"size":"min","color":"gray"}}):_vm._e()],2):_c('texts',{attrs:{"text":"ー","size":"small"}})],1):_vm._e()])]):_vm._e(),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.activeItem === 4 ? _vm.toolTipContents : null),expression:"activeItem === 4 ? toolTipContents : null",modifiers:{"right":true}}],staticClass:"item",class:{
            'item-active': _vm.activeItem === 4,
            'item-passed': _vm.activeItem >= 4,
            'item-disabled': _vm.checkNotFound
          },attrs:{"event":_vm.activeItem <= 4 ? '' : 'click',"disabled":_vm.checkNotFound},on:{"click":function($event){return _vm.sideberLinkClick(
              { name: 'trainRecipeList', params: { projectId: _vm.projectId } },
              4
            )}}},[_c('div',{staticClass:"item-main-wrap"},[_c('div',{staticClass:"item-count",class:'item-count-' + _vm.checkColor(4)},[(_vm.activeItem <= 4)?_c('texts',{attrs:{"text":"5","color":_vm.activeItem === 4 ? 'function' : 'gray'}}):_c('icons',{staticClass:"item-icon-check",attrs:{"iconName":"check","size":16,"color":"green"}})],1),_c('div',{staticClass:"item-title-wrap"},[_c('texts',{staticClass:"item-title",attrs:{"text":_vm.$t('sidebar.train.status.recipe.title'),"size":"small","color":_vm.activeItem === 4
                    ? 'emphasis'
                    : _vm.activeItem > 4
                      ? 'default'
                      : 'gray',"isBold":""}}),(_vm.activeItem === 4)?_c('icons',{attrs:{"iconName":"info","color":"emphasis","size":22}}):_vm._e()],1)]),_c('div',{staticClass:"item-sub-wrap",class:'item-sub-wrap-' + _vm.checkColor(4)},[(_vm.activeItem === 4)?_c('div',{staticClass:"item-inner-active"},[_c('texts',{staticClass:"item-bar-title",attrs:{"text":_vm.statusList[_vm.activeLink][_vm.status].name,"size":"min","color":"gray"}}),_c('div',{staticClass:"item-bar"},[_c('graph-bar-count',{attrs:{"count":_vm.statusList[_vm.activeLink][_vm.status].count,"maxCount":100}})],1)],1):_vm._e(),(_vm.activeItem > 4)?_c('div',{staticClass:"item-inner"},[_c('texts',{staticClass:"item-inner-text",attrs:{"text":_vm.$t('sidebar.train.status.recipe.itemName'),"size":"min","color":"gray"}}),(_vm.progressTraining && !_vm.recipeLoading)?_c('texts',{attrs:{"text":_vm.getRecipeName(_vm.progressTraining.recipeId),"size":"small"}}):(_vm.recipeLoading)?_c('div',{staticClass:"item-inner-text-loading"},[_c('loading-icon')],1):_c('texts',{attrs:{"text":"ー","size":"small"}})],1):_vm._e()])]),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.activeItem === 5 ? _vm.toolTipContents : null),expression:"activeItem === 5 ? toolTipContents : null",modifiers:{"right":true}}],staticClass:"item item-last",class:{
            'item-active': _vm.activeItem === 5,
            'item-passed': _vm.activeItem >= 5,
            'item-disabled': _vm.checkNotFound
          },attrs:{"disabled":_vm.checkNotFound}},[_c('div',{staticClass:"item-main-wrap"},[_c('div',{staticClass:"item-count",class:'item-count-' + _vm.checkColor(5)},[(_vm.activeItem <= 5)?_c('texts',{attrs:{"text":"6","color":_vm.activeItem === 5 ? 'function' : 'gray'}}):_c('icons',{staticClass:"item-icon-check",attrs:{"iconName":"check","size":16,"color":"green"}})],1),_c('div',{staticClass:"item-title-wrap"},[_c('texts',{staticClass:"item-title",attrs:{"text":_vm.$t('sidebar.train.status.train.title'),"size":"small","color":_vm.activeItem === 5
                    ? 'emphasis'
                    : _vm.activeItem > 5
                      ? 'default'
                      : 'gray',"isBold":""}}),(_vm.activeItem === 5)?_c('icons',{attrs:{"iconName":"info","color":"emphasis","size":22}}):_vm._e()],1)]),_c('div',{staticClass:"item-sub-wrap",class:'item-sub-wrap-' + _vm.checkColor(5)},[(_vm.activeItem === 5)?_c('div',{staticClass:"item-inner-active"},[_c('texts',{staticClass:"item-bar-title",attrs:{"text":_vm.statusList[_vm.activeLink][_vm.status].name,"size":"min","color":"gray"}}),_c('div',{staticClass:"item-bar"},[_c('graph-bar-count',{attrs:{"count":_vm.statusList[_vm.activeLink][_vm.status].count,"maxCount":100}})],1)],1):_vm._e()])])])],1):_vm._e()]),_c('popup-array',{attrs:{"showPopup":_vm.notFound ? ['preventTrainingStatus'] : _vm.showPopupItems,"popupDefs":_vm.popupDefs,"noClose":_vm.notFound},on:{"close-modal":function($event){return _vm.closePopup($event)}},scopedSlots:_vm._u([{key:"trainPreventLeave",fn:function(){return [_c('train-prevent-leave',{attrs:{"linkTo":_vm.linkTo,"progressTraining":_vm.progressTraining},on:{"not-prevent":function($event){return _vm.$emit('not-prevent')},"close-modal":function($event){_vm.closePopup('trainPreventLeave'), _vm.$emit('restart-prevent')}}})]},proxy:true},{key:"preventTrainingStatus",fn:function(){return [_c('prevent-training-status',{on:{"not-prevent":function($event){return _vm.$emit('not-prevent')}}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }