import { render, staticRenderFns } from "./trained-ai-detail-tab-inference.vue?vue&type=template&id=5da3a8e3&scoped=true&"
import script from "./trained-ai-detail-tab-inference.vue?vue&type=script&lang=js&"
export * from "./trained-ai-detail-tab-inference.vue?vue&type=script&lang=js&"
import style0 from "./trained-ai-detail-tab-inference.vue?vue&type=style&index=0&id=5da3a8e3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5da3a8e3",
  null
  
)

export default component.exports