<!-- 既存のlist-item-slotでは実現できないため、専用のリスト系のコンポーネントを使用する -->
<template>
  <div class="setting-wrap">
    <div class="setting-title">
      <texts
        :text="$t('trainedAi.timeTransformerV2.settingResult.title')"
        size="large"
      />
      <transition-toggle-contents>
        <button v-if="filteredKey" @click="closeFilter">
          <text-with-icon
            :text="
              $t(
                'trainedAi.timeTransformerV2.settingResult.types.' + filteredKey
              )
            "
            iconName="close"
            size="small"
            class="setting-filter"
          />
        </button>
      </transition-toggle-contents>
    </div>
    <div class="setting-body">
      <div class="head">
        <div
          v-for="(key, index) in field"
          :key="index"
          class="head-item"
          :class="`head-item-${key}`"
        >
          <texts :text="key.label" isBold size="small" />
        </div>
      </div>
      <div class="item">
        <div v-for="(item, index) in items" :key="index" class="item-inner">
          <div class="item-main">
            <div class="item-key">
              <texts
                class="item-key-text"
                size="small"
                isBold
                :text="
                  $t(
                    'trainedAi.timeTransformerV2.settingResult.types.' +
                      item.key
                  )
                "
              />
            </div>
            <div class="item-conditions">
              <div class="item-conditions-list">
                <conditions-list :conditions="item.conditions" />
              </div>
              <div
                v-if="!noFilter"
                class="item-filter"
                :class="{
                  'item-filter-active': filteredKey === item.key
                }"
              >
                <icons
                  iconName="search"
                  size="small"
                  isButton
                  :color="checkColor(item)"
                  :isDisabled="
                    item.columns.length === 0 && filteredKey !== item.key
                  "
                  @icon-click="clickFilter(item.columns, item.key)"
                />
              </div>
            </div>
          </div>
          <div>
            <toggle-contents
              :title="$t('trainedAi.timeTransformerV2.settingResult.columns')"
              size="small"
              :color="item.columns.length === 0 ? 'gray' : 'emphasis'"
              :isDisabled="item.columns.length === 0"
            >
              <template>
                <div class="columns-list">
                  <div
                    v-for="(column, index) in item.columns"
                    :key="index"
                    class="columns-item"
                  >
                    <dot-text :text="column" color="emphasis" size="min" />
                  </div>
                </div>
              </template>
            </toggle-contents>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import dotText from '@/components/atoms/dot-text.vue'
import toggleContents from '@/components/molecules/toggle-contents.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import textWithIcon from '@/components/molecules/text-with-icon.vue'
import conditionsList from './conditions-list.vue'

const timeConditionTypes = () => {
  return [
    {
      key: 'timeVaryingKnownCategoricals',
      conditions: {
        isKnown: true,
        isTimeVarying: true,
        isReal: false
      },
      columns: []
    },
    {
      key: 'timeVaryingKnownReals',
      conditions: {
        isKnown: true,
        isTimeVarying: true,
        isReal: true
      },
      columns: []
    },
    {
      key: 'timeVaryingUnknownCategoricals',
      conditions: {
        isKnown: false,
        isTimeVarying: true,
        isReal: false
      },
      columns: []
    },
    {
      key: 'timeVaryingUnknownReals',
      conditions: {
        isKnown: false,
        isTimeVarying: true,
        isReal: true
      },
      columns: []
    },
    {
      key: 'staticCategoricals',
      conditions: {
        isKnown: true,
        isTimeVarying: false,
        isReal: false
      },
      columns: []
    },
    {
      key: 'staticReals',
      conditions: {
        isKnown: true,
        isTimeVarying: false,
        isReal: true
      },
      columns: []
    }
  ]
}

export default {
  components: {
    icons,
    dotText,
    toggleContents,
    transitionToggleContents,
    textWithIcon,
    conditionsList
  },
  props: {
    settingList: {
      type: Array,
      default: () => []
    },
    noFilter: {
      type: Boolean,
      default: false
    },
    predictionColumns: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      filteredKey: null
    }
  },
  methods: {
    clickFilter(columns, key) {
      let emitColumns = []
      if (this.filteredKey === key) {
        this.filteredKey = null
      } else {
        emitColumns = columns
        this.filteredKey = key
      }
      this.$emit('click-time-transformer-v2-filter', emitColumns)
    },
    checkColor(item) {
      if (item.key === this.filteredKey) return 'emphasis'
      if (item.columns.length === 0) return 'gray'
      return 'default'
    },
    closeFilter() {
      this.filteredKey = null
      this.$emit('click-time-transformer-v2-filter', [])
    }
  },
  computed: {
    field() {
      return [
        {
          label: this.$t(
            'trainedAi.timeTransformerV2.settingResult.types.title'
          ),
          key: 'key',
          width: 240
        },
        {
          label: this.$t('trainedAi.timeTransformerV2.settingResult.values'),
          key: 'conditions',
          width: 328
        }
      ]
    },
    // timeConditionTypesで定義した設定の種類に適合した列の名前を、各設定の種類のcolumnsに格納する
    items() {
      const res = JSON.parse(JSON.stringify(timeConditionTypes()))
      Object.values(this.settingList).forEach((setting) => {
        if (this.predictionColumns.includes(setting.columnName)) return
        const target = Object.values(res).find((type) => {
          return !Object.entries(type.conditions).some(([key, value]) => {
            return value !== setting[key].value
          })
        })
        if (target == null) return
        target.columns.push(setting.columnName)
      })
      return res
    }
  }
}
</script>

<style lang="scss" scoped>
$grid-template-columns: 1fr adjustVW(328);

.setting {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-small;
    height: 100%;
  }
  &-title {
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
  }
  &-body {
    overflow: hidden;
    display: grid;
    flex-direction: column;
    grid-template-rows: auto minmax(0, 1fr);
    height: 100%;
  }
  &-filter {
    width: fit-content;
  }
}

.head {
  display: grid;
  grid-template-areas: 'key conditions';
  grid-template-columns: $grid-template-columns;
  padding: 0 $space-sub + $space-base * 2 $space-sub $space-sub; // 右の余白にスクロールバーとスクロールバーとの間の余白をあけているので、$space-sub + $space-bae * 2としている
  border-bottom: $border-main;
  &-key {
    grid-area: key;
  }
  &-conditions {
    grid-area: conditions;
  }
}

.item {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: $space-base $space-base $space-base 0;
  margin: $space-sub 0;
  @include scrollbar;
  &-inner {
    padding: 0 $space-sub;
    &:not(:last-of-type) {
      padding-bottom: $space-small;
      margin-bottom: $space-small;
      border-bottom: $border-sub;
    }
  }
  &-main {
    display: grid;
    grid-template-areas: 'key conditions';
    grid-template-columns: $grid-template-columns;
    grid-column-gap: $space-small;
    margin-bottom: $space-base;
  }
  &-key {
    grid-area: key;
    overflow: hidden;
    &-text {
      white-space: pre-line;
    }
  }
  &-conditions {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-area: conditions;
    grid-column-gap: $space-small;
    &-list {
      overflow: hidden;
      width: 100%;
    }
  }
  &-filter {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: adjustVW(36);
    height: adjustVW(36);
    background-color: transparent;
    border-radius: 9in;
    transition: background-color $transition-base;
    &-active {
      background-color: $key-lite;
    }
  }
}

.columns {
  &-list {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: $space-base;
    grid-row-gap: $space-base;
  }
  &-item {
    max-width: adjustVW(112);
  }
}
</style>
