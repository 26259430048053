<template>
  <div class="list">
    <template v-for="(item, index) in items">
      <bullet-item
        :key="index"
        :text="item.text"
        :caution="item.caution"
        :sub="item.sub"
        :isDisabled="item.isDisabled"
        class="item"
      />
    </template>
  </div>
</template>

<script>
import bulletItem from '@/components/atoms/bullet-item'

export default {
  components: {
    bulletItem
  },
  props: {
    /** id: id, text: 表示するテキスト, caution: アイコンを赤にする, sub: アイコンを緑にする, isDisabled: アイコンをグレーにする */
    items: Array
  }
}
</script>

<style lang="scss" scoped>
.item {
  margin: 0 0 $space-base;
  &:last-of-type {
    margin: 0;
  }
}
</style>
