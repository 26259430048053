<template>
  <div key="list" class="detail-inner">
    <div class="detail-top">
      <detail-top
        :key="resetKey"
        v-model="trainedAiInput"
        :isSaved="isSaved"
        :trainedAi="trainedAi"
        :trainedAIFormValidate="trainedAIFormValidate"
        @input-create-form="$emit('input-create-form', $event)"
        @save-trained-ai="$emit('save-trained-ai')"
        @show-trained-ai-list="$emit('show-trained-ai-list')"
      />
    </div>
    <div class="detail-body">
      <detail-body
        :accountInfo="accountInfo"
        :isSaved="isSaved"
        :recipeType="recipeType"
        :trainedAi="trainedAi"
        :selectedColumnIndex="selectedColumnIndex"
        :textMining="textMining"
        :threshold="threshold"
        :reversePositive="reversePositive"
        :testDatasetInfo="testDatasetInfo"
        :regressionGraph="regressionGraph"
        @delete-trained-ai="$emit('delete-trained-ai')"
        @change-tab="$emit('change-tab')"
        @change-test-dataset-page="$emit('change-test-dataset-page', $event)"
        @download-test-dataset="$emit('download-test-dataset', $event)"
        @change-threshold="$emit('change-threshold', $event)"
        @reverse-positive="$emit('reverse-positive', $event)"
        @load-text-mining="$emit('load-text-mining', $event)"
        @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
        @select-regression-graph="$emit('select-regression-graph', $event)"
        @download-confusion-matrix="$emit('download-confusion-matrix', $event)"
      />
    </div>
  </div>
</template>

<script>
import detailTop from './detail/detail-top'
import detailBody from './detail/detail-body'

export default {
  components: {
    detailTop,
    detailBody
  },
  props: {
    accountInfo: Object,
    isSaved: Boolean,
    recipeType: String,
    resetKey: Number,
    trainedAi: Object,
    trainedAIFormValidate: Object,
    value: Object,
    selectedColumnIndex: Number,
    textMining: Object,
    threshold: Number,
    reversePositive: Boolean,
    testDatasetInfo: Object,
    regressionGraph: Object
  },
  computed: {
    trainedAiInput: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  &-inner {
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-row-gap: $space-medium;
    height: 100%;
  }
  &-body {
    overflow: hidden;
  }
}
</style>
