// 実行済みの前処理レイヤーを表示するための共通メソッド
export function getLayers(appliedChart, inputFlag = true) {
  if (appliedChart?.layers) {
    return appliedChart.layers.slice(inputFlag ? 0 : 1)
  } else {
    return null
  }
}

export function checkPreprocessingDatasetSize({ dataset, accountInfo }) {
  const datasetSize = dataset.size
  const totalDataSize = accountInfo.totalDataSize
  const maxDataSize = accountInfo.planDetail.maxDataSize

  const showPopup = totalDataSize + datasetSize > maxDataSize

  return {
    datasetSize,
    maxDataSize,
    showPopup,
    totalDataSize
  }
}
