<template>
  <transition-toggle-contents>
    <!-- 概要タブの表示だが、学習情報を表示する -->
    <trained-ai-detail-tab-detail-no-accuracy
      v-if="activeTab === 'trainedInfo'"
      key="trainedInfo"
      :trainedAi="trainedAi"
    />
    <trained-ai-detail-tab-text-mining
      v-else-if="activeTab === 'textMining'"
      key="textMining"
      :textMining="textMining"
      :textMiningTypeList="textMiningTypeList"
      @load-text-mining="$emit('load-text-mining', $event)"
      @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
    />
    <trained-ai-detail-tab-clustering-result
      v-else-if="activeTab === 'clusteringResult'"
      key="clusteringResult"
      :clusteringInfo="trainedAi.summary.clusteringInfo"
      :clusteringSetting="clusteringSetting"
      :clusteringResult="clusteringResult"
      :clusteringDistributions="clusteringDistributions"
      :distributionItems="distributionItems"
      @input-clustering-setting="$emit('input-clustering-setting', $event)"
      @download-clustering-result="$emit('download-clustering-result', $event)"
      @change-clustering-distribution-column="
        $emit('change-clustering-distribution-column', $event)
      "
      @toggle-clustering-show-dimension="
        $emit('toggle-clustering-show-dimension', $event)
      "
    />
    <trained-ai-detail-tab-clustering-distribution
      v-else-if="activeTab === 'clusteringDistribution'"
      key="clusteringDistribution"
      :clusteringInfo="trainedAi.summary.clusteringInfo"
      :clusteringSetting="clusteringSetting"
      :clusteringResult="clusteringResult"
      :clusteringDistributions="clusteringDistributions"
      :distributionItems="distributionItems"
      @input-clustering-setting="$emit('input-clustering-setting', $event)"
      @change-clustering-distribution-column="
        $emit('change-clustering-distribution-column', $event)
      "
      @toggle-clustering-show-dimension="
        $emit('toggle-clustering-show-dimension', $event)
      "
    />
  </transition-toggle-contents>
</template>

<script>
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import trainedAiDetailTabDetailNoAccuracy from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-detail-no-accuracy'
import trainedAiDetailTabTextMining from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-text-mining'
import trainedAiDetailTabClusteringResult from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-clustering-result'
import trainedAiDetailTabClusteringDistribution from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-clustering-distribution'

export default {
  components: {
    transitionToggleContents,
    trainedAiDetailTabDetailNoAccuracy,
    trainedAiDetailTabTextMining,
    trainedAiDetailTabClusteringResult,
    trainedAiDetailTabClusteringDistribution
  },
  props: {
    trainedAi: Object,
    tabs: Array,
    activeTab: String,
    activeMenu: String,
    finishColumns: Array,
    selectedColumnIndex: Number,
    loadChangeColumn: Boolean,
    textMining: Object,
    clusteringSetting: Object,
    clusteringResult: Object,
    clusteringDistributions: Array
  },
  computed: {
    textMiningTypeList() {
      if (this.trainedAi.summary?.textMining == null) return {}
      return this.trainedAi.summary?.textMining.typeList
    },
    distributionItems() {
      const clusteringInfo = this.trainedAi.summary?.clusteringInfo
      if (
        clusteringInfo == null ||
        (clusteringInfo.distributionInputList.length === 0 &&
          clusteringInfo.dimensionList.length === 0)
      )
        return {}

      let items = []
      if (this.clusteringSetting.setting.showDimension) {
        items = clusteringInfo.dimensionList.map((item) => {
          const splitedValue = item.split('dim_')[1]
          const name = this.$t('clustering.result.dimension', {
            dimension: splitedValue
          })
          return {
            name,
            value: item
          }
        })
      } else if (clusteringInfo.distributionInputList.length > 0) {
        items = clusteringInfo.distributionInputList.map((item) => {
          return {
            name: item,
            value: item
          }
        })
      }
      return {
        items,
        hasDimensionValue:
          clusteringInfo.dimensionList &&
          clusteringInfo.dimensionList.length > 0
      }
    }
  }
}
</script>
