<template>
  <div class="c-table">
    <div class="c-table-wrap" :class="{ 'c-table-statics': isStatics }">
      <table
        class="c-table-body"
        :class="{
          'c-table-body-loading': loadPage,
          'c-table-body-active': !loadPage
        }"
      >
        <thead>
          <tr class="c-table-name-block">
            <th
              v-for="(key, index) in displayColumns"
              :key="index"
              class="c-table-name-inner"
            >
              <texts v-show="!loadPage" :text="key" size="small" />
            </th>
          </tr>
        </thead>
        <tbody>
          <table-main-graph
            v-if="isStatics"
            :graph="graph"
            :tableColumns="displayColumns"
          />
          <tr
            v-for="(entry, entryIndex) in translateTable"
            :key="entryIndex"
            class="c-table-data-block"
          >
            <td
              v-for="(key, keyIndex) in displayColumns"
              :key="keyIndex"
              class="c-table-data-inner"
            >
              <div
                v-if="isStatics && entry[key] == undefined"
                v-tooltip="noItemInfo"
                class="c-table-data-inner-no-item"
              >
                <table-td-text
                  v-show="!loadPage"
                  :popupKey="keyIndex + '-' + entryIndex"
                  text="ー"
                />
              </div>
              <template v-else>
                <table-td-text
                  v-show="!loadPage"
                  :popupKey="keyIndex + '-' + entryIndex"
                  :placeEnd="7 >= translateTable.length - entryIndex + 1"
                  :text="entry[key]"
                />
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!noPaging" class="c-table-paging">
      <paging
        :value="inPageNumber"
        :count="tableColumnsLength"
        :pagingCount="pagingCount"
        @input="changePage"
      />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import tableMainGraph from '@/components/molecules/table-main-graph.vue'
import tableTdText from '@/components/molecules/table-td-text.vue'
import paging from '@/components/molecules/paging.vue'

export default {
  components: {
    texts,
    tableMainGraph,
    tableTdText,
    paging
  },
  data() {
    return {
      inPageNumber: 1,
      loadPage: false
    }
  },
  props: {
    /** テーブルの列名の配列 */
    tableColumns: Array,
    /** テーブル本体 */
    table: Array,
    /** 統計情報を表示 */
    isStatics: Boolean,
    /** グラフボタンをクリックで表示されるグラフ */
    graph: Object,
    /** ページングの列数 */
    pagingCount: Number,
    /** ページングなしの表示 */
    noPaging: Boolean
  },
  computed: {
    translateTable() {
      const target = JSON.parse(JSON.stringify(this.table))
      if (this.isStatics) {
        target.forEach((element, index) => {
          element['/'] = this.$i18n.t('statistic.' + element['/'])
        })
        return target
      }
      return target
    },
    noItemInfo() {
      return {
        content: this.$t('common.table.statistics.objectItem'),
        trigger: 'hover',
        delay: { show: 200, hide: 200 }
      }
    },
    displayColumns() {
      if (this.noPaging) return this.tableColumns
      let startCount = (this.inPageNumber - 1) * this.pagingCount
      let endCount = this.inPageNumber * this.pagingCount

      if (this.isStatics && this.inPageNumber === 1) {
        endCount = this.inPageNumber * this.pagingCount + 1
      } else if (this.isStatics && this.inPageNumber > 1) {
        startCount = (this.inPageNumber - 1) * this.pagingCount + 1
      }
      const base = this.tableColumns.slice(startCount, endCount)
      if (this.isStatics && this.inPageNumber > 1) {
        base.unshift('/')
      }
      return [...base]
    },
    tableColumnsLength() {
      if (this.isStatics) {
        return this.tableColumns.length - 1 // 統計情報の/列分減らす
      } else {
        return this.tableColumns.length
      }
    }
  },
  methods: {
    changePage(num) {
      this.loadPage = true
      this.inPageNumber = num
      this.$nextTick(() => {
        this.loadPage = false
      })
    }
  },
  watch: {
    tableColumns: {
      handler() {
        this.loadPage = true
        this.$nextTick(() => {
          this.loadPage = false
        })
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.c-table {
  display: flex;
  flex-direction: column;
  grid-row-gap: $space-base;
  width: 100%;
  height: 100%;
  &-wrap {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    @include scrollbar;
  }
  &-body {
    width: 100%;
    text-align: center;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: collapse;
    &-loading {
      opacity: 0;
      transform: translateX($space-base);
      transition: opacity 0.15s, transform 0.15s;
      will-change: opacity, transform;
    }
    &-active {
      animation: showContent $transition-base;
    }
  }
  &-name {
    &-block {
      background: $background;
    }
    &-inner {
      position: sticky;
      top: 0;
      overflow: hidden;
      width: auto;
      height: adjustVH(56);
      padding: 0 $space-base;
      background: $background;
      font-size: $text-base;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      z-index: 11;
      &::after {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 0;
        width: 100%;
        height: 1px;
        background: $border-gray;
      }
    }
  }
  &-data {
    &-inner {
      position: relative;
      overflow: hidden;
      height: adjustVH(56);
      padding: 0 $space-small;
      font-size: $text-base;
      text-overflow: ellipsis;
      white-space: nowrap;
      z-index: 1;
      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 1px;
        width: 100%;
        height: 1px;
        background: $line-gray;
      }
      &-no-item {
        cursor: help;
      }
      &-large-csv {
        white-space: pre;
      }
    }
  }
  &-paging {
    align-self: flex-end;
    padding-right: $space-sub;
  }
}

.c-table-statics {
  .c-table {
    &-name-inner {
      &:first-child {
        left: 0;
        overflow: visible;
        background: $background;
        z-index: 23;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 1px;
          height: 100%;
          background: $border-gray;
        }
      }
    }
    &-data {
      &-block {
        &:first-child {
          .c-table-data-inner {
            position: sticky;
            top: adjustVH(56);
            background: $background;
            z-index: 22;
            &:first-child {
              position: sticky;
              left: 0;
              overflow: visible;
              z-index: 24;
            }
            &-show-graph {
              z-index: 30;
            }
          }
        }
      }
      &-inner {
        &:first-child {
          position: sticky;
          left: 0;
          overflow: visible;
          background: $background;
          z-index: 20;
          &::before {
            content: '';
            position: absolute;
            right: 0;
            bottom: 1px;
            width: 100%;
            height: 1px;
            background: $line-gray;
          }
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 1px;
            height: 100%;
            background: $border-gray;
          }
        }
      }
    }
  }
}

@keyframes showContent {
  0% {
    opacity: 0;
    transform: translateX($space-base);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.toggle-detail-enter-active,
.toggle-detail-leave-active {
  transition: opacity $transition-base;
}
.toggle-detail-enter,
.toggle-detail-leave-to {
  opacity: 0;
  will-change: opacity, transform;
}
</style>
