<template>
  <div>
    <prevent-leave
      ref="preventLeave"
      v-model="isPrevent"
      :title="$t('common.trainLeave.popup.title')"
      :saveButton="
        $t('common.trainLeave.popup.saveButton', { currentPage: currentPage })
      "
      :leaveButton="$t('common.trainLeave.popup.leaveButtonProject')"
      leaveButtonColor="sub"
      @save-event="closePrevent"
    >
      <texts class="prevent-leave-text" isShowAll size="small">
        <span
          v-html="
            $t('common.trainLeave.reason.first', {
              leavePage: leavePage,
              currentPage: currentPage
            })
          "
        />
        <span
          v-html="
            $t('common.trainLeave.reason.second', { currentPage: currentPage })
          "
        />
        <span v-html="$t('common.trainLeave.reason.project')" />
      </texts>
    </prevent-leave>
    <project-template-select-mode
      v-bind="data"
      :accountInfo="accountInfo"
      :sidebar="sidebar"
      @close-modal="closePopup($event), changeStatus('modeSelect')"
      @create-project="createProjectSelf($event)"
      @input-create-form="inputCreateForm($event)"
      @use-recipe="showPopup('ProjectTemplateCreate'), changeStatus('setting')"
      @use-template="useTemplate()"
      @page-back="pageBack"
    />
  </div>
</template>

<script>
import { projectValidator } from '@/lib/validator/project.js'
import {
  checkOuterTraining,
  checkCorrectBackPage
} from '@/lib/training-pages-correct-behavior'
import { mapActions, mapGetters } from 'vuex'
import setMountedTimer from '@/mixin/set-mounted-timer'

import projectTemplateSelectMode from '@/components/templates/project-template-select-mode.vue'
import preventLeave from '@/components/molecules/prevent-leave'

export default {
  components: {
    projectTemplateSelectMode,
    preventLeave
  },
  mixins: [setMountedTimer],
  async beforeRouteLeave(to, from, next) {
    this.isPrevent = this.checkPrevent(to.name)
    if (this.isPrevent) {
      this.leavePageName = to.name
      const result = await this.$refs.preventLeave.$confirm()
      if (result) {
        this.$router.push({
          name: 'projectList'
        })
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  computed: {
    ...mapGetters('project', ['createdProject', 'projectList']),
    ...mapGetters('auth', ['accountInfo']),

    sidebar() {
      return {
        // サイドバーに表示する情報
        project: {
          name: '退職予測',
          description:
            'このプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入ります',
          template: '退職予測テンプレート',
          dataset: 'データセット名が入ります',
          target: '予測する列名が入ります',
          preprocessingDataset: '前処理後のデータセットが入ります',
          recipe: '使用するレシピ名が入ります'
        },
        activeLink: 'projectTemplate',
        status: 'modeSelect'
      }
    },
    leavePage() {
      return this.$t('common.trainLeave.titles.' + this.leavePageName)
    },
    currentPage() {
      return this.$t('common.trainLeave.titles.' + this.currentPageName)
    }
  },
  data() {
    return {
      data: {
        popup: {
          createInfo: {
            projectFormValidate: {
              duplicate: null,
              suggest: null
            }
          },
          showPopup: [],
          submitDisabled: false
        },
        headerTabs: {
          // ヘッダーのタブ
          tabs: [],
          tabSelect: 1
        }
      },
      newProject: {
        name: '',
        description: '',
        tags: []
      },
      isPrevent: false,
      correctTransition: false,
      currentPageName: this.$route.name,
      leavePageName: null
    }
  },
  methods: {
    ...mapActions('project', ['createProject', 'loadProjectList']),
    ...mapActions('models', ['setProgressTraining']),

    openRecipeModal(e) {
      if (this.data.popup.showPopup.length > 0) {
        this.data.popup.showPopup = []
        this.data.popup.showPopup.push(e)
      } else {
        this.data.popup.showPopup.push(e)
      }
    },
    closePopup(e) {
      // ポップアップを閉じる
      this.data.popup.showPopup = this.data.popup.showPopup.filter(
        (n) => n !== e
      )
    },
    showPopup(e) {
      this.data.popup.showPopup.push(e)
    },
    createProjectSelf: async function ({ name, description, tags }) {
      this.data.popup.submitDisabled = true

      try {
        await this.createProject({ name, description, tags })
        this.correctTransition = true

        this.setProgressTraining({
          item: this.createProject.projectId,
          setType: 'project'
        })

        this.$gtmDataLayer.sendEvent('createProject', 'self')
        this.$router.push({
          name: 'datasetSetting',
          params: { projectId: this.createdProject.projectId }
        })
      } catch (err) {
        this.log_info(err)
      } finally {
        this.data.popup.submitDisabled = false
      }
    },
    changeStatus(status) {
      this.sidebar.status = status
    },
    useTemplate() {
      this.correctTransition = true
      this.$router.push({ name: 'projectTemplateList' })
    },
    inputCreateForm: function (obj) {
      const { type, form } = obj

      if (type === 'project') {
        this.newProject = Object.assign(this.newProject, form)
        this.data.popup.createInfo.projectFormValidate = projectValidator(
          this.projectList,
          this.newProject
        )
      }
    },
    checkPrevent(nextPageName) {
      return (
        !checkOuterTraining(nextPageName) &&
        !checkCorrectBackPage('project', nextPageName) &&
        !this.correctTransition
      )
    },
    closePrevent() {
      this.$refs.preventLeave.closePopup()
    },
    pageBack() {
      this.$router.push({
        name: 'projectList'
      })
    }
  },
  mounted: async function () {
    await this.loadProjectList()
  }
}
</script>

<style lang="scss" scoped>
.prevent-leave-text {
  display: flex;
  flex-direction: column;
  grid-row-gap: $space-sub;
  ::v-deep {
    .prevent-leave-text-emphasis {
      color: $text-decoration;
    }
    .prevent-leave-text-caution {
      color: $text-caution;
    }
  }
}
</style>
