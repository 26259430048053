<template>
  <div class="service-delete-wrap">
    <texts
      class="service-delete-desc"
      :text="$t('trainedAiDetails.popup.serviceDeleteCheck.desc')"
      size="small"
    />
    <div class="service-delete-list">
      <div
        v-for="(trainedAi, key) in hasServiceAi"
        :key="key"
        class="service-delete-inner"
      >
        <text-with-title
          class="service-delete-item"
          :text="trainedAi.name"
          :title="
            $t('trainedAiDetails.popup.serviceDeleteCheck.deleteTrainedAi')
          "
        />
        <div class="service-delete-item">
          <texts
            class="service-delete-sub-title"
            :text="
              $t('trainedAiDetails.popup.serviceDeleteCheck.deleteServices')
            "
            size="small"
            color="gray"
          />
          <div
            v-for="(service, key) in trainedAi.services"
            :key="key"
            class="service-delete-service-list"
          >
            <texts :text="service.name" />
          </div>
        </div>
      </div>
    </div>
    <div class="service-delete-button-list">
      <button-main
        :text="$t('common.cancel')"
        type="sub"
        @click="$emit('close-modal', 'deleteServiceCheck')"
      />
      <button-main
        :text="$t('trainedAiDetails.popup.serviceDeleteCheck.button')"
        type="caution"
        @click="deleteClick"
      />
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import textWithTitle from '@/components/molecules/text-with-title'

export default {
  components: {
    buttonMain,
    textWithTitle
  },
  props: {
    trainedAiList: Array,
    disableClick: Boolean
  },
  computed: {
    hasServiceAi() {
      if (!this.trainedAiList) return []
      return this.trainedAiList.filter((ai) => ai.services.length > 0)
    }
  },
  methods: {
    deleteClick() {
      this.$emit(
        'delete',
        this.trainedAiList.map((x) => x.id)
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.service-delete {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: adjustVW(744);
  }
  &-desc {
    flex-shrink: 0;
    margin-bottom: $space-medium;
    white-space: pre-line;
  }
  &-list {
    overflow-y: auto;
    flex-shrink: 1;
    height: 100%;
    margin-bottom: $space-medium;
    @include scrollbar;
  }
  &-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $space-medium;
    padding-bottom: $space-small;
    margin-bottom: $space-small;
    border-bottom: $border-main;
    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }
  &-item {
    overflow: hidden;
  }
  &-sub-title {
    margin-bottom: $space-min;
  }
  &-service-list {
    margin-bottom: $space-text;
  }
  &-button-list {
    display: flex;
    flex-shrink: 0;
    justify-content: flex-end;
  }
}
</style>
