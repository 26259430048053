var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[_c('div',{staticClass:"table-wrap",class:{ 'table-statics': _vm.isStatics }},[(_vm.noData)?_c('div',{key:"noData",staticClass:"no-data"},[_c('transition-toggle-contents',[(!_vm.loadingDatasetDetail)?_c('div',{key:"none",staticClass:"no-data-content"},[_c('texts',{staticClass:"no-data-title",attrs:{"text":_vm.$t('datasetSetting.selectDataset.tooLarge'),"size":"small","color":"gray"}}),_c('button-main',{attrs:{"text":_vm.$t('datasetSetting.selectDataset.tooLargeButton'),"type":"sub"},on:{"click":function($event){return _vm.$emit('show-too-large-data')}}})],1):_c('div',{key:"loading",staticClass:"no-data-content"},[_c('loading')],1)])],1):_vm._e(),_c('table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.noData),expression:"!noData"}],key:"table",staticClass:"table-body",class:{
        'table-body-loading': _vm.loadPage,
        'table-body-active': !_vm.loadPage
      },on:{"mouseleave":_vm.reaveItem}},[_c('thead',[_c('tr',{staticClass:"table-name"},_vm._l((_vm.displayColumns),function(key,index){return _c('th',{key:index,staticClass:"table-name-inner",class:_vm.cellStatus(index),on:{"mouseover":function($event){return _vm.hoverItem(index)},"click":function($event){return _vm.clickColumn(index)}}},[_c('texts',{attrs:{"text":key,"color":_vm.checkCautionName(key) ? 'caution' : '',"size":"small"}}),_c('div',{staticClass:"caution-popup-wrapper"},[_c('popup-balloon',{staticClass:"caution-popup-inner",class:{
                  'caution-popup-inner-right':
                    _vm.displayColumns.length - index < 3
                },attrs:{"isShow":_vm.preventCautionMessage ? false : _vm.showCautionMessage(index),"showCloseButton":false,"showBottom":""}},[_c('texts',{staticClass:"caution-popup-title",attrs:{"text":_vm.$i18n.t(
                      'selectTargetColumn.datasetInfo.list.caution.title'
                    )}}),_c('texts',{staticClass:"caution-popup-title-sub",attrs:{"color":"gray","size":"small","text":_vm.$i18n.t(
                      'selectTargetColumn.datasetInfo.list.caution.titleSub'
                    )}}),_c('div',{staticClass:"caution-popup-reason-message-wrapper"},[_vm._l((_vm.cautionMessage),function(item){return [(_vm.checkReason(key, item.value))?_c('div',{key:item.index,staticClass:"caution-popup-reason-message-item"},[_c('div',{staticClass:"caution-popup-reason-message-marker"}),_c('texts',{staticClass:"caution-popup-reason-message-text",attrs:{"size":"small","text":item.text}})],1):_vm._e()]})],2),_c('button-main',{staticClass:"caution-popup-checked-button",attrs:{"type":"emphasis","text":_vm.$i18n.t(
                      'selectTargetColumn.datasetInfo.list.caution.button'
                    )},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.confirmCaution(index)}}})],1)],1)],1)}),0)]),_c('tbody',[(_vm.isStatics)?_c('table-main-graph',{attrs:{"graph":_vm.graph,"tableColumns":_vm.displayColumns,"hoverTarget":_vm.hoverTarget,"selectedColumn":_vm.selectedColumn,"isCellButton":""},on:{"mouseover":function($event){return _vm.hoverItem($event)},"click":function($event){return _vm.clickColumn($event)}}}):_vm._e(),_vm._l((_vm.translateTable),function(entry,entryIndex){return _c('tr',{key:entryIndex + 'entry',staticClass:"table-data"},_vm._l((_vm.displayColumns),function(key,keyIndex){return _c('td',{key:keyIndex + 'td',staticClass:"table-data-inner",class:_vm.cellStatus(keyIndex),on:{"mouseover":function($event){return _vm.hoverItem(keyIndex)},"click":function($event){return _vm.clickColumn(keyIndex)}}},[(_vm.isStatics && entry[key] == undefined)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.noItemInfo),expression:"noItemInfo"}],staticClass:"table-data-inner-no-item"},[_c('table-td-text',{attrs:{"popupKey":keyIndex + '-' + entryIndex,"text":"ー"}})],1):_c('table-td-text',{attrs:{"popupKey":keyIndex + '-' + entryIndex,"placeEnd":7 >= _vm.translateTable.length - entryIndex + 1,"text":entry[key],"isDisabled":_vm.cellStatus(keyIndex) !== 'table-item-selected-cells'},nativeOn:{"click":function($event){return _vm.checkClickStatus(keyIndex, $event)}}})],1)}),0)})],2)])]),_c('div',{staticClass:"table-paging"},[_c('paging',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.noteHasError ? _vm.pagingDisabledMessage : null),expression:"noteHasError ? pagingDisabledMessage : null"}],attrs:{"value":_vm.inPageNumber,"count":_vm.tableColumnsLength,"pagingCount":_vm.pagingCount,"isDisabled":_vm.noteHasError},on:{"input":_vm.changePage}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }