<script>
import * as d3 from 'd3'

import Axis from './mixins/Axis'
import Tip from './mixins/Tip'

export default {
  mixins: [Axis, Tip],
  props: {
    data: {
      type: Object,
      default: () => {},
      validator(val) {
        const checkTarget = Object.keys(val)
        const checkList = [
          'max',
          'min',
          'first',
          'third',
          'median',
          'mean',
          'outside',
          'realMin',
          'realMax',
          'columnName'
        ]
        return !checkList.some((item) => {
          return !checkTarget.includes(item)
        })
      }
    },
    graphMargin: {
      type: Object,
      default: () => {
        return {
          top: 12,
          right: 12,
          bottom: 12,
          left: 32
        }
      }
    },
    isStack: {
      type: Boolean,
      default: false
    },
    transitionDuration: {
      default: 500
    },
    marginratio: {
      default: 0.01
    },
    graphSetting: {
      type: Object,
      default: () => {
        return {
          mainColor: '#850491',
          subColor: '#049185',
          blockColor: '#F7E9F9',
          block: {
            maxWidth: 200,
            opacity: 0.3
          },
          stroke: {
            width: 1.5
          },
          outside: {
            r: 5,
            opacity: 0.65
          }
        }
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    styles() {
      return {
        width: '100%',
        height: '100%',
        'min-height': '100%'
      }
    },
    xScale() {
      return d3
        .scalePoint()
        .range([0, this.graphWidth])
        .domain([this.data.columnName])
    },
    yScale() {
      return d3
        .scaleLinear()
        .range([this.graphHeight, 0])
        .domain([this.yData.mn, this.yData.mx])
        .nice()
    },
    // 凡例に余白をつけるための処理 marginratioで余白を調節できる
    yData() {
      const ex = (this.data.realMax - this.data.realMin) * this.marginratio
      const mx = this.data.realMax > 0 ? this.data.realMax + ex : 0
      const mn = this.data.realMin < 0 ? this.data.realMin - ex : 0
      return {
        ex,
        mx,
        mn
      }
    },
    boxWidth() {
      return Math.min(this.graphSetting.block.maxWidth, this.graphWidth) / 2
    },
    // ツールチップ用の変換処理
    tipsValue() {
      const outside = this.data.outside.map((value) => {
        return {
          key: this.$t('statistic.outside'),
          value
        }
      })
      return {
        min: {
          key: this.$t('statistic.min'),
          value: this.data.min
        },
        max: {
          key: this.$t('statistic.max'),
          value: this.data.max
        },
        median: {
          key: this.$t('statistic.text50'),
          value: this.data.median
        },
        mean: {
          key: this.$t('statistic.mean'),
          value: this.data.mean
        },
        outside: [...outside]
      }
    }
  },
  methods: {
    generateTip(d) {
      return `<p>${d.key}</p><p>${d.value}</p>`
    },
    drawGraph() {
      const vueThis = this
      this.drawAxes()

      this.body.selectAll('.group').remove()

      let groupSel = this.body.selectAll('.group')
      groupSel = groupSel.data([this.data.columnName])

      groupSel
        .enter()
        .append('g')
        .attr('class', 'group')
        .each(function (...args) {
          vueThis.updateGroup(this, ...args)
        })

      groupSel.each(function (...args) {
        vueThis.updateGroup(this, ...args)
      })

      groupSel.exit().remove()
    },
    updateGroup(el, g, i, node) {
      const y = d3
        .scaleLinear()
        .range([this.graphHeight, 0])
        .domain([this.yData.mn, this.yData.mx])
        .nice()

      const boxWidth = this.boxWidth
      const center = this.graphWidth / 2

      // 箱ひげ図中央の線の下側の描画
      d3.select(el)
        .append('line')
        .attr('x1', center)
        .attr('x2', center)
        .attr('y1', y(this.data.min))
        .attr('y2', y(this.data.first))
        .attr('stroke', this.graphSetting.mainColor)
        .style('stroke-width', this.graphSetting.stroke.width)

      // 箱ひげ図中央の線の上側の描画
      d3.select(el)
        .append('line')
        .attr('x1', center)
        .attr('x2', center)
        .attr('y1', y(this.data.max))
        .attr('y2', y(this.data.third))
        .attr('stroke', this.graphSetting.mainColor)
        .style('stroke-width', this.graphSetting.stroke.width)

      // 箱ひげ図のはこの描画
      d3.select(el)
        .append('rect')
        .attr('x', center - boxWidth / 2)
        .attr('y', y(this.data.third))
        .attr('height', y(this.data.first) - y(this.data.third))
        .attr('width', boxWidth)
        .style('fill', this.graphSetting.blockColor)
        .style('opacity', this.graphSetting.block.opacity)

      // 箱ひげ図のはこの枠線の描画
      d3.select(el)
        .append('rect')
        .attr('x', center - boxWidth / 2)
        .attr('y', y(this.data.third))
        .attr('height', y(this.data.first) - y(this.data.third))
        .attr('width', boxWidth)
        .style('stroke', this.graphSetting.mainColor)
        .style('stroke-width', this.graphSetting.stroke.width)
        .style('fill', 'transparent')

      // 箱ひげ図の最小値最大値の描画
      d3.select(el)
        .selectAll('toto')
        .data([this.tipsValue.min, this.tipsValue.max])
        .enter()
        .append('line')
        .attr('x1', center - boxWidth / 4)
        .attr('x2', center + boxWidth / 4)
        .attr('y1', function (d) {
          return y(d.value)
        })
        .attr('y2', function (d) {
          return y(d.value)
        })
        .attr('stroke', this.graphSetting.mainColor)
        .style('stroke-width', this.graphSetting.stroke.width)
        .on('mouseover', this.showTip)
        .on('mouseout', this.hideTip)

      // 箱ひげ図の中央値の描画
      d3.select(el)
        .selectAll('toto')
        .data([this.tipsValue.median])
        .enter()
        .append('line')
        .attr('x1', center - boxWidth / 2)
        .attr('x2', center + boxWidth / 2)
        .attr('y1', function (d) {
          return y(d.value)
        })
        .attr('y2', function (d) {
          return y(d.value)
        })
        .attr('stroke', this.graphSetting.mainColor)
        .style('stroke-width', this.graphSetting.stroke.width)
        .on('mouseover', this.showTip)
        .on('mouseout', this.hideTip)

      // 箱ひげ図の平均値の描画
      d3.select(el)
        .selectAll('meanPoints')
        .data([this.tipsValue.mean])
        .enter()
        .append('circle')
        .attr('cx', center)
        .attr('cy', function (d) {
          return y(d.value)
        })
        .attr('r', this.graphSetting.outside.r)
        .style('fill', this.graphSetting.mainColor)
        .on('mouseover', this.showTip)
        .on('mouseout', this.hideTip)

      // 箱ひげ図の外れ値の描画
      d3.select(el)
        .selectAll('indPoints')
        .data(this.tipsValue.outside)
        .enter()
        .append('circle')
        .attr('cx', center)
        .attr('cy', function (d) {
          return y(d.value)
        })
        .attr('r', this.graphSetting.outside.r)
        .style('fill', this.graphSetting.subColor)
        .style('opacity', this.graphSetting.outside.opacity)
        .on('mouseover', this.showTip)
        .on('mouseout', this.hideTip)
    }
  },
  watch: {
    data: {
      handler() {
        this.drawGraph()
      },
      deep: true
    }
  },
  beforeDestroy() {
    d3.select('.plot-svg').attr('style', 'pointer-events: none;')
  },
  destroyed() {
    this.tip.destroy()
  }
}
</script>

<style lang="scss" scoped>
div {
  height: 100%;
  > svg {
    height: 100%;
  }
}
</style>
