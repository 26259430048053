<template>
  <div v-resize:throttle.100="resized">
    <svg :id="`bar${$_uid}`" ref="graph" class="plot-svg" />
  </div>
</template>
<script>
import * as d3 from 'd3'
import resize from 'vue-resize-directive'
export default {
  directives: {
    resize
  },
  computed: {
    svg() {
      const el = this.$refs.graph
      return d3.select(el)
    }
  }
}
</script>
<style>
.plot-svg {
  width: 100%;
  height: 100%;
}
</style>
