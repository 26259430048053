var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition-toggle-contents',[_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"title"},[_c('texts',{attrs:{"text":_vm.$t('inference.popup.progress.title'),"size":"large"}})],1),_c('div',{staticClass:"list"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-inner"},[_c('transition',{attrs:{"name":"toggle-icon","mode":"out-in"}},[(!_vm.finishPreparing)?_c('loading-icon',{key:"environment",staticClass:"icon",attrs:{"size":"small","color":"emphasis"}}):_c('icons',{key:"train",staticClass:"icon",attrs:{"size":"small","color":"emphasis","iconName":"check"}})],1),_c('texts',{staticClass:"text-pre-line",attrs:{"text":_vm.$t('inference.popup.progress.environment'),"color":"emphasis"}})],1)]),(_vm.showColumnInfo)?_c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-inner"},[_c('div',{staticClass:"item-inner-column"},[_c('transition',{attrs:{"name":"toggle-icon","mode":"out-in"}},[(_vm.maxColumns !== _vm.columnProgress)?_c('loading-icon',{staticClass:"icon",attrs:{"size":"small","color":"emphasis"}}):_c('icons',{staticClass:"icon",attrs:{"size":"small","color":"emphasis","iconName":"check"}})],1),_c('texts',{staticClass:"item-inner-column-shrink",attrs:{"text":_vm.inferenceStatus.columnInfo.inferenceColumn,"color":"emphasis"}}),_c('texts',{staticClass:"item-inner-column-text",attrs:{"text":_vm.$t('inference.popup.progress.inferenceColumn'),"color":"emphasis"}}),_c('texts',{staticClass:"item-inner-progress",attrs:{"text":((_vm.columnProgress / _vm.maxColumns) * 100).toFixed() + '%',"color":"emphasis"}}),_c('texts',{attrs:{"text":_vm.$t('training.result.multiResult.columnProgress', {
                    progress: _vm.columnProgress,
                    progressMax: _vm.maxColumns
                  }),"color":"emphasis"}})],1)])]),_c('div',{staticClass:"graph"},[_c('graph-bar-count',{staticClass:"graph-inner",attrs:{"maxCount":_vm.inferenceStatus.columnInfo.allColumns.length,"count":_vm.inferenceStatus.columnInfo.finishedColumns.length}})],1)]):_vm._e(),_c('div',{class:{ 'inner-progress': _vm.showColumnInfo }},[(_vm.finishPreparing)?_c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-inner"},[_c('transition',{attrs:{"name":"toggle-item","mode":"out-in"}},[_c('div',{staticClass:"item-inner"},[_c('transition',{attrs:{"name":"toggle-icon","mode":"out-in"}},[(_vm.inferenceProgress !== _vm.maxProgress)?_c('loading-icon',{staticClass:"icon",attrs:{"size":"small","color":"emphasis"}}):_c('icons',{staticClass:"icon",attrs:{"size":"small","color":"emphasis","iconName":"check"}})],1),_c('texts',{attrs:{"text":_vm.$t('inference.popup.progress.inferenceProcessing'),"color":"emphasis"}}),_c('texts',{staticClass:"item-inner-progress",attrs:{"text":((_vm.inferenceProgress / _vm.maxProgress) * 100).toFixed() +
                        '%',"color":"emphasis"}}),_c('texts',{attrs:{"text":_vm.$t('training.result.multiResult.columnProgress', {
                        progress: _vm.inferenceProgress,
                        progressMax: _vm.maxProgress
                      }),"color":"emphasis"}})],1)])],1)]),(_vm.inferenceProgress !== _vm.maxProgress)?_c('div',{staticClass:"graph"},[_c('graph-bar-count',{staticClass:"graph-inner",attrs:{"maxCount":_vm.inferenceStatus.dataNum,"count":_vm.inferenceProgress}})],1):_vm._e()]):_vm._e(),(_vm.finishPreparing)?_c('div',{class:{ 'item-wrap': _vm.displayPostProcessing }},[(_vm.inferenceStatus.doImportance)?[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-inner"},[_c('transition',{attrs:{"name":"toggle-item","mode":"out-in"}},[_c('div',{staticClass:"item-inner"},[_c('transition',{attrs:{"name":"toggle-icon","mode":"out-in"}},[(_vm.importanceProgress !== _vm.maxProgress)?_c('loading-icon',{staticClass:"icon",attrs:{"size":"small","color":"emphasis"}}):_c('icons',{staticClass:"icon",attrs:{"size":"small","color":"emphasis","iconName":"check"}})],1),_c('texts',{attrs:{"text":_vm.$t('inference.popup.progress.importanceProcessing'),"color":"emphasis"}}),_c('texts',{staticClass:"item-inner-progress",attrs:{"text":((_vm.importanceProgress / _vm.maxProgress) * 100).toFixed() +
                          '%',"color":"emphasis"}}),_c('texts',{attrs:{"text":_vm.$t('training.result.multiResult.columnProgress', {
                          progress: _vm.importanceProgress,
                          progressMax: _vm.maxProgress
                        }),"color":"emphasis"}})],1)])],1)]),(_vm.importanceProgress !== _vm.maxProgress)?_c('div',{staticClass:"graph"},[_c('graph-bar-count',{staticClass:"graph-inner",attrs:{"maxCount":_vm.inferenceStatus.dataNum,"count":_vm.importanceProgress}})],1):_vm._e()]:[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-inner"},[_c('transition',{attrs:{"name":"toggle-item","mode":"out-in"}},[_c('texts',{attrs:{"text":_vm.$t('trainedAiDetails.noFeatureImportance'),"color":"gray"}})],1)],1)])]],2):_vm._e(),(_vm.displayPostProcessing)?_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-inner"},[_c('transition',{attrs:{"name":"toggle-icon","mode":"out-in"}},[(!_vm.inferenceStatus.finishedPostProcessing)?_c('loading-icon',{key:"environment",staticClass:"icon",attrs:{"size":"small","color":"emphasis"}}):_c('icons',{key:"train",staticClass:"icon",attrs:{"size":"small","color":"emphasis","iconName":"check"}})],1),_c('texts',{attrs:{"text":_vm.$t('inference.popup.progress.postProcessing'),"color":"emphasis"}})],1)]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }