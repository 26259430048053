<template>
  <svg viewBox="0 0 36 36" class="wait-pie">
    <circle
      class="wait-pie-base"
      cx="18"
      cy="18"
      r="15.9"
      fill="none"
      stroke="#efefef"
    />
    <circle
      class="wait-pie-main"
      cx="18"
      cy="18"
      r="15.9"
      fill="none"
      stroke="#850491"
      :stroke-dasharray="scale + ' 100'"
    />
  </svg>
</template>

<script>
export default {
  props: {
    /** 進行度 */
    progress: Number,
    /** 最大値 */
    progressMax: Number
  },
  computed: {
    scale() {
      return ((this.progress / this.progressMax) * 100).toFixed(0)
    }
  }
}
</script>

<style lang="scss" scoped>
.wait {
  &-pie {
    width: 100%;
    height: 100%;
    stroke-width: adjustVW(2);
    &-main {
      transform: rotate(-90deg);
      transform-origin: center;
      transition: stroke-dasharray $transition-base;
    }
  }
}
</style>
