<template>
  <div class="terms-card-area-wrapper">
    <plan-card-base
      class="terms-card-wrapper"
      :isTermsCard="true"
      :isPlanCard="false"
      :menuItems="this.terms"
      :activeTerm="menuTerms.activeTerm"
      @active-item="activeItem"
    >
      <texts class="plan-terms-card-price" :text="priceData.value" />
    </plan-card-base>
  </div>
</template>

<script>
import PlanCardBase from '@/components/molecules/change-plan/plan-card-base.vue'
import Texts from '@/components/atoms/text.vue'

export default {
  components: {
    PlanCardBase,
    Texts
  },
  props: {
    menuTerms: Object
  },
  data() {
    const i18nBase = {
      recipe: this.$i18n.t('changePlan.planTerm.recipe'),
      dataCapacity: this.$i18n.t('changePlan.planTerm.dataCapacity'),
      dataUpload: this.$i18n.t('changePlan.planTerm.dataUpload'),
      trainedAIs: this.$i18n.t('changePlan.planTerm.trainedAIs'),
      organization: this.$i18n.t('changePlan.planTerm.organization'),
      serverUpgrade: this.$i18n.t('changePlan.planTerm.serverUpgrade'),
      apiService: this.$i18n.t('changePlan.planTerm.apiService'),
      trendflow: this.$i18n.t('changePlan.planTerm.trendflow'),
      humanResourcesRecipe: this.$i18n.t(
        'changePlan.planTerm.humanResourcesRecipe'
      ),
      trendflowHumanResourcesRecipe: this.$i18n.t(
        'changePlan.planTerm.trendflowHumanResourcesRecipe'
      ),
      customblock: this.$i18n.t('changePlan.planTerm.customblock'),
      price: this.$i18n.t('changePlan.planTerm.price')
    }

    const termsAll = this.menuTerms.menuItems.map((term) => {
      return {
        id: term.id,
        index: term.index,
        value: i18nBase[term.id]
      }
    })

    const termsData = termsAll.filter((term) => term.id !== 'price')

    const priceData = termsAll.filter((term) => term.id === 'price')[0]

    return {
      terms: termsData,
      priceData
    }
  },
  methods: {
    activeItem: function ({ id }) {
      this.$emit('active-item', { id })
    }
  }
}
</script>

<style lang="scss" scoped>
.terms-card-area-wrapper {
  width: 100%;
  height: 100%;
  padding-top: adjustVH(88);
}

.plan-terms-card-price {
  font-size: $text-large;
}
</style>
