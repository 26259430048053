<template>
  <div class="display-count">
    <div v-if="title" class="title">
      <texts :text="title" color="gray" size="small" />
    </div>
    <div
      class="keys-block"
      :class="{
        'keys-block-horizontal': isHorizontal
      }"
    >
      <div class="keys-space">
        <div class="keys-list">
          <transition-group name="toggle-keys">
            <div
              v-for="({ key, value }, index) in limitCount"
              :key="index + key"
              class="keys-item"
            >
              <component
                :is="barType"
                class="keys-item-name"
                @click="clickBar(key)"
              >
                <texts :text="key" size="small" />
              </component>
              <div
                class="keys-item-bar"
                @mouseenter="isHoverGraph(index)"
                @mouseleave="isLeaveGraph()"
              >
                <component
                  :is="barType"
                  class="keys-item-bar-inner"
                  :class="{
                    'keys-item-bar-inner-active': key === targetBar
                  }"
                  :style="{ '--width': scaleWidth(value) + '%' }"
                  @click="clickBar(key)"
                >
                  <div
                    v-tooltip="{
                      content: key + '\n' + tipsLabel + ' ' + fixValue(value),
                      trigger: ['manual'],
                      show: isTipsShow === index && tipsLabel,
                      placement: isHorizontal ? 'right' : 'top',
                      delay: { show: 500, hide: 500 }
                    }"
                    class="keys-item-tooltip-wrap"
                    :class="{
                      'keys-item-tooltip-wrap-bottom': index === 0
                    }"
                  />
                </component>
              </div>
            </div>
          </transition-group>
        </div>
      </div>
      <div class="keys-bg">
        <div class="keys-bg-scale">
          <div class="keys-bg-scale-item">
            <div class="keys-bg-scale-text">
              0
            </div>
            <div class="keys-bg-line-item" />
          </div>
          <div class="keys-bg-scale-item">
            <div class="keys-bg-scale-text">
              {{ calcLegend(maxScale(), 0.25) }}
            </div>
            <div class="keys-bg-line-item" />
          </div>
          <div class="keys-bg-scale-item">
            <div class="keys-bg-scale-text">
              {{ calcLegend(maxScale(), 0.5) }}
            </div>
            <div class="keys-bg-line-item" />
          </div>
          <div class="keys-bg-scale-item">
            <div class="keys-bg-scale-text">
              {{ calcLegend(maxScale(), 0.75) }}
            </div>
            <div class="keys-bg-line-item" />
          </div>
          <div class="keys-bg-scale-item">
            <div class="keys-bg-scale-text">
              {{ calcLegend(maxScale(), 1) }}
            </div>
            <div class="keys-bg-line-item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toPercentage } from '@/lib/misc'

export default {
  props: {
    /** 表示するリスト key: 表示する名前 value: 表示するバーの値 */
    list: {
      type: Object,
      default: () => {}
    },
    /** グラフのタイトル */
    title: {
      type: String,
      default: ''
    },
    /** ツールチップに表示するラベル。ない場合はツールチップが非表示になる */
    tipsLabel: {
      type: String,
      default: ''
    },
    /** 最大表示件数 存在しない場合、listの長さになる */
    scaleSize: {
      type: Number,
      default: null
    },
    /** ソートせずに結果を表示するか */
    sortContent: {
      type: Boolean,
      default: false
    },
    /** 水平方向の表示に変更 */
    isHorizontal: {
      type: Boolean,
      default: false
    },
    /** グラフクリック時にイベントを発火するかどうか */
    isButton: {
      type: Boolean,
      default: false
    },
    /** グラフクリック時のイベントが発火する場合のv-modelの値 */
    value: {
      type: [String, Number],
      default: ''
    },
    /** 最大表示する個数 */
    maxCount: {
      type: Number,
      default: null
    },
    /** パーセント表示にするかどうか */
    isPercentage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isTipsShow: '',
      targetBar: this.value
    }
  },
  methods: {
    scale(e) {
      const length = String(e).length
      const digits = length - 1
      return Math.round(e * 10 ** digits) / 10 ** digits
    },
    maxScale() {
      if (this.sortedList[0]?.value == null) return 1
      const target = this.sortedList[0].value

      // 小数で1以下-1以上の場合はdigitsがずれるので処理を分岐
      if (target > -1 && target < 1) {
        const targetDecimal = String(this.targetList[0].value).split('.')[1]
        const fixValue = target > 0 ? 1 : -1
        const digits = targetDecimal.search(/[1-9]/) + fixValue
        return Math.ceil(target * 10 ** digits) / 10 ** digits
      } else {
        return Math.ceil(target)
      }
    },
    scaleWidth(e) {
      return (e / this.maxScale()) * 100
    },
    isHoverGraph(e) {
      this.isTipsShow = e
    },
    isLeaveGraph() {
      this.isTipsShow = ''
    },
    calcLegend(max, magnification) {
      const maxLength = String(max).length
      let resValue = 0
      if (max <= 1) {
        resValue =
          Math.ceil(max * 10 ** maxLength * magnification) / 10 ** maxLength
      } else {
        resValue = Math.ceil(max * magnification)
      }
      return this.fixValue(resValue)
    },
    fixValue(value) {
      if (!this.isPercentage) return value
      return toPercentage(value)
    },
    clickBar(key) {
      if (this.isButton) {
        this.targetBar = key
        this.$emit('input', key)
      }
    }
  },
  computed: {
    targetList() {
      if (this.list == null || this.list.length === 0) return []
      const target = Object.entries(this.list).map(([key, value]) => {
        return {
          key,
          value
        }
      })
      return target
    },
    sortedList() {
      const target = this.targetList
      const res = target.sort((prev, current) => {
        return current.value - prev.value
      })
      if (this.maxCount != null) {
        return res.slice(0, this.maxCount)
      } else {
        return res
      }
    },
    displayMax() {
      return this.scaleSize ?? Math.min(20, Object.keys(this.targetList).length)
    },
    limitCount() {
      if (this.sortContent) {
        return this.sortedList.slice(0, this.displayMax)
      } else {
        return this.targetList.slice(0, this.displayMax)
      }
    },
    barType() {
      if (this.isButton) {
        return 'button'
      } else {
        return 'div'
      }
    }
  },
  watch: {
    value(newVal) {
      this.targetBar = newVal
    }
  }
}
</script>

<style lang="scss" scoped>
.display-count {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  margin-bottom: $space-sub;
}

.keys {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: $space-small;
  background: $background;
  border-radius: adjustVW(16);
  box-shadow: $box-shadow-main;
  &-block {
    position: relative;
    overflow: hidden;
    height: 100%;
  }
  &-space {
    height: 100%;
    margin: $space-large 0 0;
  }
  &-list {
    position: relative;
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - #{$space-large});
    padding: 0 $space-medium $space-small 0;
    z-index: 3;
    @include scrollbar;
  }
  &-item {
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin: 0 0 $space-base;
    &:first-child {
      margin: $space-base 0 $space-base;
    }
    &:last-child:not(.toggle-words-leave-active, .toggle-words-enter-active) {
      margin: 0;
    }
    &-name {
      overflow: hidden;
      display: flex;
      align-items: center;
      width: adjustVW(120);
      margin: 0 $space-small 0 0;
    }
    &-bar {
      display: flex;
      align-items: center;
      width: calc(100% - #{adjustVW(120) + $space-small});
      @include enterBar;
      &-inner {
        --width: 100%;
        position: relative;
        width: calc(var(--width));
        height: adjustVH(16);
        background: #dcb8e0;
        border-radius: adjustVW(4);
        transition: background-color $transition-base;
        &-active {
          background: $key-color;
        }
      }
      .toggle-words-leave-active & {
        @include leaveBar;
      }
    }
    &-tooltip {
      &-inner {
        width: fit-content;
        max-width: 100%;
      }
    }
  }
  &-bg {
    position: absolute;
    top: 0;
    right: $space-small;
    width: calc(100% - #{adjustVW(120) + $space-small});
    height: 100%;
    z-index: 2;
    &-scale {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      margin: 0 0 $space-min;
      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-row-gap: $space-base;
        width: adjustVW(32);
        height: 100%;
      }
      &-text {
        height: adjustVH(24);
        color: $text-sub;
        font-size: $text-min;
        @include text-crop;
      }
    }
    &-line {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: calc(100% - #{$space-large});
      margin: $space-large 0 0;
      &-item {
        position: relative;
        width: adjustVW(32);
        height: 100%;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          width: 1px;
          height: 100%;
          background: $line-gray;
          transform: translateX(-50%);
        }
      }
    }
  }
}
.keys-block-horizontal {
  .keys {
    &-space {
      margin-top: 0;
    }
    &-list {
      overflow: hidden;
      display: flex;
      height: 100%;
      padding: 0 0 0 adjustVW(40);
      > span {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        height: calc(100% - #{adjustVW(6)});
        margin-top: adjustVW(8);
      }
    }
    &-item {
      overflow: hidden;
      flex-direction: column-reverse;
      justify-content: space-between;
      height: 100%;
      padding: 0 $space-base;
      margin: 0;
      &:first-child {
        margin: 0 0 0 $space-base;
      }
      &:last-child:not(.toggle-words-leave-active, .toggle-words-enter-active) {
        margin: 0;
      }
      &-name {
        flex-shrink: 0;
        justify-content: center;
        width: auto;
        height: adjustVH(24);
        margin: 0;
      }
      &-bar {
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        height: calc(100% - #{adjustVH(24)} - #{adjustVW(8)});
        transform-origin: bottom;
        &-inner {
          --width: 100%;
          width: calc(100% - #{$space-base});
          height: calc(var(--width));
        }
      }
    }
    &-bg {
      right: inherit;
      left: 0;
      width: 100%;
      height: calc(100% - #{adjustVH(24)});
      &-scale {
        flex-direction: column-reverse;
        &-item {
          flex-direction: row;
          justify-content: flex-start;
          grid-column-gap: $space-base;
          width: 100%;
          height: auto;
        }
        &-text {
          width: adjustVW(40);
          height: auto;
          text-align: left;
          @include text-crop(1);
        }
      }
      &-line {
        flex-direction: column-reverse;
        margin: 0;
        &-item {
          width: 100%;
          height: 1px;
          &::after {
            top: 50%;
            left: auto;
            width: 100%;
            height: 1px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

.toggle-keys-enter-active,
.toggle-keys-leave-active {
  transition: transform $transition-base, opacity $transition-base;
}
.toggle-keys-enter,
.toggle-keys-leave-to {
  opacity: 0;
  transform: translateX(-$space-small);
  will-change: opacity, transform;
}
</style>
