<template>
  <transition name="balloon-toggle" mode="out-in">
    <div
      v-if="isShow"
      class="balloon"
      :class="{
        'balloon-left': showLeft,
        'balloon-bottom': showBottom,
        'balloon-top': showTop,
        'balloon-error': error
      }"
    >
      <div class="balloon-inner" :class="{ 'balloon-inner-error': error }">
        <slot />
        <button
          v-if="showCloseButton"
          class="balloon-icon"
          @click.stop="$emit('close')"
        >
          <icons class="balloon-close" iconName="close" size="min" />
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import icons from '@/components/atoms/icon'

export default {
  components: {
    icons
  },
  props: {
    /** バルーンを表示するかどうか */
    isShow: {
      type: Boolean,
      default: false
    },
    /** バルーンを左側に表示する */
    showLeft: {
      type: Boolean,
      default: false
    },
    /** エラーが起きているかどうか */
    error: {
      type: Boolean,
      default: false
    },
    /** バルーンを下側に表示する */
    showBottom: {
      type: Boolean,
      default: false
    },
    /** バルーンを上側に表示する */
    showTop: {
      type: Boolean,
      default: false
    },
    /** バルーンを閉じるボタンを表示 */
    showCloseButton: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.balloon {
  position: absolute;
  top: adjustVW(-20);
  left: calc(100% + #{$space-sub});
  border-radius: adjustVW(16);
  cursor: auto;
  box-shadow: $box-shadow-hover;
  z-index: 100;
  &-inner {
    position: relative;
    min-height: adjustVW(88);
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(16);
    z-index: 1;
    &-error {
      background: #fdf4f4;
    }
  }
  &-icon {
    position: absolute;
    top: $space-small;
    right: $space-small;
    opacity: 0;
    transition: opacity $transition-base ease-in;
    &:hover {
      opacity: 1;
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: adjustVW(32);
      height: adjustVW(32);
      background: $background;
      border-radius: 9in;
      box-shadow: $box-shadow-main;
      z-index: 0;
      transform: translate(-50%, -50%);
    }
  }
  &-close {
    position: relative;
    z-index: 1;
  }
  &::after {
    content: '';
    position: absolute;
    top: adjustVW(24);
    left: adjustVW(-18);
    width: adjustVW(40);
    height: adjustVW(40);
    background: $background;
    border-radius: adjustVW(4);
    box-shadow: $box-shadow-hover;
    z-index: -1;
    transform: rotate(45deg);
  }
  &-left {
    right: calc(100% + #{$space-sub});
    left: inherit;
    &::after {
      right: adjustVW(-18);
      left: inherit;
    }
  }
  &-error {
    box-shadow: $border-radius-caution, $box-shadow-hover;
    &::after {
      background: #fdf4f4;
      box-shadow: $border-radius-caution, $box-shadow-hover;
    }
  }
  &-bottom {
    top: calc(
      100% + #{$space-small + adjustVW(18)}
    ); // 吹き出しを出す部分とのマージンと矢印部分の半分から角丸を引いた数
    left: adjustVW(
      -(18 + 32 - 8)
    ); // afterで作っている矢印部分の半分から、角丸を引いた数＋矢印と吹き出しの隙間-吹き出しの角丸の半分
    &::after {
      top: adjustVW(-18);
      left: adjustVW(32);
    }
  }
  &-top {
    top: auto;
    bottom: calc(
      100% + #{$space-small + adjustVW(40)}
    ); // 吹き出しを出す部分とのマージンと矢印部分の半分から角丸を引いた数
    left: adjustVW(
      -(18 + 32 - 8)
    ); // afterで作っている矢印部分の半分から、角丸を引いた数＋矢印と吹き出しの隙間-吹き出しの角丸の半分
    &::after {
      top: auto;
      bottom: adjustVW(-18);
      left: adjustVW(32);
    }
  }
}
.balloon-toggle-enter-active,
.balloon-toggle-leave-active {
  transition: transform $transition-base, opacity $transition-base;
}
.balloon-toggle-enter,
.balloon-toggle-leave-to {
  opacity: 0;
  transform: translateX(-$space-small);
  will-change: opacity, transform;
}
</style>
