<template>
  <div class="select-template-sample-dataset">
    <texts
      :text="this.$i18n.t('projectTemplate.create.select.sampleDataset')"
    />
    <select-box
      :firstSelectItem="firstSelectItem"
      :items="sampleDatasetList"
      @select-item="selectDataset"
    />
  </div>
</template>

<script>
import Texts from '@/components/atoms/text.vue'
import SelectBox from '@/components/molecules/select-box.vue'

export default {
  components: {
    SelectBox,
    Texts
  },
  props: {
    sampleDatasetList: Array,
    selectedDatasetId: String
  },
  data() {
    const selectedDataset =
      this.selectedDatasetId !== '' ? this.selectedDatasetId : ''

    const firstSelectItem =
      this.selectedDatasetId !== ''
        ? this.sampleDatasetList.find(
            (dataset) => dataset.id === this.selectedDatasetId
          )
        : {}

    return {
      datasetId: selectedDataset,
      firstSelectItem
    }
  },
  computed: {
    formData: function () {
      return {
        datasetId: this.datasetId
      }
    }
  },
  watch: {
    formData: {
      deep: true,
      handler: function (form) {
        this.$emit('input-edit-form', {
          type: 'projectTemplateSampleDataset',
          form
        })
      }
    }
  },
  methods: {
    selectDataset: function (item) {
      this.datasetId = item.id
    }
  },
  mounted: function () {
    this.$emit('input-edit-form', {
      type: 'projectTemplateSampleDataset',
      form: this.formData
    })
  }
}
</script>

<style lang="scss" scoped>
.select-template-sample-dataset {
  width: 100%;
  border: $line;
  background-color: $background;
  border-radius: adjustVW(8);
}

.submit-button {
  display: flex;
  justify-content: center;
  margin: 1rem;
}
</style>
