<template>
  <div
    class="text-mining-wrapper"
    :class="{
      'text-mining-wrapper-no-frequencies': !showFrequencies,
      'text-mining-wrapper-only-wordcloud':
        !showFrequencies && (columns == null || columns.length < 2)
    }"
  >
    <div
      v-if="showFrequencies || (columns != null && columns.length > 1)"
      class="text-mining-config"
    >
      <frequencies-wordCloud
        v-model.lazy="values"
        :maxCount="maxCount"
        :columns="columns"
        :showFrequencies="showFrequencies"
        :columnName="columnName"
        :waitTextMiningLoading="waitTextMiningLoading"
        @change-column-name="$emit('change-column-name', $event)"
      />
    </div>
    <card-base v-if="showFrequencies" class="frequencies">
      <transition-toggle-contents>
        <list-bar-graph
          v-if="!waitTextMiningLoading"
          key="frequencies"
          sortContent
          :scaleSize="values.count"
          :list="frequencies.frequencies"
          :tipsLabel="$t('textMining.frequencies.tips.title')"
          :title="$t('textMining.frequencies.title')"
        />
        <loading v-else key="loading-frequencies" />
      </transition-toggle-contents>
    </card-base>
    <card-base v-if="wordcloud" class="wordcloud">
      <div>
        <texts
          :text="$t('trainedAi.textMining.types.wordcloud')"
          size="small"
          color="gray"
        />
      </div>
      <transition-toggle-contents>
        <div
          v-if="!waitTextMiningLoading"
          key="wordcloud"
          class="wordcloud-container"
        >
          <img
            class="wordcloud-img"
            :src="'data:image/png;base64,' + wordcloud"
          >
        </div>
        <loading v-else key="loading-frequencies" />
      </transition-toggle-contents>
    </card-base>
  </div>
</template>

<script>
import loading from '@/components/atoms/loading'
import cardBase from '@/components/atoms/card-base'
import listBarGraph from '@/components/molecules/list-bar-graph'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import frequenciesWordCloud from './config-box/frequencies-wordcloud'

export default {
  components: {
    loading,
    cardBase,
    listBarGraph,
    transitionToggleContents,
    frequenciesWordCloud
  },
  props: {
    frequencies: {
      type: Object,
      default: () => {}
    },
    wordcloud: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    columns: {
      type: Array,
      default: () => []
    },
    waitTextMiningLoading: {
      type: Boolean,
      default: false
    },
    columnName: {
      type: String,
      default: ''
    }
  },
  computed: {
    showFrequencies() {
      if (this.frequencies == null || this.frequencies?.frequencies == null)
        return false
      return Object.keys(this.frequencies.frequencies).length > 0
    },
    maxCount() {
      if (!this.showFrequencies) return 0
      return Object.keys(this.frequencies.frequencies).length
    },
    values: {
      get() {
        return JSON.parse(JSON.stringify(this.value))
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.text-mining {
  &-wrapper {
    display: grid;
    grid-template-areas:
      'config config'
      'frequencies wordcloud';
    grid-template-columns: adjustVW(516) 1fr;
    grid-template-rows: auto minmax(0, 1fr);
    grid-column-gap: $space-medium;
    grid-row-gap: $space-medium;
    height: 100%;
    &-no-frequencies {
      grid-template-areas:
        'config config'
        'wordcloud wordcloud';
      width: calc(100% - #{adjustVW(516)});
    }
    &-only-wordcloud {
      display: block;
      width: calc(100% - #{adjustVW(516)});
    }
  }
  &-config {
    width: fit-content;
  }
}
.frequencies {
  grid-area: frequencies;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.wordcloud {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  grid-row-gap: $space-small;
  height: 100%;
  grid-area: wordcloud;
  &-container {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  &-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
