<template>
  <card-base class="result">
    <div class="result-title">
      <text-menu-list
        :menus="iconMenus"
        isMenuShow
        @menu-click="$emit('download-result', encoding)"
      />
      <select-box
        isGray
        :items="encodingOptions"
        :firstSelectItem="defaultEncoding"
        class="download-select-encoding"
        @input="setEncoding"
      />
    </div>
    <div class="result-body">
      <table-main
        class="result-detail"
        :tableColumns="tableColumns"
        :table="head"
        :pagingCount="8"
      />
    </div>
  </card-base>
</template>

<script>
import tableMain from '@/components/molecules/table-main'
import textMenuList from '@/components/molecules/text-menu-list'
import CardBase from '@/components/atoms/card-base.vue'
import SelectBox from '@/components/molecules/select-box.vue'

function isWindows() {
  return window.navigator.userAgent.toLowerCase().indexOf('windows') >= 0
}

export default {
  components: {
    tableMain,
    textMenuList,
    CardBase,
    SelectBox
  },
  data() {
    return {
      showDetail: false,
      encoding: isWindows() ? 'cp932' : 'utf8'
    }
  },
  props: {
    accountInfo: Object,
    head: Array,
    isTraining: Boolean
  },
  computed: {
    tableColumns() {
      return Object.keys(this.head[0])
    },
    iconMenus() {
      return [
        {
          id: 0,
          name: this.$t('inference.result.downloadResult'),
          iconName: 'download',
          value: 'download',
          isMultiple: false,
          tips: {
            name: this.$t('inference.result.downloadResult')
          }
        }
      ]
    },
    encodingOptions() {
      return [
        {
          id: 0,
          name: 'Windows (Shift-JIS)',
          value: 'cp932'
        },
        {
          id: 1,
          name: 'Mac (UTF-8)',
          value: 'utf8'
        }
      ]
    }
  },
  methods: {
    setEncoding(e) {
      console.log(e)
      this.encoding = e
    },
    clickList(e) {
      this.showDetail = true
      this.$emit('show-detail-optimization', e.id)
    },
    closeDetail() {
      this.showDetail = false
    }
  }
}
</script>
<style lang="scss" scoped>
.result {
  overflow: hidden;
  height: 100%;
  padding: $space-medium $space-medium;
  margin: 0 $space-medium $space-medium;

  .download-select-encoding {
    width: adjustVW(220);
    margin: 0 $space-medium 0 0;
  }

  &-title {
    display: flex;
    flex-direction: row-reverse;
    flex-shrink: 0;
    margin-bottom: $space-base;
  }
  &-body {
    height: 100%;
    padding-bottom: $space-medium;
    margin: 0 $space-medium $space-min;
  }
  &-detail {
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-medium;
    @extend .result-body;
  }
  &-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    &-detail {
      height: adjustVW(200);
    }
    &-table {
      height: adjustVW(466);
    }
  }
  &-list {
    overflow-y: scroll;
    height: 100%;
    padding-right: $space-base;
    @include scrollbar;
  }
}
</style>
