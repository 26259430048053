<template>
  <div class="dataset-wrap">
    <list-item-slot
      class="dataset-list"
      type="button"
      :field="this.$props.field"
      :items="this.$props.items"
      :iconButton="true"
      :iconName="'popup'"
      @icon-click="showDatasetDetail"
      @list-click="listClick"
    >
      <template v-slot:summary="entry">
        <div class="dataset-summary">
          <texts
            class="dataset-summary-name"
            :text="entry.item.name"
            isBold
            :class="{
              'dataset-summary-name-non-desc': !entry.item.description
            }"
          />
          <description
            class="dataset-summary-about"
            :text="entry.item.description"
            :line="2"
          />
        </div>
      </template>

      <template v-slot:nData="entry">
        <texts :text="entry.item" />
      </template>

      <template v-slot:columns="entry">
        <div class="dataset-summary-columns">
          <bullet-list :items="cropColumns(entry.item)" />
        </div>
      </template>
    </list-item-slot>

    <dataset-detail
      :showPopup="showPopup"
      :title="datasetDetail.summary.name"
      :about="datasetDetail.summary.description"
      :requiredColumns="requiredColumns"
      :includeColumns="datasetDetail.columns"
      @close-modal="closeDatasetDetail"
    />
  </div>
</template>

<script>
import bulletList from '@/components/molecules/bullet-list.vue'
import datasetDetail from '@/components/organisms/inference/menu/dataset/dataset-detail.vue'
import listItemSlot from '@/components/molecules/list-item-slot.vue'
import texts from '@/components/atoms/text.vue'
import description from '@/components/atoms/description.vue'

export default {
  components: {
    bulletList,
    datasetDetail,
    listItemSlot,
    texts,
    description
  },
  data() {
    return {
      showPopup: false,
      datasetDetail: {
        summary: {
          name: '',
          about: ''
        },
        features: []
      },
      lackedColumns: []
    }
  },
  props: {
    field: Array,
    items: Array,
    requiredColumns: Array
  },
  methods: {
    listClick: function ({ id: index }) {
      this.$emit('list-click', { index })
    },
    showDatasetDetail: function ({ id: index }) {
      this.datasetDetail = this.items.find((item) => item.id === index)
      this.showPopup = true
    },
    closeDatasetDetail: function () {
      this.showPopup = false
    },
    cropColumns(columns) {
      const target = JSON.parse(JSON.stringify(columns))
      const requiredColumns = []
      const unusedColumns = []
      const setRequiredColumns = new Set(this.requiredColumns)

      target.forEach((x) => {
        const required = setRequiredColumns.has(x.text)
        setRequiredColumns.delete(x.text)
        if (required) {
          requiredColumns.push({
            text: x.text,
            isDisabled: !required
          })
        } else {
          unusedColumns.push({
            text: x.text,
            isDisabled: !required
          })
        }
      })
      const result = [].concat(requiredColumns, unusedColumns)
      if (result.length < 3) return result
      return result.splice(0, 3)
    }
  }
}
</script>

<style lang="scss" scoped>
.dataset {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-list {
    overflow-y: scroll;
    height: 100%;
    padding: 0 $space-base 0 0;
    @include scrollbar;
  }

  &-summary {
    display: flex;
    flex-direction: column;

    &-name {
      margin-bottom: $space-base;
      &-non-desc {
        margin: 0;
      }
    }

    &-about {
      white-space: normal;
    }

    &-columns {
      overflow: hidden;
    }
  }
}
</style>
