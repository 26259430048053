<template>
  <div style="height: 100%">
    <table-main
      :tableColumns="displayColumns"
      :table="displayTable"
      :graph="info.graph"
      :pagingCount="7"
      :isStatics="visibleContents === 'statistics'"
    />
  </div>
</template>

<script>
import tableMain from '@/components/molecules/table-main'

export default {
  components: {
    tableMain
  },
  props: {
    table: Array,
    info: Object,
    visibleContents: String
  },
  computed: {
    fields() {
      return Object.keys(this.info.dtypes)
    },
    dataStatisticsFields() {
      return ['/'].concat(Object.keys(this.info.dtypes))
    },
    displayColumns() {
      if (this.visibleContents === 'dataset') {
        return this.fields
      } else {
        return this.dataStatisticsFields
      }
    },
    displayTable() {
      if (this.visibleContents === 'dataset') {
        return this.table
      } else {
        return this.info.describe
      }
    }
  }
}
</script>
