<!--
  データセットに使用している画像を表示する
  背景にグレーが表示されて、その枠の中に収まるようになっている
-->
<template>
  <div class="c-image-wrap">
    <transition name="image-fade" mode="out-in">
      <img
        v-if="!waitChangeImage"
        class="c-image-item"
        :src="'data:image/png;base64,' + url"
      >
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    /** Base64に変換された画像 */
    url: String
  },
  data() {
    return {
      waitChangeImage: false
    }
  },
  watch: {
    url() {
      this.waitChangeImage = true
      this.$nextTick(() => {
        this.waitChangeImage = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.c-image {
  &-wrap {
    width: 100%;
    height: 100%;
    background: $medium-gray;
  }
  &-item {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
}
.image-fade-enter-active,
.image-fade-leave-active {
  transition: opacity $transition-base, transform $transition-base;
}
.image-fade-enter,
.image-fade-leave-to {
  opacity: 0;
  transform: translateX($space-small);
  will-change: opacity;
}
</style>
