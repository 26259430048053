<template>
  <div class="select">
    <div class="select-main">
      <div v-if="isExpression" class="select-main-title">
        <texts
          :text="$t('trainedAi.optimization.expression.addConditions.title')"
          size="small"
          color="gray"
        />
        <icons
          v-tooltip="$t('trainedAi.optimization.expression.addConditions.tips')"
          iconName="info"
          size="small"
          color="gray"
        />
      </div>
      <span :data-tutorial-optimization="showTutorial ? 4 : null">
        <select-box
          ref="selectBox"
          v-model="internalType"
          v-tooltip="disabledTips"
          :data-tutorial-optimization="showTutorial ? 1 : null"
          class="select-type input-item"
          :items="selectBoxItems"
          :placeholder="
            disabledReason && disabledReason.length > 0
              ? $t('trainedAi.optimization.conditions.cantSet')
              : $t('trainedAi.optimization.conditions.none')
          "
          isGray
          showIcon
          showInfo
          isSeparateBox
          scrollBar
          showTop
          :margin="16"
          :scrollSize="200"
          :isDisabled="disabledReason && disabledReason.length > 0"
          :isRequired="isExpression && internalType === null"
          @select-box-open="$emit('select-box-open')"
          @select-box-close="$emit('select-box-close')"
        />
      </span>
    </div>
    <transition-toggle-contents>
      <div
        v-if="internalType === 'closer'"
        key="closer"
        class="select-params-closer"
        :class="{
          'select-params-closer-inference': isInference && isExpression
        }"
      >
        <texts
          class="select-params-title"
          :text="$t('trainedAi.optimization.conditions.closerSelect')"
          color="gray"
          size="small"
        />
        <input-box
          v-if="internalParams"
          v-model.number="internalParams.target"
          v-tooltip="showRequired(internalParams.target)"
          class="select-params-closer-params input-item"
          isGray
          number
          :required="internalParams.target === null"
          :placeholder="$t('trainedAi.optimization.conditions.closerSelect')"
        />
      </div>
    </transition-toggle-contents>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import inputBox from '@/components/molecules/input-box'
import selectBox from '@/components/molecules/select-box'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

export default {
  components: {
    icons,
    inputBox,
    selectBox,
    transitionToggleContents
  },
  data() {
    return {
      // 条件変更した場合のリセット
      defaultTypes: {
        closer: {
          type: 'closer',
          params: {
            target: null
          }
        },
        maximize: {
          type: 'maximize',
          params: null
        },
        minimize: {
          type: 'minimize',
          params: null
        },
        notSet: {
          type: null,
          params: null
        }
      },
      isMinLargerMax: null,
      isMaxSmallerMin: null,
      internalType: null,
      internalParams: null
    }
  },
  props: {
    /** 条件を渡す { type: '条件のタイプ', params: closerの場合 { target: null } */
    value: Object,
    /** placeholderの変更に使用 狭い領域で使うとき */
    isSpaceMin: Boolean,
    /** 推論画面での設定かどうか、配置が変更される */
    isInference: Boolean,
    isExpression: Boolean,
    disabledReason: Array,
    showTutorial: Boolean
  },
  computed: {
    selectBoxItems() {
      return [
        {
          id: 0,
          name: this.$t('trainedAi.optimization.conditions.closer'),
          value: 'closer',
          iconName: 'closer',
          iconColor: 'green',
          info: this.$t('trainedAi.optimization.conditions.closerInfo')
        },
        {
          id: 1,
          name: this.$t('trainedAi.optimization.conditions.maximize'),
          value: 'maximize',
          iconName: 'maximize',
          iconColor: 'green',
          info: this.$t('trainedAi.optimization.conditions.maximizeInfo')
        },
        {
          id: 2,
          name: this.$t('trainedAi.optimization.conditions.minimize'),
          value: 'minimize',
          iconName: 'minimize',
          iconColor: 'green',
          info: this.$t('trainedAi.optimization.conditions.minimizeInfo')
        },
        {
          id: 3,
          name: this.$t('trainedAi.optimization.conditions.none'),
          value: null,
          iconName: 'close',
          iconColor: 'green',
          info: this.$t('trainedAi.optimization.conditions.noneInfo')
        }
      ]
    },
    disabledTips() {
      if (!this.disabledReason || this.disabledReason.length === 0) return ''
      let tips = ''
      this.disabledReason.forEach((reason) => {
        const reasonText = this.$t('training.reasons.optimization.' + reason)
        if (tips.length > 0) {
          tips = tips + '\n' + reasonText
        } else {
          tips = reasonText
        }
      })
      return tips
    }
  },
  methods: {
    checkPos() {
      this.$refs.selectBox.checkPos()
    },
    showRequired(num) {
      if (num === null) {
        return this.$t('trainedAi.optimization.conditions.noParams')
      }
    }
  },
  watch: {
    value: {
      deep: true,
      handler(newVal) {
        const target = JSON.parse(JSON.stringify(newVal))
        this.internalType = target.type
        this.internalParams = target.params
      }
    },
    internalType(newVal) {
      if (this.value.type === newVal) return
      const parser = function (target) {
        return JSON.parse(JSON.stringify(target))
      }

      const params = newVal
        ? parser(this.defaultTypes[newVal])
        : parser(this.defaultTypes.notSet)
      this.internalParams = params.params

      const payload = {
        type: newVal,
        params: this.internalParams
      }
      this.$emit('input', payload)
      this.$emit('select-type')
    },
    internalParams: {
      deep: true,
      handler(newVal) {
        if (
          this.internalType === 'closer' &&
          newVal.target === this.value.params?.target
        )
          return
        if (newVal === null) return

        const payload = {
          type: this.internalType,
          params: newVal
        }
        this.$emit('input', payload)
      }
    }
  },
  mounted() {
    this.internalType = this.value.type
    this.internalParams = JSON.parse(JSON.stringify(this.value.params))
  }
}
</script>

<style lang="scss" scoped>
.select {
  display: flex;
  align-items: flex-end;
  grid-column-gap: $space-large;
  &-main {
    flex-shrink: 0;
    width: adjustVW(248);
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $space-base;
    }
  }
  &-params {
    &-title {
      flex-shrink: 0;
      margin-right: $space-small;
    }
    &-range {
      display: grid;
      align-items: center;
      grid-template-columns: max-content 1fr;
      &-separate {
        margin: 0 $space-base;
      }
      &-params {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr max-content 1fr;
      }
      &-inference {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: $space-base;
      }
    }
    &-closer {
      display: flex;
      align-items: center;
      &-inference {
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: $space-base;
      }
    }
  }
}

.input-item {
  height: adjustVW(56);
}
</style>
