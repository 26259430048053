<template>
  <div class="radio-button-list-wrap">
    <template v-if="!noSearchResults">
      <transition-toggle-contents>
        <div v-if="!toggleMulti" class="radio-button-list-body">
          <div v-if="multiSelectMode" class="radio-button-list-all-wrap">
            <checkbox-base
              class="radio-button-list-all"
              isLarge
              :text="$t('common.selectAll')"
              :checked="checked.length > 0"
              :isShowMinusIcon="isNotAllChecked"
              :isDisabled="
                checkDispCautionList && checkDispCautionList.length > 0
              "
              @input="allCheck($event)"
            />
            <div class="caution-popup-wrapper">
              <popup-balloon
                :isShow="
                  checkDispCautionList && checkDispCautionList.length > 1
                "
                showBottom
                :showCloseButton="false"
                class="caution-popup-inner"
              >
                <texts
                  class="caution-popup-title"
                  :text="
                    $t(
                      'selectTargetColumn.datasetInfo.list.caution.multiColumn'
                    )
                  "
                />
                <div class="caution-popup-main-item">
                  <div class="caution-popup-main-item-column">
                    <texts
                      v-for="(item, key) in checkDispCautionList"
                      :key="key + '_objectValue'"
                      class="caution-popup-main-item-column-item"
                      :text="item"
                      color="caution"
                    />
                  </div>
                </div>
                <button-main
                  class="caution-popup-checked-button"
                  type="emphasis"
                  :text="
                    $t('selectTargetColumn.datasetInfo.list.caution.button')
                  "
                  @click="confirmCautionAll"
                />
              </popup-balloon>
            </div>
          </div>
          <div class="radio-button-list-inner">
            <template v-for="(value, index) in values">
              <template v-if="index % 3 === 0">
                <div :key="index" class="radio-button-list">
                  <template v-for="(n, i) in 3">
                    <template v-if="!multiSelectMode">
                      <div
                        v-if="values[index + i]"
                        :key="index + n"
                        class="radio-button-list-item"
                      >
                        <radio-button-base
                          :text="values[index + i]"
                          name="radioButton"
                          :checked="checkedItem[0] === values[index + i]"
                          :caution="
                            cautionItems.indexOf(values[index + i]) !== -1
                          "
                          @input="updateValues(values[index + i])"
                        />
                        <div class="caution-popup-wrapper">
                          <popup-balloon
                            :isShow="showCautionMessage(index + i)"
                            :showTop="checkTop(index)"
                            :showBottom="!checkTop(index)"
                            :showCloseButton="false"
                            class="caution-popup-inner"
                          >
                            <texts
                              class="caution-popup-title"
                              :text="
                                $t(
                                  'selectTargetColumn.datasetInfo.list.caution.title'
                                )
                              "
                            />
                            <texts
                              class="caution-popup-title-sub"
                              color="gray"
                              size="small"
                              :text="
                                $t(
                                  'selectTargetColumn.datasetInfo.list.caution.titleSub'
                                )
                              "
                            />

                            <div class="caution-popup-reason-message-wrapper">
                              <template v-for="item in cautionMessage">
                                <div
                                  v-if="
                                    checkReason(values[index + i], item.value)
                                  "
                                  :key="item.index"
                                  class="caution-popup-reason-message-item"
                                >
                                  <div
                                    class="caution-popup-reason-message-marker"
                                  />
                                  <texts
                                    class="caution-popup-reason-message-text"
                                    size="small"
                                    :text="item.text"
                                  />
                                </div>
                              </template>
                            </div>

                            <button-main
                              class="caution-popup-checked-button"
                              type="emphasis"
                              :text="
                                $t(
                                  'selectTargetColumn.datasetInfo.list.caution.button'
                                )
                              "
                              @click="confirmCaution(values[index + i])"
                            />
                          </popup-balloon>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div
                        v-if="values[index + i]"
                        :key="index + n"
                        class="radio-button-list-checkbox"
                      >
                        <checkbox-base
                          isLarge
                          :text="values[index + i]"
                          :checked="checkedItem.indexOf(values[index + i]) >= 0"
                          :caution="
                            cautionItems.indexOf(values[index + i]) !== -1
                          "
                          :isDisabled="checkCautionNotSelf(values[index + i])"
                          @input="updateValues(values[index + i], $event)"
                        />
                        <div
                          v-if="
                            checkDispCautionList &&
                              !(checkDispCautionList.length > 1)
                          "
                          class="caution-popup-wrapper"
                        >
                          <popup-balloon
                            :isShow="showCautionMessage(index + i)"
                            :showTop="checkTop(index)"
                            :showBottom="!checkTop(index)"
                            :showCloseButton="false"
                            class="caution-popup-inner"
                          >
                            <texts
                              class="caution-popup-title"
                              :text="
                                $t(
                                  'selectTargetColumn.datasetInfo.list.caution.title'
                                )
                              "
                            />
                            <texts
                              class="caution-popup-title-sub"
                              color="gray"
                              size="small"
                              :text="
                                $t(
                                  'selectTargetColumn.datasetInfo.list.caution.titleSub'
                                )
                              "
                            />

                            <div class="caution-popup-reason-message-wrapper">
                              <template v-for="item in cautionMessage">
                                <div
                                  v-if="
                                    checkReason(values[index + i], item.value)
                                  "
                                  :key="item.index"
                                  class="caution-popup-reason-message-item"
                                >
                                  <div
                                    class="caution-popup-reason-message-marker"
                                  />
                                  <texts
                                    class="caution-popup-reason-message-text"
                                    size="small"
                                    :text="item.text"
                                  />
                                </div>
                              </template>
                            </div>

                            <button-main
                              class="caution-popup-checked-button"
                              type="emphasis"
                              :text="
                                $t(
                                  'selectTargetColumn.datasetInfo.list.caution.button'
                                )
                              "
                              @click="confirmCaution(values[index + i])"
                            />
                          </popup-balloon>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </template>
            </template>
          </div>
        </div>
      </transition-toggle-contents>
    </template>
    <div v-else class="radio-button-list-no-search-results">
      <images
        imageName="notFound"
        class="radio-button-list-no-search-results-image"
      />
      <texts :text="$t('selectTargetColumn.datasetInfo.noSearchResults')" />
    </div>
  </div>
</template>

<script>
import ButtonMain from '@/components/atoms/button-main.vue'
import PopupBalloon from '@/components/atoms/popup-balloon.vue'
import RadioButtonBase from '@/components/atoms/radio-button-base'
import checkboxBase from '@/components/atoms/checkbox-base'
import Texts from '@/components/atoms/text.vue'
import images from '@/components/atoms/images.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    ButtonMain,
    PopupBalloon,
    RadioButtonBase,
    checkboxBase,
    Texts,
    images,
    transitionToggleContents
  },
  data() {
    return {
      checkedItem: [],
      cautionItems: [],
      toggleMulti: false
    }
  },
  props: {
    /** ラジオボタン/チェックボックスに表示するテキストと値の配列 */
    values: Array,
    /** チェックされたラジオボタン/チェックボックス */
    checked: Array,
    /** 注意を表示するラジオボタンの値とその配列 */
    cautions: Array,
    cautionMessage: Array,
    checkNotes: Object,
    noSearchResults: Boolean,
    multiSelectMode: Boolean
  },
  watch: {
    checked: function (val) {
      this.checkedItem = val
    },
    // transitionを動作させるために、DOMの更新を待ってからトグルしている
    multiSelectMode() {
      this.toggleMulti = true
      this.cautionItems = this.cautions.map((item) => item.name)
      this.$nextTick(() => {
        this.toggleMulti = false
      })
    }
  },
  methods: {
    checkReason: function (itemName, reason) {
      const item = this.cautions.find((item) => item.name === itemName)

      if (item) return item[reason]
      else return false
    },
    confirmCaution: function (columnName) {
      this.$emit('check-notes', { key: columnName, showNote: true })
    },
    confirmCautionAll: function () {
      this.checkDispCautionList.forEach((item) => {
        this.$emit('check-notes', { key: item, showNote: true })
      })
    },
    showCautionMessage: function (index) {
      const item = this.values[index]
      const caution = this.cautionItems.indexOf(this.values[index]) !== -1
      const isSelected = this.checkedItem.includes(item)
      const checkNotes = this.checkNotes[this.values[index]]

      return caution && isSelected && !checkNotes
    },
    updateValues(value, selected = true) {
      const caution = this.cautionItems.find((x) => x === value)
      if (caution && selected) {
        this.$emit('check-notes', { key: value, showNote: false })
      } else {
        this.$emit('check-notes', { key: value, showNote: true })
      }
      this.$emit('select-item', { value, selected })
    },
    checkTop(index) {
      return this.values.length - index < 9 && index / 3 > 3
    },
    allCheck(e) {
      if (e) {
        /**
         * 1つも選択されてないこと前提の全件選択処理
         * すべて選択するボタンは１つでも選択されている場合に全てのチェックを外すボタンになるので、
         * １つも選択されていないときにしか全て選択は実行されない
         */
        this.values.forEach((item) => {
          const caution = this.cautionItems.find((x) => x === item)
          if (caution) {
            this.$emit('check-notes', { key: item, showNote: false })
          } else {
            this.$emit('check-notes', { key: item, showNote: true })
          }
          this.$emit('select-item', { value: item, selected: true })
        })
      } else {
        // 全件解除処理
        const checkedItem = this.checkedItem.slice()
        checkedItem.forEach((item) => {
          this.$emit('check-notes', { key: item, showNote: true })
          this.$emit('select-item', { value: item, selected: false })
        })
        // this.checkedItem.splice(0)
      }
    },
    // ポップアップ表示中はポップアップを出しているチェックボックス以外のチェックボックスを押せなくするための判定
    checkCautionNotSelf(columnName) {
      if (!this.checkNotes[columnName]) return false
      return this.checkDispCautionList && this.checkDispCautionList.length > 0
    }
  },
  computed: {
    isNotAllChecked() {
      return this.values.length !== this.checked.length
    },
    checkDispCautionList() {
      if (!this.cautions || !this.checkedItem) return []
      return this.checkedItem.filter((item) => {
        const checkItem = this.cautions.some((check) => {
          return check.name === item
        })
        return checkItem && !this.checkNotes[item]
      })
    }
  },
  mounted() {
    if (this.checkedItem !== this.checked) this.checkedItem = this.checked
    this.cautionItems = this.cautions.map((item) => item.name)
  }
}
</script>

<style lang="scss" scoped>
.radio-button-list-wrap {
  overflow: hidden;
  height: 100%;
  padding: 0 $space-base 0 0;
}

.radio-button-list {
  display: flex;
  flex-wrap: wrap;
  border-bottom: $border-main;
  &:last-of-type {
    border: none;
  }
  &-no-search-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    &-image {
      width: adjustVW(320);
      margin-bottom: $space-medium;
    }
  }
  &-body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-inner {
    overflow-y: scroll;
    flex-shrink: 1;
    height: 100%;
    @include scrollbar;
  }
}

.radio-button-list-all {
  width: fit-content;
  margin: $space-small $space-sub $space-small;
}
.radio-button-list-item {
  position: relative;
  width: calc(100% / 3);
  padding: $space-small $space-small $space-small $space-base;
  &:nth-of-type(3n) {
    padding: $space-small $space-base 0 $space-base;
  }
}

.radio-button-list-checkbox {
  position: relative;
  width: calc(100% / 3);
  padding: $space-text $space-sub;
}

.caution-popup-wrapper {
  position: absolute;
}

.caution-popup {
  &-title {
    margin-bottom: $space-base;
  }

  &-title-sub {
    margin-bottom: $space-small;
  }

  &-inner {
    left: 0;
  }
  &-main-item {
    margin-top: $space-sub;
    margin-bottom: $space-small;
    &-column {
      overflow-y: scroll;
      display: flex;
      flex-wrap: wrap;
      grid-column-gap: $space-sub;
      grid-row-gap: $space-sub;
      max-width: adjustVW(374);
      max-height: adjustVH(148);
      padding-right: $space-base;
      @include scrollbar;
    }
  }
}

.caution-popup-reason-message-wrapper {
  padding-left: $space-base;
  margin-bottom: $space-small;
}

.caution-popup-reason-message {
  &-item {
    display: flex;
    align-items: center;
    margin-bottom: $space-sub;
    &:last-of-type {
      margin: 0;
    }
  }

  &-marker {
    flex-shrink: 1;
    width: adjustVW(12);
    height: adjustVW(12);
    margin: 0 $space-base 0 0;
    background: $text-decoration;
    border-radius: 9in;
  }
  &-text {
    width: calc(100% - #{adjustVW(12) + $space-base});
    white-space: pre-line;
  }
}

.caution-popup-checked-button {
  width: 100%;
}
</style>
