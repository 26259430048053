var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.disabledTips),expression:"disabledTips"}],staticClass:"button",class:( _obj = {
    'button-confirm': _vm.check,
    'button-disabled': _vm.isDisabled,
    'button-set': !_vm.loadingItem
  }, _obj['button-' + _vm.color] = _vm.color, _obj ),style:({
    '--width': _vm.setWidth + 'px'
  }),on:{"click":function($event){!_vm.isDisabled ? _vm.updateValue() : ''}}},[_c('icons',{ref:"iconBlock",staticClass:"icon",attrs:{"iconName":_vm.iconName,"size":_vm.size,"showTips":_vm.showTips,"showTipsFlag":_vm.showTipsFlag,"isDisabled":_vm.isDisabled,"color":_vm.isDisabled ? 'gray' : _vm.color}}),_c('texts',{ref:"textBlock",staticClass:"text",attrs:{"text":_vm.text,"size":_vm.size,"color":_vm.isDisabled ? 'gray' : _vm.color}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }