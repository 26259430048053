<!--
  現在のデータ使用容量を表示するグラフ
-->
<template>
  <div class="graph-used-data-wrap">
    <div class="graph-used-data-block">
      <svg
        class="graph-used-data-inner-bar"
        xmlns="http://www.w3.org/2000/svg"
        :width="usedPercentage + '%'"
      >
        <!-- 90%を超えたら色を変更しています。しきい値は随時変更 -->
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          :fill="usedPercentage >= 90 ? '#e25f58' : '#850491'"
        />
      </svg>
    </div>
    <div class="graph-used-data-text-wrap">
      <div class="graph-used-data-text-block">
        <div
          class="graph-used-data-text-inner"
          :class="{
            'graph-used-data-text-inner-light': usedPercentage - 5 > 85
          }"
        >
          <div class="graph-used-data-text-main">
            {{ formatSize }}
          </div>
          <div class="graph-used-data-text-main-unit">
            {{ formatSizeByte }}
          </div>
        </div>
        <div class="graph-used-data-text-slash">
          /
        </div>
        <div class="graph-used-data-text-inner">
          <div class="graph-used-data-text-sub">
            {{ formatMaxSize }}
          </div>
          <div class="graph-used-data-text-sub-unit">
            {{ formatMaxSizeByte }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /** 現在の使用容量 */
    size: Number,
    /** 使用可能な容量 */
    maxSize: Number
  },
  computed: {
    usedPercentage() {
      return (this.size / this.maxSize) * 100
    },
    formatSize() {
      if (this.size < 1024) {
        return `${this.size}`
      } else if (this.size < 1024 ** 2) {
        return `${(this.size / 1024).toFixed(3)}`
      } else if (this.size < 1024 ** 3) {
        return `${(this.size / 1024 / 1024).toFixed(3)}`
      } else {
        // 表示する桁の調整あり
        return `${(this.size / 1024 / 1024 / 1024).toFixed(2)}`
      }
    },
    formatMaxSize() {
      if (this.maxSize < 1024) {
        return `${this.maxSize}`
      } else if (this.maxSize < 1024 ** 2) {
        return `${(this.maxSize / 1024).toFixed(0)}`
      } else if (this.maxSize < 1024 ** 3) {
        return `${(this.maxSize / 1024 / 1024).toFixed(0)}`
      } else {
        return `${(this.maxSize / 1024 / 1024 / 1024).toFixed(0)}`
      }
    },
    formatSizeByte() {
      if (this.size < 1024) {
        return 'B'
      } else if (this.size < 1024 ** 2) {
        return 'KB'
      } else if (this.size < 1024 ** 3) {
        return 'MB'
      } else {
        return 'GB'
      }
    },
    formatMaxSizeByte() {
      if (this.maxSize < 1024) {
        return 'B'
      } else if (this.maxSize < 1024 ** 2) {
        return 'KB'
      } else if (this.maxSize < 1024 ** 3) {
        return 'MB'
      } else {
        return 'GB'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.graph-used-data {
  &-wrap {
    display: flex;
    align-items: center;
  }
  &-block {
    position: relative;
    overflow: hidden;
    display: flex;
    width: 100%;
    height: adjustVH(40);
    margin: 0 $space-small 0 0;
    background: $background-sub;
    border-radius: adjustVW(4);
    box-shadow: inset 1px 1px 2px rgba(173, 173, 173, 0.2),
      inset -1px -1px 2px rgba(235, 235, 235, 0.2);
  }
  &-inner {
    display: inline-block;
    height: 100%;
    &-bar {
      display: inline-block;
      height: 100%;
      border-radius: adjustVW(4);
    }
  }
  &-text {
    &-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: adjustVH(40);
      padding: 0 $space-small;
      background: $background-sub;
      border-radius: adjustVW(4);
      z-index: 0;
    }
    &-block {
      display: flex;
    }
    &-inner {
      display: flex;
      align-items: flex-end;
    }
    &-main {
      margin: 0 $space-text 0 0;
      color: $text-main;
      font-size: 2.2rem;
      font-weight: bold;
      &-unit {
        color: $text-sub;
        font-size: $text-medium;
        font-weight: bold;
      }
    }
    &-slash {
      margin: 0 $space-base;
      color: $border-gray;
      font-size: $text-large;
    }
    &-sub {
      margin: 0 $space-text 0 0;
      font-size: $text-medium;
      font-weight: bold;
      &-unit {
        color: $text-sub;
        font-size: $text-base;
        font-weight: bold;
      }
    }
  }
}
</style>
