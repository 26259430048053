<template>
  <div class="add-new-service-wrap">
    <nav-page
      :page="page"
      :pageList="pageList"
      :descText="descText"
      :checkTextImageRecipeType="checkTextImageRecipeType"
      :checkTimeseriesRecipeType="checkTimeseriesRecipeType"
      :inTimeseriesPredictionColumn="inTimeseriesPredictionColumn"
      serviceType="learn"
      @page-back="pageBack"
    />
    <div class="add-new-service-inner">
      <transition-toggle-contents>
        <recipe-page
          v-if="page == 'recipe'"
          :recipes="recipes"
          :recipeLayers="recipeLayers"
          :loadRecipeDetail="loadRecipeDetail"
          :recipeDetail="recipeDetail"
          :customblockList="customblockList"
          @select-recipe="selectRecipe"
          @list-click="$emit('get-recipe-detail', $event)"
        />
        <dataset-page
          v-if="page == 'dataset'"
          :datasetList="datasetList"
          :datasetLoading="datasetLoading"
          :datasetDetail="datasetDetail"
          :loadDatasetDetail="loadDatasetDetail"
          @select-dataset="selectDataset"
          @list-click="$emit('get-dataset-detail', $event)"
        />
        <preprocessing-page
          v-if="page == 'preprocessing'"
          :preprocessingList="preprocessingList"
          @select-eda-recipe="selectEdaRecipe"
        />
        <timeseries-prediction-column-page
          v-if="page == 'timeseriesPredictionColumn'"
          :projectId="projectId"
          :datasetColumns="datasetColumns"
          :datasetDetail="datasetDetail"
          :loadDatasetDetail="loadDatasetDetail"
          @select-column="selectPredictionColumn"
        />
        <template>
          <column-page
            v-if="page == 'column' && !checkTimeseriesRecipeType"
            :projectId="projectId"
            :datasetColumns="datasetColumns"
            :datasetDetail="datasetDetail"
            :predictionColumns="predictionColumns"
            @select-column="selectColumnTimeseries"
          />
          <timeseries-column-page
            v-else-if="page == 'column' && checkTimeseriesRecipeType"
            :projectId="projectId"
            :datasetColumns="datasetColumns"
            :datasetDetail="datasetDetail"
            :selectedPredictionColumns="predictionColumns"
            @select-column="selectColumnTimeseries"
          />
        </template>
        <final-page
          v-if="page == 'final'"
          :disableClick="disableClick"
          :inputColumns="inputColumns"
          :predictionColumns="predictionColumns"
          :recipeDetail="recipeDetail"
          :serviceFormValidate="serviceFormValidate"
          :targetEdaRecipe="targetEdaRecipe"
          @input-create-form="$emit('input-create-form', $event)"
          @select-box-get-processings="
            $emit('select-box-get-processings', $event)
          "
          @cancel="$emit('cancel', $event)"
          @create-service="createService"
        />
      </transition-toggle-contents>
    </div>
  </div>
</template>

<script>
import timeseriesPredictionColumnPage from './learn-pages/timeseries-prediction-column.vue'
import timeseriesColumnPage from './learn-pages/timeseires-column.vue'
import columnPage from './learn-pages/column.vue'
import recipePage from './learn-pages/recipe.vue'
import datasetPage from './learn-pages/dataset.vue'
import preprocessingPage from './learn-pages/preprocessing.vue'
import finalPage from './learn-pages/final.vue'
import navPage from './learn-pages/nav.vue'

import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
export default {
  components: {
    timeseriesPredictionColumnPage,
    timeseriesColumnPage,
    columnPage,
    recipePage,
    datasetPage,
    preprocessingPage,
    finalPage,
    navPage,
    transitionToggleContents
  },
  data() {
    return {
      page: 'recipe',
      pageList: ['recipe', 'dataset', 'preprocessing', 'column', 'final'],

      inputColumns: [],
      predictionColumns: [],
      recipe: null,
      dataset: null,
      edaRecipe: null,
      info: {}
    }
  },
  props: {
    projectId: Number,
    preprocessingList: Array,
    recipes: Object,
    recipeLoading: Boolean,
    recipeLayers: Object,
    recipeDetail: Object,
    loadRecipeDetail: Boolean,
    datasetList: Object,
    datasetLoading: Boolean,
    datasetDetail: Object,
    loadDatasetDetail: Boolean,
    serviceFormValidate: Object,
    disableClick: Boolean,
    customblockList: Array
  },
  computed: {
    datasetColumns() {
      if (!this.dataset) return null
      return this.datasetList[this.dataset]?.columns
    },
    targetEdaRecipe() {
      if (!this.edaRecipe) return null
      return this.preprocessingList.find((item) => item.id === this.edaRecipe)
    },
    checkTextImageRecipeType() {
      if (!this.recipeDetail) return false
      const recipeInput = this.recipeDetail.body.layers.find(
        (item) => item.type === 'input'
      )
      return (
        recipeInput?.params?.dataType?.value === 'texts' ||
        recipeInput?.params?.dataType?.value === 'images'
      )
    },
    checkTimeseriesRecipeType() {
      if (!this.recipeDetail) return false
      return this.recipeDetail.body.layers.some(
        (item) => item.name === 'TrendFlow'
      )
    },
    descText() {
      switch (this.page) {
        case 'recipe':
          return this.$t('serviceList.popup.addNewService.progress.recipe.desc')
        case 'dataset':
          return this.$t(
            'serviceList.popup.addNewService.progress.dataset.desc'
          )
        case 'preprocessing':
          return this.$t(
            'serviceList.popup.addNewService.progress.preproc.desc'
          )
        case 'timeseriesPredictionColumn':
          return this.$t(
            'serviceList.popup.addNewService.progress.timeseriesPredictionColumn'
          )
        case 'column':
          return this.checkTimeseriesRecipeType
            ? this.$t(
                'serviceList.popup.addNewService.progress.timeseriesInputColumn'
              )
            : this.$t('serviceList.popup.addNewService.progress.column.desc')
        case 'final':
          return this.$t('serviceList.popup.addNewService.progress.result.desc')
        default:
          return this.$t('serviceList.popup.addNewService.progress.recipe.desc')
      }
    },
    inTimeseriesPredictionColumn() {
      return (
        this.checkTimeseriesRecipeType &&
        this.page === 'timeseriesPredictionColumn'
      )
    }
  },
  methods: {
    selectRecipe(e) {
      this.recipe = e
      if (!this.checkTextImageRecipeType) {
        this.page = 'dataset'
      } else {
        this.page = 'final'
      }
    },
    selectDataset(e) {
      this.dataset = e
      this.page = 'preprocessing'
    },
    selectEdaRecipe(e) {
      this.edaRecipe = e
      this.page = 'timeseriesPredictionColumn'
    },
    selectColumn(e) {
      this.inputColumns = e.inputColumns
      this.predictionColumns = e.predictionColumns
      this.page = 'final'
    },
    selectColumnTimeseries(e) {
      this.inputColumns = e.inputColumns
      this.page = 'final'
    },
    selectPredictionColumn(e) {
      this.predictionColumns = e.predictionColumns
      this.page = 'column'
    },
    createService(e) {
      const res = {
        recipeId: this.recipe,
        inputColumns: this.inputColumns,
        predictionColumns: this.predictionColumns,
        edaRecipeId: this.edaRecipe,
        ...e
      }
      this.$emit('create-service', res)
    },
    pageBack(e) {
      switch (e) {
        case 'recipe':
          this.recipe = null
          this.$emit('reset-create-service')
          break
        case 'dataset':
          this.dataset = null
          this.$emit('reset-create-service-dataset')
          break
        case 'preprocessing':
          this.edaRecipe = null
          break
        case 'timeseriesPredictionColumn':
          this.predictionColumns = []
          break
        case 'column':
          this.inputColumns = []
          break
        default:
          return
      }
      this.page = e
    }
  },
  destroyed() {
    this.$emit('reset-create-service')
  }
}
</script>

<style lang="scss" scoped>
.add-new-service {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    grid-row-gap: adjustVW(28);
    width: 100%;
    height: 100%;
  }
  &-inner {
    overflow: hidden;
    flex-shrink: 1;
    height: 100%;
  }
}
</style>
