<template>
  <div class="wrap">
    <text-with-title
      class="wrap-text-nickname"
      :title="$t('account.setting.info.popup.nickname.myNickname')"
      :text="accountInfo.name"
    />

    <input-nickname @change="newNickname($event)" />
    <!--
      ニックネーム作成成功のポップアップ
    -->
    <div v-if="changeNicknameError && !success" class="text">
      <text-box
        isError
        :text="$t('account.setting.info.popup.nickname.changeFailedNickname')"
      />
    </div>

    <div class="button-wrap">
      <buttons
        :type="accountId.isOk ? 'emphasis' : 'disabled'"
        :text="$t('account.setting.info.popup.nickname.createNewNickname')"
        @click="changeNickname"
      />
      <buttons
        type="sub"
        :text="$t('account.setting.info.popup.password.cancel')"
        @click="$emit('close-modal', closePopup)"
      />
    </div>
  </div>
</template>

<script>
import textBox from '@/components/atoms/text-box'
import buttons from '@/components/atoms/button-main'
import inputNickname from '@/components/molecules/signup/input-nickname.vue'
import textWithTitle from '@/components/molecules/text-with-title'
import { mapActions } from 'vuex'

export default {
  components: {
    textBox,
    buttons,
    inputNickname,
    textWithTitle
  },
  data() {
    return {
      closePopup: this.showPopup,

      accountId: {
        value: '',
        isOk: false
      },

      changeNicknameError: '',
      success: false
    }
  },
  props: {
    showPopup: Boolean,
    accountInfo: Object
  },
  methods: {
    ...mapActions('auth', ['fetchAccountInfo']),
    newNickname(id) {
      this.accountId.value = id.value
      this.accountId.isOk = id.isOk
    },
    async changeNickname() {
      this.changeNicknameError = ''
      this.success = false
      const request = {
        action: 'changeNickname',
        value: this.accountId.value
      }
      const res = await this.$sendMessageAndReceive(request)
      if (res.status === 'error') {
        this.changeNicknameError = res.message // notMatchCurrentPassword
      } else {
        this.accountId.value = ''
        this.success = true
        this.fetchAccountInfo()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  position: relative;
  width: adjustVW(640);
  &-text {
    margin: 0 0 $space-medium;
    &-nickname {
      margin: $space-base 0 $space-small;
    }
  }
  form {
    .text {
      margin-bottom: $space-small;
    }
  }
}

.button-wrap {
  margin-top: $space-medium;
}
.button-success {
  min-width: adjustVW(252);
}
.button-close-modal {
  width: adjustVW(200);
}
.button-space {
  margin-right: $space-small;
}
</style>
