<template>
  <div class="statistics-list">
    <div
      v-for="(item, key) in datasetItem"
      :key="key + columnName"
      class="statistics-item"
    >
      <texts :text="$t('statistic.' + key)" size="min" color="gray" />
      <texts
        :text="item != null ? item : 'ー'"
        size="min"
        :color="item != null ? 'green' : 'gray'"
      />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'

export default {
  components: {
    texts
  },
  props: {
    describe: {
      type: Array,
      required: true
    },
    columnName: {
      type: String,
      required: true
    }
  },
  computed: {
    datasetItem() {
      if (!this.describe) return []
      const columnName = this.columnName
      const res = {}
      this.describe.forEach((item) => {
        switch (item['/']) {
          case 'max':
            res.max = item[columnName] ?? null
            break
          case 'min':
            res.min = item[columnName] ?? null
            break
          case 'mean':
            res.mean = item[columnName] ?? null
            break
          case '50%':
            res.text50 = item[columnName] ?? null
            break
          default:
            break
        }
      })
      return res
    }
  }
}
</script>

<style lang="scss" scoped>
.statistics {
  overflow: hidden;
  display: flex;
  align-items: center;
  &-title {
    flex-shrink: 0;
    margin-right: $space-medium;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: $space-small;
    grid-row-gap: adjustVW(2);
  }
  &-item {
    display: flex;
    grid-column-gap: adjustVW(6);
  }
}
</style>
