import router from '../router'

// 学習を再開する関数 statusを渡す
export function restartTraining(status) {
  if (status.recipeId) {
    router.push({ name: 'training', params: { projectId: status.projectId } })
    // 前処理と予測する列がある場合のみレシピへ
    // 画像とテキストの場合は予測する列の選択・前処理がないので、飛ばしてレシピへ
  } else if (
    (status.passedPreprocessing && status.targetColumn) ||
    status.unstructuredDataset
  ) {
    router.push({
      name: 'trainRecipeList',
      params: { projectId: status.projectId }
    })
  } else if (status.targetColumn) {
    router.push({
      name: 'trainPreprocessing',
      params: { projectId: status.projectId, type: '0', id: status.datasetId }
    })
  } else if (status.datasetId) {
    router.push({
      name: 'selectTargetColumn',
      params: { projectId: status.projectId, datasetId: status.datasetId }
    })
  } else {
    router.push({
      name: 'datasetSetting',
      params: { projectId: status.projectId }
    })
  }
}

const trainingOrder = [
  {
    order: 0,
    stage: 'project',
    status: ['projectId']
  },
  {
    order: 1,
    stage: 'dataset',
    status: ['datasetId', 'preprocessingDatasetId']
  },
  {
    order: 2,
    stage: 'targetColumn',
    status: ['targetColumn']
  },
  {
    order: 2,
    stage: 'preprocessing',
    status: []
  },
  {
    order: 3,
    stage: 'recipe',
    status: ['recipeId']
  },
  {
    order: 4,
    stage: 'training',
    status: []
  }
]

function checkStageStatus(term, currentStage, status) {
  const { stage } = term

  const haveItem = (key, status) => {
    return status[key] !== null && status[key] !== ''
  }

  switch (stage) {
    case 'dataset': {
      if (currentStage === 'preprocessing') {
        return haveItem('datasetId', status)
      } else {
        const checker = term.status.map((key) => haveItem(key, status))

        return checker.some((boolean) => boolean)
      }
    }
    default: {
      const checker = term.status.map((key) => haveItem(key, status))
      return checker.every((boolean) => boolean)
    }
  }
}

export function checkTrainingOrder({ projectId, stage, progressList }) {
  const trainingStatus = progressList.filter(
    (status) => status.projectId === projectId
  )[0]
  if (!trainingStatus) return false

  const currentStage = trainingOrder.filter((item) => item.stage === stage)[0]

  let checkStageList = trainingOrder.filter(
    (item) => currentStage.order > item.order
  )

  if (trainingStatus.unstructuredDataset) {
    checkStageList = checkStageList.filter((item) => {
      return !(item.stage === 'targetColumn' || item.stage === 'preprocessing')
    })
  }

  if (trainingStatus.passedPreprocessing) {
    checkStageList = checkStageList.filter((item) => {
      return !(item.stage === 'preprocessing')
    })
  }

  const judgeStageList = checkStageList.map((item) =>
    checkStageStatus(item, currentStage.stage, trainingStatus)
  )

  return judgeStageList.every((boolean) => boolean)
}
