<template>
  <div class="optimization-none">
    <texts
      class="optimization-none-text"
      :text="$t('trainedAi.optimization.result.none')"
      color="gray"
    />
    <div v-tooltip="disabledTips">
      <button-main
        :text="$t('trainedAi.optimization.createNewOptimization')"
        type="emphasis"
        :disabled="checkNoDataset"
        @click="!checkNoDataset ? newOptimization() : ''"
      />
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'

export default {
  components: {
    buttonMain
  },
  props: {
    trainedAi: Object
  },
  computed: {
    checkNoDataset() {
      if (!this?.trainedAi?.summary) return true
      return (
        !this.trainedAi.summary.datasets ||
        this.trainedAi.summary.datasets.length === 0
      )
    },
    disabledTips() {
      if (this.checkNoDataset) {
        return this.$t('trainedAi.optimization.error.noDataOrRecipe')
      } else {
        return ''
      }
    }
  },
  methods: {
    newOptimization() {
      this.$router.push({
        name: 'inferenceProject',
        params: {
          projectId: this.$route.params.projectId
        },
        query: {
          trainedAiId: this.trainedAi?.summary?.id,
          isOptimization: true
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.optimization {
  &-none {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-row-gap: $space-small;
    height: 100%;
    &-text {
      text-align: center;
      white-space: pre-line;
    }
  }
}
</style>
