<template>
  <div class="list-item-wrap">
    <div class="list-item-key-block">
      <div class="radio-dummy" />
      <div v-for="key in field" :key="key.index" class="list-item-key-inner">
        <texts :text="key.label" isBold size="small" />
      </div>
    </div>
    <div class="list-item-entry-list">
      <label
        v-for="entry in items"
        :key="entry.id"
        class="list-item-entry-item"
      >
        <div class="list-item-entry-block">
          <radio-button-base
            :checked="
              !checkedItem && notCheck ? false : checkBool(entry['value'])
            "
            name="test"
            :text="entry['value']"
            noText
            @input="updateValues($event)"
          />
          <div
            v-for="key in field"
            :key="key.index"
            class="list-item-entry-inner"
          >
            <div v-if="key.key === 'name'" class="list-item-entry-text">
              <texts :text="entry['name'].name" size="large" />
              <template v-if="entry['name'].description">
                <long-description-scroll
                  v-if="isLongDescription"
                  class="list-item-entry-long-desc"
                  :text="entry['name'].description"
                />
                <description
                  v-else
                  :text="entry['name'].description"
                  :line="2"
                />
              </template>
            </div>
            <texts
              v-else
              :text="entry[key.key]"
              :color="key.isCaution ? 'caution' : ''"
              size="small"
            />
          </div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import description from '@/components/atoms/description'
import radioButtonBase from '@/components/atoms/radio-button-base'
import longDescriptionScroll from '@/components/molecules/long-description-scroll.vue'

export default {
  components: {
    texts,
    description,
    radioButtonBase,
    longDescriptionScroll
  },
  data() {
    return {
      isChecked: this.checked ? this.checked : ''
    }
  },
  props: {
    /** リストのラベル id: id, key: Objectのキー, label: 表示する文字列, isCaution: ラベルの列を赤色で表示 */
    field: Array,
    /** リスト本体 id: id, value: クリックしたときに渡す値, 残りはfieldのkeyで指定したもの */
    items: Array,
    /** 最初からチェックされているものがある場合、これを渡す */
    checked: String,
    /** 初期状態を空にする */
    notCheck: Boolean,
    /** v-modelで渡す値 */
    value: String,
    /** サンプルレシピ用 説明文を全文表示 */
    isLongDescription: Boolean
  },
  computed: {
    checkedItem() {
      return this.value
    }
  },
  methods: {
    checkBool(value) {
      return this.checkedItem === value
    },
    updateValues(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.list-item {
  &-key {
    &-block {
      display: flex;
      align-items: center;
      padding: 0 $space-sub $space-sub $space-sub;
      border-bottom: $border-title-gray;
    }
    &-inner {
      overflow: hidden;
      display: flex;
      width: 100%;
      text-align: left;
    }
  }
  &-entry {
    &-list {
      display: flex;
      flex-direction: column;
    }
    &-item {
      width: 100%;
      border-bottom: $border-sub;
      &:last-child {
        border: none;
      }
    }
    &-block {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 $space-sub;
      &:hover {
        background: $background-sub;
        opacity: 1;
      }
    }
    &-inner {
      overflow: hidden;
      display: flex;
      width: 100%;
      padding: $space-small $space-small $space-small 0;
      text-align: left;
    }
    &-toggle {
      // margin: 0 0 $space-sub;
      padding: 0 $space-sub;
      &-inner {
        padding: $space-small 0;
      }
    }
    &-icon {
      margin: 0 0 0 $space-small;
    }
    &-text {
      overflow: hidden;
    }
    &-long-desc {
      margin-top: $space-base;
    }
  }
}
.list-toggle-enter-active,
.list-toggle-leave-active {
  overflow: hidden;
  transition: all $transition-base;
}
.list-toggle-enter,
.list-toggle-leave-to {
  height: 0;
  opacity: 0;
  transition: all $transition-base;
}
.radio-dummy {
  flex-shrink: 0;
  width: adjustVW(20);
  height: adjustVW(20);
  margin: 0 $space-sub 0 0;
  opacity: 0;
}
</style>
