<template>
  <texts color="gray">
    <template v-if="type !== 'TIME_TRANSFORMER'">
      <a-time withTime timezone="UTC" :value="forecastFirstDateTime" />
      {{ ' ~ ' }}
      <a-time withTime timezone="UTC" :value="forecastLastDateTime" />
    </template>
    <template v-else>
      <a-time withTime timezone="UTC" :value="forecastLastDateTime" />
    </template>
  </texts>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    forecast: Array,
    observations: Object,
    setDateTime: Number,
    timeUnit: String,
    type: String
  },
  computed: {
    lastObservationDs() {
      if (!this.observations) return null
      const sortedObservations = Object.keys(this.observations).sort((x, y) => {
        return x - y
      })
      return parseInt(sortedObservations.pop())
    },
    lastDateTime() {
      if (this.lastObservationDs) {
        return new Date(this.lastObservationDs)
      } else {
        return null
      }
    },
    timeUnitLabel() {
      switch (this.timeUnit) {
        case 'year':
          return 'y'
        case 'month':
          return 'M'
        case 'week':
          return 'w'
        case 'day':
          return 'd'
        case 'hour':
          return 'h'
        case 'min':
          return 'm'
        case 'sec':
          return 's'
        default:
          return ''
      }
    },
    forecastFirstDateTime() {
      if (this.type === 'TIME_TRANSFORMER') {
        return null
      } else if (this.type === 'TIME_TRANSFORMER2') {
        if (!this.forecast) return null
        const target = this.forecast.find((value) => {
          return value.yhat != null
        })
        return moment(new Date(target.ds)).toDate()
      } else {
        return moment(this.lastDateTime).add(1, this.timeUnitLabel).toDate()
      }
    },
    forecastLastDateTime() {
      if (this.forecast) {
        if (this.type === 'TIME_TRANSFORMER') {
          // MFTransformerは次の周期の値しか出てこないので、forecastの末尾のみ取得
          return moment(
            new Date(this.forecast[this.forecast.length - 1].ds)
          ).toDate()
        } else if (this.type === 'TIME_TRANSFORMER2') {
          return moment(
            new Date(this.forecast[this.forecast.length - 1].ds)
          ).toDate()
        } else {
          if (this.timeUnitLabel !== 's') {
            return moment(this.lastDateTime)
              .add(this.setDateTime + 1, this.timeUnitLabel)
              .add(-1, 's')
              .toDate()
          } else {
            return moment(this.lastDateTime)
              .add(this.setDateTime + 1, this.timeUnitLabel)
              .toDate()
          }
        }
      } else {
        return null
      }
    }
  }
}
</script>
