<!-- 未使用コンポーネント おそらく最初期のプロジェクトテンプレート作成ポップアップ -->

<template>
  <form class="clone-form" @submit.prevent="submit">
    <input-box v-model="name" isGray :title="$t('projectList.projectName')" />
    <input-box
      v-model="description"
      isTextArea
      isGray
      :title="$t('common.description')"
    />
    <input-box
      v-model="wantToAchieve"
      isGray
      :title="$t('projectTemplate.wantToAchieve')"
    />
    <input-box
      v-model="predictedAndClassified"
      isGray
      :title="$t('projectTemplate.predictedAndClassified')"
    />
    <input-box
      v-model="industryOccupation"
      isGray
      :title="$t('projectTemplate.industryOccupation')"
    />
    <tag-editable-list v-model="tags" edit />

    <div class="submit-button">
      <button-main
        type="sub"
        :disabled="disableClick"
        :text="$t('common.cancel')"
        @click="$emit('cancel')"
      />
      <button-main
        button-type="submit"
        type="emphasis"
        :disabled="disableClick"
        :text="$t('common.button.create')"
      />
    </div>
  </form>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import texts from '@/components/atoms/text.vue'
import icon from '@/components/atoms/icon.vue'
import inputBox from '@/components/molecules/input-box.vue'
import buttonMain from '@/components/atoms/button-main.vue'
import TagEditableList from '@/components/molecules/tag-editable-list.vue'

export default {
  components: {
    texts,
    icon,
    inputBox,
    buttonMain,
    TagEditableList
  },
  props: {
    project: Object,
    disableClick: Boolean
  },
  data() {
    return {
      name: this.project?.name ?? '',
      description: this.project?.description ?? '',
      wantToAchieve: '',
      predictedAndClassified: '',
      industryOccupation: '',
      tags: this.project?.tags ?? []
    }
  },
  methods: {
    submit() {
      this.$emit('create-template', {
        projectId: this.project.id,
        name: this.name,
        description: this.description,
        wantToAchieve: this.wantToAchieve,
        predictedAndClassified: this.predictedAndClassified,
        industryOccupation: this.industryOccupation,
        tags: this.tags
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.project-create-form {
  width: 80rem;
}
.project-create-form > div {
  margin: 1rem;
}
.submit-button {
  display: flex;
  justify-content: center;
  margin: 1rem;
}
</style>
