var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-base',{staticClass:"c-card"},[(!_vm.isTimeTransformerV2Setting)?_c('div',{staticClass:"need-columns-wrapper"},[_c('div',{staticClass:"need-columns-item need-columns-title"},[_c('texts',{attrs:{"size":"large","text":this.$i18n.t('inference.settings.selectDataset.requiredColumns')}})],1),_c('bullet-list',{staticClass:"need-columns-scroll",attrs:{"items":_vm.columnsItems}})],1):_c('div',[(_vm.isKnownColumns.length > 0)?_c('div',{staticClass:"need-columns-block"},[_c('div',{staticClass:"need-columns-item time-transformer-title"},[_c('texts',{attrs:{"size":"large","text":_vm.$t(
              'inference.settings.selectDataset.timeTransformerV2.isKnown.title'
            )}}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            _vm.$t(
              'inference.settings.selectDataset.timeTransformerV2.isKnown.description'
            )
          ),expression:"\n            $t(\n              'inference.settings.selectDataset.timeTransformerV2.isKnown.description'\n            )\n          "}],staticClass:"time-transformer-info"},[_c('icons',{attrs:{"iconName":"info","size":"small"}})],1)],1),_c('bullet-list',{staticClass:"need-columns-scroll",attrs:{"items":_vm.isKnownColumns}})],1):_vm._e(),(_vm.isNotKnownColumns.length > 0)?_c('div',{staticClass:"need-columns-block"},[_c('div',{staticClass:"need-columns-item time-transformer-title"},[_c('texts',{attrs:{"size":"large","text":_vm.$t(
              'inference.settings.selectDataset.timeTransformerV2.isNotKnown.title'
            )}}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            _vm.$t(
              'inference.settings.selectDataset.timeTransformerV2.isNotKnown.description'
            )
          ),expression:"\n            $t(\n              'inference.settings.selectDataset.timeTransformerV2.isNotKnown.description'\n            )\n          "}],staticClass:"time-transformer-info"},[_c('icons',{attrs:{"iconName":"info","size":"small"}})],1)],1),_c('bullet-list',{staticClass:"need-columns-scroll",attrs:{"items":_vm.isNotKnownColumns}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }