export function badSeasonalityName(value) {
  let badNames = [
    'trend',
    'additive_terms',
    'daily',
    'weekly',
    'yearly',
    'holidays',
    'zeros',
    'extra_regressors_additive',
    'yhat',
    'extra_regressors_multiplicative',
    'multiplicative_terms'
  ]

  badNames = badNames.concat(
    badNames.map((x) => x + '_lower'),
    badNames.map((x) => x + '_upper'),
    [
'ds',
'y',
'cap',
'floor',
'y_scaled',
'cap_scaled',
''
]
  )
  return badNames.includes(value)
}
