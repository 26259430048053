<template>
  <popup-array
    :showPopup="showPopup"
    :popupDefs="popupDefs"
    :noClose="disableClick"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #deletePreprocessing>
      <popup-delete-preprocessing
        v-bind="deletePreprocessing"
        :deletePreprocessing="deletePreprocessing"
        :disableClick="disableClick"
        @cancel-delete="$emit('close-modal', 'deletePreprocessing')"
        @delete-preprocessing="$emit('delete-preprocessing', $event)"
      />
    </template>
    <template #intoProject>
      <into-project
        :type="$t('common.preprocessing')"
        :targets="intoProject.targets"
        :newProject="intoProject.newProject"
        :disableClick="disableClick"
        @cancel="$emit('close-modal', 'intoProject')"
        @add-to-project="$emit('add-to-project', $event)"
      />
    </template>
    <template #selectDataset>
      <popup-select-dataset
        :datasetList="datasetList"
        :preprocessing="deletePreprocessing"
        @apply-dataset="$emit('apply-dataset', $event)"
        @click-dataset="$emit('click-dataset', $event)"
      />
    </template>
    <template #checkColumn>
      <popup-check-column
        :checkDataset="checkDataset"
        :preprocessing="deletePreprocessing"
      />
    </template>
  </popup-array>
</template>

<script>
import popupDeletePreprocessing from './popup/delete-preprocessing'
import popupSelectDataset from './popup/select-dataset.vue'
import popupCheckColumn from './popup/check-column.vue'
import PopupArray from '@/components/molecules/popup/popup-array.vue'
import IntoProject from '@/components/organisms/common-popups/into-project.vue'

export default {
  components: {
    popupDeletePreprocessing,
    popupSelectDataset,
    popupCheckColumn,
    PopupArray,
    IntoProject
  },
  props: {
    deletePreprocessing: Array,
    datasetList: Array,
    showPopup: Array,
    checkDataset: Object,
    intoProject: Object,
    disableClick: Boolean
  },
  computed: {
    type() {
      return this.$t('common.preprocessing')
    },
    popupDefs() {
      let intoProject
      if (this.intoProject?.newProject) {
        intoProject = {
          title: this.$t('common.popup.intoNewProject.title', {
            type: this.type
          })
        }
      } else {
        intoProject = {
          title: this.$t('common.popup.intoProject.title', { type: this.type })
        }
      }
      return {
        deletePreprocessing: {
          title: this.$t('common.deleteConfirm')
        },
        selectDataset: {
          title: this.$t('preprocessing.selectDatasetTitle')
        },
        checkColumn: {
          title: this.checkDataset.name
        },
        intoProject
      }
    }
  }
}
</script>
