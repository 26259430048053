<template>
  <div>
    <loading-icon v-if="waitLoading" class="image-lazy-icon" />
    <img
      ref="imgs"
      class="image-lazy"
      :data-src="src"
      src=""
    >
  </div>
</template>

<script>
import loadingIcon from '@/components/atoms/loading-icon'
export default {
  components: {
    loadingIcon
  },
  data() {
    return {
      waitLoading: true
    }
  },
  props: {
    src: String
  },
  methods: {
    lazyload() {
      const options = {
        rootMargin: '100px 0px'
      }
      const observer = new IntersectionObserver(this.callback, options)
      const target = this.$refs.imgs
      observer.observe(target)
    },
    callback(entries, object) {
      const entry = entries[0]
      if (!entry.isIntersecting) return
      const img = entry.target
      this.loading(img, object)
    },
    loading(img, object) {
      const src = img.getAttribute('data-src')
      if (src) {
        img.src = src
        img.onload = function () {
          img.removeAttribute('data-src')
          this.waitLoading = false
          object.unobserve(img)
        }.bind(this)
        img.onerror = function () {
          img.removeAttribute('data-src')
          img.src = require('@/assets/images/logo.png')
          this.waitLoading = false
          object.unobserve(img)
        }
      }
    }
  },
  mounted() {
    this.lazyload()
  }
}
</script>

<style lang="scss" scoped>
.image-lazy {
  width: 100%;
  height: 100%;
  object-fit: cover;
  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
</style>
