<script>
import d3tip from 'd3-tip'
import Basis from './Basis'
export default {
  mixins: [Basis],
  mounted() {
    this.initializeTip()
  },
  data() {
    return {
      tip: null,
      showingTip: null
    }
  },
  methods: {
    showTip(d, i, nodes) {
      if (this.showingTip != null) {
        this.hideTip(d, i, nodes)
      }
      this.showingTip = [d, i, nodes]
      this.tip.show.call(nodes[i], d, i, nodes)
    },
    hideTip(d, i, nodes) {
      this.showingTip = null
      this.tip.hide.call(nodes[i], d, i, nodes)
    },
    generateTip(d) {
      let percent = ''
      if (this.isStack) {
        percent = `<p>${((d.value / d.total) * 100).toFixed(2)}%</p>`
      }
      return `<p>${d.key}</p><p>${d.value}</p>${percent}`
    },
    initializeTip() {
      this.tip = d3tip()
        .attr('class', 'd3-tip')
        .offset([-10, 0])
        .html(this.generateTip)

      this.svg.call(this.tip)
    }
  },
  destroyed() {
    this.tip.destroy()
  }
}
</script>
