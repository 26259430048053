var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('prevent-leave',{ref:"preventLeave",attrs:{"title":_vm.$t('common.trainLeave.popup.title'),"saveButton":_vm.$t('common.trainLeave.popup.saveButton', { currentPage: _vm.currentPage }),"leaveButton":_vm.$t('common.trainLeave.popup.leaveButtonProject'),"leaveButtonColor":"sub"},on:{"save-event":_vm.closePrevent},model:{value:(_vm.isPrevent),callback:function ($$v) {_vm.isPrevent=$$v},expression:"isPrevent"}},[_c('texts',{staticClass:"prevent-leave-text",attrs:{"isShowAll":"","size":"small"}},[_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.$t('common.trainLeave.reason.first', {
            leavePage: _vm.leavePage,
            currentPage: _vm.currentPage
          })
        )}}),_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.$t('common.trainLeave.reason.second', { currentPage: _vm.currentPage })
        )}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('common.trainLeave.reason.project'))}})])],1),_c('project-template-detail',{attrs:{"datasetId":_vm.datasetId,"disabledClick":_vm.disabledClick,"headerTabs":_vm.headerTabs,"loadingPage":_vm.loadingPage,"loadingDatasets":_vm.loadingDatasets,"sidebar":_vm.sidebar,"popup":_vm.popup,"projectDetail":_vm.data.projectDetail},on:{"close-modal":function($event){_vm.closePopup($event), _vm.changeStatus('confirm')},"create-project":function($event){return _vm.createProject($event)},"input-create-form":function($event){return _vm.inputCreateForm($event)},"select-project":function($event){_vm.showPopup('projectSetting'), _vm.changeStatus('setting')},"page-back":_vm.pageBack}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }