<template>
  <div class="recipe-block-list">
    <recipe-block
      v-for="block in blocks"
      :key="block.id"
      class="recipe-block-item"
      :block="block"
    />
  </div>
</template>

<script>
import recipeBlock from '@/components/atoms/recipe-block'

export default {
  components: {
    recipeBlock
  },
  props: {
    /** 学習に使用したレシピ getTrainedAIDetail で取得した result.layer_info */
    blocks: Array
  }
}
</script>

<style lang="scss" scoped>
.recipe-block {
  &-list {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    white-space: nowrap;
    @include scrollbar;
  }
  &-item {
    margin-right: 0;
    margin-bottom: $space-medium;
    &::after {
      top: inherit;
      right: inherit;
      bottom: adjustVW(-26);
      left: 50%;
      width: 1px;
      height: adjustVW(26);
      transform: translateX(-50%);
    }
    ::v-deep .recipe-block-text {
      overflow: hidden;
      width: 100%;
    }
  }
}
</style>
