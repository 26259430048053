<template>
  <inference
    v-bind="data"
    :accountInfo="accountInfo"
    :popup="popup"
    :project="project"
    :trainedAiList="trainedAiList"
    :datasetList="datasetList"
    :preprocessingList="preprocessingList"
    :disableStartInferece="disableStartInferece"
    :preprocessingColumns="preprocessingColumns"
    :requiredColumns="requiredColumns"
    :selectedTrainedAi="selectedItems.trainedAi"
    :selectedItems="selectedItems"
    :trainedAiDetail="trainedAiDetail"
    :trainedAiDetailFullInfo="trainedAiDetailFullInfo"
    :loadColumnInfo="loadColumnInfo"
    :predictedColumns="predictedColumns"
    :predictedTable="predictedTable"
    :total="total"
    :finishedTotal="finishedTotal"
    :finishColumnsFix="finishColumnsFix"
    :finishColumnsAllProcessFix="finishColumnsAllProcessFix"
    :finishedAllColumns="finishedAllColumns"
    :inferencedColumnsBase="inferencedColumnsBase"
    :inferenceStatus="inferenceStatus"
    :forecast="forecast"
    :inferencedColumns="inferencedColumns"
    :takingTooLongTextItem="takingTooLongTextItem"
    :inferenceType="inferenceType"
    :disableOptimization="disableOptimization"
    :optimizationValues="optimizationValues"
    :checkOptimizationResult="checkOptimizationResult"
    :optimizationFlag="optimizationFlag"
    :optimizationErrorMessage="optimizationErrorMessage"
    :optimizationErrorManual="optimizationErrorManual"
    @getSelectedItems="getSelectedItems"
    @start-inference="startInference"
    @start-optimization="startOptimization"
    @download-inference-result="downloadInferenceResult"
    @download-multi-result="downloadMultiResult"
    @input-multi-inference-column="inputMultiInferenceColumn"
    @change-page="changePageTableData"
    @change-column="changeColumn"
    @check-expressions="checkExpressions"
    @get-optimization-conditions-list="getOptimizationConditionsList"
    @change-optimization-page="changeOptimizationPage"
    @change-optimization-filter-value="changeOptimizationFilterValue"
    @input-edit-optimization-form="inputEditOptimizationForm"
    @save-edit-optimization-form="saveEditOptimizationForm"
    @download-optimization-result="downloadResultOptimization"
    @optimization-stop="showPopup('stopOptimization')"
    @optimization-stop-confirm="optimizationStopConfirm"
    @close-modal="closePopup"
    @close-optimization-tutorial="closeOptimizationTutorial"
    @close-optimization-result-tutorial="closeOptimizationTutorial"
    @show-optimization-tutorial="showOptimizationTutorial"
    @reset-optimization-settings="resetOptimizationSettings"
    @reset-inference-settings="resetInferenceSettings"
    @change-trained-ai-detail="changeTrainedAiDetail"
    @input-clustering-setting="inputClusteringSetting"
    @download-clustering-result="downloadClustering"
    @change-clustering-distribution-column="changeClusteringDistributionColumn"
    @toggle-clustering-show-dimension="toggleClusteringShowDimension"
    @show-inference-enveiroment-setting="
      showPopup('inferenceEnvironmentSetting')
    "
  />
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { download, base64ToBytes, base64ToUTF8 } from '@/lib/download'
import {
  preprocessAvailable,
  datasetAvailable,
  datasetSelectAvailable,
  isNatualLanguage,
  inferenceTypeOptions,
  usedCustomblockList
} from '@/lib/inference'
import { setMetricsList, getModelType } from '@/lib/trainedAI.js'
import { validConditions } from '@/lib/optimization.js'
import * as metricsDefsByModelType from '@/components/organisms/trained-ai-detail/metricsDefs'
import { optimizationConditionsValidator } from '@/lib/validator/optimizationConditions.js'
import setMountedTimer from '@/mixin/set-mounted-timer'

import inference from '@/components/templates/inference.vue'

export default {
  components: { inference },
  mixins: [setMountedTimer],
  data() {
    return {
      data: {
        sidebar: {
          // サイドバーに表示する情報
          project: {
            name: '退職予測',
            description:
              'このプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入ります'
          },
          activeLink: 'inference'
        },
        headerTabs: {
          // ヘッダーのタブ
          tabs: [],
          tabSelect: 1
        },
        body: {
          inferenceType: {
            inferenceTypeList: ['classification'],
            choosedItems: {
              choosedAiInfo: null
            }
          },
          inferenceResult: {
            inferenceType: 'classification'
          },
          inferenceErrors: null,
          inferenceWarnings: [],
          selectedColumnIndex: 0,
          optimizationInfo: {
            validExpressions: [],
            loadCheckExpressions: false,
            loadOptimizationConditionsList: false,
            settedOptimizationConditionsId: null,
            loadOptimizationConditionsUpdate: false,
            loadOptimizationDetail: false,
            loadOptimizationPaging: false,
            download: {
              downloading: false,
              downloadLoading: false,
              downloadComp: false
            },
            configItems: {
              optimizationId: '',
              filters: [],
              inPageNumber: 1,
              limit: 50
            },
            optimizationFormValidate: {
              duplicate: undefined
            },
            validPredictionColumns: {},
            firstSelectOptimization: false,
            selectedTrainedAiDataset: {}
          },
          optimizationConditionsList: [],
          reset: false,
          // テキストマイニングの結果表示用
          textMiningSetting: {},
          textMiningResult: {},
          clusteringSetting: {
            setting: {
              resultId: null,
              inPageNumber: 1,
              limit: 50,
              sort: 'classAsc',
              showDimension: false
            },
            loadingTable: false,
            loadingDistribution: false,
            download: {
              downloading: false,
              downloadLoading: false,
              downloadComp: false
            },
            distributionSetting: [
              {
                xTarget: null,
                yTarget: null
              },
              {
                xTarget: null,
                yTarget: null
              },
              {
                xTarget: null,
                yTarget: null
              }
            ]
          },
          clusteringResult: {
            inputColumns: [],
            classes: [],
            total: null,
            list: [],
            listIndexClass: []
          },
          clusteringDistributions: [],
          clusteringInfo: {}
        },
        showInferenceResult: false,
        inferenceTakingTooLong: false,
        runInference: false,
        loadingPage: false,
        downloading: false,
        downloadLoading: false,
        loadColumnResult: false,
        loadTimeseriesTrainedAiData: false,
        timeseriesSettings: null,
        tableDatas: {
          limit: 50, // 推論結果の一度に帰ってくる件数
          inPageNumber: 1,
          waitTable: false,
          graphProb: [],
          importanceProb: [],
          tableColumns: []
        }
      },
      popup: {
        // ポップアップの情報
        deleteTrainedAi: {
          trainedAiList: {
            field: [
              {
                key: 'name',
                label: '削除する学習済みAI'
              },
              {
                key: 'caution',
                label: '',
                isCaution: true
              }
            ],
            items: []
          }
        },
        disableClick: false,
        showPopup: [], // 現在表示しているポップアップ
        showTutorial: {
          optimization: false
        }
      },
      selectedItems: {
        trainedAi: null,
        dataset: null,
        preprocess: null,
        inferenceType: null,
        inferenceSettings: null,
        optimizationSettings: null,
        noPreprocess: false,
        waitSelectItem: false,
        inferenceEnvironment: 'auto'
      },
      preprocessingColumns: {
        input: [],
        output: []
      },
      requiredColumns: [],
      // trainedAiの詳細情報取得処理で取得できる、一部の情報だけを保持する変数
      trainedAiDetail: null,
      // trainedAiの詳細情報取得処理で取得できる、全ての情報だけを保持する変数
      trainedAiDetailFullInfo: {},
      loadColumnInfo: false,
      predictionColumn: [],
      // 予測する列に対する、閾値などの設定値情報を持つ変数
      predictionColumnSetting: [],
      baseTrainedAiPredictionColumns: [],
      waitTimeseriesResultFirstTime: false
    }
  },
  computed: {
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('datasets', { datasets: 'datasetList' }),
    ...mapGetters('trainedAi', ['trainedAIs']),
    ...mapGetters('preprocessings', {
      allPreprocessings: 'EDARecipes',
      allPreprocessingsLoading: 'loadingEDARecipes'
    }),
    ...mapGetters('project', ['projectList']),
    ...mapGetters('customblock', ['customblockList']),
    ...mapGetters('inference', ['inferenceErrorMessage', 'inferenceErrorDetail', 'inferenceWarnings', 'runMultiInference']),
    ...mapState('inference', [
      'inferenceId',
      'classes',
      'resultInputColumns',
      'predictedColumns',
      'predictedTable',
      'total',
      'finishColumnsList',
      'processStatusList',
      'forecast',
      'inferencedColumns',
      'inferencedColumnsBase',
      'inferenceStatus',
      'inferenceType',
      'inferencedTrainedAiId',
      'showOptimizationResult',
      'optimizationProgressStatus',
      'newlyPredictionColumns',
      'newlyFinishedPredictionColumns',
      'optimizationInProgress',
      'trainingNumIter',
      'trainingProgress',
      'optimizationNumIter',
      'optimizationProgress',
      'optimizationConditions',
      'optimizationResult',
      'optimizationSettings',
      'optimizationModelInfo',
      'optimizationConditionsInfo',
      'executedOptimizationConditions',
      'optimizationErrorMessage',
      'optimizationErrorManual',
      'jobInfo',
      'isOptimizationStopped',
      'featureImportance'
    ]),
    project() {
      const projectId = this.$route?.params?.projectId
      return projectId && this.projectList ? this.projectList[projectId] : null
    },
    // TODO project内の学習済みAIを使用しているがtrainedAiとどちらを使用するか統一する
    // https://trello.com/c/G6lnTqQ2
    trainedAiList() {
      let trainedAis = null
      if (this.project) {
        trainedAis = this.project.listAIs
      } else {
        trainedAis = this.trainedAIs ? Object.values(this.trainedAIs) : null
      }
      if (trainedAis == null) return []
      return trainedAis.map((item) => {
        const modelType = getModelType(item.type)
        let metricsDefs = metricsDefsByModelType[modelType](this.$t.bind(this))

        if (modelType === 'images') {
          // 特定の精度指標が含まれるかどうかで、分類が 2 種類 or それ以上かをチェックする
          const isMultiClass = Object.keys(item.metrics[0]).includes(
            'test_overall_accuracy'
          )

          // 分類の種類数に応じて精度評価を変更する。
          metricsDefs = isMultiClass
            ? metricsDefsByModelType.imagesMultiClass(this.$t.bind(this))
            : metricsDefsByModelType.images(this.$t.bind(this))

          // 推論のときは表示したくない精度指標があるので除去
          const metricsDefsKeyList = Object.keys(metricsDefs)
          const removeAccuracyList = ['train_accuracy', 'train_loss']

          metricsDefs = metricsDefsKeyList.reduce((acc, key) => {
            if (!removeAccuracyList.includes(key)) acc[key] = metricsDefs[key]

            return acc
          }, {})
        }

        return {
          ...item,
          summary: {
            name: item.name,
            description: item.description
          },
          expectedEffect: item.expectedEffect,
          accuracy: item.accuracy,
          inferenceType: item.type,
          metricsList: setMetricsList(item, metricsDefs),
          disabled: item.type === 'NO_ACCURACY',
          disabledTips: this.$t('common.disabled.NOT_AVAILABLE_NO_ACCURACY_AI')
        }
      })
    },
    datasetList() {
      let datasets = null
      if (this.project) {
        datasets = this.project.listData
      } else {
        datasets = this.datasets ? Object.values(this.datasets) : null
      }
      if (datasets == null) return []
      return datasets.map((item) => {
        return {
          ...item,
          summary: {
            name: item.name,
            description: item.description
          },
          // データ数が返り値に無いので仮置き
          nData: item.nData,
          // データ数が返り値に無いので仮置き
          columns: item.columns.map((x, i) => ({ id: i, text: x }))
        }
      })
    },
    preprocessingList() {
      let preprocessings = null
      if (this.project) {
        preprocessings = this.project.listEdaRecipe
      } else {
        preprocessings = this.allPreprocessings
          ? Object.values(this.allPreprocessings)
          : null
      }
      if (preprocessings == null) return []
      return preprocessings.map((item) => ({
        ...item,
        summary: {
          name: item.name,
          description: item.description
        }
      }))
    },
    disableStartInferece() {
      if (this.selectedItems.trainedAi == null) {
        return true
      }
      if (this.selectedItems.dataset == null) {
        if (
          this.selectedItems.trainedAi.type !== 'TIME' &&
          !this.selectedItems.trainedAi?.is_optimization
        ) {
          return true
        } else if (this.selectedItems.trainedAi.type === 'TIME') {
          if (
            !(
              this.selectedItems?.inferenceSettings?.time > 0 &&
              this.selectedItems?.inferenceSettings?.timeUnit
            ) ||
            this.predictionColumn.length === 0
          ) {
            return true
          }
        } else if (this.selectedItems.trainedAi?.is_optimization) {
          if (this.optimizationValues.optimizationInProgress) {
            return true
          }
          const optimizationSettings = this.selectedItems.optimizationSettings
          if (!optimizationSettings) return true

          const optimizationInfo = this.data.body.optimizationInfo
          if (
            optimizationInfo.validExpressions.length > 0 ||
            optimizationInfo.loadCheckExpressions
          )
            return true
          return this.disableOptimization.length > 0
        }
      }
      if (this.data.tableDatas.waitTable) {
        return true
      }
      return false
    },
    // 時系列の場合に、予測する列のindex番号でリストを作り、それを推論時に送る
    targetIndexList() {
      if (this.predictionColumn.length === 0) return []
      if (!(this.baseTrainedAiPredictionColumns.length > 1)) return []
      const target = this.predictionColumn.map((item) => {
        const index = this.baseTrainedAiPredictionColumns.indexOf(item)
        return index
      })
      return target.sort((x, y) => {
        return x - y
      })
    },
    // 1つのプロセスでも推論が終わった列の配列
    finishColumnsFix() {
      /**
       * {
       *  0: 100,
       *  2: 0
       * }
       * のように、推論時に送ったtargetIndexListをKeyとして、推論が何行目まで終わったかの値が返却される
       * その返却された値から、推論結果を表示できる列の列名の配列を作成している
       */
      if (
        !this.finishColumnsList ||
        !(this.inferencedColumnsBase && this.inferencedColumnsBase.length > 1)
      )
        return []
      const checkTarget = Object.keys(this.finishColumnsList).filter((item) => {
        const allFinish = this.finishColumnsList[item]
        let finishFirstProcess = false
        try {
          finishFirstProcess = this.processStatusList[item] > 0
        } catch {
          // 何もしない
        }
        return allFinish || finishFirstProcess
      })
      return checkTarget.map((item) => {
        return this.inferencedColumnsBase[item]
      })
    },
    // 全てのプロセスで推論が終わった列の配列
    finishColumnsAllProcessFix() {
      /**
       * {
       *  0: true,
       *  2: false
       * }
       * のように、推論時に送ったtargetIndexListをKeyとして、推論が完了したかどうかが返却される
       * その返却された値から、推論が終わった列の列名の配列を作成している
       */
      if (
        !this.finishColumnsList ||
        !(this.inferencedColumnsBase && this.inferencedColumnsBase.length > 1)
      )
        return []
      const checkTarget = Object.keys(this.finishColumnsList).filter((item) => {
        return this.finishColumnsList[item]
      })
      return checkTarget.map((item) => {
        return this.inferencedColumnsBase[item]
      })
    },
    takingTooLongTextItem() {
      if (!this.selectedItems?.inferenceType) return null
      if (this.selectedItems.inferenceType === 'time') {
        // MFTransformerの場合はデータセットを使用するため、通常の文章を表示
        return this.$t('inference.takingTooLongTimeseries')
      } else {
        return this.$t('inference.takingTooLong')
      }
    },
    disableOptimization() {
      if (
        !this.selectedItems.trainedAi?.is_optimization ||
        !this.selectedItems.optimizationSettings
      )
        return []
      const optimizationValid = validConditions(
        this.selectedItems.optimizationSettings.optimizationConditions,
        this.data.body.optimizationInfo.validExpressions
      )
      return optimizationValid
    },
    optimizationValues() {
      return {
        showOptimizationResult: this.showOptimizationResult,
        optimizationProgressStatus: this.optimizationProgressStatus,
        newlyPredictionColumns: this.newlyPredictionColumns,
        newlyFinishedPredictionColumns: this.newlyFinishedPredictionColumns,
        optimizationInProgress: this.optimizationInProgress,
        trainingNumIter: this.trainingNumIter,
        trainingProgress: this.trainingProgress,
        optimizationNumIter: this.optimizationNumIter,
        optimizationProgress: this.optimizationProgress,
        optimizationConditions: this.optimizationConditions,
        optimizationResult: {
          ...this.optimizationResult,
          ...this.data.body.optimizationInfo.configItems
        },
        optimizationSettings:
          this.optimizationModelInfo?.optimization_conditions,
        optimizationConditionsInfo: this.optimizationConditionsInfo,
        jobInfo: this.jobInfo
      }
    },
    checkOptimizationResult() {
      if (!this.optimizationValues) return false
      return (
        this.optimizationValues.optimizationProgressStatus ===
          'finishOptimization' &&
        !this.optimizationValues.optimizationInProgress &&
        this.optimizationValues.showOptimizationResult
      )
    },
    optimizationFlag() {
      if (!this.optimizationValues?.optimizationProgressStatus) return false
      return (
        ['training', 'preparingEnvironment', 'stoppingTraining'].indexOf(
          this.optimizationValues.optimizationProgressStatus
        ) >= 0
      )
    },
    finishedTotal() {
      const targetColumnIndex = this.data.body.selectedColumnIndex

      const hasTargetColumnIndex =
        targetColumnIndex !== null && targetColumnIndex !== undefined
      if (!hasTargetColumnIndex) return null

      // マルチプロセスで実行している場合
      if (this.processStatusList) {
        const num = this.processStatusList[targetColumnIndex]

        // 最初のプロセスが終わったタイミングで、一度だけ結果を取得する処理を走らせる
        const notGetFirst =
          (!this.runMultiInference && !this.data.showInferenceResult) ||
          (this.runMultiInference && this.data.runInference)
        if (notGetFirst && num > 0) {
          this.setFirstProcessResult()
        }
        return num
      }

      // マルチプロセスではない、または、まだ最初のプロセスも終わってない段階ではnullを返却することで、実際に表示するコンポーネントへ渡す
      return null
    },
    finishedAllColumns() {
      if (this.processStatusList) {
        // 全てのプロセスで処理が終わってるかのチェック
        if (Object.keys(this.processStatusList).length > 1) {
          const checkSet = new Set(Object.values(this.processStatusList))
          return checkSet.size === 1 && !checkSet.has(0)
        } else {
          return this.finishedTotal >= this.total
        }
      }

      // `processStatusList` を利用しない場合、このcomputedの結果は無視させたい
      return true
    }
  },
  methods: {
    ...mapActions('auth', ['fetchAccountInfo', 'updateTutorialInfo']),
    ...mapActions('inference', [
      'inference',
      'reenterInference',
      'reenterInferenceInfo',
      'getInferenceResult',
      'reset',
      'fetchTimeseriesResult',
      'fetchInferenceResultPaging',
      'optimizationAsync',
      'fetchOptimizationResult',
      'updateOptimzationConditions',
      'cancelWatchProgress',
      'stopOptimizationAsync',
      'downloadClusteringResult',
      'fetchClusteringResult',
      'fetchClusteringDistribution',
      'setInferenceInfoForInferenceResult'
      // 'getInferencedTextMining'
    ]),
    ...mapActions('trainedAi', [
      'fetchModelList',
      'fetchTimeseriesDetail',
      'fetchTrainedAiDetail',
      'fetchOptimizationConditionsList',
      'downloadOptimizationResult',
      'getTrainedTextMining'
    ]),
    ...mapActions('trainings', ['fetchValidExpressions', 'trainingAsync']),
    ...mapActions('project', ['loadProjectDetail']),
    ...mapActions('customblock', ['fetchCustomblocks']),

    convertChoosedAiInfo: function (trainedAI) {
      this.data.body.inferenceType.choosedItems.choosedAiInfo = {
        id: trainedAI.id,
        name: trainedAI.name,
        description: trainedAI.description,
        link: '/projects/' + trainedAI.id,
        // デモデータ
        targetValiable: '目的変数が入ります',
        accuracy: {
          firstAccuracy: {
            // デモデータ
            name: '再現率',
            value: trainedAI.accuracy
          },
          // デモデータ
          effect: {
            name: '退職率の削減量',
            value: -15
          }
        },
        featureImportance: trainedAI.featureImportance
      }
    },
    async getSelectedItems({
      trainedAiId,
      preprocess,
      dataset,
      inferenceType,
      inferenceSettings,
      optimizationSettings,
      inferenceEnvironment,
      resolve = () => {}
    }) {
      try {
        const checkChangeTrainedAi =
          this.selectedItems?.trainedAi?.id !== trainedAiId
        if (trainedAiId !== undefined) {
          this.selectedItems.waitSelectItem = true
          const selectedItem = this.trainedAiList.find(
            (item) => item.id === trainedAiId
          )
          if (
            selectedItem != null &&
            (!this.selectedItems?.trainedAi || checkChangeTrainedAi)
          ) {
            this.selectedItems.trainedAi = selectedItem
            const trainedAi = selectedItem
            await this.makeRequiredColumnsList(trainedAi.id)
            this.convertChoosedAiInfo(trainedAi)
            this.loadInferenceType(trainedAi)
            if (!preprocessAvailable(trainedAi, this.trainedAiDetail)) {
              this.selectedItems.preprocess = null
              this.selectedItems.noPreprocess = false
            }
            if (!datasetAvailable(trainedAi)) {
              this.selectedItems.dataset = null
            }
            if (!datasetSelectAvailable(trainedAi)) {
              if (this.selectedItems.dataset?.upload === false) {
                this.selectedItems.dataset = null
              }
            }
            if (isNatualLanguage(this.trainedAiDetail)) {
              if (this.selectedItems.dataset?.upload === false) {
                this.selectedItems.dataset = null
              }
            }
            if (trainedAi?.is_optimization) {
              await this.getSelectedOptimizationItem(trainedAi)
            }
            const layerInfo = this.trainedAiDetail?.layer_info
            const usedCustomblockItems =
              layerInfo != null ? usedCustomblockList(layerInfo) : []
            if (usedCustomblockItems.length > 0) {
              if (!this.customblockList || this.customblockList.length === 0) {
                await this.fetchCustomblocks()
              }
              const checkCustomblock = usedCustomblockItems.some((target) => {
                return !this.customblockList.some((customblock) => {
                  return customblock.customblock_id === target.customblock_id
                })
              })
              if (checkCustomblock) {
                this.showPopup('notAvailableTrainedAi')
              }
            }
          }
          this.selectedItems.waitSelectItem = false
        }
        if (preprocess !== undefined) {
          this.selectedItems.waitSelectItem = true
          if (preprocess === null) {
            this.selectedItems.noPreprocess = true
            this.selectedItems.preprocess = null
            this.preprocessingColumns = null
          } else if (
            !this.selectedItems?.preprocess ||
            this.selectedItems.preprocess?.id !== preprocess
          ) {
            const item = this.preprocessingList.find(
              (item) => item.id === preprocess
            )
            this.selectedItems.noPreprocess = false
            this.selectedItems.preprocess = item
            const detailEDARecipe = await this.$sendMessageAndReceive({
              action: 'detailEDARecipe',
              EDARecipeAccountId: item.accountId,
              EDARecipeId: item.id
            })
            if (detailEDARecipe.status !== 'error') {
              this.preprocessingColumns = {
                input: detailEDARecipe.result.input_columns,
                output: detailEDARecipe.result.output_columns
              }
            }
          }
          this.selectedItems.waitSelectItem = false
        }

        if (dataset !== undefined) {
          this.selectedItems.waitSelectItem = true
          this.data.body.inferenceErrors = null
          if (dataset.upload) {
            this.selectedItems.dataset = {
              upload: true,
              file: dataset.file
            }
          } else {
            const datasetId = dataset.id
            if (
              !this.selectedItems?.dataset ||
              this.selectedItems.dataset?.item?.id !== datasetId
            ) {
              const item = this.datasetList.find(
                (item) => item.id === datasetId
              )
              this.selectedItems.dataset = {
                upload: false,
                item
              }
            }
          }
          this.selectedItems.waitSelectItem = false
        }

        if (inferenceType !== undefined)
          this.selectedItems.inferenceType = inferenceType

        if (inferenceSettings !== undefined) {
          this.selectedItems.inferenceSettings = inferenceSettings
        }
        if (optimizationSettings !== undefined) {
          const emitConfig = JSON.parse(JSON.stringify(optimizationSettings))
          emitConfig.optimizationConditions.columnConditions =
            emitConfig.optimizationConditions.columnConditions.map(
              (condition) => {
                return {
                  column: condition.column,
                  condition: condition.condition,
                  integer: condition.disabledInteger ? false : condition.integer
                }
              }
            )
          this.selectedItems.optimizationSettings = emitConfig
        }
        if (inferenceEnvironment !== undefined) {
          this.selectedItems.inferenceEnvironment = inferenceEnvironment
        }
      } finally {
        resolve()
      }
    },
    async getSelectedOptimizationItem(trainedAi) {
      const dataset = trainedAi.datasets[0] ?? null
      if (!dataset) return
      const req = {
        action: 'validPredictColumns',
        data_id: dataset.id,
        data_account_id: dataset.accountId
      }
      try {
        const res = await this.$sendMessageAndReceive(req)
        if (res.status === 'error') {
          throw new Error(res)
        }
        this.data.body.optimizationInfo.validPredictionColumns =
          res.predict_columns
      } catch (error) {
        throw new Error(error)
      }
      const reqGetData = {
        action: 'getData',
        dataId: dataset.id,
        accountId: dataset.accountId,
        includeGraph: false,
        includeData: false
      }
      try {
        const resData = await this.$sendMessageAndReceive(reqGetData)
        if (resData.status === 'error') {
          throw new Error(resData)
        }
        this.data.body.optimizationInfo.selectedTrainedAiDataset =
          resData.detail
      } catch (error) {
        throw new Error(error)
      }
    },
    loadInferenceType: function (trainedAi) {
      const inferenceType = trainedAi.type
      const defaultInferenceType = inferenceTypeOptions.bind(this)(
        trainedAi,
        isNatualLanguage(this.trainedAiDetail)
      )
      this.selectedItems.inferenceType = defaultInferenceType[0].value
      if (typeof inferenceType === 'string') {
        this.data.body.inferenceType.inferenceTypeList = [inferenceType.toLowerCase()]
      } else {
        this.data.body.inferenceType.inferenceTypeList = inferenceType.map(
          (items) => items.toLowerCase()
        )
      }
    },
    async makeRequiredColumnsList(trainedAiId) {
      const trainedAi = await this.fetchTrainedAiDetail({
        id: trainedAiId,
        columnIndex: 0
      })
      const inputColumns = trainedAi.trainConfig.inputColumns
      this.trainedAiDetail = trainedAi.result
      this.trainedAiDetailFullInfo = trainedAi
      this.requiredColumns = inputColumns
      this.predictionColumn = trainedAi.trainConfig.predictionColumn // 予測する列（可変）
      this.baseTrainedAiPredictionColumns =
        trainedAi.trainConfig.predictionColumn // 予測する列（学習済みAIを変更するまで固定）
      this.$set(
        this.selectedItems.trainedAi,
        'predictColumn',
        this.predictionColumn
      )
      if (trainedAi?.summary?.type === 'TIME_TRANSFORMER') {
        this.requiredColumns = [...this.predictionColumn, ...this.requiredColumns]
      }
    },
    inferenceWarningText() {
      this.data.inferenceTakingTooLong = true
    },
    async reenterInferenceStart(targetInferenceId) {
      this.beforeInference()
      await this.reenterInference({
        targetInferenceId,
        predictionColumn: this.predictionColumn,
        inferencedTrainedAiId: this.selectedItems.trainedAi.id,
        baseTrainedAiPredictionColumns: this.baseTrainedAiPredictionColumns,
        inferenceType: this.selectedItems.inferenceType
      })
      this.afterInference()
    },
    async getReenterInferenceInfo(targetInferenceId) {
      const res = await this.reenterInferenceInfo({
        targetInferenceId
      })
      await this.getSelectedItems(res)
      const predictionColumn = []
      if (res.targetIndexList && res.targetIndexList.length > 0) {
        for (const index in res.targetIndexList) {
          predictionColumn.push(this.baseTrainedAiPredictionColumns[index])
        }
      }
      this.predictionColumn = predictionColumn
    },
    async startInference() {
      this.beforeInference()
      const req = {
        projectId: parseInt(this.$route.params.projectId),
        type: this.selectedItems.inferenceType,
        trainedAiId: this.selectedItems.trainedAi.id,
        accountId: this.selectedItems.trainedAi.accountId,
        EDARecipeId: this.selectedItems?.preprocess?.id,
        EDARecipeAccountId: this.selectedItems?.preprocess?.accountId,
        predictionColumn: this.predictionColumn,
        targetIndexList: this.targetIndexList,
        baseTrainedAiPredictionColumns: this.baseTrainedAiPredictionColumns, // 選択した予測する列ではなく、選択してないものも含めた一覧を保存
        inferencedTrainedAiId: this.selectedItems.trainedAi.id, // 時系列などの学習済みAIの固定用ID
        inferenceEnvironment: this.selectedItems.inferenceEnvironment
      }
      if (this.selectedItems.inferenceType === 'time') {
        req.forecastTime = this.selectedItems?.inferenceSettings?.time
        req.forecastTimeUnit = this.selectedItems?.inferenceSettings?.timeUnit
      } else {
        if (this.selectedItems.inferenceType === 'classification') {
          // 閾値指定用の設定
          const thresholdList = []
          this.predictionColumnSetting.forEach((item, index) => {
            thresholdList.push(this.predictionColumnSetting[index].threshold)
          })
          req.threshold = thresholdList
        } else if (this.selectedItems.inferenceType === 'clustering') {
          req.targetClustering =
            this.selectedItems?.inferenceSettings?.targetClustering
        }
        if (this.selectedItems.dataset.upload) {
          req.file = this.selectedItems.dataset.file
        } else {
          req.dataId = this.selectedItems.dataset.item.id
          req.dataAccountId = this.selectedItems.dataset.item.accountId
        }
      }

      await this.inference(req)

      this.afterInference()
    },
    beforeInference() {
      if (this.timeoutID) {
        window.clearTimeout(this.timeoutID)
      }
      this.data.downloadComplete = false
      this.data.runInference = true
      this.data.showInferenceResult = false
      this.data.body.inferenceErrors = null
      this.data.body.inferenceResult.inferenceType =
        this.selectedItems.inferenceType
      this.data.timeseriesSettings = null
      this.data.body.clusteringInfo = {}
      // 推論結果のテーブルの初期化
      this.data.tableDatas.inPageNumber = 1
      this.data.tableDatas.graphProb = []
      this.data.tableDatas.importanceProb = []
      this.data.tableDatas.tableColumns = []
      this.data.inferenceTakingTooLong = false

      // テキストマイニングの結果表示を初期化
      // TODO 推論実行時のテキストマイニングの結果取得実装後修正
      // this.resetTextMining()

      // 時系列MFTの場合は表示を固定する
      if (
        this.selectedItems.inferenceType === 'time' ||
        this.selectedItems.inferenceType === 'time_transformerV2'
      ) {
        this.data.timeseriesSettings = this.trainedAiDetail
      }
      // ClusterFlowの場合は表示を固定する
      if (this.selectedItems.inferenceType === 'clustering') {
        this.data.body.clusteringInfo =
          this.trainedAiDetailFullInfo?.summary?.clusteringInfo
      }
      this.data.body.selectedColumnIndex = 0
      if (this.baseTrainedAiPredictionColumns.length > 1) {
        this.data.body.selectedColumnIndex = this.targetIndexList[0]
      }

      this.timeoutID = window.setTimeout(this.inferenceWarningText, 1000 * 60)
      this.data.timeseriesSettings = this.trainedAiDetail

      // 自然言語でテキストマイニングの表示がある場合表示を固定する
      if (
        this.selectedItems.trainedAi?.textMining != null &&
        Object.keys(this.selectedItems.trainedAi.textMining).length > 0
      ) {
        this.data.body.textMiningSetting =
          this.selectedItems.trainedAi.textMining
      }
    },
    afterInference() {
      this.data.body.inferenceWarnings = this.inferenceWarnings
      if (this.inferenceErrorMessage) {
        this.data.body.inferenceErrors = {
          name: this.inferenceErrorMessage,
          detail: this.inferenceErrorDetail
        }
        window.clearTimeout(this.timeoutID)
        this.$gtmDataLayer.sendEvent('inference', 'failed')
        this.data.runInference = false
        this.data.inferenceTakingTooLong = false
        return
      }

      if (!(this.baseTrainedAiPredictionColumns.length > 1)) {
        window.clearTimeout(this.timeoutID)
        this.$gtmDataLayer.sendEvent('inference', 'complete')
      }

      if (this.predictedTable && this.predictedTable.length > 0) {
        this.setGraphProb()
        this.setImportanceProb()
        this.data.tableDatas.tableColumns = this.resultInputColumns
      }

      // TODO 推論実行時のテキストマイニングの結果取得実装後修正
      /**
      if (
        this.trainedAiDetailFullInfo.summary?.textMining != null &&
        Object.keys(this.trainedAiDetailFullInfo.summary?.textMining).length > 0
      ) {
        this.setTextMiningParams()
      }
       */
      this.data.runInference = false
      this.showResult()
      this.data.inferenceTakingTooLong = false
    },
    async startOptimization() {
      this.data.body.optimizationInfo.configItems = {
        optimizationId: '',
        filters: [],
        inPageNumber: 1,
        limit: 50
      }
      const checkGetConditions =
        this.data.body.optimizationInfo.settedOptimizationConditionsId !==
        this.selectedItems.trainedAi.id

      const payload = {
        projectId: parseInt(this.$route.params.projectId),
        trainedAiId: this.selectedItems.trainedAi.id,
        trainConfig: this.selectedItems.optimizationSettings.trainConfig,
        optimizationConditions:
          this.selectedItems.optimizationSettings.optimizationConditions,
        checkGetConditions: checkGetConditions,
        conditionsList: this.data.body.optimizationConditionsList
      }
      await this.optimizationAsync(payload)
    },
    async downloadInferenceResult({ encoding }) {
      this.data.downloadComplete = false
      this.data.downloading = true
      // すぐにダウンロードできるものは、黒い背景が一瞬しか見えないので、setTimeoutを入れている
      this.timeoutDownload = window.setTimeout(
        function () {
          this.data.downloadLoading = true
        }.bind(this),
        100
      )
      try {
        const req = {
          action: 'downloadInferenceResult',
          inferenceId: this.inferenceId,
          encoding: encoding
        }
        if (
          this.inferencedColumnsBase &&
          this.inferencedColumnsBase.length > 1
        ) {
          req.targetIndex = this.inferencedColumnsBase.indexOf(
            this.inferencedColumns[0]
          )
        }
        const res = await this.$sendMessageAndReceive(req)
        const file = res.file
        const ext = res.ext
        const decoded = base64ToBytes(file)
        await download(decoded, 'inference_result.' + ext)
      } finally {
        this.data.downloading = false
        window.clearTimeout(this.timeoutDownload)
        this.data.downloadLoading = false
        this.data.downloadComplete = true
      }
    },
    async downloadMultiResult({ encoding, column, isAll }) {
      this.data.downloadComplete = false
      this.data.downloading = true
      // すぐにダウンロードできるものは、黒い背景が一瞬しか見えないので、setTimeoutを入れている
      this.timeoutDownload = window.setTimeout(
        function () {
          this.data.downloadLoading = true
        }.bind(this),
        100
      )
      try {
        const req = {
          encoding: encoding
        }
        if (isAll) {
          const target = this.inferencedColumns.map((item) => {
            const index = this.inferencedColumnsBase.indexOf(item)
            return index
          })
          const targetIndexList = target.sort((x, y) => {
            return x - y
          })
          req.action = 'downloadAllInferenceResult'
          req.targetIndexList = targetIndexList
          req.inferenceId = this.inferenceId
          req.trainedAiId = this.inferencedTrainedAiId
        } else {
          let targetIndex = null
          // 予測する列が一つのAIとMFTransformerの場合はtargetIndexをnullにする
          if (column && this.inferencedColumnsBase.length > 1) {
            targetIndex = this.inferencedColumnsBase.indexOf(column)
          }
          req.action = 'downloadInferenceResult'
          req.targetIndex = targetIndex
          req.inferenceId = this.inferenceId
        }
        const res = await this.$sendMessageAndReceive(req)
        const file = res.file
        const ext = res.ext
        const decoded = base64ToBytes(file)
        await download(decoded, 'inference_result.' + ext)
      } finally {
        this.data.downloading = false
        window.clearTimeout(this.timeoutDownload)
        this.data.downloadLoading = false
        this.data.downloadComplete = true
      }
    },
    async changePageTableData(index) {
      if (this.data.tableDatas.waitTable) return
      this.data.tableDatas.waitTable = true
      this.data.tableDatas.inPageNumber = index
      const payload = {
        inferenceId: this.inferenceId,
        limit: this.data.tableDatas.limit,
        offset: (index - 1) * this.data.tableDatas.limit,
        columnIndex: this.data.body.selectedColumnIndex
      }
      await this.fetchInferenceResultPaging(payload)
      this.setGraphProb()
      this.setImportanceProb()
      this.data.tableDatas.tableColumns = this.resultInputColumns
      this.$nextTick(() => {
        this.data.tableDatas.waitTable = false
      })
    },
    setGraphProb() {
      // 本来は値を初期化すべきだが、列数が多い場合などに、描画時間がかかるため初期化しない
      const probHeads = this.classes.map((c) => c + '_probability')
      const graphProbList = []
      this.predictedTable.forEach((row) => {
        const graphProb = {}
        for (const k in row) {
          if (probHeads.indexOf(k) >= 0) {
            const prop = k.split('_probability')[0]
            graphProb[prop] = row[k]
          }
        }
        graphProbList.push(graphProb)
      })
      this.data.tableDatas.graphProb = graphProbList
    },
    setImportanceProb() {
      // 本来は値を初期化すべきだが、列数が多い場合などに、描画時間がかかるため初期化しない
      const importanceProbList = []

      const featureImportanceColumns =
        this.featureImportance?.input_columns ?? []
      const hasFeatureImportance = featureImportanceColumns?.length > 0

      const inputColumns = hasFeatureImportance
        ? featureImportanceColumns
        : this.requiredColumns

      const importanceHeads = inputColumns.map((c) => c + '_importance')
      this.predictedTable.forEach((row) => {
        const importanceProb = {}

        for (const k in row) {
          if (importanceHeads.indexOf(k) >= 0) {
            const prop = k.split('_importance')[0]
            importanceProb[prop] = row[k]
          }
        }

        importanceProbList.push(importanceProb)
      })

      this.data.tableDatas.importanceProb = importanceProbList
    },
    inputMultiInferenceColumn(e) {
      this.predictionColumn = e.predictionColumn
      this.predictionColumnSetting = e.predictionColumnSetting
    },
    async changeColumn(column) {
      const targetIndex = this.inferencedColumnsBase.indexOf(column)
      if (this.data.body.selectedColumnIndex === targetIndex) return
      if (targetIndex === -1) return

      this.data.body.selectedColumnIndex = targetIndex

      this.data.loadColumnResult = true
      if (
        this.inferenceType === 'time' ||
        this.inferenceType === 'time_transformerV2'
      ) {
        await this.fetchTimeseriesResult({
          id: this.inferenceId,
          targetIndex: targetIndex
        })
        // Observationsなどの時系列特有の情報を取得
        await this.changeTimeseriesTrainedAiData(column)
      } else {
        await this.changePageTableData(this.data.tableDatas.inPageNumber)
      }
      this.data.loadColumnResult = false
    },
    async changeTrainedAiDetail(columnInfo) {
      const trainedAiId = this.trainedAiDetailFullInfo.summary.id

      this.loadColumnInfo = true
      try {
        const trainedAi = await this.fetchTrainedAiDetail({
          id: trainedAiId,
          columnIndex: columnInfo.index,
          threshold: columnInfo.threshold,
          reversePositive: columnInfo.reversePositive
        })

        this.trainedAiDetail = trainedAi.result
        this.trainedAiDetailFullInfo = trainedAi
      } finally {
        this.loadColumnInfo = false
      }
    },
    async firstSetMultiResult(column) {
      const targetIndex = this.inferencedColumnsBase.indexOf(column)
      this.data.body.selectedColumnIndex = targetIndex

      this.data.loadColumnResult = true

      if (this.timeoutID) {
        window.clearTimeout(this.timeoutID)
      }
      this.$gtmDataLayer.sendEvent('inference', 'complete')

      if (
        this.inferenceType === 'time' ||
        this.inferenceType === 'time_transformerV2'
      ) {
        await this.fetchTimeseriesResult({
          id: this.inferenceId,
          targetIndex: targetIndex
        })
        // Observationsなどの時系列特有の情報を取得
        await this.changeTimeseriesTrainedAiData(column)
      } else {
        this.setTableColumns()

        await this.changePageTableData(this.data.tableDatas.inPageNumber)
      }
      this.data.loadColumnResult = false
    },
    async changeTimeseriesTrainedAiData(column) {
      if (this.data.timeseriesSettings?.prediction_column === column) return
      const targetIndex = this.inferencedColumnsBase.indexOf(column)
      this.data.loadTimeseriesTrainedAiData = true
      const res = await this.fetchTrainedAiDetail({
        id: this.inferencedTrainedAiId,
        columnIndex: targetIndex
      })
      this.data.timeseriesSettings = res.result
      this.data.loadTimeseriesTrainedAiData = false
    },
    async checkExpressions({ expressions, columns }) {
      const optimizationInfo = this.data.body.optimizationInfo
      if (optimizationInfo.loadCheckExpressions) return
      optimizationInfo.loadCheckExpressions = true
      const res = await this.fetchValidExpressions({ expressions, columns })
      optimizationInfo.validExpressions = res
      optimizationInfo.loadCheckExpressions = false
    },
    async getOptimizationConditionsList() {
      const optimizationInfo = this.data.body.optimizationInfo
      const trainedAiId = this.selectedItems.trainedAi.id
      if (trainedAiId === optimizationInfo.settedOptimizationConditionsId)
        return
      optimizationInfo.settedOptimizationConditionsId = trainedAiId

      optimizationInfo.loadOptimizationConditionsList = true

      this.data.body.optimizationConditionsList =
        await this.fetchOptimizationConditionsList(trainedAiId)

      optimizationInfo.loadOptimizationConditionsList = false
    },
    async loadOptimizationResult(id, config) {
      if (!config) return
      const targetInfo = this.data.body.optimizationInfo
      targetInfo.download.downloadComp = false

      if (targetInfo.loadOptimizationPaging) return
      targetInfo.loadOptimizationPaging = true

      targetInfo.configItems.optimizationId = id
      targetInfo.configItems.filters = config.filters
      targetInfo.configItems.inPageNumber = config.inPageNumber

      const payload = {
        trainedAiId: this.inferencedTrainedAiId,
        optimizationConditionsId: targetInfo.configItems.optimizationId,
        limit: targetInfo.configItems.limit,
        resultRanges: targetInfo.configItems.filters,
        offset:
          (targetInfo.configItems.inPageNumber - 1) *
          targetInfo.configItems.limit
      }

      await this.fetchOptimizationResult(payload)

      targetInfo.loadOptimizationPaging = false
    },
    async changeOptimizationPage({ id, page }) {
      const config = {
        inPageNumber: page,
        filters: this.data.body.optimizationInfo.configItems.filters
      }
      await this.loadOptimizationResult(id, config)
    },
    async changeOptimizationFilterValue({ id, filter }) {
      const filters = this.data.body.optimizationInfo.configItems.filters
      const checkSettedFilter = filters.findIndex((item) => {
        return item.name === filter.name
      })

      if (checkSettedFilter >= 0) {
        filters[checkSettedFilter] = filter
      } else {
        filters.push(filter)
      }

      const config = {
        inPageNumber: 1,
        filters: filters
      }
      await this.loadOptimizationResult(id, config)
    },
    async inputEditOptimizationForm(obj) {
      const { type, form } = obj

      if (type === 'optimization') {
        this.data.body.optimizationInfo.optimizationFormValidate =
          optimizationConditionsValidator(
            this.executedOptimizationConditions,
            form
          )
      }
    },
    async saveEditOptimizationForm(form) {
      this.data.body.optimizationInfo.loadOptimizationConditionsUpdate = true

      const payload = {
        optimizationConditionsId:
          this.data.body.optimizationInfo.configItems.optimizationId,
        name: form.name,
        description: form.description
      }

      await this.updateOptimzationConditions(payload)

      this.data.body.optimizationInfo.loadOptimizationConditionsUpdate = false
    },
    async downloadResultOptimization(encoding) {
      const optimizationInfo = this.data.body.optimizationInfo
      if (optimizationInfo.download.downloading) return
      optimizationInfo.download.downloading = true
      optimizationInfo.download.downloadComp = false

      // すぐにダウンロードできるものは、黒い背景が一瞬しか見えないので、setTimeoutを入れている
      this.timeoutDownload = window.setTimeout(function () {
        optimizationInfo.download.downloadLoading = true
      }, 100)

      const payload = {
        trainedAiId: this.inferencedTrainedAiId,
        optimizationConditionsId:
          this.optimizationValues.optimizationSettings.id,
        encoding: encoding
      }

      try {
        await this.downloadOptimizationResult(payload)
      } finally {
        optimizationInfo.download.downloading = false
        window.clearTimeout(this.timeoutDownload)
        optimizationInfo.download.downloadLoading = false
        optimizationInfo.download.downloadComp = true
      }
    },
    showPopup(popupName) {
      this.popup.showPopup.push(popupName)
    },
    closePopup(popupName) {
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== popupName)
    },
    closeOptimizationTutorial: async function () {
      this.popup.showTutorial.optimization = false

      const tutorialInfo = Object.assign({}, this.accountInfo.tutorialInfo, {
        optimizationResult: true
      })
      const params = {
        accountId: this.accountInfo.accountId,
        ...tutorialInfo
      }

      await this.updateTutorialInfo(params)
    },
    showOptimizationTutorial() {
      this.popup.showTutorial.optimization = true
    },
    async optimizationStopConfirm() {
      await this.stopOptimizationAsync()
      this.closePopup('stopOptimization')
    },
    resetOptimizationSettings() {
      this.data.body.optimizationInfo.validExpressions = []
      this.selectedItems.optimizationSettings = null
    },
    resetInferenceSettings() {
      this.selectedItems = {
        trainedAi: null,
        dataset: null,
        preprocess: null,
        inferenceType: null,
        inferenceSettings: null,
        optimizationSettings: null,
        noPreprocess: false,
        waitSelectItem: false,
        inferenceEnvironment: 'auto'
      }
      this.popup.showPopup = []
      this.data.body.reset = true
      this.$nextTick(() => {
        this.data.body.reset = false
      })
      /**
       *
      this.$router.go({
        name: 'inferenceProject',
        params: {
          projectId: this.$route.params.projectId
        },
        force: true
      })
       *
       */
    },
    showResult() {
      if (!this.runMultiInference) {
        this.data.showInferenceResult = true
      }
      this.data.runInference = false
    },
    setTableColumns() {
      this.data.tableDatas.tableColumns = this.requiredColumns
    },
    async setFirstProcessResult() {
      // 1ページ目を取得
      this.setTableColumns()
      await this.changePageTableData(1)

      // 結果を表示させる
      this.showResult()
    },
    inputClusteringSetting({ value, type }) {
      const settingTarget = this.data.body.clusteringSetting.setting
      switch (type) {
        case 'changeSort':
          if (settingTarget.sort === value) return
          settingTarget.sort = value
          settingTarget.inPageNumber = 1
          break
        case 'changePage':
          if (settingTarget.inPageNumber === value) return
          settingTarget.inPageNumber = value
          break
      }
      if (value != null && value !== '') {
        this.loadClusteringResult(type)
      }
    },
    async loadClusteringResult(type) {
      const clusteringSetting = this.data.body.clusteringSetting.setting

      this.data.body.clusteringSetting.loadingTable = true

      const offset =
        clusteringSetting.inPageNumber * clusteringSetting.limit -
        clusteringSetting.limit
      const payload = {
        id: this.inferenceId,
        resultId: this.selectedItems?.inferenceSettings?.targetClustering,
        offset,
        limit: clusteringSetting.limit,
        sort: clusteringSetting.sort
      }
      this.data.body.clusteringResult = await this.fetchClusteringResult(
        payload
      )

      if (type === 'changeClass') {
        const distributionTarget =
          this.data.body.clusteringSetting.distributionSetting

        const array = [...Array(3)].map((_, i) => i)
        await Promise.all(
          array.map(async (index) => {
            if (
              distributionTarget[index].xTarget != null &&
              distributionTarget[index].yTarget != null
            ) {
              const func = await this.loadClusteringDistribution(index)
              return func
            } else {
              return null
            }
          })
        )
      }
      this.data.body.clusteringSetting.loadingTable = false
    },
    changeClusteringDistributionColumn({ value, target, index }) {
      const clusteringSetting = this.data.body.clusteringSetting

      const firstCheck =
        clusteringSetting.distributionSetting[index][target] !== value
      clusteringSetting.distributionSetting[index][target] = value

      if (
        clusteringSetting.distributionSetting[index].xTarget == null &&
        clusteringSetting.distributionSetting[index].yTarget == null
      )
        return

      let checkX = false
      let checkY = false
      if (clusteringSetting.setting.showDimension) {
        checkX = this.data.body.clusteringInfo.dimensionList.includes(
          clusteringSetting.distributionSetting[index].xTarget
        )
        checkY = this.data.body.clusteringInfo.dimensionList.includes(
          clusteringSetting.distributionSetting[index].yTarget
        )
      } else {
        checkX = this.data.body.clusteringInfo.distributionInputList.includes(
          clusteringSetting.distributionSetting[index].xTarget
        )
        checkY = this.data.body.clusteringInfo.distributionInputList.includes(
          clusteringSetting.distributionSetting[index].yTarget
        )
      }

      if (checkX && checkY && firstCheck) {
        this.loadClusteringDistribution(index)
      }
    },
    toggleClusteringShowDimension(bool) {
      this.data.body.clusteringSetting.setting.showDimension = bool
      // 次元を表示するかどうかで、散布図の表示時に選択できる項目がかわるので、散布図の表示をリセットする
      this.data.body.clusteringDistributions.splice(0)
      this.data.body.clusteringSetting.distributionSetting =
        this.data.body.clusteringSetting.distributionSetting.map((setting) => {
          return {
            xTarget: null,
            yTarget: null
          }
        })
    },
    async loadClusteringDistribution(index) {
      const clusteringSetting = this.data.body.clusteringSetting

      this.data.body.clusteringSetting.loadingDistribution = true

      const payload = {
        id: this.inferenceId,
        resultId: this.selectedItems?.inferenceSettings?.targetClustering,
        xTarget: clusteringSetting.distributionSetting[index].xTarget,
        yTarget: clusteringSetting.distributionSetting[index].yTarget
      }
      const res = await this.fetchClusteringDistribution(payload)
      this.$set(this.data.body.clusteringDistributions, index, res)

      this.data.body.clusteringSetting.loadingDistribution = false
    },
    async downloadClustering({ type, encoding }) {
      const clusteringSetting = this.data.body.clusteringSetting
      if (clusteringSetting.download.downloading) return
      clusteringSetting.download.downloading = true
      clusteringSetting.download.downloadComp = false

      const payload = {
        id: this.inferenceId,
        encoding: encoding
      }

      if (type === 'filtered') {
        payload.resultId = clusteringSetting.setting.resultId
      }

      try {
        await this.downloadClusteringResult({ type, payload })
      } finally {
        clusteringSetting.download.downloading = false
        clusteringSetting.download.downloadLoading = false
        clusteringSetting.download.downloadComp = true
      }
    }
    /**
     * TODO 推論実行時のテキストマイニングの結果取得実装後修正
    // テキストマイニングの情報をリセット
    resetTextMining() {
      this.data.body.textMiningResult = textMiningResultSetting()
      this.data.body.textMiningSetting = {}

      // 自然言語でテキストマイニングの表示がある場合表示を固定する
      // TODO テスト用 selectedItems.trainedAi.textMiningでチェックを行うようにする
      this.data.body.textMiningSetting =
        this.trainedAiDetailFullInfo.summary?.textMining
    },
    // テキストマイニングのパラメータの初期値設定
    setTextMiningParams() {
      const body = this.data.body.textMiningResult
      body.columns = this.data.body.textMiningSetting?.columns ?? []
      const params = body.params

      // 感情分析がある場合は、ソート条件を感情分析の信頼度にする
      if (
        this.data.body.textMiningSetting.typeList[TEXT_MINING_TYPE.SENTIMENTS]
      ) {
        params[TEXT_MINING_PAGE_TYPE.TEXT_LIST].sentiments = 'negative'
        params[TEXT_MINING_PAGE_TYPE.TEXT_LIST].sortTarget = 'proba'
      }

      // CSVでの学習の場合自然言語の列の0番目を取得する情報の初期値としてセットする
      if (body.columns && body.columns.length > 0) {
        params.columnName = body.columns[0]
      }
    },
    // 各種テキストマイニングの結果取得
    async loadTextMining({ pageType, value, columnName }) {
      const textMiningTarget = this.data.body.textMiningResult

      textMiningTarget.waitTextMiningLoading = true

      // 頻度表示またはワードクラウドの画面で列名以外の変更があった場合はアクションを叩かない
      if (
        pageType === TEXT_MINING_PAGE_TYPE.FREQUENCIES_WORDCLOUD &&
        value.count !== textMiningTarget.params[pageType].count
      ) {
        textMiningTarget.params[pageType] = value
        textMiningTarget.waitTextMiningLoading = false
        return
      }

      // テキストごとのトピックを見る際にどのテキストを使用するのか、指定がない場合は0番目のテキストの結果をパラメータにする
      if (
        pageType === TEXT_MINING_PAGE_TYPE.TEXT_TOPIC &&
        value.targetName == null
      ) {
        if (
          textMiningTarget[TEXT_MINING_PAGE_TYPE.TEXT_LIST]?.datas == null ||
          textMiningTarget[TEXT_MINING_PAGE_TYPE.TEXT_LIST].datas.length === 0
        ) {
          const textListLoadTypes =
            TEXT_MINING_PAGE_TYPE_TO_TYPE[TEXT_MINING_PAGE_TYPE.TEXT_LIST]
          const params =
            textMiningTarget.params[TEXT_MINING_PAGE_TYPE.TEXT_LIST]

          const textList = await this.getInferencedTextMining({
            loadType: textListLoadTypes[0],
            payload: {
              ...params,
              columnName,
              id: this.trainedAIId
            }
          })
          textMiningTarget[TEXT_MINING_PAGE_TYPE.TEXT_LIST] = textList
        }
        value.targetName =
          textMiningTarget[TEXT_MINING_PAGE_TYPE.TEXT_LIST].datas[0].fileName
      }

      textMiningTarget.params[pageType] = value
      textMiningTarget.params.columnName = columnName

      const loadTypes = TEXT_MINING_PAGE_TYPE_TO_TYPE[pageType]

      for (const loadType of loadTypes) {
        // パラメータに変更がない場合かつ、該当の結果がある場合、アクションを叩かない
        if (
          columnName === textMiningTarget.params.columnName &&
          !(textMiningTarget[loadType] == null)
        ) {
          const checkDifferent = Object.entries(textMiningTarget.params[pageType]).some(([key, val]) => {
            return value[key] !== val
          })
          if (!checkDifferent) continue
        }
        let res = null
        // 共起ネットワークは学習済みのものでしか作成できないので、学習済みAIのものを叩く
        if (pageType === 'coOccurrence') {
          res = await this.getTrainedTextMining({
            loadType,
            payload: {
              ...value,
              columnName,
              id: this.inferencedTrainedAiId
            }
          })
        } else {
          res = await this.getInferencedTextMining({
            loadType,
            payload: {
              ...value,
              columnName,
              id: this.inferenceId
            }
          })
        }
        textMiningTarget[loadType] = res
      }
      textMiningTarget.params[pageType] = value
      textMiningTarget.params.columnName = columnName
      textMiningTarget.waitTextMiningLoading = false
    },
    // テキストマイニングのサイドバーの切り替え
    changeTabTextMining(pageType) {
      this.data.body.textMiningResult.activeTab = pageType
    }
    */
  },
  async mounted() {
    this.data.loadingPage = true

    await this.fetchAccountInfo()

    const projectId = this.$route?.params?.projectId
    if (projectId) {
      await this.loadProjectDetail(parseInt(projectId))
    } else {
      throw new Error('deprecated')
    }
    const trainedAiId = this.$route?.query?.trainedAiId
    const datasetId = this.$route?.query?.datasetId
    const isOptimization = this.$route?.query?.isOptimization
    let dataset
    if (datasetId) {
      dataset = { upload: false, id: datasetId }
    }
    if (trainedAiId || datasetId) {
      await this.getSelectedItems({ trainedAiId: trainedAiId, dataset })
    }
    if (isOptimization) {
      this.data.body.optimizationInfo.firstSelectOptimization = true
    }

    // 再入場用処理
    const inferenceId = this.$route.params.inferenceId
    if (inferenceId) {
      if (this.$route.name === 'inferenceResult') {
        // 学習済みAIから来た場合
        await this.getInferenceResult({ inferenceId })
        await this.getSelectedItems({ trainedAiId: this.inferencedTrainedAiId })
        await this.makeRequiredColumnsList(this.inferencedTrainedAiId)

        await this.setInferenceInfoForInferenceResult({
          predictionColumn: this.predictionColumn,
          inferencedTrainedAiId: this.inferencedTrainedAiId,
          baseTrainedAiPredictionColumns: this.baseTrainedAiPredictionColumns
        })

        this.beforeInference()
        console.log({
          predictionColumn: this.predictionColumn,
          inferencedTrainedAiId: this.inferencedTrainedAiId,
          baseTrainedAiPredictionColumns: this.baseTrainedAiPredictionColumns
        })
        await this.fetchTimeseriesResult({
          id: this.inferenceId,
          targetIndex: 0
        })
        this.afterInference()
      } else {
        await this.getReenterInferenceInfo(inferenceId)
        this.reenterInferenceStart(inferenceId)
      }
    }

    this.cancelWatchProgress()
    this.data.loadingPage = false
  },
  watch: {
    async finishColumnsFix(newVal) {
      if (
        newVal.length > 0 &&
        (this.forecast == null || this.forecast.length === 0) &&
        !this.waitTimeseriesResultFirstTime &&
        (this.data?.tableDatas?.graphProb == null ||
          this.data.tableDatas.graphProb.length === 0)
      ) {
        this.waitTimeseriesResultFirstTime = true
        await this.firstSetMultiResult(newVal[0])
        this.waitTimeseriesResultFirstTime = false
      }
    },
    optimizationResult: {
      async handler(newVal) {
        if (newVal.optimizationConditionsId) {
          const optimizationInfo = this.data.body.optimizationInfo.configItems
          optimizationInfo.optimizationId = newVal.optimizationConditionsId
          const config = {
            inPageNumber: 1,
            filters: []
          }
          await this.loadOptimizationResult(
            optimizationInfo.optimizationId,
            config
          )
        }
      },
      deep: true
    },
    isOptimizationStopped(newVal) {
      this.popup.disableClick = newVal
    }
  },
  destroyed() {
    this.reset()
  }
}
</script>
