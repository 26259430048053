<template>
  <div class="no-training-data-wrap">
    <div class="no-training-data-inner">
      <texts
        class="no-training-data-inner-text"
        :text="$t('training.popup.noTrainingData.text')"
      />
    </div>
    <div class="no-training-data-button-list">
      <button-main
        :text="$t('training.popup.noTrainingData.goDatasetSetting')"
        type="emphasis"
        @click="goDatasetSetting"
      />
      <button-main
        :text="$t('common.close')"
        type="sub"
        @click="$emit('close-modal', 'noTrainingData')"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import texts from '@/components/atoms/text'
import ButtonMain from '@/components/atoms/button-main'

export default {
  components: {
    texts,
    ButtonMain
  },
  computed: {
    projectId() {
      return parseInt(this.$route.params.projectId)
    }
  },
  methods: {
    ...mapActions('models', ['setProgressTraining']),

    goDatasetSetting() {
      this.setProgressTraining({
        item: this.projectId,
        setType: 'project'
      })

      this.$router.push({
        name: 'datasetSetting',
        params: {
          projectId: this.projectId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.no-training-data {
  &-wrap {
    width: 100%;
  }
  &-inner {
    margin: 0 0 $space-medium;
    &-text {
      white-space: pre-line;
    }
  }
  &-button-list {
    width: 100%;
  }
}
</style>
