<template>
  <div class="desc">
    <texts
      :ref="'itemDesc' + index"
      class="desc-inner"
      :text="description"
      size="small"
      noTips
    />
    <div v-if="!loadingTips" v-tooltip="tips" class="desc-tips" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingTips: false,
      tips: null
    }
  },
  props: {
    /** 表示するテキスト */
    description: String,
    /** 複数画面内にこのコンポーネントがあるとrefがダブるのでindexを付与 */
    index: {
      type: Number,
      default: 0
    }
  },
  methods: {
    descTooltip(index, text) {
      const checkTarget = this.$refs['itemDesc' + index].$el
      const textHeight = checkTarget.clientHeight
      const lineHeight = checkTarget.scrollHeight
      if (lineHeight > textHeight) {
        return {
          content: text,
          trigger: 'hover',
          delay: { show: 200, hide: 200 }
        }
      } else {
        return null
      }
    }
  },
  mounted() {
    this.loadingTips = true
    this.$nextTick(() => {
      this.tips = this.descTooltip(this.index, this.description)
      this.loadingTips = false
    })
  }
}
</script>

<style lang="scss" scoped>
.desc {
  position: relative;
  width: fit-content;
  max-width: 100%;
  &-inner {
    overflow: hidden;
    display: -webkit-box;
    word-break: break-all;
    white-space: pre-line;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    box-orient: vertical;
  }
  &-tips {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
