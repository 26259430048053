<template>
  <div class="c-wrap">
    <sidebar-project-detail
      v-if="!notFound || trainedAILoading"
      v-bind="sidebar"
    />
    <sidebar v-else />
    <div class="c-body-wrap">
      <header-tabs v-bind="headerTabs" />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="trainedAILoading" />
          <page-not-found v-else-if="notFound" />
          <trained-ai-list-main
            v-else
            v-bind="body"
            :accountInfo="accountInfo"
            :trainedAIs="trainedAIs"
            :trainedAILoading="trainedAILoading"
            :allTrainedAILoading="allTrainedAILoading"
            :project="project"
            :projectId="projectId"
            @add-new-trained-ai="$emit('add-new-trained-ai')"
            @layout-menu-click="$emit('layout-menu-click', $event)"
            @delete-menu-click="$emit('delete-menu-click', $event)"
            @project-menu-click="$emit('project-menu-click', $event)"
            @inference-menu-click="$emit('inference-menu-click', $event)"
            @service-menu-click="$emit('service-menu-click', $event)"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="disableClick" />
    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
    <trained-ai-list-popup
      v-bind="popup"
      :project="project"
      :disableClick="disableClick"
      @close-modal="$emit('close-modal', $event)"
      @cancel-delete="$emit('cancel-delete')"
      @add-to-project="$emit('add-to-project', $event)"
      @delete="$emit('delete', $event)"
      @check-service="$emit('check-service')"
    />
  </div>
</template>

<script>
import sidebarProjectDetail from '@/components/organisms/sidebar-project-detail.vue'
import Sidebar from '@/components/organisms/sidebar.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import trainedAiListMain from '@/components/organisms/trained-ai-list/trained-ai-list-main.vue'
import trainedAiListPopup from '@/components/organisms/trained-ai-list/trained-ai-list-popup'
import transitionPage from '@/components/molecules/transition-page'
import loading from '@/components/atoms/loading.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import TransitionToggleContents from '../molecules/transition-toggle-contents.vue'
import pageNotFound from '@/components/organisms/page-not-found'

export default {
  components: {
    sidebarProjectDetail,
    Sidebar,
    hoverMenu,
    headerTabs,
    trainedAiListMain,
    trainedAiListPopup,
    transitionPage,
    loading,
    loadingProcessing,
    TransitionToggleContents,
    pageNotFound
  },
  props: {
    accountInfo: Object,
    sidebar: Object,
    headerTabs: Object,
    body: Object,
    popup: Object,
    deleteTest: Object,
    project: Object,
    projectId: Number,
    trainedAIs: Object,
    trainedAILoading: Boolean,
    allTrainedAILoading: Boolean,
    notFound: Boolean,
    disableClick: Boolean
  }
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
}
</style>
