<template>
  <div v-if="expectedEffect" class="trained-ai-expected-effect">
    <div class="trained-ai-expected-effect-main">
      <div class="trained-ai-detail-title-sub">
        <texts text="このAIに期待される効果" size="large" />
      </div>
      <div class="trained-ai-expected-effect-description">
        <description-long
          text="AIに期待される効果は、AIの予測・分類結果をもとに、\n一般的な退職率の計算方法から算出されています。"
        />
      </div>
      <div class="trained-ai-expected-effect-image">
        <retiree />
      </div>
    </div>
    <div class="trained-ai-expected-effect-calculate">
      <div class="trained-ai-detail-title-sub">
        <texts text="期待される効果の計算式" size="large" />
      </div>
      <div class="trained-ai-expected-effect-description">
        <description-long
          text="退職する可能性のある人を予測するこのAIを使用して、実際に施策を行った場合、\nどの程度退職率を削減できるのか、期待値を計算しています。\n数値を変更して、ご自身に合わせた期待される効果を計算しましょう。"
        />
      </div>
      <div class="trained-ai-expected-effect-calculate-list">
        <div
          v-for="(calculateItem, index) in expectedEffect.calculateItems"
          :key="index"
          class="trained-ai-expected-effect-calculate-item"
        >
          <input-box
            v-model="calculateItem.count"
            :title="calculateItem.title"
            isGray
          />
        </div>
      </div>
      <div class="trained-ai-expected-effect-calculate-toggle-button">
        <button-toggle
          openText="計算式の詳細を開く"
          closeText="計算式の詳細を閉じる"
        >
          <div class="trained-ai-expected-effect-calculate-detail">
            <div class="trained-ai-expected-effect-calculate-detail-target">
              {{ expectedEffect.calculateTarget }}
            </div>
            <div class="trained-ai-expected-effect-calculate-detail-formula">
              {{ expectedEffect.calculateDetail }}
            </div>
          </div>
        </button-toggle>
      </div>
      <button-main text="計算式を保存する" type="emphasis" />
    </div>
  </div>
  <div v-else class="trained-ai-expected-effect-none">
    <!-- 画像張るスペースのみとっておく -->
    <images
      class="trained-ai-expected-effect-none-image"
      imageName="nowDeveloping"
    />
    <texts
      class="trained-ai-expected-effect-none-text"
      :text="$t('trainedAiDetails.expectedEffect.notImplemented')"
    />
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import images from '@/components/atoms/images'
import descriptionLong from '@/components/atoms/description-long'
import buttonMain from '@/components/atoms/button-main'
import inputBox from '@/components/molecules/input-box'
import buttonToggle from '@/components/molecules/button-toggle'
import retiree from '../trained-ai-detail-expected-effect/retiree'

export default {
  components: {
    texts,
    images,
    descriptionLong,
    buttonMain,
    inputBox,
    buttonToggle,
    retiree
  },
  props: {
    expectedEffect: Object
  }
}
</script>

<style lang="scss" scoped>
.trained-ai-expected-effect {
  display: grid;
  grid-template-columns: adjustVW(804) adjustVW(804);
  grid-column-gap: $space-medium;
  padding: 0 $space-medium $space-medium;
  &-none {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: adjustVW(804);
    height: fit-content;
    padding: $space-small;
    margin-left: $space-medium;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    &-image {
      width: adjustVW(400);
      height: auto;
      margin-bottom: $space-large;
    }
    &-text {
      margin-bottom: $space-small;
    }
  }
  &-main {
    height: fit-content;
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
  }
  &-calculate {
    height: fit-content;
    padding: $space-small;
    background: $background;
    border-radius: adjustVW(16);
    box-shadow: $box-shadow-main;
    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 0 $space-sub;
    }
    &-item {
      width: calc(33% - #{adjustVW(12)});
      margin: 0 0 $space-small;
    }
    &-detail {
      display: flex;
      padding: $space-small;
      margin: 0 0 $space-small;
      background: $background-sub;
      font-size: $text-min;
      border-radius: adjustVW(16);
      @include text-crop;
      &-target {
        white-space: pre;
      }
    }
  }
  &-description {
    margin: 0 0 $space-small;
  }
}
</style>
