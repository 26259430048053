<template>
  <transition-toggle-contents>
    <trained-ai-detail-tab-detail
      v-if="activeTab === 'detail'"
      key="detail"
      :trainedAi="trainedAi"
      :finishColumns="finishColumns"
      :loadChangeColumn="loadChangeColumn"
      :selectedColumnIndex="selectedColumnIndex"
      pageName="timeseries"
      @change-column="$emit('change-column', $event)"
    />
    <trained-ai-detail-tab-column-info
      v-else-if="activeTab === 'columnInfo'"
      key="columnInfo"
      :finishColumns="finishColumns"
      :trainedAi="trainedAi"
      :loadChangeColumn="loadChangeColumn"
      :selectedColumnIndex="selectedColumnIndex"
      @change-column="$emit('change-column', $event)"
    />
    <trained-ai-detail-tab-expected-effect
      v-else-if="activeTab === 'expectedEffect'"
      key="expectedEffect"
      :expectedEffect="trainedAi.expectedEffect"
    />
    <trained-ai-detail-tab-feature-importance
      v-else-if="activeTab === 'featureImportance'"
      key="featureImportance"
      :trainedAi="trainedAi"
      :finishColumns="finishColumns"
      :selectedColumnIndex="selectedColumnIndex"
      :loadChangeColumn="loadChangeColumn"
      @load-feature-importance="$emit('load-feature-importance')"
      @change-column="$emit('change-column', $event)"
    />
    <trained-ai-detail-tab-timeseries
      v-else-if="activeTab === 'accuracy'"
      key="accuracy"
      :trainedAi="trainedAi"
      :metricsDefs="metricsDefs"
      :finishColumns="finishColumns"
      :loadChangeColumn="loadChangeColumn"
      :selectedColumnIndex="selectedColumnIndex"
      pageName="timeseries"
      @change-column="$emit('change-column', $event)"
    />
    <trained-ai-detail-tab-trained-info
      v-else-if="activeTab === 'trainedInfo'"
      key="trainedInfo"
      :trainedAi="trainedAi"
      :metricsDefs="metricsDefs"
      :loadChangeColumn="loadChangeColumn"
      pageName="timeseries"
    />
    <trained-ai-detail-tab-dependency
      v-else-if="activeTab === 'dependency'"
      key="dependency"
      pageName="dependency"
      :trainedAi="trainedAi"
      :selectedColumnIndex="selectedColumnIndex"
      @load-inference-info="$emit('load-inference-info', $event)"
      @show-inference="$emit('show-inference', $event)"
    />
    <trained-ai-detail-tab-inference
      v-else-if="activeTab === 'inferenceResults'"
      key="inferenceResults"
      pageName="inferenceResults"
      :trainedAi="trainedAi"
      :inferenceListInfo="inferenceListInfo"
      @load-inference-info="$emit('load-inference-info', $event)"
      @show-inference="$emit('show-inference', $event)"
    />
  </transition-toggle-contents>
</template>

<script>
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import trainedAiDetailTabDetail from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-detail'
import trainedAiDetailTabExpectedEffect from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-expected-effect'
import trainedAiDetailTabTimeseries from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-timeseries'
import trainedAiDetailTabTrainedInfo from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-trained-info'
import trainedAiDetailTabColumnInfo from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-column-info'
import trainedAiDetailTabFeatureImportance from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-feature-importance-mft2'
import trainedAiDetailTabDependency from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-dependency'
import trainedAiDetailTabInference from '../trained-ai-detai-tab-inner/trained-ai-detail-tab-inference'
import { timeseries } from '../metricsDefs'

export default {
  components: {
    transitionToggleContents,
    trainedAiDetailTabDetail,
    trainedAiDetailTabExpectedEffect,
    trainedAiDetailTabFeatureImportance,
    trainedAiDetailTabTimeseries,
    trainedAiDetailTabTrainedInfo,
    trainedAiDetailTabColumnInfo,
    trainedAiDetailTabDependency,
    trainedAiDetailTabInference
  },
  props: {
    trainedAi: Object,
    tabs: Array,
    menus: Array,
    activeTab: String,
    activeMenu: String,
    pageName: String,
    finishColumns: Array,
    loadChangeColumn: Boolean,
    selectedColumnIndex: Number,
    inferenceListInfo: Object
  },
  computed: {
    metricsDefs() {
      return timeseries(this.$t.bind(this))
    }
  }
}
</script>
