<template>
  <div class="wrap">
    <div class="desc">
      <description
        :text="$t('inference.popup.environmentSetting.description')"
      />
    </div>
    <div class="select">
      <select-box v-model="internalValue" isGray :items="types" />
    </div>
    <button-main
      type="emphasis"
      :text="$t('common.close')"
      @click="$emit('close-modal')"
    />
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main.vue'
import description from '@/components/atoms/description'
import selectBox from '@/components/molecules/select-box.vue'

export default {
  components: {
    buttonMain,
    description,
    selectBox
  },
  props: {
    value: {
      type: String,
      default: 'auto'
    }
  },
  computed: {
    types() {
      return [
        {
          id: 0,
          name: this.$t('inference.popup.environmentSetting.auto'),
          value: 'auto'
        },
        {
          id: 1,
          name: this.$t('inference.popup.environmentSetting.batch'),
          value: 'batch'
        },
        {
          id: 2,
          name: this.$t('inference.popup.environmentSetting.lambda'),
          value: 'lambda'
        }
      ]
    },
    internalValue: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('inference-environment-set', newVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.desc {
  margin-bottom: $space-small;
}

.select {
  max-width: adjustVW(320);
  margin-bottom: $space-medium;
}
</style>
