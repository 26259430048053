<template>
  <label
    class="wrap"
    :class="{ 'wrap-active': active }"
    draggable="true"
    @dragenter.prevent="onDragEnter"
    @dragover.prevent="onDragOver"
    @dragleave.prevent="onDragLeave"
    @drop.prevent="onDrop"
  >
    <transition-toggle-contents name="toggle-contents" mode="out-in">
      <div v-if="!active" key="off" class="inner">
        <div class="icon">
          <icons iconName="detail" size="huge" color="default" />
        </div>
        <div class="block">
          <div class="text">
            <texts
              :text="$t('datasetList.popup.addDataset.file.dragDrop')"
              isBold
            />
          </div>
          <div class="text-min">
            <texts
              :text="$t('datasetList.popup.addDataset.file.or')"
              size="small"
              isBold
            />
          </div>
          <div class="input">
            <input
              type="file"
              :accept="accept"
              style="display: none"
              @change="inputFile($event.target.files[0])"
            >
            <texts
              :text="$t('datasetList.popup.addDataset.file.selectFile')"
              color="link"
              isBold
            />
          </div>
          <div v-if="errors.length" class="error">
            <texts
              v-for="(error, index) in errors"
              :key="index"
              class="error-text"
              :text="error"
              color="caution"
              size="small"
            />
          </div>
        </div>
      </div>

      <div v-else key="on" class="inner">
        <div class="icon">
          <icons iconName="detail" size="huge" color="emphasis" />
        </div>
        <div class="block">
          <transition-toggle-contents name="toggle-contents" mode="in-out">
            <div v-if="!drag" class="text">
              <texts :text="trimFileName" size="large" />
            </div>
          </transition-toggle-contents>
        </div>
        <div v-if="errors.length" class="error">
          <texts
            v-for="(error, index) in errors"
            :key="index"
            class="error-text"
            color="caution"
            size="small"
          >
            <i18n :path="`datasetList.errors.${error.type}`" tag="div">
              <template v-slot:file>
                {{ error.file }}
              </template>
              <template v-slot:manual>
                <a
                  :href="$urls.manual + 'project-dataset'"
                  target="_blank"
                  rel="noopener noreferrer"
                >{{ $t('datasetList.links.manual.text') }}</a>
              </template>
            </i18n>
          </texts>
        </div>
        <input
          type="file"
          :accept="accept"
          style="display: none"
          @change="inputFile($event.target.files[0])"
        >
      </div>
    </transition-toggle-contents>
  </label>
</template>

<script>
import texts from '@/components/atoms/text'
import icons from '@/components/atoms/icon'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'

export default {
  components: {
    texts,
    icons,
    transitionToggleContents
  },
  data() {
    return {
      /** ドラッグアンドドロップの領域に入ったとき（何度もover leaveのイベントが走らないようにするため） */
      enter: false,
      /** ドラッグアンドドロップが続いているとき */
      drag: false
    }
  },
  model: {
    prop: 'value',
    event: 'on-file-input'
  },
  props: {
    /** v-modelで渡す値 */
    value: File,
    /** エラー時に表示する内容 */
    errors: {
      type: Array,
      default: () => [],
      require: false
    },
    /** 入力可能なファイルの拡張子、MIME タイプの指定 */
    accept: {
      type: [String, Array],
      default: () => ['.zip', '.csv']
    }
  },
  computed: {
    active() {
      return this.drag || this.fileName
    },
    fileName() {
      return this.value?.name
    },
    trimFileName() {
      if (this.fileName && this.fileName.length > 12) {
        return this.fileName.substr(0, 12) + '...'
      }
      return this.fileName
    }
  },
  methods: {
    inputFile(e) {
      // ファイルをインポートした際の処理
      if (!e) return
      this.$emit('on-file-input', e)
    },
    /** ドラッグアンドドロップを開始したとき */
    onDragEnter() {
      this.enter = true
    },
    /** ドラッグアンドドロップ領域にホバーしてる時 */
    onDragOver(e) {
      const types = [...e.dataTransfer.types] // DomStringList to Array for IE or Edge
      if (types.includes('Files')) {
        e.preventDefault()
        e.dataTransfer.dropEffect = 'copy'
        this.enter = false // enterのフラグを消す
        this.drag = true
      } else {
        e.dataTransfer.dropEffect = 'none'
      }
    },
    onDragLeave() {
      if (this.value) {
        // すでにファイルをinputしている場合
        if (this.enter) {
          // enterフラグがある場合はフラグを消す
          this.enter = false
          return false
        } else {
          // enterフラグがない場合はドラッグ状態だけを外す
          this.drag = false
          return false
        }
      }
      if (this.enter) {
        // enterフラグがある場合はフラグを消すのみ
        this.enter = false
      } else {
        this.drag = false
      }
    },
    onDrop(event) {
      this.drag = false
      const files = event.dataTransfer.files

      const fileName = files[0].name
      const fileType = files[0].type
      const fileExt = '.' + fileName.split('.').pop().toLowerCase()

      const includeAcceptList =
        this.accept.includes(fileExt) || this.accept.includes(fileType)

      if (includeAcceptList) this.$emit('on-file-input', files[0])
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: adjustVH(320);
  background: $background-sub;
  border-radius: adjustVW(16);
  cursor: pointer;
  transition: background-color 0.3s;
  &-active {
    background-color: $background-decoration;
  }
}
.icon {
  margin: 0 0 $space-small;
  transition: all 0.3s;
}
.text {
  margin: 0 0 $space-base;
  &-min {
    margin: 0 0 $space-base;
  }
}
.block {
  width: 100%;
  margin: 0 0 $space-text;
  text-align: center;
}
.error {
  margin-top: $space-small;
  &-text {
    margin-bottom: $space-base;
    white-space: pre-line;
    > div {
      text-align: center;
    }
    &:last-of-type {
      margin: 0;
    }
  }
}
</style>
