<template>
  <div class="wrap">
    <div class="items-wrap">
      <list-item :items="services.items" :field="field" isToggle>
        <template v-slot:toggleContent="{ entry }">
          <div class="title-wrap">
            <div>
              <description :text="entry.description" />
            </div>
          </div>
          <div v-if="entry.trainedAI || entry.preprocessing" class="block">
            <div v-if="entry.trainedAI" class="inner">
              <text-with-title
                :title="$t('serviceList.header.trainedAI')"
                :text="entry.trainedAI.name"
              />
            </div>
            <div v-if="entry.preprocessing" class="inner">
              <text-with-title
                :title="
                  $t('serviceList.popup.addNewService.PreprocessingToUse')
                "
                :text="entry.preprocessing.name"
              />
            </div>
          </div>
          <div class="block">
            <div class="inner">
              <text-with-title :title="$t('common.createTime')">
                <a-time :value="entry.createdAt" />
              </text-with-title>
            </div>
            <div class="inner">
              <text-with-title :title="$t('common.updateTime')">
                <a-time :value="entry.updatedAt" />
              </text-with-title>
            </div>
          </div>
        </template>
      </list-item>
    </div>
    <div class="footer">
      <div class="button">
        <button-main
          type="sub"
          :text="$t('common.cancel')"
          :disabled="disableClick"
          @click="$emit('cancel')"
        />
      </div>
      <div class="button">
        <button-main
          type="caution"
          :text="$t('common.deleteButton')"
          :disabled="disableClick"
          @click="deleteService"
        />
      </div>
    </div>
  </div>
</template>

<script>
import description from '@/components/atoms/description'
import buttonMain from '@/components/atoms/button-main'
import textWithTitle from '@/components/molecules/text-with-title'
import listItem from '@/components/molecules/list-item'

export default {
  name: 'ServiceDeletePopup',
  components: {
    description,
    buttonMain,
    textWithTitle,
    listItem
  },
  props: {
    services: Object,
    disableClick: Boolean
  },
  methods: {
    deleteService() {
      this.$emit('delete-services', this.services.items)
    }
  },
  computed: {
    field() {
      return [
        {
          key: 'name',
          label: this.$t('serviceList.popup.deleteService.field')
        },
        {
          key: 'caution',
          label: '',
          isCaution: true
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: adjustVW(720);
  height: 100%;
  margin: $space-base 0 0;
  .items-wrap {
    overflow-y: scroll;
    padding: 0 $space-small 0 0;
    margin: 0 0 $space-medium;
    @include scrollbar;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
  }
  .button {
    margin: 0 $space-medium 0 0;
    &:last-of-type {
      margin: 0;
    }
  }
}
.block {
  display: flex;
  margin: 0 0 $space-medium;
  &:last-of-type {
    margin: 0;
  }
}
.inner {
  overflow: hidden;
  width: 100%;
  margin: 0 $space-small 0 0;
  &:last-child {
    margin: 0;
  }
}
.title-wrap {
  margin: -$space-base 0 $space-medium;
}
</style>
