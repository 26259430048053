<template>
  <div
    class="inference-vectorization-result"
    :class="{
      'inference-vectorization-result-text-mining': showTextMining
    }"
  >
    <div class="inference-vectorization-result-title-wrap">
      <transition-toggle-contents name="toggle-detail" mode="out-in">
        <!--
          TODO 推論実行時のテキストマイニングの結果取得実装後修正
        <div
          v-if="showTextMining"
          key="text-mining"
          class="inference-vectorization-result-title-inner"
        >
          <texts
            class="inference-vectorization-result-title"
            :text="$t('inference.result.dimRed.textMining')"
          />
        </div>
        -->
        <div
          v-if="showDimRed"
          key="dimRed"
          class="inference-vectorization-result-title-inner"
        >
          <texts
            class="inference-vectorization-result-title"
            :text="this.$i18n.t('inference.result.dimRed.title')"
          />
        </div>
        <div
          v-else
          key="vectorization"
          class="inference-vectorization-result-title-inner"
        >
          <texts
            class="inference-vectorization-result-title"
            :text="this.$i18n.t('inference.result.vectorization.title')"
          />
          <paging
            v-model="inPageNumber"
            class="inference-regression-result-main-card-paging"
            :count="vectorizationResult.length"
            :pagingCount="pagingCount"
          />
        </div>
      </transition-toggle-contents>
    </div>
    <transition-toggle-contents>
      <!--
        TODO 推論実行時のテキストマイニングの結果取得実装後修正
      <text-mining-result
        v-if="showTextMining"
        :textMiningResult="textMiningResult"
        :textMiningSetting="textMiningSetting"
        @load-text-mining="$emit('load-text-mining', $event)"
        @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
      />
      -->
      <div class="inference-vectorization-result-wrapper">
        <div class="inference-vectorization-result-main">
          <transition name="toggle-detail" mode="out-in">
            <vectorization-result-card-list
              v-if="!waitTable && !showDimRed"
              :targetType="targetType"
              :items="cropedItems ? cropedItems : cropItems[0]"
              :allItems="vectorizationResult"
              :posBottomPopupBorder="posBottomPopupBorder"
            />
            <card-base
              v-else-if="showDimRed && targetType === 'image'"
              class="inference-vectorization-dim-red-result"
            >
              <dimensionality-reduction-graph
                :showImage="true"
                :result="dimRedResult"
              />
            </card-base>
          </transition>
        </div>

        <div class="inference-vectorization-result-used-ai">
          <card-base class="inference-vectorization-result-used-ai-card">
            <model-info
              :title="this.$i18n.t('inference.result.card.usedAi')"
              :modelInfo="selectedTrainedAi"
            />
          </card-base>
        </div>
      </div>
    </transition-toggle-contents>
  </div>
</template>

<script>
import cardBase from '@/components/atoms/card-base.vue'
import modelInfo from '@/components/organisms/model-info.vue'
import texts from '@/components/atoms/text.vue'
import Paging from '@/components/molecules/paging.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import vectorizationResultCardList from './vectorization-result-card-list.vue'
import DimensionalityReductionGraph from '@/components/organisms/inference/result/dimensionality-reduction/dimensionality-reduction-graph.vue'
// import textMiningResult from '@/components/organisms/inference/result/text-mining/text-mining-result'

export default {
  components: {
    cardBase,
    modelInfo,
    texts,
    Paging,
    transitionToggleContents,
    vectorizationResultCardList,
    DimensionalityReductionGraph
    // textMiningResult
  },
  data() {
    return {
      inPageNumber: 1,
      pagingCount: 10,
      waitTable: false,
      cropedItems: null,
      showDimRed: false
    }
  },
  props: {
    showTextMining: {
      type: Boolean,
      default: false
    },
    targetType: String,
    vectorizationResult: Array,
    selectedTrainedAi: Object,
    dimRedResult: Array,
    posBottomPopupBorder: Number,
    textMiningResult: Object,
    textMiningSetting: Object
  },
  computed: {
    cropItems() {
      const items = JSON.parse(JSON.stringify(this.vectorizationResult))
      let cropTables = []
      if (items.length > this.pagingCount) {
        const length = Math.ceil(items.length / this.pagingCount)
        cropTables = new Array(length)
          .fill()
          .map((_, i) =>
            items.slice(i * this.pagingCount, (i + 1) * this.pagingCount)
          )
      } else {
        cropTables.push(items)
      }
      return cropTables
    },
    checkDimRed() {
      return this.dimRedResult && this.dimRedResult.length > 0
    }
  },
  methods: {},
  watch: {
    async inPageNumber(newVal) {
      this.waitTable = true
      this.cropedItems = await this.cropItems[newVal - 1]
      this.waitTable = false
    }
  }
}
</script>

<style lang="scss" scoped>
.inference-vectorization-result-wrapper {
  overflow: hidden;
  display: grid;
  grid-template-columns: adjustVW(1248) minmax(0, #{adjustVW(384)});
  grid-template-rows: auto;
  grid-column-gap: $space-base; // スクロールバーとスクロールバーにくっついたmarginが16px分なので
  height: 100%;
}

.inference-vectorization-result {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: $space-medium;
  &-main {
    overflow: hidden;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }

  &-used-ai {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    height: 100%;
    &-card {
      width: 100%;
      height: 100%;
    }
  }
  &-text-mining {
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    height: 100%;
    padding: 0;
  }
}

.inference-vectorization-result-title {
  &-wrap {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 $space-medium;
    margin-bottom: $space-small;
  }
  &-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: adjustVW(1200);
  }
}

.inference-vectorization-dim-red-result {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 $space-medium;
}

.toggle-detail-enter-active,
.toggle-detail-leave-active {
  transition: transform $transition-base, opacity $transition-base;
}
.toggle-detail-enter,
.toggle-detail-leave-to {
  opacity: 0;
  transform: translateY(-$space-small);
  will-change: opacity, transform;
}
</style>
